import React from 'react';
import {
  renderWithProviders,
  screen,
  fireEvent,
} from 'common/utils/reactTestingLibraryHelpers';
import analytics from 'common/utils/analytics';

import ReplaceTestButton from '..';

jest.mock('common/utils/analytics');

const testId = 123;
const barcodeSerialNumber = '123456';
const trackingData = {
  label: 'Claim replacement test',
  category: 'Results Kit Details',
  test_id: testId,
};

describe('<ReplaceTestButton />', () => {
  describe('with a food sensitivity kit', () => {
    beforeEach(() => {
      renderWithProviders(
        <ReplaceTestButton
          testId={testId}
          barcodeSerialNumber={barcodeSerialNumber}
        />,
      );
    });

    it('links to the account settings', () => {
      expect(screen.getByTestId('replaced-test-button')).toHaveAttribute(
        'href',
        `/replacement?barcode_serial_number=${barcodeSerialNumber}`,
      );
    });

    it('calls analytics with tracking data', () => {
      fireEvent.click(screen.getByTestId('replaced-test-button'));

      expect(analytics.track).toHaveBeenCalledWith({
        event: 'Clicked Button',
        data: trackingData,
      });
    });
  });
});
