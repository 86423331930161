import { renderHook } from '@testing-library/react';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import { RefObject } from 'react';

describe('useScrolledIntoViewOnce', () => {
  let actionMock: () => void;

  const ref = {
    current: {
      getBoundingClientRect: () => ({
        height: 100,
        top: 717,
      }),
    },
  };

  const render = () => {
    actionMock = jest.fn();

    renderHook(() =>
      useScrolledIntoViewOnce(actionMock, ref as RefObject<HTMLDivElement>),
    );
  };

  it('should call action if component is in viewport on load', () => {
    render();
    expect(actionMock).toHaveBeenCalledTimes(1);
  });

  it('should not call action if component is out of view', () => {
    ref.current.getBoundingClientRect = () => ({
      height: 250,
      top: 900,
    });
    render();
    expect(actionMock).toHaveBeenCalledTimes(0);
  });

  it('should call action only one time when scrolled into view', async () => {
    ref.current.getBoundingClientRect = () => ({
      height: 250,
      top: 900,
    });

    await render();

    ref.current.getBoundingClientRect = () => ({
      height: 250,
      top: 400,
    });

    await window.dispatchEvent(new Event('scroll'));
    expect(actionMock).toHaveBeenCalledTimes(1);
  });
});
