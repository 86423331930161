export const kitResult = {
  needs_replacement: false,
  user_email: '',
  content: '',
  kit_result_time: '',
  related_marker_results: [],
  collected_at: '2018-06-14T00:15:10.000Z',
  number: 'KR2D1E49910011491B83255A9C334CD6',
  snp_results: [],
  consult: { available: false, available_until: '2018-06-29T22:32:08.839Z' },
  tracking_url: '#',
  serial_number: 12314123,
  product_slug: 'test',
  pwn_order_number: 55555,
  pwn_confirmation_code: 5555,
  severity_resolution: 3,
  lab: {
    id: 37,
    name: 'Molecular Testing Labs, CLIA 50D2050397',
    pwn_lab_identifier: '1068',
    ship_address_id: 13321,
    content_token: 'f2a2aae2-70de-4eed-b69d-a4631f8af169',
  },
  test_marker_names: [
    'Trichomoniasis',
    'HIV 1/2',
    'Hepatitis C',
    'Herpes Simplex 2',
    'Syphilis',
    'Gonorrhea',
    'Chlamydia',
  ],
  marker_results: [
    {
      marker_id: 283,
      boundaries: [],
      boundary_conditions: [],
      observation_datetime: null,
      descriptor: 'not detected',
      test_result_id: 237410,
      severity_index: 0,
      value: 'Not Detected',
      content_token: '6483f589-6bfa-4671-b9ae-0f90b5749b62',
      id: 13787554,
      reported_at: '2018-06-14T00:15:10.000Z',
      kind: 'descriptive',
    },
    {
      marker_id: 275,
      boundaries: [],
      boundary_conditions: [],
      observation_datetime: null,
      descriptor: 'indeterminate',
      test_result_id: 237410,
      severity_index: 1,
      value: 'Indeterminate',
      content_token: 'e3973b2a-a13a-4576-beb6-f2b8375d1435',
      id: 13787555,
      reported_at: '2018-06-14T00:15:10.000Z',
      kind: 'descriptive',
    },
    {
      marker_id: 277,
      boundaries: [],
      boundary_conditions: [],
      observation_datetime: null,
      descriptor: 'detected',
      test_result_id: 237410,
      severity_index: 2,
      value: 'Detected',
      content_token: '9cccaced-3595-4001-97a0-b9f9a422c027',
      id: 13787556,
      reported_at: '2018-06-14T00:15:10.000Z',
      kind: 'descriptive',
    },
    {
      marker_id: 273,
      boundaries: [],
      boundary_conditions: [],
      observation_datetime: null,
      descriptor: 'not detected',
      test_result_id: 237410,
      severity_index: 0,
      value: 'Not Detected',
      content_token: '6806e078-561e-4b11-9d7e-738053d1015a',
      id: 13787557,
      reported_at: '2018-06-14T00:15:10.000Z',
      kind: 'descriptive',
    },
    {
      marker_id: 281,
      boundaries: [],
      boundary_conditions: [],
      observation_datetime: null,
      descriptor: 'detected',
      test_result_id: 237410,
      severity_index: 2,
      value: 'Detected',
      content_token: 'b1215335-6399-42d5-9b34-8b4520b8fa7f',
      id: 13787558,
      reported_at: '2018-06-14T00:15:10.000Z',
      kind: 'descriptive',
    },
    {
      marker_id: 279,
      boundaries: [],
      boundary_conditions: [],
      observation_datetime: null,
      descriptor: 'equivocal',
      test_result_id: 237410,
      severity_index: 4,
      value: 'Equivocal',
      content_token: '9513c192-cfcc-4bcf-a5ce-f8c5961cd0c6',
      id: 13787559,
      reported_at: '2018-06-14T00:15:10.000Z',
      kind: 'descriptive',
    },
    {
      marker_id: 271,
      boundaries: [],
      boundary_conditions: [],
      observation_datetime: null,
      descriptor: 'not detected',
      test_result_id: 237410,
      severity_index: 0,
      value: 'Not Detected',
      content_token: '8eeec053-443f-4476-94be-7164eb3ad665',
      id: 13787560,
      reported_at: '2018-06-14T00:15:10.000Z',
      kind: 'descriptive',
    },
  ],
  test: {
    id: 55,
    name: 'STD Test - Female',
    display_name: 'Sexual Health Test - Female',
    type: 'std-test-female',
    content_token: '8ae2c629-2ec4-4c4b-9ea7-9f25124daf78',
    marker_types: ['descriptive'],
    genomics_eligible: false,
    is_food_sensitivity: false,
    content: {
      TEST_OVERVIEW: 'STD Test - Female',
      RESULTS_SECTION_HEADER: 'test',
    },
    theme_icon: 'other',
    recorded_webinar: false,
  },
  pretty_number: 'KR2D1E4991',
  status: 'results_approved',
  lab_received: null,
  last_name: 'Doe',
  markers: [
    {
      descriptors: [
        'Not Detected',
        'Indeterminate',
        'Detected',
        'Invalid',
        'Equivocal',
        'Specimen Rejected',
        'Specimen Rejected',
        'Specimen Rejected',
      ],
      test_id: 55,
      name: 'Trichomoniasis',

      severity_colors: [
        'green',
        'yellow',
        'red',
        'gray',
        'gray',
        'gray',
        'gray',
        'gray',
      ],
      content_token: '43392dc5-4648-4e12-a9ed-9ba6679cc9dc',

      type: 'descriptive',
      severities: [1, 2, 3, 4, 4, 4, 4, 4],
      groupings: [],
      id: 271,
    },
    {
      descriptors: [
        'Not Detected',
        'Indeterminate',
        'Detected',
        'Invalid',
        'Equivocal',
        'Specimen Rejected',
        'Specimen Rejected',
        'Specimen Rejected',
      ],
      test_id: 55,
      name: 'HIV 1/2',

      severity_colors: [
        'green',
        'yellow',
        'red',
        'gray',
        'gray',
        'gray',
        'gray',
        'gray',
      ],
      content_token: '6d061340-936c-469e-81e2-04a246767845',

      type: 'descriptive',
      severities: [1, 2, 3, 4, 4, 4, 4, 4],
      id: 273,
      groupings: [],
    },
    {
      descriptors: [
        'Not Detected',
        'Indeterminate',
        'Detected',
        'Invalid',
        'Equivocal',
        'Specimen Rejected',
        'Specimen Rejected',
        'Specimen Rejected',
      ],
      test_id: 55,
      name: 'Hepatitis C',

      severity_colors: [
        'green',
        'yellow',
        'red',
        'gray',
        'gray',
        'gray',
        'gray',
        'gray',
      ],
      content_token: 'c1ac7b6c-d44c-41a7-99ce-e601c72331ea',

      type: 'descriptive',
      severities: [1, 2, 3, 4, 4, 4, 4, 4],
      groupings: [],
      id: 275,
    },
    {
      descriptors: [
        'Not Detected',
        'Indeterminate',
        'Detected',
        'Invalid',
        'Equivocal',
        'Specimen Rejected',
        'Specimen Rejected',
        'Specimen Rejected',
      ],
      test_id: 55,
      name: 'Herpes Simplex 2',

      severity_colors: [
        'green',
        'yellow',
        'red',
        'gray',
        'gray',
        'gray',
        'gray',
        'gray',
      ],
      content_token: 'd4d52404-89fc-47d2-8151-bb3b785f693b',

      type: 'descriptive',
      severities: [1, 2, 3, 4, 4, 4, 4, 4],
      groupings: [],
      id: 277,
    },
    {
      descriptors: [
        'Not Detected',
        'Indeterminate',
        'Detected',
        'Invalid',
        'Equivocal',
        'Specimen Rejected',
        'Specimen Rejected',
        'Specimen Rejected',
      ],
      test_id: 55,
      name: 'Syphilis',

      severity_colors: [
        'green',
        'yellow',
        'red',
        'gray',
        'gray',
        'gray',
        'gray',
        'gray',
      ],
      content_token: '994a97dc-91ba-4bbd-b10b-7b0071a64bbe',

      type: 'descriptive',
      severities: [1, 2, 3, 4, 4, 4, 4, 4],
      groupings: [],
      id: 279,
    },
    {
      descriptors: [
        'Not Detected',
        'Indeterminate',
        'Detected',
        'Specimen Rejected',
        'Invalid',
        'Equivocal',
        'Specimen Rejected',
        'Specimen Rejected',
      ],
      test_id: 55,
      name: 'Gonorrhea',

      severity_colors: [
        'green',
        'yellow',
        'red',
        'gray',
        'gray',
        'gray',
        'gray',
        'gray',
      ],
      content_token: 'a82f1f18-3e1d-4922-8182-bd3bc3988095',

      type: 'descriptive',
      severities: [1, 2, 3, 4, 4, 4, 4, 4],
      groupings: [],
      id: 281,
    },
    {
      descriptors: [
        'Not Detected',
        'Indeterminate',
        'Detected',
        'Equivocal',
        'Invalid',
        'Specimen Rejected',
        'Specimen Rejected',
        'Specimen Rejected',
      ],
      test_id: 55,
      name: 'Chlamydia',

      severity_colors: [
        'green',
        'yellow',
        'red',
        'gray',
        'gray',
        'gray',
        'gray',
        'gray',
      ],
      content_token: '2ccbe188-404a-4bfe-8479-92b9919e4d25',

      type: 'descriptive',
      severities: [1, 2, 3, 4, 4, 4, 4, 4],
      groupings: [],
      id: 283,
    },
  ],
  first_name: 'Jane',
  id: 237410,
  product_name: '',
  snps: [],
  reported_at: '2018-06-14T00:15:10.000Z',
  kind: 'descriptive',
  clia: 'ABC1234567',
};

export const equivocalMarkerWithResult = {
  descriptors: [
    'Not Detected',
    'Indeterminate',
    'Detected',
    'Invalid',
    'Equivocal',
    'Specimen Rejected',
    'Specimen Rejected',
    'Specimen Rejected',
  ],
  test_id: 55,
  name: 'Syphilis',
  severity_colors: [
    'green',
    'yellow',
    'red',
    'gray',
    'gray',
    'gray',
    'gray',
    'gray',
  ],
  content_token: '994a97dc-91ba-4bbd-b10b-7b0071a64bbe',
  type: 'descriptive',
  severities: [1, 2, 3, 4, 4, 4, 4, 4],
  groupings: [],
  id: 279,
  marker_result: {
    marker_id: 279,
    boundaries: [],
    boundary_conditions: [],
    observation_datetime: null,
    descriptor: 'equivocal',
    test_result_id: 237410,
    severity_index: 4,
    value: 'Equivocal',
    content_token: '9513c192-cfcc-4bcf-a5ce-f8c5961cd0c6',
    id: 13787559,
    reported_at: '2018-06-14T00:15:10.000Z',
    kind: 'descriptive',
  },
};

export const markers = [
  {
    descriptors: [
      'Not Detected',
      'Indeterminate',
      'Detected',
      'Invalid',
      'Equivocal',
      'Specimen Rejected',
      'Specimen Rejected',
      'Specimen Rejected',
    ],
    test_id: 55,
    name: 'Herpes Simplex 2',
    severity_colors: [
      'green',
      'yellow',
      'red',
      'gray',
      'gray',
      'gray',
      'gray',
      'gray',
    ],
    content_token: 'd4d52404-89fc-47d2-8151-bb3b785f693b',
    type: 'descriptive',
    severities: [1, 2, 3, 4, 4, 4, 4, 4],
    groupings: [],
    id: 277,
    marker_result: {
      marker_id: 277,
      boundaries: [],
      boundary_conditions: [],
      observation_datetime: null,
      descriptor: 'detected',
      test_result_id: 237410,
      severity_index: 2,
      value: 'Detected',
      content_token: '9cccaced-3595-4001-97a0-b9f9a422c027',
      id: 13787556,
      reported_at: '2018-06-14T00:15:10.000Z',
      kind: 'descriptive',
    },
  },
  {
    descriptors: [
      'Not Detected',
      'Indeterminate',
      'Detected',
      'Specimen Rejected',
      'Invalid',
      'Equivocal',
      'Specimen Rejected',
      'Specimen Rejected',
    ],
    test_id: 55,
    name: 'Gonorrhea',
    severity_colors: [
      'green',
      'yellow',
      'red',
      'gray',
      'gray',
      'gray',
      'gray',
      'gray',
    ],
    content_token: 'a82f1f18-3e1d-4922-8182-bd3bc3988095',
    type: 'descriptive',
    severities: [1, 2, 3, 4, 4, 4, 4, 4],
    groupings: [],
    id: 281,
    marker_result: {
      marker_id: 281,
      boundaries: [],
      boundary_conditions: [],
      observation_datetime: null,
      descriptor: 'detected',
      test_result_id: 237410,
      severity_index: 2,
      value: 'Detected',
      content_token: 'b1215335-6399-42d5-9b34-8b4520b8fa7f',
      id: 13787558,
      reported_at: '2018-06-14T00:15:10.000Z',
      kind: 'descriptive',
    },
  },
  {
    descriptors: [
      'Not Detected',
      'Indeterminate',
      'Detected',
      'Equivocal',
      'Invalid',
      'Specimen Rejected',
      'Specimen Rejected',
      'Specimen Rejected',
    ],
    test_id: 55,
    name: 'Chlamydia',
    severity_colors: [
      'green',
      'yellow',
      'red',
      'gray',
      'gray',
      'gray',
      'gray',
      'gray',
    ],
    content_token: '2ccbe188-404a-4bfe-8479-92b9919e4d25',
    type: 'descriptive',
    severities: [1, 2, 3, 4, 4, 4, 4, 4],
    groupings: [],
    id: 283,
    marker_result: {
      marker_id: 283,
      boundaries: [],
      boundary_conditions: [],
      observation_datetime: null,
      descriptor: 'not detected',
      test_result_id: 237410,
      severity_index: 0,
      value: 'Not Detected',
      content_token: '6483f589-6bfa-4671-b9ae-0f90b5749b62',
      id: 13787554,
      reported_at: '2018-06-14T00:15:10.000Z',
      kind: 'descriptive',
    },
  },
  {
    descriptors: [
      'Not Detected',
      'Indeterminate',
      'Detected',
      'Invalid',
      'Equivocal',
      'Specimen Rejected',
      'Specimen Rejected',
      'Specimen Rejected',
    ],
    test_id: 55,
    name: 'HIV 1/2',
    severity_colors: [
      'green',
      'yellow',
      'red',
      'gray',
      'gray',
      'gray',
      'gray',
      'gray',
    ],
    content_token: '6d061340-936c-469e-81e2-04a246767845',
    type: 'descriptive',
    severities: [1, 2, 3, 4, 4, 4, 4, 4],
    groupings: [],
    id: 273,
    marker_result: {
      marker_id: 273,
      boundaries: [],
      boundary_conditions: [],
      observation_datetime: null,
      descriptor: 'not detected',
      test_result_id: 237410,
      severity_index: 0,
      value: 'Not Detected',
      content_token: '6806e078-561e-4b11-9d7e-738053d1015a',
      id: 13787557,
      reported_at: '2018-06-14T00:15:10.000Z',
      kind: 'descriptive',
    },
  },
  equivocalMarkerWithResult,
  {
    descriptors: [
      'Not Detected',
      'Indeterminate',
      'Detected',
      'Invalid',
      'Equivocal',
      'Specimen Rejected',
      'Specimen Rejected',
      'Specimen Rejected',
    ],
    test_id: 55,
    name: 'Trichomoniasis',
    severity_colors: [
      'green',
      'yellow',
      'red',
      'gray',
      'gray',
      'gray',
      'gray',
      'gray',
    ],
    content_token: '43392dc5-4648-4e12-a9ed-9ba6679cc9dc',
    type: 'descriptive',
    severities: [1, 2, 3, 4, 4, 4, 4, 4],
    groupings: [],
    id: 271,
    marker_result: {
      marker_id: 271,
      boundaries: [],
      boundary_conditions: [],
      observation_datetime: null,
      descriptor: 'not detected',
      test_result_id: 237410,
      severity_index: 0,
      value: 'Not Detected',
      content_token: '8eeec053-443f-4476-94be-7164eb3ad665',
      id: 13787560,
      reported_at: '2018-06-14T00:15:10.000Z',
      kind: 'descriptive',
    },
  },
  {
    descriptors: [
      'Not Detected',
      'Indeterminate',
      'Detected',
      'Invalid',
      'Equivocal',
      'Specimen Rejected',
      'Specimen Rejected',
      'Specimen Rejected',
    ],
    test_id: 55,
    name: 'Hepatitis C',
    severity_colors: [
      'green',
      'yellow',
      'red',
      'gray',
      'gray',
      'gray',
      'gray',
      'gray',
    ],
    content_token: 'c1ac7b6c-d44c-41a7-99ce-e601c72331ea',
    type: 'descriptive',
    severities: [1, 2, 3, 4, 4, 4, 4, 4],
    groupings: [],
    id: 275,
    marker_result: {
      marker_id: 275,
      boundaries: [],
      boundary_conditions: [],
      observation_datetime: null,
      descriptor: 'indeterminate',
      test_result_id: 237410,
      severity_index: 1,
      value: 'Indeterminate',
      content_token: 'e3973b2a-a13a-4576-beb6-f2b8375d1435',
      id: 13787555,
      reported_at: '2018-06-14T00:15:10.000Z',
      kind: 'descriptive',
    },
  },
];

const marker = {
  id: 281,
  test_id: 55,
  descriptors: [
    'Not Detected',
    'Indeterminate',
    'Detected',
    'Specimen Rejected',
    'Invalid',
    'Equivocal',
    'Specimen Rejected',
    'Specimen Rejected',
  ],
  severities: [1, 2, 3, 4, 4, 4, 4, 4],
  groupings: [],
  severity_colors: [
    'green',
    'yellow',
    'red',
    'gray',
    'gray',
    'gray',
    'gray',
    'gray',
  ],
  name: 'Gonorrhea',
  units: null,
  min_value: null,
  max_value: null,
  content_token: 'a82f1f18-3e1d-4922-8182-bd3bc3988095',
  type: 'descriptive',
};

const markerResults = {
  id: 13787558,
  marker_id: 281,
  value: 'Detected',
  severity_index: 2,
  descriptor: 'detected',
  test_result_id: 237410,
  boundaries: [],
  boundary_conditions: [],
  reported_at: '2018-06-14T00:15:10.000Z',
  kind: 'descriptive',
  observation_datetime: null,
  content_token: 'b1215335-6399-42d5-9b34-8b4520b8fa7f',
};

export const markerWithResult = { ...marker, marker_result: markerResults };

export const detectedMarkerResult = {
  marker_id: 277,
  boundaries: [],
  boundary_conditions: [],
  observation_datetime: null,
  descriptor: 'detected',
  test_result_id: 237410,
  severity_index: 2,
  value: 'Detected',
  content_token: '9cccaced-3595-4001-97a0-b9f9a422c027',
  id: 13787556,
  reported_at: '2018-06-14T00:15:10.000Z',
  kind: 'descriptive',
};

export const detectedMarkerWithResult = {
  descriptors: [
    'Not Detected',
    'Indeterminate',
    'Detected',
    'Invalid',
    'Equivocal',
    'Specimen Rejected',
    'Specimen Rejected',
    'Specimen Rejected',
  ],
  test_id: 55,
  name: 'Herpes Simplex 2',
  severity_colors: [
    'green',
    'yellow',
    'red',
    'gray',
    'gray',
    'gray',
    'gray',
    'gray',
  ],
  content_token: 'd4d52404-89fc-47d2-8151-bb3b785f693b',
  type: 'descriptive',
  severities: [1, 2, 3, 4, 4, 4, 4, 4],
  id: 277,
  marker_result: detectedMarkerResult,
  groupings: [],
};

export const detectedMarkerWithResultContent = {
  markerOverview:
    'Gonorrhea is a common bacterial STD that is usually not symptomatic but can be cured if detected and treated early.',
  markerTellMeMore:
    '<p>Gonorrhea is caused by a bacteria known as Neisseria gonorrhoeae. It can be spread through any type of sexual intercourse (oral, vaginal, or anal) and is more common in men than women, but it can be cured with antibiotic medication.</p><p>This test checks specifically for gonorrhea in the vagina and cervix. The vaginal swab used for this test cannot rule out gonorrhea infections in other areas of the body, such as the mouth, throat, and rectum. If you believe you may be infected in one of these areas, it’s important to see your healthcare provider for appropriate testing.</p>\r\n<p>A negative test may need to be repeated in a few weeks if you’re concerned about recent exposure, or if you begin showing new symptoms, as very recent exposures may not yet show up on this test. And regardless of your result, it’s important to talk to your healthcare provider if you’re experiencing any symptoms of gonorrhea, or if you think you may have been exposed to gonorrhea recently.</p>\r\n<p>The CDC recommends being tested for gonorrhea at least once a year, or more frequently (every 3–6 months) if you’re sexually active and not in a long-term, mutually monogamous relationship with a partner who has tested negative.</p>',
  moreResources: [
    {
      name: 'WebMD Sexual Conditions Guide: Gonorrhea',
      link: 'https://www.webmd.com/sexual-conditions/guide/gonorrhea#1',
    },
    {
      name: 'CDC Information Guide: Gonorrhea ',
      link: 'https://www.cdc.gov/std/Gonorrhea/',
    },
  ],
};

export const notDetectedMarkerWithResult = {
  descriptors: [
    'Not Detected',
    'Indeterminate',
    'Detected',
    'Equivocal',
    'Invalid',
    'Specimen Rejected',
    'Specimen Rejected',
    'Specimen Rejected',
  ],
  test_id: 55,
  name: 'Chlamydia',
  severity_colors: [
    'green',
    'yellow',
    'red',
    'gray',
    'gray',
    'gray',
    'gray',
    'gray',
  ],
  content_token: '2ccbe188-404a-4bfe-8479-92b9919e4d25',
  type: 'descriptive',
  severities: [1, 2, 3, 4, 4, 4, 4, 4],
  groupings: [],
  id: 283,
  marker_result: {
    marker_id: 283,
    boundaries: [],
    boundary_conditions: [],
    observation_datetime: null,
    descriptor: 'not detected',
    test_result_id: 237410,
    severity_index: 0,
    value: 'Not Detected',
    content_token: '6483f589-6bfa-4671-b9ae-0f90b5749b62',
    id: 13787554,
    reported_at: '2018-06-14T00:15:10.000Z',
    kind: 'descriptive',
  },
};

export const indeterminateMarkerWithResult = {
  descriptors: [
    'Not Detected',
    'Indeterminate',
    'Detected',
    'Invalid',
    'Equivocal',
    'Specimen Rejected',
    'Specimen Rejected',
    'Specimen Rejected',
  ],
  test_id: 55,
  name: 'Hepatitis C',

  severity_colors: [
    'green',
    'yellow',
    'red',
    'gray',
    'gray',
    'gray',
    'gray',
    'gray',
  ],
  content_token: 'c1ac7b6c-d44c-41a7-99ce-e601c72331ea',

  type: 'descriptive',
  severities: [1, 2, 3, 4, 4, 4, 4, 4],
  groupings: [],
  id: 275,
  marker_result: {
    marker_id: 275,
    boundaries: [],
    boundary_conditions: [],
    observation_datetime: null,
    descriptor: 'indeterminate',
    test_result_id: 237410,
    severity_index: 1,
    value: 'Indeterminate',
    content_token: 'e3973b2a-a13a-4576-beb6-f2b8375d1435',
    id: 13787555,
    reported_at: '2018-06-14T00:15:10.000Z',
    kind: 'descriptive',
  },
};
