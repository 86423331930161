import { size, colors, Icons, typography } from '@everlywell/leaves';
import styled, { css } from 'styled-components';

export const AccordionTitle = styled.div<{ isPastOrFutureStep: boolean }>`
  ${typography.buttonText}
  padding: 0;
  cursor: pointer;
  color: ${({ isPastOrFutureStep }) =>
    isPastOrFutureStep ? `${colors.gray4}` : `${colors.green4}`};
`;

export const AccordionInnerTitle = styled.div`
  display: flex;
  gap: ${size.sm}px;
`;

export const InnerTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
`;

export const InnerTitle = styled.div`
  width: 100%;
`;

export const AccordionChild = styled.div`
  padding-bottom: ${size.lg}px;
  padding-left: 26px;
  border-left: ${size.xs3}px dashed ${colors.gray2};
  margin-left: ${size.sm}px;
`;

export const AccordionCopy = styled.div`
  max-width: 830px;
  padding-top: ${size.xs1}px;

  a {
    text-decoration: none;
  }

  .line-break {
    white-space: pre-wrap;
  }
`;

export const TimeDuration = styled.div<{ shouldShowMore: boolean }>`
  ${typography.captionText}
  color: ${colors.green4};
  transition: opacity 0.3ms ease-in-out;
  padding-top: ${size.xs2}px;
  ${({ shouldShowMore }) =>
    shouldShowMore
      ? css`
          opacity: 1;
        `
      : css`
          transition: opacity 0.5s ease-in-out;
          opacity: 0;
        `};
`;

export const ProgressIconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DottedLine = styled.div<{
  shouldShowMore: boolean;
}>`
  border-left: ${size.xs3}px dashed ${colors.gray2};
  height: ${size.lg}px;
  transition: height 0.5s cubic-bezier(0, 1, 0, 1);
  ${({ shouldShowMore }) =>
    shouldShowMore
      ? css`
          transition: height 0.5s ease-in-out;
        `
      : css``};
`;

export const SkinnyChevronIcon = styled(Icons.DownCaret)<{
  shouldShowMore: boolean;
}>`
  display: inline-block;
  width: 15px;
  transition: transform ease 0.5s;
  transform: rotate(${({ shouldShowMore }) => (shouldShowMore ? 180 : 0)}deg);
  margin-left: ${size.sm}px;
  color: ${colors.green4};
`;

/** Accordion expansion and close styling */
export const HiddenAccordionChild = styled.div<{
  shouldShowMore: boolean;
  addBorder: boolean;
}>`
  max-width: 830px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  padding-left: 24px;
  border-left: ${({ addBorder }) =>
    addBorder ? `${size.xs3}px dashed ${colors.gray2}` : 'none'};
  margin-left: ${size.sm}px;
  ${({ shouldShowMore }) =>
    shouldShowMore
      ? css`
          transition: max-height 1s ease-in-out;
          /**
             Animating the height from a specific value to auto using CSS animations is not straightforward
            since auto is not an animatable value. However, we can achieve a similar effect by
            animating the maximum height of the div instead and setting the max height to a large value.
          */
          max-height: 10000px;
        `
      : css``};
`;

export const ReadMoreTitle = styled.div`
  ${typography.buttonText}
  justify-content: flex-start;
  gap: ${size.sm}px;
  padding-top: ${size.lg}px;
  padding-bottom: 0;
  cursor: pointer;
  color: ${colors.green4};

  > div {
    display: flex;
  }
`;

export const SolidChevronIcon = styled(Icons.DownSolidCaret)<{
  shouldShowMore: boolean;
}>`
  display: inline-block;
  width: 16px;
  transition: transform ease 0.5s;
  transform: rotate(${({ shouldShowMore }) => (shouldShowMore ? 180 : 0)}deg);
  margin-left: ${size.sm}px;
`;

export const HiddenReadMoreAccordionChild = styled.div<{
  shouldShowMore: boolean;
}>`
  max-width: 830px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  border-left: none;
  ${({ shouldShowMore }) =>
    shouldShowMore
      ? css`
          transition: max-height 1s ease-in-out;
          /**
             Animating the height from a specific value to auto using CSS animations is not straightforward
            since auto is not an animatable value. However, we can achieve a similar effect by
            animating the maximum height of the div instead and setting the max height to a large value.
          */
          max-height: 10000px;
          border-left: none;
        `
      : css``};
`;

export const TasksSection = styled.div<{
  hasTasks: boolean;
}>`
  padding-top: ${size.lg}px;
  padding-bottom: ${({ hasTasks }) => (hasTasks ? `${size.lg}px` : 0)};
`;
