/**
 * KitCard
 */

import { Button, Localize, size } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { SCROLL_TO_TELEHEALTH, STATUSES } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import {
  QUEST_FAQ_LINK,
  QUEST_SCHEDULE_LINK,
} from 'common/utils/constants/bloodDraw';
import { KIT_LIST } from 'common/utils/constants/dataTest';
import {
  PUBLIC_APP_ROOT,
  DEFAULT_BOX_IMAGE_URL,
} from 'common/utils/constants/urls';
import {
  showConsultForName,
  generatePWNConsultLink,
} from 'common/utils/helpers';
import { KitResult } from 'common/utils/types';
import { format, parseISO } from 'date-fns';
import React, { useEffect } from 'react';

import ScheduleCard from '../../ScheduleCard';
import ConfirmIdentifiersButton from './components/ConfirmIdentifiersButton';
import ProgressBar from './components/ProgressBar';
import ReplaceTestButton from './components/ReplaceTestButton';
import Status from './components/Status';
import StatusMessage from './components/StatusMessage';
import * as Style from './components/StatusMessage/styles';
import TrackSampleButton from './components/TrackSampleButton';
import ViewResultSection from './components/ViewResultSection';
import { getActiveIndex, getLabels } from './statuses.utils';
import * as S from './styles';

type Props = {
  kitResult: KitResult;
  index?: number;
  testLabel?: string;
  getDetailedKitResult?: Function;
  className?: string;
  category?: string;
};

const KitCard = (props: Props) => {
  const {
    kitResult,
    index = 0,
    testLabel,
    className,
    category = 'Dashboard',
  } = props;

  const {
    id,
    box_image_url,
    cancel_reason,
    confirm_identifiers_issue_id,
    delivered_at,
    in_transit_at,
    is_lab_visit,
    kit_registered_at,
    lab,
    lab_received_at,
    max_process_time,
    min_process_time,
    needs_replacement,
    number,
    product_name,
    product_slug,
    pwn_order_number,
    pwn_confirmation_code,
    requisition_approved_at,
    results_approved_at,
    replacement_requested_at,
    serial_number,
    status,
    test_id,
    tracking_url,
    viewed_at,
  } = kitResult;

  const atLab =
    lab && lab.name && status === STATUSES.RESULTS_PROCESSING
      ? lab.name
      : undefined;

  let statusDate = null;
  let statusText = null;
  let barWidth = 0;
  let mobileActionItem = null;
  let desktopActionItem = null;
  let approvedDate = 0;
  let daysSinceApproved = 0;

  const isRapidAntigen = product_slug.toLowerCase().includes('covid-19-rapid');
  const trackingText = 'Track Your Kit';
  const confirmIdentifiers = Boolean(confirm_identifiers_issue_id);
  const trackSampleButton = isRapidAntigen ? undefined : (
    <TrackSampleButton
      testId={test_id}
      text={trackingText}
      trackingUrl={tracking_url}
    />
  );

  const trackingLinkElement = tracking_url ? (
    <Style.Link
      target="_blank"
      onClick={() => {
        analytics.track({
          event: ANALYTICS.EVENTS.CLICKED_BUTTON,
          data: {
            label: `Track Kit`,
            test_id,
            category: 'Results Dashboard',
          },
        });
      }}
      href={tracking_url}
    >
      here
    </Style.Link>
  ) : null;

  if (results_approved_at) {
    const todaysDate = new Date().getTime();
    approvedDate = Date.parse(results_approved_at);
    daysSinceApproved = Math.floor(
      (todaysDate - approvedDate) / (1000 * 3600 * 24),
    );
  }

  useEffect(() => {
    const viewedLabelPiece = `- ${
      viewed_at
        ? `viewed ${
            daysSinceApproved > 14 ? 'after 14 days' : 'within 14 days'
          }`
        : 'not viewed'
    }`;
    const needsReplacementLabelPiece = `- ${
      needs_replacement ? ' - needs replacement' : null
    }`;
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: `${status}${
          status === 'ready' ? viewedLabelPiece : null
        }${needsReplacementLabelPiece}`,
        test_id,
        category: 'Results Dashboard',
      },
    });
  }, [daysSinceApproved, needs_replacement, status, test_id, viewed_at]);

  if (needs_replacement) {
    const invalidState = cancel_reason === 'invalid_product_for_state';
    statusDate = {
      text: invalidState ? 'Test Issue' : 'Sample Issue',
      date: '',
      color: 'red',
    };
    statusText = invalidState ? 'Test Issue' : '\u25B2 Sample Issue';
    barWidth = 100;
    mobileActionItem = <ReplaceTestButton testId={test_id} />;
    desktopActionItem = <ReplaceTestButton testId={test_id} />;
  } else if (confirmIdentifiers) {
    statusDate = {
      text: 'Sample Issue',
      date: kit_registered_at,
      color: 'red',
    };
    statusText = '\u25B2 Sample Issue';
    barWidth = 100;
    mobileActionItem = (
      <ConfirmIdentifiersButton issueId={confirm_identifiers_issue_id} />
    );
    desktopActionItem = (
      <ConfirmIdentifiersButton issueId={confirm_identifiers_issue_id} />
    );
  } else if (status === STATUSES.KIT_REGISTERED) {
    statusDate = { text: 'Registered', date: kit_registered_at, color: 'tan' };
    statusText = 'Registered';
    barWidth = 25;
    mobileActionItem = trackSampleButton;
    desktopActionItem = trackSampleButton;
  } else if (status === STATUSES.IN_TRANSIT) {
    statusDate = {
      text: isRapidAntigen ? undefined : 'In Transit',
      date: in_transit_at,
      color: 'teal',
    };
    statusText = 'In Transit';
    barWidth = 50;
    mobileActionItem = trackSampleButton;
    desktopActionItem = trackSampleButton;
  } else if (status === STATUSES.DELIVERED) {
    statusDate = {
      text: isRapidAntigen ? undefined : 'Delivered',
      date: delivered_at,
      color: 'teal',
    };
    statusText = 'Delivered';
    barWidth = 50;
    mobileActionItem = trackSampleButton;
    desktopActionItem = trackSampleButton;
  } else if (status === STATUSES.LAB_VISIT_APPROVED) {
    barWidth = 70;
    statusDate = {
      text: 'Approved',
      date: requisition_approved_at,
      color: 'green',
    };
  } else if (status === STATUSES.RESULTS_PROCESSING) {
    statusDate = { text: 'Processing', date: lab_received_at, color: 'orange' };
    statusText = 'Processing';
    barWidth = 75;
    mobileActionItem = (
      <S.ScienceButton
        appearance="tertiary"
        href={`${PUBLIC_APP_ROOT}/science`}
        onClick={() => {
          analytics.track({
            event: ANALYTICS.EVENTS.CLICKED_BUTTON,
            data: {
              label: `View Science Page`,
              test_id,
              category: 'Results Dashboard',
            },
          });
        }}
      >
        Read About Our Science
      </S.ScienceButton>
    );
    desktopActionItem = (
      <S.ScienceButton
        appearance="tertiary"
        href={`${PUBLIC_APP_ROOT}/science`}
        onClick={() => {
          analytics.track({
            event: ANALYTICS.EVENTS.CLICKED_BUTTON,
            data: {
              label: `View Science Page`,
              test_id,
              category: 'Results Dashboard',
            },
          });
        }}
      >
        Read About Our Science
      </S.ScienceButton>
    );
  } else if (status === STATUSES.RESULTS_APPROVED && is_lab_visit) {
    barWidth = 0;
  } else if (status === STATUSES.RESULTS_APPROVED) {
    statusDate = {
      text: viewed_at ? undefined : 'Results Ready',
      date: results_approved_at,
      color: 'green',
    };
    statusText = 'Results';
    barWidth = 100;
    // desktopActionItem =  14 >= daysSinceApproved ? <ScheduleWebinarButton testId={test_id} /> : null;
  } else if (status === STATUSES.KIT_CANCELED && replacement_requested_at) {
    statusDate = {
      text: 'Replaced',
      date: '',
      color: 'gray',
    };
    statusText = 'Replaced';
    mobileActionItem = trackSampleButton;
    desktopActionItem = trackSampleButton;
  } else if (status === STATUSES.KIT_CANCELED) {
    statusDate = {
      text: 'Canceled',
      date: '',
      color: 'gray',
    };
    statusText = 'Canceled';
  }
  const resultsViewed = viewed_at && status === STATUSES.RESULTS_APPROVED;
  const shouldShowPWN =
    status === STATUSES.RESULTS_APPROVED &&
    !needs_replacement &&
    pwn_order_number &&
    pwn_confirmation_code &&
    showConsultForName(kitResult, product_name);

  const showDownloadButton =
    is_lab_visit && status === STATUSES.LAB_VISIT_APPROVED;
  const downloadButton = showDownloadButton ? (
    <Button
      appearance="tertiary"
      data-test="schedule-lab-visit"
      href={QUEST_SCHEDULE_LINK}
      target="_blank"
    >
      Schedule now
    </Button>
  ) : null;

  const mobileStatusTextMap = getLabels(
    is_lab_visit ?? false,
    status,
    isRapidAntigen,
  );

  const activeIndex = getActiveIndex(status, mobileStatusTextMap);

  const statusTexts = Object.values(mobileStatusTextMap);

  const mobileStatusText = statusTexts[activeIndex];

  const getStatusText = (statusText: string | null) => {
    if (statusText && !is_lab_visit) {
      return statusText;
    } else if (status === 'ready') {
      // don't show status text if when status is ready
      return null;
    } else {
      return mobileStatusText;
    }
  };

  return (
    <S.KitCard
      className={className}
      data-testid={`kitResult-${number}`}
      data-test={`${testLabel}${index + 1}`}
    >
      <S.StyledKitMenu
        trackingUrl={tracking_url}
        serialNumber={serial_number}
        testId={test_id}
        status={status}
        rapidAntigen={isRapidAntigen}
      />
      <S.MobileFAQLink
        href={is_lab_visit ? QUEST_FAQ_LINK : 'https://support.everlywell.com/'}
        onClick={() => {
          analytics.track({
            event: ANALYTICS.EVENTS.CLICKED_BUTTON,
            data: {
              label: `FAQ Link`,
              test_id,
              category: 'Results Dashboard',
            },
          });
        }}
      >
        <S.FAQIcon />
        FAQs
      </S.MobileFAQLink>
      <S.TestInfo>
        <S.BoxImageContainer isLabVisit={is_lab_visit}>
          <S.BoxImage
            src={box_image_url || DEFAULT_BOX_IMAGE_URL}
            alt={`${product_name} product box`}
            data-test={KIT_LIST.KIT_IMAGE}
          />
        </S.BoxImageContainer>
        <S.Details>
          <S.Title data-test={KIT_LIST.KIT_TITLE}>
            <Localize name="KitCard-name">{product_name}</Localize>
          </S.Title>
          {statusDate && statusDate.text ? (
            <S.Date data-test={KIT_LIST.KIT_DATE} color={statusDate.color}>
              {statusDate.text}{' '}
              {statusDate.date && (
                <Localize name="KitCard-registered-date">
                  {format(parseISO(statusDate.date), 'MM/dd/yy')}
                </Localize>
              )}
            </S.Date>
          ) : null}
        </S.Details>
        <S.DesktopActionSection>
          {downloadButton}
          <S.DesktopActionInner
            style={{ paddingTop: downloadButton ? `${size.md}px` : 0 }}
          >
            {desktopActionItem}
            <S.FAQLink
              href={
                is_lab_visit
                  ? QUEST_FAQ_LINK
                  : 'https://support.everlywell.com/'
              }
              onClick={() => {
                analytics.track({
                  event: ANALYTICS.EVENTS.CLICKED_BUTTON,
                  data: {
                    label: `FAQ Link`,
                    test_id,
                    category: 'Results Dashboard',
                  },
                });
              }}
            >
              <S.FAQIcon />
              FAQs
            </S.FAQLink>
          </S.DesktopActionInner>
        </S.DesktopActionSection>
      </S.TestInfo>
      {resultsViewed ? null : (
        <Status
          statusText={getStatusText(statusText)}
          needsReplacement={needs_replacement}
        />
      )}
      {resultsViewed ? null : (
        <StatusMessage
          key={`${product_slug}-${status}-${number}`}
          atLab={atLab}
          status={status}
          cancelReason={cancel_reason}
          needsReplacement={needs_replacement}
          replacementRequestedAt={replacement_requested_at}
          trackingLinkElement={trackingLinkElement}
          min_process_time={min_process_time}
          max_process_time={max_process_time}
          slug={product_slug}
          dateReceived={lab_received_at}
          isLabVisit={is_lab_visit}
          confirmIdentifiers={confirmIdentifiers}
        />
      )}
      {resultsViewed ? null : (
        <ProgressBar
          status={status}
          needsReplacement={needs_replacement}
          barWidth={barWidth}
          isRapidAntigen={isRapidAntigen}
          isLabVisit={!!is_lab_visit}
          confirmIdentifiers={confirmIdentifiers}
        />
      )}
      <S.MobileActionItems>
        {mobileActionItem}
        <div style={{ paddingTop: downloadButton ? `${size.md}px` : 0 }}>
          {downloadButton}
        </div>
      </S.MobileActionItems>
      {status === STATUSES.RESULTS_APPROVED && !needs_replacement ? (
        <ViewResultSection
          viewedAt={viewed_at}
          number={number}
          testId={test_id}
          productName={product_name}
          results_approved_at={results_approved_at}
          key={id}
          category={category}
        />
      ) : null}
      {shouldShowPWN ? (
        <ScheduleCard
          title="Speak with a physician"
          content="Receive a call from a board-certified, licensed physician within four business hours of requesting a telehealth consultation to discuss your results at no additional cost."
          buttonContent="Request Consult"
          external
          buttonHref={
            pwn_order_number && pwn_confirmation_code
              ? generatePWNConsultLink(
                  pwn_order_number,
                  pwn_confirmation_code,
                  product_name,
                )
              : `/results/${kitResult.number}/#${SCROLL_TO_TELEHEALTH}`
          }
          kitResult={kitResult}
          label="Schedule PWN Consult"
        />
      ) : null}
    </S.KitCard>
  );
};

export default KitCard;
