import { colors, mediaQueries } from '@everlywell/leaves';
import { Col_12_Span_N } from 'common/styles/grid-styles';
import styled from 'styled-components';

const ConsiderChangesSectionWrapper = styled.div`
  padding-bottom: 0;

  ${mediaQueries.forBigDesktopUp} {
    background-size: 100%;
  }
`;

const CardsSection = styled(Col_12_Span_N as any)`
  background-color: ${colors.white};
  margin-bottom: 0;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 0;
  }
`;

export { CardsSection, ConsiderChangesSectionWrapper };
