import { MARKER_TYPES } from 'common/utils/constants';
import {
  ImmutableReduxState,
  MarkerWithResult,
  NumericalMarkerContent,
  ResourceLink,
} from 'common/utils/types';
import MultiNumericalMarkerCard from 'components/MultiNumericalMarkerCard';
import NumericalMarkerCard from 'components/NumericalMarkerCard';
import * as React from 'react';
import { connect } from 'react-redux';
import { makeTailoredContent } from 'store/kitResult/NumericalMarkerCardContainer/selectors';
import { makeSelectContent } from 'store/selectors';

const CONTENT_KEYS = {
  markerOverview: 'MARKER_OVERVIEW',
  markerTellMeMore: 'MARKER_TELL_ME_MORE',
  moreResourcesLinks: 'MORE_RESOURCES_LINK_*',
  moreResourcesNames: 'MORE_RESOURCES_NAME_*',
};

type StateProps = {
  content: {
    markerOverview: string;
    markerTellMeMore: string;
    tailoredHeader: string;
    tailoredContent: string;
    moreResourcesNames: string[];
    moreResourcesLinks: string[];
  };
};

type OwnProps = {
  key: number;
  markersWithResults: MarkerWithResult[];
  contentToken: string;
  outOfRange: boolean;
  viewingSharedKitResult?: boolean;
};

type MarkerCardProps = {
  key: number;
  markersWithResults: MarkerWithResult[];
  content: NumericalMarkerContent;
  outOfRange: boolean;
  viewingSharedKitResult?: boolean;
};

const MarkerCardComponents = {
  [MARKER_TYPES.PLAIN_NUMBER]: NumericalMarkerCard,
  [MARKER_TYPES.NUMERICAL]: NumericalMarkerCard,
  [MARKER_TYPES.MULTI_NUMERICAL]: MultiNumericalMarkerCard,
};

function MarkerCard(props: MarkerCardProps) {
  const { markersWithResults, content, outOfRange, viewingSharedKitResult } =
    props;
  const marker = markersWithResults[0];
  const { type } = marker;
  const Component = MarkerCardComponents[type];
  return Component ? (
    <Component
      markersWithResults={markersWithResults}
      content={content}
      outOfRange={outOfRange}
      viewingSharedKitResult={viewingSharedKitResult}
    />
  ) : null;
}

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): StateProps {
  const { contentToken, markersWithResults } = ownProps;
  const markerContent = makeSelectContent(contentToken, CONTENT_KEYS)(state);
  const tailoredContent = makeTailoredContent(state, markersWithResults);
  const content = {
    ...markerContent,
    tailoredHeader: tailoredContent.tailoredHeader,
    tailoredContent: tailoredContent.tailoredOverview,
  };

  return {
    content,
  };
}

function mergeProps(
  stateProps: StateProps,
  _dispatchProps: null,
  ownProps: OwnProps,
): MarkerCardProps {
  const { content } = stateProps;
  if (!content) {
    return {
      ...ownProps,
      content: {
        markerOverview: '',
        markerTellMeMore: '',
        moreResources: [],
        tailoredOverview: '',
      },
    };
  }

  let moreResources: ResourceLink[] = [];
  if (content.moreResourcesNames && content.moreResourcesLinks) {
    const { moreResourcesNames, moreResourcesLinks } = content;
    moreResources = moreResourcesNames.map((name: string, index: number) => ({
      name,
      link: moreResourcesLinks[index],
    }));
  }

  const newContent = {
    markerOverview: content.markerOverview,
    markerTellMeMore: content.markerTellMeMore,
    tailoredHeader: content.tailoredHeader,
    tailoredOverview: content.tailoredContent,
    moreResources,
  };

  return { ...ownProps, content: newContent };
}

const NumericalMarkerCardContainer = connect(
  mapStateToProps,
  null,
  mergeProps,
)(MarkerCard);

export { NumericalMarkerCardContainer, MarkerCard };
