import { colors, mediaQueries, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const ToolTipWidth = 150;

const WrapperHeightWithCheckIn = 321;

const Wrapper = styled.div`
  margin-bottom: ${size.xl4}px;

  ${mediaQueries.forTabletVerticalUp} {
    margin-bottom: ${size.xl8}px;
  }
`;

const Header = styled.header`
  align-items: center;
  color: ${colors.green5};
  display: flex;
  margin-bottom: ${size.sm};

  .tooltip {
    [id^='tooltip-box'] {
      min-width: ${ToolTipWidth}px;
    }
  }
`;

const Heading = styled.div`
  font-size: 14px;
  font-weight: ${typography.overlineText};
  line-height: 1.23;
  margin-right: ${size.xs1}px;
  text-transform: uppercase;
`;

const FactCardsWrapper = styled.div`
  display: flex;

  ${mediaQueries.forPhoneOnly} {
    height: ${WrapperHeightWithCheckIn}px;
    overflow-x: auto;
    white-space: nowrap;
    /* stylelint-disable */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    /* stylelint-enable */
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export { Header, Heading, FactCardsWrapper, Wrapper };
