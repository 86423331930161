import { typography, colors, mediaQueries } from '@everlywell/leaves';
import wave from 'common/images/wave.svg';
import { pageTitle, sectionTitle } from 'common/styles/app-styles';
import { hexToRGB } from 'common/utils/helpers';
import Button from 'components/Button';
import styled from 'styled-components';

const ErrorPage = styled.div`
  /* prettier-ignore */
  background: ${colors.gray1} url("${wave}") bottom center no-repeat;
  min-height: 730px;
  border-bottom: 16px solid rgba(${hexToRGB(colors.gray2)}, 0.5);
  padding-top: 40px;
  text-align: center;

  ${mediaQueries.forTabletVerticalUp} {
    padding-top: 56px;
  }
`;

const Title = styled.h3`
  ${pageTitle as any};
  font-weight: ${typography.weight.bold};
  margin: 0 0 20px;
  color: ${colors.gray5};
`;

const Message = styled.p`
  ${sectionTitle as any};
  font-weight: ${typography.weight.light};
  letter-spacing: normal;
  margin: 0 auto 30px;
  max-width: 688px;
  color: ${colors.gray4};
  padding: 0 24px;
`;

const UpdatedButton = styled(Button)`
  &:hover,
  &:active {
    text-decoration: none;
    color: ${colors.white};
  }

  &:focus {
    color: inherit;
    &:active {
      text-decoration: none;
      color: inherit;
    }
  }

  a {
    &:focus {
      color: red;
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 193px;
  margin: 0 auto;
`;
export { ErrorPage, Title, Message, UpdatedButton as Button, ButtonWrapper };
