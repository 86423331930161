import MarkerChart from 'components/MarkerChart';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import NewMarkerChart, { Props } from '../NewMarkerChart';

export interface NewMarkerChartWrapperProps extends Props {
  classStyleFF?: string;
}

const NewMarkerChartWrapper = (props: NewMarkerChartWrapperProps) => {
  const { isClassStyle, classStyleFF } = props;

  const [showClassStyle, setShowClassStyle] = useState(isClassStyle);
  const { enableNewMarkerChart, ...others } = useFlags();
  const [searchParams] = useSearchParams();

  const getSearchParamsFlag = (flag: string) => {
    const value = searchParams.get(flag) === 'true';

    return value;
  };

  const evaluateNewChartClassStyle = (
    isClassStyle?: boolean,
    featureFlag?: string,
  ) => {
    let showClassStyle = Boolean(isClassStyle);
    if (featureFlag) {
      const isEnabledByFF = Boolean(isClassStyle && others[featureFlag]);
      const isEnabledBySearchParams = getSearchParamsFlag(featureFlag);

      showClassStyle = isEnabledByFF || isEnabledBySearchParams;
    }

    return showClassStyle;
  };

  const updateNewChartClassStyleStatus = (
    isClassStyle?: boolean,
    featureFlag?: string,
  ) => {
    const isActive = evaluateNewChartClassStyle(isClassStyle, featureFlag);
    setShowClassStyle(isActive);
  };

  useEffect(() => {
    updateNewChartClassStyleStatus(isClassStyle, classStyleFF);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClassStyle, classStyleFF, others]);

  return (
    <>
      {enableNewMarkerChart ? (
        <NewMarkerChart {...props} isClassStyle={showClassStyle} />
      ) : (
        <MarkerChart {...props} />
      )}
    </>
  );
};

export default NewMarkerChartWrapper;
