/**
 *
 * Toggle
 *
 */

import React from 'react';

import * as S from './Toggle.styles';

type Props = {
  isOn: boolean;
  onChange: () => void;
  [prop: string]: any;
};

function Toggle(props: Props) {
  const { isOn, onChange, ...rest } = props;

  return (
    <S.Wrapper
      onClick={onChange}
      aria-checked={isOn ? `true` : 'false'}
      {...rest}
    />
  );
}

export default Toggle;
