import { Button, H4 } from '@everlywell/leaves';
import Grid from 'components/Grid';
import FloatingCtaFooter from 'pages/VirtualCare/NativeScheduling/components/FloatingCtaFooter';
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSet } from 'react-use';

import ProductTile from '../ProductSelector/ProductTile';
import * as S from './ChooseTestKitOrVisitForm.styles';
import { OPTIONS } from './OptionProducts';

export type ChooseTestKitOrVisitFormProps = {
  trackingFunctions: {
    whenViewed: () => void;
    whenBackClicked: () => void;
    whenFormSubmitClicked: (selectedProduct: string) => void;
  };
};

const ChooseTestKitOrVisitForm = ({
  trackingFunctions,
}: ChooseTestKitOrVisitFormProps) => {
  const [selectedId, setSelectedId] = useState<number>();
  const [, { toggle: toggleExpandedIndex, has: isIndexExpanded }] =
    useSet<number>(new Set([]));

  const { handleSubmit, control, setValue, errors } = useForm({
    defaultValues: {
      productId: '',
    },
  });

  const navigate = useNavigate();

  const handleSelect = (productId: number) => {
    setSelectedId(productId);
    setValue('productId', productId);
  };

  const handleOpen = (productId: number) => {
    toggleExpandedIndex(productId);
  };

  const onSubmit = (data: { productId: number }) => {
    const selectedProduct = OPTIONS.find(
      (option) => option.productId === data.productId,
    )!;

    trackingFunctions.whenFormSubmitClicked(selectedProduct.slug);

    if (selectedProduct.slug === 'virtual-care-visit') {
      return navigate('/credit-redemption/products', {
        state: { redeemableProductOption: 'virtual-care-visit' },
      });
    }

    if (selectedProduct.slug === 'test-kit') {
      return navigate('/credit-redemption/address');
    }
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <Grid.Container spacing={['md']}>
        <Grid.Item width={[1]}>
          <H4 as="h1">Which product would you like to redeem?</H4>
        </Grid.Item>
        <Grid.Item width={[1]}>
          {OPTIONS.map((option, index) => (
            <Controller
              key={option.productName}
              control={control}
              name="productId"
              rules={{
                required: 'Please select an option',
              }}
              render={() => (
                <ProductTile
                  index={index}
                  handleSelect={handleSelect}
                  handleOpen={handleOpen}
                  isExpanded={isIndexExpanded(option.productId)}
                  isSelected={option.productId === selectedId}
                  isAvailableToRedeem={true}
                  innerSections={[]}
                  collectionMethods={[]}
                  {...option}
                />
              )}
            />
          ))}
        </Grid.Item>
        {errors?.productId?.message && (
          <Grid.Item width={[1]}>
            <S.ErrorMessage>{errors.productId.message}</S.ErrorMessage>
          </Grid.Item>
        )}
      </Grid.Container>
      <FloatingCtaFooter
        backButton={
          <Button
            type="button"
            appearance="secondary"
            onClick={() => {
              trackingFunctions.whenBackClicked();
              navigate('/dashboard');
            }}
          >
            Back
          </Button>
        }
        nextButton={<Button type="submit">Proceed</Button>}
      />
    </S.Form>
  );
};

export default ChooseTestKitOrVisitForm;
