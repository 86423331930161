import { Button, Calendar, Toast, ToastType } from '@everlywell/leaves';
import { createSelfScheduledTelehealthAppointment } from 'common/apis/telehealthSchedulingApis';
import { BaseRequestError } from 'common/hooks/useApi/request';
import { useCommonT2TAnalytics } from 'common/hooks/useCommonT2TAnalytics';
import useEnterpriseProgramSlug from 'common/hooks/useEnterpriseProgramSlug';
import DoctorOutline from 'common/icons/doctor_24.svg';
import Location from 'common/icons/location_24.svg';
import MedicalPhone from 'common/icons/medical-phone_24.svg';
import QuestionPhone from 'common/icons/question-phone_24.svg';
import Time from 'common/icons/time_24.svg';
import { userPlanId } from 'common/utils/userPlanId';
import Grid from 'components/Grid';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { Navigate, useNavigate } from 'react-router-dom';

import FloatingCtaFooter from '../../components/FloatingCtaFooter';
import { useNativeSchedulingContext } from '../../context/NativeSchedulingContext';
import { actions } from '../../state/scheduling-flow.state';
import * as track from './ReviewBooking.analytics';
import * as S from './ReviewBooking.styles';

/**
 * This page is the last step in the booking process before actually
 * booking the appointment. It shows the user the details of the
 * appointment they are about to book and allows them to confirm
 * or go back to the previous step.
 */

const HelpBody = ({ selectedProgram }: { selectedProgram: string }) => {
  const { enterpriseProgramSlug } = useEnterpriseProgramSlug();

  if (enterpriseProgramSlug === selectedProgram) {
    return (
      <>
        <S.HelpPhone>(888) 813-0218</S.HelpPhone>, daily from{' '}
        <S.NoWrapSpan>8 am - 11 pm EST. </S.NoWrapSpan>
      </>
    );
  }
  return (
    <>
      <S.HelpPhone>(708) 787-4438</S.HelpPhone>, Monday through Friday,{' '}
      <S.NoWrapSpan>7 a.m. to 7 p.m. CST.</S.NoWrapSpan>
    </>
  );
};

function ReviewBooking() {
  const { state, getNextStep, dispatch } = useNativeSchedulingContext();
  const { commonT2TAnalyticsData } = useCommonT2TAnalytics();
  const {
    selectedAppointment,
    selectedProgram,
    confirmedAppointment,
    reasonForVisit,
  } = state;
  const params = useMemo(
    () =>
      isEmpty(commonT2TAnalyticsData)
        ? { program: selectedProgram }
        : commonT2TAnalyticsData,
    [commonT2TAnalyticsData, selectedProgram],
  );

  const navigate = useNavigate();

  const planId = userPlanId.planId;

  const {
    mutate,
    isLoading: isSubmitting,
    isError,
    isIdle,
  } = useMutation(createSelfScheduledTelehealthAppointment, {
    onSuccess: async (data) => {
      const confirmedAppointment = data.data;

      if (confirmedAppointment) {
        userPlanId.removePlanId();

        await track.whenUserClicksConfirm(params);

        dispatch(actions.setConfirmedAppointment(confirmedAppointment));

        navigate(getNextStep() ?? '/dashboard');
      }
    },
    onError: async (error) => {
      const errorMessage = (error as BaseRequestError)?.data?.errors[0].message;
      if (errorMessage) {
        setErrorMessage(errorMessage);
        setErrorIsVisible(true);
      }
    },
  });
  const [errorIsVisible, setErrorIsVisible] = useState(isError ?? true);
  const [errorMessage, setErrorMessage] = useState(
    'Something went wrong. Please try again.',
  );
  const handleCloseToast = () => {
    setErrorIsVisible(!errorIsVisible);
  };

  const { slot: selectedAppointmentSlot, type: selectedAppointmentType } =
    selectedAppointment;

  const shouldRenderPage =
    selectedAppointmentType && selectedAppointmentSlot && selectedProgram;

  useEffect(() => {
    if (shouldRenderPage) {
      track.whenUserViewsPage(params);
    }
  }, [params, shouldRenderPage]);

  if (confirmedAppointment) {
    if (isIdle) {
      // if user goes back to this page, we need to redirect them back to the next page
      return <Navigate to={getNextStep() ?? '/dashboard'} />; // redirect to appointment confirmation page
    } else {
      // we can fall into this case as a race condition between the removal of
      // state props (shouldRenderPage being false) and the redirect in the onSuccess mutation
      return null;
    }
  }

  if (!shouldRenderPage)
    return <Navigate to="/virtual-care/scheduling" replace />; // redirect to beginning of flow

  const date = format(
    new Date(selectedAppointmentSlot.datetime),
    'EEEE, MMMM do, yyyy', // Thursday, June 16th, 2023
  );
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const time = formatInTimeZone(
    new Date(selectedAppointmentSlot.datetime),
    timezone,
    'h:mmbbb (zzz)', // 10:00am (CDT)
  );

  const handleConfirm = () => {
    mutate({
      datetime: selectedAppointmentSlot.datetime,
      provider_id: selectedAppointmentSlot.provider.id,
      program_slug: selectedProgram,
      user_plan_id: planId,
      appointment_type_id: selectedAppointmentType.id,
      reason: reasonForVisit?.reason,
      kit_results: reasonForVisit?.kit_results,
    });
  };

  const providerName = selectedAppointmentSlot.provider.name;
  const providerCredentials = selectedAppointmentSlot.provider.credentials
    ? `, ${selectedAppointmentSlot.provider.credentials.join(', ')}` // outputs: ', MD, PhD'
    : '';
  const providerDisplayName = `${providerName}${providerCredentials}`;

  return (
    <S.Container>
      <Grid.Container spacing={['xl1']}>
        <Grid.Item width={[1]}>
          <S.Heading>Review visit details</S.Heading>
        </Grid.Item>

        <Grid.Item width={[1]}>
          <Grid.Container spacing={['lg']}>
            <Grid.Item width={[1]}>
              <S.DetailText>
                <S.Icon src={MedicalPhone} alt="" />

                {selectedAppointmentType.name}
              </S.DetailText>
            </Grid.Item>

            <Grid.Item width={[1]}>
              <S.DetailText>
                <S.Icon src={DoctorOutline} alt="" />
                {providerDisplayName}
              </S.DetailText>
            </Grid.Item>

            <Grid.Item width={[1]}>
              <S.DetailText>
                <S.Icon as={Calendar} />
                {date}
              </S.DetailText>
            </Grid.Item>

            <Grid.Item width={[1]}>
              <S.DetailText>
                <S.Icon src={Time} alt="" />
                {time}
              </S.DetailText>
            </Grid.Item>

            {selectedAppointmentType.contact_type && (
              <Grid.Item width={[1]}>
                <S.DetailText>
                  <S.Icon src={Location} alt="" />
                  {selectedAppointmentType.contact_type}
                </S.DetailText>
              </Grid.Item>
            )}
          </Grid.Container>
        </Grid.Item>

        <Grid.Item width={[1]}>
          <Grid.Container spacing={['xs2']} css={{ textAlign: 'center' }}>
            <Grid.Item width={[1]}>
              <S.HelpTitle>
                <S.HelpIcon src={QuestionPhone} alt="" />
                <span>Need Help?</span>
              </S.HelpTitle>
            </Grid.Item>

            <Grid.Item width={[1]}>
              <S.HelpBody>
                Contact your healthcare provider team at{' '}
                <HelpBody selectedProgram={selectedProgram} />
              </S.HelpBody>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>

        {errorIsVisible && (
          <Grid.Item width={[1]}>
            <Toast
              type={ToastType.ERROR}
              content={errorMessage}
              handleClose={handleCloseToast}
              persistent
            />
          </Grid.Item>
        )}
      </Grid.Container>
      <FloatingCtaFooter
        backButton={
          <Button
            appearance="secondary"
            onClick={async () => {
              await track.whenUserClicksBack(params);
              navigate(-1);
            }}
            isDisabled={isSubmitting}
          >
            Back
          </Button>
        }
        nextButton={
          <Button onClick={handleConfirm} isLoading={isSubmitting}>
            Confirm and book
          </Button>
        }
      />
    </S.Container>
  );
}

export default ReviewBooking;
