import { useCommonT2TAnalytics } from 'common/hooks/useCommonT2TAnalytics';
import useEnterpriseProgramSlug from 'common/hooks/useEnterpriseProgramSlug';
import { setProgramSlug } from 'common/hooks/useProgramSlug/useProgramSlug';
import t2tSchedulingImage from 'common/images/test-to-treat-scheduling.png';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import React from 'react';

import AppointmentCard from '../AppointmentCard';
import * as S from '../styles';

export const HELP_PHONE_NUMBER = '(888) 813-0218';
export const CONTACT_DAY_TIME_TEXT = '8 AM - 11 PM EST';

const BookAppointmentSection = () => {
  const { enterpriseProgramSlug } = useEnterpriseProgramSlug();
  const { commonT2TAnalyticsData } = useCommonT2TAnalytics();

  const handleCTAClick = () => {
    setProgramSlug(enterpriseProgramSlug || '');
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        ...commonT2TAnalyticsData,
        label: ANALYTICS.LABELS.EHS_T2T.BOOK_AN_APPOINTMENT,
        page: ANALYTICS.PAGES.EHS_T2T.HOMEPAGE,
      },
    });
  };

  const handlePhoneNumberClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_LINK,
      data: {
        ...commonT2TAnalyticsData,
        label: ANALYTICS.LABELS.EHS_T2T.PHONE_NUMBER_LINK,
        page: ANALYTICS.PAGES.EHS_T2T.HOMEPAGE,
      },
    });
  };

  return (
    <S.AppointmentSection>
      <AppointmentCard
        boxImage={{
          src: t2tSchedulingImage,
          alt: 'Person using a smartphone to schedule a appointment',
        }}
        heading="Want to discuss your symptoms or test results with a provider?"
        subHeading="Schedule your video appointment today"
        callToAction={{
          content: 'Book an Appointment',
          to: '/virtual-care/registration',
        }}
        handleClick={handleCTAClick}
      />
      <S.AppointmentContactInfo>
        <p>
          Need help with appointments? Contact us at{' '}
          <a href={`tel:${HELP_PHONE_NUMBER}`} onClick={handlePhoneNumberClick}>
            {HELP_PHONE_NUMBER}
          </a>
          .
        </p>
        <p>We are available daily between {CONTACT_DAY_TIME_TEXT}.</p>
      </S.AppointmentContactInfo>
    </S.AppointmentSection>
  );
};

export default BookAppointmentSection;
