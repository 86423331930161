import { PHYSICIAN_CONTACT_FORM as DATA_TEST } from 'common/utils/constants/dataTest';
import { PhysicianContact } from 'common/utils/types';
import {
  Form,
  FormValuesType,
  VALIDATION_RULES,
  Input,
  PhoneInput,
  ZipCodeInput,
} from 'components/Forms';
import React, { useEffect, useMemo } from 'react';

import * as S from './styles';

type Props = {
  errors?: { [key: string]: string };
  isSaving: boolean;
  physicianContact: PhysicianContact;
  canUpdatePhysicianContact: boolean;
  onSubmit: (formValues: FormValuesType) => void;
  pcpFirstLastNameEnabled: boolean;
};

function giveValidationRules(pcpFirstLastNameEnabled: boolean) {
  const validations = {
    practiceName: [
      {
        rule: VALIDATION_RULES.IS_PRACTICE_NAME_OPTIONAL,
        error: 'Please enter a valid Practice Name',
      },
    ],
    address1: [
      {
        rule: VALIDATION_RULES.IS_REQUIRED,
        error: 'Please enter a valid Address',
      },
    ],
    city: [
      {
        rule: VALIDATION_RULES.IS_REQUIRED,
        error: 'Please enter a valid City',
      },
    ],
    state: [
      {
        rule: VALIDATION_RULES.IS_REQUIRED,
        error: 'Please enter a valid State',
      },
    ],
    zipCode: [
      {
        rule: VALIDATION_RULES.IS_ZIP_CODE,
        error: 'Please enter a valid Zip Code',
      },
    ],
    physicianPhone: [
      {
        rule: VALIDATION_RULES.IS_PHONE_NUMBER_OPTIONAL,
        error: 'Please enter a valid Phone Number',
      },
    ],
  };

  if (pcpFirstLastNameEnabled) {
    return {
      firstName: [
        {
          rule: VALIDATION_RULES.IS_REQUIRED,
          error: 'Please enter a valid First Name',
        },
        {
          rule: VALIDATION_RULES.IS_NAME,
          error: 'Please enter a valid First Name',
        },
      ],
      lastName: [
        {
          rule: VALIDATION_RULES.IS_REQUIRED,
          error: 'Please enter a valid Last Name',
        },
        {
          rule: VALIDATION_RULES.IS_NAME,
          error: 'Please enter a valid Last Name',
        },
      ],
      ...validations,
    };
  } else {
    validations['practiceName'].push({
      rule: VALIDATION_RULES.IS_REQUIRED,
      error: 'Please enter a valid Practice Name',
    });
    return validations;
  }
}

const isUpdateDisabled = (
  values: FormValuesType,
  physicianContact: PhysicianContact,
  isSaving: boolean,
) => {
  if (isSaving) {
    return true;
  }

  const oldValues = physicianContact as FormValuesType;
  const isDisabled = Object.keys(values).reduce((acc, next) => {
    let disabled = acc;
    if (values[next] || oldValues[next]) {
      const oldStr = String(oldValues[next]);
      const newStr = String(values[next]);
      if (newStr.trim() !== oldStr.trim()) {
        disabled = false;
      }
    }
    return disabled;
  }, true);

  return isDisabled;
};

const fillFields = (components: any, handleFieldChange: any) => {
  const addressInput: any = document.getElementById('address1');
  if (components !== undefined) {
    let address: any = {};
    components.forEach((field: any) => {
      field.types.forEach((type: any) => {
        address = Object.assign(address, {
          [type]: {
            long_name: field.long_name,
            short_name: field.short_name,
          },
        });
      });
    });

    let streetNum = '';
    let streetName = '';

    if (!address.street_number) {
      [streetNum] = addressInput.value.split(' ') || [];
    } else {
      streetNum = address.street_number.long_name;
    }

    if (address.route) {
      streetName = address.route.long_name;
    }

    const addr1 = `${streetNum} ${streetName}`;
    const city = address.locality ? address.locality.long_name : '';
    const state = address.administrative_area_level_1
      ? address.administrative_area_level_1.short_name
      : '';
    const zipCode = address.postal_code ? address.postal_code.long_name : '';

    handleFieldChange({ name: 'address1', value: addr1 });
    handleFieldChange({ name: 'city', value: city });
    handleFieldChange({ name: 'state', value: state });
    handleFieldChange({ name: 'zipCode', value: zipCode });
  }
};

function PhysicianContactForm(props: Props) {
  const {
    physicianContact,
    onSubmit,
    isSaving,
    canUpdatePhysicianContact,
    errors: initialErrors,
    pcpFirstLastNameEnabled,
  } = props;
  let autoComplete: any = null;

  if (!canUpdatePhysicianContact) {
    return <></>;
  }

  useEffect(() => {
    const addressInput: any = document.getElementById('address1');

    if (window.google && addressInput) {
      autoComplete = new window.google.maps.places.Autocomplete(addressInput);

      // prevents form submission
      window.google.maps.event.addDomListener(
        addressInput,
        'keydown',
        (event: Event) => {
          const keyEvent = event as KeyboardEvent;
          if (keyEvent.key === 'Enter') {
            keyEvent.preventDefault();
          }
        },
      );
    }
  }, []);

  return (
    <>
      <S.Title>Doctor&apos;s Contact Information</S.Title>
      <S.Box>
        <S.SubTitle>
          Please note that all of your lab results from the previous year onward
          will be sent to your updated doctor.
        </S.SubTitle>
        <Form
          onSubmit={onSubmit}
          validations={giveValidationRules(pcpFirstLastNameEnabled)}
          initialValues={physicianContact}
          initialErrors={initialErrors}
        >
          {({ values, errors, onFieldChange, onFieldValidate }) => {
            useMemo(() => {
              if (autoComplete) {
                google.maps.event.addListener(
                  autoComplete,
                  'place_changed',
                  () => {
                    new Promise((resolve) => {
                      const places = autoComplete.getPlace();
                      resolve(places);
                    }).then((fulfilled: any) => {
                      const components = fulfilled.address_components;
                      fillFields(components, onFieldChange);
                    });
                  },
                );
              }
            }, [autoComplete]);

            return (
              <S.InnerForm>
                {pcpFirstLastNameEnabled ? (
                  <>
                    <S.Field>
                      <Input
                        name="firstName"
                        onChange={onFieldChange}
                        placeholder="Fill in your doctor's first name"
                        onBlur={errors.firstName ? onFieldValidate : undefined}
                        onValidate={onFieldValidate}
                        label="First Name"
                        value={values.firstName}
                        error={errors.firstName}
                        data-test={DATA_TEST.FIRST_NAME_FIELD}
                      />
                    </S.Field>
                    <S.Field>
                      <Input
                        name="lastName"
                        onChange={onFieldChange}
                        placeholder="Fill in your doctor's last name"
                        onBlur={errors.lastName ? onFieldValidate : undefined}
                        onValidate={onFieldValidate}
                        label="Last Name"
                        value={values.lastName}
                        error={errors.lastName}
                        data-test={DATA_TEST.LAST_NAME_FIELD}
                      />
                    </S.Field>
                  </>
                ) : null}
                <S.FullWidthField>
                  <Input
                    name="practiceName"
                    onChange={onFieldChange}
                    placeholder={`Fill in your practice name${
                      pcpFirstLastNameEnabled ? ' (Optional)' : ''
                    }`}
                    onBlur={errors.practiceName ? onFieldValidate : undefined}
                    onValidate={onFieldValidate}
                    label={`Practice Name${
                      pcpFirstLastNameEnabled ? ' (Optional)' : ''
                    }`}
                    value={values.practiceName}
                    error={errors.practiceName}
                    data-test={DATA_TEST.PRACTICE_NAME_FIELD}
                  />
                </S.FullWidthField>
                <S.Field>
                  <S.AutoCompleteInput
                    name="address1"
                    onChange={onFieldChange}
                    placeholder="Fill in an address"
                    onBlur={errors.address1 ? onFieldValidate : undefined}
                    onValidate={onFieldValidate}
                    label={''}
                    value={values.address1}
                    error={errors.address1}
                    data-test={DATA_TEST.ADDRESS1_FIELD}
                  />
                </S.Field>
                <S.Field>
                  <Input
                    name="address2"
                    onChange={onFieldChange}
                    placeholder=""
                    onValidate={onFieldValidate}
                    label="Apt / Suite/ Unit (Optional)"
                    value={values.address2}
                    error={errors.address2}
                    data-test={DATA_TEST.ADDRESS2_FIELD}
                  />
                </S.Field>
                <S.Field>
                  <Input
                    name="city"
                    onChange={onFieldChange}
                    placeholder="Austin"
                    onValidate={onFieldValidate}
                    label="City"
                    value={values.city}
                    error={errors.city}
                    data-test={DATA_TEST.CITY_FIELD}
                  />
                </S.Field>
                <S.SmallWidthFieldWrapper>
                  <S.SmallWidthField>
                    <Input
                      name="state"
                      onChange={onFieldChange}
                      placeholder="TX"
                      onValidate={onFieldValidate}
                      label="State"
                      value={values.state}
                      error={errors.state}
                      data-test={DATA_TEST.STATE_FIELD}
                    />
                  </S.SmallWidthField>
                  <S.SmallWidthField>
                    <ZipCodeInput
                      name="zipCode"
                      onChange={onFieldChange}
                      placeholder="78756"
                      onValidate={onFieldValidate}
                      label="Zip Code"
                      value={values.zipCode}
                      error={errors.zipCode}
                      data-test={DATA_TEST.ZIP_CODE_FIELD}
                      maxLength={10}
                    />
                  </S.SmallWidthField>
                </S.SmallWidthFieldWrapper>
                <S.Field>
                  <PhoneInput
                    name="physicianPhone"
                    onChange={onFieldChange}
                    placeholder="(000) 000-0000"
                    onValidate={onFieldValidate}
                    label="Phone Number (Optional)"
                    value={values.physicianPhone}
                    error={errors.physicianPhone}
                    data-test={DATA_TEST.PHONE_FIELD}
                  />
                </S.Field>
                <S.ButtonRow>
                  <S.Button
                    type="submit"
                    disabled={isUpdateDisabled(
                      values,
                      physicianContact,
                      isSaving,
                    )}
                    data-test={DATA_TEST.SUBMIT_BUTTON}
                  >
                    {isSaving ? <S.Spinner /> : "Update Doctor's Info"}
                  </S.Button>
                </S.ButtonRow>
              </S.InnerForm>
            );
          }}
        </Form>
      </S.Box>
    </>
  );
}
export default PhysicianContactForm;
