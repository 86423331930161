import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function setProgramSlug(slug: string) {
  if (!slug) return;

  window.localStorage.setItem('programSlug', slug);
}

const useProgramSlug = (initialSlug?: string | null) => {
  const programSlug = initialSlug ?? window.localStorage.getItem('programSlug');
  const navigate = useNavigate();

  useEffect(() => {
    if (!initialSlug) return;

    setProgramSlug(initialSlug);
  }, [initialSlug]);

  // Redirect to results dashboard if there is no program slug saved on local storage
  useEffect(() => {
    if (!programSlug) {
      navigate('/dashboard');
    }
  }, [programSlug, navigate]);

  return { setProgramSlug, programSlug };
};

export default useProgramSlug;
