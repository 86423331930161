import {
  Button,
  colors,
  size,
  mediaQueries,
  LeftCaret,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

const BackButton = styled.div`
  color: ${colors.green5};
  width: ${size.xl2}px;
  height: ${size.lg}px;
  font-size: ${size.md}px;
  font-weight: bold;
  line-height: ${size.lg}px;
  letter-spacing: 0.27px;
  margin-bottom: ${size.md}px;
  display: inline;

  ${mediaQueries.forPhoneOnly} {
    display: flex;
    width: auto;
    align-items: center;
    margin-bottom: ${size.sm}px;
  }
`;

const LeftCaretIcon = styled(LeftCaret)`
  width: ${size.xs1}px;
  height: ${size.sm}px;
  margin: 0 ${size.xs1}px -1px 0;
  object-fit: contain;
`;
const NextButton = styled(Button)`
  background-color: ${colors.green5};
  color: ${colors.white};
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  height: ${size.xl3}px;
  margin: ${size.xl1}px 0 ${size.sm}px;
  padding: ${size.sm}px 142px ${size.sm}px 142px;
  border-radius: 1px;
  box-shadow: 0 ${size.xs3}px ${size.md}px -${size.xs2}px rgba(0, 0, 0, 0.15);
  line-height: ${size.lg}px;
  letter-spacing: 0.3px;
  text-align: center;

  ${mediaQueries.forPhoneOnly} {
    margin: ${size.xl1}px 0 0;
    padding: ${size.sm}px 87px ${size.sm}px 87px;
  }
`;

const CongregateSettingDescription = styled.p`
  ${typography.bodyTextSmall};
  color: ${colors.gray4};
  margin: -${size.md}px 0 ${size.xl1}px;
`;

const HighRiskConditions = styled.ul`
  ${typography.bodyTextSmall};
  margin-top: -${size.md}px;
  padding-left: ${size.md}px;
`;

const HighRiskList = styled.li`
  list-style-type: disc;
  width: 516px;
  min-height: 28px;
  margin: 0 0 0 6px;
  ${mediaQueries.forPhoneOnly} {
    max-width: 100%;
    min-height: 28px;
    max-height: 100%;
    margin: 0 0 0 10px;
  }
`;

export {
  BackButton,
  LeftCaretIcon,
  NextButton,
  CongregateSettingDescription,
  HighRiskConditions,
  HighRiskList,
};
