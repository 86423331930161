import { useCommonT2TAnalytics } from 'common/hooks/useCommonT2TAnalytics';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import Grid from 'components/Grid/Grid';
import Layout from 'components/Layout/Layout';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import StethoscopeIcon from './components/Icons/Icons';
import * as S from './ConfirmCancelAppointmentPage.styles';

function ConfirmCancelAppointmentPage() {
  const { commonT2TAnalyticsData } = useCommonT2TAnalytics();

  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        ...commonT2TAnalyticsData,
        page: ANALYTICS.PAGES.EHS_T2T.CANCEL_APPOINTMENT_CONFIRMATION_PAGE,
      },
    });
  }, [commonT2TAnalyticsData]);

  const handleReturnClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        ...commonT2TAnalyticsData,
        label: ANALYTICS.LABELS.EHS_T2T.RETURN_TO_DASHBOARD,
        page: ANALYTICS.PAGES.EHS_T2T.CANCEL_APPOINTMENT_CONFIRMATION_PAGE,
      },
    });
  };

  const handleScheduleClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        ...commonT2TAnalyticsData,
        label: ANALYTICS.LABELS.EHS_T2T.SCHEDULE_APPOINTMENT,
        page: ANALYTICS.PAGES.EHS_T2T.CANCEL_APPOINTMENT_CONFIRMATION_PAGE,
      },
    });
  };

  return (
    <Layout>
      <S.Container>
        <Grid.Container spacing={['md', 'lg']} alignEdges={false}>
          <Grid.Item
            width={[1]}
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <S.Title>Please review the following items.</S.Title>
          </Grid.Item>
          <Grid.Item width={[1]}>
            <S.IconWrapper>
              <StethoscopeIcon />
            </S.IconWrapper>
          </Grid.Item>
          <Grid.Item width={[1]}>
            <S.Subtitle>Your appointment has been cancelled</S.Subtitle>
          </Grid.Item>
          <Grid.Item width={[1]}>
            <S.Description>
              We're sorry you had to cancel your session. You can reschedule any
              time or contact your healthcare provider team at{' '}
              <a href="tel:855-997-0119">(855) 997-0119</a> daily from 8 AM - 11
              PM EST.
            </S.Description>
          </Grid.Item>

          <Grid.Item width={[1]}>
            <S.ButtonsContainer spacing={['sm']}>
              <Grid.Item width={[1, 0.52]}>
                <S.StyledButton
                  onClick={handleScheduleClick}
                  component={Link}
                  to={`/virtual-care/registration`}
                >
                  Schedule new appointment
                </S.StyledButton>
              </Grid.Item>
              <Grid.Item width={[1, 0.45]}>
                <S.StyledButton
                  appearance="secondary"
                  onClick={handleReturnClick}
                  component={Link}
                  to={`/dashboard`}
                >
                  Return to Dashboard
                </S.StyledButton>
              </Grid.Item>
            </S.ButtonsContainer>
          </Grid.Item>
        </Grid.Container>
      </S.Container>
    </Layout>
  );
}

export default ConfirmCancelAppointmentPage;
