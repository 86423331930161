import { SkeletonLoader, SkeletonUnit } from '@everlywell/leaves';
import React from 'react';

export default function FormSummarySkeleton() {
  return (
    <SkeletonLoader
      height={{ value: 150, unit: SkeletonUnit.Pixel }}
      width={{ value: 100, unit: SkeletonUnit.Percentage }}
    ></SkeletonLoader>
  );
}
