import { Col, Row } from '@everlywell/leaves';
import { useHealthProfileContext } from 'common/providers/HealthProfileProvider';
import { MY_APP_ROOT } from 'common/utils/constants';
import { HEALTH_PROFILE_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';
import React, { useEffect } from 'react';

import * as S from '../styles';
import Everlyloader from './images/everlyloader.gif';

const DefaultConfirmation = () => {
  const { redirection } = useHealthProfileContext();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (window) {
        window.location.href = redirection || MY_APP_ROOT;
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, []); //eslint-disable-line

  return (
    <Row data-test={`${DATA_TEST.CONTAINER}-no-kit-registration`}>
      <Col>
        <S.Image src={Everlyloader} alt="" />

        <S.Header>You've completed your health&nbsp;profile! </S.Header>
        <S.SubText>Redirecting to your dashboard</S.SubText>
      </Col>
      <Col>
        <S.RedirectText data-test={`${DATA_TEST.BUTTON}-dashboard`}>
          Taking too long?{' '}
          <S.LinkTo href={redirection || MY_APP_ROOT}> Click here </S.LinkTo> to
          be redirected to your dashboard.
        </S.RedirectText>
      </Col>
    </Row>
  );
};

export default DefaultConfirmation;
