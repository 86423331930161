import Grid from 'components/Grid/Grid';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Input, labelStyles } from '../../utils/styles';
import { FormBuilderField } from '../../utils/types';

export type DateFieldProps = FormBuilderField;

function DateField(props: DateFieldProps) {
  const { register, errors } = useFormContext();
  return (
    <Grid.Item width={[1]} key={props.id}>
      <Input
        css={labelStyles(props.variant)}
        error={errors[props.id]?.message}
        id={props.id}
        label={props.label ?? ''}
        name={props.id}
        placeholder="mm/dd/yyyy"
        readOnly={props.readOnly}
        type="date"
        ref={register({
          required: props.required && 'Please enter a date',
        })}
        required={props.required}
        role="textbox"
      />
    </Grid.Item>
  );
}

export default DateField;
