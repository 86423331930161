import {
  buildTelehealthLabelModule,
  buildTelehealthCheckboxField,
  buildTelehealthTextField,
  buildTelehealthDateField,
} from 'common/testUtils/builders/telehealthForm';
import { User } from 'common/utils/types';

import {
  TELEHEALTH_CONSENT,
  BILLING_AND_ASSIGNMENT,
  RESULTS_SHARING_CONSENT,
  PRIVACY_NOTICE,
} from './constants';

const CONSENT_FORM = 'ConsentForm';

const consents = [
  TELEHEALTH_CONSENT,
  //TODO: update after https://everlyhealth.atlassian.net/browse/VCP-157
  BILLING_AND_ASSIGNMENT,
  PRIVACY_NOTICE,
  RESULTS_SHARING_CONSENT,
];

const buildSection = (user: User) => [
  buildTelehealthLabelModule({
    id: `${CONSENT_FORM}-0`,
    label: '[header]Please review the following items.',
  }),
  buildTelehealthCheckboxField({
    id: `consents`,
    label: 'Please review and accept the following',
    options_array: consents,
    /*
      Setting 'required' to 'false' as React Hook Form's presence validation overrides other validations.
      By default, it shows the error message, "Checkbox is required. Please select at least one." which isn't suitable for this case.
      Instead, the 'userAgreeAllConsents' validation method accurately validates presence and will handle this validation.
    */
    required: false,
    validate: {
      userAgreeAllConsents: (values: string) => {
        const valuesSize = values.split('\n').length;
        if (valuesSize !== consents.length) {
          return 'Please review and accept all the consents.';
        }
        return true;
      },
    },
  }),
  buildTelehealthTextField({
    id: `signature`,
    label: 'Your full name',
    required: true,
  }),
  buildTelehealthDateField({
    id: `${CONSENT_FORM}-3`,
    label: 'Date',
    readOnly: true,
  }),
];

export function customModulesBuilder(user: User) {
  return buildSection(user);
}
