import {
  Button as LeavesButton,
  H3,
  Modal as LeavesModal,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import BaseGrid from 'components/Grid';
import styled from 'styled-components';

export const Modal = styled(LeavesModal)`
  z-index: 15;
  align-items: center;

  ${mediaQueries.forTabletHorizontalUp} {
    padding-top: ${size.xl4}px;
    height: 100%;
  }
`;

export const Title = styled(H3)`
  margin: 0;
`;

export const Copy = styled.p`
  ${typography.bodyTextSmall}
`;

export const Consent = styled.p`
  ${typography.captionText}
  opacity: 0.6;

  ${mediaQueries.forTabletVerticalUp} {
    margin-bottom: ${size.xl3}px;
  }
`;

export const Button = styled(LeavesButton)`
  width: 100%;
`;

export const centerModalStyles = {
  textAlign: 'center',
};

const BaseContainer = BaseGrid.Container;
export const Container = styled(BaseContainer)`
  max-width: 500px;
  padding: ${size.xl2}px;
`;

export const ModalInnerContainer = styled.div`
  height: 100%;
`;

export const Grid = {
  Container: styled(BaseGrid.Container)`
    margin: 0 auto;
    max-width: 500px;
  `,
};
