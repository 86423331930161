import {
  buildTelehealthLabelModule,
  buildTelehealthReadOnlyModule,
  buildTelehealthDropdownModule,
  buildTelehealthTextField,
  buildTelehealthDateField,
  buildTelehealthLocationModule,
  buildTelehealthDocumentModule,
  buildTelehealthHorizontalRadioField,
} from 'common/testUtils/builders/telehealthForm';
import { nameValuePairsToDropdownOptionLowerCase } from 'common/utils/helpers';

const buildInsuranceCardDocumentSection = [
  buildTelehealthReadOnlyModule({
    id: 'insurance_card_front_label',
    options_array: ['<p><strong>Insurance Card Front</strong></p>'],
  }),
  buildTelehealthDocumentModule({
    id: `insurance_card_front`,
    label: `[upload] Supported upload format: JPEG and PNG`,
  }),
  buildTelehealthReadOnlyModule({
    id: 'insurance_card_back_label',
    options_array: ['<p><strong>Insurance Card Back</strong></p>'],
  }),
  buildTelehealthDocumentModule({
    id: `insurance_card_back`,
    label: `[upload] Supported upload format: JPEG and PNG`,
  }),
];

const buildFormHeading = [
  buildTelehealthLabelModule({
    id: `insurance-form-heading`,
    label: '[header] Your Insurance',
  }),
  buildTelehealthReadOnlyModule({
    id: `insurance-form-description`,
    options_array: [
      '<p>Please update your insurance information. This should match exactly what is listed on your insurance card. This information may be needed for the prescriptions or orders your provider submits during the encounter. It may also be used to bill your health plan for the encounter.</p>',
    ],
    custom_module_condition: null,
  }),
];

const buildPolicyHolderInformationSection = [
  buildTelehealthLabelModule({
    id: `insurance-form-policy-holder-heading`,
    label: '[header] Policyholder information',
  }),
  buildTelehealthDropdownModule({
    id: `relationship_to_insured`,
    label:
      'Patient’s relationship to policyholder (This is the patient’s relationship to the primary person on the insurance policy)',
    options_array: [
      nameValuePairsToDropdownOptionLowerCase('Self'),
      nameValuePairsToDropdownOptionLowerCase('Spouse'),
      nameValuePairsToDropdownOptionLowerCase('Child'),
    ],
    required: true,
  }),
  buildTelehealthTextField({
    id: 'member_id',
    label: 'Member ID',
  }),
  buildTelehealthTextField({
    id: 'group_number',
    label: 'Group ID',
  }),
  buildTelehealthTextField({
    id: 'policy_holder_first_name',
    label: 'Policyholder First name',
  }),
  buildTelehealthTextField({
    id: `policy_holder_middle_name`,
    label: 'Policyholder Middle name (Optional)',
    required: false,
  }),
  buildTelehealthTextField({
    id: 'policy_holder_last_name',
    label: 'Policyholder Last name',
  }),
  buildTelehealthDropdownModule({
    id: 'policy_holder_sex',
    label:
      'Legal sex (This should match the legal sex listed on your government-issued ID)',
    sublabel: 'Please select your legal sex',
    options_array: [
      nameValuePairsToDropdownOptionLowerCase('Male'),
      nameValuePairsToDropdownOptionLowerCase('Female'),
    ],
    required: true,
  }),
  buildTelehealthDateField({
    id: 'policy_holder_dob',
    label: 'Date of birth',
  }),
];

const buildHasSecondaryInsuranceSection = [
  buildTelehealthHorizontalRadioField({
    id: 'has_secondary_insurance',
    label:
      'Do you have other insurance (such as supplemental or secondary)? We wont ask you to enter this now.',
    options_array: ['Yes', 'No'],
    required: true,
  }),
];

export const ADDRESS_SECTION_ID = 'policy_holder_address';
const buildPolicyHolderAddressSection = [
  buildTelehealthLabelModule({
    id: `insurance-form-policy-holder-address-heading`,
    label: '[header] Policyholder address',
  }),
  buildTelehealthLocationModule({
    id: ADDRESS_SECTION_ID,
    required: true,
  }),
];

export const customModules = [
  ...buildFormHeading,
  ...buildInsuranceCardDocumentSection,
  ...buildPolicyHolderInformationSection,
  ...buildPolicyHolderAddressSection,
  ...buildHasSecondaryInsuranceSection,
];
