import { colors, TertiaryLink } from '@everlywell/leaves';
import React from 'react';
import { ERROR_NOTIFICATION } from 'store/actions';
import styled from 'styled-components';

import { MY_APP_ROOT, SettingsAnchorIds } from './constants';
import { ANALYTICS } from './constants/analytics';

const Link = styled(TertiaryLink)`
  color: ${colors.black};
`;

export const NOTIFICATION_CONTENT = {
  PAYMENT_FAILURE: {
    message: 'Payment Failed',
    detail: 'Update Payment Info',
  },
};

export const notifyPaymentFailure = (setNotification: any) => {
  setNotification({
    message: NOTIFICATION_CONTENT.PAYMENT_FAILURE.message,
    type: ERROR_NOTIFICATION,
    persist: false,
    details: (
      <Link
        href={`${MY_APP_ROOT}/account/settings#${SettingsAnchorIds.payment}`}
      >
        {NOTIFICATION_CONTENT.PAYMENT_FAILURE.detail}
      </Link>
    ),
    source: ANALYTICS.LABELS.PAYMENT_INFORMATION,
    mixpanelLabel: 'Payment Failure',
  });
};
