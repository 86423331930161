export default {
  first_name: {
    displayName: 'First name',
    key: 'firstName',
  },
  last_name: {
    displayName: 'Last name',
    key: 'lastName',
  },
  email: {
    displayName: 'Email',
    key: 'email',
  },
  'consumer.phone_number': {
    displayName: 'Phone number',
    key: 'phone',
  },
  'consumer.dob': {
    displayName: 'Date of birth',
    key: 'dob',
  },
  'consumer.gender': {
    displayName: 'Sex Assigned At Birth',
    key: 'biologicalSex',
  },
};
