import { InformationV2 } from '@everlywell/leaves';
import { TelehealthAppointment } from 'common/apis/telehealthApis';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { HEALTHIE_WHITE_LABEL_SSO_LINK } from 'common/utils/constants/urls';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import StethoscopeIcon from '../../../AccountHub/assets/icon-stethoscope.svg';
import AppointmentDate from '../AppointmentDate/AppointmentDate';
import * as S from './ManageAppointmentModal.styles';

export interface ManageAppointmentModalProps {
  openModal: boolean;
  setOpenModal: (bool: boolean) => void;
  program: string;
  appointment: TelehealthAppointment;
}

function ManageAppointmentModal({
  openModal,
  setOpenModal,
  program,
  appointment,
}: ManageAppointmentModalProps) {
  const { id, start_time, provider } = appointment;
  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: 'appointment-management-modal',
        program,
      },
    });
  }, [program]);

  const redirectToHealthie = () => {
    window.open(HEALTHIE_WHITE_LABEL_SSO_LINK, '_self');
  };

  const handleRescheduleButtonClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'reschedule-appointment',
        program,
      },
    });
    redirectToHealthie();
  };

  const handleCancelButtonClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'cancel-appointment',
        program,
      },
    });
  };

  return (
    <S.ManageAppointmentModal open={openModal} openHandler={setOpenModal}>
      <S.ContentContainer>
        <S.ImageContainer src={StethoscopeIcon} alt="" />

        <S.Header>
          Your appointment on{' '}
          <AppointmentDate appointmentDate={start_time} showTimezone />
        </S.Header>
        <S.Copy>
          You’ll be meeting with{' '}
          {`${provider.first_name} ${provider.last_name}`} on Zoom. Link will be
          available within 30 mins of appointment start.
        </S.Copy>
        <S.Disclaimer>
          <S.IconWrapper>
            <InformationV2 />
          </S.IconWrapper>
          There is a $25 cancellation fee for missed or canceled appointments
          without 24hr notice—not applicable to on-demand appointments.
        </S.Disclaimer>
        <S.ContactSupportText>
          For questions, contact Everlywell partner OpenLoop at{' '}
          <span>+1 (708) 787-4438</span> Monday-Friday 7 a.m.-7 p.m. CST.
        </S.ContactSupportText>
        <S.ButtonsWrapper>
          <S.Button
            appearance="secondary"
            onClick={handleCancelButtonClick}
            to={`/virtual-care/appointments/${id}/cancel?program=${program}`}
            component={Link}
          >
            Cancel appointment
          </S.Button>

          <S.Button appearance="primary" onClick={handleRescheduleButtonClick}>
            Reschedule appointment
          </S.Button>
        </S.ButtonsWrapper>
      </S.ContentContainer>
    </S.ManageAppointmentModal>
  );
}

export default ManageAppointmentModal;
