import {
  Button,
  Container,
  size,
  H3,
  typography,
  colors,
  Row,
  Col,
  mediaQueries,
} from '@everlywell/leaves';
import styled from 'styled-components';

const Wrapper = styled(Container)`
  ${mediaQueries.forDesktopUp} {
    margin-left: 60px;
  }
`;

const PCPContent = styled(Row)`
  align-items: center;
  flex-direction: column;
  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: row;
  }
`;

const ImageColumn = styled(Col)`
  ${mediaQueries.forTabletHorizontalDown} {
    text-align: center;
  }
  ${mediaQueries.forTabletHorizontalUp} {
    order: 2;
  }
`;

const PCPImage = styled.img`
  border-radius: 50%;
  max-width: 100%;
  margin-bottom: ${size.lg}px;
  ${mediaQueries.forTabletVerticalUp} {
    margin-bottom: 0;
    margin-left: ${size.xl1}px;
    width: unset;
  }
`;

const Header = styled(H3)`
  margin-top: 0;
  text-align: center;
  max-width: 632px;
  font-weight: 400;
  ${mediaQueries.forTabletVerticalUp} {
    text-align: left;
  }
`;

const SubHeader = styled.p`
  ${typography.subtitle};
  line-height: ${size.lg}px;
  text-align: center;
  font-weight: 300;
  ${mediaQueries.forTabletVerticalUp} {
    text-align: left;
  }
`;

const PCPCtaWrapper = styled(Row)`
  margin-left: 0;
`;

const PCPCta = styled(Button)`
  margin-top: ${size.xl1}px;
  width: 100%;
  ${mediaQueries.forTabletVerticalUp} {
    width: unset;
  }
`;

const QuestionList = styled.div`
  ol {
    padding-left: 32px;

    /* Tell the <li> to not display numbers, but keep track of what the numbers should be */
    li {
      color: ${colors.gray4};
      counter-increment: list;
      font-weight: ${typography.weight.light};
      list-style-type: none;
      padding-top: 8px;
      position: relative;
      size: ${size.md}px;

      /***
      * Output the numbers using the counter() function,
      * but use a custom color, and position the numbers how we want
      */
      &:before {
        color: ${colors.green5};
        content: counter(list);
        left: -28px;
        position: absolute;
        text-align: right;
      }
    }
  }
`;

export {
  Wrapper,
  Header,
  ImageColumn,
  PCPImage,
  SubHeader,
  QuestionList,
  Row,
  Col,
  PCPCta,
  PCPContent,
  PCPCtaWrapper,
};
