import { useInteractionTracker } from 'common/hooks/useInteractionTracker';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { SETTINGS_FORM as DATA_TEST } from 'common/utils/constants/dataTest';
import { AccountSettings } from 'common/utils/types';
import {
  Checkbox,
  DateOfBirthSelectGroup,
  Form,
  FormFieldType,
  FormValuesType,
  Input,
  PhoneInput,
  RadioGroup,
  VALIDATION_RULES,
} from 'components/Forms';
import React, { useEffect } from 'react';

import S, { ButtonSpinner, ButtonText } from './styles';

type Props = {
  errors?: { [key: string]: string };
  isSaving: boolean;
  accountSettings: AccountSettings;
  onSubmit: (formValues: FormValuesType) => void;
  hasConsumerAttributes: boolean;
};

function giveValidationRules(hasConsumerAttributes: boolean) {
  const validations = {
    firstName: [
      {
        rule: VALIDATION_RULES.IS_REQUIRED,
        error: 'Please enter your first name',
      },
      {
        rule: VALIDATION_RULES.IS_NAME,
        error: 'Please enter a valid first name',
      },
    ],
    lastName: [
      {
        rule: VALIDATION_RULES.IS_REQUIRED,
        error: 'Please enter your last name',
      },
      {
        rule: VALIDATION_RULES.IS_NAME,
        error: 'Please enter a valid last name',
      },
    ],
    email: [
      {
        rule: VALIDATION_RULES.IS_EMAIL,
        error: 'Please enter a valid email',
      },
    ],
    phone: [
      {
        rule: VALIDATION_RULES.IS_PHONE_NUMBER,
        error: 'Please complete your phone number',
      },
    ],
  };

  if (hasConsumerAttributes) {
    return {
      ...validations,
      dob: [
        {
          rule: VALIDATION_RULES.IS_DATE,
          error: 'Please complete your date of birth',
        },
      ],
      biologicalSex: [
        {
          rule: VALIDATION_RULES.IS_REQUIRED,
          error: 'Please select your sex assigned at birth',
        },
      ],
    };
  }

  return validations;
}

function SettingsForm(props: Props) {
  const {
    accountSettings,
    onSubmit,
    isSaving,
    hasConsumerAttributes,
    errors: initialErrors,
  } = props;
  const trackFormInteraction = useInteractionTracker();
  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: ANALYTICS.LABELS.BASIC_INFORMATION,
      },
    });
  }, []);

  const handleFormSubmit = (formValues: FormValuesType) => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'Save Information',
        category: ANALYTICS.CATEGORIES.ACCOUNT_SETTINGS,
        component: ANALYTICS.LABELS.BASIC_INFORMATION,
      },
    });
    onSubmit(formValues);
  };

  const handleFocus = async () => {
    await trackFormInteraction({
      event: ANALYTICS.EVENTS.FORM_INTERACTION,
      data: {
        component: ANALYTICS.LABELS.BASIC_INFORMATION,
      },
    });
  };

  return (
    <>
      <S.Title>Basic Information</S.Title>
      <S.Box>
        <S.SubTitle>Contact Information</S.SubTitle>
        <Form
          onSubmit={handleFormSubmit}
          validations={giveValidationRules(hasConsumerAttributes)}
          initialValues={accountSettings}
          initialErrors={initialErrors}
        >
          {({ values, errors, onFieldChange, onFieldValidate }) => (
            <S.InnerForm>
              <S.Field>
                <Input
                  name="firstName"
                  onChange={onFieldChange}
                  placeholder="Fill in your first name"
                  onBlur={errors.firstName ? onFieldValidate : undefined}
                  onValidate={onFieldValidate}
                  label="First Name"
                  value={values.firstName}
                  error={errors.firstName}
                  data-test={DATA_TEST.FIRST_NAME_FIELD}
                  onFocus={handleFocus}
                />
              </S.Field>
              <S.Field>
                <Input
                  name="lastName"
                  onChange={onFieldChange}
                  placeholder="Fill in your last name"
                  onBlur={errors.lastName ? onFieldValidate : undefined}
                  onValidate={onFieldValidate}
                  label="Last Name"
                  value={values.lastName}
                  error={errors.lastName}
                  data-test={DATA_TEST.LAST_NAME_FIELD}
                  onFocus={handleFocus}
                />
              </S.Field>
              <S.Field>
                <Input
                  name="email"
                  onChange={onFieldChange}
                  placeholder="john@email.com"
                  onValidate={onFieldValidate}
                  label="Email"
                  readOnly={!values.canUpdateEmail}
                  value={values.email}
                  error={errors.email}
                  data-test={DATA_TEST.EMAIL_FIELD}
                  onFocus={handleFocus}
                />
              </S.Field>
              <S.Field>
                <PhoneInput
                  name="phone"
                  onChange={onFieldChange}
                  placeholder="(000) 000-0000"
                  onValidate={onFieldValidate}
                  onBlur={errors.phone ? onFieldValidate : undefined}
                  label="Phone Number"
                  value={values.phone}
                  error={errors.phone}
                  data-test={DATA_TEST.PHONE_FIELD}
                  onFocus={handleFocus}
                />
              </S.Field>
              {hasConsumerAttributes && (
                <>
                  <S.Field data-test={DATA_TEST.DOB_FIELD}>
                    <DateOfBirthSelectGroup
                      name="dob"
                      label="Date of Birth"
                      value={values.dob}
                      onChange={onFieldChange}
                      onFocus={handleFocus}
                      error={errors.dob}
                    />
                  </S.Field>
                  <S.RadioField data-test={DATA_TEST.GENDER_FIELD}>
                    <RadioGroup
                      name="biologicalSex"
                      label="Sex Assigned At Birth"
                      options={[
                        {
                          value: 'male',
                          label: 'Male',
                        },
                        {
                          value: 'female',
                          label: 'Female',
                        },
                      ]}
                      onChange={(e) => {
                        handleFocus();
                        onFieldChange(e);
                      }}
                      error={errors.biologicalSex}
                      value={values.biologicalSex}
                    />
                  </S.RadioField>
                  <S.Field>
                    <Checkbox
                      checked={!!values.wantsMarketing}
                      onChange={(e: FormFieldType) => {
                        handleFocus();
                        onFieldChange(e);
                      }}
                      onFocus={handleFocus}
                      name="wantsMarketing"
                      label="I agree to receive personalized content for Everlywell marketing purposes. Don’t worry, we’ll never spam you."
                      data-test={DATA_TEST.MARKETING_FIELD}
                    />
                  </S.Field>
                </>
              )}
              <S.ButtonRow>
                <S.Button
                  // @ts-ignore
                  type="submit"
                  disabled={isSaving}
                  data-test={DATA_TEST.SUBMIT_BUTTON}
                  className={isSaving ? 'loading' : ''}
                >
                  <ButtonSpinner saving={isSaving} />

                  <ButtonText saving={isSaving}>Save Information</ButtonText>
                </S.Button>
              </S.ButtonRow>
            </S.InnerForm>
          )}
        </Form>
      </S.Box>
    </>
  );
}

export default SettingsForm;
