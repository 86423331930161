import {
  SkeletonLoader,
  SkeletonShape,
  SkeletonUnit,
  VisuallyHidden,
} from '@everlywell/leaves';
import Grid from 'components/Grid';
import React from 'react';

const ProviderSelectionSkeleton = () => (
  <Grid.Container spacing={['lg']}>
    <VisuallyHidden>Loading providers...</VisuallyHidden>
    <Grid.Item width={[1]}>
      <SkeletonLoader
        shape={SkeletonShape.Rectangle}
        height={{ value: 86, unit: SkeletonUnit.Pixel }}
        width={{ value: 100, unit: SkeletonUnit.Percentage }}
      />
    </Grid.Item>
    <Grid.Item width={[1]}>
      <SkeletonLoader
        shape={SkeletonShape.Rectangle}
        height={{ value: 86, unit: SkeletonUnit.Pixel }}
        width={{ value: 100, unit: SkeletonUnit.Percentage }}
      />
    </Grid.Item>
    <Grid.Item width={[1]}>
      <SkeletonLoader
        shape={SkeletonShape.Rectangle}
        height={{ value: 86, unit: SkeletonUnit.Pixel }}
        width={{ value: 100, unit: SkeletonUnit.Percentage }}
      />
    </Grid.Item>
  </Grid.Container>
);

export default ProviderSelectionSkeleton;
