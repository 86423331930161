import { Row, Col, Localize } from '@everlywell/leaves';
import { MY_APP_ROOT } from 'common/utils/constants';
import { REGISTRATION_COMPLETE as DATA_TEST } from 'common/utils/constants/dataTest';
import { NO_VIDEO_FOR_SLUG } from 'common/utils/constants/features';
import { clearSessionStorage } from 'common/utils/helpers';
import { REGISTRATION_KEYS } from 'common/utils/registrationHelpers';
import {
  KitRegistrationResponse,
  KitRegistrationUser,
} from 'common/utils/types';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { TestCollectionMethod } from '../../KitCollection/model';
import severeCovidCompleteIcon from '../assets/severe-covid-complete.svg';
import HealthProfileEntryPoint from '../HealthProfileEntryPoint';
import * as S from './styles';

export interface Props {
  onSubmit: () => void;
  user: KitRegistrationUser;
  kitRegistration?: KitRegistrationResponse;
  isCovidKit: boolean;
  isEnterprise: boolean;
  productImageURL: string;
  testName: string;
  collectionTypes?: string;
}

interface StandardCompletionProps {
  collection_types: TestCollectionMethod[];
  hasCollectionMethods: boolean;
  kitRegistration?: KitRegistrationResponse;
}

const getCollectionType = (kitRegistration?: KitRegistrationResponse) => {
  if (kitRegistration) {
    return NO_VIDEO_FOR_SLUG.includes(kitRegistration.product.slug)
      ? []
      : kitRegistration.collection_types;
  }
  return [];
};

const StandardCompletion = ({
  collection_types,
  hasCollectionMethods,
  kitRegistration,
}: StandardCompletionProps) => (
  <S.StyledRow
    start="xs"
    middle="xs"
    data-test={`${DATA_TEST.CONTAINER}-standard`}
  >
    <Col xs={12}>
      <S.Header hasCollectionMethods={hasCollectionMethods}>
        Your test is registered!
      </S.Header>
    </Col>

    {hasCollectionMethods && (
      <>
        <Col xs={12}>
          <S.Subtitle>The last step is to collect your sample</S.Subtitle>
        </Col>
        <Col xs={12}>
          <Link
            to={'/collection'}
            state={{
              methods: collection_types,
              sku: kitRegistration && kitRegistration.barcode.variant_sku,
              collection_methods:
                kitRegistration && kitRegistration.collection_methods,
            }}
          >
            <S.StyledButton
              appearance="primary"
              data-test={`${DATA_TEST.BUTTON}-dashboard`}
            >
              Continue collecting sample now
            </S.StyledButton>
          </Link>
        </Col>
      </>
    )}
    <Col xs={12}>
      <S.StyledButton
        appearance={hasCollectionMethods ? 'secondary' : 'primary'}
        href={MY_APP_ROOT}
        data-test={`${DATA_TEST.BUTTON}-dashboard`}
      >
        {hasCollectionMethods ? 'Collect sample later' : 'Return to dashboard'}
      </S.StyledButton>
    </Col>
  </S.StyledRow>
);

const SevereCovidCompletion = () => (
  <Row
    center="xs"
    middle="xs"
    data-test={`${DATA_TEST.CONTAINER}-severe-symptoms`}
  >
    <S.StyledImage src={severeCovidCompleteIcon} />
    <S.Header hasCollectionMethods={false}>
      Testing is not recommended for you at this time. Please do not proceed.
    </S.Header>
    <S.Subtitle>
      Eligibility for{' '}
      <Localize name="RegistratonComplete-COVID">COVID-19</Localize> testing in
      the U.S. is determined by the Centers for Disease Control & Prevention{' '}
      <Localize name="RegistratonComplete-CDC1">(CDC)</Localize> and under
      current <Localize name="RegistratonComplete-CDC1">CDC</Localize>{' '}
      guidelines, you {"don't"} qualify for testing. We recommend you do not
      delay medical treatment. Speak to your health care provider to discuss
      your next steps in care.
    </S.Subtitle>
    <S.StyledButton appearance="primary" href={MY_APP_ROOT}>
      Return to dashboard
    </S.StyledButton>
  </Row>
);

const RegistrationComplete = ({
  user,
  kitRegistration,
  isCovidKit,
  isEnterprise,
  productImageURL,
  testName,
  collectionTypes,
}: Props) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (isCovidKit || isEnterprise) {
      navigate({ search: '?complete=true' });
      clearSessionStorage(REGISTRATION_KEYS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCovidKit, isEnterprise]);
  const shouldShowCOVIDCompletion =
    kitRegistration &&
    kitRegistration.covid &&
    user.symptoms.value &&
    user.symptoms.value === 'severe';

  if (shouldShowCOVIDCompletion) {
    return <SevereCovidCompletion />;
  }

  return !isCovidKit && !isEnterprise ? (
    <HealthProfileEntryPoint
      collectionTypes={collectionTypes}
      kitRegistration={kitRegistration}
      productImageURL={productImageURL}
      testName={testName}
    />
  ) : (
    <StandardCompletion
      collection_types={getCollectionType(kitRegistration)}
      hasCollectionMethods={getCollectionType(kitRegistration).length > 0}
      kitRegistration={kitRegistration}
    />
  );
};

export default RegistrationComplete;
