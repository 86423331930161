/**
 * ReplacementConfirmation
 */

import Layout from 'components/Layout';
import React from 'react';
import { Link } from 'react-router-dom';

import ShippingIcon from './images/shipping.svg';
import * as S from './styles';

function ReplacementConfirmation() {
  return (
    <Layout>
      <S.Wrapper>
        <S.AlertLg>
          <S.ShippingImage src={ShippingIcon} />
          <S.Heading>Shipping address confirmed!</S.Heading>
          <S.SubHeading>
            We’ll alert you via email when your replacement test has shipped.
          </S.SubHeading>
          <S.ButtonWrapper>
            <S.BackButton
              role="button"
              aria-label="Dashboard"
              component={Link}
              href="/dashboard"
              primary
            >
              Back to My Everly
            </S.BackButton>
          </S.ButtonWrapper>
        </S.AlertLg>
      </S.Wrapper>
      <S.Accent />
    </Layout>
  );
}

export default ReplacementConfirmation;
