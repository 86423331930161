import useProgramSlug from 'common/hooks/useProgramSlug';
import { differenceInYears } from 'date-fns';
import { useFormContext } from 'react-hook-form';

import { useFormModalContext } from '../../../providers';
import filtersMap from '../utils/filters';

export const useHealthieExclusionLogic = () => {
  const { allCustomModules } = useFormModalContext();
  const { watch } = useFormContext();
  const { programSlug } = useProgramSlug();

  const modulesWithExclusions = allCustomModules.filter(
    (customModule) => customModule.custom_module_condition,
  );

  // For DOB field, exclusion logic was added for specific AsyncRx programs and
  // will show the error modal if conditionals are true and
  // errors are configured in Healthie
  const validateDOB = (value: string) => {
    const program = programSlug ?? '';

    const excludeProgramsForAge50andOlder =
      program === 'bv-async' || program === 'yeast-infection-async';
    const excludeProgramsForAge65andOlder =
      program === 'genital-herpes-async' || program === 'cold-sores-async';
    const age = differenceInYears(new Date(), new Date(value));

    if (age < 18) return false;
    if (age >= 50 && excludeProgramsForAge50andOlder) return true;
    if (age >= 65 && excludeProgramsForAge65andOlder) return true;

    return false;
  };

  const shouldDisplayModal = modulesWithExclusions.some((module) => {
    const { conditional_custom_module_id, filter_type, value_to_filter } =
      module.custom_module_condition || {};

    const value = watch(conditional_custom_module_id);
    const isValidValue =
      typeof value === 'string' &&
      value.length > 0 &&
      filter_type &&
      value_to_filter;

    const isErrorField = module.required && module.label?.includes('[error]');

    const moduleWithExclusion = allCustomModules.find(
      (customModule) => customModule.id === conditional_custom_module_id,
    );

    const isDOBModule = moduleWithExclusion?.mod_type === 'dob';

    if (!isDOBModule && !isErrorField) return false;

    if (isValidValue && isDOBModule) return validateDOB(value);

    if (isValidValue && !isDOBModule) {
      return filtersMap[filter_type](value, value_to_filter);
    }

    return false;
  });

  return shouldDisplayModal;
};
