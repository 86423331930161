import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { MarkerWithResult, NumericalResult } from 'common/utils/types';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import { NumericalMarkerCardContainer } from 'containers/NumericalMarkerCardContainer';
import * as React from 'react';

import * as S from './styles';

type Props = {
  title: string;
  groupedMarkers: NumericalResult[];
  outOfRange?: boolean;
  viewingSharedKitResult?: boolean;
};

function NumericalMarkerGroup(props: Props) {
  const {
    title,
    groupedMarkers,
    outOfRange = false,
    viewingSharedKitResult,
  } = props;
  const rangeString = outOfRange ? 'Out of Range' : 'In Range';

  const markerGroupEl = useScrolledIntoViewOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: `${rangeString} Results`,
      },
    });
  });

  if (!groupedMarkers.length) return null;

  return (
    <S.NumericalMarkerGroupWrapper outOfRange={outOfRange}>
      <S.NumericalGroupTitle>{title}</S.NumericalGroupTitle>
      <div ref={markerGroupEl}>
        {groupedMarkers.map((result: NumericalResult) => {
          const markersWithResults: MarkerWithResult[] =
            Object.values(result)[0];
          const marker = markersWithResults[0];

          return (
            <NumericalMarkerCardContainer
              key={marker.id}
              markersWithResults={markersWithResults}
              contentToken={marker.content_token}
              outOfRange={outOfRange}
              viewingSharedKitResult={viewingSharedKitResult}
            />
          );
        })}
      </div>
    </S.NumericalMarkerGroupWrapper>
  );
}

export { NumericalMarkerGroup };
