import { CONTENT_CARD } from 'common/utils/constants/dataTest';
import React from 'react';

import * as S from './styles';

type Props = {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  isActive: boolean;
};

function ExpandButton(props: Props) {
  const { onClick, isActive } = props;
  return (
    <S.Wrapper
      aria-expanded={isActive}
      onClick={onClick}
      isActive={isActive}
      data-test={CONTENT_CARD.EXPAND_BUTTON}
    >
      {props.isActive ? 'Hide Options' : 'See More Options'}
    </S.Wrapper>
  );
}

export default ExpandButton;
