import { AddressSuggestion } from 'common/utils/types';
import React from 'react';

interface SuggestedAddressProps {
  suggestion: AddressSuggestion;
}

const SuggestedAddress = ({ suggestion }: SuggestedAddressProps) => (
  <>
    <div data-notranslate="true">
      {suggestion.street} {suggestion.secondary ? suggestion.secondary : ''}
    </div>
    <div data-notranslate="true">
      {suggestion.city}, {suggestion.state} {suggestion.zipcode}
    </div>
  </>
);

export default SuggestedAddress;
