import { colors, mediaQueries, size } from '@everlywell/leaves';
import { CardContainer } from 'common/styles/app-styles';
import { Col_12_Span_N } from 'common/styles/grid-styles';
import styled from 'styled-components';

const Wrapper = styled(Col_12_Span_N)`
  padding-bottom: ${size.xl1}px;
`;

const CardWrapper = styled(CardContainer)`
  margin-top: ${size.lg}px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: ${size.xs1}px;
  overflow: hidden;
  box-shadow: 0 ${size.xs3}px ${size.md}px 0 rgba(${colors.black}, 0.05);
  ${mediaQueries.forTabletVerticalUp} {
    padding: ${size.xs1}px 0;
  }
`;

export { Wrapper, Card, CardWrapper };
