import { getRequest } from 'common/hooks/useApi/request';
import { LEGACY_APP_ROOT } from 'common/utils/constants';
import { HealthProfileProgressStatusResponse } from 'common/utils/types';

export const getHealthProfileProgress = async () => {
  const response = await getRequest<HealthProfileProgressStatusResponse>(
    `${LEGACY_APP_ROOT}/health-profile/api/v2/questionnaires/health-profile/progress`,
    false,
    { rejectOnError: true },
  );
  return response;
};
