import { Button } from '@everlywell/leaves';
import StoreApi from 'common/hooks/useAccountSettings/StoreApi';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { logError } from 'common/utils/helpers';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ERROR_NOTIFICATION, setNotification } from 'store/actions';

import * as S from './DownloadCarePlanPDF.styles';

export type DownloadCarePlanPDFProps = {
  carePlanId: string | null;
};

export const DOWNLOAD_PDF_CTA = 'Download a PDF of your plan';

function DownloadCarePlanPDF({ carePlanId }: DownloadCarePlanPDFProps) {
  const [isDownloadingCarePlan, setIsDownloadingCarePlan] = useState(false);
  const dispatch = useDispatch();

  if (!carePlanId) return null;

  const downloadUrl = `/v3/telehealth/care_plans/${carePlanId}.pdf`;

  const onClick = async () => {
    setIsDownloadingCarePlan(true);
    await getCarePlanPDF();
  };

  const getCarePlanPDF = async () => {
    try {
      const { data } = await StoreApi.get<BlobPart>(downloadUrl, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(
        new Blob([data], { type: 'application/pdf' }),
      );

      const link = document.createElement('a');
      link.setAttribute('download', `care_plan-${carePlanId}.pdf`);
      link.href = url;
      link.click();
      window.URL.revokeObjectURL(url);
      return Promise.resolve();
    } catch (err) {
      dispatch(
        setNotification({
          message:
            "We're unable to process your request right now. Please retry the request. If this error persists, please contact our support team.",
          type: ERROR_NOTIFICATION,
          source: ANALYTICS.LABELS.CARE_PLAN.DOWNLOAD_CARE_PLAN_PDF,
        }),
      );
      logError((err as Error).message, {
        component: 'DownloadCarePlanPDF',
        method: 'onClick',
        error: (err as Error).message,
      });
      return Promise.reject(err);
    } finally {
      setIsDownloadingCarePlan(false);
    }
  };

  return (
    <S.Container>
      <Button
        appearance="secondary"
        onClick={onClick}
        isLoading={isDownloadingCarePlan}
      >
        {DOWNLOAD_PDF_CTA}
      </Button>
    </S.Container>
  );
}

export default DownloadCarePlanPDF;
