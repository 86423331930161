import { Allergy } from 'common/apis/telehealthApis';

const baseAllergy: Allergy = {
  id: 1,
  name: 'lactase',
};

export const buildAllergies = (overrideProps: Partial<Allergy> = {}) => ({
  ...baseAllergy,
  ...overrideProps,
});
