import {
  size,
  mediaQueries,
  Modal as LeavesModal,
  typography,
  Button as LeavesButton,
  H4,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Modal = styled(LeavesModal)`
  top: ${size.xl4}px;
  padding-bottom: ${size.xl4}px;
  > div {
    justify-content: center;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    display: flex;
    padding: ${size.xl5}px;
    top: ${size.xl7}px;
    width: 700px;

    > div {
      padding: 0;
    }
  }

  ${mediaQueries.forDesktopUp} {
    padding: ${size.xl5}px;

    & > div > div {
      overflow: hidden;
    }
  }
`;

export const Title = styled(H4)`
  margin-top: 0;
  ${mediaQueries.forDesktopUp} {
    ${typography.h3Text}
    text-align: center;
  }
`;

export const Button = styled(LeavesButton)`
  width: 100%;
  margin-top: ${size.xl1}px;
`;

export const Copy = styled.p`
  ${typography.bodyText};
`;
