import { logError } from '../helpers';

declare global {
  interface Window {
    webkit: any;
  }
}

export const iosMessage = (
  eventName: string,
  pageName: string,
  programSlug?: string,
) => {
  try {
    window?.webkit?.messageHandlers?.jsToSwift.postMessage({
      event: eventName,
      programSlug,
    });
  } catch (e: any) {
    logError(e.message, {
      app: 'results',
      pageName,
    });
  }
};
