import { colors, typography } from '@everlywell/leaves';
import { baseFont } from 'common/styles/app-styles';
import { hexToRGB } from 'common/utils/helpers';
import styled from 'styled-components';

import errorChevron from './images/error-chevron.svg';
import chevron from './images/select-chevron.svg';

const Wrapper = styled.div`
  font-weight: ${typography.weight.regular};
  position: relative;
  padding-top: 24px;
`;

const Label = styled.label`
  position: absolute;
  font-size: 16px;
  top: 24px;
  user-select: none;
  font-style: italic;
  line-height: 50px;
  left: 16px;
  color: ${colors.gray4};
  transition: all 100ms ease-in;

  &[data-has-error='true'],
  &[data-is-empty='false'],
  &[data-is-focused='true'] {
    top: 0;
    left: 0;
    font-size: 14px;
    font-style: normal;
    line-height: 1.5;
    color: ${colors.gray4};
  }
`;

const Select = styled.select`
  /* prettier-ignore */
  background-image: url("${chevron}");
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) center;
  font-family: ${baseFont};
  font-weight: inherit;
  border-radius: 3px;
  background-color: rgba(${hexToRGB(colors.gray1)}, 0.5);
  height: 48px;
  width: 100%;
  border-bottom: 2px solid transparent;
  padding: 0 16px;
  outline: 0;
  appearance: none;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.11px;
  color: ${colors.gray4};
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;

  &:focus {
    border-bottom-color: ${colors.green4};
  }

  &[data-has-error='true'] {
    /* prettier-ignore */
    background-image: url("${errorChevron}");
    background-color: rgba(${hexToRGB(colors.red1)}, 0.6);
    border-bottom: 2px solid ${colors.red2};
    caret-color: ${colors.red2};
  }

  &:-ms-expand {
    display: none;
  }

  &:-moz-focusring {
    outline: none;
  }
`;

export { Wrapper, Label, Select };
