import {
  Button,
  colors,
  H3,
  Input as RawInput,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import { hexToRGB } from 'common/utils/helpers';
import styled from 'styled-components';

interface Props {
  verticalLayout: boolean | undefined;
}

interface IntakeProps {
  intakeFlow: boolean;
}

const Wrapper = styled.div<IntakeProps>`
  width: 100%;
  max-width: ${(props) => (props.intakeFlow ? '696px' : '')};
  margin: 0 auto;
`;

const Title = styled(H3)<Props>`
  font-weight: ${(props) =>
    props.verticalLayout
      ? `${typography.weight.book}`
      : `${typography.weight.regular}`};
  font-size: 25px;
  line-height: 1.58;
  color: ${colors.teal6};
  margin: ${(props) =>
    props.verticalLayout ? `0 auto ${size.lg}px` : `0 0 ${size.lg}px`};
  text-align: ${(props) => (props.verticalLayout ? 'center' : 'left')};
  width: 100%;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.md}px;
    font-size: ${(props) =>
      props.verticalLayout ? `${size.xl1}px` : `${size.xl3}px`};
    max-width: ${(props) => (props.verticalLayout ? '80%' : '100%')};
  }
`;

const SubTitle = styled.h3`
  font-weight: ${typography.weight.light};
  font-size: 18px;
  line-height: ${size.xl1}px;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${colors.gray4};
  margin: 0 0 ${size.md}px;
`;

const Box = styled.div<Props>`
  padding: ${size.xl1}px ${size.md}px;
  border-radius: 3px;
  box-shadow: 0 ${size.xs3}px ${size.md}px 0
    rgba(${hexToRGB(colors.black)}, 0.1);
  background-color: ${colors.white};
  margin-bottom: 53px;
  ${mediaQueries.forTabletVerticalUp} {
    padding: ${size.xl1}px ${size.lg}px;
    margin-bottom: 35px;
  }
`;

const UpdatedButton = styled(Button)<Props>`
  outline: none;
  align-self: center;
  margin-top: 38px;
  font-size: 18.7px;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: ${(props) => (props.verticalLayout ? '100%' : '230px')};
  height: 50px;
  border-radius: 0;
  background: ${colors.green5};
  position: relative;

  &:hover {
    background: ${colors.teal5};
  }

  &[disabled] {
    border-color: transparent;
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.08);
    box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.08);
    color: ${colors.gray3};
    font-weight: ${typography.weight.bold};
  }

  &.loading {
    background: ${colors.green5} !important;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;

  ${mediaQueries.forTabletHorizontalUp} {
    justify-content: flex-start;
    flex: 1 0 100%;
  }
`;

const HiddenInput = styled(RawInput)`
  display: none;
`;

const InvalidAddress = styled.div`
  color: ${colors.red3};
`;

const Input = styled(RawInput)`
  svg {
    fill: none;
    stroke-dasharray: 100;
    stroke-dashoffset: 0;
    stroke-width: 5;
    display: none;
  }
`;

export {
  UpdatedButton as Button,
  ButtonRow,
  Title,
  SubTitle,
  Box,
  HiddenInput,
  InvalidAddress,
  Input,
  Wrapper,
};
