import Grid from 'components/Grid/Grid';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { containsInvalidOptionTag, removeTag } from '../../utils/tags';
import { FormBuilderField } from '../../utils/types';
import * as S from './RadioFieldGroup.styles';

export type RadioFieldGroupProps = FormBuilderField;

export const REQUIRED_ERROR = 'Please choose an option';

/**
 * Dynamic radio fields for demographics form. Controlled by the react hook form
 */
export default function RadioFieldGroup({
  id,
  label,
  options_array,
  required,
  variant,
}: RadioFieldGroupProps) {
  const { control, errors, watch } = useFormContext(); // retrieve all hook methods
  const radioVal = watch(id);

  const error = errors[id];
  return (
    <Grid.Item width={[1]}>
      <S.Container>
        <S.Label variant={variant} hasError={!!error} required={required}>
          {label}
        </S.Label>

        {options_array.map((option) => {
          const label = removeTag(option);
          return (
            <Controller
              key={option}
              control={control}
              name={id}
              rules={{
                required: required && REQUIRED_ERROR,
                validate: (value) => containsInvalidOptionTag(value),
              }}
              defaultValue={null}
              render={({ onChange, ref }) => (
                <S.RadioButton
                  checked={radioVal === option}
                  id={`${id}-${option}`}
                  name={id}
                  label={label}
                  value={option}
                  ref={ref}
                  onChange={() => onChange(option)}
                />
              )}
            />
          );
        })}
        <S.Error role="alert">{error?.message}</S.Error>
      </S.Container>
    </Grid.Item>
  );
}
