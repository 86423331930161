import React from 'react';

import * as S from './RecommendationCard.styles';

type RecommendationCardProps = {
  header: string;
  imageIcon?: string;
  recommendations: string[];
  title?: string;
};

function RecommendationCard(props: RecommendationCardProps) {
  const { header, imageIcon, recommendations, title } = props;
  const headerKey = header.replace(' ', '');
  return (
    <S.Card>
      {imageIcon && (
        <S.CardIconContainer>
          <S.CardIcon src={imageIcon} alt={header} />
        </S.CardIconContainer>
      )}
      <S.CardContent>
        <S.CardHeader>{header}</S.CardHeader>
        {title && <S.CardTitle>{title}</S.CardTitle>}
        <S.CardListContainer>
          <S.CardList>
            {recommendations.map((recommendation, index) => (
              <S.CardListItem key={`${headerKey}${index}`}>
                {recommendation}
              </S.CardListItem>
            ))}
          </S.CardList>
        </S.CardListContainer>
      </S.CardContent>
    </S.Card>
  );
}

export default RecommendationCard;
