import { H4, Row, Col, formUtils, states } from '@everlywell/leaves';
import { Address, updateShippingAddress } from 'common/apis/settingsApis';
import { shippableStates } from 'common/utils/constants/states';
import { zipcodeCleaningValidation } from 'common/utils/helpers';
import { logError } from 'common/utils/helpers';
import React, { useEffect, SyntheticEvent } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import * as S from './RedemptionAddressForm.styles';

export type RedemptionAddressFormProps = {
  title: string;
  backButtonText: string;
  confirmButtonText: string;
  backButtonCallback: () => void;
  confirmButtonCallback: (newAddress: AddressFormValues) => void;
  shippingAddress: AddressFormValues;
  handleFocus: () => void;
};

export type AddressFormValues = Omit<Address, 'id' | 'country' | 'phone'>;

/**
 * Address form for the credit redemption feature
 */
export default function RedemptionAddressForm({
  title,
  backButtonText,
  confirmButtonText,
  backButtonCallback,
  confirmButtonCallback,
  shippingAddress,
  handleFocus,
}: RedemptionAddressFormProps) {
  const { register, errors, setValue, handleSubmit } =
    useForm<AddressFormValues>();
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const {
    mutateAsync: mutateAddress,
    error: errorSavingAddress,
    isLoading: isSaving,
  } = useMutation(updateShippingAddress);

  useEffect(() => {
    if (shippingAddress) {
      setValue('first_name', shippingAddress.first_name);
      setValue('last_name', shippingAddress.last_name);
      setValue('street1', shippingAddress.street1);
      setValue('street2', shippingAddress.street2);
      setValue('city', shippingAddress.city);
      setValue('state', states.stateAbbreviationToNames[shippingAddress.state]);
      setValue('zipcode', shippingAddress.zipcode);
    }
  }, [shippingAddress, setValue]);

  const handleFormSubmit = async (formData: AddressFormValues) => {
    setErrorMessage(null);

    const formDataCopy = {
      ...formData,
      state: states.stateNameToAbbreviation[formData.state],
    };

    const { first_name, last_name, street1, street2, city, state, zipcode } =
      formDataCopy;

    try {
      await mutateAddress({
        first_name,
        last_name,
        address1: street1,
        address2: street2,
        city,
        state,
        zipcode,
      });

      confirmButtonCallback(formDataCopy);
    } catch (error: any) {
      logError((error as Error).message, {
        errorInfo: 'Error saving address on credit redemption',
        component: 'AddressConfirmation component - credit redemption',
        method: 'handleFormSubmit',
      });
    }
  };

  useEffect(() => {
    if (errorSavingAddress) {
      setErrorMessage((errorSavingAddress as any)?.data?.message);
    }
  }, [errorSavingAddress]);

  return (
    <S.Container>
      <H4>{title}</H4>
      <form onSubmit={handleSubmit(handleFormSubmit)} aria-label="form">
        <Row>
          <Col xs={12} md={6}>
            <S.Input
              id="redemption-first-name"
              name="first_name"
              label="First Name"
              placeholder="Jane"
              ref={register({ required: 'Please enter your first name' })}
              disabled={isSaving}
              error={errors.first_name && errors.first_name.message}
              onFocus={handleFocus}
            />
          </Col>
          <Col xs={12} md={6}>
            <S.Input
              id="redemption-last-name"
              name="last_name"
              label="Last Name"
              placeholder="Doe"
              ref={register({ required: 'Please enter your last name' })}
              disabled={isSaving}
              error={errors.last_name && errors.last_name.message}
              onFocus={handleFocus}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <S.Input
              id="redemption-street1"
              name="street1"
              label="Address"
              placeholder="1234 Apple St"
              ref={register({ required: 'Please enter an address' })}
              disabled={isSaving}
              error={errors.street1 && errors.street1.message}
              onFocus={handleFocus}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <S.Input
              id="redemption-street2"
              name="street2"
              label="Address 2 (optional)"
              placeholder="Apartment, suite, etc"
              ref={register}
              disabled={isSaving}
              error={errors.street2 && errors.street2.message}
              onFocus={handleFocus}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <S.Input
              id="redemption-city"
              name="city"
              label="City"
              placeholder="Austin"
              ref={register({ required: 'Please enter a city' })}
              disabled={isSaving}
              error={errors.city && errors.city.message}
              onFocus={handleFocus}
            />
          </Col>
          <Col xs={12} md={6}>
            <S.Dropdown
              // @ts-ignore
              items={shippableStates}
              label="State"
              name="state"
              id="redemption-state"
              placeholderText="State"
              showErrorMessage
              error={errors.state && errors.state.message}
              ref={register({
                required: 'Please select a state',
              })}
              onFocus={handleFocus}
              disabled={isSaving}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <S.Input
              id="redemption-zipcode"
              name="zipcode"
              label="ZIP Code"
              placeholder="12345"
              ref={register({
                required: 'Please enter a ZIP code',
                validate: {
                  validZipCode: (value: any) =>
                    formUtils.validateZipCode(value) ||
                    'Please enter a valid ZIP code',
                },
              })}
              disabled={isSaving}
              error={errors.zipcode && errors.zipcode.message}
              onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                const target = e.target as HTMLInputElement;
                const zipcode = zipcodeCleaningValidation(target.value);
                target.value = zipcode;
              }}
              onFocus={handleFocus}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <S.InvalidAddress>{errorMessage}</S.InvalidAddress>
          </Col>
        </Row>
        <S.ButtonWrapper>
          <S.BackButton
            appearance="secondary"
            onClick={() => backButtonCallback()}
            isDisabled={isSaving}
            type="button"
          >
            {backButtonText}
          </S.BackButton>
          <S.ConfirmButton
            appearance="primary"
            isDisabled={isSaving}
            isLoading={isSaving}
            type="submit"
          >
            {confirmButtonText}
          </S.ConfirmButton>
        </S.ButtonWrapper>
      </form>
    </S.Container>
  );
}
