import { mediaQueries, size } from '@everlywell/leaves';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

const containerStyles = css`
  width: 100%;
  max-width: 535px;
  margin: auto;
  padding: ${size.xl3}px ${size.md}px;
  overflow-x: clip;

  ${mediaQueries.forTabletVerticalUp} {
    padding: ${size.xl4}px ${size.lg}px;
  }
`;

export const sharedStyles = {
  containerStyles,
};
