/* eslint-disable @typescript-eslint/no-var-requires */
import {
  typography,
  size,
  colors,
  mediaQueries,
  Button,
  Icons,
} from '@everlywell/leaves';
import styled, { css } from 'styled-components';

import KitMenu from './components/KitMenu';

const handleColorType = (color: string | undefined) => {
  switch (color) {
    case 'gray':
      return `color: ${colors.gray5}; background-color: ${colors.gray1};`;
    case 'green':
      return `color: #245c3e; background-color: ${colors.green1};`;
    case 'orange':
      return `color: #98310f; background-color: #fff6f2;`;
    case 'red':
      return `color: #a2192b; background-color: ${colors.red1};`;
    case 'tan':
      return `color: #734e04; background-color: #fff8ea;`;
    case 'teal':
      return `color: #005b5e; background-color: ${colors.teal1};`;
    default:
      return `color: #734e04; background-color: #fff8ea;`;
  }
};

const KitCard = styled.div`
  background-color: ${colors.white};
  border-radius: ${size.xs1}px;
  box-shadow: 0 7px 30px -10px rgba(220, 228, 229);
  display: flex;
  flex-direction: column;
  margin-bottom: ${size.xl5}px;
  padding: ${size.md}px ${size.md}px ${size.xl1}px;
  position: relative;
  ${mediaQueries.forTabletHorizontalUp} {
    padding: ${size.lg}px ${size.xl1}px ${size.xl1}px;
  }
`;

const StyledKitMenu = styled(KitMenu)`
  position: absolute;
  right: ${size.md}px;
  top: ${size.md}px;
  ${mediaQueries.forTabletHorizontalUp} {
    right: ${size.xl1}px;
    top: ${size.lg}px;
  }
`;

const TestInfo = styled.div`
  align-items: center;
  border-bottom: 2px solid ${colors.gray1};
  display: flex;
  flex-direction: column;
  margin-bottom: ${size.xl1}px;
  text-align: center;
  ${mediaQueries.forTabletHorizontalUp} {
    align-items: center;
    align-items: flex-start;
    flex-direction: row;
    padding-bottom: ${size.md}px;
    padding-right: 36px;
  }
`;

const DesktopActionSection = styled.div`
  display: none;
  ${mediaQueries.forTabletHorizontalUp} {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    margin-left: auto;
  }

  button {
    margin-bottom: ${size.md}px;
  }
`;

const DesktopActionInner = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
`;

const ScienceButton = styled(Button)`
  margin-top: ${size.xl1}px;
  text-decoration: none !important;
  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.md}px;
    margin-top: 0;
  }
`;

const faqStyles = css`
  ${typography.buttonText};
  display: flex;
  text-decoration: none !important;
`;

const FAQLink = styled.a`
  ${faqStyles};
`;

const FAQIcon = styled(Icons.Help)`
  margin-right: ${size.xs2}px;
`;

const MobileFAQLink = styled.a`
  ${faqStyles};
  left: ${size.md}px;
  position: absolute;
  top: ${size.md}px;
  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
  }
`;

const BoxImageContainer = styled.div<{ isLabVisit?: boolean }>`
  align-items: center;
  background-color: ${({ isLabVisit }) => (isLabVisit ? 'none' : colors.teal1)};
  border-radius: 50%;
  display: flex;
  height: 92px;
  justify-content: center;
  margin-bottom: ${size.sm}px;
  width: 92px;
`;

const BoxImage = styled.img`
  width: 100px;
`;

const Details = styled.div`
  ${mediaQueries.forTabletHorizontalUp} {
    text-align: left;
  }
`;

const Title = styled.h3`
  ${typography.h5Text};
  font-weight: ${typography.weight.book};
  margin: 0 0 ${size.md}px;
  ${mediaQueries.forTabletVerticalUp} {
    padding-left: ${size.lg}px;
  }
`;

const Date = styled.div`
  border-radius: 14px;
  display: inline-block;
  font-size: 14px;
  font-weight: ${typography.weight.bold};
  line-height: ${size.lg}px;
  margin-bottom: ${size.lg}px;
  padding: ${size.xs2}px ${size.sm}px;
  ${({ color }) => handleColorType(color)};
  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 0;
  }
  ${mediaQueries.forTabletVerticalUp} {
    margin-left: ${size.lg}px;
  }
`;

const MobileActionItems = styled.div`
  display: flex;
  justify-content: center;
  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
  }

  button {
    margin-top: ${size.md}px;
  }
`;

const TrackingLink = styled.a`
  color: ${colors.teal5};
  text-decoration: none !important;
`;

const KitButton = styled(Button)`
  margin-top: ${size.xl1}px;
  text-decoration: none !important;
  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.md}px;
    margin-top: 0;
  }
`;

export {
  BoxImage,
  BoxImageContainer,
  Date,
  DesktopActionInner,
  DesktopActionSection,
  Details,
  FAQIcon,
  FAQLink,
  KitButton,
  KitCard,
  MobileActionItems,
  MobileFAQLink,
  ScienceButton,
  StyledKitMenu,
  TestInfo,
  Title,
  TrackingLink,
};
