// Custom interceptor to add a fresh JWT to each request
import { QueryClient } from 'react-query';

/**
 * Create a query client for the `react-query`'s QueryClientProvider
 *
 * See: https://react-query.tanstack.com/reference/QueryClient
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Number.POSITIVE_INFINITY,
    },
  },
});
