import { colors, H4 } from '@everlywell/leaves';
import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 100%;
`;

export const Title = styled(H4)`
  color: ${colors.teal5};
  margin: 0;
  font-weight: 700;
`;

export const Head = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
