import { DEFAULT_BOX_IMAGE_URL } from 'common/utils/constants/urls';
import { CarePlanDataResponse } from 'common/utils/types';

const visitSummary = [
  '<p>Based on your description of the types of headaches you’ve been having recently, we’re going to',
  ' start with a <strong>Women’s Health</strong> test to check your hormone',
  ' levels and we’ll have a follow up visit once your results are in. In the <em>meantime</em>',
  ' try some of the at-home remedies we discussed</p>\n<p>Try to:</p>\n<ul>\n<li>Rest</li>\n',
  '<li>Bullet List</li>\n</ul>\n<p> </p>\n<ol>\n<li>Keep formatting</li>\n',
  '<li>Numbered list</li>\n</ol>\n<p> </p>',
];

export const carePlanData: CarePlanDataResponse = {
  recommended_tests: [
    {
      id: 1,
      name: 'Women’s Health Test',
      box_image_url: DEFAULT_BOX_IMAGE_URL,
      meta_description:
        'Measure 11 key biomarkers known to play an important role in women’s overall health',
      price_in_dollars: '$199',
      master_variant_id: 2,
      slug: 'hba1c',
      credit_redeemable: false,
    },
  ],
  visit_summary: visitSummary.join(''),
  screening_recommendations: [
    'Abdominal Aortic Aneurysm Screening',
    'Breast Cancer Screening',
    'Cervical Cancer Screening',
    'Colorectal Cancer Screening',
    'Hypertension Screening',
    'Lung Cancer Screening',
    'HIV Screening',
    'Hepatitis C Screening',
    'Prediabetes/Diabetes Screening',
  ],
  provider_name: 'test',
  issued_at: '2022-10-04T00:00:00-04:00',
  lifestyle_recommendations: [
    {
      name: 'Physical Activity',
      description:
        'Begin with 3 times a week for at least 30 minutes at a time. Low impact excercises are beneficial for stress management, heart health, and sleep quality; all things that can help with managing chronic headaches.',
    },
    {
      name: 'Diet',
      description: 'Limit saturated and trans fats, sodium, and added sugars.',
    },
  ],
  vaccination_recommendations: ['Meningococcal', 'Influenza', 'Tdap'],
  patient_plan:
    '<p>Provider recommends testing to rule out potential blood condition prior to next session</p> <ul><li>Complete CBC panel at Quest labs</li></ul> <p>A member of the team will reach out once results are received to schedule follow up session</p>',
  medications: [
    {
      name: 'Deep Sea Nasal Spray (nasal - spray) 0.65%',
      dosage: '0.65%',
      active: false,
      instructions: 'Take 1 tablet',
      start_date: '2001-01-01T00:00:00-05:00',
      end_date: '2023-03-14T00:00:00-04:00',
      comments: 'It will help you',
    },
    {
      name: 'Dialyvite 800 (oral - tablet) Vitamin B Complex with C and Folic Acid',
      dosage: '1 tablet',
      active: true,
      instructions: 'Take 1 tablet',
      start_date: '2022-10-03T00:00:00-04:00',
      end_date: '2022-10-20T00:00:00-04:00',
      comments: 'Testing',
    },
    {
      name: 'Ibuprofen (oral - tablet) 200 mg',
      dosage: '200 mg',
      active: false,
      instructions: 'Take 2 tablets',
      start_date: '2022-10-04T00:00:00-04:00',
      end_date: '2022-10-27T00:00:00-04:00',
      comments: 'Test',
    },
    {
      name: 'Testim (transdermal - gel) 50 mg/5 g (1%)',
      dosage: '50 mg/5 g (1%)',
      active: true,
      instructions: 'Take 2 tablets',
      start_date: '2022-10-05T00:00:00-04:00',
      end_date: '2022-11-03T00:00:00-04:00',
      comments: 'Testingssad',
    },
    {
      name: 'Animal Shape Vitamins (oral - tablet, chewable) Multiple Vitamins',
      dosage: '1 tablet',
      active: true,
      instructions: 'take some tablets',
      start_date: '2022-10-05T00:00:00-04:00',
      end_date: null,
      comments: 'test',
    },
    {
      name: 'Ibuprofen (compounding - powder)',
      dosage: 'Not Sure',
      active: true,
      instructions: 'test',
      start_date: '2022-10-10T00:00:00-04:00',
      end_date: '2022-11-17T00:00:00-05:00',
      comments: 'test',
    },
    {
      name: 'Saleto (oral - tablet) 115 mg-210 mg-16 mg-65 mg',
      dosage: '1 tablet',
      active: false,
      instructions: 'asdfadf',
      start_date: '2022-10-03T00:00:00-04:00',
      end_date: null,
      comments: 'testada',
    },
  ],
};

export const buildTelehealthByAppointment = (
  overrideProps: Partial<CarePlanDataResponse> = {},
) => ({
  ...carePlanData,
  ...overrideProps,
});
