import { Container, Row, Col } from '@everlywell/leaves';
import useProgramSlug from 'common/hooks/useProgramSlug';
import useUser from 'common/hooks/useUser';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { HEALTHIE_WHITE_LABEL_SSO_LINK } from 'common/utils/constants/urls';
import { ProgramType } from 'common/utils/types';
import { isUserInEwPlusSigningUpForVCV } from 'common/utils/user';
import Layout from 'components/Layout';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Link } from 'react-router-dom';

import { FALLBACK_PROGRAM_SLUG } from '../InterstitialPage';
import SkipInsuranceModal from './components/SkipInsuranceModal';
import {
  PAYMENT_TYPE_CONTENT,
  PaymentTypeContent,
} from './PaymentTypePage.content';
import * as S from './PaymentTypePage.styles';
import { useRedirectAfterPaymentType } from './utils/redirections';

export type PaymentTypePageProps = {};

export const DEFAULT_VISIT_PRICE = 59;

export function cashPayPriceLabel(price: number, hasCashPayDiscount: boolean) {
  if (!hasCashPayDiscount) {
    return price.toString();
  }

  return `${Math.round(price - price * 0.2)} (20% off)`;
}

/**
 * Payment intersitial page where  users choose wheter to add their insurance or pay the consult
 */
function PaymentTypePage(props: PaymentTypePageProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { user, isLoading: isLoadingUser } = useUser();

  const { telehealthInsurance, stiPriceTest } = useFlags<{
    telehealthInsurance?: boolean;
    stiPriceTest?: number;
  }>();

  const { programSlug } = useProgramSlug();
  const program = programSlug ?? '';

  const { insurancePagePath } = useRedirectAfterPaymentType(program);

  const isEverlywellPlusMember = Boolean(
    user && isUserInEwPlusSigningUpForVCV(user, program as ProgramType),
  );

  /**
   * This approach is taken because the value can
   * momentarily be undefined as the data loads
   * */
  const isTelehealthInsuranceFormEnabled = telehealthInsurance === true;

  const isTelehealthInsuranceFormDisabled = telehealthInsurance === false;

  useEffect(() => {
    if (isTelehealthInsuranceFormEnabled && !!program) {
      analytics.track({
        event: ANALYTICS.EVENTS.VIEWED_PAGE,
        data: {
          label: ANALYTICS.PAGES.TELEHEALTH_INSURANCE_CASH_PAY,
          program,
        },
      });
    }
  }, [isTelehealthInsuranceFormEnabled, program]);

  useEffect(() => {
    if (isTelehealthInsuranceFormDisabled) {
      window.open(HEALTHIE_WHITE_LABEL_SSO_LINK, '_self');
    }
  }, [isTelehealthInsuranceFormDisabled]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'paymentTypePage',
        programName: program,
      },
    });
  }, [program]);

  const handlePrimaryButtonClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'Intake add insurance',
        program,
      },
    });
  };
  const handleSecondaryButtonClick = () => {
    setIsModalOpen(!isModalOpen);
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'Intake cash pay',
        program,
      },
    });
  };

  const CONTENT = PAYMENT_TYPE_CONTENT[program] || PAYMENT_TYPE_CONTENT.DEFAULT;

  const visitPrice =
    stiPriceTest &&
    ['sti-on-demand', 'sti-virtual-care-visit'].includes(program)
      ? stiPriceTest
      : DEFAULT_VISIT_PRICE;

  // Replace $59 with the stiPriceTest value for sti-on-demand program
  // as a part of a price test (temporary, please remove after the test is done)
  const CONTENT_UNDER_TEST: PaymentTypeContent = Object.entries(CONTENT).reduce(
    (acc, [key, value]) => {
      if (typeof value === 'string') {
        return {
          ...acc,
          [key]: value.replace(
            '$59',
            `$${cashPayPriceLabel(visitPrice, isEverlywellPlusMember)}`,
          ),
        };
      }
      return {
        ...acc,
        [key]: value,
      };
    },
    CONTENT,
  );

  if (!isTelehealthInsuranceFormEnabled) {
    return null;
  }

  if (isLoadingUser) {
    return null;
  }

  return (
    <Layout>
      <Container>
        <Row>
          <Col xs md lg>
            <S.Container>
              <S.Heading as="h1">{CONTENT_UNDER_TEST.HEADING}</S.Heading>
              <S.Copy>{CONTENT_UNDER_TEST.SUB_HEADING}</S.Copy>
              {CONTENT_UNDER_TEST?.NOTE && (
                <S.Copy>{CONTENT_UNDER_TEST.NOTE}</S.Copy>
              )}

              <S.ActionButtons>
                <S.ActionButton
                  component={Link}
                  to={insurancePagePath}
                  onClick={handlePrimaryButtonClick}
                  appearance={isEverlywellPlusMember ? 'secondary' : 'primary'}
                  hasCashPayDiscount={!isEverlywellPlusMember}
                >
                  {CONTENT_UNDER_TEST.WITH_INSURANCE}
                </S.ActionButton>
                <S.ActionButton
                  appearance={isEverlywellPlusMember ? 'primary' : 'secondary'}
                  onClick={handleSecondaryButtonClick}
                  hasCashPayDiscount={isEverlywellPlusMember}
                >
                  {CONTENT_UNDER_TEST.WITHOUT_INSURANCE}
                </S.ActionButton>
              </S.ActionButtons>

              {CONTENT_UNDER_TEST?.DISCLAIMER && (
                <S.Copy>{CONTENT_UNDER_TEST.DISCLAIMER}</S.Copy>
              )}
            </S.Container>
          </Col>
        </Row>
      </Container>
      <SkipInsuranceModal
        openModal={isModalOpen}
        setOpenModal={setIsModalOpen}
        program={program || FALLBACK_PROGRAM_SLUG}
        hasCashPayDiscount={isEverlywellPlusMember}
      />
    </Layout>
  );
}

export default PaymentTypePage;
