import React from 'react';

import * as S from './NoShowMessage.styles';

export const NoShowMessage = () => (
  <S.Container>
    <strong>Note:</strong> You will be charged a no-show fee at full cost if you
    are unable to make your appointment without canceling.
  </S.Container>
);
