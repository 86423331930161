import WhatsNextSectionHeaderContainer from 'containers/WhatsNextSection/HeaderContainer';
import * as React from 'react';

type Props = {
  contentToken: string;
  headerIcon?: string;
};

function WhatsNextSection(props: Props) {
  const { contentToken, headerIcon } = props;
  return (
    <WhatsNextSectionHeaderContainer
      contentToken={contentToken}
      headerIcon={headerIcon}
    />
  );
}

export default WhatsNextSection;
