import React from 'react';

import image404 from './images/404.svg';
import image500 from './images/500.svg';
import * as S from './styles';

export type Props = {
  code: 404 | 500;
};

const images = {
  404: image404,
  500: image500,
};

function StatusCode(props: Props) {
  const { code } = props;

  return (
    <S.StatusCode>
      <img src={images[code]} alt={`${code}`} />
    </S.StatusCode>
  );
}

export default StatusCode;
