import { Button, LocalizeDisabled } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { User } from 'common/utils/types';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import BaseCard, { BaseCardIcon } from 'pages/AccountHub/components/BaseCard';
import React from 'react';
import { Link } from 'react-router-dom';

import * as track from '../../../DashboardPage.tracking';
import { CardHeaderLink } from '../BaseCard.styles';
import SettingsIcon from './settings-icon.svg';

export type SettingsCardProps = {
  user?: User;
};
/**
 * Settings card for account hub.
 */
function SettingsCard({ user }: SettingsCardProps) {
  const bannerViewed = useScrolledIntoViewOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: ANALYTICS.LABELS.ACCOUNT_HUB.SETTINGS,
        category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
      },
    });
  });

  const handleClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.ACCOUNT_HUB.EDIT_PROFILE,
        category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
      },
    });
  };

  return (
    <>
      {user && (
        <div>
          <CardHeaderLink
            to={'/account/settings'}
            component={Link}
            onClick={() => track.whenSettingsLinkClicked()}
            //@ts-ignore TODO: refactor this component in leaves to receive generic props: https://everlyhealth.atlassian.net/browse/FE-591
            ref={bannerViewed}
          >
            Settings
          </CardHeaderLink>
          <BaseCard
            image={<BaseCardIcon src={SettingsIcon} alt="" />}
            content={{
              title: (
                <LocalizeDisabled>
                  {user?.first_name} {user?.last_name}
                </LocalizeDisabled>
              ),
              description: <noscript />,
            }}
            cta={
              <Button
                appearance="secondary"
                hasArrow
                to={'/account/settings'}
                component={Link}
                onClick={handleClick}
              >
                Edit profile
              </Button>
            }
          />
        </div>
      )}
    </>
  );
}

export default SettingsCard;
