import { Localize } from '@everlywell/leaves';
import { TEST_INFORMATION_FORM as DATA_TEST } from 'common/utils/constants/dataTest';
import React from 'react';

import infoIcon from '../../assets/ui-icons-info.svg';
import * as S from './styles';

interface ScreenerNoteProps {
  className?: string;
}

const ScreenerNote = ({ className }: ScreenerNoteProps) => (
  <S.ScreenerNoteBox className={className}>
    <S.Image src={infoIcon} alt="Information" data-test={DATA_TEST.ICON} />
    <S.ScreenerNoteText>
      Note: The <Localize name="ScreenerNote-COVID">COVID-19</Localize> PCR test
      result may be shared with state and federal officials.
    </S.ScreenerNoteText>
  </S.ScreenerNoteBox>
);

export default ScreenerNote;
