import { throttle } from 'lodash';
import { useEffect, useState, RefObject } from 'react';

type Props = {
  elemRelativeRef: RefObject<HTMLDivElement>;
  elemFixedRef: RefObject<HTMLDivElement>;
};

const getOffset = (elem: HTMLDivElement | null) =>
  elem ? elem.getBoundingClientRect().top + window.pageYOffset : 0;

const getElemHeight = (elem: HTMLDivElement | null) =>
  elem ? elem.clientHeight : 0;

const getElementInfo = (
  elemRelative: HTMLDivElement | null,
  elemFixed: HTMLDivElement | null,
) => {
  const elemHeight = getElemHeight(elemRelative ?? elemFixed);
  const relativePos = getOffset(elemRelative) - elemHeight;
  const fixedPos = getOffset(elemFixed) + elemHeight;

  return {
    elemHeight,
    fixedPos,
    relativePos,
  };
};

export const useStickyNav = ({ elemRelativeRef, elemFixedRef }: Props) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  useEffect(() => {
    let navBarPosition = 0;
    const handleScroll = () => {
      const elemRelative = elemRelativeRef.current;
      const elemFixed = elemFixedRef.current;
      const { elemHeight, fixedPos, relativePos } = getElementInfo(
        elemRelative,
        elemFixed,
      );

      if (relativePos > 0) {
        navBarPosition = relativePos;
      }

      const shouldUpdatePosition =
        (elemRelative && window.pageYOffset > relativePos) ||
        (elemFixed && navBarPosition + elemHeight <= fixedPos) ||
        false;

      if (shouldUpdatePosition) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', throttle(handleScroll, 100));
    return () => window.removeEventListener('scroll', handleScroll);
  }, [elemRelativeRef, elemFixedRef]);

  return {
    isVisible,
  };
};

export default useStickyNav;
