import { mediaQueries, size } from '@everlywell/leaves';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  z-index: 11;
  top: 57px;
  left: 0;
  right: 0;
  margin: ${size.md}px;
  ${mediaQueries.forTabletVerticalUp} {
    width: 65%;
    margin: ${size.md}px auto;
  }
  ${mediaQueries.forTabletHorizontalUp} {
    width: 35%;
    margin: ${size.md}px auto;
  }
`;
export { Wrapper };
