import { Button } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import BaseCard, { BaseCardIcon } from 'pages/AccountHub/components/BaseCard';
import React from 'react';
import { Link } from 'react-router-dom';

import PillsIcon from './pills-icon.svg';
export type MedicationsCardProps = {};

/**
 * Medications Card component for account hub
 */
function MedicationsCard(props: MedicationsCardProps) {
  const handleClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
        label: ANALYTICS.LABELS.ACCOUNT_HUB.MEDICATIONS_CARD,
      },
    });
  };

  return (
    <BaseCard
      image={<BaseCardIcon src={PillsIcon} alt="" />}
      content={{
        title: 'My Medications',
        description:
          'Check your medication status and view details about your prescriptions',
      }}
      cta={
        <Button
          component={Link}
          appearance="secondary"
          hasArrow
          onClick={handleClick}
          to="/virtual-care/medications"
        >
          View medication
        </Button>
      }
    />
  );
}

export default MedicationsCard;
