import { Notification as NotificationType } from 'common/utils/types';

import { BaseErrorResponse } from './errors';
import { CompleteStoreOrder } from './types';

export const completeStripeStoreOrder = async (): Promise<CompleteStoreOrder> =>
  /* Waiting on BE update WSE-97 */
  ({
    data: {
      client_secret: 'TEST',
    },
    error: null,
  });

export const handleErrorNotification = (
  error: BaseErrorResponse,
  setNotificationData: Function,
  notificationData: NotificationType,
) => {
  if (error.fatal) throw error;
  setNotificationData(notificationData);
};
