import React, { createContext, FC, useContext } from 'react';

import useHealthProfile from '../hooks/useHealthProfile/index';
import { HealthProfileTypes } from '../hooks/useHealthProfile/types';

type Props = {
  children?: React.ReactNode;
};

const defaultHealthProfileContext = {
  redirection: '',
  setRedirectionURL: () => {},
};

export const HealthProfileContext = createContext<HealthProfileTypes>(
  defaultHealthProfileContext,
);

export const useHealthProfileContext = () => useContext(HealthProfileContext);

export const HealthProfileProvider: FC<Props> = ({ children }) => {
  const value = useHealthProfile();

  return (
    <HealthProfileContext.Provider value={value}>
      {children}
    </HealthProfileContext.Provider>
  );
};
