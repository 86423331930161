import React, { useEffect } from 'react';

import DesktopGraphic from './images/DesktopGraphic.svg';
import MobileGraphic from './images/MobileGraphic.svg';
import * as S from './styles';

export type Props = {
  children: JSX.Element | JSX.Element[];
};

const ConfirmIdentifiersWrapper = (props: Props) => {
  const { children } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.ConfirmIdentifiersWrapper>
      <S.DesktopGraphic src={DesktopGraphic} aria-hidden="true" />
      <S.ContentContainer>{children}</S.ContentContainer>
      <S.MobileGraphic src={MobileGraphic} aria-hidden="true" />
    </S.ConfirmIdentifiersWrapper>
  );
};

export default ConfirmIdentifiersWrapper;
