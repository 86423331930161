import {
  getTaskChecklist,
  UserTaskChecklistData,
  TaskTypes,
} from 'common/apis/taskChecklistApi';
import { logError } from 'common/utils/helpers';
import React from 'react';
import { useQuery } from 'react-query';

import TaskList, { TaskListProps } from './components/TaskList';

// MyChecklist ------

function enhanceTask(task: UserTaskChecklistData) {
  switch (task.type) {
    case TaskTypes.REDEEM_WEIGHT_MANAGEMENT_CREDIT:
      const expirationDate = task.properties.expiration_date;

      const formattedDate = new Date(Date.parse(expirationDate));

      const month = formattedDate.toLocaleString('default', {
        month: 'short',
      });

      const day = formattedDate.getUTCDate();

      const year = formattedDate.getUTCFullYear();

      return {
        ...task,
        properties: {
          ...task.properties,
          expiration_date: `Offer ends ${month} ${day}, ${year}`,
        },
      };

    default:
      return task;
  }
}

const MyChecklist: React.FC = () => {
  let userTasks;

  const { data, isLoading } = useQuery('tasks', getTaskChecklist, {
    onError(error) {
      logError((error as Error).message, {
        errorInfo: 'User Tasks Response',
        component: 'MyChecklist',
        method: 'getTaskChecklist',
      });
    },
  });

  const isDataReady = data?.data?.length && !isLoading;

  if (isDataReady) {
    const { data: taskData } = data;

    userTasks = taskData?.map(enhanceTask);
  }

  const taskListData: TaskListProps = {
    title: 'My Checklist',
    tasks: userTasks && userTasks.length ? userTasks : [],
  };

  return <>{isDataReady ? <TaskList {...taskListData} /> : null}</>;
};

// Export ---------

export default React.memo(MyChecklist);
