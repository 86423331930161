import axios, { InternalAxiosRequestConfig } from 'axios';
import { API_ROOT } from 'common/utils/constants';

// Custom interceptor to add a fresh JWT to each request
const jwtInterceptor = async (
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
  const token = window.localStorage.getItem('jwtToken');
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  }
  return Promise.reject(new Error('No authorization token found'));
};

// Create our axios instance with API_ROOT set
const StoreApi = axios.create({
  baseURL: API_ROOT,
});
// Add default content type to headers
StoreApi.defaults.headers.common['Content-Type'] = 'application/json';
// Add interceptor to instance
StoreApi.interceptors.request.use(jwtInterceptor, (error) => {
  Promise.reject(error);
});

StoreApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 422) {
      return error;
    } else {
      Promise.reject(error);
    }
  },
);

export default StoreApi;
export { StoreApi, jwtInterceptor };
