import { sanitize } from 'common/utils/domHelpers';
import React from 'react';

import * as S from './styles';

export type ItemProps = {
  icon?: string;
  content?: string;
  mobileImage?: string;
  desktopImage?: string;
};

export type ChecklistProps = {
  title: string;
  items: ItemProps[];
};

const NasalSwabCollectionChecklist = (props: ChecklistProps) => {
  const { title, items = [] } = props;

  return (
    <S.Container>
      <S.ListTitle>{title}</S.ListTitle>
      {items.map(({ icon, content, mobileImage, desktopImage }) => {
        const hasImages = desktopImage || mobileImage;
        const isImageBullet = Boolean(hasImages && !icon && !content);
        return (
          <S.ItemContainer>
            {isImageBullet ? (
              <>
                <S.MobileImage src={mobileImage} />
                <S.DesktopImage src={desktopImage} />
              </>
            ) : (
              <S.BulletContainer>
                <S.IconContainer src={icon} />
                <S.Content
                  dangerouslySetInnerHTML={{ __html: sanitize(content || '') }}
                />
              </S.BulletContainer>
            )}
          </S.ItemContainer>
        );
      })}
    </S.Container>
  );
};

export default NasalSwabCollectionChecklist;
