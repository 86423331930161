import { mediaQueries, typography } from '@everlywell/leaves';
import styled from 'styled-components';

import { badgeLabel } from '../styles';

type Props = {
  isMultiNumerical?: boolean;
  showMarkerValue?: boolean;
  isException?: boolean;
};

const MarkerRangeDetailsWrapper = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0;

  ${mediaQueries.forTabletVerticalDown} {
    display: ${(props) => (props.isMultiNumerical ? 'none' : 'flex')};
  }

  ${mediaQueries.forTabletVerticalUp} {
    width: ${(props) => (props.showMarkerValue ? '272px' : '250px')};
  }
`;

const RangeNameFooter = styled.p<Props>`
  text-transform: ${(props) => (props.isException ? '' : 'capitalize')};
  ${badgeLabel};
  font-weight: ${typography.weight.light};
  flex: 2;
  padding-right: 16px;
`;

const LabelValue = styled.p`
  ${badgeLabel};
  font-weight: ${typography.weight.bold};
`;

export { MarkerRangeDetailsWrapper, RangeNameFooter, LabelValue };
