import { colors, mediaQueries, typography } from '@everlywell/leaves';
import styled from 'styled-components';

type Props = {
  labelShift: string;
};

const Canvas = styled.canvas`
  width: 100% !important;
  max-height: 329px;
  flex: 1 1 100%;
`;

const Wrapper = styled.div`
  display: flex;
`;

const GraphWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1056px;
  position: relative;
  margin: 0 auto;
`;

const YLabelWrapper = styled.div<Props>`
  display: none;
  font-size: 16px;
  height: 240px;
  margin-top: 14px;
  color: ${colors.gray4};
  font-weight: ${typography.weight.regular};

  ${mediaQueries.forTabletHorizontalUp} {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    margin-right: ${(props) => props.labelShift};
  }
`;

const YSeverityLabel = styled.span`
  white-space: nowrap;
`;

export { Canvas, Wrapper, GraphWrapper, YLabelWrapper, YSeverityLabel };
