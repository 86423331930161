import {
  Button,
  colors,
  H3,
  size,
  typography,
  mediaQueries,
} from '@everlywell/leaves';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  ${mediaQueries.forTabletVerticalDown} {
    height: 100%;
    justify-content: space-between;
  }
`;

const Body = styled.div``;

const Title = styled(H3)`
  font-weight: 400;
  font-size: 30px;
  margin: 0;
  padding: ${size.xs1}px;
  padding-left: 0;
`;

const Subtitle = styled.p`
  ${typography.bodyTextSmall}
  font-weight: ${typography.weight.book};
  font-size: 18px;
  color: ${colors.gray4};
  white-space: pre-line;
  margin-top: 0;
  letter-spacing: 0.25px;

  ${mediaQueries.forTabletVerticalDown} {
    ${typography.bodyTextSmall}
    font-size: ${size.md}px;
  }
`;

const AddressWrapper = styled.div`
  padding-top: ${size.md}px;
`;

const AddressTitle = styled.p`
  font-size: 18px;
  margin: 0;
  color: ${colors.teal4};
`;

const Address = styled.div`
  ${typography.bodyTextSmall}
  margin-left: ${size.md}px;

  p {
    margin: 0;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: ${size.xl3}px;
  padding: ${size.lg}px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${mediaQueries.forTabletVerticalDown} {
    flex-direction: column-reverse;
    padding: 0;
  }
`;

const EditAddressButton = styled(Button)`
  margin: 0 ${size.xs1}px;
  padding: ${size.sm}px 100px;

  ${mediaQueries.forTabletVerticalDown} {
    padding: ${size.sm}px ${size.lg}px;
  }

  ${mediaQueries.forTabletVerticalDown} {
    margin: ${size.xs1}px 0;
  }
`;

const UseAddressButton = styled(Button)`
  margin: 0 ${size.xs1}px;

  ${mediaQueries.forTabletVerticalDown} {
    margin: ${size.xs1}px 0;
  }
`;

export {
  Title,
  Subtitle,
  AddressWrapper,
  AddressTitle,
  Address,
  EditAddressButton,
  UseAddressButton,
  ButtonWrapper,
  Body,
  Wrapper,
};
