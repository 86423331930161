import { colors } from '@everlywell/leaves';
import {
  CollectionTypeCategory,
  CollectionTypeRedemption,
} from 'common/utils/types';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useSet } from 'react-use';

import fingerPrickBlood from './icons/finger-prick-blood.svg';
import saliva from './icons/saliva.svg';
import stool from './icons/stool.png';
import swab from './icons/swab.svg';
import urine from './icons/urine.svg';
import * as S from './ProductTile.styles';
// Types ----------

// Add more types as needed in the future
export type SectionsType = 'Markers' | 'Symptom';

export type InnerSection = {
  type: SectionsType;
  label: string;
  elements: string[];
};

export interface ProductTileProps {
  collectionMethods: CollectionTypeRedemption[];
  description?: string;
  handleOpen: (productId: number) => void;
  handleSelect: (productId: number, index: number, slug: string) => void;
  index: number;
  innerSections: InnerSection[];
  isAvailableToRedeem: boolean;
  isExpanded: boolean;
  isSelected: boolean;
  productId: number;
  productImageURL: string;
  productName: string;
  roundImage?: boolean;
  shortDescription: string | null;
  slug: string;
}

// Map collection type to icon
const ICONS: Record<CollectionTypeCategory, string> = {
  finger_prick: fingerPrickBlood,
  saliva: saliva,
  urine: urine,
  nasal_swab: swab,
  vaginal_swab: swab,
  stool: stool,
};

// ProductTile ------

const ProductTile: React.FC<ProductTileProps> = (props) => {
  const {
    collectionMethods,
    description,
    handleOpen,
    handleSelect,
    index,
    innerSections,
    isAvailableToRedeem,
    isExpanded,
    isSelected,
    productId,
    productImageURL,
    productName,
    roundImage,
    shortDescription,
    slug,
  } = props;

  const [, { toggle: toggleExpandedIndex, has: isIndexExpanded }] =
    useSet<number>(new Set([]));

  const expandableSections = innerSections.map(
    (section: InnerSection, index: number) => {
      const { type, label, elements } = section;

      const isExpanded = isIndexExpanded(index);
      const isExpandable = elements.length > 3;

      const listElements =
        elements.length > 0
          ? elements.map((element, index: number) => (
              <S.ListElement key={`${type}_element_${index}`}>
                {type === 'Markers' ? (
                  <S.CheckIcon color={colors.teal3} />
                ) : (
                  <S.BulletPoint />
                )}
                {element}
              </S.ListElement>
            ))
          : null;

      return listElements ? (
        <S.Section key={`${type}_${index}`}>
          <S.SectionTitle>{label}:</S.SectionTitle>

          <S.ListWrapper isOpen={isExpanded} role="list">
            {listElements}
          </S.ListWrapper>

          {isExpandable ? (
            <S.SeeMoreButton onClick={() => toggleExpandedIndex(index)}>
              {isExpanded ? 'See less' : 'See more'}
            </S.SeeMoreButton>
          ) : null}
        </S.Section>
      ) : null;
    },
  );

  const collectionMethodsList = collectionMethods.map(
    (method, index: number) => {
      const { name, category } = method;

      const icon = ICONS[category];

      return (
        <S.CollectionMethod key={`${index}_${name}`}>
          {icon ? <S.CollectionIcon src={icon} alt={name} /> : null}
          <S.CollectionMethodLabel hasIcon={!!icon}>
            {name}
          </S.CollectionMethodLabel>
        </S.CollectionMethod>
      );
    },
  );

  const onCardClick = (index: number) => {
    handleSelect(productId, index, slug);
  };

  return (
    <S.Wrapper
      isExpanded={isExpanded}
      isSelected={isSelected}
      key={productId}
      isAvailable={isAvailableToRedeem}
    >
      <S.TopWrapper>
        <S.StaticArea
          onClick={isAvailableToRedeem ? () => onCardClick(index) : undefined}
          data-test="select-product"
        >
          {isAvailableToRedeem ? <S.Radio isSelected={isSelected} /> : null}

          <S.ImageWrapper isRoundImage={Boolean(roundImage)}>
            <S.ImageBackgroundWrapper>
              <S.ImageBackground />
            </S.ImageBackgroundWrapper>

            <S.Image src={productImageURL} alt={productName} />
          </S.ImageWrapper>

          <S.Title>{productName}</S.Title>
        </S.StaticArea>

        <S.ChevronButton
          isExpanded={isExpanded}
          onClick={() => handleOpen(productId)}
          data-test="toggle-tile"
          type="button"
        >
          <S.ChevronIcon color={colors.green4} />
        </S.ChevronButton>
      </S.TopWrapper>

      <div>
        <S.Description>
          <ReactMarkdown>
            {description ? description : shortDescription || ''}
          </ReactMarkdown>
        </S.Description>

        {expandableSections}

        <S.Section key="collection_methods">{collectionMethodsList}</S.Section>
      </div>
    </S.Wrapper>
  );
};

// Export ---------

export default ProductTile;
