import { Localize } from '@everlywell/leaves';
import { render } from '@testing-library/react';
import { TestCollectionMethod } from 'components/KitCollection/model';
import { shallow, ShallowWrapper, mount, ReactWrapper } from 'enzyme';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from 'store';

import { KitRegistrationResponse } from './types';

export const shallowWithTheme = (children: any) => shallow(children);

export const findByTestAttr = (
  component: ShallowWrapper | ReactWrapper,
  val: string,
) => component.find(`[data-test="${val}"]`);

/**
 * @deprecated enzyme has been deprecated and will be removed
 */
export const findByTestAttrWithLocalize = (
  component: ShallowWrapper | ReactWrapper,
  val: string,
  position: number,
) => {
  const target = findByTestAttr(component, val)
    .find(Localize)
    .at(position)
    .props();
  return target;
};

export const findByComponentLocalize = (
  component: ShallowWrapper | ReactWrapper,
  position: number,
) => {
  const target = component.find(Localize).at(position).props();
  return target;
};

export const updatedMountWithTheme = (children: any) => mount(<>{children}</>);

export const dummyCollectionMethod = {
  name: 'earum',
  video_url: 'https://www.randomurl.com',
  collection_type: {
    name: 'nisi',
  },
  collection_device_images: [
    {
      name: 'velit',
      image_url: 'https://dummyimage.com/300x300/8d2ad4/7b1f04.png?text=',
    },
  ],
  collection_instructions: [
    {
      image_url: 'https://dummyimage.com/300x300/6bdb40/d0d1a4.png?text=',
      description:
        'Dolore consequuntur nisi atque officia nemo aperiam optio molestias.',
      alt_text: 'Asperiores id facere beatae ut odit et labore.',
    },
  ],
  collection_tips: [
    {
      image_url: 'https://dummyimage.com/300x300/dd6be1/fa8fae.png?text=',
      description: 'Non facere explicabo provident molestiae.',
    },
  ],
};

export const kitRegistrationResponse: KitRegistrationResponse = {
  id: 2388799,
  state: 'physician',
  collection_types: [TestCollectionMethod.blood],
  collection_methods: [dummyCollectionMethod],
  pre_kit_registration_step: '',
  collection_video_links: ['https://player.vimeo.com/video/368341287'],
  gender_restriction: 'not_relevant',
  partner_type: 'dtc',
  covid: {
    display_symptoms_confirmation: true,
    exposure_options: {
      'not exposed': 'I have not been exposed to COVID-19',
      'area community spread':
        'I live or work in a place where people reside, meet or gather in close proximity (office buildings, schools, healthcare facilities, etc.)',
      'sick contact':
        'I have been in close proximity (within 6ft) to someone who is sick but not diagnosed with COVID-19',
      'known exposure':
        'I have been in close proximity (within 6ft) to someone who has been diagnosed or presumed to have COVID-19',
    },
    symptom_level_options: {
      'no symptoms': 'No symptoms or symptoms not listed',
      mild: 'Fever between 100.4 and 102F, a new or worsening cough, a new or worsening sore throat, flu-like symptoms (chills, runny or stuffy nose, whole body aches, a headache and/or feeling tired), shortness of breath (not limiting your ability to speak) or a new loss of taste or smell',
      severe:
        'Fever over 102F or a high fever lasting more than 48 hours, inability to speak in full sentences or do simple activities without shortness of breath, severe coughing spells or coughing up blood, blue face or lips, severe and constant pain or pressure in your chest, extreme lethargy, dizziness, lightheadedness or being too weak to stand, slurred speech or seizures, being unable to stay at home due to being too sick',
    },
    registration_for_covid_rapid: false,
    needs_sample_collection_date: true,
  },
  enterprise: null,
  product: {
    id: 65,
    name: 'Food Sensitivity Comprehensive Test',
    description: '',
    available_on: '2019-09-01T00:00:00.000Z',
    deleted_at: null,
    slug: 'food-sensitivity-comprehensive-test',
    meta_description:
      'Check your sensitivity to 204 different foods with this convenient, at-home test – which makes it so much easier for you to pinpoint what foods may be best to eliminate.',
    meta_keywords:
      'food intolerance test kit, food sensitivity test, food intolerance testing at home, food sensitivity testing at home, food intolerance test, food intolerance tests, gluten intolerance test, gluten sensitivity test, gluten sensitivity test, igg test',
    tax_category_id: null,
    shipping_category_id: 14,
    created_at: '2019-09-27T21:22:36.578Z',
    updated_at: '2021-02-15T17:22:23.486Z',
    promotionable: true,
    meta_title:
      'At Home Food Sensitivity Comprehensive Test - Easy to Use and Understand - Everlywell',
    discontinue_on: null,
    sequence_cache: 0,
    subscription: false,
    displayable: true,
    position: -1,
    asin: null,
    box_image_url:
      'https://ucarecdn.com/f4eb6a31-b9f9-4269-8c16-4d790407dc63/FSC_TILTED.png',
    icon_image_url: null,
    fulfillment_provider_id: 1,
    lab_balance_weight: null,
    dropship_provider_id: null,
    paypal_product_id: 'PROD-4UL57070XT733381A',
  },
  url: {
    hippa_telehealth_url: '/hipaa-and-telehealth-consent',
    product_consent_url: '/product-consent',
    terms_and_conditions_url: '/terms-of-use/',
  },
  user: {
    id: 1,
    first_name: 'Luke',
    last_name: 'Hendrick',
    email: 'demo@everlywell.com',
    user_address: {
      id: 6922196,
      first_name: 'Luke',
      last_name: 'Hendrick',
      street1: '1331 E 43rd St',
      street2: 'Suite 300',
      city: 'Tulsa',
      state: 'OK',
      country: 'US',
      zipcode: '74105',
      phone: '(918) 619-2724',
    },
  },
  consumer: {
    id: 1,
    dob: '01/01/1991',
    gender: 'male',
    wants_marketing: false,
    age: 30,
    race: 'Asian',
    ethnicity: 'Not Latino Or Hispanic',
    phone_number: '9185551234',
  },
  barcode: {
    id: 3814848,
    dash_format: 'YJB-AAT-9257',
    variant_name: 'Food Sensitivity Comprehensive Test',
    variant_sku: 'food-sensitivity-204-pki',
    group_name: 'lh-multi',
    channel: 'Undefined',
    days_since_processed: 'about 5 hours',
    created_at: 'March 11, 2021 09:14 CST',
    created_by_name: 'Luke Hendrick',
    updated_by_name: 'Luke Hendrick',
    state: 'active',
    registered_on: 'Unregistered',
    user_name: null,
    pwn_order_number: null,
    lab_requisition_url: '/search/YJBAAT9257',
    cancel_reason: 'None given',
    refunded: false,
    lab_name: 'PerkinElmer',
    issues: [],
    auto_register: null,
    enterprise_partner_name: null,
    enterprise_partner_prefixed_display_name: null,
    enterprise_client_name: null,
    enterprise_client_prefixed_display_name: null,
    replacement_order_id: null,
    humana: false,
  },
  previously_registered_kit: false,
  preferences: {
    notification: 0,
  },
  needs_sample_collection_date: false,
  lab_visit: false,
  minimum_age: 18,
};

export const renderPageContainer = (
  PageComponent: React.ComponentType<any>,
  options?: {
    propsOverride?: any;
    storeOverride?: any;
    stateOverride?: any;
  },
) => {
  const initialState = (options && options.stateOverride) || {
    app: { loadingContent: false },
  };
  const store =
    (options && options.storeOverride) || configureStore(initialState);

  const props = (options && options.propsOverride) || {};

  return render(
    <QueryClientProvider client={new QueryClient()}>
      <BrowserRouter>
        <Provider store={store}>
          <PageComponent {...props} />
        </Provider>
      </BrowserRouter>
    </QueryClientProvider>,
  );
};

/**
 * mockSimpleStripeInput
 * is a simple mock implementation to mock stripe input.
 * https://github.com/stripe/react-stripe-js/blob/master/test/mocks.js
 */

export const mockElement = (elementType: string) => {
  const input = window.document.createElement('input');

  return {
    mount: (id: string) => window.document.querySelector(id)!.append(input),
    destroy: jest.fn(),
    on: (event: keyof HTMLElementEventMap, callback: EventListener) => {
      input.addEventListener(event, (e) => {
        const event = {
          ...e,
          elementType: elementType,
          complete: true,
        };
        callback(event);
      });
    },
    update: jest.fn(),
  };
};

export const mockElements = () => {
  const elements = {};
  return {
    create: jest.fn((type) => {
      // @ts-ignore
      elements[type] = mockElement(type);
      // @ts-ignore
      return elements[type];
    }),
    getElement: jest.fn(
      (type) =>
        // @ts-ignore
        elements[type] || null,
    ),
  };
};

export const mockStripe = () => ({
  elements: jest.fn(() => mockElements()),
  createToken: jest.fn(),
  createSource: jest.fn(),
  createPaymentMethod: jest.fn(),
  confirmCardPayment: jest.fn(),
  confirmCardSetup: jest.fn(),
  paymentRequest: jest.fn(),
  registerAppInfo: jest.fn(),
  _registerWrapper: jest.fn(),
});

export const mockFormMethods = {
  clearErrors: jest.fn(),
  control: jest.fn(),
  errors: jest.fn(),
  formState: jest.fn(),
  getValues: jest.fn(),
  handleSubmit: jest.fn(),
  register: jest.fn(),
  reset: jest.fn(),
  setError: jest.fn(),
  setValue: jest.fn(),
  trigger: jest.fn(),
  unregister: jest.fn(),
  useFormMethods: jest.fn(),
  watch: jest.fn(),
};

type Props = {
  children?: React.ReactNode;
};

export const FormWrapper: FC<Props> = ({ children }) => {
  const methods = useForm({ mode: 'onBlur' });
  return <FormProvider {...methods}>{children}</FormProvider>;
};
