import Grid from 'components/Grid';
import React from 'react';
import { useInView } from 'react-intersection-observer';

import * as S from './FloatingCtaFooter.styles';

export type FloatingCtaFooterProps = {
  backButton?: React.ReactNode;
  nextButton?: React.ReactNode;
  className?: string;
};

/**
 * Floating CTA Footer component for Native Scheduling
 */
function FloatingCtaFooter(props: FloatingCtaFooterProps) {
  const { ref, inView } = useInView();
  const { backButton, nextButton } = props;

  return (
    <>
      <S.Container
        data-test="floating-cta-container"
        inView={inView}
        data-in-view={inView}
        className={props?.className}
      >
        <S.InnerContainer>
          <Grid.Container spacing={['md', 'lg']} alignEdges={inView}>
            {backButton && (
              <Grid.Item
                width={['auto']}
                css={{
                  '>button': {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  },
                }}
              >
                {backButton}
              </Grid.Item>
            )}
            {nextButton && (
              <Grid.Item
                width={['auto']}
                css={{
                  '>button': {
                    width: '100%',
                  },
                }}
              >
                {nextButton}
              </Grid.Item>
            )}
          </Grid.Container>
        </S.InnerContainer>
      </S.Container>
      <S.InViewTrigger data-test="intersection-trigger" ref={ref} />
    </>
  );
}

export default FloatingCtaFooter;
