import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 30px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 30px;
  box-sizing: border-box;
  position: relative;
  width: 340px;
  height: 392px;
`;

const Image = styled.img`
  display: flex;
  align-self: flex-end;
  position: absolute;
  width: 100%;
  height: auto;
`;

export { Container, Wrapper, Image };
