import { RadioButton } from '@everlywell/leaves';
import React, { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';

import * as S from './RadioButtonGroup.styles';

export type RadioButtonsGroupData = {
  /** Identifier for this button */
  id: string;
  /** Radio button text */
  mainText: string;
  /** Smaller button text */
  secondaryText: string;
  /** Optional Text shown at the top of the radio button */
  calloutText?: string;
};

export type RadioButtonsGroupProps = {
  /** Name of the radio button group */
  groupName: string;
  buttonData: RadioButtonsGroupData[];
  /** Function to be called when one of the options is clicked */
  onSelect: (id: string) => void;
  /** Id of the selected option by default, nothing is selected if not provided */
  selectedId?: string;
};

export default function RadioButtonGroup({
  groupName,
  buttonData,
  selectedId,
  onSelect,
}: RadioButtonsGroupProps): JSX.Element {
  const [selectedItem, setSelectedItem] = useState(selectedId);

  const ButtonLabel = ({ mainText, secondaryText }: RadioButtonsGroupData) => (
    <S.RadioLabel>
      <S.RadioButtonTextContainer>
        <S.MainText>{mainText}</S.MainText>
        <S.SecondaryText>{secondaryText}</S.SecondaryText>
      </S.RadioButtonTextContainer>
    </S.RadioLabel>
  );

  const handleRadioClick = (id: string) => {
    onSelect(id);
    setSelectedItem(id);
  };

  useEffect(() => {
    setSelectedItem(selectedId);
  }, [selectedId]);

  useEffectOnce(() => {
    if (selectedId) {
      onSelect(selectedId);
    }
  });

  return (
    <S.Container>
      {buttonData.map((buttonData) => {
        const { id, calloutText } = buttonData;
        const isSelected = selectedItem === id;

        return (
          <S.RadioContainer
            key={id}
            onClick={() => handleRadioClick(id)}
            hasCalloutText={!!calloutText}
          >
            {calloutText ? (
              <S.CalloutText className={isSelected ? 'selected' : ''}>
                {calloutText}
              </S.CalloutText>
            ) : null}
            <RadioButton
              id={id}
              value={id}
              name={groupName}
              label={ButtonLabel(buttonData)}
              checked={isSelected}
              onChange={() => handleRadioClick(id)}
              className={isSelected ? 'radio-button selected' : 'radio-button'}
            />
          </S.RadioContainer>
        );
      })}
    </S.Container>
  );
}
