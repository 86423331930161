import { colors, mediaQueries, typography } from '@everlywell/leaves';
import { InnerContent } from 'common/styles/app-styles';
import { Col_12_Span_8 } from 'common/styles/grid-styles';
import Button from 'components/Button';
import styled from 'styled-components';

type WrapperProps = {
  isNewResultsLayout: boolean;
};

const Wrapper = styled(Col_12_Span_8)<WrapperProps>`
  background-color: ${(props: WrapperProps) =>
    props.isNewResultsLayout ? `${colors.gray1}` : `${colors.white}`};
  padding-bottom: 32px;
  margin-bottom: 0;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 0;
  }
`;

const UpdatedInnerContent = styled(InnerContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const UpdatedButton = styled(Button)`
  max-width: fit-content;
  padding: 0 1em;
  font-size: 18px;
  line-height: 2.5;
`;

const Logo = styled.img`
  margin-bottom: 40px;
  height: 28px;
  max-width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    height: 38px;
  }
`;

const Text = styled.div`
  font-weight: ${typography.weight.regular};
  font-size: 18px;
  line-height: 1.58;
  margin-bottom: 31px;
  color: ${colors.gray4};

  ${mediaQueries.forTabletVerticalUp} {
    font-size: 20.3px;
  }
`;

export {
  Wrapper,
  UpdatedInnerContent as InnerContent,
  Logo,
  Text,
  UpdatedButton as Button,
};
