import { Col } from '@everlywell/leaves';
import { COLLECTION_HELP_EMAIL, MY_APP_ROOT } from 'common/utils/constants';
import React from 'react';

import * as S from './styles';

const CollectionHelp = () => (
  <S.Wrapper center="xs" middle="xs">
    <Col xs={10} md lg={5}>
      <S.Title>Need help?</S.Title>

      <S.Subtitle>
        If you’re unsure about your collection, send us an image at{' '}
        <a href={`mailto:${COLLECTION_HELP_EMAIL}`}>{COLLECTION_HELP_EMAIL}</a>{' '}
        and include your shipping address.
      </S.Subtitle>

      <S.Btn appearance="secondary" href={`${MY_APP_ROOT}`}>
        View My Dashboard
      </S.Btn>
    </Col>
  </S.Wrapper>
);

export default CollectionHelp;
