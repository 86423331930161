import { colors } from '@everlywell/leaves';
import styled from 'styled-components';

const Wrapper = styled.button`
  padding: 0;
  position: relative;
  background-color: ${colors.gray2};
  width: 64px;
  height: 32px;
  border-radius: 16px;
  transition: background-color 200ms ease-out;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    width: 28px;
    height: 28px;
    background-color: ${colors.white};
    border-radius: 14px;
    z-index: 3;
    border: 1px solid ${colors.gray2};
    top: 2px;
    left: -1px;
    transition: transform 200ms ease-in-out;
    transform: translateX(1px);
  }

  &[aria-checked='true'] {
    background-color: ${colors.green4};

    &:before {
      border: 1px solid ${colors.white};
      transform: translateX(35px);
    }
  }
`;

export { Wrapper };
