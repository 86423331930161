import { getRequest, putRequest } from 'common/hooks/useApi/request';
import { API_ROOT } from 'common/utils/constants';
import { User } from 'common/utils/types';

export const getUser = (userId: string) =>
  getRequest<User>(`${API_ROOT}/v1/users/${userId}`, false);

export const updateUser = (data: {
  userId: string;
  user: Partial<
    Omit<User, 'consumer_attributes'> & {
      consumer_attributes: Partial<User['consumer_attributes']>;
    }
  >;
}) =>
  putRequest<User>(`${API_ROOT}/v1/users/${data.userId}`, data.user, false, {
    rejectOnError: true,
  });
