import React from 'react';

import * as S from './styles';

type Props = {
  children: React.ReactNode;
  overrideLayout?: boolean;
};

function PageHeader(props: Props) {
  const { children, ...rest } = props;

  return (
    <S.Wrapper {...rest}>
      <S.Content {...rest}>{children}</S.Content>
    </S.Wrapper>
  );
}

export default PageHeader;
