import { colors } from '@everlywell/leaves';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const Content = styled.div`
  a,
  p {
    a {
      padding: 0;
    }

    color: ${colors.green4};
    display: block;
    padding: 16px 29px;
    text-align: left;
    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
  }
`;

export const PopoverWrap = styled.div`
  position: absolute;
  right: 48px;
  top: 29px;
  width: 169px;
  z-index: 9999;
`;

export const Link = styled.a`
  color: ${colors.green4};
  text-decoration: none;

  &:hover {
    color: ${colors.green3};
  }
`;
