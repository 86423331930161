import { H3, mediaQueries, Row, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const Title = styled(H3)`
  font-weight: ${typography.weight.regular};
  margin: 0;
  margin-bottom: 50px;
`;

const Wrapper = styled(Row)`
  min-height: 620px;
  padding: 60px 0;
  box-sizing: border-box;
  width: 100%;
  margin: 0;

  ${mediaQueries.forPhoneOnly} {
    min-height: 0;
    padding: 30px 0;
  }
`;

export { Title, Wrapper };
