import {
  atLabStatus,
  labVisitStatus,
  notAtLabStatus,
  rapidAntigenStatus,
  shippingStatus,
  StatusMap,
} from './statuses.constants';

const isALabStatus = (status: string) => !shippingStatus.includes(status);

export const getLabels = (
  isLabVisit: boolean,
  status: string,
  isRapidAntigen: boolean,
): StatusMap => {
  if (isLabVisit) {
    return labVisitStatus;
  }

  if (isRapidAntigen) {
    return rapidAntigenStatus;
  }

  if (isALabStatus(status)) {
    return atLabStatus;
  }

  return notAtLabStatus;
};

export const getActiveIndex = (status: string, statuses: StatusMap) =>
  Object.keys(statuses).findIndex(
    (statusText) => statusText.toLowerCase() === status,
  );

export const getStatusTexts = (statuses: StatusMap) => Object.values(statuses);
