import { colors, mediaQueries, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const Heading = styled.legend`
  font-weight: ${typography.weight.light};
  font-size: 18px;
  line-height: 32px;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${colors.gray4};
  margin: 0 0 20px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 28px;
  }
`;

const Group = styled.div`
  margin-left: 60px;
  display: flex;
`;

export { Heading, Group };
