import { Button } from '@everlywell/leaves';
import { getKitResults } from 'common/apis/kitResultApis';
import { useCommonT2TAnalytics } from 'common/hooks/useCommonT2TAnalytics';
import analytics from 'common/utils/analytics';
import { STATUSES } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { KitResult } from 'common/utils/types';
import Grid from 'components/Grid/Grid';
import LoadingError from 'components/LoadingError';
import { StethoscopeIcon } from 'components/telehealth/Enterprise/Icons';
import NeedHelpCard from 'components/telehealth/Enterprise/NeedHelpCard';
import { HELP_PHONE_NUMBER } from 'components/telehealth/Enterprise/NeedHelpCard/utils/constants';
import { format, parseISO } from 'date-fns';
import FloatingCtaFooter from 'pages/VirtualCare/NativeScheduling/components/FloatingCtaFooter/FloatingCtaFooter';
import { useNativeSchedulingContext } from 'pages/VirtualCare/NativeScheduling/context/NativeSchedulingContext';
import { actions } from 'pages/VirtualCare/NativeScheduling/state/scheduling-flow.state';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useSet } from 'react-use';

import AppointmentReasonPageSkeleton from './AppointmentReasonPage.skeleton';
import * as S from './AppointmentReasonPage.styles';

export type AppointmentReasonPageProps = {};

type FormValues = {
  reason: string | null;
  kit_results: [];
};

export const TEXTAREA_REQUIRED_ERROR_MESSAGE =
  'Please enter your other health concern';
export const REASON_REQUIRED_ERROR_MESSAGE =
  'Please select a reason for your visit.';

/**
 * Page that allows the user to cancel their appointment
 */
function AppointmentReasonPage(props: AppointmentReasonPageProps) {
  const { commonT2TAnalyticsData } = useCommonT2TAnalytics();

  const { dispatch, getNextStep } = useNativeSchedulingContext();

  const navigate = useNavigate();

  const {
    data: response,
    isError,
    isLoading,
    refetch,
  } = useQuery(['kit-results', 0], () => getKitResults(0));

  const filterKitsResultsByStatus = (results: KitResult[], statuses: string) =>
    results.filter(({ status }) => statuses.includes(status));

  const kitResults = filterKitsResultsByStatus(
    response?.data ?? [],
    STATUSES.RESULTS_APPROVED,
  ).map((item) => ({
    text: `${item.product_name}, resulted on ${format(
      parseISO(item.results_approved_at || Date()),
      'MMMM dd, y',
    )}`,
    id: item.id,
  }));

  const { register, handleSubmit, errors } = useForm<FormValues>({
    defaultValues: {
      reason: null,
      kit_results: [],
    },
  });

  const [selectedReasons, { toggle: toggleValue, has: hasKitResult }] = useSet<
    number | string
  >(new Set([]));

  const onChangeHandler = (value: number | string) => {
    setIsSubmitting(false);
    toggleValue(value);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  /**
   * Handle saving the user's input and navigating to the next step.
   */
  const handleSaveInput = (values: FormValues) => {
    if (selectedReasons.size < 1) {
      setIsSubmitting(true);
      return;
    }

    const kit_results = Array.from(selectedReasons)
      .filter((kitId) => kitId !== 'reason')
      .map((kitId) => ({
        id: kitId,
      }));

    dispatch(
      actions.setAppointmentReason({
        reason: values.reason,
        kit_results: kit_results,
      }),
    );

    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'Continue CTA',
        page: ANALYTICS.PAGES.EHS_T2T.APPOINTMENT_REASON_PAGE,
        ...commonT2TAnalyticsData,
      },
    });

    const nextStep = getNextStep();
    navigate(nextStep ?? '/dashboard');
  };

  useEffect(() => {
    if (kitResults) {
      analytics.track({
        event: ANALYTICS.EVENTS.VIEWED_PAGE,
        data: {
          page: ANALYTICS.PAGES.EHS_T2T.APPOINTMENT_REASON_PAGE,
          ...commonT2TAnalyticsData,
        },
      });
    }
  }, [commonT2TAnalyticsData, kitResults]);

  if (isError) {
    return (
      <S.Container>
        <LoadingError handleRetry={refetch} />
      </S.Container>
    );
  }

  return (
    <S.Container>
      <S.GridItem width={[1]}>
        <StethoscopeIcon />
        <S.Heading>
          What would you like to discuss with your healthcare provider?{' '}
        </S.Heading>
      </S.GridItem>

      <Grid.Item width={[1]}>
        <S.Form onSubmit={handleSubmit(handleSaveInput)}>
          {!isLoading ? (
            kitResults.length > 0 &&
            kitResults.map((kitResult) => (
              <S.Checkbox
                key={kitResult.id}
                label={kitResult.text}
                onChange={() => onChangeHandler(kitResult.id)}
                name={`kit-result-option-${kitResult.id}`}
                checked={hasKitResult(kitResult.id)}
                border={false}
                bodyTextStyle
              />
            ))
          ) : (
            <AppointmentReasonPageSkeleton />
          )}

          <Grid.Item width={[1]}>
            <S.Checkbox
              key={'reason'}
              label={'Other Health Concern'}
              name={'other-health-concern'}
              onChange={() => onChangeHandler('reason')}
              checked={hasKitResult('reason')}
              border={false}
              bodyTextStyle
            />

            {hasKitResult('reason') && (
              <S.StyledTextArea
                ref={
                  hasKitResult('reason')
                    ? register({
                        required: TEXTAREA_REQUIRED_ERROR_MESSAGE,
                      })
                    : register()
                }
                id="reason"
                maxLength={255}
                name="reason"
                error={errors.reason?.message}
                hasError={Boolean(errors.reason?.message)}
                placeholder={`Tell us what brings you in, such as "I'd like to discuss my overall health"`}
              />
            )}
          </Grid.Item>

          {isSubmitting && selectedReasons.size < 1 && (
            <Grid.Item width={[1]}>
              <S.ErrorText>{REASON_REQUIRED_ERROR_MESSAGE}</S.ErrorText>
            </Grid.Item>
          )}

          <Grid.Item width={[1]} css={{ marginTop: '10px' }}>
            <NeedHelpCard
              onClick={() => {
                analytics.track({
                  event: ANALYTICS.EVENTS.CLICKED_BUTTON,
                  data: {
                    label: 'Call Care Team',
                    page: ANALYTICS.PAGES.EHS_T2T.APPOINTMENT_REASON_PAGE,
                    ...commonT2TAnalyticsData,
                  },
                });
              }}
              header="Need help with non-medical questions?"
              phoneNumber={HELP_PHONE_NUMBER}
            />

            <FloatingCtaFooter
              backButton={
                <Button
                  type="button"
                  appearance="secondary"
                  onClick={() => {
                    analytics.track({
                      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
                      data: {
                        label: 'Back CTA',
                        page: ANALYTICS.PAGES.EHS_T2T.APPOINTMENT_REASON_PAGE,
                        ...commonT2TAnalyticsData,
                      },
                    });
                    navigate(-1);
                  }}
                >
                  Back
                </Button>
              }
              nextButton={<Button type="submit">Continue</Button>}
            />
          </Grid.Item>
        </S.Form>
      </Grid.Item>
    </S.Container>
  );
}

export default AppointmentReasonPage;
