import {
  SkeletonLoader,
  SkeletonShape,
  SkeletonUnit,
  VisuallyHidden,
  size,
} from '@everlywell/leaves';
import Grid from 'components/Grid';
import React from 'react';

const AppointmentReasonPageSkeleton = () => (
  <Grid.Container spacing={['lg']}>
    <VisuallyHidden>Loading appointment reasons...</VisuallyHidden>
    <Grid.Item width={[1]}>
      <SkeletonLoader
        shape={SkeletonShape.Rectangle}
        height={{ value: Number(size.xl5), unit: SkeletonUnit.Pixel }}
        width={{ value: Number(size.xl6), unit: SkeletonUnit.Percentage }}
      />
    </Grid.Item>
  </Grid.Container>
);

export default AppointmentReasonPageSkeleton;
