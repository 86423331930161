/**
 * open Extole referral modal
 */

/* eslint-disable */
export function openExtoleModal() {
  (function (c, b, f, k, a) {
    c[b] = c[b] || {};
    for (c[b].q = c[b].q || []; a < k.length; ) f(k[a++], c[b]);
  })(
    window,
    'extole',
    function (c, b) {
      b[c] =
        b[c] ||
        function () {
          b.q.push([c, arguments]);
        };
    },
    ['createZone'],
    0,
  );
  window.extole.createZone({
    name: 'new_results_nav_bar',
    element_id: 'extole_zone_new_results_nav_bar',
  });
}

export function openExtoleWeightCarePlus({
  marketingProgram,
  email,
  firstName,
  lastName,
}) {
  (function (c, b, f, k, a) {
    c[b] = c[b] || {};
    for (c[b].q = c[b].q || []; a < k.length; ) f(k[a++], c[b]);
  })(
    window,
    'extole',
    function (c, b) {
      b[c] =
        b[c] ||
        function () {
          b.q.push([c, arguments]);
        };
    },
    ['createZone'],
    0,
  );

  extole.createZone({
    name: marketingProgram?.tag_name,
    element_id: 'extole_zone_virtual_care_share_experience',
    email: email,
    first_name: firstName,
    last_name: lastName,
    partnerUserId: marketingProgram.partner_user_id,
    data: {
      email: email,
      first_name: firstName,
      last_name: lastName,
      partnerUserId: marketingProgram.partner_user_id,
      labels: marketingProgram?.label,
    },
  });
}
