import { breakpoints } from '@everlywell/leaves';
import { useIsMobileEmbedded } from 'common/hooks/useIsMobileEmbedded';
import useMediaQuery from 'common/hooks/useMediaQuery';
import useProgramSlug from 'common/hooks/useProgramSlug';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { telehealthUrl } from 'common/utils/helpers';
import { PROGRAM_TYPE, FormModalType } from 'common/utils/types';
import Grid from 'components/Grid';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { useFormModalContext } from '../../providers';
import HtmlParser from '../ReadOnlyField/components/HtmlParser';
import { Image } from './components/Image';
import { ModalButtons } from './components/ModalButtons';
import * as S from './FormModal.styles';

type ModalProps = {
  title?: string;
  html: string;
  openModal: boolean;
  type: FormModalType;
};

const WC_ASYNC_PROGRAM_SLUG = PROGRAM_TYPE['weight-care-ongoing-async'];
const WC_SYNC_PROGRAM_SLUG = PROGRAM_TYPE['weight-care-ongoing'];

export const FormModal = (props: ModalProps) => {
  const { title, html, openModal, type } = props;
  const { programSlug } = useProgramSlug();
  const { isMobileEmbedded } = useIsMobileEmbedded();

  const program = programSlug ?? '';
  const { formName } = useFormModalContext();

  let redirectPath = undefined;
  let modalType = type;

  const isMobile = useMediaQuery(`(max-width: ${breakpoints.forPhoneOnly}px)`);

  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        formName: formName,
        modalLabel: title,
        program,
      },
    });
  }, [formName, program, title]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'formModalComponent',
        programName: program,
      },
    });
  }, [program]);

  // Forcing the CTA to be the enrolment of the sync program
  if (program === WC_ASYNC_PROGRAM_SLUG) {
    const url = telehealthUrl({
      program: WC_SYNC_PROGRAM_SLUG,
    });

    redirectPath = `${url.pathname}/${url.search}`;

    modalType = 'redirect';
  }

  return (
    <S.Modal
      open={openModal}
      data-test="formbuilder-modal"
      forceFullScreen={isMobile}
      openHandler={() => {}}
    >
      <S.Container spacing={['lg']}>
        <Grid.Item width={[1]} css={S.centerModalStyles}>
          <Image type={type} />
        </Grid.Item>
        {title && (
          <Grid.Item width={[1]}>
            <S.H4>{title}</S.H4>
          </Grid.Item>
        )}
        <Grid.Item width={[1]}>
          <HtmlParser html={html} />
        </Grid.Item>
        {!isMobileEmbedded && (
          <Grid.Item width={[1]} css={S.centerModalStyles}>
            <ModalButtons type={modalType} navigatePath={redirectPath} />
          </Grid.Item>
        )}
      </S.Container>
    </S.Modal>
  );
};
