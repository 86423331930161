import {
  Button,
  colors,
  H5,
  mediaQueries,
  size,
  TertiaryLink,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

type Props = {
  isHalfScreen?: boolean;
};

export const CardHeaderLink = styled(TertiaryLink)`
  text-decoration: none;
  margin-bottom: ${size.sm}px;
`;

export const BaseCard = styled.div`
  padding: ${size.lg}px ${size.md}px;
  background-color: ${colors.white};
  box-shadow: 0 7px 30px -10px rgba(220, 228, 229);
  border-radius: 8px;

  ${mediaQueries.forTabletVerticalUp} {
    padding: ${size.xl1}px;
  }
`;

export const Container = styled.div`
  display: flex;
  min-width: 100%;
  align-items: center;
  justify-content: center;
  padding: ${size.md}px 0 0 ${size.md}px;
  box-shadow: 0 7px 30px -10px rgba(220, 228, 229);
  border-radius: 8px;

  ${mediaQueries.forTabletVerticalUp} {
    justify-content: start;
    padding: ${size.lg}px 0 0 ${size.lg}px;
  }

  ${mediaQueries.forDesktopUp} {
    padding: ${size.xl1}px 0 0 ${size.xl1}px;
  }
`;

export const InnerContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  ${mediaQueries.forTabletVerticalUp} {
    width: 80%;
    flex-direction: column;
  }

  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: ${(props) => (props.isHalfScreen ? 'column' : 'row')};
  }
`;
export const ImageContainer = styled.div`
  overflow: hidden;
  display: flex;
  width: auto;
  justify-content: center;
  padding: 0 ${size.md}px ${size.lg}px 0;

  ${mediaQueries.forTabletVerticalUp} {
    width: 20%;
    padding: 0 ${size.lg}px ${size.lg}px 0;
    align-self: baseline;
  }

  ${mediaQueries.forDesktopUp} {
    width: auto;
    padding: 0 ${size.xl1}px ${size.xl1}px 0;
  }
`;

export const ContentContainer = styled.div<Props>`
  color: ${colors.gray4};
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 0 ${size.md}px ${size.lg}px 0;

  ${mediaQueries.forTabletVerticalUp} {
    width: auto;
    flex: 1;
    justify-content: start;
    align-items: start;
    text-align: left;
    flex-direction: column;
    padding: 0 ${size.sm}px ${size.xs1}px 0;
  }

  ${mediaQueries.forDesktopUp} {
    width: auto;
    flex: 1;
    padding: ${(props) =>
      props.isHalfScreen
        ? `0 ${size.sm}px ${size.xs1}px 0`
        : `0 ${size.xl1}px ${size.xl1}px 0`};
  }
`;

export const CTAContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  padding: 0 ${size.md}px ${size.md}px 0;

  ${mediaQueries.forTabletVerticalUp} {
    width: 37.5%;
    padding: 0 ${size.lg}px ${size.lg}px 0;
  }

  ${mediaQueries.forDesktopUp} {
    width: 25%;
    padding: 0 ${size.xl1}px ${size.xl1}px 0;
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  ${mediaQueries.forTabletVerticalUp} {
    text-align: left;
  }
  ${mediaQueries.forDesktopUp} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const CardHeader = styled(H5)`
  ${typography.buttonText};
  color: ${colors.teal4};
  text-align: center;
  margin: 0 0 ${size.xs1}px 0;
  ${mediaQueries.forTabletVerticalUp} {
    ${typography.h5Text};
    text-align: left;
  }
`;

export const Text = styled.p`
  ${typography.bodyTextSmall};
  color: ${colors.gray5};
  text-align: center;
  margin: 0 0 ${size.md}px;
  ${mediaQueries.forTabletVerticalUp} {
    text-align: left;
    color: ${colors.gray4};
  }
`;

export const CardButton = styled(Button)`
  margin-bottom: 20px;
  width: 100%;
  ${mediaQueries.forTabletVerticalUp} {
    min-width: 210px;
    align-self: flex-start;
  }
`;

export const CardIcon = styled.img`
  width: 72px;
  margin-top: ${size.lg}px;
  align-self: center;

  ${mediaQueries.forTabletVerticalUp} {
    margin: 0 ${size.lg}px 0 0;
    align-self: flex-start;
  }
`;

export const GreyCardHeading = styled.h2`
  ${typography.buttonText};
  color: ${colors.gray4};
  padding: ${size.xs1}px 0;
  margin: 0;

  ${mediaQueries.forTabletVerticalUp} {
    padding-top: 0;
    padding-bottom: ${size.md};
  }
`;
