import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import React from 'react';

import * as S from './AppStoreSection.styles';
import AppStore from './assets/AppStore.png';

export type AppStoreSectionProps = {
  title: string;
  description: string;
  starRatings: number;
  image: {
    src: string;
    alt: string;
  };
};

const handleClick = () => {
  analytics.track({
    event: ANALYTICS.EVENTS.CLICKED_LINK,
    data: {
      label: ANALYTICS.LABELS.WEIGHT_CARE.DASHBOARD.APP_STORE_SECTION,
      category: ANALYTICS.CATEGORIES.DASHBOARD,
    },
  });
};

/**
 * This section advertises and links to the mobile App store
 */
function AppStoreSection({
  title,
  description,
  starRatings,
  image,
}: AppStoreSectionProps) {
  return (
    <S.OuterContainer>
      <a
        href="https://apps.apple.com/app/apple-store/id1614888856?pt=124169076&ct=campaign%206&mt=8"
        target="_blank"
        rel="noreferrer"
        onClick={() => handleClick()}
      >
        <S.InnerContainer>
          <S.ImageContainer>
            <S.Image src={image.src} alt={image.alt}></S.Image>
          </S.ImageContainer>
          <S.CopyContainer>
            <S.CopyInnerContainer>
              <S.Title>{title}</S.Title>
              <S.Description>{description}</S.Description>
              <S.StarRatings
                rating={starRatings}
                numOfReviews={starRatings}
                showNumOfReviews={true}
                countText="stars"
              />
            </S.CopyInnerContainer>
          </S.CopyContainer>
          <S.AppStoreButtonContainer>
            <img
              src={AppStore}
              alt="Download on the App Store"
              width={'150'}
              height={'45'}
            />
          </S.AppStoreButtonContainer>
        </S.InnerContainer>
      </a>
    </S.OuterContainer>
  );
}

export default AppStoreSection;
