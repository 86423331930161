import { Col, Row } from '@everlywell/leaves';
import { ADDRESS_FORM as DATA_TEST } from 'common/utils/constants/dataTest';
import { DTC_STATES } from 'common/utils/constants/states';
import { userStateIdToName } from 'common/utils/helpers';
import { ShippingAddress } from 'common/utils/types';
import { Form, FormValuesType, VALIDATION_RULES } from 'components/Forms';
import { BaseDropdown } from 'components/KitRegistration/styles';
import get from 'lodash/get';
import React from 'react';

import * as S from './styles';

type DropdownOptionType = {
  value: string;
  id: string;
};

const dtcStates: DropdownOptionType[] = DTC_STATES.map(
  (state) =>
    ({
      value: state[1],
      id: state[0],
    } as DropdownOptionType),
);

type Props = {
  isSaving: boolean;
  shippingAddress?: ShippingAddress;
  onSubmit: (formValues: FormValuesType) => void;
  errors?: { [key: string]: string };
};

function ShippingAddressForm(props: Props) {
  const { shippingAddress, onSubmit, isSaving, errors: initialErrors } = props;

  return (
    <>
      <S.Title>New kit ships to</S.Title>
      <Form
        onSubmit={onSubmit}
        validations={{
          firstName: [
            {
              rule: VALIDATION_RULES.IS_REQUIRED,
              error: 'Please enter your first name',
            },
          ],
          lastName: [
            {
              rule: VALIDATION_RULES.IS_REQUIRED,
              error: 'Please enter your last name',
            },
          ],
          address1: [
            {
              rule: VALIDATION_RULES.IS_REQUIRED,
              error: 'Please enter your address',
            },
          ],
          city: [
            {
              rule: VALIDATION_RULES.IS_REQUIRED,
              error: 'Please enter your city',
            },
          ],
          state: [
            {
              rule: VALIDATION_RULES.IS_REQUIRED,
              error: 'Please enter your state',
            },
          ],
          zipCode: [
            {
              rule: VALIDATION_RULES.IS_REQUIRED,
              error: 'Please enter your zip code',
            },
          ],
        }}
        initialValues={shippingAddress as FormValuesType}
        initialErrors={initialErrors}
      >
        {({ values, errors, onFieldChange }) => {
          const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
            onFieldChange({
              name: e.currentTarget.name,
              value: e.currentTarget.value,
            });

          const stateValue = () => {
            const state = values.state || get(values.state, '');
            const isStateName = Number.isNaN(Number(String(state)));

            if (isStateName) {
              return state;
            }

            return userStateIdToName(state as number);
          };

          const valueForField = (fieldName: string): any =>
            values[fieldName] || get(values[fieldName], '');

          return (
            <>
              <Row>
                <Col xs={12} md={6}>
                  <S.StyledInput
                    id="firstName"
                    name="firstName"
                    onChange={handleChange}
                    label="First Name"
                    value={valueForField('firstName')}
                    error={errors.firstName}
                    data-test={DATA_TEST.FIRST_NAME_FIELD}
                    placeholder="Fill in your first name"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <S.StyledInput
                    id="lastName"
                    name="lastName"
                    onChange={handleChange}
                    label="Last Name"
                    value={valueForField('lastName')}
                    error={errors.lastName}
                    data-test={DATA_TEST.LAST_NAME_FIELD}
                    placeholder="Fill in your last name"
                  />
                </Col>
              </Row>
              <S.StyledInput
                id="address1"
                name="address1"
                onChange={handleChange}
                label="Address 1"
                value={valueForField('address1')}
                error={errors.address1}
                data-test={DATA_TEST.ADDRESS_1_FIELD}
                placeholder="Street address, P.O. Box"
              />
              <S.StyledInput
                id="address2"
                name="address2"
                onChange={handleChange}
                label="Apt / Suite/ Unit (Optional)"
                value={valueForField('address2')}
                error={errors.address2}
                data-test={DATA_TEST.ADDRESS_2_FIELD}
                placeholder="Apt, Suite, Building, etc."
              />
              <S.StyledInput
                id="city"
                name="city"
                onChange={handleChange}
                label="City"
                value={valueForField('city')}
                error={errors.city}
                data-test={DATA_TEST.CITY_FIELD}
                placeholder="Fill in city"
              />
              <Row>
                <S.StateCol xs={12} md={6}>
                  <S.LabelContainer>
                    <S.Label>State</S.Label>
                  </S.LabelContainer>
                  <BaseDropdown
                    id="state"
                    hideLabel
                    onChange={handleChange}
                    items={dtcStates}
                    name="state"
                    label="State"
                    value={stateValue() as string}
                  />
                </S.StateCol>
                <Col xs={12} md={6}>
                  <S.StyledInput
                    id="zipCode"
                    name="zipCode"
                    onChange={handleChange}
                    label="Zipcode"
                    value={valueForField('zipCode')}
                    error={errors.zipCode}
                    data-test={DATA_TEST.ZIP_CODE_FIELD}
                    placeholder="00000"
                  />
                </Col>
              </Row>
              <S.Submit
                type="submit"
                appearance="primary"
                disabled={isSaving}
                data-test={DATA_TEST.SUBMIT_BUTTON}
              >
                {isSaving ? <S.Spinner /> : 'Replace Kit'}
              </S.Submit>
            </>
          );
        }}
      </Form>
    </>
  );
}

export default ShippingAddressForm;
