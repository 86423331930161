import { FOOD_SENSITIVITY_PERSONALIZATION as DATA_TEST } from 'common/utils/constants/dataTest';
import {
  renderWithProviders,
  screen,
} from 'common/utils/reactTestingLibraryHelpers';
import React from 'react';

import FoodSensitivityHealthProfileTable from '../index';

const sampleProps = {
  responses: ['Food Allergy', 'Lactose Intolerant', 'Celiac Disease'],
};

const renderComponent = (props?: any) =>
  renderWithProviders(
    <FoodSensitivityHealthProfileTable {...sampleProps} {...props} />,
  );

describe('FoodSensitivityHealthProfileTable', () => {
  it('should render the desktop table', () => {
    renderComponent();
    expect(
      screen.getByTestId(`${DATA_TEST.CONTAINER}-desktop-table`),
    ).toBeInTheDocument();
  });

  it('renders all responses', () => {
    renderComponent();
    const colgroupElements = screen.getAllByTestId(
      `${DATA_TEST.COLGROUP}-desktop`,
    );
    expect(colgroupElements.length).toEqual(4);
  });
});
