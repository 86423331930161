import { Col } from '@everlywell/leaves';
import { useIsMobileEmbedded } from 'common/hooks/useIsMobileEmbedded';
import { useStickyNav } from 'common/hooks/useStickyNav';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import React, { useRef } from 'react';

import * as S from './styles';

type Props = {
  sectionsNames: string[];
  sectionSelected?: string;
};

const NavItems = (props: Props) => {
  const { sectionsNames, sectionSelected } = props;
  const { isMobileEmbedded } = useIsMobileEmbedded();

  //Prevent full page refresh for iOS app and scroll to clicked section
  const handleiOSClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    sectionName: string,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const sectionElem = window.document.getElementById(sectionName);
    const elemTop = sectionElem?.offsetTop;

    if (!isNaN(Number(elemTop))) {
      window.scrollTo(0, Number(elemTop));
    }
  };

  const handleLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    sectionName: string,
  ) => {
    if (isMobileEmbedded) {
      handleiOSClick(e, sectionName);
    }

    const trackingData = {
      label: `${sectionName} nav`,
    };
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: trackingData,
    });
  };

  return (
    <Col lg={12} md={12} xs={12}>
      <S.BarContentContainer sectionsCount={sectionsNames.length}>
        {sectionsNames.map((section) => (
          <S.LinkToSection
            href={`#${section}`}
            key={section}
            isSelected={section === sectionSelected}
            onClick={(e) => handleLinkClick(e, section)}
          >
            {section}
          </S.LinkToSection>
        ))}
      </S.BarContentContainer>
    </Col>
  );
};

function SectionsNavBar(props: Props) {
  const elemRelativeRef = useRef<HTMLDivElement>(null);
  const elemFixedRef = useRef<HTMLDivElement>(null);
  const { isVisible } = useStickyNav({ elemRelativeRef, elemFixedRef });
  const { isMobileEmbedded } = useIsMobileEmbedded();

  return (
    <>
      {isVisible ? (
        <S.NavBarRelativeWrapper
          id="navbarwrapper"
          isMobileWebview={isMobileEmbedded}
          ref={elemRelativeRef}
        >
          <NavItems {...props} />
        </S.NavBarRelativeWrapper>
      ) : (
        <S.NavBarFixedWrapper
          id="navbarwrapper"
          isMobileWebview={isMobileEmbedded}
          ref={elemFixedRef}
        >
          <NavItems {...props} />
        </S.NavBarFixedWrapper>
      )}
    </>
  );
}

export default SectionsNavBar;
