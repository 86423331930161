/* stylelint-disable property-no-vendor-prefix */
import { colors, typography } from '@everlywell/leaves';
import { baseFont } from 'common/styles/app-styles';
import { hexToRGB } from 'common/utils/helpers';
import styled from 'styled-components';

import errorChevron from './images/error-chevron.svg';
import chevron from './images/select-chevron.svg';

const Wrapper = styled.div`
  width: 100%;
`;

const Label = styled.label`
  position: absolute;
  left: -999999999px;
  color: ${colors.gray4};
`;

const Select = styled.select`
  /* prettier-ignore */
  background-image: url("${chevron}");
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) center;
  font-family: ${baseFont};
  font-weight: ${typography.weight.regular};
  color: ${colors.gray4};
  line-height: 24px;
  text-indent: 16px;
  width: 100%;
  font-size: 16px;
  background-color: ${colors.white};
  box-shadow: 4px 4px 15px -5px rgba(0, 0, 0, 0.1);
  border: solid 1px ${colors.green2};
  border-radius: 0;
  height: 50px;
  appearance: none;

  &[data-is-empty='true'] {
    color: ${colors.gray4};
    font-style: italic;
  }

  &[data-has-error='true'] {
    /* prettier-ignore */
    background: url("${errorChevron}");
    background-color: rgba(${hexToRGB(colors.red2)}, 0.6);
    background-position: calc(100% - 16px) center;
    background-repeat: no-repeat;
    border-bottom: 2px solid ${hexToRGB(colors.red2)};
    color: ${colors.gray4};
    font-style: normal;
  }

  &:-ms-expand {
    display: none;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000; /* remove double outlines in firefox */
  }
`;

export { Wrapper, Select, Label };
