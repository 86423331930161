import { Col_10 as Col10 } from 'common/styles/grid-styles';
import { SEVERITY_VALUE, SCROLL_TO_RESULTS } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import {
  isEnterpriseCustomer,
  isFaqOneAvailableForConsumers,
} from 'common/utils/helpers';
import { KitResult, MarkerWithResult } from 'common/utils/types';
import Callout from 'components/Callout';
import TooltipAccordion from 'components/TooltipAccordion';
import DescriptiveMarkerCardContainer from 'containers/DescriptiveMarkerCardContainer';
import React from 'react';

/**
 * Checks if all the markers have normal results
 * @param markers
 * @returns
 */
const hasNormalResults = (markers: MarkerWithResult[]) =>
  markers.every((marker) => {
    const { marker_result: markerResult, severities } = marker;
    const { severity_index: severityIndex } = markerResult;

    return severities[severityIndex] === SEVERITY_VALUE.NOT_DETECTED;
  });

export type DescriptiveResultDetailProps = {
  markers: MarkerWithResult[];
  kitResult?: KitResult;
};

function DescriptiveResultDetail(props: DescriptiveResultDetailProps) {
  const { kitResult, markers } = props;

  const {
    FAQ_TOOLTIP_1_HEADER,
    FAQ_TOOLTIP_1_DESCRIPTION,
    FAQ_TOOLTIP_1_HEADER_NORMAL,
    FAQ_TOOLTIP_1_DESCRIPTION_NORMAL,
    FAQ_TOOLTIP_3_PREVIEW,
    FAQ_TOOLTIP_3_DESCRIPTION,
  } = kitResult?.test?.content ?? {};

  const faqOneAvailable = isFaqOneAvailableForConsumers({
    FAQ_TOOLTIP_1_DESCRIPTION,
    FAQ_TOOLTIP_1_HEADER,
  });

  /**
   * Tooltip to be shown only when the test result is normal
   */
  const faqOneNormalAvailable =
    Boolean(FAQ_TOOLTIP_1_HEADER_NORMAL) &&
    Boolean(FAQ_TOOLTIP_1_DESCRIPTION_NORMAL) &&
    !isEnterpriseCustomer();

  const shouldShowFAQOneNormalTooltip =
    faqOneNormalAvailable && hasNormalResults(markers);

  const faqThreeAvailable = Boolean(FAQ_TOOLTIP_3_DESCRIPTION);

  return (
    <Col10 data-test="descriptive-result-detail">
      <div id={SCROLL_TO_RESULTS}>
        {shouldShowFAQOneNormalTooltip && (
          <Callout
            title={FAQ_TOOLTIP_1_HEADER_NORMAL}
            description={FAQ_TOOLTIP_1_DESCRIPTION_NORMAL}
            analyticsData={{
              label: ANALYTICS.LABELS.FAQ_TOOLTIP_1_TOP,
              category: ANALYTICS.CATEGORIES.FAQ_TOOLTIPS,
            }}
            productName={kitResult?.test?.name}
          />
        )}
        {!shouldShowFAQOneNormalTooltip && faqOneAvailable && (
          <Callout
            title={FAQ_TOOLTIP_1_HEADER}
            description={FAQ_TOOLTIP_1_DESCRIPTION}
            analyticsData={{
              label: ANALYTICS.LABELS.FAQ_TOOLTIP_1_TOP,
              category: ANALYTICS.CATEGORIES.FAQ_TOOLTIPS,
            }}
            productName={kitResult?.test?.name}
          />
        )}
        {markers.map((marker: MarkerWithResult) => {
          const { marker_result: markerResult, severities } = marker;
          const { severity_index: severityIndex } = markerResult;
          return (
            <DescriptiveMarkerCardContainer
              key={marker.id}
              marker={marker}
              contentToken={marker.content_token}
              outOfRange={
                severities[severityIndex] !== SEVERITY_VALUE.NOT_DETECTED
              }
            />
          );
        })}
        {faqThreeAvailable && (
          <TooltipAccordion
            preview={FAQ_TOOLTIP_3_PREVIEW}
            description={FAQ_TOOLTIP_3_DESCRIPTION}
          />
        )}
      </div>
    </Col10>
  );
}

export default DescriptiveResultDetail;
