import {
  size,
  Button as LeavesButton,
  Container as LeavesContainer,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled(LeavesContainer)`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  max-width: 800px;
  padding: ${size.xl3}px ${size.lg}px ${size.xl5}px;
`;

export const Button = styled(LeavesButton)`
  width: 100%;
`;

export const ErrorText = styled.div`
  ${typography.errorText}
`;

export const RequiredText = styled.div`
  ${typography.captionText}
`;
