import { physicianContact } from 'common/utils/schema';
import { PhysicianContact } from 'common/utils/types';
import { normalize } from 'normalizr';

const SET_PHYSICIAN_CONTACT = 'SET_PHYSICIAN_CONTACT';

export function setPhysicianContact(data: PhysicianContact) {
  return {
    type: SET_PHYSICIAN_CONTACT,
    payload: normalize(data, physicianContact),
  };
}
