import { Autocomplete, AutocompleteProps } from '@everlywell/leaves';
import {
  getInsurancePlans,
  TelehealthInsurancePlan,
} from 'common/apis/telehealthApis';
import useProgramSlug from 'common/hooks/useProgramSlug';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useDebounce } from 'react-use';

export type CarrierFieldProps = {
  suggestions: TelehealthInsurancePlan[];
  error?: string;
  onSelect: (item?: TelehealthInsurancePlan | null) => void;
  onBlur: () => void;
};

export const CHARACTERS_THRESHOLD = 2;
export const TOOLTIP_TEXT = `We do not accept Medicare Part B or Medicaid. However, we do accept Medicare Advantage`;
export const HELP_TEXT = `Note: ${TOOLTIP_TEXT}`;

const ITEM_KEY: keyof TelehealthInsurancePlan = 'payer_name';

/**
 * List all the insurance plans based on its value.
 */
function CarrierField(props: CarrierFieldProps) {
  const [value, setValue] = useState('');
  const [debouncedValue, setDebouncedValue] = React.useState('');
  const { programSlug } = useProgramSlug();

  useDebounce(
    () => {
      setDebouncedValue(value);
    },
    300,
    [value],
  );

  const { data: response } = useQuery(
    ['insurance-typeahead', debouncedValue],
    () =>
      getInsurancePlans({
        keywords: debouncedValue,
        program: String(programSlug),
      }),
    {
      enabled: value.length >= CHARACTERS_THRESHOLD,
      keepPreviousData: true,
    },
  );

  const suggestions: TelehealthInsurancePlan[] =
    value.length < CHARACTERS_THRESHOLD
      ? props.suggestions
      : response?.data ?? [];

  const handleInputValueChange: AutocompleteProps<TelehealthInsurancePlan>['onInputValueChange'] =
    (changes) => {
      const { inputValue } = changes;
      setValue(inputValue ?? '');
    };

  const handleSelectedItemChange: AutocompleteProps<TelehealthInsurancePlan>['onSelectedItemChange'] =
    (changes) => {
      const item = changes.selectedItem;

      props.onSelect(item);
    };

  const itemProps = {
    'data-notranslate': 'true',
  } as React.HTMLAttributes<HTMLLIElement>;

  return (
    <Autocomplete
      label="Insurance carrier name"
      suggestions={suggestions}
      onInputValueChange={handleInputValueChange}
      onSelectedItemChange={handleSelectedItemChange}
      searchKey={ITEM_KEY}
      error={props.error}
      placeholder="Example: Blue Cross Blue Shield"
      onBlur={props.onBlur}
      getListItemProps={({ item }) =>
        item.payer_name.toLowerCase().includes('medicaid')
          ? { ...itemProps, disabled: true }
          : itemProps
      }
      helperText={HELP_TEXT}
      tooltip={TOOLTIP_TEXT}
      tooltipProps={{ position: 'bottom' }}
    />
  );
}

export default CarrierField;
