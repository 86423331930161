import { Col, Row } from '@everlywell/leaves';
import { useHealthProfileContext } from 'common/providers/HealthProfileProvider';
import analytics from 'common/utils/analytics';
import { MY_APP_ROOT } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { HEALTH_PROFILE_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';
import { CollectionMethod } from 'common/utils/types';
import { TestCollectionMethod } from 'components/KitCollection/model';
import React from 'react';
import { Link } from 'react-router-dom';

import * as S from '../styles';

interface KitRegistrationConfirmationProps {
  collectionTypes: TestCollectionMethod[];
  collection_methods: CollectionMethod[];
  hasCollectionMethods: boolean;
  sku: string;
}

const KitRegistrationConfirmation = ({
  collectionTypes,
  hasCollectionMethods,
  collection_methods,
  sku,
}: KitRegistrationConfirmationProps) => {
  const { redirection } = useHealthProfileContext();

  return (
    <Row data-test={`${DATA_TEST.CONTAINER}-kit-registration`}>
      <Col>
        <S.Header>Your health profile is complete</S.Header>
      </Col>

      {hasCollectionMethods ? (
        <>
          <Col>
            <S.Subtitle>The last step is to collect your sample</S.Subtitle>
          </Col>
          <Col>
            <S.ButtonsContainer>
              <Link
                to={'/collection'}
                state={{
                  methods: collectionTypes,
                  collection_methods: collection_methods,
                  sku: sku,
                }}
              >
                <S.StyledButton
                  appearance="primary"
                  data-test={`${DATA_TEST.BUTTON}-sample-now`}
                  onClick={() => {
                    analytics.track({
                      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
                      data: {
                        label: ANALYTICS.LABELS.COLLECT_SAMPLE_NOW,
                      },
                    });
                  }}
                >
                  Collect sample now
                </S.StyledButton>
              </Link>
              <S.StyledButton
                appearance={'secondary'}
                href={redirection || MY_APP_ROOT}
                data-test={`${DATA_TEST.BUTTON}-sample-later`}
                onClick={() => {
                  analytics.track({
                    event: ANALYTICS.EVENTS.CLICKED_BUTTON,
                    data: {
                      label: ANALYTICS.LABELS.COLLECT_SAMPLE_LATER,
                    },
                  });
                }}
              >
                Collect sample later
              </S.StyledButton>
            </S.ButtonsContainer>
          </Col>
        </>
      ) : (
        <Col>
          <S.Subtitle>
            You are done with registration. <br /> Track your test’s status in
            your dashboard
          </S.Subtitle>
          <S.DashboardButton
            appearance="primary"
            href={MY_APP_ROOT}
            data-test={`${DATA_TEST.BUTTON}-dashboard`}
          >
            Go to Dashboard
          </S.DashboardButton>
        </Col>
      )}
    </Row>
  );
};

export default KitRegistrationConfirmation;
