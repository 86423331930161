import { T2TAnalyticsType } from 'common/hooks/useCommonT2TAnalytics';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';

export const whenUserViewsPage = (
  params: T2TAnalyticsType | { program: string },
) =>
  analytics.track({
    event: ANALYTICS.EVENTS.VIEWED_PAGE,
    data: {
      label: ANALYTICS.PAGES.VIRTUAL_CARE.APPOINTMENT_SCHEDULING.REVIEW_BOOKING,
      ...params,
    },
  });

export const whenUserClicksConfirm = (
  params: T2TAnalyticsType | { program: string },
) =>
  analytics.track({
    event: ANALYTICS.EVENTS.CLICKED_BUTTON,
    data: {
      label:
        ANALYTICS.LABELS.VIRTUAL_CARE.APPOINTMENT_SCHEDULING
          .REVIEW_BOOKING_CONTINUE,
      ...params,
    },
  });

export const whenUserClicksBack = (
  params: T2TAnalyticsType | { program: string },
) =>
  analytics.track({
    event: ANALYTICS.EVENTS.CLICKED_BUTTON,
    data: {
      label:
        ANALYTICS.LABELS.VIRTUAL_CARE.APPOINTMENT_SCHEDULING
          .REVIEW_BOOKING_BACK,
      ...params,
    },
  });
