import { SkeletonLoader, SkeletonUnit } from '@everlywell/leaves';
import Grid from 'components/Grid';
import VisuallyHidden from 'components/VisuallyHidden';
import React from 'react';

import { Container } from './FormBuilder.styles';

const FormBuilderSkeleton = () => (
  <Container>
    <Grid.Container spacing={['lg']}>
      <VisuallyHidden>loading...</VisuallyHidden>
      <Grid.Item width={[1]}>
        <SkeletonLoader
          width={{
            value: 250,
            unit: SkeletonUnit.Pixel,
          }}
          height={{
            value: 50,
            unit: SkeletonUnit.Pixel,
          }}
        />
      </Grid.Item>
      <Grid.Item width={[1]}>
        <SkeletonLoader
          width={{
            value: 120,
            unit: SkeletonUnit.Pixel,
          }}
          height={{
            value: 20,
            unit: SkeletonUnit.Pixel,
          }}
        />
        <SkeletonLoader
          width={{
            value: 100,
            unit: SkeletonUnit.Percentage,
          }}
          height={{
            value: 50,
            unit: SkeletonUnit.Pixel,
          }}
        />
      </Grid.Item>
      <Grid.Item width={[1]}>
        <SkeletonLoader
          width={{
            value: 120,
            unit: SkeletonUnit.Pixel,
          }}
          height={{
            value: 20,
            unit: SkeletonUnit.Pixel,
          }}
        />
        <SkeletonLoader
          width={{
            value: 100,
            unit: SkeletonUnit.Percentage,
          }}
          height={{
            value: 50,
            unit: SkeletonUnit.Pixel,
          }}
        />
      </Grid.Item>
      <Grid.Item width={[1]}>
        <SkeletonLoader
          width={{
            value: 120,
            unit: SkeletonUnit.Pixel,
          }}
          height={{
            value: 20,
            unit: SkeletonUnit.Pixel,
          }}
        />
        <SkeletonLoader
          width={{
            value: 100,
            unit: SkeletonUnit.Percentage,
          }}
          height={{
            value: 50,
            unit: SkeletonUnit.Pixel,
          }}
        />
      </Grid.Item>
      <Grid.Item width={[1]}>
        <SkeletonLoader
          width={{
            value: 120,
            unit: SkeletonUnit.Pixel,
          }}
          height={{
            value: 20,
            unit: SkeletonUnit.Pixel,
          }}
        />
        <SkeletonLoader
          width={{
            value: 100,
            unit: SkeletonUnit.Percentage,
          }}
          height={{
            value: 50,
            unit: SkeletonUnit.Pixel,
          }}
        />
      </Grid.Item>

      <Grid.Item width={[1, 1 / 3]}>
        <SkeletonLoader
          width={{
            value: 120,
            unit: SkeletonUnit.Pixel,
          }}
          height={{
            value: 20,
            unit: SkeletonUnit.Pixel,
          }}
        />
        <SkeletonLoader
          width={{
            value: 100,
            unit: SkeletonUnit.Percentage,
          }}
          height={{
            value: 50,
            unit: SkeletonUnit.Pixel,
          }}
        />
      </Grid.Item>
      <Grid.Item width={[1 / 2, 1 / 3]}>
        <SkeletonLoader
          width={{
            value: 120,
            unit: SkeletonUnit.Pixel,
          }}
          height={{
            value: 20,
            unit: SkeletonUnit.Pixel,
          }}
        />
        <SkeletonLoader
          width={{
            value: 100,
            unit: SkeletonUnit.Percentage,
          }}
          height={{
            value: 50,
            unit: SkeletonUnit.Pixel,
          }}
        />
      </Grid.Item>
      <Grid.Item width={[1 / 2, 1 / 3]}>
        <SkeletonLoader
          width={{
            value: 120,
            unit: SkeletonUnit.Pixel,
          }}
          height={{
            value: 20,
            unit: SkeletonUnit.Pixel,
          }}
        />
        <SkeletonLoader
          width={{
            value: 100,
            unit: SkeletonUnit.Percentage,
          }}
          height={{
            value: 50,
            unit: SkeletonUnit.Pixel,
          }}
        />
      </Grid.Item>

      <Grid.Item width={[1]}>
        <SkeletonLoader
          width={{
            value: 100,
            unit: SkeletonUnit.Percentage,
          }}
          height={{
            value: 50,
            unit: SkeletonUnit.Pixel,
          }}
        />
      </Grid.Item>
    </Grid.Container>
  </Container>
);

export default FormBuilderSkeleton;
