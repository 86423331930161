import {
  MARKER_TYPES,
  NOT_APPLICABLE,
  NOTAPPLICABLE,
} from 'common/utils/constants';
import {
  ImmutableReduxState,
  MarkerWithResult,
  MarkerResult,
} from 'common/utils/types';
import pluralize from 'pluralize';
import { makeSelectContent } from 'store/selectors';

type TailoredContent = {
  tailoredHeader: string;
  tailoredOverview: string;
};

const TAILORED_CONTENT_KEYS = {
  tailoredOverview: 'TAILORED_OVERVIEW',
};

function getTailoredOverview(
  markerResult: MarkerResult,
  state: ImmutableReduxState,
) {
  const { content_token: contentToken } = markerResult;

  const tailoredOverview = makeSelectContent(
    contentToken,
    TAILORED_CONTENT_KEYS,
  )(state);

  return tailoredOverview && tailoredOverview.tailoredOverview;
}

function getNumericalTailoredContent(
  markerWithResult: MarkerWithResult,
  state: ImmutableReduxState,
) {
  const { marker_result: markerResult, name, descriptors } = markerWithResult;
  const {
    severity_index: severityIndex,
    content_token: contentToken,
    value,
  } = markerResult;

  const descriptor = descriptors[severityIndex];

  let tailoredHeader = '';

  // if value is DNR, do not show descriptor in header
  const uniqueDescriptors = ['dnr', 'qns', 'nsa', 'ncal'];
  if (
    typeof value === 'string' &&
    uniqueDescriptors.includes(value.toLowerCase())
  ) {
    tailoredHeader = `Details on Your ${name} Results`;
    // if descriptor starts with the word may, do not include 'is' or 'are' in the header.
  } else if (descriptor.toLowerCase().indexOf('may ') === 0) {
    tailoredHeader = `Your ${name} ${descriptor}`;
  } else {
    tailoredHeader = `Your ${name} ${
      pluralize.isPlural(name) ? 'are' : 'is'
    } ${descriptor}`;
  }

  const tailoredOverview = makeSelectContent(
    contentToken,
    TAILORED_CONTENT_KEYS,
  )(state);

  return { tailoredHeader, ...tailoredOverview };
}

function getExtremeties(
  markersWithResults: MarkerWithResult[],
  lowSeverityIndex: number | undefined,
  highSeverityIndex: number | undefined,
): { [key: string]: MarkerResult } {
  const NoNAs = markersWithResults.filter(
    (item) =>
      item.marker_result.value.toString().toLowerCase() !== NOTAPPLICABLE &&
      item.marker_result.value.toString().toLowerCase() !== NOT_APPLICABLE,
  );

  return NoNAs.reduce((acc: any, markerWithResult) => {
    const { marker_result: markerResult } = markerWithResult;
    if (markerResult.severity_index === highSeverityIndex) {
      if (!acc.highestHigh) {
        acc.highestHigh = markerResult;
      }
      if (
        Number(markerResult.value) - markerResult.boundaries[1] >
        Number(acc.highestHigh.value) - acc.highestHigh.boundaries[1]
      ) {
        acc.highestHigh = markerResult;
      }
    }
    if (markerResult.severity_index === lowSeverityIndex) {
      if (!acc.lowestLow || markerResult.value.toString().includes('<')) {
        acc.lowestLow = markerResult;
      }
      if (
        markerResult.boundaries[0] - Number(markerResult.value) >
        acc.lowestLow.boundaries[0] - Number(acc.lowestLow.value)
      ) {
        acc.lowestLow = markerResult;
      }
    }
    return acc;
  }, {});
}

function getMultiNumericalTailoredContent(
  markersWithResults: MarkerWithResult[],
  state: ImmutableReduxState,
) {
  const markerWithResult = markersWithResults[0];
  const { severities, descriptors, name } = markerWithResult;
  let tailoredOverview;
  let tailoredHeader = '';

  const tailoredHeaderMarker = `Your ${name} ${
    pluralize.isPlural(name) ? 'are' : 'is'
  }`;
  // normal serverity will always be 1
  const normalSeverityIndex = severities.indexOf(1);
  // get array of unique severity indices for all marker results

  const NoNAs = markersWithResults.filter(
    (item) =>
      item.marker_result.value.toString().toLowerCase() !== NOTAPPLICABLE &&
      item.marker_result.value.toString().toLowerCase() !== NOT_APPLICABLE,
  );

  const uniqueSeverityIndices = Array.from(
    new Set(NoNAs.map((item) => item.marker_result.severity_index)),
  );

  const notApplicables = markersWithResults.find(
    (item) =>
      typeof item.marker_result.value === 'string' &&
      (NOT_APPLICABLE.toUpperCase() ===
        item.marker_result.value.toUpperCase() ||
        NOTAPPLICABLE.toUpperCase() === item.marker_result.value.toUpperCase()),
  );

  let normaltailoredOverview;
  let notApplicablesTailoredOverview;

  if (
    uniqueSeverityIndices.length === 1 &&
    uniqueSeverityIndices[0] === normalSeverityIndex &&
    notApplicables === undefined
  ) {
    tailoredHeader = `${tailoredHeaderMarker} ${descriptors[normalSeverityIndex]}`;
    // if all are normal - use any normal result's content_token
    tailoredOverview = getTailoredOverview(NoNAs[0].marker_result, state);
  } else if (
    // if Normal and Not Applicable - use any normal and any NA result's content_token
    uniqueSeverityIndices.length === 1 &&
    uniqueSeverityIndices[0] === normalSeverityIndex &&
    notApplicables !== undefined
  ) {
    const tailorHeaderMarker = `Your ${name} ${
      pluralize.isPlural(name) ? 'are' : 'has'
    }`;

    tailoredHeader = `${tailorHeaderMarker} ${descriptors[normalSeverityIndex]}  And Not Applicable Values`;

    normaltailoredOverview = getTailoredOverview(NoNAs[0].marker_result, state);

    notApplicablesTailoredOverview = getTailoredOverview(
      notApplicables.marker_result,
      state,
    );

    if (normaltailoredOverview !== undefined) {
      tailoredOverview = `${normaltailoredOverview} <br> ${notApplicablesTailoredOverview}`;
    } else {
      tailoredOverview = `${notApplicablesTailoredOverview}`;
    }
  } else {
    let highSeverityIndex: number | undefined;
    let lowSeverityIndex: number | undefined;
    let lowTailoredOverview;
    let highTailoredOverview;
    let notApplicableTailoredOvervieww;

    // set the correct high & low indices
    uniqueSeverityIndices.forEach((severityIndex) => {
      if (severityIndex > normalSeverityIndex) {
        highSeverityIndex = severityIndex;
        // checking explicitly to avoid = normal cases
      } else if (severityIndex < normalSeverityIndex) {
        lowSeverityIndex = severityIndex;
      }
    });

    const { highestHigh, lowestLow } = getExtremeties(
      markersWithResults,
      lowSeverityIndex,
      highSeverityIndex,
    );

    // check if any marker is high
    if (highSeverityIndex !== undefined) {
      tailoredHeader = `${tailoredHeaderMarker} ${descriptors[highSeverityIndex]}`;
      tailoredOverview = getTailoredOverview(highestHigh, state);
      highTailoredOverview = tailoredOverview;
    }

    // check if any is low
    if (lowSeverityIndex !== undefined) {
      tailoredHeader = `${tailoredHeaderMarker} ${descriptors[lowSeverityIndex]}`;
      tailoredOverview = getTailoredOverview(lowestLow, state);
      lowTailoredOverview = tailoredOverview;
    }

    // check if any are NA or N/A
    if (notApplicables !== undefined) {
      tailoredHeader = `${tailoredHeaderMarker} and Not Applicable`;
      notApplicableTailoredOvervieww = getTailoredOverview(
        notApplicables.marker_result,
        state,
      );

      makeSelectContent(
        notApplicables.marker_result.content_token,
        TAILORED_CONTENT_KEYS,
      )(state);
    }

    // check if there is both high and low
    if (highSeverityIndex !== undefined && lowSeverityIndex !== undefined) {
      tailoredHeader = `Your ${name} Has ${descriptors[highSeverityIndex]} and ${descriptors[lowSeverityIndex]} Values`;

      if (highTailoredOverview && lowTailoredOverview) {
        tailoredOverview = `${highTailoredOverview} <br> ${lowTailoredOverview}`;
      }
    }

    // check if there is both NA and high
    if (highSeverityIndex !== undefined && notApplicables !== undefined) {
      tailoredHeader = `Your ${name} Has ${descriptors[highSeverityIndex]} and Not Applicable Values`;

      if (highTailoredOverview && notApplicableTailoredOvervieww) {
        tailoredOverview = `${highTailoredOverview} <br> ${notApplicableTailoredOvervieww}`;
      }
    }

    // check if there is both NA and low
    if (notApplicables !== undefined && lowSeverityIndex !== undefined) {
      // Yes descriptors(lowSeverityIndex) is twice the same and one gets swapped.
      // We have to use the low index here which should resolve to 'Low' (twice, one for each result), later one of these gets replaced by Not Applicable
      // check here: app/components/MarkerDetails/index.ts Insane in the membrane
      tailoredHeader = `Your ${name} Has ${descriptors[lowSeverityIndex]} and Not Applicable Values`;

      if (notApplicableTailoredOvervieww && lowTailoredOverview) {
        tailoredOverview = `${lowTailoredOverview} <br> ${notApplicableTailoredOvervieww}`;
      }
    }

    // check if there is both NA, low, and high
    if (
      highSeverityIndex !== undefined &&
      lowSeverityIndex !== undefined &&
      notApplicables !== undefined
    ) {
      // Yes descriptors(lowSeverityIndex) is twice the same and one gets swapped.
      // We have to use the low index here which should resolve to 'Low' (twice, one for each result), later one of these gets replaced by Not Applicable
      // check here: app/components/MarkerDetails/index.ts Insane in the membrane
      tailoredHeader = `Your ${name} Has ${descriptors[highSeverityIndex]}, ${descriptors[lowSeverityIndex]}, and Not Applicable Values`;

      if (highTailoredOverview && lowTailoredOverview) {
        tailoredOverview = `${highTailoredOverview} <br> ${lowTailoredOverview} <br> ${notApplicableTailoredOvervieww}`;
      }
    }
  }
  return { tailoredHeader, tailoredOverview };
}

export const makeTailoredContent = (
  state: ImmutableReduxState,
  markersWithResults: MarkerWithResult[],
): TailoredContent => {
  const markerWithResult = markersWithResults[0];
  const { type } = markerWithResult;

  if (type === MARKER_TYPES.NUMERICAL) {
    return getNumericalTailoredContent(markerWithResult, state);
  }
  return getMultiNumericalTailoredContent(markersWithResults, state);
};
