import { Row, Col } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { CONFIRM_IDENTIFIERS_FORM as DATA_TEST } from 'common/utils/constants/dataTest';
import { RUM_ACTIONS } from 'common/utils/constants/rumActions';
import { isDateValid } from 'common/utils/helpers';
import { handleChange } from 'common/utils/registrationHelpers';
import {
  InputError,
  KitIssue,
  KitRegistrationInput,
  KitRegistrationUser,
} from 'common/utils/types';
import useTimeTracker from 'common/utils/useTimeTracker';
import { subDays, subYears } from 'date-fns';
import React, { useState } from 'react';

import * as S from './styles';

type ConfirmIdentifiersProps = {
  user: KitRegistrationUser;
  kitIssue: KitIssue;
  invalidFields: string[];
  setInvalidFields: React.Dispatch<React.SetStateAction<string[]>>;
  minimum_age: number;
  dateOfCollection: KitRegistrationInput<string>;
  onSubmit: (
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    dateOfCollection: string,
  ) => void;
  errors?: string;
  setErrors?: React.Dispatch<React.SetStateAction<string>>;
  stabilityTimeInDays: number;
  isSubmitting: boolean;
};

const ConfirmIdentifiersForm = (props: ConfirmIdentifiersProps) => {
  const {
    user: { firstName, lastName, dateOfBirth },
    invalidFields,
    setInvalidFields,
    stabilityTimeInDays,
    isSubmitting,
    kitIssue: { identifiers_to_confirm, minimum_age },
    dateOfCollection,
  } = props;
  const dateFormatString = 'YYYY-MM-DD';
  const { onSubmit, errors } = props;
  const fireAction = useTimeTracker(RUM_ACTIONS.SUBMIT_CONFIRM_IDENTIFIERS);
  // TODO: Discuss this with marketing and analytics.
  // const analyticsAttributes = {
  //   'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
  //   'data-analytics-category': ANALYTICS.CATEGORIES.CONFIRM_IDENTIFIERS,
  //   'data-analytics-label': ANALYTICS.LABELS.CONFIRM_IDENTIFIERS,
  // };
  const [dateError, setDateError] = useState<InputError>('');
  const [dateOfCollectionError, setDateOfCollectionError] =
    useState<InputError>('');

  const isSubmitDisabled = () =>
    !identifiers_to_confirm ||
    isSubmitting ||
    (identifiers_to_confirm?.includes('first_name') &&
      firstName.value.length < 1) ||
    (identifiers_to_confirm?.includes('last_name') &&
      lastName.value.length < 1) ||
    (identifiers_to_confirm?.includes('dob') && dateOfBirth.value.length < 1) ||
    // @ts-ignore
    dateError?.length > 0 ||
    (identifiers_to_confirm?.includes('doc') &&
      dateOfCollection.value.length < 1) ||
    // @ts-ignore
    dateOfCollectionError?.length > 0;

  const handleDateOfBirthInput = (value: string) => {
    const maximum_age = 120;
    const year = new Date().getFullYear() - maximum_age;
    const error: InputError = isDateValid(
      value,
      new Date(year, 0, 1),
      subYears(new Date(), minimum_age),
      `You must be between ${minimum_age} and ${maximum_age} to take this test`,
    );
    setDateError(error);
    handleChange(dateOfBirth, value, invalidFields, setInvalidFields);
  };

  const handleDateOfCollectionInput = (value: string) => {
    const error: InputError = isDateValid(
      value,
      subDays(new Date(), stabilityTimeInDays),
      subDays(new Date(), 1),
      `Collection date must be between yesterday and ${stabilityTimeInDays} days ago`,
    );
    setDateOfCollectionError(error);
    handleChange(dateOfCollection, value, invalidFields, setInvalidFields);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const trackingData = {
      label: 'Confirm Identifiers Submitted',
      category: ANALYTICS.CATEGORIES.CONFIRM_IDENTIFIERS,
    };
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: trackingData,
    });
    fireAction();
    onSubmit(
      firstName.value,
      lastName.value,
      dateOfBirth.value,
      dateOfCollection.value,
    );
  };

  const collectionDateMessage =
    ' If your collection date is beyond the the time frame for a stable sample, a new kit will be sent to you free of charge.';

  return (
    <>
      <Row start="xs" middle="xs">
        <Col>
          <S.TitleWrapper>
            <S.Title>Confirmation required</S.Title>
          </S.TitleWrapper>
          <S.SubtitleWrapper>
            <S.Subtitle>
              Please confirm the information below so your kit can be processed.
              {identifiers_to_confirm?.includes('doc') && collectionDateMessage}
            </S.Subtitle>
          </S.SubtitleWrapper>
        </Col>
        <Col>
          <form onSubmit={handleSubmit} data-test={DATA_TEST.FORM}>
            {identifiers_to_confirm?.includes('first_name') && (
              <S.StyledInput
                label="First Name"
                id={firstName.id}
                value={firstName.value}
                name={firstName.id}
                inputClassName="inspectletIgnore"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(
                    firstName,
                    e.target.value,
                    invalidFields,
                    setInvalidFields,
                  );
                }}
                error={
                  invalidFields.includes(firstName.id)
                    ? firstName.error
                    : undefined
                }
                data-test={`${DATA_TEST.INPUT}-${firstName.id}`}
              />
            )}
            {identifiers_to_confirm?.includes('last_name') && (
              <S.StyledInput
                label="Last Name"
                id={lastName.id}
                value={lastName.value}
                name={lastName.id}
                inputClassName="inspectletIgnore"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(
                    lastName,
                    e.target.value,
                    invalidFields,
                    setInvalidFields,
                  );
                }}
                error={
                  invalidFields.includes(lastName.id)
                    ? lastName.error
                    : undefined
                }
                data-test={`${DATA_TEST.INPUT}-${lastName.id}`}
              />
            )}
            {identifiers_to_confirm?.includes('dob') && (
              <S.StyledInput
                label={`Date Of Birth (${dateFormatString})`}
                id={dateOfBirth.id}
                value={dateOfBirth.value}
                name={dateOfBirth.id}
                className={dateOfBirth.id}
                inputClassName="inspectletIgnore"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleDateOfBirthInput(e.target.value);
                }}
                error={dateError}
                type="text"
                data-test={`${DATA_TEST.INPUT}-${dateOfBirth.id}`}
                placeholder={dateFormatString}
              />
            )}
            {identifiers_to_confirm?.includes('doc') && (
              <S.StyledInput
                label={`Date Of Sample Collection (${dateFormatString})`}
                id={dateOfCollection.id}
                value={dateOfCollection.value}
                name={dateOfCollection.id}
                className={dateOfCollection.id}
                inputClassName="inspectletIgnore"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleDateOfCollectionInput(e.target.value);
                }}
                error={dateOfCollectionError}
                type="text"
                data-test={`${DATA_TEST.INPUT}-${dateOfCollection.id}`}
                placeholder={dateFormatString}
              />
            )}
            <S.Error>{errors}</S.Error>
            <S.Submit
              isDisabled={isSubmitDisabled()}
              appearance="primary"
              data-test={DATA_TEST.SUBMIT}
            >
              Confirm
            </S.Submit>
            <S.Disclaimer>
              Have additional questions? Contact Customer Experience, and we
              will help you through any questions or issues.
            </S.Disclaimer>
          </form>
        </Col>
      </Row>
    </>
  );
};

export default ConfirmIdentifiersForm;
