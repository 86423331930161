import useProgramSlug from 'common/hooks/useProgramSlug';
import Grid from 'components/Grid/Grid';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Input } from '../../utils/styles';
import { FormBuilderField } from '../../utils/types';
import BmiCalculation from './components/BmiCalculation/BmiCalculation';

export type WeightFieldProps = FormBuilderField;

const WC_PROGRAMS_SLUGS = [
  'weight-management',
  'weight-management-full',
  'weight-care-ongoing',
];

function WeightField(props: WeightFieldProps) {
  const { watch, register, errors, clearErrors, setError } = useFormContext();

  const { programSlug } = useProgramSlug();

  const isWeightCareProgram = WC_PROGRAMS_SLUGS.includes(programSlug ?? '');

  const heightFieldModule = props.modules.find(
    ({ mod_type }) => mod_type === 'Height (in.)',
  );
  const weightFieldModule = props.modules.find(
    ({ mod_type }) => mod_type === 'Weight',
  );

  return (
    <React.Fragment>
      <Grid.Item width={[1, 1 / 2]}>
        <Input
          id={props.id}
          name={props.id}
          label={props.label ?? ''}
          type="number"
          error={errors[props.id]?.message}
          required={props.required}
          ref={register({
            required: props.required ? 'Please enter your weight' : false,
          })}
        />
      </Grid.Item>
      {/*
      TODO: remove this customization Use BMIField component: https://everlyhealth.atlassian.net/browse/IN2-565
      Show BMI calculation when both height and weight fields are present
      */}
      {isWeightCareProgram && heightFieldModule && weightFieldModule ? (
        <Grid.Item width={[1]}>
          <BmiCalculation
            height={{
              feet: parseInt(watch(`${heightFieldModule.id}-tmp`)?.feet),
              inches: parseInt(watch(`${heightFieldModule.id}-tmp`)?.inches),
            }}
            weight={parseInt(watch(weightFieldModule.id))}
            errors={errors}
            register={register}
            clearErrors={clearErrors}
            setError={setError}
          />
        </Grid.Item>
      ) : null}
    </React.Fragment>
  );
}

export default WeightField;
