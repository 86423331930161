import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import Grid from 'components/Grid';
import React, { FC, useEffect, Fragment } from 'react';

import DEFAULT_CONTENT from './content';
import * as S from './NoAreaServiceModal.styles';

export interface NoAreaServiceModalProps {
  openModal: boolean;
  setOpenModal: (_: boolean) => void;
  handleContinueToSchedule: () => void;
  program: string;
}

export const NoAreaServiceModal: FC<NoAreaServiceModalProps> = ({
  openModal,
  setOpenModal,
  handleContinueToSchedule,
  program,
}: NoAreaServiceModalProps) => {
  const handleCloseModal = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label:
          ANALYTICS.PAGES.INTERSTITIAL.NO_AREA_SERVICE_MODAL
            .CTA_SCHEDULE_APPOINTMENT,
        program,
      },
    });

    handleContinueToSchedule();
  };

  useEffect(() => {
    if (openModal) {
      analytics.track({
        event: ANALYTICS.EVENTS.VIEWED_PAGE,
        data: {
          page: ANALYTICS.PAGES.INTERSTITIAL.NO_AREA_SERVICE_MODAL.PAGE,
        },
      });
    }
  }, [openModal]);

  return (
    <S.Modal open={openModal} openHandler={setOpenModal}>
      <Grid.Container spacing={[]}>
        <Grid.Item width={[1]}>
          <S.Title>{DEFAULT_CONTENT.title}</S.Title>
        </Grid.Item>
        <Grid.Item width={[1]}>
          <>
            {DEFAULT_CONTENT.copy.map((copyParragraph, index) => (
              <Fragment key={`no-service-modal-copy-${index}`}>
                <S.Copy>{copyParragraph}</S.Copy>
                {index < DEFAULT_CONTENT.copy.length - 1 && <br />}
              </Fragment>
            ))}
          </>
        </Grid.Item>
        <Grid.Item width={[1]}>
          <S.Button appearance="primary" onClick={handleCloseModal}>
            Schedule future appointment
          </S.Button>
        </Grid.Item>
      </Grid.Container>
    </S.Modal>
  );
};

export default NoAreaServiceModal;
