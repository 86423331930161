import { getUserMedications } from 'common/apis/telehealthApis';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { logError } from 'common/utils/helpers';
import React from 'react';
import { useQuery } from 'react-query';
import { useEffectOnce } from 'react-use';

import ErrorCard from '../../components/ErrorCard';
import PageHeader from './components/PageHeader';
import PrescriptionCard from './components/PrescriptionCard';
import PrescriptionCardSkeleton from './components/PrescriptionCard/PrescriptionCard.skeleton';
import * as S from './MedicationPage.styles';

export type MedicationPageProps = {};

/**
 * List all user medications
 */
function MedicationPage(props: MedicationPageProps) {
  const {
    data: userMedicationsResponse,
    isLoading,
    isError,
    refetch,
  } = useQuery('telehealth-medications', getUserMedications, {
    onError(error) {
      logError((error as Error).message, {
        errorInfo: 'User Medications Response',
        component: 'MedicationPage',
        method: 'getUserMedications',
      });
    },
  });

  useEffectOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        page: ANALYTICS.PAGES.ACCOUNT_HUB.MEDICATIONS,
      },
    });
  });

  const medications = userMedicationsResponse?.data ?? [];

  return (
    <>
      <PageHeader />
      <S.PrescriptionsList>
        {isLoading && <PrescriptionCardSkeleton />}
        {isError && <ErrorCard onRetry={refetch} />}
        {!isError &&
          medications.map((medication, index) => (
            <PrescriptionCard key={index} medication={medication} />
          ))}
      </S.PrescriptionsList>
    </>
  );
}

export default MedicationPage;
