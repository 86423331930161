import {
  size,
  colors,
  Accordion,
  typography,
  mediaQueries,
  LeftCaret,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: row;
  }
`;

export const LeftColumn = styled.div`
  flex: 1;
`;

export const RightColumn = styled.div`
  display: flex;
  order: -1;
  align-items: center;
  justify-content: flex-end;

  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: column;
    justify-content: center;
    order: 0;
  }
`;

export const AccordionHeading = styled(Accordion.Heading)`
  background-color: ${colors.green1};
  border-radius: ${size.xs2}px;
  padding: ${size.xs1}px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
`;

export const ToggleIcon = styled(LeftCaret)`
  transform: rotate(90deg);
  width: 8px;
`;

export const Eyebrow = styled.span`
  ${typography.subtitle};
  display: inline-block;
  padding: ${size.xs2}px ${size.sm}px;
  color: ${colors.green5};
  margin: ${size.md}px 0;
  background: ${colors.green1};
  border: 1.5px solid ${colors.green2};
  border-radius: ${size.md}px;

  ${mediaQueries.forTabletVerticalUp} {
    margin-top: 0;
    margin-bottom: ${size.xs1}px;
  }
`;

export const Heading = styled.h2`
  ${typography.buttonText};
  color: ${colors.teal4};
  margin: 0 0 ${size.xs1}px;

  ${mediaQueries.forTabletVerticalUp} {
    ${typography.h5Text};
  }
`;

export const Details = styled.p`
  ${typography.captionText};
  line-height: 23px;
  margin: 0;
`;
