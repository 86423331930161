import { size, colors, typography } from '@everlywell/leaves';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const activeStyles = css`
  background-color: ${colors.green2};
`;

export const Item = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: ${size.xs1}px;
  ${typography.buttonText};
  color: ${colors.green4};
  margin-bottom: ${size.xs1}px;
  text-decoration: none;
  border-radius: ${size.xs2}px;

  &:hover,
  &:focus {
    color: ${colors.green3};

    path {
      stroke: ${colors.green3};
    }
  }

  &.active,
  &:active {
    ${activeStyles};
  }
`;

export const IconContainer = styled.span`
  width: ${size.lg}px;
  height: ${size.lg}px;
  margin-right: ${size.xs1}px;

  svg {
    fill: none;
    stroke: ${colors.green4};
  }
`;
