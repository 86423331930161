import { mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  ${mediaQueries.forTabletVerticalUp} {
    justify-content: flex-start;
  }
`;
