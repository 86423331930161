import useEnterpriseProgramSlug from 'common/hooks/useEnterpriseProgramSlug';
import useProgramSlug from 'common/hooks/useProgramSlug';
import { HEALTHIE_WHITE_LABEL_SSO_LINK } from 'common/utils/constants/urls';
import Layout from 'components/Layout';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useReducer } from 'react';
import TagManager from 'react-gtm-module';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import ConfirmationPage from '../NativeIntake/components/ConfirmationPage';
import { NativeSchedulingContextProvider } from './context/NativeSchedulingContext';
import * as S from './NativeScheduling.styles';
import AppointmentTimePage from './pages/AppointmentTimePage';
import AppointmentTypeSelection from './pages/AppointmentTypeSelection';
import ProviderSelection from './pages/ProviderSelection';
import ReviewBooking from './pages/ReviewBooking';
import AppointmentReasonPage from "./pages/AppointmentReasonPage";
import { defaultState, reducer } from './state/scheduling-flow.state';

export const NATIVE_SCHEDULING_PATH = '/virtual-care/scheduling';

/**
 * This components contains the routes and pages for the native scheduling flow.
 */
function NativeScheduling() {
  const { nativeAppointmentScheduling } = useFlags<{
    nativeAppointmentScheduling?: boolean;
  }>();
  const { programSlug } = useProgramSlug();
  const { enterpriseProgramSlug } = useEnterpriseProgramSlug();

  const navigate = useNavigate();
  const location = useLocation();

  const program = programSlug ?? defaultState.selectedProgram;
  const initialState = { ...defaultState, selectedProgram: program };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (
      !state.selectedProvider &&
      location.pathname === '/virtual-care/scheduling/time-selection'
    ) {
      navigate('/virtual-care/scheduling/provider-selection');
    }
  }, [location.pathname, navigate, state.selectedProvider]);

  const getNextStep = () => {
    switch (location.pathname) {
      case '/virtual-care/scheduling/appointment-type-selection':
        return '/virtual-care/scheduling/appointment-reason-selection';

      case '/virtual-care/scheduling/appointment-reason-selection':
        return '/virtual-care/scheduling/provider-selection';

      case '/virtual-care/scheduling/provider-selection':
        return '/virtual-care/scheduling/time-selection';

      case '/virtual-care/scheduling/time-selection':
        return '/virtual-care/scheduling/review';

      case '/virtual-care/scheduling/review':
        return '/virtual-care/scheduling/confirmation';

      default:
        return null;
    }
  };

  useEffect(() => {
    if (nativeAppointmentScheduling === false) {
      window.open(HEALTHIE_WHITE_LABEL_SSO_LINK, '_self');
    }
  }, [nativeAppointmentScheduling]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'nativeSchedulingPage',
        programName: program,
      },
    });
  }, [program]);

  if (!nativeAppointmentScheduling) return null;

  const firstSchedulingStepRoute = program === enterpriseProgramSlug ? '/virtual-care/scheduling/appointment-type-selection' : '/virtual-care/scheduling/provider-selection';

  return (
    <Layout>
      <S.Container>
        <NativeSchedulingContextProvider
          value={{
            state,
            dispatch,
            getNextStep,
          }}
        >
          <Routes>
            <Route path="appointment-type-selection" element={<AppointmentTypeSelection />} />
            <Route path="appointment-reason-selection" element={<AppointmentReasonPage />} />
            <Route path="provider-selection" element={<ProviderSelection />} />
            <Route path="time-selection" element={<AppointmentTimePage />} />
            <Route path="review" element={<ReviewBooking />} />
            <Route path="confirmation" element={<ConfirmationPage />} />
            <Route
              path="*"
              element={
                <Navigate
                  to={firstSchedulingStepRoute}
                  replace
                />
              }
            />
          </Routes>
        </NativeSchedulingContextProvider>
      </S.Container>
    </Layout>
  );
}

export default NativeScheduling;
