import { DESCRIPTIVE_SEVERITIES } from 'common/utils/constants';

// At least one abnormal result
export const ifAbnormal = (groupCount: Record<number, number>) =>
  groupCount[DESCRIPTIVE_SEVERITIES.DETECTED];

export const ifEquivocalIndeterminate = (groupCount: Record<number, number>) =>
  groupCount[DESCRIPTIVE_SEVERITIES.EQUIVOCAL_OR_INDETERMINATE];

export const ifInvalid = (groupCount: Record<number, number>) =>
  groupCount[DESCRIPTIVE_SEVERITIES.INVALID_OR_REJECTED];
