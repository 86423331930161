import { StepsIconType } from 'components/Steps/model';
import React from 'react';

import checkmark from './assets/checkmark.svg';
import * as S from './styles';

type Props = {
  type: StepsIconType | undefined;
};

const StepIcon = ({ type }: Props) => {
  switch (type) {
    case StepsIconType.check: {
      return (
        <S.Icon data-test="StepIconId">
          <img src={checkmark} alt="check" />
        </S.Icon>
      );
    }
    default: {
      return null;
    }
  }
};

export default React.memo(StepIcon);
