import AcceptedCards from 'components/StripeComponent/AcceptedCards/AcceptedCards';
import React from 'react';

import * as S from './Payment.styles';
export type TitleAlignProps = 'left' | 'center';
type PaymentDescriptionProps = {
  acceptedCards?: boolean;
  caption?: string;
  children?: React.ReactNode;
  title: string;
  titleAlign?: TitleAlignProps;
  acceptedCardsNote?: string;
};
const PaymentDescription = ({
  acceptedCards = true,
  acceptedCardsNote,
  caption,
  children,
  title,
  titleAlign = 'left',
}: PaymentDescriptionProps) => (
  <S.DescriptionWrapper>
    <S.H3 align={titleAlign}>{title}</S.H3>
    {caption && <S.Caption>{caption}</S.Caption>}
    {children && children}
    {acceptedCards && (
      <AcceptedCards showTip={false} acceptedCardsNote={acceptedCardsNote} />
    )}
  </S.DescriptionWrapper>
);

export default PaymentDescription;
