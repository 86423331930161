import {
  breakpoints,
  colors,
  mediaQueries,
  typography,
} from '@everlywell/leaves';
import { bodyCopyLarge } from 'common/styles/app-styles';
import styled from 'styled-components';

type Props = {
  outOfRange: boolean;
};

const NumericalMarkerGroupWrapper = styled.div<Props>`
  margin-bottom: ${(props) => (props.outOfRange ? '8px' : '0')};

  ${mediaQueries.forTabletVerticalUp} {
    margin-bottom: 0;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${(props) => (props.outOfRange ? '8px' : '0')};
  }

  ${mediaQueries.forDesktopUp} {
    max-width: ${breakpoints.forDesktopDown}px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const NumericalGroupTitle = styled.h2`
  ${bodyCopyLarge};
  font-weight: ${typography.weight.bold};
  margin: 0 0 24px;
  padding: 0 8px;
  color: ${colors.gray4};

  ${mediaQueries.forTabletHorizontalUp} {
    padding: 0 16px;
  }
`;

export { NumericalMarkerGroupWrapper, NumericalGroupTitle };
