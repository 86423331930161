import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FormBuilderField } from '../../utils/types';
import Height from './components/Height/Height';

export type HeightFieldProps = FormBuilderField;

function HeightField(props: HeightFieldProps) {
  const { register, errors } = useFormContext();
  return (
    <Height
      variant={props.variant}
      id={props.id}
      label={props.label}
      errors={errors}
      register={register}
      required={props.required}
    />
  );
}

export default HeightField;
