import { WM_FULL_PROGRAM_SLUG, WM_PROGRAM_SLUG } from '../InterstitialPage';

export type InsurancePageContent = {
  WITHOUT_INSURANCE: string;
};
export const INSURANCE_PAGE_CONTENT: Record<string, InsurancePageContent> = {
  DEFAULT: {
    WITHOUT_INSURANCE: `I'll pay without insurance`,
  },
  [WM_PROGRAM_SLUG]: {
    WITHOUT_INSURANCE: `I'll pay for my prescription without insurance`,
  },
  [WM_FULL_PROGRAM_SLUG]: {
    WITHOUT_INSURANCE: `I'll pay for my prescription without insurance`,
  },
};
