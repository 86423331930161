import { ANALYTICS } from 'common/utils/constants/analytics';
import HelpscoutItems from 'common/utils/helpScoutContent.json';
import CommonQuestionsItems from 'components/CommonQuestionsItems';
import * as React from 'react';

import closeIcon from './images/collapse.svg';
import expandIcon from './images/expand.svg';

type Props = {
  helpscoutCategoryId: string;
};

type HelpscoutArticle = {
  name: string;
  text: string;
};

let HelpscoutArticles: any = {};
HelpscoutArticles = HelpscoutItems;

const formatHelpscoutArticles = (articles: HelpscoutArticle[]) =>
  articles.map((item: HelpscoutArticle) => ({
    label: item.name,
    content: item.text,
    expandIcon,
    closeIcon,
    isOpen: false,
    clickAttributes: {
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        category: ANALYTICS.CATEGORIES.COMMON_QUESTIONS,
        'FAQ Title': item.name,
        page: ANALYTICS.PAGES.KIT_RESULT,
      },
    },
  }));

function CommonQuestionsSectionContent(props: Props) {
  const { helpscoutCategoryId } = props;
  const articles = HelpscoutArticles[helpscoutCategoryId];
  let accordionItems;

  if (articles) {
    accordionItems = formatHelpscoutArticles(articles);

    return <CommonQuestionsItems sections={accordionItems} />;
  }
  return null;
}

export default CommonQuestionsSectionContent;
