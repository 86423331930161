import { size, mediaQueries, InformationV2, Tooltip } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InformationIcon = styled(InformationV2)`
  fill: none;
  margin-left: ${size.sm}px;
`;

export const ToolTip = styled(Tooltip)`
  > div {
    width: 100%;
  }

  button {
    width: inherit;
  }
  ${mediaQueries.forTabletVerticalUp} {
    > div {
      align-self: flex-start;
    }
    div:nth-child(2) {
      margin: 0 36px ${size.xs2}px;
    }

    button {
      width: auto;
    }
  }
`;
