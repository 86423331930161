import React from 'react';

import { SIDataImageType, SIDataSizeType } from '../data/model';
import * as S from './styles';

type Props = {
  children: any;
  width: SIDataSizeType;
  height: SIDataSizeType;
  image: SIDataImageType;
};

const ImageWithMarkers = ({ width, height, image, children }: Props) => (
  <S.Wrapper width={width} height={height}>
    <S.Image image={image} src={image.src} alt={image.alt} />
    {children}
  </S.Wrapper>
);

export default ImageWithMarkers;
