import {
  Input,
  Dropdown,
  Row as LeavesRow,
  typography,
  size,
  colors,
  mediaQueries,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const FieldWrapper = styled.div`
  margin-bottom: ${size.lg}px;
`;

export const DropdownMenu = styled(Dropdown)`
  select {
    background-color: white;
  }

  select:focus {
    border: 1px solid ${colors.gray2};
    border-bottom: 1px solid ${colors.green2};
    box-shadow: ${colors.teal5} 0 0 0 1px inset;
  }
`;

export const AddPolicySection = styled.div`
  margin-top: ${size.sm}px;
  margin-bottom: ${size.xl1}px;
`;

export const AddPolicyLink = styled.span`
  ${typography.buttonText}
  cursor: pointer;
  margin: ${size.lg}px 0;
`;

export const TextInput = styled(Input)`
  svg {
    display: none;
  }

  margin-bottom: 0;
`;

export const Row = styled(LeavesRow)`
  margin-top: ${size.md}px;
  margin-bottom: ${size.md}px;
`;

export const CardImageSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  grid-row-gap: ${size.lg}px;

  ${mediaQueries.forTabletVerticalUp} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${size.lg}px;
  }
`;
