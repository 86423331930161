import { Button, useHooks } from '@everlywell/leaves';
import { useTelehealthAppointments } from 'common/apis/telehealthApis';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { isWcAsyncScheduleEnabled } from 'common/utils/flags';
import { PROGRAM_TYPE, ProgramType } from 'common/utils/types';
import BaseCard, {
  BaseCardSkeleton,
} from 'pages/AccountHub/components/BaseCard';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import FollowUpVisitCard from '../components/FollowUpVisitCard';
import * as S from '../components/TelehealthAppointments/TelehealthAppointments.styles';

export type FollowUpSectionProps = {
  isWcPlusMember: boolean;
  isVCVTab?: boolean;
};

const FOLLOW_UP_VISIT_HEADER_TEXT = 'Schedule your next visit';
const WEIGHT_CARE_SYNC_TEXT =
  'Prefer to meet with a provider again? Schedule your next telehealth appointment here';

function FollowUpSection({ isWcPlusMember, isVCVTab }: FollowUpSectionProps) {
  const { isDesktop } = useHooks.useWindowSize();

  const isWCAsyncEnabled = isWcAsyncScheduleEnabled();

  const {
    data: completedAppointmentsResponse,
    isLoading: isLoadingCompletedAppointments,
  } = useTelehealthAppointments({
    period: 'past',
  });

  const allAppointments =
    completedAppointmentsResponse?.data?.appointments ?? [];

  const isLoading = isLoadingCompletedAppointments;

  const WCAppointments = allAppointments.filter(
    (appointment) =>
      appointment.program.slug === PROGRAM_TYPE['weight-management-full'] ||
      appointment.program.slug === PROGRAM_TYPE['weight-care-ongoing'] ||
      appointment.program.slug === PROGRAM_TYPE['weight-care-ongoing-async'],
  );

  // An appointment is considered completed if it is returned as occurred or null
  const completedWCAppointments = WCAppointments.filter(
    (appointment) =>
      appointment.status === 'occurred' || appointment.status === null,
  );

  const getExtraFollowUpButton = (
    label: string,
    programType: ProgramType,
  ): ReactNode | undefined => {
    if (isWcPlusMember) {
      return (
        <Button
          appearance="secondary"
          hasArrow
          href={`/telehealth/sessions/?program=${PROGRAM_TYPE[programType]}`}
          component={Link}
          onClick={() => {
            analytics.track({
              event: ANALYTICS.EVENTS.CLICKED_BUTTON,
              data: {
                category: ANALYTICS.PAGES.ACCOUNT_HUB.VIRTUAL_CARE,
                label: label,
                program: PROGRAM_TYPE[programType],
              },
            });
          }}
        >
          {label}
        </Button>
      );
    }

    return undefined;
  };

  if (isLoading) {
    return (
      <BaseCard
        content={{
          title: (
            <BaseCardSkeleton.Title loadingText="Loading next visit information" />
          ),
          description: <BaseCardSkeleton.Description />,
        }}
      />
    );
  }

  // If not a WC+ member, we will not display the CTA or the Link
  let extraButton = null;
  let extraLink = null;

  if (isWcPlusMember && !isVCVTab) {
    // If no appointments occurred
    if (completedWCAppointments.length === 0) {
      extraButton = getExtraFollowUpButton(
        'Schedule',
        PROGRAM_TYPE['weight-management-full'],
      );
      // If 1 appointment occurred
    } else if (completedWCAppointments.length === 1) {
      extraButton = getExtraFollowUpButton(
        'Schedule',
        PROGRAM_TYPE['weight-care-ongoing'],
      );
      // If 2+ appointments occurred
    } else if (completedWCAppointments.length >= 2) {
      // and WC+ Async Feature Flag is enabled
      if (isWCAsyncEnabled) {
        extraButton = getExtraFollowUpButton(
          'Refill',
          PROGRAM_TYPE['weight-care-ongoing-async'],
        );
        extraLink = (
          <Link
            to={`/telehealth/sessions/?program=${PROGRAM_TYPE['weight-care-ongoing']}`}
          >
            {WEIGHT_CARE_SYNC_TEXT}
          </Link>
        );
        // and WC+ Async Feature Flag is not enabled
      } else {
        extraButton = getExtraFollowUpButton(
          'Schedule',
          PROGRAM_TYPE['weight-care-ongoing'],
        );
      }
    }
  }

  return (
    <section>
      {isDesktop ? (
        <S.NextVisitHeader>{FOLLOW_UP_VISIT_HEADER_TEXT}</S.NextVisitHeader>
      ) : null}
      <FollowUpVisitCard
        extraButton={extraButton}
        extraLink={extraLink}
        hasAsync={completedWCAppointments.length >= 2}
        completedWCAppointmentsAmount={completedWCAppointments.length}
        isWcPlusMember={isWcPlusMember}
      />
    </section>
  );
}

export default FollowUpSection;
