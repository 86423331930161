import { Button, breakpoints } from '@everlywell/leaves';
import { UserTaskModes, UserTaskTypes } from 'common/apis/telehealthApis';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { isEmpty } from 'lodash';
import React from 'react';
import { useWindowSize } from 'react-use';

import * as S from './JourneyTask.styles';

export type JourneyTaskProps = {
  type: UserTaskTypes;
  id: number;
  title: string;
  cta?: {
    link: string;
    label: string;
  } | null;
  image_uri: string;
  mode: UserTaskModes;
  expires_at: string | null;
  handleUserTask: (id: number) => void;
};

/**
 * This component is to show the tasks in a journey's step
 */
function JourneyTask(props: JourneyTaskProps) {
  const { image_uri, title, cta, id, mode, handleUserTask } = props;
  const { width } = useWindowSize();
  const isMobile = width <= breakpoints.forPhoneOnly;

  const handleTaskCTAClick = (ctaLabel: string, id?: number) => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.WEIGHT_CARE.JOURNEY_CHECKLIST.TASK_BUTTON_CLICK,
        category:
          ANALYTICS.LABELS.WEIGHT_CARE.DASHBOARD.JOURNEY_CHECKLIST_SECTION,
        cta: ctaLabel,
      },
    });

    // Only for `user_driven` mode tasks.
    if (id) {
      handleUserTask(id);
    }
  };

  return (
    <>
      {isMobile ? (
        <S.TasksWrapper>
          <S.TaskImageWrapper>
            <S.TaskImage src={image_uri} alt="task image" />
            <S.Title>{title}</S.Title>
          </S.TaskImageWrapper>
          <S.TaskTitleCTAWrapper>
            {/* It is possible to have a task with no CTA */}
            {cta && !isEmpty(cta) ? (
              <Button
                appearance="primary"
                href={cta.link}
                onClick={() => handleTaskCTAClick(cta.label, id)}
              >
                {cta.label}
              </Button>
            ) : null}
          </S.TaskTitleCTAWrapper>
        </S.TasksWrapper>
      ) : (
        <S.TasksWrapper>
          <S.TaskImageWrapper>
            <S.TaskImage src={image_uri} alt="task image" />
          </S.TaskImageWrapper>
          <S.TaskTitleCTAWrapper>
            <S.Title>{title}</S.Title>
            {/* It is possible to have a task with no CTA */}
            {cta && !isEmpty(cta) ? (
              mode === UserTaskModes.USER_DRIVEN ? (
                <Button
                  appearance="primary"
                  onClick={() => handleTaskCTAClick(cta.label, id)}
                >
                  {cta?.label}
                </Button>
              ) : (
                <Button
                  appearance="primary"
                  href={cta.link}
                  onClick={() => handleTaskCTAClick(cta.label)}
                >
                  {cta?.label}
                </Button>
              )
            ) : null}
          </S.TaskTitleCTAWrapper>
        </S.TasksWrapper>
      )}
    </>
  );
}

export default JourneyTask;
