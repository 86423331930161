import { STATUSES } from 'common/utils/constants';
import { Marker, MarkerResult } from 'common/utils/types';
import { createSelector } from 'reselect';
import {
  selectKitDomain,
  selectLabDomain,
  selectSessionDomain,
  selectTestDomain,
  selectMarkersDomain,
  selectMarkerResultDomain,
  selectContentSnippetsDomain,
} from 'store/selectors';

function populateEntityWithContent(entity: any, contentSnippets: any): any {
  if (Array.isArray(entity)) {
    return entity.map((e) => populateEntityWithContent(e, contentSnippets));
  }

  const { content_token } = entity;
  return {
    ...entity,
    content: content_token && contentSnippets[content_token],
  };
}

function populateKitResultWithContent(kitResult: any, contentSnippets: any) {
  const { lab, marker_results, markers, test } = kitResult;

  return {
    ...kitResult,
    test: populateEntityWithContent(test, contentSnippets),
    lab: populateEntityWithContent(lab, contentSnippets),
    marker_results,
    markers,
  };
}

const makeSelectSession = () =>
  createSelector(selectSessionDomain, (session) => session.toJS());

const makeSelectContentToken = (kitResultId: string) =>
  createSelector([selectKitDomain, selectTestDomain], (kits, tests) => {
    // in case of a shared result, 'kits' will only have one result, so we select that by default.
    const kitResult = kits.get(kitResultId) || kits.first();

    if (kitResult) {
      const testId = kitResult.get('test');
      const test = tests.get(`${testId}`);

      if (test) {
        return test.get('content_token');
      }
    }

    return undefined;
  });

const makeSelectKitResult = (kitResultIdOrPublishHash: string) =>
  createSelector(
    [
      selectKitDomain,
      selectLabDomain,
      selectMarkersDomain,
      selectMarkerResultDomain,
      selectTestDomain,
      selectContentSnippetsDomain,
    ],
    (kits, labs, markers, markerResults, tests, contentSnippets) => {
      const kitResult =
        // look for kitResult using the kitId
        kits.get(kitResultIdOrPublishHash) ||
        // above statement will fail if param passed is the publish hash
        // look for kitResult using publish hash
        kits.find(
          (kit) =>
            typeof kit !== 'undefined' &&
            kit.get('publish_hash') === kitResultIdOrPublishHash,
        );

      if (kitResult) {
        const status = kitResult.get('status');

        if (STATUSES.RESULTS_VIEWABLE.includes(status)) {
          try {
            // lab
            const lab = labs.get(`${kitResult.get('lab')}`);

            // markers
            const allMarkers: Marker[] = [];
            const krMarkers = kitResult.get('markers');
            if (krMarkers) {
              krMarkers.forEach((marker: Marker) => {
                allMarkers.push(markers.get(`${marker}`).toJS());
              });
            }
            // marker_results
            const allMarkerResults: MarkerResult[] = [];
            const krMarkerResults = kitResult.get('marker_results');
            if (krMarkerResults) {
              krMarkerResults.forEach((markerResult: MarkerResult) => {
                allMarkerResults.push(
                  markerResults.get(`${markerResult}`).toJS(),
                );
              });
            }

            // test
            const test = tests.get(`${kitResult.get('test')}`);

            // content
            const contentByToken: { [key: string]: any } = {};
            if (test && !contentSnippets.isEmpty()) {
              const testContentToken = test.get('content_token');
              const markerContentTokens = allMarkers.map(
                (marker) => marker.content_token,
              );
              const allContentTokens = [
                testContentToken,
                ...markerContentTokens,
              ];

              allContentTokens.forEach((contentToken) => {
                contentByToken[contentToken] =
                  contentSnippets.get(contentToken) &&
                  contentSnippets.get(contentToken).toJS();
              });
            } else {
              return undefined;
            }

            // all data needed for kitResult
            return populateKitResultWithContent(
              Object.assign({}, kitResult.toJS(), {
                viewable: true,
                lab: lab.toJS(),
                test: test.toJS(),
                markers: allMarkers,
                marker_results: allMarkerResults,
              }),
              contentSnippets.toJS(),
            );
          } catch (err) {
            return undefined;
          }

          // if kitResult is not approved, return object with viewable boolean instead of kitResult data
        } else {
          return { viewable: false };
        }
      }

      return undefined;
    },
  );

export { makeSelectSession, makeSelectKitResult, makeSelectContentToken };
