import BlueArrowDown from './arrow-down-blue.svg';
import GreenArrowDown from './arrow-down-green.svg';
import RedArrowDown from './arrow-down-red.svg';
import YellowArrowDown from './arrow-down-yellow.svg';
import BlueArrowUp from './arrow-up-blue.svg';
import GrayArrowUp from './arrow-up-gray.svg';
import GreenArrowUp from './arrow-up-green.svg';
import RedArrowUp from './arrow-up-red.svg';
import YellowArrowUp from './arrow-up-yellow.svg';
import BlueMinus from './blue-minus.svg';
import BlueCheck from './check-blue.svg';
import GreenCheck from './check-green.svg';
import RedCheck from './check-red.svg';
import YellowCheck from './check-yellow.svg';
import ControlMembership from './control-membership.svg';
import RedExclamation from './exclamation-red.svg';
import GrayMinus from './minus-gray.svg';
import GreenMinus from './minus-green.svg';
import RedMinus from './minus-red.svg';
import YellowMinus from './minus-yellow.svg';
import RedPlus from './plus-red.svg';
import Trash from './trash.svg';

type Icons = {
  [key: string]: string;
};

export const icons: Icons = {
  greenCheck: GreenCheck,
  greenArrowUp: GreenArrowUp,
  greenArrowDown: GreenArrowDown,
  yellowCheck: YellowCheck,
  yellowArrowUp: YellowArrowUp,
  yellowArrowDown: YellowArrowDown,
  redCheck: RedCheck,
  redArrowUp: RedArrowUp,
  redArrowDown: RedArrowDown,
  blueCheck: BlueCheck,
  blueArrowUp: BlueArrowUp,
  blueArrowDown: BlueArrowDown,
  grayMinus: GrayMinus,
  redMinus: RedMinus,
  blueMinus: BlueMinus,
  grayArrowUp: GrayArrowUp,
  redPlus: RedPlus,
  greenMinus: GreenMinus,
  yellowMinus: YellowMinus,
  redExclamation: RedExclamation,
  controlMembership: ControlMembership,
  trash: Trash,
};
