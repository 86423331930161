import {
  EnterpriseInsuranceFormData,
  TelehealthFormAnswer,
} from 'common/apis/telehealthApis';
import { Response } from 'common/hooks/useApi/request';
import { getFormattedDate } from 'components/telehealth/FormBuilder/components/LocationField/utils';

import { ADDRESS_SECTION_ID } from '../config';

type InsuranceFormFieldsData = Omit<
  EnterpriseInsuranceFormData,
  'program_slug' | 'patient_id' | 'self_payment'
>;

export const parseFormData = (
  formData: TelehealthFormAnswer[],
): InsuranceFormFieldsData => {
  const values = formData
    .filter((field) => field?.type !== 'read_only' && field?.type !== 'label')
    .reduce((acc, field) => {
      if (typeof field.answer !== 'string') {
        if ((field.custom_module_id === 'insurance_card_front' || field.custom_module_id === 'insurance_card_back') && field.answer[0]) {
          return { ...acc, ...{ [field.custom_module_id]: field.answer[0] } };
        } else {
          return acc;
        }
      }

      if (field.custom_module_id === 'has_secondary_insurance') {
        return { ...acc, ...{ [field.custom_module_id]: field.answer === 'Yes' } };
      } 
      
      const fieldId = field.custom_module_id;
      let value = field.answer;

      if (field.custom_module_id === 'policy_holder_address') {
        // country is not really necessary
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [address1, address2, state, city, _country, zipcode] =
          value.split(/\r\n/);

        return {
          ...acc,
          ...{
            policy_holder_address1: address1,
            policy_holder_address2: address2 ?? '',
            policy_holder_city: city,
            policy_holder_state: state,
            policy_holder_zip: zipcode,
          },
        };
      }

      return { ...acc, ...{ [fieldId]: value } };
    }, {});

  return values as InsuranceFormFieldsData;
};

export const setDefaultValues = (
  responseData?: Response<EnterpriseInsuranceFormData>,
): Record<string, any> | undefined => {
  const defaultValues = responseData?.data;

  let addressDefaultValuesObj = {};
  const addressDefaultValues = getAddressDefaultValues(defaultValues);
  if (addressDefaultValues) {
    addressDefaultValuesObj = getFormattedDate({
      addressSectionId: ADDRESS_SECTION_ID,
      defaultValuesObj: addressDefaultValues,
    });
  }

  return {
    ...defaultValues,
    ...addressDefaultValuesObj,
    relationship_to_insured: 'self',
  };
};

function getAddressDefaultValues(
  props: EnterpriseInsuranceFormData | null | undefined,
) {
  return {
    address1: props?.policy_holder_address1,
    address2: props?.policy_holder_address2,
    city: props?.policy_holder_city,
    state: props?.policy_holder_state,
    zip: props?.policy_holder_zip,
  };
}
