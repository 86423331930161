import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { useNativeSchedulingContext } from 'pages/VirtualCare/NativeScheduling/context/NativeSchedulingContext';
import React from 'react';
import { Link } from 'react-router-dom';

import * as S from './AddToCalendar.styles';
import AppleLogoIcon from './Images/apple-logo-icon.svg';
import AppointmentConfirmationIcon from './Images/appointment-confirmation-icon.svg';
import GoogleCalendarIcon from './Images/google-calendar-icon.svg';
import OutlookCalendarIcon from './Images/outlook-calendar-icon.svg';
import YahooLogoIcon from './Images/yahoo-logo-icon.svg';

type CalendarType = 'google' | 'apple' | 'outlook' | 'yahoo';

type CalendarLink = {
  label: string;
  icon: string;
  calendarLink: string;
  calendarType: CalendarType;
};

export const AddToCalendar = ({ ctaLink = '/virtual-care' }) => {
  const { state } = useNativeSchedulingContext();
  const isMobileApp = sessionStorage.getItem('embedded') === 'true';

  const { confirmedAppointment, selectedProgram } = state;

  if (!confirmedAppointment) return null;

  const { provider, add_to_calendar_links } = confirmedAppointment;
  const { google_cal, ical, outlook_cal, yahoo_cal } = add_to_calendar_links;

  const calendarLinks: CalendarLink[] = [
    {
      label: 'Google',
      icon: GoogleCalendarIcon,
      calendarLink: google_cal,
      calendarType: 'google',
    },
    {
      label: 'Apple',
      icon: AppleLogoIcon,
      calendarLink: ical,
      calendarType: 'apple',
    },
    {
      label: 'Outlook',
      icon: OutlookCalendarIcon,
      calendarLink: outlook_cal,
      calendarType: 'outlook',
    },
    {
      label: 'Yahoo',
      icon: YahooLogoIcon,
      calendarLink: yahoo_cal,
      calendarType: 'yahoo',
    },
  ];

  const handleAddToCalendarButtonClick =
    (calendar_type: CalendarType) => () => {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: 'add to calendar',
          program: selectedProgram,
          calendar_type,
        },
      });
    };
  return (
    <S.ContentContainer>
      <S.IconContainer src={AppointmentConfirmationIcon} alt="" />
      <S.Header>
        You're booked with {provider.first_name} {provider.last_name}!
      </S.Header>

      {!isMobileApp && (
        <>
          <S.Text>Add to calendar</S.Text>

          <S.AddToCalendarWrapper>
            {calendarLinks.map(
              ({ label, icon, calendarLink, calendarType }) => (
                <S.AddToCalendarButton
                  appearance="secondary"
                  target="_blank"
                  rel="noreferrer"
                  href={calendarLink}
                  onClick={handleAddToCalendarButtonClick(calendarType)}
                  key={`${label}-calendar`}
                >
                  <img src={icon} alt="" />
                  {label}
                </S.AddToCalendarButton>
              ),
            )}
          </S.AddToCalendarWrapper>

          <S.AppointmentsButton
            appearance="primary"
            component={Link}
            to={ctaLink}
          >
            My appointments
          </S.AppointmentsButton>
        </>
      )}
    </S.ContentContainer>
  );
};
