import { useSearchParams } from 'react-router-dom';

type IsMobileEmbeddedProps = {
  isMobileEmbedded: boolean;
};

export const useIsMobileEmbedded = (): IsMobileEmbeddedProps => {
  const [searchParams] = useSearchParams();
  const isEmbedded =
    searchParams.get('embedded') === 'true' ||
    sessionStorage.getItem('embedded') === 'true';

  const isMobileEmbedded =
    isEmbedded || window.navigator.userAgent.includes('com.everlyhealth.ios');

  return { isMobileEmbedded };
};
