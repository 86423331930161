import { colors, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.p`
  background-color: ${colors.teal1};
  padding: ${size.sm}px ${size.md}px;
  color: ${colors.gray4};
  font-size: ${size.sm}px;
  font-weight: ${typography.weight.book};

  strong {
    font-weight: ${typography.weight.bold};
  }
`;
