import { KitRegistrationUser } from 'common/utils/types';
import React from 'react';

interface UserAddressProps {
  user: KitRegistrationUser;
}

const UserAddress = ({ user }: UserAddressProps) => (
  <>
    <div data-notranslate="true">
      {user.streetAddress.value}{' '}
      {user.subAddress.value ? user.subAddress.value : ''}
    </div>
    <div data-notranslate="true">
      {user.addressCity.value}, {user.addressState.value}{' '}
      {user.addressZipCode.value}
    </div>
  </>
);

export default UserAddress;
