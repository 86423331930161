import { FoodSensitivityGroup } from 'common/utils/types';

const noReactivityGroups = (groups: FoodSensitivityGroup[]) =>
  groups
    .filter((group) => group.groupedMarkers.length === 0)
    .sort((first, second) => {
      if (!first.includedSeverityIndexes || !second.includedSeverityIndexes)
        return 0;
      return (
        Math.min(...first.includedSeverityIndexes) -
        Math.min(...second.includedSeverityIndexes)
      );
    });

const noReactivityGroupsGroupedBySequence = (groups: FoodSensitivityGroup[]) =>
  noReactivityGroups(groups).reduce(
    (
      newGroups: FoodSensitivityGroup[][],
      group,
      index: number,
      initialGroups,
    ) => {
      if (
        !index ||
        group.includedSeverityIndexes?.[0] !==
          (initialGroups?.[index - 1]?.includedSeverityIndexes?.[0] || 0) + 1
      )
        newGroups.push([]);
      newGroups[newGroups.length - 1].push(group);
      return newGroups;
    },
    [],
  );

const consolidateReactivityGroups = (groups: FoodSensitivityGroup[]) =>
  groups.filter((group) => group.groupedMarkers.length > 0);

const noReactivityGroupTitle = (groups: FoodSensitivityGroup[]) => {
  const regex = new RegExp('(very \\w+)|(\\w+)', 'i');
  return groups.length > 1
    ? `${groups[0].title.match(regex)?.[0]} to ${
        groups[groups.length - 1].title
      }`
    : groups[0]?.title;
};

const filterNoReactivityGroupSeverities = (
  severities: (number[] | undefined)[],
) =>
  ([] as any[])
    .concat(...severities)
    .filter(Number.isFinite)
    .filter((element, index, arr) => arr.indexOf(element) === index)
    .sort();

const noReactivityGroupSeverities = (groups: FoodSensitivityGroup[]) =>
  filterNoReactivityGroupSeverities(
    groups.map((group: FoodSensitivityGroup) => group.includedSeverityIndexes),
  );

const consolidateNoReactivityGroups = (groups: FoodSensitivityGroup[]) =>
  noReactivityGroupsGroupedBySequence(groups).map(
    (groupsBySequence: FoodSensitivityGroup[]) => ({
      title: noReactivityGroupTitle(groupsBySequence),
      groupedMarkers: [],
      includedSeverityIndexes: noReactivityGroupSeverities(groupsBySequence),
    }),
  );

const consolidateFSReactivityGroups = (groups: FoodSensitivityGroup[]) =>
  consolidateReactivityGroups(groups)
    .concat(consolidateNoReactivityGroups(groups))
    .sort((first, second) => {
      if (!first.includedSeverityIndexes || !second.includedSeverityIndexes)
        return 0;
      return (
        Math.min(...second.includedSeverityIndexes) -
        Math.min(...first.includedSeverityIndexes)
      );
    });

export default consolidateFSReactivityGroups;
