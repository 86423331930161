import analytics from 'common/utils/analytics';
import { NOT_APPLICABLE, NOTAPPLICABLE } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { MARKER_DETAILS as DATA_TEST } from 'common/utils/constants/dataTest';
import { sanitize } from 'common/utils/domHelpers';
import { formatStringSeparator } from 'common/utils/formatText';
import {
  isMarkerValueException,
  isHumanaCustomer,
  jumpToSection,
  useToggleStateWithTracking,
} from 'common/utils/helpers';
import {
  NumericalMarkerContent,
  DescriptiveMarkerContent,
  MarkerWithResult,
  HistoricalResult,
  ResourceLink,
} from 'common/utils/types';
import ResultsOverTimeContainer from 'containers/ResultsOverTimeContainer';
import React, { useState, useRef, useEffect } from 'react';

import * as S from './styles';
import useKeyboardActions from './useKeyboardActions';

type Props = {
  name: string;
  content?: NumericalMarkerContent & DescriptiveMarkerContent;
  detailsExpanded?: boolean;
  markerValue: string;
  markerWithResult?: MarkerWithResult;
  viewingSharedKitResult?: boolean;
};

/**
 * For numerical markers we want to remove the invalid results (all values that are non a number) from the chart
 */
export const validHistoricalResults = (
  markerWithResult: MarkerWithResult | undefined,
) => {
  if (markerWithResult === undefined) {
    return [];
  }

  const historicalResults =
    markerWithResult.marker_result.historical_results || [];

  if (markerWithResult.type !== 'numerical') {
    return historicalResults;
  }

  return historicalResults.filter((result) => isFinite(Number(result.value)));
};

const MarkerDetails = (props: Props) => {
  const contentContainerRef: React.RefObject<HTMLDivElement> | null =
    useRef(null);

  const tmmContentRef: React.RefObject<HTMLDivElement> | null = useRef(null);

  const {
    content,
    detailsExpanded = false,
    name,
    markerValue,
    markerWithResult,
    viewingSharedKitResult,
  } = props;

  const tellMeMoreScrollId = `about-${name}`;

  const [height, setHeight] = useState(0);
  const [expanded, toggleExpanded] = useToggleStateWithTracking({
    defaultState: detailsExpanded || false,
    inactiveLabel: 'See more details',
    activeLabel: 'Hide details',
    callback: (enableScroll: boolean) =>
      jumpToSection(enableScroll, `${name}-card`),
  });
  const [tmmHeight, setTmmHeight] = useState(0);
  const [tmmExpanded, toggleTmmExpanded] = useToggleStateWithTracking({
    defaultState: false,
    inactiveLabel: 'Tell Me More',
    activeLabel: 'Collapse section',
    callback: (enableScroll: boolean) =>
      jumpToSection(enableScroll, tellMeMoreScrollId),
  });

  useEffect(() => {
    let newHeight = 0;
    let newTmmHeight = 0;
    if (expanded && contentContainerRef.current) {
      newHeight = contentContainerRef.current.scrollHeight;
      if (tmmExpanded && tmmContentRef.current) {
        newTmmHeight = tmmContentRef.current.scrollHeight;
      }
      if (tmmExpanded) {
        newHeight += newTmmHeight;
      } else {
        newHeight -= tmmHeight;
      }
    }
    setHeight(newHeight);
    setTmmHeight(newTmmHeight);
  }, [expanded, tmmExpanded]); //eslint-disable-line

  const markerOverview = {
    __html: (content && sanitize(content.markerOverview)) || '',
  };
  const tellMeMoreContent = {
    __html: (content && sanitize(content.markerTellMeMore)) || '',
  };
  let tailoredHeader = (content && content.tailoredHeader) || '';
  const tailoredOverview = {
    __html:
      (content &&
        content.tailoredOverview &&
        sanitize(content.tailoredOverview)) ||
      '',
  };

  const contentToggleEl = useKeyboardActions(toggleExpanded);

  const tmmToggleEl = useKeyboardActions(toggleTmmExpanded);

  const dataTest = formatStringSeparator(name.toLowerCase());

  // handle <dl or n/>a results
  const isException = isMarkerValueException(markerValue);

  if (
    isException &&
    (markerValue === NOT_APPLICABLE || markerValue === NOTAPPLICABLE)
  ) {
    tailoredHeader = tailoredHeader.replace('Low', 'Not Applicable');
  }

  const notDNR = (result: HistoricalResult) => result.value !== 'DNR';

  const historicalResults = validHistoricalResults(markerWithResult);

  const hasROT = Boolean(
    markerWithResult &&
      historicalResults &&
      historicalResults.filter(notDNR).length > 1,
  );

  const thirdParty = isHumanaCustomer();

  const fireAnalyticsClickEvent = (link: ResourceLink, number: number) => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: number
          ? `${ANALYTICS.LABELS.RESOURCE_LINK_BUTTON} ${number}`
          : ANALYTICS.LABELS.RESOURCE_LINK_BUTTON,
        category: ANALYTICS.CATEGORIES.RESOURCES_LINKS,
        name: link.name,
        url: link.link,
      },
    });
  };

  return (
    <>
      <S.MarkerContentWrapper
        expanded={expanded}
        ref={contentContainerRef}
        height={height}
        id={tellMeMoreScrollId}
      >
        <S.MarkerContentSection data-test={`${dataTest}-about-section`}>
          <S.Title>About {name}</S.Title>
          <S.MarkerContent>
            {content && content.markerOverview && (
              // eslint-disable-next-line react/no-danger
              <p dangerouslySetInnerHTML={markerOverview} />
            )}
          </S.MarkerContent>
          <S.TellMeMoreContent
            data-test={DATA_TEST.TELL_ME_MORE_CONTENT}
            dangerouslySetInnerHTML={tellMeMoreContent}
            height={tmmHeight}
            tmmExpanded={tmmExpanded}
            ref={tmmContentRef}
          />
          <S.TellMeMoreToggle
            role="button"
            tabIndex={0}
            aria-expanded={tmmExpanded}
            tmmExpanded={tmmExpanded}
            data-test={DATA_TEST.TELL_ME_MORE_TOGGLE}
            ref={tmmToggleEl}
          >
            {tmmExpanded ? 'Hide' : 'Tell Me More'}
          </S.TellMeMoreToggle>
        </S.MarkerContentSection>

        {hasROT && markerWithResult && !thirdParty && !viewingSharedKitResult && (
          <S.GraphWrapper>
            <S.GraphTitle>Your Results Over Time</S.GraphTitle>
            <ResultsOverTimeContainer
              markerWithResult={markerWithResult}
              historicalResults={historicalResults}
              isShowing={expanded}
            />
          </S.GraphWrapper>
        )}

        {content && content.tailoredOverview && (
          <S.MarkerContentSection>
            <S.Title data-test={`${dataTest}-tailored-header`}>
              {tailoredHeader}
            </S.Title>
            <S.MarkerContent
              dangerouslySetInnerHTML={tailoredOverview}
              data-test={`${dataTest}-tailored-content`}
            />
          </S.MarkerContentSection>
        )}

        {content && content.moreResources && content.moreResources.length > 0 && (
          <S.MarkerContentSection>
            <S.Title>More Resources</S.Title>
            {content.moreResources.map((link, idx) => (
              <S.ResourceSection>
                <S.ResourceLink
                  target="_blank"
                  rel="noopener noreferrer"
                  href={link.link}
                  onClick={() => fireAnalyticsClickEvent(link, idx + 1)}
                >
                  {link.name}
                </S.ResourceLink>
              </S.ResourceSection>
            ))}
          </S.MarkerContentSection>
        )}
      </S.MarkerContentWrapper>
      <S.ContentToggle
        aria-expanded={expanded}
        role="button"
        tabIndex={0}
        data-test={DATA_TEST.MARKER_DETAILS_TOGGLE}
        expanded={expanded}
        ref={contentToggleEl}
      >
        {expanded ? 'Hide Details' : 'See More Details'}
      </S.ContentToggle>
    </>
  );
};

export default MarkerDetails;
