type Value = string | null | undefined;
export const getFormattedDate = ({
  addressSectionId,
  defaultValuesObj,
}: {
  addressSectionId: string | '';
  defaultValuesObj: Record<string, Value>;
}): Record<string, string> => {
  let addressObj: Record<string, any> = {
    [`${addressSectionId}-tmp`]: {},
  };

  addressObj[`${addressSectionId}-tmp`] = {
    addressLine1: defaultValuesObj['address1'],
    addressLine2: defaultValuesObj['address2'],
    city: defaultValuesObj['city'],
    state: defaultValuesObj['state'],
    country: 'US',
    zipCode: defaultValuesObj['zip'],
  };
  addressObj[addressSectionId] = Object.values(
    addressObj[`${addressSectionId}-tmp`],
  ).join('\r\n');

  return addressObj;
};
