import { size, colors, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${size.md}px;
`;

export const RadioContainer = styled.div<{ hasCalloutText: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .radio-button {
    border: 1px solid ${colors.green2};
    padding: ${size.md}px;
    background-color: ${colors.white};
    display: flex;
    width: 100%;

    &.selected {
      background-color: ${colors.green1};
      border-left-color: ${colors.green4};
      border-right-color: ${colors.green4};
      border-bottom-color: ${colors.green4};
      border-style: solid;
      ${({ hasCalloutText }) =>
        hasCalloutText
          ? `
            border-left-width: 1px;
            border-right-width: 1px;
            border-bottom-width:1px`
          : `border-top-color: ${colors.green4};
             border-width: 1px;`}
    }

    label {
      width: 100%;
      align-items: baseline;

      > div {
        width: 100%;
      }

      :after {
        top: 0;
        margin-top: 10px;
      }
    }

    ${({ hasCalloutText }) =>
      hasCalloutText
        ? `border-radius: 0 0 ${size.xs2}px ${size.xs2}px;`
        : `border-radius: ${size.xs2}px;`}
  }
`;

export const RadioLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const RadioButtonTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${typography.bodyText};
`;

export const CalloutText = styled.span`
  ${typography.buttonTextSmall};
  display: block;
  background-color: ${colors.green3};
  border: 1px solid ${colors.green2};
  border-radius: ${size.xs2}px ${size.xs2}px 0 0;
  color: ${colors.white};
  padding: ${size.xs2}px 0;
  text-align: center;

  &.selected {
    border: 1px solid ${colors.green4};
  }
`;

export const MainText = styled.span`
  ${typography.buttonTextSmall};
  color: ${colors.green4};
  display: block;
`;

export const SecondaryText = styled.span`
  ${typography.captionText};
  display: block;
`;
