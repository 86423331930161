import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import { fromJS } from 'immutable';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import sagas from './saga';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}) {
  const preloadedState = fromJS(initialState);

  const middlewares = [sagaMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const composedEnhancers =
    composeWithDevToolsDevelopmentOnly(middlewareEnhancer);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  sagaMiddleware.run(sagas);

  return store;
}
