import { colors, mediaQueries, typography } from '@everlywell/leaves';
import {
  CardContainer,
  bodyCopy,
  bodyCopyLarge,
} from 'common/styles/app-styles';
import Button from 'components/Button';
import styled from 'styled-components';

const Container = styled(CardContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 259px;
  margin-top: 8px;

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
    max-width: 903px;
    margin-left: auto;
    margin-right: auto;
  }

  ${mediaQueries.forDesktopUp} {
    flex-direction: row;
    max-width: 1189px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  min-height: 182px;
  background-color: ${colors.gray1};
  border-bottom: 1px solid ${colors.gray2};

  ${mediaQueries.forTabletHorizontalUp} {
    border-bottom: none;
    border-right: 1px solid ${colors.gray2};
  }
  ${mediaQueries.forDesktopUp} {
    flex-basis: 369px;
    flex-grow: 0;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: flex-start;
  padding: 24px 24px 16px;

  ${mediaQueries.forTabletHorizontalUp} {
    padding: 31px 31px 31px 34px;
    flex-basis: 146px;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: center;
  width: calc(100% - 48px);
  margin: 0;
  padding: 10px 0 32px;
  border-top: 1px solid ${colors.gray2};

  ${mediaQueries.forTabletHorizontalUp} {
    width: 100%;
    margin: 30px 0;
    padding: 0 32px;
    border-top: none;
    border-left: 1px solid ${colors.gray2};
    align-self: stretch;
    display: flex;
    justify-content: space-between;
  }
`;

const Icon = styled.img`
  width: 121px;

  ${mediaQueries.forTabletHorizontalUp} {
    width: 151px;
  }
`;

const Header = styled.h3`
  margin: 0 0 4px;
  ${bodyCopy};
  letter-spacing: normal;
  font-weight: ${typography.weight.regular};
  color: ${colors.gray5};

  ${mediaQueries.forTabletHorizontalUp} {
    ${bodyCopyLarge};
    line-height: 24px;
  }

  ${mediaQueries.forDesktopUp} {
    font-size: 20.2px;
  }
`;

const Description = styled.p`
  ${bodyCopy};
  line-height: 30px;
  font-weight: ${typography.weight.light};
  color: ${colors.gray5};

  ${mediaQueries.forTabletHorizontalUp} {
    ${bodyCopyLarge};
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  ${bodyCopy};
  line-height: 30px;
  list-style-type: none;
  text-align: left;
  font-weight: ${typography.weight.light};
  color: ${colors.gray5};
`;

const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
  padding: 4px 0 0;

  &:first-child {
    padding-top: 0;
  }
`;

const ListIcon = styled.img`
  display: inline-block;
  margin-top: 4px;
  margin-right: 12px;
  width: 16px;
  height: 16px;
`;

const ScheduleButton = styled(Button)`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
  max-width: 300px;
  align-self: center;
  text-decoration: none !important;
  ${mediaQueries.forTabletHorizontalUp} {
    align-self: flex-start;
    max-width: none;
  }
`;

const TooltipIcon = styled.img`
  width: 28px;
  margin-top: -4px;
  margin-left: 5px;
  align-self: flex-start;
`;

const TooltipContent = styled.div`
  padding: 16px 16px 0;
`;

const ModalTitle = styled.h3`
  ${bodyCopy};
  color: ${colors.gray5};
  font-weight: ${typography.weight.bold};
  margin: 0 0 8px;
`;

const ModalDescription = styled.div`
  ${bodyCopy};
  color: ${colors.gray5};
  font-weight: ${typography.weight.light};
  margin-bottom: 16px;

  p {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 32px;
    }
  }
`;

const DateAndIcon = styled.span`
  white-space: nowrap;
`;

export {
  Container,
  IconWrapper,
  DescriptionWrapper,
  ActionWrapper,
  Icon,
  Header,
  Description,
  List,
  ListItem,
  ListIcon,
  ScheduleButton,
  TooltipIcon,
  TooltipContent,
  ModalTitle,
  ModalDescription,
  DateAndIcon,
};
