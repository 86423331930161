import { icons } from 'common/icons';
import React from 'react';

import { Icon } from '../styles';

const ICON_MAP = {
  'arrow-down': 'ArrowDown',
  'arrow-up': 'ArrowUp',
  check: 'Check',
  plus: 'Plus',
  minus: 'Minus',
  exclamation: 'Exclamation',
};

export type IconMapKeys = keyof typeof ICON_MAP;

export type Props = {
  iconType: IconMapKeys;
  iconColor: string;
  height: number;
  width: number;
};
const ReactivityBadgeIcon = (props: Props) => {
  const { iconType, iconColor, height, width } = props;

  const iconName = `${iconColor}${ICON_MAP[iconType]}`;
  return (
    <Icon
      data-test={iconName}
      src={icons[iconName]}
      aria-hidden="true"
      height={height}
      width={width}
    />
  );
};

export default ReactivityBadgeIcon;
