/**
 *
 * KitResultDetailPageContainer
 *
 */
/* eslint-disable react-hooks/exhaustive-deps */
import useApi from 'common/hooks/useApi';
import { ImmutableReduxState, KitResult, User } from 'common/utils/types';
import KitResultDetailPage from 'pages/KitResultDetailPage';
import NewLayoutKitResultDetailPage from 'pages/KitResultDetailPage/NewLayoutKitResultDetailPage';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState, createContext, Context } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { getKitResult, getKitResultContent } from 'store/actions';
import { SessionState } from 'store/session/types';
import { setUserData } from 'store/user/actions';

import { useMapStateToProps } from './hooks';

export interface StateProps {
  // TODO: this will change to only use kitResultId once API has been updated per
  // discussion here: https://everlywell.slack.com/archives/GB3HVQ9UK/p1554224358033400
  kitResultIdOrPublishHash: string;
  session: SessionState;
  kitResult?: KitResult;
  contentToken?: string;
  loadingContent: boolean;
  showConsult: boolean;
  testId?: number;
  sku?: string;
  viewingSharedKitResult?: boolean;
  userId: string;
}

export type MapProps = {
  state: ImmutableReduxState;
  ownProps: OwnProps;
};

export type DispatchProps = {
  setUserData: (data: User) => void;
  getKitResult: ({
    kitResultIdOrPublishHash,
    shared,
  }: {
    kitResultIdOrPublishHash: string;
    shared?: boolean;
  }) => void;
  getKitResultContent: (kitContentString: string) => void;
};

const COVID_KEYWORD = 'covid';

export interface OwnProps {
  viewingSharedKitResult?: boolean;
}

export const SeverityContext: Context<number | null> = createContext<
  number | null
>(null);

function KitResultDetailPageContainer(props: MapProps & DispatchProps) {
  const { state, ownProps } = props;
  const navigate = useNavigate();
  const stateProps = useMapStateToProps({ state, ownProps });
  const { contentToken, kitResult, userId, testId } = stateProps;
  const [severity, setSeverity] = useState<number | null>(null);
  const { fetchUserData } = useApi();
  const { enableNewResultLayout } = useFlags();

  // Fetch user Data so Layout component has access to user data to select membership info
  // ideally in the future, the Layout that renders the header could fetch the user data so we don't do it in each "page"
  useEffect(() => {
    if (userId) {
      fetchUserData(userId).then((data) => {
        props.setUserData({ ...data });
      });
    }
  }, [userId]);

  useEffect(() => {
    if (kitResult && !kitResult.viewable) {
      navigate('/dashboard', { replace: true });
      return;
    }
    if (contentToken) {
      props.getKitResultContent(contentToken);
    }
  }, [contentToken]);

  // listen for a kitResult - add data to context
  useEffect(() => {
    if (
      kitResult &&
      kitResult.markers &&
      kitResult.marker_results &&
      kitResult.test &&
      kitResult.test.type &&
      kitResult.test.type.toLowerCase().includes(COVID_KEYWORD)
    ) {
      const marker = kitResult.markers[0];
      const markerResult = kitResult.marker_results[0];

      const { severities } = marker;
      const { severity_index: severityIndex } = markerResult;

      // pass the test type: lyme-test or covid-19-test and the severityValue that matches up with SEVERITY_VALUE
      setSeverity(severities[severityIndex]);
    }
  }, [kitResult]);

  return (
    <SeverityContext.Provider value={severity}>
      {/*
        Check if the current test ID is present on the new Layout feature flag
        If Yes: renders the new result page layout (NewLayoutKitResultDetailPage);
        If No, renders the legacy result page layout (KitResultDetailPage);
      */}
      {enableNewResultLayout &&
      enableNewResultLayout['testIds'] &&
      (enableNewResultLayout['testIds'] as number[]).includes(testId ?? 0) ? (
        <NewLayoutKitResultDetailPage {...props} {...stateProps} />
      ) : (
        <KitResultDetailPage {...props} {...stateProps} />
      )}
    </SeverityContext.Provider>
  );
}

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): MapProps {
  return {
    state,
    ownProps,
  };
}

const withConnect = connect(mapStateToProps, {
  getKitResult,
  getKitResultContent,
  setUserData,
});

export default compose<any>(withConnect)(KitResultDetailPageContainer);
