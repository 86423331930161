import { LocalizeDisabled, LocalizeIgnore } from '@everlywell/leaves';
import { PageHeaderWrapper } from 'pages/AccountHub/AccountHub.styles';
import React from 'react';

import { LoaderSmall } from '../LoadingCarePlan';
import Illustration from './illustration.svg';
import * as S from './PageHeader.styles';
import PageHeaderLink from './PageHeaderLink';

type PageHeaderProps = {
  firstName: string;
  isLoadingUser: boolean;
};

/**
 * This page header displays the user's first name at the top of their care plan
 */
function PageHeader(props: PageHeaderProps) {
  return (
    <PageHeaderWrapper>
      <S.Container>
        <PageHeaderLink />
        <S.ContentContainer>
          {props.isLoadingUser ? (
            <LoaderSmall />
          ) : (
            <S.Header data-isolate>
              <LocalizeDisabled>
                {props?.firstName ? `${props?.firstName}` : ''}
              </LocalizeDisabled>
              <LocalizeIgnore>{props?.firstName ? `'s ` : ''}</LocalizeIgnore>
              Care Plan
            </S.Header>
          )}
          <S.Image src={Illustration} alt="" />
        </S.ContentContainer>
      </S.Container>
    </PageHeaderWrapper>
  );
}

export default PageHeader;
