import { theme, Box, Heading, Icon, Grid, GridItem } from '@everlywell/ui-kit';
import { ArrowLeft } from '@phosphor-icons/react';
import useCarePlan from 'common/hooks/useCarePlan';
import useOrders from 'common/hooks/useOrders';
import { OrderParam } from 'common/hooks/useOrders/useOrders';
import useUser from 'common/hooks/useUser';
import {
  CONTENTFUL_PRODUCTS_ACCESS_TOKEN,
  CONTENTFUL_PRODUCTS_SPACE_ID,
} from 'common/utils/constants';
import PlanSummary from 'components/PlanSummary';
import CarePlanInformation from 'components/telehealth/AthenaCarePlans/CarePlanInformation';
import CarePlanAccordion from 'components/telehealth/CarePlan/components/CarePlanAccordion/CarePlanAccordion';
import { CarePlanInfoCardProps } from 'components/telehealth/CarePlan/components/CarePlanInfoCard';
import ApolloContainer from 'containers/ApolloContainer';
import { format } from 'date-fns';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { formatOrdersAccordionItems } from './helpers';

export interface AthenaCarePlanPageProps {}

const AthenaCarePlanPage = (props: AthenaCarePlanPageProps) => {
  const { user } = useUser();
  const firstName = user?.first_name;

  const { space } = theme;

  const { appointmentId } = useParams();
  const {
    assessment,
    diagnosis,
    providerName,
    orders,
    isLoading: isLoadingCarePlan,
  } = useCarePlan(appointmentId ?? '');
  const {
    orders: ordersDetails,
    fetchOrders,
    isLoading: isLoadingOrders,
  } = useOrders([]);

  // placeholder for actual appointment date
  const date = format(new Date(), 'do LLLL yyyy');

  const handleButtonClick = (params: OrderParam[]) => {
    fetchOrders(params);
  };

  const formattedOrdersAccordionItems = formatOrdersAccordionItems(
    orders,
    ordersDetails,
    {
      handleClick: handleButtonClick,
      isLoading: isLoadingOrders,
    },
  );

  const formattedAccordionItems: CarePlanInfoCardProps[] = [
    {
      sectionName: 'Diagnosis',
      iconName: 'CircleDashed',
      items: diagnosis,
    },
    ...formattedOrdersAccordionItems,
  ];

  // TODO: Add loading state
  // This is a temporary place holder for loading state, design still in progress
  if (isLoadingCarePlan) {
    return <p>Loading...</p>;
  }

  return (
    <>
      {/* TODO: Contentful Test, Remove/replace/update after implementing Care plans data */}
      <ApolloContainer
        spaceId={CONTENTFUL_PRODUCTS_SPACE_ID}
        contentfulPublicAccessToken={CONTENTFUL_PRODUCTS_ACCESS_TOKEN}
      >
        <CarePlanInformation />
      </ApolloContainer>
      <Grid gap={{ base: space[8], desktop: 0 }}>
        <GridItem width={[1]} paddingBottom="0">
          <Link to="/dashboard">
            <Icon
              as={ArrowLeft}
              size="sm"
              fill="tints.uiGrey"
              backgroundColor="tints.white"
              borderRadius="3xl"
              cursor="pointer"
              weight={'regular'}
              height={space[12]}
              padding={space[3]}
              width={space[12]}
              aria-label="Left arrow icon"
              aria-hidden="true"
            ></Icon>
          </Link>
        </GridItem>
        <Box paddingLeft={{ base: 0, desktop: '112' }}>
          <GridItem width={[1]}>
            <Box paddingBottom={{ base: space[4], tablet: '44' }}>
              {firstName ? (
                <Heading as="h1" size="xl" textAlign="center">
                  Hey {firstName}, your
                  <br />
                  <span>care plan is ready</span>
                </Heading>
              ) : (
                <Heading as="h1" size="xl" textAlign="center">
                  Your care plan is ready
                </Heading>
              )}
            </Box>
          </GridItem>
          <Box display={{ base: '', tablet: 'flex' }}>
            <GridItem width={[1, 1 / 3]}>
              <Box paddingBottom={{ base: space[4], tablet: 0 }}>
                <CarePlanAccordion items={formattedAccordionItems} />
              </Box>
            </GridItem>
            <GridItem width={[1, 2 / 3]}>
              <Box
                marginLeft={{
                  base: 'calc(-50vw + 50%)',
                  tablet: space[6],
                  desktop: space[6],
                }}
                marginRight={{
                  base: 'calc(-50vw + 50%)',
                  tablet: 0,
                  desktop: 0,
                }}
              >
                <PlanSummary
                  date={date}
                  assessment={assessment}
                  physicianName={providerName}
                  programName={'UTI Virtual Care Visit'}
                />
              </Box>
            </GridItem>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default AthenaCarePlanPage;
