import {
  size,
  mediaQueries,
  H4,
  Container as LeavesContainer,
  typography,
  Button,
} from '@everlywell/leaves';
import styled, { css } from 'styled-components';

type ActionButtonProps = {
  hasCashPayDiscount: boolean;
};

export const Container = styled(LeavesContainer)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  text-align: center;
  max-width: 600px;
  padding: ${size.xl3}px 0 ${size.xl7}px;
`;

export const Heading = styled(H4)`
  ${typography.h4Text};
  text-align: center;
  margin: ${size.xl1}px 0 ${size.sm}px;

  ${mediaQueries.forDesktopUp} {
    ${typography.h3Text};
    margin-bottom: ${size.md}px;
  }
`;

export const Copy = styled.p`
  ${typography.bodyTextSmall};
  text-align: center;
  margin: 0 0 ${size.lg}px;
`;

export const ActionButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: ${size.md}px;
  row-gap: ${size.md}px;
  margin-bottom: ${size.lg}px;

  ${mediaQueries.forTabletHorizontalUp} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ActionButton = styled(Button)<ActionButtonProps>`
  ${({ hasCashPayDiscount }) =>
    !hasCashPayDiscount &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;
