export const RUM_ACTIONS = {
  SUBMIT_KIT_ID: 'Submit Kit ID',
  SUBMIT_CONFIRM_IDENTIFIERS: 'Submit Confirm Identifiers',
  SUBMIT_INFO: 'Submit Tester Info',
  SUBMIT_REVIEW: 'Submit Review',
  COVID_QUESTIONS: 'Covid Questionnaire Complete',
  COMPLETED: 'Registration Completed',
};

export const COVID_SCREENER_STEPS = {
  DESCRIBE_SYMPTOMS: 'Describes Your Symptoms',
  DATE_OF_SYMPTOMS: 'Date Of Symptoms',
  COVID_EXPOSURE: 'Covid Exposure',
  MULTI_QUESTIONS: 'Multiple Questions',
  HIGH_RISK: 'PreExisting Medical Issues',
  SEVERE_SYMPTOMS: 'Severe Symptoms',
  VACCINE: 'Covid Vaccine',
  VACCINE_DOSES: 'Vaccine Doses',
  NEW_YORK_STATE: 'New York',
};
