import { colors, size } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: ${size.xl5}px;
`;

export const Nav = styled.nav`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: ${size.xs3}px;
`;

export const Text = styled.p`
  text-align: center;
  color: ${colors.gray4};
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-top: ${size.xs2}px;
`;
