import { ToastType } from '@everlywell/leaves';
import { Notification as NotificationType } from 'common/utils/types';
import {
  ClientError,
  PaymentServerErrors,
} from 'components/Notification/NotificationContext';
import { useEffect, useState } from 'react';

export function errorCode(error?: ClientError) {
  if (!error) return false;

  const paymentServerErrorsValues = Object.values(PaymentServerErrors);
  const status = Number(error.code);
  let hasHttpError =
    paymentServerErrorsValues.some((value) => value === error.type) ||
    (400 <= status && status < 500) ||
    (500 <= status && status < 600);

  return hasHttpError;
}

export const useCastPaymentErrorToNotificationData = (
  error: ClientError | undefined,
) => {
  const [notificationData, setNotificationData] = useState<
    NotificationType | undefined
  >();

  useEffect(() => {
    if (errorCode(error)) {
      setNotificationData({
        id: 'error-type',
        message: error!.message,
        persist: false,
        source: error?.status || '',
        type: ToastType.ERROR,
      });
    } else {
      setNotificationData(undefined);
    }
  }, [error]);

  return notificationData;
};
