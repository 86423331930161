import { STATUSES } from 'common/utils/constants';
import {
  ImmutableReduxState,
  ImmutableContentSnippet,
  KitResult,
} from 'common/utils/types';
import { createSelector } from 'reselect';
import { initialState } from 'store/reducer';
import { ImmutableSessionState, SessionState } from 'store/session/types';

// Char used at the end of the contentKey to indicate an array of content
const ARRAY_INDICATOR = `*`;

function parseArrayOfSnippets(
  contentKey: string,
  snippets: ImmutableContentSnippet,
) {
  const contentItems = [];
  let x = 1;

  while (true) {
    // TODO: Is there a reason this is while(true)
    // rather than while(snippet) ? This throws an ESlint warning
    // @ts-ignore
    const snippet = snippets.get(contentKey.replace(/.$/, x));

    if (snippet) {
      contentItems.push(snippet);
      x += 1;
    } else {
      break;
    }
  }

  return contentItems;
}

export const selectSessionDomain = (state: ImmutableReduxState) =>
  state.get('session');

export const selectKitDomain = (state: ImmutableReduxState) =>
  state.get('entities').get('kits');

export const selectKitsDomain = (state: ImmutableReduxState) =>
  state.get('entities').get('kits').toJS();

export const selectLabDomain = (state: ImmutableReduxState) =>
  state.get('entities').get('labs');

export const selectMarkersDomain = (state: ImmutableReduxState) =>
  state.get('entities').get('markers');

export const selectMarkerResultDomain = (state: ImmutableReduxState) =>
  state.get('entities').get('marker_results');

export const selectPhysicianContactDomain = (state: ImmutableReduxState) =>
  state.get('entities').get('physician_contact');

export const selectTestDomain = (state: ImmutableReduxState) =>
  state.get('entities').get('tests');

export const selectContentSnippetsDomain = (state: ImmutableReduxState) =>
  state.get('entities').get('content_snippets');

export const selectGlobalDomain = (state: ImmutableReduxState) =>
  state.get('app');

export const selectUserDomain = (state: ImmutableReduxState) =>
  state.get('entities').get('users');

/// Make Selectors

export const makeSelectContent = (
  contentToken: string,
  contentKeys: { [prop: string]: string },
) =>
  createSelector([selectContentSnippetsDomain], (contentSnippets) => {
    const snippets = contentSnippets.get(contentToken);

    if (snippets) {
      return Object.keys(contentKeys).reduce((acc: any, prop) => {
        const contentKey: string = contentKeys[prop];
        const isArrayOfContent = contentKey.endsWith(ARRAY_INDICATOR);

        if (isArrayOfContent) {
          acc[prop] = parseArrayOfSnippets(contentKey, snippets);
        } else {
          // @ts-ignore
          acc[prop] = snippets.get(contentKey);
        }

        return acc;
      }, {});
    }

    return undefined;
  });

export const makeSelectLoadingContent = () =>
  createSelector(selectGlobalDomain, (globalState) =>
    globalState.get('loadingContent'),
  );

export const selectRouter = (state: ImmutableReduxState) => state.get('router');

export const selectGlobal = (state: ImmutableReduxState) =>
  state.get('app', initialState);

export const makeSelectLoading = () =>
  createSelector(selectGlobal, (globalState) => globalState.get('loading'));

export const makeSelectLoadingResults = () =>
  createSelector(selectGlobal, (globalState) =>
    globalState.get('loadingResults'),
  );

export const makeSelectError = () =>
  createSelector(selectGlobal, (globalState) => globalState.get('error'));

export const makeSelectErrorStatus = () =>
  createSelector(selectGlobal, (globalState) => globalState.get('errorStatus'));

export const makeSelectAuthenticated = () =>
  createSelector(selectGlobal, (globalState) =>
    globalState.get('authenticated'),
  );

export const makeSelectLocation = () =>
  createSelector(selectRouter, (routerState) =>
    routerState.get('location').toJS(),
  );

export const makeSelectNotification = () =>
  createSelector(selectGlobal, (globalState) =>
    globalState.get('notification'),
  );

export const makeSelectSession = () =>
  createSelector(selectSessionDomain, (globalState) => globalState.toJS());

export const makeSelectUser = () =>
  createSelector(
    makeSelectSession(),
    selectUserDomain,
    (session: SessionState, users: any) => {
      try {
        if (users) {
          return users.get(session.userId).toJS();
        }
      } catch (err) {
        return undefined;
      }
      return undefined;
    },
  );

export const makeSelectUserId = () =>
  createSelector(selectSessionDomain, (session: any) => session.get('userId'));

// DashboardContainer
//
//

export const makeSelectKitResults = () =>
  createSelector(selectKitsDomain, (kitResults) =>
    Object.keys(kitResults).map((kit) => kitResults[kit]),
  );

export const makeSelectInProgressKitResults = () =>
  createSelector(makeSelectKitResults(), (kitResults: KitResult[]) =>
    kitResults
      .filter((kitResult: KitResult) =>
        STATUSES.IN_PROGRESS.includes(kitResult.status),
      )
      .sort(
        (a: any, b: any) =>
          +new Date(b.kit_registered_at) - +new Date(a.kit_registered_at),
      ),
  );

export const makeSelectCompletedKitResults = () =>
  createSelector(makeSelectKitResults(), (kitResults: KitResult[]) =>
    kitResults
      .filter(
        (kitResult: KitResult) =>
          kitResult.status === STATUSES.RESULTS_APPROVED,
      )
      .sort(
        (a: any, b: any) =>
          +new Date(b.results_approved_at) - +new Date(a.results_approved_at),
      ),
  );

// PhysicianContactFormContainer
//
//

export const makeSelectPhysicianContact = () =>
  createSelector(selectPhysicianContactDomain, (physicianContact: any) => {
    const storeData = physicianContact.values().next().value;
    const physician = storeData ? storeData.toJS() : {};
    if (physician) {
      return {
        firstName: physician.first_name,
        lastName: physician.last_name,
        practiceName: physician.practice_name,
        address1: physician.address_1,
        address2: physician.address_2,
        city: physician.city,
        state: physician.state,
        zipCode: physician.zipcode,
        physicianPhone: physician.phone,
      };
    }
  });

export const makeSelectCanUpdatePhysicianContact = () =>
  createSelector(
    selectSessionDomain,
    selectUserDomain,
    (session: ImmutableSessionState, users: any) => {
      const userId = session.get('userId');
      const userData = users.get(userId);
      const user = userData ? userData.toJS() : {};
      const { can_update_physician_details } = user;

      return can_update_physician_details;
    },
  );

// Membership
//
//
// backend response still returns `sti` as the membershipType on the user
// we need to map that to its actual name for use with creating links to the page

const membershipNameMap: { [key: string]: 'control' | 'current' } = {
  sti: 'current',
  control: 'control',
};

export const makeSelectMembership = () =>
  createSelector(
    makeSelectSession(),
    selectUserDomain,
    (session: SessionState, users: any) => {
      try {
        if (users) {
          const user = users.get(session.userId).toJS();
          if (user) {
            const { membership } = user;
            return {
              activeMembership: membership
                ? membershipNameMap[membership.membership_type]
                : undefined,
              numOfMemberCredits: membership ? membership.active_credits : 0,
              state: membership ? membership.state : undefined,
            };
          }
        }
      } catch (err) {
        return undefined;
      }
      return undefined;
    },
  );

// Replacement Page
//
//

export const makeSelectShippingAddress = () =>
  createSelector(
    selectSessionDomain,
    selectUserDomain,
    (session: ImmutableSessionState, users: any) => {
      const userId = session.get('userId');
      const userData = users.get(userId);
      const user = userData ? userData.toJS() : {};
      const { ship_address = {} } = user;

      if (ship_address === null) {
        return undefined;
      }

      const {
        id,
        firstname,
        lastname,
        address1,
        address2,
        city,
        state_id,
        zipcode,
      } = ship_address;

      if (id) {
        return {
          id,
          firstName: firstname || '',
          lastName: lastname || '',
          address1: address1 || '',
          address2: address2 || '',
          city: city || '',
          state: state_id,
          zipCode: zipcode,
        };
      }
      return undefined;
    },
  );

// Settings Form
//
//

export const makeSelectAccountSettings = () =>
  createSelector(
    selectSessionDomain,
    selectUserDomain,
    (session: ImmutableSessionState, users: any) => {
      const userId = session.get('userId');
      const userData = users.get(userId);
      const user = userData ? userData.toJS() : {};
      const { consumer_attributes = {} } = user;

      // Looking for the `full_user_data` field that we explicitly
      // set to true when we fetch the user data ourselves. The
      // token request that gets run on authentication has partial
      // data, it won't have this field and we don't want this
      // selector running with that partial data.
      if (user.full_user_data) {
        return {
          id: user.id,
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email,
          phone: user.phone_number,
          dob: consumer_attributes.dob,
          biologicalSex: consumer_attributes.gender,
          wantsMarketing: consumer_attributes.wants_marketing,
          canUpdateEmail: user?.can_update_email ?? true,
        };
      }
    },
  );

export const makeSelectHasConsumerAttributes = () =>
  createSelector(
    selectSessionDomain,
    selectUserDomain,
    (session: ImmutableSessionState, users: any) => {
      const userId = session.get('userId');
      const userData = users.get(userId);
      const user = userData ? userData.toJS() : {};
      const { consumer_attributes = {} } = user;

      return !!Object.keys(consumer_attributes).length;
    },
  );

export const makeSelectShippingSettings = () =>
  createSelector(
    selectSessionDomain,
    selectUserDomain,
    (session: ImmutableSessionState, users: any) => {
      const userId = session.get('userId');
      const userData = users.get(userId);
      const user = userData ? userData.toJS() : {};
      const { ship_address = {} } = user;

      const {
        address1 = '',
        address2 = '',
        city = '',
        state_id = '',
        zipcode = '',
      } = ship_address;

      return {
        address1,
        address2,
        city,
        state: state_id,
        zipcode,
      };
    },
  );

// Share Subheader Button
//
//

export const makeSelectShareKitResult = (kitResultId: string) =>
  createSelector([selectKitDomain, selectTestDomain], (kits, tests) => {
    const kitResult = kits.get(kitResultId);
    const test = tests.get(`${kitResult.get('test')}`);

    return {
      testName: test.get('display_name'),
      publishHash: kitResult.get('publish_hash'),
    };
  });

// Take Action Section

export const makeSelectKitResultConsultAvailableUntil = (kitResultId: string) =>
  createSelector([selectKitDomain], (kits) => {
    const kitResult = kits.get(kitResultId);

    if (kitResult) {
      return kitResult.getIn(['consult', 'available_until']);
    }

    return undefined;
  });

export const makeSelectKitResultTestName = (kitResultId: string) =>
  createSelector([selectKitDomain, selectTestDomain], (kits, tests) => {
    const kitResult = kits.get(kitResultId);

    if (kitResult) {
      const test = tests.get(`${kitResult.get('test')}`);

      if (test) {
        return test.get('name');
      }
    }

    return undefined;
  });
