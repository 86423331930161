import Grid from 'components/Grid';
import { FormBuilderVariant } from 'components/telehealth/FormBuilder/utils/types';
import React, { Ref, useState } from 'react';
import { FieldErrors, RegisterOptions } from 'react-hook-form';

import * as S from './Height.styles';

export type HeightProps = {
  id: string;
  label: string | null;
  register<T>(rules?: RegisterOptions): (ref: (T & Ref<T>) | null) => void;
  errors: FieldErrors;
  variant: FormBuilderVariant;
  required?: boolean;
};

export const REQUIRED_FEET_ERROR = 'Please enter your height in feet';
export const REQUIRED_INCHES_ERROR = 'Please enter your height in inches';
export const INVALID_NUMBER_ERROR = 'Please enter a valid number';

/**
 * Component for demographics from with feet and inches for the height field
 */
export default function Height({
  id,
  label,
  register,
  errors,
  variant,
  required,
}: HeightProps) {
  const [feetValue, setFeetValue] = useState<number>();
  const [inchesValue, setInchesValue] = useState<number>();

  // Validation function feet field and inches field
  const isNotNegative = (value: string) =>
    parseInt(value) < 0 ? INVALID_NUMBER_ERROR : true;

  //  Backend expects values converted to inches
  const getValuesConvertedToInches = () => {
    if (!feetValue || !inchesValue) {
      return '';
    }

    const feetInInches = feetValue * 12;
    return feetInInches + inchesValue;
  };

  const tempFieldName = `${id}-tmp`;
  const error = errors?.[tempFieldName];

  return (
    <>
      <S.Label hasError={!!error} variant={variant}>
        {label}
      </S.Label>

      <Grid.Item width={[1, 1 / 4]}>
        <S.Input
          label="Feet"
          type="number"
          id={`${tempFieldName}.feet`}
          name={`${tempFieldName}.feet`}
          error={error?.feet?.message}
          onChange={(event) =>
            event?.target?.value && setFeetValue(parseFloat(event.target.value))
          }
          required={required}
          ref={register<HTMLInputElement>({
            required: REQUIRED_FEET_ERROR,
            validate: {
              isNotNegative: isNotNegative,
            },
          })}
        />
      </Grid.Item>
      <Grid.Item width={[1, 1 / 4]}>
        <S.Input
          label="Inches"
          type="number"
          id={`${tempFieldName}.inches`}
          name={`${tempFieldName}.inches`}
          error={error?.inches?.message}
          onChange={(event) =>
            event?.target?.value &&
            setInchesValue(parseFloat(event.target.value))
          }
          required={required}
          ref={register<HTMLInputElement>({
            required: REQUIRED_INCHES_ERROR,
            validate: {
              isNotNegative: isNotNegative,
            },
          })}
        />
      </Grid.Item>
      <input
        type="hidden"
        name={id}
        ref={register()}
        value={getValuesConvertedToInches()}
      />
    </>
  );
}
