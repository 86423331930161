import { MockedProvider } from '@apollo/client/testing';
import { render, screen } from '@testing-library/react';
import { PCP_QUESTIONS_QUERY } from 'common/utils/contentfulGraphQlQueries';
import React from 'react';
import { PCP_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';

import PCPQuestions from '..';

const sampleProps = {
  testType: 'thyroid',
  kitResultId: '1',
  pcp: true,
};

const data = {
  resultCollection: {
    items: [
      {
        productSlug: sampleProps.testType,
        pcpQuestionsCollection: {
          items: [
            {
              questions: ['hi'],
            },
          ],
        },
      },
    ],
  },
};

const mocks = [
  {
    request: {
      query: PCP_QUESTIONS_QUERY,
      variables: { productSlug: sampleProps.testType },
    },
    result: { data },
  },
];

const renderComponent = (props?: any) =>
  render(
    <MockedProvider mocks={mocks} addTypename={false}>
      <PCPQuestions {...sampleProps} {...props} />
    </MockedProvider>,
  );

describe('<PCPQuestions />', () => {
  describe('with a pcp', () => {
    beforeEach(() => {
      renderComponent();
    });

    it('should render the correct header when pcp is yes', async () => {
      const header = await screen.findByTestId(DATA_TEST.HEADER);
      expect(header).toHaveTextContent(
        /Plan on sharing these results with your Primary Care Provider?/,
      );
    });

    it('should render the correct sub header when pcp is yes', async () => {
      const sub = await screen.findByTestId(DATA_TEST.SUB_HEADER);
      expect(sub).toHaveTextContent(
        /Here are some questions you can ask during your next visit./,
      );
    });
  });

  describe('without a pcp', () => {
    beforeEach(() => {
      renderComponent({ pcp: false });
    });

    it('should render the correct header when pcp is no', async () => {
      const header = await screen.findByTestId(DATA_TEST.HEADER);
      expect(header).toHaveTextContent(
        /If you talk to a healthcare provider, here are some questions you can ask about your results/,
      );
    });

    it('should not render a sub header when pcp is no', async () => {
      const subHeader = screen.queryByTestId(DATA_TEST.SUB_HEADER);
      expect(subHeader).toBeNull();
    });
  });
});
