import { useSessionStorage } from 'common/hooks/useSessionStorage';
import { stateAbbreviationToNames } from 'common/utils/constants/states';
import {
  getCurrentDate,
  getCurrentTime,
  toTitleCase,
  isDateValid,
  formatPhoneNumber,
} from 'common/utils/helpers';
import {
  CovidVaccineData,
  KitRegistrationResponse,
  KitRegistrationUser,
} from 'common/utils/types';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useState } from 'react';

export const splitAndFormat = (str: string): string =>
  str.split('_').map(toTitleCase).join(' ');

const getPhoneNumber = (kitRegistrationResponse: KitRegistrationResponse) => {
  if (
    kitRegistrationResponse.consumer &&
    kitRegistrationResponse.consumer.phone_number
  ) {
    const formattedPhone = formatPhoneNumber(
      kitRegistrationResponse.consumer.phone_number,
    );
    return formattedPhone;
  }
  if (
    kitRegistrationResponse.user &&
    kitRegistrationResponse.user.user_address &&
    kitRegistrationResponse.user.user_address.phone
  ) {
    const formattedPhone = formatPhoneNumber(
      kitRegistrationResponse.user.user_address.phone,
    );
    return formattedPhone;
  }
  return '';
};

export const validDateFormat = (value: string) =>
  moment(value, 'MM/DD/YYYY', true).isValid() ||
  moment(value, 'YYYY-MM-DD', true).isValid();
const initiaCovidVaccineDatalValue: CovidVaccineData = {
  doses: 0,
  received: 'yes',
  additional_doses: [],
};

const useRegistrationUser: () => [
  KitRegistrationUser,
  (kitRegistration: KitRegistrationResponse) => void,
] = () => {
  const [addressCity, setAddressCity] = useState('');
  const [addressState, setAddressState] = useState('');
  const [addressZipCode, setAddressZipCode] = useState('');
  const [biologicalSex, setBiologicalSex] = useState('');
  const [congregateSetting, setCongregateSetting] = useState('');
  const [covidVaccineData, setCovidVaccineData] = useState<CovidVaccineData>(
    initiaCovidVaccineDatalValue,
  );
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [employerName, setEmployerName] = useState('');
  const [ethnicity, setEthnicity] = useState('');
  const [exposure, setExposure] = useState('');
  const [firstCovidTest, setFirstCovidTest] = useState('');
  const [firstName, setFirstName] = useState('');
  const [healthcareSetting, setHealthCareSetting] = useState('');
  const [highRisk, setHighRisk] = useState('');
  const [lastName, setLastName] = useState('');
  const [newYorkEmployment, setNewYorkEmployment] = useState('');
  const [newYorkResident, setNewYorkResident] = useState('');
  const [newYorkSchoolName, setNewYorkSchoolName] = useState('');
  const [newYorkStudent, setNewYorkStudent] = useState('');
  const [overrideAddress, setOverrideAddress] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pregnancyStatus, setPregnancyStatus] = useState('');
  const [race, setRace] = useState('');
  const [vaccineProduct, setVaccineProduct] = useState('');
  const [otherVaccineProduct, setOtherVaccineProduct] = useState('');
  const [vaccineDoses, setVaccineDoses] = useState('');
  const [vaccinated, setVaccinated] = useState('');
  const [vaccineDate, setVaccineDate] = useState('');
  const [vaccineDate2, setVaccineDate2] = useState('');

  const [sampleCollectionDate, setSampleCollectionDate] = useState(
    moment(getCurrentDate()).format('MM/DD/YYYY'),
  );
  const [sampleCollectionTime, setSampleCollectionTime] = useState(
    getCurrentTime(),
  );
  const [streetAddress, setStreetAddress] = useState('');
  const [subAddress, setSubAddress] = useState('');
  const [symptoms, setSymptoms] = useState('');
  const [symptomsDate, setSymptomsDate] = useState('');
  const [textUpdates, setTextUpdates] = useState(false);
  const [thirdPartyId, setThirdPartyId] = useState('');
  const [wantsMarketing, setWantsMarketing] = useState(true);

  const [consentCopy, setConsentCopy] = useSessionStorage('consentCopy', '');
  const [consentName, setConsentName] = useSessionStorage('consentName', '');
  const [consentOrganizationName, setConsentOrganizationName] =
    useSessionStorage('consentOrganizationName', '');
  const [consentTimestamp, setConsentTimestamp] = useSessionStorage(
    'consentTimestamp',
    '',
  );
  const [customTermsChecked, setCustomTermsChecked] = useSessionStorage(
    'customTermsChecked',
    false,
  );
  const [employerCity, setEmployerCity] = useSessionStorage('employerCity', '');
  const [employerState, setEmployerState] = useSessionStorage(
    'employerState',
    '',
  );
  const [employerStreetAddress, setEmployerStreetAddress] = useSessionStorage(
    'employerStreetAddress',
    '',
  );
  const [employerSubAddress, setEmployerSubAddress] = useSessionStorage(
    'employerSubAddress',
    '',
  );
  const [employerZipCode, setEmployerZipCode] = useSessionStorage(
    'employerAddressZipCode',
    '',
  );
  const [schoolCity, setSchoolCity] = useSessionStorage(
    'schoolAddressCity',
    '',
  );
  const [schoolState, setSchoolState] = useSessionStorage(
    'schoolAddressState',
    '',
  );
  const [schoolStreetAddress, setSchoolStreetAddress] = useSessionStorage(
    'schoolStreetAddress',
    '',
  );
  const [schoolSubAddress, setSchoolSubAddress] = useSessionStorage(
    'schoolSubAddress',
    '',
  );
  const [schoolZipCode, setSchoolZipCode] = useSessionStorage(
    'schoolAddressZipCode',
    '',
  );

  const user: KitRegistrationUser = {
    covidVaccineData: {
      value: covidVaccineData,
      setValue: setCovidVaccineData,
    },
    firstName: {
      value: firstName,
      setValue: setFirstName,
      name: 'firstName',
      id: 'firstName',
      required: true,
      validation: new RegExp('\\w+'),
      invalid: false,
      error: 'Please enter your first name',
    },
    lastName: {
      value: lastName,
      setValue: setLastName,
      name: 'lastName',
      id: 'lastName',
      required: true,
      validation: new RegExp('\\w+'),
      invalid: false,
      error: 'Please enter your last name',
    },
    dateOfBirth: {
      value: dateOfBirth,
      setValue: setDateOfBirth,
      name: 'dateOfBirth',
      id: 'dateOfBirth',
      required: true,
      validation: {
        test: (val: string) => !isDateValid(val),
      },
      invalid: false,
      error: 'Please enter your date of birth',
    },
    biologicalSex: {
      value: biologicalSex,
      setValue: setBiologicalSex,
      name: 'biologicalSex',
      id: 'biologicalSex',
      required: true,
      validation: new RegExp(/male$/),
    },
    streetAddress: {
      value: streetAddress,
      setValue: setStreetAddress,
      name: 'streetAddress',
      id: 'streetAddress',
      required: true,
      validation: new RegExp('\\w+'),
      invalid: false,
      error: 'Please enter your street address',
    },
    subAddress: {
      value: subAddress,
      setValue: setSubAddress,
      name: 'subAddress',
      id: 'subAddress',
      required: false,
    },
    addressCity: {
      value: addressCity,
      setValue: setAddressCity,
      name: 'addressCity',
      id: 'addressCity',
      required: true,
      validation: new RegExp('\\w+'),
      invalid: false,
      error: 'Please enter your city',
    },
    addressState: {
      value: addressState,
      setValue: setAddressState,
      name: 'addressState',
      id: 'addressState',
      required: true,
      validation: new RegExp('\\w+'),
      invalid: false,
      error: 'Please select a state',
    },
    addressZipCode: {
      value: addressZipCode,
      setValue: setAddressZipCode,
      name: 'addressZipCode',
      id: 'addressZipCode',
      required: true,
      validation: new RegExp('\\b(\\d{5})\\b'),
      invalid: false,
      error: 'Please enter your 5-digit zip code',
    },
    textUpdates: {
      value: textUpdates,
      checked: textUpdates,
      setValue: setTextUpdates,
      name: 'textUpdates',
      id: 'textUpdates',
      required: false,
    },
    customTerms: {
      value: customTermsChecked,
      checked: customTermsChecked,
      setValue: setCustomTermsChecked,
      name: 'customTerms',
      id: 'customTerms',
      required: false,
    },
    wantsMarketing: {
      value: wantsMarketing,
      checked: wantsMarketing,
      setValue: setWantsMarketing,
      name: 'wantsMarketing',
      id: 'wantsMarketing',
      required: false,
    },
    phoneNumber: {
      value: phoneNumber,
      setValue: setPhoneNumber,
      name: 'phoneNumber',
      id: 'phoneNumber',
      required: true,
      validation: new RegExp(/\(\d{3}\) \d{3}-\d{4}$/),
      invalid: false,
    },
    race: {
      value: race,
      setValue: setRace,
      name: 'race',
      id: 'race',
      required: false,
    },
    ethnicity: {
      value: ethnicity,
      setValue: setEthnicity,
      name: 'ethnicity',
      id: 'ethnicity',
      required: false,
    },
    pregnancyStatus: {
      value: pregnancyStatus,
      setValue: setPregnancyStatus,
      name: 'pregnancyStatus',
      id: 'pregnancyStatus',
      required: false,
    },
    emailAddress: {
      value: emailAddress,
      setValue: setEmailAddress,
      name: 'emailAddress',
      id: 'emailAddress',
      required: false,
    },
    thirdPartyId: {
      value: thirdPartyId,
      setValue: setThirdPartyId,
      name: 'thirdPartyId',
      id: 'thirdPartyId',
      required: false,
    },
    sampleCollectionTime: {
      value: sampleCollectionTime,
      setValue: setSampleCollectionTime,
      name: 'sampleCollectionTime',
      id: 'sampleCollectionTime',
      required: false,
    },
    sampleCollectionDate: {
      value: sampleCollectionDate,
      setValue: setSampleCollectionDate,
      name: 'sampleCollectionDate',
      id: 'sampleCollectionDate',
      required: false,
    },
    symptoms: {
      value: symptoms,
      setValue: setSymptoms,
    },
    symptomsDate: {
      value: symptomsDate,
      setValue: setSymptomsDate,
    },
    exposure: {
      value: exposure,
      setValue: setExposure,
    },
    firstCovidTest: {
      value: firstCovidTest,
      setValue: setFirstCovidTest,
    },
    healthcareSetting: {
      value: healthcareSetting,
      setValue: setHealthCareSetting,
    },
    congregateSetting: {
      value: congregateSetting,
      setValue: setCongregateSetting,
    },
    highRisk: {
      value: highRisk,
      setValue: setHighRisk,
    },
    vaccinated: {
      value: vaccinated,
      setValue: setVaccinated,
    },
    vaccineProduct: {
      value: vaccineProduct,
      setValue: setVaccineProduct,
    },
    otherVaccineProduct: {
      value: otherVaccineProduct,
      setValue: setOtherVaccineProduct,
    },
    vaccineDoses: {
      value: vaccineDoses,
      setValue: setVaccineDoses,
    },
    vaccineDate: {
      value: vaccineDate,
      setValue: setVaccineDate,
    },
    vaccineDate2: {
      value: vaccineDate2,
      setValue: setVaccineDate2,
    },
    consentTimestamp: {
      value: consentTimestamp,
      setValue: setConsentTimestamp,
    },
    consentCopy: {
      value: consentCopy,
      setValue: setConsentCopy,
    },
    consentName: {
      value: consentName,
      setValue: setConsentName,
    },
    consentOrganizationName: {
      value: consentOrganizationName,
      setValue: setConsentOrganizationName,
    },
    overrideAddress: {
      value: overrideAddress,
      setValue: setOverrideAddress,
    },
    newYorkResident: {
      value: newYorkResident,
      setValue: setNewYorkResident,
    },
    newYorkEmployment: {
      value: newYorkEmployment,
      setValue: setNewYorkEmployment,
    },
    newYorkStudent: {
      value: newYorkStudent,
      setValue: setNewYorkStudent,
    },
    employerName: {
      value: employerName,
      setValue: setEmployerName,
    },
    employerStreetAddress: {
      value: employerStreetAddress,
      setValue: setEmployerStreetAddress,
      name: 'employerStreetAddress',
      id: 'employerStreetAddress',
      required: false,
      validation: new RegExp('\\w+'),
      error: 'Please enter your street address',
    },
    employerSubAddress: {
      value: employerSubAddress,
      setValue: setEmployerSubAddress,
      name: 'subAddress',
      id: 'subAddress',
      required: false,
    },
    employerCity: {
      value: employerCity,
      setValue: setEmployerCity,
      name: 'employerCity',
      id: 'employerCity',
      required: false,
      validation: new RegExp('\\w+'),
      error: 'Please enter your city',
    },
    employerState: {
      value: employerState,
      setValue: setEmployerState,
      name: 'employerState',
      id: 'employerState',
      required: false,
      validation: new RegExp('\\w+'),
      error: 'Please select a state',
    },
    employerZipCode: {
      value: employerZipCode,
      setValue: setEmployerZipCode,
      name: 'employerZipCode',
      id: 'employerZipCode',
      required: false,
      error: 'Please enter your zip code',
    },
    newYorkSchoolName: {
      value: newYorkSchoolName,
      setValue: setNewYorkSchoolName,
    },
    schoolStreetAddress: {
      value: schoolStreetAddress,
      setValue: setSchoolStreetAddress,
      name: 'schoolStreetAddress',
      id: 'schoolStreetAddress',
      required: false,
      error: 'Please enter your street address',
    },
    schoolSubAddress: {
      value: schoolSubAddress,
      setValue: setSchoolSubAddress,
      name: 'subAddress',
      id: 'subAddress',
      required: false,
    },
    schoolCity: {
      value: schoolCity,
      setValue: setSchoolCity,
      name: 'schoolCity',
      id: 'schoolCity',
      required: false,
      error: 'Please enter your city',
    },
    schoolState: {
      value: schoolState,
      setValue: setSchoolState,
      name: 'schoolState',
      id: 'schoolState',
      required: false,
      error: 'Please select a state',
    },
    schoolZipCode: {
      value: schoolZipCode,
      setValue: setSchoolZipCode,
      name: 'schoolZipCode',
      id: 'schoolZipCode',
      required: false,
      error: 'Please enter your zip code',
    },
  };
  const setUser = (kitRegistration: KitRegistrationResponse) => {
    user.firstName.setValue(kitRegistration.user.first_name);
    user.lastName.setValue(kitRegistration.user.last_name);
    user.emailAddress.setValue(kitRegistration.user.email);
    user.phoneNumber.setValue(getPhoneNumber(kitRegistration));
    if (!isEmpty(kitRegistration.barcode?.issues)) {
      user.sampleCollectionDate.setValue('');
    }
    if (kitRegistration.user.user_address) {
      user.streetAddress.setValue(kitRegistration.user.user_address.street1);
      user.subAddress.setValue(
        kitRegistration.user.user_address.street2
          ? kitRegistration.user.user_address.street2
          : '',
      );
      user.addressCity.setValue(kitRegistration.user.user_address.city);
      user.addressState.setValue(
        stateAbbreviationToNames[kitRegistration.user.user_address.state],
      );
      user.addressZipCode.setValue(kitRegistration.user.user_address.zipcode);
    }
    if (kitRegistration.consumer) {
      user.dateOfBirth.setValue(
        moment(kitRegistration.consumer.dob).format('MM/DD/YYYY'),
      );
      user.textUpdates.setValue(kitRegistration.consumer.wants_marketing);
      user.biologicalSex.setValue(kitRegistration.consumer.gender);
      if (kitRegistration.covid) {
        user.race.setValue(
          kitRegistration.consumer.race
            ? splitAndFormat(kitRegistration.consumer.race)
            : user.race.value,
        );
        user.ethnicity.setValue(
          kitRegistration.consumer.ethnicity
            ? splitAndFormat(kitRegistration.consumer.ethnicity)
            : user.ethnicity.value,
        );
      }
    }
  };
  return [user, setUser];
};

export const useKitRegistration = (): [
  KitRegistrationResponse | undefined,
  (kitRegistration: KitRegistrationResponse) => void,
] => {
  const [value, setValue] = useSessionStorage('kitRegistration', '');
  const setKitRegistration = (kitRegistration: KitRegistrationResponse) => {
    setValue(JSON.stringify(kitRegistration));
  };
  return [value ? JSON.parse(value) : undefined, setKitRegistration];
};

export default useRegistrationUser;
