import { Col_10 as Col10 } from 'common/styles/grid-styles';
import { STATUSES, LEGACY_APP_ROOT, JWT_KEY } from 'common/utils/constants';
import { GENOMICS_REPORT } from 'common/utils/constants/dataTest';
import React from 'react';

import helixIcon from './images/helix.svg';
import * as S from './styles';

export type Props = {
  status: string;
  krNumber: string;
};

const GenomicsResultDetail = (props: Props) => {
  const { status, krNumber } = props;
  const jwt = window.localStorage.getItem(JWT_KEY);
  const isApproved = status === STATUSES.GENOMICS_APPROVED;
  return (
    <Col10>
      <S.CardWrapper>
        <S.TitleWrapper>
          <S.Title data-test={GENOMICS_REPORT.TITLE}>
            {isApproved
              ? 'Sequencing completed by'
              : 'Your Sample is Being Sequenced by'}
          </S.Title>
          <S.HelixIconWrapper>
            <img src={helixIcon} alt="Helix logo" />
          </S.HelixIconWrapper>
        </S.TitleWrapper>
        <S.BodyCopy data-test={GENOMICS_REPORT.INFO}>
          {isApproved
            ? 'Your genomic results are ready.'
            : 'Our partner Helix is now actively sequencing your DNA from the sample you provided. Not too much longer now!'}
        </S.BodyCopy>
        {isApproved && (
          <>
            <S.ViewGenomicReport
              data-test={GENOMICS_REPORT.VIEW_REPORT}
              to={`${LEGACY_APP_ROOT}/helix/genomics_reports/${krNumber}?jwt=${jwt}`}
              target="_blank"
            >
              <S.ViewGenomicReportButton>
                View your genomic report
              </S.ViewGenomicReportButton>
            </S.ViewGenomicReport>
          </>
        )}
      </S.CardWrapper>
    </Col10>
  );
};

export default GenomicsResultDetail;
