import {
  theme,
  Box,
  Button,
  Flex,
  Heading,
  Text,
  VStack,
  Icon,
  useDisclosure,
} from '@everlywell/ui-kit';
import { Stethoscope } from '@phosphor-icons/react';
import { CONTACT_CARE } from 'common/utils/constants';
import { sanitize } from 'common/utils/domHelpers';
import { hexToRGB } from 'common/utils/helpers';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';

const delay = '300ms';
const {
  space,
  colors: { tints },
  fontWeights,
} = theme;

export type PlanSummaryProps = {
  date: string;
  assessment: string;
  physicianName: string;
  programName: string;
};

function PlanSummary(props: PlanSummaryProps) {
  const { date, physicianName, assessment, programName: program } = props;
  const { isOpen, onToggle } = useDisclosure();
  const [dynamicHeight, setDynamicHeight] = useState('130px');
  const assessmentTextRef = useRef<HTMLDivElement>(null);
  const formattedAssessment = assessment ? parse(sanitize(assessment)) : '';

  const toggleView = () => onToggle();

  useEffect(() => {
    if (isOpen && assessmentTextRef.current) {
      setDynamicHeight(`${assessmentTextRef.current.scrollHeight}px`);
    } else {
      setDynamicHeight('130px');
    }
  }, [isOpen]);

  return (
    <Box
      backgroundColor="tints.white"
      borderColor="tints.lightCream"
      borderRadius={space[5]}
      borderWidth="1px"
      overflow="hidden"
      padding={`${space[8]} ${space[6]} ${space[12]}`}
    >
      <VStack align="stretch" spacing={[8, null, 6]}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          color="tints.uiGrey"
        >
          <Heading fontSize={['md', null, 'lg']} color="tints.uiGrey">
            Plan
          </Heading>

          <Heading fontSize={['md', null, 'lg']} color="tints.uiGrey">
            {program}
          </Heading>
        </Flex>

        <Box
          overflow="hidden"
          position="relative"
          height={dynamicHeight}
          transition={`height ${delay} ease-out`}
        >
          <Text ref={assessmentTextRef} fontSize={'xl'} color="tints.black">
            {formattedAssessment}
          </Text>
          <Box
            background={`linear-gradient(180deg, rgba(${hexToRGB(
              tints.white,
            )}, 0.00) 0%, ${tints.white} 100%)`}
            bottom="0"
            height="48px"
            left="0"
            pointerEvents="none"
            position="absolute"
            right="0"
            sx={{
              opacity: isOpen ? 0 : 1,
              transition: `opacity ${delay} ease-in-out`,
            }}
          />
        </Box>

        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Button
            colorScheme="greenCS"
            onClick={toggleView}
            size="md"
            variant="secondary"
            width={{ base: '100%', tablet: 'auto' }}
            sx={{
              '& svg': {
                fill: tints.white,
              },
            }}
          >
            {isOpen ? 'View less' : 'View more'}
          </Button>
        </Flex>

        <Box
          backgroundColor="tints.lightCream"
          padding={4}
          borderRadius="xl"
          width={['100%', null, '320px']}
        >
          <Flex alignItems="center">
            <Icon
              as={Stethoscope}
              backgroundColor="tints.darkCream"
              borderRadius="3xl"
              fill="tints.uiGrey"
              height={space[10]}
              padding={2}
              width={space[10]}
            />
            <Text
              marginLeft={2}
              fontSize="md"
              color="tints.black"
              fontWeight={fontWeights.medium}
            >
              with {physicianName}
              <br />
              <Text
                as="span"
                color="tints.uiGrey"
                fontSize="md"
                fontWeight={fontWeights.normal}
              >
                on {date}
              </Text>
            </Text>
          </Flex>
        </Box>

        <Text
          borderTop={`1px solid ${tints.lightGrey}`}
          color="tints.uiGrey"
          fontSize="sm"
          padding={`${space[6]} ${space[4]}`}
          paddingBottom={0}
          textAlign="center"
        >
          {CONTACT_CARE.INITIAL_TEXT}
          <Text
            as="a"
            fontSize="sm"
            color="viridian.base"
            href={`tel:${CONTACT_CARE.PHONE.VALUE}`}
          >
            {CONTACT_CARE.PHONE.NUMBER}
          </Text>
          {CONTACT_CARE.BOTTOM_TEXT}
        </Text>
      </VStack>
    </Box>
  );
}

export default PlanSummary;
