import { Input, size, Modal, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

const BaseModal = styled(Modal)`
  ${mediaQueries.forTabletHorizontalUp} {
    top: ${size.xl8}px;
  }
`;

const BaseInput = styled(Input)`
  svg {
    fill: none;
  }
  &&& {
    &[data-error] {
      ${mediaQueries.forPhoneOnly} {
        height: auto;
        margin-bottom: 20px;
      }
    }
    input {
      ${mediaQueries.forPhoneOnly} {
        ${props =>
          props.type === 'date' ? { 'min-height': `${size.xl3}px` } : {}};
      }
    }

    input::-webkit-calendar-picker-indicator {
      z-index: ${size.xs1};
      margin-right: ${size.md}px;
    }
  }
  span {
    opacity: ${props => (props.error ? 1 : 0)};
  }
`;

export { BaseInput, BaseModal };
