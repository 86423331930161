/* istanbul ignore file */
import { colors } from '@everlywell/leaves';
import { logError } from 'common/utils/helpers';
import React, { useEffect, useRef } from 'react';

import { SampleCardViewport } from '../model';
import * as S from './styles';

type Props = {
  line: any;
  title: any;
  viewport: SampleCardViewport;
  lineColor?: string;
  lineWidth?: number;
};

const ImageMarker = ({
  line,
  title,
  viewport,
  lineColor,
  lineWidth,
}: Props) => {
  const canvas: React.Ref<any> = useRef();

  useEffect(() => {
    let context: null | CanvasRenderingContext2D = null;

    // Get positions of lines from data's breakpoints
    const getLinesPositions = (viewport: SampleCardViewport) => {
      const { start, end } = line;

      let startX = start.x;
      let startY = start.y;
      let endX = end.x;
      let endY = end.y;

      switch (viewport) {
        case SampleCardViewport.mobile: {
          startX = start.xs;
          startY = start.ys;
          endX = end.xs;
          endY = end.ys;
          break;
        }
        case SampleCardViewport.tablet: {
          startX = start.xm;
          startY = start.ym;
          endX = end.xm;
          endY = end.ym;
          break;
        }

        default: {
          break;
        }
      }

      return { startX, startY, endX, endY };
    };

    const initializeCanvas = () => {
      const canvasEl: undefined | HTMLCanvasElement = canvas.current;
      if (canvasEl === undefined) return;
      canvasEl.width = canvasEl.clientWidth;
      canvasEl.height = canvasEl.clientHeight;

      context = canvasEl.getContext('2d');
    };

    const renderMarkers = () => {
      try {
        if (context === null) return;

        const { startX, startY, endX, endY } = getLinesPositions(viewport);

        context.beginPath();
        context.moveTo(startX, startY);
        context.lineTo(endX, endY);
        context.strokeStyle = lineColor || colors.teal6;
        context.lineWidth = lineWidth || 1;
        context.stroke();
      } catch (err) {
        const error = err as Error;
        logError(error.message, {
          component: 'ImageMarker',
          method: 'lineTo',
        });
      }
    };

    initializeCanvas();
    renderMarkers();
  }, [viewport, line, lineColor, lineWidth]);

  return (
    <S.Wrapper>
      {title && <S.Title title={title}>{title.copy}</S.Title>}

      {canvas && <canvas ref={canvas} />}
    </S.Wrapper>
  );
};

export default ImageMarker;
