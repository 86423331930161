import { H5, colors, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${size.md}px;
  gap: ${size.md}px;
  background: ${colors.white};
  border: 1px solid ${colors.teal1};
  box-shadow: 0 7px 30px -10px rgba(220, 228, 229, 0.5);
  border-radius: ${size.xs1}px;
`;

export const Heading = styled(H5)`
  margin: 0;
`;

export const Text = styled.p`
  ${typography.bodyTextSmall};
  text-align: center;
  margin: 0;

  a {
    color: ${colors.blue1};
    text-decoration: none;
    font-weight: inherit;
  }
`;
