import { useRef, useState, useEffect, RefObject } from 'react';

type RenderProps = {
  triggerEl: RefObject<HTMLDivElement>;
  iconEl: RefObject<HTMLDivElement>;
  isShown: boolean;
  tooltipId: string;
};

type Props = {
  tooltipId: string;
  children: (props: RenderProps) => JSX.Element;
};

function TooltipWrapper(props: Props) {
  const { children, tooltipId } = props;
  const triggerEl = useRef<HTMLDivElement>(null);
  const iconEl = useRef(null);
  const [isShown, setIsShown] = useState(false);

  function handleShow() {
    setIsShown(true);
  }

  function handleClose() {
    setIsShown(false);
  }

  useEffect(() => {
    if (!triggerEl.current) {
      return;
    }

    triggerEl.current.addEventListener('mouseover', handleShow);
    triggerEl.current.addEventListener('focus', handleShow);
    triggerEl.current.addEventListener('mouseout', handleClose);
    triggerEl.current.addEventListener('blur', handleClose);

    // eslint-disable-next-line consistent-return
    return () => {
      if (!triggerEl.current) {
        return;
      }

      triggerEl.current.removeEventListener('mouseover', handleShow);
      triggerEl.current.removeEventListener('focus', handleShow);
      triggerEl.current.removeEventListener('mouseout', handleClose);
      triggerEl.current.removeEventListener('blur', handleClose);
    };
  }, [triggerEl]);

  return children({
    triggerEl,
    iconEl,
    isShown,
    tooltipId,
  });
}

export default TooltipWrapper;
