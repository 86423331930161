import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { JourneyTaskType } from 'components/JourneyChecklist/JourneyChecklist';
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

import GreenCheckedCircle from '../../assets/green-check-circle.svg';
import GreenCircle from '../../assets/green-circle.svg';
import UnfilledGreenCircle from '../../assets/unfilled-light-green-circle.svg';
import JourneyTask from '../../components/JourneyTask';
import * as S from './JourneyStep.styles';

export type JourneyStepProps = {
  id: number;
  title: string;
  is_current: boolean;
  primary_description: string;
  secondary_description?: string | null;
  estimated_time_to_completion: string | null;
  tasks: JourneyTaskType[];
  currentStepIndex: number;
  stepIndex: number;
  stepsLength: number;
  handleUserTask: (id: number) => void;
};

/**
 * This component is to show the steps in a journey
 */
function JourneyStep({
  title,
  estimated_time_to_completion,
  is_current,
  primary_description,
  secondary_description,
  tasks,
  currentStepIndex,
  stepIndex,
  stepsLength,
  handleUserTask,
}: JourneyStepProps) {
  const [shouldShowMoreOuterContent, setShouldShowMoreOuterContent] =
    useState<boolean>(false);
  const [shouldShowMoreInnerContent, setShouldShowMoreInnerContent] =
    useState<boolean>(false);
  const isLastStep = stepIndex === stepsLength - 1;

  useEffect(() => {
    // Open current step's accordion on initial page load
    if (stepIndex === currentStepIndex) {
      setShouldShowMoreOuterContent(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAccordionClick = (accordionTitle: string) => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.WEIGHT_CARE.JOURNEY_CHECKLIST.OPEN_ACCORDION,
        category:
          ANALYTICS.LABELS.WEIGHT_CARE.DASHBOARD.JOURNEY_CHECKLIST_SECTION,
        cta: accordionTitle,
      },
    });
  };

  const displayIcon = (
    stepIndex: number,
    currentStepIndex: number,
    is_current: boolean,
  ) => {
    /* past step that is done */
    if (stepIndex < currentStepIndex && !is_current) {
      return <img src={GreenCheckedCircle} alt="green checked circle" />;
    }
    /* future steps */
    if (stepIndex > currentStepIndex && !is_current) {
      return <img src={UnfilledGreenCircle} alt="unfilled green circle" />;
    }
    /* current step */
    if (is_current) {
      return <img src={GreenCircle} alt="green circle" />;
    }
    return null;
  };

  return (
    <div id={stepIndex.toString()}>
      <S.AccordionTitle
        isPastOrFutureStep={stepIndex !== currentStepIndex}
        onClick={() => {
          handleAccordionClick(title);
          setShouldShowMoreOuterContent(!shouldShowMoreOuterContent);
        }}
      >
        <S.AccordionInnerTitle>
          <S.ProgressIconsWrapper>
            {displayIcon(stepIndex, currentStepIndex, is_current)}

            {/* do not show vertical dotted line on last step's icon */}
            {!isLastStep ? (
              <S.DottedLine shouldShowMore={shouldShowMoreOuterContent} />
            ) : null}
          </S.ProgressIconsWrapper>
          <S.InnerTitleWrapper>
            <S.InnerTitle>
              {title}
              {estimated_time_to_completion ? (
                <S.TimeDuration shouldShowMore={shouldShowMoreOuterContent}>
                  {estimated_time_to_completion}
                </S.TimeDuration>
              ) : null}
            </S.InnerTitle>
            <S.SkinnyChevronIcon shouldShowMore={shouldShowMoreOuterContent} />
          </S.InnerTitleWrapper>
        </S.AccordionInnerTitle>
      </S.AccordionTitle>

      {/* Inside content of accordion */}
      <S.HiddenAccordionChild
        shouldShowMore={shouldShowMoreOuterContent}
        addBorder={!isLastStep}
      >
        <S.AccordionCopy>
          <ReactMarkdown className="line-break">
            {`${primary_description}`}
          </ReactMarkdown>
          {secondary_description ? (
            <S.ReadMoreTitle
              onClick={() => {
                handleAccordionClick(`${title} - Read more`);
                setShouldShowMoreInnerContent(!shouldShowMoreInnerContent);
              }}
            >
              Read more
              <S.SolidChevronIcon shouldShowMore={shouldShowMoreInnerContent} />
            </S.ReadMoreTitle>
          ) : null}
        </S.AccordionCopy>
        {secondary_description ? (
          <S.HiddenReadMoreAccordionChild
            shouldShowMore={shouldShowMoreInnerContent}
          >
            <S.AccordionCopy>
              <ReactMarkdown className="line-break">
                {secondary_description}
              </ReactMarkdown>
            </S.AccordionCopy>
          </S.HiddenReadMoreAccordionChild>
        ) : null}
        <S.TasksSection hasTasks={tasks.length > 0}>
          {tasks.length > 0 &&
            tasks.map((task, i) => (
              <JourneyTask
                key={`task-${i}`}
                handleUserTask={handleUserTask}
                {...task}
              />
            ))}
        </S.TasksSection>
      </S.HiddenAccordionChild>
    </div>
  );
}

export default JourneyStep;
