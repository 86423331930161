import {
  Accordion,
  breakpoints,
  colors,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

type HeadingProps = {
  isOpened: boolean;
};

export const Wrapper = styled.div`
  margin-bottom: ${size.lg}px;
  box-shadow: 0 ${size.xs3}px ${size.md}px 0 rgba(0, 0, 0, 0.05);
  ${mediaQueries.forDesktopUp} {
    max-width: ${breakpoints.forDesktopDown}px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyledDesktopHeading = styled(Accordion.Heading)<HeadingProps>`
  display: none;
  border-radius: ${(props) => (props.isOpened ? '8px 8px 0 0' : '8px')};
  background-color: ${colors.green5};
  color: white;
  padding: ${size.md}px ${size.lg}px;
  ${mediaQueries.forDesktopUp} {
    height: 52px;
  }
  ${mediaQueries.forTabletHorizontalUp} {
    display: flex;
  }

  & > div {
    height: ${(props) => (props.isOpened ? 'unset' : '7px')};
    width: ${(props) => (props.isOpened ? 'unset' : '13px')};
    margin-top: ${(props) => (props.isOpened ? 'unset' : '25px')};
  }
`;

export const StyledFooterHeading = styled(Accordion.Heading)`
  border-radius: 8px;
  color: ${colors.green5};
  padding: ${size.md}px ${size.lg}px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 ${size.xs3}px ${size.md}px 0 rgba(${colors.gray1}, 0.05);
  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
  }
`;

export const StyledMobileHeading = styled.div`
  background-color: ${colors.green5};
  color: white;
  border-radius: 8px 8px 0 0;
  padding: ${size.md}px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 24px;

  img {
    margin-right: ${size.sm}px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
  }
`;

export const StyledText = styled.span`
  font-size: 18px;
  line-height: 24px;
  margin-right: ${size.md}px;
  margin-left: ${size.md}px;
  text-align: left;
  width: 100%;
`;

export const ChildText = styled.p`
  font-size: 16px;
  line-height: 1.78;
  font-weight: 300;

  a {
    color: ${colors.blue1};
    font-weight: ${typography.weight.bold};
    text-decoration: none;
  }
`;

export const CenteredText = styled.span`
  font-size: 18px;
  line-height: 24px;
  margin-right: ${size.md}px;
  text-align: center;
`;

export const StyledDescriptionChild = styled(Accordion.Child)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${size.lg}px;
  background-color: ${colors.white};
  border: 0.5px solid ${colors.gray1};
  line-height: 1.78;
  color: rgb(72, 72, 72);
  font-weight: 300;
  border-radius: 0 0 8px 8px;

  img {
    width: 48px;
    margin-bottom: ${size.md}px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row-reverse;
    background-color: ${colors.white};
    min-height: 52px;
    padding: ${size.lg}px ${size.lg}px ${size.lg}px 65px;

    img {
      margin-right: ${size.xl3}px;
      margin-left: ${size.lg}px;
    }
  }
`;
