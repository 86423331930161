import { LocalizeIgnore } from '@everlywell/leaves';
import React from 'react';

import * as S from './styles';

export type RegistrationContent = {
  title: string;
  subtitle: JSX.Element;
  inputLabel: string;
  disclaimer: string;
  registerLabel: string;
};

export const AT_HOME_REGISTRATION: RegistrationContent = {
  title: 'Kit registration',
  subtitle: (
    <S.Subtitle>
      Enter the Unique <LocalizeIgnore>Kit ID</LocalizeIgnore> or use your
      mobile device to scan the QR Code located on the registration slip inside
      of your kit.&nbsp;
    </S.Subtitle>
  ),
  inputLabel: 'KIT ID',
  disclaimer: 'The person registering the kit must be the test taker.',
  registerLabel: 'Next',
};

export const IN_LAB_REGISTRATION: RegistrationContent = {
  title: 'Lab request registration',
  subtitle: (
    <S.Subtitle>
      Enter the Unique <LocalizeIgnore>lab request ID</LocalizeIgnore> you
      received in your order confirmation email or click the button in the email
      to fill in your ID.&nbsp;
    </S.Subtitle>
  ),
  inputLabel: 'LAB REQUEST ID',
  disclaimer: 'The person registering the test must be the test taker.',
  registerLabel: 'Next',
};
