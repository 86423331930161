/* istanbul ignore file */
import useApi from 'common/hooks/useApi';
import { Redirection } from 'common/hooks/useHealthProfile/types';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { logError } from 'common/utils/helpers';
import {
  HealthProfileQuestion,
  HealthProfileQuestionnaire,
} from 'common/utils/types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { useNavigationQuestion } from '../../common/providers/NavigationQuestionProvider';
import { FilterParams, FilterProps, filterQuestionnaire } from './helpers';

export type KeyValue = {
  [QuestionnaireQuestionId: string]: HealthProfileQuestion;
};
export type KeyValueQuestionsSet = {
  answered?: boolean;
  all: KeyValue;
};

type Props = {
  pathname: string;
  redirectToParam: string | null | undefined;
  setRedirect: (url: string) => void;
};

type RedirectToParams = {
  has_started: boolean;
  redirection: Redirection;
  redirectToParam: string | null | undefined;
  setRedirectionURL: (url: string) => void;
};

export const useFilterQuestions = ({
  keyValueQuestionsSetAll,
  questions,
  unansweredParam,
}: FilterProps): FilterParams => {
  const [filterQuestions, setFilterParams] = useState<FilterParams>(questions);
  useEffect(() => {
    const filterQuestions = filterQuestionnaire({
      keyValueQuestionsSetAll,
      questions,
      unansweredParam,
    });
    setFilterParams(() => filterQuestions);
  }, [questions, keyValueQuestionsSetAll, unansweredParam]);

  return filterQuestions;
};

export const useInitialEffects = ({
  pathname,
  redirectToParam,
  setRedirect,
}: Props) => {
  useEffect(() => {
    if (redirectToParam) {
      setRedirect(redirectToParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectToParam]);

  useEffect(() => {
    async function track() {
      try {
        await analytics.track({
          event: ANALYTICS.EVENTS.VIEWED_PAGE,
          data: { label: 'Health Profile' },
        });
      } catch (err) {
        logError((err as Error).message, {
          errorInfo: 'Error tracking viewed health profile complete page',
          component: 'HealthProfile',
          method: 'track',
        });
      }
    }

    track();
  }, [pathname]);
};

export const useCastQuestions = (questions: HealthProfileQuestion[]) => {
  const [keyValueQuestions, setKeyValueQuestions] = useState<KeyValue>({});
  useEffect(() => {
    questions.forEach((question) => {
      const qQId = question.questionnaire_question_id.toString();
      setKeyValueQuestions((obj) => ({ ...obj, [qQId]: question }));
    });
  }, [questions]);

  return keyValueQuestions;
};

export const useInitialCurrentQuestion = (
  questions: HealthProfileQuestion[],
  questionnaireId: number,
  firstQQId: number | false,
  urlChunk: string,
) => {
  const { setCurrentQuestion } = useNavigationQuestion();
  const navigate = useNavigate();

  useEffect(() => {
    if (questions && questions[0]?.questionnaire_question_id) {
      const FQ = questions[0].questionnaire_question_id;
      setCurrentQuestion(() => FQ);
      setCurrentQuestion(() => questions[0].questionnaire_question_id);

      navigate(`/health-profile/${questionnaireId}/${firstQQId}/1${urlChunk}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, setCurrentQuestion]);
};

export const useQuestionsCounter = (
  questions: HealthProfileQuestion[],
): string => {
  const { totalQuestions, setTotalQuestions } = useNavigationQuestion();
  const [questionsCounter, setQuestionsCounter] = useState<string>('');

  useEffectOnce(() => {
    const total = questions.filter((q) => q.root).length;
    setTotalQuestions(() => total);
    setQuestionsCounter(() => total.toString());
  });

  useEffect(() => {
    const total = questions.filter((q) => q.root).length;
    if (totalQuestions !== total) {
      setTotalQuestions(() => total);
      setQuestionsCounter(() => total.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions.length]);

  useEffect(() => {
    if (questionsCounter !== '' && totalQuestions !== 0) {
      setQuestionsCounter(totalQuestions.toString());
    }
  }, [totalQuestions, questionsCounter]);
  return questionsCounter;
};

export const useHealthProfileData = (reEntryParam?: string | null) => {
  const { fetchQuestionnaire } = useApi();
  const [healthProfileData, setHealthProfileData] = useState<
    HealthProfileQuestionnaire | undefined
  >();
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    const loadHealthProfileData = async () => {
      try {
        const healthProfileData: HealthProfileQuestionnaire =
          await fetchQuestionnaire();
        setHealthProfileData(() => healthProfileData);
        setFetched(() => true);
      } catch (err) {
        logError((err as Error).message, {
          errorInfo: 'Error fetching health profile',
          component: 'HealthProfile',
          method: 'loadHealthProfileData',
        });
      }
    };
    loadHealthProfileData();
  }, [fetchQuestionnaire, reEntryParam]);

  return {
    healthProfileData,
    fetched,
  };
};

export const useRedirectToParam = ({
  has_started,
  redirection,
  redirectToParam,
  setRedirectionURL,
}: RedirectToParams) => {
  useEffect(() => {
    if (redirectToParam && redirectToParam !== redirection) {
      setRedirectionURL(redirectToParam);
      if (has_started && redirectToParam) {
        window.location.href = redirectToParam;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectToParam]);
};
