import { formatPhoneNumber } from 'common/utils/helpers';
import { justTheNumbers } from 'components/Forms/validations';
import Grid from 'components/Grid/Grid';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import validator from 'validator';

import { Input, labelStyles } from '../../utils/styles';
import { FormBuilderField } from '../../utils/types';

export type PhoneFieldProps = FormBuilderField;

function PhoneField(props: PhoneFieldProps) {
  const { control, errors } = useFormContext();

  return (
    <Grid.Item width={[1]}>
      <Controller
        control={control}
        name={props.id}
        defaultValue=""
        rules={{
          required: props.required && 'Please enter a phone number',
          validate: {
            isPhoneNumber: (value: string) => {
              if (!props.required && value === '') return true;
              if (justTheNumbers(value ?? '').length < 10) {
                return 'Please complete your phone number';
              } else if (!validator.isMobilePhone(value)) {
                return 'Please enter a valid phone number';
              } else {
                return true;
              }
            },
          },
        }}
        render={(field) => (
          <Input
            css={labelStyles(props.variant)}
            id={field.name}
            name={field.name}
            placeholder="(000) 000-0000"
            label={props.label ?? ''}
            ref={field.ref}
            required={props.required}
            onChange={(e) => {
              const value = e.target.value;
              if (value) {
                const formattedValue = formatPhoneNumber(justTheNumbers(value));
                field.onChange(formattedValue);
              } else {
                field.onChange(value);
              }
            }}
            onBlur={field.onBlur}
            value={field.value}
            error={errors[props.id]?.message}
          />
        )}
      />
    </Grid.Item>
  );
}

export default PhoneField;
