import { KitResult } from 'common/utils/types';
import { uniqueId } from 'lodash';

const baseKitResult: KitResult = {
  id: 1785428,
  kit_registered_at: '2020-11-20T02:28:07.598Z',
  lab_received_at: '2020-11-20T02:28:07.598Z',
  in_transit_at: '2020-11-20T02:28:07.598Z',
  results_approved_at: '2020-11-20T02:28:07.598Z',
  max_process_time: 3,
  min_process_time: 1,
  needs_replacement: false,
  number: 'KR146A7F9B9A69478FB6B34108DA594D',
  product_name: 'HbA1c Test - Medicare Advantage',
  status: 'registered',
  test_id: 77,
  viewed_at: '',
  tracking_url: '#',
  serial_number: 12314123,
  user_email: 'test@everlywell.com',
  kit_result_time: '2020-11-20T02:28:07.598Z',
  content: 'test',
  product_slug: 'test',
  clia: 'ABC1234567',
  test: {
    id: 77,
    name: 'STD Test - Female',
    display_name: 'Sexual Health Test - Female',
    type: 'std-test-female',
    content_token: '8ae2c629-2ec4-4c4b-9ea7-9f25124daf78',
    marker_types: ['descriptive'],
    genomics_eligible: false,
    is_food_sensitivity: false,
    content: {
      TEST_OVERVIEW: 'STD Test - Female',
      RESULTS_SECTION_HEADER: 'test',
    },
    theme_icon: 'other',
    recorded_webinar: false,
  },
  marker_results: [
    {
      id: 13787558,
      marker_id: 281,
      value: 'Detected',
      severity_index: 2,
      descriptor: 'detected',
      test_result_id: 237410,
      boundaries: [],
      boundary_conditions: [],
      reported_at: '2018-06-14T00:15:10.000Z',
      kind: 'descriptive',
      observation_datetime: null,
      content_token: 'b1215335-6399-42d5-9b34-8b4520b8fa7f',
    },
  ],
};

export const buildKitResult = (props: Partial<KitResult> = {}): KitResult => ({
  ...baseKitResult,
  id: parseInt(uniqueId()),
  number: uniqueId('KITRESULT'),
  ...props,
});
