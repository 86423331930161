import { colors, H1, mediaQueries, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  min-height: 730px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 ${size.md}px;

  ${mediaQueries.forTabletVerticalUp} {
    margin: 0 auto;
    max-width: 80%;
    padding: ${size.lg}px 0 0 ${size.lg}px;
  }

  ${mediaQueries.forDesktopUp} {
    padding: ${size.xl1}px 0 0 ${size.xl1}px;
  }

  ${mediaQueries.forPhoneOnly} {
    margin: 0;
    height: 100%;
    min-height: auto;
    overflow: hidden;
  }
`;

export const ImageContainer = styled.div`
  overflow: hidden;
  display: flex;
  width: auto;
  justify-content: center;
  padding: 0 ${size.md}px ${size.lg}px 0;

  ${mediaQueries.forTabletVerticalUp} {
    width: 40%;
    padding: 0 ${size.lg}px ${size.lg}px 0;
  }

  ${mediaQueries.forDesktopUp} {
    width: auto;
    padding: 0 ${size.xl1}px ${size.xl1}px 0;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 0 ${size.xl1}px;
  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: column;
  }
`;

export const ContentContainer = styled.div`
  color: ${colors.gray4};
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 0 ${size.md}px ${size.lg}px 0;

  ${mediaQueries.forTabletVerticalUp} {
    margin: 0 auto;
    max-width: 80%;
    flex: 1;
    flex-direction: column;
    padding: 0 ${size.sm}px ${size.xs1}px 0;
  }

  ${mediaQueries.forDesktopUp} {
    width: auto;
    flex: 1;
  }
`;

export const CTAContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  padding: 0 ${size.md}px ${size.md}px 0;

  ${mediaQueries.forTabletVerticalUp} {
    width: 37.5%;
    padding: 0 ${size.lg}px ${size.lg}px 0;
  }

  ${mediaQueries.forDesktopUp} {
    width: 25%;
    padding: 0 ${size.xl1}px ${size.xl1}px 0;
  }
`;

export const CardHeader = styled(H1)`
  font-weight: ${typography.weight.book};
  font-size: 33px;
  line-height: 2.75rem;
  letter-spacing: 0.25px;
  color: ${colors.teal4};
  text-align: center;
  margin: 0 0 ${size.lg}px 0;

  ${mediaQueries.forTabletVerticalDown} {
    font-size: 25px;
  }
`;

export const Text = styled.p`
  ${typography.bodyText};
  color: ${colors.gray5};
  text-align: center;
  margin: 0 0 ${size.md}px;
  ${mediaQueries.forTabletVerticalUp} {
    color: ${colors.gray4};
  }
`;

export const CustomLink = styled.a`
  text-decoration: none;
  font-weight: 700;
  color: ${colors.green4};
`;
