import { SkeletonLoader, SkeletonUnit } from '@everlywell/leaves';
import Grid from 'components/Grid';
import React from 'react';

import ProviderImage from './assets/telehealth-interstitial-image.png';
import * as S from './InterstitialPage.styles';

const InterstitialPageSkeleton = () => {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    // avoid showing the skeleton for only a split second
    const timeout = setTimeout(() => {
      setShow(true);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <S.Container css={!show ? { visibility: 'hidden' } : {}}>
      <Grid.Container spacing={['lg']}>
        <Grid.Item
          width={[1]}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <S.Image src={ProviderImage} alt="" />
        </Grid.Item>

        <Grid.Item width={[1]}>
          <SkeletonLoader
            width={{
              value: 100,
              unit: SkeletonUnit.Percentage,
            }}
            height={{
              value: 30,
              unit: SkeletonUnit.Pixel,
            }}
          />
          <SkeletonLoader
            width={{
              value: 100,
              unit: SkeletonUnit.Percentage,
            }}
            height={{
              value: 30,
              unit: SkeletonUnit.Pixel,
            }}
          />
        </Grid.Item>

        <Grid.Item width={[1]}>
          <SkeletonLoader
            width={{
              value: 120,
              unit: SkeletonUnit.Pixel,
            }}
            height={{
              value: 20,
              unit: SkeletonUnit.Pixel,
            }}
          />
          <SkeletonLoader
            width={{
              value: 100,
              unit: SkeletonUnit.Percentage,
            }}
            height={{
              value: 50,
              unit: SkeletonUnit.Pixel,
            }}
          />
        </Grid.Item>

        <Grid.Item width={[1]}>
          <SkeletonLoader
            width={{
              value: 100,
              unit: SkeletonUnit.Percentage,
            }}
            height={{
              value: 50,
              unit: SkeletonUnit.Pixel,
            }}
          />
        </Grid.Item>
      </Grid.Container>
    </S.Container>
  );
};

export default InterstitialPageSkeleton;
