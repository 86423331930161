import { useQuery } from '@apollo/client';
import analytics from 'common/utils/analytics';
import { LEGACY_APP_ROOT } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { PCP_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';
import { PCP_QUESTIONS_QUERY } from 'common/utils/contentfulGraphQlQueries';
import { hasRatedPersonalization } from 'common/utils/helpers';
import { logError } from 'common/utils/helpers';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import React, { useRef, useState, useEffect } from 'react';

import PersonalizationCheckIn from '../../PersonalizationCheckIn';
import PcpPersonImage from '../images/pcp_person.png';
import * as S from './styles';

type Props = {
  testType?: string;
  kitResultId: string;
  pcp: boolean;
};

type Item = {
  questions: string[];
};

function PCPQuestions(props: Props) {
  const { testType, kitResultId, pcp } = props;
  const { data, error } = useQuery(PCP_QUESTIONS_QUERY, {
    variables: { productSlug: testType },
  });
  const doctorPDFUrl = `${LEGACY_APP_ROOT}/results/${kitResultId}/doctor_results.pdf`;
  const contentRef = useRef<HTMLDivElement>(null);
  const [questions, setQuestions] = useState<string[]>([]);

  useEffect(() => {
    if (error) {
      logError((error as Error).message, {
        errorInfo: 'Contenful GraphQL Query Error',
        component: 'PCPQuestions',
        method: 'PCPQuestions',
      });
      return;
    }
    const product = data?.resultCollection?.items[0];
    const collected = product?.pcpQuestionsCollection?.items?.reduce(
      (acc: string[], item: Item) => [...acc, ...item.questions],
      [],
    );
    if (collected) setQuestions(collected);
  }, [testType, data, error]);

  const pcpQuestionsViewed = useScrolledIntoViewOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: pcp
          ? ANALYTICS.LABELS.PCP_QUESTIONS
          : ANALYTICS.LABELS.NO_PCP_QUESTIONS,
      },
    });
  });

  function handleSaveResultsClick() {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: pcp
          ? ANALYTICS.LABELS.PCP_QUESTIONS_SAVE_RESULTS
          : ANALYTICS.LABELS.NO_PCP_QUESTIONS_SAVE_RESULTS,
      },
    });

    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.DOWNLOAD_PDF_REPORT,
      },
    });
  }

  if (!questions.length) return null;

  const YesHeader = () => (
    <>
      <S.Header data-test={DATA_TEST.HEADER}>
        Plan on sharing these results with your Primary Care Provider?
      </S.Header>
      <S.SubHeader data-test={DATA_TEST.SUB_HEADER}>
        Here are some questions you can ask during your next visit.
      </S.SubHeader>
    </>
  );

  const NoHeader = () => (
    <S.Header data-test={DATA_TEST.HEADER}>
      If you talk to a healthcare provider, here are some questions you can ask
      about your results
    </S.Header>
  );

  const widgetLabel = pcp
    ? ANALYTICS.LABELS.PCP_QUESTIONS
    : ANALYTICS.LABELS.NO_PCP_QUESTIONS;

  return (
    <S.Wrapper data-test={DATA_TEST.CONTAINER}>
      <S.PCPContent>
        <S.ImageColumn xs>
          <S.PCPImage src={PcpPersonImage} />
        </S.ImageColumn>
        <S.Col lg>
          {pcp ? <YesHeader /> : <NoHeader />}
          <div ref={contentRef}>
            <S.QuestionList ref={pcpQuestionsViewed}>
              <ol>
                {questions.map((q: string) => (
                  <li key={q}>{q}</li>
                ))}
              </ol>
            </S.QuestionList>
          </div>
        </S.Col>
      </S.PCPContent>

      <S.PCPCta
        appearance="secondary"
        // @ts-ignore
        target="_blank"
        href={doctorPDFUrl}
        data-test={DATA_TEST.DOCTOR_PDF}
        onClick={handleSaveResultsClick}
      >
        Save Results
      </S.PCPCta>
      {!hasRatedPersonalization(widgetLabel) ? (
        <PersonalizationCheckIn widget={widgetLabel} hasToolTip />
      ) : null}
    </S.Wrapper>
  );
}

export default PCPQuestions;
