import { mediaQueries, size } from '@everlywell/leaves';
import styled from 'styled-components';

export const InnerContent = styled.div`
  margin: 0 auto;
`;

export const PageContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${size.md}px;
  ${mediaQueries.forTabletVerticalUp} {
    gap: ${size.lg}px;
  }
`;

export const Section = styled.div`
  width: 100%;
`;

export const HiddenExtoleSpanForModalDisplay = styled.span`
  display: none;
`;
