import { format, parseISO } from 'date-fns';
import React from 'react';

import * as S from './TestKitCard.styles';

// Types ----------

export interface TestKitCardProps {
  title: string;
  registerDate?: string;
  imageURL?: string;
}

// TestKitCard ------

const TestKitCard: React.FC<TestKitCardProps> = (props) => {
  const { title, registerDate, imageURL } = props;

  const date = format(parseISO(registerDate!), 'MM/dd/yy');

  return (
    <S.Wrapper>
      <S.Content>
        <S.Image src={imageURL} />

        <S.ContentWrapper>
          <S.Title>{title}</S.Title>
          <S.RegisterDate>{`Registered on ${date}`}</S.RegisterDate>
        </S.ContentWrapper>
      </S.Content>

      <S.InteractionWrapper>
        <S.Button
          href="https://support.everlywell.com/article/507-sample-collection-videos"
          appearance="primary"
        >
          View instructions
        </S.Button>
      </S.InteractionWrapper>

      {/* NOTE: Temporarily removed for the first iteration of the card */}
      {/* <S.ToggleButton>
        <S.Chevron />
      </S.ToggleButton> */}
    </S.Wrapper>
  );
};

// Export ---------

export default React.memo(TestKitCard);
