import { CollectionDeviceImage } from 'common/utils/types';
import React from 'react';

import CollectionMethodStep from './CollectionStep';
import { StepsIconType, StepsItemsPerRow } from './model';
import * as S from './styles';

type Props = {
  collectionMethodsList: CollectionDeviceImage[];
  icon?: StepsIconType | undefined;
  itemsPerRow?: StepsItemsPerRow;
};

const CollectionMethodSteps = ({
  icon,
  collectionMethodsList,
  itemsPerRow,
}: Props) => (
  <S.Steps>
    {collectionMethodsList.map((collectionMethod, i) => (
      <CollectionMethodStep
        key={i.toString()}
        collectionMethodStep={collectionMethod}
        icon={icon}
        width={itemsPerRow ? 100 / Number(itemsPerRow) : 45}
      />
    ))}
  </S.Steps>
);

export default CollectionMethodSteps;
