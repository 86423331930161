import React from 'react';

import * as S from './InfoIcon.styles';

export type BadgeIconProps = {};

/**
 * The icon displayed when the health profile is completed
 */
function BadgeIcon(props: BadgeIconProps) {
  return (
    <S.SVG
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.875 11.5C10.875 10.9477 11.3227 10.5 11.875 10.5H12.125C12.6773 10.5 13.125 10.9477 13.125 11.5V17.75C13.125 18.3023 12.6773 18.75 12.125 18.75H11.875C11.3227 18.75 10.875 18.3023 10.875 17.75V11.5Z"
        fill="#007377"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.875 7.125C10.875 6.82292 10.9797 6.5599 11.1891 6.33594C11.3985 6.11198 11.664 6 11.9854 6C12.3068 6 12.5771 6.10677 12.7963 6.32031C13.0154 6.53386 13.125 6.80208 13.125 7.125C13.125 7.44792 13.0154 7.71614 12.7963 7.92969C12.5771 8.14323 12.3068 8.25 11.9854 8.25C11.664 8.25 11.3985 8.13802 11.1891 7.91406C10.9797 7.6901 10.875 7.42708 10.875 7.125Z"
        fill="#007377"
      />
      <rect
        x="0.75"
        y="0.75"
        width="22.5"
        height="22.5"
        rx="11.25"
        stroke="#007377"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </S.SVG>
  );
}

export default BadgeIcon;
