import React, { ReactElement, useState } from 'react';

import * as S from './styles';
import TabTitle from './TabTitle';

type Props = {
  children: ReactElement[];
  hideTitles?: boolean;
  preSelectedTabIndex?: number;
  handleCallback?: (title: string) => void;
};

const Tabs: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    children,
    hideTitles,
    preSelectedTabIndex,
    handleCallback = () => {},
  } = props;

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    preSelectedTabIndex || 0,
  );

  const getTabTitles = () => (
    <ul>
      {children.map((item, index) => (
        <TabTitle
          key={item.props.title}
          title={item.props.title}
          index={index}
          isActive={index === selectedTabIndex}
          setSelectedTab={setSelectedTabIndex}
          handleCallback={() => handleCallback(item.props.title)}
        />
      ))}
    </ul>
  );

  return (
    <S.TabsWrapper>
      {!hideTitles && getTabTitles()}

      {children[selectedTabIndex]}
    </S.TabsWrapper>
  );
};

export default Tabs;
