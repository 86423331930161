import { size, colors, typography } from '@everlywell/leaves';
import Grid from 'components/Grid';
import styled from 'styled-components';

export const Container = styled.div``;

export const Heading = styled.h2`
  ${typography.bodyText};
  color: ${colors.gray4};
  font-weight: ${typography.weight.light};
  margin: 0;
`;

export const Card = styled.div`
  padding: ${size.sm}px;
  border-radius: ${size.sm}px;
  border: 0.5px solid ${colors.green2};
  background: ${colors.white};
  box-shadow: 0 0.5px 0 0 ${colors.gray1};
`;

export const Label = styled.h6`
  ${typography.bodyTextSmall};
  font-weight: ${typography.weight.xbold};
  color: ${colors.gray4};
  margin: 0;
`;

export const Text = styled.p`
  ${typography.bodyTextSmall};
  color: ${colors.gray4};
  font-weight: ${typography.weight.light};
`;

export const StyledGridItem = styled(Grid.Item)`
  flex-grow: 0;
  min-width: ${size.xl7}px;
`;
