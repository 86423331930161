import { renderWithProviders } from 'common/utils/reactTestingLibraryHelpers';
import React from 'react';

import costIcon from '../../images/icons-cost.svg';
import TakeActionSection from '../index';

const defaultProps = {
  header: 'test header',
  description: 'test description',
  date: new Date().toISOString(),
  iconSrc: costIcon,
  alt: 'icon alt',
  tooltip: {
    header: 'tooltip header',
    description: 'tooltip description',
  },
  testName: 'Cholesterol and Lipids Test',
  pwnOrderNumber: 55555,
  pwnConfirmationNumber: 55555,
};

const renderComponent = (props: any = {}) =>
  renderWithProviders(<TakeActionSection {...defaultProps} {...props} />);

describe('<ScheduleConsult />', () => {
  it('should render', () => {
    const { getByTestId } = renderComponent();
    expect(getByTestId('schedule-consult')).toBeDefined();
  });
});
