const MIN_REQUIRED_AGE = 16;
const MAX_AGE = 120;
const DEFAULT_NUM_OF_DAYS = 31;
const CURRENT_YEAR = new Date().getFullYear();

const minTwoDigits = (n: number) => (n < 10 ? `0${n}` : `${n}`);

const getRange = (start: number, stop: number): number[] =>
  Array.from({ length: start - stop + 1 }, (_, i) => start - i);

const monthOptions = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

export {
  minTwoDigits,
  getRange,
  monthOptions,
  DEFAULT_NUM_OF_DAYS,
  MIN_REQUIRED_AGE,
  MAX_AGE,
  CURRENT_YEAR,
};
