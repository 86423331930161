import {
  Button,
  colors,
  mediaQueries,
  Row,
  size,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 7px 30px -10px rgba(220, 228, 229);
  border-radius: ${size.xs1}px;
  width: 343px;
  height: 497px;
  overflow: hidden;
  margin: ${size.lg}px ${size.md}px;

  ${mediaQueries.forTabletVerticalUp} {
    margin: ${size.xl1}px ${size.md}px;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${size.xs2}px ${size.sm}px;
  gap: ${size.xs2};
  background-color: ${colors.teal1};
  border: 1.5px solid ${colors.teal2};
  border-radius: ${size.md}px;
  margin: ${size.lg}px 73px;
  min-width: 197px;
  color: ${colors.teal5};
`;

const TestImage = styled.img`
  width: 183px;
  height: 116px;
  margin: 21.5px 80px;
`;

const TestName = styled.div`
  font-size: 18px;
  line-height: ${size.lg}px;
  color: ${colors.teal4};
  font-weight: ${typography.weight.xbold};
  margin-left: ${size.lg}px;
`;

const TestDescription = styled.div`
  ${typography.bodyTextSmall};
  margin: ${size.xs1}px ${size.lg}px;
  /* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 84px;
`;

const TestPrice = styled.div`
  font-weight: ${typography.weight.xbold};
  font-size: 18px;
  line-height: ${size.lg}px;
  letter-spacing: 0.25px;
  color: ${colors.gray4};
  margin-left: ${size.lg}px;
`;

const AddCartButton = styled(Button)`
  width: 311px;
  border-radius: ${size.xs2}px;
  margin: ${size.md}px ${size.md}px ${size.lg}px;
`;

const CardsWrapper = styled(Row)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: row;
  }
`;

export {
  CardsWrapper,
  Card,
  Title,
  TestImage,
  TestName,
  TestDescription,
  TestPrice,
  AddCartButton,
};
