import { size, mediaQueries } from '@everlywell/leaves';
import Grid from 'components/Grid';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  overflow: hidden;
  padding: ${size.xl3}px 0 ${size.md}px;
  width: 100%;

  ${mediaQueries.forDesktopUp} {
    padding: ${size.xl4}px 0 ${size.md}px 0;
  }
`;

export const InnerContainer = styled.div`
  ${mediaQueries.forTabletVerticalUp} {
    width: 500px;
  }
`;

export const LoaderContainer = styled(Container)`
  margin: auto;
  width: 500px;
`;

export const Item = styled(Grid.Item)`
  display: block;

  ${mediaQueries.forTabletVerticalUp} {
    display: flex;
    justify-content: center;
  }
`;
