import { colors } from '@everlywell/leaves';
import { createGlobalStyle } from 'styled-components';

import { baseFont } from './app-styles';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    scroll-behavior: smooth;
    scroll-padding-top: 80px;
  }

  button {
    padding: 0;
    font-family: inherit;
    border: 0;
    background: transparent;
  }

  body {
    font-family: ${baseFont};
    color: ${colors.gray4};
    font-style: normal;
    font-stretch: normal;
    -webkit-font-smoothing: antialiased;
  }

  body .BeaconFabButtonFrame {
    right: unset;
    left: calc(100vw - 100px);
  }

  p {
    margin: 0;
  }

  svg {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }

  body.fontLoaded {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  strong {
    font-weight: 600;
  }

  #app {
    min-height: 100%;
    min-width: 100%;
  }

  @keyframes delay-dismiss {
    0% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }

  #alert-flash {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 15;
    opacity: 0;
    text-align: center;
    padding: 1.2rem;
    background-color: #39b900;
    color: white;
    border-radius: 0;
    border: 0;
    margin-bottom: 0;
    animation-duration: 7s;
    animation-name: delay-dismiss;

    &.alert-success {
      background-color: #1eaae6;
      color: white;
    }

    &.alert-info,
    &.alert-notice {
      background-color: #1eaae6;
      color: white;
    }

    &.alert-danger,
    &.alert-error {
      background-color: #f3d1cf;
      color: #d04540;
      border: solid 1px #d04540;
    }
  }

  a {
    color: ${colors.green4};

    &:hover {
      color: ${colors.green3};
      text-decoration: none;
    }

    &:active {
      color: ${colors.green3};
      text-decoration: none;
    }

    &:focus {
      color: #00314d;
      text-decoration: none;
      outline: none;

      &:active {
        color: ${colors.green3};
        text-decoration: none;
      }
    }
  }
`;

export default GlobalStyle;
