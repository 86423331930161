import { typography, colors } from '@everlywell/leaves';
import styled from 'styled-components';

export const SVG = styled.svg`
  display: block;
  align-self: flex-start;
`;

export const Percentage = styled.text`
  ${typography.buttonText}
  fill: ${colors.teal4};
  text-anchor: middle;
`;
