import { mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  > a {
    height: 380px;
    max-width: 100%;

    ${mediaQueries.forTabletHorizontalUp} {
      height: 284px;
    }

    h3 {
      margin-bottom: 5px;
      margin-top: 0;
    }
  }
`;

export const Logo = styled.img`
  width: 39px;
  height: 14px;
`;

export const LogoWrapper = styled.div`
  position: absolute;
  right: 24px;
  bottom: 33px;
`;
