import useApi from 'common/hooks/useApi';
import { logError } from 'common/utils/helpers';
import {
  HealthProfileQuestion,
  PreRegistrationDisclaimerType,
  PreRegistrationScreenerType,
} from 'common/utils/types';

import getDisclaimerContent from './disclaimers';
import getQuestionnaireByProductSlug from './questionnaires';
import { getScreenerContent } from './screenerParser';

export type GetQuestionnaireType = {
  screenerContent: PreRegistrationScreenerType | null | undefined;
  disclaimerContent: PreRegistrationDisclaimerType | null | undefined;
};

export type UseScreenerQuestionnaireType = {
  getQuestionnaire(slug: string): Promise<GetQuestionnaireType>;
};

const useScreenerQuestionnaire = (): UseScreenerQuestionnaireType => {
  const { fetchQuestionnaire } = useApi();

  const parseScreenerContent = (rawQuestions: HealthProfileQuestion[]) => {
    const isValid = rawQuestions && rawQuestions.length;
    if (isValid) {
      const [rawQuestion] = rawQuestions;
      const content = getScreenerContent(rawQuestion);
      return content;
    }
  };

  const parseDisclaimerContent = (questionnaire: string) => {
    const content = getDisclaimerContent(questionnaire);
    return content;
  };

  const getQuestionnaireContent = async (
    questionnaire: string,
  ): Promise<GetQuestionnaireType> => {
    let screener: PreRegistrationScreenerType | null | undefined;
    let disclaimer: PreRegistrationDisclaimerType | null | undefined;

    try {
      const { questions } = (await fetchQuestionnaire(questionnaire)) || {};
      screener = parseScreenerContent(questions);
      disclaimer = parseDisclaimerContent(questionnaire);
    } catch (error) {
      if (error instanceof Error) {
        logError(error.message, {
          method: 'getQuestionnaireContent',
        });
      }
    }

    return { screenerContent: screener, disclaimerContent: disclaimer };
  };

  const getQuestionnaire = async (
    slug: string,
  ): Promise<GetQuestionnaireType> => {
    let screenerContent: PreRegistrationScreenerType | null | undefined;
    let disclaimerContent: PreRegistrationDisclaimerType | null | undefined;

    try {
      const questionnaire = getQuestionnaireByProductSlug(slug);
      if (questionnaire) {
        const response = await getQuestionnaireContent(questionnaire);
        screenerContent = response.screenerContent;
        disclaimerContent = response.disclaimerContent;
      }
    } catch (error) {
      if (error instanceof Error) {
        logError(error.message, {
          method: 'getQuestionnaire',
        });
      }
    }

    return { screenerContent, disclaimerContent };
  };

  return { getQuestionnaire };
};

export default useScreenerQuestionnaire;
