/**
 *
 * SubHeaderButton
 *
 */
import React from 'react';

import saveIcon from './images/save.svg';
import shareIcon from './images/share.svg';
import * as S from './styles';

type Props = {
  afterText?: string;
  onClick?: () => void;
  href?: string | URL;
  isActive: boolean;
  name: string;
  shouldShowChevron: boolean;
  hideChevronOnMobile?: boolean;
  hideTextOnMobile?: boolean;
  dataTest: string;
  style?: object;
  analyticsAttributes?: object;
  textTransform?: string;
};

type Icons = {
  [prop: string]: string;
};

function SubHeaderButton(props: Props) {
  const {
    afterText,
    isActive,
    name,
    shouldShowChevron,
    hideChevronOnMobile,
    hideTextOnMobile,
    dataTest,
    onClick,
    href,
    style,
    analyticsAttributes,
    textTransform,
  } = props;

  const icons: Icons = {
    save: saveIcon,
    share: shareIcon,
    'View Documents': saveIcon,
  };

  return (
    // @ts-ignore
    // The `href` prop breaks type checking for this component.
    // Seems to be a known limitation of TS with styled components, though I'm pretty sure
    // we do this in leaves.
    <S.Wrapper
      as={href ? 'a' : 'button'}
      href={href}
      onClick={onClick}
      aria-label={name}
      data-test={dataTest}
      style={style}
    >
      {icons[name] && <S.Icon iconSrc={icons[name]} />}
      <S.Name
        afterText={afterText}
        hideTextOnMobile={hideTextOnMobile}
        {...analyticsAttributes}
        textTransform={textTransform || 'capitalize'}
      >
        {name}
      </S.Name>
      {shouldShowChevron && (
        <S.Chevron
          isActive={isActive}
          hideChevronOnMobile={hideChevronOnMobile}
        />
      )}
    </S.Wrapper>
  );
}

export default SubHeaderButton;
