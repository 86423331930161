/**
 *
 * ReferAFriendButton
 *
 */

import { breakpoints } from '@everlywell/leaves';
import useMediaQuery from 'common/hooks/useMediaQuery';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { SUB_HEADER as DATA_TEST } from 'common/utils/constants/dataTest';
import { openExtoleModal } from 'common/utils/vendor/extole';
import React from 'react';

import SubHeaderButton from '../SubHeaderButton';
import * as S from './ReferAFriendButton.styles';

type Props = {
  testName: string;
};

function ReferAFriendButton(props: Props) {
  const { testName } = props;

  const isTablet = useMediaQuery(
    `(max-width: ${breakpoints.forTabletVerticalDown}px)`,
  );

  const referralProgram =
    testName.toLowerCase().includes('covid') || isTablet
      ? ANALYTICS.LABELS.REFER_FRIENDS
      : ANALYTICS.LABELS.REFER_FRIENDS_REWARD;

  const analyticsAttributes = {
    'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
    'data-analytics-category': ANALYTICS.CATEGORIES.NAVIGATION,
    'data-analytics-label': referralProgram,
  };

  return (
    <S.Wrapper>
      <SubHeaderButton
        onClick={openExtoleModal}
        isActive={false}
        name={referralProgram}
        shouldShowChevron={false}
        dataTest={DATA_TEST.REFER_A_FRIEND_BUTTON}
        analyticsAttributes={analyticsAttributes}
        style={{ fontWeight: 600 }}
        textTransform="none"
      />
    </S.Wrapper>
  );
}

export default ReferAFriendButton;
