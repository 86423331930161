import {
  Button,
  Container as LeavesContainer,
  typography,
  size,
} from '@everlywell/leaves';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled(LeavesContainer)`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  max-width: 800px;
  padding-top: ${size.xl3}px;
  padding-bottom: ${size.xl5}px;
`;

export const AddPolicyLink = styled.span`
  ${typography.buttonText}
  cursor: pointer;
  margin: ${size.lg}px 0;
`;

export const ButtonsContainer = styled.div`
  box-shadow: 0 ${size.xs2}px ${size.sm}px 0 rgba(0, 0, 0, 0.1);
`;

export const NavigateButtonsSection = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const ContinueButton = styled(Button)`
  width: 75%;
  margin-left: ${size.md}px;
`;

export const NoInsuranceLink = styled(Link)`
  ${typography.buttonText}
  display: flex;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
`;

export const ErrorText = styled.div`
  ${typography.errorText}
  text-align: center;
`;
