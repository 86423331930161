import {
  Row,
  Button,
  colors,
  H3,
  RadioButton,
  Tooltip,
  size,
  typography,
  mediaQueries,
} from '@everlywell/leaves';
import styled from 'styled-components';

const ConsentButton = styled(Button)`
  width: 100%;
`;

const Title = styled(H3)`
  font-weight: ${typography.weight.bold};
  padding-bottom: ${size.lg}px;
  margin-bottom: 0;
`;

const CustomContentContainer = styled.div`
  padding-bottom: ${size.md}px;
`;

const Subtitle = styled.p`
  ${typography.bodyText}
  font-weight: ${typography.weight.bold};
  color: ${colors.gray4};
`;

const StyledRow = styled(Row)`
  margin-top: ${size.xl8}px;

  @media (max-height: 740px) {
    margin-top: ${size.xl1}px;
  }
  ${mediaQueries.forTabletHorizontalUp} {
    margin-top: 0;
  }
`;

const RadioGroup = styled.div`
  margin-bottom: ${size.lg}px;
`;

const StyledRadioButton = styled(RadioButton)`
  margin-top: ${size.md}px;
`;

const Footer = styled.div`
  margin-top: ${size.xl2}px;
`;

const TooltipIcon = styled.img`
  width: 20px;
  margin-left: 5px;
  vertical-align: text-bottom;
`;

const StyledTooltip = styled(Tooltip)`
  display: inline-flex;
  z-index: 100;

  .tooltipBox {
    width: auto;
    opacity: 0;
    display: none;
    padding: ${size.lg}px;

    ${mediaQueries.forTabletHorizontalUp} {
      width: 270px;
    }
  }
`;

const CustomerCareInformationWrapper = styled.div`
  border: 1px solid #99b49b;
  padding: 10px;
  background: #f7faf7;
`;

const ContactData = styled.a`
  color: #4d749c;
  font-weight: bold;
  text-decoration: none;
`;

export {
  Title,
  Subtitle,
  ConsentButton,
  StyledRow,
  CustomContentContainer,
  RadioGroup,
  StyledRadioButton,
  Footer,
  TooltipIcon,
  StyledTooltip,
  CustomerCareInformationWrapper,
  ContactData,
};
