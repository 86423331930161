import { colors, size, typography } from '@everlywell/leaves';
import styled, { css } from 'styled-components';

export const InsuranceCard = styled.label<{ hasError?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 178px;
  background: ${colors.white};
  border: 1.1787px solid ${colors.green1};
  box-shadow: 0 7px 30px -10px rgba(220, 228, 229, 0.5);
  border-radius: 6.2866px;
  cursor: pointer;
  overflow: hidden;
  ${(props) =>
    props.hasError &&
    css`
      border-color: ${colors.red3};
    `}

  img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  :focus-within {
    border-color: ${colors.teal5};
  }

  &:focus-visible {
    outline: auto;
  }
`;

export const InsuranceCardText = styled.div`
  ${typography.bodyText}
  color: ${colors.green4};
  font-weight: ${typography.weight.bold};
`;

export const PlusIcon = styled.span`
  color: ${colors.green4};
  font-weight: 100;
  font-size: ${size.xl5}px;
`;

export const FileInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0;
`;

export const InsuranceCardAddIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${size.xl6}px;
  width: ${size.xl6}px;
  margin-bottom: ${size.md}px;
  border-radius: 50%;
  background-color: ${colors.green1};
`;

export const Error = styled.div`
  color: ${colors.red3};
`;
