import { Modal, InformationBox } from '@everlywell/leaves';
import { useCommonT2TAnalytics } from 'common/hooks/useCommonT2TAnalytics';
import useEnterpriseProgramSlug from 'common/hooks/useEnterpriseProgramSlug';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import AppointmentDate from 'pages/AccountHub/components/AppointmentDate';
import React, { useState, FC } from 'react';

import * as S from './CancelAppointmentCTA.styles';

interface CancelModalProps {
  openModal: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  appointmentDate: string;
  appointmentId: string;
  providerFullName: string;
}

interface CancelAppointmentCTAProps {
  appointmentId: string;
  appointmentDate: string;
  providerFullName: string;
}

const CancelModal: FC<CancelModalProps> = ({
  openModal,
  setIsModalOpen,
  appointmentDate,
  providerFullName,
  appointmentId,
}) => {
  const { enterpriseProgramSlug } = useEnterpriseProgramSlug();
  const { commonT2TAnalyticsData } = useCommonT2TAnalytics();

  const handleReturnClick = () => {
    setIsModalOpen(false);
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        ...commonT2TAnalyticsData,
        label: ANALYTICS.LABELS.EHS_T2T.RETURN_TO_DASHBOARD,
        page: ANALYTICS.PAGES.EHS_T2T.CANCEL_APPOINTMENT_MODAL,
      },
    });
  };

  const handleCancelClick = () => {
    setIsModalOpen(false);
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        ...commonT2TAnalyticsData,
        label: ANALYTICS.LABELS.EHS_T2T.CANCEL_AN_APPOINTMENT,
        page: ANALYTICS.PAGES.EHS_T2T.CANCEL_APPOINTMENT_MODAL,
      },
    });
  };

  return (
    <Modal open={openModal} openHandler={setIsModalOpen}>
      <S.Header>
        Your appointment on{' '}
        <AppointmentDate
          appointmentDate={appointmentDate}
          showTimezone={true}
          showDayOfWeek={false}
          options={{ dayPattern: 'do' }}
        />
      </S.Header>
      <S.Title>You'll be meeting with Dr. {providerFullName} on video.</S.Title>

      <S.InfoBox>
        <InformationBox text="Please note to reschedule your appointment, you need to cancel and book a new appointment or contact our care team at (855) 997-0119 daily from 8 AM - 11 PM EST." />
      </S.InfoBox>

      <S.Container>
        <S.Row>
          <S.Col xs={6}>
            <S.StyledButton
              onClick={() => handleReturnClick()}
              appearance="secondary"
            >
              Return to Dashboard
            </S.StyledButton>
          </S.Col>
          <S.Col xs={6}>
            <S.StyledButton
              onClick={() => handleCancelClick()}
              appearance="primary"
              href={`/virtual-care/appointments/${appointmentId}/cancel?program=${enterpriseProgramSlug}`}
            >
              Proceed to cancel Appointment
            </S.StyledButton>
          </S.Col>
        </S.Row>
      </S.Container>
    </Modal>
  );
};

const CancelAppointmentCTA: FC<CancelAppointmentCTAProps> = ({
  appointmentId,
  appointmentDate,
  providerFullName,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const { commonT2TAnalyticsData } = useCommonT2TAnalytics();

  const handleCTAClick = () => {
    setOpenModal(true);
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        ...commonT2TAnalyticsData,
        label: ANALYTICS.LABELS.EHS_T2T.MANAGE_AN_APPOINTMENT,
        page: ANALYTICS.PAGES.EHS_T2T.HOMEPAGE,
      },
    });
  };

  return (
    <div>
      <S.ManageButton onClick={() => handleCTAClick()} appearance="secondary">
        Manage Appointment
      </S.ManageButton>
      <CancelModal
        openModal={openModal}
        setIsModalOpen={setOpenModal}
        appointmentDate={appointmentDate}
        providerFullName={providerFullName}
        appointmentId={appointmentId}
      />
    </div>
  );
};

export default CancelAppointmentCTA;
