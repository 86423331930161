import { SkeletonLoader, SkeletonUnit } from '@everlywell/leaves';
import {
  useTelehealthUserJourneys,
  updateUserTaskAction,
  UserTaskModes,
  UserTaskTypes,
} from 'common/apis/telehealthApis';
import useUser from 'common/hooks/useUser';
import MyChecklist from 'components/CreditRedemption/components/MyChecklist';
import ErrorCard from 'pages/AccountHub/components/ErrorCard';
import React from 'react';
import { useMutation } from 'react-query';

import JourneyStep from './components/JourneyStep';
import * as S from './JourneyChecklist.styles';

export type JourneyTaskType = {
  type: UserTaskTypes;
  id: number;
  title: string;
  cta?: {
    link: string;
    label: string;
  } | null;
  image_uri: string;
  mode: UserTaskModes;
  expires_at: string | null;
};

export type JourneyStepType = {
  id: number;
  title: string;
  is_current: boolean;
  primary_description: string;
  secondary_description?: string | null;
  estimated_time_to_completion: string | null;
  tasks: JourneyTaskType[];
};

export type JourneyChecklistProps = {
  slugFilter: string;
};

/**
 * This component is to show a checklist of tasks that need to be completed for a journey
 */
function JourneyChecklist({ slugFilter }: JourneyChecklistProps) {
  const { user } = useUser();

  const {
    data: response,
    isLoading,
    error,
    refetch: refetchJourneys,
  } = useTelehealthUserJourneys(
    { id: user?.user_plans?.enabled[0]?.id || 0 },
    { enabled: Boolean(user?.id) },
  );

  // Filter the journey by slug
  const WCJourney = response?.data?.journeys.find(
    (journey) => journey.slug === slugFilter,
  );
  const { steps } = WCJourney || {};

  const currentStep = steps?.find((step) => step.is_current);
  const currentStepTaskNumber = currentStep?.tasks.length;

  const { mutate: doUpdateUser } = useMutation(updateUserTaskAction, {
    onSuccess: (response) => {
      if (response.data?.meta.journeys) {
        /**
         * TODO: Refactor to avoid using refetch and set the new WCJ
         * data from the response of the mutation.
         */
        refetchJourneys();
      }
    },
  });

  const handleUserTask = (taskId: number) => {
    const id = user?.user_plans?.enabled[0]?.id || 0;
    doUpdateUser({ taskId: taskId, planId: id });
  };

  if (isLoading && !error) {
    return (
      <S.Container>
        <SkeletonLoader
          height={{
            value: 260,
            unit: SkeletonUnit.Pixel,
          }}
          width={{
            value: 100,
            unit: SkeletonUnit.Percentage,
          }}
        />
      </S.Container>
    );
  }

  if (error) {
    return (
      <S.Container>
        <ErrorCard onRetry={refetchJourneys} />
      </S.Container>
    );
  }

  return (
    <>
      {steps && steps?.length > 0 ? (
        <S.Container>
          <S.TitleWrapper>
            <S.Title>Getting Started</S.Title>
            {currentStepTaskNumber && currentStepTaskNumber > 0 ? (
              <S.RedCircleIconWrapper>
                <S.RedCircleWithTaskNumber>
                  {currentStepTaskNumber}
                </S.RedCircleWithTaskNumber>
              </S.RedCircleIconWrapper>
            ) : null}
          </S.TitleWrapper>
          <div>
            {steps.map((step, i) => {
              const currentStepIndex = steps.findIndex(
                (step) => step.is_current === true,
              );
              return (
                <JourneyStep
                  key={`step-${i}`}
                  currentStepIndex={currentStepIndex}
                  stepIndex={i}
                  stepsLength={steps.length}
                  handleUserTask={handleUserTask}
                  {...step}
                />
              );
            })}
          </div>
        </S.Container>
      ) : (
        // Show the MyChecklist if the journey has no steps
        <S.ChecklistWrapper>
          <MyChecklist />
        </S.ChecklistWrapper>
      )}
    </>
  );
}

export default JourneyChecklist;
