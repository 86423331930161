import { typography, colors, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

const StatusMessage = styled.p`
  ${typography.subtitle};
  margin: 0;
  ${mediaQueries.forTabletHorizontalUp} {
    text-align: center;
  }
`;

export const LabName = styled.span`
  display: block;
  color: ${colors.green5};
`;

const Link = styled.a`
  color: ${colors.green5};
  text-decoration: none;

  &:hover {
    color: ${colors.green5};
    text-decoration: underline;
  }
`;

export { StatusMessage, Link };
