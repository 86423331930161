import { useQuery as useApolloClientQuery } from '@apollo/client';
import type { Document } from '@contentful/rich-text-types';
import { getPersonalizations } from 'common/apis/personalizationApis';
import { FACTS_COLLECTION_QUERY } from 'common/utils/contentfulGraphQlQueries';
import { logError } from 'common/utils/helpers';
import { FactCardValues } from 'common/utils/types';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

export type FactCollectionItem = {
  copy: {
    json: Document;
  };
  icon: {
    title: string;
    description: string;
    contentType: string;
    fileName: string;
    size: number;
    url: string;
    width: number;
    height: number;
  };
  slug: string;
  source1?: string | null;
  source1Url?: string | null;
  source2?: string | null;
  source2Url?: string | null;
  title: string;
};

const usePersonalizedFacts = () => {
  const {
    data: personalizationsResponse,
    isLoading: isLoadingPersonalizationsResponse,
  } = useQuery('personalizations', getPersonalizations, {
    onError(error) {
      logError((error as Error).message, {
        errorInfo: 'Error Fetching Personalizations',
        component: 'useFactsHook',
        method: 'getPersonalizations',
      });
    },
  });

  // TODO: this is hitting contentful API in runtime, should it be fetched on build time instead?
  const { data: factsCollectionResponse, loading: isLoadingFactsCollection } =
    useApolloClientQuery<{
      factCollection: {
        items: FactCollectionItem[];
      };
    }>(FACTS_COLLECTION_QUERY, {
      onError(error) {
        logError((error as Error).message, {
          errorInfo: 'Graphql fetching Error',
          component: 'useFactsHook',
          method: 'useApolloClientQuery',
        });
      },
    });

  const [facts, setFacts] = useState<FactCollectionItem[]>([]);

  useEffect(() => {
    const facts = factsCollectionResponse?.factCollection.items;
    const personalizations = personalizationsResponse?.data?.health_profile;

    if (facts && personalizations) {
      const eligibleFacts = getEligibleFacts(
        facts,
        personalizations.fact_cards,
      );

      setFacts(eligibleFacts);
    }
  }, [factsCollectionResponse, personalizationsResponse]);

  return {
    facts,
    isLoading: isLoadingPersonalizationsResponse || isLoadingFactsCollection,
  };
};

export default usePersonalizedFacts;

const factCardPersonalizations: Record<FactCardValues, string> = {
  'Digestive Disorder': 'digestive-disorder-',
  'Headaches/Migraines': 'migraines-',
  'Thyroid Disorder': 'thyroid-disorder-',
};

export interface FactItem {
  slug: string;
}

export const getEligibleFacts = <T extends FactItem>(
  items: T[],
  factCardValues: FactCardValues[],
) => {
  const translatedValues = factCardValues.map(
    (value) => factCardPersonalizations[value],
  );

  return items.filter(({ slug }) =>
    translatedValues.some((value) => slug.includes(value)),
  );
};
