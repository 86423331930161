import { waitFor } from 'common/utils/reactTestingLibraryHelpers';

import CommonQuestionsSectionContent from '..';

import { renderWithProviders } from 'common/utils/reactTestingLibraryHelpers';
import React from 'react';

jest.mock('common/utils/helpScoutContent.json', () => ({
  '1': [
    {
      id: '1',
      number: 519,
      collectionId: '5db07cd304286364bc90c994',
      slug: 'what-do-i-do-if-i-only-have-mild-reactivities-nothing-moderate-or-high',
      status: 'published',
      hasDraft: false,
      name: 'What do I do if I only have mild reactivities (nothing moderate or high)?',
      text: '<p>Everybody processes and responds to triggers differently, so even if your immune system has only a mild IgG reactivity to a food, it could still be causing you symptoms that feel anything <i style="background-color: initial;">but </i>mild. Some people find that their worst symptoms are caused by foods in the “mild” category!&nbsp;</p>',
      categories: ['5db0803f04286364bc90c9e3'],
      related: [],
      keywords: [],
      viewCount: 0,
      createdBy: 347981,
      updatedBy: 347981,
      publicUrl:
        'https://support.everlywell.com/article/519-what-do-i-do-if-i-only-have-mild-reactivities-nothing-moderate-or-high',
      popularity: 0,
      createdAt: '2019-10-23T16:47:10Z',
      updatedAt: '2019-10-23T16:47:24Z',
      lastPublishedAt: '2019-10-23T16:47:24Z',
    },
  ],
}));
it('renders personalized greeting', async () => {
  const { getByText } = renderWithProviders(
    <CommonQuestionsSectionContent helpscoutCategoryId="1" />,
  );

  await waitFor(() =>
    getByText(
      /What do I do if I only have mild reactivities (nothing moderate or high)?/i,
    ),
  );
  await waitFor(() =>
    getByText(/Everybody processes and responds to triggers differently/i),
  );
});
