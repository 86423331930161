import { size, mediaQueries, typography, colors } from '@everlywell/leaves';
import styled from 'styled-components';

export const ContainerWrapper = styled.div`
  width: 100%;
  padding: ${size.lg}px ${size.md}px;
  height: 100%;
  box-shadow: 0 7px 30px -10px rgba(220, 228, 229);
  ${mediaQueries.forTabletVerticalUp} {
    padding: ${size.xl1}px;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${size.md}px;
  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: row;
    align-items: center;
    gap: ${size.lg}px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: start;
  width: 100%;
  ${mediaQueries.forTabletVerticalUp} {
    width: auto;
  }
`;

export const BaseCardIcon = styled.img`
  width: 72px;
  height: 72px;
  ${mediaQueries.forTabletVerticalUp} {
    width: 96px;
    height: 96px;
  }
`;

export const InternalItem = styled.div`
  width: 100%;
  ${mediaQueries.forTabletVerticalUp} {
    width: auto;
    flex: 1;
  }
`;

export const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  ${mediaQueries.forTabletVerticalUp} {
    align-items: flex-start;
    text-align: start;
    align-self: flex-start;
    min-width: 60%;
    width: auto;
    flex: 1;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  align-self: center;
  ${mediaQueries.forTabletVerticalUp} {
    width: auto;
  }
`;

export const ButtonContainerInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${mediaQueries.forTabletVerticalUp} {
    width: auto;
    min-width: 280px;
  }
  svg {
    display: inline-block;
    fill: red;
  }
`;

export const Title = styled.h3`
  ${typography.h5Text}
  color: ${colors.teal4};
  margin: 0 0 ${size.xs1}px;
  width: 100%;
`;

export const Text = styled.div`
  ${typography.bodyTextSmall};
  margin: 0;
  width: 100%;
`;
