import { Localize } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { STATUSES } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import React, { useState, useRef, useEffect } from 'react';

import * as S from './styles';

type Props = {
  trackingUrl?: string;
  productLink?: string;
  testId?: number;
  status: string;
  serialNumber?: number;
  rapidAntigen: boolean;
};

const KitMenu = (props: Props) => {
  const {
    trackingUrl,
    // productLink,
    testId,
    status,
    serialNumber,
    rapidAntigen,
    ...rest
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef<HTMLDivElement>(null);

  const handleClick = (e: any) => {
    // @ts-ignore
    if (node.current.contains(e.target)) return;
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const nonTrackableStatus = [
    STATUSES.KIT_REGISTERED,
    STATUSES.IN_TRANSIT,
    STATUSES.KIT_CANCELED,
  ];

  const showTracking =
    !(rapidAntigen || nonTrackableStatus.includes(status)) && !!trackingUrl;

  return (
    <S.KitMenuContainer ref={node} {...rest}>
      {isOpen ? (
        <S.KitMenu>
          {showTracking ? (
            <S.TrackPackage
              href={trackingUrl}
              target="_blank"
              data-test="url-text"
              onClick={() => {
                analytics.track({
                  event: ANALYTICS.EVENTS.CLICKED_BUTTON,
                  data: {
                    label: `Track Kit - Kebab`,
                    test_id: testId,
                    category: 'Results Dashboard',
                  },
                });
              }}
            >
              Track Your Kit
            </S.TrackPackage>
          ) : null}
          <S.KitId borderBar={showTracking} data-test="kitId-text">
            {/* @ts-ignore */}
            Kit ID: <Localize name="kit-id">{serialNumber}</Localize>
          </S.KitId>
        </S.KitMenu>
      ) : null}
      <S.KebobButton
        data-test="kebob-icon"
        aria-label="Kit Menu"
        onClick={() => {
          setIsOpen(!isOpen);
          analytics.track({
            event: ANALYTICS.EVENTS.CLICKED_BUTTON,
            data: {
              label: `Open Kebab Menu`,
              testId,
              category: 'Results Dashboard',
            },
          });
        }}
      >
        <S.KebobIcon />
      </S.KebobButton>
    </S.KitMenuContainer>
  );
};

export default KitMenu;
