import { colors, mediaQueries } from '@everlywell/leaves';
import Button from 'components/Button';
import styled from 'styled-components';

import { ModalTitle, ModalDescription } from '../styles';

type FooterProps = {
  needsGradient: boolean;
};

const Wrapper = styled.div`
  letter-spacing: 0;
  overflow: auto;
  padding: 32px 24px 96px;

  ${mediaQueries.forTabletHorizontalUp} {
    padding: 32px 32px 96px;
  }
`;

const Footer = styled.footer<FooterProps>`
  background-color: ${colors.white};
  border: 1px solid ${colors.gray2};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 24px 32px;
  position: absolute;
  width: 100%;

  &:before {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    content: '';
    display: ${(props) => (props.needsGradient ? 'block' : 'none')};
    height: 60px;
    position: absolute;
    top: -61px;
    width: calc(100% - 2px);
  }

  ${mediaQueries.forTabletHorizontalUp} {
    background-color: ${colors.white};
    border: none;

    &:before {
      content: none;
    }
  }
`;

const Title = styled(ModalTitle)`
  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 4px;
  }
`;
const Description = styled(ModalDescription)`
  ${mediaQueries.forTabletHorizontalUp} {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 24px;

    p:last-child {
      margin-bottom: 16px;
    }
  }
`;

const ContinueButton = styled(Button)`
  align-items: center;

  &:hover,
  &:active {
    text-decoration: none;
    color: ${colors.white};
  }

  &:focus {
    color: inherit;

    &:active {
      text-decoration: none;
      color: inherit;
    }
  }
`;

export { Wrapper, Title, Description, Footer, ContinueButton };
