import labIcon from './assets/next-steps_icon-lab-location.png';
import resultsIcon from './assets/next-steps-icon-digital-results.png';
import emailIcon from './assets/next-steps-icon-email.png';
import telephoneIcon from './assets/next-steps-Icon-telephone.png';

type ConfirmationStep = {
  image: {
    src: string;
    alt: string;
  };
  key: string;
  header: string;
  description: string;
};

export const confirmationSteps: ConfirmationStep[] = [
  {
    image: {
      src: emailIcon,
      alt: 'email icon',
    },
    key: 'get-email',
    header: 'Wait to receive your confirmation email',
    description: `Once your order is approved, you will receive a confirmation email.
    Requests are usually approved within 1-2 business days.`,
  },
  {
    image: {
      src: telephoneIcon,
      alt: 'telephone icon',
    },
    key: 'call-lab',
    header: 'Once approved, contact your Quest Diagnostics location',
    description: `Call your lab location to see if they are accepting walks-in or
    schedule an appointment.`,
  },
  {
    image: {
      src: labIcon,
      alt: 'lab icon',
    },
    key: 'visit-lab',
    header: 'Visit a Quest Lab to complete your test',
    description: `Your confirmation email will include pre-test instructions and what
            to bring to the lab.`,
  },
  {
    image: {
      src: resultsIcon,
      alt: 'results icon',
    },
    key: 'get-results',
    header: 'Receive your results in a few days',
    description: `You will receive a text and email from Everlywell when your results
    are ready to be viewed. This typically takes 1-2 business days after
    your test is received by the lab.`,
  },
];
