import analytics from 'common/utils/analytics';
import React from 'react';

import * as S from './styles';

export type Props = {
  label: string;
  content: string;
  clickAttributes?: any;
};

class CommonQuestionsItem extends React.PureComponent<Props> {
  trackAccordionClick = () => {
    analytics.track(this.props.clickAttributes);
  };

  render() {
    const { label, content } = this.props as Props;

    return (
      <S.AccordionItem>
        <S.AccordionHeading onClick={this.trackAccordionClick}>
          {label}
        </S.AccordionHeading>
        <S.AccordionChild>
          <S.AccordionText
            /* eslint react/no-danger: "off" */
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </S.AccordionChild>
      </S.AccordionItem>
    );
  }
}

export default CommonQuestionsItem;
