import { colors, Label as LeavesLabel } from '@everlywell/leaves';
import styled, { css } from 'styled-components';

import { labelTextCSSbyVariant } from '../../utils/styles';
import { FormBuilderVariant } from '../../utils/types';

export const Label = styled(LeavesLabel)<{
  hasError: boolean;
  variant: FormBuilderVariant;
}>`
  ${({ hasError }) =>
    hasError &&
    css`
      color: ${colors.red3};
    `}
  width: 100%;
  ${(props) => labelTextCSSbyVariant(props.variant)}
`;
