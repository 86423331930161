import { colors, typography, H5, size, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

const Heading = styled(H5)`
  color: ${colors.black};
  line-height: ${size.xl1}px;
  font-weight: ${typography.weight.xbold};
  margin: 0;
`;

const LineBreakSmall = styled.span`
  display: block;

  ${mediaQueries.forTabletVerticalUp} {
    display: inline;
  }
`;

const TextWrapper = styled.div`
  max-width: 644px;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: ${size.xs1}px;
  padding-right: 30px;
  padding-left: 30px;
  text-align: center;
  font-weight: ${typography.weight.regular};
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #767676;

  ${mediaQueries.forTabletVerticalUp} {
    padding: 0 61px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    padding: 0 99px;
  }

  ${mediaQueries.forDesktopUp} {
    padding: 0 117px;
  }
`;

const ActionItems = styled.div`
  display: flex;
  margin-bottom: 0;
  margin-top: ${size.xl1}px;

  ${mediaQueries.forTabletVerticalUp} {
    justify-content: center;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    align-items: center;
  }
`;

const Icon = styled.div`
  align-self: center;
  height: 100px;
  margin-top: ${size.lg}px;
  margin-bottom: 0;
`;

const Link = styled.a`
  color: #19a0e2;
  text-decoration: none;
`;

const LinkButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  color: ${colors.white};
  border-radius: 5px;
  font-size: ${size.md}px;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  height: 3rem;
  padding: 1rem 5rem;
  text-decoration: none;
  font-weight: ${typography.weight.regular};
  &:hover {
    color: ${colors.white};
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.div`
  ${typography.overlineText}
  margin: 0 129px ${size.sm}px 0;
`;

export {
  Heading,
  Info,
  ActionItems,
  Icon,
  Link,
  LineBreakSmall,
  LinkButton,
  TextWrapper,
  CardWrapper,
  CardTitle,
};
