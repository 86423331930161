import {
  mediaQueries,
  size,
  typography,
  colors,
  Accordion,
} from '@everlywell/leaves';
import styled from 'styled-components';

const MenuDivision = styled.hr`
  border-top: 1px solid ${colors.gray2};
`;

const AccordionWrapper = styled(Accordion.Wrapper)`
  max-width: 532px;
`;

const AccordionHeading = styled(Accordion.Heading)`
  position: static;
  font-weight: ${typography.weight.bold};
  font-size: 18px;
  padding: ${size.md}px ${size.lg}px ${size.md}px ${size.lg}px;
  letter-spacing: 0.3px;
  border-top: 1px solid ${colors.gray2};
  text-align: left;
`;

const AccordionItem = styled(Accordion.Item)`
  height: auto;
  color: ${colors.green5};
`;

const AccordionChild = styled(Accordion.Child)`
  color: ${colors.gray4};
  font-weight: ${typography.weight.book};
  padding: 0;
  ${mediaQueries.forPhoneOnly} {
    min-height: 158px;
  }
`;

const AccordionText = styled.p`
  ${typography.bodyText}
  font-size: 18px;
  padding: 0 ${size.lg}px ${size.lg}px ${size.lg}px;
  ${mediaQueries.forPhoneOnly} {
    font-size: ${size.md}px;
  }
`;

export {
  MenuDivision,
  AccordionWrapper,
  AccordionHeading,
  AccordionItem,
  AccordionChild,
  AccordionText,
};
