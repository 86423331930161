import { typography, H4, size, mediaQueries, colors } from '@everlywell/leaves';
import { pageTitle, sectionTitle } from 'common/styles/app-styles';
import styled, { css } from 'styled-components';

type Props = {
  backgroundSrc?: string;
  hasIcon: boolean;
};

// in this case, url can't receive single quotes on dynamic strings
// eslint-disable-next-line no-use-before-define
const backgroundImage = css<Props>`
  /* Image url needs double quotes instead of single due to svg-url-loader */

  /* prettier-ignore */
  background-image: url("${(props) => props.backgroundSrc}");
  padding-bottom: 128px;
  background-repeat: no-repeat;
  background-position: bottom center;

  ${mediaQueries.forBigDesktopUp} {
    background-size: 100%;
  }
`;

const Wrapper = styled.div<Props>`
  ${(props) => props.backgroundSrc && backgroundImage};
  padding-top: ${(props) => (props.hasIcon ? '0' : '32px')};

  ${mediaQueries.forTabletVerticalUp} {
    padding-top: ${(props) => (props.hasIcon ? '0' : '62px')};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 923px;
`;

const Icon = styled.img`
  margin-bottom: 8px;
  max-width: 80%;

  ${mediaQueries.forTabletVerticalUp} {
    max-width: 100%;
    margin-bottom: 14px;
  }
`;

const Header = styled(H4)`
  margin: ${size.sm}px 0;
  text-align: center;

  ${mediaQueries.forTabletHorizontalUp} {
    ${pageTitle};
  }
`;

const Description = styled.p`
  ${typography.bodyTextSmall};
  margin: 0;
  text-align: center;

  ${mediaQueries.forTabletHorizontalUp} {
    ${sectionTitle};
    letter-spacing: normal;
    max-width: 850px;
  }

  ul {
    margin: 25px 0;
    padding-left: 48px;

    ${mediaQueries.forTabletHorizontalUp} {
      padding-left: 75px;
    }
  }

  li {
    margin-bottom: 15px;
    text-align: left;
  }

  a {
    color: ${colors.green4};

    &:hover,
    &:active,
    &:focus {
      color: ${colors.green3};
    }
  }
`;

export { Wrapper, ContentWrapper, Icon, Header, Description };
