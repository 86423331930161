import { Localize } from '@everlywell/leaves';
import { COVID_SCREENER_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';
import React from 'react';

import infoIcon from '../../assets/ui-icons-alert.svg';
import * as S from './styles';

interface SevereNoteProps {
  addMargin: boolean;
  className?: string;
}

const SevereNote = ({ className, addMargin }: SevereNoteProps) => (
  <S.SevereNoteBox
    addMargin={addMargin}
    data-test={`${DATA_TEST.CONTAINER}-severe`}
    className={className}
  >
    <S.Image src={infoIcon} alt="Warning Icon" />

    <S.SevereNoteText>
      Because you indicated that you have severe symptoms, testing is not
      recommended under <Localize name="COVID-screener-CDC">CDC</Localize>{' '}
      guidelines at this time. Please consult with your healthcare provider.
    </S.SevereNoteText>
  </S.SevereNoteBox>
);

export default SevereNote;
