import {
  breakpoints,
  colors,
  mediaQueries,
  typography,
} from '@everlywell/leaves';
import {
  CardContainer,
  bodyCopy,
  bodyCopyLarge,
  sectionTitle,
} from 'common/styles/app-styles';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const CardWrapper = styled(CardContainer)`
  display: flex;
  padding: 32px 25px;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${mediaQueries.forTabletVerticalUp} {
    padding: 40px;
  }

  ${mediaQueries.forDesktopUp} {
    max-width: ${breakpoints.forDesktopDown}px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;

  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: row;
    align-items: center;
  }
`;

const Title = styled.div`
  ${bodyCopyLarge};
  letter-spacing: normal;
  font-weight: ${typography.weight.bold};
  ${mediaQueries.forTabletVerticalUp} {
    ${sectionTitle};
    letter-spacing: normal;
  }
`;

const HelixIconWrapper = styled.div`
  img {
    height: 18px;
  }
  ${mediaQueries.forTabletVerticalUp} {
    margin-left: 8px;
    margin-bottom: 0;
    img {
      height: 20px;
    }
  }
`;

const BodyCopy = styled.div`
  ${bodyCopy};
  line-height: normal;
  font-weight: ${typography.weight.light};
  margin-bottom: 26px;

  ${mediaQueries.forTabletVerticalUp} {
    margin-bottom: 18px;
  }
`;

const ViewGenomicReport = styled(Link)`
  display: block;
  width: 100%;
  color: ${colors.green4};

  &:hover {
    color: ${colors.green3};
  }

  ${mediaQueries.forTabletVerticalUp} {
    width: auto;
  }
`;

const ViewGenomicReportButton = styled(Button)`
  max-width: 277px;

  ${mediaQueries.forTabletVerticalUp} {
    padding: 0 21px;
  }
`;

export {
  CardWrapper,
  Title,
  BodyCopy,
  ViewGenomicReport,
  ViewGenomicReportButton,
  HelixIconWrapper,
  TitleWrapper,
};
