import { H3 } from '@everlywell/leaves';
import Grid from 'components/Grid';
import { uniqueId } from 'lodash';
import React, { useState } from 'react';

import BookingLoadingIcon from './assets/booking_loading_icon.svg';
import * as S from './OnDemandConfirmation.styles';

const OnDemandConfirmationSkeleton = () => {
  const [labelId] = useState(`on-demand-loading-label-${uniqueId()}`);

  return (
    <Grid.Container
      spacing={['md']}
      role="progressbar"
      aria-labelledby={labelId}
    >
      <Grid.Item width={[1]} css={{ justifyContent: 'center' }}>
        <img src={BookingLoadingIcon} alt="" />
      </Grid.Item>

      <Grid.Item width={[1]}>
        <H3 as="h1" id={labelId} style={{ margin: 0 }}>
          Booking your appointment...
        </H3>
      </Grid.Item>

      <Grid.Item
        width={[1]}
        css={{ display: 'flex', justifyContent: 'center' }}
      >
        <S.Spinner />
      </Grid.Item>
    </Grid.Container>
  );
};

export default OnDemandConfirmationSkeleton;
