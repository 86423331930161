import {
  size,
  mediaQueries,
  Container as LeavesContainer,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled(LeavesContainer)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 68px); /* viewport height - header height */
  overflow: hidden;
  max-width: 800px;
  padding: ${size.xl3}px ${size.lg}px ${size.xl5}px;

  ${mediaQueries.forTabletVerticalUp} {
    /* viewport height - header height */
    min-height: calc(100vh - 58px);
  }
`;
