import React from 'react';

import certifyCheckMark from '../../images/certify-check-mark.svg';
import * as S from './Source.styles';

export type SourceProps = {
  /** This is the text that is displayed in the component*/
  source: string;
};

/**
 * It renders the checkmark icon with the source of the fact
 */
function Source({ source }: SourceProps) {
  return (
    <S.Source>
      <img src={certifyCheckMark} alt="" style={{ marginRight: '8px' }} />
      {source}
    </S.Source>
  );
}

export default Source;
