/* eslint-disable @typescript-eslint/no-unused-vars */
import { useHooks } from '@everlywell/leaves';
import React, { useState } from 'react';

import * as S from '../styles';

interface Props {
  collectionMethods?: boolean;
  activeStep: number;
  currentStep: number;
}

const HealthProfileProgressBar = ({
  collectionMethods,
  activeStep,
  currentStep,
}: Props) => {
  const standardSteps = ['Register', 'Health Profile', 'Collection'];
  const noCollectionSteps = ['Register', 'Health Profile', 'Done'];
  const [steps, setSteps] = useState(
    collectionMethods ? standardSteps : noCollectionSteps,
  );

  const { navigation } = useHooks.useStep({
    initialStep: activeStep,
    steps,
  });
  return (
    <S.HealthProfileProgressBar
      steps={steps}
      currentStep={currentStep}
      goToStep={navigation.goToStep}
      isDisabled
    />
  );
};

export default HealthProfileProgressBar;
