/**
 * ReplaceTestButton
 */

import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import React from 'react';

import * as S from '../../styles';

type Props = {
  testId?: number;
};

const ReplaceTestButton = ({ testId }: Props) => (
  <S.KitButton
    data-test="replaced-test-button"
    appearance="primary"
    href="/replacement"
    onClick={() => {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: `Replacement Kit - QNS`,
          test_id: testId,
          category: 'Results Dashboard',
        },
      });
    }}
  >
    Replace Kit
  </S.KitButton>
);

export default ReplaceTestButton;
