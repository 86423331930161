import { TelehealthSessionData } from 'common/apis/telehealthApis';

const baseTelehealthSessionUser: TelehealthSessionData = {
  state_abbrev: 'CA',
  time_zone: 'America/Los_Angeles',
  results_sharing_consented_at: new Date(),
  program_integration: 'consumer',
};

export const buildTelehealthSession = (
  overrides?: Partial<TelehealthSessionData>,
): TelehealthSessionData => ({
  ...baseTelehealthSessionUser,
  ...overrides,
});
