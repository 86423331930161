import { logError } from 'common/utils/helpers';
import { useState } from 'react';

import { CareDashboardTypes } from './types';

export const useCareDashboard = (): CareDashboardTypes => {
  const [loadingDashboard, setLoading] = useState(false);

  const onLoadingDashboard = (loading: boolean) => {
    try {
      setLoading(loading);
    } catch (err) {
      logError((err as Error).message, {
        component: 'useCareDashboard',
        method: 'setLoadingDashboard',
        error: (err as Error).message,
      });
      return Promise.reject(err);
    }
  };

  return {
    loadingDashboard,
    onLoadingDashboard,
  };
};

export default useCareDashboard;
