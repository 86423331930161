import { size, mediaQueries, colors } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  margin-top: ${size.xl2}px;
  margin-bottom: ${size.xl6}px;
  overflow: hidden;
  position: relative;

  ${mediaQueries.forTabletVerticalDown} {
    margin-bottom: 0;
  }

  ${mediaQueries.forPhoneOnly} {
    margin-top: 0;
    margin-bottom: ${size.xl4}px;
  }
`;

export const Loading = styled.div`
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  opacity: 0.95;
  z-index: 100;

  &:after {
    position: absolute;
    animation: loading 500ms infinite linear;
    border: ${size.xs3}px solid ${colors.green3};
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    height: ${size.xl1}px;
    width: ${size.xl1}px;
    left: 50%;
    top: 40%;
    margin-left: -0.4rem;
    margin-top: -0.4rem;

    ${mediaQueries.forDesktopDown} {
      top: 45%;
    }
  }

  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
