enum SampleCardViewport {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
}

enum SampleCardMarkerDirection {
  left = 'left',
  right = 'right',
  center = 'center',
}

export { SampleCardMarkerDirection, SampleCardViewport };
