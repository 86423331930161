import { SkeletonLoader, SkeletonUnit } from '@everlywell/leaves';
import Grid from 'components/Grid';
import React from 'react';

export const StateNameSkeleton = () => (
  <>
    <SkeletonLoader
      width={{
        value: 75,
        unit: SkeletonUnit.Pixel,
      }}
      height={{
        value: 18,
        unit: SkeletonUnit.Pixel,
      }}
    />
  </>
);

export const DaySelectorSkeleton = () => (
  <>
    <SkeletonLoader
      width={{
        value: 100,
        unit: SkeletonUnit.Percentage,
      }}
      height={{
        value: 80,
        unit: SkeletonUnit.Pixel,
      }}
    />
  </>
);

export const TimeSelectorSkeleton = () => (
  <>
    <SkeletonLoader
      width={{
        value: 100,
        unit: SkeletonUnit.Percentage,
      }}
      height={{
        value: 45,
        unit: SkeletonUnit.Pixel,
      }}
    />
    <SkeletonLoader
      width={{
        value: 100,
        unit: SkeletonUnit.Percentage,
      }}
      height={{
        value: 200,
        unit: SkeletonUnit.Pixel,
      }}
    />
  </>
);

export const AppointmentTimePageSkeleton = () => (
  <Grid.Container spacing={['lg']}>
    <Grid.Item width={[1]}>
      <SkeletonLoader
        width={{
          value: 250,
          unit: SkeletonUnit.Pixel,
        }}
        height={{
          value: 50,
          unit: SkeletonUnit.Pixel,
        }}
      />
    </Grid.Item>
    <Grid.Item width={[1]}>
      <SkeletonLoader
        width={{
          value: 120,
          unit: SkeletonUnit.Pixel,
        }}
        height={{
          value: 20,
          unit: SkeletonUnit.Pixel,
        }}
      />
      <SkeletonLoader
        width={{
          value: 100,
          unit: SkeletonUnit.Percentage,
        }}
        height={{
          value: 50,
          unit: SkeletonUnit.Pixel,
        }}
      />
    </Grid.Item>
    <Grid.Item width={[1]}>
      <SkeletonLoader
        width={{
          value: 120,
          unit: SkeletonUnit.Pixel,
        }}
        height={{
          value: 20,
          unit: SkeletonUnit.Pixel,
        }}
      />
      <SkeletonLoader
        width={{
          value: 100,
          unit: SkeletonUnit.Percentage,
        }}
        height={{
          value: 50,
          unit: SkeletonUnit.Pixel,
        }}
      />
    </Grid.Item>
    <Grid.Item width={[1]}>
      <SkeletonLoader
        width={{
          value: 120,
          unit: SkeletonUnit.Pixel,
        }}
        height={{
          value: 20,
          unit: SkeletonUnit.Pixel,
        }}
      />
      <SkeletonLoader
        width={{
          value: 100,
          unit: SkeletonUnit.Percentage,
        }}
        height={{
          value: 50,
          unit: SkeletonUnit.Pixel,
        }}
      />
    </Grid.Item>

    <Grid.Item width={[1 / 2, 1 / 2]}>
      <DaySelectorSkeleton />
    </Grid.Item>
    <Grid.Item width={[1 / 2, 1 / 2]}>
      <TimeSelectorSkeleton />
    </Grid.Item>

    <Grid.Item width={[1]}>
      <SkeletonLoader
        width={{
          value: 100,
          unit: SkeletonUnit.Percentage,
        }}
        height={{
          value: 50,
          unit: SkeletonUnit.Pixel,
        }}
      />
    </Grid.Item>
  </Grid.Container>
);
