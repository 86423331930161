import {
  size,
  colors,
  Container as LeavesContainer,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled(LeavesContainer)`
  display: flex;
  width: 100%;
`;

export const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 550px;
  padding: ${size.xl4}px 0 ${size.xl5}px;
  margin: auto;
  color: ${colors.gray5};
`;

export const Copy = styled.p`
  ${typography.bodyTextSmall}
  margin: 0;
  text-align: left;
`;

export const Link = styled.a`
  ${typography.buttonTextSmall}
`;

export const Disclaimer = styled.div`
  ${typography.bodyTextSmall}
  padding: ${size.md}px;
  background-color: ${colors.teal1};
  border-radius: 8px;
  margin-top: ${size.lg}px;
  text-align: left;

  span {
    ${typography.buttonTextSmall}
    white-space: nowrap;
  }
`;

export const Spinner = styled.div`
  animation: opacity 0.8s linear infinite;
  border-color: transparent;
  border-radius: 100%;
  border-style: solid;
  border-top-color: ${colors.gray1};
  border-width: 4px;
  box-sizing: border-box;
  height: 32px;
  width: 32px;

  @keyframes opacity {
    0% {
      opacity: 1;
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
      opacity: 0.4;
    }
    100% {
      opacity: 1;
      transform: rotate(360deg);
    }
  }
`;
