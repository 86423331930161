import { typography, H2, mediaQueries, breakpoints } from '@everlywell/leaves';
import { size } from '@everlywell/leaves';
import { sectionTitleExtraLarge } from 'common/styles/app-styles';
import styled, { css } from 'styled-components';

type WrapperProps = {
  backgroundSrc?: string;
  noTopBorder?: boolean;
};

const backgroundImage = css<WrapperProps>`
  background-image: ${(props) => `url("${props.backgroundSrc}")`};
  background-repeat: no-repeat;
  background-position: bottom center;
  padding-bottom: 100px;

  ${mediaQueries.forBigDesktopUp} {
    background-size: 100%;
  }
`;

const NewLayoutSectionHeaderWrapper = styled.div<WrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) => props.backgroundSrc && backgroundImage};
  text-align: center;
  position: relative;

  ${mediaQueries.forBigDesktopUp} {
    background-size: 100%;
  }

  & img {
    margin-top: 83px;
    margin-bottom: 32px;
  }
`;

const Header = styled(H2)`
  width: 100%;
  padding: 0;
  margin: 0;

  ${mediaQueries.forTabletVerticalUp} {
    ${sectionTitleExtraLarge};
    font-weight: ${typography.weight.bold};
  }
`;

const Description = styled.p`
  position: relative;
  ${typography.bodyText};
  padding: 0;
  margin: ${size.xs1}px 0;
  z-index: 1;
  ${mediaQueries.forDesktopUp} {
    max-width: ${breakpoints.forDesktopUp}px;
  }
`;

export { NewLayoutSectionHeaderWrapper, Header, Description };
