import {
  H2,
  Container,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const CarePlanContainer = styled(Container)`
  min-height: 300px;
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const CarePlanHeaderIcon = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  margin-top: 56px;

  ${mediaQueries.forTabletVerticalUp} {
    display: none;
  }
`;

export const Icon = styled.img`
  align-self: center;
`;

export const CarePlanHeader = styled(H2)`
  margin-bottom: ${size.xl1}px;
  text-align: center;
  font-weight: ${typography.weight.xbold};

  ${mediaQueries.forTabletVerticalUp} {
    text-align: left;
  }
`;

export const DisclaimerText = styled.div`
  ${typography.bodyTextSmall};
  line-height: 26px;
  letter-spacing: 0.25px;
  font-feature-settings: 'ss01' on;
  font-weight: ${typography.weight.light};
  text-align: center;
  margin: ${size.lg}px ${size.md}px;

  ${mediaQueries.forTabletVerticalUp} {
    text-align: left;
    margin: ${size.lg}px 0 ${size.lg}px;
    br {
      display: none;
    }
  }

  a {
    color: #006298;
    text-decoration: none;
  }
`;
