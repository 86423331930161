export const ANALYTICS = {
  CATEGORIES: {
    NAVIGATION: 'Navigation',
    COMMON_QUESTIONS: `Common Questions`,
    WHATS_NEXT: `What's Next`,
    SHARE_RESULTS: 'Share Results',
    TELEHEALTH: 'Telehealth',
    GROUP_BY: 'FS - Group by Food',
    DOWNLOAD_PDF: 'Download PDF',
    FAQ_TOOLTIPS: 'FAQ Tooltips',
    EXPLORE_MARKERS: 'Explore Markers',
    FS_MARKER_GROUP: 'FS marker group card',
    RESULTS: 'Results',
    REGISTRATION: 'Registration',
    CONFIRM_IDENTIFIERS: 'Confirm Identifiers',
    DASHBOARD: 'Dashboard',
    TAKE_ACTION: 'Take Action',
    RESOURCES_LINKS: 'Resources Links',
    ACCOUNT_SETTINGS: 'Account Settings',
    NOTIFICATION: 'Notification',
    CARE_PATHWAYS: 'Care Pathways',
    WEIGHT_CARE: 'Weight Care+',
    ACCOUNT_HUB: 'Account Hub',
    CREDIT_REDEMPTION: 'Credit Redemption',
    NAVBAR_NAVIGATION: 'Navbar Navigation',
  },
  EVENTS: {
    CHANGE_VALUE: 'Change Value',
    CLEAR_NOTIFICATION: 'Notification Cleared',
    CLICKED_BUTTON: 'Clicked Button',
    CLICKED_LINK: 'Clicked Link',
    VIEWED_PAGE: 'Viewed Page',
    VIEWED_COMPONENT: 'Viewed Component',
    VIEWED_NOTIFICATION: 'Viewed Notification',
    API_FAILURE: 'API Failure',
    API_SUCCESS: 'API Success',
    FORM_INTERACTION: 'Begin Form Interaction',
    NOTIFICATION: 'Notification',
    QR_CODE_USED: 'QR Code Used at Registration',
    QUESTION_SKIPPED: 'Question Skipped',
    RESPONSE: 'Question Response',
    SWIPE: 'Slider Swipe Gesture',
    PRE_REGISTER_SCREENER_ANSWER: 'Pre Register Screener Answer',
    OPENED_SHARE_EXPERIENCE: 'Extole Refer a Friend Modal Opened',
  },
  LABELS: {
    ACCOUNT_HUB: {
      HEADER: 'Viewed Header',
      TEST_RESULTS_CARD: 'Viewed Test Results',
      MEDICATIONS_CARD: 'Viewed Medications Card',
      HEALTH_PROFILE_CARD: 'Viewed Health Profile card',
      TELEHEALTH_CARD: 'Viewed Telehealth card',
      SETTINGS: 'Viewed Settings',
      START_HEALTH_PROFILE: 'Start Health Profile button',
      FINISH_HEALTH_PROFILE: 'Finish Health Profile button',
      VIEW_RESULTS: 'View Results button',
      TEST_RESULTS: 'Test Results button',
      TELEHEALTH_CLICK: 'Telehealth button',
      TELEHEALTH_CLICK_LEARN_MORE: 'Telehealth Learn More button',
      SETTINGS_CLICK: 'Settings button',
      EDIT_PROFILE: 'Edit Profile button',
      SHOP_PRODUCTS: 'Shop Products button',
      REGISTER_KIT: 'Register Kit button',
      BOOK_APPT: 'Book Appointment button',
      JOIN_APPT: 'Join Appointment button',
      MANAGE_APPT: 'Manage Appoinment button',
      VIEW_CARE_PLAN: 'View Care Plan button',
      VIEW_ORDER_CONFIRMATION_MODAL: 'View Order Confirmation modal',
      MEDICATION_EXPAND: 'Expand medications',
      MEDICATION_COLLAPSE: 'Collapse medications',
    },
    ADD_NEW_CARD_MODAL: 'Add New Card',
    BASIC_INFORMATION: 'Basic Information',
    COLLECT_SAMPLE_NOW: 'Collect Sample Now',
    COLLECT_SAMPLE_LATER: 'Collect Sample Later',
    CONFIRM_IDENTIFIERS: 'Confirm Identifiers',
    CONSIDER_CHANGES: 'Consider Some Changes',
    CURRENT_MARKER: 'Current Marker',
    DASHBOARD_MEMBERSHIP_ON_RAMP: 'Dashboard Membership On Ramp',
    DASHBOARD_MEMBERSHIP_REDEEM_CREDIT: 'Dashboard Membership Redeem Credit',
    DOWNLOAD_PDF_REPORT: 'Download PDF Report',
    FACT_SET_PERSONALIZATION: 'Fact Set Personalization',
    FACT_SOURCE: 'Fact Source',
    FAQS: 'FAQs',
    FAQ_TOOLTIP_1_TOP: 'FAQ Top',
    FAQ_TOOLTIP_2_MIDDLE: 'FAQ Middle',
    FAQ_TOOLTIP_3_BUTTON: 'FAQ Bottom',
    FINISH_HEALTH_PROFILE_BUTTON: 'Finish Health Profile Button',
    FOOTER: 'Site Footer',
    APP_STORE: 'App Store CTA in Footer',
    FS_WIDGET: 'FS Personalization',
    FS_WIDGET_LINK: 'FS Personalization Link',
    FS_ON_RAMP_LABEL: 'Elimination Diet Promo',
    GIFT_CARDS: 'Gift Cards',
    HEALTH_PROFILE_BANNER: 'Health Profile Banner',
    HISTORICAL_MARKER: 'Historical Marker',
    CARE_DASHBOARD: {
      DASHBOARD: {
        label: 'Care Pathways Dashboard',
      },
      DISCOVER_MORE: {
        label: 'Care Pathways Dashboard - Discover More',
        article: 'Care Pathways Dashboard - Discover More Article ',
        nextSlide:
          'Care Pathways Dashboard Discover More - Next Slide Button Interaction',
        prevSlide:
          'Care Pathways Dashboard Discover More - Previous Slide Button Interaction',
        swipe:
          'Care Pathways Dashboard Discover More - Carousel Swipe Mobile Interaction',
      },
      FAQ_SECTION: {
        label: 'Care Pathways Dashboard - FAQ section',
        question: 'Care Pathways Dashboard - FAQ',
      },
      CANCELLATION_MODULE: {
        label: 'Care Pathways Dashboard - Cancellation module',
      },
    },
    CARE_INTAKE: {
      CONFIRMATION: 'Care Pathways Intake - Confirmation',
      SHIPPING: 'Care Pathways Intake - Shipping',
      NEXT: {
        SHIPPING_INFO: 'Care Pathways Intake - Shipping next button',
        PAYMENT_INFO: 'Care Pathways Intake - Payment information next button',
      },
      PAGE: 'Care Pathways Intake',
      PAYMENT_INFO: 'Care Pathways Intake - Payment Information',
      WANTS_MARKETING: 'Wants Marketing',
    },
    WEIGHT_CARE: {
      DASHBOARD: {
        DISCOVER_MORE: {
          swipe: 'Weight Care+ Dashboard Recommended Articles - Carousel Swipe',
        },
        APP_STORE_SECTION: 'Weight Care+ Dashboard - App Store Section',
        RECOMMENDED_ARTICLES_SECTION:
          'Weight Care+ Dashboard - Recommended Articles Section',
        JOURNEY_CHECKLIST_SECTION:
          'Weight Care+ Dashboard - Journey Progress Checklist Section',
      },
      ENROLLMENT: {
        PAYMENT_INFO: 'Weight Care+ Checkout - Payment Information',
      },
      JOURNEY_CHECKLIST: {
        TASK_BUTTON_CLICK:
          'Weight Care+ Dashboard - Journey Progress Checklist Section - Task Button Click',
        OPEN_ACCORDION:
          'Weight Care+ Dashboard - Journey Progress Checklist Section - Expand Accordion Click',
      },
    },
    NOTIFICATION: 'Notification',
    NO_PCP_QUESTIONS: 'No PCP Questions',
    NO_PCP_QUESTIONS_SAVE_RESULTS: 'No PCP Questions Save Results Button',
    PAYMENT_INFORMATION: 'Payment Information',
    PCP_QUESTIONS: 'PCP Questions',
    PCP_QUESTIONS_SAVE_RESULTS: 'PCP Questions Save Results Button',
    PRE_REGISTER_DISCLAIMER_CTA: 'Pre Register Disclaimer CTA Click',
    PHYSICIAN_CONTACT_FORM: 'Physician Contact Form',
    RAF_VARIANT_A: 'RAF Variant A Offered',
    RAF_VARIANT_B: 'RAF Variant B Offered',
    REFER_FRIENDS: 'Refer Friends',
    REFER_FRIENDS_REWARD: 'Refer friends and earn rewards',
    REGISTRATION: 'Registration',
    RESOURCE_LINKS: 'Resource Links',
    RESOURCE_LINK_BUTTON: 'Read Article',
    RESULTS_INTRO: 'Results Introduction',
    SELECT_SUGGESTED_ADDRESS: 'Selected Suggested Address',
    SHIPPING_INFORMATION: 'Shipping Information',
    START_HEALTH_PROFILE_BUTTON: 'Start Health Profile Button',
    TELEHEALTH: 'Telehealth',
    THUMBS_DOWN_BUTTON: 'Thumbs Down Button',
    THUMBS_UP_BUTTON: 'Thumbs Up Button',
    VIEW_RESULTS: 'View Results',
    WATCH_WEBINAR: 'Watch Webinar',
    WHATS_NEXT: "Decide What's Next",
    RESULTS_SECTION: 'Results Section',
    CARE_PLAN: {
      CARE_PLAN_PAGE: 'Care plan',
      TEST_RECOMMENDATION: 'Test recommendation',
      TEST_RECOMMENDATION_ADD_TO_CART: 'Test recommendation add to cart',
      PATIENT_PLAN: 'Patient plan',
      CARE_PLAN_VIEW_PRODUCT: 'Care Plan View Product',
      DOWNLOAD_CARE_PLAN_PDF: 'Download Care Plan PDF',
      CARE_PLAN_REDEEM_CREDIT: 'Care Plan Redeem Credit',
    },
    VIRTUAL_CARE_FORMS: {
      CONTINUE: 'continue',
      CREDIT_CARD_CONTINUE: 'credit card continue',
      DEMOGRAPHICS_CONTINUE: 'Demographics continue',
      INSURANCE_CONTINUE: 'Insurance continue',
      MEDICAL_INTAKE_CONTINUE: 'Medical intake continue',
      WELCOME_TO_PROGRAM_CONTINUE: 'Welcome continue',
      PATIENT_REGISTRATION_CONTINUE: 'Continue to consents',
    },
    VIRTUAL_CARE: {
      APPOINTMENT_SCHEDULING: {
        APPOINTMENT_TYPE_SELECTION_CONTINUE:
          'Continue (VCV appointment type selection)',
        PROVIDER_SELECTION_CONTINUE: 'Continue (VCV provider selection)',
        APPOINTMENT_SELECTION_CONTINUE: 'Continue (Appointment Page)',
        APPOINTMENT_SELECTION_BACK: 'Back (Appointment Page)',
        REVIEW_BOOKING_CONTINUE: 'Confirm and book (VCV review visit details)',
        REVIEW_BOOKING_BACK: 'Back (VCV review visit details)',
      },
    },
    TELEHEALTH_STATE_SELECT_CTA: 'State Selector CTA click',
    SAMPLE_COLLECTION_VIDEOS: 'Sample Collection Videos on Results Dashboard',
    ACCOUNT_SETTINGS: {
      TABS: {
        ACCOUNT: 'Settings Account Tab',
        MEMBERSHIPS: 'Settings Memberships Tab',
      },
      SHIPPING_ADDRESS: {
        EDIT: 'Edit Weight Care+ Shipping Address',
        SAVE: 'Save Weight Care+ Shipping Address',
        CANCEL: 'Cancel Weight Care+ Shipping Address',
      },
      PAYMENT: {
        EDIT: 'Edit Weight Care+ Payment',
        SAVE: 'Save Weight Care+ Payment',
        CANCEL: 'Cancel Weight Care+ Payment',
      },
    },
    CREDIT_REDEMPTION: {
      CLOSE_CONSENT_MODAL: 'Close consent Modal',
      CONTINUE_CONSENT_MODAL: 'Use My Credit for Virtual Care',
      ADDRESS_CONFIRMATION_EDIT_CLICK: 'Address Confirmation - Edit Click',
      ADDRESS_CONFIRMATION_BACK_CLICK: 'Address Confirmation - Back Click',
      ADDRESS_CONFIRMATION_CONFIRM_CLICK:
        "Address Confirmation - We're All Set Click",
      ADDRESS_FORM_BACK_CLICK: 'Address Form - Back Click',
      ADDRESS_FORM_CONFIRM_CLICK: 'Address Form - Submit Click',
      PRODUCT_SELECTION_BACK_CLICK: 'Product Selection - Back Click',
      PRODUCT_SELECTION_CLICK: 'Product Selection  - Order Placed',
      CONFIRMATION_CLICK: 'Confirmation - Button Click',
      TEST_KIT_OR_VCV_SELECTION_BACK_CLICK:
        'Test Kit Or VCV Selection - Back Click',
      TEST_KIT_OR_VCV_SELECTION_PROCEED_CLICK:
        'Test Kit Or VCV Selection - Proceed Click',
    },
    VIRTUAL_INTAKE: {
      REVIEW: {
        SELECTION_BACK: 'back',
        SELECTION_SUBMIT: 'submit',
      },
      CONFIRMATION: {
        GO_TO_DASHBOARD: 'go to my everly portal',
      },
    },
    EHS_T2T: {
      DATA_PRIVACY_POLICY_LINK: 'Data Privacy Policy - Link Click',
      BOOK_AN_APPOINTMENT: 'Book an Appointment CTA',
      MANAGE_AN_APPOINTMENT: 'Manage Appointment CTA',
      CANCEL_AN_APPOINTMENT: 'Cancel Appointment CTA',
      RETURN_TO_DASHBOARD: 'Return to Dashboard CTA',
      FAQ_LINK: 'FAQ CTA',
      PHONE_NUMBER_LINK: 'Patient care phone number',
      SCHEDULE_APPOINTMENT: 'Schedule new appt CTA',
    },
    EHS_T2T_FORMS: {
      CONFIRMATION_CONTINUE: 'Continue to athena portal',
      CONSENT_CONTINUE: 'Continue to insurance or scheduling',
      INSURANCE_CONTINUE: 'Insurance continue',
    },
  },
  PAGES: {
    ACCOUNT_HUB: {
      CREDIT_CARD: 'credit card',
      DASHBOARD: 'Account Hub Dashboard',
      DEMOGRAPHICS: 'Demographics',
      MEDICAL_INTAKE: 'Medical Intake',
      MEDICATIONS: 'Medications',
      PAYMENT_DETAILS: 'Payment details',
      VIRTUAL_CARE: 'Virtual Care',
      WEIGHT_CARE_PLUS: 'Weight Care+',
      WELCOME_TO_PROGRAM: 'Welcome To Program',
    },
    ACCOUNT_SETTINGS: 'Account Settings',
    ALL_RESULTS: 'All Results',
    COVID_SCREENER: {
      newYorkQuestions: 'New York Question',
      covidVaccine: 'Vaccine Question',
      highRisk: 'High Risk Question',
      multiQuestions: 'Multi Question',
      exposure: 'Exposure Question',
      symptoms: 'Symptoms Question',
    } as { [key: string]: string },
    DASHBOARD: 'Results Dashboard',
    DATA_SHARING_MODAL: 'Data Sharing Modal',
    INTERSTITIAL: {
      NO_AREA_SERVICE_MODAL: {
        PAGE: 'On demand state not supported',
        CTA_SCHEDULE_APPOINTMENT: 'State not supported future appointment',
      },
      NO_ASYNC_SERVICE_AREA_MODAL: {
        PAGE: 'Async Rx state not supported',
        CTA_SCHEDULE_APPOINTMENT:
          'Async Rx state not supported - future appointment',
      },
    },
    KIT_RESULT: 'Kit Result',
    REGISTRATION: {
      registration: 'Kit ID Form',
      preScreener: 'Pre Screener Modal',
      preDisclaimer: 'Pre Disclaimer Modal',
      'tester info': 'Tester Information',
      sample: 'Sample Collection',
      screener: 'COVID-19 Screener',
      review: 'Registration Confirmation',
      done: 'Registration Complete',
    },
    SHARED_KIT_RESULT: 'Shared Kit Result',
    TELEHEALTH_INSURANCE_DETAILS_PAGE: 'Insurance details',
    TELEHEALTH_INSURANCE_PAGE: 'Insurance Page',
    TELEHEALTH_INSURANCE_CASH_PAY: 'Insurance or cash pay',
    TELEHEALTH_SESSIONS: 'Interstitial Page for Virtual Care Visit',
    TELEHEALTH_SKIP_INSURANCE_MODAL: 'Skip insurance modal',
    VIRTUAL_CARE_INTAKE: 'Virtual Care Intake',
    VIRTUAL_CARE: {
      APPOINTMENT_SCHEDULING: {
        APPOINTMENT_TYPE_SELECTION: 'VCV appointment type selection',
        PROVIDER_SELECTION: 'VCV provider selection',
        REVIEW_BOOKING: 'VCV review visit details',
      },
    },
    ON_DEMAND_CONFIRMATION: 'On-demand confirmation',
    CREDIT_REDEMPTION: {
      TEST_KIT_OR_VCV_SELECTION: 'Test Kit Or VCV Step Viewed',
      ADDRESS: 'Address Confirmation Step Viewed',
      PRODUCT_SELECTION: 'Product Selection Step Viewed',
      CONFIRMATION: 'Confirmation Step Viewed',
      CONSENT_MODAL: 'ConsentModal',
    },
    CANCEL_APPOINTMENT_PAGE: 'Cancel appointment page',
    APPOINTMENT_TIME: 'appointment time page',
    EHS_T2T: {
      CONSENTS_PAGE: 'T2T Consents Page',
      HOMEPAGE: 'T2T Patient Home Page',
      REGISTRATION_PAGE: 'T2T Patient Registration Page',
      INSURANCE_PAGE: 'T2T Insurance Page',
      CANCEL_APPOINTMENT_CONFIRMATION_PAGE:
        'T2T Cancel Appointment Confirmation Page',
      CONFIRMATION_PAGE: 'T2T Confirmation Page',
      CANCEL_APPOINTMENT_PAGE: 'T2T Cancel Appointment Review Page',
      CANCEL_APPOINTMENT_MODAL: 'T2T Cancel Appointment Warning Page',
      APPOINTMENT_REASON_PAGE: 'T2T Reason for Visit Page',
    },
  },
};
