import { colors, H3, mediaQueries, size, typography } from '@everlywell/leaves';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

type LabelContainerProps = {
  firstLabel?: boolean;
  withQR?: boolean;
};

const Title = styled(H3)`
  font-weight: ${typography.weight.bold};
  padding-bottom: ${size.sm}px;
`;

const Subtitle = styled.p`
  ${typography.bodyTextSmall}
  font-weight: ${typography.weight.book};
  color: ${colors.gray4};
  margin-bottom: ${size.xl2}px;
`;

const ShapeContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  ${mediaQueries.forTabletHorizontalUp} {
    flex-flow: row nowrap;
  }
`;

const CombinedShape = styled.div`
  width: 245px;
  height: 123px;
  box-shadow: 0 ${size.xs1}px 37px -${size.sm}px #dce4e5;
  background-color: ${(props) => props.color};
  color: ${colors.black};
  margin-bottom: ${size.xl1}px;
  position: relative;
  ${mediaQueries.forTabletHorizontalUp} {
    margin-right: ${size.xl3}px;
  }
`;

const Notch = styled.div`
  position: absolute;
  top: ${size.sm}px;
  left: 80%;
  transform: translate(-50%, -50%) rotate(180deg);
  height: 25px;
  width: 20px;
  border-radius: 150px 150px 0 0;
  background-color: white;
`;

const LabelContainer = styled.div<LabelContainerProps>`
  background-color: ${colors.white};
  width: 112px;
  height: ${size.xl1}px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: ${size.md}px;
  right: ${size.lg}px;
  ${({ withQR }) =>
    withQR &&
    `
    padding-left: 6px;
    height: ${size.xl3}px;
    width: 106px;

    img {
      width: 36px;
      height: 36px;
    }

    p {
      font-size: 14px;
      padding-left: ${size.sm}px;
    }
  `}
`;

const Link = styled(RouterLink)`
  color: ${colors.green5};
  font-weight: ${typography.weight.bold};
  text-decoration: none;

  &:hover {
    color: ${colors.blue1};
  }
`;

export {
  CombinedShape,
  Title,
  LabelContainer,
  ShapeContainer,
  Subtitle,
  Notch,
  Link,
};
