import { size, SkeletonLoader, SkeletonUnit } from '@everlywell/leaves';
import VisuallyHidden from 'components/VisuallyHidden';
import React from 'react';

import * as S from './LoadingCarePlan.styles';

export type LoadingCarePlanProps = {};

export const LoaderSmall = () => (
  <SkeletonLoader
    css={{ marginTop: `${size.xl1}px` }}
    height={{ value: 20, unit: SkeletonUnit.Pixel }}
    width={{ value: 15, unit: SkeletonUnit.Percentage }}
  />
);

const LoaderLarge = () => (
  <SkeletonLoader
    css={{ marginTop: `${size.xs1}px` }}
    height={{ value: 20, unit: SkeletonUnit.Pixel }}
    width={{ value: 50, unit: SkeletonUnit.Percentage }}
  />
);

export const LOADING_TEXT = 'Loading care plan information';

/**
 * Loading state component for the care plan
 */
function LoadingCarePlan(props: LoadingCarePlanProps) {
  return (
    <S.Container>
      <VisuallyHidden>{LOADING_TEXT}</VisuallyHidden>
      <S.ContentContainer>
        <LoaderSmall />
        <LoaderLarge />
        <LoaderSmall />
        <LoaderLarge />
      </S.ContentContainer>
    </S.Container>
  );
}

export default LoadingCarePlan;
