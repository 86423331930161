import { mediaQueries, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  ${typography.bodyTextSmall};

  ${mediaQueries.forTabletVerticalUp} {
    ${typography.bodyText};
  }

  p {
    margin: 0;
  }
`;
