import { mediaQueries, size, Button, Input } from '@everlywell/leaves';
import styled from 'styled-components';

const NextBtn = styled(Button)`
  width: 556px;
  height: ${size.xl3}px;
  margin: 28px ${size.md}px 0 0;
  padding: ${size.sm}px 148px ${size.sm}px 148px;
  border-radius: 1px;
  box-shadow: 0 ${size.xs3}px ${size.md}px -${size.xs2}px rgba(0, 0, 0, 0.15);

  ${mediaQueries.forTabletVerticalDown} {
    width: 100%;
  }

  ${mediaQueries.forPhoneOnly} {
    margin: 85% 0 0;
    padding: ${size.sm}px 87px ${size.sm}px 87px;
  }
`;

const InputDate = styled(Input)`
  width: 556px;
  height: 100px;
  min-height: 100px;

  ${mediaQueries.forTabletVerticalDown} {
    width: 100%;
  }

  ${mediaQueries.forPhoneOnly} {
    height: 100px;
  }

  input {
    min-height: 50px;
  }

  svg {
    fill: none;
  }
`;

export { NextBtn, InputDate };
