export const RACE: { [value: string]: string } = {
  'American Indian Or Alaskan Native': '0',
  Asian: '1',
  'Black Or African American': '2',
  'Native Hawaiian Or Pacific Islander': '3',
  'Two Or More Races': '4',
  White: '5',
  Other: '6',
  'Prefer Not To Answer': '7',
};

export const ETHNICITY: { [value: string]: string } = {
  'Latino Or Hispanic': '0',
  'Not Latino Or Hispanic': '1',
  'Prefer Not To Answer': '2',
};
