import { Icons } from '@everlywell/leaves';
import { format, subMonths } from 'date-fns';
import React from 'react';

import * as S from './MonthSelector.styles';

export type MonthSelectorPropsChangeHandler = () => void;

export type MonthSelectorProps = {
  /* Current month, 0 => Jan, 1 => Feb ... */
  month: number;

  /* Current year */
  year: number;

  /* Minimum date that can be selected */
  min?: Date;

  /* Callback for when the next button is clicked */
  onNext: MonthSelectorPropsChangeHandler;

  /* Callback for when the previous button is clicked */
  onPrev: MonthSelectorPropsChangeHandler;

  onNextHover?: React.MouseEventHandler<HTMLElement>;

  onPrevHover?: React.MouseEventHandler<HTMLElement>;
};

/**
 * Component for selecting a month
 */
function MonthSelector({
  month,
  year,
  min,
  onNext,
  onPrev,
  onNextHover,
  onPrevHover,
}: MonthSelectorProps) {
  const date = new Date(year, month);

  const disabled =
    min && subMonths(date, 1) < new Date(min.getFullYear(), min.getMonth());

  return (
    <S.Container>
      <S.StyledButton
        className="svg-class"
        type="button"
        disabled={disabled}
        appearance="text"
        aria-label="Previous month"
        onClick={() => onPrev()}
        onMouseEnter={onPrevHover}
      >
        <Icons.LeftCaret />
      </S.StyledButton>
      <S.Label role="heading">
        <span>{format(date, 'MMMM')}</span> <span>{format(date, 'yyyy')}</span>
      </S.Label>
      <S.StyledButton
        className="svg-class"
        type="button"
        appearance="text"
        aria-label="Next month"
        onClick={() => onNext()}
        onMouseEnter={onNextHover}
      >
        <Icons.RightCaret />
      </S.StyledButton>
    </S.Container>
  );
}

export default MonthSelector;
