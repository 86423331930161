import { throttle } from 'lodash';
import { useEffect, useRef, RefObject } from 'react';

export const THROTTLE = 200;

const useScrolledIntoViewOnce = (
  action: Function,
  ref?: RefObject<HTMLDivElement>,
) => {
  /**
   * To satisfy react-hooks/rules-of-hooks, instead of
   * conditionally calling useRef, we need to call useRef
   * everytime, then reassign to ref if ref exists. This
   * way, the hooks are called in the same order every time.
   */
  let containerEl = useRef<HTMLDivElement>(null);

  if (ref) containerEl = ref;

  const isInView = () => {
    if (!containerEl.current) {
      return null;
    }

    const windowHeight = window.innerHeight;
    const { height: containerHeight, top: containerTop } =
      containerEl.current.getBoundingClientRect();

    // If element top is greater than 0 and at least half of the element is in viewport, track it
    return (
      containerTop > 0 && containerHeight / 2 + containerTop < windowHeight
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isInView()) {
        action();
        stopListening();
      }
    };

    const handleScrollThrottled = throttle(handleScroll, THROTTLE);

    const stopListening = () => {
      window.removeEventListener('scroll', handleScrollThrottled);
    };

    if (isInView()) {
      action();
      return;
    }

    window.addEventListener('scroll', handleScrollThrottled);

    return () => {
      stopListening();
    };
  }, [action]);

  return containerEl;
};

export { useScrolledIntoViewOnce };
