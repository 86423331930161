import { mediaQueries } from '@everlywell/leaves';
import PageHeader from 'components/PageHeader';
import styled from 'styled-components';

const Content = styled.div`
  background-color: #f9f9f9;
  padding: 16px 24px 48px;
`;

const InnerContent = styled.div`
  margin: 0 auto;
  max-width: 1157px;
  min-height: 1056px;

  ${mediaQueries.forTabletHorizontalUp} {
    min-height: 722px;
  }
`;

const UpdatedPageHeader = styled(PageHeader)`
  background: #f9f9f9;
  min-height: 0;
  padding: 16px 24px 12px 10px;
`;

export { Content, InnerContent, UpdatedPageHeader as PageHeader };
