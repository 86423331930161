import DBS1 from '../assets/sample-collection-tips-illustrations-dbs-1.svg';
import DBS2 from '../assets/sample-collection-tips-illustrations-dbs-2.svg';
import DBS3 from '../assets/sample-collection-tips-illustrations-dbs-3.svg';
import DBS4 from '../assets/sample-collection-tips-illustrations-dbs-4.svg';

const DBSSteps = [
  {
    id: '1',
    image: DBS1,
    alt:
      'Write your name, date of birth, and collection date on the card. The lab cannot process your sample if this step is skipped.',
    description:
      'Write your name, date of birth, and collection date on the card. The lab cannot process your sample if this step is skipped.',
  },
  {
    id: '2',
    image: DBS2,
    alt:
      'Fill ALL circles with large, free-falling drops until they are completely full. It’s okay if blood goes outside of the line.',
    description:
      'Fill ALL circles with large, free-falling drops until they are completely full. It’s okay if blood goes outside of the line.',
  },
  {
    id: '3',
    image: DBS3,
    alt:
      'Flip the card over and check that each blood drop has soaked through the back of the card before allowing to dry.',
    description:
      'Flip the card over and check that each blood drop has soaked through the back of the card before allowing to dry.',
  },
  {
    id: '4',
    image: DBS4,
    alt: 'Leave card out for at least 30 minutes or until completely dry.',
    description:
      'Leave card out for at least 30 minutes or until completely dry.',
  },
];

export { DBSSteps };
