import useContentfulRecommendedArticles from 'common/hooks/useContentfulRecommendedArticles';
import analytics from 'common/utils/analytics';
import { Article } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { DATA_TEST_WEIGHT_MANAGEMENT } from 'common/utils/constants/dataTest';
import React, { SyntheticEvent, useCallback, useRef } from 'react';
import { Controller } from 'swiper/modules';
import { Swiper, SwiperRef } from 'swiper/react';

import * as S from './RecommendedArticlesSection.styles';

export type RecommendedArticlesProps = {
  /** This is the text that is displayed in the component*/
  title: string;
};

const PAGE_CONTAINER_ID = '32x68CjvK3dcmCQ3ukI7XB';
const REDIRECT_URL =
  'https://www.everlywell.com/blog/categories/weight-management/';

/**
 * [Add the description for what this component does here].
 */
function RecommendedArticlesSection(props: RecommendedArticlesProps) {
  const { title } = props;
  const { isLoading, articles } =
    useContentfulRecommendedArticles(PAGE_CONTAINER_ID);

  const sliderRef = useRef<SwiperRef | null>(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();

    analytics.track({
      event: `${ANALYTICS.EVENTS.CLICKED_BUTTON} (previous slide)`,
      data: {
        label:
          ANALYTICS.LABELS.WEIGHT_CARE.DASHBOARD.RECOMMENDED_ARTICLES_SECTION,
        description: ANALYTICS.LABELS.WEIGHT_CARE.DASHBOARD.DISCOVER_MORE.swipe,
      },
    });
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();

    analytics.track({
      event: `${ANALYTICS.EVENTS.CLICKED_BUTTON} (next slide)`,
      data: {
        label:
          ANALYTICS.LABELS.WEIGHT_CARE.DASHBOARD.RECOMMENDED_ARTICLES_SECTION,
        description: ANALYTICS.LABELS.WEIGHT_CARE.DASHBOARD.DISCOVER_MORE.swipe,
      },
    });
  }, []);

  const onCardClick = (e: SyntheticEvent, article: Article, index: number) => {
    e.preventDefault();

    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        category: ANALYTICS.CATEGORIES.WEIGHT_CARE,
        label: `${ANALYTICS.LABELS.WEIGHT_CARE.DASHBOARD.RECOMMENDED_ARTICLES_SECTION} ${index}`,
        title: article.title,
      },
    });

    window.location.assign(REDIRECT_URL);
  };

  const onSwipe = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.SWIPE,
      data: {
        label:
          ANALYTICS.LABELS.WEIGHT_CARE.DASHBOARD.RECOMMENDED_ARTICLES_SECTION,
        description: ANALYTICS.LABELS.WEIGHT_CARE.DASHBOARD.DISCOVER_MORE.swipe,
      },
    });
  };

  return (
    <>
      <S.SectionText>{title}</S.SectionText>
      {isLoading ? null : (
        <Swiper
          ref={sliderRef}
          // @ts-ignore Seems to be an issue with this version of Swiper - https://stackoverflow.com/questions/74776861/react-swiper-property-slidesperview-does-not-exist-on-type-intrinsicattribute
          modules={[Controller]}
          spaceBetween={16}
          slidesPerView={'auto'}
          onSlideChange={onSwipe}
          pagination={{ clickable: true }}
        >
          {articles?.map((article, index) => {
            const {
              attributesCollection: { items },
              heroSection: {
                mobileImage: { url },
              },
              sectionsCollection: { items: sectionItems },
            } = article;

            return (
              <S.SwiperSlideItem key={`slide-item-${index}`}>
                <S.CardWrapper
                  key={`card-wrapper-${index}`}
                  data-testid={`${DATA_TEST_WEIGHT_MANAGEMENT.DASHBOARD.RECOMMENDED_ARTICLE_CARD}-${index}`}
                  onClick={(e: SyntheticEvent) =>
                    onCardClick(e, article, index)
                  }
                >
                  <S.CardImage src={url} />
                  <S.CardFooter>
                    <S.CardHeadingCopy>
                      {sectionItems[0].headline}
                    </S.CardHeadingCopy>
                    <S.CardFooterBottom>
                      <S.CategoryText>
                        {items[0].program === 'weight-management-full' &&
                          'Weight Care+'}
                      </S.CategoryText>
                      <S.ReadDurationText>
                        {items[1].minutes} min read
                      </S.ReadDurationText>
                    </S.CardFooterBottom>
                  </S.CardFooter>
                </S.CardWrapper>
              </S.SwiperSlideItem>
            );
          })}
          <S.PaginationContainer>
            <S.PaginationButton
              data-testid="pagination-prev"
              onClick={handlePrev}
            >
              <S.PaginationLeft />
            </S.PaginationButton>
            <S.PaginationButton
              data-testid="pagination-next"
              onClick={handleNext}
            >
              <S.PaginationRight />
            </S.PaginationButton>
          </S.PaginationContainer>
        </Swiper>
      )}
    </>
  );
}

export default RecommendedArticlesSection;
