import { ImmutableReduxState } from 'common/utils/types';
import SectionHeader, {
  Props as SectionHeaderProps,
} from 'components/SectionHeader';
import React from 'react';
import { connect } from 'react-redux';
import { makeSelectContent } from 'store/selectors';

import * as S from './styles';

export const CONTENT_KEYS = {
  header: 'TEST_RESOURCES_HEADER',
  description: 'TEST_RESOURCES_SUBHEADER',
  iconSrc: 'TEST_RESOURCES_ICON',
  alt: '',
};

type OwnProps = {
  contentToken: string;
};

export function ResourcesSectionHeaderContainer(props: SectionHeaderProps) {
  const hasContent = !!props.header;

  if (!hasContent) {
    return null;
  }

  return (
    <S.ResourcesLinksSectionWrapper>
      <SectionHeader {...props} />
    </S.ResourcesLinksSectionWrapper>
  );
}

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): SectionHeaderProps {
  return makeSelectContent(ownProps.contentToken, CONTENT_KEYS)(state);
}

export default connect(mapStateToProps)(ResourcesSectionHeaderContainer);
