import { colors, mediaQueries } from '@everlywell/leaves';
import { bodyCopy } from 'common/styles/app-styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import arrow from './SubHeaderButton/images/chevron.svg';

const SubHeader = styled.nav`
  width: 100%;
  background-color: ${colors.white};
  padding: 16px 24px;
  position: relative;
  font-size: 16px;
  display: flex;
  justify-content: center;
`;

const SubHeaderContents = styled.div`
  max-width: 1396px;
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
  position: relative;

  & div:last-child {
    margin-right: 0;
  }

  ${mediaQueries.forDesktopUp} {
    padding: 0 32px;
  }
`;

const Left = styled.div`
  display: block;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 100%;
  justify-content: flex-end;
`;

const BackToDashboard = styled(Link)`
  ${bodyCopy};
  align-items: center;
  color: ${colors.green4};
  display: flex;
  text-decoration: none;
  white-space: nowrap;
  span {
    display: none;

    ${mediaQueries.forTabletVerticalUp} {
      margin-left: 12px;
      display: inline-block;
    }
  }

  &:before {
    content: '';
    display: inline-block;
    height: 11px;
    width: 11px;

    /* prettier-ignore */
    mask: url("${arrow}");
    background: ${colors.green4};
    transform: rotate(90deg);
  }

  &:hover {
    text-decoration: none;

    &:before {
      background: ${colors.green3};
    }
  }
`;

const SaveSubHeaderButtonContainer = styled.div`
  display: none;

  ${mediaQueries.forDesktopUp} {
    display: block;
  }
`;

export {
  SaveSubHeaderButtonContainer,
  SubHeader,
  SubHeaderContents,
  BackToDashboard,
  Left,
  Right,
};
