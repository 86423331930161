import { Test } from 'common/utils/types';

const markerResults = [
  {
    id: 3611189,
    marker_id: 16,
    value: 0.18,
    severity_index: 0,
    descriptor: 'normal reactivity',
    test_result_id: 18247,
    boundaries: [0.2, 0.3, 0.4],
    boundary_conditions: ['upper', 'lower', 'lower'],
    reported_at: '2017-08-04T17:37:50.743Z',
    observation_datetime: null,
    content_token: 'b029c870-e574-4952-83fd-591a6216b547',
    kind: 'food-sensitivity',
  },
  {
    id: 3611201,
    marker_id: 28,
    value: 0.218,
    severity_index: 1,
    descriptor: 'mild reactivity',
    test_result_id: 18247,
    boundaries: [0.2, 0.3, 0.4],
    boundary_conditions: ['upper', 'lower', 'lower'],
    reported_at: '2017-08-04T17:37:50.743Z',
    observation_datetime: null,
    content_token: 'e5c4d24b-af28-4c3a-b158-e3ac49add420',
    kind: 'food-sensitivity',
  },
  {
    id: 3611230,
    marker_id: 57,
    value: 0.366,
    severity_index: 2,
    descriptor: 'moderate reactivity',
    test_result_id: 18247,
    boundaries: [0.2, 0.3, 0.4],
    boundary_conditions: ['upper', 'lower', 'lower'],
    reported_at: '2017-08-04T17:37:50.743Z',
    observation_datetime: null,
    content_token: '9b5b05eb-7c57-4333-913e-328974e8aa74',
    kind: 'food-sensitivity',
  },
  {
    id: 10868247,
    marker_id: 58,
    value: 0.198,
    severity_index: 0,
    descriptor: 'normal reactivity',
    test_result_id: 187419,
    boundaries: [0.199, 0.299, 0.399],
    boundary_conditions: ['lower', 'lower', 'lower'],
    reported_at: '2017-08-04T17:35:05.478Z',
    observation_datetime: null,
    content_token: 'e282dc56-0be8-43da-a454-e900954c56e5',
    kind: 'food-sensitivity',
  },
  {
    id: 10868249,
    marker_id: 59,
    value: 0.165,
    severity_index: 0,
    descriptor: 'normal reactivity',
    test_result_id: 187419,
    boundaries: [0.199, 0.299, 0.399],
    boundary_conditions: ['lower', 'lower', 'lower'],
    reported_at: '2017-08-04T17:35:05.478Z',
    observation_datetime: null,
    content_token: '675bd01c-318c-4e8b-ad15-4d7eaa1f33b6',
    kind: 'food-sensitivity',
  },
  {
    id: 10868251,
    marker_id: 60,
    value: 0.151,
    severity_index: 0,
    descriptor: 'normal reactivity',
    test_result_id: 187419,
    boundaries: [0.199, 0.299, 0.399],
    boundary_conditions: ['lower', 'lower', 'lower'],
    reported_at: '2017-08-04T17:35:05.478Z',
    observation_datetime: null,
    content_token: 'da8b0ef3-227a-4b1e-9ded-3d7e6cea53fa',
    kind: 'food-sensitivity',
  },
  {
    id: 10868253,
    marker_id: 61,
    value: 0.185,
    severity_index: 0,
    descriptor: 'normal reactivity',
    test_result_id: 187419,
    boundaries: [0.199, 0.299, 0.399],
    boundary_conditions: ['lower', 'lower', 'lower'],
    reported_at: '2017-08-04T17:35:05.478Z',
    observation_datetime: null,
    content_token: '32b6fdf0-8d76-4fec-915c-63de5f19a087',
    kind: 'food-sensitivity',
  },
  {
    id: 10868255,
    marker_id: 62,
    value: 0.149,
    severity_index: 0,
    descriptor: 'normal reactivity',
    test_result_id: 187419,
    boundaries: [0.199, 0.299, 0.399],
    boundary_conditions: ['lower', 'lower', 'lower'],
    reported_at: '2017-08-04T17:35:05.478Z',
    observation_datetime: null,
    content_token: '9102c0e3-7a1e-488f-8d62-8ee21dc0f38a',
    kind: 'food-sensitivity',
  },
  {
    id: 10868257,
    marker_id: 63,
    value: 0.222,
    severity_index: 1,
    descriptor: 'mild reactivity',
    test_result_id: 187419,
    boundaries: [0.199, 0.299, 0.399],
    boundary_conditions: ['lower', 'lower', 'lower'],
    reported_at: '2017-08-04T17:35:05.478Z',
    observation_datetime: null,
    content_token: '58ef362d-9a12-44c6-a0bb-a5b408b56fd6',
    kind: 'food-sensitivity',
  },
  {
    id: 10868259,
    marker_id: 64,
    value: 0.149,
    severity_index: 1,
    descriptor: 'normal reactivity',
    test_result_id: 187419,
    boundaries: [0.199, 0.299, 0.399],
    boundary_conditions: ['lower', 'lower', 'lower'],
    reported_at: '2017-08-04T17:35:05.478Z',
    observation_datetime: null,
    content_token: '2dfa34c5-2543-40ae-aa67-8d2abddf39b2',
    kind: 'food-sensitivity',
  },
  {
    id: 10868261,
    marker_id: 71,
    value: 0.41,
    severity_index: 3,
    descriptor: 'high reactivity',
    test_result_id: 187419,
    boundaries: [0.199, 0.299, 0.399],
    boundary_conditions: ['lower', 'lower', 'lower'],
    reported_at: '2017-08-04T17:35:05.478Z',
    observation_datetime: null,
    content_token: '15418e42-44c1-402b-8ddd-b9239735e0f9',
    kind: 'food-sensitivity',
  },
  {
    id: 10868263,
    marker_id: 66,
    value: 0.171,
    severity_index: 0,
    descriptor: 'normal reactivity',
    test_result_id: 187419,
    boundaries: [0.199, 0.299, 0.399],
    boundary_conditions: ['lower', 'lower', 'lower'],
    reported_at: '2017-08-04T17:35:05.478Z',
    observation_datetime: null,
    content_token: 'bfa23453-acbf-4b96-9d73-6709a4a3237e',
    kind: 'food-sensitivity',
  },
  {
    id: 10868265,
    marker_id: 67,
    value: 0.159,
    severity_index: 0,
    descriptor: 'normal reactivity',
    test_result_id: 187419,
    boundaries: [0.199, 0.299, 0.399],
    boundary_conditions: ['lower', 'lower', 'lower'],
    reported_at: '2017-08-04T17:35:05.478Z',
    observation_datetime: null,
    content_token: '312e7da6-a0af-4ed3-b2ed-c4bba635129f',
    kind: 'food-sensitivity',
  },
  {
    id: 10868267,
    marker_id: 68,
    value: 0.187,
    severity_index: 0,
    descriptor: 'normal reactivity',
    test_result_id: 187419,
    boundaries: [0.199, 0.299, 0.399],
    boundary_conditions: ['lower', 'lower', 'lower'],
    reported_at: '2017-08-04T17:35:05.478Z',
    observation_datetime: null,
    content_token: '414123df-32a4-4096-a913-86ce12297a87',
    kind: 'food-sensitivity',
  },
  {
    id: 10868269,
    marker_id: 69,
    value: 0.312,
    severity_index: 2,
    descriptor: 'moderate reactivity',
    test_result_id: 187419,
    boundaries: [0.199, 0.299, 0.399],
    boundary_conditions: ['lower', 'lower', 'lower'],
    reported_at: '2017-08-04T17:35:05.478Z',
    observation_datetime: null,
    content_token: '72ca4e86-247e-4b41-bd80-0511e04f4ffe',
    kind: 'food-sensitivity',
  },
];

const markers = [
  {
    id: 28,
    test_id: 3,
    descriptors: [
      'Normal Reactivity',
      'Mild Reactivity',
      'Moderate Reactivity',
      'High Reactivity',
    ],
    severities: [1, 2, 5, 3],
    groupings: [],
    severity_colors: ['green', 'yellow', 'blue', 'red'],
    name: 'BRAN',
    units: undefined,
    min_value: 0,
    max_value: 3,
    content_token: 'bdea1add-ae4b-4b76-b023-1789109855d9',
    type: 'food-sensitivity',
  },
  {
    id: 16,
    test_id: 3,
    descriptors: [
      'Normal Reactivity',
      'Mild Reactivity',
      'Moderate Reactivity',
      'High Reactivity',
    ],
    severities: [1, 2, 5, 3],
    groupings: [],
    severity_colors: ['green', 'yellow', 'blue', 'red'],
    name: 'ALMOND',
    units: undefined,
    min_value: 0,
    max_value: 3,
    content_token: 'c849ed84-af5c-4352-a12b-cc95291c2d58',
    type: 'food-sensitivity',
  },
  {
    id: 69,
    test_id: 3,
    descriptors: [
      'Normal Reactivity',
      'Mild Reactivity',
      'Moderate Reactivity',
      'High Reactivity',
    ],
    severities: [1, 2, 5, 3],
    groupings: [],
    severity_colors: ['green', 'yellow', 'blue', 'red'],
    name: 'ACORN SQUASH',
    units: undefined,
    min_value: 0,
    max_value: 3,
    content_token: 'c849ed84-af5c-4352-a12b-cc95291c2d66',
    type: 'food-sensitivity',
  },
  {
    id: 71,
    test_id: 3,
    descriptors: [
      'Normal Reactivity',
      'Mild Reactivity',
      'Moderate Reactivity',
      'High Reactivity',
    ],
    severities: [1, 2, 5, 3],
    groupings: [],
    severity_colors: ['green', 'yellow', 'blue', 'red'],
    name: 'CARROT',
    units: undefined,
    min_value: 0,
    max_value: 3,
    content_token: 'a549fd84-ab1c-4362-a13b-ca95222c2d55',
    type: 'food-sensitivity',
  },
];

const markerContentBarley = {
  RESOURCES_NAME_2: 'Health Benefits of Barley',
  RESOURCES_LINK_1: 'http://www.barleyfoods.org/nutrition.html',
  RESOURCES_NAME_1: 'Barley Health & Nutrition Overview',
  RESOURCES_LINK_2:
    'http://wholegrainscouncil.org/whole-grains-101/health-benefits-of-barley',
  OVERVIEW_DESCRIPTION:
    'Barley is a major grain and is widely-regarded as one of the first cultivated grains. It is primarily used in brewing beer and cereal products. It contains hordein which is a prolamin glycoprotein that is present in barley and some other cereals. It is commonly referred to as a "type" of gluten but it contains less gluten than wheat. Watch for barley in rice milks and syrups (especially brown rice syrup), sauces, soups, cereals, protein bars and snack foods.',
};

const markerContentBasil = {
  RESOURCES_LINK_1: 'http://www.nutrition-and-you.com/basil-herb.html',
  RESOURCES_NAME_1: 'Health Benefits of Basil',
  RESOURCES_LINK_2: 'http://www.health.com/health/gallery/0,,20500778,00.html',
  RESOURCES_NAME_2: 'The Sweet Benefits of Basil',
  OVERVIEW_DESCRIPTION:
    'Basil, a perennial plant, is a leafy, green herb, belonging to the mint family. Basil is frequently used in Mediterranean cooking. There are many other vegetable alternatives if removing this item during an elimination diet.',
};

const testContent = {
  RESOURCES_SECTION_HEADER: 'test',
  RESULTS_SECTION_HEADER: 'test',
  WHATS_NEXT_SECTION_HEADER: 'test',
  HELP_SECTION_HEADER: 'test',
  HIGH_REACTIVITY_OVERVIEW:
    'High reactivity foods (Class 3 foods in your lab results) are measured as the strongest immune response in your blood sample. Not all high reactivity foods cause symptoms, but they may be the most likely to—so these are good options to target in an elimination diet.',
};

const content = {
  'b98f873d-495b-45f5-8008-e99b5591838e': testContent,
  'bdea1add-ae4b-4b76-b023-1789109855d9': markerContentBarley,
  'c849ed84-af5c-4352-a12b-cc95291c2d58': markerContentBasil,
};

const test = {
  id: 3,
  name: 'Food Sensitivity Test',
  display_name: 'Food Sensitivity',
  type: 'food-sensitivity',
  marker_types: ['food-sensitivity'],
  content_token: 'b98f873d-495b-45f5-8008-e99b5591838e',
  content: testContent,
  genomics_eligible: true,
  is_food_sensitivity: true,
};

const lab = {
  content_token: 'a1ba523d-0a79-4e33-adb3-2b23ee991afd',
  id: 2345,
  name: 'Alletess, CLIA 12345',
  pwn_lab_identifier: '498',
  ship_address_id: 7163,
};

const kitResult = {
  number: 123,
  product_name: 'Food Sensitivity Test',
  user_email: 'a@b.c',
  kit_result_time: '123',
  status: 'results_approved',
  test_id: 3,
  test,
  lab,
  markers,
  marker_results: markerResults,
  publish_hash: '123',
  content,
  pwn_order_number: 12,
  pwn_confirmation_code: 123,
  consult: {
    available: true,
    available_until: '2050-07-31T14:34:59Z',
  },
  live_webinar_available: true,
};

const fsTest: Test = {
  id: 3,
  name: 'Food Sensitivity Test',
  display_name: 'Food Sensitivity',
  type: 'food-sensitivity',
  marker_types: ['food-sensitivity'],
  content_token: 'b98f873d-495b-45f5-8008-e99b5591838e',
  content: testContent,
  genomics_eligible: true,
  is_food_sensitivity: true,
  theme_icon: 'other',
  recorded_webinar: false,
};

const fsTestWithoutSections: Test = {
  id: 3,
  name: 'Food Sensitivity Test',
  display_name: 'Food Sensitivity',
  type: 'food-sensitivity',
  marker_types: ['food-sensitivity'],
  content_token: 'b98f873d-495b-45f5-8008-e99b5591838e',
  content: { HIGH_REACTIVITY_OVERVIEW: 'High reactivity foods' },
  genomics_eligible: true,
  is_food_sensitivity: true,
  theme_icon: 'other',
  recorded_webinar: false,
};

const fsKitResultWithoutSections = {
  ...kitResult,
  test: fsTestWithoutSections,
};

export {
  markerResults,
  markers,
  content,
  test,
  lab,
  kitResult,
  fsTest,
  fsKitResultWithoutSections,
};
