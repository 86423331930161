import {
  isEnterpriseCustomer,
  isHumanaCustomer,
  isNonHumanaEnterpriseCustomer,
} from 'common/utils/helpers';
import * as React from 'react';

import ShareSubHeaderButton from '../../containers/ShareSubHeaderButton';
import FollowUpSubHeaderButton from './FollowUpSubHeaderButton';
import ReferAFriendButton from './ReferAFriendButton';
import SaveSubHeaderButton from './SaveSubHeaderButton';
import * as S from './SubHeader.styles';

export type SubHeaderProps = {
  kitResultNumber: string;
  kitResultHasAnyDnrMarkers?: boolean;
  testId?: number;
  testName: string;
  paid: boolean;
  shouldShowTelehealthConsult: boolean;
};

function SubHeader(props: SubHeaderProps) {
  const {
    kitResultNumber,
    kitResultHasAnyDnrMarkers,
    testId,

    testName,
    paid,
    shouldShowTelehealthConsult,
  } = props;

  const healthPlan = isNonHumanaEnterpriseCustomer();
  const isHumana = isHumanaCustomer();

  const route = isEnterpriseCustomer() ? '/dashboard' : '/results';
  const copy = isEnterpriseCustomer() ? 'Dashboard' : 'Test results';

  return (
    <S.SubHeader>
      <S.SubHeaderContents>
        <S.Left>
          <S.BackToDashboard aria-label={copy} to={route}>
            <span>{copy}</span>
          </S.BackToDashboard>
        </S.Left>
        <S.Right>
          {!isHumana && !healthPlan && (
            <>
              <ReferAFriendButton testName={testName} />
            </>
          )}
          <S.SaveSubHeaderButtonContainer>
            <SaveSubHeaderButton
              kitResultId={kitResultNumber}
              kitResultHasAnyDnrMarkers={kitResultHasAnyDnrMarkers}
              testId={testId}
              testName={testName}
              paid={paid}
            />
          </S.SaveSubHeaderButtonContainer>
          <ShareSubHeaderButton kitResultId={kitResultNumber} />
          {shouldShowTelehealthConsult && (
            <FollowUpSubHeaderButton testName={testName} testId={testId} />
          )}
        </S.Right>
      </S.SubHeaderContents>
    </S.SubHeader>
  );
}

export default SubHeader;
