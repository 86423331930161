import { colors } from '@everlywell/leaves';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${colors.white};
  background-clip: padding-box;
  position: absolute;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.03);
  border: 1px solid ${colors.gray2};
  border-radius: 3px;
  width: auto;
  cursor: default;

  &:before,
  &:after {
    border-style: solid;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    right: 10px;
  }

  &:before {
    border-width: 0 12px 11px;
    border-color: ${colors.gray2} transparent;
    top: -11px;
  }

  &:after {
    border-width: 0 12px 12px;
    border-color: ${colors.white} transparent;
    top: -10px;
  }
`;

const InnerContent = styled.div`
  position: relative;
`;

export { Wrapper, InnerContent };
