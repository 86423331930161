import { useTelehealthAppointments } from 'common/apis/telehealthApis';
import useUser from 'common/hooks/useUser/useUser';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { isUserWeightCarePlusActiveMember } from 'common/utils/user';
import { openExtoleWeightCarePlus } from 'common/utils/vendor/extole';
import React, { useEffect, useState } from 'react';

import OpenLoopSupport from '../../components/OpenLoopSupport';
import TelehealthAppointments from './components/TelehealthAppointments';
import FollowUpSection from './FollowUpSection';
import MedicationsSection from './MedicationsSection';
import * as S from './TelehealthDashboard.styles';

export type TelehealthAppointmentsProps = {};

export enum AppointmentType {
  Upcoming,
  Completed,
}
export type TelehealthDashboardProps = {};

/**
 * Telehealth dashboard that shows users information and actions
 * related to the telehealth experience
 */
function TelehealthDashboard(props: TelehealthDashboardProps) {
  const [currentTab, setCurrentTab] = useState<AppointmentType>(
    AppointmentType.Upcoming,
  );
  const [isTelehealthDashboardViewed, setIsTelehealthDashboardViewed] =
    useState<boolean>(false);

  const handleSelectTab = (index: AppointmentType) => {
    setCurrentTab(index);
  };

  const { user } = useUser();

  const { data: completedAppointmentsResponse } = useTelehealthAppointments({
    period: 'past',
  });

  const allAppointments =
    completedAppointmentsResponse?.data?.appointments ?? [];

  // An appointment is considered completed if it is returned as occurred or null
  const completedAppointments = allAppointments.filter(
    (appointment) =>
      appointment.status === 'occurred' || appointment.status === null,
  );

  useEffect(() => {
    if (user && !isTelehealthDashboardViewed) {
      analytics.track({
        event: ANALYTICS.EVENTS.VIEWED_PAGE,
        data: {
          page: ANALYTICS.PAGES.ACCOUNT_HUB.VIRTUAL_CARE,
          category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
        },
      });
      setIsTelehealthDashboardViewed(true);
    }
  }, [user, isTelehealthDashboardViewed]);

  useEffect(() => {
    if (!user) return;

    const weightManagementPlan = user?.user_plans?.enabled.find(
      (plan) => plan.program_plan_type === 'weight_management',
    );

    if (
      user &&
      weightManagementPlan?.marketing &&
      weightManagementPlan?.marketing?.tag_name &&
      weightManagementPlan?.marketing?.partner_user_id &&
      !localStorage.getItem('wcPlusRAFDate')
    ) {
      openExtoleWeightCarePlus({
        marketingProgram: weightManagementPlan?.marketing,
        email: user?.email,
        firstName: user?.first_name,
        lastName: user?.last_name,
      });
      // This is needed to control the last time the modal was open and to prevent it from opening again
      localStorage.setItem('wcPlusRAFDate', new Date().toDateString());

      analytics.track({
        event: ANALYTICS.EVENTS.OPENED_SHARE_EXPERIENCE,
        data: {
          program: weightManagementPlan.program_plan_name,
        },
      });
    }
  }, [user]);

  const isActiveWCPlusMember = user && isUserWeightCarePlusActiveMember(user);

  return (
    <>
      <S.PageContent>
        <S.Section>
          <TelehealthAppointments
            currentTab={currentTab}
            handleSelectTab={handleSelectTab}
          />
        </S.Section>

        <S.Section>
          <MedicationsSection />
        </S.Section>

        {completedAppointments.length >= 1 ? (
          <S.Section>
            <FollowUpSection
              isWcPlusMember={!!isActiveWCPlusMember}
              isVCVTab={true}
            />
          </S.Section>
        ) : null}

        <S.Section>
          <OpenLoopSupport />
        </S.Section>

        <S.HiddenExtoleSpanForModalDisplay id="extole_zone_virtual_care_share_experience"></S.HiddenExtoleSpanForModalDisplay>
      </S.PageContent>
    </>
  );
}

export default TelehealthDashboard;
