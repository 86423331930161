import { Checkbox, CheckboxProps, VisuallyHidden } from '@everlywell/leaves';
import { TelehealthCustomModule } from 'common/apis/telehealthApis';
import { API_ROOT } from 'common/utils/constants';
import { logError } from 'common/utils/helpers';
import { ErrorMessage } from 'components/telehealth/FormBuilder/utils/styles';
import React, { forwardRef, useEffect, useRef } from 'react';

export type ConsentFieldProps = Omit<CheckboxProps, 'label' | 'name'> & {
  modules: TelehealthCustomModule[];
  error?: string;
};

/**
 * Consent Field Component
 * Shows a checkbox with consent agreement text
 */

const ConsentField = forwardRef<HTMLInputElement, ConsentFieldProps>(
  ({ modules, error, ...props }, ref) => {
    const notificationSent = useRef(false);
    const documents = modules.filter(
      ({ mod_type, options_array, label }) =>
        mod_type === 'document' &&
        !label?.startsWith('[upload]') &&
        options_array.length > 0,
    );
    const agreeToAboveModule = modules.find(
      ({ mod_type }) => mod_type === 'agree_to_above',
    );

    useEffect(() => {
      if (
        !notificationSent.current &&
        agreeToAboveModule &&
        documents.length === 0
      ) {
        notificationSent.current = true;
        logError('No consent documents were found', {
          errorInfo:
            'agree to above module found without any documents to attach',
          component: 'Demographics::ConsentField',
          method: 'render',
        });
      }
    }, [documents, agreeToAboveModule]);

    if (!agreeToAboveModule) {
      return null;
    }

    const label =
      documents.length > 0 ? (
        <>
          I hereby agree to
          {documents.map(({ label, options_array }, index, array) => {
            const id = options_array[0] ?? '#';
            const hasMultipleDocuments = array.length > 1;
            const isTheLastItem = index === array.length - 1;

            return (
              <React.Fragment key={`${label}-${index}-${id}`}>
                {hasMultipleDocuments && isTheLastItem ? ' and ' : ' '}
                the{' '}
                <a
                  href={`${API_ROOT}/v2/telehealth/documents/${id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {label ?? 'consent forms'}
                </a>
              </React.Fragment>
            );
          })}
        </>
      ) : (
        agreeToAboveModule.label || 'I hereby agree to the consent forms'
      );

    return (
      <>
        <Checkbox
          ref={ref}
          {...props}
          label={
            <>
              {label}

              {agreeToAboveModule?.required && (
                <>
                  <span aria-hidden="true">*</span>
                  <VisuallyHidden>{`(this field is required)`}</VisuallyHidden>
                </>
              )}
            </>
          }
          name={`${agreeToAboveModule.id}`}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </>
    );
  },
);

export default ConsentField;
