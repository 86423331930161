export default {
  title: 'Payment',
  paymentInfo: {
    creditCardText: 'Card ending in',
    creditCardLastFourDigits: '1234',
  },
  modalTitle: 'Change Payment',
  editButtonText: 'Edit',
  cancelButtonText: 'Cancel',
  saveButtonText: 'Save',
  notifications: {
    fetchFailed: {
      title: 'An error occurred',
      message: 'Failed to load payment information',
    },
    updateFailed: {
      title: 'An error occurred',
      message: 'Failed to update payment information',
    },
    updateSuccess: {
      title: 'Update',
      message: 'Payment information updated',
    },
    defaultError: {
      title: 'An error occurred',
      message: 'Please try again.',
    },
  },
};
