import analytics from 'common/utils/analytics';
import { MY_APP_ROOT } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { ImmutableReduxState } from 'common/utils/types';
import ShareSubHeaderButton from 'components/SubHeader/ShareSubHeaderButton';
import React from 'react';
import { connect } from 'react-redux';
import { setKitResultSharingOff, setKitResultSharingOn } from 'store/actions';
import { makeSelectShareKitResult } from 'store/selectors';

type StateProps = {
  publishHash?: string;
  testName?: string;
};

export type SharingAction = ({ kitResultId }: { kitResultId: string }) => void;

type DispatchProps = {
  setKitResultSharingOn: SharingAction;
  setKitResultSharingOff: SharingAction;
};

type OwnProps = {
  kitResultId: string;
};

export type Props = StateProps & DispatchProps & OwnProps;

export function ShareSubHeaderButtonContainer(props: Props) {
  const { publishHash, testName } = props;

  function handleToggleShare() {
    const { kitResultId } = props;
    if (publishHash) {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: 'Turn Off Sharing',
          category: ANALYTICS.CATEGORIES.SHARE_RESULTS,
        },
      });
      props.setKitResultSharingOff({ kitResultId });
    } else {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: 'Start Sharing',
          category: ANALYTICS.CATEGORIES.SHARE_RESULTS,
        },
      });
      props.setKitResultSharingOn({ kitResultId });
    }
  }

  return (
    <ShareSubHeaderButton
      testName={testName}
      shareLink={publishHash && `${MY_APP_ROOT}/share/${publishHash}`}
      onToggleShare={handleToggleShare}
    />
  );
}

export default connect(
  (state: ImmutableReduxState, ownProps: OwnProps) => {
    const { kitResultId } = ownProps;
    return makeSelectShareKitResult(kitResultId)(state);
  },
  {
    setKitResultSharingOn,
    setKitResultSharingOff,
  },
)(ShareSubHeaderButtonContainer);
