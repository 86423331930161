import { Button } from '@everlywell/leaves';
import {
  TelehealthFormAnswer,
  savePatientConsent,
} from 'common/apis/telehealthApis';
import { BaseRequestError, handleError } from 'common/hooks/useApi/request';
import { useCommonT2TAnalytics } from 'common/hooks/useCommonT2TAnalytics';
import useUser from 'common/hooks/useUser';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { logError } from 'common/utils/helpers';
import Layout from 'components/Layout';
import FormBuilder from 'components/telehealth/FormBuilder';
import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import useEffectOnce from 'react-use/lib/useEffectOnce';

import { useNativeIntakeContext } from '../../context/NativeIntakeContext';
import { customModulesBuilder } from './config';
import SkeletonEnterpriseConsentsForm from './SkeletonEnterpriseConsentsForm';
import { parseFormData, setDefaultValues } from './utils';

/**
 * [Test to Threat Consent Page].
 */
function EnterpriseConsentsFormPage() {
  const navigate = useNavigate();
  const { user } = useUser();
  const { goToNextStep: contextGoToNextStep } = useNativeIntakeContext();
  const { commonT2TAnalyticsData } = useCommonT2TAnalytics();

  const customModules = customModulesBuilder(user!);
  const {
    mutate: setConsentUser,
    isLoading: isSubmitting,
    status,
    error,
  } = useMutation(savePatientConsent, {
    onSuccess: async (_data) => {
      await analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: ANALYTICS.LABELS.EHS_T2T_FORMS.CONSENT_CONTINUE,
          ...commonT2TAnalyticsData,
        },
      });
      contextGoToNextStep();
    },
  });

  useEffectOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        page: ANALYTICS.PAGES.EHS_T2T.CONSENTS_PAGE,
        ...commonT2TAnalyticsData,
      },
    });
  });

  useEffect(() => {
    if (status === 'error') {
      logError((error as BaseRequestError).message, error as BaseRequestError);
    }
  }, [error, status]);

  const handleBackToHome = () => {
    navigate('/dashboard');
  };

  const onSubmit = (answers: TelehealthFormAnswer[]) => {
    const data = parseFormData(answers);
    setConsentUser(data);
  };

  if (!user) {
    return (
      <Layout>
        <SkeletonEnterpriseConsentsForm />
      </Layout>
    );
  }

  const defaultValues = setDefaultValues(customModules, user);
  return (
    <Layout>
      <FormBuilder
        id="consents-form"
        name="Consents Form"
        custom_modules={customModules}
        defaultValues={defaultValues}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
        error={handleError(error as BaseRequestError)}
      >
        <Button
          style={{ width: '100%' }}
          appearance="text"
          onClick={handleBackToHome}
        >
          No thanks, take me back to Home
        </Button>
      </FormBuilder>
    </Layout>
  );
}

export default EnterpriseConsentsFormPage;
