import { size, colors, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  ${typography.captionText};
  display: grid;
  grid-template-columns: ${size.lg}px 1fr;
  gap: ${size.xs1}px;
  padding: ${size.sm}px;
  background: ${colors.green1};
  border-radius: ${size.xs1}px;
  color: ${colors.gray4};
`;
