import { Address } from 'common/apis/settingsApis';

const baseAddress: Address = {
  id: 1,
  first_name: 'John',
  last_name: 'Doe',
  street1: '123 Main St',
  street2: null,
  city: 'San Francisco',
  state: 'CA',
  country: 'US',
  zipcode: '94105',
  phone: null,
};

export const buildMemberAddress = (
  overrides: Partial<Address> = {},
): Address => ({
  ...baseAddress,
  ...overrides,
});
