import { sanitize } from 'common/utils/domHelpers';
import parse from 'html-react-parser';
import React from 'react';
import { Link } from 'react-router-dom';

import * as S from '../styles';

type ExclusiveLinkProps =
  | { href: string; to?: never }
  | { href?: never; to: string };

export type AppointmentCardProps = {
  boxImage: {
    src: string;
    alt: string;
  };
  heading: string | React.ReactNode;
  subHeading: string | React.ReactNode;
  callToAction?: {
    appearance?: 'text' | 'primary' | 'secondary' | 'hero' | 'tertiary';
    content: string;
    additionalContent?: string | React.ReactNode;
    otherProps?: Record<string, string | number | boolean | null>;
  } & ExclusiveLinkProps;
  handleClick?: () => void;
};

const AppointmentCard = ({
  boxImage,
  heading,
  subHeading,
  callToAction,
  handleClick,
}: AppointmentCardProps) => {
  const renderContent = (content: string | React.ReactNode) => {
    if (React.isValidElement(content)) return content;

    if (typeof content === 'string') return parse(sanitize(content));

    return null;
  };

  return (
    <S.AppointmentCard>
      <S.AppointmentInfo>
        <S.AppointmentBoxImageContainer>
          <S.AppointmentBoxImage src={boxImage.src} alt={boxImage.alt} />
        </S.AppointmentBoxImageContainer>
        <S.AppointmentDetails>
          <S.AppointmentDetailsHeading>
            {renderContent(heading)}
          </S.AppointmentDetailsHeading>
          <S.AppointmentDetailsSubHeading>
            {renderContent(subHeading)}
          </S.AppointmentDetailsSubHeading>
        </S.AppointmentDetails>
      </S.AppointmentInfo>
      {callToAction && (
        <S.AppointmentCTAandInfo>
          <S.AppointmentCTAButton
            {...callToAction.otherProps}
            {...(callToAction.to && { to: callToAction.to })}
            {...(callToAction.href && { href: callToAction.href })}
            component={Link}
            appearance={callToAction.appearance ?? 'secondary'}
            onClick={handleClick}
          >
            {callToAction.content}
          </S.AppointmentCTAButton>
          {callToAction?.additionalContent && (
            <S.AppointmentCTAAdditionalInfo>
              {renderContent(callToAction.additionalContent)}
            </S.AppointmentCTAAdditionalInfo>
          )}
        </S.AppointmentCTAandInfo>
      )}
    </S.AppointmentCard>
  );
};

export default AppointmentCard;
