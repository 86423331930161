import {
  getCarePlan,
  TelehealthCarePlanOrderTypes,
  TelehealthCarePlanOrders,
} from 'common/apis/telehealthApis';
import { useQuery } from 'react-query';

/**
 * Custom hook to fetch and process care plan data for a given appointment.
 *
 * @param {string} appointmentId - The ID of the appointment to fetch the care plan for.
 * @returns {Object} An object containing the care plan details:
 * - `assessment` {string} - The assessment information from the care plan.
 * - `diagnosis` {Array} - The list of diagnoses from the care plan.
 * - `orders` {Object} - The filtered orders from the care plan, excluding empty orders.
 * - `providerName` {string} - The name of the physician associated with the care plan.
 * - `isLoading` {boolean} - Indicates if the data is currently being loaded or fetched.
 * - `isError` {boolean} - Indicates if there was an error fetching the data.
 * - `error` {Object} - The error object if an error occurred.
 *
 * @description
 * This hook uses the `useQuery` hook from `react-query` to fetch the care plan data
 * for a given appointment ID. It processes the response to extract relevant information
 * such as assessment, diagnoses, orders, and physician name. The orders are filtered
 * to exclude any empty orders.
 *
 * The `filteredOrders` object is created by iterating over the `orders` object and
 * including only those orders that have a non-empty array. This ensures that the
 * returned orders object contains only meaningful data.
 */
const useCarePlan = (appointmentId: string) => {
  const {
    data: response,
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery(
    ['telehealth-care-plan', appointmentId],
    () => getCarePlan(appointmentId),
    {
      refetchOnWindowFocus: false,
    },
  );

  const carePlan = response?.data?.care_plan ?? null;

  const assessment = carePlan?.assessment ?? '';
  const diagnosis = carePlan?.diagnoses ?? [];
  const orders = carePlan?.orders ?? null;
  const providerName = carePlan?.physician?.name ?? '';

  const filteredOrders =
    orders &&
    Object.keys(orders).reduce((acc, key) => {
      const order = orders[key as TelehealthCarePlanOrderTypes];

      if (order.length > 0) {
        acc[key as TelehealthCarePlanOrderTypes] = order;
      }

      return acc;
    }, {} as TelehealthCarePlanOrders);

  return {
    assessment,
    diagnosis,
    orders: filteredOrders,
    providerName,
    isLoading: isLoading || isFetching,
    isError,
    error,
  };
};

export default useCarePlan;
