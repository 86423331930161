import analytics from 'common/utils/analytics';
import { DATE_FORMAT_SHORT } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import {
  TAKE_ACTION,
  WHATS_NEXT_SECTION,
} from 'common/utils/constants/dataTest';
import { generatePWNConsultLink } from 'common/utils/helpers';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import Tooltip, { TooltipWrapper } from 'components/Tooltip';
import { format, parseISO } from 'date-fns';
import React, { RefObject } from 'react';

import costIcon from '../images/icons-cost.svg';
import expireIcon from '../images/icons-expire.svg';
import infoIcon from '../images/icons-info.svg';
import timeIcon from '../images/icons-time.svg';
import whoIcon from '../images/icons-who.svg';
import * as S from './styles';

type Item = {
  alt: string;
  iconSrc: string;
  text: string;
};

export type Props = {
  header: string;
  description: string;
  date: string | undefined;
  items?: Item[];
  iconSrc: string;
  alt: string;
  tooltip: {
    header: string;
    description: string;
  };
  testName: string;
  pwnOrderNumber: number | undefined;
  pwnConfirmationNumber: number | undefined;
};

const defaultItems = [
  {
    iconSrc: whoIcon,
    alt: 'Who',
    text: 'Telehealth Consultation',
  },
  {
    iconSrc: timeIcon,
    alt: 'Time',
    text: 'Within 4 business hours',
  },
  {
    iconSrc: costIcon,
    alt: 'Cost',
    text: 'Free',
  },
];

const ScheduleConsult = (props: Props) => {
  const {
    iconSrc,
    alt,
    header,
    description,
    items = defaultItems,
    date,
    testName,
    tooltip,
    pwnOrderNumber,
    pwnConfirmationNumber,
  } = props;

  const containerEl = useScrolledIntoViewOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: 'Schedule PWN Consult',
        category: ANALYTICS.CATEGORIES.WHATS_NEXT,
        testName,
      },
    });
  });

  return (
    <S.Container data-test="schedule-consult" ref={containerEl}>
      <S.IconWrapper>
        <S.Icon src={iconSrc} aria-hidden="true" alt={alt} />
      </S.IconWrapper>
      <S.DescriptionWrapper>
        <S.Header>{header}</S.Header>
        <S.Description>{description}</S.Description>
      </S.DescriptionWrapper>
      <S.ActionWrapper>
        <S.List>
          {items.map((item, index) => (
            <S.ListItem key={item.alt + index.toString()}>
              <S.ListIcon src={item.iconSrc} alt={item.alt} />
              {item.text}
            </S.ListItem>
          ))}
          {date ? (
            <TooltipWrapper tooltipId="schedule-tooltip">
              {({ triggerEl, iconEl, isShown, tooltipId }) => (
                // @ts-ignore-next-line
                <S.ListItem ref={triggerEl}>
                  <S.ListIcon src={expireIcon} alt="Expiration Date" />
                  <p>
                    <span data-isolate>Available until</span>
                    <S.DateAndIcon>
                      {' '}
                      {format(parseISO(date), DATE_FORMAT_SHORT)}
                      <S.TooltipIcon
                        src={infoIcon}
                        alt="info"
                        aria-describedby={tooltipId}
                        aria-expanded={isShown ? 'true' : 'false'}
                        ref={iconEl as RefObject<HTMLImageElement>}
                        data-test={TAKE_ACTION.SCHEDULE_TOOLTIP}
                      />
                    </S.DateAndIcon>
                  </p>
                  <Tooltip
                    isShown={isShown}
                    triggerEl={triggerEl}
                    iconEl={iconEl}
                    id={tooltipId}
                    data-test={TAKE_ACTION.SCHEDULE_TOOLTIP_CONTENT}
                    tooltipWidth={320}
                  >
                    <S.TooltipContent>
                      <S.ModalTitle>{tooltip.header}</S.ModalTitle>
                      <S.ModalDescription>
                        {tooltip.description}
                      </S.ModalDescription>
                    </S.TooltipContent>
                  </Tooltip>
                </S.ListItem>
              )}
            </TooltipWrapper>
          ) : null}
        </S.List>
        {pwnOrderNumber && pwnConfirmationNumber && (
          <S.ScheduleButton
            appearance={'secondary'}
            data-test={WHATS_NEXT_SECTION.SCHEDULE_NOW_BUTTON}
            target="_blank"
            trackingData={{
              label: 'Schedule PWN Consult',
              category: ANALYTICS.CATEGORIES.WHATS_NEXT,
              testName,
            }}
            href={generatePWNConsultLink(
              pwnOrderNumber,
              pwnConfirmationNumber,
              testName,
            )}
          >
            Request Consult
          </S.ScheduleButton>
        )}
      </S.ActionWrapper>
    </S.Container>
  );
};

export default ScheduleConsult;
