import { useRadio, UseRadioProps } from '@chakra-ui/react';
import { Box, theme } from '@everlywell/ui-kit';
import React from 'react';

export type RadioButtonProps = {
  children: React.ReactNode;
} & UseRadioProps;

const RadioButton = React.forwardRef(
  (props: RadioButtonProps, ref: React.Ref<HTMLInputElement>) => {
    const { children, ...rest } = props;
    const { getInputProps, getRadioProps } = useRadio(rest);

    const input = getInputProps({ ref });
    const radio = getRadioProps();

    return (
      <Box as="label">
        <input {...input} ref={ref} />
        <Box
          {...radio}
          role="radio"
          aria-checked={radio['aria-checked']}
          padding={3}
          color="tints.black"
          fontFamily="body"
          fontWeight="medium"
          cursor="pointer"
          borderWidth="3px"
          borderRadius="lg"
          borderColor="transparent"
          boxShadow={`0 0 0 1px ${theme.colors.tints.darkCream}`}
          backgroundColor="white"
          _checked={{
            borderColor: 'viridian.base',
            borderWidth: '3px',
          }}
          _focus={{
            boxShadow: 'outline',
          }}
          _focusVisible={{
            outline: 0,
          }}
        >
          {children}
        </Box>
      </Box>
    );
  },
);

export default RadioButton;
