import { POPOVER as dataTest } from 'common/utils/constants/dataTest';
import useClickOff from 'components/Modal/useClickOff';
import useDisableBodyScroll from 'components/Modal/useDisableBodyScroll';
import useHasScrollableContent from 'components/Modal/useHasScrollableContent';
import React from 'react';

import * as S from './Popover.styles';

export type PopoverProps = {
  children: JSX.Element | JSX.Element[];
  onClose: () => void;
  footer?: ({
    hasScrollableContent,
  }: {
    hasScrollableContent: boolean;
  }) => boolean | JSX.Element;
};

function Popover(props: PopoverProps) {
  const { children, onClose, footer } = props;
  const wrapperEl = useClickOff(onClose);
  const innerContentWrapperEl = useDisableBodyScroll(wrapperEl);
  const hasScrollableContent = useHasScrollableContent(innerContentWrapperEl);

  return (
    <S.Wrapper ref={wrapperEl} data-test={dataTest.CONTENT}>
      <S.InnerContent>{children}</S.InnerContent>
      {footer && footer({ hasScrollableContent })}
    </S.Wrapper>
  );
}

export default Popover;
