import useApi from 'common/hooks/useApi';
import { useAnswers } from 'common/providers/AnswersProvider';
import { useNavigationQuestion } from 'common/providers/NavigationQuestionProvider';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { logError } from 'common/utils/helpers';
import {
  HealthProfileQuestion,
  HealthProfileResponse,
} from 'common/utils/types';
import {
  generateNextUrl,
  generatePreviousUrl,
  getNextQQIdByResponse,
  useValidationParameter,
} from 'components/HealthProfile/helpers';
import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';

import * as S from './styles';

export interface QuestionFooterProps {
  answer?: string | number | any[];
  error?: string;
  questionData: HealthProfileQuestion;
  questionnaireId: number;
  setResponse: () => Promise<HealthProfileResponse>;
}

const QuestionFooter = ({
  answer,
  error,
  questionData,
  questionnaireId,
  setResponse,
}: QuestionFooterProps) => {
  const {
    questionnaire_question_id: questionnaireQuestionId,
    skippable,
    title,
  } = questionData;
  const {
    keyValueQuestionsSet,
    prevQuestionId,
    setCurrentQuestion,
    setPrevQuestion,
    setTotalQuestions,
    totalQuestions,
  } = useNavigationQuestion();
  const { submitHealthProfileAnswer } = useApi();
  const { allAnswers, setAllAnswers } = useAnswers();

  const { ref, inView } = useInView();

  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const unansweredUrlChunk = useValidationParameter('unanswered');
  const validator = unansweredUrlChunk ? 'unanswered' : 'test';
  const urlChunk = useValidationParameter(validator);
  const navigate = useNavigate();

  const showBackBtn = prevQuestionId?.length > 0;
  const [showBackButton, setShowBackButton] = useState<boolean>(showBackBtn);

  useEffect(() => {
    if (!isLoading) {
      setShowBackButton(() => showBackBtn);
    }
  }, [showBackBtn, setShowBackButton, isLoading]);

  const handleSubmitQuestion = async (answers: HealthProfileResponse) => {
    const {
      content,
      questionnaire_question_id: currentQuestionnaireQuestionId,
      title,
    } = questionData;

    setPrevQuestion((previousQuestionIdsStack) =>
      previousQuestionIdsStack.concat(currentQuestionnaireQuestionId),
    );
    const data: any = {
      title,
      content,
      responses: answers.options.map(({ content }: any) => content),
      value: answers.value,
    };

    try {
      const response = await submitHealthProfileAnswer(
        answers,
        questionData.questionnaire_question_id,
      );
      const { questionnaire_question_id: questionnaireQuestionId } = response;
      await analytics.track({ event: ANALYTICS.EVENTS.RESPONSE, data });

      const updatedAnswers = { ...allAnswers };
      updatedAnswers[questionnaireQuestionId] = response;
      setAllAnswers(updatedAnswers);

      const nextQqId = getNextQQIdByResponse(
        questionData,
        response,
        keyValueQuestionsSet,
      );
      let nextQuestion = null;
      if (nextQqId) {
        nextQuestion = keyValueQuestionsSet.current.all[nextQqId];
      }

      let newTotalQ = totalQuestions;
      if (nextQuestion && !nextQuestion?.root) {
        newTotalQ += 1;
        setTotalQuestions((total) => total + 1);
      }

      setCurrentQuestion(() => {
        setIsLoading(() => false);
        setDisabled(() => false);
        return nextQqId;
      });

      const prevQL = prevQuestionId.length;
      const questionsLength = prevQL + 1;
      const isCompleted = questionsLength >= newTotalQ;
      const nextUrl = generateNextUrl({
        currentQuestion: questionData,
        isCompleted,
        nextQqId,
        questionnaireId,
        questionsLength,
        urlChunk,
      });

      navigate(nextUrl, { replace: true });
    } catch (err) {
      logError((err as Error).message, {
        errorInfo: 'error submitting health profile response',
        component: 'QuestionFooter',
        method: 'handleSubmitQuestion',
      });
    }
  };

  const handleBack = () => {
    setPrevQuestion((previousQuestionIdsStack) => {
      const questionsLength = previousQuestionIdsStack.length + 1;
      const previousId = previousQuestionIdsStack.pop();
      const emptyNumberArray: number[] = [];
      const newStack = emptyNumberArray.concat(previousQuestionIdsStack);
      if (previousId) {
        setCurrentQuestion(() => previousId);
        if (!questionData.root) {
          setTotalQuestions((total) => total - 1);
        }
      }
      const prevUrl = generatePreviousUrl({
        questionsLength,
        questionnaireId,
        questionnaireQuestionId: previousId,
        urlChunk,
      });

      navigate(prevUrl);
      return newStack;
    });
  };

  const BackButton = () => (
    <S.BackButton
      isDisabled={disabled}
      onClick={handleBack}
      appearance="secondary"
    >
      Back
    </S.BackButton>
  );

  const SkipQuestionButton = () => (
    <S.SkipQuestionButton
      disabled={disabled}
      onClick={() => {
        setDisabled(true);

        const skippedAnswers = {
          questionnaire_question_id: questionnaireQuestionId,
          skipped: true,
          options: [],
          value: null,
          id: allAnswers[questionnaireQuestionId]?.id,
        };

        analytics.track({
          event: ANALYTICS.EVENTS.QUESTION_SKIPPED,
          data: { questionId: questionnaireQuestionId, title },
        });
        handleSubmitQuestion(skippedAnswers);
      }}
    >
      Skip Question
    </S.SkipQuestionButton>
  );

  const SubmitButton = () => {
    const hasNoAnswer = !answer;
    const hasWrongAnswer = !!error;
    return (
      <S.ContinueButton
        data-testid="healthProfile-submitButton"
        isDisabled={hasWrongAnswer || hasNoAnswer || disabled}
        isLoading={isLoading}
        appearance="primary"
        showBackButton={showBackButton}
        onClick={async () => {
          setIsLoading(() => true);
          setDisabled(() => true);
          handleSubmitQuestion(await setResponse());
        }}
      >
        Continue
      </S.ContinueButton>
    );
  };

  return (
    <>
      <S.StickyQuestionFooter inView={inView}>
        {showBackButton ? <BackButton /> : null}
        <SubmitButton />
        {skippable ? <SkipQuestionButton /> : null}
      </S.StickyQuestionFooter>
      <div ref={ref} />
    </>
  );
};

export default QuestionFooter;
