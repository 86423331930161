import { mediaQueries } from '@everlywell/leaves';
import styled, { css } from 'styled-components';

// grid-template-columns: 1fr repeat(11, 16px 1fr) -> this will create 12 equal columns of the viewport with 16px gaps between each column (so rendering 24 total columns)
const col_12_grid_styles = css`
  display: grid;
  grid-template-columns: 1fr repeat(11, 16px 1fr);
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;

  ${mediaQueries.forDesktopUp} {
    max-width: 1392px;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
  }
`;

const span_12_cols = css`
  grid-column: 1 / 24;
`;

const span_10_cols = css`
  grid-column: 3 / 22;
`;

const span_8_cols = css`
  grid-column: 5 / 20;
`;

// @ts-ignore
const span_10_cols_layout = css`
  ${span_12_cols};

  ${mediaQueries.forTabletVerticalUp} {
    ${span_10_cols};
  }
`;

const span_8_cols_layout = css`
  ${span_12_cols};

  ${mediaQueries.forTabletVerticalUp} {
    ${span_10_cols};
  }

  ${mediaQueries.forTabletHorizontalUp} {
    ${span_8_cols};
  }
`;

// margins here are for spacing between different sections
const col_12_span_n_styles = css`
  width: 100%;
  padding: 0;
  margin: 0;
`;

const Col_12_Grid = styled.div`
  ${col_12_grid_styles};
`;

const Col_10 = styled.div`
  ${span_10_cols_layout};
`;

const Col_8 = styled.div`
  ${span_8_cols_layout};
`;

// Col_12_Span_N: These are for containers that span the full width of the viewport and have content that is displayed in our normal grid columns
// When writing such components, we would need to have a main wrapper that will inherit the 'Col_12_Span_N' style.
// Another div inside of that container for creating the 12 columns of the grid.
// And a 3rd div to span N columns. Inside the 3rd div is where the code of the component should lie.
// You will not need to inherit any other styled components for the 2nd and third div wrappers as that is handled in the parent styles itself.
// Example:
// <Col_12_Span_N>
//   <div>
//     <YourStuffContainer>
//       <YourStuff />
//     </YourStuffContainer>
//   </div>
// </Col_12_Span_N>

// full width containers with content spanning 10 columns
const Col_12_Span_10 = styled.div`
  ${col_12_span_n_styles};

  > div {
    ${col_12_grid_styles};

    > div {
      ${span_10_cols_layout};
    }
  }
`;

// full width containers with content spanning 8 columns
const Col_12_Span_8 = styled.div`
  ${col_12_span_n_styles};

  /* stylelint-disable */
  > div {
    ${col_12_grid_styles};

    > div {
      ${span_8_cols_layout};
    }
  }
`;

// full width containers with content spanning different set of columns.
// follows the same concept as the Col_12_Span_10 and Col_12_Span_8 styles.
// difference in this one is that the 3rd nested div does not have Span_10 or Span_8 styles inherited by default
// ...so that different sections can have a combination of these
// Example:
// <Col_12_Span_N>
//   <div>
//     <YourStuffContainer1> -> in your styles this div would inherit Col_10 or Col_8 based on your needs
//       <YourStuff />
//     </YourStuffContainer1>
//     <YourStuffContainer2> -> in your styles this div would inherit Col_10 or Col_8 based on your needs
//       <YourStuff />
//     </YourStuffContainer2>
//   </div>
// </Col_12_Span_N>
const Col_12_Span_N = styled.div`
  ${col_12_span_n_styles};

  > div {
    ${col_12_grid_styles};
  }
`;

export {
  Col_12_Grid,
  Col_10,
  Col_8,
  Col_12_Span_10,
  Col_12_Span_8,
  Col_12_Span_N,
};
