import { mediaQueries, colors, size, Button, H3 } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: 400px;
  align-items: center;
  justify-content: center;
  padding: ${size.md}px 0 0 ${size.md}px;

  ${mediaQueries.forTabletVerticalUp} {
    padding: ${size.lg}px 0 0 ${size.lg}px;
  }

  ${mediaQueries.forDesktopUp} {
    padding: ${size.xl1}px 0 0 ${size.xl1}px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  overflow: hidden;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-right: ${size.md}px;
`;

export const ContentContainer = styled.div`
  color: ${colors.gray4};
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 0 ${size.md}px ${size.lg}px 0;
`;

export const CTAContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 0 ${size.md}px ${size.md}px 0;
`;

export const ButtonLink = styled(Button)`
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    width: 406px;
  }
`;

export const Header = styled(H3)`
  color: ${colors.gray5};
`;
