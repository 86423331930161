import useAllergies from 'common/hooks/useAllergies';
import { MultiSelectAutoCompleteProps } from 'components/MultiSelectAutoComplete';
import React from 'react';

import { FormBuilderField } from '../../utils/types';
import { AutoCompleteField } from '../AutoCompleteField/AutoCompleteField';

type AllergiesAutoCompleteFieldProps = FormBuilderField &
  MultiSelectAutoCompleteProps;
export const AllergiesAutoCompleteField = (
  props: AllergiesAutoCompleteFieldProps,
) => {
  const { allergies: data, ...rest } = useAllergies();
  return (
    <AutoCompleteField
      data={data}
      {...rest}
      {...props}
      notFoundMessage={props.notFoundMessage || 'No allergies found'}
    />
  );
};
