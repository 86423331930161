import { ImmutableReduxState } from 'common/utils/types';
import CommonQuestionsContent from 'pages/KitResultDetailPage/CommonQuestionsSection/Content';
import { connect } from 'react-redux';
import { makeSelectContent } from 'store/selectors';

const CONTENT_KEYS = {
  helpscoutCategoryId: 'HELPSCOUT_CATEGORY_ID',
};

type OwnProps = {
  contentToken: string;
};

type Props = {
  helpscoutCategoryId: string;
};

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): Props {
  return makeSelectContent(ownProps.contentToken, CONTENT_KEYS)(state);
}

export default connect(mapStateToProps)(CommonQuestionsContent);
