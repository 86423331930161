/* eslint-disable @typescript-eslint/no-var-requires */
import { breakpoints } from '@everlywell/leaves';
import Modal from 'components/Modal';
import React, { useState, useEffect } from 'react';

import Popover from './Popover';

export type PopoverModalProps = {
  onClose: () => void;
  children: JSX.Element | JSX.Element[];
  footer?: ({
    hasScrollableContent,
  }: {
    hasScrollableContent: boolean;
  }) => boolean | JSX.Element;
};

function PopoverModal(props: PopoverModalProps) {
  const { onClose, children, footer } = props;

  const tabletMediaQuery = window.matchMedia(
    `(min-width: ${breakpoints.forTabletHorizontalUp}px)`,
  );
  const [isDesktop, setIsDesktop] = useState(tabletMediaQuery.matches);
  const checkIfIsDesktop = () => setIsDesktop(tabletMediaQuery.matches);
  const Component = isDesktop ? Popover : Modal;

  useEffect(() => {
    // TODO: fix react-hooks/exhaustive-deps error
    tabletMediaQuery.addListener(checkIfIsDesktop);

    return () => {
      tabletMediaQuery.removeListener(checkIfIsDesktop);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Component onClose={onClose} footer={footer}>
      {children}
    </Component>
  );
}

export default PopoverModal;
