import styled from 'styled-components';

const Steps = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  margin: 0 auto 30px;
`;

export { Steps };
