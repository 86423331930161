import { LocalizeDisabled, useHooks } from '@everlywell/leaves';
import { truncate } from 'common/utils/helpers';
import { Medication } from 'common/utils/types';
import CONTENT from 'pages/CarePlanPage/content';
import React, { SyntheticEvent, useState } from 'react';
import { easings, useSpring, animated as a } from 'react-spring';
import { useMeasure } from 'react-use';

import * as S from './MedicationDetails.styles';

type MedicationProps = {
  medication: Medication;
};

function MedicationDetailsItem(props: MedicationProps) {
  const { medication } = props;
  const [isOpened, setIsOpened] = useState(false);
  const [ref, { height: viewHeight }] = useMeasure<HTMLDivElement>();
  const { isMobile } = useHooks.useWindowSize();
  const nameLimit = isMobile ? 20 : 120;
  const medicationName = !isOpened
    ? truncate(medication.name, nameLimit)
    : medication.name;

  const handleClick = (e: SyntheticEvent<HTMLElement, Event>) => {
    setIsOpened(!isOpened);
  };

  const ToggleButton = ({ text }: { text: string }) => (
    <S.ToggleButton onClick={handleClick}>{text}</S.ToggleButton>
  );

  const toggleAnimation = useSpring({
    height: isOpened ? viewHeight : 0,
    opacity: isOpened ? 1 : 0,
  });

  const buttonAnimation = useSpring({
    config: {
      duration: 100,
      easing: easings.easeInOutBack,
    },
    delay: 0,
  });

  return (
    <div>
      <S.Heading>
        <S.Title>{medicationName}</S.Title>
        <a.div style={buttonAnimation}>
          <ToggleButton
            text={
              isOpened
                ? CONTENT.medication_list.content.hide_details
                : CONTENT.medication_list.content.view_details
            }
          />
        </a.div>
      </S.Heading>
      <a.div
        style={{
          ...toggleAnimation,
          overflow: 'hidden',
        }}
      >
        <S.Child ref={ref}>
          <S.Content>
            {medication.dosage && (
              <S.MedicationItem>
                <S.MedicationItemText>
                  {CONTENT.medication_list.content.dosage_text}
                </S.MedicationItemText>
                <S.MedicationItemValue>
                  <LocalizeDisabled>{medication.dosage}</LocalizeDisabled>
                </S.MedicationItemValue>
              </S.MedicationItem>
            )}
            <S.MedicationItem>
              <S.MedicationItemText>
                {CONTENT.medication_list.content.status_text}
              </S.MedicationItemText>
              <S.MedicationItemValue>
                {medication.active
                  ? CONTENT.medication_list.content.active_text
                  : CONTENT.medication_list.content.inactive_text}
              </S.MedicationItemValue>
            </S.MedicationItem>
            {medication.instructions && (
              <S.MedicationItem>
                <S.MedicationItemText>
                  {CONTENT.medication_list.content.instructions_text}
                </S.MedicationItemText>
                <S.MedicationItemValue>
                  {medication.instructions}
                </S.MedicationItemValue>
              </S.MedicationItem>
            )}
            {medication.comments && (
              <S.MedicationItem>
                <S.MedicationItemText>
                  {CONTENT.medication_list.content.comments_text}
                </S.MedicationItemText>
                <S.MedicationItemValue>
                  {medication.comments}
                </S.MedicationItemValue>
              </S.MedicationItem>
            )}
          </S.Content>
        </S.Child>
      </a.div>
    </div>
  );
}

export default MedicationDetailsItem;
