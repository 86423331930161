import { Col } from '@everlywell/leaves';
import React from 'react';

import * as S from './styles';

const TestCollection = () => (
  <S.Wrapper center="xs" middle="xs">
    <Col xs={11} md lg={8}>
      <S.Title>The last step is to collect your sample</S.Title>
      <S.Subtitle>
        We’ve provided instructions and tips for collecting your sample below.
      </S.Subtitle>
    </Col>
  </S.Wrapper>
);

export default TestCollection;
