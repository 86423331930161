import Grid from 'components/Grid';
import React, { FC } from 'react';

import content from './OrderConfirmationModal.content';
import * as S from './OrderConfirmationModal.styles';

export type OrderConfirmationModalProps = {
  openModal: boolean;
  setOpenModal: (_: boolean) => void;
  onCTAClick: () => void;
};

/**
 * Modal that explains to the user
 * for the AsyncRx program
 */
export const OrderConfirmationModal: FC<OrderConfirmationModalProps> = ({
  openModal,
  setOpenModal,
  onCTAClick,
}: OrderConfirmationModalProps) => (
  <S.Modal open={openModal} openHandler={setOpenModal}>
    <Grid.Container spacing={[]}>
      <Grid.Item width={[1]}>
        <S.ImageContainer>
          {/* Icon - Start */}
          <svg
            width="96"
            height="96"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_417_820)">
              <path
                opacity="0.96"
                d="M56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28Z"
                fill="#EEF5F6"
              />
              <line
                x1="13"
                y1="22.9808"
                x2="21.3462"
                y2="22.9808"
                stroke="#007377"
                stroke-width="1.5"
              />
              <line
                x1="16.5769"
                y1="26.5579"
                x2="21.3461"
                y2="26.5579"
                stroke="#007377"
                stroke-width="1.5"
              />
              <path
                d="M13 30.8849L20.1539 30.8849"
                stroke="#007377"
                stroke-width="1.5"
              />
              <path
                d="M35.5101 21.3457C34.3903 18.7316 32.0099 17.7688 29.3498 17.7691C26.6898 17.7694 23.3299 19.0069 22.3497 21.7585C21.3695 24.5102 20.9499 27.5367 20.5297 29.3253C20.1095 31.1139 19.4097 33.1773 17.3096 35.9289C15.2096 38.6804 18.2898 39.2307 18.2898 39.2307H40.1302C40.83 39.2307 42.65 38.1303 40.83 35.9289C39.01 33.7274 38.1584 31.6153 37.47 29.3253C36.6893 26.7286 36.63 23.9598 35.5101 21.3457Z"
                stroke="#007377"
                stroke-width="1.5"
              />
              <path
                d="M32.0767 39.2307C32.0767 41.2062 30.4753 42.8076 28.4998 42.8076C26.5243 42.8076 24.9229 41.2062 24.9229 39.2307"
                stroke="#007377"
                stroke-width="1.5"
              />
              <circle
                cx="37.4998"
                cy="25.4998"
                r="5.49981"
                fill="#C9E9ED"
                stroke="#007377"
                stroke-width="1.5"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M37.2502 21.999V25.9988V21.999Z"
                fill="#C9E9ED"
              />
              <path
                d="M37.2502 21.999V25.9988"
                stroke="#007377"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M40.9991 25.9998H37.4993H40.9991Z"
                fill="#C9E9ED"
              />
              <path
                d="M40.9991 25.9998H37.4993"
                stroke="#007377"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M26.1152 17.7691V15.2808C26.1152 15.2808 26.3744 12.9999 29.2256 12.9999C32.0768 12.9999 32.0768 15.2808 32.0768 15.2808V17.7691"
                stroke="#007377"
                stroke-width="1.5"
              />
            </g>
            <defs>
              <clipPath id="clip0_417_820">
                <rect width="56" height="56" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {/* Icon - End */}
        </S.ImageContainer>
      </Grid.Item>
      <Grid.Item width={[1]}>
        <S.Title>{content.title}</S.Title>
      </Grid.Item>
      <Grid.Item width={[1]}>
        <S.Copy>{content.copy}</S.Copy>
      </Grid.Item>
      <Grid.Item width={[1]}>
        <S.Button appearance="primary" onClick={onCTAClick}>
          {content.ctaText}
        </S.Button>
      </Grid.Item>
    </Grid.Container>
  </S.Modal>
);

export default OrderConfirmationModal;
