import { size } from '@everlywell/leaves';
import styled from 'styled-components';

const Section = styled.section`
  padding-top: ${size.md}px;
`;

const SectionBody = styled.div`
  display: block;
`;

export { Section, SectionBody };
