import { getKitResults } from 'common/apis/kitResultApis';
import useUser from 'common/hooks/useUser/useUser';
import analytics from 'common/utils/analytics';
import { STATUSES } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { KitResult } from 'common/utils/types';
import KitList from 'components/KitList';
import SkeletonTestResults from 'components/SkeletonTestResults';
import ApolloContainer from 'containers/ApolloContainer';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Navigate, useLocation } from 'react-router-dom';

import NoResults from './NoResults';
import ResultsPagination from './ResultsPagination';

/**
 * Results Page Component.
 *
 * Lists all the Kits Results for a the authenticated user
 */
function ResultsPage() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = parseInt(params.get('page') ?? '1');
  const [isResultsPageViewed, setIsResultsPageViewed] =
    useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const { data: response, isLoading } = useQuery(['kit-results', page], () =>
    getKitResults(page),
  );
  const { user } = useUser();

  useEffect(() => {
    if (user && !isResultsPageViewed) {
      analytics.track({
        event: ANALYTICS.EVENTS.VIEWED_PAGE,
        data: {
          page: ANALYTICS.PAGES.ALL_RESULTS,
        },
      });
      setIsResultsPageViewed(true);
    }
  }, [user, isResultsPageViewed]);

  const { inProgressKitResults, completedKitResults } = groupKitResults(
    response?.data ?? [],
  );

  const hasResults =
    inProgressKitResults.length > 0 || completedKitResults.length > 0;

  /**
   * When the page param exceeds the amount of pages available, it redirects to the first page.
   * So, the users do not see a blank page.
   */
  const shouldRedirectToTheFirstPage = page !== 1 && !hasResults && !isLoading;

  if (shouldRedirectToTheFirstPage) {
    return <Navigate to="/results" />;
  }
  return (
    <ApolloContainer>
      {isLoading && <SkeletonTestResults ariaLabel="loading my results" />}
      {!isLoading && !hasResults && <NoResults />}
      {!isLoading && hasResults && (
        <>
          <KitList kitResults={inProgressKitResults} heading="IN PROGRESS" />
          <KitList kitResults={completedKitResults} heading="COMPLETED" />
          <ResultsPagination
            currentPage={page}
            isLoading={isLoading}
            {...paginationPropsFromHeaders(response?.headers)}
          />
        </>
      )}
    </ApolloContainer>
  );
}

export default ResultsPage;

const filterKitsResultsByStatus = (results: KitResult[], statuses: string[]) =>
  results.filter(({ status }) => statuses.includes(status));

const groupKitResults = (data: KitResult[]) => ({
  inProgressKitResults: filterKitsResultsByStatus(data, STATUSES.IN_PROGRESS),
  completedKitResults: filterKitsResultsByStatus(data, [
    STATUSES.RESULTS_APPROVED,
  ]),
});

/**
 * Gets the total and per page values from the headers response
 * @param headers
 * @returns
 */
const paginationPropsFromHeaders = (headers?: Headers) => {
  const itemsPerPage = parseInt(headers?.get('per-page') ?? '');
  const totalItems = parseInt(headers?.get('total') ?? '');

  if ([itemsPerPage, totalItems].some(isNaN)) {
    return { itemsPerPage: 0, totalItems: 0 };
  }

  return { itemsPerPage, totalItems };
};
