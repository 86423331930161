import { isSameDay } from 'date-fns';
import React, { useState } from 'react';

import DateCard from './components/DateCard';
import * as S from './DaySelector.styles';

export type DaySelectorProps = {
  days: Date[];
  selectedDay: Date | null;
  onSelectedDay: (date: Date) => void;
};

/**
 * This component is used to select a day from a list of days.
 */
function DaySelector({ days, selectedDay, onSelectedDay }: DaySelectorProps) {
  const [containerScroll, setContainerScroll] = useState({
    current: 0,
    width: 0,
  });

  const handleContainerScroll = (event: React.MouseEvent<HTMLDivElement>) => {
    const { scrollLeft, scrollWidth, offsetWidth } = event.currentTarget;

    setContainerScroll({
      current: scrollLeft,
      width: scrollWidth - offsetWidth,
    });
  };

  return (
    <S.OuterContainer
      data-scroll-current={containerScroll.current}
      data-scroll-width={containerScroll.width}
    >
      <S.Container onScroll={handleContainerScroll}>
        {days.map((day, index) => (
          <DateCard
            key={`DateCard-${index}`}
            date={day}
            isSelected={selectedDay ? isSameDay(day, selectedDay) : false}
            onClick={() => onSelectedDay(day)}
          />
        ))}
      </S.Container>
    </S.OuterContainer>
  );
}

export default DaySelector;
