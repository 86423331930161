/**
 *
 * ReactivityBadge
 *
 */

import {
  LESS_THAN_DETECTABLE_LIMIT,
  NOT_APPLICABLE,
  NOTAPPLICABLE,
  DNR,
  QNS,
  NSA,
  NCAL,
  INVALID,
} from 'common/utils/constants';
import { formatStringSeparator } from 'common/utils/formatText';
import { isMarkerValueException, isHumanaCustomer } from 'common/utils/helpers';
import { MarkerResult, Marker, MarkerValueException } from 'common/utils/types';
import ReactivityBadgeIconContainer from 'containers/ReactivityBadgeIconContainer';
import React from 'react';

import HistoricalData from './HistoricalData';
import MarkerRangeDetails from './MarkerRangeDetails';
import * as S from './styles';

type Props = {
  marker: Marker;
  markerResult: MarkerResult;
  markerLabel: string; // label next to the icon over the range level
  showMarkerValue?: boolean; // marker result value on the chart
  showRangeInfo?: boolean; // true if badge footer is to be shown
  isMultiNumerical?: boolean; // true if test type is multi-numerical
  dataTest?: string;
  viewingSharedKitResult?: boolean;
  showComparison?: boolean;
};

const ReactivityBadge = (props: Props) => {
  const {
    isMultiNumerical = false,
    markerLabel,
    marker,
    markerResult,
    showMarkerValue = false,
    showRangeInfo = false,
    dataTest = '',
    viewingSharedKitResult,
    showComparison = false,
  } = props;

  const markerValue = markerResult.value.toString().toLowerCase();
  const isOptionalLiteral =
    markerResult.severity_index > markerResult.boundaries.length;
  const isException = isMarkerValueException(markerValue) || isOptionalLiteral;

  let exception: MarkerValueException = {
    name: '',
    descriptor: '',
    footerDescriptor: '',
    value: '',
    severityIndex: 0,
  };

  if (isException) {
    if (isOptionalLiteral) {
      exception = {
        name: markerValue,
        descriptor: marker.descriptors[markerResult.severity_index],
        footerDescriptor: '',
        value: '',
        severityIndex: markerResult.severity_index,
      };
    } else if (markerValue === LESS_THAN_DETECTABLE_LIMIT) {
      exception = {
        name: LESS_THAN_DETECTABLE_LIMIT,
        descriptor: 'Below detectable limit',
        footerDescriptor: 'Outside of Reportable Range',
        value: '',
        severityIndex: 0,
      };
    } else if (
      markerValue === NOT_APPLICABLE ||
      markerValue === NOTAPPLICABLE
    ) {
      exception = {
        name: NOT_APPLICABLE,
        descriptor: 'Not Applicable',
        footerDescriptor: 'Not in any range',
        value: 'N/A',
        severityIndex: marker.severities.length - 1,
      };
    } else if (markerValue === DNR) {
      exception = {
        name: DNR,
        descriptor: 'Did Not Report',
        footerDescriptor: 'Not in any range',
        value: 'DNR',
        severityIndex: marker.severities.length - 1,
      };
    } else if (markerValue === QNS) {
      exception = {
        name: QNS,
        descriptor: 'Quality Not Sufficient',
        footerDescriptor: 'Not in any range',
        value: 'QNS',
        severityIndex: marker.severities.length - 1,
      };
    } else if (markerValue === NSA) {
      exception = {
        name: NSA,
        descriptor: 'NSA',
        footerDescriptor: 'Not Suitable for Analysis',
        value: 'NSA',
        severityIndex: marker.severities.length - 1,
      };
    } else if (markerValue === NCAL) {
      exception = {
        name: NCAL,
        descriptor: 'NCAL',
        footerDescriptor: 'Could Not Be Calculated',
        value: 'NCAL',
        severityIndex: marker.severities.length - 1,
      };
    } else if (markerValue === INVALID) {
      exception = {
        name: INVALID,
        descriptor: 'Invalid',
        footerDescriptor: 'Not in any range',
        value: 'Invalid',
        severityIndex: marker.severities.length - 1,
      };
    }
  }

  const {
    severity_index: severityIndex,
    historical_results: historicalResults,
    results_approved_at: resultsApprovedAt,
    id: currentResultId,
  } = markerResult;

  const { type: markerType, descriptors } = marker;

  let previousResult = null;

  if (historicalResults && historicalResults.length > 1) {
    const markerResultIds = historicalResults.map((result) => result.id) || [];
    const currentResultIndex = markerResultIds.indexOf(currentResultId);

    if (!(currentResultIndex === markerResultIds.length - 1)) {
      previousResult = historicalResults[currentResultIndex + 1];
    }
  }

  let descriptor = descriptors[severityIndex];

  if (descriptor) {
    descriptor = descriptor.replace(/range/gi, '').trim();
  }

  const formattedDataTest = formatStringSeparator(dataTest.toLowerCase());
  const displayValue = isException
    ? exception.value.toString().replace(/ +/g, '')
    : markerResult.value.toString().replace(/ +/g, '');

  const thirdParty = isHumanaCustomer();

  return (
    <S.Container>
      <S.ReactivityBadgeContainer
        data-test={dataTest}
        showMarkerValue={showMarkerValue}
      >
        <S.ReactivityBadgeBody>
          <S.MarkerInfo>
            <ReactivityBadgeIconContainer
              dataTest={`${formattedDataTest}-icon`}
              severityIndex={
                isException && typeof exception.severityIndex === 'number'
                  ? exception.severityIndex
                  : severityIndex
              }
              marker={marker}
              exception={isException ? exception.name : null}
              markerType={markerType}
              isOptionalLiteral={isOptionalLiteral}
            />
            <S.TitleContainer>
              <S.LabelName>{markerLabel}</S.LabelName>
              <S.RangeName>
                {isException ? exception.descriptor : descriptor}
              </S.RangeName>
            </S.TitleContainer>
          </S.MarkerInfo>
          <S.MarkerValue
            data-test={`${formattedDataTest}-value`}
            show={showMarkerValue}
          >
            {displayValue}
          </S.MarkerValue>
        </S.ReactivityBadgeBody>
        {/* leaving this placeholder here for comparison data as it will use the same styles as the previous footer */}
        {showComparison &&
          !thirdParty &&
          previousResult &&
          !viewingSharedKitResult && (
            <HistoricalData
              resultsApprovedAt={resultsApprovedAt || ''}
              historicalResult={previousResult}
              marker={marker}
              dataTest={`${formattedDataTest}-historical-comparison`}
            />
          )}
      </S.ReactivityBadgeContainer>
      {showRangeInfo && (
        <MarkerRangeDetails
          isMultiNumerical={isMultiNumerical}
          isException={isException}
          formattedDataTest={formattedDataTest}
          exception={exception}
          descriptor={descriptor}
          showMarkerValue={showMarkerValue}
          markerResult={markerResult}
        />
      )}
    </S.Container>
  );
};

export default ReactivityBadge;
