import { debounce } from 'lodash';
import { useState, useEffect } from 'react';

const DEBOUNCE_WAIT = 300;

function useMediaQuery(query: string) {
  const mediaQuery = window.matchMedia(query);

  const [isMatching, setIsMatching] = useState(mediaQuery.matches);

  const checkIfIsMatching = () => {
    setIsMatching(mediaQuery.matches);
  };

  useEffect(() => {
    mediaQuery.addListener(checkIfIsMatching);
    window.addEventListener(
      'resize',
      debounce(checkIfIsMatching, DEBOUNCE_WAIT),
    );
    return () => {
      mediaQuery.removeListener(checkIfIsMatching);
      window.removeEventListener(
        'resize',
        debounce(checkIfIsMatching, DEBOUNCE_WAIT),
      );
    };
  });

  return isMatching;
}

export default useMediaQuery;
