/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { ActionT, Notification } from 'common/utils/types';
import { Map } from 'immutable';

import {
  AUTHENTICATION_SUCCESS,
  HTTP_AUTHENTICATION_SUCCESS,
  HTTP_GET_KITS_REQUEST,
  HTTP_GET_KIT_RESULT_FAILURE,
  HTTP_GET_KIT_RESULT_CONTENT_REQUEST,
  HTTP_GET_KIT_RESULT_CONTENT_SUCCESS,
  HTTP_GET_KIT_RESULT_CONTENT_FAILURE,
  HTTP_GET_KITS_SUCCESS,
  LOADING_ON,
  LOADING_OFF,
  SET_NOTIFICAION,
  CLEAR_NOTIFICAION,
} from './constants';

export type InitialStateT = Map<string, any>;

// The initial state of the App
// @ts-ignore
export const initialState: ImmutableAppState = Map({
  loading: false,
  loadingResults: false,
  loadingContent: false,
  error: false,
  errorStatus: null,
  authenticated: false,
  notification: undefined,
});

interface AppState {
  loading: boolean;
  loadingResults: boolean;
  loadingContent: boolean;
  error: boolean;
  errorStatus?: number | null;
  authenticated: boolean;
  notification?: Notification;
}

export interface ImmutableAppState extends Map<string, any> {
  toJS(): AppState;
  get<Index extends keyof AppState>(key: Index): AppState[Index];
  set<Key extends keyof AppState>(
    key: Key,
    value: AppState[Key],
  ): Map<string, any>;
}

function appReducer(state: ImmutableAppState = initialState, action: ActionT) {
  switch (action.type) {
    case AUTHENTICATION_SUCCESS:
      return state.set('authenticated', true);
    case HTTP_AUTHENTICATION_SUCCESS:
      return state.merge({
        authenticated: true,
      });
    case HTTP_GET_KIT_RESULT_FAILURE:
      return state.merge({
        error: true,
        errorStatus: action.payload.status || null,
      });
    case LOADING_ON:
      return state.set('loading', true);
    case LOADING_OFF:
      return state.set('loading', false);
    case HTTP_GET_KITS_REQUEST:
      return state.set('loadingResults', true);
    case HTTP_GET_KITS_SUCCESS:
      return state.set('loadingResults', false);
    case HTTP_GET_KIT_RESULT_CONTENT_REQUEST:
      return state.set('loadingContent', true);
    case HTTP_GET_KIT_RESULT_CONTENT_FAILURE:
    case HTTP_GET_KIT_RESULT_CONTENT_SUCCESS:
      return state.set('loadingContent', false);
    case SET_NOTIFICAION:
      return state.set('notification', action.payload);
    case CLEAR_NOTIFICAION:
      return state.delete('notification');
    default:
      return state;
  }
}

export default appReducer;
