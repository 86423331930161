import Grid from 'components/Grid/Grid';
import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useSet } from 'react-use';

import { FormBuilderField } from '../../utils/types';
import HtmlParser from '../ReadOnlyField/components/HtmlParser';
import * as S from './CheckboxFieldGroup.styles';

export type CheckboxFieldGroupProps = FormBuilderField;

export const REQUIRED_ERROR =
  'Checkbox is required. Please select at least one.';
export const NONE_OF_THE_ABOVE_REGEX = /none of the above/i;

/**
 * Dynamic checkbox fields for demographics form. Controlled by the react hook form
 */
export default function CheckboxFieldGroup({
  id,
  label,
  options_array,
  required,
  validate,
  variant,
}: CheckboxFieldGroupProps) {
  const {
    field: { onChange: sendValueChange, ref },
  } = useController({
    name: id,
    defaultValue: '',
    rules: {
      required: required && REQUIRED_ERROR,
      validate: validate,
    },
  });

  const { errors } = useFormContext();
  const error = errors[id];

  // Internal copy to track selected values
  const [
    values,
    {
      toggle: toggleValue,
      has: hasValue,
      reset: resetValues,
      remove: removeValue,
    },
  ] = useSet<string>(new Set([]));

  const noneOption = options_array.find((option) =>
    option.match(NONE_OF_THE_ABOVE_REGEX),
  );

  const onChangeHandler = (value: string) => {
    /**
     * If any other option is checked, then none of the above option is unchecked
     */
    const isNoneOptionPresent =
      noneOption && value !== noneOption && hasValue(noneOption);
    if (isNoneOptionPresent) {
      removeValue(noneOption);
    }

    /**
     * If none of the above option is checked, then all the values are unchecked
     */
    const isNoneOptionBeingChecked =
      noneOption && value === noneOption && !hasValue(noneOption);
    if (isNoneOptionBeingChecked) {
      resetValues();
    }

    toggleValue(value);
  };

  useEffect(() => {
    // Backend expects values separated by a \n
    sendValueChange(values.size ? Array.from(values).join('\n') : '');
  }, [sendValueChange, values]);

  return (
    <Grid.Item width={[1]}>
      <S.Container>
        <S.Label variant={variant} hasError={!!error} required={required}>
          {label}
        </S.Label>
        {options_array.map((option) => (
          <S.Checkbox
            ref={ref}
            onChange={() => onChangeHandler(option)}
            key={option}
            label={<HtmlParser html={option} />}
            name={option}
            checked={hasValue(option)}
            border={false}
            bodyTextStyle
          />
        ))}
        <S.Error role="alert">{error?.message}</S.Error>
      </S.Container>
    </Grid.Item>
  );
}
