import { Localize } from '@everlywell/leaves';
import { format, parseJSON } from 'date-fns';
import React from 'react';

/**
 * Component to render appointment dates for Virtual Care.
 * For Localize the date has to be split up into each individual element so we can translate it properly.
 */

export type AppointmentDateProps = {
  appointmentDate: string;
  showTimezone: boolean;
  showTime?: boolean;
  showDayOfWeek?: boolean;
  options?: {
    dayPattern: string;
  };
};

function AppointmentDate({
  appointmentDate,
  showTimezone,
  showTime = true,
  showDayOfWeek = true,
  options,
}: AppointmentDateProps) {
  const formattedDayOfWeek = format(parseJSON(appointmentDate), 'EEEE');
  const formattedMonth = format(parseJSON(appointmentDate), 'LLLL');
  const formattedDay = format(
    parseJSON(appointmentDate),
    options?.dayPattern ?? 'd',
  );
  const formattedYear = format(parseJSON(appointmentDate), ' yyyy');
  const formattedTime = format(parseJSON(appointmentDate), 'p');
  const formattedTimeZone = new Date(appointmentDate)
    .toLocaleTimeString('en-us', { timeZoneName: 'short' })
    .split(' ')[2];
  return (
    <>
      <strong>
        {showDayOfWeek && (
          <>
            <span data-isolate>
              <Localize name="dayOfWeek">{formattedDayOfWeek}</Localize>
            </span>
            ,{' '}
          </>
        )}
        <span data-isolate>
          <Localize name="month">{formattedMonth}</Localize>
        </span>{' '}
        <span data-isolate>
          <Localize name="day">{formattedDay}</Localize>
        </span>
        ,<Localize name="year">{formattedYear}</Localize>
        {showTime && (
          <>
            {' '}
            at <Localize name="appointmentTime">{formattedTime}</Localize>{' '}
          </>
        )}
        {showTime && showTimezone && formattedTimeZone}
      </strong>
    </>
  );
}

export default AppointmentDate;
