import { STATUSES } from 'common/utils/constants';

export enum STATUSES_LABELS {
  Approved = 'Approved',
  Delivered = 'Delivered',
  InTransit = 'In transit',
  Processing = 'Processing',
  Ready = 'Results ready',
  Registered = 'Registered',
  lab_visit_approved = 'Ready to schedule',
  OrderSubmitted = 'Order submitted',
}

export type StatusMap = Record<string, STATUSES_LABELS>;

export const atLabStatus: StatusMap = {
  [STATUSES.KIT_REGISTERED]: STATUSES_LABELS.Registered,
  [STATUSES.DELIVERED]: STATUSES_LABELS.Delivered,
  [STATUSES.RESULTS_PROCESSING]: STATUSES_LABELS.Processing,
  [STATUSES.RESULTS_APPROVED]: STATUSES_LABELS.Ready,
};

export const labVisitStatus: StatusMap = {
  [STATUSES.KIT_REGISTERED]: STATUSES_LABELS.OrderSubmitted,
  [STATUSES.LAB_VISIT_APPROVED]: STATUSES_LABELS.lab_visit_approved,
  [STATUSES.RESULTS_APPROVED]: STATUSES_LABELS.Ready,
};

export const notAtLabStatus: StatusMap = {
  [STATUSES.KIT_REGISTERED]: STATUSES_LABELS.Registered,
  [STATUSES.IN_TRANSIT]: STATUSES_LABELS.InTransit,
  [STATUSES.RESULTS_PROCESSING]: STATUSES_LABELS.Processing,
  [STATUSES.RESULTS_APPROVED]: STATUSES_LABELS.Ready,
};

export const rapidAntigenStatus: StatusMap = {
  [STATUSES.KIT_REGISTERED]: STATUSES_LABELS.Registered,
  [STATUSES.RESULTS_PROCESSING]: STATUSES_LABELS.Processing,
  [STATUSES.RESULTS_APPROVED]: STATUSES_LABELS.Ready,
};

export const shippingStatus = [STATUSES.KIT_REGISTERED, STATUSES.IN_TRANSIT];
