import { PROGRAM_TYPE } from 'common/utils/types';

import {
  WM_FULL_PROGRAM_SLUG,
  WM_PROGRAM_SLUG,
  STI_ON_DEMAND_PROGRAM_SLUG,
} from '../InterstitialPage';

export type PaymentTypeContent = {
  HEADING: string;
  SUB_HEADING: string;
  WITH_INSURANCE: string;
  WITHOUT_INSURANCE: string;
  NOTE?: string;
  DISCLAIMER?: string;
};

const WM_PROGRAM_CONTENT = {
  HEADING: 'Planning to use insurance for prescriptions?',
  SUB_HEADING: `We can help you navigate insurance coverage for your prescription.`,
  NOTE: 'Insurance is for prescriptions only; initial and ongoing consult costs are not covered by insurance.',
  WITH_INSURANCE: 'Add insurance',
  WITHOUT_INSURANCE: 'Skip',
  DISCLAIMER: `Federal and state healthcare programs, including Medicare, Medicaid, TRICARE and GEHA (Government Employees Health Association) are excluded.`,
};

const ON_DEMAND_CONTENT = {
  HEADING: 'Would you like to use insurance?',
  SUB_HEADING:
    'Due to the immediacy of on-demand appointments, insurance coverage notifications will likely come after your consult. If coverage is insufficient, or if you are on Medicare Part B or Medicaid, you will be responsible for paying the cash price.',
  WITH_INSURANCE: 'Add insurance',
  WITHOUT_INSURANCE: 'Pay $59 without insurance',
};

export const PAYMENT_TYPE_CONTENT: Record<string, PaymentTypeContent> = {
  DEFAULT: {
    HEADING: 'Would you like to use insurance?',
    SUB_HEADING: `You will receive an email before your scheduled appointment with a pricing estimate and details on insurance eligibility. If coverage is insufficient, or if you are on Medicare Part B or Medicaid, you will be responsible for paying the cash price.`,
    WITH_INSURANCE: 'Add insurance',
    WITHOUT_INSURANCE: 'Pay $59 without insurance',
  },
  [WM_PROGRAM_SLUG]: WM_PROGRAM_CONTENT,
  [WM_FULL_PROGRAM_SLUG]: WM_PROGRAM_CONTENT,
  [PROGRAM_TYPE['weight-care-ongoing']]: WM_PROGRAM_CONTENT,
  [STI_ON_DEMAND_PROGRAM_SLUG]: ON_DEMAND_CONTENT,
};
