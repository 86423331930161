import {
  SkeletonLoader,
  SkeletonUnit,
  SkeletonShape,
} from '@everlywell/leaves';
import { breakpoints } from '@everlywell/leaves';
import React from 'react';
import { useWindowSize } from 'react-use';

import * as S from '../styles';

const AppointmentCardSkeleton = () => {
  const { width } = useWindowSize();
  const isMobile = width <= breakpoints.forPhoneOnly;

  return (
    <S.AppointmentCard>
      <S.AppointmentInfo>
        <S.AppointmentBoxImageContainer>
          <SkeletonLoader
            width={{
              value: 100,
              unit: SkeletonUnit.Percentage,
            }}
            height={{
              value: 100,
              unit: SkeletonUnit.Percentage,
            }}
            shape={SkeletonShape.Circle}
          />
        </S.AppointmentBoxImageContainer>
        <S.AppointmentDetails>
          <S.AppointmentDetailsHeading>
            <SkeletonLoader
              width={{
                value: isMobile ? 350 : 450,
                unit: SkeletonUnit.Pixel,
              }}
              height={{
                value: 30,
                unit: SkeletonUnit.Pixel,
              }}
            />
          </S.AppointmentDetailsHeading>
          <SkeletonLoader
            width={{
              value: isMobile ? 375 : 550,
              unit: SkeletonUnit.Pixel,
            }}
            height={{
              value: 30,
              unit: SkeletonUnit.Pixel,
            }}
          />
        </S.AppointmentDetails>
      </S.AppointmentInfo>
      <S.AppointmentCTAandInfo>
        <SkeletonLoader
          width={{
            value: isMobile ? 100 : 230,
            unit: isMobile ? SkeletonUnit.Percentage : SkeletonUnit.Pixel,
          }}
          height={{
            value: 48,
            unit: SkeletonUnit.Pixel,
          }}
        />
      </S.AppointmentCTAandInfo>
    </S.AppointmentCard>
  );
};

export default AppointmentCardSkeleton;
