import { useEffect, useState } from 'react';

const usePoolingCookie = (interval: number = 200) => {
  const [cookie, setCookie] = useState(document.cookie);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (document.cookie !== cookie) {
        setCookie(document.cookie);
      }
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [cookie, interval]);

  return cookie;
};

export default usePoolingCookie;
