import { typography, colors } from '@everlywell/leaves';
import { bodyCopy } from 'common/styles/app-styles';
import styled from 'styled-components';

type Props = {
  isActive: boolean;
};

const Wrapper = styled.button<Props>`
  ${bodyCopy};
  color: ${colors.green4};
  font-weight: ${typography.weight.bold};
  max-width: 200px;
  margin: 0 auto;

  &:hover {
    text-decoration: none;
  }

  &:after {
    display: block;
    ${(props) =>
      props.isActive
        ? `content: '▲'; transform: translateY(1px) scaleY(0.7) scaleX(1.5);`
        : `content: '▼'; transform: translateY(1px) scaleY(0.7) scaleX(1.5);`};
  }
`;

export { Wrapper };
