/**
 *
 * IntensityMarkerCard
 *
 */

import analytics from 'common/utils/analytics';
import {
  CLASS_STYLE_CHART_FF,
  MARKER_VALUE_EXCEPTIONS,
  TEST_SLUGS,
} from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { INTENSITY_RESULT } from 'common/utils/constants/dataTest';
import { sanitize } from 'common/utils/domHelpers';
import * as FS from 'common/utils/formatText';
import {
  calculateComponentHeight,
  getRGBColorBySeverityIndex,
  getRGBRangeColors,
} from 'common/utils/helpers';
import {
  Marker,
  MarkerResult,
  IntensityMarkerContent,
} from 'common/utils/types';
import NewMarkerChartWrapper from 'components/NewMarkerChartWrapper';
import { isEqual } from 'lodash';
import React from 'react';

import * as S from './styles';

export type Props = {
  content: IntensityMarkerContent;
  groupedByCategory: boolean;
  handleMarkerChange: Function;
  isLastElement: boolean;
  marker: Marker;
  markerResult: MarkerResult;
  selectedMarker: number;
  setLastElementRef: Function;
  type?: string;
  updateCardHeight: Function;
};

type State = {
  isOpen: boolean;
};

function markerOverview(
  severity: number,
  content: IntensityMarkerContent,
): string {
  const overview = content && content.markerOverview;
  const abnormalReactivity = content && content.abnormalReactivity;

  if (severity > 1) {
    return abnormalReactivity || overview || 'Marker Overview';
  }

  return overview || 'Normal Marker Overview';
}

class IntensityMarkerCard extends React.PureComponent<Props, State> {
  lastMarkerRef: React.RefObject<HTMLDivElement> | null;

  markerContainerRef: React.RefObject<HTMLDivElement> | null;

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.lastMarkerRef = React.createRef();
    this.markerContainerRef = React.createRef();
  }

  componentDidMount() {
    calculateComponentHeight(
      this.markerContainerRef,
      this.props.updateCardHeight,
    );
  }

  componentDidUpdate = (prevProps: Props) => {
    const shouldUpdate = !isEqual(prevProps, this.props);
    if (shouldUpdate) {
      calculateComponentHeight(
        this.markerContainerRef,
        this.props.updateCardHeight,
      );
    }
  };

  handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    // dont keep focus state on actual clicks
    event.preventDefault();
  };

  handleClick = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (event.type === 'click') event.currentTarget.blur();

    const { severity_index: severityIndex } = this.props.markerResult;
    const { descriptors } = this.props.marker;

    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'Marker result click',
        category: descriptors[severityIndex],
      },
    });

    this.setState((prevState: State) => ({ isOpen: !prevState.isOpen }));
    this.props.handleMarkerChange(this.props.markerResult.id);
  };

  handleKeyDown = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    // Only handle Enter(13) and Space (32)
    // keyCode has been deprecated https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
    if (ev.key !== 'Enter' && ev.key !== ' ') return;

    // Don't scroll down on Space
    ev.preventDefault();

    this.handleClick(ev);
  };

  render() {
    const {
      content,
      groupedByCategory,
      type,
      isLastElement,
      marker,
      markerResult,
      selectedMarker,
      setLastElementRef,
    } = this.props as Props;

    const { isOpen } = this.state as State;

    const classStyleFF = type ? CLASS_STYLE_CHART_FF[type] : '';
    const isClassStyle = Object.keys(TEST_SLUGS).some(
      (key: any) => TEST_SLUGS[key] === type,
    );

    const severityValue: number =
      marker.severities[Number(markerResult.severity_index)];
    const severityColor = getRGBColorBySeverityIndex(
      markerResult.severity_index,
      marker,
    );

    const formattedMarkerName = FS.formatMarkerName(marker.name);
    const markerContentDataTest = FS.formatStringSeparator(
      `content ${formattedMarkerName.toLowerCase()}`,
    );
    const isSelectedMarker = selectedMarker === markerResult.id;
    const markerChartDataTest = FS.formatStringSeparator(
      `chart ${formattedMarkerName.toLowerCase()}`,
    );

    return (
      // @ts-ignore-next-line
      <S.MarkerCardContainer ref={isLastElement && this.lastMarkerRef}>
        {isLastElement && setLastElementRef(this.lastMarkerRef)}
        <S.MarkerTab
          data-test={FS.formatStringSeparator(
            `marker tab ${marker.name.toLocaleLowerCase()}`,
          )}
          className={`${isOpen ? `show-mobile` : ``} ${
            isSelectedMarker ? 'show' : 'hide'
          }`}
          severityColor={severityColor}
          groupedByCategory={groupedByCategory}
          role="button"
          tabIndex={0}
          onClick={this.handleClick}
          onMouseDown={this.handleMouseDown}
          onKeyDown={this.handleKeyDown}
          data-id={markerResult.id}
        >
          <span>{marker.name.toLocaleLowerCase()}</span>
        </S.MarkerTab>
        <S.MarkerContent
          data-test={markerContentDataTest}
          className={`${isOpen ? `show-mobile` : ``} ${
            isSelectedMarker ? 'show' : 'hide'
          }`}
        >
          <S.MarkerContentContainer
            // @ts-ignore-next-line
            ref={isSelectedMarker && this.markerContainerRef}
          >
            <S.MarkerContentTitle>
              Your {formattedMarkerName} Result
            </S.MarkerContentTitle>
            {(isSelectedMarker || isOpen) && (
              <S.MarkerChartWrapper data-test={markerChartDataTest}>
                <NewMarkerChartWrapper
                  classStyleFF={classStyleFF}
                  excludedMarkerValues={MARKER_VALUE_EXCEPTIONS}
                  isClassStyle={isClassStyle}
                  marker={marker}
                  markerResult={markerResult}
                  rangeColors={getRGBRangeColors(marker)}
                />
              </S.MarkerChartWrapper>
            )}
            <S.MarkerContentDataTitle
              data-test={INTENSITY_RESULT.OVERVIEW_HEADER}
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  (content && content.markerOverviewHeader) || '',
                ),
              }}
            />
            <S.MarkerCardBodyCopy
              dangerouslySetInnerHTML={{
                __html: sanitize(markerOverview(severityValue, content)),
              }}
            />
            <S.MarkerCardDataBlockContainer>
              <S.MarkerCardDataBlock
                data-test={INTENSITY_RESULT.EXTENDED_CONTENT_BLOCK}
              >
                <S.MarkerContentDataTitle>
                  {content && content.markerOverviewExtendedContent1Header}
                </S.MarkerContentDataTitle>
                <S.MarkerCardBodyCopy
                  className="left"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(
                      (content && content.markerOverviewExtendedContent1) || '',
                    ),
                  }}
                />
              </S.MarkerCardDataBlock>
              <S.MarkerCardDataBlock
                data-test={INTENSITY_RESULT.EXTENDED_CONTENT_BLOCK}
              >
                <S.MarkerContentDataTitle>
                  {content && content.markerOverviewExtendedContent2Header}
                </S.MarkerContentDataTitle>
                <S.MarkerCardBodyCopy
                  dangerouslySetInnerHTML={{
                    __html: sanitize(
                      (content && content.markerOverviewExtendedContent2) || '',
                    ),
                  }}
                />
              </S.MarkerCardDataBlock>
            </S.MarkerCardDataBlockContainer>
          </S.MarkerContentContainer>
        </S.MarkerContent>
      </S.MarkerCardContainer>
    );
  }
}

export default IntensityMarkerCard;
