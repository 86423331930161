import React from 'react';

import UserProfile from './components/UserProfile';

/**
 * Content for the account tab
 */
export default function AccountTabContent() {
  return <UserProfile />;
}
