import React, { FC } from 'react';

import { INTAKE } from '../../constants';
import * as S from '../Payment.styles';

type Props = {
  isSubmitting: boolean;
};

const LoadingAnimation: FC<Props> = ({ isSubmitting }) => (
  <div>
    {isSubmitting ? (
      <S.ProgressCircleAnimation data-testid={INTAKE.LOADING_ANIMATION} />
    ) : null}
  </div>
);

export default LoadingAnimation;
