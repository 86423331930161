import { useEffect, useRef } from 'react';

function useKeyboardActions(onTrigger: Function) {
  const wrapperEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!wrapperEl || !wrapperEl.current) return;
    const handleClick = (e: MouseEvent) => {
      if (e.currentTarget) {
        (e.currentTarget as HTMLDivElement).blur();
      }

      onTrigger();
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      // Only handle Enter(13) and Space (32)
      // keyCode has been deprecated https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        onTrigger();
      }
    };
    const currentEl = wrapperEl.current;
    currentEl.addEventListener('click', handleClick);
    currentEl.addEventListener('keydown', handleKeyDown);

    // for consistent-return
    // eslint-disable-next-line
    return () => {
      if (!currentEl) return;
      currentEl.removeEventListener('click', handleClick);
      currentEl.removeEventListener('keydown', handleKeyDown);
    };
  }, [wrapperEl, onTrigger]);

  return wrapperEl;
}

export default useKeyboardActions;
