import { colors, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 530px;
  margin: 0 auto;
  height: 100%;
  padding-bottom: ${size.xs3}px;
`;

export const StepCount = styled.span`
  ${typography.overlineText};
  margin-bottom: ${size.md}px;
`;

export const CircleAnimation = styled.div`
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  opacity: 0.95;
  z-index: 100;

  &:after {
    position: absolute;
    animation: loading 500ms infinite linear;
    border: ${size.xs3}px solid ${colors.green3};
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    height: ${size.xl1}px;
    width: ${size.xl1}px;
    left: 50%;
    top: 40%;
    margin-left: -0.4rem;
    margin-top: -0.4rem;
  }
`;
