import { size, colors, mediaQueries, typography, H1 } from '@everlywell/leaves';
import PageHeaderLink from 'pages/CarePlanPage/components/PageHeader/PageHeaderLink';
import styled, { css } from 'styled-components';

export const container = css`
  background-color: ${colors.teal5};
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: ${size.md}px ${size.md}px ${size.xl3}px;
  margin: -${size.md}px -${size.md}px ${size.xl1}px -${size.md}px;

  ${mediaQueries.forTabletVerticalUp} {
    background-color: transparent;
    border-radius: 0;
    padding: 0 0 ${size.xl2}px;
    margin: 0;
  }

  ${mediaQueries.forDesktopUp} {
    padding: 0 0 ${size.xl2}px;
  }
`;

export const Heading = styled(H1)`
  ${typography.h2Text};
  margin: 0;
  color: ${colors.white};

  ${mediaQueries.forTabletVerticalUp} {
    color: ${colors.teal4};
  }
`;

export const VirtualCareLink = styled(PageHeaderLink)`
  display: flex;

  ${mediaQueries.forTabletVerticalUp} {
    ${typography.buttonTextSmall}
    margin-bottom: ${size.xs1}px;
  }
`;

export const contentContainer = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
});

export const image = css({
  [mediaQueries.forTabletVerticalUp]: {
    display: 'none',
  },
});
