import {
  colors,
  mediaQueries,
  size,
  typography,
  Button,
} from '@everlywell/leaves';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const MAX_WIDTH_FOR_BANNER = 1180;
const MIN_WIDTH_FOR_BANNER = 703;
const BANNER_BUTTON_WIDTH = 113;

const Banner = styled.div`
  background-color: ${colors.white};
  border-radius: ${size.xs1}px;
  border-top: ${size.xs2}px solid ${colors.green6};
  box-shadow: 0 7px 30px -10px rgba(220, 228, 229, 0.5);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin: ${size.md}px 0;
  width: 100%;
  max-width: ${MAX_WIDTH_FOR_BANNER}px;
  padding: ${size.lg}px ${size.xl1}px;

  ${mediaQueries.forTabletVerticalUp} {
    min-width: ${MIN_WIDTH_FOR_BANNER}px;
  }
`;

const BannerContainer = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: -1px;
  padding: 0 3%;
  background-color: ${colors.white};

  ${mediaQueries.forTabletVerticalUp} {
    padding: 0 7%;
  }

  ${mediaQueries.forDesktopUp} {
    padding: 0;
  }
`;

const BannerWrapper = styled.div`
  display: flex;
  width: 100%;
  ${mediaQueries.forPhoneOnly} {
    flex-direction: column;
    margin-top: -${size.xs1}px;
    width: 80%;
  }
`;

const ButtonLink = styled(Link)`
  align-self: center;
  margin-left: auto;

  ${mediaQueries.forPhoneOnly} {
    margin-left: 0;
    margin-right: auto;
  }
`;

const BannerButton = styled(Button)`
  font-weight: ${typography.weight.xbold};
  border-radius: ${size.xs3}px;
  height: ${size.xl1}px;
  width: ${BANNER_BUTTON_WIDTH}px;

  ${mediaQueries.forPhoneOnly} {
    margin: ${size.sm}px auto 0 0;
  }
`;

const Circle = styled.svg`
  display: block;
  height: ${size.xl2}px;
  width: ${size.xl2}px;

  .circle-background {
    fill: none;
    stroke: ${colors.green2};
    stroke-width: 3.8;
  }

  .circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    stroke: ${colors.teal6};
    transform: scale(-1, 1);
    transform-origin: center;
  }

  ${mediaQueries.forPhoneOnly} {
    align-self: flex-start;
    margin-top: -6px;
  }
`;

const Percentage = styled.text`
  fill: ${colors.gray5};
  font-size: ${size.sm}px;
  text-anchor: middle;
`;

const ContentWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;

  ${mediaQueries.forPhoneOnly} {
    margin: 0;
  }
`;

const Header = styled.p`
  color: ${colors.teal6};
  font-size: ${size.md};
  font-weight: ${typography.weight.xbold};
`;

const Image = styled.img`
  align-self: flex-start;
  margin: -${size.xs2}px ${size.md}px 0 0;
  width: ${size.xl2}px;
  ${mediaQueries.forTabletVerticalUp} {
    align-self: center;
  }
`;

const Text = styled.p`
  color: ${colors.gray5};
  font-size: 14px;
  font-weight: ${typography.weight.light};
`;

const StarImage = styled.img`
  height: 17px;
  margin-right: ${size.lg}px;
  width: ${size.sm}px;

  ${mediaQueries.forPhoneOnly} {
    margin: -6px ${size.md}px 0 0;
  }
`;

export {
  Banner,
  BannerContainer,
  BannerWrapper,
  BannerButton,
  ButtonLink,
  Circle,
  ContentWrapper,
  Header,
  Image,
  Percentage,
  StarImage,
  Text,
};
