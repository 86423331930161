/**
 * This is our new Kit Result Layout.
 *
 * It should be divided into 3 sections: Results, Next Steps and Resources.
 * You can find more details in: https://everlyhealth.atlassian.net/browse/RE-197
 *
 */
import { datadogRum } from '@datadog/browser-rum';
import { Col } from '@everlywell/leaves';
import useApi from 'common/hooks/useApi';
import analytics from 'common/utils/analytics';
import {
  MARKER_TYPES,
  ANCESTRY_TEST_IDS,
  TEST_IDS,
  COVID_19_IDS,
  FS_PERSONALIZATION_CONDITIONS,
  RESULT_SECTIONS_NAMES,
  LOCALIZE_SKUS,
} from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import {
  COBRANDED_ANCESTRY_BANNER,
  KIT_RESULT_DETAIL,
  RESULT_DETAILS as DATA_TEST,
} from 'common/utils/constants/dataTest';
import { TAKE_ACTION_ORDER_OVERRIDE } from 'common/utils/constants/features';
import {
  getIsVisibleThreshold,
  isEnterpriseCustomer,
  isHumanaCustomer,
} from 'common/utils/helpers';
import { logError } from 'common/utils/helpers';
import {
  groupBySeverity,
  groupFSMarkersBySeverity,
} from 'common/utils/resultGenerator';
import { hasTelehealthConsult } from 'common/utils/telehealthConsult';
import {
  FactCardValues,
  HealthProfileProps,
  KitResult,
  Personalizations,
} from 'common/utils/types';
import FoodSensitivityResultDetail from 'components/FoodSensitivityResultDetail';
import GenomicsResultDetail from 'components/GenomicsResultDetail';
import IntensityResultDetail from 'components/IntensityResultDetail';
import Layout from 'components/Layout';
import AncestryBannerContainer from 'containers/AncestryBannerContainer';
import DescriptiveResultDetailContainer from 'containers/DescriptiveResultDetailContainer';
import {
  StateProps,
  DispatchProps,
} from 'containers/KitResultDetailPageContainer';
import NumericalResultDetailContainer from 'containers/NumericalResultDetailContainer';
import ResultsIntro from 'containers/ResultsIntro';
import ProductPromo from 'containers/TakeActionSection/ProductPromo';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';
import { isUndefined } from 'lodash';
import SavePdfButton from 'pages/KitResultDetailPage/SavePdfButton';
import React, { useEffect, useState } from 'react';
import { IntersectionOptions, useInView } from 'react-intersection-observer';
import { useEffectOnce } from 'react-use';

import AncestryFooter from '../AncestryFooter';
import BeforeYouDiveInSection from '../BeforeYouDiveInSection';
import CommonQuestionsSection from '../CommonQuestionsSection';
import ConsiderChangesSection from '../ConsiderChangesSection';
import GetHelpSection from '../GetHelpSection';
import MarkerReplacementSection from '../MarkerReplacementSection';
import ResourcesLinksSection from '../ResourcesLinksSection';
import ResultSection from '../ResultSection';
import SubNavbar from '../SubNavbar';
import TakeActionSection from '../TakeActionSection';
import WhatsNextSection from '../WhatsNextSection';
import ResultDetailSection from './ResultDetailSection';
import SectionsNavBar from './SectionsNavBar';
import * as S from './styles';

const isHumana = isHumanaCustomer();

type Props = StateProps & DispatchProps;

function NewLayoutKitResultDetailPage(props: Props) {
  const {
    contentToken,
    kitResult,
    kitResultIdOrPublishHash,
    getKitResult,
    testId,
    viewingSharedKitResult,
    showConsult,
    sku,
  } = props;
  let isConsultAvailable = false;
  let shouldShowUpsell = false;

  const { getPersonalizations } = useApi();
  const [personalizations, setPersonalizations] = useState<HealthProfileProps>({
    food_sensitivity: [] as Array<string>,
    pcp: null,
    fact_cards: [] as FactCardValues[],
  });

  const { showResultsPromoCard: showResultsPromoCardFlag } = useFlags();

  const isAncestryTest = testId && ANCESTRY_TEST_IDS.includes(testId);
  const isWomensHealth = testId && [TEST_IDS.WOMENS_HEALTH].includes(testId);
  const isFoodSensitivity =
    kitResult?.test?.marker_types &&
    kitResult?.test?.marker_types?.includes(MARKER_TYPES.FOOD_SENSITIVITY);

  const conditions = personalizations?.food_sensitivity.filter((condition) =>
    Object.values(FS_PERSONALIZATION_CONDITIONS).includes(condition),
  );
  const shouldShowPCPQuestions = typeof personalizations?.pcp === 'boolean';

  // Show Personalization Section if  is an FS Test, and has one or more of the required conditions
  const showFsPersonalization = isFoodSensitivity && conditions.length;

  const getUserPreferredLanguage = (): string => {
    let prevLanguage = 'en';

    if (window.Localize) {
      window.Localize.detectLanguage((err: Error, languages: string[]) => {
        if (err) {
          logError((err as Error).message, {
            errorInfo: 'Error Window Localize',
            component: 'NewLayoutKitResultDetailPage',
            method: 'getUserPreferredLanguage',
          });
          return;
        }
        if (languages[0].startsWith('es')) {
          prevLanguage = 'es';
        }
        return null;
      });
    }

    return prevLanguage;
  };

  const setResultLanguage = (
    userPreferredLanguage: string,
    testId: number | undefined,
    sku: string | undefined,
  ) => {
    const isCovidTest = testId && COVID_19_IDS.includes(testId);
    const shouldLocalizeTestResult =
      isCovidTest || (sku && LOCALIZE_SKUS.includes(sku));

    const lang = shouldLocalizeTestResult ? userPreferredLanguage : 'en';

    window?.Localize?.setLanguage(lang);
  };

  useEffect(() => {
    if (!window.Localize) return;

    const userPreferredLanguage = getUserPreferredLanguage();

    setResultLanguage(userPreferredLanguage, testId, sku);

    // Send the test id and sku to Datadog for better event tracking
    if (testId !== undefined && sku !== undefined) {
      datadogRum.setGlobalContextProperty('resultContext', {
        testId,
        sku,
      });
    }

    return () => {
      window?.Localize?.setLanguage(userPreferredLanguage);
    };
  }, [testId, sku]);

  useEffect(
    () => {
      if (!kitResult) {
        getKitResult({
          kitResultIdOrPublishHash,
          shared: viewingSharedKitResult,
        });
      } else {
        let hasROT = false;
        if (kitResult.marker_results && kitResult.marker_results.length) {
          const markerResult = kitResult.marker_results[0];
          hasROT = Boolean(
            markerResult.historical_results &&
              markerResult.historical_results.length > 1,
          );
        }

        // track kit result view
        analytics.track({
          event: ANALYTICS.EVENTS.VIEWED_PAGE,
          data: {
            page: viewingSharedKitResult
              ? ANALYTICS.PAGES.SHARED_KIT_RESULT
              : ANALYTICS.PAGES.KIT_RESULT,
            'Kit Result Number': kitResult.number,
            'Test ID': testId,
            'Test Name': kitResult.test && kitResult.test.name,
            'Discussion Available': isConsultAvailable,
            'Results over time offered': hasROT,
          },
        });
      }
    }, // happens only once. Using kitResult instead will fire two tracking events // testId - a property on kitResult.test - ensures analytics tracking
    // since content is loaded separately.
    [
      testId,
      getKitResult,
      isConsultAvailable,
      isWomensHealth,
      kitResult,
      kitResultIdOrPublishHash,
      viewingSharedKitResult,
    ],
  );

  // load personalizations
  useEffectOnce(() => {
    const loadPersonalizations = async () => {
      try {
        const personalizations: Personalizations = await getPersonalizations();
        setPersonalizations(personalizations.health_profile);
      } catch (err) {
        logError((err as Error).message, {
          errorInfo: 'Error fetchGraphql fetching Erroring personalizations',
          component: 'NewLayoutKitResultDetailPage',
          method: 'loadPersonalizations',
        });
      }
    };
    if (!viewingSharedKitResult) {
      loadPersonalizations();
    }
  });

  const [sectionSelected, setSectionSelected] = useState('');

  const useInViewOptions = {
    threshold: getIsVisibleThreshold(),
    fallbackInView: true,
    trackVisibility: true,
    delay: 100,
  } as IntersectionOptions;

  const [resultSectionRef, inResultsView] = useInView({
    ...useInViewOptions,
    initialInView: true,
  });
  const [nextStepSectionRef, inNextStepsView] = useInView(useInViewOptions);
  const [resourcesSectionRef, inResourcesView] = useInView(useInViewOptions);
  const [helpRef, inHelpView] = useInView(useInViewOptions);

  useEffect(() => {
    if (inResultsView) setSectionSelected(RESULT_SECTIONS_NAMES.RESULTS);
    if (inNextStepsView) setSectionSelected(RESULT_SECTIONS_NAMES.NEXT_STEPS);
    if (inResourcesView) setSectionSelected(RESULT_SECTIONS_NAMES.RESOURCES);
    if (inHelpView) setSectionSelected(RESULT_SECTIONS_NAMES.HELP);
  }, [inResultsView, inNextStepsView, inResourcesView, inHelpView]);

  // wait for kit result, test and content to load before rendering
  // shared results don't have content right now; let them pass for cypress
  if (
    !kitResult ||
    !kitResult.test ||
    (!viewingSharedKitResult && (!contentToken || !kitResult.test.content))
  ) {
    return null;
  }

  const {
    markers,
    marker_results: markerResults,
    severity_resolution: severityResolution,
    severity_resolutions: severityResolutions,
    genomics_result_status: genomicsResultStatus,
    number: kitNumber,
    first_name: firstName,
    test,
    lab,
    viewed_at,
    live_webinar_available = false,
  } = kitResult;
  const { name: testName, marker_types } = test;

  // get a snippet content for each section to verifiy if its section will exist;
  const {
    RESOURCES_SECTION_HEADER,
    WHATS_NEXT_SECTION_HEADER,
    HELP_SECTION_HEADER,
    PRODUCT_PROMO_ICON,
    PRODUCT_PROMO_DESCRIPTION_TITLE,
    PRODUCT_PROMO_DESCRIPTION,
  } = test?.content ?? {};

  const sectionsNames = [RESULT_SECTIONS_NAMES.RESULTS];

  // add the section name to navbar if it exist
  WHATS_NEXT_SECTION_HEADER !== null &&
    !isUndefined(WHATS_NEXT_SECTION_HEADER) &&
    sectionsNames.push(RESULT_SECTIONS_NAMES.NEXT_STEPS);
  RESOURCES_SECTION_HEADER !== null &&
    !isUndefined(RESOURCES_SECTION_HEADER) &&
    sectionsNames.push(RESULT_SECTIONS_NAMES.RESOURCES);
  HELP_SECTION_HEADER !== null &&
    !isUndefined(HELP_SECTION_HEADER) &&
    sectionsNames.push(RESULT_SECTIONS_NAMES.HELP);

  const isFSTest = TAKE_ACTION_ORDER_OVERRIDE.includes(testName || '');

  // determine consult availability based on whether the
  // result is shared and the info in kitResult.consult
  if (!viewingSharedKitResult && kitResult.consult) {
    const {
      consult: { available, available_until },
    } = kitResult;

    const currentUtcTime = new Date().toISOString();
    isConsultAvailable = available && currentUtcTime < available_until;
  }

  const shouldShowConsult = (isConsultAvailable && showConsult) || false;
  const shouldShowLiveWebinar =
    (!viewingSharedKitResult && live_webinar_available && showConsult) || false;
  const shouldShowWebinar =
    (!viewingSharedKitResult &&
      showConsult &&
      kitResult.test.recorded_webinar) ||
    false;
  const anyConsultAvailable =
    shouldShowLiveWebinar || shouldShowConsult || shouldShowWebinar;

  let groupCount = {};

  if (markers && markerResults) {
    const groupedMarkers = isFSTest
      ? groupFSMarkersBySeverity(markers, markerResults)
      : groupBySeverity(markers, markerResults);

    groupCount = Object.keys(groupedMarkers).reduce(
      (acc: { [key: number]: number }, severityValue: string) => {
        const severity = Number(severityValue);
        try {
          acc[severity] = groupedMarkers[severity].length;
        } catch (err) {
          logError((err as Error).message, {
            errorInfo: 'Grouped Markers severity not found',
            component: 'NewLayoutKitResultDetailPage',
            method: 'groupCount',
          });
        }
        return acc;
      },
      {},
    );
  }

  const controlGroups = _.uniqBy(
    _.flatten(_.map(markers, 'groupings')),
    'grouping_type',
  );

  if (!kitResult.test.marker_types || !kitResult.test.marker_types.length) {
    return null;
  }

  if (!kitResult || !kitResult.marker_results) {
    return null;
  }

  const modifiedKitResult: KitResult = { ...kitResult };
  modifiedKitResult.marker_results = [];

  const fsKr = JSON.parse(JSON.stringify(modifiedKitResult));
  const descriptiveKr = JSON.parse(JSON.stringify(modifiedKitResult));
  const intensityKr = JSON.parse(JSON.stringify(modifiedKitResult));
  const numericalKr = JSON.parse(JSON.stringify(modifiedKitResult));

  kitResult.marker_results.forEach((markerResult) => {
    if (markerResult.kind === MARKER_TYPES.FOOD_SENSITIVITY) {
      fsKr.marker_results.push(markerResult);
    } else if (markerResult.kind === MARKER_TYPES.DESCRIPTIVE) {
      descriptiveKr.marker_results.push(markerResult);
    } else if (markerResult.kind === MARKER_TYPES.INTENSITY) {
      intensityKr.marker_results.push(markerResult);
    } else {
      numericalKr.marker_results.push(markerResult);
    }
  });

  const resultDetails = [];
  if (fsKr.marker_results && fsKr.marker_results.length > 0) {
    resultDetails.push(
      <FoodSensitivityResultDetail
        kitResult={fsKr}
        showFsPersonalization={Boolean(showFsPersonalization)}
        key={fsKr.id}
      />,
    );
  }
  if (descriptiveKr.marker_results && descriptiveKr.marker_results.length > 0) {
    resultDetails.push(
      <DescriptiveResultDetailContainer
        kitResult={descriptiveKr}
        key={descriptiveKr.id}
      />,
    );
  }
  if (intensityKr.marker_results && intensityKr.marker_results.length > 0) {
    resultDetails.push(
      <IntensityResultDetail
        kitResult={intensityKr}
        controlGroups={controlGroups}
        key={intensityKr.id}
      />,
    );
  }
  if (numericalKr.marker_results && numericalKr.marker_results.length > 0) {
    resultDetails.push(
      <NumericalResultDetailContainer
        kitResult={numericalKr}
        viewingSharedKitResult={viewingSharedKitResult}
        key={numericalKr.id}
      />,
    );
  }

  if (!marker_types || !testName) return null;

  const labName = lab ? lab.name.split(',')[0].trim() : '';
  const clia = kitResult.clia ? kitResult.clia : '';

  if (test && test.content && test.content.SHOW_PRODUCT_UPSELL) {
    shouldShowUpsell =
      !viewingSharedKitResult &&
      test.content.SHOW_PRODUCT_UPSELL.toLowerCase() === 'true';
  }
  const shouldShowTakeAction =
    shouldShowUpsell || anyConsultAvailable || shouldShowPCPQuestions;
  const shouldShowTelehealthConsult: boolean = hasTelehealthConsult(kitResult);

  const introHeaderIcon = test.theme_icon
    ? `./images/${test.theme_icon}_full.svg`
    : './images/other_full.svg';
  const sectionHeaderIcon = test.theme_icon
    ? `./images/${test.theme_icon}_half.svg`
    : './images/other_half.svg';
  const imageIntroHeaderIcon = require(`${introHeaderIcon}`);
  const imageSectionHeaderIcon = require(`${sectionHeaderIcon}`);

  const showResultsPromoCard =
    showResultsPromoCardFlag &&
    !isEnterpriseCustomer() &&
    Boolean(PRODUCT_PROMO_ICON) &&
    Boolean(PRODUCT_PROMO_DESCRIPTION_TITLE) &&
    Boolean(PRODUCT_PROMO_DESCRIPTION);

  return (
    <Layout
      subHeader={
        !viewingSharedKitResult && (
          <SubNavbar
            testName={testName}
            kitResultNumber={kitNumber}
            testId={testId}
            data-test={KIT_RESULT_DETAIL.SUB_HEADER}
            shouldShowTelehealthConsult={shouldShowTelehealthConsult}
          />
        )
      }
    >
      <div>
        {viewingSharedKitResult && (
          <S.SharedResultInfo data-test={KIT_RESULT_DETAIL.SHARED_RESULT_INFO}>
            {`You're viewing a copy of ${firstName}'s ${testName} results`}
          </S.SharedResultInfo>
        )}
        {isAncestryTest && !viewingSharedKitResult && (
          <S.BannerWrapper
            data-test={COBRANDED_ANCESTRY_BANNER.BANNER_CONTAINER}
          >
            <AncestryBannerContainer
              testId={testId || 0}
              contentToken={contentToken || ''}
            />
          </S.BannerWrapper>
        )}
        {contentToken && (
          <ResultsIntro
            contentToken={contentToken}
            markerTypes={marker_types}
            userName={firstName || 'Everly'}
            resultsApprovedDate={kitResult.results_approved_at || 'unknown'}
            severityResolution={severityResolution}
            severityResolutions={severityResolutions}
            groupCount={groupCount}
            testId={testId || 0}
            testName={testName}
            testType={kitResult.test.type}
            viewingSharedResult={viewingSharedKitResult}
            consultAvailable={anyConsultAvailable}
            isThirdParty={isHumana}
            labName={labName}
            clia={clia}
            conditions={conditions}
            headerIcon={imageIntroHeaderIcon}
          />
        )}
        {kitResult.marker_replacement &&
        Object.keys(kitResult.marker_replacement).length > 0 ? (
          <MarkerReplacementSection
            kitResult={kitResult}
          ></MarkerReplacementSection>
        ) : null}
        <SectionsNavBar
          sectionsNames={sectionsNames}
          sectionSelected={sectionSelected}
        />
        <S.Sections rounded={true}>
          {/*
             !!!! First Section: Results !!!!
             All test result content including FAQ tooltips
         */}
          {contentToken ? (
            <ResultDetailSection
              sectionName="Results"
              reference={resultSectionRef}
            >
              {
                <ResultSection
                  defaultContent={{
                    header: 'Your test results',
                    description: '',
                  }}
                  onMissingContent={() => {
                    logError(`Missing content for kit: ${kitResult.number}`, {
                      errorInfo:
                        'header or description no present for results sections',
                      component: 'NewLayoutKitResultDetailPage',
                      method: 'ResultSection',
                    });
                  }}
                  contentToken={contentToken}
                  headerIcon={imageSectionHeaderIcon}
                />
              }
              {showFsPersonalization ? (
                <BeforeYouDiveInSection
                  viewedAt={viewed_at}
                  conditions={conditions}
                />
              ) : null}
              <Col
                lg={10}
                lgOffset={1}
                md={10}
                mdOffset={1}
                xs={12}
                data-test="marker_results"
              >
                {resultDetails.map((details) => details)}
                {genomicsResultStatus && !viewingSharedKitResult && (
                  <GenomicsResultDetail
                    status={genomicsResultStatus}
                    krNumber={kitResultIdOrPublishHash}
                  />
                )}
              </Col>
              {!viewingSharedKitResult && (
                <S.SavePDFSection>
                  <SavePdfButton
                    kitResult={kitResult}
                    key={DATA_TEST.SAVE_PDF_BUTTON}
                  />
                </S.SavePDFSection>
              )}
            </ResultDetailSection>
          ) : null}
          {/*
           !!!! Second Section: Next Steps !!!!
           - “Decide What’s Next” header (WHATS_NEXT)
           - Consider some changes
           - Take Action
         */}
          {WHATS_NEXT_SECTION_HEADER && contentToken ? (
            <ResultDetailSection
              sectionName="Next Steps"
              reference={nextStepSectionRef}
            >
              <WhatsNextSection
                contentToken={contentToken}
                headerIcon={imageSectionHeaderIcon}
              />
              {showResultsPromoCard && (
                <S.PromoWrapper>
                  <ProductPromo
                    contentToken={contentToken}
                    kitResultId={String(kitResult.id)}
                  />
                </S.PromoWrapper>
              )}
              <ConsiderChangesSection contentToken={contentToken} />
              {shouldShowTakeAction ? (
                <TakeActionSection
                  contentToken={contentToken}
                  kitResultId={kitResult.number}
                  showConsult={shouldShowConsult}
                  showLiveWebinar={shouldShowLiveWebinar}
                  showUpsell={shouldShowUpsell}
                  shouldShowPCPQuestions={shouldShowPCPQuestions}
                  availableUntil={
                    kitResult.consult && kitResult.consult.available_until
                  }
                  pwnOrderNumber={kitResult.pwn_order_number}
                  pwnConfirmationNumber={kitResult.pwn_confirmation_code}
                  testDisplayName={testName}
                  testType={kitResult.test.type}
                  shouldShowTelehealthConsult={shouldShowTelehealthConsult}
                  pcp={personalizations.pcp}
                  isWebinarEnabledForTest={shouldShowWebinar}
                />
              ) : null}
            </ResultDetailSection>
          ) : null}
          {/*
          !!!! Third Section: Resources !!!!
          - Learning Resources
          - Test-level Resource Links
          - FAQs (COMMON_QUESTIONS)
        */}
          {RESOURCES_SECTION_HEADER && contentToken ? (
            <ResultDetailSection
              sectionName="Resources"
              reference={resourcesSectionRef}
            >
              <ResourcesLinksSection
                contentToken={contentToken}
                headerIcon={imageSectionHeaderIcon}
              />
              <CommonQuestionsSection
                contentToken={contentToken}
                kitResult={kitResult}
              />
            </ResultDetailSection>
          ) : null}

          {/*
          !!!! Fourth Section: GetHelp !!!!
          - GetHelp Section
        */}
          {HELP_SECTION_HEADER && contentToken ? (
            <ResultDetailSection sectionName="Help" reference={helpRef}>
              <GetHelpSection
                contentToken={contentToken}
                headerIcon={imageSectionHeaderIcon}
              />
            </ResultDetailSection>
          ) : null}
        </S.Sections>

        {isAncestryTest && !viewingSharedKitResult && (
          <AncestryFooter isNewResultsLayout testId={kitResult.test.id || 0} />
        )}
      </div>
    </Layout>
  );
}

export default NewLayoutKitResultDetailPage;
