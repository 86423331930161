import { Card } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import React from 'react';

import * as S from './styles';

export type Props = {
  name: string;
  description: string;
  link: string;
  image: string;
  logo: string;
  number: number;
};

function ResourceLinkCard(props: Props) {
  const { name, description, link, image, logo, number } = props;

  const fireAnalyticsClickEvent = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: number
          ? `${ANALYTICS.LABELS.RESOURCE_LINK_BUTTON} ${number}`
          : ANALYTICS.LABELS.RESOURCE_LINK_BUTTON,
        category: ANALYTICS.CATEGORIES.RESOURCES_LINKS,
        name,
        link,
      },
    });
  };

  return (
    <S.Wrapper
      onClick={fireAnalyticsClickEvent}
      onContextMenu={fireAnalyticsClickEvent}
    >
      <Card.Article
        image={image}
        title={name}
        copy={description}
        url={link}
        imageAlt={name}
      />
      {logo && (
        <S.LogoWrapper>
          <S.Logo src={logo} />
        </S.LogoWrapper>
      )}
    </S.Wrapper>
  );
}

export default ResourceLinkCard;
