import { COVID_SCREENER_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';
import { COVID_SCREENER_STEPS } from 'common/utils/constants/rumActions';
import { isDateValid, useFormattedDate } from 'common/utils/helpers';
import { InputError } from 'common/utils/types';
import useTimeTracker from 'common/utils/useTimeTracker';
import { BaseModal } from 'components/KitRegistration/styles';
import React, { useState, useEffect } from 'react';

import * as S from '../../KitIDModal/styles';
import { NextBtn, InputDate } from './styles';

interface Props {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  value: string;
  setValue: (value: string) => void;
  setDateIsValid: (value: boolean) => void;
}
const SymptomsDateModal = ({
  openModal,
  setOpenModal,
  value,
  setValue,
  setDateIsValid,
}: Props) => {
  const [error, setError] = useState<InputError>('');
  const formatDate = useFormattedDate();
  const fireAction = useTimeTracker(COVID_SCREENER_STEPS.DATE_OF_SYMPTOMS);
  const handleModalClose = () => {
    if (error) {
      setValue('');
    }
    setOpenModal(false);
  };
  const handleInput = (value: string) => {
    const formattedSymptomsDate = formatDate(value);
    const error: InputError = isDateValid(
      formattedSymptomsDate,
      new Date(2019, 10, 30),
    );
    setDateIsValid(!error);
    setError(error);
    setValue(formattedSymptomsDate);
  };
  useEffect(() => {
    if (value) {
      const error: InputError = isDateValid(value, new Date(2019, 10, 30));
      setDateIsValid(!error);
      setError(error);
    }
  }, []);
  const handleClick = () => {
    fireAction();
    setOpenModal(false);
  };

  return (
    <BaseModal
      open={openModal}
      openHandler={handleModalClose}
      data-test={DATA_TEST.MODAL}
    >
      <S.Title>What day did your symptoms first begin?</S.Title>
      <InputDate
        inputClassName="inspectletIgnore"
        value={value}
        onChange={(e: { target: { value: string } }) => {
          handleInput(e.target.value);
        }}
        error={error}
        type="text"
        id="symptoms-date"
        name="symptom date"
        label="Date (MM/DD/YYYY)"
        placeholder="MM/DD/YYYY"
        data-test={`${DATA_TEST.INPUT}-symptoms-date`}
      />
      <NextBtn
        appearance="primary"
        isDisabled={!!error}
        onClick={handleClick}
        data-test={`${DATA_TEST.BUTTON}-next-modal`}
      >
        Next
      </NextBtn>
    </BaseModal>
  );
};

export default SymptomsDateModal;
