import { dayOfWeek } from 'common/utils/date.constants';
import { getDate, getDay, format } from 'date-fns';
import React from 'react';

import * as S from './DateCard.styles';

export type DateCardProps = {
  date: Date;
  isSelected: boolean;
  onClick: () => void;
};

function DateCard({ date, isSelected, onClick }: DateCardProps) {
  const weekdayNumber = getDay(date);
  const weekday = dayOfWeek[weekdayNumber];
  const dateNumber = getDate(date);

  return (
    <S.Container
      data-test="date-card"
      onClick={onClick}
      isDateSelected={isSelected}
      aria-pressed={isSelected}
      aria-label={format(date, 'PPPP')} // Friday, April 29th, 1453
      type="button"
    >
      <S.Number>{dateNumber}</S.Number>
      <S.WeekDay>{weekday?.substring(0, 3)}</S.WeekDay>
    </S.Container>
  );
}

export default DateCard;
