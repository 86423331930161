import { size, mediaQueries, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const IntroWrapper = styled.div`
  max-width: 532px;
  padding: 0 ${size.md}px;
  margin: ${size.xl5}px auto;

  ${mediaQueries.forTabletVerticalUp} {
    margin: ${size.xl8}px auto;
  }
`;

const QuestionWrapper = styled.div`
  margin: ${size.xl5}px auto;
  max-width: 532px;
  padding: 0 ${size.md}px;
`;

const Pagination = styled.p`
  ${typography.overlineText}
`;

export { IntroWrapper, QuestionWrapper, Pagination };
