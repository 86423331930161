import {
  size,
  colors,
  typography,
  mediaQueries,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Title = styled.h2`
  ${typography.buttonText};

  color: ${colors.teal5};
  margin: 0 0 ${size.xs1}px 0;
`;

export const TaskListWrapper = styled.div`
  margin-bottom: ${size.xl1}px;
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    margin-bottom: ${size.xl2}px;
  }
`;