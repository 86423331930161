import { TabMenu as LeavesTabMenu } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;

  /* Needed to enable scrolling. If this is not set, the tabs will overflow the container */
  > div {
    width: 100%;
  }
`;

export const TabMenu = styled(LeavesTabMenu)`
  ul {
    padding: 0;
    margin: 0;
  }
`;
