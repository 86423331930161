import {
  RESULTS_DISCUSSION,
  SCROLL_TO_TELEHEALTH,
} from 'common/utils/constants';
import { TAKE_ACTION } from 'common/utils/constants/dataTest';
import { jumpToAnchor } from 'common/utils/helpers';
import { ImmutableReduxState } from 'common/utils/types';
import ApolloContainer from 'containers/ApolloContainer';
import TakeActionSectionHeader from 'containers/TakeActionSection/HeaderContainer';
import ProductUpsell from 'containers/TakeActionSection/ProductUpsell';
import ResultsDiscussion from 'containers/TakeActionSection/ResultsDiscussion';
import Webinar, { CONTENT_KEYS } from 'containers/TakeActionSection/Webinar';
import { isUndefined } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeSelectContent } from 'store/selectors';

import shieldIcon from './images/icons-shield.svg';
import PCPQuestions from './PCPQuestions';
import ScheduleConsult from './ScheduleConsult';
import * as S from './styles';

type StateProps = {
  isWebinarAvailable: boolean;
};

type OwnProps = {
  contentToken: string;
  kitResultId: string;
  showConsult: boolean;
  showLiveWebinar: boolean;
  showUpsell: boolean;
  shouldShowPCPQuestions: boolean;
  availableUntil: string | undefined;
  pwnOrderNumber: number | undefined;
  pwnConfirmationNumber: number | undefined;
  testDisplayName: string;
  testType?: string;
  shouldShowTelehealthConsult?: boolean;
  pcp: boolean | null;
  isWebinarEnabledForTest: boolean;
};

type Props = OwnProps & StateProps;

export function TakeActionSection(props: Props) {
  const {
    contentToken,
    kitResultId,
    showConsult,
    showLiveWebinar,
    showUpsell,
    shouldShowPCPQuestions,
    availableUntil,
    pwnOrderNumber,
    pwnConfirmationNumber,
    testDisplayName,
    testType,
    shouldShowTelehealthConsult,
    pcp,
  } = props;

  const showSchedulePwnConsult = showConsult && shouldShowTelehealthConsult;

  const showWebinar = props.isWebinarAvailable && props.isWebinarEnabledForTest;

  useEffect(() => {
    // detects an anchor in the url and jumps to the section if the anchor
    // corresponds to an id of a section
    const scrollTimer = jumpToAnchor('TakeActionSection', 'jumpToAnchor');

    return () => clearTimeout(scrollTimer);
  }, []);

  return (
    <ApolloContainer>
      <div id={SCROLL_TO_TELEHEALTH}>
        <S.TakeActionWrapper
          data-test={TAKE_ACTION.SECTION}
          id={RESULTS_DISCUSSION}
        >
          <div>
            <S.Col10Wrapper>
              <TakeActionSectionHeader contentToken={contentToken} />
              {shouldShowPCPQuestions && (
                <PCPQuestions
                  testType={testType}
                  kitResultId={kitResultId}
                  pcp={pcp as boolean}
                />
              )}
            </S.Col10Wrapper>
            <S.Col10Wrapper>
              {showSchedulePwnConsult ? (
                <ScheduleConsult
                  header="Speak with a physician."
                  description="Receive a call from a board-certified, licensed physician within four business hours of requesting a telehealth consultation to discuss your results at no additional cost."
                  date={availableUntil}
                  pwnOrderNumber={pwnOrderNumber}
                  pwnConfirmationNumber={pwnConfirmationNumber}
                  testName={testDisplayName}
                  iconSrc={shieldIcon}
                  alt="Schedule Consult Icon"
                  tooltip={{
                    header: 'Speak with a Healthcare Pro!',
                    description:
                      'You will be able to book your discussion within 14 days of receiving your results, and can have your discussion any time within 30 days of receiving your results!',
                  }}
                />
              ) : null}
              {showLiveWebinar && (
                <ResultsDiscussion
                  contentToken={contentToken}
                  kitResultId={kitResultId}
                />
              )}
              {showWebinar && (
                <Webinar
                  contentToken={contentToken}
                  kitResultId={kitResultId}
                />
              )}
              {showUpsell && (
                <ProductUpsell
                  contentToken={contentToken}
                  kitResultId={kitResultId}
                />
              )}
            </S.Col10Wrapper>
          </div>
        </S.TakeActionWrapper>
      </div>
    </ApolloContainer>
  );
}

const mapStateToProps = (
  state: ImmutableReduxState,
  ownProps: OwnProps,
): StateProps => ({
  isWebinarAvailable: !Object.values(
    makeSelectContent(ownProps.contentToken, CONTENT_KEYS)(state),
  ).some((value) => isUndefined(value)),
});

const connector = connect(mapStateToProps, {});

export default connector(TakeActionSection);
