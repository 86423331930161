import { Col, Container, Row } from '@everlywell/leaves';
import WelcomeToProgramPage from 'pages/AccountHub/pages/WelcomeToProgramPage';
import { WM_FULL_PROGRAM_SLUG } from 'pages/InterstitialPage';
import React, { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { IntakeStep } from '..';

import { INTAKE, INTAKE_STEPS } from '../constants';
import { IntakeContext } from '../context';

type Props = {
  steps: IntakeStep[];
};

export const IntakeSteps = ({ steps }: Props) => {
  const { isIntakeComplete, step, setIntakeStep } = useContext(IntakeContext);
  const [searchParams] = useSearchParams();

  const program = searchParams.get('program') || WM_FULL_PROGRAM_SLUG;
  const currentStep = steps && steps[step];
  const Step = currentStep.component;
  /** Commented this logic out for now as it may be needed in the future
   * when the Weight Management flow starts utilizing the progress bar
   */
  // const intakeSteps = steps.map((step) => step.label);
  // const progressBarCurrentStep =
  //   currentStep.value === 0 ? 0 : currentStep.value;

  useEffectOnce(() => {
    setIntakeStep && setIntakeStep(INTAKE_STEPS.shipping.value);
  });

  return (
    <Container data-testid={INTAKE.INTAKE_STEPS_CONTAINER}>
      <Container>
        {/* Commented this logic out for now as it may be needed in the future
        when the Weight Management flow starts utilizing the progress bar */}
        {/* {!isIntakeComplete ? (
          <S.Steps>
            <ProgressBar
              steps={intakeSteps}
              currentStep={progressBarCurrentStep}
              goToStep={setIntakeStep}
              isDisabled
              hasTopLabels
            />
          </S.Steps>
        ) : null} */}
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            {!isIntakeComplete ? (
              <>{Step && <Step {...currentStep} program={program} />}</>
            ) : (
              <WelcomeToProgramPage program={program}></WelcomeToProgramPage>
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
export default IntakeSteps;
