const SLUGS = {
  DEMOGRAPHICS_FORM_ROUTE: '/virtual-care/demographics',
  DYNAMIC_FORM_ROUTE: '/virtual-intake',
  FALLBACK_PROGRAM_SLUG: 'virtual-care-visit',
  STI_ON_DEMAND_PROGRAM_SLUG: 'sti-on-demand',
  STI_VIRTUAL_CARE_PROGRAM_SLUG: 'sti-virtual-care-visit',
  VIRTUAL_CARE_VISIT: 'virtual-care-visit',
  WM_FULL_PROGRAM_SLUG: 'weight-management-full',
  WM_PROGRAM_SLUG: 'weight-management',
};

export default SLUGS;
