import { COBRANDED_BANNER_COLORS } from 'common/utils/constants';
import { COBRANDED_ANCESTRY_BANNER } from 'common/utils/constants/dataTest';
import React from 'react';

import cobrandedAncestryLogo from './images/cobranded-ancestry-logo.svg';
import * as S from './styles';

export type Props = {
  testId: number;
  content: string;
};

const AncestryBanner = (props: Props) => {
  const { testId, content } = props;

  return (
    <S.Wrapper color={COBRANDED_BANNER_COLORS[testId]}>
      <S.GridWrapper>
        <S.InnerContentWrapper>
          <S.CobrandedIcon data-test={COBRANDED_ANCESTRY_BANNER.BANNER_LOGO}>
            <img
              src={cobrandedAncestryLogo}
              alt="AncestryHealth and Everlywell cobranded logo"
            />
          </S.CobrandedIcon>
          <S.ContentWrapper
            data-test={COBRANDED_ANCESTRY_BANNER.BANNER_CONTENT}
          >
            {content}
          </S.ContentWrapper>
        </S.InnerContentWrapper>
      </S.GridWrapper>
    </S.Wrapper>
  );
};

export default AncestryBanner;
