// Search for "Adding food classifications" in Confluence for more info about this file.
type FoodClassifications = {
  [key: string]: {
    [key: string]: string;
  };
};

const GROUPS = {
  DAIRY: 'dairy',
  FRUITS: 'fruits',
  EGGS: 'eggs',
  GRAINS: 'grains',
  LEGUMES: 'legumes',
  MEAT: 'meat',
  SEAFOOD: 'seafood',
  SEEDS_AND_NUTS: 'seedsAndNuts',
  SPICE: 'spice',
  VEGETABLES: 'vegetables',
};

export const FOOD_CLASSIFICATIONS: FoodClassifications = {
  ALLETESS: {
    // fruits
    APPLE: GROUPS.FRUITS,
    BANANA: GROUPS.FRUITS,
    BLUEBERRY: GROUPS.FRUITS,
    CANTALOUPE: GROUPS.FRUITS,
    COCONUT: GROUPS.FRUITS,
    GRAPE: GROUPS.FRUITS,
    GRAPEFRUIT: GROUPS.FRUITS,
    LEMON: GROUPS.FRUITS,
    ORANGE: GROUPS.FRUITS,
    PEACH: GROUPS.FRUITS,
    PEAR: GROUPS.FRUITS,
    PINEAPPLE: GROUPS.FRUITS,
    STRAWBERRY: GROUPS.FRUITS,
    TOMATO: GROUPS.FRUITS,
    WATERMELON: GROUPS.FRUITS,
    APRICOT: GROUPS.FRUITS,
    BLACKBERRY: GROUPS.FRUITS,
    CHERRY: GROUPS.FRUITS,
    CRANBERRY: GROUPS.FRUITS,
    DATE: GROUPS.FRUITS,
    FIG: GROUPS.FRUITS,
    KIWI: GROUPS.FRUITS,
    LIME: GROUPS.FRUITS,
    MANGO: GROUPS.FRUITS,
    'MELON, HONEYDEW': GROUPS.FRUITS,
    PAPAYA: GROUPS.FRUITS,
    PLUM: GROUPS.FRUITS,
    RASPBERRY: GROUPS.FRUITS,
    RHUBARB: GROUPS.FRUITS,
    TANGERINE: GROUPS.FRUITS,

    // spice
    'PEPPER, BLACK': GROUPS.SPICE,
    'BAY LEAF': GROUPS.SPICE,
    COCOA: GROUPS.SPICE,
    COFFEE: GROUPS.SPICE,
    COLA: GROUPS.SPICE,
    CINNAMON: GROUPS.SPICE,
    DILL: GROUPS.SPICE,
    GARLIC: GROUPS.SPICE,
    GINGER: GROUPS.SPICE,
    HONEY: GROUPS.SPICE,
    MUSTARD: GROUPS.SPICE,
    OREGANO: GROUPS.SPICE,
    TARRAGON: GROUPS.SPICE,
    'TEA, BLACK': GROUPS.SPICE,
    CILANTRO: GROUPS.SPICE,
    CLOVES: GROUPS.SPICE,
    CAROB: GROUPS.SPICE,
    FENNEL: GROUPS.SPICE,
    GINSENG: GROUPS.SPICE,
    HORSERADISH: GROUPS.SPICE,
    LICORICE: GROUPS.SPICE,
    NUTMEG: GROUPS.SPICE,
    PAPRIKA: GROUPS.SPICE,
    PARSLEY: GROUPS.SPICE,
    PEPPERMINT: GROUPS.SPICE,
    ROSEMARY: GROUPS.SPICE,
    SAGE: GROUPS.SPICE,
    'TEA, GREEN': GROUPS.SPICE,
    THYME: GROUPS.SPICE,
    TURMERIC: GROUPS.SPICE,
    'VANILLA BEAN': GROUPS.SPICE,

    // seafood
    CLAM: GROUPS.SEAFOOD,
    CODFISH: GROUPS.SEAFOOD,
    CRAB: GROUPS.SEAFOOD,
    HADDOCK: GROUPS.SEAFOOD,
    LOBSTER: GROUPS.SEAFOOD,
    PRAWN: GROUPS.SEAFOOD,
    SALMON: GROUPS.SEAFOOD,
    SCALLOP: GROUPS.SEAFOOD,
    SHRIMP: GROUPS.SEAFOOD,
    SOLE: GROUPS.SEAFOOD,
    SWORDFISH: GROUPS.SEAFOOD,
    TUNA: GROUPS.SEAFOOD,
    ANCHOVY: GROUPS.SEAFOOD,
    'BASS, BLACK': GROUPS.SEAFOOD,
    FLOUNDER: GROUPS.SEAFOOD,
    HALIBUT: GROUPS.SEAFOOD,
    HERRING: GROUPS.SEAFOOD,
    MACKEREL: GROUPS.SEAFOOD,
    MUSSELS: GROUPS.SEAFOOD,
    OYSTER: GROUPS.SEAFOOD,
    'PERCH, SEA': GROUPS.SEAFOOD,
    'PIKE, WALLEYE': GROUPS.SEAFOOD,
    'RED SNAPPER': GROUPS.SEAFOOD,
    SQUID: GROUPS.SEAFOOD,
    TROUT: GROUPS.SEAFOOD,

    // meat
    BEEF: GROUPS.MEAT,
    CHICKEN: GROUPS.MEAT,
    LAMB: GROUPS.MEAT,
    PORK: GROUPS.MEAT,
    TURKEY: GROUPS.MEAT,
    BUFFALO: GROUPS.MEAT,
    'DEER (VENISON)': GROUPS.MEAT,
    DUCK: GROUPS.MEAT,

    // dairy
    'CHEESE, CHEDDAR': GROUPS.DAIRY,
    'CHEESE, COTTAGE': GROUPS.DAIRY,
    'EGG WHITE': GROUPS.DAIRY,
    'EGG YOLK': GROUPS.DAIRY,
    'MILK, COWS': GROUPS.DAIRY,
    'CHEESE, MOZZARELLA': GROUPS.DAIRY,
    YOGURT: GROUPS.DAIRY,
    CASEIN: GROUPS.DAIRY,
    'CHEESE, BLUE': GROUPS.DAIRY,
    'CHEESE, SWISS': GROUPS.DAIRY,
    "MILK, GOAT'S": GROUPS.DAIRY,
    "MILK, SHEEP'S": GROUPS.DAIRY,
    WHEY: GROUPS.DAIRY,

    // grains
    'YEAST, BAKERS': GROUPS.GRAINS,
    BARLEY: GROUPS.GRAINS,
    BRAN: GROUPS.GRAINS,
    'YEAST, BREWERS': GROUPS.GRAINS,
    'RICE, BROWN': GROUPS.GRAINS,
    GLUTEN: GROUPS.GRAINS,
    MALT: GROUPS.GRAINS,
    OATS: GROUPS.GRAINS,
    RYE: GROUPS.GRAINS,
    WHEAT: GROUPS.GRAINS,
    AMARANTH: GROUPS.GRAINS,
    ARROWROOT: GROUPS.GRAINS,
    BUCKWHEAT: GROUPS.GRAINS,
    HOPS: GROUPS.GRAINS,
    MILLET: GROUPS.GRAINS,
    QUINOA: GROUPS.GRAINS,
    SORGHUM: GROUPS.GRAINS,
    TAPIOCA: GROUPS.GRAINS,
    TEFF: GROUPS.GRAINS,

    // seeds and nuts:
    ALMOND: GROUPS.SEEDS_AND_NUTS,
    CASHEW: GROUPS.SEEDS_AND_NUTS,
    'CHIA SEED': GROUPS.SEEDS_AND_NUTS,
    PEANUT: GROUPS.SEEDS_AND_NUTS,
    SAFFLOWER: GROUPS.SEEDS_AND_NUTS,
    SESAME: GROUPS.SEEDS_AND_NUTS,
    SUNFLOWER: GROUPS.SEEDS_AND_NUTS,
    'WALNUT, BLACK': GROUPS.SEEDS_AND_NUTS,
    'BRAZIL NUT': GROUPS.SEEDS_AND_NUTS,
    CHESTNUT: GROUPS.SEEDS_AND_NUTS,
    CANOLA: GROUPS.SEEDS_AND_NUTS,
    FLAXSEED: GROUPS.SEEDS_AND_NUTS,
    'HAZELNUT (FILBERT)': GROUPS.SEEDS_AND_NUTS,
    'HEMP SEED': GROUPS.SEEDS_AND_NUTS,
    MACADAMIA: GROUPS.SEEDS_AND_NUTS,
    PECAN: GROUPS.SEEDS_AND_NUTS,
    'PINE NUT': GROUPS.SEEDS_AND_NUTS,
    PISTACHIO: GROUPS.SEEDS_AND_NUTS,
    'POPPY SEED': GROUPS.SEEDS_AND_NUTS,

    // vegetables
    ASPARAGUS: GROUPS.VEGETABLES,
    AVOCADO: GROUPS.VEGETABLES,
    BASIL: GROUPS.VEGETABLES,
    'PEPPER, BELL': GROUPS.VEGETABLES,
    BROCCOLI: GROUPS.VEGETABLES,
    CABBAGE: GROUPS.VEGETABLES,
    CARROT: GROUPS.VEGETABLES,
    CAULIFLOWER: GROUPS.VEGETABLES,
    CELERY: GROUPS.VEGETABLES,
    CORN: GROUPS.VEGETABLES,
    CUCUMBER: GROUPS.VEGETABLES,
    EGGPLANT: GROUPS.VEGETABLES,
    'BEAN, GREEN': GROUPS.VEGETABLES,
    'OLIVE, GREEN': GROUPS.VEGETABLES,
    'PEA, GREEN': GROUPS.VEGETABLES,
    KALE: GROUPS.VEGETABLES,
    KELP: GROUPS.VEGETABLES,
    LETTUCE: GROUPS.VEGETABLES,
    'BEAN, LIMA': GROUPS.VEGETABLES,
    MUSHROOM: GROUPS.VEGETABLES,
    ONION: GROUPS.VEGETABLES,
    SOYBEAN: GROUPS.VEGETABLES,
    SPINACH: GROUPS.VEGETABLES,
    SQUASH: GROUPS.VEGETABLES,
    'POTATO, SWEET': GROUPS.VEGETABLES,
    'POTATO, WHITE': GROUPS.VEGETABLES,
    ARTICHOKE: GROUPS.VEGETABLES,
    'BEAN, KIDNEY': GROUPS.VEGETABLES,
    'BEET, RED': GROUPS.VEGETABLES,
    'BRUSSELS SPROUT': GROUPS.VEGETABLES,
    'CHICKPEA (GARBANZO)': GROUPS.VEGETABLES,
    LENTIL: GROUPS.VEGETABLES,
    'NAVY BEAN': GROUPS.VEGETABLES,
    OKRA: GROUPS.VEGETABLES,
    PARSNIP: GROUPS.VEGETABLES,
    'PEA, BLACK-EYED': GROUPS.VEGETABLES,
    PUMPKIN: GROUPS.VEGETABLES,
    RADISH: GROUPS.VEGETABLES,
    TURNIP: GROUPS.VEGETABLES,
    ZUCCHINI: GROUPS.VEGETABLES,
  },
  PKI: {
    // fruits
    APPLE: GROUPS.FRUITS,
    AVOCADO: GROUPS.FRUITS,
    BANANA: GROUPS.FRUITS,
    BLUEBERRY: GROUPS.FRUITS,
    CANTALOUPE: GROUPS.FRUITS,
    COCONUT: GROUPS.FRUITS,
    GRAPE: GROUPS.FRUITS,
    GRAPEFRUIT: GROUPS.FRUITS,
    LEMON: GROUPS.FRUITS,
    ORANGE: GROUPS.FRUITS,
    PEACH: GROUPS.FRUITS,
    PEAR: GROUPS.FRUITS,
    PINEAPPLE: GROUPS.FRUITS,
    STRAWBERRY: GROUPS.FRUITS,
    TOMATO: GROUPS.FRUITS,
    WATERMELON: GROUPS.FRUITS,
    APRICOT: GROUPS.FRUITS,
    'BLACK CURRANT': GROUPS.FRUITS,
    BLACKBERRY: GROUPS.FRUITS,
    CHERRY: GROUPS.FRUITS,
    CRANBERRY: GROUPS.FRUITS,
    DATE: GROUPS.FRUITS,
    FIG: GROUPS.FRUITS,
    GOOSEBERRY: GROUPS.FRUITS,
    'HONEYDEW MELON': GROUPS.FRUITS,
    'KEY LIME': GROUPS.FRUITS,
    KIWI: GROUPS.FRUITS,
    LYCHEE: GROUPS.FRUITS,
    MANGO: GROUPS.FRUITS,
    NECTARINE: GROUPS.FRUITS,
    PAPAYA: GROUPS.FRUITS,
    PLUM: GROUPS.FRUITS,
    POMEGRANATE: GROUPS.FRUITS,
    RAISIN: GROUPS.FRUITS,
    RASPBERRY: GROUPS.FRUITS,

    // spice
    BASIL: GROUPS.SPICE,
    'BAY LEAF': GROUPS.SPICE,
    'PEPPER, BLACK': GROUPS.SPICE,
    'BLACK PEPPER': GROUPS.SPICE,
    'TEA, BLACK': GROUPS.SPICE,
    'BLACK TEA': GROUPS.SPICE,
    COCOA: GROUPS.SPICE,
    COFFEE: GROUPS.SPICE,
    COLA: GROUPS.SPICE,
    CINNAMON: GROUPS.SPICE,
    DILL: GROUPS.SPICE,
    GARLIC: GROUPS.SPICE,
    GINGER: GROUPS.SPICE,
    HONEY: GROUPS.SPICE,
    MUSTARD: GROUPS.SPICE,
    'MUSTARD SEED': GROUPS.SPICE,
    OREGANO: GROUPS.SPICE,
    TARRAGON: GROUPS.SPICE,
    'ALOE VERA': GROUPS.SPICE,
    ANISE: GROUPS.SPICE,
    'BAKING POWDER': GROUPS.SPICE,
    CAPER: GROUPS.SPICE,
    'CAYENNE PEPPER': GROUPS.SPICE,
    CHAMOMILE: GROUPS.SPICE,
    CHICORY: GROUPS.SPICE,
    'CHILI PEPPER': GROUPS.SPICE,
    CILANTRO: GROUPS.SPICE,
    CLOVES: GROUPS.SPICE,
    CUMIN: GROUPS.SPICE,
    CURRY: GROUPS.SPICE,
    FENNEL: GROUPS.SPICE,
    HOPS: GROUPS.SPICE,
    HORSERADISH: GROUPS.SPICE,
    'LICORICE ROOT': GROUPS.SPICE,
    MARJORAM: GROUPS.SPICE,
    MINT: GROUPS.SPICE,
    NUTMEG: GROUPS.SPICE,
    PARSLEY: GROUPS.SPICE,
    PEPPERMINT: GROUPS.SPICE,
    ROSEMARY: GROUPS.SPICE,
    SAFFRON: GROUPS.SPICE,
    SAGE: GROUPS.SPICE,
    THYME: GROUPS.SPICE,
    VANILLA: GROUPS.SPICE,

    // seafood
    CLAM: GROUPS.SEAFOOD,
    CODFISH: GROUPS.SEAFOOD,
    CRAB: GROUPS.SEAFOOD,
    HADDOCK: GROUPS.SEAFOOD,
    LOBSTER: GROUPS.SEAFOOD,
    PRAWN: GROUPS.SEAFOOD,
    SALMON: GROUPS.SEAFOOD,
    SCALLOP: GROUPS.SEAFOOD,
    SHRIMP: GROUPS.SEAFOOD,
    SOLE: GROUPS.SEAFOOD,
    SWORDFISH: GROUPS.SEAFOOD,
    TUNA: GROUPS.SEAFOOD,
    ANCHOVY: GROUPS.SEAFOOD,
    BRANZINO: GROUPS.SEAFOOD,
    CARP: GROUPS.SEAFOOD,
    CRAYFISH: GROUPS.SEAFOOD,
    EEL: GROUPS.SEAFOOD,
    HERRING: GROUPS.SEAFOOD,
    MACKEREL: GROUPS.SEAFOOD,
    OCTOPUS: GROUPS.SEAFOOD,
    'PACIFIC OYSTER': GROUPS.SEAFOOD,
    PERCH: GROUPS.SEAFOOD,
    PIKE: GROUPS.SEAFOOD,
    SQUID: GROUPS.SEAFOOD,
    TROUT: GROUPS.SEAFOOD,

    // meat
    BEEF: GROUPS.MEAT,
    CHICKEN: GROUPS.MEAT,
    LAMB: GROUPS.MEAT,
    PORK: GROUPS.MEAT,
    TURKEY: GROUPS.MEAT,
    DUCK: GROUPS.MEAT,
    QUAIL: GROUPS.MEAT,

    // dairy
    'CHEESE, CHEDDAR': GROUPS.DAIRY,
    'CHEDDAR CHEESE': GROUPS.DAIRY,
    'CHEESE, COTTAGE': GROUPS.DAIRY,
    'COTTAGE CHEESE': GROUPS.DAIRY,
    'MILK, COWS': GROUPS.DAIRY,
    'COW MILK': GROUPS.DAIRY,
    "COW'S MILK": GROUPS.DAIRY,
    'CHEESE, MOZZARELLA': GROUPS.DAIRY,
    'MOZZARELLA CHEESE': GROUPS.DAIRY,
    MOZZARELLA: GROUPS.DAIRY,
    YOGURT: GROUPS.DAIRY,
    BUTTER: GROUPS.DAIRY,
    CASEIN: GROUPS.DAIRY,
    'EMMENTAL CHEESE': GROUPS.DAIRY,
    'GOAT CHEESE': GROUPS.DAIRY,
    "GOAT'S CHEESE": GROUPS.DAIRY,
    'GOAT MILK': GROUPS.DAIRY,
    KEFIR: GROUPS.DAIRY,
    'PROCESSED CHEESE': GROUPS.DAIRY,
    'SHEEP CHEESE': GROUPS.DAIRY,
    "SHEEP'S CHEESE": GROUPS.DAIRY,
    'SHEEP MILK': GROUPS.DAIRY,
    WHEY: GROUPS.DAIRY,

    // eggs
    'EGG WHITE': GROUPS.EGGS,
    'EGG YOLK': GROUPS.EGGS,

    // grains
    'YEAST, BAKERS': GROUPS.GRAINS,
    "BAKER'S YEAST": GROUPS.GRAINS,
    BARLEY: GROUPS.GRAINS,
    'BARLEY GRAIN': GROUPS.GRAINS,
    BRAN: GROUPS.GRAINS,
    'YEAST, BREWERS': GROUPS.GRAINS,
    "BREWER'S YEAST": GROUPS.GRAINS,
    'RICE, BROWN': GROUPS.GRAINS,
    'BROWN RICE': GROUPS.GRAINS,
    GLUTEN: GROUPS.GRAINS,
    MALT: GROUPS.GRAINS,
    OATS: GROUPS.GRAINS,
    RYE: GROUPS.GRAINS,
    WHEAT: GROUPS.GRAINS,
    BUCKWHEAT: GROUPS.GRAINS,
    MILLET: GROUPS.GRAINS,
    SPELT: GROUPS.GRAINS,

    // seeds and nuts:
    ALMOND: GROUPS.SEEDS_AND_NUTS,
    'BLACK WALNUT': GROUPS.SEEDS_AND_NUTS,
    'WALNUT, BLACK': GROUPS.SEEDS_AND_NUTS,
    CASHEW: GROUPS.SEEDS_AND_NUTS,
    'CASHEW NUT': GROUPS.SEEDS_AND_NUTS,
    'CHIA SEED': GROUPS.SEEDS_AND_NUTS,
    'CHIA SEEDS': GROUPS.SEEDS_AND_NUTS,
    SAFFLOWER: GROUPS.SEEDS_AND_NUTS,
    SESAME: GROUPS.SEEDS_AND_NUTS,
    SUNFLOWER: GROUPS.SEEDS_AND_NUTS,
    'SUNFLOWER SEED': GROUPS.SEEDS_AND_NUTS,
    'BRAZIL NUT': GROUPS.SEEDS_AND_NUTS,
    CHESTNUT: GROUPS.SEEDS_AND_NUTS,
    FLAXSEED: GROUPS.SEEDS_AND_NUTS,
    HAZELNUT: GROUPS.SEEDS_AND_NUTS,
    MACADAMIA: GROUPS.SEEDS_AND_NUTS,
    'PINE NUT': GROUPS.SEEDS_AND_NUTS,
    PISTACHIO: GROUPS.SEEDS_AND_NUTS,
    'POPPY SEED': GROUPS.SEEDS_AND_NUTS,
    RAPESEED: GROUPS.SEEDS_AND_NUTS,

    // legumes
    'BEAN, LIMA': GROUPS.LEGUMES,
    'LIMA BEAN': GROUPS.LEGUMES,
    PEANUT: GROUPS.LEGUMES,
    'PEA, GREEN': GROUPS.LEGUMES,
    'GREEN PEA': GROUPS.LEGUMES,
    SOYBEAN: GROUPS.LEGUMES,
    'BEAN, GREEN': GROUPS.LEGUMES,
    'GREEN BEAN': GROUPS.LEGUMES,
    CAROB: GROUPS.LEGUMES,
    CHICKPEA: GROUPS.LEGUMES,
    'FAVA BEAN': GROUPS.LEGUMES,
    'KIDNEY BEAN': GROUPS.LEGUMES,
    'BEAN, KIDNEY': GROUPS.LEGUMES,
    'MUNG BEAN': GROUPS.LEGUMES,
    'NAVY BEAN': GROUPS.LEGUMES,
    'SNOW PEA': GROUPS.LEGUMES,
    LENTIL: GROUPS.LEGUMES,

    // vegetables
    ARUGULA: GROUPS.VEGETABLES,
    ASPARAGUS: GROUPS.VEGETABLES,
    'BAMBOO SHOOTS': GROUPS.VEGETABLES,
    'PEPPER, BELL': GROUPS.VEGETABLES,
    'BELL PEPPER': GROUPS.VEGETABLES,
    BROCCOLI: GROUPS.VEGETABLES,
    CABBAGE: GROUPS.VEGETABLES,
    'GREEN CABBAGE': GROUPS.VEGETABLES,
    CARROT: GROUPS.VEGETABLES,
    CAULIFLOWER: GROUPS.VEGETABLES,
    CELERY: GROUPS.VEGETABLES,
    CORN: GROUPS.VEGETABLES,
    CUCUMBER: GROUPS.VEGETABLES,
    EGGPLANT: GROUPS.VEGETABLES,
    'OLIVE, GREEN': GROUPS.VEGETABLES,
    'GREEN OLIVE': GROUPS.VEGETABLES,
    KALE: GROUPS.VEGETABLES,
    KELP: GROUPS.VEGETABLES,
    'ICEBERG LETTUCE': GROUPS.VEGETABLES,
    LETTUCE: GROUPS.VEGETABLES,
    MUSHROOM: GROUPS.VEGETABLES,
    ONION: GROUPS.VEGETABLES,
    SPINACH: GROUPS.VEGETABLES,
    SQUASH: GROUPS.VEGETABLES,
    'SWEET POTATO': GROUPS.VEGETABLES,
    'POTATO, SWEET': GROUPS.VEGETABLES,
    'POTATO, WHITE': GROUPS.VEGETABLES,
    'WHITE POTATO': GROUPS.VEGETABLES,
    POTATO: GROUPS.VEGETABLES,
    ARTICHOKE: GROUPS.VEGETABLES,
    'BEET, RED': GROUPS.VEGETABLES,
    BEET: GROUPS.VEGETABLES,
    'BRUSSELS SPROUT': GROUPS.VEGETABLES,
    'BRUSSEL SPROUTS': GROUPS.VEGETABLES,
    'BRUSSEL SPROUT': GROUPS.VEGETABLES,
    'BUTTER LETTUCE': GROUPS.VEGETABLES,
    CHARD: GROUPS.VEGETABLES,
    CHIVE: GROUPS.VEGETABLES,
    'GRAPE LEAF': GROUPS.VEGETABLES,
    'JERUSALEM ARTICHOKE': GROUPS.VEGETABLES,
    LEEK: GROUPS.VEGETABLES,
    RADISH: GROUPS.VEGETABLES,
    'RED CABBAGE': GROUPS.VEGETABLES,
    'CABBAGE, GREEN': GROUPS.VEGETABLES,
    SHALLOT: GROUPS.VEGETABLES,
    'SHIITAKE MUSHROOM': GROUPS.VEGETABLES,
    TURNIP: GROUPS.VEGETABLES,
    'WHITE MUSHROOM': GROUPS.VEGETABLES,
    'WINTER SQUASH': GROUPS.VEGETABLES,
    ZUCCHINI: GROUPS.VEGETABLES,

    // NEXUS EXTRAS BC We use sku on a product but it's the same product with a different variant/test now (sad face)
    TEA: GROUPS.SPICE,
    'COCOA BEAN': GROUPS.SPICE,
    'COCOA BEAN (CHOCOLATE)': GROUPS.SPICE,
    'COCOA_BEAN_(CHOCOLATE)': GROUPS.SPICE,
    'PAPRIKA, SWEET PEPPER': GROUPS.VEGETABLES,
    'SUNFLOWER SEED/OIL': GROUPS.SEEDS_AND_NUTS,
    TURKEY_MEAT: GROUPS.MEAT,
    'TURKEY MEAT': GROUPS.MEAT,
    RICE: GROUPS.GRAINS,
    SAFFLOWER_OIL: GROUPS.SEEDS_AND_NUTS,
    'SAFFLOWER SEED/OIL': GROUPS.SEEDS_AND_NUTS,
    SESAME_SEED: GROUPS.SEEDS_AND_NUTS,
    'SESAME SEED/OIL': GROUPS.SEEDS_AND_NUTS,
    STRING_BEAN: GROUPS.LEGUMES,
    'STRING BEAN': GROUPS.LEGUMES,
    'CORN,_SWEET': GROUPS.VEGETABLES,
    'CORN, SWEET': GROUPS.VEGETABLES,

    // NEXUS COMPREHENSIVE
    'SWEET CHERRY': GROUPS.FRUITS,
    LIME: GROUPS.FRUITS,
    KIWI_FRUIT: GROUPS.FRUITS,
    POMEGRANATES: GROUPS.FRUITS,

    // spice

    AGAVE: GROUPS.SPICE,
    CAPERS: GROUPS.SPICE,
    'TABASCO PEPPER': GROUPS.SPICE,
    CLOVE: GROUPS.SPICE,
    TOFU: GROUPS.LEGUMES,
    'CURRY POWDER': GROUPS.SPICE,
    AGAVE_NECTAR: GROUPS.SPICE,
    CAMOMILE_TEA: GROUPS.SPICE,
    CAYENNE_PEPPER: GROUPS.SPICE,
    FENNEL_SEED: GROUPS.SPICE,

    // seafood
    'SEA BASS': GROUPS.SEAFOOD,
    'EUROPEAN OYSTER': GROUPS.SEAFOOD,
    'EUROPEAN PERCH': GROUPS.SEAFOOD,
    'NORTHERN PRAWN': GROUPS.SEAFOOD,
    'TIGER PRAWN': GROUPS.SEAFOOD,
    'BLACK SCALLOP': GROUPS.SEAFOOD,
    'BLUE CRAB': GROUPS.SEAFOOD,
    'GREAT SCALLOP': GROUPS.SEAFOOD,
    'YELLOWFIN TUNA': GROUPS.SEAFOOD,
    OYSTER: GROUPS.SEAFOOD,

    // dairy
    'AMERICAN CHEESE': GROUPS.DAIRY,
    'BOILED MILK': GROUPS.DAIRY,
    'SWISS CHEESE': GROUPS.DAIRY,
    PROCESSED_CHEESE: GROUPS.DAIRY,
    BOILED_MILK: GROUPS.DAIRY,
    COW_MILK: GROUPS.DAIRY,
    GOAT_MILK_CHEESE: GROUPS.DAIRY,
    MOZZARELLA_CHEESE: GROUPS.DAIRY,
    SHEEP_MILK_CHEESE: GROUPS.DAIRY,
    SHEEPS_MILK: GROUPS.DAIRY,
    SWISS_CHEESE: GROUPS.DAIRY,

    // grains
    COMMON_MILLET: GROUPS.GRAINS,

    // seeds and nuts:
    'SAFFLOWER OIL': GROUPS.SEEDS_AND_NUTS,
    'SESAME SEED': GROUPS.SEEDS_AND_NUTS,
    'RAPESEED (CANOLA)': GROUPS.SEEDS_AND_NUTS,
    CHIA_SEED: GROUPS.SEEDS_AND_NUTS,
    FLAX_SEED: GROUPS.SEEDS_AND_NUTS,
    PISTACHIO_NUT: GROUPS.SEEDS_AND_NUTS,
    RAPE_SEEDS: GROUPS.SEEDS_AND_NUTS,

    // legumes
    MUNG_BEANS: GROUPS.LEGUMES,
    RED_KIDNEY_BEAN: GROUPS.LEGUMES,
    WHITE_BEAN: GROUPS.LEGUMES,
    'GRASS PEA': GROUPS.LEGUMES,
    SNOW_PEA: GROUPS.LEGUMES,

    // vegetables
    'BRUSSELS SPROUTS': GROUPS.VEGETABLES,
    CHIVES: GROUPS.VEGETABLES,
    'SWEET CORN': GROUPS.VEGETABLES,
    SUNCHOKE: GROUPS.VEGETABLES,
    'SEAWEED (NORI)': GROUPS.VEGETABLES,
    'PAPRIKA,_SWEET_PEPPER': GROUPS.VEGETABLES,
    'BELL PEPPER (PAPRIKA)': GROUPS.VEGETABLES,
    'PAPRIKA,_SWEET PEPPER': GROUPS.VEGETABLES,
    BRUSSELS_SPROUTS: GROUPS.VEGETABLES,
    VINE_LEAF: GROUPS.VEGETABLES,
    JERUSALEM_ARTICHOKE: GROUPS.VEGETABLES,
  },
};
