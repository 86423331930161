import { PROGRAM_TYPE } from 'common/utils/types';

export type InsurancePageContent = {
  HEADING: string;
  WITH_INSURANCE: string;
  WITHOUT_INSURANCE: string;
};

export const WC_INSURANCE_CONTENT = {
  HEADING: `Continue without insurance coverage for prescriptions?`,
  WITHOUT_INSURANCE: 'Skip insurance coverage for prescriptions',
  WITH_INSURANCE: 'Add insurance',
};

export const INSURANCE_PAGE_CONTENT: Record<string, InsurancePageContent> = {
  DEFAULT: {
    HEADING: `Continue without insurance coverage?`,
    WITHOUT_INSURANCE: 'Pay $59 without insurance',
    WITH_INSURANCE: 'Add insurance',
  },
  [PROGRAM_TYPE['weight-management']]: WC_INSURANCE_CONTENT,
  [PROGRAM_TYPE['weight-management-full']]: WC_INSURANCE_CONTENT,
  [PROGRAM_TYPE['weight-care-ongoing']]: WC_INSURANCE_CONTENT,
};
