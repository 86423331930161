import { colors, size, typography } from '@everlywell/leaves';
import { Input as LeavesInput } from '@everlywell/leaves';
import styled, { css } from 'styled-components';

import { FormBuilderVariant } from './types';

// NOTE: we need to enforce the label to remain the same color, even if the field is in error state.
const secondaryLabelCSS = css`
  ${typography.h5Text}
  display: block;
  color: ${colors.teal4} !important;
  margin-bottom: ${size.md}px;
`;

export const labelTextCSSbyVariant = (variant: FormBuilderVariant) => {
  switch (variant) {
    case 'secondary':
      return secondaryLabelCSS;

    default:
      return css``;
  }
};

export const labelStyles = (variant: FormBuilderVariant) => css`
  label {
    ${labelTextCSSbyVariant(variant)}
  }
`;

export const Input = styled(LeavesInput)`
  margin-bottom: 0;
`;

export const ErrorMessage = styled.span`
  ${typography.errorText};
  font-weight: ${typography.weight.regular};
`;
