import Grid from 'components/Grid/Grid';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Input, labelStyles } from '../../utils/styles';
import { FormBuilderField } from '../../utils/types';

export type DateFieldProps = FormBuilderField;

function DateField(props: DateFieldProps) {
  const { register, errors } = useFormContext();
  return (
    <Grid.Item width={[1]} key={props.id}>
      <Input
        css={labelStyles(props.variant)}
        id={props.id}
        label={props.label ?? ''}
        name={props.id}
        ref={register({
          required: props.required && 'Please fill in this field',
          validate: props.validate,
        })}
        required={props.required}
        type="text"
        error={errors[props.id]?.message}
      />
    </Grid.Item>
  );
}

export default DateField;
