import { sanitize } from 'common/utils/domHelpers';
import { LifeStyleRecommendation } from 'common/utils/types';
import React from 'react';

import * as S from './LifestyleRecommendationsCard.styles';

type CardProps = {
  header: string;
  imageIcon: string;
  recommendations: LifeStyleRecommendation[];
};

function LifestyleRecommendationsCard(props: CardProps) {
  const { header, imageIcon, recommendations } = props;

  const headerKey = header.replace(' ', '');
  return (
    <S.Card>
      <S.CardIconContainer>
        <S.CardIcon src={imageIcon} />
      </S.CardIconContainer>

      <S.CardContent>
        <S.CardHeader>{header}</S.CardHeader>

        {recommendations.map((recommendation, index) => (
          <div key={`${headerKey}${index}`}>
            <S.RecommendationName>{recommendation.name}</S.RecommendationName>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(recommendation.description),
              }}
            />
          </div>
        ))}
      </S.CardContent>
    </S.Card>
  );
}

export default LifestyleRecommendationsCard;
