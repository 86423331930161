import image2 from '../assets/blood-card-3.png';
import image1 from '../assets/large-blood-card.png';
import { SampleCardMarkerDirection } from '../model';
import { SIDataType } from './model';

const DBSMailingSample: SIDataType[] = [
  {
    id: '1',
    width: {
      l: '340px',
    },
    height: {
      l: '392px',
      s: '320px',
    },
    subtitle: {
      copy: 'Front',
      fontSize: {
        s: '20px',
      },
      position: {
        x: 160,
        y: 370,
        // Phones
        xs: 57,
        ys: 250,
      },
    },
    image: {
      src: image1,
      alt: 'DBS card',
      width: {
        l: '340px',
        s: '190px',
      },
      height: {
        l: '392px',
        s: 'auto',
      },
      position: {
        x: 0,
        y: 0,
        // Phones
        xs: -20,
        ys: 50,
      },
    },
    markers: [
      {
        id: '1',
        title: {
          copy: 'All circles are completely full',
          width: {
            l: '160px',
            s: '130px',
          },
          height: {
            l: 'auto',
          },
          alignment: {
            l: SampleCardMarkerDirection.right,
            s: SampleCardMarkerDirection.left,
          },
          fontSize: {
            s: '14px',
          },
          position: {
            x: -175,
            y: 165,
            // Medium devices
            xm: -175,
            ym: 165,
            // Phones
            xs: 215,
            ys: 138,
          },
        },
        line: {
          start: {
            x: 0,
            y: 190,
            // Medium devices
            xm: 0,
            ym: 190,
            // Phones
            xs: 145,
            ys: 158,
          },
          end: {
            x: 120,
            y: 190,
            // Medium devices
            xm: 120,
            ym: 190,
            // Phones
            xs: 200,
            ys: 158,
          },
        },
      },
      {
        id: '2',
        title: {
          copy: 'Information filled out',
          width: {
            l: '180px',
            s: '130px',
          },
          height: {
            l: 'auto',
          },
          alignment: {
            l: SampleCardMarkerDirection.right,
            s: SampleCardMarkerDirection.left,
          },
          fontSize: {
            s: '14px',
          },
          position: {
            x: -195,
            y: 322,
            // Medium devices
            xm: -195,
            ym: 322,
            // Phones
            xs: 215,
            ys: 223,
          },
        },
        line: {
          start: {
            x: 0,
            y: 335,
            // Medium devices
            xm: 0,
            ym: 335,
            // Phones
            xs: 145,
            ys: 235,
          },
          end: {
            x: 120,
            y: 335,
            // Medium devices
            xm: 120,
            ym: 335,
            // Phones
            xs: 200,
            ys: 235,
          },
        },
      },
    ],
  },
  {
    id: '2',
    width: {
      l: '340px',
    },
    height: {
      l: '392px',
      s: '320px',
    },
    subtitle: {
      copy: 'Back',
      fontSize: {
        s: '20px',
      },
      position: {
        x: 150,
        y: 370,
        // Phones
        xs: 62,
        ys: 202,
      },
    },
    image: {
      src: image2,
      alt: 'Blood samples',
      width: {
        l: '280px',
        s: '155px',
      },
      height: {
        l: 'auto',
      },
      position: {
        x: 0,
        y: 138,
        // Phones
        xs: 7,
        ys: 80,
      },
    },
    markers: [
      {
        id: '1',
        title: {
          copy: 'Blood has soaked through to the back of the card',
          width: {
            l: '180px',
            s: '130px',
          },
          alignment: {
            l: SampleCardMarkerDirection.left,
          },
          fontSize: {
            s: '14px',
          },
          position: {
            x: 345,
            y: 205,
            // Medium devices
            xm: 345,
            ym: 210,
            // Phones
            xs: 215,
            ys: 105,
          },
        },
        line: {
          start: {
            x: 270,
            y: 220,
            // Medium devices
            xm: 265,
            ym: 220,
            // Phones
            xs: 140,
            ys: 121,
          },
          end: {
            x: 327,
            y: 220,
            // Medium devices
            xm: 327,
            ym: 220,
            // Phones
            xs: 200,
            ys: 120,
          },
        },
      },
    ],
  },
];

export { DBSMailingSample };
