enum StepsIconType {
  check = 'check',
}

enum StepsItemsPerRow {
  auto = 'auto',
  two = 2,
  four = 4,
}

interface StepsList {
  id: string;
  description: string;
  image: string;
  alt: string;
  icon?: string;
}

const StepsIconTypes: string[] = Object.values(StepsIconType);

export { StepsIconType, StepsIconTypes, StepsItemsPerRow, StepsList };
