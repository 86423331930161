/**
 * ReplaceTestButton
 */

import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import React from 'react';

import * as S from '../../styles';

type Props = {
  testId?: number;
  barcodeSerialNumber?: string;
  status?: string;
};

const HandleAnalytics = (testId?: number) => {
  analytics.track({
    event: ANALYTICS.EVENTS.CLICKED_BUTTON,
    data: {
      label: `Claim replacement test`,
      test_id: testId,
      category: 'Results Kit Details',
    },
  });
};

const ReplaceTestButton = ({ testId, barcodeSerialNumber, status }: Props) => (
  <S.ReplacementButton
    data-test="replaced-test-button"
    appearance="primary"
    href={`/replacement?barcode_serial_number=${barcodeSerialNumber}`}
    onClick={() => HandleAnalytics(testId)}
    disabled={status !== 'offered'}
  >
    Claim replacement test
  </S.ReplacementButton>
);

export default ReplaceTestButton;
