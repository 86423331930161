import { Checkbox, Input, RadioButton, typography } from '@everlywell/leaves';
import { updateUser } from 'common/apis/userApis';
import { useInteractionTracker } from 'common/hooks/useInteractionTracker';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { SETTINGS_FORM as DATA_TEST } from 'common/utils/constants/dataTest';
import { User } from 'common/utils/types';
import { isValidName, justTheNumbers } from 'components/Forms/validations';
import { format, isValid, parse } from 'date-fns';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { ERROR_NOTIFICATION, setNotification } from 'store/actions';
import validator from 'validator';

import * as S from './BasicInfoForm.styles';
import DateOfBirthSelectGroup from './components/DateOfBirthSelectGroup';

function formatPhoneNumber(value: string) {
  if (value.length > 6) {
    return value.replace(/(\d{3})(\d{3})(\d{0,4})/, '($1) $2-$3');
  }

  if (value.length > 3) {
    return value.replace(/(\d{3})(\d{0,3})/, '($1) $2');
  }

  return value;
}

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  month: string;
  day: string;
  year: string;
  biologicalSex: 'male' | 'female';
  wantsMarketing: boolean;
};

type UpdateUser = {
  first_name: string;
  last_name: string;
  phone_number: string;
  consumer_attributes: {
    dob: string;
    gender: string;
    wants_marketing: boolean;
  };
  email?: string;
};

export type BasicInfoFormProps = {
  user: User | null | undefined;
  hasCancelButton?: boolean;
  setShowSummary?: (state: boolean) => void | null;
};

/**
 * Displays user's basic info and allows for user to update their info
 */
function BasicInfoForm({
  user,
  hasCancelButton,
  setShowSummary,
}: BasicInfoFormProps) {
  const hasConsumerAttributes = !isEmpty(user?.consumer_attributes);

  const userDob =
    user && 'dob' in user.consumer_attributes
      ? parse(user.consumer_attributes.dob, 'yyyy-MM-dd', new Date())
      : undefined;

  const userDobMonth = userDob ? format(userDob, 'MM') : undefined;
  const userDobDay = userDob ? format(userDob, 'dd') : undefined;
  const userDobYear = userDob ? format(userDob, 'yyyy') : undefined;
  const isString = (data: any) => typeof data === 'string';

  const isEmailUpdateAllowed = user?.can_update_email ?? true;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      firstName: user?.first_name,
      lastName: user?.last_name,
      email: user?.email,
      phone: formatPhoneNumber(user?.phone_number ?? ''),
      month: userDobMonth,
      day: userDobDay,
      year: userDobYear,
      biologicalSex:
        user && 'gender' in user.consumer_attributes
          ? user.consumer_attributes.gender
          : undefined,
      wantsMarketing:
        user && 'wants_marketing' in user.consumer_attributes
          ? user.consumer_attributes.wants_marketing
          : undefined,
    },
  });

  const values = watch();

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate: doUpdateUser, isLoading } = useMutation(updateUser, {
    onSuccess: (response) => {
      if (response.data) {
        queryClient.setQueryData(['user', response.data.id], response.data);
        queryClient
          .invalidateQueries(['user'])
          .finally(() => setShowSummary && setShowSummary(true));
      }
      analytics.track({
        event: ANALYTICS.EVENTS.API_SUCCESS,
        data: {
          label: 'Update Succeeded',
          component: ANALYTICS.LABELS.BASIC_INFORMATION,
        },
      });
      dispatch(
        setNotification({
          message: 'Information saved!',
          source: ANALYTICS.LABELS.BASIC_INFORMATION,
        }),
      );
    },
    onError: (errs) => {
      analytics.track({
        event: ANALYTICS.EVENTS.API_FAILURE,
        data: {
          label: 'Update Failed',
          component: ANALYTICS.LABELS.BASIC_INFORMATION,
        },
      });
      if (errs instanceof Error) {
        dispatch(
          setNotification({
            message:
              'There was an issue with updating your account settings, please contact Customer Experience.',
            type: ERROR_NOTIFICATION,
            source: ANALYTICS.LABELS.BASIC_INFORMATION,
          }),
        );
      }
    },
  });

  const onSubmit = (data: FormValues) => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'Save Information',
        category: ANALYTICS.CATEGORIES.ACCOUNT_SETTINGS,
        component: ANALYTICS.LABELS.BASIC_INFORMATION,
      },
    });
    const dob = `${data.year}-${data.month}-${data.day}`;

    const updateUser: UpdateUser = {
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: justTheNumbers(data.phone),
      consumer_attributes: {
        dob,
        gender: data.biologicalSex,
        wants_marketing: data.wantsMarketing,
      },
    };

    if (isEmailUpdateAllowed) {
      updateUser.email = data.email;
    }

    doUpdateUser({
      userId: user ? String(user.id) : '',
      user: updateUser,
    });
  };

  const trackFormInteraction = useInteractionTracker();
  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: ANALYTICS.LABELS.BASIC_INFORMATION,
      },
    });
  }, []);

  const handleFocus = async () => {
    await trackFormInteraction({
      event: ANALYTICS.EVENTS.FORM_INTERACTION,
      data: {
        component: ANALYTICS.LABELS.BASIC_INFORMATION,
      },
    });
  };

  return (
    <S.Container hasCancelButton={hasCancelButton}>
      {hasCancelButton ? (
        <S.ContactInfoTitle>Contact Information</S.ContactInfoTitle>
      ) : (
        <S.Title>Profile</S.Title>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.InnerForm>
          <S.Field>
            <Input
              id="firstName"
              name="firstName"
              placeholder="Fill in your first name"
              label="First Name"
              data-test={DATA_TEST.FIRST_NAME_FIELD}
              ref={register({
                required: 'Please enter your first name',
                setValueAs: (value) => value.trim(),
                validate: {
                  isName: (value: string) =>
                    (isString(value) && isValidName(value)) ||
                    'Please fill in a valid first name',
                },
              })}
              onFocus={handleFocus}
              error={errors.firstName?.message}
            />
          </S.Field>
          <S.Field>
            <Input
              id="lastName"
              name="lastName"
              placeholder="Fill in your last name"
              label="Last Name"
              ref={register({
                required: 'Please enter your last name',
                setValueAs: (value) => value.trim(),
                validate: {
                  isName: (value: string) =>
                    (isString(value) && isValidName(value)) ||
                    'Please fill in a valid last name',
                },
              })}
              error={errors.lastName?.message}
              data-test={DATA_TEST.LAST_NAME_FIELD}
              onFocus={handleFocus}
            />
          </S.Field>
          <S.Field>
            <Input
              id="email"
              name="email"
              placeholder="john@email.com"
              readOnly={!isEmailUpdateAllowed}
              label="Email"
              ref={register({
                /**
                 * CX team typically uses this form (masquerading as the user)
                 * to update Enterprise user's information. Because enterprise users
                 * don't have an email address, having this required is problematic.
                 * The server will still validate that the email is present for public
                 * users.
                 */
                // required: 'Please enter your email',
                setValueAs: (value) => value.trim(),
                validate: {
                  isEmail: (value: string) =>
                    // allow empty field but not invalid emails
                    (isString(value) && value.length === 0) ||
                    (isString(value) && validator.isEmail(value)) ||
                    'Please enter a valid email',
                },
              })}
              error={errors.email?.message}
              data-test={DATA_TEST.EMAIL_FIELD}
              onFocus={handleFocus}
            />
          </S.Field>
          <S.Field>
            {user && (
              <Controller
                defaultValue={formatPhoneNumber(user.phone_number ?? '')}
                control={control}
                name={'phone'}
                rules={{
                  required: 'Please complete your phone number',
                  validate: {
                    isPhoneNumber: (value: string) => {
                      if (justTheNumbers(value ?? '').length < 10) {
                        return 'Please complete your phone number';
                      } else if (!validator.isMobilePhone(value)) {
                        return 'Please fill in a valid phone number';
                      } else {
                        return true;
                      }
                    },
                  },
                }}
                render={(props) => (
                  <Input
                    id={props.name}
                    name={props.name}
                    placeholder="(000) 000-0000"
                    label="Phone Number"
                    ref={props.ref}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value) {
                        const formattedValue = formatPhoneNumber(
                          justTheNumbers(value),
                        );
                        props.onChange(formattedValue);
                      } else {
                        props.onChange(value);
                      }
                    }}
                    onBlur={props.onBlur}
                    value={props.value}
                    error={errors.phone?.message}
                    data-test={DATA_TEST.PHONE_FIELD}
                    onFocus={handleFocus}
                  />
                )}
              />
            )}
          </S.Field>
          {hasConsumerAttributes && (
            <>
              <S.Field>
                <DateOfBirthSelectGroup
                  label={<S.Title>Date of Birth</S.Title>}
                  showFieldLabels={false}
                  monthField={{
                    name: 'month',
                    ref: register(),
                  }}
                  dayField={{
                    name: 'day',
                    ref: register(),
                  }}
                  yearField={{
                    name: 'year',
                    ref: register({
                      validate: {
                        isDate: (value: string) =>
                          isValid(
                            parse(
                              `${value}-${values.month}-${values.day}`,
                              'yyyy-MM-dd',
                              new Date(),
                            ),
                          ) || 'Please fill in a valid date of birth',
                      },
                    }),
                  }}
                  error={errors.year?.message}
                />
              </S.Field>
              <S.RadioField data-test={DATA_TEST.GENDER_FIELD}>
                <S.Title>Sex Assigned at Birth</S.Title>
                <div css={{ display: 'flex' }}>
                  <RadioButton
                    id="Male"
                    name="biologicalSex"
                    label="Male"
                    value="male"
                    ref={register({
                      required: 'Please select your sex assigned at birth',
                    })}
                    border
                    css={S.radioStyles}
                  />
                  <S.Spacer />
                  <RadioButton
                    id="Female"
                    name="biologicalSex"
                    label="Female"
                    value="female"
                    ref={register({
                      required: 'Please select your sex assigned at birth',
                    })}
                    border
                    css={S.radioStyles}
                  />
                </div>
                <div css={typography.errorText}>
                  {errors.biologicalSex?.message}
                </div>
              </S.RadioField>
              <S.Field hasCancelButton={hasCancelButton}>
                <Checkbox
                  checked={Boolean(watch('wantsMarketing'))}
                  name="wantsMarketing"
                  onChange={(e) => {
                    setValue('wantsMarketing', !Boolean(values.wantsMarketing));
                  }}
                  ref={register()}
                  label="I agree to receive personalized content for Everlywell marketing purposes. Don’t worry, we’ll never spam you."
                  data-test={DATA_TEST.MARKETING_FIELD}
                  bodyTextStyle
                />
              </S.Field>
            </>
          )}
        </S.InnerForm>

        <S.ButtonRow hasCancelButton={hasCancelButton}>
          {hasCancelButton && (
            <S.CancelButton
              onClick={() => setShowSummary && setShowSummary(true)}
              appearance="tertiary"
            >
              Cancel
            </S.CancelButton>
          )}
          <S.Button
            type="submit"
            isLoading={isLoading}
            data-test={DATA_TEST.SUBMIT_BUTTON}
          >
            Save
          </S.Button>
        </S.ButtonRow>
      </form>
    </S.Container>
  );
}

export default BasicInfoForm;
