import {
  getAppointmentsSlots,
  AppointmentSlot,
  AppointmentsSlotsResponse,
} from 'common/apis/telehealthSchedulingApis';
import { Response } from 'common/hooks/useApi/request';
import {
  isAfter,
  endOfMonth,
  isBefore,
  addDays,
  format,
  isSameMonth,
} from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNull, uniqBy } from 'lodash';
import { useQueryClient, useQuery } from 'react-query';

const APPOINTMENT_SLOT_DATE_FORMAT_PARAM = 'yyyy-MM-dd';

const APPOINTMENT_DATES_INTERVAL_DAYS = 7;

const fetchAvailableAppointmentTimes = async (data: {
  month: number; // 0, 1, 2 - month of year
  year: number; // 2022, 2023, 2024 - year
  selectedProgram: string;
  selectedProvider: number | number[] | null;
  interval?: number;
  selectedAppointmentTypeId: string | null;
}) => {
  const {
    month: selectedMonth,
    year: selectedYear,
    selectedProgram,
    selectedProvider,
    interval = APPOINTMENT_DATES_INTERVAL_DAYS,
    selectedAppointmentTypeId,
  } = data;

  const promises: Array<ReturnType<typeof getAppointmentsSlots>> = [];

  const beginningOfMonth = new Date(selectedYear, selectedMonth, 1);
  // Is today after the beginning of SELECTED the month?
  const startDate = isAfter(new Date(), beginningOfMonth)
    ? new Date()
    : beginningOfMonth;

  // split request into multiple requests based off of the interval value
  let fromDate = startDate;
  while (isBefore(fromDate, endOfMonth(startDate))) {
    const datePlusInterval = addDays(fromDate, interval);
    const toDate = isBefore(datePlusInterval, endOfMonth(startDate))
      ? datePlusInterval
      : endOfMonth(startDate);

    promises.push(
      getAppointmentsSlots({
        programSlug: selectedProgram,
        startDate: format(fromDate, APPOINTMENT_SLOT_DATE_FORMAT_PARAM), // 8/1/2021
        endDate: format(toDate, APPOINTMENT_SLOT_DATE_FORMAT_PARAM), // 8/15/2021
        selectedProvider,
        selectedAppointmentTypeId,
      }),
    );

    fromDate = addDays(toDate, 1);
  }

  const [response1, ...restResponses] = await Promise.all(promises);
  const slots = [
    ...(response1.data?.slots ?? []),
    ...restResponses.reduce(
      (acc, response) => [...acc, ...(response.data?.slots ?? [])],
      [] as AppointmentSlot[],
    ),
    // filter out any slots that are not in the selected month (due to weirdness with the API)
  ].filter((slot) => isSameMonth(new Date(slot.datetime), beginningOfMonth));
  // meta will be the same for each request, so just take the first one
  const meta = response1.data?.meta;

  return {
    ...response1,
    data: {
      slots,
      meta,
    },
  } as Response<AppointmentsSlotsResponse>;
};

type PrefetchOptions = {
  selectedProgram: string;
  selectedProvider: number | number[] | null;
  selectedAppointmentTypeId: string | null;
};

export const usePrefetchAppointmentTimes = (options: PrefetchOptions) => {
  const { selectedProgram, selectedProvider, selectedAppointmentTypeId } =
    options;
  const { appointmentTimesInterval } = useFlags();

  const queryClient = useQueryClient();
  const prefetchSpecifiedMonth = (month: number, year: number) => {
    const queryKey = [
      'available-appointment-times',
      selectedProvider,
      month,
      year,
    ];

    if (!queryClient.getQueryCache().find(queryKey)) {
      queryClient.prefetchQuery(
        queryKey,
        () =>
          fetchAvailableAppointmentTimes({
            month,
            year,
            selectedProgram,
            selectedProvider,
            interval: appointmentTimesInterval,
            selectedAppointmentTypeId,
          }),
        {
          staleTime: 1000 * 60 * 1, // 1 minutes
          cacheTime: 1000 * 60 * 5, // 5 minutes
        },
      );
    }
  };

  return { prefetchSpecifiedMonth };
};

type FetchOptions = {
  month: number; // 0, 1, 2 - month of year
  year: number; // 2022, 2023, 2024 - year
  selectedProgram: string;
  selectedProvider: number | number[] | null;
  selectedAppointmentTypeId: string | null;
};

const useAppointmentTimes = (options: FetchOptions) => {
  const {
    month: selectedMonth,
    year: selectedYear,
    selectedProgram,
    selectedProvider,
    selectedAppointmentTypeId,
  } = options;

  const { appointmentTimesInterval = APPOINTMENT_DATES_INTERVAL_DAYS } =
    useFlags();

  const { data, isLoading, isError, refetch } = useQuery(
    [
      'available-appointment-times',
      selectedProvider,
      selectedMonth,
      selectedYear,
    ],
    async () =>
      await fetchAvailableAppointmentTimes({
        month: selectedMonth,
        year: selectedYear,
        selectedProgram,
        selectedProvider,
        interval: appointmentTimesInterval,
        selectedAppointmentTypeId,
      }),
    {
      enabled: !isNull(selectedProvider),
      retry: false,
      staleTime: 1000 * 60 * 1, // 1 minutes
      cacheTime: 1000 * 60 * 5, // 5 minutes
    },
  );

  const { prefetchSpecifiedMonth } = usePrefetchAppointmentTimes({
    selectedProgram,
    selectedProvider,
    selectedAppointmentTypeId,
  });

  const slots = uniqBy(data?.data?.slots ?? [], 'datetime');

  // [ "2023-08-08T09:00:00Z", ...]
  const availableTimes = slots.map((slot) => slot.datetime) ?? [];

  const availableDays =
    slots.reduce((acc, slot) => {
      const dayOfMonth = new Date(slot.datetime).getDate(); // 0, 1, 2 - day of month
      const availableSlotsForDay = [...(acc[dayOfMonth] ?? []), slot]; // array of appointment slots for that day

      acc[dayOfMonth] = availableSlotsForDay;
      return acc;
    }, {} as { [dayOfMonth: string]: Array<AppointmentSlot> }) ?? {};

  const availableAppointmentSlots =
    slots.reduce((acc, slot) => {
      acc[slot.datetime] = slot;
      return acc;
    }, {} as { [dateTime: string]: AppointmentSlot }) ?? {};

  const selectedState = data?.data?.meta.user.state_abbrev;
  const appointmentType = data?.data?.meta.appointment_type;

  return {
    availableTimes,
    availableDays,
    availableAppointmentSlots,
    selectedState,
    appointmentType,
    isLoading,
    isError,
    refetch,
    prefetchSpecifiedMonth,
  };
};

export default useAppointmentTimes;
