import { ShippingSettings } from 'common/utils/types';
import { StripePaymentSource } from 'pages/OldSettingsPage/PaymentSettings/helpers';

import StoreApi from './StoreApi';

// Paypal-specific types
type PaypalPaymentMethod = 'PayPal';
type PaypalPaymentInformation = {
  payment_method: PaypalPaymentMethod;
};

// Stripe-specific types
type StripePaymentMethod = 'Stripe';
export type StripePaymentInformation = {
  payment_method: StripePaymentMethod;
  default_source: StripePaymentSource;
};

export type PaymentDetails = {
  user_id: string;
  payment_information: StripePaymentInformation | PaypalPaymentInformation;
  message?: string;
};

export type CardError = {
  message: string;
  path: string;
  type: string;
};

export type MembershipDetails = {
  id: string;
  type: string;
  user_id: string;
  order_id: string;
  status: string;
  program_plan_id: string;
  message?: string;
  errors?: CardError[];
};

const PAYMENT_INFO_URI = `/v1/settings/payment_information`;

const MEMBERSHIP_URI = '/v3/memberships/';

const getBillingDetails = async (): Promise<PaymentDetails> => {
  try {
    const { data } = await StoreApi.get<PaymentDetails>(PAYMENT_INFO_URI);
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const updateBillingDetails = async (
  payment_details: any,
): Promise<PaymentDetails> => {
  const payload = { payment_details };
  try {
    const { data } = await StoreApi.put<PaymentDetails>(
      PAYMENT_INFO_URI,
      payload,
    );
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

/**
 * Creates the billing details object after an Axios token is
 * generated from the frontend with the card data.
 */
const createBillingDetails = async (token: string): Promise<any> => {
  const payload = { token };

  try {
    const response = await StoreApi.post<PaymentDetails>(
      PAYMENT_INFO_URI,
      payload,
      {
        /**
         * Always return true to allow Axios to retrieve
         * the error data returned from the backend
         * instead of the default behavior of rejecting
         * responses that are not 2XX.
         * More info: https://axios-http.com/docs/handling_errors
         */
        validateStatus: (_) => true,
      },
    );

    // Catching all non-2XX status
    if (response.status >= 300) {
      const errorMessage = response.data.message;
      // Throwing the error manually to pass down the message from the BE API
      throw new Error(errorMessage);
    }

    return response.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};

const ERRORS_TO_IGNORE = ['Program::CreateUserPlan', 'Cannot transition state'];

/* Some errors from the memberships endpoint are not relevant to the user, we fallback
 to a generic error message if we detect them. These values were take from Data Dog*/
const hasPartialIgnoreError = (value: string): boolean =>
  ERRORS_TO_IGNORE.some((error) => value.includes(error));

const createMembershipBilling = async (payload: {
  token: string;
  variantId: string;
  wantsMarketing: boolean;
  marketingCampaignId?: number;
  extoleShareableCode?: string;
}): Promise<MembershipDetails> => {
  const {
    token,
    variantId,
    wantsMarketing,
    marketingCampaignId,
    extoleShareableCode,
  } = payload;
  const payloadRequest = {
    variant_id: variantId,
    payment: {
      payment_token: token,
    },
    wants_marketing: wantsMarketing,
    marketing_campaign_id: marketingCampaignId,
    marketing_advocate_code: extoleShareableCode,
  };

  try {
    const { data, status } = await StoreApi.post<MembershipDetails>(
      MEMBERSHIP_URI,
      payloadRequest,
      {
        /**
         * Always return true to allow Axios to retrieve
         * the error data returned from the backend
         * instead of the default behavior of rejecting
         * responses that are not 2XX.
         * More info: https://axios-http.com/docs/handling_errors
         */
        validateStatus: (_) => true,
      },
    );

    // Catching all non-2XX status
    if (status >= 300) {
      let errorMessage = data.errors?.[0]?.message;

      if (!errorMessage || hasPartialIgnoreError(errorMessage)) {
        errorMessage =
          "We're unable to process your payment right now. Please try again later.";
      }

      // Throwing the error manually to pass down the message from the BE API
      throw new Error(errorMessage);
    }

    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};
// Shipping Data
const SHIPPING_INFO_URI = `/v1/settings/shipping_address`;

const getShippingAddress = async () => {
  try {
    const result = await StoreApi.get(SHIPPING_INFO_URI);
    return result.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const updateShippingAddress = async (shipping_address: ShippingSettings) => {
  const payload = { shipping_address };
  try {
    const req = await StoreApi.post(SHIPPING_INFO_URI, payload);

    if (!req) {
      throw new Error();
    }
    return req.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const useAccountSettings = () => ({
  updateBillingDetails,
  getBillingDetails,
  createBillingDetails,
  createMembershipBilling,
  getShippingAddress,
  updateShippingAddress,
});

export default useAccountSettings;
