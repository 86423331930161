import _ from 'lodash';
/**
 * Formats marker name to read better in the overview section
 * @param { String } marker name of the marker
 * @returns { String } ex. formatMarkerName("Milk, Goat's") => Goat's Milk
 */
const formatMarkerName = (marker: string) => {
  const nameArray = marker.toLocaleLowerCase().split(',');
  let formattedMarkerName;

  if (nameArray.length > 1) {
    const formattedWords = nameArray.map(
      (word) => `${word.trim().charAt(0).toUpperCase()}${word.trim().slice(1)}`,
    );
    formattedMarkerName = `${formattedWords[1]} ${formattedWords[0]}`;
  } else {
    formattedMarkerName = _.toLower(marker);
  }

  return formattedMarkerName;
};

const formatTestName = (test: string) => {
  let formattedTest = test;
  if (test.includes('(')) {
    formattedTest = test.replace(/\([^)]+\)/, '').trim();
  }
  return formattedTest;
};

// replace all whitespace and forward slashes with separator
const formatStringSeparator = (stringToFormat: string, separator = '-') =>
  stringToFormat.replace(/,\s|\s|\//g, separator);

/**
 * Pluralize "Credit(s)" text based on amount of credits
 */
const pluralizeCreditText = (totalCredits: number) => {
  const pluralRules = new Intl.PluralRules('en-US');

  const rule = pluralRules.select(totalCredits);

  const suffixes = new Map([
    ['one', 'Credit'],
    ['other', 'Credits'],
  ]);

  return suffixes.get(rule);
};

export {
  formatMarkerName,
  formatTestName,
  formatStringSeparator,
  pluralizeCreditText,
};
