import {
  Button as BaseButton,
  colors,
  H3,
  mediaQueries,
  Modal,
  size,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

const ManageAppointmentModal = styled(Modal)`
  top: ${size.xl4}px;

  ${mediaQueries.forDesktopUp} {
    top: ${size.xl8}px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${size.xl3}px;

  ${mediaQueries.forTabletVerticalUp} {
    margin-top: 0;
  }
`;

const Header = styled(H3)`
  text-align: left;
  font-weight: ${typography.weight.bold};

  ${mediaQueries.forTabletVerticalUp} {
    margin: ${size.xl1}px 0 ${size.md}px;
    width: 535px;
  }

  strong {
    font-weight: ${typography.weight.bold};
    var {
      font-style: normal;
    }
  }
`;

const ImageContainer = styled.img`
  height: 72px;
  width: 72px;
`;

const Copy = styled.div`
  ${typography.bodyTextSmall}
  text-align: left;
  font-weight: ${typography.weight.light};
  ${mediaQueries.forTabletVerticalUp} {
    width: 532px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: ${size.xl1}px 0;
  gap: ${size.sm}px;
  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: row;
  }
`;

const Button = styled(BaseButton)`
  width: 100%;
  ${mediaQueries.forTabletVerticalUp} {
    width: 532px;
  }
`;

const Disclaimer = styled.div`
  ${typography.bodyTextXSmall}
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 112px;
  padding: 10px ${size.md}px;
  gap: 10px;
  margin: ${size.lg}px 0;
  background: ${colors.green1};
  border-radius: ${size.xs1}px;
  ${mediaQueries.forTabletVerticalUp} {
    width: 535px;
    height: 89px;
  }
`;

const IconWrapper = styled.div`
  width: ${size.lg}px;
  height: ${size.lg}px;
`;

const ContactSupportText = styled.p`
  ${typography.bodyTextXSmall}
  width: 100%;
  text-align: center;
  font-feature-settings: 'ss01' on;

  span {
    color: ${colors.blue1};
  }
  ${mediaQueries.forTabletVerticalUp} {
    width: 503px;
  }
`;

export {
  Header,
  ImageContainer,
  Copy,
  ContentContainer,
  ButtonsWrapper,
  Button,
  ManageAppointmentModal,
  Disclaimer,
  IconWrapper,
  ContactSupportText,
};
