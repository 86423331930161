import { Col } from '@everlywell/leaves';
import { COVID_SCREENER_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';
import { COVID_SCREENER_STEPS } from 'common/utils/constants/rumActions';
import { KitRegistrationUser } from 'common/utils/types';
import useTimeTracker from 'common/utils/useTimeTracker';
import React from 'react';

import BaseQuestion from '../../../BaseQuestion';
import * as S from '../styles';

interface Props {
  user: KitRegistrationUser;
  totalQuestions: number;
  questionNumber: number;
  handleNext: (action: any) => void;
  handleBack: (action: any) => void;
}

const HighRiskQuestion = ({
  user,
  totalQuestions,
  questionNumber,
  handleNext,
  handleBack,
}: Props) => {
  const fireAction = useTimeTracker(COVID_SCREENER_STEPS.HIGH_RISK);
  const answers = [
    {
      id: 'high-risk',
      label: 'Yes',
    },
    {
      id: 'low-risk',
      label: 'No',
    },
  ];

  const subText = (
    <S.HighRiskConditions>
      <S.HighRiskList>
        Chronic conditions (diabetes, high blood pressure, lung disease, etc.)
      </S.HighRiskList>
      <S.HighRiskList>
        Weakened immune system (AIDS, rheumatoid arthritis, chemotherapy,
        steroids, organ or bone marrow transplant, etc.)
      </S.HighRiskList>
      <S.HighRiskList>
        Heart condition (heart attacks, stroke, etc.)
      </S.HighRiskList>
      <S.HighRiskList>Neurologic condition or had a stroke</S.HighRiskList>
      <S.HighRiskList>Pregnancy</S.HighRiskList>
      <S.HighRiskList>Regular smoking or vaping</S.HighRiskList>
      <S.HighRiskList>Overweight or Obese</S.HighRiskList>
    </S.HighRiskConditions>
  );

  return (
    <Col>
      <S.BackButton onClick={() => handleBack(fireAction)}>
        <S.LeftCaretIcon /> Back
      </S.BackButton>
      <BaseQuestion
        question="Do any of the following apply to you? (Yes/No)"
        subText={subText}
        questionNumber={questionNumber}
        totalQuestions={totalQuestions}
        answers={answers}
        value={user.highRisk.value}
        setValue={user.highRisk.setValue}
      />
      <S.NextButton
        isDisabled={user.highRisk.value === ''}
        appearance="primary"
        onClick={() => handleNext(fireAction)}
        data-test={`${DATA_TEST.BUTTON}-next`}
      >
        Next
      </S.NextButton>
    </Col>
  );
};
export default HighRiskQuestion;
