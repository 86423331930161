import {
  colors,
  mediaQueries,
  typography,
  Button,
  size,
} from '@everlywell/leaves';
import { bodyCopyLarge } from 'common/styles/app-styles';
import { Col_12_Grid } from 'common/styles/grid-styles';
import { hexToRGB } from 'common/utils/helpers';
import styled from 'styled-components';

const ResultsWrapper = styled(Col_12_Grid)`
  padding-bottom: 8px;
  margin-top: 32px;

  ${mediaQueries.forTabletVerticalUp} {
    padding-bottom: 0;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    padding-bottom: 8px;
    margin-top: 40px;
  }
`;

const IconWrapper = styled.span`
  margin-right: 6px;
`;

const SharedResultInfo = styled.div`
  background-color: rgba(${hexToRGB(colors.green4)}, 0.9);
  padding: 8px 0;
  font-weight: ${typography.weight.regular};
  ${bodyCopyLarge};
  text-align: center;
  color: ${colors.white};
  z-index: 5;
  position: relative;

  ${mediaQueries.forTabletVerticalUp} {
    padding-bottom: 4px;
  }
`;

const BannerWrapper = styled.div`
  display: block;
`;

const FSPersonalization = styled.div`
  padding: 50px;
  height: 400px;
`;

const ReplacementWrapper = styled.div`
  text-align: center;
`;

const ReplacementButton = styled(Button)`
  margin-top: ${size.xl1}px;
  text-decoration: none !important;
  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.md}px;
    margin-top: 0;
  }
`;

export {
  ResultsWrapper,
  IconWrapper,
  SharedResultInfo,
  BannerWrapper,
  FSPersonalization,
  ReplacementWrapper,
  ReplacementButton,
};
