import React from 'react';

import Input, { Props } from '../Input';

import { FormFieldType } from '..';

import { justTheNumbers } from '../Input/utils';
import { formatPhoneNumber } from './utils';

function PhoneInput(props: Props) {
  const { onChange, onValidate, ...rest } = props;

  function handleChange({ name, value }: FormFieldType) {
    onChange({ name, value: justTheNumbers(value as string) });
  }

  function handleValidate({ name, value }: FormFieldType) {
    onValidate({ name, value: justTheNumbers(value as string) });
  }

  return (
    <Input
      {...rest}
      value={formatPhoneNumber((props.value as string) || '')}
      type="tel"
      onChange={handleChange}
      onValidate={handleValidate}
    />
  );
}

export default PhoneInput;
