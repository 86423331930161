import { TrackVisibilityWrapper } from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import React from 'react';

import ResourceLinkCard, {
  Props as ResourceLinkCardProps,
} from './ResourceLinkCard';
import * as S from './styles';

export type Props = {
  cards: ResourceLinkCardProps[];
};

function ResourceLinkCollection(props: Props) {
  const { cards } = props;

  const hasMoreThanOne = cards.length > 1;
  const hasMany = cards.length > 2;

  const renderDesktopManyResources = (cards: ResourceLinkCardProps[]) => {
    const slideData: { image: React.ReactNode; imageAlt: string }[] = [];
    const isEven = cards.length % 2 === 1;
    const lastElementIndex = isEven ? cards.length - 2 : cards.length - 1;

    // TLDR: think in a more ellegant way to get pairs;
    for (let i = 0; i <= lastElementIndex; i += 2) {
      slideData.push({
        image: (
          <S.CardContainer>
            <S.SmallInner>
              <ResourceLinkCard {...cards[i]} />
            </S.SmallInner>
            <S.SmallInner>
              <ResourceLinkCard {...cards[i + 1]} />
            </S.SmallInner>
          </S.CardContainer>
        ),
        imageAlt: '',
      });
    }
    // add last element aligned to left;
    if (isEven) {
      slideData.push({
        image: (
          <S.LeftAlignedContainer>
            <S.SmallInner>
              <ResourceLinkCard
                {...cards[cards.length - 1]}
                key={cards.length - 1}
              />
            </S.SmallInner>
          </S.LeftAlignedContainer>
        ),
        imageAlt: '',
      });
    }

    return <S.CustomHeroSlider slideData={slideData} />;
  };

  const renderMobileManyResources = (cards: ResourceLinkCardProps[]) => {
    const slideData: { image: React.ReactNode; imageAlt: string }[] = [];
    cards.map((card) => {
      slideData.push({
        image: (
          <S.MobileInner>
            <ResourceLinkCard {...card} />
          </S.MobileInner>
        ),
        imageAlt: '',
      });
      return card;
    });

    return <S.CustomHeroSlider slideData={slideData} />;
  };

  const renderTwoCardsOnDesktop = (
    card1: ResourceLinkCardProps,
    card2: ResourceLinkCardProps,
  ) => (
    <S.CardContainer>
      <S.SmallInner>
        <ResourceLinkCard {...card1} />
      </S.SmallInner>
      <S.SmallInner>
        <ResourceLinkCard {...card2} />
      </S.SmallInner>
    </S.CardContainer>
  );

  // case 1: has many cards = desktop and mobile carousel
  if (hasMany) {
    return (
      <TrackVisibilityWrapper label={ANALYTICS.LABELS.RESOURCE_LINKS}>
        <S.DesktopContainer>
          {renderDesktopManyResources(cards)}
        </S.DesktopContainer>
        <S.MobileContainer>
          {renderMobileManyResources(cards)}
        </S.MobileContainer>
      </TrackVisibilityWrapper>
    );
  }

  // case 2: has two cards = desktop = both side by side centered, mobile = carousel
  if (hasMoreThanOne) {
    return (
      <TrackVisibilityWrapper label={ANALYTICS.LABELS.RESOURCE_LINKS}>
        <S.DesktopContainer>
          {renderTwoCardsOnDesktop(cards[0], cards[1])}
        </S.DesktopContainer>
        <S.MobileContainer>
          {renderMobileManyResources(cards)}
        </S.MobileContainer>
      </TrackVisibilityWrapper>
    );
  }

  // case 3: has only one card = bigger centered;
  return (
    <TrackVisibilityWrapper label={ANALYTICS.LABELS.RESOURCE_LINKS}>
      <S.LargeInner>
        <ResourceLinkCard {...cards[0]} />
      </S.LargeInner>
    </TrackVisibilityWrapper>
  );
}

export default ResourceLinkCollection;
