import { mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

const StyledInputContainer = styled.div`
  position: relative;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 0;
  }
`;

export { StyledInputContainer };
