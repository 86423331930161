/* eslint-disable @typescript-eslint/no-var-requires */
import { size, colors, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

const HeroCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 32px;
  border-radius: 8px;
  box-shadow: 0 ${size.xs3}px ${size.md}px 0 rgba(0, 0, 0, 0.05);
  background-color: ${colors.white};
  flex-direction: column;
  text-align: center;
  min-height: 413px;
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    height: 35em;
  }
`;

export { HeroCard };
