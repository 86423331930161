import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import PageHeader from 'pages/CarePlanPage/components/PageHeader';
import CONTENT from 'pages/CarePlanPage/content';
import React from 'react';
import { useEffectOnce } from 'react-use';

import useCarePlan from '../../useCarePlan';
import ConsultDetails from '../ConsultDetails/ConsultDetails';
import DownloadCarePlanPDF from '../DownloadCarePlanPDF';
import ErrorComponent from '../ErrorComponent';
import LifestyleRecommendationsCard from '../LifestyleRecommendationsCard';
import LoadingCarePlan from '../LoadingCarePlan';
import MedicationDetails from '../MedicationDetails';
import PatientPlanCard from '../PatientPlanCard/PatientPlanCard';
import RecommendationCard from '../RecommendationCard';
import RecommendedTestCards from '../RecommendedTestCards';
import VisitSummary from '../VisitSummary';
import * as S from './CarePlanLayout.styles';
import RecommendedTestIcon from './images/recommendedTestIcon.svg';
import LabDetails from './LabDetails';

const HEALTHIE_PHONE_NUMBER = '(708)-787-4438';

function PageLayout() {
  const {
    isCarePlanLoading,
    isError,
    isLoadingUser,
    userInfo,
    carePlanData,
    formAnswerGroupId,
    errorStatusCode,
  } = useCarePlan();

  const {
    recommended_tests = [],
    visit_summary,
    screening_recommendations = [],
    issued_at,
    provider_name,
    vaccination_recommendations,
    patient_plan,
    lifestyle_recommendations = [],
    medications = [],
    lab = null,
  } = carePlanData || {};

  useEffectOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        label: ANALYTICS.LABELS.CARE_PLAN.CARE_PLAN_PAGE,

        formAnswerGroupId,
      },
    });
  });

  return (
    <S.CarePlanContainer>
      {isError && <ErrorComponent statusCode={errorStatusCode} />}
      {!isError && (
        <PageHeader
          firstName={userInfo?.first_name}
          isLoadingUser={isLoadingUser}
        />
      )}
      {isCarePlanLoading && <LoadingCarePlan />}
      {carePlanData && (
        <>
          {issued_at && provider_name && (
            <ConsultDetails
              issued_at={issued_at}
              provider_name={provider_name}
            />
          )}
          {visit_summary && <VisitSummary contentHTML={visit_summary} />}
          <S.DisclaimerText>
            If you’re having serious symptoms, please seek medical attention
            right away.
          </S.DisclaimerText>
          <S.CarePlanHeaderIcon>
            <S.Icon src={RecommendedTestIcon} />
          </S.CarePlanHeaderIcon>
          <S.CarePlanHeader>Provider recommendations</S.CarePlanHeader>

          {recommended_tests.length > 0 ? (
            <RecommendedTestCards
              recommendedTests={recommended_tests}
              formAnswerGroupId={formAnswerGroupId}
            />
          ) : null}

          {lab ? (
            <LabDetails
              header={CONTENT.lab.header}
              documentsLabel={CONTENT.lab.documentsLabel}
              icon={CONTENT.lab.icon}
              disclaimer={CONTENT.lab.discalimer}
              viewDetailsLabel={CONTENT.lab.viewDetails}
              hideDetailsLabel={CONTENT.lab.hideDetails}
              carePlanLab={{
                name: lab.name,
                cpt_codes: lab.cpt_codes,
              }}
            ></LabDetails>
          ) : null}

          {medications.length > 0 ? (
            <MedicationDetails
              header={CONTENT.medication_list.header}
              imageIcon={CONTENT.medication_list.image_icon}
              medications={medications}
            />
          ) : null}

          {screening_recommendations.length > 0 ? (
            <RecommendationCard
              header={CONTENT.screening_recommendations.header}
              imageIcon={CONTENT.screening_recommendations.image_icon}
              title={CONTENT.screening_recommendations.title}
              recommendations={screening_recommendations}
            />
          ) : null}
          {lifestyle_recommendations.length > 0 ? (
            <LifestyleRecommendationsCard
              header={CONTENT.lifestyle_changes.header}
              imageIcon={CONTENT.lifestyle_changes.image_icon}
              recommendations={lifestyle_recommendations}
            />
          ) : null}
          {vaccination_recommendations.length > 0 ? (
            <RecommendationCard
              header={CONTENT.vaccination_recommendations.header}
              imageIcon={CONTENT.vaccination_recommendations.image_icon}
              title={CONTENT.vaccination_recommendations.title}
              recommendations={vaccination_recommendations}
            />
          ) : null}

          {patient_plan && (
            <PatientPlanCard
              plan={patient_plan}
              formAnswerGroupId={formAnswerGroupId}
            />
          )}
          <S.DisclaimerText>
            Contact your OpenLoop provider team at <br />
            <a href={`tel:${HEALTHIE_PHONE_NUMBER}`}>{HEALTHIE_PHONE_NUMBER}</a>
            , Monday through Friday, 7am-7pm CST with any questions or concerns
            related to your care.
          </S.DisclaimerText>

          <DownloadCarePlanPDF carePlanId={formAnswerGroupId} />
        </>
      )}
    </S.CarePlanContainer>
  );
}

export default PageLayout;
