import { Col } from '@everlywell/leaves';
import { COVID_SCREENER_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';
import { COVID_SCREENER_STEPS } from 'common/utils/constants/rumActions';
import { KitRegistrationUser } from 'common/utils/types';
import useTimeTracker from 'common/utils/useTimeTracker';
import React from 'react';

import BaseQuestion from '../../../BaseQuestion';
import * as S from '../styles';

interface Props {
  user: KitRegistrationUser;
  totalQuestions: number;
  questionNumber: number;
  handleNext: (action: any) => void;
  handleBack: (action: any) => void;
}

const ExposureQuestion = ({
  user,
  totalQuestions,
  questionNumber,
  handleNext,
  handleBack,
}: Props) => {
  const fireAction = useTimeTracker(COVID_SCREENER_STEPS.COVID_EXPOSURE);
  const answers = [
    {
      id: 'not-exposed',
      label: 'I have not been exposed to COVID-19',
    },
    {
      id: 'area-community-spread',
      label:
        'I live or work in a place where people reside, meet or gather in close proximity (office buildings, schools, healthcare facilities, etc.)',
    },
    {
      id: 'sick-contact',
      label:
        'I have been in close proximity (within 6ft) to someone who is sick but not diagnosed with COVID-19',
    },
    {
      id: 'known-exposure',
      label:
        'I have been in close proximity (within 6ft) to someone who has been diagnosed or presumed to have COVID-19',
    },
  ];
  return (
    <Col>
      <S.BackButton
        data-test={`${DATA_TEST.BUTTON}-back`}
        onClick={() => handleBack(fireAction)}
      >
        <S.LeftCaretIcon /> Back
      </S.BackButton>
      <BaseQuestion
        question="Please describe your exposure to Coronavirus."
        questionNumber={questionNumber}
        totalQuestions={totalQuestions}
        answers={answers}
        value={user.exposure.value}
        setValue={user.exposure.setValue}
      />
      <S.NextButton
        isDisabled={user.exposure.value === ''}
        appearance="primary"
        onClick={() => handleNext(fireAction)}
        data-test={`${DATA_TEST.BUTTON}-next`}
      >
        Next
      </S.NextButton>
    </Col>
  );
};
export default ExposureQuestion;
