import { Localize } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import {
  SCROLL_TO_RESULTS,
  TOTAL_ABNORMAL_DESCRIPTOR,
  MARKER_TYPES,
} from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { INTRO as DATA_TEST } from 'common/utils/constants/dataTest';
import { sanitize } from 'common/utils/domHelpers';
import { jumpToSection } from 'common/utils/helpers';
import pluralize from 'pluralize';
import React from 'react';

import * as S from './styles';

export type Props = {
  intro: string;
  totalAbnormal: number;
  markerType: string;
  introScrollToContent: string;
  testId: number;
  testName: string;
  testType: string;
  conditions?: string[];
};

function IntroCount(props: Props) {
  const {
    intro,
    totalAbnormal,
    markerType,
    introScrollToContent,
    testId,
    testName,
    testType,
    conditions,
  } = props;

  const stringToReplace = '%COUNT%';

  const isFoodSensitivity = markerType === MARKER_TYPES.FOOD_SENSITIVITY;
  const isFAL = testType === 'food-allergy-test';
  const abnormalDescriptor = isFAL
    ? 'food'
    : TOTAL_ABNORMAL_DESCRIPTOR[markerType];

  // Shows Personalization if they've selected one of the required
  // Health Profile Answer, and we're on a FS test result.
  const showFSPersonalization = conditions?.length && isFoodSensitivity;

  const fsPersponalizationTextPiece =
    " and you indicated you've had a history of";

  let fsPersonalizationConditionsText;
  switch (conditions?.length) {
    case 3:
      fsPersonalizationConditionsText = `${conditions[0]}, ${conditions[1]}, and ${conditions[2]}`;
      break;
    case 2:
      fsPersonalizationConditionsText = `${conditions[0]} and ${conditions[1]}`;
      break;
    case 1:
      fsPersonalizationConditionsText = `${conditions[0]}`;
      break;
    default:
      fsPersonalizationConditionsText = '';
  }

  if (intro.indexOf(stringToReplace) === -1) {
    return (
      <S.Intro
        data-test={DATA_TEST.CONTENT}
        dangerouslySetInnerHTML={{ __html: sanitize(intro) }}
      />
    );
  }
  const splitIntro = intro.split(stringToReplace);

  const introPart1 = splitIntro[0];
  const introPart2 = splitIntro[1];

  const dataAttributes = {
    'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
    'data-analytics-label': 'Clicked intro number',
  };

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    const element = event.target as HTMLElement;
    if (!element || !element.href) return;

    // Snippet created in Spree for ScrollToContent may contain multiple links;
    // parse link that was clicked on to obtain ID of section user is scrolling to
    const linkId = element.href.split('#').pop();
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        category: `Scroll to ${linkId}`,
        label: ANALYTICS.CATEGORIES.RESULTS,
        testId,
        testName,
      },
    });
  }

  return (
    <S.Intro data-test={DATA_TEST.CONTENT}>
      <span>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: sanitize(introPart1) }} />
        <S.AbnormalCount
          data-test={DATA_TEST.ABNORMAL_COUNT}
          // eslint-disable-next-line no-script-url
          onClick={() => jumpToSection(true, SCROLL_TO_RESULTS, true)}
          {...dataAttributes}
        >
          <Localize name="totalAbnormal" pluralize={totalAbnormal}>
            {totalAbnormal}
          </Localize>{' '}
          {pluralize(abnormalDescriptor, totalAbnormal)}
        </S.AbnormalCount>
        {showFSPersonalization ? (
          <>
            {fsPersponalizationTextPiece}{' '}
            <S.ConditionsHistory>
              {fsPersonalizationConditionsText}
            </S.ConditionsHistory>
          </>
        ) : null}
      </span>
      <span dangerouslySetInnerHTML={{ __html: sanitize(introPart2) }} />
      <S.ScrollToContent
        onClick={handleClick}
        data-test={DATA_TEST.SCROLL_TO_CONTENT}
        dangerouslySetInnerHTML={{ __html: sanitize(introScrollToContent) }}
      />
    </S.Intro>
  );
}

export default IntroCount;
