import React from 'react';

import * as S from './styles';

type Props = {
  children?: string;
};

function ErrorText(props: Props) {
  const { children } = props;
  return <S.Wrapper>{children || '\u00A0'}</S.Wrapper>;
}

export default ErrorText;
