import { Button } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { iosMessage } from 'common/utils/trackers/ios';
import { PROGRAM_TYPE } from 'common/utils/types';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import brandGraphicImage from './assets/Brand-Graphic.png';
import { WELCOME_TYPE_CONTENT } from './WelcomeToProgramPage.content';
import * as S from './WelcomeToProgramPage.styles';

export type WelcomeToProgramPageProps = {
  program: string;
};

/**
 * Welcome page for a virtual care program
 */
export default function WelcomeToProgramPage({
  program,
}: WelcomeToProgramPageProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const CONTENT = WELCOME_TYPE_CONTENT[program];
  const isEmbedded =
    searchParams.get('embedded') === 'true' ||
    sessionStorage.getItem('embedded') === 'true';
  const isEverlywellPlus = program === PROGRAM_TYPE['everlywell-plus'];

  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        page: ANALYTICS.PAGES.ACCOUNT_HUB.WELCOME_TO_PROGRAM,
        program,
      },
    });
    iosMessage('didJoinProgram', 'WelcomeToProgramPage', program);
    // Scroll to the top when it loads
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'welcomeToProgramPage',
        programName: program,
      },
    });
  }, [program]);

  const navigateToInterstitialPage = () => {
    navigate({
      pathname: '/telehealth/sessions',
      search: createSearchParams({
        program,
      }).toString(),
    });
  };

  const sendButtonClickEvent = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.VIRTUAL_CARE_FORMS.WELCOME_TO_PROGRAM_CONTINUE,
        program,
      },
    });
  };

  const handleButtonClick = () => {
    sendButtonClickEvent();

    switch (program) {
      case PROGRAM_TYPE['weight-management-full']:
        navigateToInterstitialPage();
        break;
      case PROGRAM_TYPE['everlywell-plus']:
        navigate('/dashboard');
        break;
      default:
        navigateToInterstitialPage();
    }
  };

  return (
    <S.Container>
      <S.BrandImage src={brandGraphicImage} alt="" />
      <S.Heading>{CONTENT.HEADING}</S.Heading>
      <S.SubHeading>{CONTENT.SUB_HEADING}</S.SubHeading>
      {!isEmbedded || (isEmbedded && !isEverlywellPlus) ? (
        <Button type="button" onClick={handleButtonClick}>
          {CONTENT.BUTTON}
        </Button>
      ) : null}
    </S.Container>
  );
}
