import { OptionsType } from 'components/Forms';
import React, { SyntheticEvent } from 'react';

import * as S from './styles';

type Props = {
  value: string;
  label: string;
  name: string;
  hasError?: boolean;
  options: OptionsType[];
  onChange: (event: SyntheticEvent<HTMLSelectElement>) => void;
  onBlur?: (event: SyntheticEvent<HTMLSelectElement>) => void;
  [key: string]: any;
};

function Select(props: Props) {
  const {
    label,
    name,
    hasError,
    value = '',
    onChange,
    options,
    onBlur,
    ...rest
  } = props;

  const isEmpty = value === '';

  return (
    <S.Wrapper>
      <S.Label htmlFor={name}>{label}</S.Label>
      <S.Select
        id={name}
        aria-label={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        data-is-empty={isEmpty}
        data-has-error={hasError}
        name={name}
        {...rest}
      >
        <option disabled value="">
          {label}
        </option>
        {options.map((option: OptionsType) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </S.Select>
    </S.Wrapper>
  );
}

export default Select;
