import { ImmutableReduxState, FsMarkerContent } from 'common/utils/types';
import FoodSensitivityMarkerCard from 'components/FoodSensitivityMarkerCard';
import { connect } from 'react-redux';
import { makeSelectContent } from 'store/selectors';

const CONTENT_KEYS = {
  markerOverview: 'MARKER_OVERVIEW',
  abnormalReactivity: 'ABNORMAL_REACTIVITY',
  hiddenSources: 'HIDDEN_SOURCES',
  crossReactivites: 'CROSS_REACTIVITIES',
};

type StateProps = {
  content: FsMarkerContent;
};

type OwnProps = {
  contentToken: string;
};

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): StateProps {
  const { contentToken } = ownProps;
  return {
    // @ts-ignore
    content: makeSelectContent(contentToken, CONTENT_KEYS)(state),
  };
}

export default connect(mapStateToProps)(FoodSensitivityMarkerCard);
