import { LocalizeDisabled } from '@everlywell/leaves';
import AppointmentDate from 'pages/AccountHub/components/AppointmentDate';
import React from 'react';

import * as S from './ConsultDetails.styles';

type PageHeaderProps = {
  issued_at: string;
  provider_name: string;
};

function ConsultDetails(props: PageHeaderProps) {
  const { issued_at, provider_name } = props;

  return (
    <S.Wrapper>
      <S.Header>
        Provider: <LocalizeDisabled>{provider_name}</LocalizeDisabled>
      </S.Header>
      {issued_at && (
        <S.Content>
          Your Care Plan was <strong>entered by your provider</strong> on{' '}
          <AppointmentDate appointmentDate={issued_at} showTimezone={false} />
        </S.Content>
      )}
    </S.Wrapper>
  );
}

export default ConsultDetails;
