import { colors, size, mediaQueries, typography } from '@everlywell/leaves';
import styled from 'styled-components';

interface SevereNoteProps {
  addMargin: boolean;
}

const SevereNoteBox = styled.div<SevereNoteProps>`
  background-color: ${colors.red1};
  height: auto;
  width: 100%;
  margin: ${(props) => (props.addMargin ? `${size.xl1}px 0` : `0`)};
  padding: ${size.md}px;
  border-radius: ${size.xs2}px;
  display: flex;
`;

const SevereNoteText = styled.p`
  ${typography.bodyTextSmall};
  width: 487px;
  height: auto;

  ${mediaQueries.forPhoneOnly} {
    width: 258px;
    height: 168px;
  }
`;
const Image = styled.img`
  width: ${size.lg}px;
  height: ${size.lg}px;
  margin: 0 ${size.sm}px 59px 0;
  object-fit: contain;
  ${mediaQueries.forPhoneOnly} {
    margin: 0 ${size.sm}px 143px 0;
  }
`;

export { SevereNoteBox, SevereNoteText, Image };
