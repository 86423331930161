import { size, colors, typography, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

import { BaseCard, CardButton } from '../BaseCard.styles';

const ICON_SIZE = 92;

export const Container = styled(BaseCard)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQueries.forTabletVerticalUp} {
    display: grid;
    grid-template-columns: ${ICON_SIZE}px 1fr auto;
    column-gap: ${size.lg}px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${ICON_SIZE}px;
  margin-bottom: ${size.md}px;
  background-color: ${colors.teal1};
  border-radius: 50%;
  height: ${ICON_SIZE}px;
`;

export const Icon = styled.img`
  width: ${ICON_SIZE + 8}px;
`;

export const Name = styled.div`
  ${typography.buttonTextSmall};
  margin-bottom: ${size.xs1}px;
`;

export const ReleasedOn = styled.div`
  ${typography.captionText};
  margin-bottom: ${size.md}px;

  ${mediaQueries.forTabletVerticalUp} {
    margin-bottom: 0;
  }
`;

export const CTA = styled(CardButton)`
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    align-self: end;
  }
`;
