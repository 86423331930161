/* istanbul ignore file */
import ErrorIcon from 'common/images/error-identity.svg';
import {
  CONFIRM_IDENTIFIERS_FORM as DATA_TEST,
  REPLACEMENT,
} from 'common/utils/constants/dataTest';
import Layout from 'components/Layout';
import ConfirmIcon from 'pages/ReplacementConfirmation/images/shipping.svg';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import * as S from './styles';

export const ConfirmIdentifiersPostActionContainer = () => {
  const { action } = useParams();
  let icon;
  let title;
  let subTitle;
  let url;
  let buttonTitle;
  let ariaLabel;

  switch (action) {
    case 'replacement':
      icon = ErrorIcon;
      title = 'Replacement required';
      subTitle =
        'Unfortunately this kit needs to be replaced because the sample has expired.';
      url = '/replacement';
      buttonTitle = 'Order free replacement';
      ariaLabel = 'Replacement';
      break;
    case 'customer_support':
      icon = ErrorIcon;
      title = 'Something went wrong';
      subTitle =
        'We weren’t able to confirm your information, but someone from our Customer Experience team will be reaching out within 1 business day to help resolve this issue. We strive to give you the best experience possible and appreciate your patience.';
      url = '/dashboard';
      buttonTitle = 'Go to dashboard';
      ariaLabel = 'Dashboard';
      break;
    case 'confirmed':
      icon = ConfirmIcon;
      title = 'Information confirmed!';
      subTitle =
        'You will receive an email update shortly when your sample begins processing. Be sure to check your results dashboard for the most up-to-date timing on when you should expect your results!';
      url = '/dashboard';
      buttonTitle = 'Go to dashboard';
      ariaLabel = 'Dashboard';
      break;
    case 'program_ended':
      icon = ErrorIcon;
      title = 'The time limit to confirm information for this test has passed';
      subTitle =
        'This means the lab can no longer send a replacement kit. We’re sorry for the inconvenience. Please get in touch with your doctor to discuss your screening options or contact your health plan to find out if you are eligible for another at-home test kit.';
      url = '/dashboard';
      buttonTitle = 'Go to dashboard';
      ariaLabel = 'Dashboard';
      break;
  }

  return (
    <Layout>
      <S.Wrapper data-test={REPLACEMENT.CONFIRMATION_WRAPPER}>
        <S.AlertLg>
          <S.Image src={icon} />
          <S.Heading>{title}</S.Heading>
          <S.SubHeading>{subTitle}</S.SubHeading>
          <S.ButtonWrapper>
            <S.BackButton
              href={url}
              component={Link}
              data-test={DATA_TEST.POST_ACTION_BUTTON}
              aria-label={ariaLabel}
              trackingData={{
                label: buttonTitle,
                category: 'Confirm Identifiers page',
              }}
            >
              {buttonTitle}
            </S.BackButton>
          </S.ButtonWrapper>
        </S.AlertLg>
      </S.Wrapper>
      <S.Accent />
    </Layout>
  );
};
export default ConfirmIdentifiersPostActionContainer;
