import { ActionT } from 'common/utils/types';
import { fromJS } from 'immutable';

import { ImmutableEntitiesState } from './types';

export const initialState: ImmutableEntitiesState = fromJS({
  users: {},
  physician_contact: {},
  kits: {},
  labs: {},
  markers: {},
  marker_results: {},
  tests: {},
  content_snippets: {},
});

export default function entitiesReducer(
  state: ImmutableEntitiesState = initialState,
  action: ActionT,
): ImmutableEntitiesState {
  if (action.payload && action.payload.entities) {
    return state.mergeDeep(action.payload.entities);
  }

  return state;
}
