import { getDaysInMonth } from 'date-fns';
import React, { useState, SyntheticEvent, useEffect } from 'react';

import {
  Select,
  ErrorText,
  FormFieldType,
  OptionsType,
  FormValueType,
} from '..';

import {
  monthOptions as months,
  minTwoDigits,
  getRange,
  MIN_REQUIRED_AGE,
  MAX_AGE,
  DEFAULT_NUM_OF_DAYS,
  CURRENT_YEAR,
} from './lib';
import * as S from './styles';

function getDateParts(dateValue: string) {
  const datePieces = dateValue.split('-');
  const [year = '', month = '', day = ''] = datePieces;

  return {
    year,
    month,
    day,
  };
}

function getYearOptions() {
  const currentYear = CURRENT_YEAR;
  const youngestAllowedYear = currentYear - MIN_REQUIRED_AGE;
  const oldestAllowedYear = currentYear - MAX_AGE;
  const yearRange = getRange(youngestAllowedYear, oldestAllowedYear);

  return yearRange.map(year => ({
    label: `${year}`,
    value: `${year}`,
  }));
}

function getDayOptions(month: string, year: string) {
  let daysInMonth: number;
  const dayOptions: OptionsType[] = [];

  if (!month || !year) {
    daysInMonth = DEFAULT_NUM_OF_DAYS;
  } else {
    daysInMonth = getDaysInMonth(new Date(+year, +month - 1));
  }

  for (let day = 1; day <= daysInMonth; day += 1) {
    dayOptions.push({ label: `${day}`, value: minTwoDigits(day) });
  }

  return dayOptions;
}

function getBirthDateOptions(month: string, year: string) {
  const yearOptions = getYearOptions();
  const dayOptions = getDayOptions(month, year);

  return {
    yearOptions,
    monthOptions: months,
    dayOptions,
  };
}

type Props = {
  value: FormValueType;
  label: string;
  name: string;
  error?: string;
  onChange: (formField: FormFieldType) => void;
  [key: string]: any;
};

function DateOfBirthSelectGroup(props: Props) {
  const {
    name: rootName,
    error,
    value: dateValue,
    onChange,
    label,
    ...rest
  } = props;

  const [hasErrors, setHasErrors] = useState({
    month: false,
    day: false,
    year: false,
  });

  const [dateParts, setDateParts] = useState(
    getDateParts(typeof dateValue === 'string' ? dateValue : ''),
  );

  const { month, day, year } = dateParts;

  const { monthOptions, dayOptions, yearOptions } = getBirthDateOptions(
    month,
    year,
  );

  useEffect(() => {
    setDateParts(getDateParts(typeof dateValue === 'string' ? dateValue : ''));
  }, [dateValue]);

  useEffect(() => {
    // only submit dob value to the form when we have all date values
    if (month && day && year) {
      onChange({
        name: rootName,
        value: `${year}-${month}-${day}`,
      });
    }
    if (error) {
      setHasErrors({
        month: !month,
        day: !day,
        year: !year,
      });
    }
  }, [dateParts, error]);

  function handleChange(event: SyntheticEvent<HTMLSelectElement>) {
    const { currentTarget } = event;
    const { name, value } = currentTarget;

    setDateParts(prevDateParts => ({
      ...prevDateParts,
      [name]: value,
    }));
  }

  function handleBlur(event: SyntheticEvent<HTMLSelectElement>) {
    const { currentTarget } = event;
    const { name, value } = currentTarget;

    setHasErrors(prevErrors => ({
      ...prevErrors,
      [name]: value === '',
    }));
  }

  const requiredError =
    hasErrors.month || hasErrors.day || hasErrors.year
      ? 'Date of birth is required'
      : '';

  const errorMessage = error || requiredError;

  return (
    <>
      <S.Heading id="dateOfBirth">{label}</S.Heading>
      <S.SelectGroup role="group" aria-labelledby="dateOfBirth">
        <Select
          aria-label="date of birth month"
          options={monthOptions}
          {...rest}
          onChange={handleChange}
          onBlur={handleBlur}
          value={month}
          name="month"
          label="Month"
          hasError={hasErrors.month}
        />
        <Select
          options={dayOptions}
          aria-label="date of birth day"
          {...rest}
          onChange={handleChange}
          onBlur={handleBlur}
          value={day}
          name="day"
          label="Day"
          hasError={hasErrors.day}
        />
        <Select
          options={yearOptions}
          aria-label="date of birth year"
          {...rest}
          onChange={handleChange}
          onBlur={handleBlur}
          value={year}
          name="year"
          label="Year"
          hasError={hasErrors.year}
        />
      </S.SelectGroup>
      <ErrorText>{errorMessage}</ErrorText>
    </>
  );
}

export default DateOfBirthSelectGroup;
