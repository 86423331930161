import { TrackVisibilityWrapper } from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { hasRatedPersonalization } from 'common/utils/helpers';
import FoodSensitivityHealthProfileTable from 'pages/KitResultDetailPage/BeforeYouDiveInSection/components/FoodSensitivityHealthProfileTable';
import { debounce } from 'lodash';
import React, { useState, useRef, RefObject, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';

import PersonalizationCheckIn from '../PersonalizationCheckIn';
import brandMark from './images/brandMark.svg';
import WhatWeMeasure from './images/WhatWeMeasure.svg';
import YourResults from './images/YourResults.svg';
import * as S from './styles';

type Props = {
  conditions: string[];
  viewedAt?: string;
};

function BeforeYouDiveInSection({ conditions, viewedAt }: Props) {
  const [open, setOpen] = useState(!viewedAt);
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const getElementHeight = (ref?: RefObject<HTMLDivElement>): string =>
    ref?.current ? `${ref.current.getBoundingClientRect().height}px` : '0px';

  const [height, setHeight] = useState(getElementHeight(contentRef));

  useEffect(() => {
    const handleResize = () => {
      setHeight(getElementHeight(contentRef));
    };
    window.addEventListener('resize', debounce(handleResize, 100));
    handleResize();
    return () => {
      window.removeEventListener('resize', debounce(handleResize, 100));
    };
  }, [contentRef, activeIndex]);

  const sectionAnimation = useSpring({
    to: {
      opacity: open ? 1 : 0,
      height: open ? height : '0px',
    },
  });

  let conditionsString;
  switch (conditions.length) {
    case 3:
      conditionsString = `You indicated that you had a history of ${conditions[0]}, ${conditions[1]}, and ${conditions[2]}. This may not be reflected in your test results.`;
      break;
    case 2:
      conditionsString = `You indicated that you had a history of ${conditions[0]} and ${conditions[1]}. This may not be reflected in your test results.`;
      break;
    case 1:
      conditionsString = `You indicated that you had a history of ${conditions[0]}. This may not be reflected in your test results.`;
      break;
    default:
      conditionsString = '';
  }

  return (
    <TrackVisibilityWrapper label={ANALYTICS.LABELS.FS_WIDGET}>
      <div id="fsPersonalization"></div>
      <S.SectionWrapper lg={10} lgOffset={1} md={10} mdOffset={1} xs={12}>
        <S.SectionHeader>HEALTH PROFILE</S.SectionHeader>
        <S.Section>
          <S.Intro>
            <S.IntroIcon alt="" src={brandMark} />
            <S.IntroCopyWrapper>
              <S.IntroHeader>Before you dive into your results</S.IntroHeader>
              <S.IntroText>{conditionsString}</S.IntroText>
            </S.IntroCopyWrapper>
          </S.Intro>
          <animated.div style={sectionAnimation}>
            <div ref={contentRef}>
              <S.InfoBlockContainer>
                <S.InfoBlock>
                  <div>
                    <S.IconWrapper>
                      <S.Icon alt="" src={YourResults} />
                    </S.IconWrapper>
                  </div>
                  <S.InfoBlockContent>
                    <S.InfoBlockTitle>Your results</S.InfoBlockTitle>
                    <S.InfoBlockText>
                      Results are based on your current diet, so if you haven’t
                      consumed a certain food for a longer period of time, it
                      isn’t likely to show up in your results.
                    </S.InfoBlockText>
                  </S.InfoBlockContent>
                </S.InfoBlock>
                <S.InfoBlock>
                  <div>
                    <S.IconWrapper>
                      <S.Icon alt="" src={WhatWeMeasure} />
                    </S.IconWrapper>
                  </div>
                  <S.InfoBlockContent>
                    <S.InfoBlockTitle>What we measure</S.InfoBlockTitle>
                    <S.InfoBlockText>
                      This test measures IgG antibody reactivity to determine
                      suspected foods to consider for a temporary elimination
                      diet and add-back challenge.
                    </S.InfoBlockText>
                  </S.InfoBlockContent>
                </S.InfoBlock>
              </S.InfoBlockContainer>
              <FoodSensitivityHealthProfileTable
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                responses={conditions}
              />
            </div>
          </animated.div>
          <S.ContainerToggleButton onClick={() => setOpen(!open)}>
            See {open ? 'less' : 'more'} information <S.UpCaretIcon />
          </S.ContainerToggleButton>
        </S.Section>
        {!hasRatedPersonalization('FS Celiac Personalization') ? (
          <PersonalizationCheckIn
            widget="FS Celiac Personalization"
            hasToolTip
          />
        ) : null}
      </S.SectionWrapper>
    </TrackVisibilityWrapper>
  );
}

export default BeforeYouDiveInSection;
