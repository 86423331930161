import {
  colors,
  typography,
  H5,
  size,
  mediaQueries,
  Button,
} from '@everlywell/leaves';
import styled from 'styled-components';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${size.xl4}px;
`;

const CardTitle = styled.div`
  ${typography.overlineText}
  margin: 0 129px ${size.sm}px 0;
`;

const Heading = styled(H5)`
  color: ${colors.black};
  line-height: ${size.xl1}px;
  font-weight: ${typography.weight.xbold};
  margin: 0;
`;

const LineBreakSmall = styled.span`
  display: block;

  ${mediaQueries.forTabletVerticalUp} {
    display: inline;
  }
`;

const Info = styled.div`
  max-width: 80%;
  box-sizing: content-box;
  text-align: center;
  font-weight: ${typography.weight.regular};
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #767676;

  ${mediaQueries.forDesktopUp} {
    max-width: 644px;
  }

  p {
    margin-bottom: ${size.sm}px;
  }
`;

const ActionItems = styled.div`
  display: flex;
  margin-bottom: 0;
  margin-top: ${size.xl4}px;
`;

const Icon = styled.div`
  align-self: center;
  height: 100px;
  margin-top: ${size.lg}px;
  margin-bottom: 0;
`;

const Link = styled.a`
  color: #19a0e2;
  text-decoration: none;
`;

const RegisterKitButton = styled(Button)`
  min-width: 250px;
`;

export {
  Heading,
  Info,
  ActionItems,
  Icon,
  Link,
  LineBreakSmall,
  CardWrapper,
  CardTitle,
  RegisterKitButton,
};
