import { ImmutableReduxState } from 'common/utils/types';
import SectionHeader, {
  Props as SectionHeaderProps,
} from 'components/SectionHeader';
import React from 'react';
import { connect } from 'react-redux';
import { makeSelectContent } from 'store/selectors';

import * as S from './styles';

const CONTENT_KEYS = {
  header: 'CARDS_HEADING',
  description: 'CARDS_DESCRIPTION',
  iconSrc: 'CARDS_HEADING_ICON',
  alt: 'CARDS_HEADING_ICON_ALT',
};

type OwnProps = {
  contentToken: string;
};

export function CardsSectionHeaderContainer(props: SectionHeaderProps) {
  const hasContent = !!props.header;

  if (!hasContent) {
    return null;
  }

  return (
    <S.CardsSectionHeaderContainer>
      <SectionHeader {...props} />
    </S.CardsSectionHeaderContainer>
  );
}

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): SectionHeaderProps {
  return makeSelectContent(ownProps.contentToken, CONTENT_KEYS)(state);
}

export default connect(mapStateToProps)(CardsSectionHeaderContainer);
