import React from 'react';

import { StepsIconType } from '../model';
import { StepType } from './model';
import StepIcon from './StepIcon';
import * as S from './styles';

export type StepProps = {
  key: string;
  step: StepType;
  icon: StepsIconType | undefined;
  width: number;
};

const Step = ({ step, icon, width }: StepProps) => {
  const hasIcon = Boolean(icon);

  return (
    <S.Step width={width} icon={hasIcon}>
      <S.Wrapper>
        {icon && <StepIcon type={icon} />}

        <S.Description icon={hasIcon}>{step?.description}</S.Description>
      </S.Wrapper>
      <S.StepImg>
        <S.Image src={step?.image} alt={step?.alt} />
      </S.StepImg>
    </S.Step>
  );
};

export default React.memo(Step);
