import {
  H2 as LeavesH2,
  H5 as LeavesH5,
  colors,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import { hexToRGB } from 'common/utils/helpers';
import styled from 'styled-components';

import * as BaseStyles from '../AppointmentConfirmationPage/AppointmentConfirmationPage.styles';
import PatientPortalImage from '../Images/patient_portal.jpg';

export * from '../AppointmentConfirmationPage/AppointmentConfirmationPage.styles';

export const Container = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
    height: 100%;
    margin: auto;

    > div {
      width: 50%;
    }
  }
`;

export const PromoContainer = styled.div`
  height: 832px;

  ${mediaQueries.forTabletHorizontalUp} {
    height: auto;
  }
`;

export const ImageWrapper = styled.div`
  align-items: stretch;
  background-image: linear-gradient(
      180deg,
      rgba(${hexToRGB(colors.black)}, 0) 0%,
      rgba(${hexToRGB(colors.black)}, 0.7) 80.79%
    ),
    url('${PatientPortalImage}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  padding: 0 ${size.xl2}px;
  ${mediaQueries.forTabletHorizontalUp} {
    height: calc(100%);
  }
`;

export const H2 = styled(LeavesH2)`
  margin-top: 0;
  color: ${colors.white};
`;

export const H5 = styled(LeavesH5)`
  color: ${colors.white};
  font-weight: ${typography.weight.book};
`;

export const PromoButton = styled(BaseStyles.AppointmentsButton)`
  width: auto;

  ${mediaQueries.forTabletVerticalUp} {
    margin: ${size.xl3}px 0 calc(${size.xl4}px + ${size.xl3}px);
    width: fit-content;
  }
`;

export const AppointmentsButton = styled(BaseStyles.AppointmentsButton)`
  ${mediaQueries.forTabletVerticalUp} {
    margin: ${size.xl3}px 0 0;
  }
`;
