import { mediaQueries } from '@everlywell/leaves';
import { Col_12_Span_10 } from 'common/styles/grid-styles';
import styled from 'styled-components';

const ListHeaderWrapper = styled(Col_12_Span_10)`
  margin-bottom: 0;
  padding-bottom: 40px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 0;
    padding-bottom: 48px;
  }
`;

export { ListHeaderWrapper };
