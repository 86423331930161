const INTAKE_STEPS = {
  signup: {
    label: 'Sign Up',
    value: 0,
    component: null,
  },
  shipping: {
    label: 'Shipping',
    value: 1,
    component: null,
  },
  payment: {
    label: 'Payment',
    value: 2,
    component: null,
  },
};

const INTAKE = {
  LOADING_ANIMATION: 'loading-animation',
  INTAKE_STEPS_CONTAINER: 'intake-steps-container',
};

export { INTAKE, INTAKE_STEPS };
