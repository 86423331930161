import { colors, typography } from '@everlywell/leaves';
import { hexToRGB } from 'common/utils/helpers';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-weight: ${typography.weight.regular};
  position: relative;
  padding-top: 24px;
`;

const Label = styled.label`
  position: absolute;
  font-size: 16px;
  top: 24px;
  user-select: none;
  font-style: italic;
  font-weight: ${typography.weight.bold};
  line-height: 50px;
  left: 16px;
  color: ${colors.gray4};
  transition: all 100ms ease-in;

  &[data-has-error='true'],
  &[data-is-empty='false'],
  &[data-is-focused='true'],
  &[for='email'] {
    top: 0;
    left: 0;
    font-style: normal;
    line-height: 1.71;
    color: ${colors.gray4};
  }
`;

const Input = styled.input`
  font-family: inherit;
  font-weight: inherit;
  border-radius: 3px;
  height: 48px;
  width: 100%;
  background-color: ${colors.white};
  box-shadow: 4px 4px 15px -5px rgba(0, 0, 0, 0.1);
  border: solid 1px ${colors.green2};
  padding: 0 16px;
  outline: 0;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.11px;
  color: ${colors.gray4};

  &::placeholder {
    opacity: 1;
    color: ${colors.gray4};
  }

  &[data-is-empty='true'] {
    &::placeholder {
      font-style: italic;
      color: ${colors.gray4};
    }
  }

  &:read-only {
    background-color: ${colors.gray1};
  }

  &:focus {
    border: 1px solid ${colors.gray2};
    border-bottom: 1px solid ${colors.teal5};
    box-shadow: ${colors.teal5} 0 0 0 1px inset;
    caret-color: ${colors.teal4};
    ~ label {
      color: ${colors.teal6};
    }
  }

  &[data-has-error='true'] {
    background-color: rgba(${hexToRGB(colors.red2)}, 0.1);
    border-bottom: 2px solid ${colors.red2};
    caret-color: ${colors.red2};
  }

  &:-moz-focusring {
    outline: none;
  }
`;

export { Wrapper, Label, Input };
