import { colors, size } from '@everlywell/leaves';
import VisuallyHidden from 'components/VisuallyHidden';
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import * as S from './LoadingSkeleton.styles';

const inputHeight = `${size.xl3}px`;
const titleHeight = `${size.lg}px`;
const marketingTitleHeight = '19px';
const buttonHeight = `${size.xl3}px`;
const subtitleColor = colors.gray1;
const buttonColor = colors.gray1;
// Note: these colors are not in the styleguide on purpose
const subtitleHighlightColor = '#e8e8e8';
const inputColor = '#fafafa';

export type LoadingSkeletonProps = {
  /** This is the text that is displayed in the component*/
  text?: string;
};

/**
 * Skeleton component for loading states
 *
 * (taken from OldSettingsPage/SettingsFormSkeleton)
 */
function LoadingSkeleton(props: LoadingSkeletonProps) {
  return (
    <S.Wrapper>
      <VisuallyHidden>Loading...</VisuallyHidden>
      <S.FormWrapper>
        <FourFields />
        <CustomFields />
        <CheckboxField />
        <Button />
      </S.FormWrapper>
    </S.Wrapper>
  );
}

function FourFields() {
  return (
    <S.FourFieldsWrapper>
      <S.FourFieldsTitle>
        <SkeletonTheme
          highlightColor={subtitleHighlightColor}
          baseColor={subtitleColor}
        >
          <Skeleton height={titleHeight} />
        </SkeletonTheme>
      </S.FourFieldsTitle>
      <SkeletonTheme baseColor={inputColor}>
        <S.FourFieldsInputWrapper>
          <div>
            <Skeleton height={inputHeight} />
          </div>
          <div>
            <Skeleton height={inputHeight} />
          </div>
          <div>
            <Skeleton height={inputHeight} />
          </div>
          <div>
            <Skeleton height={inputHeight} />
          </div>
        </S.FourFieldsInputWrapper>
      </SkeletonTheme>
    </S.FourFieldsWrapper>
  );
}

function CustomFields() {
  return (
    <S.CustomFieldsWrapper>
      <S.CustomFieldsContainer>
        <S.CustomInputTitle>
          <SkeletonTheme
            highlightColor={subtitleHighlightColor}
            baseColor={subtitleColor}
          >
            <Skeleton height={titleHeight} />
          </SkeletonTheme>
        </S.CustomInputTitle>
        <SkeletonTheme baseColor={inputColor}>
          <S.DateOfBirthInputsWrapper>
            <div>
              <Skeleton height={inputHeight} />
            </div>
            <div>
              <Skeleton height={inputHeight} />
            </div>
            <div>
              <Skeleton height={inputHeight} />
            </div>
          </S.DateOfBirthInputsWrapper>
        </SkeletonTheme>
      </S.CustomFieldsContainer>
      <S.CustomFieldsContainer>
        <S.CustomInputTitle>
          <SkeletonTheme
            highlightColor={subtitleHighlightColor}
            baseColor={subtitleColor}
          >
            <Skeleton height={titleHeight} />
          </SkeletonTheme>
        </S.CustomInputTitle>
        <SkeletonTheme baseColor={inputColor}>
          <S.BiologicalSexInputsWrapper>
            <div>
              <Skeleton height={inputHeight} />
            </div>
            <div>
              <Skeleton height={inputHeight} />
            </div>
          </S.BiologicalSexInputsWrapper>
        </SkeletonTheme>
      </S.CustomFieldsContainer>
    </S.CustomFieldsWrapper>
  );
}

function CheckboxField() {
  return (
    <SkeletonTheme baseColor={inputColor}>
      <S.CheckboxWrapper>
        <S.Checkbox>
          <Skeleton height={inputHeight} />
        </S.Checkbox>
        <S.CheckboxTextContainer>
          <S.CheckboxText>
            <Skeleton height={marketingTitleHeight} />
          </S.CheckboxText>
          <S.CheckboxText>
            <Skeleton height={marketingTitleHeight} />
          </S.CheckboxText>
        </S.CheckboxTextContainer>
      </S.CheckboxWrapper>
    </SkeletonTheme>
  );
}

function Button() {
  return (
    <S.ButtonWrapper>
      <SkeletonTheme
        highlightColor={subtitleHighlightColor}
        baseColor={buttonColor}
      >
        <Skeleton height={buttonHeight} />
      </SkeletonTheme>
    </S.ButtonWrapper>
  );
}

export default LoadingSkeleton;
