import { VisuallyHidden } from '@everlywell/leaves';
import useUser from 'common/hooks/useUser';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { DOWNLOAD_APP_ON_APP_STORE_URL } from 'common/utils/constants/urls';
import { hashSha1 } from 'common/utils/helpers';
import { iosMessage } from 'common/utils/trackers/ios';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import { AddToCalendar } from 'components/AddToCalendar';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Navigate } from 'react-router-dom';

import { useNativeSchedulingContext } from '../../context/NativeSchedulingContext';
import AppStoreIcon from '../Images/download-app-store.svg';
import iPhoneImage from '../Images/iPhone.png';
import OrangeShape from '../Images/orange-shape.svg';
import TealShapes from '../Images/teal-shapes.svg';
import * as S from './AppointmentConfirmationPage.styles';

export type AppointmentConfirmationPageProps = {};

/**
 * This is the  appointment confirmation page
 * where a user can also add their appointment to their calendar.
 */
function AppointmentConfirmationPage(props: AppointmentConfirmationPageProps) {
  const { state } = useNativeSchedulingContext();
  const { confirmedAppointment, selectedProgram } = state;

  const isMobileApp = sessionStorage.getItem('embedded') === 'true';

  const { data: userData } = useUser();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'VCV Booking',
        program: selectedProgram,
        hashedEmail: hashSha1(userData?.data?.email || ''),
        userId: userData?.data?.id,
      },
    });

    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        label: 'appointment confirmation',
        program: selectedProgram,
      },
    });

    iosMessage(
      'didBookAppointment',
      'AppointmentConfirmationPage',
      selectedProgram,
    );
  }, [selectedProgram, userData?.data?.email, userData?.data?.id]);

  const appPromoContainerEl = useScrolledIntoViewOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: 'VCV scheduling app on ramp',
        program: selectedProgram,
      },
    });
  });

  const handleDownloadAppButtonClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'VCV scheduling app store link',
        program: selectedProgram,
      },
    });
  };

  /**
   * In case user refreshes the page, confirmedAppointment
   * will be null and we should redirect them to the virtual care dashboard
   */
  if (!confirmedAppointment) return <Navigate to="/virtual-care" replace />;

  return (
    <S.Container>
      <AddToCalendar />
      {!isMobileApp && (
        <S.AppPromoContainer ref={appPromoContainerEl}>
          <S.Text>Get the Everlywell app today</S.Text>
          <S.Copy>
            Now that you've scheduled, download the app to get timely reminders
            and join your appointment
          </S.Copy>
          <S.AppImagesWrapper>
            <img src={TealShapes} alt="" />
            <img src={iPhoneImage} alt="" />
            <img src={OrangeShape} alt="" />
          </S.AppImagesWrapper>

          <S.AppStoreLink
            href={DOWNLOAD_APP_ON_APP_STORE_URL}
            target="_blank"
            rel="noreferrer"
            onClick={handleDownloadAppButtonClick}
          >
            <img src={AppStoreIcon} alt="" />
            <VisuallyHidden>Download the App Button</VisuallyHidden>
          </S.AppStoreLink>
        </S.AppPromoContainer>
      )}
    </S.Container>
  );
}

export default AppointmentConfirmationPage;
