import { typography, size } from '@everlywell/leaves';
import styled from 'styled-components';

const Header = styled.header`
  display: flex;
  font-size: 14.2px;
  justify-content: space-between;
  line-height: ${size.xl1}px;
  margin-bottom: ${size.xs1}px;
`;

const UpdatedHeading = styled.div`
  ${typography.overlineText}
`;

const Number = styled.div`
  ${typography.overlineText}
`;

const KitList = styled.div`
  margin: 0;
  padding: 0;
`;

export { Header, UpdatedHeading as Heading, Number, KitList };
