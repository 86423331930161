/* eslint-disable @typescript-eslint/no-var-requires */
import { mediaQueries, typography } from '@everlywell/leaves';
import { bodyCopyLarge } from 'common/styles/app-styles';
import Button from 'components/Button';
import styled from 'styled-components';

export const Wrapper = styled(Button)`
  ${bodyCopyLarge};
  font-weight: ${typography.weight.regular};
  margin-bottom: 0;
  border-radius: 0;
  text-transform: capitalize;
  letter-spacing: 0;
  border-left-width: 1px;
  border-right-width: 1px;
  width: auto;
  min-width: 195px;
  height: 48px;
  padding: 5px;

  ${mediaQueries.forPhoneOnly} {
    font-size: 16px;
    width: 100%;
    min-width: auto;
  }

  &:focus {
    position: relative;
  }

  &:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left-width: 2px;
  }

  &:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-right-width: 2px;
  }
`;

export const GroupLabel = styled.span`
  display: inline;
  ${mediaQueries.forPhoneOnly} {
    display: none;
  }
`;
