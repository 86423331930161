import { Col, Row, LocalizeDisabled } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { API_ERRORS, HIPPA_URL, PRODUCT_URL } from 'common/utils/constants';
import { REGISTRATION_MODAL_ACTIONS as MODAL_ACTIONS } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { REGISTRATION_CONFIRMATION as DATA_TEST } from 'common/utils/constants/dataTest';
import { RUM_ACTIONS } from 'common/utils/constants/rumActions';
import { DEFAULT_BOX_IMAGE_URL } from 'common/utils/constants/urls';
import { sanitize } from 'common/utils/domHelpers';
import { jumpToSection, toTitleCase } from 'common/utils/helpers';
import {
  compareAddresses,
  validateUserInfo,
} from 'common/utils/registrationHelpers';
import {
  KitRegistrationUser,
  ThirdPartyRegistration,
  AddressSuggestion,
  AddressDetails,
} from 'common/utils/types';
import useTimeTracker from 'common/utils/useTimeTracker';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import AddressInformation from '../TestInformationForm/AddressInformation';
import Consent from '../TestInformationForm/Consent';
import ContactInformation from '../TestInformationForm/ContactInformation';
import PersonalInformation from '../TestInformationForm/PersonalInformation';
import ThirdPartyId from '../TestInformationForm/ThirdPartyId';
import ConfirmationErrorModal from './ConfirmationErrorModal';
import ConfirmationErrorWithAddressModal from './ConfirmationErrorWithAddressModal';
import ConfirmationErrorWithSuggestionsModal from './ConfirmationErrorWithSuggestionsModal';
import CovidScreenerConfirmation from './CovidScreenerConfirmation';
import * as S from './styles';

interface Props {
  user: KitRegistrationUser;
  onSubmit: Function;
  productImageURL: string;
  testName: string;
  isCovidKit: boolean;
  minimum_age: number;
  barcode: string;
  previouslyRegistered: boolean;
  customTermsEnabled: boolean;
  customTerms: string;
  consumerContainsSex: boolean;
  consumerContainsDOB: boolean;
  errors?: string;
  isAddressError?: boolean;
  setErrors?: React.Dispatch<React.SetStateAction<string>>;
  addressSuggestions?: AddressSuggestion[];
  onScreenerEdit?: () => void;
  thirdPartyId?: ThirdPartyRegistration;
  isLabVisit?: boolean;
}

const formatAddress = (user: KitRegistrationUser) =>
  `${user.streetAddress.value} ${
    user.subAddress.value ? user.subAddress.value : ''
  } ${user.addressCity.value}, ${user.addressState.value} ${
    user.addressZipCode.value
  }`;

const Confirmation = ({
  productImageURL,
  testName,
  user,
  isCovidKit,
  onSubmit,
  barcode,
  previouslyRegistered,
  onScreenerEdit,
  minimum_age,
  errors,
  isAddressError,
  setErrors,
  addressSuggestions,
  thirdPartyId,
  customTermsEnabled,
  customTerms,
  consumerContainsSex,
  consumerContainsDOB,
  isLabVisit,
}: Props) => {
  const [editPersonalInformation, setEditPersonalInformation] = useState(false);
  const [editAddressInformation, setEditAddressInformation] = useState(false);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showMinimumAgeModal, setShowMinimumAgeModal] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmedAddress, setConfirmedAddress] = useState<AddressDetails>();
  const [selectedSuggestion, setselectedSuggestion] =
    useState<AddressDetails>();

  const suggestions = addressSuggestions || [];
  const hasAddressSuggestions = suggestions.length > 0;

  const jumpToAddressInformationSection = () =>
    jumpToSection(true, 'addressInformation', true);

  const onSelectSuggestion = (suggestion: AddressSuggestion) => {
    setIsSubmitting(false);
    setselectedSuggestion(suggestion);

    user.streetAddress.setValue(suggestion.street);
    user.subAddress.setValue(suggestion.secondary);
    user.addressCity.setValue(suggestion.city);
    user.addressState.setValue(suggestion.state);
    user.addressZipCode.setValue(suggestion.zipcode);

    jumpToAddressInformationSection();
    setShowAddressModal(false);

    if (setErrors) {
      setErrors('');
      setShowMinimumAgeModal(false);
    }
  };

  const handleModalClose = (cta: string) => {
    const isEditingAddress = cta === MODAL_ACTIONS.EDIT_ADDRESS;
    const isReturningToConfirmation = cta === 'Return to confirmation';
    const isConfirmingAddress = [
      MODAL_ACTIONS.USE_SELECTED_ADDRESS,
      MODAL_ACTIONS.USE_ADDRESS,
    ].includes(cta);

    if (isConfirmingAddress) {
      jumpToAddressInformationSection();
      setShowAddressModal(false);

      const address = {
        street: user.streetAddress.value,
        secondary: user.subAddress.value,
        city: user.addressCity.value,
        state: user.addressState.value,
        zipcode: user.addressZipCode.value,
      };

      setConfirmedAddress(address);
    } else if (isReturningToConfirmation) {
      setShowAddressModal(false);
    } else if (isEditingAddress) {
      setEditAddressInformation(true);
      jumpToAddressInformationSection();
      setShowAddressModal(false);
    } else {
      setShowAddressModal(false);

      if (!!errors) {
        setEditAddressInformation(true);
        jumpToAddressInformationSection();
      } else {
        jumpToSection(true, 'confirmation', true);
      }
    }

    setIsSubmitting(false);

    if (setErrors) {
      setErrors('');
      setShowMinimumAgeModal(false);
    }
  };

  const disableThirdPartyIdInput = () =>
    !!(
      thirdPartyId &&
      !thirdPartyId.third_party_member_id &&
      !!thirdPartyId.third_party_id
    );

  const thirdPartyIsValid = () => {
    let isValid = true;
    if (
      thirdPartyId &&
      !thirdPartyId.third_party_member_id &&
      !thirdPartyId.third_party_id &&
      thirdPartyId.regex_format
    ) {
      const pattern = new RegExp(thirdPartyId.regex_format.toString());
      isValid = pattern.test(user.thirdPartyId.value);
    }
    return isValid;
  };

  const isSubmitDisabled = () =>
    editAddressInformation ||
    editPersonalInformation ||
    isSubmitting ||
    (customTermsEnabled && !user.customTerms.value) ||
    !thirdPartyIsValid();

  useEffect(() => {
    if (!!errors && !!isAddressError) {
      setShowAddressModal((showAddressModal) => !showAddressModal);
    } else if (
      !!errors &&
      errors.includes(API_ERRORS.kitRegistration.MINIMUM_AGE_ERROR.value)
    ) {
      setShowMinimumAgeModal(true);
    } else {
      setShowErrorModal((showErrorModal) => !!errors && !showErrorModal);
    }
  }, [errors, isAddressError]);

  const fireAction = useTimeTracker(RUM_ACTIONS.SUBMIT_REVIEW);

  const handleSaveClick = (
    value: boolean,
    setValue: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    if (value) {
      const { valid, updatedFields } = validateUserInfo(user);
      if (!valid) {
        setInvalidFields(invalidFields.concat(updatedFields));

        // Expand the Contact Information section if Phone Number is invalid to show the error message
        if (updatedFields.includes('phoneNumber')) {
          setEditAddressInformation(true);
        }
        return;
      }
    }
    setValue(!value);
  };

  const handleOnSubmit = (overrideAddress = false) => {
    if (isLabVisit) {
      const trackingData = {
        label: 'Register Test Submitted',
        category: ANALYTICS.CATEGORIES.REGISTRATION,
      };
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: trackingData,
      });
    }

    const isSuggestionSubmitted = compareAddresses(user, selectedSuggestion);

    if (isSuggestionSubmitted) {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: ANALYTICS.LABELS.SELECT_SUGGESTED_ADDRESS,
          category: ANALYTICS.CATEGORIES.REGISTRATION,
        },
      });
    }

    const isConfirmed = compareAddresses(user, confirmedAddress);

    fireAction();
    setIsSubmitting(true);
    onSubmit(overrideAddress || isConfirmed);
  };

  const kitInfoHeader = isLabVisit
    ? 'Lab request registration'
    : 'Kit Information';

  const submitButtonText = isLabVisit ? 'Register test' : 'Register Kit';

  const idLabel = isLabVisit ? 'Lab Request ID' : 'Unique Kit ID';

  return (
    <Row start="md" center="xs">
      <div id="confirmation" data-test={DATA_TEST.CONTAINER} />
      <Col xs md>
        <S.Header>Please review your information</S.Header>
      </Col>
      <Col xs={12}>
        <Row between="md">
          <Col md={6} xs={12}>
            <Row start="xs">
              <Col>
                <S.KitInfoHeader>{kitInfoHeader}</S.KitInfoHeader>
              </Col>
              <Col>
                <S.RegistrationLabel>Test Name</S.RegistrationLabel>
                <S.TestNameDetail className="inspectletIgnore">
                  {testName}
                </S.TestNameDetail>
              </Col>
              <Col>
                <S.RegistrationLabel>{idLabel}</S.RegistrationLabel>
                <S.TestNameDetail className="inspectletIgnore">
                  <LocalizeDisabled>{barcode}</LocalizeDisabled>
                </S.TestNameDetail>
              </Col>
            </Row>
          </Col>
          <S.ImageCol first="xs" md={6} xs={12} last="md">
            <S.BoxImageContainer>
              <S.BoxImage
                src={productImageURL || DEFAULT_BOX_IMAGE_URL}
                alt={testName}
              />
            </S.BoxImageContainer>
          </S.ImageCol>
        </Row>
        <S.SectionDivider />
      </Col>
      <Col xs={12}>
        <Row start="xs">
          <Col xs={12}>
            <Row between="xs">
              <Col xs={10} md>
                <S.KitInfoHeader>Personal Information</S.KitInfoHeader>
              </Col>
              <Col xs md>
                <S.EditButton
                  data-test={`${DATA_TEST.EDIT}-personal`}
                  onClick={() =>
                    handleSaveClick(
                      editPersonalInformation,
                      setEditPersonalInformation,
                    )
                  }
                >
                  {editPersonalInformation ? 'Save' : 'Edit'}
                </S.EditButton>
              </Col>
            </Row>
          </Col>
          {!editPersonalInformation && (
            <>
              <Col xs={12}>
                <Row between="xs">
                  <Col xs={6} md={6}>
                    <S.RegistrationLabel>First Name</S.RegistrationLabel>
                    <S.TestNameDetail
                      className="inspectletIgnore"
                      data-test={`${DATA_TEST.DISPLAY}-${user.firstName.id}`}
                    >
                      <LocalizeDisabled>
                        {user.firstName.value}
                      </LocalizeDisabled>
                    </S.TestNameDetail>
                  </Col>
                  <Col xs={6} md={6}>
                    <S.RegistrationLabel>Last Name</S.RegistrationLabel>
                    <S.TestNameDetail
                      className="inspectletIgnore"
                      data-test={`${DATA_TEST.DISPLAY}-${user.lastName.id}`}
                    >
                      <LocalizeDisabled>{user.lastName.value}</LocalizeDisabled>
                    </S.TestNameDetail>
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <Row between="xs">
                  <Col xs={6} md={6}>
                    <S.RegistrationLabel>Date Of Birth</S.RegistrationLabel>
                    <S.TestNameDetail
                      className="inspectletIgnore"
                      data-test={`${DATA_TEST.DISPLAY}-${user.dateOfBirth.id}`}
                    >
                      <LocalizeDisabled>
                        {user.dateOfBirth.value
                          ? moment(user.dateOfBirth.value).format('MM/DD/YYYY')
                          : ''}
                      </LocalizeDisabled>
                    </S.TestNameDetail>
                  </Col>
                  <Col xs={6} md={6}>
                    <S.RegistrationLabel>
                      Sex Assigned At Birth
                    </S.RegistrationLabel>
                    <S.TestNameDetail
                      className="inspectletIgnore"
                      data-test={`${DATA_TEST.DISPLAY}-${user.biologicalSex.name}`}
                    >
                      {toTitleCase(user.biologicalSex.value)}
                    </S.TestNameDetail>
                  </Col>
                </Row>
              </Col>
              {isCovidKit && (
                <>
                  <Col xs={12}>
                    <Row between="xs">
                      <Col xs={6} md={6}>
                        <S.RegistrationLabel>Race Name</S.RegistrationLabel>
                        <S.TestNameDetail
                          className="inspectletIgnore"
                          data-test={`${DATA_TEST.DISPLAY}-${user.race.id}`}
                        >
                          {user.race.value}
                        </S.TestNameDetail>
                      </Col>
                      <Col xs={6} md={6}>
                        <S.RegistrationLabel>Ethnicity</S.RegistrationLabel>
                        <S.TestNameDetail
                          className="inspectletIgnore"
                          data-test={`${DATA_TEST.DISPLAY}-${user.ethnicity.id}`}
                        >
                          {user.ethnicity.value}
                        </S.TestNameDetail>
                      </Col>
                    </Row>
                  </Col>
                  {user.biologicalSex.value === 'female' && (
                    <Col xs={12}>
                      <Row between="xs">
                        <Col xs={6} md={6}>
                          <S.RegistrationLabel>
                            Currently Pregnant
                          </S.RegistrationLabel>
                          <S.TestNameDetail
                            className="inspectletIgnore"
                            data-test={`${DATA_TEST.DISPLAY}-${user.pregnancyStatus.id}`}
                          >
                            {user.pregnancyStatus.value}
                          </S.TestNameDetail>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </>
              )}
            </>
          )}
          {editPersonalInformation && (
            <Col xs={12}>
              <PersonalInformation
                user={user}
                invalidFields={invalidFields}
                setInvalidFields={setInvalidFields}
                isCovid={isCovidKit}
                minimum_age={minimum_age}
                consumerContainsSex={consumerContainsSex}
                consumerContainsDOB={consumerContainsDOB}
              />
            </Col>
          )}
        </Row>
        <S.SectionDivider />
      </Col>
      <Col xs={12}>
        <Row start="xs">
          <Col xs={12}>
            <Row between="xs">
              <div id="addressInformation" />
              <Col xs={10} md>
                <S.KitInfoHeader>Address and Contact</S.KitInfoHeader>
              </Col>
              <Col xs md>
                <S.EditButton
                  data-test={`${DATA_TEST.EDIT}-address`}
                  onClick={() =>
                    handleSaveClick(
                      editAddressInformation,
                      setEditAddressInformation,
                    )
                  }
                >
                  {editAddressInformation ? 'Save' : 'Edit'}
                </S.EditButton>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <S.RegistrationLabel>Email</S.RegistrationLabel>
            <S.TestNameDetailEmail
              className="inspectletIgnore"
              data-test={`${DATA_TEST.DISPLAY}-${user.emailAddress.id}`}
            >
              <LocalizeDisabled>{user.emailAddress.value}</LocalizeDisabled>
            </S.TestNameDetailEmail>
          </Col>
          {thirdPartyId && !editAddressInformation && (
            <Col xs={12}>
              <S.RegistrationLabel>
                {thirdPartyId.third_party_id_label}
              </S.RegistrationLabel>
              <S.TestNameDetail
                className="inspectletIgnore"
                data-test={`${DATA_TEST.DISPLAY}-${user.thirdPartyId.id}`}
              >
                <LocalizeDisabled>
                  {thirdPartyId.third_party_id
                    ? thirdPartyId.third_party_id
                    : user.thirdPartyId.value}
                </LocalizeDisabled>
              </S.TestNameDetail>
            </Col>
          )}
          {thirdPartyId && editAddressInformation && (
            <Col xs={12}>
              <ThirdPartyId
                thirdPartyId={thirdPartyId}
                user={user}
                isDisabled={disableThirdPartyIdInput()}
              />
            </Col>
          )}
          <Col xs={12}>
            {!editAddressInformation && (
              <>
                <S.RegistrationLabel>Phone Number</S.RegistrationLabel>
                <S.TestNameDetail
                  className="inspectletIgnore"
                  data-test={`${DATA_TEST.DISPLAY}-${user.phoneNumber.id}`}
                >
                  <LocalizeDisabled>{user.phoneNumber.value}</LocalizeDisabled>
                </S.TestNameDetail>
              </>
            )}
            {editAddressInformation && (
              <S.ContactWrapper>
                <ContactInformation
                  user={user}
                  invalidFields={invalidFields}
                  setInvalidFields={setInvalidFields}
                />
              </S.ContactWrapper>
            )}
          </Col>
          {!editAddressInformation && (
            <Col xs={12}>
              <S.RegistrationLabel>Address</S.RegistrationLabel>
              <S.TestNameDetail className="inspectletIgnore">
                <LocalizeDisabled>{formatAddress(user)}</LocalizeDisabled>
              </S.TestNameDetail>
            </Col>
          )}
          {editAddressInformation && (
            <S.AddressCol xs={12}>
              <AddressInformation
                streetAddress={user.streetAddress}
                subAddress={user.subAddress}
                city={user.addressCity}
                state={user.addressState}
                zipCode={user.addressZipCode}
                overrideAddress={user.overrideAddress}
                invalidFields={invalidFields}
                setInvalidFields={setInvalidFields}
                shouldFocus={isAddressError && editAddressInformation}
              />
            </S.AddressCol>
          )}
        </Row>
        {!previouslyRegistered && (
          <S.CheckBoxCol>
            <S.CheckBox
              checked={!!user.wantsMarketing.checked}
              label=""
              aria-labelledby="wantsMarketingCheckboxLabel"
              name={user.wantsMarketing.name ? user.wantsMarketing.name : ''}
              onChange={() =>
                user.wantsMarketing.setValue(!user.wantsMarketing.checked)
              }
              data-test={`${DATA_TEST.CHECKBOX}-${user.wantsMarketing.id}`}
            />
            <label
              htmlFor={user.wantsMarketing.name}
              id={`${user.wantsMarketing.name}Label`}
            >
              {
                'I agree to customized suggestions and content for Everlywell marketing purposes. We will never spam you!'
              }
            </label>
          </S.CheckBoxCol>
        )}
        <Consent
          previouslyRegistered
          sms={{
            label:
              "Get real time text updates on the status of your test. We'll never include personal information",
            id: 'smsCheckbox',
            checked: user.textUpdates.checked || false,
            setValue: () =>
              user.textUpdates.setValue(!user.textUpdates.checked),
          }}
          hippa={{
            label:
              ' I have read and accept the HIPAA & Telehealth Authorizations',
            id: 'hippaCheckbox',
            checked: true,
            setValue: () => {},
          }}
          product={{
            label: 'I have read and accept the Product Consent',
            id: 'productCheckbox',
            checked: true,
            setValue: () => {},
          }}
          customTermsEnabled={customTermsEnabled}
          customTerms={
            customTermsEnabled
              ? {
                  label: customTerms,
                  id: 'customTerms',
                  checked: user.customTerms.value,
                  setValue: user.customTerms.setValue,
                }
              : undefined
          }
          hippaURL={HIPPA_URL}
          productURL={PRODUCT_URL}
        />
      </Col>
      {isCovidKit && onScreenerEdit && (
        <Row start="xs">
          <S.SectionDivider />
          <CovidScreenerConfirmation
            user={user}
            onScreenerEdit={onScreenerEdit}
          />
        </Row>
      )}
      <S.SubmitButton
        onClick={() => handleOnSubmit()}
        isDisabled={isSubmitDisabled()}
        appearance="primary"
        data-dd-action-name={RUM_ACTIONS.SUBMIT_REVIEW}
      >
        {submitButtonText}
      </S.SubmitButton>

      {showAddressModal && isAddressError && !hasAddressSuggestions && (
        <ConfirmationErrorWithAddressModal
          openModal={showAddressModal}
          setOpenModal={handleModalClose}
          title="Verify Address Details"
          message={errors || ''}
          user={user}
        />
      )}

      {showAddressModal && isAddressError && hasAddressSuggestions && (
        <ConfirmationErrorWithSuggestionsModal
          openModal={showAddressModal}
          setOpenModal={handleModalClose}
          title="Verify your address"
          message={errors || ''}
          user={user}
          suggestions={suggestions}
          onSelectSuggestion={onSelectSuggestion}
        />
      )}

      {showErrorModal && (
        <ConfirmationErrorModal
          openModal={showErrorModal}
          setOpenModal={handleModalClose}
          title="Sorry there was an error"
          message={errors || ''}
          cta="Return to confirmation"
        />
      )}

      {showMinimumAgeModal && (
        <ConfirmationErrorModal
          openModal={showMinimumAgeModal}
          setOpenModal={handleModalClose}
          title={`Sorry, you must be ${minimum_age}+ to take this test`}
          message={
            <span
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  API_ERRORS.kitRegistration.MINIMUM_AGE_ERROR.display,
                ),
              }}
            />
          }
          cta="Return to confirmation"
        />
      )}
    </Row>
  );
};
export default Confirmation;
