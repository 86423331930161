import { useParams } from 'react-router-dom';
import {
  makeSelectSession,
  makeSelectKitResult,
  makeSelectContentToken,
} from 'store/kitResult/KitResultDetailPageContainer/selectors';
import {
  makeSelectLoadingContent,
  makeSelectContent,
  makeSelectUserId,
} from 'store/selectors';

import { MapProps, StateProps } from '.';

const CONTENT_KEYS = {
  showConsult: 'SHOW_SCHEDULE_CONSULT',
};

export const useMapStateToProps = ({
  state,
  ownProps,
}: MapProps): StateProps => {
  const { kitResultIdOrPublishHash: kR } = useParams();
  const { viewingSharedKitResult } = ownProps;
  const kitResultIdOrPublishHash = kR as string;
  const session = makeSelectSession()(state);
  const userId = makeSelectUserId()(state);

  const kitResult = makeSelectKitResult(kitResultIdOrPublishHash!)(state);
  const contentToken = makeSelectContentToken(kitResultIdOrPublishHash!)(state);

  const isConsultAvailable = makeSelectContent(
    contentToken || '',
    CONTENT_KEYS,
  )(state);

  const showConsult =
    isConsultAvailable &&
    isConsultAvailable.showConsult &&
    typeof isConsultAvailable.showConsult === 'string' &&
    isConsultAvailable.showConsult.toLowerCase() === 'true';

  return {
    kitResultIdOrPublishHash,
    session,
    kitResult,
    contentToken,
    viewingSharedKitResult,
    showConsult,
    loadingContent: makeSelectLoadingContent()(state),
    testId: kitResult && kitResult.test && kitResult.test.id,
    sku: kitResult?.test?.type,
    userId,
  };
};
