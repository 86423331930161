import { Col } from '@everlywell/leaves';
import { useAnswers } from 'common/providers/AnswersProvider';
import { HEALTH_PROFILE_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';
import { HealthProfileOption, QuestionProps } from 'common/utils/types';
import React, { useState, useEffect } from 'react';

import { setResponse } from '../../helpers';

import { QuestionFooter } from '..';

import * as S from '../styles';

const HealthProfileSingleQuestion = ({
  className,
  questionData,
  questionnaireId,
}: QuestionProps) => {
  const {
    content,
    description,
    question_options: options,
    questionnaire_question_id: questionnaireQuestionId,
  } = questionData;
  const [singleAnswer, setSingleAnswer] = useState<any>([]);
  const { allAnswers } = useAnswers();

  useEffect(() => {
    if (allAnswers[questionnaireQuestionId!]) {
      const previousAnswer = allAnswers[questionnaireQuestionId!]?.options;
      setSingleAnswer(previousAnswer);
    } else {
      setSingleAnswer(null);
    }
  }, [allAnswers, questionnaireQuestionId]);

  const handleChange = (option: HealthProfileOption) => {
    setSingleAnswer([option]);
  };

  return (
    <S.QuestionContainer className={className}>
      <Col>
        <S.Question>{content}</S.Question>
        {description ? <S.SubText>{description}</S.SubText> : null}
      </Col>
      {options.map((option: HealthProfileOption) => {
        const isChecked =
          singleAnswer && singleAnswer[0]?.option_id === option.option_id;
        const optionId = option.option_id.toString();
        return (
          <S.Column key={option.option_id}>
            <S.SingleAnswer
              key={optionId}
              id={optionId}
              name={optionId}
              label={
                option.description
                  ? `${option.content}
                    ${option.description}`
                  : option.content
              }
              checked={isChecked}
              onChange={() => handleChange(option)}
              data-test={`${DATA_TEST.CONTAINER}-answers`}
            />
          </S.Column>
        );
      })}
      <QuestionFooter
        answer={singleAnswer?.length ?? 0}
        questionData={questionData}
        questionnaireId={questionnaireId}
        setResponse={() =>
          setResponse(allAnswers, questionnaireQuestionId, {
            options: singleAnswer,
          })
        }
      />
    </S.QuestionContainer>
  );
};

export default HealthProfileSingleQuestion;
