import { logError } from 'common/utils/helpers';
import { useState } from 'react';

import { HealthProfileTypes, Redirection } from './types';

export const useHealthProfile = (
  initialRedirection?: string,
): HealthProfileTypes => {
  const [redirection, setRedirection] = useState<Redirection>(
    initialRedirection || null,
  );

  const setRedirectionURL = (url: string) => {
    try {
      setRedirection(url);
      return url;
    } catch (err) {
      logError((err as Error).message, {
        component: 'useHealthProfile',
        method: 'setRedirectionURL',
      });
      return Promise.reject(err);
    }
  };

  return {
    redirection,
    setRedirectionURL,
  };
};

export default useHealthProfile;
