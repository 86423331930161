import { mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
  border-radius: 3px;
`;

const FormTitle = styled.div`
  width: 218px;
  margin-bottom: 34px;
  border-radius: 11px;

  ${mediaQueries.forTabletHorizontalUp} {
    width: 327px;
  }
`;

const GridInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 49px 24px;

  ${mediaQueries.forTabletHorizontalUp} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

const GridStateDoubleColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 18px;
  margin-top: -18px;
  width: 100%;

  ${mediaQueries.forTabletHorizontalUp} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    margin-top: 0;
  }
`;

const ButtonWrapper = styled.div`
  margin: 66px auto 0;

  ${mediaQueries.forTabletHorizontalUp} {
    margin: 62px 0 0;
  }
`;

export {
  ButtonWrapper,
  FormTitle,
  FormWrapper,
  GridInputWrapper,
  GridStateDoubleColumnWrapper,
};
