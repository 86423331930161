import {
  TelehealthAppointment,
  useTelehealthAppointments,
} from 'common/apis/telehealthApis';
import { format, parseJSON } from 'date-fns';
import ErrorCard from 'pages/AccountHub/components/ErrorCard';
import React, { useEffect, useState } from 'react';

import AppointmentCard, { LoadingAppointmentCard } from '../AppointmentCard';
import * as S from './AppointmentList.styles';

export const EMPTY_STATE_MESSAGE = {
  upcoming: 'You have no upcoming appointments to show',
  completed: 'You have no past appointments to show',
};

export type AppointmentListProps = {
  appointmentType: 'upcoming' | 'completed';
  /**
   * This prop will allow the list to only display one
   * appointment card and have the option to expand to
   * see the rest of the list
   */
  isExpandable?: boolean;
  /**
   * This prop will allow the list to have a label
   * readable by screen readers
   */
  label?: string;
  /**
   * This prop will allow the list to have a label
   * attached to an external element
   */
  labelledBy?: string;
  /**
   * This prop allows for filtering only appointments
   * from a list of programs
   */
  filterProgramSlugs?: string[];
};

/**
 * This component will fetch and display a list of virtual care appointments
 */
function AppointmentList(props: AppointmentListProps) {
  const {
    appointmentType,
    isExpandable,
    label,
    labelledBy,
    filterProgramSlugs,
  } = props;
  const period = appointmentType === 'upcoming' ? 'future' : 'past';
  const sort_by = appointmentType === 'upcoming' ? 'date_asc' : 'date_desc';

  const {
    data: response,
    isSuccess,
    isLoading,
    isError,
    refetch,
  } = useTelehealthAppointments({
    period,
    sort_by,
    program_slugs: filterProgramSlugs,
  });

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => setIsExpanded((prevValue) => !prevValue);
  const [buttonKey] = useState(() => Math.random());

  const firstHiddenAppointmentRef = React.useRef<HTMLLIElement>(null);
  useEffect(() => {
    if (isExpanded && firstHiddenAppointmentRef.current) {
      firstHiddenAppointmentRef.current.focus();
    }
  }, [isExpanded, firstHiddenAppointmentRef]);

  const appointments = response?.data?.appointments ?? [];

  const [firstAppointment, ...restAppointments] = appointments;

  const formatAppointmentLabel = (appointment: TelehealthAppointment) => {
    const formattedDate = format(parseJSON(appointment.start_time), 'PPPP');
    const formattedTime = format(parseJSON(appointment.start_time), 'h:mmaaa');

    return `Appointment on ${formattedDate} at ${formattedTime} with ${appointment.provider.credentials} ${appointment.provider.last_name}`;
  };

  return (
    <S.AppointmentsContainer
      aria-label={label}
      aria-labelledby={labelledBy}
      role="list"
    >
      {isError ? (
        <S.ListItem tabIndex={0}>
          <ErrorCard onRetry={() => refetch()} />
        </S.ListItem>
      ) : null}

      {isLoading ? (
        <S.ListItem tabIndex={0}>
          <LoadingAppointmentCard />
        </S.ListItem>
      ) : null}

      {/* show first appointment */}
      {isSuccess &&
        (firstAppointment ? (
          <S.ListItem
            tabIndex={0}
            key={firstAppointment.id}
            aria-label={formatAppointmentLabel(firstAppointment)}
          >
            <AppointmentCard appointment={firstAppointment} />
          </S.ListItem>
        ) : (
          <S.ListItem css={S.NoResultsText}>
            {EMPTY_STATE_MESSAGE[appointmentType]}
          </S.ListItem>
        ))}

      {/* show rest of appointments */}
      {isSuccess &&
        restAppointments.length > 0 &&
        (isExpandable ? (
          <>
            {isExpanded &&
              restAppointments.map((appointment, index) => (
                <S.ListItem
                  key={appointment.id}
                  tabIndex={0}
                  ref={index === 0 ? firstHiddenAppointmentRef : null}
                  aria-label={formatAppointmentLabel(appointment)}
                >
                  <AppointmentCard appointment={appointment} />
                </S.ListItem>
              ))}
            <S.ListItem key={`view-history-${appointmentType}-${buttonKey}`}>
              <S.ViewMoreButton appearance="text" onClick={toggleExpand}>
                {isExpanded ? 'Hide history' : 'View history'}
                <S.Caret isExpanded={isExpanded} style={{ width: '12px' }} />
              </S.ViewMoreButton>
            </S.ListItem>
          </>
        ) : (
          restAppointments.map((appointment) => (
            <S.ListItem
              key={appointment.id}
              tabIndex={0}
              aria-label={formatAppointmentLabel(appointment)}
            >
              <AppointmentCard appointment={appointment} />
            </S.ListItem>
          ))
        ))}
    </S.AppointmentsContainer>
  );
}

export default AppointmentList;
