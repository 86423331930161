import React from 'react';

import { StepsIconType, StepsItemsPerRow, StepsList } from './model';
import Step from './Step';
import * as S from './styles';

type Props = {
  list: StepsList[];
  icon?: StepsIconType | undefined;
  itemsPerRow?: StepsItemsPerRow;
};

const Steps = ({ icon, list, itemsPerRow }: Props) => (
  <S.Steps>
    {list.map((step) => (
      <Step
        key={step.id}
        step={step}
        icon={icon}
        width={itemsPerRow ? 100 / Number(itemsPerRow) : 45}
      />
    ))}
  </S.Steps>
);

export default Steps;
