import { Button, Localize } from '@everlywell/leaves';
import { getTelehealthProgramAppointmentTypes } from 'common/apis/telehealthApis';
import { useCommonT2TAnalytics } from 'common/hooks/useCommonT2TAnalytics';
import Grid from 'components/Grid';
import LoadingError from 'components/LoadingError';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import FloatingCtaFooter from '../../components/FloatingCtaFooter';
import { useNativeSchedulingContext } from '../../context/NativeSchedulingContext';
import { actions } from '../../state/scheduling-flow.state';
import AppointmentTypeOption from './components/AppointmentTypeOption';
import * as track from './AppointmentTypeSelection.analytics';
import AppointmentTypeSelectionSkeleton from './AppointmentTypeSelection.skeleton';
import * as S from './AppointmentTypeSelection.styles';

export type AppointmentTypeSelectionProps = {};

type FormValues = {
  program_appointment_type_id: string | null;
};

/**
 * This is a page that represents a step in the appointment scheduling flow
 * where the user selects a appointment type.
 */
function AppointmentTypeSelection(props: AppointmentTypeSelectionProps) {
  const { commonT2TAnalyticsData } = useCommonT2TAnalytics();

  const { dispatch, state, getNextStep } = useNativeSchedulingContext();

  const program = state.selectedProgram;

  const navigate = useNavigate();

  const {
    data: telehealthProgramAppointmentTypesResponse,
    isError,
    refetch,
  } = useQuery(['telehealth-program-appointment-types'], () =>
    getTelehealthProgramAppointmentTypes(program)
  );
  const availableAppointmentTypes = telehealthProgramAppointmentTypesResponse?.data?.appointment_types;

  const { register, handleSubmit, errors, watch } = useForm<FormValues>({
    defaultValues: {
      program_appointment_type_id: null
    },
  });

  /**
   * Handle saving the user's input and navigating to the next step.
   */
  const handleSaveInput = (values: FormValues) => {
    const programAppointmentType = values.program_appointment_type_id

    dispatch(actions.setSelectedAppointmentType(programAppointmentType));

    track.whenUserClicksContinue({
      program,
      extraParams: { ...commonT2TAnalyticsData },
    });

    const nextStep = getNextStep();
    navigate(nextStep ?? '/dashboard');
  };

  useEffect(() => {
    if (availableAppointmentTypes) {
      track.whenUserViewsPage({
        program,
        extraParams: {
          ...commonT2TAnalyticsData,
        },
      });
    }
  }, [commonT2TAnalyticsData, program, availableAppointmentTypes]);

  if (isError) {
    return (
      <S.Container>
        <LoadingError handleRetry={refetch} />
      </S.Container>
    );
  }

  return (
    <S.Container>
      <Grid.Container spacing={['lg']}>
        <Grid.Item width={[1]}>
          <S.Heading>Select your appointment type</S.Heading>
        </Grid.Item>

        <Grid.Item width={[1]}>
          {availableAppointmentTypes ? (
            <S.Form onSubmit={handleSubmit(handleSaveInput)}>
              <Grid.Container spacing={['lg']}>
              {availableAppointmentTypes.length > 0 ? (
                  availableAppointmentTypes.map((appointmentType) => (
                    <Grid.Item width={[1]} key={appointmentType.id}>
                      <AppointmentTypeOption
                        ref={register({ required: 'Please select an appointment type' })}
                        name="program_appointment_type_id"
                        value={String(appointmentType.id)}
                        label={appointmentType.name}
                      />
                    </Grid.Item>
                  ))
                ) : (
                  <p>You are booking the default appointment type. Please continue.</p>
                )}
                {errors.program_appointment_type_id && (
                  <Grid.Item width={[1]}>
                    <S.ErrorText>{errors.program_appointment_type_id.message}</S.ErrorText>
                  </Grid.Item>
                )}
              </Grid.Container>

              <FloatingCtaFooter
                backButton={
                  <Button
                    type="button"
                    appearance="secondary"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>
                }
                nextButton={<Button type="submit">Continue</Button>}
              />
            </S.Form>
          ) : (
            <AppointmentTypeSelectionSkeleton />
          )}
        </Grid.Item>
      </Grid.Container>
    </S.Container>
  );
}

export default AppointmentTypeSelection;
