import { RadioButton } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import {
  PreRegistrationScreenerOptionsValues,
  PreRegistrationScreenerOptionType,
  PreRegistrationScreenerAnswer,
  PreRegistrationScreenerType,
} from 'common/utils/types';
import React, { useState } from 'react';

import * as S from './styles';

type Props = {
  eligibleOptionCallback: (option: PreRegistrationScreenerAnswer) => void;
  ineligibleOptionCallback: (option: PreRegistrationScreenerAnswer) => void;
  screener: PreRegistrationScreenerType;
};

const PreRegistrationScreener: React.FC<Props> = (props: Props) => {
  const { eligibleOptionCallback, ineligibleOptionCallback, screener } = props;

  const { callToActionText, options, title: question, description } = screener;

  const [selectedOption, setSelectedOption] =
    useState<PreRegistrationScreenerOptionType | null>(null);

  const isCTADisabled = !(selectedOption && selectedOption.value);

  const handleTrackEvent = (option: PreRegistrationScreenerOptionType) => {
    const { value, label } = option || {};
    analytics.track({
      event: ANALYTICS.EVENTS.PRE_REGISTER_SCREENER_ANSWER,
      data: {
        question: question,
        answer: label,
        value: value,
      },
    });
  };

  const handleOnCTAClick = () => {
    if (selectedOption) {
      const { value, label: answer } = selectedOption;
      const option = { [question]: answer };

      handleTrackEvent(selectedOption);

      if (value === PreRegistrationScreenerOptionsValues.eligible) {
        eligibleOptionCallback(option);
      } else {
        ineligibleOptionCallback(option);
      }
    }
  };

  return (
    <S.Container>
      <S.Title>{question}</S.Title>

      {description ? <S.Description>{description}</S.Description> : null}

      {options ? (
        <S.Body data-testid="options">
          {options.map((option: PreRegistrationScreenerOptionType) => {
            const { label, value, items } = option;

            const hasValidItems = items && items.length;

            return (
              <S.RadioOption
                key={label}
                onClick={() => setSelectedOption(option)}
              >
                <S.RadioOptionHeading>
                  <RadioButton
                    id={label}
                    name="option"
                    value={value}
                    checked={
                      selectedOption ? selectedOption.label === label : false
                    }
                  />

                  <S.OptionTitle>{label}</S.OptionTitle>
                </S.RadioOptionHeading>

                {hasValidItems ? (
                  <S.RadioOptionContent>
                    <S.UL>
                      {items &&
                        items.map((item: string, idx: number) => (
                          <S.LI key={idx}>{item}</S.LI>
                        ))}
                    </S.UL>
                  </S.RadioOptionContent>
                ) : null}
              </S.RadioOption>
            );
          })}
        </S.Body>
      ) : null}

      <S.Footer>
        <S.CTA
          data-testid="cta"
          appearance="primary"
          onClick={handleOnCTAClick}
          isDisabled={isCTADisabled}
        >
          {callToActionText}
        </S.CTA>
      </S.Footer>
    </S.Container>
  );
};

export default PreRegistrationScreener;
