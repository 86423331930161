import { useEffect, useRef } from 'react';

function useClickOff(onClose: () => void) {
  const wrapperEl = useRef<HTMLDivElement>(null);

  function handleClick(e: Event) {
    if (!wrapperEl.current || !e.target) {
      return;
    }

    if (!wrapperEl.current.contains(e.target as Node)) {
      onClose();
    }
  }

  // Solves the issue of tapping away in iOS to trigger the click event on the document
  useEffect(() => {
    document.body.style.cursor = 'pointer';

    return () => {
      document.body.style.cursor = 'default';
    };
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClick, { capture: true });

    return () => {
      document.removeEventListener('click', handleClick, { capture: true });
    };
  }, []);

  return wrapperEl;
}

export default useClickOff;
