import { BaseCardSkeleton } from 'pages/AccountHub/components/BaseCard';
import React from 'react';

/**
 * Skeleton for a test result card
 */
function ReadyTestResultCardSkeleton() {
  return <BaseCardSkeleton loadingText="loading test results" />;
}

export default ReadyTestResultCardSkeleton;
