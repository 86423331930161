import { UseIsFlagActive } from 'common/utils/flags';
import { CollectionMethod } from 'common/utils/types';
import Layout from 'components/Layout';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import BloodCollection from './BloodCollection';
import CollectionDeviceImages from './CollectionDeviceImages';
import CollectionHelp from './CollectionHelp';
import CollectionInstructions from './CollectionInstruction';
import CollectionMethods from './CollectionMethods';
import CollectionTips from './CollectionTips';
import CollectionVideo from './CollectionVideos';
import MailingSample from './MailingSample';
import { TestCollectionMethod, bloodCollectionMethods } from './model';
import NasalSwabCollection from './NasalSwabCollection';
import SampleInstructions from './SampleInstructions';
import TestCollection from './TestCollection';

export type KitCollectionState = {
  sku: string;
  methods: TestCollectionMethod[];
  collection_methods: CollectionMethod[];
};

export type KitCollectionProps = KitCollectionState;

const KitCollection = () => {
  const location = useLocation();

  if (!location || !location.state) {
    return <Navigate to="/" />;
  }
  const state = location.state as KitCollectionProps;
  const {
    sku,
    collection_methods: [collection_method],
  } = state;

  const { methods = [TestCollectionMethod.default] } = state;

  const hasMethods = methods && Array.isArray(methods);
  const hasBloodMethods = hasMethods
    ? bloodCollectionMethods.some((bloodMethod) =>
        methods.includes(bloodMethod),
      )
    : false;
  const hasNasalSwabMethods = hasMethods
    ? methods.includes(TestCollectionMethod.nasal_swab)
    : false;

  if (UseIsFlagActive('kitRegistrationSerializer') && !hasNasalSwabMethods) {
    return (
      <Layout>
        <TestCollection />

        {collection_method.collection_tips.length > 0 ? (
          <CollectionTips tips={collection_method.collection_tips} />
        ) : null}

        <CollectionVideo
          methodName={collection_method.name}
          videoUrl={collection_method.video_url}
        />

        {collection_method.collection_instructions.length > 0 ? (
          <CollectionInstructions
            collectionInstructions={collection_method.collection_instructions}
          />
        ) : null}

        {collection_method.collection_device_images.length > 0 ? (
          <CollectionDeviceImages
            collectionDeviceImages={collection_method.collection_device_images}
          />
        ) : null}

        <CollectionHelp />
      </Layout>
    );
  } else {
    return (
      <Layout>
        <TestCollection />
        {hasBloodMethods ? <BloodCollection /> : null}
        <CollectionMethods
          methods={methods}
          hasNasalSwabMethod={hasNasalSwabMethods}
        />
        {hasNasalSwabMethods ? <NasalSwabCollection /> : null}
        {hasBloodMethods && (
          <>
            <MailingSample methods={methods} sku={sku} />
            <SampleInstructions methods={methods} sku={sku} />
          </>
        )}
        <CollectionHelp />
      </Layout>
    );
  }
};

export default KitCollection;
