import { ANALYTICS } from 'common/utils/constants/analytics';
import {
  SUB_NAVBAR,
  SUB_HEADER as DATA_TEST,
} from 'common/utils/constants/dataTest';
import { isEnterpriseCustomer, isHumanaCustomer } from 'common/utils/helpers';
import { renderWithProviders as render } from 'common/utils/reactTestingLibraryHelpers';
import React from 'react';

import SubNavbar, { Props } from '../SubNavbar';

const isHumanaCustomerMock = isHumanaCustomer as jest.Mock<
  ReturnType<typeof isHumanaCustomer>
>;
const isEnterpriseCustomerMock = isEnterpriseCustomer as jest.Mock<
  ReturnType<typeof isEnterpriseCustomer>
>;
const defaultProps: Props = {
  kitResultNumber: '1234',
  testId: 1,
  testName: 'Food Sensitivity',
  shouldShowTelehealthConsult: false,
};

jest.mock('common/utils/helpers', () => ({
  ...jest.requireActual('common/utils/helpers'),
  isHumanaCustomer: jest.fn(() => false),
  isEnterpriseCustomer: jest.fn(() => true),
}));

describe('given a rendered component <SubNavbar />', () => {
  const doRender = (overrides: Partial<Props> = {}) =>
    render(<SubNavbar {...defaultProps} {...overrides} />);

  describe('when is not a third party', () => {
    it('then it should render the ReferAFriendButton if user is not a third party member', () => {
      const { getByTestId } = doRender();

      expect(getByTestId(DATA_TEST.REFER_A_FRIEND_BUTTON)).toBeDefined();
    });
  });

  describe('when is a third party', () => {
    beforeEach(() => {
      isHumanaCustomerMock.mockReturnValue(true);
    });
    it('then it should not render the ReferAFriendButton if user a third party member', () => {
      const { queryByTestId } = doRender();

      expect(queryByTestId(DATA_TEST.REFER_A_FRIEND_BUTTON)).toBeNull();
    });
  });

  describe('when there is a Telehealth consult', () => {
    it('then it should render the Telehealth button', () => {
      const { getByTestId } = doRender({ shouldShowTelehealthConsult: true });

      expect(getByTestId(ANALYTICS.LABELS.TELEHEALTH)).toBeDefined();
    });
  });

  describe('when there is not a Telehealth consult', () => {
    it('then it should render the Telehealth button', () => {
      const { queryByTestId } = doRender({
        shouldShowTelehealthConsult: false,
      });

      expect(queryByTestId(ANALYTICS.LABELS.TELEHEALTH)).toBeNull();
    });
  });

  it('then it should render the Save PDF button', () => {
    const { getByTestId } = doRender();

    expect(getByTestId(SUB_NAVBAR.SAVE_PDF_BUTTON)).toBeDefined();
  });

  describe('When the user is enterprise user', () => {
    beforeEach(() => {
      isEnterpriseCustomerMock.mockReturnValue(true);
    });
    it('then it should render the back button with dashboard URL', () => {
      const dashboardURL = '/dashboard';
      const { getByTestId } = doRender();

      expect(getByTestId(SUB_NAVBAR.DASHBOARD_BUTTON)).toBeDefined();
      expect(getByTestId(SUB_NAVBAR.DASHBOARD_BUTTON).href).toContain(
        dashboardURL,
      );
    });
  });
  describe('When the user is not enterprise user', () => {
    beforeEach(() => {
      isEnterpriseCustomerMock.mockReturnValue(false);
    });
    it('then it should render the back button  with results URL', () => {
      const dashboardURL = '/results';
      const { getByTestId } = doRender();

      expect(getByTestId(SUB_NAVBAR.DASHBOARD_BUTTON)).toBeDefined();
      expect(getByTestId(SUB_NAVBAR.DASHBOARD_BUTTON).href).toContain(
        dashboardURL,
      );
    });
  });
});
