import { Input as LeavesInput, typography } from '@everlywell/leaves';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Input = styled(LeavesInput)<{ hasError: boolean }>`
  margin-bottom: 0;

  /* When there is a helper text and no error, make the helper text bold. 
  * HelperText is a prop of the input component */
  ${({ hasError }) =>
    !hasError &&
    css`
      div {
        font-weight: ${typography.weight.bold};
      }
    `}
`;
