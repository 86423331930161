import React from 'react';

/**
 * Icon to be used in the header of the page
 */
function GeneralAccentPhysicianIcon() {
  return (
    <svg
      width="90"
      height="91"
      viewBox="0 0 90 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="18" y="18.5" width="54" height="54" rx="27" fill="#F5FAF7" />
      <path
        d="M43.4423 29.75V29.75C44.1856 29.75 44.8886 29.9307 45.5101 30.2498C47.2989 31.1708 48.2628 33.2102 47.9375 35.2341L44.3609 49.9081C43.7032 52.6073 41.3382 54.5 38.6248 54.5C35.9124 54.5 33.5475 52.6073 32.8898 49.9081L29.3122 35.2341C28.9879 33.2102 29.9518 31.1708 31.7396 30.2498C32.362 29.9307 33.064 29.75 33.8084 29.75"
        stroke="#16644A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M39 54.8693V57.9496C39 60.5059 39.965 63.0425 43.646 63.0425C47.327 63.0425 48.9694 58.5783 49.5 55.625L52.0859 45.2616C52.3664 43.2469 54.0434 41.75 56.0232 41.75C58.2197 41.75 60 43.5813 60 45.8408V52.5453"
        stroke="#16644A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M62.25 55.625C62.25 57.0758 61.0752 58.25 59.625 58.25C58.1748 58.25 57 57.0758 57 55.625C57 54.1742 58.1748 53 59.625 53C61.0752 53 62.25 54.1742 62.25 55.625Z"
        stroke="#16644A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.0089 31.25H35.4217C34.4986 31.25 33.75 30.5787 33.75 29.75C33.75 28.9223 34.4986 28.25 35.4217 28.25H36.0089C36.4174 28.25 36.75 28.5473 36.75 28.9145V30.5855C36.75 30.9517 36.4174 31.25 36.0089 31.25Z"
        stroke="#16644A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.2402 28.25H41.8277C42.7522 28.25 43.5 28.9213 43.5 29.75C43.5 30.5777 42.7522 31.25 41.8277 31.25H41.2402C40.8316 31.25 40.5 30.9517 40.5 30.5855V28.9145C40.5 28.5473 40.8316 28.25 41.2402 28.25Z"
        stroke="#16644A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="63" cy="26.5" r="7.875" fill="#F16173" />
      <rect
        x="62.0625"
        y="22.5625"
        width="1.875"
        height="5.25"
        rx="0.3125"
        fill="white"
      />
      <rect
        x="62.0625"
        y="28.9375"
        width="1.875"
        height="1.875"
        rx="0.3125"
        fill="white"
      />
    </svg>
  );
}

function PhoneQuestionMarkerIcon() {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5.33594"
        y="1.43457"
        width="12.6504"
        height="21.1308"
        rx="2"
        stroke="#007377"
        stroke-width="1.5"
      />
      <ellipse
        cx="11.9123"
        cy="3.49537"
        rx="0.506016"
        ry="0.515386"
        fill="#007377"
      />
      <line
        x1="5.83984"
        y1="5.52881"
        x2="17.4782"
        y2="5.52881"
        stroke="#007377"
        stroke-width="1.5"
      />
      <path d="M5.33594 19.1465H17.5131" stroke="#007377" stroke-width="1.5" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5153 14.3145V13.8251C10.5153 13.619 10.531 13.4344 10.5624 13.2712C10.5938 13.108 10.6431 12.9535 10.7104 12.8075C10.7776 12.6615 10.8673 12.5198 10.9794 12.3824C11.0915 12.245 11.2328 12.0991 11.4032 11.9445L12.0086 11.3906C12.179 11.2447 12.3247 11.0815 12.4458 10.9012C12.5669 10.7208 12.6274 10.5019 12.6274 10.2443C12.6274 9.90937 12.5176 9.62815 12.2978 9.40059C12.0781 9.17303 11.7664 9.05925 11.3628 9.05925C11.1566 9.05925 10.9705 9.09575 10.8045 9.16874C10.6386 9.24173 10.4951 9.34048 10.3741 9.46499C10.253 9.58951 10.161 9.73334 10.0983 9.89649C10.0355 10.0596 9.99961 10.2271 9.99065 10.3988L8.33594 10.2571C8.38975 9.84497 8.5041 9.47787 8.67899 9.15586C8.85388 8.83384 9.08033 8.56121 9.35836 8.33794C9.63639 8.11468 9.95477 7.94509 10.3135 7.82916C10.6723 7.71324 11.0579 7.65527 11.4705 7.65527C11.8561 7.65527 12.2216 7.70894 12.5669 7.81628C12.9122 7.92362 13.2171 8.08248 13.4817 8.29286C13.7463 8.50324 13.9548 8.763 14.1072 9.07214C14.2597 9.38127 14.3359 9.73763 14.3359 10.1412C14.3359 10.416 14.3045 10.6564 14.2418 10.8625C14.179 11.0686 14.0893 11.2575 13.9727 11.4293C13.8561 11.601 13.7149 11.7663 13.5489 11.9252C13.383 12.084 13.1969 12.2493 12.9906 12.4211C12.8113 12.5671 12.6655 12.6959 12.5534 12.8075C12.4413 12.9191 12.3516 13.0308 12.2844 13.1424C12.2171 13.254 12.17 13.3742 12.1431 13.503C12.1162 13.6319 12.1028 13.7907 12.1028 13.9796V14.3145H10.5153ZM10.2462 16.1049C10.2462 15.8301 10.3516 15.5939 10.5624 15.3964C10.7731 15.1989 11.0265 15.1002 11.3225 15.1002C11.6095 15.1002 11.8606 15.1946 12.0758 15.3836C12.2911 15.5725 12.3987 15.8043 12.3987 16.0791C12.3987 16.3539 12.2933 16.59 12.0825 16.7875C11.8718 16.985 11.6184 17.0838 11.3225 17.0838C11.179 17.0838 11.0422 17.058 10.9121 17.0065C10.7821 16.955 10.6677 16.8863 10.5691 16.8004C10.4704 16.7145 10.392 16.6115 10.3337 16.4913C10.2754 16.3711 10.2462 16.2423 10.2462 16.1049Z"
        fill="#007377"
      />
    </svg>
  );
}

function StethoscopeIcon () {
  return (
    <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="18" y="18" width="54" height="54" rx="27" fill="#F5FAF7"/>
      <path d="M43.4423 29.25V29.25C44.1856 29.25 44.8886 29.4307 45.5101 29.7498C47.2989 30.6708 48.2628 32.7102 47.9375 34.7341L44.3609 49.4081C43.7032 52.1073 41.3382 54 38.6248 54C35.9124 54 33.5475 52.1073 32.8898 49.4081L29.3122 34.7341C28.9879 32.7102 29.9518 30.6708 31.7396 29.7498C32.362 29.4307 33.064 29.25 33.8084 29.25" stroke="#16644A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M39 54.3693V57.4496C39 60.0059 39.965 62.5425 43.646 62.5425C47.327 62.5425 48.9694 58.0783 49.5 55.125L52.0859 44.7616C52.3664 42.7469 54.0434 41.25 56.0232 41.25C58.2197 41.25 60 43.0813 60 45.3408V52.0453" stroke="#16644A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M62.25 55.125C62.25 56.5758 61.0752 57.75 59.625 57.75C58.1748 57.75 57 56.5758 57 55.125C57 53.6742 58.1748 52.5 59.625 52.5C61.0752 52.5 62.25 53.6742 62.25 55.125Z" stroke="#16644A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M36.0089 30.75H35.4217C34.4986 30.75 33.75 30.0787 33.75 29.25C33.75 28.4223 34.4986 27.75 35.4217 27.75H36.0089C36.4174 27.75 36.75 28.0473 36.75 28.4145V30.0855C36.75 30.4517 36.4174 30.75 36.0089 30.75Z" stroke="#16644A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M41.2402 27.75H41.8277C42.7522 27.75 43.5 28.4213 43.5 29.25C43.5 30.0777 42.7522 30.75 41.8277 30.75H41.2402C40.8316 30.75 40.5 30.4517 40.5 30.0855V28.4145C40.5 28.0473 40.8316 27.75 41.2402 27.75Z" stroke="#16644A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export { GeneralAccentPhysicianIcon, PhoneQuestionMarkerIcon, StethoscopeIcon };
