import { breakpoints } from '@everlywell/leaves';
import useMediaQuery from 'common/hooks/useMediaQuery';
import Pagination from 'components/Pagination';
import React from 'react';

export type ResultsPaginationProps = {
  isLoading: boolean;
  itemsPerPage: number;
  totalItems: number;
  currentPage: number;
};

/**
 * ResultsPagination component.
 */
function ResultsPagination({ isLoading, ...props }: ResultsPaginationProps) {
  const size = useResponsiveSize();

  if (isLoading) {
    return null;
  }

  return <Pagination {...props} size={size} />;
}

export default ResultsPagination;

const useResponsiveSize = () => {
  const isMobile = useMediaQuery(
    `(max-width: ${breakpoints.forTabletVerticalUp}px)`,
  );

  return isMobile ? 3 : 5;
};
