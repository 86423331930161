import { Button, Toast, ToastType } from '@everlywell/leaves';
import { createAsyncTelehealthAppointment } from 'common/apis/telehealthSchedulingApis';
import { BaseRequestError } from 'common/hooks/useApi/request';
import useProgramType from 'common/hooks/useProgramType';
import Location from 'common/icons/location_24.svg';
import MedicalPhone from 'common/icons/medical-phone_24.svg';
import QuestionPhone from 'common/icons/question-phone_24.svg';
import Time from 'common/icons/time_24.svg';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { isAsyncProgram } from 'common/utils/helpers';
import { ProgramDisplayNames, ProgramType } from 'common/utils/types';
import { userPlanId } from 'common/utils/userPlanId';
import Grid from 'components/Grid';
import Layout from 'components/Layout';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FloatingCtaFooter from 'pages/VirtualCare/NativeScheduling/components/FloatingCtaFooter';
import React, { useState, useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import Loader from '../../components/Loader';
import { useNativeIntakeContext } from '../../context/NativeIntakeContext';
import { getRelativePath } from '../../utils/helpers';
import * as S from './ReviewPage.styles';

/**
 * This page is the review step in the intake process before actually
 * confirm the appointment. It shows the user the details of the
 * appointment they are about to book and program it is from
 */
function ReviewPage() {
  const { excludeReviewPage } = useFlags<{
    excludeReviewPage: boolean;
  }>();
  const confirmAndSubmitClick = useRef(false);
  const { currentProgramSlug } = useNativeIntakeContext();
  const programType = useProgramType(currentProgramSlug as ProgramType);
  const programDisplayName =
    ProgramDisplayNames[currentProgramSlug as ProgramType] ?? 'Online Review';

  const navigate = useNavigate();

  const planId = userPlanId.planId;

  useEffectOnce(() => {
    if (isAsyncProgram(programType) && excludeReviewPage) {
      mutate({
        program_slug: currentProgramSlug,
        user_plan_id: planId,
      });
    }
  });

  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        program: currentProgramSlug,
        page: 'Visit Review',
      },
    });
  }, [currentProgramSlug]);

  const { mutate, isError, isLoading } = useMutation(
    createAsyncTelehealthAppointment,
    {
      onSuccess: async (data) => {
        const confirmedAppointment = data.data;

        if (confirmedAppointment) {
          if (confirmAndSubmitClick.current) {
            analytics.track({
              event: ANALYTICS.EVENTS.CLICKED_BUTTON,
              data: {
                label: ANALYTICS.LABELS.VIRTUAL_INTAKE.REVIEW.SELECTION_SUBMIT,
                program: currentProgramSlug,
              },
            });
          }

          const confirmationPath = getRelativePath('async/confirmation');
          navigate(confirmationPath);
        }
      },
      onError: async (error) => {
        confirmAndSubmitClick.current = false;
        const errorMessage = (error as BaseRequestError)?.data?.errors[0]
          .message;
        if (errorMessage) {
          setErrorMessage(errorMessage);
          setErrorIsVisible(true);
        }
      },
    },
  );

  const [errorIsVisible, setErrorIsVisible] = useState(isError ?? true);
  const [errorMessage, setErrorMessage] = useState(
    'Something went wrong. Please try again.',
  );
  const handleCloseToast = () => {
    setErrorIsVisible(!errorIsVisible);
  };
  const handleSubmit = () => {
    confirmAndSubmitClick.current = true;
    mutate({
      program_slug: currentProgramSlug,
      user_plan_id: planId,
    });
  };

  if (!isError && isLoading) {
    return <Loader />;
  }

  return (
    <Layout>
      <S.Container>
        <Grid.Container spacing={['xl1']}>
          <Grid.Item width={[1]}>
            <S.Heading>Does this look correct?</S.Heading>
          </Grid.Item>

          <Grid.Item width={[1]}>
            <Grid.Container spacing={['lg']}>
              <Grid.Item width={[1]}>
                <S.DetailText>
                  <S.Icon src={MedicalPhone} alt="" />
                  {programDisplayName}
                </S.DetailText>
              </Grid.Item>

              <Grid.Item width={[1]}>
                <S.DetailText>
                  <S.Icon src={Time} alt="" />
                  Review will be completed within 24 hours
                </S.DetailText>
              </Grid.Item>

              <Grid.Item width={[1]}>
                <S.DetailText>
                  <S.Icon src={Location} alt="" verticalAlign />
                  You will be notified by email when your clinician has shared
                  your care plan to your My Everly portal.
                </S.DetailText>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>

          <Grid.Item width={[1]}>
            <Grid.Container spacing={['xs2']} css={{ textAlign: 'center' }}>
              <Grid.Item width={[1]}>
                <S.HelpTitle>
                  <S.HelpIcon src={QuestionPhone} alt="" />
                  <span>Need Help?</span>
                </S.HelpTitle>
              </Grid.Item>

              <Grid.Item width={[1]}>
                <S.HelpBody>
                  Contact your healthcare provider team at{' '}
                  <S.HelpPhone>(708) 787-4438</S.HelpPhone>, Monday through
                  Friday, <S.NoWrapSpan>7 a.m. to 7 p.m. CST.</S.NoWrapSpan>
                </S.HelpBody>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          {errorIsVisible && (
            <Grid.Item width={[1]}>
              <Toast
                type={ToastType.ERROR}
                content={errorMessage}
                handleClose={handleCloseToast}
                persistent
              />
            </Grid.Item>
          )}
        </Grid.Container>
        <FloatingCtaFooter
          nextButton={
            <Button onClick={handleSubmit} isLoading={isLoading}>
              Confirm and Submit
            </Button>
          }
        />
      </S.Container>
    </Layout>
  );
}

export default ReviewPage;
