import { TEST_INFORMATION_FORM as DATA_TEST } from 'common/utils/constants/dataTest';
import { formatPhoneNumber } from 'common/utils/helpers';
import { handleChange } from 'common/utils/registrationHelpers';
import { KitRegistrationUser } from 'common/utils/types';
import React from 'react';

import infoIcon from '../../assets/ui-icons-info.svg';
import * as S from '../styles';
type ContactInformationProps = {
  user: KitRegistrationUser;
  invalidFields: string[];
  setInvalidFields: React.Dispatch<React.SetStateAction<string[]>>;
};

const ContactInformation = (props: ContactInformationProps) => {
  const {
    user: { phoneNumber },
    invalidFields,
    setInvalidFields,
  } = props;
  const validatePhoneNumber = (value: string) => {
    if (value.length === 0) return undefined;
    return /\(\d{3}\) \d{3}-\d{4}$/.test(value)
      ? undefined
      : `Please enter your 10 digit phone number`;
  };
  return (
    <S.ContactInformation>
      <S.StyledInput
        label="Phone Number"
        id={phoneNumber.id}
        value={phoneNumber.value}
        name={phoneNumber.id}
        type="tel"
        inputClassName="inspectletIgnore"
        error={
          invalidFields.includes('phoneNumber')
            ? `Please enter your 10 digit phone number`
            : validatePhoneNumber(phoneNumber.value)
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(
            phoneNumber,
            formatPhoneNumber(e.target.value),
            invalidFields,
            setInvalidFields,
          )
        }
        data-test={`${DATA_TEST.INPUT}-${phoneNumber.id}`}
      />
      <S.TooltipWrapper>
        <S.ContactInformationTooltip
          animationSpeed="normal"
          content="In case we need to contact you about your results"
          position="top"
          arrow="right"
          tooltipBoxClass="tooltipBox"
        >
          <S.Image
            src={infoIcon}
            alt="Information"
            data-test={DATA_TEST.ICON}
          />
        </S.ContactInformationTooltip>
      </S.TooltipWrapper>
    </S.ContactInformation>
  );
};

export default ContactInformation;
