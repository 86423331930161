import DBS2 from '../assets/sample-collection-tips-illustrations-dbs-2.svg';
import DBS3 from '../assets/sample-collection-tips-illustrations-dbs-3.svg';
import DBSII1 from '../assets/sample-collection-tips-illustrations-dbs-ii-1.svg';
import DBSII4 from '../assets/sample-collection-tips-illustrations-dbs-ii-4.svg';

const DBSIISteps = [
  {
    id: '1',
    image: DBSII1,
    alt: 'Make sure that you mail back the card that came with the kit you registered.',
    description:
      'Make sure that you mail back the card that came with the kit you registered.',
  },
  {
    id: '2',
    image: DBS2,
    alt: 'Fill ALL circles with large, free-falling drops until they are completely full. It’s okay if blood goes outside of the line.',
    description:
      'Fill ALL circles with large, free-falling drops until they are completely full. It’s okay if blood goes outside of the line.',
  },
  {
    id: '3',
    image: DBS3,
    alt: 'Flip the card over and check that each blood drop has soaked through the back of the card before allowing to dry.',
    description:
      'Flip the card over and check that each blood drop has soaked through the back of the card before allowing to dry.',
  },
  {
    id: '4',
    image: DBSII4,
    alt: 'Leave card out for at least 30 minutes or until completely dry before placing it inside the biohazard bag.',
    description:
      'Leave card out for at least 30 minutes or until completely dry before placing it inside the biohazard bag.',
  },
];

export { DBSIISteps };
