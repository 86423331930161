import { LEGACY_APP_ROOT, TEST_IDS } from 'common/utils/constants';
import { SUB_HEADER as DATA_TEST } from 'common/utils/constants/dataTest';
import { Test } from 'common/utils/types';
import { shallow, ShallowWrapper } from 'enzyme';
import React from 'react';
import { Link } from 'react-router-dom';

import SavePdfButton, { Props } from '../SavePdfButton';
import * as S from '../styles';

const test: Test = {
  id: 3,
  name: 'Food Sensitivity',
  marker_types: ['food-sensitivity'],
  type: 'food-sensitivity',
  content_token: '123asdf-123fda',
  display_name: 'Food Sensitivity',
  theme_icon: 'other',
  recorded_webinar: false,
};

const kitResult: Props['kitResult'] = {
  id: 112233,
  needs_replacement: false,
  number: 'KRDB99CD65BB5643438B506AF7830D60',
  user_email: 'demo@everlywell.com',
  kit_result_time: '2021-11-10T16:24:31.000Z',
  status: 'results_approved',
  tracking_url: 'www.ups.com',
  product_slug: 'food-sensitivity',
  clia: '39D0673919',
  serial_number: 65549690,
  product_name: 'Food Sensitivity Test',
  content: {},
  test,
  marker_results: [
    {
      marker_id: 111,
      descriptor: 'low reactivity',
      boundaries: [],
      boundary_conditions: [],
      severity_index: 1,
      value: 11,
      content_token: '123asdf-123fda',
      id: 111000,
      test_result_id: 111999,
    },
    {
      marker_id: 20,
      descriptor: 'low reactivity',
      boundaries: [],
      boundary_conditions: [],
      severity_index: 1,
      value: 12,
      content_token: '123asdf-123fdaz',
      id: 222000,
      test_result_id: 222999,
    },
    {
      marker_id: 19,
      descriptor: 'mild reactivity',
      boundaries: [],
      boundary_conditions: [],
      severity_index: 1,
      value: 40,
      content_token: '123asdf-123fday',
      id: 333000,
      test_result_id: 333999,
    },
  ],
  markers: [
    {
      name: 'YOGURT',
      id: 111,
      content_token: '123asdf-123fday',
      descriptors: [],
      severities: [1, 2, 3, 2, 1],
      severity_colors: ['green', 'yellow', 'red'],
      type: 'food-sensitivity',
      groupings: [],
      test_id: 444000,
    },
    {
      name: 'BANANA',
      id: 20,
      content_token: '123asdf-123fday',
      descriptors: [],
      severities: [1, 2, 3, 2, 1],
      severity_colors: ['green', 'yellow', 'red'],
      type: 'food-sensitivity',
      groupings: [],
      test_id: 20000,
    },
    {
      name: 'AVACADO',
      id: 19,
      content_token: '123asdf-123fday',
      descriptors: [],
      severities: [1, 2, 3, 2, 1],
      severity_colors: ['green', 'yellow', 'red'],
      type: 'food-sensitivity',
      groupings: [],
      test_id: 19000,
    },
  ],
};

const defaultProp: Props = {
  kitResult,
};

const renderComponent = (props: Partial<Props> = {}): ShallowWrapper =>
  shallow(<SavePdfButton {...defaultProp} {...props} />);

describe('<SavePDFButton />', () => {
  describe('should render the save PDF button', () => {
    it('should render the save pdf single button', () => {
      const mockKitResult = { ...kitResult, test: { ...test } };
      const component = renderComponent({
        kitResult: mockKitResult,
      });

      expect(
        component.containsMatchingElement(
          <S.PdfButton
            data-test={DATA_TEST.SAVE_BUTTON}
            appearance={'secondary'}
          >
            Save a PDF of your results
          </S.PdfButton>,
        ),
      ).toBeTruthy();
    });
  });

  describe('when DNR status', () => {
    it('should render the save pdf without url', () => {
      const mockKitResult = { ...kitResult, test: { ...test } };
      mockKitResult.any_dnr_marker_results = true;
      const component = renderComponent({
        kitResult: mockKitResult,
      });

      expect(
        component.containsMatchingElement(
          <S.PdfButton
            data-test={DATA_TEST.SAVE_BUTTON}
            appearance={'secondary'}
          >
            Save a PDF of your results
          </S.PdfButton>,
        ),
      ).toBeTruthy();
    });

    it('should render the care message when there is DNR tests', () => {
      const mockKitResult = { ...kitResult, test: { ...test } };
      mockKitResult.any_dnr_marker_results = true;
      const component = renderComponent({
        kitResult: mockKitResult,
      });
      const expectedMessage =
        'Please contact contact@everlywell.com to receive a PDF of your results.';
      component
        .find(`[data-test="${DATA_TEST.SAVE_BUTTON}"]`)
        .simulate('click');

      expect(
        component.find(`[data-test="${DATA_TEST.DNR_PDF}"]`).text(),
      ).toMatch(expectedMessage);
    });
  });

  describe('when covid test', () => {
    const covidTest: Test = {
      id: TEST_IDS.COVID_19_GRAVITY_DTC,
      name: 'COVID-19 Test (HCP)',
      marker_types: ['descriptive'],
      type: 'covid-19-hcp',
      content_token: 'c7a53d7d-c2ae-4141-8f0e-a40b390217db',
      display_name: 'COVID-19 Test (HCP)',
      theme_icon: 'other',
      recorded_webinar: false,
      content: {},
    };

    const covidKitResult: Props['kitResult'] = {
      needs_replacement: false,
      user_email: 'demo@everlywell.com',
      kit_result_time: '2021-11-10T16:24:31.000Z',
      tracking_url: 'www.ups.com',
      product_slug: 'food-sensitivity',
      any_dnr_marker_results: false,
      clia: '44D2026010',
      consult: { available: false, available_until: '' },
      first_name: 'Demo',
      genomics_result_status: undefined,
      id: 4202857,
      lab: {
        id: 48,
        name: 'Compass Laboratory Services, CLIA 44D2026010',
        pwn_lab_identifier: '1448',
        ship_address_id: 1608437,
        content_token: 'd2cef37e-554c-4eb1-893a-40743c6cec70',
      },
      marker_results: [
        {
          boundaries: [],
          boundary_conditions: [],
          content_token: '40531d58-8c72-4b7c-af1f-8abeafbb7595',
          descriptor: 'negative',
          historical_results: [],
          id: 135197630,
          kind: 'descriptive',
          marker_id: 1058,
          observation_datetime: null,
          reported_at: '2022-01-24T21:49:44.000Z',
          results_approved_at: '2022-01-24T21:49:44.000Z',
          severity_index: 1,
          test_result_id: 4202857,
          value: 'negative',
        },
      ],
      markers: [
        {
          content_token: '22ef48ac-27a3-4b67-8fc1-43945b79dbb0',
          descriptors: ['Positive', 'Negative', 'Indeterminate'],
          groupings: [],
          id: 1058,
          max_value: undefined,
          min_value: undefined,
          name: 'SARS-CoV-2',
          severities: [3, 1, 2],
          severity_colors: ['red', 'green', 'gray'],
          test_id: 121,
          type: 'descriptive',
          units: '',
        },
      ],
      number: 'KR11F296716AE744D9AD5B3986CD1F9C',
      serial_number: 65549690,
      paid: true,
      product_name: '',
      publish_hash: undefined,
      published_at: undefined,
      pwn_confirmation_code: undefined,
      pwn_order_number: 56630474,
      results_approved_at: '2022-01-24T21:49:44.000Z',
      status: 'results_approved',
      test: covidTest,
      viewable: true,
      content: {},
    };

    it('should render the view documents button without url', () => {
      const mockKitResult = { ...covidKitResult, test: { ...covidTest } };
      const component = renderComponent({
        kitResult: mockKitResult,
      });
      const button = component.find(`[data-test="${DATA_TEST.SAVE_BUTTON}"]`);
      const expectedText = 'View My Documents';

      expect(button.text()).toMatch(expectedText);
      expect(button.prop('href')).toBeUndefined();
    });

    it('should render the chevron', () => {
      const mockKitResult = { ...covidKitResult, test: { ...covidTest } };
      const component = renderComponent({
        kitResult: mockKitResult,
      });

      expect(
        component.containsMatchingElement(
          <S.Chevron hideChevronOnMobile={false} />,
        ),
      ).toBeTruthy();
    });

    it('should render the popover with all options ', () => {
      const mockKitResult = { ...covidKitResult, test: { ...covidTest } };
      const component = renderComponent({
        kitResult: mockKitResult,
      });
      const receiptPDFLink = `${LEGACY_APP_ROOT}/results/${mockKitResult?.number}/reimbursement_letter.pdf`;
      const doctorPDFUrl = `${LEGACY_APP_ROOT}/results/${mockKitResult?.number}/doctor_results.pdf`;

      component
        .find(`[data-test="${DATA_TEST.SAVE_BUTTON}"]`)
        .simulate('click');

      expect(
        component.containsMatchingElement(
          <S.Content>
            <Link
              target="_blank"
              to={doctorPDFUrl}
              data-test={DATA_TEST.DOCTOR_PDF}
            >
              Save a PDF of your results
            </Link>
            <Link
              target="_blank"
              to={receiptPDFLink}
              data-test={DATA_TEST.ITEMIZED_RECEIPT_PDF}
            >
              Itemized Receipt PDF
            </Link>
          </S.Content>,
        ),
      ).toBeTruthy();
    });

    describe('with DNR status', () => {
      it('renders the popover with the itemized receipt PDF and the Customer Experience message ', () => {
        const mockKitResult = { ...covidKitResult, test: { ...covidTest } };
        mockKitResult.any_dnr_marker_results = true;

        const component = renderComponent({
          kitResult: mockKitResult,
        });
        const receiptPDFLink = `${LEGACY_APP_ROOT}/results/${mockKitResult?.number}/reimbursement_letter.pdf`;

        component
          .find(`[data-test="${DATA_TEST.SAVE_BUTTON}"]`)
          .simulate('click');

        expect(
          component.containsMatchingElement(
            <S.Content>
              <p data-test={DATA_TEST.DNR_PDF}>
                Please contact{' '}
                <a href="mailto:contact@everlywell.com">
                  contact@everlywell.com
                </a>{' '}
                to receive a PDF of your results.
              </p>
              <Link
                target="_blank"
                to={receiptPDFLink}
                data-test={DATA_TEST.ITEMIZED_RECEIPT_PDF}
              >
                Itemized Receipt PDF
              </Link>
            </S.Content>,
          ),
        ).toBeTruthy();
      });
    });
  });
});
