import { Col_10 as Col10 } from 'common/styles/grid-styles';
import { ImmutableReduxState } from 'common/utils/types';
import { Props as SectionHeaderProps } from 'components/SectionHeader';
import ResourcesCollection from 'containers/ConsiderChangesSection/ResourcesCollectionContainer';
import ResourcesSectionHeader, {
  CONTENT_KEYS,
} from 'containers/ConsiderChangesSection/ResourcesSectionHeaderContainer';
import ResourcesLinksSectionHeader from 'containers/ResourcesLinksSection/HeaderContainer';
import * as React from 'react';
import { connect } from 'react-redux';
import { makeSelectContent } from 'store/selectors';

type Props = {
  contentToken: string;
  headerIcon?: string;
};

function ResourcesLinksSection(props: Props & SectionHeaderProps) {
  const { contentToken, headerIcon } = props;
  const hasContent = !!props.header;

  if (!hasContent) {
    return null;
  }
  return (
    <>
      <ResourcesLinksSectionHeader
        contentToken={contentToken}
        headerIcon={headerIcon}
      />
      <ResourcesSectionHeader contentToken={contentToken} />
      <Col10>
        <ResourcesCollection contentToken={contentToken} />
      </Col10>
    </>
  );
}

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: Props,
): SectionHeaderProps {
  return makeSelectContent(ownProps.contentToken, CONTENT_KEYS)(state);
}

export default connect(mapStateToProps)(ResourcesLinksSection);
