import Grid from 'components/Grid';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormBuilderField } from '../../utils/types';
import * as S from './BloodPressureField.styles';

export type BloodPressureFieldProps = FormBuilderField;

const HELPER_TEXT = 'Please use your systolic and diastolic blood pressure';
const REQUIRED_SYSTOLIC_ERROR = 'Please enter your systolic blood pressure';
const REQUIRED_DIASTOLIC_ERROR = 'Please enter your diastolic blood pressure';
const INVALID_NUMBER_ERROR = 'Please enter a valid number';

function BloodPressureField(props: BloodPressureFieldProps) {
  const { register, errors, watch, setValue } = useFormContext();
  const { id, label, variant, required } = props;

  const tempFieldName = `${props.id}-tmp`;
  const error = errors?.[tempFieldName];

  const bloodPressureValues = watch(tempFieldName);

  const systolic = bloodPressureValues?.['systolic'] ?? '';
  const diastolic = bloodPressureValues?.['diastolic'] ?? '';

  useEffect(() => {
    const value = `${systolic}/${diastolic}`;

    setValue(id, value);
  }, [setValue, systolic, diastolic, id]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (target.value.length > 3) {
      target.value = target.value.slice(0, 3);
    }
  };

  return (
    <Grid.Item width={[1]}>
      <S.FieldContainer>
        <S.Label
          hasError={!!error}
          variant={variant}
          isRequired={required}
          id={`${id}-label`}
        >
          {label}
        </S.Label>
        <S.HelperText>{HELPER_TEXT}</S.HelperText>
        <S.InputGroup>
          <S.Input
            type="number"
            id={`${tempFieldName}.systolic`}
            name={`${tempFieldName}.systolic`}
            aria-label={`Systolic`}
            placeholder="120"
            required={required}
            ref={register<HTMLInputElement>({
              required: required && REQUIRED_SYSTOLIC_ERROR,
              min: {
                value: 0,
                message: INVALID_NUMBER_ERROR,
              },
            })}
            onInput={handleInput}
            error={!!error?.systolic?.message}
            min={0}
          />
          <S.InputSeparator>/</S.InputSeparator>
          <S.Input
            type="number"
            id={`${tempFieldName}.diastolic`}
            name={`${tempFieldName}.diastolic`}
            aria-label={`Diastolic`}
            placeholder="80"
            required={required}
            ref={register<HTMLInputElement>({
              required: required && REQUIRED_DIASTOLIC_ERROR,
              min: {
                value: 0,
                message: INVALID_NUMBER_ERROR,
              },
            })}
            onInput={handleInput}
            error={!!error?.diastolic?.message}
            min={0}
          />
        </S.InputGroup>
        <S.ErrorMessageGroup>
          {error?.systolic && (
            <S.ErrorMessage>{error.systolic.message}</S.ErrorMessage>
          )}
          {error?.diastolic && (
            <S.ErrorMessage>{error.diastolic.message}</S.ErrorMessage>
          )}
        </S.ErrorMessageGroup>
        <input
          type="hidden"
          id={id}
          name={id}
          ref={register()}
          aria-labelledby={`${id}-label`}
        />
      </S.FieldContainer>
    </Grid.Item>
  );
}

export default BloodPressureField;
