import {
  ImmutableReduxState,
  DescriptiveMarkerContent,
  MarkerWithResult,
  ResourceLink,
} from 'common/utils/types';
import DescriptiveMarkerCard from 'components/DescriptiveMarkerCard';
import { connect } from 'react-redux';
import { makeSelectContent } from 'store/selectors';

const CONTENT_KEYS = {
  markerOverview: 'MARKER_OVERVIEW',
  markerTellMeMore: 'MARKER_TELL_ME_MORE',
  moreResourcesLinks: 'MORE_RESOURCES_LINK_*',
  moreResourcesNames: 'MORE_RESOURCES_NAME_*',
};

const TAILORED_CONTENT_KEYS = {
  tailoredOverview: 'TAILORED_OVERVIEW',
};

type StateProps = {
  content: {
    markerOverview: string;
    markerTellMeMore: string;
    moreResourcesNames: string[];
    moreResourcesLinks: string[];
    tailoredContent: string;
  };
};

type OwnProps = {
  key: number;
  marker: MarkerWithResult;
  contentToken: string;
};

export type DescriptiveProps = {
  key: number;
  marker: MarkerWithResult;
  content: DescriptiveMarkerContent;
};

function getTailoredContent(
  state: ImmutableReduxState,
  marker: MarkerWithResult,
) {
  const defaultContent = {
    tailoredOverview: null,
  };
  const tailoredOverview =
    makeSelectContent(
      marker.marker_result.content_token,
      TAILORED_CONTENT_KEYS,
    )(state) || defaultContent;

  return {
    ...tailoredOverview,
  };
}

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): StateProps {
  const { contentToken, marker } = ownProps;

  const markerContent = makeSelectContent(contentToken, CONTENT_KEYS)(state);
  const tailoredContent = getTailoredContent(state, marker);
  const content = {
    ...markerContent,
    tailoredContent: tailoredContent.tailoredOverview,
  };

  return {
    content,
  };
}

export function mergeProps(
  stateProps: StateProps,
  _dispatchProps: null,
  ownProps: OwnProps,
): DescriptiveProps {
  const { content } = stateProps;
  if (!content) {
    return {
      ...ownProps,
      content: {
        markerOverview: '',
        markerTellMeMore: '',
        moreResources: [],
        tailoredOverview: '',
      },
    };
  }

  let moreResources: ResourceLink[] = [];
  if (content.moreResourcesNames && content.moreResourcesLinks) {
    const { moreResourcesNames, moreResourcesLinks } = content;
    moreResources = moreResourcesNames.map((name: string, index: number) => ({
      name,
      link: moreResourcesLinks[index],
    }));
  }

  const newContent = {
    markerOverview: content.markerOverview,
    markerTellMeMore: content.markerTellMeMore,
    tailoredOverview: content.tailoredContent,
    moreResources,
  };

  return { ...ownProps, content: newContent };
}

export default connect(
  mapStateToProps,
  null,
  mergeProps,
)(DescriptiveMarkerCard);
