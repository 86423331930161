import Grid from 'components/Grid/Grid';
import { differenceInYears } from 'date-fns';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Input, labelStyles } from '../../utils/styles';
import { FormBuilderField } from '../../utils/types';

export type DobFieldProps = FormBuilderField;

function DOBField(props: DobFieldProps) {
  const { register, errors } = useFormContext();
  return (
    <Grid.Item width={[1]} key={props.id}>
      <Input
        css={labelStyles(props.variant)}
        label={props.label ?? ''}
        type="date"
        role="textbox"
        placeholder="mm/dd/yyyy"
        id={props.id}
        name={props.id}
        required={props.required}
        ref={register({
          required: props.required && 'Please enter a date of birth',
          validate: {
            is18orOlder: (value: string) => {
              const age = differenceInYears(new Date(), new Date(value));
              if (age < 18) {
                return 'You must be 18 or older';
              } else {
                return true;
              }
            },
          },
        })}
        error={errors[props.id]?.message}
      />
    </Grid.Item>
  );
}

export default DOBField;
