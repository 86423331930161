import { Modal, breakpoints } from '@everlywell/leaves';
import { useCommonT2TAnalytics } from 'common/hooks/useCommonT2TAnalytics';
import useMediaQuery from 'common/hooks/useMediaQuery';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { PATIENT_PORTAL } from 'common/utils/constants/urls';
import Grid from 'components/Grid';
import React from 'react';

import * as S from './ConfirmationModal.styles';

type ModalProps = {
  openModal: boolean;
  openHandler: () => void;
};

export const ComfirmationModal = ({ openModal, openHandler }: ModalProps) => {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.forPhoneOnly}px)`);
  const { commonT2TAnalyticsData } = useCommonT2TAnalytics();

  const handleOnClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.EHS_T2T_FORMS.CONFIRMATION_CONTINUE,
        ...commonT2TAnalyticsData,
      },
    });
  };

  return (
    <Modal
      open={openModal}
      data-test="formbuilder-modal"
      forceFullScreen={isMobile}
      openHandler={openHandler}
    >
      <Grid.Container spacing={['md']}>
        <Grid.Item width={[1]}>
          <S.H3>You’ll now be sent to our MyCare for Everly Portal</S.H3>
        </Grid.Item>

        <Grid.Item width={[1]}>
          <S.Description>
            This portal is where you’ll complete your medical intake, process
            payments (if necessary) and view your after-visit summary.
          </S.Description>
        </Grid.Item>

        <Grid.Item width={[1]}>
          <S.Button
            href={`${PATIENT_PORTAL}`}
            target="_blank"
            onClick={handleOnClick}
          >
            Continue
          </S.Button>
        </Grid.Item>
      </Grid.Container>
    </Modal>
  );
};
