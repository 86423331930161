import { getCarePlanByFormAnswerGroupId } from 'common/apis/telehealthApis';
import { getUser } from 'common/apis/userApis';
import { BaseRequestError, Response } from 'common/hooks/useApi/request';
import { logError } from 'common/utils/helpers';
import {
  CarePlanDataResponse,
  MembershipStatus,
  MembershipType,
  User,
} from 'common/utils/types';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { makeSelectSession } from 'store/selectors';

import { CarePlanTypes } from './types';

export const defaultUser: User = {
  actions: {},
  age: 0,
  consumer_attributes: {},
  email: '',
  can_update_email: true,
  first_name: '',
  id: 0,
  last_name: '',
  phone_number: '',
  ship_address: {},
  subscriptions: [],
  created_at: new Date(),
  ip_address: '',
  membership: {
    active_credits: 0,
    membership_type: MembershipType.Control,
    state: MembershipStatus.Active,
  },
  enrolled_programs: [],
  credits: [],
  next_credit_date: '',
};

/**
 * Fetches a care plan based on the formId or appointmentId.
 * formId is used for the new API, appointmentId is used for the old API. Both are used for the transition period.
 *
 * Later, we will only use the formId.
 * @returns
 */
const useCarePlan = (): CarePlanTypes => {
  const { userId } = useSelector(makeSelectSession());
  const [searchParams] = useSearchParams();

  const formAnswerGroupId = searchParams.get('formId');

  const {
    data: carePlanResponse,
    isLoading: isCarePlanLoading,
    isError,
    error,
  } = useQuery<Response<CarePlanDataResponse>, BaseRequestError>(
    ['care-plan-data-by-form', formAnswerGroupId],
    () => getCarePlanByFormAnswerGroupId(formAnswerGroupId!),
    {
      enabled: Boolean(formAnswerGroupId),
      onError(error) {
        logError(error.message, {
          component: 'useCarePlan',
          method: 'getCarePlanByFormAnswerGroupId',
        });
        return error;
      },
      retry: false,
    },
  );

  const {
    data: userResponse,
    isSuccess: isUserSuccess,
    isLoading: isLoadingUser,
  } = useQuery(['user', userId], () => getUser(userId!), {
    enabled: Boolean(userId),
    onError(error) {
      logError((error as Error).message, {
        component: 'useCarePlan',
        method: 'getUser',
      });
    },
  });

  const carePlanData = carePlanResponse?.data as CarePlanDataResponse;
  const errorStatusCode = error?.fetchResponse?.status;
  const userInfo =
    isUserSuccess && userResponse?.data
      ? { ...userResponse.data, full_user_data: true }
      : defaultUser;

  return {
    isError: isError || !formAnswerGroupId, // formAnswerGroupId is required
    isCarePlanLoading,
    isLoadingUser,
    userInfo,
    carePlanData,
    formAnswerGroupId,
    errorStatusCode: !formAnswerGroupId ? 404 : errorStatusCode,
  };
};

export default useCarePlan;
