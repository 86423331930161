import { useQuery } from '@apollo/client';
import { Localize, localizeUtils } from '@everlywell/leaves';
import { STATUSES, HOLIDAYS } from 'common/utils/constants';
import { QUEST_SCHEDULE_LINK } from 'common/utils/constants/bloodDraw';
import { EVERLYWELL_SUPPORT_ROOT } from 'common/utils/constants/urls';
import { RESULTS_QUERY } from 'common/utils/contentfulGraphQlQueries';
import { format, addBusinessDays } from 'date-fns';
import React from 'react';

import * as S from './styles';

export type Props = {
  status: string;
  atLab: string | undefined;
  trackingLinkElement: JSX.Element | null;
  slug: string;
  needsReplacement: boolean;
  replacementRequestedAt: string | undefined;
  confirmIdentifiers?: boolean;
  cancelReason?: string;
  min_process_time?: number;
  max_process_time?: number;
  dateReceived?: string;
  isLabVisit?: boolean;
  testId?: number;
};

type KitData = {
  productSlug: string;
  nonCovidDeliveredText: string;
};

/**
 * check if the tracking message should be displayed
 * @param { JSX.Element | null } trackingLinkElement a link to track shipment, if available
 * @param { string } trackingMessage displayed when a link is available
 * @param { string } unavailableMessage displayed when a link is not available
 * returns array with message and optional trackingLinkElement based on trackingLink availability
 */
const displayTrackingLinkMessage = (
  trackingLinkElement: JSX.Element | null,
  trackingMessage: string,
  unavailableMessage = '',
): [string, JSX.Element?] =>
  trackingLinkElement
    ? [trackingMessage, trackingLinkElement]
    : [unavailableMessage];

const StatusMessageText = (props: Props) => {
  const {
    needsReplacement,
    replacementRequestedAt,
    cancelReason,
    status,
    trackingLinkElement,
    min_process_time,
    max_process_time,
    slug,
    dateReceived,
    confirmIdentifiers,
  } = props;

  const { data, loading } = useQuery(RESULTS_QUERY);

  const isInRange =
    (start: Date, end: Date): any =>
    (acc: number, holiday: Date) =>
      holiday >= start && holiday <= end ? acc + 1 : acc;
  const holidaysToAdd = (start: Date, end: Date): number =>
    HOLIDAYS.reduce(isInRange(start, end), 0);

  const rangeInHours = (): string => {
    const startHour = min_process_time ? min_process_time * 24 : 24;
    const endHour = max_process_time ? max_process_time * 24 : 48;
    return `${startHour}-${endHour}`;
  };

  const formatDate = (base: string, daysToAdd: number): string => {
    const expectedDate = addBusinessDays(new Date(base), daysToAdd);
    const withHolidays = addBusinessDays(
      expectedDate,
      holidaysToAdd(new Date(base), expectedDate),
    );
    return `${format(withHolidays, 'EEEE')}, ${format(withHolidays, 'M/dd')}`;
  };

  let nonCovidDeliveredText = loading
    ? ''
    : 'Your kit has arrived at our partner lab. You will be notified when the lab begins processing your sample.';

  if (data) {
    data.resultCollection.items.map((kitData: KitData) => {
      if (kitData.productSlug === slug) {
        // eslint-disable-next-line
        nonCovidDeliveredText = kitData.nonCovidDeliveredText;
      }
      return kitData;
    });
  }

  const MESSAGE_TEXT = {
    [STATUSES.KIT_REGISTERED]: {
      COVID: [
        `Your kit was registered`,
        ...displayTrackingLinkMessage(
          trackingLinkElement,
          `. Once you mail your kit, track it `,
        ),
        `. Results are typically ready within ${rangeInHours()} hours of the lab receiving your sample.`,
      ],
      NON_COVID: [
        `Your kit was registered`,
        ...displayTrackingLinkMessage(
          trackingLinkElement,
          `. Once you mail your kit, track it `,
        ),
        `. If you're experiencing any issues with the status of your kit updating, read our FAQ `,
        <S.Link
          target="_blank"
          href={`${EVERLYWELL_SUPPORT_ROOT}/article/1039-why-has-there-been-no-update-to-my-shipping-status`}
        >
          here
        </S.Link>,
        '.',
      ],
      COVID_RAPID_ANTIGEN: [`Your kit was registered.`],
    },
    [STATUSES.IN_TRANSIT]: {
      COVID: [
        `Your kit is on its way to our partner lab or awaiting processing. `,
        `Results are typically ready within ${rangeInHours()} hours of the lab receiving your sample`,
        ...displayTrackingLinkMessage(trackingLinkElement, `. Track it `),
        '.',
      ],
      NON_COVID: [
        `Your kit is on its way to our partner lab or awaiting processing`,
        ...displayTrackingLinkMessage(trackingLinkElement, `. Track it `),
        '.',
      ],
    },
    [STATUSES.DELIVERED]: {
      COVID: [
        `Your kit has arrived at our partner lab and is awaiting processing. Results are typically ready within ${rangeInHours()} hours of the lab receiving your sample`,
        ...displayTrackingLinkMessage(
          trackingLinkElement,
          `. See when it arrived `,
        ),
        '.',
      ],
      NON_COVID: nonCovidDeliveredText,
    },
    [STATUSES.RESULTS_PROCESSING]: {
      COVID: [
        `Your sample is being processed. Results are typically ready within ${rangeInHours()} hours of the lab receiving your sample`,
        ...displayTrackingLinkMessage(
          trackingLinkElement,
          `. See when it arrived `,
        ),
        '.',
      ],
      NON_COVID: (
        <span>
          <span data-isolate>
            {`Your sample is being processed.${
              dateReceived && min_process_time && max_process_time
                ? ' Expect results between'
                : ''
            }`}
          </span>{' '}
          {dateReceived && min_process_time && max_process_time && (
            <>
              {localizeUtils.wrapTimeAgoInLocalizeVar(
                formatDate(dateReceived, min_process_time),
                'StatusMessage-noncovid-processing-s',
              )}{' '}
              -{' '}
              {localizeUtils.wrapTimeAgoInLocalizeVar(
                formatDate(dateReceived, max_process_time),
                'StatusMessage-noncovid-processing-e',
              )}
              .
            </>
          )}
        </span>
      ),
      COVID_RAPID_ANTIGEN: [`Your sample is being processed.`],
    },
    [STATUSES.RESULTS_APPROVED]: {
      COVID: 'Your test results are ready to view.',
      COVID_RAPID_ANTIGEN: 'Your test results are ready to view.',
      NON_COVID: 'Your test results are ready to view.',
    },
    [STATUSES.KIT_CANCELED]: {
      COVID:
        "Your replacement kit is on its way. We'll notify you of updates to this shipment as they become available.",
      NON_COVID:
        "Your replacement kit is on its way. We'll notify you of updates to this shipment as they become available.",
    },
  };

  if (cancelReason === 'invalid_product_for_state') {
    return (
      <>
        There has been an issue with processing your test. Please complete the
        kit replacement form.
      </>
    );
  }
  if (needsReplacement) {
    return (
      <>
        There's an issue with your sample. Please complete the kit replacement
        form.
      </>
    );
  }
  if (confirmIdentifiers) {
    return (
      <>
        There's an issue with the data listed on your sample. Please confirm the
        missing or incorrect data.
      </>
    );
  }

  if (status === STATUSES.KIT_CANCELED && !replacementRequestedAt) {
    return (
      <>
        Your kit could not be replaced. For any further assistance, please
        contact our support team.
      </>
    );
  }

  if (!MESSAGE_TEXT[status]) return <></>;

  let covidType = slug.includes('covid-19-rapid')
    ? MESSAGE_TEXT[status].COVID_RAPID_ANTIGEN
    : MESSAGE_TEXT[status].COVID;

  return slug.includes('covid') ? (
    <>{covidType}</>
  ) : (
    <>{MESSAGE_TEXT[status].NON_COVID}</>
  );
};

const getLabVisitMessage = (status: string): string | JSX.Element => {
  const LAB_VISIT_MESSAGES = {
    [STATUSES.KIT_REGISTERED]:
      'Your order has been received! Once it is approved, you will receive an email with instructions to schedule your in-person appointment at a Quest Patient Service Center.',
    [STATUSES.LAB_VISIT_APPROVED]: (
      <>
        Your order has been approved. Call your preferred Quest lab location to
        see if they are accepting walk-ins or{' '}
        <S.Link href={QUEST_SCHEDULE_LINK} target="_blank">
          schedule an appointment online.
        </S.Link>{' '}
        Remember to bring your photo ID with you.
      </>
    ),
  };

  return LAB_VISIT_MESSAGES[status];
};

const StatusMessage = (props: Props) => {
  const { atLab, isLabVisit, status } = props;

  const statusMessageText = isLabVisit ? (
    getLabVisitMessage(status)
  ) : (
    <StatusMessageText {...props} />
  );

  return (
    <S.StatusMessage data-test="status-message">
      {statusMessageText}

      {atLab ? (
        <S.LabName>
          <Localize name="KitCardStatusMessage-atLab">{`${atLab}`}</Localize>
        </S.LabName>
      ) : null}
    </S.StatusMessage>
  );
};

export default StatusMessage;
