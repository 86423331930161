import { ActionT } from 'common/utils/types';
import { fromJS } from 'immutable';
import {
  HTTP_AUTHENTICATION_SUCCESS,
  AUTHENTICATION_SUCCESS,
} from 'store/constants';

import { ImmutableSessionState } from './types';

export const initialState: ImmutableSessionState = fromJS({
  token: window.localStorage.getItem('jwtToken') || undefined,
  userId: window.localStorage.getItem('userId') || undefined,
});

export default function sessionReducer(
  state = initialState,
  action: ActionT,
): ImmutableSessionState {
  switch (action.type) {
    case HTTP_AUTHENTICATION_SUCCESS:
    case AUTHENTICATION_SUCCESS:
      return state.mergeDeep({
        token: window.localStorage.getItem('jwtToken'),
        userId: window.localStorage.getItem('userId'),
      });
    default:
    // noop
  }

  // TODO: handle error and log out case

  return state;
}
