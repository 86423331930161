import { CareDashboardProvider } from 'common/providers/CareDashboardProvider';
import CareDashboard from 'components/CareDashboard/CareDashboard';
import ApolloContainer from 'containers/ApolloContainer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { Navigate, Route, useLocation, Routes } from 'react-router-dom';

export const CareDashboardContainer = () => {
  const { useFertilityIntake } = useFlags();
  const location = useLocation();

  if (!location || !useFertilityIntake) {
    return <Navigate to="/" />;
  }

  return (
    <ApolloContainer>
      <CareDashboardProvider>
        <Routes>
          <Route {...props} path={`/health-path`} element={<CareDashboard />} />
        </Routes>
      </CareDashboardProvider>
    </ApolloContainer>
  );
};

export default CareDashboardContainer;
