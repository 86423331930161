import { ToastType } from '@everlywell/leaves';
import {
  API_ROOT,
  USERS_BASE_URL,
  ALL_RESULTS_URL,
  AUTH_BY_TOKEN_URL,
  RESULT_URL,
  CONTENT_API_ROOT,
  CONTENT_SNIPPET_PARAMS,
} from 'common/utils/constants';
import { Notification } from 'common/utils/types';
import {
  AUTHENTICATION_SUCCESS,
  HTTP_AUTHENTICATION_REQUEST,
  HTTP_GET_KITS_REQUEST,
  HTTP_GET_USER_DATA_REQUEST,
  INVALIDATE_AUTH_REDIRECT_REQUEST,
  SET_NOTIFICAION,
  CLEAR_NOTIFICAION,
  HTTP_GET_KIT_RESULT_REQUEST,
  HTTP_GET_KIT_RESULT_CONTENT_REQUEST,
  HTTP_SET_KIT_RESULT_SHARING_ON_REQUEST,
  HTTP_SET_KIT_RESULT_SHARING_OFF_REQUEST,
} from 'store/constants';
// export const SUCCESS_NOTIFICATION = ToastType.SUCCESS;
// export const WARNING_NOTIFICATION = ToastType.WARNING;
// export const INFO_NOTIFICATION = ToastType.INFO;

/**
 * returns action with all data to get a user's kit results
 * @param { String } userId current user's id
 * @param { Boolean } useMock if you are using a mock server
 */
export function getKitResults() {
  return {
    type: HTTP_GET_KITS_REQUEST,
    payload: {
      url: `${API_ROOT}${ALL_RESULTS_URL}`,
    },
  };
}

/**
 * returns action with all data to get a user's kit results
 * @param { String } userId current user's id
 */
export function getUserData(userId: string) {
  return {
    type: HTTP_GET_USER_DATA_REQUEST,
    payload: {
      url: `${API_ROOT}${USERS_BASE_URL}/${userId}`,
    },
  };
}

export const ERROR_NOTIFICATION = ToastType.ERROR;

/**
 * authenticate with Store using token received in URL on Login Redirecct
 * @param { String } token authentication token that is grabbed from URL when redirected
 * from Store - use this token to get the JWT associated with the user
 */
export function authenticateToken(token: string) {
  return {
    type: HTTP_AUTHENTICATION_REQUEST,
    payload: {
      url: `${API_ROOT}${AUTH_BY_TOKEN_URL}`,
      options: {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

/**
 * invalidate auth and redirect user to Login
 * @param { String } url URL to redirect user to when auth expires
 * should send user to Login on store, and upon logging in will redirect them
 * back to the URL they were originally trying to access
 */
export function invalidateAuthRedirectRequest(url: string) {
  return {
    type: INVALIDATE_AUTH_REDIRECT_REQUEST,
    payload: url,
  };
}

/**
 * authentication succeeded - creates action to update authenticated state
 */
export function receiveAuthentication() {
  return {
    type: AUTHENTICATION_SUCCESS,
  };
}

export function setNotification(payload: Notification) {
  return {
    type: SET_NOTIFICAION,
    payload,
  };
}

export function clearNotification() {
  return {
    type: CLEAR_NOTIFICAION,
  };
}

// KitResultDetails

export function getKitResult({
  kitResultIdOrPublishHash,
  shared,
}: {
  kitResultIdOrPublishHash: string;
  shared?: boolean;
}) {
  const url = shared
    ? `${API_ROOT}${RESULT_URL}/${kitResultIdOrPublishHash}?shared=true`
    : `${API_ROOT}${RESULT_URL}/${kitResultIdOrPublishHash}`;

  return {
    type: HTTP_GET_KIT_RESULT_REQUEST,
    payload: {
      url,
    },
  };
}

export function getKitResultContent(kitContentToken: string) {
  return {
    type: HTTP_GET_KIT_RESULT_CONTENT_REQUEST,
    payload: {
      url: `${CONTENT_API_ROOT}/${kitContentToken}/${CONTENT_SNIPPET_PARAMS}`,
    },
  };
}

// Share Subheader Button
//
//

export function setKitResultSharingOn({
  kitResultId,
}: {
  kitResultId: string;
}) {
  return {
    type: HTTP_SET_KIT_RESULT_SHARING_ON_REQUEST,
    payload: {
      url: `${API_ROOT}${RESULT_URL}/${kitResultId}/share`,
      options: {
        method: 'PUT',
        body: JSON.stringify({
          share: 'true', // TODO: this should be of type boolean, add ticket in JIRA
        }),
      },
    },
  };
}

export function setKitResultSharingOff({
  kitResultId,
}: {
  kitResultId: string;
}) {
  return {
    type: HTTP_SET_KIT_RESULT_SHARING_OFF_REQUEST,
    payload: {
      url: `${API_ROOT}${RESULT_URL}/${kitResultId}/share`,
      options: {
        method: 'PUT',
        body: JSON.stringify({
          share: 'false', // TODO: this should be of type boolean, add ticket in JIRA
        }),
      },
    },
  };
}
