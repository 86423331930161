import {
  Button,
  SkeletonLoader,
  SkeletonShape,
  SkeletonUnit,
  InformationBox,
} from '@everlywell/leaves';
import { getShippingAddress } from 'common/apis/settingsApis';
import { logError } from 'common/utils/helpers';
import ErrorCard from 'pages/AccountHub/components/ErrorCard';
import FormSummary from 'pages/AccountHub/pages/SettingsPage/FormSummary';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

import RedemptionAddressForm, {
  AddressFormValues,
} from '../RedemptionAddressForm/RedemptionAddressForm';
import * as S from './AddressConfirmation.styles';

export type AddressConfirmationProps = {
  onEditClick: () => void;
  onConfirmClick: (newAddress: AddressFormValues) => void;
  onBackClick: () => void;
  onFormBackClick: () => void;
  onFormSubmitClick: () => void;
  isNyUserCallback: (isNyUser: boolean) => void;
};

const EMPTY_ADDRESS: AddressFormValues = {
  first_name: '',
  last_name: '',
  street1: '',
  street2: '',
  city: '',
  state: '',
  zipcode: '',
};

/**
 * Address confirmation component for CreditRedemption. User can confirm their address here or update it.
 */
export default function AddressConfirmation({
  onEditClick,
  onConfirmClick,
  onBackClick,
  onFormBackClick,
  onFormSubmitClick,
  isNyUserCallback,
}: AddressConfirmationProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [wasEdited, setWasEdited] = useState(false);
  const [newShippingAddress, setNewShippingAddress] =
    useState<AddressFormValues>();

  const {
    data: shippingAddressResponse,
    error: errorWithAddress,
    refetch: refetchAddress,
    isLoading,
  } = useQuery(['shipping-address'], getShippingAddress, {});

  const shippingAddress =
    newShippingAddress || shippingAddressResponse?.data || EMPTY_ADDRESS;

  const hasCompleteAddress =
    !isLoading &&
    shippingAddress?.first_name &&
    shippingAddress?.last_name &&
    shippingAddress?.street1 &&
    shippingAddress?.city &&
    shippingAddress?.state &&
    shippingAddress?.zipcode;

  const addressSummaryLines = hasCompleteAddress
    ? [
        `${shippingAddress.first_name} ${shippingAddress.last_name}`,
        `${shippingAddress.street1}`,
        `${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.zipcode}`,
      ]
    : [''];

  if (errorWithAddress) {
    logError((errorWithAddress as Error).message, {
      errorInfo: 'Error fetching Address',
      component: 'AddressConfirmation',
      method: 'errorWithAddress',
    });
  }

  const handleEditClick = () => {
    setIsEditing(true);
    onEditClick();
  };

  const handleBackClick = () => {
    setIsEditing(false);
    onBackClick();
  };

  const handleFormBack = () => {
    setIsEditing(false);
    setWasEdited(false);
    onFormBackClick();
  };

  const handleFormSubmit = (addressFormValues: AddressFormValues) => {
    setNewShippingAddress(addressFormValues);
    setIsEditing(false);
    setWasEdited(true);
    onFormSubmitClick();
    isNyUserCallback(addressFormValues.state === 'NY');
  };

  const handleOnConfirmClick = () => {
    const address = newShippingAddress || shippingAddress;
    isNyUserCallback(address.state === 'NY');
    onConfirmClick(address);
  };

  const showSummary = hasCompleteAddress && !errorWithAddress && !isEditing;
  const showAddressFrom = isEditing || (!hasCompleteAddress && !isLoading);
  const showButtons =
    !isEditing && !errorWithAddress && !isLoading && hasCompleteAddress;
  const showButtonsSkeleton = !showButtons && !isEditing && hasCompleteAddress;
  const showUpdateInfo = wasEdited && !isEditing;
  const showError = errorWithAddress && !isLoading;

  return (
    <S.Container>
      <S.MainContent>
        {isLoading ? (
          <SkeletonLoader
            height={{
              value: 132,
              unit: SkeletonUnit.Pixel,
            }}
            width={{
              value: 100,
              unit: SkeletonUnit.Percentage,
            }}
            shape={SkeletonShape.Rectangle}
          />
        ) : null}

        {showSummary ? (
          <>
            <S.ConfirmationTitle>
              Is this your delivery address?
            </S.ConfirmationTitle>
            <FormSummary
              topLine=""
              secondaryLines={addressSummaryLines}
              onEditClick={() => handleEditClick()}
            />
          </>
        ) : null}

        {/* If the address is not complete, show the form immediately */}
        {showAddressFrom ? (
          <RedemptionAddressForm
            title="Delivery address"
            backButtonText="Back"
            confirmButtonText="Confirm"
            backButtonCallback={handleFormBack}
            confirmButtonCallback={handleFormSubmit}
            shippingAddress={shippingAddress}
            handleFocus={() => {}}
          />
        ) : null}

        {/* TODO: Show this after the address is edited */}
        {showUpdateInfo ? (
          <S.InformationBoxWrapper>
            <InformationBox text="We've updated your address"></InformationBox>
          </S.InformationBoxWrapper>
        ) : null}

        {showError ? <ErrorCard onRetry={refetchAddress} /> : null}
      </S.MainContent>

      {!isEditing ? (
        <S.ButtonsWrapper>
          {showButtons ? (
            <>
              <S.BackButton appearance="secondary" onClick={handleBackClick}>
                Back
              </S.BackButton>
              <Button onClick={handleOnConfirmClick}>We're all set</Button>
            </>
          ) : null}

          {showButtonsSkeleton ? (
            <SkeletonLoader
              height={{
                value: 50,
                unit: SkeletonUnit.Pixel,
              }}
              width={{
                value: 100,
                unit: SkeletonUnit.Percentage,
              }}
              shape={SkeletonShape.Rectangle}
            />
          ) : null}
        </S.ButtonsWrapper>
      ) : null}
    </S.Container>
  );
}
