import { mediaQueries } from '@everlywell/leaves';
import { theme } from '@everlywell/ui-kit';
import styled from 'styled-components';

const {
  colors: { tints },
} = theme;

const Container = styled.div<{
  inMobileWebview: boolean;
  isAthenaCarePlanPage: boolean;
}>`
  background-color: ${({ isAthenaCarePlanPage }) =>
    isAthenaCarePlanPage ? tints.cream : ''};
  padding-top: ${({ inMobileWebview }) => (inMobileWebview ? 0 : '68px')};

  ${mediaQueries.forTabletHorizontalUp} {
    padding-top: ${({ inMobileWebview }) => (inMobileWebview ? 0 : '57px')};
  }
`;

export { Container };
