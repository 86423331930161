import { useQuery as useApolloClientQuery } from '@apollo/client';
import { Article } from 'common/utils/constants';
import { ALL_RECOMMENDED_ARTICLES } from 'common/utils/contentfulGraphQlQueries';
import { logError } from 'common/utils/helpers';

export type RecommendedArticlesCollection = {
  pageContainer: {
    pagesCollection: {
      items: Article[];
    };
  };
};

export default function useContentfulRecommendedArticles(
  pageContainerId: string,
) {
  const { data, loading: isLoading } =
    useApolloClientQuery<RecommendedArticlesCollection>(
      ALL_RECOMMENDED_ARTICLES,
      {
        variables: {
          pageContainerId,
        },
        onError(error) {
          logError((error as Error).message, {
            errorInfo: 'Graphql fetching Error',
            component: 'useContentfulRecommendedArticles',
            method: 'useApolloClientQuery',
          });
        },
      },
    );

  const articles = data?.pageContainer.pagesCollection.items;

  return {
    articles: articles ?? [],
    isLoading,
  };
}
