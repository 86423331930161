import {
  GetSavedInsurancePlansResponse,
  getSavedInsurancePlans,
} from 'common/apis/telehealthApis';
import { useQuery } from 'react-query';

export const useLatestInsurancePlans = () => {
  const result = useQuery(['useLatestInsurancePlans'], () =>
    getSavedInsurancePlans(),
  );

  let primaryInsurance;
  let secondaryInsurance;

  if (result.data?.data) {
    // NOTE: API returns insurance_details from most recent to least recent
    // so the first item in the array is the most recent insurance
    const insuranceDetails = result.data.data.insurance_details;
    primaryInsurance = findInsuranceByPriorityType(insuranceDetails, 'primary');
    secondaryInsurance = findInsuranceByPriorityType(
      insuranceDetails,
      'secondary',
    );
  }

  return { primaryInsurance, secondaryInsurance, ...result };
};

const findInsuranceByPriorityType = (
  items: GetSavedInsurancePlansResponse['insurance_details'],
  type: 'primary' | 'secondary',
) => items.find((item) => item.priority_type === type);
