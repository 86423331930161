import { colors, mediaQueries, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Link = styled.a`
  ${typography.bodyTextSmall};
  color: ${colors.blue1};
  text-decoration: none;
`;

export const ButtonWrapper = styled.div`
  margin-top: ${size.xs1}px;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${mediaQueries.forTabletVerticalUp} {
    width: 280px;
  }
`;

export const LinkWrapper = styled.div`
  margin-top: ${size.xs1}px;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${mediaQueries.forTabletVerticalUp} {
    width: 100%;
  }
`;
