import {
  documentToReactComponents,
  NodeRenderer,
} from '@contentful/rich-text-react-renderer';
import { INLINES, Hyperlink } from '@contentful/rich-text-types';
import type { Document } from '@contentful/rich-text-types';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { PERSONALIZATION_FACTS as DATA_TEST } from 'common/utils/constants/dataTest';
import { hasRatedPersonalization } from 'common/utils/helpers';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import PersonalizationCheckIn from 'pages/KitResultDetailPage/PersonalizationCheckIn';
import { capitalize } from 'lodash';
import React, { FC } from 'react';

import certifyCheckMark from './images/certify-check-mark.svg';
import * as S from './styles';

const PERSONALIZATION_LABEL = ANALYTICS.LABELS.FACT_SET_PERSONALIZATION;

type Source = string;
interface SourcesProps {
  sources: (Source | undefined)[];
  length: number;
}

export type Props = {
  index?: number;
  testLabel?: string;
  length: number;

  copy: {
    json: Document;
  };
  icon: {
    description: string;
    title: string;
    url: string;
  };
  slug: string;
  source1?: Source;
  source2?: Source;
  title: string;
};

const Link = ({ node }: { node: Hyperlink }): JSX.Element => (
  <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
    {node.content[0].value}
  </a>
);

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: ((node: Hyperlink) => (
      <Link node={node} />
    )) as unknown as NodeRenderer,
  },
};

const Sources: FC<SourcesProps> = ({ sources, length }) => (
  <S.SourcesWrapper length={length}>
    {sources.map((source, index) =>
      source ? (
        <S.Source key={`source-${source}`}>
          <img
            src={certifyCheckMark}
            alt="certified checkmark"
            style={{ marginRight: '8px' }}
          />
          {source}
        </S.Source>
      ) : null,
    )}
  </S.SourcesWrapper>
);

const FactCard: FC<Props> = ({
  copy,
  icon,
  index = 0,
  slug,
  source1,
  source2,
  testLabel,
  title,
  length,
}) => {
  const sources: (Source | undefined)[] = [source1, source2];

  const factCardPersonalizationEl = useScrolledIntoViewOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: `${ANALYTICS.LABELS.FACT_SET_PERSONALIZATION} - ${slug}`,
      },
    });
  });

  const handleClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: `${ANALYTICS.LABELS.FACT_SOURCE} [${sources}] - ${slug}`,
      },
    });
  };

  return (
    <S.Wrapper
      data-testid={`${DATA_TEST.FACT_CARD}-${slug}`}
      data-test={`${testLabel}${index + 1}`}
      ref={factCardPersonalizationEl}
    >
      <S.FactContainer length={length}>
        <S.ContentWrapper length={length}>
          <S.Title length={length}>{capitalize(title)}</S.Title>
          <S.Copy onClick={handleClick}>
            {copy ? documentToReactComponents(copy.json, options) : ''}
          </S.Copy>
          <Sources length={length} sources={sources} />
        </S.ContentWrapper>
        <S.ImageWrapper length={length}>
          <S.Image
            src={icon?.url}
            alt={`${icon?.title} fact`}
            data-test={DATA_TEST.FACT_IMAGE}
            length={length}
          />
        </S.ImageWrapper>
      </S.FactContainer>
      {!hasRatedPersonalization(slug, PERSONALIZATION_LABEL) ? (
        <PersonalizationCheckIn
          widget={`${PERSONALIZATION_LABEL} - ${slug}`}
          hasToolTip={false}
        />
      ) : null}
    </S.Wrapper>
  );
};

export default FactCard;
