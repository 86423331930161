export default {
  title: 'Membership Shipping Information',
  careTitle: 'Where should we ship your monthly Plan bundle?',
  subtitle: 'Shipping Address',
  saveButton: 'Save',
  cancelButton: 'Cancel',
  notifications: {
    fetchFailed: {
      title: 'An error occurred',
      message: 'Failed to load shipping address information',
    },
    updateFailed: {
      title: 'An error occurred',
      message: 'Failed to update shipping address information',
    },
    updateSuccess: {
      title: 'Update',
      message: 'Shipping information updated',
    },
    defaultError: {
      title: 'An error occurred',
      message: 'Please try again.',
    },
  },
};
