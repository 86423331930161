import { STATUSES } from 'common/utils/constants';
import React from 'react';

import {
  getActiveIndex,
  getLabels,
  getStatusTexts,
} from '../../statuses.utils';
import * as S from './styles';

type Props = {
  status: string;
  needsReplacement: boolean;
  barWidth: number;
  isRapidAntigen: boolean;
  confirmIdentifiers: boolean;
  isLabVisit: boolean;
};

const ProgressBar = (props: Props) => {
  const {
    status,
    needsReplacement,
    barWidth,
    isRapidAntigen,
    confirmIdentifiers,
    isLabVisit,
  } = props;

  const statuses = getLabels(isLabVisit, status, isRapidAntigen);

  const activeIndex = getActiveIndex(status, statuses);

  const statusTexts = getStatusTexts(statuses);

  const getProgressBarContent = () =>
    statusTexts.map((statusText, i) => (
      <S.StatusText
        hasIssue={needsReplacement || confirmIdentifiers}
        isActive={i <= activeIndex}
        key={statusText}
        data-test="status-text"
      >
        <S.StatusTextWrapper>{statusText}</S.StatusTextWrapper>
        {i < activeIndex ||
        (i <= activeIndex && status === STATUSES.DELIVERED) ||
        (i <= activeIndex && status === STATUSES.LAB_VISIT_APPROVED) ||
        activeIndex === statusTexts.length - 1 ||
        i === 0 ? (
          <S.CheckMark data-test="check-mark-icon" />
        ) : null}
      </S.StatusText>
    ));

  return barWidth ? (
    <S.ProgressBarContainer>
      <S.ProgressBar
        status={status}
        hasIssue={needsReplacement || confirmIdentifiers}
        width={barWidth}
      />
      <S.StatusWrapper hasIssue={needsReplacement || confirmIdentifiers}>
        {getProgressBarContent()}
      </S.StatusWrapper>
    </S.ProgressBarContainer>
  ) : null;
};

export default ProgressBar;
