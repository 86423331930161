import { colors, mediaQueries } from '@everlywell/leaves';
import { hexToRGB } from 'common/utils/helpers';
import styled from 'styled-components';

import triangle from './images/triangle.svg';

type Props = {
  triangleLeftPosition: number;
  tooltipWidth?: number;
};

export const TRIANGLE_WIDTH = 24;
export const TRIANGLE_HEIGHT = 13;

const Wrapper = styled.div<Props>`
  background-color: ${colors.white};
  position: absolute;
  z-index: 11;
  box-shadow: 0 0 9px 0 rgba(${hexToRGB(colors.black)}, 0.03);
  border: 1px solid ${colors.gray2};
  border-radius: 3px;
  max-width: calc(100% - 72px);

  ${mediaQueries.forTabletVerticalUp} {
    ${(props) => (props.tooltipWidth ? `width: ${props.tooltipWidth}px` : '')};
  }

  &:after {
    /* prettier-ignore */
    background-image: url("${triangle}");
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    width: ${TRIANGLE_WIDTH}px;
    height: ${TRIANGLE_HEIGHT}px;
    bottom: -${TRIANGLE_HEIGHT - 1}px;
    left: ${(props) => props.triangleLeftPosition}px;
  }
`;

export { Wrapper };
