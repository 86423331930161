import { TelehealthCustomModuleCondition } from 'common/apis/telehealthApis';
import { useFormContext } from 'react-hook-form';

import { filtersMap } from '../../utils/filters';

/**
 * Check if the field should be visible or not based on the condition payload
 */
function useFieldVisibility(condition: TelehealthCustomModuleCondition | null) {
  const { watch } = useFormContext();

  const { conditional_custom_module_id, filter_type, value_to_filter } =
    condition || {};

  if (!condition) {
    return true;
  }

  const value = watch(conditional_custom_module_id);

  // value is required and should be a string.
  // If there is no value, then the condition is not met.
  let isVisible =
    typeof value === 'string' &&
    value.length > 0 &&
    filter_type &&
    value_to_filter
      ? filtersMap[filter_type](value, value_to_filter)
      : false;

  return isVisible;
}

export default useFieldVisibility;
