import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { HEALTH_PROFILE_BANNER as DATA_TEST } from 'common/utils/constants/dataTest';
import { User } from 'common/utils/types';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import React from 'react';

import Star from './images/Star.svg';
import StartIcon from './images/StartIcon.svg';
import * as S from './styles';

export interface Props {
  progress: number;
  seen: boolean;
  unansweredQuestions: number;
  user: User;
  lastInteraction?: string | null;
}

export const bannerHeaders = {
  finish: 'Finish your health profile',
  start: 'Start filling out your health profile',
};

const HealthProfileBanner = ({
  progress,
  seen,
  unansweredQuestions,
  user,

  lastInteraction,
}: Props) => {
  const bannerViewed = useScrolledIntoViewOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: ANALYTICS.LABELS.HEALTH_PROFILE_BANNER,
      },
    });
  });

  const handleClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: seen
          ? ANALYTICS.LABELS.FINISH_HEALTH_PROFILE_BUTTON
          : ANALYTICS.LABELS.START_HEALTH_PROFILE_BUTTON,
      },
    });
  };
  const ProgressCircle = () => (
    <>
      <S.Circle viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
          className="circle-background"
          d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="circle"
          strokeDasharray={`${progress}, 100`}
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <S.Percentage x="19" y="22">{`${progress}%`}</S.Percentage>
      </S.Circle>
      <S.StarImage src={Star} alt="" />
    </>
  );

  const pluralizeAnswers: string =
    unansweredQuestions === 1 ? 'answer' : 'answers';

  return (
    <S.BannerContainer>
      <S.Banner data-test={DATA_TEST.CONTAINER} ref={bannerViewed}>
        {seen ? (
          <ProgressCircle data-test={`${DATA_TEST.ICON}-progress-circle`} />
        ) : (
          <S.Image src={StartIcon} alt="" />
        )}
        <S.BannerWrapper>
          <S.ContentWrapper>
            <S.Header>
              {seen ? bannerHeaders.finish : bannerHeaders.start}
            </S.Header>
            <S.Text>
              {seen
                ? `You're only ${unansweredQuestions} ${pluralizeAnswers} away from more personalized data
              and insights.`
                : 'Get more personalized data and insights about your health by answering a few questions.'}
            </S.Text>
          </S.ContentWrapper>
          <S.ButtonLink
            to={'/health-profile/?unanswered=true'}
            state={{ user, lastInteraction }}
          >
            <S.BannerButton appearance="tertiary" onClick={handleClick}>
              {seen ? 'Finish' : 'Start'}
            </S.BannerButton>
          </S.ButtonLink>
        </S.BannerWrapper>
      </S.Banner>
    </S.BannerContainer>
  );
};

export default HealthProfileBanner;
