import { Box, Text, useRadioGroup } from '@everlywell/ui-kit';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FormBuilderField } from '../../utils/types';
import RadioButton from './RadioButton';

export type SnomedFieldProps = Omit<FormBuilderField, 'options_array'> & {
  options_array: Array<string[]>;
};

const SnomedField = (props: SnomedFieldProps) => {
  const { register, errors } = useFormContext();
  const { label, options_array, required, id } = props;

  const { getRadioProps, getRootProps } = useRadioGroup({
    name: id,
    onChange: () => {},
  });

  return (
    <Box
      {...getRootProps}
      as="fieldset"
      display="flex"
      flexDirection="column"
      gap="3"
    >
      {label && (
        <Text
          as="legend"
          color="tints.black"
          fontFamily="heading"
          fontSize="4xl"
          marginBottom="4"
        >
          {label}
        </Text>
      )}
      {options_array?.map(([label, value]) => {
        const radio = getRadioProps({ value });
        return (
          <RadioButton
            key={value}
            {...radio}
            ref={register({ required: required && 'Please select an option' })}
          >
            {label}
          </RadioButton>
        );
      })}
      {errors?.[id] && (
        <Text fontSize="md" color="utility.warning">
          {errors[id].message}
        </Text>
      )}
    </Box>
  );
};

export default SnomedField;
