import { Information } from '@everlywell/leaves';
import React from 'react';

import * as S from './AcceptedCards.styles';

export type Props = {
  showTip?: boolean;
  acceptedCardsNote?: string;
};

export const AcceptedCards = ({ acceptedCardsNote, showTip = true }: Props) => (
  <S.Container>
    <S.AcceptedCardsContainer data-test="accepted-cards-section">
      <S.Cards>
        <S.MasterCard
          src="https://images.ctfassets.net/lrd5ir86ur94/14FLbzPYa4aleWD1E7pvUa/75a42e3c86e1bb0acc900d3d0dcbcbfb/Mastercard.png"
          alt="Master Card"
        />
        <S.Visa
          src="https://images.ctfassets.net/lrd5ir86ur94/1yummzxHenvxPXyY3ecLvJ/1092906965ca9b72f38bd9d4ee816aad/Visa.png"
          alt="Visa"
        />
        <S.AmericanExpress
          src="https://images.ctfassets.net/lrd5ir86ur94/1ex0hTkXy4QePFKRa3US2m/22b0ec234f75096ebae058ab6f089c94/American_Express.png"
          alt="American Express"
        />
        <S.Discover
          src="https://images.ctfassets.net/lrd5ir86ur94/1QMQbuqKj8bs46p1iWAplI/599259a9c10962fc82463428b250e505/Discover.png"
          alt="Discover"
        />
      </S.Cards>
    </S.AcceptedCardsContainer>
    {acceptedCardsNote && (
      <S.Note data-test="accepted-cards-hsa-note">
        {showTip && (
          <S.Tip
            content="Tests are eligible for reimbursement from your FSA/HSA, but Everlywell cannot guarantee reimbursement."
            position="top"
            arrow="center"
            animationSpeed="normal"
            tooltipBoxClass="paymentTooltip"
          >
            <Information />
          </S.Tip>
        )}
        <S.HSA>{acceptedCardsNote}</S.HSA>
      </S.Note>
    )}
  </S.Container>
);

export default AcceptedCards;
