import { colors, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

type StepProps = {
  width: number;
  icon?: boolean;
};

type DescriptionProps = {
  icon?: boolean;
};

const Description = styled.div<DescriptionProps>`
  width: 100%;
  text-align: ${(props) => (props.icon ? 'left' : 'center')};
  color: ${colors.gray4};
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const itemMarginHorizontal = 7; // Left and right margins
const itemMarginVertical = 3; // Top and bottom margins

const Step = styled.div<StepProps>`
  width: calc(${(props) => props.width - 5}% - ${itemMarginHorizontal}%);
  margin: 0 ${itemMarginHorizontal / 2}% ${itemMarginVertical}%
    ${itemMarginHorizontal / 2}%;
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.icon ? 'column' : 'column-reverse')};

  ${mediaQueries.forPhoneOnly} {
    width: 100%;
    margin: 3% 0;
  }
`;

const StepImg = styled.div<DescriptionProps>`
  overflow: hidden;
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: ${(props) => (props.icon ? 'flex-start' : 'center')};

  img {
    width: 100%;
    height: auto;
    ${(props) => (props.icon ? '' : 'align-self: center;')};
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  margin: 10px 0;
`;

export { Description, Image, Step, StepImg, Wrapper };
