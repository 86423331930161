import { mediaQueries, size } from '@everlywell/leaves';
import { Col_12_Span_10 } from 'common/styles/grid-styles';
import styled from 'styled-components';

const ResourcesLinksSectionWrapper = styled(Col_12_Span_10)`
  padding-top: ${size.xl3}px;
  padding-bottom: ${size.xl1}px;

  ${mediaQueries.forTabletHorizontalUp} {
    padding-bottom: ${size.xl3}px;
    padding-top: ${size.xl6}px;
  }

  ${mediaQueries.forBigDesktopUp} {
    background-size: 100%;
  }
`;

export { ResourcesLinksSectionWrapper };
