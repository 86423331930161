import { typography, Container, mediaQueries } from '@everlywell/leaves';
import { colors, size } from '@everlywell/leaves';
import { bodyCopyLarge } from 'common/styles/app-styles';
import { Col_10 as Col10 } from 'common/styles/grid-styles';
import { hexToRGB } from 'common/utils/helpers';
import styled, { css } from 'styled-components';

type Props = {
  rounded?: boolean;
};

const ResultsWrapper = styled(Container)`
  padding-bottom: ${size.xs1}px;
  margin: 0 75px;

  ${mediaQueries.forTabletVerticalUp} {
    padding-bottom: 0;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    padding-bottom: ${size.xs1}px;
  }
`;

const IconWrapper = styled.span`
  margin-right: 6px;
`;

const roundedHeader = css`
  &:first-child {
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;

    ${mediaQueries.forTabletVerticalUp} {
      border-top-left-radius: 180px;
      border-top-right-radius: 180px;
    }
  }
`;

/* stylelint-disable no-descending-specificity */
const Sections = styled.div<Props>`
  section:nth-child(odd) {
    background-color: ${colors.white};

    > div {
      ${(props) => props.rounded && roundedHeader};
      background-color: ${colors.gray1};

      > div {
        margin-bottom: 0;
        padding-bottom: ${size.sm}px;
      }
    }
  }

  section:nth-child(2n) {
    background-color: ${colors.gray1};

    > div {
      ${(props) => props.rounded && roundedHeader};
      background-color: ${colors.white};

      > div {
        margin-bottom: 0;
        padding-bottom: ${size.sm}px;
      }
    }
  }
`;
/* stylelint-enable no-descending-specificity */

const SharedResultInfo = styled.div`
  background-color: rgba(${hexToRGB(colors.green4)}, 0.9);
  padding: ${size.xs1}px 0;
  font-weight: ${typography.weight.regular};
  ${bodyCopyLarge};
  text-align: center;
  color: ${colors.white};
  z-index: 5;
  position: relative;

  ${mediaQueries.forTabletVerticalUp} {
    padding-bottom: ${size.xs2}px 0;
  }
`;

const BannerWrapper = styled.div`
  display: block;
`;

const FSPersonalization = styled.div`
  padding: ${size.xl3}px;
  height: 400px;
`;

const SavePDFSection = styled(Col10)`
  margin-top: ${size.xl2}px;
`;

const PromoWrapper = styled.div`
  padding-top: ${size.xl1}px;

  ${mediaQueries.forTabletHorizontalUp} {
    padding: ${size.xl2}px 0;
  }
`;

export {
  BannerWrapper,
  FSPersonalization,
  IconWrapper,
  PromoWrapper,
  ResultsWrapper,
  SavePDFSection,
  Sections,
  SharedResultInfo,
};
