import { Col } from '@everlywell/leaves';
import { invisibleInkSKUS } from 'common/utils/constants';
import React, { useEffect, useState } from 'react';

import { TestCollectionMethod, bloodCollectionMethods } from '../model';
import { breakpointsVals } from './breakpoints';
import { ADXMailingSample } from './data/adx.data';
import { DBADXMailingSample } from './data/db-adx.data';
import { DBSIIMailingSample } from './data/dbs-ii.data';
import { DBSMailingSample } from './data/dbs.data';
import ImageMarker from './ImageMarker';
import ImageWithMarkers from './ImageWithMarkers';
import { SampleCardViewport } from './model';
import * as S from './styles';

type Props = {
  methods: TestCollectionMethod[];
  sku?: string;
};

const sampleMap = {
  [TestCollectionMethod.blood]: DBSMailingSample,
  [TestCollectionMethod.blood_adx100]: ADXMailingSample,
  [TestCollectionMethod.blood_double_strip_adx]: DBADXMailingSample,
  [TestCollectionMethod.blood_dbs_ii]: DBSIIMailingSample,
};

const targetMethod = ({ methods, sku }: Props) => {
  if (sku && invisibleInkSKUS.includes(sku)) {
    return TestCollectionMethod.blood_dbs_ii;
  }

  return (
    bloodCollectionMethods.find((collectionMethod) =>
      methods.includes(collectionMethod),
    ) || TestCollectionMethod.blood
  );
};

const SampleInstructions = (props: Props) => {
  const targetCollectionMethod = targetMethod(props);

  const getViewport = () => {
    switch (true) {
      case window.innerWidth <= breakpointsVals.s: {
        return SampleCardViewport.mobile;
      }
      case window.innerWidth <= breakpointsVals.m &&
        window.innerWidth > breakpointsVals.s: {
        return SampleCardViewport.tablet;
      }
      default: {
        return SampleCardViewport.desktop;
      }
    }
  };
  const targetData = sampleMap[targetCollectionMethod];
  const [_viewport, _setViewport] = useState(getViewport());

  useEffect(() => {
    const debouncedViewportResize = () => {
      const viewport = getViewport();
      _setViewport(viewport);
    };
    window.addEventListener('resize', debouncedViewportResize);

    return () => window.removeEventListener('resize', debouncedViewportResize);
  }, []);

  if (targetData && Array.isArray(targetData)) {
    return (
      <S.Wrapper center="xs" middle="xs">
        <Col xs={12} md={12} lg={12}>
          <S.Title>Your sample should look like this</S.Title>
          <S.Card>
            {targetData &&
              Array.isArray(targetData) &&
              targetData.map((item) => (
                <ImageWithMarkers
                  key={item.id}
                  width={item.width}
                  height={item.height}
                  image={item.image}
                >
                  {item.markers &&
                    Array.isArray(item.markers) &&
                    item.markers.length &&
                    item.markers.map((marker: any) => (
                      <ImageMarker
                        key={marker.id}
                        line={marker.line}
                        title={marker.title}
                        lineColor={marker.lineColor}
                        viewport={_viewport}
                      />
                    ))}
                  {item.subtitle && item.subtitle.copy && (
                    <S.Subtitle subtitle={item.subtitle}>
                      {item.subtitle.copy}
                    </S.Subtitle>
                  )}
                </ImageWithMarkers>
              ))}
          </S.Card>
        </Col>
      </S.Wrapper>
    );
  }
  return null;
};

export default SampleInstructions;
