import { Button as LeavesButton, mediaQueries, size } from '@everlywell/leaves';
import styled from 'styled-components';

export const Button = styled(LeavesButton)`
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    margin-top: ${size.lg}px;
  }
`;
