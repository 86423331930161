/**
 *
 * FoodSensitivityResultDetail
 *
 */

import { Localize } from '@everlywell/leaves';
import { Col_10 as Col10 } from 'common/styles/grid-styles';
import consolidateFSReactivityGroups from 'common/utils/consolidateFSReactivityGroups';
import {
  GROUPING_METHODS,
  SCROLL_TO_RESULTS,
  FS_LAB,
} from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { formatStringSeparator } from 'common/utils/formatText';
import { isFaqOneAvailableForConsumers } from 'common/utils/helpers';
import { generateFSResultProps } from 'common/utils/resultGenerator';
import { KitResult, FoodSensitivityGroup } from 'common/utils/types';
import Callout from 'components/Callout';
import TooltipAccordion from 'components/TooltipAccordion';
import _ from 'lodash';
import pluralize from 'pluralize';
import React from 'react';

import FoodSensitivityMarkerGroupCard from '../FoodSensitivityMarkerGroupCard';
import SegmentedControl from '../SegmentedControl';
import * as S from './styles';

export type Props = {
  kitResult?: KitResult;
  showFsPersonalization?: boolean;
};

type State = {
  groupBy: string;
  normalReactivityIsExpanded: boolean;
};

const controlList = [
  { label: 'Reactivity', value: GROUPING_METHODS.REACTIVITY },
  { label: 'Food', value: GROUPING_METHODS.FOOD, eventTracking: true },
];

/* eslint-disable react/prefer-stateless-function */
class FoodSensitivityResultDetail extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.renderFoodSensitivityResults =
      this.renderFoodSensitivityResults.bind(this);
    this.state = {
      groupBy: GROUPING_METHODS.REACTIVITY,
      normalReactivityIsExpanded: false,
    };
  }

  handleGroupChange = (value: string) => {
    this.setState({ groupBy: value });
  };

  renderMarkerGroupHeadingContainer = (
    reactivity: string,
    totalMarkers: number,
    severityIndexes: number[] | undefined,
  ) => {
    const isNormal = reactivity === 'normal reactivity';
    let headingSuffix = '';
    if (isNormal) {
      headingSuffix = this.state.normalReactivityIsExpanded
        ? '-expanded'
        : '-closed';
    }
    const dataTest = formatStringSeparator(
      `heading ${reactivity.toLowerCase()}${headingSuffix}`,
    );

    const getSeverityLabel = () => {
      if (!severityIndexes) return '';

      if (severityIndexes.length && severityIndexes.length === 1) {
        const severity = severityIndexes.map((severityIndex, index) => {
          if (index < severityIndexes.length - 1) {
            const indexLabel = severityIndex;
            return indexLabel;
          }
          return `(Class ${severityIndex}): `;
        });
        return severity;
      }

      const firstItem = severityIndexes[0];
      const lastItem = severityIndexes[severityIndexes.length - 1];

      return `(Class  ${firstItem} - ${lastItem}): `;
    };

    if (!totalMarkers)
      return (
        <S.MarkerGroupHeadingContainer>
          <S.MarkerGroupHeading isHidden={false} data-test={dataTest}>
            {/* You didn&rsquo;t show {reactivity} to any foods */}
            <S.GroupName>{reactivity} </S.GroupName>
            <S.LineBreak />
            <S.GroupName>{getSeverityLabel()}</S.GroupName>
            <Localize
              name="IntensityResultDetail-normalReactivityTotalMarkers"
              pluralize={totalMarkers}
            >
              {totalMarkers}
            </Localize>{' '}
            {_.capitalize(pluralize('food', totalMarkers))}
          </S.MarkerGroupHeading>
        </S.MarkerGroupHeadingContainer>
      );

    const normalReactivity = reactivity === 'normal reactivity';
    const normalReactivityHeading = normalReactivity ? (
      <S.MarkerGroupHeading
        isHidden={this.state.normalReactivityIsExpanded}
        data-test={dataTest}
      >
        Your results were normal for the rest of the foods in the test
      </S.MarkerGroupHeading>
    ) : null;

    return (
      <S.MarkerGroupHeadingContainer>
        {normalReactivityHeading}
        <S.MarkerGroupHeading
          isHidden={normalReactivity && !this.state.normalReactivityIsExpanded}
          data-test={dataTest}
        >
          <S.GroupName>{reactivity} </S.GroupName>
          <S.LineBreak />
          <S.GroupName>{getSeverityLabel()}</S.GroupName>
          <Localize name="FSResultDetail-totalMarkers" pluralize={totalMarkers}>
            {totalMarkers}
          </Localize>
          {` `}
          {pluralize('Food', totalMarkers)}
        </S.MarkerGroupHeading>
      </S.MarkerGroupHeadingContainer>
    );
  };

  renderMarkerGroupFooterContainer = (
    reactivity: string,
    kitResult: KitResult,
  ) => {
    const isMild = reactivity === 'mild reactivity';

    const { FAQ_TOOLTIP_3_PREVIEW, FAQ_TOOLTIP_3_DESCRIPTION } =
      kitResult?.test?.content ?? {};

    const faqThreeAvailable = Boolean(FAQ_TOOLTIP_3_DESCRIPTION);

    const mildReactivityFooterTooltip =
      isMild && faqThreeAvailable ? (
        <TooltipAccordion
          preview={FAQ_TOOLTIP_3_PREVIEW}
          description={FAQ_TOOLTIP_3_DESCRIPTION}
        />
      ) : null;

    return mildReactivityFooterTooltip;
  };

  showNormalReactivity = () =>
    this.setState({
      normalReactivityIsExpanded: true,
    });

  // Placeholder function to generate the facade
  renderFoodSensitivityResults = (groupBy?: string) => {
    const { kitResult } = this.props;

    if (!kitResult) return null;

    const { marker_results, markers, test } = kitResult;

    if (!marker_results || !markers || !test) return null;

    const lab = FS_LAB[test.type];

    let isScrollIdSet = false;

    const { FAQ_TOOLTIP_2_HEADER, FAQ_TOOLTIP_2_DESCRIPTION } =
      kitResult.test?.content ?? {};

    const faqTwoAvailable = Boolean(FAQ_TOOLTIP_2_DESCRIPTION);

    return consolidateFSReactivityGroups(
      generateFSResultProps(marker_results, markers, lab, groupBy),
    ).map((fsGroup: FoodSensitivityGroup, index: number) => {
      const groupedMarkersCount = fsGroup.groupedMarkers.length;
      let groupId = '';

      if (groupedMarkersCount && !isScrollIdSet) {
        isScrollIdSet = true;
        groupId = SCROLL_TO_RESULTS;
      }

      return (
        <div
          key={index.toString()}
          id={groupId}
          data-id={index.toString()}
          data-test={formatStringSeparator(
            `group ${fsGroup.title.toLowerCase()}`,
          )}
        >
          <S.MarkerGroupWrapper>
            {this.renderMarkerGroupHeadingContainer(
              fsGroup.title,
              groupedMarkersCount,
              fsGroup.includedSeverityIndexes,
            )}

            <FoodSensitivityMarkerGroupCard
              markers={markers}
              markerResults={fsGroup.groupedMarkers}
              groupedByFood={this.state.groupBy === GROUPING_METHODS.FOOD}
              groupTitle={fsGroup.title}
              showNormalReactivity={this.showNormalReactivity}
              normalReactivityIsExpanded={this.state.normalReactivityIsExpanded}
              test={test}
            />
          </S.MarkerGroupWrapper>
          {!this.props.showFsPersonalization &&
            faqTwoAvailable &&
            fsGroup.title === 'moderate reactivity' && (
              <Callout
                title={FAQ_TOOLTIP_2_HEADER}
                description={FAQ_TOOLTIP_2_DESCRIPTION}
                analyticsData={{
                  label: ANALYTICS.LABELS.FAQ_TOOLTIP_2_MIDDLE,
                  category: ANALYTICS.CATEGORIES.FAQ_TOOLTIPS,
                }}
                productName={kitResult?.test?.name}
              />
            )}
          {this.renderMarkerGroupFooterContainer(fsGroup.title, kitResult)}
        </div>
      );
    });
  };

  render() {
    const { kitResult, showFsPersonalization } = this.props;

    if (!kitResult) return null;

    const { FAQ_TOOLTIP_1_HEADER, FAQ_TOOLTIP_1_DESCRIPTION } =
      kitResult.test?.content ?? {};

    const faqOneAvailable = isFaqOneAvailableForConsumers({
      FAQ_TOOLTIP_1_DESCRIPTION,
      FAQ_TOOLTIP_1_HEADER,
    });
    const shouldDisplayCallout = !showFsPersonalization && faqOneAvailable;

    return (
      <Col10>
        {shouldDisplayCallout && (
          <Callout
            title={FAQ_TOOLTIP_1_HEADER}
            description={FAQ_TOOLTIP_1_DESCRIPTION}
            analyticsData={{
              label: ANALYTICS.LABELS.FAQ_TOOLTIP_1_TOP,
              category: ANALYTICS.CATEGORIES.FAQ_TOOLTIPS,
            }}
            productName={kitResult?.test?.name}
          />
        )}
        <SegmentedControl
          controlList={controlList}
          onChange={this.handleGroupChange}
        />
        {this.renderFoodSensitivityResults(this.state.groupBy)}
      </Col10>
    );
  }
}

export default FoodSensitivityResultDetail;
