import { size } from '@everlywell/leaves';
import styled from 'styled-components';

export const SVG = styled.svg`
  color: transparent;
  display: block;
  width: 24px;
  margin-left: ${size.xs1}px;
  align-self: flex-start;
`;
