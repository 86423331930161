import { Provider } from 'common/apis/telehealthSchedulingApis';
import { random } from 'lodash';

const baseProvider: Provider = {
  id: '1',
  name: 'Dr. John Doe',
  credentials: ['MD'],
  has_future_availabilities: true,
};

export const buildProvider = (overrides?: Partial<Provider>): Provider => ({
  ...baseProvider,
  id: random(0, 1000).toString(),
  ...overrides,
});
