/**
 *
 * Skeleton loader view for test results list
 *
 */

import React from 'react';

import SkeletonTestResultsList from './SkeletonTestResultsList';

type SkeletonTestResultsProps = {
  ariaLabel?: string;
};
function SkeletonTestResults(props: SkeletonTestResultsProps) {
  return (
    <div aria-label={props.ariaLabel ?? 'Loading Tests Results'}>
      <SkeletonTestResultsList />
      <SkeletonTestResultsList />
    </div>
  );
}

export default SkeletonTestResults;
