import { getRequest } from 'common/hooks/useApi/request';
import { API_ROOT } from 'common/utils/constants';
import { KitResult } from 'common/utils/types';

export const getKitResults = async (page: number | string = 1) =>
  getRequest<KitResult[]>(
    `${API_ROOT}/v2/results_dashboard?page=${page}`,
    false,
    { rejectOnError: true },
  );

export const getAccountHubKitResults = async () =>
  getRequest<KitResult[]>(`${API_ROOT}/v2/account_hub`, false, {
    rejectOnError: true,
  });
