import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';

export const whenDashboardPageViewed = () =>
  analytics.track({
    event: ANALYTICS.EVENTS.VIEWED_PAGE,
    data: {
      page: ANALYTICS.PAGES.ACCOUNT_HUB.DASHBOARD,
    },
  });

export const whenSettingsLinkClicked = () =>
  analytics.track({
    event: ANALYTICS.EVENTS.CLICKED_BUTTON,
    data: {
      label: ANALYTICS.LABELS.ACCOUNT_HUB.SETTINGS_CLICK,
      category: ANALYTICS.CATEGORIES.DASHBOARD,
    },
  });

export const whenPurchaseConfirmationModalIsDisplayed = () => {
  analytics.track({
    event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
    data: {
      label: ANALYTICS.LABELS.ACCOUNT_HUB.VIEW_ORDER_CONFIRMATION_MODAL,
      category: ANALYTICS.CATEGORIES.DASHBOARD,
    },
  });
};
