import { ENTERPRISE_MIXPANEL_API_KEY } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import Cookies from 'js-cookie';
import mixpanel, { OverridedMixpanel, Dict } from 'mixpanel-browser';
import React from 'react';

import * as S from './styles';

interface MixpanelLibrary extends OverridedMixpanel {
  enterprise: {
    track: (event_name: string, properties?: Dict | undefined) => void;
  };
}

if (typeof window !== 'undefined' && ENTERPRISE_MIXPANEL_API_KEY) {
  mixpanel.init(ENTERPRISE_MIXPANEL_API_KEY, {}, 'enterprise');
}

function HealthPlanFooter(): JSX.Element {
  const trackSampleInstructionsURL = () => {
    const enterpriseMixpanel = (mixpanel as MixpanelLibrary).enterprise;

    if (!enterpriseMixpanel) return;

    enterpriseMixpanel.track(ANALYTICS.EVENTS.CLICKED_LINK, {
      label: ANALYTICS.LABELS.SAMPLE_COLLECTION_VIDEOS,
      page: ANALYTICS.PAGES.DASHBOARD,
      path: document.location.pathname,
      enterprise_slug: Cookies.get('thirdparty'),
    });
  };

  return (
    <S.Footer>
      <S.Container>
        <S.NavLinks>
          <a href={'https://www.everlywell.com/terms-of-use/'}>Terms of Use</a>
          <a href={'https://www.everlywell.com/privacy-policy'}>
            Privacy Policy
          </a>

          <a href={'https://www.everlywell.com/terms-of-use/'}>
            User Agreement
          </a>

          <a href={'https://www.everlywell.com/members/consentandnotice'}>
            Informed Consent and Notice of Privacy Practices
          </a>
        </S.NavLinks>

        <S.ContactAvailability>
          <S.ContactText>
            Need Help? Contact us at <a href="tel:855-923-2678">855-923-2678</a>
            .
          </S.ContactText>
          <S.ContactText>
            We are available Monday through Friday between 8 AM-8 PM EST.
          </S.ContactText>
        </S.ContactAvailability>

        <S.SampleInstructions>
          Need help collecting your test sample?{' '}
          <a
            href="https://memberfaqs.everlywell.com/category/1292-sample-collection"
            id="sampleCollectionVideosURL"
            target="_blank"
            rel="noreferrer"
            onClick={trackSampleInstructionsURL}
          >
            Check out our sample collection videos.
          </a>
        </S.SampleInstructions>

        <S.Copyright>
          © {new Date().getFullYear()} Everlywell, Inc. All rights reserved.
        </S.Copyright>
      </S.Container>
    </S.Footer>
  );
}

export default HealthPlanFooter;
