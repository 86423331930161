import useUser from '../useUser';

const useProgramType = (program: string) => {
  const { user } = useUser();

  const programType = user?.enrolled_programs.find(
    (enrolledProgram) => enrolledProgram.slug === program,
  )?.program_type;

  if (!programType) return '';

  return programType;
};

export default useProgramType;
