/* eslint-disable @typescript-eslint/no-var-requires */
/**
 *
 * SaveSubHeaderButton
 *
 */
import analytics from 'common/utils/analytics';
import { RESULTS_DISCUSSION } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { jumpToSection } from 'common/utils/helpers';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import React from 'react';

import * as S from './FollowUpSubHeaderButton.styles';

type Props = {
  testId?: number;
  testName?: string;
};

function FollowUpSubHeaderButton(props: Props) {
  const { testId, testName } = props;
  const analyticsAttributes = {
    'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
    'data-analytics-category': ANALYTICS.CATEGORIES.TELEHEALTH,
    'data-analytics-label': ANALYTICS.LABELS.TELEHEALTH,
    'data-analytics-test-id': testId,
  };
  const followUpButtonEl = useScrolledIntoViewOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: ANALYTICS.LABELS.TELEHEALTH,
        testId,
        testName,
        category: ANALYTICS.CATEGORIES.TELEHEALTH,
      },
    });
  });
  return (
    <S.Wrapper>
      <S.FollowUpButton
        data-test={ANALYTICS.LABELS.TELEHEALTH}
        ref={followUpButtonEl}
        onClick={() => jumpToSection(true, RESULTS_DISCUSSION, true)}
        appearance="tertiary"
        {...analyticsAttributes}
      >
        {ANALYTICS.LABELS.TELEHEALTH}
      </S.FollowUpButton>
    </S.Wrapper>
  );
}

export default FollowUpSubHeaderButton;
