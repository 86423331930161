import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { sanitize } from 'common/utils/domHelpers';
import {
  KitRegistrationResponse,
  PreRegistrationDisclaimerType,
} from 'common/utils/types';
import React, { FC } from 'react';

import * as S from './styles';

type Props = PreRegistrationDisclaimerType & {
  kitRegistration?: KitRegistrationResponse;
};

function parseDisclaimerBody(
  body: string,
  kitRegistration: KitRegistrationResponse,
) {
  const {
    barcode: { dash_format: kitId },
    user: { first_name, last_name },
  } = kitRegistration;

  return body.replace(/kitId|fullName/gi, function (match) {
    switch (match) {
      case 'kitId':
        return kitId;
      case 'fullName':
        return `${first_name} ${last_name}`;
      default:
        return '';
    }
  });
}

const PreRegistrationDisclaimer: FC<Props> = (props: Props) => {
  const { callToAction, body, title, kitRegistration } = props;

  const { text, url } = callToAction;

  const label = text || 'Close';

  const handleTrackEvent = async () => {
    await analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        ctaLabel: label,
        ctaURL: url,
        disclaimerTitle: title,
        label: ANALYTICS.LABELS.PRE_REGISTER_DISCLAIMER_CTA,
      },
    });
  };

  const parsedBody = kitRegistration
    ? parseDisclaimerBody(sanitize(body), kitRegistration)
    : body;

  return (
    <S.Container>
      <S.Title dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
      <S.Body dangerouslySetInnerHTML={{ __html: parsedBody }} />
      <S.Footer>
        <S.Link onClick={handleTrackEvent} href={url}>
          {label}
        </S.Link>
      </S.Footer>
    </S.Container>
  );
};

export default PreRegistrationDisclaimer;
