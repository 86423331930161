import { typography, colors, mediaQueries } from '@everlywell/leaves';
import { Col_12_Span_10 } from 'common/styles/grid-styles';
import styled from 'styled-components';

const Wrapper = styled(Col_12_Span_10)`
  background-color: ${(props: { color?: string }) =>
    props.color ? props.color : colors.gray1};
  position: relative;
  z-index: 1;
  justify-content: center;
`;

const GridWrapper = styled.div`
  padding: 24px 0;
`;

const InnerContentWrapper = styled.div`
  ${mediaQueries.forTabletHorizontalUp} {
    display: flex;
    align-items: center;
  }
`;

const CobrandedIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;

  img {
    width: 201px;
    height: 60px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    padding-right: 32px;
    padding-bottom: 0;
    justify-content: flex-end;

    img {
      height: 76px;
      width: 201px;
    }
  }

  ${mediaQueries.forDesktopUp} {
    justify-content: flex-end;
  }
`;

const ContentWrapper = styled.div`
  font-size: 16px;
  line-height: 1.75;
  color: ${colors.gray4};
  font-weight: ${typography.weight.regular};
  text-align: center;

  ${mediaQueries.forTabletHorizontalUp} {
    justify-content: flex-start;
    text-align: left;
  }
`;

export {
  Wrapper,
  GridWrapper,
  InnerContentWrapper,
  CobrandedIcon,
  ContentWrapper,
};
