import { mediaQueries } from '@everlywell/leaves';
import { Col_10 } from 'common/styles/grid-styles';
import styled from 'styled-components';

const CardsSectionHeaderContainer = styled(Col_10)`
  padding-bottom: 32px;

  ${mediaQueries.forTabletHorizontalUp} {
    padding-bottom: 48px;
  }
`;

export { CardsSectionHeaderContainer };
