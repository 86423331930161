/**
 * KitList
 */

import { Localize } from '@everlywell/leaves';
import { STATUSES } from 'common/utils/constants';
import { KitResult } from 'common/utils/types';
import { flatten } from 'lodash-es';
import pluralize from 'pluralize';
import React from 'react';

import KitCard from './KitCard';
import * as S from './KitList.styles';

export type KitListProps = {
  heading: string;
  kitResults: KitResult[];
  testLabel?: string;
};

function KitList(props: KitListProps) {
  const { kitResults, heading, testLabel } = props;
  const numberOfKitResults = kitResults.length;

  if (kitResults.length === 0) {
    return null;
  }

  return (
    <div data-test={testLabel}>
      <S.Header>
        <S.Heading data-test={heading.replace(' ', '-').toLowerCase()}>
          {heading} {pluralize(`TEST`, numberOfKitResults)}
        </S.Heading>
        <S.Number data-test="test-count">
          <Localize name="number-of-kit-results" pluralize={numberOfKitResults}>
            {numberOfKitResults}
          </Localize>{' '}
          {pluralize(`TEST`, numberOfKitResults)}
        </S.Number>
      </S.Header>
      <S.KitList>
        {kitResults.map((kitResult: KitResult, index: number) => (
          <KitCard
            testLabel={testLabel}
            index={index}
            key={kitResult.number}
            kitResult={kitResult}
          />
        ))}
      </S.KitList>
    </div>
  );
}

export default KitList;

/**
 * Group and sort kits by results_approved_at or kit_registered_at depending of the status
 */

export const sortKitsResults = (kitResults: KitResult[]): KitResult[] => {
  if (kitResults.length === 0) return [];

  const groupedKitResults = kitResults.reduce<Record<string, KitResult[]>>(
    (acc, kr) => {
      if (!kr.product_name) return acc;
      if (!acc[String(kr.product_name)]) acc[String(kr.product_name)] = [];
      acc[String(kr.product_name)].push(kr);
      return acc;
    },
    {},
  );

  const selector =
    kitResults[0].status === STATUSES.RESULTS_APPROVED
      ? 'results_approved_at'
      : 'kit_registered_at';
  const sortedGroupKeys = Object.keys(groupedKitResults).sort(
    (a, b) =>
      +new Date(groupedKitResults[b][0][selector] as string) -
      +new Date(groupedKitResults[a][0][selector] as string),
  );

  return flatten(sortedGroupKeys.map((key) => groupedKitResults[key]));
};
