import {
  typography,
  size,
  Col,
  colors,
  mediaQueries,
} from '@everlywell/leaves';
import Button from 'components/Button';
import { BaseInput } from 'components/KitRegistration/styles';
import styled from 'styled-components';

const StyledInput = styled(BaseInput)`
  height: ${size.xl6}px;
  margin-bottom: ${size.xs1}px;
`;

const Title = styled.h4`
  font-weight: ${typography.weight.regular};
  font-size: 20.3px;
  line-height: 1.58;
  margin: 0 0 24px;
  color: ${colors.teal6};

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 16px;
  }
`;

const Spinner = styled.div`
  animation: opacity 0.8s linear infinite;
  border-color: transparent;
  border-radius: 100%;
  border-style: solid;
  border-top-color: ${colors.gray1};
  border-width: 4px;
  box-sizing: border-box;
  height: 32px;
  width: 32px;

  @keyframes opacity {
    0% {
      opacity: 1;
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
      opacity: 0.4;
    }
    100% {
      opacity: 1;
      transform: rotate(360deg);
    }
  }
`;

const StateCol = styled(Col)`
  margin-top: -${size.xs2}px;
  margin-bottom: ${size.xl1}px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const Label = styled.p`
  ${typography.bodyTextSmall}
  font-weight: ${typography.weight.bold};
  padding-right: ${size.xs1}px;
`;

const Submit = styled(Button)`
  width: 100%;
  border-radius: 0;
  background-color: ${colors.green5};
  margin-top: ${size.lg}px;
`;

export { Title, Submit, LabelContainer, Label, StateCol, StyledInput, Spinner };
