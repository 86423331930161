import { Button, colors, size } from '@everlywell/leaves';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const FollowUpButton = styled(Button as any)`
  color: ${colors.blue1};
  border-color: ${colors.blue1};
  margin-left: ${size.md}px;
  white-space: nowrap;
  &:hover,
  &:focus {
    color: ${colors.green3};
    background-color: rgba(184, 221, 225, 0.2);
    border-color: ${colors.blue1};
  }
`;
