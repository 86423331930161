import { colors, typography, size, H1, mediaQueries } from '@everlywell/leaves';
import { pageTitle } from 'common/styles/app-styles';
import styled from 'styled-components';

const Wrapper = styled(H1)`
  color: ${colors.teal6};
  font-size: ${size.xl2}px;
  font-weight: ${typography.weight.bold};
  ${pageTitle};
  margin-top: 39px;
  margin-bottom: ${size.xs1}px;
  ${mediaQueries.forTabletVerticalDown} {
    font-size: ${size.xl1}px;
  }
`;

export { Wrapper };
