import { typography, colors, size, mediaQueries } from '@everlywell/leaves';
import { bodyCopyLarge, sectionTitle } from 'common/styles/app-styles';
import { Col_12_Span_10 } from 'common/styles/grid-styles';
import styled from 'styled-components';

type ListItemProps = {
  isCovid?: boolean;
};

const ListWithIconsWrapper = styled(Col_12_Span_10)`
  padding-top: 24px;
  padding-bottom: 0;

  ${mediaQueries.forTabletHorizontalUp} {
    padding-top: 40px;
    padding-bottom: 0;
  }
  ${mediaQueries.forBigDesktopUp} {
    background-size: 100%;
  }
`;

const Inner = styled.div`
  overflow: auto;
  margin: 0 auto;
  max-width: 923px;
`;

const Header = styled.h3`
  ${sectionTitle};
  font-weight: ${typography.weight.bold};
  line-height: 32px;
  letter-spacing: 0;
  margin: 0 0 32px;
  text-align: center;
  color: ${colors.teal6};

  ${mediaQueries.forTabletVerticalUp} {
    margin: 0 0 28px;
  }
  ${mediaQueries.forTabletHorizontalUp} {
    font-size: 25.8px;
  }
`;

const List = styled.ul`
  padding: 0;
  list-style: none;
  margin-bottom: 8px;
`;

const ListItem = styled.li<ListItemProps>`
  display: flex;
  align-items: ${(props) => (props.isCovid ? 'center' : 'flex-start')};
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 32px;

  ${mediaQueries.forTabletVerticalUp} {
    flex-wrap: nowrap;
    margin-bottom: 33px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    align-items: ${(props) => (props.isCovid ? 'center' : 'flex-start')};
    margin-bottom: 40px;
  }
`;

const IconWrapper = styled.div<ListItemProps>`
  width: 100px;
  max-width: ${(props) => (props.isCovid ? '56px' : '40px')};
  text-align: center;
  margin-bottom: 16px;

  ${mediaQueries.forTabletVerticalUp} {
    margin-right: 16px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 0;
    margin-top: ${(props) => (props.isCovid ? '0' : '12px')};
  }
`;

const Icon = styled.img`
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    margin-right: 16px;
  }
`;

const Text = styled.p`
  ${typography.bodyText}
  letter-spacing: 0;
  margin: ${size.md}px 0;
  text-align: center;
  flex: 0 1 100%;

  ${mediaQueries.forTabletVerticalUp} {
    text-align: left;
    line-height: 28px;
    padding: 0;
    margin: 0 ${size.md}px;
  }
  ${mediaQueries.forTabletHorizontalUp} {
    ${bodyCopyLarge};
  }
`;

export {
  ListWithIconsWrapper,
  Inner,
  Header,
  List,
  ListItem,
  IconWrapper,
  Icon,
  Text,
};
