import { size, colors } from '@everlywell/leaves';
import styled from 'styled-components';

const SIDE_NAV_WIDTH = 260;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${colors.green1};
`;

export const NavContainer = styled.nav`
  position: sticky;

  /* header height */
  top: 58px;
  width: ${SIDE_NAV_WIDTH}px;
  display: flex;
  flex-direction: column;
  padding: ${size.xl2}px ${size.xl3}px;
`;
