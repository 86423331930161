import { mediaQueries, size } from '@everlywell/leaves';
import KitCard from 'components/KitList/KitCard';
import styled from 'styled-components';

export const Heading = styled.h2`
  padding: ${size.xs1}px 0;
  margin: 0;

  ${mediaQueries.forTabletVerticalUp} {
    padding-top: 0;
    padding-bottom: ${size.md};
  }
`;

export const KitResultCard = styled(KitCard)`
  margin-bottom: 0;
`;
