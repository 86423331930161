import { typography, mediaQueries, colors } from '@everlywell/leaves';
import styled from 'styled-components';

const SelectGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    & > div {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const Heading = styled.legend`
  font-weight: ${typography.weight.light};
  font-size: 18px;
  line-height: 32px;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${colors.gray4};
  margin: 0 0 16px;
`;

export { SelectGroup, Heading };
