import { getAllergies } from 'common/apis/telehealthApis';
import { useState } from 'react';
import { useQuery } from 'react-query';

const useAllergies = () => {
  const [name, setName] = useState('');
  const {
    data: response,
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery(['telehealth-allergies', name], () => getAllergies({ name }), {
    enabled: name.length >= 2,
    refetchOnWindowFocus: false,
  });

  return {
    allergies: response?.data?.allergies ?? [],
    search: setName,
    isLoading: isLoading || isFetching,
    isError,
    error,
  };
};

export default useAllergies;
