/**
 *
 * Individual skeleton test result card
 *
 */

import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';

function SkeletonTestResultCard() {
  return (
    <S.TestResultCard>
      <S.TestResultDetails>
        <S.BoxImage>
          <Skeleton height="80px" />
        </S.BoxImage>
        <S.TestResultDescription>
          <S.Title>
            <Skeleton height="24px" />
          </S.Title>
          <S.Spacer />
          <S.Date>
            <Skeleton height="19px" />
          </S.Date>
        </S.TestResultDescription>
      </S.TestResultDetails>
      <S.TestResultStatus>
        <S.Status>
          <Skeleton height="26px" />
        </S.Status>
        <S.Divider />
        <S.Button>
          <Skeleton height="48px" />
        </S.Button>
      </S.TestResultStatus>
    </S.TestResultCard>
  );
}

export default SkeletonTestResultCard;
