import ShippingSettings from 'pages/OldSettingsPage/ShippingSettings';
import React, { useContext } from 'react';

import { IntakeContext } from '../context';
import CONTENT from './content';
import * as S from './Shipping.styles';
import { VariantContentType } from './types';

interface Props {
  variant?: string;
}

const Shipping = ({ variant }: Props) => {
  const { setIntakeStep } = useContext(IntakeContext);
  const onSubmitCallback = () => {
    setIntakeStep && setIntakeStep(2);
  };

  const selectedVariant = variant || 'conception-plan'; // TODO remove this after we receive the variant id and match it with the content.ts
  const content: VariantContentType =
    CONTENT[selectedVariant as keyof typeof CONTENT] || CONTENT.default;

  return (
    <>
      <S.ProductImageContainer>
        {content.image && (
          <S.ProductImage src={content.image.src} alt={content.image.alt} />
        )}
      </S.ProductImageContainer>

      <ShippingSettings
        onSubmitCallback={onSubmitCallback}
        content={content}
        verticalLayout
        intakeFlow
      />

      {content.disclaimer && <S.Disclaimer>{content.disclaimer}</S.Disclaimer>}
    </>
  );
};

export default Shipping;
