import { Row, Col } from '@everlywell/leaves';
import { COLLECTION_HELP_EMAIL } from 'common/utils/constants';
import { REPLACEMENT as dataTest } from 'common/utils/constants/dataTest';
import { EVERLYWELL_SUPPORT_ROOT } from 'common/utils/constants/urls';
import { ShippingAddress } from 'common/utils/types';
import { FormValuesType } from 'components/Forms';
import get from 'lodash/get';
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { HeaderParagraphSkeleton } from './HeaderParagraphSkeleton';
import MissingReplacementVariantModal from './MissingReplacementVariantModal';
import ShippingAddressForm from './ShippingAddressForm';
import { ShippingAddressFormSkeleton } from './ShippingAddressFormSkeleton';
import * as S from './styles';

type ReplacementFormProps = {
  replacement: {
    testNames?: string[];
    hasReplacement?: boolean;
  };
  shippingAddress?: ShippingAddress;
  isSaving: boolean;
  onSubmit: (formValues: FormValuesType) => void;
  errors?: { [key: string]: string };
};

const ReplacementForm = (props: ReplacementFormProps) => {
  const { replacement, errors, onSubmit, shippingAddress, isSaving } = props;

  const { hasReplacement, testNames } = replacement;

  const [isMissingReplacementModalOpen, setIsMissingReplacementModalOpen] =
    useState(get(errors, 'error') === 'Missing replacement variant');

  useEffect(() => {
    setIsMissingReplacementModalOpen(
      get(errors, 'error') === 'Missing replacement variant',
    );
  }, [errors]);

  if (hasReplacement === false) {
    return <Navigate to="/replacement/confirmation" replace />;
  }

  const isInvalidProductForState =
    get(props.replacement, 'reasons', [])[0] === 'Invalid Product For State';

  const title = () => 'Sorry, there was an issue processing your kit.';

  const userState = get(shippingAddress, 'state');

  const body = () => {
    if (isInvalidProductForState && userState) {
      return `
        Looks like you have a kit that cannot be processed in the state of ${userState}.
        Please confirm your address below, and we'll send you a replacement kit ASAP.
        Sorry for the inconvenience.
        `;
    }

    return 'We are more than happy to send you a replacement kit at no additional cost... just confirm your shipping address below!';
  };

  const missingReplacementVariantModalBody = () => `
    Unfortunately we were not able to process your replacement order request. We truly apologize for the inconvenience as we know how important these results are to you.

    We are dedicated to helping you get your results and care about your health.

    Please email our Customer Experience team so they can assist you with next steps and to answer any questions.
  `;

  const helpInfo = () => {
    if (isInvalidProductForState) {
      return '';
    }

    return (
      <S.SubSubtitle>
        If you need help, contact{' '}
        <S.Link
          to={`${EVERLYWELL_SUPPORT_ROOT}/article/45-how-do-i-submit-a-customer-support-request`}
          data-test={dataTest.CONTACT_US_LINK}
          target="_blank"
        >
          Customer Experience
        </S.Link>{' '}
        and we would be happy to walk you through recollecting your sample.
      </S.SubSubtitle>
    );
  };

  const updatedTestInfo = () => {
    if (isInvalidProductForState) {
      return '';
    }

    return (
      <S.SubSubtitle>
        Some Everlywell tests are now being processed by a recent addition to
        our network of CLIA-certified and CAP-accredited laboratories. You can
        find the updated{' '}
        <S.Link
          to={`${EVERLYWELL_SUPPORT_ROOT}/article/507-sample-collection-videos`}
          data-test={dataTest.SAMPLE_REQUIREMENTS_LINK}
          target="_blank"
        >
          sample requirements
        </S.Link>{' '}
        and{' '}
        <S.Link
          to={`${EVERLYWELL_SUPPORT_ROOT}/article/52-what-biomarkers-are-included-in-each-panel`}
          data-test={dataTest.BIOMARKERS_LINK}
          target="_blank"
        >
          biomarkers
        </S.Link>{' '}
        on our support site.
      </S.SubSubtitle>
    );
  };

  return (
    <Row start="xs" middle="xs">
      {!hasReplacement || !testNames ? (
        <Col>
          <S.TitleWrapper>
            <S.Title>{title()}</S.Title>
          </S.TitleWrapper>
          <HeaderParagraphSkeleton />
          <ShippingAddressFormSkeleton />
        </Col>
      ) : (
        <>
          <Col>
            <S.TitleWrapper>
              <S.Title>{title()}</S.Title>
            </S.TitleWrapper>
            <S.SubtitleWrapper>
              <S.Subtitle data-test={dataTest.WELCOME_TEXT}>
                {body()}
                {helpInfo()}
                {updatedTestInfo()}
              </S.Subtitle>
            </S.SubtitleWrapper>
          </Col>
          <Col>
            <ShippingAddressForm
              errors={errors}
              onSubmit={onSubmit}
              shippingAddress={shippingAddress}
              isSaving={isSaving}
            />
          </Col>
          {isMissingReplacementModalOpen && (
            <MissingReplacementVariantModal
              openModal={isMissingReplacementModalOpen}
              setOpenModal={() => setIsMissingReplacementModalOpen(false)}
              title="There seems to be an issue."
              message={missingReplacementVariantModalBody()}
              cta={
                <S.ModalCta
                  onClick={() =>
                    window.open(`mailto:${COLLECTION_HELP_EMAIL}`, '_blank')
                  }
                >
                  Email Us
                </S.ModalCta>
              }
            />
          )}
        </>
      )}
    </Row>
  );
};

export default ReplacementForm;
