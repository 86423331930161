import {
  size,
  mediaQueries,
  typography,
  colors,
  InformationV2,
  Button,
  H5,
} from '@everlywell/leaves';
import styled, { css } from 'styled-components';

import StethoscopeIcon from './assets/stock_doctor_image.jpg';

export const Container = styled.div({
  display: 'flex',
  minWidth: '100%',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `${size.md}px 0 0 ${size.md}px`,
  boxShadow: `rgb(220 228 229 / 50%) 0px 7px 30px -10px`,
  borderRadius: '8px',

  [mediaQueries.forTabletVerticalUp]: {
    justifyContent: 'start',
    padding: `${size.lg}px 0 0 ${size.lg}px`,
  },

  [mediaQueries.forDesktopUp]: {
    padding: `${size.xl1}px 0 0 ${size.xl1}px`,
  },
});

export const ImageContainer = styled.div<{
  // this allows for the image to expand to the full width of the container
  // bleeding into the padding and margins
  bleedEdges?: boolean;
}>((props) => ({
  overflow: 'hidden',
  display: 'flex',
  width: 'auto',
  justifyContent: 'center',
  padding: `0 ${size.md}px ${size.lg}px 0`,
  ...(props.bleedEdges && {
    margin: `-${size.md}px  -${size.md}px 0  -${size.md}px`,
  }),

  [mediaQueries.forTabletVerticalUp]: {
    width: `20%`,
    padding: `0 ${size.lg}px ${size.lg}px 0`,
    ...(props.bleedEdges && {
      margin: `-${size.lg}px 0 -${size.lg}px -${size.lg}px`,
    }),
  },

  [mediaQueries.forDesktopUp]: {
    width: `auto`,
    padding: `0 ${size.xl1}px ${size.xl1}px 0`,
    ...(props.bleedEdges && {
      margin: `-${size.xl1}px 0 -${size.xl1}px -${size.xl1}px`,
    }),
  },
}));

export const backgroundImage = css({
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  backgroundImage: `url(${StethoscopeIcon})`,
  width: '100vw',
  aspectRatio: '2',
  backgroundPosition: 'center bottom',
  backgroundSize: 'cover',

  [mediaQueries.forTabletVerticalUp]: {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    borderTopRightRadius: '0px',
    width: '100%',
    height: '160px',
    aspectRatio: 'unset',
  },

  [mediaQueries.forDesktopUp]: {
    width: '200px',
    height: 'unset',
    aspectRatio: '1',
  },
});

export const ContentContainer = styled.div({
  color: colors.gray4,
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: `0 ${size.md}px ${size.lg}px 0`,

  [mediaQueries.forTabletVerticalUp]: {
    width: `auto`,
    flex: 1,
    justifyContent: 'start',
    alignItems: 'start',
    textAlign: 'left',
    padding: `0 ${size.lg}px ${size.lg}px 0`,
  },

  [mediaQueries.forDesktopUp]: {
    width: `auto`,
    flex: 1,
    padding: `0 ${size.xl1}px ${size.xl1}px 0`,
  },
});

export const appointmentTitle = css`
  ${typography.h5Text};
  margin: 0;
  margin-bottom: ${size.sm}px;
`;

export const CTAContainer = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: `0 ${size.md}px ${size.md}px 0`,

  [mediaQueries.forTabletVerticalUp]: {
    width: `37.5%`,
    padding: `0 ${size.lg}px ${size.lg}px 0`,
  },

  [mediaQueries.forDesktopUp]: {
    width: `auto`,
    minWidth: '280px',
    padding: `0 ${size.xl1}px ${size.xl1}px 0`,
  },
});

const sharedButtonStyles = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  alignItems: 'center',
};

export const buttonOneStyles = css(sharedButtonStyles);
export const buttonTwoStyles = css({
  ...sharedButtonStyles,
  marginTop: `${size.md}px`,
});

export const InformationIcon = styled(InformationV2)`
  fill: none;
  margin-left: ${size.sm}px;
`;

export const DescriptionText = styled.div`
  ${typography.bodyTextSmall};

  a {
    color: #006298;
    text-decoration: none;
  }
`;

export const NextVisitCardContainer = styled.div`
  height: auto;
  width: 100%;
  padding: ${size.md}px ${size.lg}px;
  box-shadow: 0 7px 30px -10px rgba(220, 228, 229);
  border-radius: ${size.xs1}px;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${mediaQueries.forDesktopUp} {
    flex-direction: row;
    align-items: stretch;
    text-align: left;
    padding: ${size.xl1}px;
  }
`;

export const NextVisitCardHeader = styled(H5)`
  margin: ${size.md}px 0;

  ${mediaQueries.forDesktopUp} {
    margin: 0 0 ${size.xs1}px 0;
  }
`;

export const ScheduleNowButton = styled(Button)`
  padding: ${size.sm}px ${size.lg}px;
  gap: 10px;
  width: 100%;
  margin: ${size.lg}px 0 0;

  ${mediaQueries.forDesktopUp} {
    width: 210px;
    margin: ${size.lg}px 0 ${size.xl1}px;
  }
`;

export const IconContainer = styled.div`
  width: 72px;
  height: 72px;
`;

export const IconImg = styled.img`
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 0;
  width: 100%;

  ${mediaQueries.forDesktopUp} {
    margin-left: ${size.lg}px;
    align-items: stretch;
  }
`;
export const TextWrapper = styled.div`
  ${mediaQueries.forDesktopUp} {
    max-width: 664px;
  }
`;
