import useApi from 'common/hooks/useApi';
import { isActiveMember } from 'common/utils/helpers';
import {
  ImmutableReduxState,
  Notification as NotificationType,
  PhysicianContact,
  User,
} from 'common/utils/types';
import SettingsPage from 'pages/OldSettingsPage';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { setNotification } from 'store/actions';
import { setPhysicianContact } from 'store/physicianContact/actions';
import { makeSelectUserId } from 'store/selectors';
import { setUserData } from 'store/user/actions';

type StateProps = {
  userId: string;
};

type DispatchProps = {
  setUserData: (data: User) => void;
  setPhysicianContact: (data: PhysicianContact) => void;
  setNotification: (notification: NotificationType) => void;
};

type Props = StateProps & DispatchProps;

/**
 * @deprecated - This component is deprecated and will be removed in the future due
 * to the Account Hub work.
 */
function SettingsPageContainer(props: Props) {
  const { statusCode, wasSuccessful, fetchUserData } = useApi();
  const { userId, setPhysicianContact, setUserData } = props;
  const [hasMembership, setHasMembership] = useState(false);

  useEffectOnce(() => {
    fetchUserData(userId).then((data) => {
      const { physician, membership } = data;
      if (physician) {
        delete physician.id;
        setPhysicianContact(physician);
      }
      setHasMembership(!!(membership && isActiveMember(membership.state)));

      setUserData({ ...data, full_user_data: true });
    });
  });

  return (
    <SettingsPage
      wasSuccessful={wasSuccessful}
      statusCode={statusCode}
      hasMembership={hasMembership}
    />
  );
}

/**
 * @deprecated - This component is deprecated and will be removed in the future due
 * to the Account Hub work.
 */
export default connect(
  (state: ImmutableReduxState): StateProps => ({
    userId: makeSelectUserId()(state),
  }),
  {
    setUserData,
    setPhysicianContact,
    setNotification,
  },
)(SettingsPageContainer);
