/* istanbul ignore file */
import { QuestionProps } from 'common/utils/types';
import React from 'react';

import {
  HealthProfileSingleQuestion,
  HealthProfileHeightQuestion,
  HealthProfileWeightQuestion,
  HealthProfileMultiAnswerWithFreeTextQuestion,
} from '..';

const QuestionHandler = (props: QuestionProps) => {
  const { type } = props.questionData;

  let questionComponent = null;

  switch (type) {
    case 'single_select':
      questionComponent = <HealthProfileSingleQuestion {...props} />;
      break;
    case 'free_response_height':
      questionComponent = <HealthProfileHeightQuestion {...props} />;
      break;
    case 'free_response_weight':
      questionComponent = <HealthProfileWeightQuestion {...props} />;
      break;
    case 'multi_select_free_response_text':
    case 'multi_select':
      questionComponent = (
        <HealthProfileMultiAnswerWithFreeTextQuestion {...props} />
      );
      break;
    default:
      questionComponent = (
        <h2>{`${type} not recognizable as Question Type`}</h2>
      );
  }
  return questionComponent;
};

export default QuestionHandler;
