import React from 'react';

import SocialLink from './SocialLink';
import * as S from './SocialLinks.styles';

type Props = {
  shareLink: string;
};

function SocialLinks(props: Props) {
  const { shareLink } = props;

  const links = [
    {
      href: `https://www.facebook.com/sharer/sharer.php?u=${shareLink}`,
      title: 'Facebook',
      color: '#3b5998',
      icon: `<svg width="29" height="28" viewBox="0 0 29 28">
          <path
            fill="#919193"
            fillRule="nonzero"
            d="M28.822 3.053v22c0 .833-.291 1.541-.875 2.125a2.893 2.893 0 0 1-2.125.875H20.51V16.99h3.75l.562-4.25H20.51v-2.687c0-.667.125-1.167.375-1.5.333-.375.896-.563 1.687-.563h2.25V4.24c-.875-.125-1.958-.187-3.25-.187-1.666 0-2.99.49-3.969 1.468-.979.98-1.468 2.344-1.468 4.094v3.125h-3.813v4.25h3.813v11.063H3.822a2.893 2.893 0 0 1-2.125-.875 2.893 2.893 0 0 1-.875-2.125v-22c0-.834.292-1.542.875-2.125A2.893 2.893 0 0 1 3.822.053h22c.834 0 1.542.291 2.125.875.584.583.875 1.291.875 2.125z"
          />
        </svg>`,
    },
    {
      href: `http://twitter.com/share?text=Check%20out%20my%20EverlyWell%20Results%21&url=${shareLink}&hashtags=everlywell,health,wellness,lifebetterlived`,
      title: 'Twitter',
      color: '#1da1f2',
      icon: `
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="23"
          viewBox="0 0 28 23"
        >
          <path
            fill="#919193"
            fillRule="nonzero"
            d="M24.924 5.866c.036.146.054.383.054.711 0 2.625-.638 5.159-1.914 7.602-1.312 2.588-3.153 4.648-5.523 6.18-2.589 1.713-5.56 2.57-8.914 2.57-3.208 0-6.143-.857-8.805-2.57.401.036.857.054 1.367.054 2.662 0 5.05-.82 7.164-2.46-1.276 0-2.397-.375-3.363-1.122-.966-.747-1.631-1.686-1.996-2.816.365.036.711.054 1.04.054.51 0 1.02-.054 1.53-.164-1.312-.291-2.406-.966-3.28-2.023C1.407 10.825.97 9.622.97 8.272v-.109c.802.474 1.659.73 2.57.766a6.37 6.37 0 0 1-1.86-2.078 5.516 5.516 0 0 1-.71-2.762c0-1.003.273-1.96.82-2.871a15.834 15.834 0 0 0 5.223 4.265 15.896 15.896 0 0 0 6.59 1.75 7.97 7.97 0 0 1-.11-1.312c0-1.02.255-1.978.766-2.871A5.62 5.62 0 0 1 16.338.944 5.546 5.546 0 0 1 19.182.18c.838 0 1.613.164 2.324.492.71.328 1.34.766 1.887 1.312A11.683 11.683 0 0 0 27.057.616c-.438 1.35-1.276 2.407-2.516 3.172a12.335 12.335 0 0 0 3.281-.93 12.622 12.622 0 0 1-2.898 3.008z"
          />
        </svg>
      `,
    },
  ];

  return (
    <S.Wrapper>
      {links.map(({ title, ...rest }) => (
        <SocialLink key={title} {...rest}>
          {title}
        </SocialLink>
      ))}
    </S.Wrapper>
  );
}

export default SocialLinks;
