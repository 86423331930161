import {
  Icons,
  size,
  colors,
  typography,
  mediaQueries,
} from '@everlywell/leaves';
import styled, { css } from 'styled-components';

type WrapperProps = {
  isSelected: boolean;
  isExpanded: boolean;
  isAvailable: boolean;
};

/*
 Animating the height from a specific value to auto using CSS animations is not straightforward
 since auto is not an animatable value. However, we can achieve a similar effect by
 animating the maximum height of the div instead and setting the max height to a large value.
*/
const isExpandedStyles = css`
  height: auto;
  max-height: none;
`;

export const Wrapper = styled.div<WrapperProps>`
  border-radius: 8px;
  border: ${(props) =>
    props.isSelected
      ? `1px solid ${colors.green4}`
      : `1px solid ${colors.green2}`};
  box-shadow: 0 7px 30px -10px rgba(220, 228, 229, 0.5);
  height: auto;
  margin-bottom: ${size.sm}px;
  max-height: 104px;
  overflow: hidden;
  padding: ${size.md}px;
  position: relative;
  width: 100%;
  cursor: ${(props) => (props.isAvailable ? 'pointer' : 'default')};

  &:last-child {
    margin-bottom: 0;
  }

  ${mediaQueries.forDesktopUp} {
    cursor: pointer;
  }

  ${(props) => (props.isExpanded ? isExpandedStyles : null)};
`;

type ChevronProps = {
  isExpanded: boolean;
};

const chevronExpandedStyles = css`
  transform: rotate(180deg) translateX(6px);
`;

export const ChevronButton = styled.button<ChevronProps>`
  background: transparent;
  border: 0;
  border-radius: 50%;
  height: 72px;
  margin: 0;
  padding: 0;
  right: 16px;
  width: ${size.xl3}px;
  transform: rotate(0deg);
  transition: transform ease 0.5s;

  ${mediaQueries.forDesktopUp} {
    cursor: pointer;
  }

  ${(props) => props.isExpanded && chevronExpandedStyles};
`;

export const ChevronIcon = styled(Icons.DownSolidCaret)`
  display: inline-block;
  width: 17px;
`;

export const StaticArea = styled.div`
  align-items: center;
  display: flex;
  height: 72px;
  width: 100%;
`;

type RadioProps = {
  isSelected: boolean;
};

export const Radio = styled.div<RadioProps>`
  border-radius: 50%;
  border: 1px solid ${colors.green3};
  height: 20px;
  margin-right: ${size.md}px;
  position: relative;
  width: 20px;

  &:before {
    background-color: ${(props) =>
      props.isSelected ? colors.green4 : 'transparent'};
    border-radius: 50%;
    content: '';
    display: block;
    height: ${size.sm}px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: ${size.sm}px;
  }
`;
type ImageWrapperType = {
  isRoundImage: boolean;
};
export const ImageWrapper = styled.div<ImageWrapperType>`
  display: flex;
  align-items: center;
  position: relative;
  width: ${size.xl4}px;
  ${({ isRoundImage }) =>
    isRoundImage &&
    css`
      > img {
        height: 55px;
        width: 55px;
        border-radius: 50%;
      }
    `}
`;

export const ImageBackgroundWrapper = styled.div`
  padding-top: 100%;
  height: 0;
  position: absolute;
  width: 100%;
`;

export const ImageBackground = styled.div`
  background-color: ${colors.teal1};
  border-radius: 50%;
  height: 0;
  padding-top: 80%;
  width: 80%;
  z-index: 0;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  z-index: 1;
`;

export const Title = styled.h2`
  ${typography.buttonTextSmall};
  color: ${colors.green4};
  flex: 1;
  padding: 0 ${size.md}px 0 ${size.md}px;
`;

export const TopWrapper = styled.div`
  display: flex;
`;

export const Description = styled.p`
  ${typography.bodyTextSmall};
  margin: ${size.md}px 0;
`;

export const Section = styled.div`
  margin-bottom: ${size.md}px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SectionTitle = styled.h3`
  ${typography.buttonTextSmall};
  color: ${colors.gray4};
  margin: 0 0 ${size.xs1}px 0;
  text-transform: capitalize;
`;

type ExandableElementProps = {
  isOpen: boolean;
};

export const ListWrapper = styled.ul<ExandableElementProps>`
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: ${({ isOpen }) => (isOpen ? 'none' : '94px')};
  height: auto;
  overflow: hidden;
`;

export const SeeMoreButton = styled.button`
  ${typography.buttonTextSmall};
  background: none;
  border: 0;
  color: ${colors.green4};
  margin-top: ${size.xs1}px;
  padding: 0;
  transition: opacity ease 0.5s;

  ${mediaQueries.forDesktopUp} {
    cursor: pointer;
  }
`;

export const CheckIcon = styled(Icons.CheckMark)`
  width: 10px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  display: block;
`;

export const BulletPoint = styled.span`
  background-color: ${colors.gray4};
  border-radius: 50%;
  height: ${size.xs2}px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: ${size.xs2}px;
`;

export const ListElement = styled.li`
  ${typography.bodyTextSmall};
  display: block;
  margin: 0 0 ${size.xs1}px 0;
  padding: 0 0 0 18px;
  position: relative;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CollectionMethod = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  margin-bottom: ${size.xs1}px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CollectionIcon = styled.img`
  width: 30px;
  height: 30px;
  display: block;
`;

export const CollectionMethodLabel = styled.span<{ hasIcon: boolean }>`
  ${typography.buttonTextSmall};
  color: ${colors.gray4};
  margin-left: ${({ hasIcon }) => (hasIcon ? size.xs1 : '0')}px;
`;
