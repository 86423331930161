export const convertCardBrandToKey = (brand?: string) => {
  switch (brand?.toLowerCase()) {
    case 'visa':
      return 'visa';
    case 'mastercard':
    case 'master card':
      return 'masterCard';
    case 'discover':
      return 'discover';
    case 'amex':
    case 'americanexpress':
    case 'american express':
      return 'americanExpress';
    case 'paypal':
      return 'payPal';
    default:
      return 'visa';
  }
};
