import { colors, typography } from '@everlywell/leaves';
import {
  StripeElementStyle,
  CreateTokenCardData,
  StripeCardNumberElement,
  StripeElements,
  Token,
  Stripe,
} from '@stripe/stripe-js';

// Shared element styles
export const stripeElementStyles: StripeElementStyle = {
  base: {
    color: colors.gray4,
    fontFamily: typography.type.nexa,
    lineHeight: '1.78',
    fontSize: '18px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: colors.gray3,
      fontWeight: typography.weight.book,
    },
    ':disabled': {
      color: colors.gray3,
    },
  },
  invalid: {},
};

export const parseDate = async (dateString: string): Promise<expires> => {
  const [month, year] = dateString.split('/');

  const validateYear = (year: number): void => {
    // Ensure we're comparing decades, not millenia
    if (year < new Date().getFullYear() % 2000) {
      throw new Error('You must provide a valid two-digit year');
    }
  };

  const validateMonth = (month: number): void => {
    const inRange = month > 0 && month <= 12;
    if (!inRange) {
      throw new Error('You must provide a valid month');
    }
  };

  try {
    validateYear(+year);
    validateMonth(+month);

    return {
      exp_month: month.padStart(2, '0'),
      exp_year: year.slice(-2), // Make sure we're only sending back the last two
    };
  } catch (err) {
    return Promise.reject(err as Error);
  }
};

export const formatStripeDates = (exp_year: string, exp_month: string) => {
  const year = exp_year.slice(-2);
  const month = exp_month.padStart(2, '0');

  return formatDate(`${month}/${year}`);
};

export const formatDate = (input: string) => {
  const date = input.replace(/[^\d]/g, '');
  if (date.length >= 3) {
    return `${date.slice(0, 2)}/${date.slice(2, 4)}`;
  }
  return date;
};

interface expires {
  exp_month: string;
  exp_year: string;
}

export const fetchStripeToken = async (
  stripe: Stripe | null,
  cardElement: StripeCardNumberElement,
  data: CreateTokenCardData,
): Promise<Token> => {
  try {
    if (!stripe) {
      throw new Error('Stripe is not initialized');
    } else {
      const { token, error } = await stripe.createToken(cardElement, data);
      if (error) {
        // The error returned by Stripe call.
        throw error;
      } else if (!token) {
        // Undefined behaviour
        throw new Error('Failed to retrieve Stripe token');
      } else {
        // Happy path :)
        return token;
      }
    }
  } catch (err) {
    return Promise.reject(err as Error);
  }
};

export const formatFormData = (formData: any): CreateTokenCardData => ({
  name: formData.cardHolderName || '',
  address_line1: formData.address1 || '',
  address_line2: formData.address2 || '',
  address_city: formData.city || '',
  address_state: formData.state || '',
  address_zip: formData.zip_code || '',
  address_country: 'US',
});

export const formatPaymentSettingsFormData = (
  formData: any,
): CreateTokenCardData => ({
  name: `${formData.cardHolderFirstName} ${formData.cardHolderLastName}` || '',
  address_zip: formData.zipCode || '',
});

export const getCardElement = async (elements: StripeElements | null) => {
  if (!elements)
    return Promise.reject(new Error('Stripe is not properly initialized'));

  const cardElement = elements.getElement('cardNumber');
  if (!cardElement) {
    return Promise.reject(
      new Error('Stripe elements are not configured properly'),
    );
  }
  return cardElement;
};
interface StripeCardInfo {
  name: string;
  last4: string;
  exp_month: string;
  exp_year: string;
  brand: string;
}

interface StripeAddressInfo {
  address_city: string;
  address_line1: string;
  address_line2: string;
  address_state: string;
  address_zip: string;
}

export interface StripePaymentSource
  extends StripeCardInfo,
    StripeAddressInfo {}

export const maskCardNumber = (cardNumber?: string): string => {
  if (!cardNumber) return '';
  if (cardNumber.length >= 4) {
    const mask = '**** '.repeat(3);
    const lastFour = cardNumber.slice(-4);
    return mask + lastFour;
  }
  return '**** **** **** ****';
};
