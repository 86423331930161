import { colors, mediaQueries, size, Icons } from '@everlywell/leaves';
import styled from 'styled-components';

type ProgressBarProps = {
  status: string;
  hasIssue: boolean;
  width: number;
};

type StatusTextProps = {
  hasIssue: boolean;
  isActive?: boolean;
};

const getBarColor = (hasIssue: boolean) => (hasIssue ? colors.red1 : '#d9f0e9');

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: ${size.lg}px;
  position: relative;
  border-radius: 19px;
  background-color: #eff6f4;
  margin-top: ${size.md}px;
  ${mediaQueries.forTabletHorizontalUp} {
    height: 38px;
    margin-top: ${size.xl1}px;
  }
`;

export const ProgressBar = styled.div<ProgressBarProps>`
  position: absolute;
  background-color: ${(props) => getBarColor(props.hasIssue)};
  border-color: ${(props) => getBarColor(props.hasIssue)};
  border-width: 2px;
  border-style: solid;
  width: ${(props) => props.width}%;
  height: 100%;
  border-radius: 19px;
`;

export const StatusWrapper = styled.div<StatusTextProps>`
  display: flex;
  justify-content: ${(props) => (props.hasIssue ? 'center' : 'space-between')};
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 19px;
`;

export const StatusText = styled.div<StatusTextProps>`
  z-index: 1;
  align-items: center;
  justify-content: center;
  width: 25%;
  display: ${(props) => (props.hasIssue ? 'none' : 'flex')};
  color: ${(props) => (props.isActive ? '#025557' : '#d7d7d7')};
`;

export const StatusTextWrapper = styled.span`
  display: none;
  ${mediaQueries.forTabletHorizontalUp} {
    display: inline;
    margin-right: 8px;
  }
`;

export const CheckMark = styled(Icons.CheckMark)`
  width: 9px;
  height: 8px;
`;
