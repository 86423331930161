import { TelehealthPharmacy } from 'common/apis/telehealthApis';

const basePharmacy: TelehealthPharmacy = {
  name: 'CA Pharmacy Store 10.6',
  line1: '1313 A S. Harbor Boulevard',
  line2: '',
  city: 'Anaheim',
  state: 'CA',
  zip: '92804',
  phone_number: '7142121113',
};

export const buildTelehealthPharmacy = (
  overrideProps: Partial<TelehealthPharmacy> = {},
) => ({
  ...basePharmacy,
  ...overrideProps,
});
