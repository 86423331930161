/**
 * Currently an open bug with body-scroll-lock on iPhone specifically iOS 12.x
 * if you touch at the bottom of the page when the browser controls ( bottom bar ) are hidden
 * you are still able to scroll the background
 *
 * when the controls down at the bottom are visible, the body-scroll-lock will work as intended
 *
 * the touchmove event isn't triggered at all down in the area of the bottom toolbar.
 * bug filed: https://bugs.webkit.org/show_bug.cgi?id=189586
 * github issue: https://github.com/willmcpo/body-scroll-lock/issues/82
 * */

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useEffect, useRef, RefObject } from 'react';

function useDisableBodyScroll(ref?: RefObject<HTMLDivElement>) {
  const wrapperEl = ref || useRef<HTMLDivElement>(null);
  useEffect(() => {
    const node = wrapperEl.current;

    if (node) {
      disableBodyScroll(node, {
        reserveScrollBarGap: true,
      });
    }
    return () => {
      if (node) {
        enableBodyScroll(node);
      }
    };
  }, [wrapperEl]);

  return wrapperEl;
}

export default useDisableBodyScroll;
