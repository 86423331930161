import {
  breakpoints,
  colors,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import { bodyCopyLarge } from 'common/styles/app-styles';
import styled from 'styled-components';

const WrapperLabel = styled.div`
  ${bodyCopyLarge};
  display: block;
  font-weight: ${typography.weight.light};
  color: ${colors.gray4};
  text-align: center;
  margin: ${size.md}px 0;

  ${mediaQueries.forTabletVerticalUp} {
    display: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  margin-bottom: ${size.xl1}px;
  justify-content: center;

  ${mediaQueries.forDesktopUp} {
    max-width: ${breakpoints.forDesktopDown}px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export { WrapperLabel, Wrapper };
