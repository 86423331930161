import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { SHARE as dataTest, SUB_HEADER } from 'common/utils/constants/dataTest';
import { copyToClipboard } from 'common/utils/helpers';
import React from 'react';

import * as S from './CopyLink.styles';

export type CopyLinkProps = {
  shareLink: string;
};

function CopyLink(props: CopyLinkProps) {
  const { shareLink } = props;

  function handleCopy() {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'Copy Share Link',
        category: ANALYTICS.CATEGORIES.SHARE_RESULTS,
      },
    });

    copyToClipboard(shareLink);
  }

  return (
    <S.Wrapper>
      <S.Input
        data-test={dataTest.LINK_INPUT}
        name="shareLinkTextBox"
        value={shareLink}
        onChange={() => {}}
      />
      <S.Button data-test={SUB_HEADER.SHARE_COPY_BUTTON} onClick={handleCopy}>
        Copy
      </S.Button>
    </S.Wrapper>
  );
}

export default CopyLink;
