import { Container, size, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

const ConfirmIdentifiersWrapper = styled(Container)`
  padding-top: 185px;
  padding-bottom: ${size.xl1}px;

  ${mediaQueries.forTabletHorizontalUp} {
    display: flex;
    justify-content: space-around;
    padding-bottom: 220px;
  }
  ${mediaQueries.forTabletHorizontalDown} {
    padding-top: ${size.xl1}px;
  }
`;

const DesktopGraphic = styled.img`
  display: none;
  ${mediaQueries.forTabletHorizontalUp} {
    display: inline-block;
  }
`;

const ContentContainer = styled.div`
  flex-grow: 2;
  ${mediaQueries.forTabletHorizontalUp} {
    max-width: 532px;
  }
`;

const MobileGraphic = styled.img`
  margin-top: ${size.xl2}px;
  width: 100%;
  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
  }
`;

export {
  ConfirmIdentifiersWrapper,
  DesktopGraphic,
  ContentContainer,
  MobileGraphic,
};
