import { colors, mediaQueries } from '@everlywell/leaves';
import { hexToRGB } from 'common/utils/helpers';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(${hexToRGB(colors.black)}, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999999;
  cursor: default;
`;

const Content = styled.div`
  position: relative;
  margin: 0 16px;
  max-width: 700px;

  ${mediaQueries.forTabletVerticalUp} {
    margin: 0 32px;
  }
`;

const InnerContent = styled.div`
  background-color: ${colors.white};
  border-radius: 3px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border: solid 1px ${colors.gray2};
  max-height: 77vh;

  ol,
  ul {
    padding-left: 24px;
  }
`;

const CloseButton = styled.button`
  display: block;
  position: absolute;
  z-index: 1;
  background-color: ${colors.white};
  right: -12px;
  top: -12px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 2px 14px 0 rgba(${hexToRGB(colors.black)}, 0.15);
  transition: transform 0.25s ease-in-out;
  cursor: pointer;
  transform: translateZ(0);

  &:hover {
    transform: scale(1.1);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    height: 1px;
    width: 16px;
    background-color: rgba(${hexToRGB(colors.black)}, 0.5);
    transform-origin: center;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

export { Wrapper, Content, InnerContent, CloseButton };
