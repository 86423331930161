import { Button } from '@everlywell/leaves';
import React, { useCallback } from 'react';

import * as S from './styles';

type Props = {
  title: string;
  index: number;
  setSelectedTab: (index: number) => void;
  isActive?: boolean;
  handleCallback?: (title: string) => void;
};

// Todo: Export button constant apparance from leaves to use here
export enum ButtonAppearance {
  primary = 'primary',
  secondary = 'secondary',
}

const TabTitle: React.FC<Props> = (props: Props) => {
  const { title, setSelectedTab, index, isActive, handleCallback } = props;

  const handleOnClick = useCallback(() => {
    if (handleCallback) {
      handleCallback(title);
    }
    setSelectedTab(index);
  }, [setSelectedTab, index, title]);

  const appearance = isActive
    ? ButtonAppearance.primary
    : ButtonAppearance.secondary;

  return (
    <S.TabTitleWrapper>
      <Button data-test={title} onClick={handleOnClick} appearance={appearance}>
        {title}
      </Button>
    </S.TabTitleWrapper>
  );
};

export default TabTitle;
