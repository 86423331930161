import { Col, Row } from '@everlywell/leaves';
import { useHealthProfileProgressStatus } from 'common/hooks/useHealthProfileProgressStatus';
import { useTouchOnHealthProfile } from 'common/hooks/useTouchOnHealthProfile';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { HEALTH_PROFILE_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';
import {
  DEFAULT_BOX_IMAGE_URL,
  PUBLIC_APP_ROOT,
} from 'common/utils/constants/urls';
import { filterQuestionnaire } from 'components/HealthProfile/helpers';
import {
  useCastQuestions,
  useHealthProfileData,
  useQuestionsCounter,
} from 'components/HealthProfile/hooks';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  HealthProfileProgressStatus,
  KitRegistrationResponse,
} from '../../../common/utils/types';
import { Copy } from './components';
import { HealthProfileLoader } from './components/HealthProfileLoader';
import * as S from './styles';

interface Props {
  kitRegistration?: KitRegistrationResponse;
  productImageURL: string;
  testName: string;
  collectionTypes?: string;
}

const NUMBER_OF_LOADERS = 4;

const HealthProfileEntryPoint = ({
  collectionTypes,
  kitRegistration,
  productImageURL,
  testName,
}: Props) => {
  const { fetched, healthProfileData } = useHealthProfileData();
  const {
    healthProfileProgressStatus: questionnaire,
    loading: loadingHealthProfileProgressStatus,
  } = useHealthProfileProgressStatus();
  const { unanswered_questions } = questionnaire as HealthProfileProgressStatus;

  useTouchOnHealthProfile({
    lastInteraction: null,
    kitRegistration,
  });

  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: 'Health Profile Entry',
      },
    });
  }, []);
  const previouslyRegisteredKit = Boolean(
    kitRegistration?.previously_registered_kit,
  );

  const questions = healthProfileData?.questions ?? [];
  const keyValueQuestionsSetAll = useCastQuestions(questions);
  const unansweredParam = previouslyRegisteredKit && unanswered_questions > 0;

  const filterQuestions = filterQuestionnaire({
    questions,
    unansweredParam,
    keyValueQuestionsSetAll,
  });

  const questionsLength = useQuestionsCounter(filterQuestions);

  let search = '';
  if (unansweredParam) {
    search = '?unanswered=true';
  }

  if (!fetched || loadingHealthProfileProgressStatus) {
    return <HealthProfileLoader numberOfLoaders={NUMBER_OF_LOADERS} />;
  }

  return (
    <Row data-test={`${DATA_TEST.CONTAINER}-entry-point`}>
      <S.ImageCol>
        <S.BoxImageContainer>
          <S.BoxImage
            src={productImageURL || DEFAULT_BOX_IMAGE_URL}
            alt={testName}
          />
        </S.BoxImageContainer>
      </S.ImageCol>
      <S.ImageCol>
        <S.CheckMark />
      </S.ImageCol>

      <Col>
        <S.Header>
          Your test is registered!
          <br />
          Now, let&apos;s build your health profile.
        </S.Header>
      </Col>
      <Copy
        questionsLength={questionsLength}
        unansweredParam={unansweredParam}
      />
      <Col>
        <S.AccordionWrapper>
          <S.AccordionItem>
            <S.AccordionHeading>About our Privacy Policy</S.AccordionHeading>
            <S.AccordionChild showBorderBottom>
              <S.AccordionText>
                As always, we take privacy seriously. Please review our{' '}
                <S.Link
                  href={`${PUBLIC_APP_ROOT}/privacy-policy`}
                  target="_blank"
                >
                  privacy policy
                </S.Link>{' '}
                and{' '}
                <S.Link
                  href={`${PUBLIC_APP_ROOT}/terms-of-use/`}
                  target="_blank"
                >
                  user agreement
                </S.Link>
                {''}
                {''} for more information.
              </S.AccordionText>
            </S.AccordionChild>
          </S.AccordionItem>
        </S.AccordionWrapper>
      </Col>
      <Col>
        <Link to={`/health-profile/${search}`} state={{ collectionTypes }}>
          <S.ContinueButton appearance="primary">Start</S.ContinueButton>
        </Link>
      </Col>
    </Row>
  );
};

export default HealthProfileEntryPoint;
