import { HealthProfileResponse } from 'common/utils/types';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

type Props = {
  children: ReactNode;
};
export type AnswerObj = { [key: string]: HealthProfileResponse };
type ContextProps = {
  allAnswers: AnswerObj;
  setAllAnswers: Dispatch<SetStateAction<AnswerObj>>;
};

const AnswersContext = createContext<ContextProps>({
  allAnswers: {},
  setAllAnswers: () => {},
});

export const useAnswers = () => useContext(AnswersContext);
const AnswersProvider = ({ children }: Props) => {
  const [allAnswers, setAllAnswers] = useState({});

  return (
    <AnswersContext.Provider
      value={{
        allAnswers,
        setAllAnswers,
      }}
    >
      {children}
    </AnswersContext.Provider>
  );
};

export default AnswersProvider;
