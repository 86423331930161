import { WeightMembershipData } from 'common/utils/types';

//TODO: update this file as needed once Weight Membership Data endpoint is available
export const defaultWeightMembershipData: WeightMembershipData = {
  type: 'VIP Plan',
  monthlyPrice: '129',
  nextBillingDate: '1/22/23',
  enrolled: '12/12/22',
  membershipStatus: 'Active',
};

export const getWeightMembershipData = new Promise((resolve, reject) => {
  resolve(defaultWeightMembershipData);
});
