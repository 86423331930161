import { colors, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: ${size.xl7}px;
`;

export const Banner = styled.div`
  width: 100%;
`;

export const SectionHeader = styled.h3`
  display: flex;
  justify-content: space-between;
  ${typography.h5Text};
  color: ${colors.green4};
`;
