import { TAKE_ACTION } from 'common/utils/constants/dataTest';
import { render } from 'common/utils/reactTestingLibraryHelpers';
import React from 'react';

import ScheduleModal from '../index';
import * as modalProps from './testProps';

const renderComponent = () =>
  render(<ScheduleModal {...modalProps} onClose={jest.fn()} />);

describe('<ScheduleModal />', () => {
  it('should render the header text', () => {
    const { getByTestId } = renderComponent();
    expect(getByTestId(TAKE_ACTION.SCHEDULE_MODAL_TITLE).innerHTML).toContain(
      modalProps.header,
    );
  });

  it('should render the description text', () => {
    const { getByTestId } = renderComponent();
    expect(
      getByTestId(TAKE_ACTION.SCHEDULE_MODAL_DESCRIPTION).innerHTML,
    ).toContain(modalProps.description);
  });

  it('should render a button link to schedule a consultation', () => {
    const { getByTestId } = renderComponent();
    expect(
      getByTestId(TAKE_ACTION.SCHEDULE_CONTINUE_BUTTON).getAttribute('href'),
    ).toEqual(modalProps.link);
  });
});
