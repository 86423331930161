import {
  KitResult,
  Test,
  Marker,
  MarkerResult,
  HistoricalResult,
  Consult,
  Content,
} from 'common/utils/types';

const testContent: Content = {
  HIGH_REACTIVITY_OVERVIEW:
    'High reactivity foods (Class 3 foods in your lab results) are measured as the strongest immune response in your blood sample. Not all high reactivity foods cause symptoms, but they may be the most likely to—so these are good options to target in an elimination diet.',
};

const test: Test = {
  id: 41,
  name: 'Thyroid Test',
  display_name: 'Thyroid Test',
  type: 'thyroid-test',
  marker_types: ['thyroid'],
  content_token: 'b98f873d-495b-45f5-6006-e99b5591837u',
  genomics_eligible: true,
  theme_icon: 'other',
  content: testContent,
  recorded_webinar: false,
};

const tshMarker: Marker = {
  content_token: '37825df3-0a00-46c0-8d94-e2e8c8144495',
  descriptors: ['Low', 'Normal', 'High'],
  id: 974,
  name: 'TSH',
  severities: [2, 1, 3],
  severity_colors: ['red'],
  test_id: 41,
  type: 'tsh',
  groupings: [],
};

const t4Marker: Marker = {
  content_token: '45825df3-0a00-46c0-8d94-e2e8c8145588',
  descriptors: ['Low', 'Normal', 'High'],
  id: 975,
  name: 'Free T4',
  severities: [2, 1, 3],
  severity_colors: ['red'],
  test_id: 41,
  type: 't4',
  groupings: [],
};

const tshHistoricalResult: HistoricalResult = {
  id: 12223,
  marker_id: 974,
  value: 5.2,
  severity_index: 0,
  descriptor: 'foo',
  test_result_id: 12344,
  boundaries: [0, 150],
  results_approved_at: new Date().toString(),
  collected_at: '2021-10-16T10:34:59Z',
  boundary_conditions: ['a'],
};

const t4HistoricalResult: HistoricalResult = {
  id: 12224,
  marker_id: 975,
  value: 0.65,
  severity_index: 0,
  descriptor: 'foo',
  test_result_id: 12345,
  boundaries: [0, 150],
  results_approved_at: new Date().toString(),
  collected_at: '2021-10-16T10:34:59Z',
  boundary_conditions: ['a'],
};

const tshMarkerResult: MarkerResult = {
  marker_id: 974,
  value: 5.2,
  boundaries: [0, 150],
  boundary_conditions: [],
  content_token: 'test',
  descriptor: 'foo',
  id: 1234,
  severity_index: 0,
  test_result_id: 12344,
  historical_results: [tshHistoricalResult],
};

const t4MarkerResult: MarkerResult = {
  marker_id: 975,
  value: 0.65,
  boundaries: [0, 150],
  boundary_conditions: [],
  content_token: 'test',
  descriptor: 'foo',
  id: 1234,
  severity_index: 0,
  test_result_id: 12345,
  historical_results: [t4HistoricalResult],
};

const consult: Consult = {
  available: true,
  available_until: new Date('2050-01-01').toISOString(),
};

const unavailableConsult: Consult = {
  available: false,
  available_until: new Date('2050-01-01').toISOString(),
};

const kitResult: KitResult = {
  id: 123,
  product_name: 'Thyroid Test',
  user_email: 'a@b.c',
  kit_result_time: '123',
  status: 'results_approved',
  test_id: 41,
  test,
  markers: [tshMarker, t4Marker],
  marker_results: [tshMarkerResult, t4MarkerResult],
  needs_replacement: false,
  results_approved_at: new Date().toISOString(),
  number: 'K123',
  tracking_url: 'foo',
  product_slug: 'thyroid-test',
  serial_number: 1872651415,
  pwn_order_number: 987651234,
  pwn_confirmation_code: 615232,
  content: 'Foo test',
  consult,
  clia: 'ABC1234567',
};

const unavailableKitResult: KitResult = {
  ...kitResult,
  consult: unavailableConsult,
};

export {
  kitResult,
  unavailableKitResult,
  tshMarker,
  t4Marker,
  test,
  tshMarkerResult,
  t4MarkerResult,
  tshHistoricalResult,
  t4HistoricalResult,
};
