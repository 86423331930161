/* eslint-disable @typescript-eslint/no-var-requires */
/* stylelint-disable no-descending-specificity */
import { colors, mediaQueries, typography } from '@everlywell/leaves';
import { modalContentTitle, bodyCopyLarge } from 'common/styles/app-styles';
import { hexToRGB } from 'common/utils/helpers';
import Button from 'components/Button';
import { Spinner } from 'pages/OldSettingsPage/SettingsForm/PhysicianContactForm/styles';
import styled from 'styled-components';

type FooterProps = {
  needsGradient?: boolean;
};

const Wrapper = styled.div`
  position: relative;
`;

const UpdatedSpinner = styled(Spinner)`
  align-self: center;
  position: relative;
  z-index: 1;
`;

const Content = styled.div`
  ${bodyCopyLarge};
  font-weight: ${typography.weight.light};
  color: ${colors.gray4};
`;

const ToggleContainer = styled.div`
  display: flex;
  margin: 20px 0 10px;

  span {
    margin-right: 16px;
    ${mediaQueries.forTabletHorizontalUp} {
      margin-right: 24px;
    }
  }

  ${mediaQueries.forPhoneOnly} {
    flex-wrap: wrap;
  }
`;

const UpdatedButton = styled(Button)`
  text-transform: none;
  letter-spacing: normal;
  width: unset;
  padding: 10px 21px;
  margin: 0;

  &:first-child {
    margin: 0 0 16px;
    display: none;
    ${mediaQueries.forTabletVerticalUp} {
      margin: 0 24px 0 0;
      display: block;
    }
  }

  ${mediaQueries.forTabletVerticalUp} {
    flex: 0 0 auto;
  }
`;

const Main = styled.div`
  padding: 26px 32px;

  h2 {
    font-weight: ${typography.weight.bold};
    ${modalContentTitle};
  }
`;

const Footer = styled.footer<FooterProps>`
  background-color: ${colors.white};
  padding: 26px 32px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  position: relative;

  ${mediaQueries.forTabletHorizontalUp} {
    border-top: 1px solid ${colors.gray2};
  }

  &:before {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    content: '';
    display: ${(props) => (props.needsGradient ? 'block' : 'none')};
    height: 60px;
    position: absolute;
    top: -61px;
    left: 0%;
    width: calc(100% - 2px);
  }
`;

const SpinnerWrapper = styled.div`
  background-color: rgba(${hexToRGB(colors.white)}, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopoverModalWrap = styled.div`
  position: absolute;
  right: -12px;
  top: 29px;
  width: 436px;
`;

const DisclosureText = styled.span`
  ${bodyCopyLarge};
  font-weight: ${typography.weight.light};
`;

const SharingText = styled.span`
  ${bodyCopyLarge};
  font-weight: ${typography.weight.light};
`;

export {
  Content,
  ToggleContainer,
  UpdatedButton as Button,
  UpdatedSpinner as Spinner,
  Main,
  Footer,
  PopoverModalWrap,
  SpinnerWrapper,
  Wrapper,
  DisclosureText,
  SharingText,
};
