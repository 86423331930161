/* eslint-disable no-shadow */
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import {
  ImmutableReduxState,
  Notification as NotificationType,
} from 'common/utils/types';
import Notification from 'components/Notification';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { clearNotification } from 'store/actions';
import { makeSelectNotification } from 'store/selectors';

type StateProps = {
  notification?: NotificationType;
};

type DispatchProps = {
  clearNotification: () => void;
};

type Props = StateProps & DispatchProps;

const getLabelFromNotification = (notification: NotificationType) => {
  if (notification.mixpanelLabel) return notification.mixpanelLabel;
  return (notification.details ?? notification.message) as string;
};

export const CLEAR_NOTIFICATION_DELAY = 5000;

function NotificationContainer(props: Props) {
  const { notification } = props;
  const { clearNotification, ...rest } = props;
  const type = notification && notification.type;
  const persistent = notification && notification.persist;
  useEffect(() => {
    if (notification && !notification.persist) {
      analytics.track({
        event: ANALYTICS.EVENTS.NOTIFICATION,
        data: {
          label: getLabelFromNotification(notification),
          type: notification.type,
          category: ANALYTICS.CATEGORIES.NOTIFICATION,
          component: notification.source,
        },
      });
      setTimeout(clearNotification, CLEAR_NOTIFICATION_DELAY);
    }
  }, [notification]);

  return (
    <Notification
      clearNotification={clearNotification}
      type={type}
      persistent={persistent}
      {...rest}
    />
  );
}

function mapStateToProps(state: ImmutableReduxState): StateProps {
  return {
    notification: makeSelectNotification()(state),
  };
}

const mapDispatchToProps = {
  clearNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationContainer);
