import { useTelehealthAppointments } from 'common/apis/telehealthApis';
import useEnterpriseProgramSlug from 'common/hooks/useEnterpriseProgramSlug';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';

import AppointmentsSectionGroup from './AppointmentsSectionGroup';
import { AppointmentsContextProvider } from './context';

export const HELP_PHONE_NUMBER = '(855) 997-0119';
export const CONTACT_DAY_TIME_TEXT = '8 AM-8 PM EST';

const Appointments = () => {
  const { enterpriseTestToTreat } = useFlags();
  const { enterpriseProgramSlug } = useEnterpriseProgramSlug();

  const { data: response, isLoading } = useTelehealthAppointments(
    {
      period: 'all',
      program_slugs: [enterpriseProgramSlug ?? ''],
    },
    {
      enabled: !!enterpriseProgramSlug,
    },
  );

  if (!enterpriseTestToTreat || !enterpriseProgramSlug) return null;

  const appointments = response?.data?.appointments ?? [];

  return (
    <AppointmentsContextProvider appointments={appointments}>
      <AppointmentsSectionGroup isLoading={isLoading} />
    </AppointmentsContextProvider>
  );
};

export default Appointments;
