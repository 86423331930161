import { Button, Col, localizeUtils } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { SCHEDULE_CARD } from 'common/utils/constants/dataTest';
import { getNumberOfDays } from 'common/utils/helpers';
import { KitResult } from 'common/utils/types';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import pluralize from 'pluralize';
import React from 'react';

import primaryShield from './images/Shield.svg';
import * as S from './styles';
type Props = {
  title: string;
  content: string;
  buttonContent: string;
  buttonHref: string;
  label: string;
  external: boolean;
  kitResult: KitResult;
};
const ScheduleCard = (props: Props) => {
  const {
    kitResult,
    title,
    content,
    buttonContent,
    buttonHref,
    label,
    external,
  } = props;
  const { consult, product_name } = kitResult;
  const { available, available_until } = consult || {
    available: false,
    available_until: '',
  };
  const numberOfDays = getNumberOfDays(available_until);
  const handleButtonClick = async () => {
    const trackingData = {
      label,
      category: ANALYTICS.CATEGORIES.DASHBOARD,
      testName: product_name,
    };
    await analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: trackingData,
    });
    window.open(buttonHref, external ? '_blank' : '_self');
  };
  const scheduleCardEl = useScrolledIntoViewOnce(() => {
    const viewedTrackingData = {
      label,
      category: ANALYTICS.CATEGORIES.DASHBOARD,
      testName: product_name,
    };
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: viewedTrackingData,
    });
  });
  const pluralizedNumberOfDays = pluralize('day', numberOfDays, true);
  const localizedTimeAgo = localizeUtils.wrapTimeAgoInLocalizeVar(
    pluralizedNumberOfDays,
    'ScheduleCard-numberOfDays',
  );
  return (
    <S.ScheduleCardContainer>
      <S.ScheduleCard ref={scheduleCardEl}>
        <S.FluidRow middle="md" start="md">
          <Col xs={9} md lg={8} xl={7}>
            <S.TitleWrapper>
              <S.Title data-test={SCHEDULE_CARD.SCHEDULE_CARD_TITLE}>
                {title}
              </S.Title>
              {available && available_until && (
                <S.Subtitle>{localizedTimeAgo} left</S.Subtitle>
              )}
            </S.TitleWrapper>
            <S.ContentWrapper>
              <S.Content data-test={SCHEDULE_CARD.SCHEDULE_CARD_CONTENT}>
                {content}
              </S.Content>
            </S.ContentWrapper>
          </Col>
          <Col xs={3} md={2} first="md" lg={1} xl={2}>
            <S.ImageWrapper>
              <S.Image
                src={primaryShield}
                alt="Primary Shield"
                data-test={SCHEDULE_CARD.SCHEDULE_CARD_ICON}
              />
            </S.ImageWrapper>
          </Col>
          <Col xs md={3} lg={3}>
            <S.ButtonWrapper>
              <Button
                data-test={SCHEDULE_CARD.SCHEDULE_CARD_BUTTON}
                onClick={handleButtonClick}
                appearance="tertiary"
              >
                {buttonContent}
              </Button>
            </S.ButtonWrapper>
          </Col>
        </S.FluidRow>
      </S.ScheduleCard>
    </S.ScheduleCardContainer>
  );
};

export default ScheduleCard;
