import { useAccordionContext, HeadingProps } from '@everlywell/leaves';
import { TelehealthMedication } from 'common/apis/telehealthApis';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import VisuallyHidden from 'components/VisuallyHidden';
import { format } from 'date-fns';
import { lowerCase } from 'lodash';
import React from 'react';
import { animated, useSpring } from 'react-spring';

import * as S from './CardHeader.styles';

export type CardHeaderProps = Omit<HeadingProps, 'children'> & {
  medication: TelehealthMedication;
};

/**
 * Header for the Prescriptions Card.
 */
function CardHeader({ medication, ...props }: CardHeaderProps) {
  const { openedItems } = useAccordionContext();

  // NOTE: At this point uid is defined by the Item Accordion component
  const isExpanded = openedItems?.includes(props.uid!);
  const styles = useSpring({
    transform: `rotate(${isExpanded ? 0 : 180}deg)`,
    width: 'auto',
    height: 'auto',
  });

  return (
    <S.Container>
      <S.LeftColumn>
        <S.Eyebrow>{formatStatus(medication.status.trim())}</S.Eyebrow>
        <S.Heading>{medication.product_name}</S.Heading>
        <S.Details>
          Ordered on
          {format(new Date(medication.date_written), ' MM/dd/yy')}
          <br />
          Refills: {medication.refills}
        </S.Details>
      </S.LeftColumn>
      <S.RightColumn>
        <S.AccordionHeading
          {...props}
          hideCaret
          onClick={() => {
            analytics.track({
              event: ANALYTICS.EVENTS.CLICKED_BUTTON,
              data: {
                category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
                label: isExpanded
                  ? ANALYTICS.LABELS.ACCOUNT_HUB.MEDICATION_COLLAPSE
                  : ANALYTICS.LABELS.ACCOUNT_HUB.MEDICATION_EXPAND,
              },
            });
          }}
        >
          <animated.div style={styles}>
            <S.ToggleIcon />
            <VisuallyHidden>
              {isExpanded ? 'Collapse card' : 'Expand card'}
            </VisuallyHidden>
          </animated.div>
        </S.AccordionHeading>
      </S.RightColumn>
    </S.Container>
  );
}

export default CardHeader;

/**
 * Transform a prescription status into a readable sentence.
 * @param {string} status: string in PascalCase
 *
 */
export const formatStatus = (status: string) => {
  if (status.match(/^erx/i)) {
    const rest = status.replace(/^erx/i, '');

    return `eRx ${lowerCase(rest)}`;
  }

  const lower = lowerCase(status);
  return lower.charAt(0).toUpperCase() + lower.slice(1);
};
