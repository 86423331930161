/**
 * DashboardPage
 */

import { breakpoints, Col, Row, size } from '@everlywell/leaves';
import { getUser } from 'common/apis/userApis';
import { notifyPaymentFailure } from 'common/utils/notifications';
import {
  MembershipStatus,
  Notification as NotificationType,
} from 'common/utils/types';
import MyChecklist from 'components/CreditRedemption/components/MyChecklist/MyChecklist';
import ApolloContainer from 'containers/ApolloContainer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { setNotification as _setNotification } from 'store/actions';
import { makeSelectSession } from 'store/selectors';
import { setUserData } from 'store/user/actions';

import SettingsCard from './components/Cards/SettingsCard';
import TelehealthCard from './components/Cards/TelehealthCard';
import HealthProfileSection from './components/HealthProfileSection';
import OrderConfirmationModal from './components/OrderConfirmationModal';
import PageHeader from './components/PageHeader';
import TestResultsSection from './components/TestResultsSection';
import * as S from './DashboardPage.styles';
import * as track from './DashboardPage.tracking';

export type DashboardPageProps = {};

enum ConfirmationModalStatus {
  Initial,
  Visible,
  Hidden,
}

const DashboardPage = (props: DashboardPageProps) => {
  const [showConfirmationModalState, setShowConfirmationModalState] = useState(
    ConfirmationModalStatus.Initial,
  );

  const [searchParams] = useSearchParams();

  const [isDashboardPageViewed, setIsDashboardPageViewed] =
    useState<boolean>(false);
  const dispatch = useDispatch();

  const { isChecklistModuleEnabled } = useFlags();

  const { userId } = useSelector(makeSelectSession());

  const { data: response } = useQuery(
    ['user', userId],
    () => getUser(userId!),
    {
      enabled: Boolean(userId),
      onSuccess: (response) => {
        if (response.data) {
          dispatch(
            setUserData({
              ...response.data,
              full_user_data: true,
            }),
          );
        }
      },
    },
  );
  const user = response?.data || undefined;
  const membership = user?.membership;

  const onSetOpenModal = () => {
    setShowConfirmationModalState(ConfirmationModalStatus.Hidden);
  };

  useEffect(() => {
    if (user && !isDashboardPageViewed) {
      track.whenDashboardPageViewed();
      setIsDashboardPageViewed(true);
    }
  }, [user, isDashboardPageViewed]);

  useEffect(() => {
    const setNotification = (notification: NotificationType) =>
      dispatch(_setNotification(notification));
    if (setNotification && membership?.state === MembershipStatus.Failed) {
      notifyPaymentFailure(setNotification);
    }
  }, [dispatch, membership]);

  useEffect(() => {
    const param = searchParams.get('purchase-confirmation');
    const shouldShowConfirmationModal =
      !!param && showConfirmationModalState === ConfirmationModalStatus.Initial;

    if (shouldShowConfirmationModal) {
      setShowConfirmationModalState(ConfirmationModalStatus.Visible);
      track.whenPurchaseConfirmationModalIsDisplayed();
    }
  }, [searchParams, showConfirmationModalState]);

  const { width } = useWindowSize();
  const isDesktop = width >= breakpoints.forDesktopUp;
  const userDataIsNotEmpty = user && !isEmpty(user.consumer_attributes);

  return (
    <ApolloContainer>
      <S.Container>
        <Row>
          {/* start of section */}
          <Col sm={12}>
            <PageHeader user={user} />
          </Col>

          {/* Conditionally renders the Checklist if LaunchDarkly flag is enabled. */}
          {isChecklistModuleEnabled ? (
            <Col>
              <MyChecklist />
            </Col>
          ) : null}

          <Col sm={12} css={{ marginBottom: `${size.xl1}px` }}>
            <TestResultsSection user={user} />
          </Col>

          <Col
            sm={12}
            lg={userDataIsNotEmpty ? 6 : 12}
            css={{ marginBottom: `${size.xl1}px` }}
          >
            <TelehealthCard user={user} />
          </Col>

          {userDataIsNotEmpty && (
            <Col sm={12} lg={6} css={{ marginBottom: `${size.xl1}px` }}>
              <HealthProfileSection user={user!} />
            </Col>
          )}
          {!isDesktop && (
            <Col sm={12} md={12}>
              <SettingsCard user={user} />
            </Col>
          )}
        </Row>
      </S.Container>
      <div>
        <OrderConfirmationModal
          openModal={
            showConfirmationModalState === ConfirmationModalStatus.Visible
          }
          setOpenModal={onSetOpenModal}
          onCTAClick={onSetOpenModal}
        />
      </div>
    </ApolloContainer>
  );
};

export default DashboardPage;
