import { theme, useMediaQuery } from '@everlywell/ui-kit';
import React, { Dispatch, forwardRef, SetStateAction } from 'react';
import { GroupBase, MultiValue, PropsValue, SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import Select from 'react-select/dist/declarations/src/Select';

import {
  ClearIndicator,
  DropdownIndicator,
  MultiValueRemove,
  Option,
  ThemeMultiSelect,
  colourStyles,
} from './InternalComponents';
import { OptionType } from './types';

export type MultiSelectAutoCompleteProps = {
  callbackLoadingOptions?: (
    inputValue: string,
    callback: (options: OptionType[]) => void,
  ) => void;
  defaultOptions?: OptionType[];
  defaultValue?: OptionType[];
  hasError?: boolean;
  isLoading?: boolean;
  notFoundMessage?: string;
  options?: OptionType[];
  isMulti?: boolean;
  placeholder?: string;
  setHasError?: Dispatch<SetStateAction<boolean>>;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  onChange?: (value: OptionType[]) => void;
  value?: PropsValue<OptionType> | undefined;
  name?: string;
};

/**
 * The Multi Select Autocomplete is the one in charge
 * of display a dropdown with options based on the input, and give suggestions on the results
 */
const MultiSelectAutoComplete = forwardRef<
  Select<OptionType, boolean, GroupBase<OptionType>>,
  MultiSelectAutoCompleteProps
>((props, ref) => {
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);
  const {
    callbackLoadingOptions,
    defaultOptions,
    defaultValue,
    hasError = false,
    isLoading,
    isMulti = true,
    notFoundMessage = 'No options found',
    onChange,
    placeholder = '',
    setHasError,
    name,
  } = props;

  const handleOnChange = (
    newValue: MultiValue<OptionType> | SingleValue<OptionType>,
  ) => {
    if (Array.isArray(newValue)) {
      onChange?.(newValue as OptionType[]);
    } else if (newValue) {
      onChange?.([newValue] as OptionType[]);
    } else {
      onChange?.([]);
    }
  };

  return (
    <>
      {/* @ts-ignore */}
      <AsyncSelect
        id={name}
        name={name}
        aria-errormessage={notFoundMessage}
        aria-hidden="false"
        aria-label={placeholder}
        autoFocus={false}
        blurInputOnSelect={false}
        cacheOptions
        closeMenuOnSelect={false}
        components={{
          ClearIndicator,
          DropdownIndicator,
          IndicatorSeparator: null,
          MultiValueRemove,
          Option,
        }}
        defaultOptions={defaultOptions}
        defaultValue={defaultValue}
        hideSelectedOptions={false}
        isLoading={isLoading}
        isMulti={isMulti}
        loadOptions={callbackLoadingOptions}
        noOptionsMessage={() => notFoundMessage}
        onBlur={() => setHasError?.(false)}
        onChange={handleOnChange}
        placeholder={placeholder}
        ref={ref}
        styles={colourStyles({ hasError, isMobile })}
        theme={ThemeMultiSelect}
      />
    </>
  );
});

export default MultiSelectAutoComplete;
