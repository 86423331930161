import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { DEFAULT_BOX_IMAGE_URL } from 'common/utils/constants/urls';
import { KitResult } from 'common/utils/types';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';

import * as S from './ReadyTestResultsCard.styles';

export type ReadyTestResultsCardProps = {
  kitResult: KitResult;
};

/**
 * Displays the info for a test kit with `ready` status
 */

function ReadyTestResultCard(props: ReadyTestResultsCardProps) {
  const { kitResult } = props;
  return (
    <S.Container>
      <S.IconContainer>
        <S.Icon src={kitResult.box_image_url || DEFAULT_BOX_IMAGE_URL} alt="" />
      </S.IconContainer>

      <div>
        <S.Name>{kitResult.product_name}</S.Name>
        <S.ReleasedOn>
          {kitResult.results_approved_at
            ? `Released on ${formatDate(kitResult.results_approved_at)}`
            : '-'}
        </S.ReleasedOn>
      </div>
      <S.CTA
        appearance="primary"
        component={Link}
        to={`/results/${kitResult.number}`}
        onClick={() => {
          analytics.track({
            event: ANALYTICS.EVENTS.CLICKED_BUTTON,
            data: {
              category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
              label: ANALYTICS.LABELS.ACCOUNT_HUB.VIEW_RESULTS,
              'Test ID': kitResult.test_id,
              'Test Name': kitResult.product_name,
            },
          });
          return false;
        }}
      >
        View results
      </S.CTA>
    </S.Container>
  );
}

export default ReadyTestResultCard;

const formatDate = (date: string) => format(parseISO(date), 'MM/dd/yy');
