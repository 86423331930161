import { Marker, MarkerResult } from 'common/utils/types';

export const MAX_MARKER_INDICATOR_PERCENTAGE = 97.0;

export const getMarkerPosition = (
  markerResult: MarkerResult,
  marker?: Marker,
  isClassStyle?: boolean,
) => {
  const { boundaries = [], value: markerValue } = markerResult || {};
  const { descriptors = [], max_value } = marker || {};

  const totalBoundaries = boundaries.length;
  const boundaryRange = 100 / (totalBoundaries + 1);
  const usableDescriptors = descriptors.slice(0, totalBoundaries + 1);

  let markerResultPosition = 0;
  let end = boundaryRange;
  let last = 0;

  usableDescriptors.map((descriptor: string, index: number) => {
    const lowerBoundary = boundaries[index - 1] || 0;
    const markerMaxValue = max_value;
    const upperBoundary = boundaries[index] || markerMaxValue || Infinity;

    const markerStringValue =
      typeof markerValue === 'string'
        ? markerValue.replace(/[^\d.-]/g, '')
        : markerValue.toString();
    const markerNumValue = parseFloat(markerStringValue);

    // low reactivity
    if (index === 0) {
      if (lowerBoundary < markerNumValue && markerNumValue <= upperBoundary) {
        // marker value is within the current range
        const min = lowerBoundary;

        markerResultPosition = getMarkerResultPosition(
          boundaries,
          index,
          min,
          markerMaxValue!,
          last,
          markerNumValue,
          totalBoundaries,
          isClassStyle,
        );
      }
      // mild and above reactivity
    } else {
      if (lowerBoundary <= markerNumValue && markerNumValue <= upperBoundary) {
        // marker value is within the current range
        const min = lowerBoundary + 0.0001;

        markerResultPosition = getMarkerResultPosition(
          boundaries,
          index,
          min,
          markerMaxValue!,
          last,
          markerNumValue,
          totalBoundaries,
          isClassStyle,
        );
      }

      // marker value is over the marker's maximum value, simply set it to the
      // farthest right
      if (markerMaxValue && markerNumValue >= markerMaxValue) {
        markerResultPosition = MAX_MARKER_INDICATOR_PERCENTAGE;
      }
    }

    // save the last position
    last = end;
    end += boundaryRange;

    return descriptor;
  });

  return markerResultPosition;
};

/**
 * Calculate the position of the current marker within the range
 * @param boundaries Array of boundary values
 * @param index Current descriptor being iterated
 * @param min Minimum value for the range
 * @param markerMaxValue
 * @param last
 * @param markerNumValue Numeric value of the marker result
 * @param totalBoundaries
 * @param isClassStyle Flag for new class chart style
 * @returns Numeric percentage position of the current marker within the range
 */
const getMarkerResultPosition = (
  boundaries: number[],
  index: number,
  min: number,
  markerMaxValue: number,
  last: number,
  markerNumValue: number,
  totalBoundaries: number,
  isClassStyle: boolean | undefined,
) => {
  const upperBoundary = boundaries[index] || markerMaxValue || Infinity;

  // make maximum upper boundary range consistent with minimum
  const max = Number.isFinite(upperBoundary)
    ? upperBoundary
    : min + boundaries[0];

  // math
  // don't let position go outside chart
  const regularMarkerPosition = getRegularMarkerResultPosition(
    last,
    max,
    min,
    markerNumValue,
    totalBoundaries,
  );

  return isClassStyle
    ? getMiddleMarkerResultPosition(regularMarkerPosition, totalBoundaries)
    : regularMarkerPosition;
};

const getRegularMarkerResultPosition = (
  last: number,
  max: number,
  min: number,
  markerValue: number,
  totalBoundaries: number,
) => {
  const position =
    last + ((markerValue - min) / (max - min) / (totalBoundaries + 1)) * 100;
  const minPosition = Math.min(MAX_MARKER_INDICATOR_PERCENTAGE, position);

  return minPosition;
};

const getMiddleMarkerResultPosition = (
  markerPosition: number,
  totalBoundaries: number,
) => {
  // Calculate boundary step size. (e.g: 20 units per class/range)
  const step = 100 / (totalBoundaries + 1);

  // Calculate on which zone/range/class the marker belongs (e.g: Class 0)
  const boundaryMarker = Math.floor(markerPosition / step);

  // Calculate the central position value of the class (e.g: Class 0: 0.0, Class 1: 20.0 -> Central Position: 10.0)
  const position = (step * (2 * boundaryMarker + 1)) / 2;
  const minPosition = Math.min(MAX_MARKER_INDICATOR_PERCENTAGE, position);

  return minPosition;
};
