import React from 'react';

import { toTitleCase } from './helpers';
import {
  AddressDetails,
  KitRegistrationInput,
  KitRegistrationResponse,
  KitRegistrationUser,
} from './types';

export const validateUserInfo = (user: KitRegistrationUser) => {
  let valid = true;
  const updatedFields: string[] = [];
  Object.values(user).forEach((input: KitRegistrationInput<string>) => {
    const validInput = input.validation
      ? input.validation.test(input.value)
      : true;
    if (input.required && !validInput) {
      valid = false;
      updatedFields.push(input.id);
    }
  });
  return { valid, updatedFields };
};

export const handleChange = (
  input: KitRegistrationInput<string>,
  value: string,
  invalidFields?: string[],
  setInvalidFields?: React.Dispatch<React.SetStateAction<string[]>>,
) => {
  if (invalidFields && setInvalidFields && invalidFields.includes(input.id)) {
    setInvalidFields(invalidFields.filter((item) => item !== input.id));
  }
  input.setValue(value);
};

export const getKitType = (
  kitType: string,
  kitRegistration?: KitRegistrationResponse,
) => {
  if (kitRegistration && kitRegistration.enterprise) return 'Enterprise';
  if (kitType === 'needsSample') return 'Invisible Ink';
  return toTitleCase(kitType);
};

export const compareAddresses = (
  user: KitRegistrationUser,
  address: AddressDetails | undefined,
) => {
  if (!address) {
    return false;
  }

  return (
    user.streetAddress.value === address.street &&
    user.subAddress.value === address.secondary &&
    user.addressCity.value === address.city &&
    user.addressState.value === address.state &&
    user.addressZipCode.value === address.zipcode
  );
};

type RegistrationConsumerFields = 'dob' | 'gender';

export const registrationContainsValue = (
  value: RegistrationConsumerFields,
  kitRegistration?: KitRegistrationResponse,
) => {
  let doesContainValue = false;
  if (kitRegistration && kitRegistration.consumer) {
    doesContainValue = kitRegistration.consumer[value] !== '';
  }
  return doesContainValue;
};

export const REGISTRATION_KEYS = [
  'activeStep',
  'addressCity',
  'addressState',
  'addressZipCode',
  'authorizationChecked',
  'biologicalSex',
  'congregateSetting',
  'consentChecked',
  'dateOfBirth',
  'emailAddress',
  'employerCity',
  'employerName',
  'employerState',
  'employerStreetAddress',
  'employerSubAddress',
  'employerZipCode',
  'ethnicity',
  'exposure',
  'firstCovidTest',
  'firstName',
  'healthcareSetting',
  'highRisk',
  'kitRegistration',
  'kitType',
  'lastName',
  'newYorkEmployment',
  'newYorkResident',
  'newYorkSchoolName',
  'newYorkStudent',
  'overrideAddress',
  'phoneNumber',
  'planId',
  'pregnancyStatus',
  'race',
  'registrationStarted',
  'sampleCollectionDate',
  'sampleCollectionTime',
  'schoolCity',
  'schoolState',
  'schoolStreetAddress',
  'schoolSubAddress',
  'schoolZipCode',
  'screenerIndex',
  'steps',
  'streetAddress',
  'subAddress',
  'symptoms',
  'symptomsDate',
  'textUpdates',
  'vaccinated',
  'vaccineDate',
  'vaccineDate2',
  'vaccineDoses',
  'vaccineProduct',
];
