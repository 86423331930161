/* eslint-disable @typescript-eslint/no-var-requires */
import { typography, colors } from '@everlywell/leaves';
import Button from 'components/Button';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex: 1 0 100%;
  margin-bottom: 28px;
  position: relative;
`;

const Input = styled.input`
  border: 1px solid ${colors.gray2};
  display: block;
  height: 48px;
  width: 100%;
  padding: 8px 11.4px 7.1px;
  background-color: ${colors.white};
  appearance: none;
  border-radius: 0;
  border-right: 0;
  color: ${colors.gray4};
  font-weight: ${typography.weight.regular};
  font-size: 16px;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0.65) 90%,
    rgba(0, 0, 0, 0) 100%
  );
  overflow: hidden;
`;

const UpdatedButton = styled(Button)`
  width: auto;
  max-width: 76px;
  font-weight: ${typography.weight.regular};
  letter-spacing: normal;
  line-height: 1.33;
  padding: 12px 18px 12px 11px;
  border-radius: 0 35px 35px 0;
  text-transform: capitalize;
  flex: 1 1 auto;
`;

export { Wrapper, UpdatedButton as Button, Input };
