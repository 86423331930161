import { useFlags } from 'launchdarkly-react-client-sdk';

import useUser from '../useUser';

const useEnterpriseProgramSlug = (): {
  enterpriseProgramSlug: string | null;
  testToTreatProgramSlug: string | null;
} => {
  const { enterpriseTestToTreat } = useFlags();
  const { user } = useUser();
  const enterpriseProgramSlug =
    user?.enterprise_attributes?.enterprise_telehealth_program_slug;
  const testToTreatProgramSlug =
    user?.enterprise_attributes?.test_to_treat_program_slug;

  if (
    !enterpriseTestToTreat ||
    !enterpriseProgramSlug ||
    !testToTreatProgramSlug
  ) {
    return { enterpriseProgramSlug: null, testToTreatProgramSlug: null };
  }

  return { enterpriseProgramSlug, testToTreatProgramSlug };
};

export default useEnterpriseProgramSlug;
