import { colors, H3, H4, Row, typography } from '@everlywell/leaves';
import styled from 'styled-components';

import { breakpoints } from './breakpoints';

type SubtitleProps = {
  subtitle: any;
};

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  margin: 50px 0;

  ${breakpoints.m} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  ${breakpoints.s} {
    margin: 0 0 50px;
  }
`;

const Title = styled(H3)`
  font-weight: ${typography.weight.regular};
`;

const Subtitle = styled(H4)<SubtitleProps>`
  position: absolute;
  left: ${({ subtitle: { position } }) => (position && position.x) || 0}px;
  top: ${({ subtitle: { position } }) => (position && position.y) || 0}px;

  ${breakpoints.m} {
    ${({ subtitle: { position } }) =>
      position && position.xm && `left: ${position.xm}px`};
    ${({ subtitle: { position } }) =>
      position && position.ym && `top: ${position.ym}px`};
  }

  ${breakpoints.s} {
    ${({ subtitle: { position } }) =>
      position && position.xs && `left: ${position.xs}px`};
    ${({ subtitle: { position } }) =>
      position && position.ys && `top: ${position.ys}px`};
    ${({ subtitle: { fontSize } }) =>
      fontSize && fontSize.s && `font-size: ${fontSize.s}`};
  }
`;

const Wrapper = styled(Row)`
  background: ${colors.teal1};
  box-sizing: border-box;
  padding: 60px 0;
  min-height: 777px;
  width: 100%;
  overflow: hidden;
  margin: 0;

  ${breakpoints.m} {
    min-height: 0;
  }

  ${breakpoints.s} {
    padding: 30px 0;
  }
`;

export { Card, Subtitle, Title, Wrapper };
