import {
  colors,
  size,
  Container,
  H3,
  typography,
  StarReviews,
  mediaQueries,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const OuterContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: ${size.lg}px 0 0 0;
  width: 100%;

  a:hover,
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
  }
  a {
    text-align: center;
  }
`;

export const InnerContainer = styled.div`
  display: inline-block;
  background-color: ${colors.teal5};
  gap: ${size.lg}px;
  border-radius: ${size.xs1}px;
  position: relative;

  ${mediaQueries.forDesktopUp} {
    display: flex;
    align-items: flex-start;
  }
`;

export const ImageContainer = styled.div`
  height: 15rem;
  width: 100%;
  position: relative;
  background-color: ${colors.teal2};
  border-top-left-radius: ${size.xs1}px;
  clip-path: circle(80% at 26% -4%);

  ${mediaQueries.forTabletVerticalUp} {
    height: 21rem;
    clip-path: circle(59% at 26% -4%);
  }

  ${mediaQueries.forTabletHorizontalUp} {
    height: 24rem;
  }

  ${mediaQueries.forDesktopUp} {
    height: 15rem;
    width: 50%;
    clip-path: circle(88% at 7% -16%);
  }

  ${mediaQueries.forBigDesktopUp} {
    clip-path: circle(89% at 7% -21%);
  }
`;

export const Image = styled.img`
  width: 24rem;
  position: absolute;
  left: -71px;
  top: -33px;

  ${mediaQueries.forTabletVerticalUp} {
    width: 31rem;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    width: 35rem;
  }

  ${mediaQueries.forDesktopUp} {
    /* Using vw for more responsiveness in this breakpoint */
    width: 21vw;
    left: -53px;
  }

  ${mediaQueries.forBigDesktopUp} {
    width: 21rem;
  }
`;

export const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: ${size.lg}px ${size.lg}px 0 ${size.lg}px;
  text-align: center;

  ${mediaQueries.forDesktopUp} {
    padding: ${size.lg}px 0;
  }
`;

export const CopyInnerContainer = styled.div`
  text-align: left;
`;

export const Title = styled(H3)`
  font-weight: ${typography.weight.bold};
  color: ${colors.white};
  margin: 0;

  ${mediaQueries.forDesktopUp} {
    /* overriding leaves H3 size based on design */
    font-size: ${size.lg}px;
    line-height: inherit;
  }
`;

export const Description = styled.div`
  ${typography.bodyText}
  color: ${colors.white};
  font-size: ${size.md}px;
`;

export const StarRatings = styled(StarReviews)`
  color: ${colors.white};
  margin: ${size.lg}px 0;

  > div {
    color: ${colors.white};
  }

  ${mediaQueries.forDesktopUp} {
    margin: ${size.lg}px 0 0 0;
  }
`;

export const AppStoreButtonContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  padding: 0 ${size.lg}px ${size.lg}px ${size.lg}px;

  ${mediaQueries.forDesktopUp} {
    padding: ${size.lg}px ${size.lg}px ${size.lg}px 0;
  }
`;
