import React from 'react';

import EditIcon from '../components/Icons/edit-icon';
import * as S from './FormSummary.styles';

export type FormSummaryProps = {
  /**
   * The main line of the summary. Uses a big font size
   */
  topLine?: string;
  /**
   * All other lines on the the top one with a smaller font size
   */
  secondaryLines?: string[];
  /**
   * Called when the edit button is called
   */
  onEditClick?: () => void;
};

/**
 * Summary of form data. Useful for forms that have a summary with and edit button before showing the actual form
 */
export default function FormSummary({
  topLine,
  secondaryLines,
  onEditClick,
}: FormSummaryProps) {
  return (
    <S.Container>
      <div>
        {topLine ? <S.FirstLine data-notranslate>{topLine}</S.FirstLine> : null}
        {secondaryLines
          ? secondaryLines?.map(
              (line) =>
                line && (
                  <S.SecondLine data-notranslate key={line}>
                    {line}
                  </S.SecondLine>
                ),
            )
          : null}
      </div>
      <S.EditButton onClick={onEditClick}>
        <S.EditIconWrapper>
          <EditIcon />
        </S.EditIconWrapper>
        Edit
      </S.EditButton>
    </S.Container>
  );
}
