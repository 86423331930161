import { size, colors, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Source = styled.div`
  ${typography.captionText};
  display: flex;
  align-items: center;
  color: ${colors.teal4};
  margin-bottom: ${size.md}px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
