import {
  getTelehealthAnswerGroups,
  getTelehealthForm,
  saveTelehealthForm,
  TelehealthFormAnswer,
} from 'common/apis/telehealthApis';
import useProgramSlug from 'common/hooks/useProgramSlug';
import useUser from 'common/hooks/useUser';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { FormType } from 'common/utils/types';
import Layout from 'components/Layout';
import FormBuilder from 'components/telehealth/FormBuilder/FormBuilder';
import { setDefaultValues } from 'pages/FormPageBuilder/utils';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ErrorPage from '../ErrorPage';
import DemographicsSkeleton from './Demographics.skeleton';
import * as S from './Demographics.styles';

export type DemographicsProps = {};

const FORM_SLUG = 'demographics';

/**
 * This is the Demographics page component that renders the
 * personal information form for the Telehealth flow.
 */
function Demographics(props: DemographicsProps) {
  const { data: userData, isLoading: isLoadingUserData } = useUser();
  const { programSlug } = useProgramSlug();
  const program = programSlug ?? '';

  /**
   * Load existing form answer data
   */
  const {
    data: response,
    isLoading: isLoadingAnswers,
    isSuccess: didFetchAnswerGroups,
    isError: isErrorAnswersGroup,
  } = useQuery(
    [
      'telehealth-form-answers-groups',
      {
        form_id: FORM_SLUG,
        program,
      },
    ],
    () =>
      getTelehealthAnswerGroups(
        {
          form_id: FORM_SLUG,
        },
        {
          program,
        },
      ),
    {
      enabled: !!program,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const formAlreadyCompleted = data.data?.[0]?.finished;

        if (formAlreadyCompleted) {
          goToNextScreen();
        }
      },
    },
  );

  const answerGroup = response?.data?.[0];
  const formAlreadyCompleted = !!answerGroup?.finished;

  /**
   * Load the form field configuration data
   * to build the form, only if the form has not
   * already been completed. Otherwise, redirect
   * to the payment type page link.
   */
  const {
    data,
    isLoading: isLoadingForm,
    isError: isErrorTelehealthForm,
  } = useQuery(
    ['telehealth-form', FORM_SLUG, { program }],
    () => getTelehealthForm(FORM_SLUG, { program }),
    {
      enabled: didFetchAnswerGroups && !formAlreadyCompleted && !!program,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        analytics.track({
          event: ANALYTICS.EVENTS.VIEWED_PAGE,
          data: {
            page: ANALYTICS.PAGES.ACCOUNT_HUB.DEMOGRAPHICS,
            program,
          },
        });
      },
    },
  );

  const navigate = useNavigate();

  /**
   * Determine which screen should be displayed next
   */
  const goToNextScreen = () => {
    // NOTE: It always redirect to the medical intake.
    // Medical intake page will take care of the redirection logic.

    navigate(`/virtual-care/medical-intake`);
  };

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'demographicsPage',
        programName: program,
      },
    });
  }, [program]);

  /**
   * Handle saving the form data
   * and redirecting to the payment type page
   * if successful.
   */
  const {
    mutate,
    isLoading: isSubmitting,
    isError: isErrorSubmitting,
  } = useMutation(saveTelehealthForm, {
    onSuccess: async () => {
      await analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: ANALYTICS.LABELS.VIRTUAL_CARE_FORMS.DEMOGRAPHICS_CONTINUE,
          program,
        },
      });
      goToNextScreen();
    },
  });

  const onSubmit = (answers: TelehealthFormAnswer[]) => {
    mutate({ form_id: FORM_SLUG, answers, program });
  };

  const isLoading = isLoadingForm || isLoadingAnswers || isLoadingUserData;

  const isError = isErrorTelehealthForm || isErrorAnswersGroup;

  if (isLoading) {
    return (
      <Layout>
        <S.Container>
          <DemographicsSkeleton />
        </S.Container>
      </Layout>
    );
  }

  if (isError) {
    // TODO: replace with new error page
    return <ErrorPage />;
  }
  //

  if (data?.data) {
    const { custom_modules } = data.data;
    const defaultValues = setDefaultValues(
      custom_modules,
      userData,
      'demographics' as FormType,
    );

    return (
      <Layout>
        <FormBuilder
          {...data.data}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          defaultValues={defaultValues}
          error={
            isErrorSubmitting
              ? { message: 'Unable to submit form. Please try again.' }
              : null
          }
        />
      </Layout>
    );
  }

  return null;
}

export default Demographics;
