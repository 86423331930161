import { ToastType } from '@everlywell/leaves';
import { Notification as NotificationType } from 'common/utils/types';

const NOTIFICATIONS = {
  INTAKE_COMPLETE_STORE_FAILURE: (source: string): NotificationType => ({
    id: 'COMPLETE_STORE_FAILURE',
    message: `There was a problem handling your request`,
    details: 'Please try again',
    type: ToastType.ERROR,
    persist: false,
    mixpanelLabel: 'IntakeFailure',
    source,
  }),
  INTAKE_PAYMENT_COMPLETE: (source: string): NotificationType => ({
    id: 'PAYMENT_COMPLETE',
    message: 'A confirmation email has been sent to you',
    details: 'Bundle details are in this email',
    type: ToastType.SUCCESS,
    persist: false,
    mixpanelLabel: 'IntakeFailure',
    source,
  }),
  INTAKE_STRIPE_INTENT_FAILURE: (source: string): NotificationType => ({
    id: 'STRIPE_INTENT_FAILURE',
    message: `There was a problem with your card`,
    details: 'Please try again',
    type: ToastType.ERROR,
    persist: false,
    mixpanelLabel: 'IntakeFailure',
    source,
  }),
};

export default NOTIFICATIONS;
