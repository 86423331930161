import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
  StripeElementChangeEvent,
  StripeElements,
  Stripe,
  StripeCardNumberElement,
  Token,
} from '@stripe/stripe-js';
import { logError } from 'common/utils/helpers';
import {
  fetchStripeToken,
  formatFormData,
  getCardElement,
} from 'common/utils/stripeHelpers';
import { ClientError } from 'components/Notification/NotificationContext';
import { isUndefined } from 'lodash-es';
import React, {
  Context,
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react';

import { StripeElementsErrors } from '../../CardDetailsStripe';

function useOnChangeStripeElementsValidation(): {
  stripeElementsErrors: StripeElementsErrors;
  onChangeStripeElementsValidation: (event?: StripeElementChangeEvent) => void;
} {
  const [stripeElementsErrors, setStripeElementsErrors] =
    useState<StripeElementsErrors>({});
  const onChangeStripeElementsValidation = (
    event?: StripeElementChangeEvent,
  ) => {
    if (isUndefined(event)) {
      return;
    }

    if (event.error) {
      setStripeElementsErrors((prev: any) => ({
        ...prev,
        [event.elementType]: event.error?.code,
        [event.error!.code]: { message: event.error?.message },
      }));
    } else {
      setStripeElementsErrors((prev: any) => {
        const el = prev[event.elementType];
        delete prev[el];
        delete prev[event.elementType];
        return Object.assign({}, prev);
      });
    }
  };
  return { stripeElementsErrors, onChangeStripeElementsValidation };
}

function checkStripeElementsOnError(
  stripe: Stripe | null,
  elements: StripeElements | null,
) {
  if (elements) {
    const cardInput = elements.getElement('cardNumber')!;
    try {
      fetchStripeToken(stripe, cardInput, {});
    } catch (e) {
      const error = e as ClientError;
      logError(error.message, error);
    }
  }
}

async function fetchToken(
  stripe: Stripe | null,
  elements: StripeElements | null,
  formData: Record<string, any>,
  customFormat?: (params: Record<string, any>) => {},
) {
  const cardElement: StripeCardNumberElement = await getCardElement(elements);

  const token: Token = await fetchStripeToken(
    stripe,
    cardElement,
    customFormat ? customFormat(formData) : formatFormData(formData),
  );

  return token;
}

export type StripeFormContextType = {
  checkStripeElementsOnError: () => void;
  elements: StripeElements | null;
  fetchToken: (
    params: any,
    customFormat?: (params: Record<string, any>) => {},
  ) => Promise<Token>;
  onChangeStripeElementsValidation: (event?: StripeElementChangeEvent) => void;
  stripe: Stripe | null;
  stripeElementsErrors: StripeElementsErrors;
};

const StripeFormContext: Context<StripeFormContextType | undefined> =
  createContext<StripeFormContextType | undefined>(undefined);

export const useStripeFormContext = () =>
  useContext(StripeFormContext) as StripeFormContextType;

type Props = { children: ReactNode };

interface StripeFormProviderProps extends Props {
  stripe: Promise<Stripe | null> | null;
}

export const StripeFormProvider: React.FC<StripeFormProviderProps> = ({
  stripe,
  children,
}) => (
  <Elements stripe={stripe}>
    <StripeFormInnerProvider>{children}</StripeFormInnerProvider>
  </Elements>
);

const StripeFormInnerProvider: React.FC<Props> = ({ children }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { stripeElementsErrors, onChangeStripeElementsValidation } =
    useOnChangeStripeElementsValidation();

  const value = {
    checkStripeElementsOnError: () =>
      checkStripeElementsOnError(stripe, elements),
    elements,
    fetchToken: (
      formData: Record<string, any>,
      customFormat?: ((params: Record<string, any>) => {}) | undefined,
    ) => fetchToken(stripe, elements, formData, customFormat),
    onChangeStripeElementsValidation,
    stripe,
    stripeElementsErrors,
  };

  return (
    <StripeFormContext.Provider value={value}>
      {children}
    </StripeFormContext.Provider>
  );
};
