/*
 * DashboardPageContainer
 */
import ODP from 'pages/OldDashboardPage';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getKitResults, getUserData, setNotification } from 'store/actions';
import {
  makeSelectLoadingResults,
  makeSelectSession,
  makeSelectInProgressKitResults,
  makeSelectCompletedKitResults,
  makeSelectUser,
} from 'store/selectors';

// @ts-ignore
const mapStateToProps = createStructuredSelector({
  session: makeSelectSession(),
  inProgressKitResults: makeSelectInProgressKitResults(),
  completedKitResults: makeSelectCompletedKitResults(),
  user: makeSelectUser(),
  loadingResults: makeSelectLoadingResults(),
});

const withConnect = connect(mapStateToProps, {
  getKitResults,
  getUserData,
  setNotification,
});

/**
 * @deprecated - This component is deprecated and will be removed in the future due
 * to the Account Hub work.
 */
const OldDashboardPage = compose<any>(withConnect)(ODP);
export default OldDashboardPage;
