import { getWeightMembershipData } from 'common/apis/weightCareMembershipApis';
import { WeightMembershipData } from 'common/utils/types';
import { useQuery } from 'react-query';

import { WeightMembershipTypes } from './types';

//TODO: update this file as needed once Weight Membership Data endpoint is ready
const useWeightMembership = (): WeightMembershipTypes => {
  const { data } = useQuery(
    ['weight-membership-data'],
    () => getWeightMembershipData,
  );
  const weightMembershipData = data as WeightMembershipData;

  return {
    weightMembershipData,
  };
};

export default useWeightMembership;
