import useProgramsBySlug from 'common/hooks/usePrograms';
import { showMultipleVariantsWcPlus } from 'common/utils/flags';
import { SubscriptionPlan } from 'common/utils/types';
import RadioButtonGroup from 'components/RadioButtonGroup';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import * as S from './VariantSelector.styles';

export type VariantSelectorProps = {
  slug: string;
  onSelect: (id: string) => void;
  hasVariantsCallback: (hasVariants: boolean) => void;
  isWMLiteUser: boolean;
  planSlug: string | null;
  setIntervalPrice: (price: string) => void;
};

function getBonusCreditsCallout(subscriptionPlans: SubscriptionPlan[]) {
  const subPlanWithMaxBonusCredit = _.maxBy(
    subscriptionPlans,
    'enrollment_credits_amount',
  );

  const subPlanWithMinBonusCredit = _.minBy(
    subscriptionPlans,
    'enrollment_credits_amount',
  );

  const enrollmentCreditDifference =
    _.toNumber(subPlanWithMaxBonusCredit?.enrollment_credits_amount) -
    _.toNumber(subPlanWithMinBonusCredit?.enrollment_credits_amount);

  if (enrollmentCreditDifference) {
    return `Best value - enjoy ${enrollmentCreditDifference} bonus credits!`;
  }
}

function annualSavingsCallout(annualSavingsAmount: number) {
  if (annualSavingsAmount > 0) {
    return `Best value - save $${annualSavingsAmount}`;
  }
}

export default function VariantSelector({
  slug,
  onSelect,
  hasVariantsCallback,
  isWMLiteUser,
  planSlug,
  setIntervalPrice,
}: VariantSelectorProps) {
  const hasMultipleVariantsFeature = showMultipleVariantsWcPlus();
  const [wasAutoSelected, setWasAutoSelected] = useState(false);

  const { data: programInfo } = useProgramsBySlug({
    slug,
  });

  const { subscription_plans } = programInfo.data;

  const hasOnlyOneVariant = subscription_plans?.length === 1;

  const subscriptionPlan = getFirstSubscriptionPlan(
    subscription_plans,
    planSlug,
  );

  useEffect(() => {
    hasVariantsCallback(!hasOnlyOneVariant);
  }, [hasOnlyOneVariant, hasVariantsCallback]);

  useEffect(() => {
    if (subscriptionPlan && !wasAutoSelected) {
      onSelect(subscriptionPlan.spree_variant_id.toString());
      setWasAutoSelected(true);
      setIntervalPrice(
        `$${subscriptionPlan.interval_price}/${subscriptionPlan.interval}`,
      );
    }
  }, [subscriptionPlan, onSelect, wasAutoSelected, setIntervalPrice]);

  // There are 3 reasons to hide the buttons:
  // 1. The feature flag is not enabled
  // 2. The program has only one variant
  // 3. The user is a WMLite user

  // TODO: Commented "the only 1 variant" validation, revisit and update when
  // there are designs. Original validation below.
  // const hideButtons =
  //   hasMultipleVariantsFeature && !hasOnlyOneVariant && !isWMLiteUser;

  const hideButtons = hasMultipleVariantsFeature && !isWMLiteUser;

  const bonusCreditsCallout = getBonusCreditsCallout(subscription_plans || []);

  return hideButtons ? (
    <S.Container>
      <RadioButtonGroup
        groupName="variantId"
        onSelect={(id) => onSelect(id)}
        selectedId={subscriptionPlan?.spree_variant_id.toString()}
        buttonData={
          subscription_plans?.map((plan, planIndex) => {
            const {
              spree_variant_id,
              interval_price,
              annual_savings,
              interval,
              description,
              promotional_price,
            } = plan;

            const calloutText =
              planIndex === 0
                ? bonusCreditsCallout || annualSavingsCallout(annual_savings)
                : undefined;

            return {
              id: spree_variant_id.toString(),
              mainText: !_.isNil(promotional_price)
                ? `$${promotional_price} first month`
                : `$${interval_price}/${interval}`,
              secondaryText: !_.isNil(promotional_price)
                ? `$${interval_price}/${interval} after with ${description}`
                : description,
              calloutText: calloutText,
            };
          }) || []
        }
      ></RadioButtonGroup>
    </S.Container>
  ) : null;
}

/*
 * This function is used to get the subscription plan for the plan slug
 * If the plan slug is not found, it will return the first subscription plan
 * @param subscription_plans - array of subscription plans
 * @param planSlug - slug of the plan
 */
const getFirstSubscriptionPlan = (
  subscription_plans: SubscriptionPlan[] | null | undefined,
  planSlug: string | null,
): SubscriptionPlan | undefined => {
  const formattedPlanSlug = planSlug?.toLowerCase();

  // Find the plan that matches the plan slug.
  const subPlan = formattedPlanSlug
    ? subscription_plans?.find((plan) => {
        // Plane name with spaces replaced with dashes and lowercased
        const planName = plan.name.replaceAll(' ', '-').toLowerCase();

        // Match by plan name or description
        return (
          planName.includes(formattedPlanSlug) ||
          plan.description.toLowerCase().includes(formattedPlanSlug)
        );
      })
    : undefined;

  return subPlan || subscription_plans?.[0];
};
