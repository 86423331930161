import { Accordion } from '@everlywell/leaves';
import { TelehealthMedication } from 'common/apis/telehealthApis';
import React from 'react';

import CardContent from './components/CardContent';
import CardHeader from './components/CardHeader';
import * as S from './PrescriptionCard.styles';

export type PrescriptionCardProps = {
  medication: TelehealthMedication;
};

/**
 * It displays all the medication info for a user.
 */
function PrescriptionCard({ medication }: PrescriptionCardProps) {
  return (
    <S.Container>
      <Accordion.Wrapper>
        <S.AccordionItem>
          <CardHeader medication={medication} />
          <Accordion.Child css={{ padding: 0 }}>
            <CardContent medication={medication} />
          </Accordion.Child>
        </S.AccordionItem>
      </Accordion.Wrapper>
    </S.Container>
  );
}

export default PrescriptionCard;
