import { FormValueType, FormValuesType, FormFieldType } from 'components/Forms';
import { isValidName } from 'components/Forms/Input/utils';
import {
  isValidPhoneNumber,
  isValidPhoneNumberOptional,
} from 'components/Forms/PhoneInput/utils';
import { isValidZipCode } from 'components/Forms/ZipCodeInput/utils';
import React, { useState, SyntheticEvent, useEffect } from 'react';
import validator from 'validator';

const HEADER_OFFSET = 112;

type FormErrorsType = {
  [key: string]: string;
};

type ChildProps = {
  onFieldChange: (formfield: FormFieldType) => void;
  onFieldValidate: (formfield: FormFieldType) => void;
  values: FormValuesType;
  errors: FormErrorsType;
};

type ValidationRule =
  | 'isRequired'
  | 'isDate'
  | 'isEmail'
  | 'isPhoneNumber'
  | 'isName';

type Validations = { rule: ValidationRule; error: string }[];

type Props = {
  initialValues?: {
    [key: string]: FormValueType;
  };
  initialErrors?: {
    [key: string]: string;
  };
  onSubmit: (values: FormValuesType) => void;
  children: (props: ChildProps) => React.ReactNode;
  validations?: {
    [key: string]: Validations;
  };
};

export const VALIDATION_RULES = {
  IS_REQUIRED: 'isRequired' as ValidationRule,
  IS_DATE: 'isDate' as ValidationRule,
  IS_PHONE_NUMBER: 'isPhoneNumber' as ValidationRule,
  IS_PHONE_NUMBER_OPTIONAL: 'isPhoneNumberOptional' as ValidationRule,
  IS_EMAIL: 'isEmail' as ValidationRule,
  IS_NAME: 'isName' as ValidationRule,
  IS_PRACTICE_NAME_OPTIONAL: 'isPracticeNameOptional' as ValidationRule,
  IS_ZIP_CODE: 'isZipCode' as ValidationRule,
};

function doesValuePassValidationRule(
  value: FormValueType,
  rule: ValidationRule,
) {
  switch (rule) {
    case VALIDATION_RULES.IS_REQUIRED:
      if (typeof value === 'undefined') return false;
      if (typeof value === 'string' && value.trim() === '') return false;
      return value !== '';
    case VALIDATION_RULES.IS_DATE:
      return typeof value === 'string' ? validator.isISO8601(value) : false;
    case VALIDATION_RULES.IS_EMAIL:
      return (
        (typeof value === 'string' &&
          (value === '' || validator.isEmail(value))) ||
        value === null
      );
    case VALIDATION_RULES.IS_PHONE_NUMBER:
      return typeof value === 'string' ? isValidPhoneNumber(value) : false;
    case VALIDATION_RULES.IS_PHONE_NUMBER_OPTIONAL:
      if (!value) return true;
      return typeof value === 'string'
        ? isValidPhoneNumberOptional(value)
        : false;
    case VALIDATION_RULES.IS_NAME:
      return typeof value === 'string' ? isValidName(value) : false;
    case VALIDATION_RULES.IS_PRACTICE_NAME_OPTIONAL:
      if (!value) return true;
      return typeof value === 'string' ? isValidName(value) : false;
    case VALIDATION_RULES.IS_ZIP_CODE:
      return typeof value === 'string' ? isValidZipCode(value) : false;
    default:
      return false;
  }
}

function findFirstErrorForValidations(
  validations: Validations,
  value: FormValueType,
): string | undefined {
  const failedValidation = validations?.find((validation) => {
    const { rule } = validation;

    return !doesValuePassValidationRule(value, rule);
  });

  if (failedValidation) {
    return failedValidation.error;
  }

  return undefined;
}

function Form(props: Props) {
  const {
    children,
    initialValues = {},
    initialErrors = {},
    onSubmit,
    validations = {},
  } = props;
  const [values, setValues] = useState<FormValuesType>(initialValues);
  const [errors, setErrors] = useState<FormErrorsType>(initialErrors);

  useEffect(() => {
    setErrors(initialErrors);
  }, [initialErrors]);

  function handleFieldChange(formfield: FormFieldType) {
    const { name, value } = formfield;

    setValues((previousValues: any) => ({
      ...previousValues,
      [name]: value,
    }));
  }

  function handleFieldValidate(formfield: FormFieldType) {
    const { name, value } = formfield;
    const updatedErrors = { ...errors };
    const error = findFirstErrorForValidations(validations[name], value);

    if (error) {
      updatedErrors[name] = error;
    } else {
      delete updatedErrors[name];
    }

    setErrors(updatedErrors);
  }

  function scrollToError(firstErrorElement: Element) {
    const topOfElement =
      window.pageYOffset +
      firstErrorElement.getBoundingClientRect().top -
      HEADER_OFFSET;

    window.scroll({ top: topOfElement, behavior: 'smooth' });
  }

  function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    const updatedErrors = Object.keys(validations).reduce(
      (acc: FormErrorsType, name) => {
        const error = findFirstErrorForValidations(
          validations[name],
          values[name],
        );
        if (error) {
          acc[name] = error;
        }
        return acc;
      },
      {},
    );

    setErrors(updatedErrors);

    if (!Object.keys(updatedErrors).length) {
      onSubmit(values);
    } else {
      const firstErrorFieldName = Object.keys(updatedErrors)[0];

      const firstErrorElement = Array.from(event.currentTarget.elements).find(
        // @ts-ignore
        (element) => element.name === firstErrorFieldName,
      );

      if (firstErrorElement) {
        scrollToError(firstErrorElement);
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      {children({
        values,
        errors,
        onFieldChange: handleFieldChange,
        onFieldValidate: handleFieldValidate,
      })}
    </form>
  );
}

export default Form;
