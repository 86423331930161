import { Col, Row } from '@everlywell/leaves';
import { TEST_INFORMATION_FORM as DATA_TEST } from 'common/utils/constants/dataTest';
import { ETHNICITY, RACE } from 'common/utils/constants/raceAndEthnicity';
import { isDateValid, useFormattedDate } from 'common/utils/helpers';
import { handleChange } from 'common/utils/registrationHelpers';
import { InputError, KitRegistrationUser } from 'common/utils/types';
import { subYears } from 'date-fns';
import React, { useState } from 'react';

import infoIcon from '../../assets/ui-icons-info.svg';
import * as S from '../styles';

type PersonalInformationProps = {
  user: KitRegistrationUser;
  invalidFields: string[];
  setInvalidFields: React.Dispatch<React.SetStateAction<string[]>>;
  isCovid: boolean;
  minimum_age: number;
  consumerContainsSex: boolean;
  consumerContainsDOB: boolean;
  kitGenderRestriction?: string;
};

const PersonalInformation = (props: PersonalInformationProps) => {
  const {
    user: {
      firstName,
      lastName,
      dateOfBirth,
      race,
      ethnicity,
      biologicalSex,
      pregnancyStatus,
    },
    invalidFields,
    setInvalidFields,
    isCovid,
    minimum_age,
    consumerContainsSex,
    consumerContainsDOB,
    kitGenderRestriction,
  } = props;
  const [dateError, setDateError] = useState<InputError>('');
  const [genderError, setGenderError] = useState<InputError>('');
  const formatDate = useFormattedDate(dateOfBirth.value);
  const genderAtBirthInvalidField = invalidFields.includes('biologicalSex');

  const handleDateInput = (value: string) => {
    const formattedDOB = formatDate(value);
    const error: InputError = isDateValid(
      formattedDOB,
      new Date(1000, 0, 1),
      subYears(new Date(), minimum_age),
      `You must be ${minimum_age} or older to take this test`,
    );
    setDateError(error);
    handleChange(dateOfBirth, formattedDOB, invalidFields, setInvalidFields);
    if (error) {
      setInvalidFields([...invalidFields, dateOfBirth.id]);
    }
  };
  const displayDateError = (
    error: InputError,
    invalidFields: string[],
  ): InputError | undefined => {
    if (error) {
      return error;
    }
    if (invalidFields.includes(dateOfBirth.id)) {
      return dateOfBirth.error ? dateOfBirth.error : undefined;
    }
    return undefined;
  };

  const handleGender = (value: string) => {
    const error: InputError =
      kitGenderRestriction &&
      kitGenderRestriction !== value &&
      kitGenderRestriction !== 'not_relevant'
        ? `Your assigned sex at birth must be ${kitGenderRestriction} in order to take this test.`
        : '';
    handleChange(biologicalSex, value, invalidFields, setInvalidFields);
    setGenderError(error);
    if (error && !invalidFields.includes(`${biologicalSex.id}-${value}`)) {
      setInvalidFields([...invalidFields, `${biologicalSex.id}-${value}`]);
    }
    const index = invalidFields.indexOf(`${biologicalSex.id}`);
    const removeInvalidField = invalidFields.splice(index, 1);
    return removeInvalidField;
  };

  return (
    <>
      <S.StyledInput
        label="First Name"
        id={firstName.id}
        value={firstName.value as string}
        name={firstName.id}
        inputClassName="inspectletIgnore"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleChange(
            firstName,
            e.target.value,
            invalidFields,
            setInvalidFields,
          );
        }}
        error={
          invalidFields.includes(firstName.id) ? firstName.error : undefined
        }
        data-test={`${DATA_TEST.INPUT}-${firstName.id}`}
      />
      <S.StyledInput
        label="Last Name"
        id={lastName.id}
        value={lastName.value}
        name={lastName.id}
        inputClassName="inspectletIgnore"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleChange(
            lastName,
            e.target.value,
            invalidFields,
            setInvalidFields,
          );
        }}
        error={invalidFields.includes(lastName.id) ? lastName.error : undefined}
        data-test={`${DATA_TEST.INPUT}-${lastName.id}`}
      />
      <S.StyledInput
        disabled={consumerContainsDOB}
        label="Date Of Birth (MM/DD/YYYY)"
        maxLength="10"
        id={dateOfBirth.id}
        value={dateOfBirth.value}
        name={dateOfBirth.id}
        className={dateOfBirth.id}
        inputClassName="inspectletIgnore"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleDateInput(e.target.value);
        }}
        error={displayDateError(dateError, invalidFields)}
        type="text"
        inputMode="numeric"
        data-test={`${DATA_TEST.INPUT}-${dateOfBirth.id}`}
        placeholder="MM/DD/YYYY"
      />
      {isCovid && race && ethnicity && (
        <>
          <S.PickerRow>
            <S.PickerCol xs={12} md={6}>
              <S.LabelContainer>
                <S.Label error={invalidFields.includes(race.id)}>Race</S.Label>
                <S.RaceEthnicityTooltip
                  animationSpeed="normal"
                  content="The United States Department of Health and Human Services (DHHS) requires this information to be reported"
                  position="top"
                  arrow="left"
                  tooltipBoxClass="tooltipBox"
                >
                  <S.Image
                    src={infoIcon}
                    alt="Information"
                    data-test={DATA_TEST.ICON}
                  />
                </S.RaceEthnicityTooltip>
              </S.LabelContainer>
              <S.ErrorDropdown
                items={Object.entries(RACE)
                  .map(([value, id]) => ({
                    value,
                    id,
                  }))
                  .concat([{ value: '', id: 'none' }])}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(
                    race,
                    e.target.value,
                    invalidFields,
                    setInvalidFields,
                  );
                }}
                className="inspectletIgnore"
                value={race.value}
                label="Race"
                hideLabel
                name={race.id}
                data-test={`${DATA_TEST.INPUT}-${race.id}`}
                error={invalidFields.includes(race.id)}
              />
              <S.ErrorText error={invalidFields.includes(race.id)}>
                Please select a value
              </S.ErrorText>
            </S.PickerCol>
            <S.PickerCol xs={12} md={6}>
              <S.LabelContainer>
                <S.Label error={invalidFields.includes(race.id)}>
                  Ethnicity
                </S.Label>
                <S.RaceEthnicityTooltip
                  animationSpeed="normal"
                  content="The United States Department of Health and Human Services (DHHS) requires this information to be reported"
                  position="top"
                  arrow="left"
                  tooltipBoxClass="tooltipBox"
                >
                  <S.Image
                    src={infoIcon}
                    alt="Information"
                    data-test={DATA_TEST.ICON}
                  />
                </S.RaceEthnicityTooltip>
              </S.LabelContainer>
              <S.ErrorDropdown
                items={Object.entries(ETHNICITY)
                  .map(([value, id]) => ({
                    value,
                    id,
                  }))
                  .concat([{ value: '', id: 'none' }])}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(
                    ethnicity,
                    e.target.value,
                    invalidFields,
                    setInvalidFields,
                  )
                }
                className="inspectletIgnore"
                value={ethnicity.value}
                hideLabel
                label={ethnicity.id}
                name={ethnicity.id}
                data-test={`${DATA_TEST.INPUT}-${ethnicity.id}`}
                error={invalidFields.includes(ethnicity.id)}
              />
              <S.ErrorText error={invalidFields.includes(ethnicity.id)}>
                Please select a value
              </S.ErrorText>
            </S.PickerCol>
          </S.PickerRow>
        </>
      )}
      <S.LabelContainer>
        <S.Label
          error={genderAtBirthInvalidField || genderError ? true : false}
        >
          Sex Assigned At Birth
        </S.Label>
        <S.BiologicalSexTooltip
          animationSpeed="normal"
          content={
            isCovid
              ? `The United States Department of Health and Human Services (DHHS) requires this information to be reported`
              : 'Sex assigned at birth affects how your results are reported'
          }
          position="top"
          arrow="center"
          tooltipBoxClass="tooltipBox"
        >
          <S.Image
            src={infoIcon}
            alt="Information"
            data-test={DATA_TEST.ICON}
          />
        </S.BiologicalSexTooltip>
      </S.LabelContainer>
      <Row>
        <Col xs={12} md={6}>
          <S.BorderRadio
            disabled={consumerContainsSex}
            id={`${biologicalSex.id}-female`}
            name={`${biologicalSex.id}-female`}
            label="Female"
            checked={biologicalSex.value === `female`}
            data-test={`${DATA_TEST.INPUT}-${biologicalSex.id}-female`}
            className="inspectletIgnore"
            value="female"
            error={
              genderAtBirthInvalidField ||
              invalidFields.includes(`${biologicalSex.id}-female`)
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleGender(e.target.value);
            }}
          />
        </Col>
        <Col xs={12} md={6}>
          <S.BorderRadio
            disabled={consumerContainsSex}
            id={`${biologicalSex.id}-male`}
            name={`${biologicalSex.id}-male`}
            label="Male"
            checked={biologicalSex.value === `male`}
            data-test={`${DATA_TEST.INPUT}-${biologicalSex.id}-male`}
            className="inspectletIgnore"
            value="male"
            error={
              genderAtBirthInvalidField ||
              invalidFields.includes(`${biologicalSex.id}-male`)
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleGender(e.target.value);
            }}
          />
        </Col>
        <Col xs={12} md={12}>
          <S.ErrorText error={genderError ? true : false} id="genderError">
            {genderError}
          </S.ErrorText>
          <S.ErrorText error={genderAtBirthInvalidField}>
            Please select a value
          </S.ErrorText>
        </Col>
      </Row>
      {isCovid && pregnancyStatus && biologicalSex.value === 'female' && (
        <>
          <S.LabelContainer>
            <S.Label error={invalidFields.includes('pregnancyStatus')}>
              Are you currently pregnant?
            </S.Label>
          </S.LabelContainer>
          <Row>
            <Col xs={12} md={6}>
              <S.BorderRadio
                error={invalidFields.includes('pregnancyStatus')}
                id={`${pregnancyStatus.id}-yes`}
                name={`${pregnancyStatus.id}-yes`}
                label="Yes"
                checked={pregnancyStatus.value === 'yes'}
                data-test={`${DATA_TEST.INPUT}-pregnancyStatus-yes`}
                className="inspectletIgnore"
                onChange={() =>
                  handleChange(
                    pregnancyStatus,
                    'yes',
                    invalidFields,
                    setInvalidFields,
                  )
                }
              />
            </Col>
            <Col xs={12} md={6}>
              <S.BorderRadio
                error={invalidFields.includes('pregnancyStatus')}
                id={`${pregnancyStatus.id}-no`}
                name={`${pregnancyStatus.id}-no`}
                label="No"
                checked={pregnancyStatus.value === 'no'}
                data-test={`${DATA_TEST.INPUT}-pregnancyStatus-no`}
                className="inspectletIgnore"
                onChange={() =>
                  handleChange(
                    pregnancyStatus,
                    'no',
                    invalidFields,
                    setInvalidFields,
                  )
                }
              />
            </Col>
            <Col xs={12} md={6}>
              <S.ErrorText error={invalidFields.includes('pregnancyStatus')}>
                Please select a value
              </S.ErrorText>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default PersonalInformation;
