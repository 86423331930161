import {
  colors,
  typography,
  Label as LeavesLabel,
  RadioButton as RadioButtonLeaves,
  size,
} from '@everlywell/leaves';
import styled, { css } from 'styled-components';

import { labelTextCSSbyVariant } from '../../utils/styles';
import { FormBuilderVariant } from '../../utils/types';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Label = styled(LeavesLabel)<{
  hasError: boolean;
  variant: FormBuilderVariant;
}>`
  ${({ hasError }) =>
    hasError &&
    css`
      color: ${colors.red3};
    `}
  width: 100%;
  ${({ variant }) => labelTextCSSbyVariant(variant)}
`;

export const Error = styled.span`
  ${typography.errorText};
  font-weight: ${typography.weight.regular};
`;

export const RadioButton = styled(RadioButtonLeaves)`
  margin: ${size.xs1}px 0;
`;
