import {
  ConsentFormFieldsData,
  TelehealthCustomModule,
  TelehealthFormAnswer,
} from 'common/apis/telehealthApis';
import { User } from 'common/utils/types';

import {
  BILLING_AND_ASSIGNMENT,
  PRIVACY_NOTICE,
  RESULTS_SHARING_CONSENT,
  TELEHEALTH_CONSENT,
} from './constants';

export const setDefaultValues = (
  custom_modules: TelehealthCustomModule[],
  user: User | undefined | null,
): Record<string, string> =>
  custom_modules.reduce<Record<string, string>>((acc, { id, mod_type }) => {
    if (mod_type === 'date') {
      acc[id] = new Date().toISOString().split('T')[0];
    } else if (id === 'signature' && mod_type === 'text' && user) {
      acc[id] = `${user.first_name} ${user.last_name}`;
    }
    return acc;
  }, {});

export const parseFormData = (
  formData: TelehealthFormAnswer[],
): ConsentFormFieldsData => {
  const values = formData
    .filter((field) => field?.type !== 'read_only' && field?.type !== 'label')
    .reduce((acc, field) => {
      if (
        field?.custom_module_id === 'consents' &&
        field?.type === 'checkbox'
      ) {
        const values = field.answer as string;

        const data: Omit<ConsentFormFieldsData, 'signature'> = {
          billing_and_assignment: values.includes(BILLING_AND_ASSIGNMENT),
          privacy_notice: values.includes(PRIVACY_NOTICE),
          results_sharing_consent: values.includes(RESULTS_SHARING_CONSENT),
          telehealth_consent: values.includes(TELEHEALTH_CONSENT),
        };
        return {
          ...acc,
          ...data,
        };
      }
      return { ...acc, ...{ [field.custom_module_id]: field.answer } };
    }, {});

  return values as ConsentFormFieldsData;
};
