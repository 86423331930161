import { TelehealthCustomModule } from 'common/apis/telehealthApis';
import Grid from 'components/Grid/Grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';

import * as S from './LabelField.styles';

export type LabelFieldProps = TelehealthCustomModule;

export const HEADER_TAG = '[header]';

type TitleProps = {
  label: string | null;
};
const Title = ({ label }: TitleProps) => {
  const { showDynamicFormHeaders } = useFlags<{
    showDynamicFormHeaders: boolean;
  }>();
  const shouldRenderAsHeader = label?.includes(HEADER_TAG);

  if (shouldRenderAsHeader && !showDynamicFormHeaders) {
    return null;
  } else if (shouldRenderAsHeader && showDynamicFormHeaders) {
    return <S.Title>{label?.split(HEADER_TAG)}</S.Title>;
  } else {
    return <S.Container>{label}</S.Container>;
  }
};

/**
 * Renders simple text by using single line.
 */
function LabelField(props: LabelFieldProps) {
  return (
    <Grid.Item width={[1]}>
      <Title label={props.label} />
    </Grid.Item>
  );
}

export default LabelField;
