import {
  size,
  colors,
  H2,
  typography,
  Button,
  mediaQueries,
} from '@everlywell/leaves';
import styled, { css } from 'styled-components';

const containerStyles = css`
  width: 100%;
  max-width: 535px;
  margin: auto;
  padding: ${size.xl3}px ${size.md}px;
  overflow-x: clip;

  ${mediaQueries.forTabletVerticalUp} {
    padding: ${size.xl4}px ${size.lg}px;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${containerStyles}
`;

export const IconContainer = styled.img`
  width: 128px;
  height: 128px;
`;

export const Header = styled(H2)`
  text-align: center;
  margin-bottom: ${size.xl1}px;
`;

export const Text = styled.p`
  ${typography.h4Text};
  font-weight: ${typography.weight.bold};
  margin: 0;
`;

export const AppointmentsButton = styled(Button)`
  width: 100%;
  margin: ${size.xl1}px 0;

  ${mediaQueries.forTabletVerticalUp} {
    margin: ${size.xl3}px 0;
  }
`;

export const AddToCalendarWrapper = styled.div`
  margin-top: ${size.lg}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  ${mediaQueries.forTabletVerticalUp} {
    width: 60%;
  }
`;

export const AddToCalendarButton = styled(Button)`
  width: 100%;
  border: 1px solid ${colors.green2};
  margin: ${size.sm}px 0;

  img {
    margin: 0 ${size.xs1}px ${size.xs3}px 0;
  }
`;

export const AppPromoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.green1};
  width: 100%;
  text-align: center;
  padding: ${size.xl2}px 0;
  div {
    ${mediaQueries.forTabletVerticalUp} {
      width: 532px;
    }
  }
`;

export const Copy = styled.div`
  ${typography.bodyText};
  width: 100%;
  padding: 0 ${size.lg}px;
  margin: ${size.sm}px 0 ${size.xl1}px;
`;

export const AppImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  img:nth-child(1) {
    margin-left: -${size.md}px;
    align-self: flex-start;
    margin-top: auto;
    ${mediaQueries.forTabletVerticalUp} {
      margin-right: ${size.xl2}px;
      margin-left: 0;
    }
  }

  img:nth-child(2) {
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: ${size.md}px;
    ${mediaQueries.forTabletVerticalUp} {
      margin-right: 0;
    }
  }

  img:nth-child(3) {
    align-self: flex-end;
    margin-top: ${size.xl4}px;
    margin-bottom: auto;
    ${mediaQueries.forTabletVerticalUp} {
      margin-left: ${size.xl4}px;
    }
  }

  ${mediaQueries.forTabletVerticalUp} {
    justify-content: center;
  }
`;

export const AppStoreLink = styled.a`
  img {
    margin-top: ${size.md}px;
  }
`;
