/**
 *
 * IntensityResultDetail
 *
 */

import { Localize } from '@everlywell/leaves';
import { Col_10 as Col10 } from 'common/styles/grid-styles';
import consolidateFSReactivityGroups from 'common/utils/consolidateFSReactivityGroups';
import {
  GROUPING_METHODS,
  SCROLL_TO_RESULTS,
  FS_LAB,
  TOTAL_ABNORMAL_DESCRIPTOR,
} from 'common/utils/constants';
import { CALLOUT as CALLOUT_DATA_TEST } from 'common/utils/constants/dataTest';
import { formatStringSeparator } from 'common/utils/formatText';
import { generateIntensityResultProps } from 'common/utils/resultGenerator';
import { KitResult, FoodSensitivityGroup, Grouping } from 'common/utils/types';
import Callout from 'components/Callout';
import _ from 'lodash';
import pluralize from 'pluralize';
import React from 'react';

import IntensityMarkerGroupCard from '../IntensityMarkerGroupCard';
import SegmentedControl from '../SegmentedControl';
import * as S from './styles';

export type Props = {
  kitResult?: KitResult;
  controlGroups?: Grouping[];
};

type State = {
  groupBy: string;
  normalReactivityIsExpanded: boolean;
};

/* eslint-disable react/prefer-stateless-function */
class IntensityResultDetail extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.renderIntensityResults = this.renderIntensityResults.bind(this);
    this.state = {
      groupBy: GROUPING_METHODS.REACTIVITY,
      normalReactivityIsExpanded: this.isResultInitiallyExpanded(),
    };
  }

  isResultInitiallyExpanded = () => {
    const marker_results = this.props.kitResult?.marker_results;
    if (marker_results) {
      return (
        marker_results.filter((r) => r.descriptor === 'very low reactivity')
          .length === marker_results.length
      );
    }

    return false;
  };

  handleGroupChange = (value: string) => {
    this.setState({ groupBy: value });
  };

  renderMarkerGroupHeadingContainer = (
    reactivity: string,
    totalMarkers: number,
    markerType: string,
    severityIndexes: number[] | undefined,
  ) => {
    const FAL_KIT_TYPE = 'food-allergy-test';
    const { kitResult } = this.props;
    const isFAL = kitResult && kitResult.test?.type === FAL_KIT_TYPE;
    const abnormalDescriptor = isFAL
      ? 'food'
      : TOTAL_ABNORMAL_DESCRIPTOR[markerType];

    const isNormal = reactivity === 'normal reactivity';
    let headingSuffix = '';
    if (isNormal) {
      headingSuffix = this.state.normalReactivityIsExpanded
        ? '-expanded'
        : '-closed';
    }
    const dataTest = formatStringSeparator(
      `heading ${reactivity.toLowerCase()}${headingSuffix}`,
    );

    const getSeverityLabel = () => {
      if (!severityIndexes) return '';

      if (severityIndexes.length && severityIndexes.length === 1) {
        const severity = severityIndexes.map((severityIndex, index) => {
          if (index < severityIndexes.length - 1) {
            const indexLabel = severityIndex;
            return indexLabel;
          }
          return `(Class ${severityIndex}): `;
        });
        return severity;
      }

      const firstItem = severityIndexes[0];
      const lastItem = severityIndexes[severityIndexes.length - 1];

      return `(Class  ${firstItem} - ${lastItem}): `;
    };

    if (!totalMarkers)
      return (
        <S.MarkerGroupHeadingContainer>
          <S.MarkerGroupHeading isHidden={false} data-test={dataTest}>
            <S.GroupName>{reactivity} </S.GroupName>
            <S.LineBreak />
            <S.GroupName>{getSeverityLabel()}</S.GroupName>
            <Localize
              name="IntensityResultDetail-normalReactivityTotalMarkers"
              pluralize={totalMarkers}
            >
              {totalMarkers}
            </Localize>{' '}
            {_.capitalize(pluralize(abnormalDescriptor, totalMarkers))}
          </S.MarkerGroupHeading>
        </S.MarkerGroupHeadingContainer>
      );

    const normalReactivity =
      reactivity === 'normal reactivity' ||
      reactivity === 'very low reactivity';
    const reactivityLevel = reactivity.replace(' reactivity', '');
    const normalReactivityHeading = normalReactivity ? (
      <S.MarkerGroupHeading
        isHidden={this.state.normalReactivityIsExpanded}
        data-test={dataTest}
      >
        Your results were {reactivityLevel} for the rest of the{' '}
        {pluralize(abnormalDescriptor)} in the test
      </S.MarkerGroupHeading>
    ) : null;
    return (
      <S.MarkerGroupHeadingContainer>
        {normalReactivityHeading}
        <S.MarkerGroupHeading
          isHidden={normalReactivity && !this.state.normalReactivityIsExpanded}
          data-test={dataTest}
        >
          <S.GroupName>{reactivity} </S.GroupName>
          <S.LineBreak />
          <S.GroupName>{getSeverityLabel()}</S.GroupName>
          <Localize
            name="IntensityResultDetail-normalReactivityTotalMarkers"
            pluralize={totalMarkers}
          >
            {totalMarkers}
          </Localize>{' '}
          {_.capitalize(pluralize(abnormalDescriptor, totalMarkers))}
        </S.MarkerGroupHeading>
      </S.MarkerGroupHeadingContainer>
    );
  };

  showNormalReactivity = () =>
    this.setState({
      normalReactivityIsExpanded: true,
    });

  // Placeholder function to generate the facade
  renderIntensityResults = (groupBy?: string) => {
    const { kitResult } = this.props;

    if (!kitResult) return null;

    const { marker_results, markers, test } = kitResult;

    if (!marker_results || !markers || !test) return null;
    if (!test.marker_types || !test.marker_types.length) {
      return null;
    }
    const markerType = test.marker_types[0];

    const lab = FS_LAB[test.type];

    let isScrollIdSet = false;

    return consolidateFSReactivityGroups(
      generateIntensityResultProps(marker_results, markers, lab, groupBy),
    ).map((fsGroup: FoodSensitivityGroup, index: number) => {
      const groupedMarkersCount = fsGroup.groupedMarkers.length;
      let groupId = '';

      if (groupedMarkersCount && !isScrollIdSet) {
        isScrollIdSet = true;
        groupId = SCROLL_TO_RESULTS;
      }

      return (
        <div
          key={index.toString()}
          id={groupId}
          data-id={index.toString()}
          data-test={formatStringSeparator(
            `group ${fsGroup.title.toLowerCase()}`,
          )}
        >
          {this.renderMarkerGroupHeadingContainer(
            fsGroup.title,
            groupedMarkersCount,
            markerType,
            fsGroup.includedSeverityIndexes,
          )}

          <IntensityMarkerGroupCard
            key={index.toString()}
            markers={markers}
            markerResults={fsGroup.groupedMarkers}
            groupedByCategory={
              this.state.groupBy === GROUPING_METHODS.FOOD ||
              this.state.groupBy === GROUPING_METHODS.CATEGORY
            }
            groupTitle={fsGroup.title}
            showNormalReactivity={this.showNormalReactivity}
            normalReactivityIsExpanded={this.state.normalReactivityIsExpanded}
            test={test}
            markerType={markerType}
          />
        </div>
      );
    });
  };

  buildControlList = () => {
    const { controlGroups } = this.props;
    if (controlGroups) {
      return controlGroups.map((group: any) => ({
        label: group.grouping_type,
        value: group.grouping_type,
        event_tracking: true,
      }));
    }
    return [];
  };

  render() {
    const { kitResult } = this.props;

    // Food Allergy Test only validation to hide grouping menu
    const FAL_KIT_TYPE = 'food-allergy-test';

    const { FAQ_TOOLTIP_3_DESCRIPTION } = kitResult?.test?.content ?? {};

    const hasTooltipAccordion = !!FAQ_TOOLTIP_3_DESCRIPTION;

    const isFAL = kitResult && kitResult.test?.type === FAL_KIT_TYPE;

    if (!kitResult) return null;
    const controlList = this.buildControlList();
    // Turn this into a snippet. This only relates to IOA,
    controlList.unshift({
      label: 'Reactivity',
      value: GROUPING_METHODS.REACTIVITY,
      event_tracking: true,
    });
    return (
      <Col10>
        {!isFAL ? (
          <SegmentedControl
            controlList={controlList}
            onChange={this.handleGroupChange}
          />
        ) : null}

        {this.renderIntensityResults(this.state.groupBy)}

        {isFAL && hasTooltipAccordion ? (
          <Callout
            dataTestId={CALLOUT_DATA_TEST.CONTAINER}
            description={FAQ_TOOLTIP_3_DESCRIPTION}
            productName={kitResult?.test?.name}
          />
        ) : null}
      </Col10>
    );
  }
}

export default IntensityResultDetail;
