/**
 * https://date-fns.org/v2.30.0/docs/getDay#returns
 * getDay returns:
 * 0 | 1 | 2 | 3 | 4 | 5 | 6
 * the day of week, 0 represents Sunday
 *
 * dayOfWeek returns eg:
 * dayOfWeek[1] => Monday
 *
 */
export const dayOfWeek = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednsday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};
