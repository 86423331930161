import React from 'react';

import Illustration from './illustration.svg';
import * as S from './PageHeader.styles';

export type PageHeaderProps = {};

/**
 * This page header displays the headline for the page and the back button
 */
function PageHeader(props: PageHeaderProps) {
  return (
    <div css={S.container}>
      <S.VirtualCareLink />
      <div css={S.contentContainer}>
        <S.Heading as="h1">Medication</S.Heading>
        <img src={Illustration} alt="" css={S.image} />
      </div>
    </div>
  );
}

export default PageHeader;
