import { colors, mediaQueries, typography } from '@everlywell/leaves';
import { bodyCopy } from 'common/styles/app-styles';
import { hexToRGB } from 'common/utils/helpers';
import styled, { css } from 'styled-components';

type IconProps = {
  height: number;
  width: number;
};

type Props = {
  showMarkerValue?: boolean;
  show?: boolean;
};

export const badgeLabel = css`
  ${bodyCopy};
  margin: 0;
  color: ${colors.gray4};
`;

const ReactivityBadgeContainer = styled.div<Props>`
  padding: 16px;
  border-radius: 4px;
  border: solid 1px rgba(${hexToRGB(colors.gray2)}, 0.5);
  width: 100%;
  background-color: ${colors.white};
  ${mediaQueries.forTabletVerticalUp} {
    width: ${(props) => (props.showMarkerValue ? '272px' : '250px')};
  }
`;

const ReactivityBadgeBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MarkerInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Icon = styled.img<IconProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: 50%;
`;

const TitleContainer = styled.div`
  padding: 0 8px;
`;

const LabelName = styled.p`
  ${badgeLabel};
  font-weight: ${typography.weight.light};
`;

const RangeName = styled.p`
  ${badgeLabel};
  font-weight: ${typography.weight.bold};
  text-transform: capitalize;
`;

const MarkerValue = styled.div<Props>`
  font-size: 28px;
  font-weight: ${typography.weight.xbold};
  color: ${colors.gray4};
  line-height: 48px;
  letter-spacing: 0.09px;
  display: ${(props) => (props.show ? 'block' : 'none')};
`;

const Container = styled.div`
  ${mediaQueries.forTabletVerticalUp} {
    margin: 24px 0;
  }
`;

export {
  ReactivityBadgeContainer,
  ReactivityBadgeBody,
  MarkerInfo,
  Icon,
  TitleContainer,
  LabelName,
  RangeName,
  MarkerValue,
  Container,
};
