import { breakpoints } from '@everlywell/leaves';
import useMediaQuery from 'common/hooks/useMediaQuery';
import { sanitize } from 'common/utils/domHelpers';
import { MarkerResult } from 'common/utils/types';
import { startCase } from 'lodash';
import React from 'react';

import * as S from './styles';

const BREAKPOINT = `(max-width: ${breakpoints.forTabletVerticalDown}px)`;

export type Props = {
  isClassStyle: boolean;
  markerResult: MarkerResult;
};

export const MarkerBadgeLabel = (props: Props) => {
  const { isClassStyle, markerResult } = props;
  const { descriptor, severity_index, value } = markerResult;

  const isMobile = useMediaQuery(BREAKPOINT);

  const createLabelComponent = (text: string) => `<span>${text}</span>`;

  const getClassLabel = (descriptor: string) => {
    let plain = descriptor.toLowerCase().trim();
    return startCase(plain);
  };

  const getRegularLabel = () => {
    const label = value ? value.toString().replace(/ +/g, '') : `${value}`;
    return createLabelComponent(label);
  };

  const getClassStyleLabel = () => {
    let text = descriptor ? getClassLabel(descriptor) : `${severity_index}`;

    if (isMobile) {
      text = `Class ${severity_index} </br> ${text}`;
    }

    return createLabelComponent(text);
  };

  const label = isClassStyle ? getClassStyleLabel() : getRegularLabel();

  return (
    <S.MakerBadgeContent
      dangerouslySetInnerHTML={{ __html: sanitize(label) }} // Using this to handle custom HTML formats on the label
    />
  );
};
