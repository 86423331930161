import { ImmutableReduxState } from 'common/utils/types';
import Header from 'components/Header';
import { connect } from 'react-redux';
import { makeSelectUserId, makeSelectAuthenticated } from 'store/selectors';

type Props = {
  authenticated: boolean;
  userId: number;
};

function mapStateToProps(state: ImmutableReduxState): Props {
  return {
    authenticated: makeSelectAuthenticated()(state),
    userId: makeSelectUserId()(state),
  };
}

export default connect(mapStateToProps)(Header);
