import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { WHATS_NEXT_SECTION } from 'common/utils/constants/dataTest';
import { sanitize } from 'common/utils/domHelpers';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import React from 'react';

import * as S from './styles';

type Item = {
  alt: string;
  iconSrc: string;
  text: string;
};

export type Props = {
  header: string;
  description: string;
  items: Item[];
  iconSrc: string;
  alt: string;
  testName: string;
  url: string;
  productName: string;
  buttonText: string;
};

function ProductUpsell(props: Props) {
  const {
    iconSrc,
    alt,
    header,
    description,
    items = [],
    testName,
    url,
    productName,
    buttonText,
  } = props;

  const containerEl = useScrolledIntoViewOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: 'Upsell widget',
        page: ANALYTICS.PAGES.KIT_RESULT,
        product: productName,
      },
    });
  });

  const trackingData = {
    label: 'View product',
    category: 'Upsell widget',
    product: productName,
    resultsProduct: testName,
  };

  function formatList() {
    return items.map((item: Item, index: number) => (
      <S.ListItem key={item.alt + index.toString()}>
        <S.ListIcon src={item.iconSrc} alt={item.alt} aria-hidden="true" />
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: sanitize(item.text) }} />
      </S.ListItem>
    ));
  }

  return (
    <S.Container ref={containerEl}>
      <S.IconWrapper>
        <S.Icon src={iconSrc} alt={alt} />
      </S.IconWrapper>
      <S.DescriptionWrapper>
        <S.Header dangerouslySetInnerHTML={{ __html: sanitize(header) }} />
        <S.Description
          dangerouslySetInnerHTML={{ __html: sanitize(description) }}
        />
      </S.DescriptionWrapper>
      <S.ActionWrapper>
        {items.length > 0 && (
          <S.List data-test={WHATS_NEXT_SECTION.PRODUCT_UPSELL_LIST}>
            {formatList()}
          </S.List>
        )}
        <S.LearnMoreButton
          appearance={'secondary'}
          data-test={WHATS_NEXT_SECTION.PRODUCT_UPSELL_BUTTON}
          href={url}
          target="_blank"
          trackingData={trackingData}
        >
          {buttonText || 'Learn More'}
        </S.LearnMoreButton>
      </S.ActionWrapper>
    </S.Container>
  );
}

export default ProductUpsell;
