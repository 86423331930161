import { size, H5, colors, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

export const VisitSummaryCard = styled.div`
  padding: ${size.xl1}px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 7px 30px -10px rgba(220, 228, 229);
  border-radius: ${size.xs1}px;
  height: fit-content;
  margin: ${size.xl1}px 10px;
  background-color: ${colors.white};

  ${mediaQueries.forTabletVerticalUp} {
    margin: 0;
    border-radius: 0 8px 8px 0;
  }
`;

export const VisitSummaryHeader = styled(H5)`
  margin: 0 0 8px;
  min-width: 280px;
`;

export const VisitSummaryContent = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.25px;
  font-feature-settings: 'ss01' on;
`;
