import DBADX1 from '../assets/sample-collection-tips-illustrations-db-adx-1.svg';
import DBADX2 from '../assets/sample-collection-tips-illustrations-db-adx-2.svg';
import DBADX3 from '../assets/sample-collection-tips-illustrations-db-adx-3.svg';
import DBADX4 from '../assets/sample-collection-tips-illustrations-db-adx-4.svg';

const DBADXSteps = [
  {
    id: '1',
    image: DBADX1,
    alt:
      'Write your name, date of birth, and collection date on the card. The lab cannot process your sample if this step is skipped.',
    description:
      'Write your name, date of birth, and collection date on the card. The lab cannot process your sample if this step is skipped.',
  },
  {
    id: '2',
    image: DBADX2,
    alt:
      'You will be collecting two samples on one card in the same session. Drip 4-5 large, free-falling blood drops into the collection squares on the left.',
    description:
      'You will be collecting two samples on one card in the same session. Drip 4-5 large, free-falling blood drops into the collection squares on the left.',
  },
  {
    id: '3',
    image: DBADX3,
    alt:
      'Continue adding drops until the blood reaches the designated line on the right. Blood will soak through and naturally expand. Repeat these steps on the second square.',
    description:
      'Continue adding drops until the blood reaches the designated line on the right. Blood will soak through and naturally expand. Repeat these steps on the second square.',
  },
  {
    id: '4',
    image: DBADX4,
    alt:
      'Once blood expands to the first line on the right hand side of the card, let sample air dry for at least 2 hours.',
    description:
      'Once blood expands to the first line on the right hand side of the card, let sample air dry for at least 2 hours.',
  },
];

export { DBADXSteps };
