import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import React from 'react';

import * as S from './styles';

type Props = {
  trackingUrl: string;
  text: string;
  testId?: number;
};

const TrackSampleButton = ({ trackingUrl, text, testId }: Props) =>
  trackingUrl ? (
    <S.Button
      appearance="tertiary"
      href={trackingUrl}
      // @ts-ignore
      target="_blank"
      onClick={() => {
        analytics.track({
          event: ANALYTICS.EVENTS.CLICKED_BUTTON,
          data: {
            label: `Track Kit`,
            test_id: testId,
            category: 'Results Dashboard',
          },
        });
      }}
    >
      {text}
    </S.Button>
  ) : null;

export default TrackSampleButton;
