import { TelehealthCarePlanOrderTypes } from 'common/apis/telehealthApis';
import { CarePlanInfoCardProps } from 'components/telehealth/CarePlan/components/CarePlanInfoCard';

export const ORDER_TYPE_MAPPING: Record<
  TelehealthCarePlanOrderTypes,
  { iconName: CarePlanInfoCardProps['iconName']; text: string }
> = {
  patient_info_orders: {
    iconName: 'Info',
    text: 'Patient Information',
  },
  lab_orders: {
    iconName: 'FileText',
    text: 'Lab Orders',
  },
  other_orders: {
    iconName: 'TextAlignLeft',
    text: 'Other',
  },
  prescription_orders: {
    iconName: 'Prescription',
    text: 'Prescription',
  },
  vaccine_orders: {
    iconName: 'Syringe',
    text: 'Vaccines',
  },
  referral_orders: {
    iconName: 'ExcludeSquare',
    text: 'Procedures',
  },
  consult_orders: {
    iconName: 'ArrowSquareUpRight',
    text: 'Consult Referrals',
  },
  imaging_orders: {
    iconName: 'UserFocus',
    text: 'Imaging',
  },
};
