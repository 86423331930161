import {
  H3 as BaseH3,
  Button as LeavesButton,
  Dropdown,
  Input as BaseInput,
  colors,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

type InputProps = {
  bottomInput?: boolean;
};

type DropdownWrapperProps = {
  isSelected: boolean;
};

type Props = {
  align?: string;
};

export const Input = styled(BaseInput)<InputProps>`
  svg {
    display: none;
    fill: none;
    stroke-dasharray: 100;
    stroke-dashoffset: 0;
    stroke-width: 5;
  }

  ${(props) => {
    if (props.bottomInput) {
      return 'margin-bottom: 0;';
    }
  }}
`;

export const DropdownWrapper = styled(Dropdown)<DropdownWrapperProps>`
  padding-bottom: 0;
  & select {
    color: ${(props) => (props.isSelected ? colors.gray4 : colors.gray3)};
  }
`;

export const Wrapper = styled.div`
  margin-bottom: ${size.xl1}px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xl4}px;
  }
`;

export const ToastWrapper = styled.div`
  & > div {
    max-width: 600px;
    width: calc(100% - ${size.xl1}px);

    ${mediaQueries.forTabletVerticalUp} {
      width: 65%;
      margin: ${size.md}px auto;
    }
  }
`;

export const Button = styled(LeavesButton)`
  width: 100%;
`;

export const OrderWrapper = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: ${size.md}px;

  ${mediaQueries.forTabletHorizontalUp} {
    padding: ${size.lg}px;
  }
`;
export const Container = styled.div`
  width: 100%;

  ${mediaQueries.forDesktopUp} {
    margin: auto;
  }
`;

export const DescriptionWrapper = styled.div`
  margin-bottom: ${size.xl1}px;

  ${mediaQueries.forDesktopUp} {
    margin-bottom: ${size.lg}px;
  }
`;

export const Caption = styled.p`
  ${typography.captionText}
  margin-bottom: ${size.lg}px;

  ${mediaQueries.forDesktopUp} {
    font-size: 18px;
    margin-bottom: ${size.md}px;
  }
`;

export const H3 = styled(BaseH3)<Props>`
  font-size: ${size.lg}px;
  margin-top: 0;
  text-align: ${(props) => props.align || 'left'};

  ${mediaQueries.forDesktopUp} {
    font-size: ${size.xl1}px;
  }
`;

export const MarketingOptInLabel = styled.div`
  ${typography.bodyTextSmall};
  a {
    color: ${colors.green4};
    font-weight: ${typography.weight.bold};
    text-decoration: none;
  }
`;
