import { SkeletonLoader, SkeletonUnit, size } from '@everlywell/leaves';
import Grid from 'components/Grid';
import React from 'react';

const CreditCardFormSkeleton = () => (
  <Grid.Container spacing={['lg']}>
    <Grid.Item width={[1]}>
      <SkeletonLoader
        width={{
          value: 200,
          unit: SkeletonUnit.Pixel,
        }}
        height={{
          value: Number(size.xl3),
          unit: SkeletonUnit.Pixel,
        }}
      />
    </Grid.Item>
    <Grid.Item width={[1]}>
      <SkeletonLoader
        width={{
          value: 100,
          unit: SkeletonUnit.Percentage,
        }}
        height={{
          value: Number(size.xl3),
          unit: SkeletonUnit.Pixel,
        }}
      />
    </Grid.Item>
    <Grid.Item width={[1]} css={{ marginBottom: `${size.xl6}px` }}>
      <SkeletonLoader
        width={{
          value: 200,
          unit: SkeletonUnit.Pixel,
        }}
        height={{
          value: Number(size.xl3),
          unit: SkeletonUnit.Pixel,
        }}
      />
    </Grid.Item>

    <Grid.Item width={[1 / 3, 1 / 3]}>
      <SkeletonLoader
        width={{
          value: 100,
          unit: SkeletonUnit.Percentage,
        }}
        height={{
          value: Number(size.xl3),
          unit: SkeletonUnit.Pixel,
        }}
      />
    </Grid.Item>

    <Grid.Item width={[2 / 3, 2 / 3]}>
      <SkeletonLoader
        width={{
          value: 100,
          unit: SkeletonUnit.Percentage,
        }}
        height={{
          value: Number(size.xl3),
          unit: SkeletonUnit.Pixel,
        }}
      />
    </Grid.Item>
  </Grid.Container>
);

export default CreditCardFormSkeleton;
