/**
 * Skeleton loader for Settings form
 */

import { colors, size } from '@everlywell/leaves';
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import * as S from './styles';

const subtitleColor = colors.gray1;
const TextHeight = `${size.lg}px`;
// Note: this color is not in the styleguide on purpose
const subtitleHighlightColor = '#e8e8e8';

function HeaderParagraphSkeleton() {
  return (
    <>
      <S.UpperParagraphWrapper>
        <SkeletonTheme
          highlightColor={subtitleHighlightColor}
          baseColor={subtitleColor}
        >
          <S.Text>
            <Skeleton height={TextHeight} />
          </S.Text>
          <S.Text>
            <Skeleton height={TextHeight} />
          </S.Text>
          <S.Text>
            <Skeleton height={TextHeight} />
          </S.Text>
        </SkeletonTheme>
      </S.UpperParagraphWrapper>
      <S.LowerParagraphWrapper>
        <SkeletonTheme
          highlightColor={subtitleHighlightColor}
          baseColor={subtitleColor}
        >
          <S.Text>
            <Skeleton height={TextHeight} />
          </S.Text>
          <S.Text>
            <Skeleton height={TextHeight} />
          </S.Text>
        </SkeletonTheme>
      </S.LowerParagraphWrapper>
    </>
  );
}

export { HeaderParagraphSkeleton };
