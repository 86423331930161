/* eslint-disable no-lonely-if */
import { TEST_WITH_TIMED_MARKERS } from 'common/utils/constants/features';
import {
  KitResult,
  MarkerWithResult,
  GroupedMarkers,
  NumericalResult,
} from 'common/utils/types';

const collectionRegEx = /\(([a-zA-Z]+)\)/; // match string b/w parentheses
const collectionMap: { [key: string]: number } = {
  Morning: 1,
  Noon: 2,
  Evening: 3,
  Night: 4,
};

export const makeGroupedMarkersKitResult = (
  kitResult: KitResult,
): GroupedMarkers => {
  const { markers, marker_results: markerResults, lab, test } = kitResult;
  const MarkerGroup: NumericalResult = {};
  const groupedMarkers: GroupedMarkers = {
    outOfRange: [] as NumericalResult[],
    inRange: [] as NumericalResult[],
    lab,
  };

  if (markerResults && markers) {
    markerResults.forEach((marker_result) => {
      const marker = markers.find(
        (markerItem) => markerItem.id === marker_result.marker_id,
      );

      if (marker) {
        // only if this is Everlywell DX (id: 42)
        if (
          (lab && lab.id === 42) ||
          (test && test.id && TEST_WITH_TIMED_MARKERS.includes(test.id))
        ) {
          // only if the marker name is formatted with the collection time in the title
          // i.e. Creatinine (Morning), Creatinine (Noon)
          if (collectionRegEx.test(marker.name)) {
            const nameAndCollectionTime = marker.name.split(' (');
            const markerName = nameAndCollectionTime[0];
            const collectionTimeMatch = collectionRegEx.exec(marker.name) || [];
            const collectionTime = collectionTimeMatch[1];
            const markerWithResult1 = {
              ...marker,
              name: markerName,
              order: marker_result.observation_datetime
                ? undefined
                : collectionMap[collectionTime],
              marker_result,
            };

            if (MarkerGroup[markerName]) {
              MarkerGroup[markerName].push(markerWithResult1);
            } else {
              MarkerGroup[markerName] = [markerWithResult1];
            }
          } else {
            const name = marker.name.split(' (')[0];
            const markerWithResult = { ...marker, name, marker_result };
            if (MarkerGroup[marker.name]) {
              MarkerGroup[marker.name].push(markerWithResult);
            } else {
              MarkerGroup[marker.name] = [markerWithResult];
            }
          }
        } else {
          const markerWithResult = { ...marker, marker_result };
          if (MarkerGroup[marker.name]) {
            MarkerGroup[marker.name].push(markerWithResult);
          } else {
            MarkerGroup[marker.name] = [markerWithResult];
          }
        }
      }
    });
  }

  Object.keys(MarkerGroup).forEach((key) => {
    const filteredResult = MarkerGroup[key].filter(
      (result: MarkerWithResult) =>
        result.severities[result.marker_result.severity_index] !== 1,
    );

    const resultSet = { [key]: MarkerGroup[key] };

    if (filteredResult.length) {
      groupedMarkers.outOfRange.push(resultSet);
    } else {
      groupedMarkers.inRange.push(resultSet);
    }
  });

  return groupedMarkers;
};
