import React from 'react';

import CarePlanLayout from './components/CarePlanLayout';

/**
 * Care plan that shows users information and actions
 * related to the telehealth experience
 */
const CarePlanPage = () => <CarePlanLayout />;

export default CarePlanPage;
