import { colors, mediaQueries, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: flex-start;
  padding: ${size.md}px ${size.md}px 0;
  margin: ${size.xl1}px 10px ${size.xl3}px 10px;

  ${mediaQueries.forTabletVerticalUp} {
    padding: 0;
    margin: 0 0 ${size.xl3}px 0;
  }
`;

export const Header = styled.p`
  margin-bottom: 6px;
  font-size: 18px;
  line-height: ${size.lg}px;
  letter-spacing: 0.5px;
  color: ${colors.teal4};
  font-weight: ${typography.weight.book};
`;

export const Content = styled.p`
  ${typography.bodyTextSmall}
  color: ${colors.gray4};
`;
