import { updatedMountWithTheme as mount } from 'common/utils/testHelpers';
import React from 'react';

import AncestryFooter from '../index';

const props = {
  testId: 1,
};

describe('<AncestryFooter />', () => {
  it('should render correctly', () => {
    const renderedComponent = mount(<AncestryFooter {...props} />);
    expect(renderedComponent.exists()).toEqual(true);
  });
});
