import { Col } from '@everlywell/leaves';
import { CollectionInstructionType } from 'common/utils/types';
import { StepsIconType } from 'components/Steps/model';
import React from 'react';

import CollectionMethodSteps from './CollectionSteps';
import * as S from './MailingSample/styles';

type Props = {
  collectionInstructions: CollectionInstructionType[];
};

const CollectionInstruction = ({ collectionInstructions }: Props) => {
  if (!collectionInstructions.length) {
    return null;
  }

  return (
    <S.Wrapper center="xs" middle="xs">
      <Col xs={10} md={12} lg={8}>
        <S.Title>Before mailing your sample, make sure to:</S.Title>

        <CollectionMethodSteps
          collectionMethodsList={collectionInstructions}
          icon={StepsIconType.check}
        />
      </Col>
    </S.Wrapper>
  );
};
export default CollectionInstruction;
