import {
  typography,
  H3 as LeavesH3,
  Button as LeavesButton,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Description = styled.p`
  ${typography.bodyTextSmall}
  font-weight: ${typography.weight.light};
`;

export const H3 = styled(LeavesH3)`
  margin: 0 !important;
`;

export const Button = styled(LeavesButton)`
  width: 100%;
`;
