import { Localize, localizeUtils } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { format, formatDistance, parseISO } from 'date-fns';
import React from 'react';

import * as S from './styles';

type Props = {
  viewedAt?: string;
  number: string;
  testId?: number;
  productName: string;
  results_approved_at?: string | undefined;
  category?: string;
};

const ViewResultSection = ({
  viewedAt,
  number,
  testId,
  productName,
  results_approved_at,
  category,
}: Props) => {
  const timeAgo =
    results_approved_at &&
    formatDistance(new Date(results_approved_at), new Date(), {
      addSuffix: true,
    });

  const localizedTimeAgo = localizeUtils.wrapTimeAgoInLocalizeVar(
    timeAgo || '',
    'KitCardViewResultSection-timeAgo',
  );
  const shortDate =
    results_approved_at && format(parseISO(results_approved_at), 'MM/dd/yy');
  return (
    // @ts-ignore
    <S.ViewResultsSection viewedAt={viewedAt}>
      {results_approved_at ? (
        <S.TextSection>
          <S.CollectionText>
            Results Released{' '}
            <Localize name="KitCardViewResultSection-shortDate">
              {shortDate}
            </Localize>
          </S.CollectionText>
          <S.TimeText>{localizedTimeAgo}</S.TimeText>
        </S.TextSection>
      ) : null}
      <S.ViewButton
        data-test="view-results-button"
        data-is-viewed={!!viewedAt}
        appearance={viewedAt ? 'secondary' : 'primary'}
        href={`/results/${number}`}
        onClick={() => {
          analytics.track({
            event: ANALYTICS.EVENTS.CLICKED_BUTTON,
            data: {
              label: ANALYTICS.LABELS.VIEW_RESULTS,
              'Test ID': testId,
              'Test Name': productName,
              category,
            },
          });
          return false;
        }}
      >
        View
        <S.HideOnMobile> Results</S.HideOnMobile>
      </S.ViewButton>
    </S.ViewResultsSection>
  );
};

export default ViewResultSection;
