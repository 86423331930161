import {
  buildTelehealthDateOfBirthModule,
  buildTelehealthDropdownModule,
  buildTelehealthLabelModule,
  buildTelehealthLocationModule,
  buildTelehealthPhoneModule,
  buildTelehealthRadioFieldGroup,
  buildTelehealthReadOnlyModule,
  buildTelehealthTextField,
} from 'common/testUtils/builders/telehealthForm';
import { stateNameToAbbreviation } from 'common/utils/constants/states';
import { nameValuePairsToDropdownOptionLowerCase } from 'common/utils/helpers';

const states = Object.keys(stateNameToAbbreviation);

const FORM_HEADING_PREFIX = 'ApptRegistrationForm-HeadingSection';
const buildFormHeading = [
  buildTelehealthLabelModule({
    id: `${FORM_HEADING_PREFIX}-header`,
    label: '[header] Appointment registration',
  }),
  buildTelehealthReadOnlyModule({
    id: `${FORM_HEADING_PREFIX}-copy`,
    options_array: [
      '<p>Please update your information. Read more about our <a target="_blank" rel="noreferrer" href="https://www.everlyhealth.com/privacy-policy/">Data Privacy Policy.</a></p>',
    ],
    custom_module_condition: null,
  }),
];

const buildPersonalInfoSection = [
  buildTelehealthLabelModule({
    id: `PersonalInfoSection`,
    label: '[header] Personal information',
  }),
  buildTelehealthTextField({
    id: `first_name`,
    label: 'First name',
    required: true,
  }),
  buildTelehealthTextField({
    id: `middle_name`,
    label: 'Middle name (Optional)',
    required: false,
  }),
  buildTelehealthTextField({
    id: `last_name`,
    label: 'Last name',
    required: true,
  }),
  buildTelehealthDropdownModule({
    id: `sex`,
    label:
      'Legal sex (This should match the legal sex listed on your government-issued ID)',
    sublabel: 'Please select your legal sex',
    options_array: [
      nameValuePairsToDropdownOptionLowerCase('Male'),
      nameValuePairsToDropdownOptionLowerCase('Female'),
    ],
    required: true,
  }),
  buildTelehealthDateOfBirthModule({
    id: `dob`,
    label: 'Date of birth',
    required: true,
  }),
  buildTelehealthDropdownModule({
    id: `maritalstatus`,
    label: 'Marital status',
    sublabel: 'Please select an option',
    options_array: [
      nameValuePairsToDropdownOptionLowerCase('Married'),
      nameValuePairsToDropdownOptionLowerCase('Single'),
      nameValuePairsToDropdownOptionLowerCase('Divorced'),
      nameValuePairsToDropdownOptionLowerCase('Separated'),
      nameValuePairsToDropdownOptionLowerCase('Widowed'),
      nameValuePairsToDropdownOptionLowerCase('Partner'),
    ],
    required: true,
  }),
];

export const ADDRESS_SECTION_ID = 'AddressSection';
const buildAddressFormSection = [
  buildTelehealthLabelModule({
    label: '[header] Residence address',
  }),
  buildTelehealthLocationModule({
    id: ADDRESS_SECTION_ID,
    required: true,
  }),
];

const buildAppointmentInfoSectionForm = [
  buildTelehealthLabelModule({
    id: `ApptInfoSection`,
    label: '[header] Appointment information',
  }),
  buildTelehealthDropdownModule({
    id: `current_state_location`,
    label:
      "What state will you be located in during your appointment? (We'll use the state you list here to match you with providers licensed in your state)",
    sublabel: 'Please select an option',
    options_array: states,
    required: true,
  }),
  buildTelehealthTextField({
    id: `email`,
    label: 'Email address',
    required: true,
  }),
];

const buildTellUsAboutYourselfSection = [
  buildTelehealthLabelModule({
    id: `TellUsAboutYourselfSection`,
    label: '[header] Tell us about yourself',
  }),
  buildTelehealthDropdownModule({
    id: `language`,
    label: 'Language (Optional)',
    sublabel: 'Select your preferred language',
    options_array: [
      nameValuePairsToDropdownOptionLowerCase('Arabic'),
      nameValuePairsToDropdownOptionLowerCase('Chinese'),
      nameValuePairsToDropdownOptionLowerCase('English'),
      nameValuePairsToDropdownOptionLowerCase('Polish'),
      nameValuePairsToDropdownOptionLowerCase('Portuguese'),
      nameValuePairsToDropdownOptionLowerCase('Russian'),
      nameValuePairsToDropdownOptionLowerCase('Somali'),
      nameValuePairsToDropdownOptionLowerCase('Spanish'),
      nameValuePairsToDropdownOptionLowerCase('Vietnamese'),
      nameValuePairsToDropdownOptionLowerCase('Choose not to disclose'),
    ],
    required: false,
  }),
  buildTelehealthRadioFieldGroup({
    id: `race`,
    label: 'Race (Optional)',
    options_array: [
      'White',
      'Black or African American',
      'American Indian or Alaska Native',
      'Asian',
      'Native Hawaiian or Other Pacific Islander',
      'Other Race',
      'Choose not to disclose',
    ],
    required: false,
  }),
  buildTelehealthDropdownModule({
    id: `ethnicity`,
    label: 'Ethnicity (Optional)',
    sublabel: 'Select your ethnicity',
    options_array: [
      nameValuePairsToDropdownOptionLowerCase('Hispanic or Latino'),
      nameValuePairsToDropdownOptionLowerCase('Not Hispanic or Latino'),
      nameValuePairsToDropdownOptionLowerCase('Choose not to disclose'),
    ],
    required: false,
  }),
  buildTelehealthDropdownModule({
    id: `sex_assigned_at_birth`,
    label: 'Sex assigned at birth (Optional)',
    sublabel: 'Select your sex assigned at birth',
    options_array: [
      nameValuePairsToDropdownOptionLowerCase('Male'),
      nameValuePairsToDropdownOptionLowerCase('Female'),
      nameValuePairsToDropdownOptionLowerCase('Choose not to disclose'),
    ],
    required: false,
  }),
  buildTelehealthDropdownModule({
    id: `gender_identity`,
    label: 'Gender identity (Optional)',
    sublabel: 'Select your gender identity',
    options_array: [
      nameValuePairsToDropdownOptionLowerCase('Identifies as male'),
      nameValuePairsToDropdownOptionLowerCase('Identifies as female'),
      nameValuePairsToDropdownOptionLowerCase('Transgender male'),
      nameValuePairsToDropdownOptionLowerCase('Transgender female'),
      { name: 'Gender non-conforming', value: 'gender_non_conforming' },
      nameValuePairsToDropdownOptionLowerCase('Choose not to disclose'),
    ],
    required: false,
  }),
  buildTelehealthDropdownModule({
    id: `sexual_orientation`,
    label: 'Sexual orientation (Optional)',
    sublabel: 'Select your sexual orientation',
    options_array: [
      nameValuePairsToDropdownOptionLowerCase('Straight'),
      nameValuePairsToDropdownOptionLowerCase('Gay or Lesbian'),
      nameValuePairsToDropdownOptionLowerCase('Bisexual'),
      nameValuePairsToDropdownOptionLowerCase('Choose not to disclose'),
    ],
    required: false,
  }),
];

const buildFormContactInformationSection = [
  buildTelehealthLabelModule({
    id: `ContactInformationSection`,
    label: '[header] Contact Information',
  }),
  buildTelehealthPhoneModule({
    id: `home_phone`,
    label: 'Home phone number',
    required: true,
  }),
  buildTelehealthPhoneModule({
    id: `mobile_phone`,
    label: 'Mobile phone number',
    required: false,
  }),
  buildTelehealthRadioFieldGroup({
    id: `consent_to_text`,
    label: 'Agree to text messages (for appointment reminders)',
    options_array: ['Yes', 'No'],
    required: true,
  }),
  buildTelehealthRadioFieldGroup({
    id: `consent_to_call`,
    label:
      'Agree to call (for appointment reminders and messages about your care)',
    options_array: ['Yes', 'No'],
    required: true,
  }),
];

export const customModules = [
  ...buildFormHeading,
  ...buildPersonalInfoSection,
  ...buildAddressFormSection,
  ...buildAppointmentInfoSectionForm,
  ...buildTellUsAboutYourselfSection,
  ...buildFormContactInformationSection,
];
