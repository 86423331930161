import {
  SkeletonLoader,
  SkeletonShape,
  SkeletonUnit,
} from '@everlywell/leaves';
import React, { memo } from 'react';

import * as S from '../styles';

const heightSkeleton = 60;
const widthSkeleton = 100;
const durationSkeleton = 1;
type Props = {
  numberOfLoaders: number;
};
export const HealthProfileLoader = memo(({ numberOfLoaders }: Props) => {
  const arr = Array.from({ length: numberOfLoaders });

  return (
    <S.SkeletonContainer>
      {arr.map((_, index) => (
        <S.SkeletonWrapper key={index}>
          <SkeletonLoader
            height={{
              value: heightSkeleton,
              unit: SkeletonUnit.Pixel
            }}
            width={{
              value: widthSkeleton,
              unit: SkeletonUnit.Percentage
            }}
            duration={durationSkeleton}
            shape={SkeletonShape.Rectangle}
          />
        </S.SkeletonWrapper>
      ))
      }
    </S.SkeletonContainer>
  );
});
