import { Button, Localize } from '@everlywell/leaves';
import { getTelehealthProviders } from 'common/apis/telehealthSchedulingApis';
import { useCommonT2TAnalytics } from 'common/hooks/useCommonT2TAnalytics';
import useUser from 'common/hooks/useUser';
import Grid from 'components/Grid';
import LoadingError from 'components/LoadingError';
import { isArray } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import FloatingCtaFooter from '../../components/FloatingCtaFooter';
import { useNativeSchedulingContext } from '../../context/NativeSchedulingContext';
import { actions } from '../../state/scheduling-flow.state';
import { usePrefetchAppointmentTimes } from '../AppointmentTimePage';
import ProviderRadioOption from './components/ProviderRadioOption';
import * as track from './ProviderSelection.analytics';
import ProviderSelectionSkeleton from './ProviderSelection.skeleton';
import * as S from './ProviderSelection.styles';

export type ProviderSelectionProps = {};

type FormValues = {
  provider: string;
};

/**
 * This is a page that represents a step in the appointment scheduling flow
 * where the user selects a provider.
 */
function ProviderSelection(props: ProviderSelectionProps) {
  const { commonT2TAnalyticsData } = useCommonT2TAnalytics();

  const { dispatch, state, getNextStep } = useNativeSchedulingContext();

  const program = state.selectedProgram;
  const selectedAppointmentTypeId = state.selectedAppointmentTypeId;

  const navigate = useNavigate();

  const { register, handleSubmit, errors, watch } = useForm<FormValues>({
    defaultValues: {
      provider:
        state.selectedProvider === null || isArray(state.selectedProvider)
          ? 'no_preference'
          : String(state.selectedProvider),
    },
  });

  const { user } = useUser();

  const {
    data: telehealthProvidersResponse,
    isError,
    refetch,
  } = useQuery(['telehealth-providers'], () =>
    getTelehealthProviders({ programSlug: program }),
  );

  const providers = telehealthProvidersResponse?.data;

  // sort providers so that providers with future availabilities are at the top
  const sortedProviders = providers?.sort((a, b) => {
    if (a.has_future_availabilities > b.has_future_availabilities) return -1;
    if (a.has_future_availabilities === b.has_future_availabilities) return 0;
    return 1;
  });

  const selectedProvider = watch('provider');

  /**
   * Prefetch the current month of appointment times when the user selects a provider.
   */

  // filter so only providers with future availabilities are prefecthed
  const providersWithAvailability = providers?.filter(
    (provider) => provider.has_future_availabilities === true,
  );

  const providersForAppointmentTimes =
    selectedProvider === 'no_preference'
      ? providersWithAvailability?.map((provider) => +provider.id) ?? null
      : +selectedProvider;

  const { prefetchSpecifiedMonth } = usePrefetchAppointmentTimes({
    selectedProgram: program,
    selectedProvider: providersForAppointmentTimes,
    selectedAppointmentTypeId: selectedAppointmentTypeId,
  });

  const prefetchAppointmentTimes = useCallback(() => {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();

    prefetchSpecifiedMonth(month, year);
  }, [prefetchSpecifiedMonth]);

  useEffect(() => {
    if (providersForAppointmentTimes !== null) {
      prefetchAppointmentTimes();
    }
  }, [prefetchAppointmentTimes, providersForAppointmentTimes]);

  /**
   * Handle saving the user's input and navigating to the next step.
   */
  const handleSaveInput = (values: FormValues) => {
    const provider =
      values.provider === 'no_preference'
        ? providersWithAvailability!.map((provider) => +provider.id)
        : +values.provider;
    dispatch(actions.setSelectedProvider(provider));

    track.whenUserClicksContinue({
      program,
      extraParams: { ...commonT2TAnalyticsData },
    });

    const nextStep = getNextStep();
    navigate(nextStep ?? '/dashboard');
  };

  useEffect(() => {
    if (providers) {
      track.whenUserViewsPage({
        program,
        extraParams: {
          ...commonT2TAnalyticsData,
        },
      });
    }
  }, [commonT2TAnalyticsData, program, providers]);

  if (isError) {
    return (
      <S.Container>
        <LoadingError handleRetry={refetch} />
      </S.Container>
    );
  }

  return (
    <S.Container>
      <Grid.Container spacing={['lg']}>
        <Grid.Item width={[1]}>
          <S.Heading>Select your provider</S.Heading>
        </Grid.Item>

        {user?.health_provider_user_state && (
          <Grid.Item width={[1]}>
            <S.SubHeading>
              Providers available in{' '}
              <S.UserState>
                <Localize name="provider-state">
                  {user.health_provider_user_state}
                </Localize>
              </S.UserState>
              .
            </S.SubHeading>
          </Grid.Item>
        )}

        <Grid.Item width={[1]}>
          {sortedProviders ? (
            <S.Form onSubmit={handleSubmit(handleSaveInput)}>
              <Grid.Container spacing={['lg']}>
                <Grid.Item width={[1]}>
                  <ProviderRadioOption
                    ref={register({ required: 'Please select a provider' })}
                    name="provider"
                    label={'First available'}
                    value={'no_preference'}
                    subLabel={'Show all providers'}
                  />
                </Grid.Item>
                {sortedProviders.map((provider) => (
                  <Grid.Item width={[1]} key={provider.id}>
                    <ProviderRadioOption
                      ref={register({ required: 'Please select a provider' })}
                      name="provider"
                      label={provider.name}
                      value={provider.id}
                      subLabel={provider.credentials.join(', ')}
                      disabled={!provider.has_future_availabilities}
                    />
                  </Grid.Item>
                ))}
                {errors.provider && (
                  <Grid.Item width={[1]}>
                    <S.ErrorText>{errors.provider.message}</S.ErrorText>
                  </Grid.Item>
                )}
              </Grid.Container>

              <FloatingCtaFooter
                backButton={
                  <Button
                    type="button"
                    appearance="secondary"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>
                }
                nextButton={<Button type="submit">Continue</Button>}
              />
            </S.Form>
          ) : (
            <ProviderSelectionSkeleton />
          )}
        </Grid.Item>
      </Grid.Container>
    </S.Container>
  );
}

export default ProviderSelection;
