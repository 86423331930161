import { Button } from '@everlywell/leaves';
import BaseCard, { BaseCardIcon } from 'pages/AccountHub/components/BaseCard';
import React from 'react';

import ErrorIcon from './error-icon.svg';
import * as S from './ErrorCard.styles';

export type ErrorCardProps = {
  onRetry: () => void;
};

/**
 * Error card component used for account hub UI.
 */
function ErrorCard({ onRetry }: ErrorCardProps) {
  return (
    <BaseCard
      image={<BaseCardIcon src={ErrorIcon} alt="" />}
      content={{
        title: "Sorry, we're currently experiencing technical issues.",
        description: (
          <>
            Please try again. If the issue continues, contact{' '}
            <S.CustomLink
              href="https://support.everlywell.com/"
              target="_blank"
            >
              Customer Experience
            </S.CustomLink>
            .
          </>
        ),
      }}
      cta={
        <Button appearance="secondary" onClick={onRetry}>
          Try again
        </Button>
      }
    />
  );
}

export default ErrorCard;
