import { Col } from '@everlywell/leaves';
import { COVID_SCREENER_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';
import { COVID_SCREENER_STEPS } from 'common/utils/constants/rumActions';
import { KitRegistrationUser } from 'common/utils/types';
import useTimeTracker from 'common/utils/useTimeTracker';
import React from 'react';

import BaseQuestion from '../../../BaseQuestion';
import * as S from '../styles';

interface Props {
  user: KitRegistrationUser;
  totalQuestions: number;
  questionNumber: number;
  handleNext: (action: any) => void;
  handleBack: (action: any) => void;
}

const MultiQuestions = ({
  user,
  totalQuestions,
  questionNumber,
  handleNext,
  handleBack,
}: Props) => {
  const fireAction = useTimeTracker(COVID_SCREENER_STEPS.MULTI_QUESTIONS);
  const handleButtonDisabled = () =>
    user.firstCovidTest.value === '' ||
    user.healthcareSetting.value === '' ||
    user.congregateSetting.value === '';
  const firstCovidTestAnswers = [
    {
      id: 'yes-first-covid-test',
      label: 'Yes',
    },
    {
      id: 'no-first-covid-test',
      label: 'No',
    },
  ];
  const healthcareSettingAnswers = [
    {
      id: 'yes-healthcare-setting',
      label: 'Yes',
    },
    {
      id: 'no-healthcare-setting',
      label: 'No',
    },
  ];
  const congregateSettingAnswers = [
    {
      id: 'yes-congregate-setting',
      label: 'Yes',
    },
    {
      id: 'no-congregate-setting',
      label: 'No',
    },
  ];
  const congregateSubText = (
    <S.CongregateSettingDescription>
      (e.g. nursing homes, residential care, psychiatric treatment facilities,
      group homes, board and care homes, homeless shelter, foster care or other
      setting)
    </S.CongregateSettingDescription>
  );
  return (
    <Col>
      <S.BackButton onClick={() => handleBack(fireAction)}>
        <S.LeftCaretIcon /> Back
      </S.BackButton>

      <BaseQuestion
        question="Is this your first COVID-19 test (PCR, antigen, or antibody)?"
        questionNumber={questionNumber}
        totalQuestions={totalQuestions}
        answers={firstCovidTestAnswers}
        value={user.firstCovidTest.value}
        setValue={user.firstCovidTest.setValue}
        className="first-covid-test"
      />
      <BaseQuestion
        question="Are you employed in a healthcare setting?"
        questionNumber={questionNumber + 1}
        totalQuestions={totalQuestions}
        answers={healthcareSettingAnswers}
        value={user.healthcareSetting.value}
        setValue={user.healthcareSetting.setValue}
        className="healthcare-setting"
      />
      <BaseQuestion
        question="Do you live or work in a congregate setting?"
        subText={congregateSubText}
        questionNumber={questionNumber + 2}
        totalQuestions={totalQuestions}
        answers={congregateSettingAnswers}
        value={user.congregateSetting.value}
        setValue={user.congregateSetting.setValue}
        className="congregate-setting"
      />
      <S.NextButton
        isDisabled={handleButtonDisabled()}
        appearance="primary"
        onClick={() => handleNext(fireAction)}
        data-test={`${DATA_TEST.BUTTON}-next`}
      >
        Next
      </S.NextButton>
    </Col>
  );
};
export default MultiQuestions;
