import {
  colors,
  Container,
  Row,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

const ScheduleCardContainer = styled(Container)`
  padding: ${size.md}px 0;
`;

const ScheduleCard = styled.div`
  box-shadow: 0 2px 30px -5px rgba(0, 0, 0, 0.1);
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  text-decoration: none;
  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Subtitle = styled.span`
  background-color: ${colors.teal1};
  border-radius: ${size.xl1}px;
  padding: ${size.xs2}px ${size.sm}px;
  ${typography.captionText};
  color: #005b5e;
`;

const Title = styled.p`
  ${typography.bodyText};
  color: ${colors.teal6};
  margin-right: ${size.sm}px;
`;

const Content = styled.p`
  ${typography.bodyTextXSmall};
  font-weight: ${typography.weight.book};
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: ${size.md}px;
  ${mediaQueries.forTabletHorizontalUp} {
    justify-content: flex-start;
  }
`;

const Image = styled.img`
  width: ${size.xl3}px;
`;

const TitleWrapper = styled.div`
  padding: ${size.xs1}px ${size.md}px;
  ${mediaQueries.forTabletHorizontalUp} {
    display: flex;
    flex-flow: row nowrap;
    padding: ${size.md}px 0 0;
    margin-left: -${size.xl1}px;
  }
  ${mediaQueries.forDesktopDown} {
    margin-left: ${size.sm}px;
  }

  ${mediaQueries.forBigDesktopUp} {
    margin-left: -${size.sm}px;
  }
`;
const ContentWrapper = styled.div`
  margin-right: -${size.xl6}px;
  padding: ${size.md}px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-right: 0;
    padding: 0 0 ${size.md}px;
    margin-left: -${size.xl1}px;
  }

  ${mediaQueries.forDesktopDown} {
    margin-left: ${size.sm}px;
  }

  ${mediaQueries.forBigDesktopUp} {
    margin-left: -${size.sm}px;
  }
`;
const ButtonWrapper = styled.div`
  font-size: ${size.md}px;
  padding: 0 ${size.md}px ${size.md}px;
  ${mediaQueries.forTabletHorizontalUp} {
    display: flex;
    justify-content: flex-end;
    padding: ${size.md}px 0;
  }
`;

const FluidRow = styled(Row)`
  width: 100%;
  cursor: default;
  margin-right: 0;
  margin-left: 0;
`;
export {
  ScheduleCardContainer,
  ScheduleCard,
  Title,
  Subtitle,
  ImageWrapper,
  Image,
  TitleWrapper,
  ContentWrapper,
  ButtonWrapper,
  Content,
  FluidRow,
};
