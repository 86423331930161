import {
  size,
  colors,
  typography,
  Button,
  mediaQueries,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Title = styled.h2`
  ${typography.h4Text};
  margin: 0 0 ${size.xs1}px 0;
`;

export const Description = styled.p`
  margin: 0 0 ${size.md}px 0;
`;

export const Products = styled.div<{ disableClicks: boolean }>`
  pointer-events: ${({ disableClicks }) => (disableClicks ? 'none' : '')};
  padding-bottom: ${size.xl8}px;

  ${mediaQueries.forDesktopUp} {
    padding-bottom: ${size.xl3}px;
  }
`;

export const LoaderWrapper = styled.div`
  > div {
    margin-bottom: ${size.md}px;
  }
`;

export const ActionArea = styled.div`
  align-items: center;
  background-color: ${colors.white};
  bottom: 0;
  box-shadow: 0 -3px 19px rgba(0, 0, 0, 0.08);
  display: flex;
  left: 0;
  padding: ${size.md}px;
  position: fixed;
  width: 100%;
  z-index: 1;

  ${mediaQueries.forDesktopUp} {
    position: initial;
    padding: 0;
    margin-top: ${size.md}px;
    box-shadow: none;
  }
`;

export const OrderButton = styled(Button)`
  margin-left: ${size.md}px;
  width: calc(70% - ${size.md}px);
`;

export const BackButton = styled(Button)`
  padding-left: 0;
  padding-right: 0;
  width: 30%;
`;

export const InformationBoxTitle = styled.p`
  ${typography.bodyTextSmall};
  font-weight: ${typography.weight.bold};
`;

export const InformationContent = styled.p`
  ${typography.bodyTextSmall};
`;

export const Link = styled.a`
  ${typography.buttonText}
  text-decoration: none;
`;

export const ProductSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${size.sm}px;
  margin-bottom: ${size.md}px;
`;

export const ProductSectionHeading = styled.h2`
  ${typography.buttonText};
  font-weight: ${typography.weight.regular};
`;
