import React from 'react';

import AppointmentsCardGroup from '../AppointmentsCardGroup';
import BookAppointmentSection from '../BookAppointmentSection';
import { useAppointmentsContext } from '../context';
import * as S from '../styles';

type Props = {
  isLoading: boolean;
};

const AppointmentSectionGroup = ({ isLoading }: Props) => {
  const { pastAppointments, futureAppointments } = useAppointmentsContext();

  return (
    <S.AppointmentContainer>
      <AppointmentsCardGroup
        appointments={futureAppointments}
        heading="Upcoming Appointments"
        noAppointmentsMessage="You have no upcoming appointments."
        showSkeleton={isLoading}
      />
      <BookAppointmentSection />
      <AppointmentsCardGroup
        appointments={pastAppointments}
        heading="Past Appointments"
        noAppointmentsMessage="You have no past appointments."
        showSkeleton={isLoading}
      />
    </S.AppointmentContainer>
  );
};

export default AppointmentSectionGroup;
