import checkmark from './assets/checkmark.svg';
import img1Desktop from './assets/identifier-sticker-desktop.png';
import img1Mobile from './assets/identifier-sticker-mobile.png';
import img2Desktop from './assets/place-sticker-desktop.png';
import img2Mobile from './assets/place-sticker-mobile.png';
import redcross from './assets/redcross.svg';
import { ItemProps } from './checklist';

const SPANISH_VIDEO_URL = 'https://www.everlywell.com/collect#covid19';

const CONTENT = {
  TITLE:
    'Remember to double check the information on your identifier sticker before you mail your sample.',
  DO_LIST: {
    TITLE: 'DO',
    ITEMS: [
      {
        icon: checkmark,
        content:
          'Write your <strong>full name, date of birth (DOB), and the date of collection</strong> on your identifier sticker. Please print clearly.',
      },
      {
        desktopImage: img1Desktop,
        mobileImage: img1Mobile,
      },
      {
        icon: checkmark,
        content:
          'Make sure that your information matches what you use to register your kit.',
      },
      {
        icon: checkmark,
        content:
          '<strong>Place the sticker with your full name, DOB, and collection date on the collection tube.</strong>',
      },
      {
        desktopImage: img2Desktop,
        mobileImage: img2Mobile,
      },
      {
        icon: checkmark,
        content:
          'If you are under 18, please ask your parent or guardian for assistance with the packaging and return shipment of your specimen.',
      },
    ] as ItemProps[],
  },
  DO_NOT_LIST: {
    TITLE: 'DO NOT',
    ITEMS: [
      {
        icon: redcross,
        content: 'Do not cover the barcode sticker already on the tube.',
      },
      {
        icon: redcross,
        content: 'Do not pour the liquid out of the tube.',
      },
    ] as ItemProps[],
  },
};

export { SPANISH_VIDEO_URL, CONTENT };
