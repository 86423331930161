import {
  Button,
  Checkbox,
  Col,
  colors,
  H4,
  H5,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';
const BoxImageContainer = styled.div`
  height: ${size.xl8}px;
  width: ${size.xl8}px;
  background-color: ${colors.teal1};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: ${size.xl1}px;
  ${mediaQueries.forTabletHorizontalUp} {
    margin-right: -${size.xl6}px;
    margin-bottom: 0;
  }
`;
const BoxImage = styled.img`
  width: 100%;
`;

const ImageCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

const RegistrationLabel = styled.p`
  ${typography.bodyTextSmall};
  font-weight: ${typography.weight.bold};
`;

const TestNameDetail = styled.p`
  ${typography.bodyTextSmall};
  font-weight: ${typography.weight.book};
  padding-bottom: ${size.sm}px;
  text-transform: capitalize;
`;

const TestNameDetailEmail = styled(TestNameDetail)`
  text-transform: none;
`;

const Header = styled(H4)`
  margin-bottom: ${size.xl1}px;
  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xl3}px;
  }
`;

const KitInfoHeader = styled(H5)`
  padding-bottom: ${size.md}px;
  margin: 0;
`;

const ErrorText = styled.p`
  ${typography.errorText};
`;

const SectionDivider = styled.hr`
  width: 100%;
  border: 1px solid ${colors.gray2};
  margin-top: 20px;
  margin-bottom: ${size.xl1}px;
`;

const SectionHeaderCol = styled(Col)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

const EditButton = styled.p`
  ${typography.bodyTextXSmall};
  font-weight: ${typography.weight.bold};
  color: ${colors.green6};
  text-align: right;
  &:hover {
    cursor: pointer;
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
`;

const CheckBox = styled(Checkbox)`
  max-width: ${size.xl3}px;
  margin-left: -${size.sm}px;
`;

const CheckBoxCol = styled(Col)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: ${size.sm}px;
  text-align: left;
  label {
    ${typography.bodyTextSmall};
    font-weight: ${typography.weight.book};
  }
`;

const CheckBoxLabel = styled.label`
  ${mediaQueries.forPhoneOnly} {
    text-align: left;
    margin-left: 10px;
  }
`;

const ContactWrapper = styled.div`
  position: relative;
`;

const AddressCol = styled(Col)`
  padding-bottom: ${size.md}px;
`;

export {
  Header,
  KitInfoHeader,
  BoxImage,
  BoxImageContainer,
  ImageCol,
  RegistrationLabel,
  TestNameDetail,
  TestNameDetailEmail,
  SectionDivider,
  SectionHeaderCol,
  EditButton,
  SubmitButton,
  CheckBox,
  CheckBoxCol,
  CheckBoxLabel,
  ContactWrapper,
  AddressCol,
  ErrorText,
};
