import { createContext, useContext } from 'react';

import { ActionT, defaultState, State } from '../state/scheduling-flow.state';

export interface NativeSchedulingContextInterface {
  dispatch: (action: ActionT) => void;
  state: State;
  getNextStep: () => string | null;
}

export const NativeSchedulingContext =
  createContext<NativeSchedulingContextInterface>({
    dispatch: () => {},
    state: defaultState,
    getNextStep: () => null,
  });

export const NativeSchedulingContextProvider = NativeSchedulingContext.Provider;

export const useNativeSchedulingContext = () =>
  useContext(NativeSchedulingContext);
