import {
  size,
  colors,
  typography,
  mediaQueries,
} from '@everlywell/leaves';
import styled, { css } from 'styled-components';

export const TaskWrapper = styled.div`
  align-items: stretch;
  border-radius: ${size.md}px;
  border: 1px solid ${colors.green2};
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: ${size.sm}px;
  min-height: 90px;
  overflow: hidden;
  padding: ${size.xs1}px ${size.md}px ${size.xs1}px 0;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  ${mediaQueries.forTabletVerticalUp} {
    cursor: pointer;
  }
`;

type TaskImageWrapperProps = {
  backgroundColor?: string
  imageURL?: string;
}

export const TaskImageWrapper = styled.div<TaskImageWrapperProps>`
  background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'transparent'};
  background-image: ${({imageURL}) => imageURL && `url(${imageURL})`};
  background-size: cover;
  background-position: center;
  position: relative;
  width: 70px;
`;

export const TaskCreditsWrapper = styled.div`
  width: 55px;
  height: 55px;
  margin: 9px 10px 9px 12px;
  border-radius: 50%;
  border: 2px solid ${colors.green4};
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
`

const creditsLabelStyles = css`
  color: ${colors.green4};
  display: block;
  width: 100%;
  text-align: center;
  line-height: 1;
`

export const TotalCredits = styled.span`
  ${typography.bodyTextXSmall};
  ${creditsLabelStyles};
  
  font-size: ${size.md}px;
  font-weight: 400;
`

export const CreditsLabel = styled.span`
  ${typography.bodyTextXSmall};
  ${creditsLabelStyles};
  
  font-size: 11px;
  font-weight: 700;
`

export const TaskContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: ${colors.white};
  width: calc(100% - 70px);

  ${mediaQueries.forTabletVerticalUp} {
    padding-left: ${size.xs1}px;
  }
`

export const InnerWrapper = styled.div`
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
`

export const ContentHug = styled.div`
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    width: calc(100% - 130px);
  }
`

export const TaskTitle = styled.h3`
  ${typography.bodyText};

  color: ${colors.gray4};
  line-height: ${size.lg}px;
  margin: 0 0 ${size.xs2}px;
`

export const TaskCTA = styled.p`  
  ${typography.buttonTextSmall};

  color: ${colors.green4};
  margin: 0;

  ${mediaQueries.forTabletVerticalUp} {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 130px;
  }
`

export const TaskDate = styled.p`
  ${typography.captionText};

  color: ${colors.gray4};
  margin: 0;
  padding-left: ${size.md}px;
  position: relative;

  &:before {
    background-color: ${colors.red3};
    border-radius: 50%;
    content: '';
    display: block;
    height: ${size.xs1}px;
    left: 0%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: ${size.xs1}px;
  }
`