import { Container } from '@everlywell/leaves';
import { text, boolean } from '@storybook/addon-knobs';
import React from 'react';
import styled from 'styled-components';

import PersonalizationCheckIn from './index';

export default {
  title: 'Components/PersonalizationCheckIn',
  component: PersonalizationCheckIn,
};

const PersonalizationContainer = styled(Container)`
  max-width: 532px;
  margin-top: 10%;
`;

export const Example = () => {
  const widget = text('Wiget', 'widget');
  const response = boolean('hasToolTip', true);
  return (
    <PersonalizationContainer>
      <PersonalizationCheckIn widget={widget} hasToolTip={response} />
    </PersonalizationContainer>
  );
};
