import React from 'react';

import * as S from './BadgeIcon.styles';

export type BadgeIconProps = React.SVGAttributes<SVGSVGElement>;

/**
 * The icon displayed when the health profile is completed
 */
function BadgeIcon(props: BadgeIconProps) {
  return (
    <S.SVG
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="35" cy="35" r="35" fill="#EEF5F6" />
      <path
        d="M43.2286 45.2852V58.6566L35.0001 50.428L26.7715 58.6566V45.2852"
        fill="none"
        stroke="#007377"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="35.0001"
        cy="27.7999"
        r="16.4571"
        fill="#C9E9ED"
        stroke="#007377"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <ellipse
        cx="34.9998"
        cy="27.7996"
        rx="11.3143"
        ry="11.3143"
        fill="white"
        stroke="#007377"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.9998 20.939L37.3964 25.7424H42.1998L38.1987 28.7047L39.8032 34.187L34.9998 31.1218L30.1964 34.0944L31.8009 28.715L27.7998 25.7424H32.6032L34.9998 20.939Z"
        fill="#C9E9ED"
        stroke="#007377"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </S.SVG>
  );
}

export default BadgeIcon;
