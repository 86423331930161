import {
  Button as RawButton,
  Input as RawInput,
  colors,
  mediaQueries,
  size,
  typography,
  H4,
} from '@everlywell/leaves';
import { hexToRGB } from 'common/utils/helpers';
import styled, { css } from 'styled-components';

type Props = {
  hasCancelButton?: boolean;
};

export const Container = styled.div<Props>`
  ${(props) => props.hasCancelButton && `width: 100%;`}
`;

export const Title = styled.h2`
  ${typography.h4Text}
  margin-top: 0;
  margin-bottom: 16px;
`;

export const ContactInfoTitle = styled(H4)`
  width: 100%;
  ${typography.h4Text};
  margin-top: 0;
`;

export const SubTitle = styled.h3`
  font-weight: ${typography.weight.light};
  font-size: 18px;
  line-height: 32px;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${colors.gray4};
  margin: 0 0 16px;
`;

export const Box = styled.div`
  padding: 32px 16px;
  border-radius: 3px;
  box-shadow: 0 ${size.xs3}px ${size.md}px 0
    rgba(${hexToRGB(colors.black)}, 0.1);
  background-color: ${colors.white};
  margin-bottom: 53px;
  ${mediaQueries.forTabletVerticalUp} {
    padding: 32px 24px;
    margin-bottom: 35px;
  }
`;

export const radioStyles = css({
  boxShadow: `0 ${size.xs3}px ${size.md}px 0
  rgba(${hexToRGB(colors.black)}, 0.1)`,
});

export const InnerForm = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQueries.forTabletHorizontalUp} {
    flex-flow: row wrap;
    justify-content: space-between;
  }
`;

export const Field = styled.div<Props>`
  input:not([type|='checkbox']) ~ div > svg {
    fill: none;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    flex: 0 0 calc(50% - 12px);
    ${(props) => props.hasCancelButton && `flex-grow: 1;`}
  }
`;

export const RadioField = styled.fieldset`
  border: 0;
  margin: 0;
  padding: 0;

  ${mediaQueries.forTabletHorizontalUp} {
    flex: 0 0 calc(50% - 12px);
  }

  /*
  TODO: This should be fixed at leaves, since this is a workaround to make the RadioButton fit with the other inputs fields' size
  Note: https://gitlab.com/everlywell/apps/results/-/merge_requests/705#note_1236395592
   */
  label {
    height: 50px;
  }
`;

export const Spacer = styled.div`
  padding: ${size.sm}px;
`;
export const Button = styled(RawButton)({
  width: '100%',
  minWidth: '210px',

  [mediaQueries.forTabletHorizontalUp]: {
    width: 'auto',
  },
});

export const CancelButton = styled(RawButton)`
  width: 100%;

  ${mediaQueries.forTabletHorizontalUp} {
    width: 210px;
  }
`;

export const ButtonRow = styled.div<Props>`
  margin-top: ${size.md}px;
  display: flex;
  justify-content: center;
  ${(props) => props.hasCancelButton && `flex-direction: column`};

  ${mediaQueries.forTabletHorizontalUp} {
    ${(props) => props.hasCancelButton && `flex-direction: row`};
    justify-content: ${(props) =>
      props.hasCancelButton ? `end` : `flex-start`};
    flex: 1 0 100%;
  }

  ${(props) => props.hasCancelButton && `gap: ${size.md}px;`}
`;

export const Input = styled(RawInput)`
  svg {
    display: none;
  }
`;
