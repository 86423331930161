import { mediaQueries, size } from '@everlywell/leaves';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  z-index: 11;
  top: 57px;
  left: 0;
  right: 0;
  margin: ${size.md}px;
  ${mediaQueries.forTabletHorizontalUp} {
    width: 35%;
    margin: ${size.md}px auto;
  }
  ${mediaQueries.forTabletHorizontalDown} {
    width: 85%;
    margin: ${size.md}px auto;
  }
  ${mediaQueries.forPhoneOnly} {
    width: inherit;
    margin: ${size.md}px;
  }

  div[type="success"] svg {
    fill: none;
    stroke-dasharray: 100;
    stroke-dashoffset: 0;
    stroke-width: 5;
  }
`;
export { Wrapper };
