/**
 *
 * ShareSubHeaderButton
 *
 */
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import {
  SHARE as SHARE_DATA_TEST,
  SUB_HEADER as SUB_HEADER_DATA_TEST,
} from 'common/utils/constants/dataTest';
import React, { useState, useEffect } from 'react';

import PopoverModal from '../PopoverModal';
import SubHeaderButton from '../SubHeaderButton';
import CopyLink from './CopyLink';
import * as S from './ShareSubHeaderButton.styles';
import SocialLinks from './SocialLinks';
import Toggle from './Toggle';

export type ShareSubHeaderButtonProps = {
  onToggleShare: () => void;
  shareLink?: string;
  testName?: string;
};

function ShareSubHeaderButton(props: ShareSubHeaderButtonProps) {
  const { onToggleShare, shareLink, testName = 'Unknown' } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isSharing, setIsSharing] = useState(!!shareLink);
  const [isWaitingForChange, setIsWaitingForChange] = useState(false);

  function handleToggleOpen() {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: { label: 'Share', category: ANALYTICS.CATEGORIES.SHARE_RESULTS },
    });
    setIsOpen((currentIsOpen) => !currentIsOpen);
  }

  function handleToggleShare() {
    onToggleShare();
    setIsWaitingForChange(true);
    setIsSharing((currentIsSharing) => !currentIsSharing);
  }

  function handleCancel() {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'Decline Share',
        category: ANALYTICS.CATEGORIES.SHARE_RESULTS,
      },
    });
    setIsOpen(false);
  }

  useEffect(() => {
    // Check for sharing being turned off completely and close popover.
    if (isWaitingForChange && !isSharing && !shareLink) {
      setIsOpen(false);
    }
  }, [isWaitingForChange, isSharing, shareLink]);

  return (
    <S.Wrapper>
      <SubHeaderButton
        onClick={handleToggleOpen}
        isActive={isOpen}
        name="share"
        dataTest={SUB_HEADER_DATA_TEST.SHARE_BUTTON}
        shouldShowChevron
        hideChevronOnMobile
        hideTextOnMobile
      />

      {isOpen && (
        <S.PopoverModalWrap>
          <PopoverModal
            onClose={handleToggleOpen}
            footer={({ hasScrollableContent }) =>
              shareLink ? (
                <S.Footer needsGradient={hasScrollableContent}>
                  <CopyLink shareLink={shareLink} />
                  <SocialLinks shareLink={shareLink} />
                  {!isSharing && (
                    <S.SpinnerWrapper>
                      <S.Spinner />
                    </S.SpinnerWrapper>
                  )}
                </S.Footer>
              ) : (
                <S.Footer needsGradient={hasScrollableContent}>
                  <S.Button onClick={handleCancel} secondary>
                    Cancel
                  </S.Button>
                  <S.Button
                    onClick={handleToggleShare}
                    data-test={SHARE_DATA_TEST.START_SHARING_BUTTON}
                  >
                    Start Sharing
                  </S.Button>
                  {isSharing && (
                    <S.SpinnerWrapper>
                      <S.Spinner />
                    </S.SpinnerWrapper>
                  )}
                </S.Footer>
              )
            }
          >
            <S.Content>
              {shareLink ? (
                <>
                  <S.Main>
                    <S.SharingText aria-live="polite">
                      Share your <span data-isolate>{testName}</span> results
                      with the EverlyWell community, friends, and family
                    </S.SharingText>
                    <S.ToggleContainer aria-live="polite">
                      <span>Visible to the Public</span>
                      <Toggle
                        data-test={SHARE_DATA_TEST.TOGGLE}
                        onChange={handleToggleShare}
                        isOn={isSharing}
                      />
                    </S.ToggleContainer>
                  </S.Main>
                </>
              ) : (
                <>
                  <S.Main aria-live="polite" role="dialog" aria-modal="true">
                    <h2>Are You Sure?</h2>
                    <S.DisclosureText>
                      You understand that you are voluntarily choosing to
                      disclose your medical and personal health information, and
                      that EverlyWell is not responsible for the sharing of your
                      data by any party online or otherwise once you accept the
                      &apos;Start Sharing&apos; button below. You also
                      understand that once your data is shared, it is no longer
                      secure and the privacy of that data is no longer protected
                      by HIPAA.
                    </S.DisclosureText>
                  </S.Main>
                </>
              )}
            </S.Content>
          </PopoverModal>
        </S.PopoverModalWrap>
      )}
    </S.Wrapper>
  );
}

export default ShareSubHeaderButton;
