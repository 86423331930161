import { Button, Dropdown } from '@everlywell/leaves';
import {
  CancelAppointmentPayload,
  cancelAppointment,
} from 'common/apis/telehealthApis';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import Grid from 'components/Grid/Grid';
import Layout from 'components/Layout/Layout';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import * as S from './CancelAppointmentPage.styles';
import Disclaimer from './components/Disclaimer';
import HeaderIcon from './components/HeaderIcon';
import NeedHelpCard from './components/NeedHelpCard';

const ReasonOptions = [
  'Cost of Service',
  'Unacceptable wait times for appointment',
  'Insufficient appointment instructions (i.e. how to join)',
  'Seeking in-person treatment',
  'I found a different online provider',
  'Other (please explain below)',
].map((item, index) => ({
  id: index.toString(),
  text: item,
  value: item,
}));

export const DROPDOWN_REQUIRED_ERROR_MESSAGE = 'Please choose an option';
export const TEXTAREA_REQUIRED_ERROR_MESSAGE = 'Please fill in this field';

export type CancelAppointmentPageProps = {};

/**
 * Page that allows the user to cancel their appointment
 */
function CancelAppointmentPage(props: CancelAppointmentPageProps) {
  const { appointmentId } = useParams();
  const [searchParams] = useSearchParams();

  const program = searchParams.get('program');

  const navigate = useNavigate();

  const form = useForm({
    mode: 'onBlur',
    defaultValues: {
      reason: '',
      feedback: '',
    },
  });

  const { mutate: submitCancellation, isLoading: isSubmitting } = useMutation(
    cancelAppointment,
    {
      onSuccess: async () => {
        await analytics.track({
          event: ANALYTICS.EVENTS.CLICKED_BUTTON,
          data: {
            label: 'Confirm cancellation',
            program,
            appointmentId,
          },
        });

        navigate(
          `/virtual-care/appointments/${appointmentId}/cancel-confirmation?program=${program}`,
        );
      },

      onError: () => {
        navigate('/error');
      },
    },
  );

  const submitForm = (data: CancelAppointmentPayload) => {
    submitCancellation({
      appointmentId: appointmentId ?? '',
      reason: data.reason,
      feedback: data.feedback,
    });
  };

  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        page: ANALYTICS.PAGES.CANCEL_APPOINTMENT_PAGE,
        program,
        appointmentId,
      },
    });
  }, [program, appointmentId]);

  return (
    <Layout>
      <S.Container as="form" onSubmit={form.handleSubmit(submitForm)}>
        <Grid.Container spacing={['md', 'lg']} alignEdges={false}>
          <Grid.Item
            width={[1]}
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <HeaderIcon />
            <S.Heading as="h1">Are you sure you want to cancel?</S.Heading>
          </Grid.Item>
          <Grid.Item width={[1]}>
            <S.Label htmlFor="reason">
              What is the biggest reason for your wish to cancel?
            </S.Label>

            <Dropdown
              ref={form.register({
                required: DROPDOWN_REQUIRED_ERROR_MESSAGE,
              })}
              id="reason"
              name="reason"
              label=""
              error={form.errors.reason?.message}
              showErrorMessage
              items={[
                {
                  id: '',
                  text: 'Please select a reason',
                  value: '',
                },
                ...ReasonOptions,
              ]}
            />
          </Grid.Item>

          <Grid.Item width={[1]}>
            <S.StyledTextArea
              ref={form.register({
                required: TEXTAREA_REQUIRED_ERROR_MESSAGE,
              })}
              id="feedback"
              name="feedback"
              label="How could we better help you?"
              error={form.errors.feedback?.message}
              hasError={Boolean(form.errors.feedback?.message)}
              placeholder="I found care through another telehealth provider"
            />
          </Grid.Item>

          <Grid.Item width={[1]}>
            <Disclaimer />
          </Grid.Item>

          <Grid.Item width={[1]}>
            <NeedHelpCard
              onClick={() => {
                analytics.track({
                  event: ANALYTICS.EVENTS.CLICKED_BUTTON,
                  data: {
                    label: 'Call OpenLoop',
                    program,
                    appointmentId,
                  },
                });
              }}
            />
          </Grid.Item>

          <Grid.Item width={[1]}>
            <S.ButtonsContainer spacing={['sm']}>
              <Grid.Item width={[1, 0.45]}>
                <Button
                  type="submit"
                  css={{ width: '100%' }}
                  isLoading={isSubmitting}
                >
                  Cancel appointment
                </Button>
              </Grid.Item>
              <Grid.Item width={[1, 0.52]}>
                <Button
                  component={Link}
                  to="/dashboard"
                  appearance="secondary"
                  css={{ width: '100%' }}
                  onClick={() => {
                    analytics.track({
                      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
                      data: {
                        label: 'Do not cancel',
                        program,
                        appointmentId,
                      },
                    });
                  }}
                >
                  No, I do not wish to cancel
                </Button>
              </Grid.Item>
            </S.ButtonsContainer>
          </Grid.Item>
        </Grid.Container>
      </S.Container>
    </Layout>
  );
}

export default CancelAppointmentPage;
