import { user } from 'common/utils/schema';
import { User } from 'common/utils/types';
import { normalize } from 'normalizr';

const SET_USER_DATA = 'SET_USER_DATA';

export function setUserData(data: User) {
  return {
    type: SET_USER_DATA,
    payload: normalize(data, user),
  };
}
