import { breakpoints, Card } from '@everlywell/leaves';
import analytics, { TrackVisibilityWrapper } from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { CARE_PATHWAYS_DASHBOARD } from 'common/utils/constants/dataTest';
import { ContentSectionType } from 'common/utils/types';
import * as S from 'components/DiscoverMore/DiscoverMore.styles';
import NatalistLogo from 'components/DiscoverMore/images/NatalistLogo.png';
import { debounce } from 'lodash';
import React, { useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

export interface DiscoverMoreProps {
  title: string;
  headline: string;
  subHeadline: string;
  articles: ContentSectionType[];
}

const DiscoverMore = ({
  title,
  headline,
  subHeadline,
  articles,
}: DiscoverMoreProps): JSX.Element | null => {
  const [_innerWidth, _setInnerWidth] = useState(window.innerWidth);
  const prevRef = useRef<HTMLDivElement>(null);
  const nextRef = useRef<HTMLDivElement>(null);

  const trackSliderClick = async (next: boolean) => {
    await analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.CARE_DASHBOARD.DISCOVER_MORE.label,
        description: next
          ? ANALYTICS.LABELS.CARE_DASHBOARD.DISCOVER_MORE.nextSlide
          : ANALYTICS.LABELS.CARE_DASHBOARD.DISCOVER_MORE.prevSlide,
      },
    });
  };

  const onSwipe = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.SWIPE,
      data: {
        label: ANALYTICS.LABELS.CARE_DASHBOARD.DISCOVER_MORE.label,
        description: ANALYTICS.LABELS.CARE_DASHBOARD.DISCOVER_MORE.swipe,
      },
    });
  };

  const onCardClick = (article: ContentSectionType, index: number) => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        category: ANALYTICS.CATEGORIES.CARE_PATHWAYS,
        label: `${ANALYTICS.LABELS.CARE_DASHBOARD.DISCOVER_MORE.article} ${index}`,
        title: article.title,
        id: article.sectionId,
      },
    });
  };

  useEffectOnce(() => {
    const handleResize = debounce(() => {
      if (_innerWidth !== window.innerWidth) _setInnerWidth(window.innerWidth);
    }, 1000);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const slidesPerView = _innerWidth <= breakpoints.forPhoneOnly ? 1 : 2;

  const singleSlide = articles?.length === 1;

  const showPowerBySection = Boolean(subHeadline);

  return (
    <TrackVisibilityWrapper
      label={ANALYTICS.LABELS.CARE_DASHBOARD.DISCOVER_MORE.label}
    >
      <S.Container data-test={CARE_PATHWAYS_DASHBOARD.DISCOVER_MORE_SECTION}>
        <S.SectionName id="test-included-title">{title}</S.SectionName>
        <S.SectionSubHeader showPowerBySection={showPowerBySection}>
          {headline}
        </S.SectionSubHeader>
        {showPowerBySection && (
          <S.SectionPowerByText>
            {subHeadline || 'Powered by '}{' '}
            <S.NatalistLogoImage src={NatalistLogo} alt="Natalist" />
          </S.SectionPowerByText>
        )}
        <S.SliderContainer>
          <Swiper
            className="mySwiper"
            // github issue https://github.com/nolimits4web/swiper/issues/6843
            // @ts-ignore
            loop={!singleSlide}
            modules={[Pagination, Navigation]}
            navigation={
              singleSlide
                ? false
                : { prevEl: prevRef?.current, nextEl: nextRef?.current }
            }
            onSlideChange={() =>
              window.innerWidth <= breakpoints.forPhoneOnly && onSwipe()
            }
            pagination={{ clickable: true }}
            slidesPerView={slidesPerView}
            spaceBetween={0}
          >
            {articles?.map((article: ContentSectionType, index: number) => (
              <SwiperSlide key={article.sectionId}>
                {() => (
                  <S.CardWrapper
                    key={article.sectionId}
                    data-testid={CARE_PATHWAYS_DASHBOARD.DISCOVER_MORE_SLIDE}
                    onClick={() => onCardClick(article, index)}
                  >
                    <Card.Article
                      image={
                        <S.Img
                          src={article?.image?.url}
                          style={{ width: '100%', height: '100%' }}
                          alt={article?.image?.title}
                        />
                      }
                      imageAlt={article.title}
                      title={article.title}
                      copy={article.description || ''}
                      url={''}
                    />
                  </S.CardWrapper>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
          {!singleSlide && (
            <>
              {/* @ts-ignore */}
              <S.Prev ref={prevRef} onClick={() => trackSliderClick()}>
                <S.LeftIcon />
              </S.Prev>
              {/* @ts-ignore */}
              <S.Next ref={nextRef} onClick={() => trackSliderClick(true)}>
                <S.RightIcon />
              </S.Next>
            </>
          )}
        </S.SliderContainer>
      </S.Container>
    </TrackVisibilityWrapper>
  );
};

export default DiscoverMore;
