import analytics from 'common/utils/analytics';
import { LEGACY_APP_ROOT, COVID_19_IDS } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { RESULT_DETAILS as DATA_TEST } from 'common/utils/constants/dataTest';
import { KitResult } from 'common/utils/types';
import Popover from 'components/SubHeader/PopoverModal/Popover';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import * as S from './styles';

export type Props = {
  kitResult?: KitResult;
};

const SavePdfButton = (props: Props) => {
  const { kitResult } = props;

  const [isClicked, setIsClicked] = useState<boolean>(false);
  if (!kitResult) return null;

  const { test, paid = false } = kitResult;
  const testId = test?.id;
  const doctorPDFUrl = `${LEGACY_APP_ROOT}/results/${kitResult?.number}/doctor_results.pdf`;
  const receiptPDFLink = `${LEGACY_APP_ROOT}/results/${kitResult?.number}/reimbursement_letter.pdf`;
  const analyticsAttributes = {
    'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
    'data-analytics-category': ANALYTICS.CATEGORIES.DOWNLOAD_PDF,
    'data-analytics-label': 'Download doctor PDF',
    'data-analytics-test-id': testId,
  };
  const reimbursementAnalyticsAttributes = {
    'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
    'data-analytics-category': ANALYTICS.CATEGORIES.DOWNLOAD_PDF,
    'data-analytics-label': 'Download reimbursement PDF',
    'data-analytics-test-id': testId,
  };
  const kitResultHasAnyDnrMarkers = kitResult.any_dnr_marker_results;
  const isCovidDTCKit = testId && COVID_19_IDS.includes(testId) && paid;
  const buttonURL =
    kitResultHasAnyDnrMarkers || isCovidDTCKit ? undefined : doctorPDFUrl;

  const handleButtonClick = () => {
    setIsClicked(!isClicked);

    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.DOWNLOAD_PDF_REPORT,
      },
    });
    window.open(buttonURL, '_blank');
  };

  const savePDFButton = () => (
    <S.PdfButton
      data-test={DATA_TEST.SAVE_BUTTON}
      appearance={'secondary'}
      onClick={handleButtonClick}
      {...analyticsAttributes}
    >
      {isCovidDTCKit ? 'View My Documents' : 'Save a PDF of your results'}
      {isCovidDTCKit && (
        <S.Chevron isActive={isClicked} hideChevronOnMobile={false} />
      )}
    </S.PdfButton>
  );

  const contactCustomerCareOption = () => (
    <p data-test={DATA_TEST.DNR_PDF}>
      Please contact{' '}
      <a href="mailto:contact@everlywell.com">contact@everlywell.com</a> to
      receive a PDF of your results.
    </p>
  );

  const savePDFOption = () => (
    <Link
      target="_blank"
      to={doctorPDFUrl}
      data-test={DATA_TEST.DOCTOR_PDF}
      {...analyticsAttributes}
    >
      Save a PDF of your results
    </Link>
  );

  const receiptPDFOption = () => (
    <Link
      target="_blank"
      to={receiptPDFLink}
      data-test={DATA_TEST.ITEMIZED_RECEIPT_PDF}
      {...reimbursementAnalyticsAttributes}
    >
      Itemized Receipt PDF
    </Link>
  );

  return (
    <>
      <S.PdfButtonWrapper>
        {savePDFButton()}
        {isClicked && isCovidDTCKit && (
          <S.PopoverWrap>
            <Popover onClose={handleButtonClick}>
              <S.Content>
                {!kitResultHasAnyDnrMarkers && savePDFOption()}
                {kitResultHasAnyDnrMarkers && contactCustomerCareOption()}
                {receiptPDFOption()}
              </S.Content>
            </Popover>
          </S.PopoverWrap>
        )}
        {kitResultHasAnyDnrMarkers && isClicked && !isCovidDTCKit
          ? contactCustomerCareOption()
          : ''}
      </S.PdfButtonWrapper>
    </>
  );
};

export default SavePdfButton;
