/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable';

import entitiesReducer from './entities/reducer';
import appReducer from './reducer';
import sessionReducer from './session/reducer';

export default combineReducers({
  entities: entitiesReducer,
  session: sessionReducer,
  app: appReducer,
});
