import { size, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

const IntroWrapper = styled.div`
  max-width: 532px;
  padding: 0 ${size.md}px;
  margin: ${size.xl5}px auto;

  ${mediaQueries.forTabletVerticalUp} {
    margin: ${size.xl8}px auto;
  }
`;

const QuestionWrapper = styled.div`
  max-width: 532px;
  padding: 0 ${size.md}px;
  margin: ${size.xl5}px auto;
`;

const BlankSpacer = styled.div`
  max-width: 532px;
  min-height: 532px;
  padding: 0 ${size.md}px;
  margin: ${size.xl5}px auto;
`;

export { IntroWrapper, QuestionWrapper, BlankSpacer };
