import { TelehealthAppointment } from 'common/apis/telehealthApis';
import t2tProviderImage from 'common/images/test-to-treat-provider.svg';
import { format } from 'date-fns';
import { STATUSES } from 'pages/AccountHub/pages/TelehealthDashboard/components/TelehealthAppointments/utils';
import React from 'react';

import AppointmentCard, { AppointmentCardSkeleton } from '../AppointmentCard';
import { useAppointmentsContext } from '../context';
import * as S from '../styles';
import {
  handleCanceledOrNoShow,
  handleOccurred,
  handleFutureAppointment,
  handleTodayAppointment,
  FormattedAppointment,
} from './helpers';

export type AppointmentsCardGroupProps = {
  appointments: TelehealthAppointment[];
  heading: string;
  noAppointmentsMessage: string;
  showSkeleton: boolean;
};

const useFormatAppointment = () => {
  const {
    isUpcomingAppointment,
    isAppointmentToday,
    isAfterCurrentTimePlusThreeHours,
  } = useAppointmentsContext();

  const formatAppointment = (
    appointment: TelehealthAppointment,
  ): FormattedAppointment => {
    const startTime = new Date(appointment.start_time);
    const formattedTime = format(startTime, 'p');
    const providerFullName = `${appointment?.provider?.first_name} ${appointment?.provider?.last_name}`;

    if (isUpcomingAppointment(appointment)) {
      if (isAppointmentToday(appointment)) {
        return handleTodayAppointment({
          appointment,
          providerFullName,
        });
      }

      return handleFutureAppointment({
        appointment,
        providerFullName,
      });
    }

    if (
      appointment.status === STATUSES.NoShow ||
      appointment.status === STATUSES.Cancelled
    ) {
      return handleCanceledOrNoShow({
        appointment,
        providerFullName,
        formattedTime,
      });
    }

    return handleOccurred({
      appointment,
      providerFullName,
      formattedTime,
      isAfterThreePlusHours: isAfterCurrentTimePlusThreeHours(appointment),
    });
  };

  return { formatAppointment };
};

const AppointmentsSection = ({
  appointments = [],
  heading,
  noAppointmentsMessage,
  showSkeleton = false,
}: AppointmentsCardGroupProps) => {
  const { formatAppointment } = useFormatAppointment();

  const formattedAppointments = appointments.map(formatAppointment);

  return (
    <S.AppointmentSection>
      <S.Heading>{heading}</S.Heading>

      {showSkeleton && <AppointmentCardSkeleton />}

      {!showSkeleton && appointments.length === 0 && (
        <S.AppointmentBlankSlateMessage>
          {noAppointmentsMessage}
        </S.AppointmentBlankSlateMessage>
      )}

      {formattedAppointments.map((appointment) => (
        <AppointmentCard
          key={appointment.id}
          {...appointment}
          boxImage={{
            src: t2tProviderImage,
            alt: 'Computer icon with a person in it and mobile phone icon',
          }}
        />
      ))}
    </S.AppointmentSection>
  );
};

export default AppointmentsSection;
