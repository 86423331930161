import { Test } from 'common/utils/types';
import Layout from 'components/Layout';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { INTAKE_STEPS } from './constants';
import { IntakeProvider } from './context';
import IntakeSteps from './IntakeSteps';
import Payment from './Payment';
import Shipping from './Shipping';

export interface IntakeProps {
  test?: Test;
  sku?: string;
}

export type IntakeStep = {
  label: string;
  value: number;
  component: React.ElementType | null;
};

const Intake = (props: IntakeProps) => {
  const location = useLocation();
  if (!location) {
    return <Navigate to="/" />;
  }

  const steps = [
    /** Commented this logic out for now as it may be needed in the future
     * when the Weight Management flow starts utilizing the progress bar
     */
    // {
    //   ...INTAKE_STEPS.signup,
    // },
    {
      ...INTAKE_STEPS.shipping,
      component: Shipping,
    },
    {
      ...INTAKE_STEPS.payment,
      component: Payment,
    },
  ];

  return (
    <Layout>
      <IntakeProvider>
        <IntakeSteps steps={steps} />
      </IntakeProvider>
    </Layout>
  );
};

export default Intake;
