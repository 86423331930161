import { sendNotificationEvent } from 'common/apis/subscriptionsApi';
import { showFlash } from 'common/utils/domHelpers';
import { isValidEmail } from 'common/utils/formHelpers';

async function newSubscribe(email: string) {
  const payload = {
    event_type: 'emailCollectionResults',
    resource: {
      email,
      data_fields: { emailCollectionResults: email },
    },
  };

  try {
    await sendNotificationEvent(payload);
    showFlash('alert-success', 'Thanks for subscribing!');
  } catch (error) {
    showFlash(
      'alert-error',
      'There was an issue with your subscription. Please try again.',
    );
  }
}

export function handleIterableSubscription(email: string) {
  if (!isValidEmail(email)) {
    return showFlash('alert-error', 'Please enter a valid email.');
  }

  return newSubscribe(email);
}
