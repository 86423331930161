const iconSrc =
  'https://everlywell-qa.s3.amazonaws.com/snippet_assets/FS_108.png';
const alt = 'Food Sensitivity 108';
const header = 'Test 108 new foods';
const testName = 'Food Sensitivity-96';
const productName = 'Food Sensitivity Expansion Test';
const description =
  'Everlywell now offers a food sensitivity test that lets you check 108 additional foods.';
const url = 'https://qa.everlywell.com/products/food-sensitivity-expansion/';
const items = [
  {
    iconSrc:
      'https://everlywell-qa.s3.amazonaws.com/snippet_assets/icons-measures.svg',
    alt: '1',
    text: 'IgG Reactivity to 108 Foods',
  },
  {
    iconSrc:
      'https://everlywell-qa.s3.amazonaws.com/snippet_assets/icon-blood.svg',
    alt: '2',
    text: 'Finger prick blood',
  },
  {
    iconSrc:
      'https://everlywell-qa.s3.amazonaws.com/snippet_assets/icon-cost.svg',
    alt: '3',
    text: '89',
  },
];

export { iconSrc, alt, header, description, items, url, testName, productName };
