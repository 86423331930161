import { size, mediaQueries, colors } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div({
  display: 'flex',
  minWidth: '100%',
  flexWrap: 'wrap',
  alignItems: 'start',
  justifyContent: 'start',
});

export const ContentContainer = styled.div({
  color: colors.gray4,
  display: 'flex',
  width: '100%',
  justifyContent: 'start',
  textAlign: 'left',
  alignItems: 'start',
  flexDirection: 'column',
  padding: `0 ${size.md}px ${size.lg}px ${size.lg}px`,

  [mediaQueries.forTabletVerticalUp]: {
    width: `auto`,
    flex: 1,
    padding: `0 ${size.lg}px ${size.lg}px 0`,
  },

  [mediaQueries.forDesktopUp]: {
    width: `auto`,
    flex: 1,
    padding: `0 ${size.xl1}px ${size.xl1}px 0`,
  },
});
