import Grid from 'components/Grid';
import React from 'react';

import ErrorIcon from './assets/error-icon.svg';
import * as S from './LoadingError.styles';

export type LoadingErrorProps = {
  handleRetry?: () => void;
};

/**
 * Loading error component for Native Scheduling
 *
 * Note: this was taken/adapted from the SSO Error page
 */
function LoadingError(props: LoadingErrorProps) {
  return (
    <Grid.Container spacing={['lg']}>
      <Grid.Item
        width={[1]}
        css={{ display: 'flex', justifyContent: 'center' }}
      >
        <img src={ErrorIcon} alt="" />
      </Grid.Item>
      <Grid.Item width={[1]}>
        <S.CardHeader>
          Sorry, we're currently experiencing technical issues.
        </S.CardHeader>
      </Grid.Item>
      <Grid.Item width={[1]}>
        <S.Text>
          Please try again. If the issue continues, contact{' '}
          <S.CustomLink href="https://support.everlywell.com/" target="_blank">
            Customer Experience
          </S.CustomLink>
          .
        </S.Text>
      </Grid.Item>
      <Grid.Item
        width={[1]}
        css={{ display: 'flex', justifyContent: 'center' }}
      >
        <S.Button appearance="primary" onClick={props.handleRetry}>
          Try again
        </S.Button>
      </Grid.Item>
    </Grid.Container>
  );
}

export default LoadingError;
