import {
  NOT_APPLICABLE,
  MARKER_TYPES,
  NOTAPPLICABLE,
  DNR,
  NSA,
  QNS,
  NCAL,
  INVALID,
} from 'common/utils/constants';
import { Marker } from 'common/utils/types';
import ReactivityBadgeIcon, {
  IconMapKeys,
} from 'components/ReactivityBadge/ReactivityBadgeIcon';
import React from 'react';

export type Props = {
  severityIndex: number;
  marker: Marker;
  exception?: string | null;
  markerType: string;
  height?: number;
  width?: number;
  dataTest: string;
  isOptionalLiteral?: boolean;
};

function ReactivityBadgeIconContainer(props: Props) {
  const {
    severityIndex,
    marker,
    exception,
    height = 40,
    width = 40,
    markerType,
    dataTest,
    isOptionalLiteral = false,
  } = props;

  const severities = marker.severities;
  const color = marker.severity_colors[severityIndex];
  const normalIndex = severities.indexOf(1);
  const exceptions = [
    NOT_APPLICABLE,
    NOTAPPLICABLE,
    DNR,
    QNS,
    NSA,
    NCAL,
    INVALID,
  ];

  const hasException = exception && exceptions.includes(exception);
  const normalSeverity = severities[severityIndex] === 1;
  const lowSeverity = severityIndex < normalIndex;
  const elevatedSeverity = severities[severityIndex] >= 2;

  const defaultIconColor = 'gray';
  const defaultIconType: IconMapKeys = 'minus';

  // Used only for "Plain Number" marker type, not "Numerical"
  const iconForPlainNumber = (): { color: string; iconType: IconMapKeys } => {
    const iconType = elevatedSeverity ? 'exclamation' : 'check';
    return {
      color,
      iconType,
    };
  };

  // Used for Numerical and Multi-Numerical marker types
  const iconForNonDescriptive = (): {
    color: string;
    iconType: IconMapKeys;
  } => {
    if (hasException) return { color: 'gray', iconType: 'minus' };
    if (normalSeverity) return { color, iconType: 'check' };
    if (lowSeverity) return { color, iconType: 'arrow-down' };
    if (isOptionalLiteral) return { color, iconType: 'minus' };
    return {
      color,
      iconType: 'arrow-up',
    };
  };

  // Used for Descriptive marker type
  const iconForHighSeverity = (): {
    color: string;
    iconType: IconMapKeys;
  } => {
    const iconType = 'plus';
    return {
      color,
      iconType,
    };
  };

  const getIconName = (): {
    color: string;
    iconType: IconMapKeys;
  } => {
    if (markerType === MARKER_TYPES.PLAIN_NUMBER) return iconForPlainNumber();
    if (markerType !== MARKER_TYPES.DESCRIPTIVE) return iconForNonDescriptive();
    if (severities[severityIndex] === 3) return iconForHighSeverity();
    return { color, iconType };
  };

  const { color: iconColor = defaultIconColor, iconType = defaultIconType } =
    getIconName();

  return (
    <div data-test={dataTest}>
      <ReactivityBadgeIcon
        iconColor={iconColor}
        iconType={iconType}
        height={height}
        width={width}
      />
    </div>
  );
}

export default ReactivityBadgeIconContainer;
