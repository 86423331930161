import React from 'react';

/**
 * Icon to be used in the header of the page
 */
function Icon() {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="18" y="18" width="54" height="54" rx="27" fill="#F5FAF7" />
      <path
        d="M43.4423 29.25V29.25C44.1856 29.25 44.8886 29.4307 45.5101 29.7498C47.2989 30.6708 48.2628 32.7102 47.9375 34.7341L44.3609 49.4081C43.7032 52.1073 41.3382 54 38.6248 54C35.9124 54 33.5475 52.1073 32.8898 49.4081L29.3122 34.7341C28.9879 32.7102 29.9518 30.6708 31.7396 29.7498C32.362 29.4307 33.064 29.25 33.8084 29.25"
        stroke="#16644A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39 54.3693V57.4496C39 60.0059 39.965 62.5425 43.646 62.5425C47.327 62.5425 48.9694 58.0783 49.5 55.125L52.0859 44.7616C52.3664 42.7469 54.0434 41.25 56.0232 41.25C58.2197 41.25 60 43.0813 60 45.3408V52.0453"
        stroke="#16644A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.25 55.125C62.25 56.5758 61.0752 57.75 59.625 57.75C58.1748 57.75 57 56.5758 57 55.125C57 53.6742 58.1748 52.5 59.625 52.5C61.0752 52.5 62.25 53.6742 62.25 55.125Z"
        stroke="#16644A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0089 30.75H35.4217C34.4986 30.75 33.75 30.0787 33.75 29.25C33.75 28.4223 34.4986 27.75 35.4217 27.75H36.0089C36.4174 27.75 36.75 28.0473 36.75 28.4145V30.0855C36.75 3'auto'17 36.4174 30.75 36.0089 30.75Z"
        stroke="#16644A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.2402 27.75H41.8277C42.7522 27.75 43.5 28.4213 43.5 29.25C43.5 30.0777 42.7522 30.75 41.8277 30.75H41.2402C40.8316 30.75 40.5 30.4517 40.5 30.0855V28.4145C40.5 28.0473 40.8316 27.75 41.2402 27.75Z"
        stroke="#16644A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="63" cy="26" r="7.875" fill="#16644A" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.8277 22.9223C65.685 22.7797 65.4551 22.7755 65.3073 22.9127L62.0509 25.9365L60.6797 24.7762C60.5308 24.6503 60.3102 24.6594 60.1723 24.7973L59.1723 25.7973C59.0259 25.9438 59.0259 26.1812 59.1723 26.3277L61.7973 28.9527C61.9438 29.0991 62.1812 29.0991 62.3277 28.9527L66.8277 24.4527C66.9741 24.3062 66.9741 24.0688 66.8277 23.9223L65.8277 22.9223Z"
        fill="white"
      />
    </svg>
  );
}

export default Icon;
