import {
  Icons,
  size,
  colors,
  mediaQueries,
  EnterpriseDesktopMenuStyles,
  HeaderStyles,
  EnterpriseMobileMenuStyles,
} from '@everlywell/leaves';
import { hexToRGB } from 'common/utils/helpers';
import styled from 'styled-components';

type HeaderProps = {
  shouldHide: boolean;
  showShadow: boolean;
  headerOffsetHeight: number;
};

const CartStub = styled(Icons.Cart)`
  height: ${size.lg}px;
  color: ${colors.green5};
  margin-top: ${size.xs2}px;
  ${mediaQueries.forTabletVerticalUp} {
    /* TODO: use a size variable and update the spacing in leaves to use the same variable */
    margin-left: 14px;
  }
  g path {
    fill: none;
    &:first-child {
      fill: ${colors.green1};
    }
  }
`;

const HeaderWrapper = styled.div<HeaderProps>`
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: ${colors.white};
  transition: transform 0.25s ease-in-out;
  transform: ${(props) =>
    props.shouldHide
      ? `translateY(calc(-98% + ${props.headerOffsetHeight}px))`
      : 'translateY(0)'};
  box-shadow: ${(props) =>
    props.showShadow
      ? `0 9px 9px -9px rgba(${hexToRGB(colors.gray5)}, 0.13)`
      : ''};

  ${mediaQueries.forDesktopUp} {
    transform: ${(props) =>
      props.shouldHide
        ? `translateY(calc(-127% + ${props.headerOffsetHeight}px))`
        : 'translateY(0)'};
  }

  div[class^='StyledCartIcon'] {
    visibility: hidden;
  }
`;

const HeaderActivator = styled.div`
  position: absolute;
  width: 100%;
  opacity: 1;
  bottom: 0;
  height: 0;
  background-color: rgba(${hexToRGB(colors.white)}, 0.1);

  ${mediaQueries.forTabletVerticalUp} {
    bottom: -20px;
    height: 20px;
  }
`;

const DLSHeader = styled.div`
  z-index: 15;
  position: relative;

  &[data-healthplan='true'] {
    [aria-label='Support'] {
      background-color: transparent;

      &:hover,
      &:focus {
        &:after {
          bottom: 0;
          transform: translateY(3px);
        }
      }
    }

    ${CartStub} {
      width: 0;
      height: 0;
      visibility: hidden;
    }

    ${HeaderStyles.Header} {
      height: 56px;
    }

    ${EnterpriseDesktopMenuStyles.AccountSubMenu} {
      bottom: -2.5rem;

      > a:not(:last-child) {
        display: none;
      }
    }

    /**
    * Using interpolation tagging per this comment:
    * https://github.com/styled-components/stylelint-processor-styled-components/issues/248#issuecomment-465863019
    */
    ${/* sc-selector */ EnterpriseMobileMenuStyles.MenuTop},
    ${/* sc-selector */ EnterpriseMobileMenuStyles.MenuMiddle} {
      display: none;
    }

    ${EnterpriseMobileMenuStyles.MenuBottom} {
      padding-top: 0;

      ${EnterpriseMobileMenuStyles.LinkCategoryHeading} {
        &:last-of-type ~ a:not(:last-child) {
          display: none;
        }
      }
    }
  }
`;

export { DLSHeader, HeaderWrapper, HeaderActivator, CartStub };
