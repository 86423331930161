import useApi from 'common/hooks/useApi';
import { ANALYTICS } from 'common/utils/constants/analytics';
import {
  ImmutableReduxState,
  PhysicianContact,
  Notification as NotificationType,
} from 'common/utils/types';
import { FormValuesType } from 'components/Forms';
import PhysicianContactForm from 'pages/OldSettingsPage/SettingsForm/PhysicianContactForm';
import { SettingsFormSkeleton } from 'pages/OldSettingsPage/SettingsFormSkeleton';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setNotification, ERROR_NOTIFICATION } from 'store/actions';
import { setPhysicianContact } from 'store/physicianContact/actions';
import {
  makeSelectUserId,
  makeSelectPhysicianContact,
  makeSelectCanUpdatePhysicianContact,
} from 'store/selectors';

type StateProps = {
  physicianContact?: PhysicianContact;
  userId: string;
  canUpdatePhysicianContact: boolean;
};

type DispatchProps = {
  setPhysicianContact: (physicianContact: PhysicianContact) => void;
  setNotification: (notification: NotificationType) => void;
};

type Props = StateProps & DispatchProps;

export function PhysicianContactFormContainer(props: Props) {
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const { updatePhysicianContact } = useApi();
  const { userId, physicianContact, canUpdatePhysicianContact } = props;
  const { pcpFirstLastNameEnabled } = useFlags();

  function handleSubmit(submittedFromValues: FormValuesType) {
    setIsSaving(true);
    updatePhysicianContact(
      userId,
      submittedFromValues as PhysicianContact,
      pcpFirstLastNameEnabled,
    )
      .then((data) => {
        const { physician } = data;
        delete physician.id;
        props.setPhysicianContact(physician);
        props.setNotification({
          message: 'Information saved!',
          source: ANALYTICS.LABELS.PHYSICIAN_CONTACT_FORM,
        });
        setIsSaving(false);
      })
      .catch((errs) => {
        if (errs instanceof Error) {
          props.setNotification({
            message:
              'There was an issue with updating your account settings, please contact Customer Experience.',
            type: ERROR_NOTIFICATION,
            source: ANALYTICS.LABELS.PHYSICIAN_CONTACT_FORM,
          });
        } else {
          setErrors(errs);
        }
        setIsSaving(false);
      });
  }

  if (!physicianContact) {
    return <SettingsFormSkeleton />;
  }

  return (
    <PhysicianContactForm
      errors={errors}
      onSubmit={handleSubmit}
      physicianContact={physicianContact}
      canUpdatePhysicianContact={canUpdatePhysicianContact}
      isSaving={isSaving}
      pcpFirstLastNameEnabled={pcpFirstLastNameEnabled}
    />
  );
}

export default connect(
  (state: ImmutableReduxState) => ({
    physicianContact: makeSelectPhysicianContact()(state),
    userId: makeSelectUserId()(state),
    canUpdatePhysicianContact: makeSelectCanUpdatePhysicianContact()(state),
  }),
  {
    setPhysicianContact,
    setNotification,
  },
)(PhysicianContactFormContainer);
