import React from 'react';

import { RangeLabel } from './RangeLabel';

export type Props = {
  boundaryRange: number;
  descriptors?: string[];
  isClassStyle: boolean;
  markerResultPosition: number;
  rangeColors?: any;
  totalDescriptors: number;
};

export const RangeLabels = (props: Props) => {
  const {
    boundaryRange,
    descriptors,
    isClassStyle,
    markerResultPosition,
    rangeColors,
    totalDescriptors,
  } = props;

  let end = boundaryRange;
  let last = 0;

  return (
    <>
      {descriptors?.map((item: string, index: number) => {
        const component = (
          <RangeLabel
            markerResultPosition={markerResultPosition}
            boundaryRange={boundaryRange}
            descriptor={item}
            index={index}
            isClassStyle={isClassStyle}
            key={`RangeLabel-${index}`}
            last={last}
            rangeColors={rangeColors}
            totalDescriptors={totalDescriptors}
          />
        );
        last = end;
        end += boundaryRange;

        return component;
      })}
    </>
  );
};
