import { size } from '@everlywell/leaves';
import React, { HTMLAttributes, useEffect, useState } from 'react';

import * as S from './AddCardImage.styles';

export type AddCardImageProps = HTMLAttributes<HTMLLabelElement> & {
  id: string;
  name: string;
  label: string;
  onChange: (file: File) => void;
  error?: string;
};

function AddCardImage({
  id,
  name,
  label,
  error,
  onChange,
  ...rest
}: AddCardImageProps) {
  const [selectedFile, setSelectedFile] = useState<File | null | undefined>();
  const [preview, setPreview] = useState<string | undefined>();

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    setSelectedFile(e.target.files[0]);
    onChange(e.target.files[0]);
  };

  return (
    <div>
      <S.InsuranceCard hasError={Boolean(error)} htmlFor={id} {...rest}>
        <S.FileInput
          id={id}
          name={name}
          type="file"
          accept="image/*"
          onChange={onSelectFile}
        />

        {selectedFile && <img src={preview} alt={selectedFile.name} />}
        {!selectedFile && (
          <div style={{ padding: `${size.md}px` }}>
            <S.InsuranceCardAddIcon>
              <S.PlusIcon>+</S.PlusIcon>
            </S.InsuranceCardAddIcon>
            <S.InsuranceCardText>{label}</S.InsuranceCardText>
          </div>
        )}
      </S.InsuranceCard>
      <S.Error>{error}</S.Error>
    </div>
  );
}

export default AddCardImage;
