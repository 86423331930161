import { Accordion, Col, Container } from '@everlywell/leaves';
import analytics, { TrackVisibilityWrapper } from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { CARE_PATHWAYS_DASHBOARD } from 'common/utils/constants/dataTest';
import { sanitize } from 'common/utils/domHelpers';
import { ContentSectionType } from 'common/utils/types';
import * as S from 'components/FAQSection/FAQSection.styles';
import SectionWrapper from 'components/SectionWrapper';
import React from 'react';

import CONTENT from './FAQSection.content';

export interface FAQSectionProps {
  title?: string;
  listOfContentSectionsCollection?: { items: ContentSectionType[] };
  handleFAQClick?: Function;
}

const FAQSection = ({
  listOfContentSectionsCollection,
  title = CONTENT.title,
  handleFAQClick = (question: string, index: number) => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: `${ANALYTICS.LABELS.CARE_DASHBOARD.FAQ_SECTION.question} ${index}`,
        question,
      },
    });
  },
}: FAQSectionProps) => {
  const questions = listOfContentSectionsCollection?.items || [];
  return questions.length > 0 ? (
    <TrackVisibilityWrapper
      label={ANALYTICS.LABELS.CARE_DASHBOARD.FAQ_SECTION.label}
    >
      <SectionWrapper
        id="faq"
        data-test-id={CARE_PATHWAYS_DASHBOARD.FAQ_SECTION}
      >
        <Container>
          <S.Row>
            <S.SectionHeader>{title}</S.SectionHeader>
          </S.Row>
          <S.Row>
            <Col>
              {questions?.map(
                (
                  { title, description, sectionId }: ContentSectionType,
                  index: number,
                ) => (
                  <Accordion.Wrapper key={sectionId}>
                    <Accordion.Item>
                      <S.Heading onClick={handleFAQClick(title, index)}>
                        <S.Title>{title}</S.Title>
                      </S.Heading>
                      <S.Child>
                        <S.Content
                          dangerouslySetInnerHTML={{
                            __html: sanitize(description),
                          }}
                        />
                      </S.Child>
                    </Accordion.Item>
                  </Accordion.Wrapper>
                ),
              )}
            </Col>
          </S.Row>
        </Container>
      </SectionWrapper>
    </TrackVisibilityWrapper>
  ) : null;
};

export default FAQSection;
