import React from 'react';

export default function TopIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="69"
      height="60"
      viewBox="0 0 69 60"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2656 59.3045L34.2656 30.0449L0.265625 30.0449C0.265624 46.2046 15.4879 59.3045 34.2655 59.3045L34.2656 59.3045Z"
        fill="#007377"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.2656 59.3045L68.2656 30.0449L34.2656 30.0449C34.2656 46.2046 49.4879 59.3045 68.2655 59.3045L68.2656 59.3045Z"
        fill="#1EA676"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.265624 0.785308L0.265621 30.0449L34.2656 30.0449C34.2656 13.8853 19.0432 0.785308 0.265624 0.785308Z"
        fill="#C9E9ED"
      />
    </svg>
  );
}
