import useApi from 'common/hooks/useApi';
import { userStateNameToId } from 'common/utils/helpers';
import { getParameterByName } from 'common/utils/helpers';
import { ImmutableReduxState, User, ShippingAddress } from 'common/utils/types';
import { FormValuesType } from 'components/Forms';
import Layout from 'components/Layout';
import ReplacementForm from 'pages/ReplacementForm';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeSelectShippingAddress, makeSelectUserId } from 'store/selectors';
import { setUserData } from 'store/user/actions';

import DesktopGraphic from './images/DesktopGraphic.svg';
import MobileGraphic from './images/MobileGraphic.svg';
import * as S from './styles';

type StateProps = {
  userId: string;
  shippingAddress?: ShippingAddress;
};

type DispatchProps = {
  setUserData: (data: User) => void;
};

type RawUser = {
  actions?: {
    confirm_shipping?: {
      test_name: string;
      enterprise_partner: string;
      reason: string;
    }[];
  };
};

type Props = StateProps & DispatchProps;

export function ReplacementPageContainer(props: Props) {
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState<RawUser>();
  const [isSaving, setIsSaving] = useState(false);
  const { updateShippingAddress } = useApi();
  const [replacement, setReplacement] = useState({});
  const { fetchUserData } = useApi();
  const { shippingAddress, userId } = props;
  const barcodeSerialNumber =
    getParameterByName('barcode_serial_number', window.location.search) ?? '';
  const [inconclusiveResults, setInconclusiveResults] = useState(
    barcodeSerialNumber.length,
  );

  useEffect(() => {
    fetchUserData(userId).then(setUser);
  }, [userId]);

  useEffect(() => {
    if (user) {
      const { actions = {} } = user;
      const { confirm_shipping = [] } = actions;
      if (inconclusiveResults) {
        setReplacement({
          testNames: [],
          reasons: ['Marker Replacement'],
          hasReplacement: true,
        });
        setInconclusiveResults(0);
      } else if (confirm_shipping.length && !barcodeSerialNumber.length) {
        setReplacement({
          testNames: confirm_shipping.map((action) => action.test_name),
          hasOnlyHumana: confirm_shipping.every(
            (action) => action.enterprise_partner === 'humana',
          ),
          reasons: confirm_shipping.map((action) => action.reason),
          hasReplacement: true,
        });
      } else {
        setReplacement({ hasReplacement: false });
      }

      props.setUserData(user as User);
    }
  }, [user]);

  function handleSubmit(formValues: FormValuesType) {
    setIsSaving(true);

    const formmattedFormValues = formValues;
    const isStateName = Number.isNaN(Number(String(formValues.state)));

    if (isStateName) {
      formmattedFormValues.state = userStateNameToId(String(formValues.state));
    }

    updateShippingAddress(
      userId,
      formmattedFormValues as ShippingAddress,
      barcodeSerialNumber,
    )
      .then(setUser)
      .catch((errs) => {
        setErrors(errs);
        setIsSaving(false);
      });
  }

  return (
    <Layout>
      <S.ReplacementWrapper>
        <S.DesktopGraphic src={DesktopGraphic} aria-hidden="true" />
        <S.ContentWrapper>
          <S.ContentContainer>
            <ReplacementForm
              replacement={replacement}
              errors={errors}
              onSubmit={handleSubmit}
              shippingAddress={shippingAddress}
              isSaving={isSaving}
            />
          </S.ContentContainer>
        </S.ContentWrapper>
        <S.MobileGraphic src={MobileGraphic} aria-hidden="true" />
      </S.ReplacementWrapper>
    </Layout>
  );
}

export default connect(
  (state: ImmutableReduxState): StateProps => ({
    userId: makeSelectUserId()(state),
    shippingAddress: makeSelectShippingAddress()(state),
  }),
  {
    setUserData,
  },
)(ReplacementPageContainer);
