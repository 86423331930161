import { size, colors, typography, Button, Icons } from '@everlywell/leaves';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  border-radius: ${size.xs1}px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: ${size.md}px;
  margin-bottom: ${size.lg}px;
`;

export const AccordionButton = styled(Button)`
  display: flex;
  padding: 0;
  width: 26px;
  height: 26px;
  margin: 0;
  align-items: center;
  justify-content: center;
  background-color: ${colors.green1};
`;

export const AccordionIcon = styled(Icons.DownCaret)<{
  isExpanded: boolean;
}>`
  margin: 0 !important;
  width: ${size.md}px !important;
  height: ${size.xs1}px !important;
  transition: transform 0.3s ease-in-out;
  ${({ isExpanded }) =>
    isExpanded
      ? css`
          transform: rotate(180deg);
        `
      : css``}
`;

export const AccordionChild = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
  max-height: 0;
  ${({ isExpanded }) =>
    isExpanded
      ? css`
          transition: max-height 1s ease-in-out;
          /**
             Animating the height from a specific value to auto using CSS animations is not straightforward
            since auto is not an animatable value. However, we can achieve a similar effect by
            animating the maximum height of the div instead and setting the max height to a large value.
          */
          max-height: 1000px;
        `
      : css``};
`;

export const AccordionHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

export const BadgeContainer = styled.div`
  display: flex;
  margin-bottom: ${size.md}px;
  justify-content: space-between;
  width: 100%;
`;

export const Badge = styled.p`
  ${typography.bodyTextSmall};
  display: flex;
  padding: ${size.xs2}px ${size.sm}px;
  justify-content: center;
  align-items: center;
  border-radius: ${size.md}px;
  border: 1px solid ${colors.green2};
  background-color: ${colors.green1};
  color: ${colors.green5};
`;

export const ProductName = styled.p`
  ${typography.bodyText};
  color: ${colors.gray5};
  margin-bottom: ${size.xs1}px;
  text-align: left;
`;

export const HeaderLabel = styled.p`
  ${typography.captionText};
  color: ${colors.gray4};
`;

export const ChildLabel = styled.p`
  ${typography.buttonTextSmall};
  color: ${colors.gray4};
`;

export const Separator = styled.hr`
  border: 1px solid ${colors.teal1};
  margin: ${size.md}px 0;
`;

export const Directions = styled.p`
  ${typography.bodyTextSmall};
  color: ${colors.gray4};
  margin: ${size.xs1}px 0 ${size.md}px 0;
`;

export const PharmacyContainer = styled.div`
  border-radius: ${size.xs2}px;
  background-color: ${colors.gray1};
  padding: ${size.md}px;
`;
