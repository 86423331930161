import { Row } from '@everlywell/leaves';
import { COVID_SCREENER_STEPS } from 'common/utils/constants/rumActions';
import { isDateValid, useFormattedDate } from 'common/utils/helpers';
import {
  AdditionalDose,
  CovidVaccineData,
  InputError,
  KitRegistrationUser,
} from 'common/utils/types';
import useTimeTracker from 'common/utils/useTimeTracker';
import { isEmpty } from 'lodash';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useEffectOnce } from 'react-use';

import * as BaseS from '../../../BaseQuestion/styles';
import { BackButton, NextButton, LeftCaretIcon } from '../styles';
import * as S from './styles';

export interface Props {
  user: KitRegistrationUser;
  totalQuestions: number;
  handleNext: (action: any) => void;
  handleBack: (action: any) => void;
  questionNumber: number;
}

export type Entry = AdditionalDose;
type OtherVaccine = {
  value: string;
  isSelected: boolean;
};
type VaccineProductAnswers = (
  | {
      id: string;
      label: string;
    }
  | {
      id: string;
      label: JSX.Element;
    }
)[];

function doseCopy(dose: number): string {
  switch (dose) {
    case 1:
      return `${dose}st `;
    case 2:
      return `${dose}nd `;
    case 3:
      return `${dose}rd `;
    default:
      return `${dose}th `;
  }
}

function castBackToEntries(covidVaccineData: CovidVaccineData): Entry[] {
  let entries: Entry[] = [];
  const keys = Object.keys(covidVaccineData);
  const keysL = keys.length;

  let firstDose: Entry | {} = {};
  let secondDose: Entry | {} = {};

  for (let i = 0; i < keysL; i++) {
    const key: string = keys[i];

    switch (key) {
      case 'first_dose_date': {
        (firstDose as Entry)['date'] =
          covidVaccineData.first_dose_date as string;
        (firstDose as Entry)['dose'] = 1;

        break;
      }
      case 'name': {
        (firstDose as Entry)['name'] = covidVaccineData.name as string;
        break;
      }
      case 'second_dose_date': {
        (secondDose as Entry)['date'] =
          covidVaccineData.second_dose_date as string;
        (secondDose as Entry)['dose'] = 2;
        break;
      }
      case 'second_dose_name': {
        (secondDose as Entry)['name'] =
          covidVaccineData.second_dose_name as string;
        break;
      }
      case 'additional_doses':
        covidVaccineData.additional_doses?.forEach((additionalDosis) => {
          entries.push({
            date: additionalDosis.date,
            dose: additionalDosis.dose,
            name: additionalDosis.name,
          });
        });
        break;
    }
  }

  if (!isEmpty(secondDose)) {
    entries.unshift(secondDose as Entry);
  }
  if (!isEmpty(firstDose)) {
    entries.unshift(firstDose as Entry);
  }
  return entries;
}

function CovidVaccineDoses(
  { user, handleBack, handleNext, totalQuestions, questionNumber }: Props,
  { dose = 0, name, date }: Entry,
) {
  const [, setDateIsValid] = useState<boolean>(false);
  const [allEntries, setAllEntries] = useState<Entry[]>([]);
  const [currentDose, setCurrentDose] = useState<number>(0);
  const [entryDate, setEntryDate] = useState<string>(date || '');
  const [error, setError] = useState<InputError>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [otherVaccine, setOtherVaccine] = useState<OtherVaccine>({
    value: '',
    isSelected: false,
  });
  const [vaccineName, setVaccineName] = useState<string | JSX.Element>(
    name || '',
  );
  const [vaccineDose, setVaccineDose] = useState<number>(
    user.covidVaccineData?.value?.doses ?? 0,
  );
  const formatFirstDate = useFormattedDate();
  const {
    covidVaccineData: {
      setValue: setCovidVaccineData,
      value: initialCovidVaccineValue,
    },
  } = user;
  const fireAction = useTimeTracker(COVID_SCREENER_STEPS.VACCINE_DOSES);

  const vaccineProductAnswers: VaccineProductAnswers = [
    {
      id: 'moderna',
      label: 'Moderna',
    },
    {
      id: 'pfizer',
      label: 'Pfizer',
    },
    {
      id: 'johnson_and_johnson',
      label: 'Johnson & Johnson',
    },
    {
      id: 'other',
      label: (
        <S.OtherLabel
          id={'other-product'}
          name={'other-product'}
          type="text"
          label="Other"
          placeholder="Product Name"
          onChange={(event) => {
            const value = event.currentTarget.value;
            setOtherVaccine({ isSelected: otherVaccine.isSelected, value });
          }}
          value={otherVaccine.value}
        />
      ),
    },
  ];

  useEffectOnce(() => {
    if ((initialCovidVaccineValue?.doses || 0) > 0) {
      const entries = castBackToEntries(initialCovidVaccineValue);
      setAllEntries(entries);
    }
  });

  const castData = useCallback(
    (initVal = { additional_doses: [], received: '' }): CovidVaccineData => {
      let covidVaccineData: CovidVaccineData = initVal;
      for (const entry of allEntries) {
        switch (entry.dose) {
          case 1: {
            covidVaccineData = {
              ...covidVaccineData,
              first_dose_date: entry.date,
              name: entry.name as string,
            };
            break;
          }
          case 2: {
            covidVaccineData = {
              ...covidVaccineData,
              second_dose_date: entry.date,
              second_dose_name: entry.name as string,
            };
            break;
          }
          default: {
            const { additional_doses, ...rest } = covidVaccineData;
            const additionalDoses = additional_doses?.concat([entry]);
            covidVaccineData = {
              ...rest,
              additional_doses: additionalDoses,
            };
            break;
          }
        }
      }

      covidVaccineData.doses = allEntries.length;
      return covidVaccineData;
    },
    [allEntries],
  );

  useEffect(() => {
    if (allEntries.length > 0) {
      const covidVaccineData = castData();
      setCovidVaccineData(covidVaccineData);
    }
    // TODO check if hooks dependencies
  }, [allEntries, castData, setCovidVaccineData]);

  function backCallbackAction(
    setTotalQuestions: Dispatch<SetStateAction<number>>,
  ) {
    fireAction();
    if (user.vaccinated.value === 'yes-vaccinated') {
      setTotalQuestions(totalQuestions - 1);
    }
  }

  function resetAndClose(): void {
    setEntryDate(() => '');
    setIsEditing(() => false);
    setOpenModal(false);
    setOtherVaccine({ isSelected: false, value: '' });
    setVaccineName(() => '');
  }

  function isAnotherVaccine(vaccineName: string): boolean {
    let isAnotherVaccine = true;
    for (const vaccine of vaccineProductAnswers) {
      if (vaccine.id === 'other') continue;
      if (vaccine.label === vaccineName) {
        isAnotherVaccine = false;
      }
    }
    return isAnotherVaccine;
  }
  function handleEdit(entry: Entry, currentDose: number) {
    setCurrentDose(() => currentDose);
    setEntryDate(() => entry.date);
    setIsEditing(() => true);
    setOpenModal(() => true);
    if (isAnotherVaccine(entry.name as string)) {
      setVaccineName(() => 'other-product');
      setOtherVaccine({ isSelected: true, value: entry.name as string });
    } else {
      setVaccineName(() => entry.name);
    }
  }

  function handleAddButton() {
    setOpenModal(true);
  }

  function handleInput(value: string) {
    const formattedFirstDate = formatFirstDate(value);
    const error: InputError = isDateValid(
      formattedFirstDate,
      new Date(2020, 10, 1),
    );
    setDateIsValid(!error);
    setError(error);
    setEntryDate(formattedFirstDate);
  }

  function handleDelete() {
    const filteredAndReorderEntries = allEntries
      .filter((entry) => entry.dose !== currentDose)
      .map((entry, index) => ({ ...entry, dose: index + 1 }));

    setAllEntries(filteredAndReorderEntries);
    if (vaccineDose > 0) {
      setVaccineDose(vaccineDose - 1);
    }
    resetAndClose();
  }

  function handleSave(label: string | JSX.Element, date: string) {
    const name = otherVaccine.isSelected ? otherVaccine.value : label;

    if (isEditing) {
      const entry: Entry = { dose: currentDose, name, date };
      setAllEntries((allEntries: Entry[]) =>
        allEntries.map((e) => (e.dose === currentDose ? entry : e)),
      );
    } else {
      const newVaccineDose = vaccineDose + 1;
      setVaccineDose(newVaccineDose);
      const entry: Entry = { dose: newVaccineDose, name, date };
      setAllEntries((allEntries: Entry[]) => {
        const newEntries = allEntries.concat(entry);
        return newEntries;
      });
    }
    resetAndClose();
  }

  function isNextDisabled(): boolean {
    return allEntries.length === 0;
  }

  function isDisabled(): boolean {
    return (
      error !== '' ||
      entryDate === '' ||
      vaccineName === '' ||
      (otherVaccine.isSelected && otherVaccine.value === '')
    );
  }

  return (
    <Row>
      <S.Column>
        <BackButton onClick={() => handleBack(backCallbackAction)}>
          <LeftCaretIcon /> Back
        </BackButton>
        <BaseS.QuestionNumber>
          {questionNumber} of {totalQuestions}
        </BaseS.QuestionNumber>
        <S.Column>
          <S.VaccineEntryH4>
            Please add the date and product for every dose you've received
          </S.VaccineEntryH4>
        </S.Column>

        {allEntries.length > 0
          ? allEntries.map((entry: any, currentDose: number) => (
              <S.VaccineEntry>
                <S.VaccineEntryContentWrapper>
                  <S.DoseNumber>{entry.dose}</S.DoseNumber>
                  <S.DosesCopy>
                    <S.VaccineShotText>
                      {doseCopy(entry.dose)}
                      vaccine shot
                    </S.VaccineShotText>
                    <S.VaccineDetailsText>{entry.name}</S.VaccineDetailsText>
                    <S.VaccineDetailsText>{entry.date}</S.VaccineDetailsText>
                  </S.DosesCopy>
                  <S.EditButton
                    onClick={() => handleEdit(entry, currentDose + 1)}
                  >
                    Edit
                  </S.EditButton>
                </S.VaccineEntryContentWrapper>
              </S.VaccineEntry>
            ))
          : null}
        <S.EntryContainer>
          <S.EntryContentWrapper>
            Vaccine Dose
            <S.AddButton onClick={handleAddButton}>Add</S.AddButton>
          </S.EntryContentWrapper>
        </S.EntryContainer>
      </S.Column>
      {openModal && (
        <S.EntryModal open={openModal} openHandler={resetAndClose} persist>
          <BaseS.AnswerBox>
            <S.Question>Which vaccine product did you receive?</S.Question>
            <S.RadioButtonsContainer>
              {vaccineProductAnswers.map((answer) => {
                const label =
                  (answer.label as JSX.Element)?.props?.id ?? answer.label;
                const isChecked = vaccineName === label;
                const className =
                  (answer.label as JSX.Element)?.props?.id === undefined
                    ? ''
                    : 'other-radio-button';
                return (
                  <BaseS.Answer
                    id={answer.id}
                    className={className}
                    checked={isChecked}
                    key={answer.id}
                    label={answer.label}
                    name={answer.id}
                    onChange={() => {
                      if (answer.id === 'other') {
                        setVaccineName((answer.label as JSX.Element).props.id);
                        setOtherVaccine({ isSelected: true, value: '' });
                      } else {
                        setVaccineName(answer.label);
                        setOtherVaccine({ isSelected: false, value: '' });
                      }
                    }}
                  />
                );
              })}
            </S.RadioButtonsContainer>
          </BaseS.AnswerBox>

          <BaseS.AnswerBox>
            <S.Question2>
              What is the date you received your vaccine?
            </S.Question2>

            <BaseS.VaccineDate
              id="vaccine-date"
              error={error}
              label="Date"
              name="vaccine date"
              onChange={(e: { target: { value: string } }) =>
                handleInput(e.target.value)
              }
              placeholder="MM/DD/YYYY"
              type="text"
              value={entryDate}
            />
          </BaseS.AnswerBox>
          <S.ButtonsContainer>
            <S.RemoveButton appearance="secondary" onClick={handleDelete}>
              Remove
            </S.RemoveButton>

            <S.SaveButton
              isDisabled={isDisabled()}
              appearance="primary"
              onClick={() => handleSave(vaccineName, entryDate)}
            >
              Save
            </S.SaveButton>
          </S.ButtonsContainer>
        </S.EntryModal>
      )}
      <NextButton
        appearance="primary"
        onClick={() => handleNext(fireAction)}
        isDisabled={isNextDisabled()}
      >
        Next
      </NextButton>
    </Row>
  );
}
export default CovidVaccineDoses;
