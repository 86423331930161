import { mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

const StatusCode = styled.div`
  width: 300px;
  height: 164px;
  margin: 0 auto 32px;

  ${mediaQueries.forTabletVerticalUp} {
    width: 402px;
    height: 218px;
  }

  img {
    max-width: 100%;
  }
`;

export { StatusCode };
