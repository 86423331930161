import { Col_10 as Col10, Col_8 as Col8 } from 'common/styles/grid-styles';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { COMMON_QUESTIONS } from 'common/utils/constants/dataTest';
import { KitResult } from 'common/utils/types';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import CommonQuestionsSectionContent from 'containers/CommonQuestionsSection/ContentContainer';
import CommonQuestionsSectionHeader from 'containers/CommonQuestionsSection/HeaderContainer';
import * as React from 'react';

import * as S from './styles';

type Props = {
  contentToken: string;
  kitResult: KitResult;
};

function CommonQuestionsSection(props: Props) {
  const { contentToken, kitResult } = props;

  const commonQuestionsEl = useScrolledIntoViewOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        'Test Name': kitResult && kitResult.test && kitResult.test.name,
        page: ANALYTICS.PAGES.KIT_RESULT,
        category: ANALYTICS.CATEGORIES.COMMON_QUESTIONS,
      },
    });
  });
  return (
    <S.Wrapper
      ref={commonQuestionsEl}
      id="common-questions-section"
      data-test={COMMON_QUESTIONS.SECTION}
    >
      <div>
        <Col8>
          <CommonQuestionsSectionHeader contentToken={contentToken} />
        </Col8>
        <Col10>
          <CommonQuestionsSectionContent contentToken={contentToken} />
        </Col10>
      </div>
    </S.Wrapper>
  );
}

export default CommonQuestionsSection;
