import { SkeletonLoader, SkeletonUnit } from '@everlywell/leaves';
import React from 'react';
import Grid from 'components/Grid';

const DemographicsSkeleton = () => (
  <Grid.Container spacing={['lg']}>
    <Grid.Item width={[1]}>
      <SkeletonLoader
        width={{
          value: 250,
          unit: SkeletonUnit.Pixel,
        }}
        height={{
          value: 50,
          unit: SkeletonUnit.Pixel,
        }}
      />
    </Grid.Item>
    <Grid.Item width={[1]}>
      <SkeletonLoader
        width={{
          value: 120,
          unit: SkeletonUnit.Pixel,
        }}
        height={{
          value: 20,
          unit: SkeletonUnit.Pixel,
        }}
      />
      <SkeletonLoader
        width={{
          value: 100,
          unit: SkeletonUnit.Percentage,
        }}
        height={{
          value: 50,
          unit: SkeletonUnit.Pixel,
        }}
      />
    </Grid.Item>
    <Grid.Item width={[1]}>
      <SkeletonLoader
        width={{
          value: 120,
          unit: SkeletonUnit.Pixel,
        }}
        height={{
          value: 20,
          unit: SkeletonUnit.Pixel,
        }}
      />
      <SkeletonLoader
        width={{
          value: 100,
          unit: SkeletonUnit.Percentage,
        }}
        height={{
          value: 50,
          unit: SkeletonUnit.Pixel,
        }}
      />
    </Grid.Item>
    <Grid.Item width={[1]}>
      <SkeletonLoader
        width={{
          value: 120,
          unit: SkeletonUnit.Pixel,
        }}
        height={{
          value: 20,
          unit: SkeletonUnit.Pixel,
        }}
      />
      <SkeletonLoader
        width={{
          value: 100,
          unit: SkeletonUnit.Percentage,
        }}
        height={{
          value: 50,
          unit: SkeletonUnit.Pixel,
        }}
      />
    </Grid.Item>
    <Grid.Item width={[1]}>
      <SkeletonLoader
        width={{
          value: 120,
          unit: SkeletonUnit.Pixel,
        }}
        height={{
          value: 20,
          unit: SkeletonUnit.Pixel,
        }}
      />
      <SkeletonLoader
        width={{
          value: 100,
          unit: SkeletonUnit.Percentage,
        }}
        height={{
          value: 50,
          unit: SkeletonUnit.Pixel,
        }}
      />
    </Grid.Item>

    <Grid.Item width={[1, 1 / 3]}>
      <SkeletonLoader
        width={{
          value: 120,
          unit: SkeletonUnit.Pixel,
        }}
        height={{
          value: 20,
          unit: SkeletonUnit.Pixel,
        }}
      />
      <SkeletonLoader
        width={{
          value: 100,
          unit: SkeletonUnit.Percentage,
        }}
        height={{
          value: 50,
          unit: SkeletonUnit.Pixel,
        }}
      />
    </Grid.Item>
    <Grid.Item width={[1 / 2, 1 / 3]}>
      <SkeletonLoader
        width={{
          value: 120,
          unit: SkeletonUnit.Pixel,
        }}
        height={{
          value: 20,
          unit: SkeletonUnit.Pixel,
        }}
      />
      <SkeletonLoader
        width={{
          value: 100,
          unit: SkeletonUnit.Percentage,
        }}
        height={{
          value: 50,
          unit: SkeletonUnit.Pixel,
        }}
      />
    </Grid.Item>
    <Grid.Item width={[1 / 2, 1 / 3]}>
      <SkeletonLoader
        width={{
          value: 120,
          unit: SkeletonUnit.Pixel,
        }}
        height={{
          value: 20,
          unit: SkeletonUnit.Pixel,
        }}
      />
      <SkeletonLoader
        width={{
          value: 100,
          unit: SkeletonUnit.Percentage,
        }}
        height={{
          value: 50,
          unit: SkeletonUnit.Pixel,
        }}
      />
    </Grid.Item>

    <Grid.Item width={[1]}>
      <SkeletonLoader
        width={{
          value: 100,
          unit: SkeletonUnit.Percentage,
        }}
        height={{
          value: 50,
          unit: SkeletonUnit.Pixel,
        }}
      />
    </Grid.Item>
  </Grid.Container>
);

export default DemographicsSkeleton;
