import { Button } from '@everlywell/leaves';
import React from 'react';

import * as S from './RedemptionConfirmation.styles';

export type RedemptionConfirmationProps = {
  title: string;
  header: string;
  subHeader: string;
  productImageUrl: string;
  productName: string;
  deliveryEstimation: string;
  buttonText: string;
  buttonCallback: () => void;
};

/**
 * Confirmation step for a credit redemption feature
 */
export default function RedemptionConfirmation({
  title,
  header,
  subHeader,
  productImageUrl,
  productName,
  deliveryEstimation,
  buttonText,
  buttonCallback,
}: RedemptionConfirmationProps) {
  return (
    <S.Container>
      <S.DetailsWrapper>
        <S.Title>{title}</S.Title>
        <S.Header>{header}</S.Header>
        <S.SubHeader>{subHeader}</S.SubHeader>
        <S.ProductWrapper>
          <S.ImageWrapper>
            <S.ProductImage src={productImageUrl} alt={productName} />
          </S.ImageWrapper>
          <S.ProductDetailsWrapper>
            <S.ProductName>{productName}</S.ProductName>
            <S.DeliveryEstimation>{deliveryEstimation}</S.DeliveryEstimation>
          </S.ProductDetailsWrapper>
        </S.ProductWrapper>
      </S.DetailsWrapper>
      <S.ButtonWrapper>
        <Button onClick={() => buttonCallback()}>{buttonText}</Button>
      </S.ButtonWrapper>
    </S.Container>
  );
}
