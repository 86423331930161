import GetHelpSectionHeaderContainer from 'containers/GetHelpSection/HeaderContainer';
import * as React from 'react';

type Props = {
  contentToken: string;
  headerIcon?: string;
};

function GetHelpSection(props: Props) {
  const { contentToken, headerIcon } = props;
  return <GetHelpSectionHeaderContainer contentToken={contentToken} headerIcon={headerIcon} />;
}

export default GetHelpSection;
