import {
  Checkbox,
  Col,
  H4,
  Input,
  ProgressBar,
  RadioButton,
  TextArea,
  colors,
  mediaQueries,
  size,
  typography,
  Row,
} from '@everlywell/leaves';
import styled from 'styled-components';

const Column = styled(Col)`
  &:first-of-type {
    margin-top: ${size.xs1}px;
  }
  &:last-of-type {
    margin-bottom: ${size.xs1}px;
  }
`;

const QuestionContainer = styled(Row)`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 600px;
`;

const Question = styled(H4)`
  color: ${colors.teal6};
  font-size: ${size.lg}px;
  font-weight: ${typography.weight.light};
  margin-bottom: ${size.lg}px;
  text-align: left;
  width: 100%;
`;

const SubText = styled.p`
  color: ${colors.gray4};
  font-size: ${size.md}px;
  font-weight: ${typography.weight.light};
  margin-top: -20px;
  margin-bottom: ${size.sm}px;
  text-align: left;
  width: 90%;
`;

const SingleAnswer = styled(RadioButton)`
  background-color: ${({ checked }) => (checked ? '#f5faf7' : colors.white)};
  border-radius: ${size.xs3}px;
  border: solid 1px ${colors.green2};
  box-shadow: ${({ checked }) =>
    checked ? `0 ${size.xs1}px ${size.xl1}px -${size.sm}px #dce4e5` : null};
  height: auto;
  margin: ${size.xs1}px 0;
  padding-left: ${size.md}px;
  width: 100%;

  input {
    cursor: pointer;
    height: 100%;
    margin-left: -${size.md}px;
    width: 100%;
    z-index: 1;
  }
  label {
    height: auto;
    margin: ${size.md}px 0;
  }
`;

const MultiAnswer = styled(Checkbox)`
  margin: ${size.xs1}px 0;
`;

const TextAreaWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const FreeResponseTextArea = styled(TextArea)`
  margin: ${size.md}px -60px 0;
  padding: 10px ${size.md}px;
  width: 500px;

  textarea {
    overflow: hidden;
    resize: none;
  }

  ${mediaQueries.forPhoneOnly} {
    width: 340px;
  }
`;

const Aside = styled.aside`
  margin: 9px 0;
  position: absolute;
  text-align: right;
`;

const CharacterCounter = styled.span`
  color: ${colors.gray3};
  font-size: 14px;
  font-weight: ${typography.weight.book};
  line-height: 23px;
`;

const WeightInput = styled(Input)`
  width: 100%;
  svg {
    fill: none;
  }
`;

const HeightWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const HeightInput = styled(Input)`
  width: 100%;
  margin-right: ${size.lg}px;
  svg {
    fill: none;
  }
`;

const HealthProfileProgressBar = styled(ProgressBar)`
  margin: auto;
  max-width: 285px;
  position: stick;
  top: 0;
`;

export {
  Aside,
  CharacterCounter,
  Column,
  FreeResponseTextArea,
  HealthProfileProgressBar,
  HeightInput,
  HeightWrapper,
  MultiAnswer,
  QuestionContainer,
  Question,
  SingleAnswer,
  SubText,
  TextAreaWrapper,
  WeightInput,
};
