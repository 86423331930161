function formatZipCode(value: string) {
  if (value.length > 5) {
    return value.replace(/(\d{5})(\d{0,4})/, '$1-$2');
  }

  return value;
}

function isValidZipCode(value: string) {
  return /(^\d{5}$)|(^\d{5}\d{4}$)/.test(value);
}

export { formatZipCode, isValidZipCode };
