import { Col, Localize } from '@everlywell/leaves';
import { COVID_SCREENER_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';
import { COVID_SCREENER_STEPS } from 'common/utils/constants/rumActions';
import { KitRegistrationUser } from 'common/utils/types';
import useTimeTracker from 'common/utils/useTimeTracker';
import React, { useState } from 'react';

import BaseQuestion from '../../../BaseQuestion';
import ScreenerNote from '../ScreenerNote';
import SevereNote from '../SevereNote';
import * as S from '../styles';
import SymptomsDateModal from '../SymptomsDateModal';

interface Props {
  user: KitRegistrationUser;
  totalQuestions: number;
  questionNumber: number;
  handleNext: (action: any) => void;
  handleBack: (action: any) => void;
}
const SymptomsQuestion = ({
  user,
  totalQuestions,
  questionNumber,
  handleNext,
}: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [dateIsValid, setDateIsValid] = useState(false);
  const fireAction = useTimeTracker(COVID_SCREENER_STEPS.DESCRIBE_SYMPTOMS);
  const routeNext = () => {
    if (user.symptoms.value !== 'no-symptoms') {
      setOpenModal(true);
    } else {
      handleNext(fireAction);
    }
  };
  const handleModalClose = () => {
    if (openModal && dateIsValid) {
      handleNext(fireAction);
    }
    setOpenModal(false);
  };
  const answers = [
    {
      id: 'no-symptoms',
      label: <p>No symptoms or symptoms not listed</p>,
    },
    {
      id: 'mild',
      label: (
        <ul>
          <li>
            Fever between{' '}
            <Localize name="COVID-symptomsScreener-fever-betweenStart">
              100.4
            </Localize>{' '}
            and{' '}
            <Localize name="COVID-symptomsScreener-fever-betweenEnd">
              102F
            </Localize>
          </li>
          <li>A new or worsening cough</li>
          <li>A new or worsening sore throat</li>
          <li>
            Flu-like symptoms (chills, runny or stuffy nose, whole body aches, a
            headache and/or feeling tired)
          </li>
          <li>
            Shortness of breath (not limiting your ability to speak) or a new
            loss of taste or smell
          </li>
        </ul>
      ),
    },
    {
      id: 'severe',
      label: (
        <ul>
          <li>
            Fever over{' '}
            <Localize name="COVID-symptomsScreener-fever-over">102F</Localize>{' '}
            or a high fever lasting more than{' '}
            <Localize name="COVID-symptomsScreener-48-hours">48</Localize> hours
          </li>
          <li>
            Inability to speak in full sentences or do simple activities without
            shortness of breath
          </li>
          <li>Severe coughing spells or coughing up blood</li>
          <li>Blue face or lips</li>
          <li>
            Severe and constant pain or pressure in your chest extreme lethargy,
            dizziness, lightheadedness or being too weak to stand,
          </li>
          <li>Slurred speech or seizures</li>
          <li>Being unable to stay at home due to being too sick</li>
        </ul>
      ),
    },
  ];
  return (
    <Col>
      <BaseQuestion
        question="What best describes your symptoms?"
        questionNumber={questionNumber}
        totalQuestions={totalQuestions}
        answers={answers}
        value={user.symptoms.value}
        setValue={user.symptoms.setValue}
      />
      {user.symptoms.value === 'severe' && <SevereNote addMargin />}
      <ScreenerNote />

      <S.NextButton
        isDisabled={user.symptoms.value === ''}
        appearance="primary"
        onClick={routeNext}
        data-test={`${DATA_TEST.BUTTON}-next`}
      >
        Next
      </S.NextButton>
      {openModal && (
        <SymptomsDateModal
          openModal={openModal}
          setOpenModal={handleModalClose}
          value={user.symptomsDate.value}
          setValue={user.symptomsDate.setValue}
          setDateIsValid={setDateIsValid}
        />
      )}
    </Col>
  );
};

export default SymptomsQuestion;
