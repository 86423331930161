import { Col } from '@everlywell/leaves';
import { COVID_SCREENER_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';
import { COVID_SCREENER_STEPS } from 'common/utils/constants/rumActions';
import { KitRegistrationUser } from 'common/utils/types';
import useTimeTracker from 'common/utils/useTimeTracker';
import React, { Dispatch, SetStateAction, useRef } from 'react';

import BaseQuestion from '../../../BaseQuestion';
import * as S from '../styles';

export type SetTotalQuestions = Dispatch<SetStateAction<number>>;
interface Props {
  user: KitRegistrationUser;
  totalQuestions: number;
  questionNumber: number;
  handleNext: (action: any, step: number) => void;
  handleBack: (action: any) => void;
}

const CovidVaccineQuestion = ({
  user,
  totalQuestions,
  questionNumber,
  handleNext,
  handleBack,
}: Props) => {
  const hasNotUpdatedTotalQuestions = useRef<boolean>(true);

  const fireAction = useTimeTracker(COVID_SCREENER_STEPS.VACCINE);

  function nextCallbackAction(setTotalQuestions: SetTotalQuestions) {
    fireAction();
    if (
      hasNotUpdatedTotalQuestions &&
      user.vaccinated.value === 'yes-vaccinated'
    ) {
      hasNotUpdatedTotalQuestions.current = false;
      setTotalQuestions(totalQuestions + 1);
    }
  }

  const answers = [
    {
      id: 'yes-vaccinated',
      label: 'Yes',
    },
    {
      id: 'not-vaccinated',
      label: 'No',
    },
    {
      id: 'prefer-not-to-answer',
      label: 'Prefer not to answer',
    },
  ];

  return (
    <Col>
      <S.BackButton
        data-test={`${DATA_TEST.BUTTON}-back`}
        onClick={() => handleBack(fireAction)}
      >
        <S.LeftCaretIcon /> Back
      </S.BackButton>
      <BaseQuestion
        question="Have you ever received a dose of a COVID-19 vaccine?"
        questionNumber={questionNumber}
        totalQuestions={totalQuestions}
        answers={answers}
        value={user.vaccinated.value}
        setValue={user.vaccinated.setValue}
        className="vaccinated"
      />

      <S.NextButton
        isDisabled={user.vaccinated.value === ''}
        appearance="primary"
        data-test={`${DATA_TEST.BUTTON}-next`}
        onClick={() => {
          const step = user.vaccinated.value === 'yes-vaccinated' ? 1 : 2;
          handleNext(nextCallbackAction, step);
        }}
      >
        Next
      </S.NextButton>
    </Col>
  );
};
export default CovidVaccineQuestion;
