import { SkeletonLoader, SkeletonUnit } from '@everlywell/leaves';
import { TelehealthMedication } from 'common/apis/telehealthApis';
import useMedications from 'common/hooks/useMedications/useMedications';
import React, { useEffect, useRef, useState } from 'react';

import ErrorCard from '../ErrorCard';
import * as S from './MyPrescriptions.styles';
import PrescriptionAccordion from './PrescriptionAccordion';

export type MyPrescriptionsProps = {
  prescriptions: TelehealthMedication[];
};

/**
 * This is a component that displays a list of prescriptions for the user
 */
export default function MyPrescriptions() {
  const {
    medications,
    isLoading,
    error,
    refetch: refetchMedications,
  } = useMedications();

  // Show 3 prescriptions at a time. When show more is clicked, show the next 3
  const BATCH_SIZE = 3;

  const prescriptions = medications || [];

  const [firstRx, ...remainingRx] = prescriptions;
  const [visibleRx, setVisibleRx] = useState<TelehealthMedication[]>([]);
  const [hasMoreRx, setHasMoreRx] = useState<boolean>(remainingRx.length > 0);
  const [firstInvisibleIndex, setFirstInvisibleIndex] = useState<number>(0);

  const headerRef = useRef<HTMLDivElement>(null);

  const handleShowMoreLess = () => {
    const hasMore = visibleRx.length < remainingRx.length;

    if (hasMore) {
      // Add prescriptions in the next batch to the visible list
      setVisibleRx((prevVisiblePrescriptions) => [
        ...prevVisiblePrescriptions,
        ...remainingRx.slice(
          firstInvisibleIndex,
          firstInvisibleIndex + BATCH_SIZE,
        ),
      ]);

      // Update the first invisible index
      setFirstInvisibleIndex(firstInvisibleIndex + BATCH_SIZE);
    } else {
      // Reset to initial state
      setVisibleRx([]);
      // Scroll to My prescriptions header
      if (headerRef.current?.scrollIntoView) {
        headerRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      setFirstInvisibleIndex(0);
    }
  };

  useEffect(() => {
    const hasMore = visibleRx.length < remainingRx.length;
    setHasMoreRx(hasMore);
  }, [remainingRx.length, visibleRx.length]);

  if (isLoading && !error) {
    return (
      <S.Container>
        <SkeletonLoader
          height={{
            value: 260,
            unit: SkeletonUnit.Pixel,
          }}
          width={{
            value: 100,
            unit: SkeletonUnit.Percentage,
          }}
        />
      </S.Container>
    );
  }

  if (error) {
    return (
      <S.Container>
        <ErrorCard onRetry={refetchMedications} />
      </S.Container>
    );
  }

  // If there are no prescriptions, don't show the section
  return prescriptions.length ? (
    <S.Container>
      <S.HeaderContainer>
        <S.Header ref={headerRef}>My Prescriptions</S.Header>
        <S.PrescriptionNumber>
          {prescriptions.length} Rx from Everlywell
        </S.PrescriptionNumber>
      </S.HeaderContainer>

      {/* Show first prescription by default */}
      <PrescriptionAccordion
        key={firstRx.product_name}
        prescription={firstRx}
      />

      {/* Show remaining prescriptions after clicking show more, show 3 at a time */}
      {remainingRx.length ? (
        <>
          <S.RemainingRxContainer
            isExpanded={visibleRx.length > 0}
            aria-expanded={visibleRx.length > 0}
            data-test="remaining-prescriptions"
          >
            {visibleRx.map((prescription, index) => (
              <PrescriptionAccordion
                key={`${prescription.product_name}-${index}`}
                prescription={prescription}
              />
            ))}
          </S.RemainingRxContainer>

          <S.ExpandLink
            onClick={() => handleShowMoreLess()}
            aria-expanded={visibleRx.length > 0}
          >
            {hasMoreRx ? 'Show more' : 'Show less'}
            <S.ChevronIcon isExpanded={!hasMoreRx} />
          </S.ExpandLink>
        </>
      ) : null}
    </S.Container>
  ) : null;
}
