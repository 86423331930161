import { MarkerWithResult } from 'common/utils/types';

const numericalMarkerWithResults: MarkerWithResult[] = [
  {
    id: 180,
    test_id: 2,
    descriptors: ['Normal', 'Borderline', 'High'],
    severities: [1, 2, 3],
    severity_colors: ['green', 'yellow', 'red'],
    name: 'TPOab',
    units: 'IU/mL',
    min_value: 0,
    max_value: 500,
    content_token: 'c67b7116-2cbf-4a3a-87c6-fa83432bf581',
    type: 'numerical',
    groupings: [],
    marker_result: {
      id: 2610871,
      marker_id: 180,
      value: 20,
      severity_index: 0,
      descriptor: 'normal',
      test_result_id: 45929,
      boundaries: [70, 150],
      boundary_conditions: ['upper', 'lower'],
      reported_at: '2018-06-29T20:07:52.000Z',
      results_approved_at: '2018-06-29T20:07:52.000Z',
      observation_datetime: null,
      content_token: '049bc07a-792a-4e38-be26-b4b3292dc453',
    },
  },
];

const kitResult = {
  number: 'KR274159488',
  pretty_number: 'KR27415948',
  id: 18232,
  first_name: 'Demo',
  last_name: 'busyrrx',
  publish_hash: 'c7332fccc49d92e11f69c0b4',
  published_at: '2018-10-01T14:00:42.340Z',
  reported_at: '2017-08-04T17:36:53.258Z',
  collected_at: null,
  pwn_order_number: '33958129',
  status: 'results_approved',
  lab: {
    id: 36,
    name: 'Ayumetrix, CLIA 38D2112285',
    pwn_lab_identifier: '1087',
    ship_address_id: 7165,
    content_token: 'b8030361-a883-4efb-9544-210ecba7f243',
  },
  lab_received: null,
  test_id: 47,
  test: {
    id: 47,
    name: 'Metabolism Test',
    display_name: 'Metabolism',
    type: 'metabolism',
    content_token: 'b6be47b7-8648-4cf2-a171-99a97a8a0299',
    marker_types: ['numerical'],
    genomics_eligible: true,
    is_food_sensitivity: false,
    content: {
      TEST_OVERVIEW: 'Metabolism Test',
      RESULTS_SECTION_HEADER: 'test',
    },
  },
  marker_results: [
    {
      id: 1020256,
      marker_id: 225,
      value: 5,
      severity_index: 1,
      descriptor: 'normal',
      test_result_id: 18232,
      boundaries: ['1.5', '9.6'],
      boundary_conditions: ['upper', 'lower'],
      reported_at: '2017-08-04T17:36:53.258Z',
      results_approved_at: '2017-08-04T17:36:53.258Z',
      observation_datetime: null,
      content_token: '21742d0a-707e-46ba-9f85-248185d68255',
    },
    {
      id: 1020257,
      marker_id: 227,
      value: 65,
      severity_index: 2,
      descriptor: 'high',
      test_result_id: 18232,
      boundaries: ['10.0', '61.0'],
      boundary_conditions: ['upper', 'lower'],
      reported_at: '2017-08-04T17:36:53.258Z',
      results_approved_at: '2017-08-04T17:36:53.258Z',
      observation_datetime: null,
      content_token: 'c7835ab0-0ff5-4528-b643-17812f68ad2c',
    },
    {
      id: 1020258,
      marker_id: 226,
      value: 0.3,
      severity_index: 0,
      descriptor: 'low',
      test_result_id: 18232,
      boundaries: ['0.5', '4.7'],
      boundary_conditions: ['upper', 'lower'],
      reported_at: '2017-08-04T17:36:53.258Z',
      results_approved_at: '2017-08-04T17:36:53.258Z',
      observation_datetime: null,
      content_token: 'dcb8dfbe-c6f5-4fa2-931f-b0071f67988a',
    },
  ],
  related_marker_results: [
    {
      id: 13759177,
      marker_id: 225,
      value: 5.5,
      severity_index: 1,
      descriptor: 'normal',
      test_result_id: 230564,
      boundaries: ['1.5', '9.6'],
      boundary_conditions: ['upper', 'lower'],
      reported_at: '2019-06-08T00:17:43.000Z',
      observation_datetime: null,
      content_token: '21742d0a-707e-46ba-9f85-248185d68255',
    },
    {
      id: 13759178,
      marker_id: 227,
      value: 7,
      severity_index: 0,
      descriptor: 'low',
      test_result_id: 230564,
      boundaries: ['10.0', '61.0'],
      boundary_conditions: ['upper', 'lower'],
      reported_at: '2019-06-08T00:17:43.000Z',
      observation_datetime: null,
      content_token: 'fc55b1b2-466c-421e-a2fe-2251509f2a79',
    },
    {
      id: 13759179,
      marker_id: 226,
      value: '<0.05',
      severity_index: 0,
      descriptor: '<0.05',
      test_result_id: 230564,
      boundaries: ['0.5', '4.7'],
      boundary_conditions: ['upper', 'lower'],
      reported_at: '2019-06-08T00:17:43.000Z',
      observation_datetime: null,
      content_token: '67d9a217-c079-44a5-ab96-2a4170cb27c2',
    },
    {
      id: 13787398,
      marker_id: 225,
      value: 10.1,
      severity_index: 2,
      descriptor: 'high',
      test_result_id: 237094,
      boundaries: ['1.5', '9.6'],
      boundary_conditions: ['upper', 'lower'],
      reported_at: '2019-06-08T00:17:43.000Z',
      observation_datetime: null,
      content_token: 'cbed46af-bc54-4d01-bd22-2bdbe6406937',
    },
    {
      id: 13787399,
      marker_id: 227,
      value: 41,
      severity_index: 1,
      descriptor: 'normal',
      test_result_id: 237094,
      boundaries: ['10.0', '61.0'],
      boundary_conditions: ['upper', 'lower'],
      reported_at: '2019-06-08T00:17:43.000Z',
      observation_datetime: null,
      content_token: 'c7835ab0-0ff5-4528-b643-17812f68ad2c',
    },
    {
      id: 13787400,
      marker_id: 226,
      value: '<0.05',
      severity_index: 0,
      descriptor: '<0.05',
      test_result_id: 237094,
      boundaries: ['0.5', '4.7'],
      boundary_conditions: ['upper', 'lower'],
      reported_at: '2019-06-08T00:17:43.000Z',
      observation_datetime: null,
      content_token: '67d9a217-c079-44a5-ab96-2a4170cb27c2',
    },
  ],
  markers: [
    {
      id: 227,
      test_id: 47,
      descriptors: ['Low', 'Normal', 'High'],
      severities: [2, 1, 3],
      severity_colors: ['yellow', 'green', 'red'],
      name: 'Free Testosterone',
      units: 'pg/mL',
      min_value: 0,
      max_value: 100,
      content_token: 'd833497f-44cd-49f5-b6ff-1b0791509b00',
      type: 'numerical',
      groupings: [],
    },
    {
      id: 225,
      test_id: 47,
      descriptors: ['Low', 'Normal', 'High'],
      severities: [2, 1, 3],
      severity_colors: ['yellow', 'green', 'red'],
      name: 'Cortisol',
      units: 'ng/mL',
      min_value: 0,
      max_value: 30,
      content_token: '0567a867-dd72-4772-9184-395a3d3b1334',
      type: 'numerical',
      groupings: [],
    },
    {
      id: 226,
      test_id: 47,
      descriptors: ['Low', 'Normal', 'High'],
      severities: [2, 1, 3],
      severity_colors: ['yellow', 'green', 'red'],
      name: 'TSH',
      units: 'μU/mL',
      min_value: 0,
      max_value: 20,
      content_token: '6d6697d5-72e1-402e-b3a6-38cbfa1aad05',
      type: 'numerical',
      groupings: [],
    },
  ],
  genomics_result_status: 'approved',
  snps: [
    {
      id: 12,
      rs_id: 'rs5082',
      gene: 'APOA2',
      chromosome: 'chr1',
      base_pairs: [
        {
          id: 45,
          letters: null,
          significance: 'no_result',
          frequency: 0,
        },
        {
          id: 46,
          letters: 'GG',
          significance: 'variant',
          frequency: 5.62,
        },
        {
          id: 47,
          letters: 'GA',
          significance: 'common',
          frequency: 36.17,
        },
        {
          id: 48,
          letters: 'AA',
          significance: 'common',
          frequency: 58.22,
        },
      ],
      content_token: '3be68340-738b-46af-a362-bce6de2b745a',
    },
    {
      id: 13,
      rs_id: 'rs1421085',
      gene: 'FTO',
      chromosome: 'chr16',
      base_pairs: [
        {
          id: 49,
          letters: null,
          significance: 'no_result',
          frequency: 0,
        },
        {
          id: 50,
          letters: 'TT',
          significance: 'common',
          frequency: 59.51,
        },
        {
          id: 51,
          letters: 'TC',
          significance: 'variant',
          frequency: 35.27,
        },
        {
          id: 52,
          letters: 'CC',
          significance: 'variant',
          frequency: 5.23,
        },
      ],
      content_token: '3ab1cc29-0427-419e-85fb-7463772fbb1d',
    },
    {
      id: 14,
      rs_id: 'rs17782313',
      gene: 'MC4R',
      chromosome: 'chr18',
      base_pairs: [
        {
          id: 53,
          letters: null,
          significance: 'no_result',
          frequency: 0,
        },
        {
          id: 54,
          letters: 'TT',
          significance: 'common',
          frequency: 57.76,
        },
        {
          id: 55,
          letters: 'TC',
          significance: 'variant',
          frequency: 36.48,
        },
        {
          id: 56,
          letters: 'CC',
          significance: 'variant',
          frequency: 5.76,
        },
      ],
      content_token: 'ed684baa-beb7-4db3-a849-2bf1bfe09665',
    },
    {
      id: 15,
      rs_id: 'rs1801282',
      gene: 'PPARG',
      chromosome: 'chr3',
      base_pairs: [
        {
          id: 57,
          letters: null,
          significance: 'no_result',
          frequency: 0,
        },
        {
          id: 58,
          letters: 'CC',
          significance: 'common',
          frequency: 86.43,
        },
        {
          id: 59,
          letters: 'CG',
          significance: 'variant',
          frequency: 13.07,
        },
        {
          id: 60,
          letters: 'GG',
          significance: 'variant',
          frequency: 0.49,
        },
      ],
      content_token: '984794a9-52af-49d3-a462-0308f5e10079',
    },
  ],
  snp_results: [
    {
      id: 539,
      snp_id: 12,
      base_pair_id: 47,
    },
    {
      id: 540,
      snp_id: 15,
      base_pair_id: 58,
    },
    {
      id: 541,
      snp_id: 13,
      base_pair_id: 51,
    },
    {
      id: 542,
      snp_id: 14,
      base_pair_id: 54,
    },
  ],
  test_marker_names: [
    'Free Testosterone',
    'Free Testosterone',
    'Cortisol',
    'TSH',
  ],
  consult: {
    available: false,
    available_until: '2017-08-17T20:14:27.169Z',
  },
};

const groupedNumericalMarkerWithResults = [
  {
    TPOab: [...numericalMarkerWithResults],
  },
];

const ancestryKitResult = Object.assign(kitResult, {
  test: {
    id: 86,
    name: 'Metabolism Test Ancestry',
    display_name: 'Metabolism',
    type: 'metabolism',
    content_token: 'b6be47b7-8648-4cf2-a171-99a97a8a0299',
    marker_types: ['numerical'],
    genomics_eligible: true,
    is_food_sensitivity: false,
    content: {
      TEST_OVERVIEW: 'Metabolism Test',
      RESULTS_SECTION_HEADER: 'test',
    },
  },
});

export {
  numericalMarkerWithResults,
  kitResult,
  groupedNumericalMarkerWithResults,
  ancestryKitResult,
};
