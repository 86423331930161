import { theme, Box } from '@everlywell/ui-kit';
import React from 'react';

type CarePlanCardContainerProps = {
  children: React.ReactNode;
  isExpanded?: boolean;
};

const CarePlanCardContainer = ({
  children,
  isExpanded,
  ...rest
}: CarePlanCardContainerProps) => (
  <Box
    backgroundColor="tints.white"
    border={`1px solid ${theme.colors.tints.lightCream}`}
    borderRadius="2xl"
    boxShadow={isExpanded ? theme.shadows.tooltip : 'none'}
    transition="box-shadow 0.2s ease-in-out"
    {...rest}
  >
    {children}
  </Box>
);

export default CarePlanCardContainer;
