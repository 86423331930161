import { Col, Row } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { HIPPA_URL, PRODUCT_URL } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { TEST_INFORMATION_FORM as DATA_TEST } from 'common/utils/constants/dataTest';
import { RUM_ACTIONS } from 'common/utils/constants/rumActions';
import { logError } from 'common/utils/helpers';
import { validateUserInfo } from 'common/utils/registrationHelpers';
import {
  KitRegistrationUser,
  ThirdPartyRegistration,
} from 'common/utils/types';
import useTimeTracker from 'common/utils/useTimeTracker';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';

import DataSharingModal from '../DataSharingModal';
import AddressInformation from './AddressInformation';
import Consent from './Consent';
import ContactInformation from './ContactInformation';
import PersonalInformation from './PersonalInformation';
import * as S from './styles';
import ThirdPartyId from './ThirdPartyId';

type TestInformationFormProps = {
  onSubmit: () => void;
  user: KitRegistrationUser;
  explicitConsent: boolean;
  isCovid: boolean;
  customConsentCopy?: string;
  customTermsEnabled: boolean;
  customTerms: string;
  testName?: string;
  companyName?: string;
  thirdPartyId?: ThirdPartyRegistration;
  minimum_age: number;
  consumerContainsSex: boolean;
  consumerContainsDOB: boolean;
  previouslyRegistered: boolean;
  kitGenderRestriction?: string;
};

const TestInformationForm = (props: TestInformationFormProps) => {
  const {
    onSubmit,
    isCovid,
    explicitConsent,
    user,
    testName,
    companyName,
    minimum_age,
    thirdPartyId,
    customConsentCopy = '',
    previouslyRegistered = false,
    customTermsEnabled,
    customTerms,
    consumerContainsSex,
    consumerContainsDOB,
    kitGenderRestriction,
  } = props;
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const fireAction = useTimeTracker(RUM_ACTIONS.SUBMIT_INFO);
  const analyticsAttributes = {
    'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
    'data-analytics-category': ANALYTICS.CATEGORIES.REGISTRATION,
    'data-analytics-label': ANALYTICS.LABELS.REGISTRATION,
  };
  const [authorizationChecked, setAuthorizationChecked] =
    useState(previouslyRegistered);
  const [consentChecked, setConsentChecked] = useState(previouslyRegistered);
  const [openModal, setOpenModal] = useState(false);
  const ldClient = useLDClient();
  useEffect(() => {
    if (companyName) {
      ldClient?.identify({
        key: companyName?.toLowerCase(),
      });
    }
  });

  const checkCustomTermsChecked = () =>
    customTermsEnabled ? user.customTerms.value : true;
  const disableThirdPartyIdInput = () =>
    !!(
      thirdPartyId &&
      !thirdPartyId.third_party_member_id &&
      !!thirdPartyId.third_party_id
    );
  const thirdPartyIsValid = () => {
    let isValid = true;
    if (
      thirdPartyId &&
      !thirdPartyId.third_party_member_id &&
      !thirdPartyId.third_party_id &&
      thirdPartyId.regex_format
    ) {
      const pattern = new RegExp(thirdPartyId.regex_format.toString());
      isValid = pattern.test(user.thirdPartyId.value);
    }
    return isValid;
  };

  const covidFieldsHaveValue = (): [boolean, string[]] => {
    const invalidFields: string[] = [];
    if (!user.race.value) {
      invalidFields.push('race');
    }
    if (!user.ethnicity.value) {
      invalidFields.push('ethnicity');
    }
    if (user.biologicalSex.value === 'female' && !user.pregnancyStatus.value) {
      invalidFields.push('pregnancyStatus');
    }
    if (!user.biologicalSex.value) {
      invalidFields.push('biologicalSex');
    }
    return [invalidFields.length === 0, invalidFields];
  };

  const checkBiologicalSexValue = (): [boolean, string[]] => {
    if (!user.biologicalSex.value && kitGenderRestriction !== 'not relevant') {
      invalidFields.push('biologicalSex');
    }
    return [invalidFields.length === 0, invalidFields];
  };

  const allowSubmit = () =>
    invalidFields.length === 0 &&
    authorizationChecked &&
    consentChecked &&
    checkCustomTermsChecked() &&
    thirdPartyIsValid() &&
    checkBiologicalSexValue();
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let covidValid = true;
    let invalidFields: string[] = [];
    if (isCovid) {
      [covidValid, invalidFields] = covidFieldsHaveValue();
    }
    const { valid, updatedFields } = validateUserInfo(user);
    if (valid && covidValid) {
      const trackingData = {
        label: 'User Info Submitted',
        category: ANALYTICS.CATEGORIES.REGISTRATION,
      };
      await analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: trackingData,
      });
      fireAction();
      if (explicitConsent) {
        setOpenModal(true);
      } else {
        onSubmit();
      }
    } else {
      setInvalidFields([...invalidFields, ...updatedFields]);
      logError(
        `Invalid TestInformationForm fields: ${JSON.stringify(updatedFields)}`,
      );
    }
  };
  return (
    <Row start="xs" middle="xs">
      <S.HeaderColumn>
        <S.Header>
          We need additional information in order to authorize your test,
          process your sample, and provide you with accurate results. We use
          enterprise level encryption and security to keep your information
          safe. Read more about our{' '}
          <S.DataPrivacyLink
            href="https://www.everlywell.com/privacy-policy/"
            target="_blank"
          >
            Data Privacy Policy
          </S.DataPrivacyLink>
        </S.Header>
      </S.HeaderColumn>
      <Col>
        <form data-test={DATA_TEST.FORM} onSubmit={handleSubmit}>
          <S.PersonalInformationColumn>
            <S.SectionHeader>Personal Information</S.SectionHeader>
            <PersonalInformation
              consumerContainsSex={consumerContainsSex}
              consumerContainsDOB={consumerContainsDOB}
              isCovid={isCovid}
              user={user}
              invalidFields={invalidFields}
              setInvalidFields={setInvalidFields}
              minimum_age={minimum_age}
              kitGenderRestriction={kitGenderRestriction}
            />
          </S.PersonalInformationColumn>
          <S.AddressColumn>
            <S.SectionHeader>Address</S.SectionHeader>
            <AddressInformation
              streetAddress={user.streetAddress}
              subAddress={user.subAddress}
              city={user.addressCity}
              state={user.addressState}
              zipCode={user.addressZipCode}
              overrideAddress={user.overrideAddress}
              invalidFields={invalidFields}
              setInvalidFields={setInvalidFields}
            />
          </S.AddressColumn>
          <S.ContactInformationColumn>
            <S.SectionHeader>Contact Information</S.SectionHeader>
            <ContactInformation
              user={user}
              invalidFields={invalidFields}
              setInvalidFields={setInvalidFields}
            />
          </S.ContactInformationColumn>
          {thirdPartyId && (
            <S.ContactInformationColumn>
              <ThirdPartyId
                thirdPartyId={thirdPartyId}
                user={user}
                isDisabled={disableThirdPartyIdInput()}
              />
            </S.ContactInformationColumn>
          )}
          <Consent
            previouslyRegistered={previouslyRegistered}
            sms={{
              label:
                "Get real time text updates on the status of your test. We'll never include personal information",
              id: 'smsCheckbox',
              checked: user.textUpdates.checked || false,
              setValue: user.textUpdates.setValue,
            }}
            hippa={{
              label:
                ' I have read and accept the HIPAA & Telehealth Authorizations',
              id: 'hippaCheckbox',
              checked: authorizationChecked,
              setValue: setAuthorizationChecked,
            }}
            product={{
              label: 'I have read and accept the Product Consent',
              id: 'productCheckbox',
              checked: consentChecked,
              setValue: setConsentChecked,
            }}
            customTermsEnabled={customTermsEnabled}
            customTerms={
              customTermsEnabled
                ? {
                    label: customTerms,
                    id: 'customTerms',
                    checked: user.customTerms.value,
                    setValue: user.customTerms.setValue,
                  }
                : undefined
            }
            hippaURL={HIPPA_URL}
            productURL={PRODUCT_URL}
          />
          <Col>
            <S.SubmitButton
              isDisabled={!allowSubmit()}
              appearance="primary"
              data-test={DATA_TEST.SUBMIT}
              {...analyticsAttributes}
            >
              Next
            </S.SubmitButton>
          </Col>
        </form>
        {openModal && (
          <DataSharingModal
            closeModal={() => setOpenModal(false)}
            testName={testName || ''}
            companyName={companyName || ''}
            customConsentCopy={customConsentCopy}
            onSubmit={onSubmit}
            user={user}
          />
        )}
      </Col>
    </Row>
  );
};

export default TestInformationForm;
