import { TEST_IDS } from '../constants';

export const DASHBOARD_PAGE = {
  NEW_FS_RESULTS: 'new_fs_results',
};

export const PWN_CONSULT_TESTS: string[] = [
  'Cholesterol and Lipids Test',
  // 'Heart Health Test',
  'Thyroid Test',
];

export const TAKE_ACTION_ORDER_OVERRIDE = [
  'Food Sensitivity Test',
  'Food Sensitivity Expansion Test',
  'Food Sensitivity Expansion Test - 88',
  'Food Sensitivity Test - 96',
  'Food Sensitivity Comprehensive Test',
];

export const NO_VIDEO_FOR_SLUG = ['covid-19-rapid-test-hcp'];

export const TEST_WITH_TIMED_MARKERS = [TEST_IDS?.WOMENS_HEALTH];
