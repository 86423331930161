import {
  Col,
  Button,
  H3,
  Input,
  size,
  typography,
  mediaQueries,
} from '@everlywell/leaves';
import styled from 'styled-components';

import { BaseDropdown } from '../styles';

const Subheader = styled.p`
  ${typography.bodyText};
  margin-bottom: ${size.lg}px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 0;
  }
`;

const Header = styled(H3)`
  margin: 0 0 ${size.sm}px 0;
`;
const StyledInput = styled(Input)`
  svg {
    fill: none;
  }

  &&& {
    input {
      ${(props) =>
        props.type === 'date' ? { 'padding-right': `${size.md}px` } : {}};
    }
  }
`;

const Picker = styled(BaseDropdown)`
  width: 100%;
  margin-bottom: ${size.xl8}px;
`;

const SubmitButton = styled(Button)`
  width: 100%;
`;

const HeaderCol = styled(Col)`
  ${mediaQueries.forTabletHorizontalUp} {
    margin: ${size.xl4}px 0;
  }
`;

const ButtonCol = styled(Col)`
  margin-top: -${size.xl3}px;
`;

export {
  Header,
  HeaderCol,
  ButtonCol,
  Subheader,
  StyledInput,
  Picker,
  SubmitButton,
};
