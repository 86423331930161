import React, { forwardRef } from 'react';

import * as S from 'components/SectionWrapper/SectionWrapper.styles';

interface Props extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  id?: string;
  className?: string;
  backgroundColor?: 'Primary' | 'Secondary';
}

export const SectionWrapper = forwardRef<HTMLElement, Props>((props, ref) => (
  <S.SectionWrapper
    {...props}
    ref={ref}
    className={props.className}
    backgroundColor={props.backgroundColor}
  >
    {props.children}
  </S.SectionWrapper>
));

export default SectionWrapper;
