import { colors, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const FileInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${size.md}px;
`;

const FileInputCard = styled.div`
  width: 100%;
  max-height: 148px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${size.xs1}px;
  padding: ${size.md}px;
  border: 1px solid ${colors.green1};
  border-radius: ${size.xs1}px;
  background-color: ${colors.white};
  box-shadow: 0 7px 30px -10px rgba(220, 228, 229, 0.5);
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:focus {
    border-color: ${colors.green2};
  }
`;

const FileInputIcon = styled.div`
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.green4};
  font-weight: ${typography.weight.thin};
  font-size: ${size.xl4}px;
  border-radius: 100%;
  background-color: ${colors.green1};
`;

const FileInputDescription = styled.p`
  ${typography.subtitleSmall};
  margin: 0;
  color: ${colors.gray4};
  font-size: 14px;
  font-weight: ${typography.weight.light};
`;

const FileInputText = styled.div`
  ${typography.bodyText}
  color: ${colors.green4};
  font-weight: ${typography.weight.bold};
`;

const FileInputSelectedField = styled.div`
  display: flex;
  width: 100%;
  padding: 10px ${size.md}px;
  font-size: 18px;
  font-weight: ${typography.weight.light};
  color: ${colors.gray5};
  border-radius: ${size.xs2}px;
  border: 1px solid ${colors.green2};
  background-color: ${colors.white};
`;

const TextWrapper = styled.p`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FileInputRemoveButton = styled.button`
  padding: 0;
  margin-left: auto;
  background: transparent;
  border: 0;
  cursor: pointer;
`;

const FileInputError = styled.div`
  color: ${colors.red3};
  font-weight: ${typography.weight.regular};
`;

export {
  FileInputCard,
  FileInputContainer,
  FileInputDescription,
  FileInputError,
  FileInputIcon,
  FileInputRemoveButton,
  FileInputSelectedField,
  FileInputText,
  TextWrapper,
};
