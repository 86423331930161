import { WWW_APP_ROOT } from 'common/utils/constants';

const CONTENT = {
  body: `<p>
    Because of a history of severe symptoms after consuming foods included in this test, we recommend that you speak with a healthcare provider, allergy specialist, or immunologist to determine the next steps for care.
  </p>
  <p>
    Please reach out to our <a href="mailto:contact@everlywell.com?subject=Food%20Allergy%20Test%20Eligibility&body=Hello%20Customer%20Care%2C%0D%0A%0D%0AThe%20Food%20Allergy%20Test%20form%20at%20registration%20has%20confirmed%20that%20I%20am%20not%20eligible%20to%20test.%20Can%20you%20assist%20me%20with%20next%20steps%3F%0D%0A%0D%0AHere%E2%80%99s%20my%20information%3A%0D%0A%0D%0AFull%20Name%3A%20fullName%0D%0AOrder%20Number%20(or%20Name%20of%20Retailer)%3A%0D%0AKit%20ID%3A%20kitId%0D%0A">Customer Experience team</a> for next steps. You can read more about our <a href="https://support.everlywell.com/article/1269-food-allergy-test-eligibility" target="_blank">eligibility requirements here</a>.
  </p>`,
  callToAction: {
    text: 'Learn More',
    url: `${WWW_APP_ROOT}/category/nutritional-digestive-health/`,
  },
  title: 'Based on your response, this test is not recommended for you.',
};

export default CONTENT;
