import Cookies from 'js-cookie';

import useEnterpriseProgramSlug from '../useEnterpriseProgramSlug';
import useProgramSlug from '../useProgramSlug';

export type T2TAnalyticsType = {
  telehealth_program?: null | string;
  enterprise_partner_slug?: null | string;
  enterprise_program_slug?: null | string;
};

export const useCommonT2TAnalytics = (): {
  commonT2TAnalyticsData: T2TAnalyticsType;
} => {
  const { enterpriseProgramSlug, testToTreatProgramSlug } =
    useEnterpriseProgramSlug();
  const { programSlug } = useProgramSlug();

  const commonT2TAnalyticsData =
    programSlug === enterpriseProgramSlug
      ? {
          telehealth_program: enterpriseProgramSlug,
          enterprise_partner_slug: Cookies.get('thirdparty'),
          enterprise_program_slug: testToTreatProgramSlug,
        }
      : {};

  return { commonT2TAnalyticsData };
};
