import { REGISTRATION_MODAL_ACTIONS as ACTIONS } from 'common/utils/constants';
import { REGISTRATION_CONFIRMATION as DATA_TEST } from 'common/utils/constants/dataTest';
import { AddressSuggestion, KitRegistrationUser } from 'common/utils/types';
import { BaseModal } from 'components/KitRegistration/styles';
import React, { useState } from 'react';

import * as S from './styles';
import SuggestedAddress from './SuggestedAddress';
import UserAddress from './UserAddress';

export interface ConfirmationErrorWithSuggestionsModalProps {
  openModal: boolean;
  setOpenModal: (cta: string) => void;
  title: string;
  message: string;
  user: KitRegistrationUser;
  suggestions: AddressSuggestion[];
  onSelectSuggestion: (suggestion: any) => void;
}

const USER_ADDRESS = 'user-address';

const ConfirmationErrorWithSuggestionsModal = ({
  openModal,
  setOpenModal,
  title,
  message,
  user,
  suggestions,
  onSelectSuggestion,
}: ConfirmationErrorWithSuggestionsModalProps) => {
  const [selected, setSelected] = useState(USER_ADDRESS);

  const hasMultipleSuggestions = suggestions.length > 1;
  const isUserAddressSelected = selected === USER_ADDRESS;
  const selectedSuggestion =
    !isUserAddressSelected && suggestions[parseInt(selected)];

  const onOptionChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;

    setSelected(target.id);
  };

  const onEditAddressButtonClick = () => setOpenModal(ACTIONS.EDIT_ADDRESS);
  const onUseSuggestedAddressButtonClick = () =>
    isUserAddressSelected
      ? setOpenModal(ACTIONS.USE_SELECTED_ADDRESS)
      : onSelectSuggestion(selectedSuggestion);

  const suggestionsTitle = hasMultipleSuggestions
    ? 'Suggested addresses'
    : 'Suggested address';

  return (
    <BaseModal
      open={openModal}
      openHandler={setOpenModal}
      data-test={`${DATA_TEST.MODAL}-error-with-suggestions`}
    >
      <S.Title>{title}</S.Title>
      <S.Subtitle>{message}</S.Subtitle>

      <S.SuggestionsWrapper>
        <S.OptionWrapper>
          <S.OptionTitle>You entered:</S.OptionTitle>

          <S.AddressOption
            id={USER_ADDRESS}
            name="address"
            label={<UserAddress user={user} />}
            checked={isUserAddressSelected}
            onChange={onOptionChange}
          />
        </S.OptionWrapper>

        <S.OptionWrapper>
          <S.OptionTitle>{suggestionsTitle}:</S.OptionTitle>

          {suggestions.map((suggestion, index) => (
            <S.AddressOption
              id={`${index}`}
              name="address"
              key={`suggestion-${index}`}
              label={<SuggestedAddress suggestion={suggestion} />}
              checked={selected === `${index}`}
              onChange={onOptionChange}
            />
          ))}
        </S.OptionWrapper>
      </S.SuggestionsWrapper>

      <S.ButtonWrapper>
        <S.Button appearance="secondary" onClick={onEditAddressButtonClick}>
          {ACTIONS.EDIT_ADDRESS}
        </S.Button>

        <S.Button
          appearance="primary"
          onClick={onUseSuggestedAddressButtonClick}
        >
          {ACTIONS.USE_SELECTED_ADDRESS}
        </S.Button>
      </S.ButtonWrapper>
    </BaseModal>
  );
};

export default ConfirmationErrorWithSuggestionsModal;
