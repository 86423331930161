import { TelehealthCustomModule } from 'common/apis/telehealthApis';
import Grid from 'components/Grid/Grid';
import React from 'react';

import { TAGS } from '../../constants';
import { useFormModalContext } from '../../providers';
import { removeTag } from '../../utils/tags';
import { FormModal } from '../FormModal';
import HtmlParser from './components/HtmlParser';
import * as S from './ReadOnlyField.styles';

export type ReadOnlyFieldProps = TelehealthCustomModule;

/**
 * Render a custom module with mod_type: read_only.
 */
function ReadOnlyField(props: ReadOnlyFieldProps) {
  const { showModal } = useFormModalContext();

  const modalToBeShown = props.required && props.label?.includes(TAGS.ERROR);
  const title = removeTag(props.label);
  const html = props.options_array.join('\r\n');

  const openModal = modalToBeShown && showModal;

  if (modalToBeShown && !showModal) {
    return null;
  }

  return (
    <Grid.Item width={[1]}>
      {openModal ? (
        <FormModal
          title={title}
          html={html}
          openModal={openModal}
          type="error"
        />
      ) : (
        <S.Container>
          <HtmlParser
            html={html}
            isInlineError={props.label?.includes(TAGS.INLINE_ERROR)}
          />
        </S.Container>
      )}
    </Grid.Item>
  );
}
export default ReadOnlyField;
