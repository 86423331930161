import React from 'react';

import * as S from './styles';

export type Props = {
  index: number;
  isClassStyle: boolean;
  last: number;
  upperBoundary: number;
};

export const BoundaryBadge = (props: Props) => {
  const { index, last, isClassStyle, upperBoundary } = props;

  return (
    <>
      {isClassStyle ? null : (
        <S.Boundary key={`range_${index.toString()}`} percentage={last}>
          {isClassStyle ? null : (
            <S.BoundaryBadge>{upperBoundary}</S.BoundaryBadge>
          )}
        </S.Boundary>
      )}
    </>
  );
};
