import React, { createContext, FC, useContext } from 'react';

import useCareDashboard from '../hooks/useCareDashboard/index';
import { CareDashboardTypes } from '../hooks/useCareDashboard/types';

type Props = {
  children?: React.ReactNode;
};

const defaultCareDashboardContext = {
  loadingDashboard: true,
  onLoadingDashboard: () => {},
};

export const CareDashboardContext = createContext<CareDashboardTypes>(
  defaultCareDashboardContext,
);

export const useCareDashboardContext = () => useContext(CareDashboardContext);

export const CareDashboardProvider: FC<Props> = ({ children }) => {
  const value = useCareDashboard();

  return (
    <CareDashboardContext.Provider value={value}>
      {children}
    </CareDashboardContext.Provider>
  );
};
