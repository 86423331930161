import { MARKER_VALUE_EXCEPTIONS } from 'common/utils/constants';
import { formatStringSeparator } from 'common/utils/formatText';
import { getRGBRangeColors } from 'common/utils/helpers';
import { MarkerWithResult } from 'common/utils/types';
import NewMarkerChartWrapper from 'components/NewMarkerChartWrapper';
import React from 'react';

import ReactivityBadge from '../ReactivityBadge';
import * as S from './styles';

export type Props = {
  markerWithResult: MarkerWithResult;
  collectionNumber: number;
  addScrollId: boolean;
};

const MultiNumericalChartRow = (props: Props) => {
  const { markerWithResult, collectionNumber, addScrollId } = props;
  const { marker_result: markerResult } = markerWithResult;
  const collectionIndexArray = ['1st', '2nd', '3rd', '4th'];
  const markerName = markerWithResult.name.toLowerCase();
  const collectionLabel = `${collectionIndexArray[collectionNumber]}`;

  const testAttr = formatStringSeparator(
    `${markerName} ${collectionLabel.toLowerCase()} collection`,
  );

  return (
    <>
      <S.ChartGridWrapper key={markerResult.id}>
        <S.CollectionLabel data-test={testAttr}>
          {collectionLabel}
          <span> Collection</span>
        </S.CollectionLabel>
        <S.MarkerChartContainer data-test={`${testAttr}-marker-chart`}>
          <NewMarkerChartWrapper
            marker={markerWithResult}
            markerResult={markerResult}
            rangeColors={getRGBRangeColors(markerWithResult)}
            excludedMarkerValues={MARKER_VALUE_EXCEPTIONS}
          />
        </S.MarkerChartContainer>
      </S.ChartGridWrapper>
      <S.ReactivityBadgeWrapper
        id={addScrollId ? `${markerWithResult.name}-card` : ''}
      >
        <S.CollectionLabelTablet data-test={`${testAttr}-tablet`}>
          {collectionLabel}
          <span> Collection</span>
        </S.CollectionLabelTablet>
        <ReactivityBadge
          marker={markerWithResult}
          markerResult={markerResult}
          markerLabel="Your Level"
          showMarkerValue
          showRangeInfo
          isMultiNumerical
          dataTest={`${testAttr}-badge`}
        />
      </S.ReactivityBadgeWrapper>
    </>
  );
};

export default MultiNumericalChartRow;
