import { isString } from 'lodash';
import React, { FC } from 'react';
import { useContext } from 'react';
import {
  UNSAFE_NavigationContext,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { QueryParamConfig, StringParam } from 'serialize-query-params';

/**
 * Taken from:
 * https://stackoverflow.com/a/71097818/3276790
 */
export function withRouter<P extends {}>(Component: FC<P>) {
  function ComponentWithRouterProp(props: P) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

/**
 * Taken from:
 * https://dev.to/jacobgavin/react-router-v6-usesearchparams-25hn
 */

type NewValueType<D> = D | ((latestValue: D) => D);
type UrlUpdateType = 'replace' | 'push' | undefined;
type UseSearchParam<D, D2 = D> = [
  D2,
  (newValue: NewValueType<D>, updateType?: UrlUpdateType) => void,
];

export function useSearchParam<D, D2 = D>(
  name: string,
  config: QueryParamConfig<D, D2> = StringParam as QueryParamConfig<any>,
): UseSearchParam<D, D2> {
  const [searchParams, setSearchParams] = useSearchParams();
  const { navigator } = useContext(UNSAFE_NavigationContext);

  const setNewValue = (
    valueOrFn: NewValueType<D>,
    updateType?: UrlUpdateType,
  ): void => {
    let newValue;
    const value = searchParams.get(name);
    if (typeof valueOrFn === 'function') {
      newValue = (valueOrFn as Function)(config.decode(value));
    } else {
      newValue = valueOrFn;
    }
    const encodedValue = config.encode(newValue);

    const params = new URLSearchParams((navigator as any).location.search);

    if (isString(encodedValue)) {
      params.set(name, encodedValue);
    } else {
      params.delete(name);
    }
    setSearchParams(params, { replace: updateType === 'replace' });
  };

  const decodedValue = config.decode(searchParams.get(name));
  return [decodedValue, setNewValue];
}
