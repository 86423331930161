import { useQuery as useApolloClientQuery } from '@apollo/client';
import { ATHENA_CARE_PLANS } from 'common/utils/contentfulGraphQlQueries';
import { logError } from 'common/utils/helpers';

export interface CarePlan {
  pageSection: {
    eyebrow: string;
    displayName: string;
    __typename: string;
  };
}

export default function useContentfulCarePlan(entryId: string) {
  const { data, loading: isLoading } = useApolloClientQuery<CarePlan>(
    ATHENA_CARE_PLANS,
    {
      variables: {
        entryId,
      },
      onError(error) {
        logError((error as Error).message, {
          errorInfo: 'Graphql fetching Error',
          component: 'useContentfulCarePlan',
          method: 'useApolloClientQuery',
        });
      },
    },
  );

  const carePlanData = data?.pageSection;

  return {
    carePlan: carePlanData || null,
    isLoading,
  };
}
