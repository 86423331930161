import {
  SkeletonLoader,
  SkeletonShape,
  SkeletonUnit,
} from '@everlywell/leaves';
import VisuallyHidden from 'components/VisuallyHidden';
import React from 'react';

import BaseCard from './BaseCard';
import * as S from './BaseCard.styles';

const SkeletonImage = () => (
  <S.BaseCardIcon
    as={SkeletonLoader}
    width={{
      value: 80,
      unit: SkeletonUnit.Pixel,
    }}
    height={{
      value: 80,
      unit: SkeletonUnit.Pixel,
    }}
    shape={SkeletonShape.Circle}
  />
);

const SkeletonTitle = ({ loadingText }: { loadingText: string }) => (
  <>
    <VisuallyHidden>{loadingText}</VisuallyHidden>
    <SkeletonLoader
      height={{ value: 26, unit: SkeletonUnit.Pixel }}
      width={{ value: 200, unit: SkeletonUnit.Pixel }}
    />
  </>
);

const SkeletonDescription = () => (
  <>
    <SkeletonLoader
      height={{ value: 20, unit: SkeletonUnit.Pixel }}
      width={{ value: 100, unit: SkeletonUnit.Percentage }}
    />
    <br />
    <SkeletonLoader
      height={{ value: 20, unit: SkeletonUnit.Pixel }}
      width={{ value: 100, unit: SkeletonUnit.Percentage }}
    />
  </>
);

const SkeletonCta = () => (
  <SkeletonLoader
    height={{ value: 48, unit: SkeletonUnit.Pixel }}
    width={{ value: 100, unit: SkeletonUnit.Percentage }}
  />
);

export type BaseCardSkeletonProps = {
  loadingText: string;
};

/**
 * This is a standard skeleton loader for the BaseCard component.
 *
 * If you need to customize the skeleton loader, you can use the
 * BaseCard component directly and pass in the skeleton loader components.
 *
 * @example
 * <BaseCard
 *   content={{
 *     title: <BaseCardSkeleton.Title loadingText="Loading section card" />,
 *     description: <BaseCardSkeleton.Description />,
 *   }}
 *   cta={<BaseCardSkeleton.Cta />}
 * />
 */
function BaseCardSkeleton(props: BaseCardSkeletonProps) {
  const { loadingText } = props;

  return (
    <BaseCard
      image={<SkeletonImage />}
      content={{
        title: <SkeletonTitle loadingText={loadingText} />,
        description: <SkeletonDescription />,
      }}
      cta={<SkeletonCta />}
    />
  );
}

BaseCardSkeleton.Image = SkeletonImage;
BaseCardSkeleton.Title = SkeletonTitle;
BaseCardSkeleton.Description = SkeletonDescription;
BaseCardSkeleton.Cta = SkeletonCta;

export default BaseCardSkeleton;
