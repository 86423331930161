import { SEVERITY_VALUE } from 'common/utils/constants';
import { ImmutableReduxState } from 'common/utils/types';
import SectionHeader, {
  Props as SectionHeaderProps,
} from 'components/SectionHeader';
import { SeverityContext } from 'containers/KitResultDetailPageContainer';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { makeSelectContent } from 'store/selectors';

import * as S from './styles';

const CONTENT_KEYS = {
  header: 'LIST_HEADING',
  description: 'LIST_DESCRIPTION',
  iconSrc: 'LIST_ICON',
  alt: 'LIST_ICON_ALT',
  // only COVID tests contain these content_keys at the moment
  detectedHeader: 'LIST_HEADING_DETECTED',
  notDetectedHeader: 'LIST_HEADING_NOT_DETECTED',
  indeterminateHeader: 'LIST_HEADING_INDETERMINATE',
  detectedDescription: 'LIST_DESCRIPTION_DETECTED',
  notDetectedDescription: 'LIST_DESCRIPTION_NOT_DETECTED',
  indeterminateDescription: 'LIST_DESCRIPTION_INDETERMINATE',
  detectedIconSrc: 'LIST_ICON_DETECTED',
  notDetectedIconSrc: 'LIST_ICON_NOT_DETECTED',
  indeterminateIconSrc: 'LIST_ICON_INDETERMINATE',
};

type OwnProps = {
  contentToken: string;
};

interface CustomizedContent {
  detectedHeader?: string;
  notDetectedHeader?: string;
  indeterminateHeader?: string;
  detectedDescription?: string;
  notDetectedDescription?: string;
  indeterminateDescription?: string;
  detectedIconSrc?: string;
  notDetectedIconSrc?: string;
  indeterminateIconSrc?: string;
}

export function SectionHeaderContainer(
  props: SectionHeaderProps & CustomizedContent,
) {
  const hasContent =
    !!props.description ||
    !!props.detectedDescription ||
    !!props.notDetectedDescription ||
    !!props.indeterminateDescription;
  // severityValue if it exits (which it only does for COVID-19 test)
  const severityValue = useContext(SeverityContext);

  if (!hasContent) {
    return null;
  }

  // only COVID-19 test will have this severityValue
  if (severityValue) {
    let { header, description, iconSrc } = props;

    if (severityValue === SEVERITY_VALUE.DETECTED) {
      header = props.detectedHeader;
      description = props.detectedDescription;
      iconSrc = props.detectedIconSrc;
    } else if (severityValue === SEVERITY_VALUE.NOT_DETECTED) {
      header = props.notDetectedHeader;
      description = props.notDetectedDescription;
      iconSrc = props.notDetectedIconSrc;
    } else if (severityValue === SEVERITY_VALUE.INDETERMINATE) {
      header = props.indeterminateHeader;
      description = props.indeterminateDescription;
      iconSrc = props.indeterminateIconSrc;
    }

    return (
      <S.ListHeaderWrapper>
        <SectionHeader
          {...props}
          header={header}
          description={description}
          iconSrc={iconSrc}
        />
      </S.ListHeaderWrapper>
    );
  }

  return (
    <S.ListHeaderWrapper>
      <SectionHeader {...props} />
    </S.ListHeaderWrapper>
  );
}

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): SectionHeaderProps {
  return makeSelectContent(ownProps.contentToken, CONTENT_KEYS)(state);
}

export default connect(mapStateToProps)(SectionHeaderContainer);
