import { size, typography, mediaQueries, TextArea } from '@everlywell/leaves';
import Grid from 'components/Grid/Grid';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: ${size.xl8}px;
`;

export const Heading = styled.h1`
  ${typography.h4Text};
  margin: 0;
  text-align: center;
`;

export const Label = styled.label`
  ${typography.bodyTextSmall};
  display: block;
  margin-bottom: ${size.lg}px;
`;

export const StyledTextArea = styled(TextArea)<{ hasError: boolean }>`
  label {
    padding-bottom: ${size.xs2}px;
  }

  /** FIXME: https://everlyhealth.atlassian.net/browse/IN2-704 */
  #feedback-error {
    display: ${({ hasError }) => (hasError ? 'block' : 'none')};
  }
`;

export const ButtonsContainer = styled(Grid.Container)`
  justify-content: center;
  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: row-reverse;
  }
`;
