import { SEVERITY_VALUE, SCREEN } from 'common/utils/constants';
import { KitResult, MarkerWithResult } from 'common/utils/types';
import { createSelector } from 'reselect';
import { selectMarkersDomain } from 'store/selectors';

export const makeGroupedMarkersKitResult = (kitResult: KitResult) =>
  createSelector(selectMarkersDomain, (markers) => {
    const markersWithResults: MarkerWithResult[] = [];
    const { markers: krMarkers, marker_results: markerResults } = kitResult;

    const defaultTopMarkers: MarkerWithResult[] = [];
    const detectedMarkers: MarkerWithResult[] = [];
    const notDetectedMarkers: MarkerWithResult[] = [];
    const equivocalMarkers: MarkerWithResult[] = [];
    const indeterminateMarkers: MarkerWithResult[] = [];
    const otherMarkers: MarkerWithResult[] = [];

    if (markerResults && krMarkers) {
      markerResults.forEach((marker_result) => {
        const marker = markers.get(marker_result.marker_id.toString()).toJS();
        if (marker) {
          const { severities } = marker;
          const { descriptor, severity_index: severityIndex } = marker_result;
          const severity = severities[severityIndex];
          if (marker.name.includes(SCREEN)) {
            defaultTopMarkers.push({ ...marker, marker_result });
          } else {
            switch (severity) {
              case SEVERITY_VALUE.NOT_DETECTED:
                notDetectedMarkers.push({ ...marker, marker_result });
                break;
              case SEVERITY_VALUE.INDETERMINATE:
                indeterminateMarkers.push({ ...marker, marker_result });
                break;
              case SEVERITY_VALUE.DETECTED:
                detectedMarkers.push({ ...marker, marker_result });
                break;
              case SEVERITY_VALUE.OTHER:
                if (descriptor === 'equivocal') {
                  equivocalMarkers.push({ ...marker, marker_result });
                } else {
                  otherMarkers.push({ ...marker, marker_result });
                }
                break;
              default:
                otherMarkers.push({ ...marker, marker_result });
                break;
            }
          }
        }
      });

      markersWithResults.push(
        ...defaultTopMarkers,
        ...detectedMarkers,
        ...equivocalMarkers,
        ...indeterminateMarkers,
        ...otherMarkers,
        ...notDetectedMarkers,
      );
    }
    return markersWithResults;
  });
