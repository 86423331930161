import { colors, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Heading = styled.h1`
  ${typography.h4Text};
  margin: 0;
  text-align: center;
`;

export const Text = styled.p`
  ${typography.bodyTextSmall};
  margin: 0;

  a {
    color: ${colors.blue1};
    text-decoration: none;
    font-weight: inherit;
  }
`;
