import React, { Context, createContext } from 'react';

import { IntakeContextType } from './types';
import useIntake, { defaultIntake } from './useIntake';

export const IntakeContext: Context<IntakeContextType> =
  createContext<IntakeContextType>(defaultIntake);

type Props = {
  children?: React.ReactNode;
};

export const IntakeProvider: React.FC<Props> = ({ children }) => {
  const value = useIntake();

  return (
    <IntakeContext.Provider value={value}>{children}</IntakeContext.Provider>
  );
};
