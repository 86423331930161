import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNativeIntakeContext } from 'pages/VirtualCare/NativeIntake/context/NativeIntakeContext';
import { getRelativePath } from 'pages/VirtualCare/NativeIntake/utils/helpers';
import { useNavigate } from 'react-router-dom';

type RedirectAfterInsuranceTypes = {
  intakePrefixSlug: string;
  navigateToNextPage: Function;
  navigationPath: string;
};
export const useRedirectAfterDetailPage = (): RedirectAfterInsuranceTypes => {
  const navigate = useNavigate();

  const { showDynamicIntakeFlow } = useFlags<{
    showDynamicIntakeFlow: boolean;
  }>();
  const { nextStep } = useNativeIntakeContext();

  const intakePrefixSlug = showDynamicIntakeFlow
    ? `/virtual-intake`
    : `/virtual-care`;

  const navigateToNextPage = () =>
    navigate(`${intakePrefixSlug}/insurance/add`);
  if (showDynamicIntakeFlow) {
    return {
      intakePrefixSlug,
      navigateToNextPage: navigateToNextPage,
      navigationPath: getRelativePath(nextStep),
    };
  }

  return {
    intakePrefixSlug,
    navigateToNextPage,
    navigationPath: `${intakePrefixSlug}/payment`,
  };
};
