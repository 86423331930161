import {
  HealthProfileOption,
  HealthProfileQuestion,
  PreRegistrationScreenerOptionType,
  PreRegistrationScreenerType,
} from 'common/utils/types';

const getScreenerContent = (
  rawQuestion: HealthProfileQuestion,
): PreRegistrationScreenerType => {
  const { content, question_options, description } = rawQuestion;

  const options = getScreenerOptions(question_options);

  return {
    callToActionText: 'Next',
    title: content,
    options,
    description,
  };
};

const getScreenerOptions = (
  rawOptions: HealthProfileOption[],
): PreRegistrationScreenerOptionType[] =>
  rawOptions
    .sort((current, next) => current.position - next.position)
    .map(({ content = '', description = '' }) => {
      const { label = '', items = '' } = getOptionValues(content);

      return {
        items: items,
        label: label,
        value: description,
      };
    }) as PreRegistrationScreenerOptionType[];

type OptionValues = {
  label?: string;
  items?: string[];
};

const getOptionValues = (content: string): OptionValues => {
  const [label, ...items] = content ? content.split('\n') : [''];
  return { label, items };
};

export { getScreenerContent };
