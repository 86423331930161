import { Col } from '@everlywell/leaves';
import { EVERLYWELL_SAMPLE_COLLECTION } from 'common/utils/constants/urls';
import { CollectionTip } from 'common/utils/types';
import React from 'react';

import * as S from './BloodCollection/styles';
import CollectionMethodSteps from './CollectionSteps';

type Props = {
  tips: CollectionTip[];
};

const CollectionTips = ({ tips }: Props) => {
  if (!tips.length) {
    return null;
  }

  return (
    <S.Wrapper center="xs" middle="xs">
      <Col xs={10} md lg={9}>
        <S.Title>Tips before you collect</S.Title>

        <CollectionMethodSteps
          collectionMethodsList={tips}
          itemsPerRow={tips.length}
        />

        <S.Btn appearance="secondary" href={EVERLYWELL_SAMPLE_COLLECTION}>
          More sample collection tips
        </S.Btn>
      </Col>
    </S.Wrapper>
  );
};

export default CollectionTips;
