import analytics, { track } from 'common/utils/analytics';
import { DATE_FORMAT_SHORT } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import {
  TAKE_ACTION,
  WHATS_NEXT_SECTION,
} from 'common/utils/constants/dataTest';
import { sanitize } from 'common/utils/domHelpers';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import Tooltip, { TooltipWrapper } from 'components/Tooltip';
import { format, parseISO } from 'date-fns';
import React, { RefObject, useState } from 'react';

import costIcon from '../images/icons-cost.svg';
import expireIcon from '../images/icons-expire.svg';
import infoIcon from '../images/icons-info.svg';
import timeIcon from '../images/icons-time.svg';
import whoIcon from '../images/icons-who.svg';
import ScheduleModal from './ScheduleModal';
import * as S from './styles';

type Item = {
  alt: string;
  iconSrc: string;
  text: string;
};

export type Props = {
  header: string;
  description: string;
  date: string;
  items?: Item[];
  iconSrc: string;
  alt: string;
  modal: {
    header: string;
    description: string;
    link: string;
  };
  tooltip: {
    header: string;
    description: string;
  };
  testName: string;
  buttonContent: string;
  directlyOpenLink?: boolean;
  isWebinar?: boolean;
};

const defaultItems = [
  {
    iconSrc: whoIcon,
    alt: 'Who',
    text: 'Telehealth Consultation',
  },
  {
    iconSrc: timeIcon,
    alt: 'Time',
    text: 'Within 4 business hours',
  },
  {
    iconSrc: costIcon,
    alt: 'Cost',
    text: 'Free',
  },
];

function ResultsDiscussion(props: Props) {
  const {
    iconSrc,
    alt,
    header,
    description,
    items = defaultItems,
    date,
    testName,
    modal: modalProps,
    tooltip,
    buttonContent,
    directlyOpenLink,
    isWebinar,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const containerEl = useScrolledIntoViewOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: 'Schedule Results Discussion',
        category: ANALYTICS.CATEGORIES.WHATS_NEXT,
        testName,
      },
    });
  });

  const handleButtonClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (directlyOpenLink) {
      await track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: ANALYTICS.LABELS.WATCH_WEBINAR,
          category: ANALYTICS.CATEGORIES.TAKE_ACTION,
          testName,
        },
      });
      window.open(modalProps.link, '_blank');
    } else {
      setIsOpen(true);
    }
  };

  const isSchedule = buttonContent === 'Schedule';

  return (
    <S.Container ref={containerEl}>
      <S.IconWrapper>
        <S.Icon src={iconSrc} aria-hidden="true" alt={alt} />
      </S.IconWrapper>
      <S.DescriptionWrapper>
        <S.Header dangerouslySetInnerHTML={{ __html: sanitize(header) }} />
        <S.Description
          dangerouslySetInnerHTML={{ __html: sanitize(description) }}
        />
      </S.DescriptionWrapper>
      <S.ActionWrapper>
        <S.List>
          {items.map((item, index) => (
            <S.ListItem key={item.alt + index.toString()}>
              <S.ListIcon src={item.iconSrc} alt={item.alt} />
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: sanitize(item.text) }} />
            </S.ListItem>
          ))}
          {isSchedule && (
            <TooltipWrapper tooltipId="schedule-tooltip">
              {({ triggerEl, iconEl, isShown, tooltipId }) => (
                // @ts-ignore-next-line
                <S.ListItem ref={triggerEl}>
                  <S.ListIcon src={expireIcon} alt="Expiration Date" />
                  <p>
                  <span data-isolate>Available until</span>
                    <S.DateAndIcon>
                      {' '}
                      {format(parseISO(date), DATE_FORMAT_SHORT)}
                      <S.TooltipIcon
                        src={infoIcon}
                        alt="info"
                        aria-describedby={tooltipId}
                        aria-expanded={isShown ? 'true' : 'false'}
                        ref={iconEl as RefObject<HTMLImageElement>}
                        data-test={
                          isSchedule
                            ? TAKE_ACTION.SCHEDULE_TOOLTIP
                            : TAKE_ACTION.WATCH_TOOLTIP
                        }
                      />
                    </S.DateAndIcon>
                  </p>
                  <Tooltip
                    isShown={isShown}
                    triggerEl={triggerEl}
                    iconEl={iconEl}
                    id={tooltipId}
                    data-test={
                      isSchedule
                        ? TAKE_ACTION.SCHEDULE_TOOLTIP_CONTENT
                        : TAKE_ACTION.WATCH_TOOLTIP_CONTENT
                    }
                    tooltipWidth={320}
                  >
                    <S.TooltipContent>
                      <S.ModalTitle
                        dangerouslySetInnerHTML={{
                          __html: sanitize(tooltip.header),
                        }}
                      />
                      <S.ModalDescription
                        dangerouslySetInnerHTML={{
                          __html: sanitize(tooltip.description),
                        }}
                      />
                    </S.TooltipContent>
                  </Tooltip>
                </S.ListItem>
              )}
            </TooltipWrapper>
          )}
          {!isSchedule && (
            <S.ListItem>
              <S.ListIcon src={expireIcon} alt="Expiration Date" />
              {isWebinar ? (
                <p>
                  Available
                  <S.DateAndIcon> Always</S.DateAndIcon>
                </p>
              ) : (
                <p>
                  <span data-isolate>Available until</span>
                  <S.DateAndIcon>
                    {' '}
                    {format(parseISO(date), DATE_FORMAT_SHORT)}
                  </S.DateAndIcon>
                </p>
              )}
            </S.ListItem>
          )}
        </S.List>
        <S.ScheduleButton
          appearance={'secondary'}
          data-test={
            isSchedule
              ? WHATS_NEXT_SECTION.SCHEDULE_NOW_BUTTON
              : WHATS_NEXT_SECTION.WATCH_WEBINAR_BUTTON
          }
          onClick={handleButtonClick}
          trackingData={{
            label: 'Results Discussion',
            category: ANALYTICS.CATEGORIES.WHATS_NEXT,
            testName,
          }}
        >
          {buttonContent}
        </S.ScheduleButton>
        {isOpen && (
          <ScheduleModal
            onClose={handleClose}
            testName={testName}
            {...modalProps}
          />
        )}
      </S.ActionWrapper>
    </S.Container>
  );
}

export default ResultsDiscussion;
