import { KitResult } from 'common/utils/types';
import { Intro, GridWrapper } from 'components/ResultsIntro/styles';
import { format, parseISO } from 'date-fns';
import * as React from 'react';

import ReplaceTestButton from './components/ReplaceTestButton';
import * as S from './styles';

type Props = {
  kitResult: KitResult;
};

const MarkerReplacementSection = (props: Props) => {
  var { kitResult } = props;

  return (
    <GridWrapper>
      <S.ReplacementWrapper>
        <Intro>
          It is recommended that you repeat testing to obtain a definitive
          result, so we're offering you a free replacement for our{' '}
          {kitResult.marker_replacement?.replacement_product}.
        </Intro>
        <div>
          <ReplaceTestButton
            testId={kitResult.test?.id}
            barcodeSerialNumber={kitResult.barcode_serial_number}
            status={kitResult.marker_replacement?.status}
          />
        </div>
        {kitResult.marker_replacement?.status === 'requested' ? (
          <S.ReplacementLegend>
            Your replacement test was claimed on{' '}
            {format(
              parseISO(kitResult.marker_replacement.requested_at),
              'MM/dd/yy',
            )}
            . You will receive an order confirmation email.
          </S.ReplacementLegend>
        ) : null}
        {kitResult.marker_replacement?.status === 'resulted' ? (
          <S.ReplacementLegend>
            You received results for this replacement test on{' '}
            {format(
              parseISO(kitResult.marker_replacement.resulted_at),
              'MM/dd/yy',
            )}
            . Click{' '}
            <S.Link
              href={`/results/${kitResult.marker_replacement.replacement_result_number}`}
            >
              here
            </S.Link>{' '}
            to view results.
          </S.ReplacementLegend>
        ) : null}
      </S.ReplacementWrapper>
    </GridWrapper>
  );
};

export default MarkerReplacementSection;
