import { ANCESTRY_FOOTER as dataTest } from 'common/utils/constants/dataTest';
import { ANCESTRY_URLS } from 'common/utils/constants/urls';
import { AncestryUrlType } from 'common/utils/types';
import React from 'react';

import * as S from './styles';

type Props = {
  isNewResultsLayout?: boolean;
  testId: number;
};

function AncestryFooter({ isNewResultsLayout = false, testId }: Props) {
  const ancestryUrls: AncestryUrlType = ANCESTRY_URLS;
  return (
    <S.Wrapper isNewResultsLayout={isNewResultsLayout}>
      <div>
        <S.InnerContent>
          <S.Button
            href={ancestryUrls[testId]}
            target="_blank"
            data-test={dataTest.BUTTON}
            trackingData={{
              label: `Sign in to Ancestry`,
            }}
          >
            Explore AncestryHealth
          </S.Button>
        </S.InnerContent>
      </div>
    </S.Wrapper>
  );
}

export default AncestryFooter;
