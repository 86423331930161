/* stylelint-disable no-descending-specificity */
import { colors, mediaQueries, size } from '@everlywell/leaves';
import { hexToRGB } from 'common/utils/helpers';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 35px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  background-color: ${colors.white};
  box-shadow: 0 ${size.xs3}px ${size.md}px 0
    rgba(${hexToRGB(colors.black)}, 0.05);

  ${mediaQueries.forTabletVerticalUp} {
    padding: 36px 24px 32px;
  }
`;

const FourFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormTitle = styled.div`
  width: 164px;
  margin-bottom: 34px;
  border-radius: 11px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 20px;
    width: 324px;
  }
`;

const FourFieldsTitle = styled.div`
  margin-bottom: 36px;
  width: 169px;
`;

const FourFieldsInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 50px 24px;

  ${mediaQueries.forTabletHorizontalUp} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

/* Date of Birth and Biological Sex wrapper */
const CustomFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 36px;

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
    margin-top: 30px;
  }
`;

const DateOfBirthInputsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 6px;

  ${mediaQueries.forTabletHorizontalUp} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 16px;
  }
`;

const BiologicalSexInputsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 20px;

  ${mediaQueries.forTabletHorizontalUp} {
    grid-template-columns: repeat(2, 136px);
  }
`;

const CustomInputTitle = styled.div`
  width: 169px;
  margin-bottom: 14px;
`;

const CustomFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:last-child {
    margin-top: 48px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    width: calc(50% - 10px);

    &:last-child {
      margin-left: 20px;
      margin-top: 0;
    }
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 35px;

  ${mediaQueries.forTabletHorizontalUp} {
    width: calc(50% - 10px);
    margin-top: 30px;
  }
`;

const Checkbox = styled.div`
  width: 48px;
`;

const CheckboxTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 16px;
`;

const CheckboxText = styled.div`
  width: 100%;

  &:last-child {
    width: 191px;
    margin-top: 5px;
  }
`;

const ButtonWrapper = styled.div`
  width: 169px;
  margin: 42px auto 0;

  ${mediaQueries.forTabletHorizontalUp} {
    margin: 30px 0 0;
  }
`;

export {
  Wrapper,
  FormWrapper,
  FourFieldsWrapper,
  FormTitle,
  FourFieldsTitle,
  FourFieldsInputWrapper,
  CustomFieldsContainer,
  CustomFieldsWrapper,
  DateOfBirthInputsWrapper,
  BiologicalSexInputsWrapper,
  CustomInputTitle,
  CheckboxWrapper,
  Checkbox,
  CheckboxTextContainer,
  CheckboxText,
  ButtonWrapper,
};
