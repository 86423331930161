import { Col } from '@everlywell/leaves';
import React from 'react';

import * as S from '../styles';

type CopyProps = {
  unansweredParam: boolean;
  questionsLength: string;
};
export const Copy = ({ unansweredParam, questionsLength }: CopyProps) => {
  let subtitle = (
    <span>
      Help us learn more about your health and lifestyle by answering some
      questions. Your answers will allow us to provide
      personalized&nbsp;insights.
    </span>
  );

  if (unansweredParam) {
    subtitle = (
      <span>
        You have {questionsLength} more questions to answer to get more
        personalized content on your dashboard and in your results.
      </span>
    );
  }

  return (
    <Col>
      <S.Subtitle>{subtitle}</S.Subtitle>
    </Col>
  );
};
