import { TrackVisibilityWrapper } from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { sanitize } from 'common/utils/domHelpers';
import React from 'react';

import PatientPlanIcon from './Icons/patient_plan.svg';
import * as S from './PatientPlanCard.styles';

type PatientPlanCardProps = {
  plan: string;
  formAnswerGroupId: string | null;
};

function PatientPlanCard(props: PatientPlanCardProps) {
  const { plan, formAnswerGroupId } = props;

  return (
    <TrackVisibilityWrapper
      label={ANALYTICS.LABELS.CARE_PLAN.PATIENT_PLAN}
      formAnswerGroupId={formAnswerGroupId}
    >
      <S.Card>
        {PatientPlanIcon && (
          <S.CardIconContainer>
            <S.CardIcon src={PatientPlanIcon} />
          </S.CardIconContainer>
        )}
        <S.CardContent>
          <S.CardHeader>Patient Plan</S.CardHeader>
          <S.CardText dangerouslySetInnerHTML={{ __html: sanitize(plan) }} />
        </S.CardContent>
      </S.Card>
    </TrackVisibilityWrapper>
  );
}

export default PatientPlanCard;
