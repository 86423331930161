import BaseCard, {
  BaseCardSkeleton,
} from 'pages/AccountHub/components/BaseCard';
import React from 'react';

export type LoadingAppointmentCardProps = {};

/**
 * Loading state component for the appointment card
 */
function LoadingAppointmentCard(props: LoadingAppointmentCardProps) {
  return (
    <BaseCard
      content={{
        title: (
          <BaseCardSkeleton.Title loadingText="Loading appointment information" />
        ),
        description: <BaseCardSkeleton.Description />,
      }}
      cta={<BaseCardSkeleton.Cta />}
    />
  );
}

export default LoadingAppointmentCard;
