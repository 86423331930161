import useContentfulIntake from 'common/hooks/useContentfulCarePlan';
import React from 'react';

// Types ----------

interface CarePlanInformationProps {}

// Constants ----------

// TODO: Replace testing entry ID when we have real content
// https://app.contentful.com/spaces/ydyvnem5zkxh/entries/2sZo3aMRSv78uu9y65gPPu
const ENTRY_ID = '2sZo3aMRSv78uu9y65gPPu';

// CarePlanInformation ------

const CarePlanInformation: React.FC<CarePlanInformationProps> = (props) => {
  const { isLoading, carePlan } = useContentfulIntake(ENTRY_ID);

  return (
    <>
      {carePlan && !isLoading && (
        <div>
          <h1>{carePlan?.__typename}</h1>
          <span>{carePlan.displayName}</span>
        </div>
      )}
    </>
  );
};

// Export ---------

export default React.memo(CarePlanInformation);
