import { size, colors, typography } from '@everlywell/leaves';
import { hexToRGB } from 'common/utils/helpers';
import styled, { css } from 'styled-components';

export const CARD_WIDTH = 32;

type ContainerProps = {
  isDateSelected: boolean;
};
export const Container = styled.button<ContainerProps>`
  border-radius: ${size.md}px;
  border: 1px solid rgba(${hexToRGB(colors.black)}, 0.25);
  box-sizing: content-box;
  height: auto;
  margin-bottom: ${size.md}px;
  padding: ${size.sm}px ${size.xs1}px;
  width: ${CARD_WIDTH}px;
  color: ${colors.gray4};
  cursor: pointer;
  transition: all 100ms ease-in-out;

  &:hover,
  &:focus {
    color: ${colors.green4};
    border-color: ${colors.green4};
  }

  ${({ isDateSelected }) =>
    isDateSelected &&
    css`
      background-color: ${colors.green2};
      border-color: ${colors.green4};
      box-shadow: 0 0 0 1px ${colors.green4} inset;
      color: ${colors.green4};
    `}
`;

const sharedStyles = css`
  width: ${size.xl1}px;
  font-weight: ${typography.weight.xbold};
  text-align: center;
`;

export const Number = styled.div`
  font-size: ${size.lg}px;
  ${sharedStyles};
  text-wrap: nowrap;
`;

export const WeekDay = styled.div`
  font-size: ${size.sm}px;
  ${sharedStyles};
`;
