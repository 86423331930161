import { Button } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { User } from 'common/utils/types';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import BaseCard, { BaseCardIcon } from 'pages/AccountHub/components/BaseCard';
import React from 'react';
import { Link } from 'react-router-dom';

import BadgeIcon from './components/BadgeIcon';
import ProgressCircle from './components/ProgressCircle';

export interface HealthProfileCardProps {
  progress: number;
  unansweredQuestions: number;
  user: User;
  lastInteraction?: string | null;
}

const HealthProfileCard = ({
  progress,
  unansweredQuestions,
  user,
  lastInteraction,
}: HealthProfileCardProps) => {
  const bannerViewed = useScrolledIntoViewOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: ANALYTICS.LABELS.ACCOUNT_HUB.HEALTH_PROFILE_CARD,
        category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
      },
    });
  });

  const handleClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
        label: seen
          ? ANALYTICS.LABELS.ACCOUNT_HUB.FINISH_HEALTH_PROFILE
          : ANALYTICS.LABELS.ACCOUNT_HUB.START_HEALTH_PROFILE,
      },
    });
  };

  const { headline, text } = getCopy({ unansweredQuestions, progress });
  const seen = progress > 0;

  return (
    <BaseCard
      ref={bannerViewed}
      image={
        <>
          {progress < 100 && (
            <BaseCardIcon as={ProgressCircle} progress={progress ?? 0} />
          )}
          {progress >= 100 && <BaseCardIcon as={BadgeIcon} />}
        </>
      }
      content={{
        title: headline,
        description: text,
      }}
      cta={
        progress < 100 && (
          <Link
            to={'/health-profile/?unanswered=true'}
            state={{ user, lastInteraction }}
          >
            <Button appearance="secondary" hasArrow onClick={handleClick}>
              {seen ? 'Finish' : 'Get started'}
            </Button>
          </Link>
        )
      }
    />
  );
};

export default HealthProfileCard;

const getCopy = ({
  unansweredQuestions,
  progress,
}: Pick<HealthProfileCardProps, 'unansweredQuestions' | 'progress'>) => {
  const pluralizeAnswers: string =
    unansweredQuestions === 1 ? 'question' : 'questions';

  if (progress === 0) {
    return {
      headline: 'Start your health profile',
      text: 'Answer some questions for personalized data and insights.',
    };
  }
  if (progress === 100) {
    return {
      headline: 'You did it!',
      text: 'Thanks for completing your health profile and helping us work on making your experience better.',
    };
  }

  return {
    headline: 'Complete your health profile',
    text: `Answer ${unansweredQuestions} more ${pluralizeAnswers} for more personalized data`,
  };
};
