import styled from 'styled-components';

import { breakpoints } from '../breakpoints';
import { SIDataImageType, SIDataSizeType } from '../data/model';

type WrapperProps = {
  width: SIDataSizeType;
  height: SIDataSizeType;
};

type ImageProps = {
  image: SIDataImageType;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  margin: 0 30px;
  box-sizing: border-box;
  position: relative;
  width: ${(props) => props.width.l || '100%'};
  height: ${(props) => props.height.l || '100%'};

  ${breakpoints.m} {
    ${({ width }) => width && width.m && `width: ${width.m}`};
    ${({ height }) => height && height.m && `height: ${height.m}`};
  }

  ${breakpoints.s} {
    ${({ width }) => width && width.s && `width: ${width.s}`};
    ${({ height }) => height && height.s && `height: ${height.s}`};
    margin: 0;
  }
`;

const Image = styled.img<ImageProps>`
  display: flex;
  align-self: flex-end;
  position: absolute;
  width: ${({ image: { width } }) => (width && width.l) || '100%'};
  height: ${({ image: { height } }) => (height && height.l) || 'auto'};
  ${({ image: { position } }) =>
    position && position.x && `left: ${position.x}px`};
  ${({ image: { position } }) =>
    position && position.y && `top: ${position.y}px`};

  ${breakpoints.m} {
    ${({ image: { width } }) => width && width.m && `width: ${width.m}`};
    ${({ image: { height } }) => height && height.m && `height: ${height.m}`};
    ${({ image: { position } }) =>
      position && position.xm && `left: ${position.xm}px`};
    ${({ image: { position } }) =>
      position && position.ym && `top: ${position.ym}px`};
  }

  ${breakpoints.s} {
    ${({ image: { width } }) => width && width.s && `width: ${width.s}`};
    ${({ image: { height } }) => height && height.s && `height: ${height.s}`};
    ${({ image: { position } }) =>
      position && position.xs && `left: ${position.xs}px`};
    ${({ image: { position } }) =>
      position && position.ys && `top: ${position.ys}px`};
  }
`;

export { Wrapper, Image };
