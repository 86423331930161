import { FormModalType } from 'common/utils/types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import * as S from './ModalButtons.styles';

export type ModalButtonsProps = {
  type: FormModalType;
  navigatePath?: string;
};

export const ModalButtons = ({ type, navigatePath }: ModalButtonsProps) => {
  const navigate = useNavigate();

  switch (type) {
    case 'redirect':
      return (
        <S.Button
          onClick={() => navigate(navigatePath ? navigatePath : '/dashboard')}
        >
          Schedule your appointment
        </S.Button>
      );

    default:
      return (
        <S.Button onClick={() => navigate('/dashboard')}>
          Return to My Everly
        </S.Button>
      );

    // TODO: refactor as needed on the warning modal is implemented
    // case 'warning':
    // TODO: refactor as needed on the error modal is implemented
    // case 'error':
    // TODO: refactor as needed on the info modal is implemented
    // case 'info':
    // return (
    //   <>
    //     <S.Button>Return to My Everly</S.Button>
    //     <S.Button appearance="secondary" onClick={() => { }}>
    //       Dismiss
    //     </S.Button>
    //   </>
    // );
  }
};
