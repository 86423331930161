// Hook
import { logError } from 'common/utils/helpers';
import { useState } from 'react';

export function useSessionStorage<T = string>(
  key: string,
  initialValue: T,
): [T, (value: T) => void] {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      if (error instanceof Error) {
        logError(error.message, {
          component: `getValue for ${key}`,
          method: `getValue in useSessionStorage`,
        });
      }
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      if (error instanceof Error) {
        logError(error.message, {
          component: `setValue for ${key}`,
          method: 'setValue in useSessionStorage',
        });
      }
    }
  };

  return [storedValue, setValue];
}
