export default {
  'ship_address.firstname': {
    displayName: 'First name',
    key: 'firstName',
  },
  'ship_address.lastname': {
    displayName: 'Last name',
    key: 'lastName',
  },
  'ship_address.address1': {
    displayName: 'Address 1',
    key: 'address1',
  },
  'ship_address.city': {
    displayName: 'City',
    key: 'city',
  },
  'ship_address.state': {
    displayName: 'State',
    key: 'state',
  },
  'ship_address.zipcode': {
    displayName: 'Zip Code',
    key: 'zipCode',
  },
};
