import { PreRegistrationDisclaimerType } from 'common/utils/types';

import CONTENT from './food-allergy-comprehensive-test';

type DisclaimerTypes = {
  [key: string]: PreRegistrationDisclaimerType;
};

// Disclaimer Content that belongs to a given questionnaire. List of Questionnaires: (../questionnaires.ts)
const DISCLAIMERS: DisclaimerTypes = {
  'food-allergy-screener': CONTENT as PreRegistrationDisclaimerType,
};

const getDisclaimerContent = (
  slug: string,
): PreRegistrationDisclaimerType | null =>
  DISCLAIMERS.hasOwnProperty(slug) ? DISCLAIMERS[slug] : null;

export default getDisclaimerContent;
