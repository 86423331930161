// for useValidationParameter will fix in the future
/* eslint-disable react-hooks/rules-of-hooks */
import { useSearchParam } from 'common/polyfill/router';
import {
  HealthProfileOption,
  HealthProfileQuestion,
  HealthProfileResponse,
  HealthProfileResponseOptions,
} from 'common/utils/types';
import { isEmpty, isNull } from 'lodash';
import { MutableRefObject } from 'react';
import { StringParam } from 'serialize-query-params';

import { KeyValue, KeyValueQuestionsSet } from './hooks';

export const useValidationParameter = (name: string) => {
  const [queryParameter] = useSearchParam(name, StringParam);
  const validationUrlChunk = queryParameter ? `?${name}=${queryParameter}` : '';
  return validationUrlChunk;
};

export type FilterProps = {
  questions: HealthProfileQuestion[];
  unansweredParam: boolean;
  keyValueQuestionsSetAll: KeyValue;
};
export type FilterParams = HealthProfileQuestion[];
interface GenerateUrlProp {
  questionnaireId: number;
  urlChunk: string;
  questionsLength: number;
}
interface PrevUrlProps extends GenerateUrlProp {
  questionnaireQuestionId: number | null | undefined;
}
interface NextUrlProps extends GenerateUrlProp {
  currentQuestion: HealthProfileQuestion | null | undefined;
  isCompleted: boolean;
  nextQqId: NextQuestionnaireQuestionId;
}

export const generatePreviousUrl = ({
  questionsLength,
  questionnaireQuestionId: qQId,
  questionnaireId,
  urlChunk,
}: PrevUrlProps) => {
  const base = '/health-profile';
  let url = '';
  if (questionsLength === 0) {
    url = `${base}/${urlChunk}`;
  } else {
    const newIndex = questionsLength - 1;
    url = `${base}/${questionnaireId}/${qQId}/${newIndex}${urlChunk}`;
  }
  return url;
};

export const generateNextUrl = ({
  currentQuestion,
  isCompleted,
  nextQqId,
  questionnaireId,
  questionsLength,
  urlChunk,
}: NextUrlProps) => {
  const base = '/health-profile';
  let url = '';
  if (
    currentQuestion?.default_next_questionnaire_question_id === null ||
    isCompleted
  ) {
    url = `${base}/complete${urlChunk}`;
  } else {
    const newStep = questionsLength + 1;
    url = `${base}/${questionnaireId}/${nextQqId}/${newStep}${urlChunk}`;
  }
  return url;
};

export const setResponse = async (
  allAnswers: { [x: string]: HealthProfileResponse },
  questionnaireQuestionId: number,
  payload: {},
) => {
  const updatedAnswer = {
    ...allAnswers[questionnaireQuestionId],
    questionnaire_question_id: questionnaireQuestionId,
    skipped: false,
    ...payload,
  };
  return updatedAnswer;
};

type NextQuestionnaireQuestionId = number | null | undefined;

export const getNextQQIdByResponse = (
  currentQuestion: HealthProfileQuestion,
  response: HealthProfileResponse,
  keyValueQuestionsSet: MutableRefObject<KeyValueQuestionsSet>,
): NextQuestionnaireQuestionId => {
  const opts = response.options.map((o) => o.option_id);

  let nextQQId = currentQuestion.question_options.filter((o) =>
    opts.includes(o.option_id),
  )[0]?.next_questionnaire_question_id;

  if (keyValueQuestionsSet.current.answered) {
    const qQ = getNextUnansweredQq(
      currentQuestion,
      response,
      keyValueQuestionsSet,
    );
    nextQQId = qQ ? qQ.questionnaire_question_id : nextQQId;
    return nextQQId;
  } else {
    return nextQQId || currentQuestion.default_next_questionnaire_question_id;
  }
};

function getNextUnansweredQq(
  currentQuestion: HealthProfileQuestion,
  response: HealthProfileResponse,
  keyValueQuestionsSet: MutableRefObject<KeyValueQuestionsSet>,
): HealthProfileQuestion | null {
  const opts = response.options.map((o) => o.option_id);
  const qQId = currentQuestion.question_options.filter((o) =>
    opts?.includes(o.option_id),
  )[0]?.next_questionnaire_question_id;

  const nextQQId =
    qQId || currentQuestion.default_next_questionnaire_question_id;

  let nextQ = null;
  if (nextQQId) {
    nextQ = keyValueQuestionsSet.current.all[nextQQId];
    if (nextQ.unanswered || !nextQ.root) {
      return nextQ;
    } else {
      return getNextUnansweredQq(nextQ, response, keyValueQuestionsSet);
    }
  }
  return nextQ;
}

type GetUnansweredTree = {
  currentQuestion: HealthProfileQuestion;
  keyValueQuestionsSetAll: KeyValue;
};
export function getUnansweredTree({
  currentQuestion,
  keyValueQuestionsSetAll,
}: GetUnansweredTree): HealthProfileQuestion[] {
  let filterQuestions: HealthProfileQuestion[] = [];

  if (currentQuestion.unanswered) {
    filterQuestions.push(currentQuestion);
    const nextQuestions = getNextQuestionsInTree(
      currentQuestion.default_next_questionnaire_question_id,
      keyValueQuestionsSetAll,
    );
    filterQuestions = filterQuestions.concat(nextQuestions);
  } else {
    const { options } = currentQuestion.response!;
    const qQId = mapOptionToQuestionId(options, currentQuestion);
    const nextQuestions = getNextQuestionsInTree(qQId, keyValueQuestionsSetAll);
    filterQuestions = filterQuestions.concat(nextQuestions);
  }

  return filterQuestions;
}

function getNextQuestionsInTree(
  qQid: QuestionId,
  keyValueQuestionsSetAll: KeyValue,
): HealthProfileQuestion[] {
  if (isNull(qQid)) {
    return [];
  }
  const nextQuestion = keyValueQuestionsSetAll[qQid];

  if (nextQuestion.root) {
    return getUnansweredTree({
      currentQuestion: nextQuestion,
      keyValueQuestionsSetAll,
    });
  }

  return [];
}

type QuestionId = number | null;
function mapOptionToQuestionId(
  responseOptions: HealthProfileResponseOptions[],
  question: HealthProfileQuestion,
): QuestionId {
  let qQId = question.default_next_questionnaire_question_id;
  const { question_options: questionOptions } = question;

  // TODO implement logic for multiselect pair with backend
  const qO = questionOptions.find(
    (qO) => qO.option_id === responseOptions[0].option_id,
  );
  if (qO?.next_questionnaire_question_id) {
    qQId = qO.next_questionnaire_question_id;
  }

  return qQId;
}

export const filterQuestionnaire = ({
  keyValueQuestionsSetAll,
  questions,
  unansweredParam,
}: FilterProps): FilterParams => {
  const emptyArr: HealthProfileQuestion[] = [];

  let filterQuestions = emptyArr.concat(questions.filter((q) => q.root));
  if (unansweredParam && !isEmpty(keyValueQuestionsSetAll)) {
    filterQuestions = questions
      .filter((q) => q.unanswered)
      .filter((q) => q.root);
  }
  return filterQuestions;
};

export function sortQuestionOptions(options: HealthProfileOption[]) {
  options.sort((a, b) => a.position - b.position);
}
