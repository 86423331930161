import { Col, colors, H5, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const ScreenerQuestion = styled.p`
  ${typography.bodyTextSmall};
  font-weight: ${typography.weight.bold};
`;

const ScreenerAnswer = styled.p`
  ${typography.bodyTextSmall};
  font-weight: ${typography.weight.book};
  text-transform: capitalize;
  padding-bottom: ${size.sm}px;
`;

const EditButton = styled.p`
  ${typography.bodyTextXSmall};
  font-weight: ${typography.weight.bold};
  color: ${colors.green6};
  text-align: right;
  &:hover {
    cursor: pointer;
  }
`;

const Header = styled(H5)`
  padding-bottom: ${size.md}px;
  margin: 0;
`;

interface HeaderColumnProps {
  severe: boolean;
}

const HeaderColumn = styled(Col)<HeaderColumnProps>`
  padding-top: ${(props) => (props.severe ? `${size.xl1}px` : '0')};
`;

const Ol = styled.ol`
  padding-left: ${size.lg}px;
`;

export {
  EditButton,
  Header,
  HeaderColumn,
  ScreenerAnswer,
  ScreenerQuestion,
  Ol,
};
