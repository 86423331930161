import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { EnterHandler, ExitHandler } from 'react-transition-group/Transition';

import * as S from './styles';

export type FadeProps = {
  active: boolean;
  children: JSX.Element | JSX.Element[];
  duration?: number;
  onEnter?: EnterHandler;
  onExited?: ExitHandler;
};

function Fade(props: FadeProps) {
  const { active, children, duration = 300, onEnter, onExited } = props;
  return (
    <>
      {/* @ts-ignore */}
      <CSSTransition
        in={active}
        timeout={duration}
        unmountOnExit
        onEnter={onEnter}
        onExited={onExited}
      >
        <S.FadeWrapper duration={duration}>{children}</S.FadeWrapper>
      </CSSTransition>
    </>
  );
}

export default Fade;
