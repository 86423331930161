import useEnterpriseProgramSlug from 'common/hooks/useEnterpriseProgramSlug';
import useProgramSlug from 'common/hooks/useProgramSlug';
import AppointmentConfirmationPage from 'pages/VirtualCare/NativeScheduling/pages/AppointmentConfirmationPage';
import EnterpriseConfirmationPage from 'pages/VirtualCare/NativeScheduling/pages/EnterpriseConfirmationPage';
import React from 'react';

/**
 * This component is the responsible to redirect to the correct page.
 */
function ConfirmationPage() {
  const { enterpriseProgramSlug } = useEnterpriseProgramSlug();
  const { programSlug } = useProgramSlug();

  if (programSlug === enterpriseProgramSlug) {
    return <EnterpriseConfirmationPage />;
  }

  return <AppointmentConfirmationPage />;
}

export default ConfirmationPage;
