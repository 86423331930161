import { typography, size, colors, mediaQueries } from '@everlywell/leaves';
import { hexToRGB } from 'common/utils/helpers';
import Button from 'components/Button';
import styled from 'styled-components';import {
  Input,
} from 'components/Forms';

const Title = styled.h2`
  color: ${colors.gray4};
  font-size: 20.3px;
  font-weight: ${typography.weight.bold};
  line-height: 1.58;
  margin: 0 0 24px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 16px;
  }
`;

const SubTitle = styled.h3`
  font-weight: ${typography.weight.light};
  font-size: 18px;
  line-height: 32px;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${colors.gray4};
  margin: 0 0 32px;
`;

const Box = styled.div`
  background-color: ${colors.white};
  border-radius: 3px;
  box-shadow: 0 ${size.xs3}px ${size.md}px 0
    rgba(${hexToRGB(colors.black)}, 0.1);
  margin-bottom: 53px;
  padding: 32px 16px;

  ${mediaQueries.forTabletVerticalUp} {
    padding: 32px 24px;
    margin-bottom: 35px;
  }
`;

const InnerForm = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQueries.forTabletHorizontalUp} {
    flex-flow: row wrap;
    justify-content: space-between;
  }
`;

const FullWidthField = styled.div`
  ${mediaQueries.forTabletHorizontalUp} {
    flex: 0 0 100%;
  }
`;

const Field = styled.div`
  ${mediaQueries.forTabletHorizontalUp} {
    flex: 0 0 calc(50% - 12px);
  }
`;

const SmallWidthFieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${mediaQueries.forTabletHorizontalUp} {
    flex: 0 0 calc(50% - 12px);
  }
`;

const SmallWidthField = styled.div`
  flex: 0 0 calc(50% - 12px);
`;

const UpdatedButton = styled(Button)`
  outline: none;
  align-self: center;
  margin-top: 38px;
  font-size: 18.7px;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 230px;
  height: 50px;
  border-radius: 24px;

  &[disabled],
  &[disabled]:hover {
    cursor: not-allowed;
    border-color: transparent;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;

  ${mediaQueries.forTabletHorizontalUp} {
    justify-content: flex-start;
    flex: 1 0 100%;
  }
`;

const Spinner = styled.div`
  animation: opacity 0.8s linear infinite;
  border-color: transparent;
  border-radius: 100%;
  border-style: solid;
  border-top-color: ${colors.gray1};
  border-width: 4px;
  box-sizing: border-box;
  height: 32px;
  width: 32px;

  @keyframes opacity {
    0% {
      opacity: 1;
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
      opacity: 0.4;
    }
    100% {
      opacity: 1;
      transform: rotate(360deg);
    }
  }
`;

const AutoCompleteInput = styled(Input)`
  font-size: 16px;
  font-weight: 600;
  line-height: 50px;
  font-style: italic;
`;

export {
  AutoCompleteInput,
  Box,
  ButtonRow,
  Field,
  FullWidthField,
  InnerForm,
  SmallWidthField,
  SmallWidthFieldWrapper,
  Spinner,
  SubTitle,
  Title,
  UpdatedButton as Button,
};
