import { TelehealthAppointment } from 'common/apis/telehealthApis';
import { isAfter, isFuture, isPast, isToday, addHours } from 'date-fns';
import React, { createContext, useContext } from 'react';

type ContextProps = {
  pastAppointments: TelehealthAppointment[];
  futureAppointments: TelehealthAppointment[];
  allAppointments: TelehealthAppointment[];
  isAppointmentToday: (appointment: TelehealthAppointment) => boolean;
  isAfterCurrentTimePlusThreeHours: (
    appointment: TelehealthAppointment,
  ) => boolean;
  isUpcomingAppointment: (appointment: TelehealthAppointment) => boolean;
  hasCarePlan: (appointment: TelehealthAppointment) => boolean;
};

type AppointmentsContextProviderProps = {
  appointments: TelehealthAppointment[];
  children: React.ReactNode;
};

export const AppointmentsContext = createContext<ContextProps>({
  pastAppointments: [],
  futureAppointments: [],
  allAppointments: [],
  isAppointmentToday: (_appointment: TelehealthAppointment): boolean =>
    ({} as boolean),
  isAfterCurrentTimePlusThreeHours: (
    _appointment: TelehealthAppointment,
  ): boolean => ({} as boolean),
  isUpcomingAppointment: (_appointment: TelehealthAppointment): boolean =>
    ({} as boolean),
  hasCarePlan: (_appointment: TelehealthAppointment): boolean =>
    ({} as boolean),
});

export const AppointmentsContextProvider = ({
  children,
  appointments = [],
}: AppointmentsContextProviderProps) => {
  const isUpcomingAppointment = (appointment: TelehealthAppointment) =>
    isFuture(new Date(appointment.end_time)) &&
    appointment.status === null &&
    appointment.care_plan === null;

  const isPastAppointment = (appointment: TelehealthAppointment) =>
    isPast(new Date(appointment.end_time)) ||
    appointment.care_plan !== null ||
    appointment.status !== null;

  const futureAppointments = appointments?.filter(isUpcomingAppointment) ?? [];
  const pastAppointments = appointments?.filter(isPastAppointment) ?? [];

  const isAppointmentToday = (appointment: TelehealthAppointment) => {
    const appointmentStartTime = new Date(appointment.start_time);

    return isToday(appointmentStartTime);
  };

  const isAfterCurrentTimePlusThreeHours = (
    appointment: TelehealthAppointment,
  ) => isAfter(new Date(), addHours(new Date(appointment.end_time), 3));

  const hasCarePlan = (appointment: TelehealthAppointment) =>
    appointment.care_plan !== null;

  return (
    <AppointmentsContext.Provider
      value={{
        allAppointments: appointments,
        pastAppointments,
        futureAppointments,
        isAppointmentToday,
        isUpcomingAppointment,
        isAfterCurrentTimePlusThreeHours,
        hasCarePlan,
      }}
    >
      {children}
    </AppointmentsContext.Provider>
  );
};

export const useAppointmentsContext = () => useContext(AppointmentsContext);
