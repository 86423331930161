import { colors, typography, mediaQueries } from '@everlywell/leaves';
import {
  bodyCopy,
  sectionTitle,
  bodyCopyLarge,
  ArrowUp,
  ArrowDown,
} from 'common/styles/app-styles';
import styled from 'styled-components';

type Props = {
  expanded?: boolean;
  contentHeight?: string;
  tmmExpanded?: boolean;
  tmmHeight?: string;
  height?: number;
};

const Title = styled.h3`
  padding-top: 32px;
  color: ${colors.gray4};
  ${sectionTitle};
  font-weight: ${typography.weight.regular};
  margin: 0;
  ${mediaQueries.forTabletVerticalUp} {
    font-weight: ${typography.weight.bold};
  }

  ${mediaQueries.forPhoneOnly} {
    ${bodyCopy};
  }
`;

const MarkerContentWrapper = styled.div<Props>`
  overflow: hidden;
  background: ${colors.white};
  box-sizing: content-box;
  transition: height 0.3s ease-in;
  height: ${(props) => `${props.height}px`};
  padding: 0 16px;
  ${(props) =>
    props.expanded ? `border-top: solid 1px #ebebeb;` : `border-top: none;`};
  ${mediaQueries.forTabletVerticalUp} {
    padding: 0 24px;
  }
`;

const MarkerContentSection = styled.div`
  ${sectionTitle};
  padding-bottom: 32px;
  position: relative;
  z-index: 1;

  ${mediaQueries.forTabletVerticalUp} {
    font-weight: ${typography.weight.bold};
  }
`;

const MarkerContent = styled.div`
  margin-top: 8px;
  font-weight: ${typography.weight.light};
  ${bodyCopyLarge};

  p {
    font-weight: ${typography.weight.light};
    margin: 8px 0 16px;
  }

  a {
    font-weight: ${typography.weight.bold};
  }

  ${mediaQueries.forPhoneOnly} {
    ${bodyCopy};
  }
`;

const ContentToggle = styled.div<Props>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: ${colors.white};
  border-radius: 0 0 8px 8px;
  border-top: solid 1px #ebebeb;
  cursor: pointer;
  padding: 8px 0;
  color: ${colors.green4};
  ${bodyCopy};
  font-weight: ${typography.weight.regular};

  &:after {
    content: '';
    ${(props) => (props.expanded ? ArrowUp : ArrowDown)};
  }

  &:hover {
    color: ${colors.green3};

    &:after {
      content: '';
      ${(props) =>
        props.expanded
          ? `border-bottom-color: #0083CC;`
          : `border-top-color: #0083CC;`};
    }
  }
`;

const ResourceSection = styled.div`
  margin-top: 8px;
`;

const ResourceLink = styled.a`
  color: ${colors.green4};
  text-decoration: none;
  white-space: unset;

  &:hover {
    color: ${colors.green3};
  }

  ${bodyCopy};
`;

const TellMeMoreToggle = styled.div<Props>`
  display: flex;
  align-items: center;
  color: ${colors.green4};
  ${bodyCopy};
  font-weight: ${typography.weight.regular};
  padding: 0;
  cursor: pointer;
  &:after {
    content: '';
    ${(props) => (props.tmmExpanded ? ArrowUp : ArrowDown)};
  }

  &:hover {
    color: ${colors.green3};

    &:after {
      content: '';
      ${(props) =>
        props.tmmExpanded
          ? `border-bottom-color: #0083CC;`
          : `border-top-color: #0083CC;`};
    }
  }
`;

const TellMeMoreContent = styled.div<Props>`
  overflow: hidden;
  padding-top: 8px;
  font-weight: ${typography.weight.light};
  ${bodyCopy};
  height: ${(props) => `${props.height}px`};
  transition: height 0.2s ease-in, margin 0.2s ease-in;

  p {
    font-weight: ${typography.weight.light};
    ${bodyCopy};
    margin: 0;
    padding: ${(props) => (props.tmmExpanded ? '8px 0 16px' : '0')};
    transition: height 0.2s ease-in, margin 0.2s ease-in;
  }

  ${mediaQueries.forTabletVerticalUp} {
    ${bodyCopyLarge};

    p {
      ${bodyCopyLarge};
    }
  }
`;

const GraphWrapper = styled.div`
  display: block;
  position: relative;
  margin-bottom: 32px;
  ${mediaQueries.forTabletVerticalUp} {
    margin-bottom: 16px;
  }
`;

const GraphTitle = styled.h4`
  ${sectionTitle};
  font-weight: ${typography.weight.bold};
  margin: 0;
`;

export {
  Title,
  ContentToggle,
  MarkerContentWrapper,
  MarkerContentSection,
  MarkerContent,
  TellMeMoreToggle,
  TellMeMoreContent,
  ResourceSection,
  ResourceLink,
  GraphTitle,
  GraphWrapper,
};
