import React, { useEffect, useState, Ref } from 'react';
import { ErrorOption, FieldErrors, RegisterOptions } from 'react-hook-form';

import * as S from './BmiCalculation.styles';

export type BmiCalculationProps = {
  height: {
    feet?: number;
    inches?: number;
  };
  weight?: number;
  register: <T>(rules?: RegisterOptions) => (ref: (T & Ref<T>) | null) => void;
  errors: FieldErrors;
  clearErrors: (name: string) => void;
  setError: (name: string, error: ErrorOption) => void;
};

const CONVERSION_FACTOR = 703;

export const REQUIRED_ERROR =
  'Please enter your height and weight to calculate your BMI';
export const MEDIUM_BMI_WARNING =
  'For BMI 27-29, program eligibility requires one or more: Type 2 diabetes, prediabetes, hypertension, hyperlipidemia, sleep apnea, cardiovascular disease';

/**
 * Component for BMI calculation, receives height and weight and produces the results
 */
export default function BmiCalculation({
  height,
  weight,
  register,
  errors,
  clearErrors,
  setError,
}: BmiCalculationProps) {
  const [BMI, setBMI] = useState<string>();

  const isBMIValid = (value: string) =>
    parseFloat(value) < 27 ? `BMI = ${value};  BMI must be 27 or above` : true;

  const isBMIRange27_29 = (value: string) => {
    const parsedValue = Number(value && parseFloat(value));

    return parsedValue > 27 && parsedValue < 30
      ? MEDIUM_BMI_WARNING
      : undefined;
  };

  useEffect(() => {
    if (
      height?.feet !== undefined &&
      height?.feet >= 0 &&
      height?.inches !== undefined &&
      height?.inches >= 0 &&
      weight !== undefined &&
      weight >= 0
    ) {
      // Height converted in inches
      const heightInInches = height.feet * 12 + height.inches;

      /* BMI Formula:  (weight / (height * height)) * 703 */
      const result = (weight / Math.pow(heightInInches, 2)) * CONVERSION_FACTOR;

      // Set result with two decimal points
      setBMI(result.toFixed(2));

      if (BMI) {
        const validationResult = isBMIValid(BMI);
        if (validationResult === true) {
          clearErrors('BMI');
        } else {
          setError('BMI', {
            type: 'lowBMI',
            message: validationResult,
          });
        }
      }
    } else {
      setBMI(undefined);
    }
  }, [height?.feet, height?.inches, weight, BMI, clearErrors, setError]);

  return (
    <S.Container>
      <S.Input
        name="BMI-readonly"
        id="BMI-readonly"
        label="BMI"
        value={BMI?.toString()}
        ref={register<HTMLInputElement>({
          required: REQUIRED_ERROR,
          validate: {
            lowBMI: isBMIValid,
          },
        })}
        error={errors?.BMI?.message}
        hasError={errors?.BMI}
        helperText={BMI && isBMIRange27_29(BMI)}
        readOnly
      />
    </S.Container>
  );
}
