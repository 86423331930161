import {
  size,
  TabMenu,
  Tab,
  typography,
  Button,
  colors,
  mediaQueries,
  H4,
} from '@everlywell/leaves';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${size.xl1}px;
  ${mediaQueries.forTabletVerticalUp} {
    gap: ${size.xl4}px;
  }
`;

export const AppointmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${size.md}px;
`;

export const CustomTabMenu = styled(TabMenu)`
  ul {
    padding: 0;
    margin: 0;

    li {
      width: 100%;
    }
  }
`;

export const CustomTab = styled(Tab)`
  width: 100%;
`;

export const SectionTitle = styled.h2`
  ${typography.overlineText}
  margin: 0;
  padding: 0 0 ${size.md}px;
  padding-top: 0 !important; /* overlineText has a default padding-top which we shouldn't have */
`;

export const CustomButton = styled(Button)`
  width: 80%;
`;

export const NoResultsText = css`
  ${typography.bodyText}
  color: ${colors.black};
  font-weight: ${typography.weight.book};

  ${mediaQueries.forPhoneOnly} {
    text-align: center;
    font-size: 21px;
    line-height: 30px;
  }
`;

export const NextVisitHeader = styled(H4)`
  margin: 0 0 ${size.md}px;
`;
