import React from 'react';

import * as S from './PaginationItem.styles';

export type PaginationItemProps = React.ComponentProps<typeof S.Item> & {
  /** Number of the page */
  page: number;

  /** Link's url */
  to: string;

  /** Override the page number when it is set */
  text?: string;

  /** Is the current page? @default false */
  current?: boolean;

  /** Is the item disabled? @default false */
  disabled?: boolean;

  /** Is the item visible? @default true */
  visible?: boolean;
};

/**
 * Pagination Item.
 */
function PaginationItem({
  page,
  to,
  disabled,
  current = false,
  visible = true,
  text,
  ...props
}: PaginationItemProps) {
  const ariaLabel = current
    ? `Current page, page ${page}`
    : `Go to page ${page}`;

  const linkText = text ?? page;

  if (disabled) {
    return <S.DisabledItem aria-disabled="true">{linkText}</S.DisabledItem>;
  }

  return (
    <S.Item to={to} aria-current={current} aria-label={ariaLabel} {...props}>
      {linkText}
    </S.Item>
  );
}

export default PaginationItem;
