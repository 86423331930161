import { Button, H3, TertiaryLink } from '@everlywell/leaves';
import useProgramSlug from 'common/hooks/useProgramSlug';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import Grid from 'components/Grid';
import Layout from 'components/Layout';
import ErrorPage from 'pages/ErrorPage';
import { Container } from 'pages/InsurancePage/InsurancePage.styles';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import ShowInsurancePageSkeleton from '../InsurancePage/InsurancePage.skeleton';
import InsuranceCard from './components/InsuranceCard';
import { useLatestInsurancePlans } from './hooks/useLatestInsurancePlans';
import { useRedirectAfterDetailPage } from './utils/redirections';

export type InsuranceDetailsPageProps = {};

/**
 * Fetch insurance information for a use.
 * If no insurance information is found, redirect the user to the insurance form.
 */
function InsuranceDetailsPage(props: InsuranceDetailsPageProps) {
  const { programSlug } = useProgramSlug();
  const {
    primaryInsurance,
    secondaryInsurance,
    isLoading,
    isError,
    isFetched,
  } = useLatestInsurancePlans();

  const { navigateToNextPage, navigationPath, intakePrefixSlug } =
    useRedirectAfterDetailPage();

  const navigate = useNavigate();
  const onNavigateBack = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        page: ANALYTICS.PAGES.TELEHEALTH_INSURANCE_DETAILS_PAGE,
        program: programSlug,
        label: 'back',
      },
    });
    navigate(-1);
  };

  useEffect(() => {
    if (!isLoading && !primaryInsurance && !secondaryInsurance) {
      navigateToNextPage();
    }
  }, [primaryInsurance, secondaryInsurance, isLoading]);

  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        page: ANALYTICS.PAGES.TELEHEALTH_INSURANCE_DETAILS_PAGE,
        program: programSlug,
      },
    });
  }, [isFetched, programSlug]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'insuranceDetailsPage',
        programName: programSlug,
      },
    });
  }, [programSlug]);

  if (isLoading) {
    return (
      <Layout>
        <Container>
          <ShowInsurancePageSkeleton />
        </Container>
      </Layout>
    );
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <Layout>
      <Container>
        <Grid.Container spacing={['lg']}>
          <Grid.Item width={[1]}>
            <H3 as="h1" style={{ margin: 0 }}>
              Add insurance
            </H3>
          </Grid.Item>
          {primaryInsurance && (
            <Grid.Item width={[1]}>
              <InsuranceCard
                heading="Primary Insurance"
                insuranceName={primaryInsurance.insurance_plan.payer.name}
                memberID={primaryInsurance.num}
                groupID={primaryInsurance.group_num}
              />
            </Grid.Item>
          )}
          {secondaryInsurance && (
            <Grid.Item width={[1]}>
              <InsuranceCard
                heading="Secondary Insurance"
                insuranceName={secondaryInsurance.insurance_plan.payer.name}
                memberID={secondaryInsurance.num}
                groupID={secondaryInsurance.group_num}
              />
            </Grid.Item>
          )}
          <Grid.Item width={[1]}>
            <TertiaryLink
              component={Link}
              to={`${intakePrefixSlug}/insurance/add`}
              onClick={() => {
                analytics.track({
                  event: ANALYTICS.EVENTS.CLICKED_BUTTON,
                  data: {
                    page: ANALYTICS.PAGES.TELEHEALTH_INSURANCE_DETAILS_PAGE,
                    program: programSlug,
                    label: 'Add alternate policy',
                  },
                });
              }}
            >
              Enter an alternate insurance
            </TertiaryLink>
          </Grid.Item>

          <Grid.Item width={[1]}>
            <Grid.Container spacing={['md', 'lg']}>
              <Grid.Item width={['auto']} css={{ flexGrow: 0 }}>
                <Button
                  type="button"
                  appearance="secondary"
                  onClick={onNavigateBack}
                  css={{ width: '100%' }}
                >
                  Back
                </Button>
              </Grid.Item>
              <Grid.Item width={['auto']}>
                <Button
                  component={Link}
                  to={navigationPath}
                  css={{ width: '100%' }}
                  onClick={() => {
                    analytics.track({
                      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
                      data: {
                        page: ANALYTICS.PAGES.TELEHEALTH_INSURANCE_DETAILS_PAGE,
                        program: programSlug,
                        label: 'continue',
                      },
                    });
                  }}
                >
                  Continue
                </Button>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
      </Container>
    </Layout>
  );
}

export default InsuranceDetailsPage;
