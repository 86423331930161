import { shallow } from 'enzyme';
import React from 'react';

import { TakeActionSection } from '../index';

const availableTestProps = {
  contentToken: '1234',
  kitResultId: '1234',
  showConsult: true,
  showLiveWebinar: true,
  showUpsell: true,
  shouldShowPCPQuestions: true,
  availableUntil: new Date().toString(),
  pwnOrderNumber: 55555,
  pwnConfirmationNumber: 55555,
  testDisplayName: 'Cholesterol & Lipids Test',
  pcp: true,
  isWebinarEnabledForTest: false,
  isWebinarAvailable: false,
};

const unavailableTestProps = {
  contentToken: '1234',
  kitResultId: '1234',
  showConsult: false,
  showLiveWebinar: false,
  showUpsell: false,
  shouldShowPCPQuestions: true,
  availableUntil: new Date().toString(),
  pwnOrderNumber: 55555,
  pwnConfirmationNumber: 55555,
  testDisplayName: 'Cholesterol & Lipids Test',
  pcp: true,
  isWebinarEnabledForTest: false,
  isWebinarAvailable: false,
};

const fsAvailableTestProps = {
  contentToken: '1234',
  kitResultId: '1234',
  showConsult: true,
  showLiveWebinar: true,
  showUpsell: true,
  shouldShowPCPQuestions: true,
  availableUntil: new Date().toString(),
  pwnOrderNumber: 55555,
  pwnConfirmationNumber: 55555,
  testDisplayName: 'Food Sensitivity Test',
  pcp: true,
  isWebinarEnabledForTest: true,
  isWebinarAvailable: true,
};

describe('<TakeActionSection />', () => {
  describe('when results discussion is available', () => {
    it('should render the Result Discussion Section', () => {
      const renderedComponent = shallow(
        <TakeActionSection {...availableTestProps} />,
      );
      expect(renderedComponent.find('Connect(ResultsDiscussion)')).toHaveLength(
        1,
      );
    });
  });

  describe('when results discussion is not available', () => {
    it('should not render the Result Discussion Section', () => {
      const renderedComponent = shallow(
        <TakeActionSection {...unavailableTestProps} />,
      );
      expect(renderedComponent.find('Connect(ResultsDiscussion)')).toHaveLength(
        0,
      );
    });
  });

  describe('when product upsell is present', () => {
    it('should render the Product Upsell Section', () => {
      const renderedComponent = shallow(
        <TakeActionSection {...availableTestProps} />,
      );
      expect(renderedComponent.find('Connect(ProductUpsell)')).toHaveLength(1);
    });
  });

  describe('when product upsell is not present', () => {
    it('should not render the Product Upsell Section', () => {
      const renderedComponent = shallow(
        <TakeActionSection {...unavailableTestProps} />,
      );
      expect(renderedComponent.find('Connect(ProductUpsell)')).toHaveLength(0);
    });
  });

  describe('when consult is available for fs tests', () => {
    it('should render the Webinar Card', () => {
      const renderedComponent = shallow(
        <TakeActionSection {...fsAvailableTestProps} />,
      );
      expect(renderedComponent.find('Connect(ResultsDiscussion)')).toHaveLength(
        2,
      );
    });
  });

  describe('when telehealth consult is available', () => {
    const thyroidAvailableTestProps = {
      contentToken: '1234',
      kitResultId: '1234',
      showConsult: true,
      showLiveWebinar: false,
      showUpsell: true,
      shouldShowPCPQuestions: true,
      availableUntil: new Date().toString(),
      pwnOrderNumber: 55555,
      pwnConfirmationNumber: 55555,
      testDisplayName: 'Thyroid Test',
      shouldShowTelehealthConsult: true,
      pcp: true,
      isWebinarEnabledForTest: false,
      isWebinarAvailable: false,
    };

    it('displays the scheduling consult link', () => {
      const renderedComponent = shallow(
        <TakeActionSection {...thyroidAvailableTestProps} />,
      );
      expect(renderedComponent.find('ScheduleConsult')).toHaveLength(1);
    });
  });

  describe('when live webinar and consult is available', () => {
    const thyroidAvailableTestProps = {
      contentToken: '1234',
      kitResultId: '1234',
      showConsult: true,
      showLiveWebinar: true,
      showUpsell: true,
      shouldShowPCPQuestions: true,
      availableUntil: new Date().toString(),
      pwnOrderNumber: 55555,
      pwnConfirmationNumber: 55555,
      testDisplayName: 'Thyroid Test',
      shouldShowTelehealthConsult: true,
      pcp: true,
      isWebinarEnabledForTest: false,
      isWebinarAvailable: false,
    };

    it('display the live webinar schedule link and telehealth consult', () => {
      const renderedComponent = shallow(
        <TakeActionSection {...thyroidAvailableTestProps} />,
      );
      expect(renderedComponent.find('Connect(ResultsDiscussion)')).toHaveLength(
        1,
      );
      expect(renderedComponent.find('ScheduleConsult')).toHaveLength(1);
    });
  });

  describe('when telehealth consult is not available', () => {
    const thyroidAvailableTestProps = {
      contentToken: '1234',
      kitResultId: '1234',
      showConsult: false,
      showLiveWebinar: true,
      showUpsell: true,
      shouldShowPCPQuestions: true,
      availableUntil: new Date().toString(),
      pwnOrderNumber: 55555,
      pwnConfirmationNumber: 55555,
      testDisplayName: 'Thyroid Test',
      shouldShowTelehealthConsult: false,
      pcp: true,
      isWebinarEnabledForTest: false,
      isWebinarAvailable: false,
    };

    it('does not display the scheduling consult link', () => {
      const renderedComponent = shallow(
        <TakeActionSection {...thyroidAvailableTestProps} />,
      );
      expect(renderedComponent.find('ScheduleConsult')).toHaveLength(0);
    });
  });

  describe('when live webinar is not available and consult is available', () => {
    const thyroidAvailableTestProps = {
      contentToken: '1234',
      kitResultId: '1234',
      showConsult: true,
      showLiveWebinar: false,
      showUpsell: true,
      shouldShowPCPQuestions: true,
      availableUntil: new Date().toString(),
      pwnOrderNumber: 55555,
      pwnConfirmationNumber: 55555,
      testDisplayName: 'Thyroid Test',
      shouldShowTelehealthConsult: true,
      pcp: true,
      isWebinarEnabledForTest: false,
      isWebinarAvailable: false,
    };

    it('does not display the live webinar schedule link', () => {
      const renderedComponent = shallow(
        <TakeActionSection {...thyroidAvailableTestProps} />,
      );
      expect(renderedComponent.find('Connect(ResultsDiscussion)')).toHaveLength(
        0,
      );
      expect(renderedComponent.find('ScheduleConsult')).toHaveLength(1);
    });
  });
});
