import { Text, TextProps } from '@everlywell/ui-kit';
import React from 'react';

export type CarePlanInfoCardTextProps = {
  content: string;
  color?: TextProps['color'];
};

const CarePlanInfoCardText = ({
  content,
  color = 'tints.uiGrey',
}: CarePlanInfoCardTextProps) => (
  <Text size="lg" color={color}>
    {content}
  </Text>
);

export default CarePlanInfoCardText;
