import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import React from 'react';

import * as S from './SectionFooter.styles';
import TopIcon from './TopIcon';

export type SectionFooterProps = {
  title: string;
  subTitle?: string;
  link?: {
    href: string;
    label: string;
  };
};

/**
 * Shows a SectionFooter. To be used in the bottom of a page
 * @param props The SectionFooter props.
 * @returns The SectionFooter component
 *
 */
export default function SectionFooter({
  title,
  subTitle,
  link,
}: SectionFooterProps) {
  const onLinkClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_LINK,
      data: {
        label: title,
        link: link?.href,
      },
    });
  };

  return (
    <S.Container>
      <S.IconContainer>
        <TopIcon />
      </S.IconContainer>

      <S.Title>{title}</S.Title>

      {subTitle && <S.SubTitle>{subTitle}</S.SubTitle>}

      {link && (
        <S.Link href={link.href} onClick={onLinkClick}>
          {link.label}
        </S.Link>
      )}
    </S.Container>
  );
}
