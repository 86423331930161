import { TelehealthCarePlan } from 'common/apis/telehealthApis';

const baseCarePlan: TelehealthCarePlan = {
  assessment: `
    This is an updated example of the assessment and plan. <br /> <br />
    The patient has shown significant improvement in their condition. Continued monitoring and follow-up
    appointments are recommended to ensure ongoing progress. <br /> <br />Please adhere to the prescribed
    medication and lifestyle changes for optimal results.
  `,
  diagnoses: [
    {
      description: 'screening for malignant neoplasm of colon',
      note: `
        Patient has completed colorectal cancer screening with FIT, and had a POSITIVE FIT
        result. \r\nA follow-on screening colonoscopy is recommended as part of the
        full screening continuum (CMS, Jan. 2023).\r\nA gastroenterology referral
        for positive FIT and screening colonoscopy are ordered here.  Please see included
        documentation for more information obtained at today''s visit.\r\n\r\nEducation:  \r\nWith
        positive FIT, there is an increased likelihood that colorectal cancer is present.  Therefore,
        this referral should be acted upon quickly by patient and provider receiving
        referral.  Follow-on colonoscopy or gastroenterology consult is recommended
        to be arranged as soon as possible. \r\n
      `,
    },
  ],
  orders: {
    lab_orders: [{ id: 1, type: 'lab', description: 'Screen for cancer' }],
    prescription_orders: [
      {
        id: 2,
        type: 'prescription',
        description: 'Please follow up with your primary care provider',
      },
    ],
    patient_info_orders: [],
    imaging_orders: [],
    referral_orders: [],
    other_orders: [],
    vaccine_orders: [],
    consult_orders: [],
  },
  physician: {
    name: 'John Doe',
  },
};

export const buildCarePlan = (
  overrideProps: Partial<TelehealthCarePlan> = {},
) => ({
  ...baseCarePlan,
  ...overrideProps,
});
