import {
  Button,
  H3,
  mediaQueries,
  Modal,
  size,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

const ShareResultsModal = styled(Modal)`
  top: ${size.xl4}px;

  ${mediaQueries.forPhoneOnly} {
    div:nth-child(2) {
      padding: 0;
    }
  }

  ${mediaQueries.forTabletVerticalUp} {
    div:nth-child(2) {
      align-items: center;
      justify-content: center;
      margin-bottom: ${size.xl8}px;
    }
  }
  ${mediaQueries.forTabletHorizontalUp} {
    div:nth-child(2) {
      margin-bottom: 0;
      overflow: hidden;
    }
  }
  ${mediaQueries.forDesktopUp} {
    top: ${size.xl8}px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${size.xl3}px;

  ${mediaQueries.forTabletVerticalUp} {
    margin-top: 0;
  }
`;

const Header = styled(H3)`
  margin: ${size.md}px;
  text-align: center;

  ${mediaQueries.forTabletVerticalUp} {
    margin: ${size.xl1}px 0 ${size.md}px;
    width: 484px;
  }
`;

const IconContainer = styled.img`
  height: 72px;
  width: 72px;
`;

const Copy = styled.div`
  ${typography.bodyTextSmall}
  text-align: center;
  margin-bottom: ${size.lg}px;
  font-weight: ${typography.weight.light};
  ${mediaQueries.forTabletVerticalUp} {
    width: 532px;
    margin-bottom: ${size.xl1}px;
  }
`;

const ConsentButton = styled(Button)`
  width: 100%;
  ${mediaQueries.forTabletVerticalUp} {
    width: 532px;
  }
`;

const NonConsentButton = styled(Button)`
  margin: ${size.md}px;
`;

export {
  Header,
  IconContainer,
  Copy,
  NonConsentButton,
  ContentContainer,
  ConsentButton,
  ShareResultsModal,
};
