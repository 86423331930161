import React from 'react';

import CarePlanCardContainer from '../CarePlanCardContainer';
import CarePlanInfoCardBody from './CarePlanInfoCardBody';
import CarePlanInfoCardHeader from './CarePlanInfoCardHeader';

export type CarePlanInfoCardProps = {
  sectionName: string;
  iconName:
    | 'CircleDashed'
    | 'FileText'
    | 'Prescription'
    | 'ArrowSquareUpRight'
    | 'Syringe'
    | 'ExcludeSquare'
    | 'TextAlignLeft'
    | 'Info'
    | 'UserFocus';
  items?: {
    description: string;
    note?: string;
  }[];
  headerWrapper?: {
    component: React.ElementType;
    props?: Record<string, unknown>;
  };
  bodyWrapper?: {
    component: React.ElementType;
    props?: Record<string, unknown>;
  };
  onButtonClick?: () => void;
  isLoading?: boolean;
  isExpanded?: boolean;
};

const CarePlanInfoCard = ({
  sectionName,
  iconName,
  items,
  headerWrapper,
  bodyWrapper,
  onButtonClick,
  isLoading,
  isExpanded,
}: CarePlanInfoCardProps) => (
  <CarePlanCardContainer isExpanded={isExpanded}>
    <CarePlanInfoCardHeader
      sectionName={sectionName}
      iconName={iconName}
      itemsCount={items?.length ?? 0}
      wrapper={headerWrapper}
      onClick={onButtonClick}
    />
    <CarePlanInfoCardBody
      items={items}
      wrapper={bodyWrapper}
      isLoading={isLoading}
    />
  </CarePlanCardContainer>
);

export default CarePlanInfoCard;
