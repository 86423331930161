import { Col, Row } from '@everlywell/leaves';
import { FOOD_SENSITIVITY_PERSONALIZATION as DATA_TEST } from 'common/utils/constants/dataTest';
import React, { useEffect, useRef, useState } from 'react';
import { useSpring } from 'react-spring';

import * as S from './styles';

export interface Props {
  responses: string[];
  activeIndex: number;
  setActiveIndex: (arg0: number) => void;
}

export type ContentObject = {
  [key: string]: string;
};

const FoodSensitivityHealthProfileTable = ({
  responses,
  activeIndex,
  setActiveIndex,
}: Props) => {
  const [activeTabWidth, setActiveTabWidth] = useState(0);
  const [activeTabOffset, setActiveTabOffset] = useState(0);
  const sentenceCaseResponses = responses.map((response) => {
    const editedResponse = response.split(' ');
    editedResponse[1] = editedResponse[1].toLowerCase();
    return editedResponse.join(' ');
  });
  const allItems = ['Food sensitivity'].concat(sentenceCaseResponses);
  const ref = useRef<Array<HTMLDivElement>>(Array(allItems.length));
  const width = ref?.current[activeIndex]?.scrollWidth;
  const offset = ref?.current[activeIndex]?.offsetLeft;

  useEffect(() => {
    setActiveTabWidth(width);
    setActiveTabOffset(offset);
  }, [width, offset]);

  // stubbed content until we add content to Contentful

  const whatIsIt: ContentObject = {
    'Food sensitivity':
      'A delayed non life threatening immune response to a specific food perceived by the individual',
    'Food allergy':
      'An often immediate, potentially life-threatening immune response to a specific food',
    'Lactose intolerance':
      'An often rapid discomfort due to a deficiency of lactose, an enzyme needed to break down lactose during digestion',
    'Celiac disease':
      'A genetic autoimmune condition where ingestion of gluten leads to damage of the small intestine',
  };

  const howItsDiscovered: ContentObject = {
    'Food sensitivity': 'Blood test for reactivity of IgG antibodies',
    'Food allergy': 'Skin prick test, blood test for IgE antibodies',
    'Lactose intolerance': 'Hydrogen breath test',
    'Celiac disease':
      'Blood test for tTG-IgA antibodies, genetic testing, confirmatory endoscopy/biopsy',
  };

  const commonSymptoms: ContentObject = {
    'Food sensitivity':
      'Gastrointestinal symptoms such as bloating and headaches',
    'Food allergy':
      'Coughing or wheezing. Face, tongue, and lip swelling. Flushed skin, rash, or hives Tingling or itchy sensation in mouth',
    'Lactose intolerance':
      'Abdominal cramping, bloating, diarrhea, gas, nausea and/or vomiting',
    'Celiac disease':
      'Chronic diarrhea, foul-smelling fatty stools, weight loss, fatigue, bone or joint pain, bone loss, itchy skin rash',
  };

  const handleSelect = (index: number) => {
    setActiveIndex(index);
  };

  const tabAnimation = useSpring({
    to: {
      width: activeTabWidth ? `${activeTabWidth}px` : '0px',
      left: activeTabOffset ? `${activeTabOffset}px` : '0px',
    },
  });

  return (
    <Row>
      <S.DesktopTableCol>
        <S.Header>Explore the differences </S.Header>
        <S.Table data-test={`${DATA_TEST.CONTAINER}-desktop-table`}>
          <colgroup>
            <col></col>

            {allItems.map((response) => (
              <col
                key={response}
                className={response}
                data-test={`${DATA_TEST.COLGROUP}-desktop`}
              ></col>
            ))}
          </colgroup>
          <tbody>
            <tr>
              <td></td>

              {allItems.map((response) => (
                <th key={response} scope="col">
                  {response}
                </th>
              ))}
            </tr>
            <tr>
              <th scope="row">What is it</th>
              {allItems.map((response) => (
                <td key={response} className={response}>
                  {whatIsIt[response]}
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row">How {"it's"} discovered</th>

              {allItems.map((response) => (
                <td key={response} className={response}>
                  {howItsDiscovered[response]}
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row">Common symptoms</th>

              {allItems.map((response) => (
                <td key={response} className={response}>
                  {commonSymptoms[response]}
                </td>
              ))}
            </tr>
          </tbody>
        </S.Table>
      </S.DesktopTableCol>
      <S.Tabs
        id="FS-Mobile-Table"
        aria-label="Health Profile Pill Navigation"
        aria-controls="productGrid"
      >
        {allItems.map((item, idx) => (
          <S.Tab
            active={idx === activeIndex}
            key={item}
            ref={(el) => {
              if (el) {
                // @ts-ignore
                ref.current[idx] = el;
              }
            }}
            onClick={() => handleSelect(idx)}
          >
            {item}
          </S.Tab>
        ))}
        <S.BubbleTab style={tabAnimation} />
      </S.Tabs>
      <Col>
        <S.MobilePillContext>
          <tbody>
            <tr>
              <th scope="row">What is it</th>
              <td>{whatIsIt[allItems[activeIndex]]}</td>
            </tr>
            <tr>
              <th scope="row">How it's discovered</th>
              <td>{howItsDiscovered[allItems[activeIndex]]}</td>
            </tr>
            <tr>
              <th scope="row">Common symptoms</th>
              <td>{commonSymptoms[allItems[activeIndex]]}</td>
            </tr>
          </tbody>
        </S.MobilePillContext>
      </Col>
    </Row>
  );
};

export default FoodSensitivityHealthProfileTable;
