import { typography, size, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

export const DataMainContainer = styled.div`
  margin-top: ${size.lg}px;
`;

export const UpContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
  }
`;

export const Plan = styled.h3`
  ${typography.h3Text}
  margin: 0 0 ${size.md}px;
  flex: 1;

  ${mediaQueries.forTabletHorizontalUp} {
    font-size: ${size.lg}px;
    margin-bottom: ${size.lg}px;
  }
`;

export const MonthlyPrice = styled.div`
  ${typography.bodyTextSmall}
  margin-top: ${size.xs2}px;

  ${mediaQueries.forTabletHorizontalUp} {
    font-size: ${size.md}px;
  }
`;

export const NextBillingDate = styled.div`
  ${typography.bodyTextSmall}

  ${mediaQueries.forTabletHorizontalUp} {
    margin-top: 0;
    font-size: ${size.md}px;
  }
`;

export const BillingTitle = styled.p`
  ${typography.bodyText}
  font-weight: ${typography.weight.bold}
`;

export const Enrolled = styled.div`
  ${typography.bodyTextSmall};
  flex: 1;
  margin-bottom: ${size.md}px;

  ${mediaQueries.forTabletHorizontalUp} {
    font-size: ${size.md}px;
    margin-bottom: ${size.lg}px;
  }
`;

export const EnrolledTitle = styled.div`
  ${typography.bodyText}
  font-weight: ${typography.weight.bold};
`;
