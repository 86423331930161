import { SEVERITY_VALUE } from 'common/utils/constants';
import { ImmutableReduxState } from 'common/utils/types';
import ListWithIcons, {
  Props as ListWithIconsProps,
  Item,
} from 'components/ListWithIcons';
import { SeverityContext } from 'containers/KitResultDetailPageContainer';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { makeSelectContent } from 'store/selectors';

const CONTENT_KEYS = {
  header: 'LIST_SUBHEAD',
  texts: 'LIST_DESCRIPTION_*',
  iconSrcs: 'LIST_ICON_*',
  alts: 'LIST_ICON_ALT_*',

  // customized snippets - only covid-19-test will have for now
  detectedHeader: 'LIST_SUBHEAD_DETECTED',
  notDetectedHeader: 'LIST_SUBHEAD_NOT_DETECTED',
  indeterminateHeader: 'LIST_SUBHEAD_INDETERMINATE',
  detectedTexts: 'LIST_DESCRIPTION_DETECTED_*',
  notDetectedTexts: 'LIST_DESCRIPTION_NOT_DETECTED_*',
  indeterminateTexts: 'LIST_DESCRIPTION_INDETERMINATE_*',
  detectedIconSrcs: 'LIST_ICON_DETECTED_*',
  notDetectedIconSrcs: 'LIST_ICON_NOT_DETECTED_*',
  indeterminateIconSrcs: 'LIST_ICON_INDETERMINATE_*',
};

type StateProps = {
  content: {
    header: string;
    texts?: string[];
    detectedTexts?: string[];
    notDetectedTexts?: string[];
    indeterminateTexts?: string[];
    detectedHeader?: string;
    notDetectedHeader?: string;
    indeterminateHeader?: string;
    iconSrcs: string[];
    detectedIconSrcs?: string[];
    notDetectedIconSrcs?: string[];
    indeterminateIconSrcs?: string[];
    alts: string[];
  };
};

type OwnProps = {
  contentToken: string;
};

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): StateProps {
  return {
    // @ts-ignore
    content: makeSelectContent(ownProps.contentToken, CONTENT_KEYS)(state),
  };
}

interface CustomizedContent {
  detectedItems?: Item[];
  notDetectedItems?: Item[];
  indeterminateItems?: Item[];
  detectedHeader?: string;
  notDetectedHeader?: string;
  indeterminateHeader?: string;
}

export function ListWithIconsContainer(
  props: ListWithIconsProps & CustomizedContent,
) {
  const {
    items,
    detectedItems = [],
    notDetectedItems = [],
    indeterminateItems = [],
    header,
    detectedHeader,
    notDetectedHeader,
    indeterminateHeader,
  } = props;

  const hasContent =
    items.length > 0 ||
    detectedItems.length > 0 ||
    notDetectedItems.length > 0 ||
    indeterminateItems.length > 0;

  // only COVID-19-Test will have a severityValue
  const severityValue = useContext(SeverityContext);

  if (!hasContent) {
    return null;
  }

  let isCovid = false;

  if (severityValue) {
    isCovid = true;
    let customizedItems: Item[] = items;
    let customizedHeader: string = header;

    if (
      severityValue === SEVERITY_VALUE.DETECTED &&
      detectedItems &&
      detectedHeader
    ) {
      customizedItems = detectedItems;
      customizedHeader = detectedHeader;
    } else if (
      severityValue === SEVERITY_VALUE.NOT_DETECTED &&
      notDetectedItems &&
      notDetectedHeader
    ) {
      customizedItems = notDetectedItems;
      customizedHeader = notDetectedHeader;
    } else if (
      severityValue === SEVERITY_VALUE.INDETERMINATE &&
      indeterminateItems &&
      indeterminateHeader
    ) {
      customizedItems = indeterminateItems;
      customizedHeader = indeterminateHeader;
    }
    return (
      <ListWithIcons
        {...props}
        items={customizedItems}
        header={customizedHeader}
        isCovid={isCovid}
      />
    );
  }

  // pass in header and items
  return <ListWithIcons {...props} />;
}

export function mergeProps(
  stateProps: StateProps,
  dispatchProps: null,
  ownProps: OwnProps,
): ListWithIconsProps & CustomizedContent {
  const { content } = stateProps;
  if (!content) {
    return {
      ...ownProps,
      header: '',
      items: [],
    };
  }

  const {
    header,
    detectedHeader,
    notDetectedHeader,
    indeterminateHeader,
    texts,
    iconSrcs,
    detectedIconSrcs,
    notDetectedIconSrcs,
    indeterminateIconSrcs,
    alts,
    detectedTexts,
    notDetectedTexts,
    indeterminateTexts,
  } = content;

  let items: Item[] = [];
  let detectedItems: Item[] = [];
  let notDetectedItems: Item[] = [];
  let indeterminateItems: Item[] = [];

  if (texts) {
    items = texts.map((text, index) => ({
      text,
      alt: alts[index],
      iconSrc: iconSrcs[index],
    }));
  }

  if (detectedTexts) {
    detectedItems = detectedTexts.map((text, index) => ({
      text,
      alt: alts[index],
      iconSrc: detectedIconSrcs ? detectedIconSrcs[index] : iconSrcs[index],
    }));
  }

  if (notDetectedTexts) {
    notDetectedItems = notDetectedTexts.map((text, index) => ({
      text,
      alt: alts[index],
      iconSrc: notDetectedIconSrcs
        ? notDetectedIconSrcs[index]
        : iconSrcs[index],
    }));
  }

  if (indeterminateTexts) {
    indeterminateItems = indeterminateTexts.map((text, index) => ({
      text,
      alt: alts[index],
      iconSrc: indeterminateIconSrcs
        ? indeterminateIconSrcs[index]
        : iconSrcs[index],
    }));
  }

  return {
    header,
    detectedHeader,
    notDetectedHeader,
    indeterminateHeader,
    items,
    detectedItems,
    notDetectedItems,
    indeterminateItems,
  };
}

export default connect(
  mapStateToProps,
  null,
  mergeProps,
)(ListWithIconsContainer);
