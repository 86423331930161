import { Dropdown, formUtils, states } from '@everlywell/leaves';
import Grid from 'components/Grid/Grid';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { Input, labelStyles } from '../../utils/styles';
import { FormBuilderField } from '../../utils/types';

export type LocationFieldProps = FormBuilderField;

function getDefaultValues(formValues: string) {
  const values = formValues?.split(/\r\n/);
  return {
    addressLine1: values?.[0] || '',
    addressLine2: values?.[1] || '',
    city: values?.[2] || '',
    state: values?.[3] || '',
    zipCode: values?.[values.length - 1] || '',
  };
}

function LocationField(props: LocationFieldProps) {
  const { register, errors, watch, control } = useFormContext();
  /**
   * We need to use a temp field name because the name prop is used to send the proper data to the backend.
   */
  const tempFieldName = `${props.id}-tmp`;
  const { addressLine1, addressLine2, city, state, zipCode } = getDefaultValues(
    watch(props.id),
  );

  // country code is hardcoded for now to US customers
  const hiddenValue = `${watch(tempFieldName)?.addressLine1 ?? ''}\r\n${
    watch(tempFieldName)?.addressLine2 ?? ''
  }\r\n${watch(tempFieldName)?.state ?? ''}\r\n${
    watch(tempFieldName)?.city ?? ''
  }\r\nUS\r\n${watch(tempFieldName)?.zipCode ?? ''}`;

  return (
    <React.Fragment>
      <Grid.Item width={[1]}>
        <Controller
          control={control}
          name={`${tempFieldName}.addressLine1`}
          rules={{
            required: props.required && 'Please enter an address line 1',
          }}
          defaultValue={addressLine1}
          render={({ onChange, ref, value }) => (
            <Input
              css={labelStyles(props.variant)}
              error={errors[tempFieldName]?.addressLine1?.message}
              id={`${tempFieldName}.addressLine1`}
              label="Address Line 1"
              name={`${tempFieldName}.addressLine1`}
              onChange={onChange}
              ref={ref}
              required={props.required}
              type="text"
              value={value}
            />
          )}
        />
      </Grid.Item>

      <Grid.Item width={[1]}>
        <Controller
          control={control}
          name={`${tempFieldName}.addressLine2`}
          id={`${tempFieldName}.addressLine2`}
          defaultValue={addressLine2}
          render={({ onChange, ref, value }) => (
            <Input
              css={labelStyles(props.variant)}
              id={`${tempFieldName}.addressLine2`}
              label="Address Line 2 (optional)"
              name={`${tempFieldName}.addressLine2`}
              onChange={onChange}
              ref={ref}
              type="text"
              value={value}
            />
          )}
        />
      </Grid.Item>

      <Grid.Item width={[1, 1 / 3]}>
        <Controller
          control={control}
          name={`${tempFieldName}.city`}
          id={`${tempFieldName}.city`}
          rules={{
            required: props.required && 'Please enter a city',
          }}
          defaultValue={city}
          render={({ onChange, ref, value }) => (
            <Input
              css={labelStyles(props.variant)}
              label="City"
              type="text"
              id={`${tempFieldName}.city`}
              name={`${tempFieldName}.city`}
              required={props.required}
              error={errors[tempFieldName]?.city?.message}
              onChange={onChange}
              value={value}
              ref={ref}
            />
          )}
        />
      </Grid.Item>

      <Grid.Item width={[1 / 2, 1 / 3]}>
        <Controller
          control={control}
          name={`${tempFieldName}.state`}
          id={`${tempFieldName}.state`}
          defaultValue={state}
          rules={{
            required: props.required && 'Please enter a state',
          }}
          render={({ onChange, ref, value }) => (
            <Dropdown
              css={labelStyles(props.variant)}
              label="State"
              id={`${tempFieldName}.state`}
              name={`${tempFieldName}.state`}
              placeholderText="Select a state"
              showErrorMessage
              items={Object.entries(states.stateAbbreviationToNames).map(
                ([abbreviation, name]) => ({
                  value: abbreviation,
                  text: name,
                  id: abbreviation,
                }),
              )}
              required={props.required}
              ref={ref}
              error={errors[tempFieldName]?.state?.message}
              value={value}
              onChange={onChange}
            />
          )}
        ></Controller>
      </Grid.Item>

      <Grid.Item width={[1 / 2, 1 / 3]}>
        <Controller
          control={control}
          id={`${tempFieldName}.zipCode`}
          name={`${tempFieldName}.zipCode`}
          defaultValue={zipCode}
          rules={{
            required: props.required && 'Please enter a zip code',
            validate: {
              validZipCode: (value: any) =>
                props.required
                  ? formUtils.validateZipCode(value) ||
                    'Please enter a valid ZIP code'
                  : true,
            },
          }}
          render={({ onChange, ref, value }) => (
            <Input
              css={labelStyles(props.variant)}
              label="Zip Code"
              type="text"
              id={`${tempFieldName}.zipCode`}
              name={`${tempFieldName}.zipCode`}
              required={props.required}
              ref={ref}
              error={errors[tempFieldName]?.zipCode?.message}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </Grid.Item>
      <input
        type="hidden"
        name={props.id}
        ref={register()}
        value={hiddenValue}
      />
    </React.Fragment>
  );
}

export default LocationField;
