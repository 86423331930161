import {
  H4 as LeavesH4,
  Modal as LeavesModal,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import Grid from 'components/Grid';
import styled from 'styled-components';

export const Modal = styled(LeavesModal)`
  top: ${size.xl4}px;

  ${mediaQueries.forTabletHorizontalUp} {
    top: ${size.xl8}px;
  }

  & > button {
    display: none;
  }

  p {
    ${typography.bodyTextSmall}
    font-weight: ${typography.weight.light};
  }
`;

export const H4 = styled(LeavesH4)`
  margin: 0;
`;

export const centerModalStyles = {
  textAlign: 'center',
};

export const Container = styled(Grid.Container)`
  ${mediaQueries.forTabletHorizontalUp} {
    margin: 0;
  }
`;
