import { icons } from 'common/icons';
import Grid from 'components/Grid/Grid';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FormBuilderField } from '../../utils/types';
import * as S from './DocumentField.styles';

export type DocumentFieldProps = FormBuilderField;

function DocumentField(props: DocumentFieldProps) {
  const { register, errors, watch, setValue } = useFormContext();

  const label = props?.label?.replace(/\[upload\]\s*/g, '');
  const fieldName = props.id;

  const files = watch(fieldName);
  const selectedFile = files?.[0];

  return (
    <Grid.Item width={[1]}>
      <S.FileInputContainer>
        <S.FileInputDescription>{label}</S.FileInputDescription>
        {!selectedFile ? (
          <label htmlFor={fieldName}>
            <S.FileInputCard role="button">
              <S.FileInputIcon>+</S.FileInputIcon>
              <S.FileInputText>
                Upload here{props.required ? '' : ' (Optional)'}
              </S.FileInputText>
            </S.FileInputCard>
          </label>
        ) : (
          <S.FileInputSelectedField>
            <S.TextWrapper>{selectedFile.name}</S.TextWrapper>
            <S.FileInputRemoveButton
              type="button"
              onClick={() => setValue(fieldName, null)}
              aria-label="Remove"
            >
              <img src={icons['trash']} alt="Remove file icon" />
            </S.FileInputRemoveButton>
          </S.FileInputSelectedField>
        )}
        <input
          type="file"
          id={fieldName}
          name={fieldName}
          accept="image/png, image/jpeg, .pdf"
          hidden={true}
          ref={register({
            validate: {
              required: (value) => {
                if (!props.required) return true;
                return value?.[0] || 'Please select a file';
              },
              fileSize: (value) => {
                if (!props.required) return true;
                return (
                  (value[0]?.size && value[0]?.size <= 50 * 1024 * 1024) ||
                  'File size must be less than 50MB'
                );
              },
            },
          })}
          data-test="fileInput"
        />
        {errors && (
          <S.FileInputError>{errors[fieldName]?.message}</S.FileInputError>
        )}
      </S.FileInputContainer>
    </Grid.Item>
  );
}

export default DocumentField;
