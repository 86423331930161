/* istanbul ignore file */
import { useKitRegistration } from 'common/hooks/useRegistrationUser';
import { useTouchOnHealthProfile } from 'common/hooks/useTouchOnHealthProfile';
import { useSearchParam } from 'common/polyfill/router';
import AnswersProvider from 'common/providers/AnswersProvider';
import { HealthProfileProvider } from 'common/providers/HealthProfileProvider';
import NavigationQuestionProvider from 'common/providers/NavigationQuestionProvider';
import { RegistrationConsumer, User } from 'common/utils/types';
import HealthProfile from 'components/HealthProfile';
import HealthProfileProgressBar from 'components/HealthProfile/components/ProgressBar';
import { useHealthProfileData } from 'components/HealthProfile/hooks';
import Layout from 'components/Layout';
import { isUndefined } from 'lodash';
import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { StringParam } from 'serialize-query-params';

import * as S from './styles';
type LocationState = {
  user: User;
  lastInteraction: string;
};
export const HealthProfileContainer = () => {
  const location = useLocation();
  const state = location?.state as LocationState;

  const [_consumerId, setConsumerId] = useState('');
  const [consumerQueryParam] = useSearchParam('cp', StringParam);
  const [kitRegistration] = useKitRegistration();
  const [testQueryParameter] = useSearchParam('test', StringParam);
  const [reEntryParameter] = useSearchParam('unanswered', StringParam);
  const { fetched, healthProfileData } = useHealthProfileData(reEntryParameter);

  useTouchOnHealthProfile({
    lastInteraction: state?.lastInteraction || null,
    reEntryParameter,
    kitRegistration,
  });

  function isHealthProfileRoute() {
    const consumerId =
      (state?.user?.consumer_attributes as RegistrationConsumer)?.id ||
      _consumerId;
    return (
      !isUndefined(kitRegistration) ||
      testQueryParameter ||
      reEntryParameter ||
      !isUndefined(consumerId)
    );
  }
  const healthProfileRoute = isHealthProfileRoute();

  useEffectOnce(() => {
    if (consumerQueryParam) {
      setConsumerId(consumerQueryParam);
    }
  });

  return (
    <Layout>
      {healthProfileRoute ? (
        fetched ? (
          <HealthProfileProvider>
            <AnswersProvider>
              <NavigationQuestionProvider>
                <HealthProfile
                  // @ts-ignore
                  healthProfileData={healthProfileData}
                  kitRegistration={kitRegistration}
                />
              </NavigationQuestionProvider>
            </AnswersProvider>
          </HealthProfileProvider>
        ) : (
          <>
            <HealthProfileProgressBar activeStep={1} currentStep={1} />
            <S.BlankSpacer />
          </>
        )
      ) : (
        <Navigate to="/register" />
      )}
    </Layout>
  );
};
export default HealthProfileContainer;
