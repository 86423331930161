// State

import {
  SelectedProvider,
  AppointmentSlot,
  AppointmentsSlotsMeta,
  NewSelfScheduledAppointment,
  AppointmentReason,
} from 'common/apis/telehealthSchedulingApis';

export type State = {
  reasonForVisit: AppointmentReason | null;
  selectedProvider: SelectedProvider;
  selectedAppointmentTypeId: string | null;
  selectedProgram: string;
  confirmedAppointment: NewSelfScheduledAppointment | null;
  selectedAppointment: {
    slot: AppointmentSlot | null;
    type: AppointmentsSlotsMeta['appointment_type'] | null;
  };
};

export const defaultState: State = {
  reasonForVisit: {
    reason: null,
    kit_results: [],
  },
  selectedProvider: null,
  selectedAppointmentTypeId: null,
  selectedProgram: 'virtual-care-visit',
  confirmedAppointment: null,
  selectedAppointment: {
    slot: null,
    type: null,
  },
};

// Actions
type SetProviderAction = {
  type: 'SET_SELECTED_PROVIDER';
  payload: number | number[];
};
type SetSelectedAppointmentTypeIdAction = {
  type: 'SET_SELECTED_APPOINTMENT_TYPE';
  payload: string | null;
};
type ConfirmedAppointmentAction = {
  type: 'SET_CONFIRMED_APPOINTMENT';
  payload: NewSelfScheduledAppointment;
};
type SetAppointmentAction = {
  type: 'SET_SELECTED_APPOINTMENT';
  payload: {
    slot: AppointmentSlot;
    type: AppointmentsSlotsMeta['appointment_type'];
  };
};
type SetAppointmentReasonAction = {
  type: 'SET_REASON_FOR_VISIT_APPOINTMENT';
  payload: AppointmentReason;
};

export type ActionT =
  | SetAppointmentReasonAction
  | SetProviderAction
  | SetSelectedAppointmentTypeIdAction
  | ConfirmedAppointmentAction
  | SetAppointmentAction;

const setSelectedProvider = (providerId: number | number[]): ActionT => ({
  type: 'SET_SELECTED_PROVIDER',
  payload: providerId,
});

const setSelectedAppointmentType = (
  appointmentTypeId: string | null,
): ActionT => ({
  type: 'SET_SELECTED_APPOINTMENT_TYPE',
  payload: appointmentTypeId,
});

const setSelectedAppointment = (
  slot: AppointmentSlot,
  type: AppointmentsSlotsMeta['appointment_type'],
): ActionT => ({
  type: 'SET_SELECTED_APPOINTMENT',
  payload: { slot, type },
});

const setConfirmedAppointment = (
  appointment: NewSelfScheduledAppointment,
): ActionT => ({
  type: 'SET_CONFIRMED_APPOINTMENT',
  payload: appointment,
});

const setAppointmentReason = (reasonForVisit: AppointmentReason): ActionT => ({
  type: 'SET_REASON_FOR_VISIT_APPOINTMENT',
  payload: reasonForVisit,
});

export const actions = {
  setSelectedProvider,
  setSelectedAppointmentType,
  setSelectedAppointment,
  setConfirmedAppointment,
  setAppointmentReason,
};

// Reducer
export const reducer = (
  state: State = defaultState,
  action: ActionT,
): State => {
  switch (action.type) {
    case 'SET_REASON_FOR_VISIT_APPOINTMENT':
      return {
        ...state,
        reasonForVisit: action.payload,
      };
    case 'SET_SELECTED_PROVIDER':
      return {
        ...state,
        selectedProvider: action.payload,
        selectedAppointment: {
          slot: null,
          type: null,
        },
        confirmedAppointment: null,
      };
    case 'SET_SELECTED_APPOINTMENT_TYPE':
      return {
        ...state,
        selectedAppointmentTypeId: action.payload,
        selectedAppointment: {
          slot: null,
          type: null,
        },
        confirmedAppointment: null,
        reasonForVisit: null,
      };
    case 'SET_CONFIRMED_APPOINTMENT':
      return {
        selectedProgram: state.selectedProgram,
        selectedAppointmentTypeId: state.selectedAppointmentTypeId,
        selectedProvider: null,
        selectedAppointment: state.selectedAppointment,
        confirmedAppointment: action.payload,
        reasonForVisit: state.reasonForVisit,
      };
    case 'SET_SELECTED_APPOINTMENT':
      return {
        ...state,
        selectedAppointment: action.payload,
      };
    default:
      return state;
  }
};
