import React from 'react';

import { SPANISH_VIDEO_URL } from './content';
import * as S from './styles';

const NasalSwabSpanishVideoLink = () => (
  <S.VideoLinkContainer>
    <S.VideoLabel>¿Prefiere Español?</S.VideoLabel>
    <S.VideoLink href={SPANISH_VIDEO_URL}>
      Vea nuestro video instructivo aquí
    </S.VideoLink>
  </S.VideoLinkContainer>
);

export default NasalSwabSpanishVideoLink;
