import fetchMock from 'jest-fetch-mock';
import { rest, graphql } from 'msw';
import { setupServer } from 'msw/node';

import { handlers } from './handlers';

const server = setupServer(...handlers);
export { server, rest, graphql };

/**
 * Starts the node server to handle all requests
 */
beforeAll(() => server.listen());

/**
 * Restore handlers after each test
 */
afterEach(() => server.resetHandlers());

/**
 * Stops the node server
 */
afterAll(() => server.close());

/**
 * MSW server is not able to intercept the fetch calls, since fetch is being mocked globally (setupTests.ts#L9), .
 * So, this disable the mocks temporarily.
 */

beforeAll(() => {
  fetchMock.dontMock();
});

afterAll(() => {
  fetchMock.doMock();
});
