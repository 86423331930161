import { breakpoints, Button, size } from '@everlywell/leaves';
import useMediaQuery from 'common/hooks/useMediaQuery';
import analytics from 'common/utils/analytics';
import { LEGACY_APP_ROOT, PRODUCTS_INDEX_ROUTE } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import BaseCard, { BaseCardIcon } from 'pages/AccountHub/components/BaseCard';
import React from 'react';
import { Link } from 'react-router-dom';

import MicroscopeIcon from '../../Icons/MicroscopeIcon';

export type ShopProductsCardProps = {};

/**
 * Card component with an icon, heading, copy and cta blocks
 */

function ShopProductsCard(props: ShopProductsCardProps) {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.forPhoneOnly}px)`);

  const handleClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.ACCOUNT_HUB.SHOP_PRODUCTS,
        category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
      },
    });
  };

  const handleRegisterClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.ACCOUNT_HUB.REGISTER_KIT,
        category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
      },
    });
  };

  return (
    <BaseCard
      image={<BaseCardIcon as={MicroscopeIcon} />}
      content={{
        title: 'Take the next step on your health and wellness journey',
        description:
          'Register your Everlywell kit, or search for a test to get started.',
      }}
      cta={
        <>
          {!isMobile && (
            <Button
              component={Link}
              appearance="primary"
              to="/register"
              onClick={handleRegisterClick}
            >
              Register kit
            </Button>
          )}
          <Button
            appearance={isMobile ? 'primary' : 'secondary'}
            href={`${LEGACY_APP_ROOT}${PRODUCTS_INDEX_ROUTE}`}
            onClick={handleClick}
            css={{ marginTop: `${size.md}px` }}
          >
            Shop products
          </Button>
        </>
      }
    />
  );
}

export default ShopProductsCard;
