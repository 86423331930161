import { mediaQueries } from '@everlywell/leaves';
import tabletHorizontalTopCurve from 'common/images/background-top-1024.svg';
import desktopTopCurve from 'common/images/background-top-1440.svg';
import jumboTopCurve from 'common/images/background-top-2560.svg';
import mobileTopCurve from 'common/images/background-top-375.svg';
import tabletVerticalTopCurve from 'common/images/background-top-768.svg';
import styled from 'styled-components';

const Wrapper = styled.div<{ overrideLayout?: boolean }>`
  /* prettier-ignore */
  background-image: url("${mobileTopCurve}");
  background-position: center bottom;
  background-size: contain;
  padding: 16px 7% 48px;
  min-height: 152px;

  ${mediaQueries.forTabletVerticalUp} {
    /* prettier-ignore */
    background-image: url("${tabletVerticalTopCurve}");
  }

  ${mediaQueries.forTabletHorizontalUp} {
    /* prettier-ignore */
    background-image: url("${tabletHorizontalTopCurve}");
  }

  ${mediaQueries.forDesktopUp} {
    /* prettier-ignore */
    background-image: url("${desktopTopCurve}");
    padding: ${(props) =>
      props.overrideLayout ? '16px 7% 80px 2%' : '16px 7% 48px'};
  }

  ${mediaQueries.forBigDesktopUp} {
    /* prettier-ignore */
    background-image: url("${jumboTopCurve}");
  }
`;

const Content = styled.div<{ overrideLayout?: boolean }>`
  margin: ${(props) => (props.overrideLayout ? '0' : '0 auto')};
  max-width: 1157px;
`;

export { Wrapper, Content };
