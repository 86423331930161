import analytics, { TrackProps } from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { TAGS } from 'components/telehealth/FormBuilder/constants';
import { removeTag } from 'components/telehealth/FormBuilder/utils/tags';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { FieldProps } from '../../Field';

/**
 * useFieldTracking is a custom hook that tracks the field value changes
 */
function useFieldTracking(
  field: FieldProps,
  programSlug: string | null,
): string | null {
  const { label, id } = field;
  const hasTrackTag = label?.includes(TAGS.TRACK);
  const value = useWatch({ name: field.id }) as string;
  const [fieldsVal, setFieldsVal] = useState<Record<string, string>>({});

  /**
   * Validates the previous is not equal to the current value
   */
  useEffect(() => {
    if (hasTrackTag && fieldsVal[id] !== value && value !== '') {
      setFieldsVal((fields) => ({ ...fields, [id]: value }));
      const trackOptions = {
        event: ANALYTICS.EVENTS.CHANGE_VALUE,
        data: {
          label: removeTag(value),
          program: programSlug,
        },
      };
      onTrackFn(trackOptions);
    }
  }, [value, id, label, programSlug, hasTrackTag, fieldsVal]);

  if (!hasTrackTag) {
    return label;
  }

  return label?.split(TAGS.TRACK)[1] || null;
}

export default useFieldTracking;

function onTrackFn(trackProps: TrackProps) {
  analytics.track({
    ...trackProps,
  });
}
