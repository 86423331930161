import {
  size,
  Button as LeavesButton,
  Container as LeavesContainer,
  mediaQueries,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled(LeavesContainer)`
  display: flex;
  width: 100%;
  padding-top: ${size.xl2}px;
  padding-bottom: ${size.xl2}px;

  ${mediaQueries.forTabletVerticalUp} {
    max-width: 550px;
  }
`;

export const Image = styled.img`
  max-width: 128px;
`;

export const Heading = styled.h1`
  ${typography.h4Text};
  text-align: center;
  margin: 0;
`;

export const Copy = styled.div`
  ${typography.bodyText}
  text-align: center;
`;

export const Button = styled(LeavesButton)`
  width: 100%;
`;

export const ErrorText = styled.div`
  ${typography.errorText}
  text-align: center;
`;
