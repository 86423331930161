import { Button } from '@everlywell/leaves';
import { TelehealthAppointment } from 'common/apis/telehealthApis';
import { CTA_CARE_PLAN } from 'pages/AccountHub/pages/TelehealthDashboard/components/TelehealthAppointments/components/AppointmentCard';
import { isPmStatusOccurred } from 'pages/AccountHub/pages/TelehealthDashboard/components/TelehealthAppointments/utils';
import React from 'react';
import { Link } from 'react-router-dom';

import * as S from './CTA.styles';

type CTAProps = {
  appointment: TelehealthAppointment;
  isManageAppointmentModalEnabled: boolean;
  handleClick: () => void;
  isOnDemandAppointment: boolean;
  parsedTelehealthUrl: URL;
  canJoinAppointment: boolean;
  isAppointmentAsync: boolean;
};
const CTA = ({
  appointment,
  isManageAppointmentModalEnabled,
  handleClick,
  isOnDemandAppointment,
  parsedTelehealthUrl,
  canJoinAppointment,
  isAppointmentAsync,
}: CTAProps) => {
  if (!isPmStatusOccurred(appointment.status)) {
    return null;
  }

  const manageAppointmentOnHealthieProps = {
    component: Link,
    to: `${parsedTelehealthUrl.pathname}${parsedTelehealthUrl.search}`,
  };

  // a user can join an appointment if it's on demand
  // or if it's a scheduled appointment within 15 minutes of the start time

  const canJoinAppointmentButtonProps = { href: appointment.join_url };

  const cannotJoinAppointmentButtonProps = isManageAppointmentModalEnabled
    ? { href: undefined }
    : manageAppointmentOnHealthieProps;

  const isCarePlanAvailable = Boolean(appointment?.care_plan?.id);

  if (isAppointmentAsync) {
    return isCarePlanAvailable ? (
      <S.CarePlanButton
        appearance="primary"
        component={Link}
        to={`/virtual-care/care-plans?formId=${appointment?.care_plan?.id}`}
      >
        {CTA_CARE_PLAN}
      </S.CarePlanButton>
    ) : null;
  }

  return (
    <>
      {canJoinAppointment && (
        <Button
          appearance="secondary"
          hasArrow
          onClick={handleClick}
          target="_blank"
          {...canJoinAppointmentButtonProps}
        >
          Join appointment
        </Button>
      )}
      {!canJoinAppointment && (
        <Button
          appearance="secondary"
          hasArrow
          onClick={handleClick}
          target="_blank"
          {...cannotJoinAppointmentButtonProps}
        >
          Manage appointment
        </Button>
      )}
    </>
  );
};

export default CTA;
