import {
  colors,
  size,
  SkeletonLoader,
  SkeletonUnit,
  breakpoints,
} from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import * as S from './Navbar.styles';
import { useNavLinks } from './navLinks';

export type NavbarProps = {};

/**
 * Navigation component for both mobile & desktop view on the My Everly dashboard
 */
export default function Navbar(props: NavbarProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading, navLinks } = useNavLinks();
  const { width } = useWindowSize();
  const isDesktop = width >= breakpoints.forDesktopUp;
  const [hoveringTab, setHoveringTab] = useState('');
  const onHover = (tabName: string) => {
    setHoveringTab(tabName);
  };

  const onHoverLeave = () => {
    setHoveringTab('');
  };

  // An array of paths for the nav links : ['/dashboard', '/results', ......]
  const navPaths = navLinks.map((link) => link.path);

  const pathname = location.pathname;

  // indexOf returns -1 if the pathname is not in the array
  const initialActiveIndex = navPaths.indexOf(pathname);

  const onTabSelect = (index: number) => {
    navigate(navLinks[index].path);

    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_LINK,
      data: {
        label: navLinks[index].name,
        category: ANALYTICS.CATEGORIES.NAVBAR_NAVIGATION,
      },
    });
  };

  return (
    <S.TabMenu
      id="tab-menu-navbar"
      ariaLabel="tab-menu-navbar"
      ariaControls="tab-menu-navbar"
      colorConfig={{
        containerBackground: colors.green1,
        inactiveBorderColor: colors.green1,
        inactiveBackground: colors.green1,
        inactiveTextColor: colors.green5,
        selectedBackground: colors.green2,
      }}
      initialActiveIndex={initialActiveIndex > -1 ? initialActiveIndex : 0}
      onItemSelect={onTabSelect}
      borderRadius={Number(size.xs2)}
      tabHeight={isDesktop ? 'auto' : Number(size.xl4)}
      isLoading={isLoading}
      isScrollable
      isRound
      addContainerPadding
    >
      {isLoading
        ? // The average number of links is 5, so we can use this to render the skeleton
          [...Array(5)].fill(null).map((_, index) => (
            <SkeletonLoader
              key={index}
              height={{
                value: 40,
                unit: SkeletonUnit.Pixel,
              }}
              width={{
                value: isDesktop ? 100 : 150,
                unit: isDesktop ? SkeletonUnit.Percentage : SkeletonUnit.Pixel,
              }}
            />
          ))
        : navLinks.map((navLink) => (
            <S.TabWrapper
              onMouseOver={() => onHover(navLink.name)}
              onMouseLeave={onHoverLeave}
            >
              <S.Tab
                key={navLink.name}
                className={pathname === navLink.path ? 'active' : ''}
              >
                <S.IconContainer>
                  {pathname === navLink.path || hoveringTab === navLink.name ? (
                    <navLink.activeIcon />
                  ) : (
                    <navLink.icon />
                  )}
                </S.IconContainer>
                <S.TabName data-testid={navLink.dataTestId}>
                  {`${navLink.name}`}
                </S.TabName>
              </S.Tab>
            </S.TabWrapper>
          ))}
    </S.TabMenu>
  );
}
