import { mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex: 1 0 100%;

  ${mediaQueries.forTabletHorizontalUp} {
    justify-content: space-between;
  }
`;

export { Wrapper };
