import {
  API_ROOT,
  USERS_BASE_URL,
  LEGACY_APP_ROOT,
} from 'common/utils/constants';
import { ETHNICITY, RACE } from 'common/utils/constants/raceAndEthnicity';
import { HEALTH_PROFILE_API } from 'common/utils/constants/urls';
import { standarizeError } from 'common/utils/errors';
import {
  buildCOVIDPayload,
  getTimezoneFromBrowser,
  logError,
} from 'common/utils/helpers';
import {
  KitRegistrationResponse,
  ShippingAddress,
  PhysicianContact,
  KitRegistrationUser,
  PatchRegistrationPayload,
  AccountSettings,
  HealthProfileQuestionnaire,
  HealthProfileResponse,
  Personalizations,
  HealthProfileProgressStatusResponse,
  CovidRegistrationPayload,
  TouchHealthProfileProgressStatus,
  HealthProfileResponseOptions,
  KitRegistrationScreener,
  KitRegistrationData,
  AddressValidationError,
} from 'common/utils/types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useCallback, useState } from 'react';

import accountSettingsFieldNames from './accountSettingsFieldNames';
import physicianContactFieldNames from './physicianContactFieldNames';
import { getRequest, putRequest, postRequest, patchRequest } from './request';
import shippingAddressFieldNames from './shippingAddressFieldNames';

type FieldNames = {
  [key: string]: {
    displayName: string;
    key: string;
  };
};

type RawErrors = {
  [key: string]: string[];
};

type ParsedErrors = {
  [key: string]: string;
};

type PayloadType = {
  first_name: string;
  last_name: string;
  phone_number: string;
  consumer_attributes: {
    dob: string;
    gender: string;
    wants_marketing: string;
  };
  email?: string;
};

function parseErrorsForFields({
  fieldNames,
  errors,
}: {
  fieldNames: FieldNames;
  errors?: RawErrors;
}) {
  if (errors) {
    return Object.keys(errors).reduce((acc: ParsedErrors, fieldName) => {
      const [firstError] = errors[fieldName];
      const { displayName, key } = fieldNames[fieldName];
      acc[key] = `${displayName} ${firstError.toLowerCase()}`;
      return acc;
    }, {});
  }

  return new Error();
}

function useApi() {
  const [wasSuccessful, setWasSuccessful] = useState<boolean>();
  const [statusCode, setStatusCode] = useState<number>();

  async function fetchUserData(userId: string) {
    const response = await getRequest(`${API_ROOT}${USERS_BASE_URL}/${userId}`);
    setWasSuccessful(response.wasSuccessful);
    setStatusCode(response.statusCode);

    return response.data;
  }

  async function updatePhysicianContact(
    userId: string,
    physicianContact: PhysicianContact,
    pcpFirstLastNameEnabled: boolean,
  ) {
    const physicianData = pcpFirstLastNameEnabled
      ? {
          practice_name: physicianContact.practiceName,
          first_name: physicianContact.firstName,
          last_name: physicianContact.lastName,
          address_1: physicianContact.address1,
          address_2: physicianContact.address2,
          city: physicianContact.city,
          state: physicianContact.state,
          zipcode: physicianContact.zipCode,
          phone: physicianContact.physicianPhone,
        }
      : {
          practice_name: physicianContact.practiceName,
          address_1: physicianContact.address1,
          address_2: physicianContact.address2,
          city: physicianContact.city,
          state: physicianContact.state,
          zipcode: physicianContact.zipCode,
          phone: physicianContact.physicianPhone,
        };

    const response = await postRequest(
      `${API_ROOT}${USERS_BASE_URL}/${userId}/assign_physician`,
      {
        physician: physicianData,
      },
    );
    setStatusCode(response.statusCode);
    setWasSuccessful(response.wasSuccessful);

    if (!response.wasSuccessful) {
      throw parseErrorsForFields({
        fieldNames: physicianContactFieldNames,
        errors: response.errors,
      });
    }

    return response.data;
  }

  async function updateShippingAddress(
    userId: string,
    shippingAddress: ShippingAddress,
    barcodeSerialNumber?: string,
  ) {
    const {
      id,
      firstName,
      lastName,
      address1,
      address2,
      city,
      state,
      zipCode,
    } = shippingAddress;

    const response = await putRequest(
      `${API_ROOT}${USERS_BASE_URL}/${userId}`,
      {
        id: userId,
        barcode_serial_number: barcodeSerialNumber,
        user: {
          ship_address_attributes: {
            id,
            firstname: firstName,
            lastname: lastName,
            address1,
            address2,
            city,
            state_id: state,
            zipcode: zipCode,
          },
          ship_address_confirmed: true,
        },
      },
    );

    setStatusCode(response.statusCode);
    setWasSuccessful(response.wasSuccessful);

    if (!response.wasSuccessful) {
      if (response.errors.error) {
        throw response.errors;
      } else {
        throw parseErrorsForFields({
          fieldNames: shippingAddressFieldNames,
          errors: response.errors,
        });
      }
    }

    return response.data;
  }

  async function postKitRegistration(
    kitId: string,
  ): Promise<KitRegistrationResponse> {
    const payload = {
      kit_registration: { serial_number: kitId },
    };
    const response = await postRequest(`${API_ROOT}/v2/register/`, payload);

    setStatusCode(response.statusCode);
    setWasSuccessful(response.wasSuccessful);

    if (!response.wasSuccessful) {
      logError(response.errors[0].message, {
        payload,
        method: 'postKitRegistration',
      });
      throw response.errors[0].message;
    }

    return response.data as KitRegistrationResponse;
  }

  const fetchKitIssueData = useCallback(async (kitIssueId: number) => {
    const response = await getRequest(
      `${API_ROOT}/v2/kit_issues/${kitIssueId}`,
    );
    // @ts-ignore
    setWasSuccessful(response.wasSuccessful);
    // @ts-ignore
    setStatusCode(response.statusCode);

    return response.data;
  }, []);

  /* eslint-disable no-nested-ternary */
  async function patchKitIssues(
    kitIssueId: number,
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    dateOfCollection: string,
  ) {
    // if dateOfCollection has time, then just send dateOfCollection as it is
    // else attach 12:00pm and user's timezone to dateOfCollection and send it
    const format_allowed = ['YYYY-MM-DD', 'MM/DD/YYYY'];
    const payload = {
      first_name: firstName,
      last_name: lastName,
      dob: moment(dateOfBirth, format_allowed).isValid()
        ? moment(dateOfBirth, format_allowed).format('YYYY-MM-DD')
        : dateOfBirth,
      doc: moment(dateOfCollection, format_allowed).isValid()
        ? moment(dateOfCollection).format('h:mm a') !== '12:00 am'
          ? moment(dateOfCollection, format_allowed).format()
          : moment(dateOfCollection, format_allowed).add(12, 'hours').format()
        : dateOfCollection,
    };
    const response = await patchRequest(
      `${API_ROOT}/v2/kit_issues/${kitIssueId}`,
      payload,
    );

    setStatusCode(response.statusCode);
    setWasSuccessful(response.wasSuccessful);

    if (!response.wasSuccessful) {
      logError(response.errors[0].message, {
        payload,
        method: 'patchKitIssues',
      });
      throw response.errors[0];
    }

    return response.data;
  }

  async function patchKitRegistration(
    user: KitRegistrationUser,
    kitRegistration?: KitRegistrationResponse,
    overrideAddress?: boolean,
    screenerData?: KitRegistrationScreener,
  ): Promise<any> {
    if (!kitRegistration) {
      throw new Error('No Kit Registration Provided');
    }

    const payload: PatchRegistrationPayload = {
      consumer: {
        attributes: {
          first_name: user.firstName.value,
          last_name: user.lastName.value,
          dob: moment(user.dateOfBirth.value).isValid()
            ? moment(user.dateOfBirth.value).format('YYYY-MM-DD')
            : user.dateOfBirth.value,
          gender: user.biologicalSex.value,
          race: user.race ? parseInt(RACE[user.race.value], 10) : null,
          ethnicity: user.ethnicity
            ? parseInt(ETHNICITY[user.ethnicity.value], 10)
            : null,
          phone_number: user.phoneNumber.value,
        },
        preference: {
          notification: Number(Boolean(user.textUpdates.value)),
        },
      },
      spree_address: {
        address1: user.streetAddress.value,
        address2: user.subAddress.value,
        city: user.addressCity.value.trim(),
        state: user.addressState.value,
        zipcode: user.addressZipCode.value.trim(),
        country: 'US',
        phone: user.phoneNumber.value,
      },
      kit_registration: {
        serial_number: kitRegistration.barcode.dash_format.replace(/-/g, ''),
      },
    };
    if (kitRegistration.enterprise) {
      payload.kit_registration = {
        ...payload.kit_registration,
      };
      if (kitRegistration.enterprise.third_party) {
        payload.kit_registration.third_party_id = kitRegistration.enterprise
          .third_party.third_party_id
          ? kitRegistration.enterprise.third_party.third_party_id
          : user.thirdPartyId.value;
      }
      if (user.consentTimestamp.value !== '') {
        payload.kit_registration.data_sharing = true;
        payload.kit_registration.consent = {
          timestamp: user.consentTimestamp.value,
          user_name: user.consentName.value,
          organization_name: user.consentOrganizationName.value,
          copy: user.consentCopy.value,
        };
      } else {
        payload.kit_registration.data_sharing = false;
      }
    }

    if (kitRegistration.covid) {
      payload.kit_registration.data = {
        ...buildCOVIDPayload(user),
        sample_date: moment(user.sampleCollectionDate.value).isValid()
          ? moment(user.sampleCollectionDate.value).format('YYYY-MM-DD')
          : user.sampleCollectionDate.value,
        sample_time: user.sampleCollectionTime.value,
        sample_tz: getTimezoneFromBrowser(),
      };
      payload.consumer.attributes.pregnancy_status =
        user.pregnancyStatus && user.biologicalSex.value !== 'male'
          ? user.pregnancyStatus.value === 'yes'
          : false;
    } else if (kitRegistration.needs_sample_collection_date) {
      payload.kit_registration.data = {
        sample_date: moment(user.sampleCollectionDate.value).isValid()
          ? moment(user.sampleCollectionDate.value).format('YYYY-MM-DD')
          : user.sampleCollectionDate.value,
        sample_time: user.sampleCollectionTime.value,
        sample_tz: getTimezoneFromBrowser(),
      };
    }

    // Injects Screener Information
    const hasScreenerData = !isEmpty(screenerData);
    payload.kit_registration.data = {
      ...payload.kit_registration.data,
      ...(hasScreenerData && { screener: screenerData }),
    } as KitRegistrationData & CovidRegistrationPayload;

    if (!kitRegistration.previously_registered_kit) {
      payload.consumer.attributes = {
        ...payload.consumer.attributes,
        wants_marketing: Number(Boolean(user.wantsMarketing.checked)),
      };
    }
    if (overrideAddress || user.overrideAddress.value) {
      payload.kit_registration.confirmed_address = 'true';
    }
    const response = await patchRequest(
      `${API_ROOT}/v2/kit_registrations/${kitRegistration.id}`,
      payload,
    );

    setStatusCode(response.statusCode);
    setWasSuccessful(response.wasSuccessful);

    if (!response.wasSuccessful) {
      if (
        get(response.errors[0], 'message', '').includes(
          'Please replace with an available product',
        )
      ) {
        window.location.assign('/replacement');
      } else {
        const errorMessage = response.errors[0].message;
        const addressSuggestions = response?.data?.address_suggestions;
        const addressInvalid = response?.data?.address_invalid;

        logError(errorMessage, { payload, method: 'patchKitRegistration' });

        throw new AddressValidationError(
          errorMessage,
          addressSuggestions,
          addressInvalid,
        );
      }
    }

    return response.data as KitRegistrationResponse;
  }

  type FetchQuestionnaireProps = (
    questionnarie?: string | null,
    params?: string,
  ) => Promise<HealthProfileQuestionnaire>;

  const fetchQuestionnaire = useCallback<FetchQuestionnaireProps>(
    async (questionnarie, params) => {
      const questionnairePath = questionnarie || 'health-profile';
      const extraParams = params || '';

      const URL = `${LEGACY_APP_ROOT}/${HEALTH_PROFILE_API}/questionnaires/${questionnairePath}${extraParams}`;
      const response = await getRequest(URL, false);

      setStatusCode(response.statusCode);
      setWasSuccessful(response.wasSuccessful);

      if (!response.wasSuccessful) {
        const errMsg = standarizeError(response.errors);
        logError(errMsg, {
          method: 'fetchQuestionnaire',
          params: extraParams,
          questionnarie,
        });
        throw errMsg;
      }

      return response.data as HealthProfileQuestionnaire;
    },
    [],
  );

  async function submitHealthProfileAnswer(
    answers: HealthProfileResponse,
    questionnaireQuestionId: number,
  ): Promise<HealthProfileResponse> {
    let response;
    const formattedOptions = answers.options.length
      ? answers.options.map(
          (option: HealthProfileResponseOptions) => option.option_id,
        )
      : answers.options;
    if (answers.id) {
      const payload = {
        response: {
          ...answers,
          options: formattedOptions,
          id: answers.id,
          questionnaire_question_id: questionnaireQuestionId,
        },
      };

      response = await patchRequest(
        `${LEGACY_APP_ROOT}/${HEALTH_PROFILE_API}/responses/${answers.id}`,
        payload,
      );

      setStatusCode(response.statusCode);
      setWasSuccessful(response.wasSuccessful);

      if (!response.wasSuccessful) {
        const errMsg = standarizeError(response.errors);
        logError(errMsg, {
          payload,
          method: 'patchHealthProfileAnswer',
        });
        throw errMsg;
      }
    } else {
      const payload = {
        response: {
          ...answers,
          options: formattedOptions,
          questionnaire_question_id: questionnaireQuestionId,
        },
      };

      response = await postRequest(
        `${LEGACY_APP_ROOT}/${HEALTH_PROFILE_API}/responses`,
        payload,
      );

      setStatusCode(response.statusCode);
      setWasSuccessful(response.wasSuccessful);

      if (!response.wasSuccessful) {
        const errMsg = standarizeError(response.errors);
        logError(errMsg, {
          payload,
          method: 'postHealthProfileAnswer',
        });
        throw errMsg;
      }
    }
    return response.data as HealthProfileResponse;
  }

  async function updateAccountSettings(
    userId: string,
    accountSettings: AccountSettings,
  ) {
    const {
      firstName,
      lastName,
      email,
      phone,
      dob,
      biologicalSex,
      wantsMarketing,
      canUpdateEmail,
    } = accountSettings;

    const payload: PayloadType = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
      consumer_attributes: {
        dob,
        gender: biologicalSex,
        wants_marketing: wantsMarketing,
      },
    };

    if (canUpdateEmail) {
      payload.email = email;
    }

    const response = await putRequest(
      `${API_ROOT}${USERS_BASE_URL}/${userId}`,
      payload,
    );

    setStatusCode(response.statusCode);
    setWasSuccessful(response.wasSuccessful);

    if (!response.wasSuccessful) {
      throw parseErrorsForFields({
        fieldNames: accountSettingsFieldNames,
        errors: response.errors,
      });
    }

    return response.data;
  }

  async function getPersonalizations(): Promise<Personalizations> {
    const response = await getRequest(
      `${LEGACY_APP_ROOT}/aapi/v2/personalizations`,
      false,
    );

    setStatusCode(response.statusCode);
    setWasSuccessful(response.wasSuccessful);

    if (!response.wasSuccessful) {
      const errMsg = standarizeError(response.errors);
      logError(errMsg, {
        method: 'getPersonalizations',
      });
      throw errMsg;
    }

    return response.data as Personalizations;
  }

  const getHealthProfileProgressStatus = useCallback<
    () => Promise<HealthProfileProgressStatusResponse>
  >(async () => {
    const response = await getRequest(
      `${LEGACY_APP_ROOT}/health-profile/api/v2/questionnaires/health-profile/progress`,
      false,
    );

    setStatusCode(response.statusCode);
    setWasSuccessful(response.wasSuccessful);

    if (!response.wasSuccessful) {
      logError(response.errors?.[0].message, {
        method: 'getHealthProfileProgressStatus',
      });
      throw response.errors?.[0].message;
    }

    return response.data as HealthProfileProgressStatusResponse;
  }, []);

  const postVaccineDoses = useCallback<
    (payload: CovidRegistrationPayload) => Promise<any>
  >(async (payload) => {
    const response = await postRequest(
      `${LEGACY_APP_ROOT}/ent/v1/autoregister`,
      {
        COVID_vaccine_data: payload,
      },
    );

    setStatusCode(response.statusCode);
    setWasSuccessful(response.wasSuccessful);

    if (!response.wasSuccessful) {
      logError(response.errors[0].message, {
        method: 'postVaccineDoses',
      });
      throw response.errors[0].message;
    }

    return response;
  }, []);

  type Touch = () => Promise<TouchHealthProfileProgressStatus>;
  const touchHealthProfileProgressStatus = useCallback<Touch>(async () => {
    let response;
    response = await putRequest(
      `${LEGACY_APP_ROOT}/health-profile/api/v2/questionnaires/health-profile/touch`,
      {},
    );

    setStatusCode(response.statusCode);
    setWasSuccessful(response.wasSuccessful);

    if (!response.wasSuccessful) {
      const errMsg = standarizeError(response.errors);
      logError(errMsg, {
        method: 'putHealthProfileLastInteractionDate',
      });
      throw errMsg;
    }
    return response.data;
  }, []);

  return {
    fetchKitIssueData,
    fetchUserData,
    fetchQuestionnaire,
    getHealthProfileProgressStatus,
    getPersonalizations,
    patchKitIssues,
    patchKitRegistration,
    postKitRegistration,
    postVaccineDoses,
    statusCode,
    submitHealthProfileAnswer,
    updateAccountSettings,
    updatePhysicianContact,
    updateShippingAddress,
    wasSuccessful,
    touchHealthProfileProgressStatus,
  };
}

export default useApi;
