import { mediaQueries, typography } from '@everlywell/leaves';
import styled from 'styled-components';

type WrapperProps = {
  color: string;
};

type SvgWrapperProps = {
  fill: string;
};

const Wrapper = styled.a<WrapperProps>`
  display: flex;
  cursor: pointer;
  align-items: center;
  font-weight: ${typography.weight.bold};
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-decoration: none;
  text-align: center;
  color: ${(props) => props.color};

  .long-text {
    display: none;

    ${mediaQueries.forTabletVerticalUp} {
      display: block;
    }
  }

  .short-text {
    display: block;

    ${mediaQueries.forTabletVerticalUp} {
      display: none;
    }
  }
`;

const Icon = styled.div`
  margin-right: 10px;
`;

const SvgWrapper = styled.span<SvgWrapperProps>`
  svg {
    path {
      fill: ${(props) => props.fill};
    }
  }
`;

export { Wrapper, Icon, SvgWrapper };
