import { AppointmentSlot } from 'common/apis/telehealthSchedulingApis';
import { format, utcToZonedTime } from 'date-fns-tz';

export const formatTime = (datetime: string, timeZone: string) => {
  const date = new Date(datetime);
  const zonedDate = utcToZonedTime(date, timeZone);

  return format(zonedDate, 'p');
};

const START_AFTERNOON_TIME = 12;
const START_EVENING_TIME = 17;

export const getTimePeriod = (datetime: string, timeZone: string) => {
  const date = new Date(datetime);
  const zonedDate = utcToZonedTime(date, timeZone);
  const hours = zonedDate.getHours();

  if (hours >= START_AFTERNOON_TIME && hours < START_EVENING_TIME)
    return 'afternoon';

  if (hours >= START_EVENING_TIME) return 'evening';

  return 'morning';
};

export const parseAvailableSlots = (
  availableSlots: AppointmentSlot[],
  timeZone: string,
) => {
  if (!availableSlots) return [];

  return availableSlots.map((availableSlot) => ({
    value: availableSlot.datetime,
    formattedTime: formatTime(availableSlot.datetime, timeZone),
    period: getTimePeriod(availableSlot.datetime, timeZone),
  }));
};
