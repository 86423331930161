import { CARE_PLAN_DASHBOARD } from 'common/utils/constants/dataTest';
import { sanitize } from 'common/utils/domHelpers';
import React from 'react';

import * as S from './VisitSummary.styles';
type PageHeaderProps = {
  contentHTML: string;
};

function VisitSummary(props: PageHeaderProps) {
  const { contentHTML } = props;
  return (
    <S.VisitSummaryCard>
      <S.VisitSummaryHeader data-test={CARE_PLAN_DASHBOARD.VISIT_SUMMARY}>
        Visit Summary
      </S.VisitSummaryHeader>
      <S.VisitSummaryContent
        dangerouslySetInnerHTML={{ __html: sanitize(contentHTML) }}
      />
    </S.VisitSummaryCard>
  );
}

export default VisitSummary;
