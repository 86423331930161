import { ToastType, formUtils, Input } from '@everlywell/leaves';
import { Token } from '@stripe/stripe-js';
import { useAccountSettings } from 'common/hooks/useAccountSettings';
import { useInteractionTracker } from 'common/hooks/useInteractionTracker';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { zipcodeCleaningValidation } from 'common/utils/helpers';
import { formatPaymentSettingsFormData } from 'common/utils/stripeHelpers';
import { Notification as NotificationType } from 'common/utils/types';
import Grid from 'components/Grid';
import CardDetailsStripe from 'components/StripeComponent/CardDetailsStripe';
import { useStripeFormContext } from 'components/StripeComponent/Providers/StripeFormProvider';
import ToastNotification from 'components/ToastNotification';
import PaymentDescription from 'pages/CreditCardForm/components/Payment/PaymentDescription';
import React, { FC, useState, useCallback, SyntheticEvent } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import DEFAULT_CONTENT from '../../content';
import * as S from './UpdatePaymentModal.styles';

export interface UpdatePaymentModalProps {
  openModal: boolean;
  setOpenModal: (_: boolean) => void;
  onSuccess: Function;
  onError: Function;
}

type FormValues = {
  cardHolderFirstName: string;
  cardHolderLastName: string;
  cardNumber: string;
  expirationDate: string;
  cvcNumber: string;
  zipCode: string;
};

export type PaymentFormData = {
  cardHolderFirstName: string;
  cardHolderLastName: string;
  zipCode: string;
};

export const UpdatePaymentModal: FC<UpdatePaymentModalProps> = ({
  openModal,
  setOpenModal,
  onSuccess,
  onError,
}: UpdatePaymentModalProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [stripeFormStarted, setStripeFormStarted] = useState(false);

  const [notificationData, setNotificationData] = useState<
    NotificationType | undefined
  >();
  const trackFormInteraction = useInteractionTracker();
  const { createBillingDetails } = useAccountSettings();
  const { fetchToken, checkStripeElementsOnError } = useStripeFormContext();

  const useFormMethods = useForm<FormValues>({
    mode: 'onBlur',
  });
  const { register, errors, handleSubmit, reset } = useFormMethods;

  const handleFocus = async () => {
    await trackFormInteraction({
      event: ANALYTICS.EVENTS.FORM_INTERACTION,
      data: {
        component: ANALYTICS.LABELS.PAYMENT_INFORMATION,
      },
    });
  };

  const handleClearNotification = () => {
    setNotificationData(undefined);
  };

  const handleStripeFocus = useCallback(() => {
    if (!stripeFormStarted) {
      setStripeFormStarted(true);
      trackFormInteraction({
        event: ANALYTICS.EVENTS.FORM_INTERACTION,
        data: {
          component: ANALYTICS.LABELS.PAYMENT_INFORMATION,
        },
      });
    }
  }, [trackFormInteraction, stripeFormStarted, setStripeFormStarted]);

  const onSubmit = async (formData: PaymentFormData) => {
    setIsSaving(true);
    try {
      await analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: ANALYTICS.LABELS.ACCOUNT_SETTINGS.PAYMENT.SAVE,
          category: ANALYTICS.CATEGORIES.ACCOUNT_SETTINGS,
          component: ANALYTICS.LABELS.PAYMENT_INFORMATION,
        },
      });
      const token: Token = await fetchToken(
        formData,
        formatPaymentSettingsFormData,
      );
      const response = await createBillingDetails(token.id);
      onSuccess({ message: 'Sucessfully added new card' }, response);
      // Close the modal
      setOpenModal(false);
      reset(formData);
      setIsSaving(false);
    } catch (err: any) {
      const details =
        'Please try again, or contact customer service if the problem persists.';

      onError({
        message: `Failed to add new card`,
        details: details,
        error: err,
      });

      setNotificationData({
        message: `Failed to add new card`,
        details: err.message,
        source: ANALYTICS.LABELS.PAYMENT_INFORMATION,
        type: ToastType.ERROR,
        persist: true,
      });

      setIsSaving(false);
    }
  };

  const onCancel = async (formData: any) => {
    await analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.ACCOUNT_SETTINGS.PAYMENT.CANCEL,
        category: ANALYTICS.CATEGORIES.ACCOUNT_SETTINGS,
        component: ANALYTICS.LABELS.PAYMENT_INFORMATION,
      },
    });
    handleModalOpen(false);
    reset(formData);
  };

  const handleModalOpen = (isOpen: boolean) => {
    setOpenModal(isOpen);

    if (!isOpen) {
      setNotificationData(undefined);
    }
  };

  return (
    <S.Modal
      data-test="update-payment-modal"
      open={openModal}
      openHandler={handleModalOpen}
      zIndex={15}
    >
      {notificationData && (
        <S.ToastWrapper>
          <ToastNotification
            notification={notificationData}
            clearNotification={handleClearNotification}
          />
        </S.ToastWrapper>
      )}
      <PaymentDescription title={DEFAULT_CONTENT.modalTitle} acceptedCards />
      <FormProvider {...useFormMethods}>
        <S.PaymentForm
          data-test="update-payment-modal-form"
          onSubmit={handleSubmit(onSubmit, checkStripeElementsOnError)}
        >
          <S.Wrapper data-test="card-details-stripe">
            <Grid.Container spacing={['md']}>
              <Grid.Item width={[1, 1 / 2]}>
                <Input
                  data-test="cardHolderFirstName-input"
                  id="cardHolderFirstName"
                  name="cardHolderFirstName"
                  label="Cardholder First Name"
                  placeholder="First Name"
                  ref={register({
                    required: 'Please enter your first name.',
                  })}
                  error={
                    errors.cardHolderFirstName &&
                    errors.cardHolderFirstName.message
                  }
                  onChange={handleFocus}
                  disabled={isSaving}
                />
              </Grid.Item>
              <Grid.Item width={[1, 1 / 2]}>
                <Input
                  data-test="cardHolderLastName-input"
                  id="cardHolderLastName"
                  name="cardHolderLastName"
                  label="Cardholder Last Name"
                  placeholder="Last Name"
                  ref={register({
                    required: 'Please enter your last name.',
                  })}
                  error={
                    errors.cardHolderLastName &&
                    errors.cardHolderLastName.message
                  }
                  onChange={handleFocus}
                  disabled={isSaving}
                />
              </Grid.Item>

              <CardDetailsStripe
                handleFocus={handleStripeFocus}
                isDisabled={isSaving}
              />

              <Grid.Item width={[1]}>
                <Input
                  data-test="zipCode-input"
                  id="zipCode"
                  name="zipCode"
                  label="ZIP Code"
                  placeholder="12345"
                  ref={register({
                    required: 'Please enter a ZIP code',
                    validate: (value) =>
                      formUtils.validateZipCode(value) ||
                      'Please enter a valid ZIP code.',
                  })}
                  error={errors.zipCode && errors.zipCode.message}
                  onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                    const target = e.target as HTMLInputElement;
                    const zipcode = zipcodeCleaningValidation(target.value);
                    target.value = zipcode;
                  }}
                  onFocus={handleFocus}
                  disabled={isSaving}
                />
              </Grid.Item>
            </Grid.Container>
          </S.Wrapper>
          <S.ButtonRow>
            <S.CancelButton
              data-test="update-payment-cancel-button"
              appearance="secondary"
              isDisabled={isSaving}
              onClick={(formData) => onCancel(formData)}
            >
              {DEFAULT_CONTENT.cancelButtonText}
            </S.CancelButton>
            <S.SaveButton
              data-test="update-payment-save-button"
              appearance="primary"
              isDisabled={isSaving}
            >
              {DEFAULT_CONTENT.saveButtonText}
            </S.SaveButton>
          </S.ButtonRow>
        </S.PaymentForm>
      </FormProvider>
    </S.Modal>
  );
};

export default UpdatePaymentModal;
