import { Col } from '@everlywell/leaves';
import Player from '@vimeo/player';
import analytics from 'common/utils/analytics';
import { COLLECTION_VIDEO_URLS } from 'common/utils/constants';
import React, { createRef, useEffect, useState } from 'react';

import { TestCollectionMethod } from '../model';
import NasalSwabSpanishVideoLink from '../NasalSwabCollection/VideoLink';
import * as S from './styles';

type Props = {
  methods?: TestCollectionMethod[];
  isFoodSensitivity?: boolean | undefined;
  hasNasalSwabMethod?: boolean | undefined;
};

const track = (event: string, method: string) =>
  analytics.track({ event, data: { method } });

const CollectionMethods = ({
  methods,
  isFoodSensitivity,
  hasNasalSwabMethod,
}: Props) => {
  const vimeoRef = createRef<HTMLIFrameElement>();
  const [player, setPlayer] = useState<any>();

  useEffect(() => {
    const method = (methods && methods[0]) || TestCollectionMethod.default;
    if (method !== TestCollectionMethod.default && vimeoRef.current) {
      const player = new Player(vimeoRef.current);
      setPlayer(player);
      player.on('loaded', () => track('video loaded', method));
      player.on('play', () => track('video started', method));
      player.on('ended', () => track('video ended', method));
    }
    return () => {
      if (player) {
        player.off('loaded');
        player.off('play');
        player.off('ended');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods]);

  return (
    <S.Wrapper center="xs">
      <Col xs={11} md lg={9}>
        <S.Title>
          <span data-test="collection-methods">
            {
              'When you’re ready to collect your sample, watch this instructional video.'
            }
          </span>
        </S.Title>
        {methods &&
          methods[0] &&
          methods.map((collection: TestCollectionMethod, idx: number) => (
            <>
              <S.Embed key={collection} data-test={`${collection}-${idx}`}>
                <iframe
                  ref={vimeoRef}
                  title="Collection Method"
                  src={
                    COLLECTION_VIDEO_URLS[
                      collection || TestCollectionMethod.default
                    ]
                  }
                  frameBorder="0"
                />
                <S.Decor />
              </S.Embed>
              {hasNasalSwabMethod ? <NasalSwabSpanishVideoLink /> : null}
            </>
          ))}

        {isFoodSensitivity && (
          <S.Instructions
            // eslint-disable-next-line global-require
            href={require('./data/fs-Instructions.pdf')}
            // @ts-ignore
            target="_blank"
            data-testid="collection-instructions-doc"
          >
            View digital instructions
          </S.Instructions>
        )}
      </Col>
    </S.Wrapper>
  );
};

export default CollectionMethods;
