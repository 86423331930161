import { postRequest } from 'common/hooks/useApi/request';
import { API_ROOT, STORE_BASIC_AUTH_TOKEN } from 'common/utils/constants';

type IterableNotificationEvents = {
  event_type: string;
  resource: {
    email: string;
    data_fields: Record<string, string>;
  };
};

// Create Iterable notification events
export const sendNotificationEvent = async (
  payload: IterableNotificationEvents,
) => {
  const storeAuth = `Basic ${STORE_BASIC_AUTH_TOKEN}`;

  return postRequest<IterableNotificationEvents>(
    `${API_ROOT}/v3/notification_events`,
    payload,
    false,
    {
      rejectOnError: true,
      headers: {
        Authorization: storeAuth,
        'Content-Type': 'application/json',
        'EW-Event-Source': 'results',
      },
    },
  );
};
