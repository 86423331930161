import { getRequest } from 'common/hooks/useApi/request';
import { API_ROOT } from 'common/utils/constants';
import { Program } from 'common/utils/types';

const PROGRAMS_URI = 'v3/telehealth/programs';

export const getProgramInfoBySlug = async (slug: string) =>
  getRequest<Program>(`${API_ROOT}/${PROGRAMS_URI}/${slug}`, false, {
    rejectOnError: true,
  });
