import { colors, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-weight: ${typography.weight.bold};
  color: ${colors.red2};
  font-size: 14.2px;
  line-height: 2.11;
  letter-spacing: 0.1px;
`;

export { Wrapper };
