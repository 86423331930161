import { getTelehealthIntakeForms } from 'common/apis/telehealthApis';
import useProgramSlug from 'common/hooks/useProgramSlug';
import { userPlanId } from 'common/utils/userPlanId';
import PrivateRoute from 'components/PrivateRoute';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { toNumber, isInteger } from 'lodash-es';
import ErrorPage from 'pages/ErrorPage';
import InsuranceDetailsPage from 'pages/InsuranceDetailsPage';
import InsurancePage from 'pages/InsurancePage';
import OnDemandConfirmation from 'pages/VirtualCare/OnDemandConfirmation';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Loader from './components/Loader';
import {
  NativeIntakeContextProvider,
  NativeIntakeContextProviderProps,
} from './context/NativeIntakeContext';
import AsyncConfirmation from './pages/AsyncConfirmation';
import ReviewPage from './pages/ReviewPage';
import { formsMap } from './utils/config';
import { INTAKE_FLOW_ENTRY_PATH } from './utils/constants';
import { getFormsConfig, matchUrlPattern } from './utils/helpers';

function NativeIntake() {
  const { showDynamicIntakeFlow } = useFlags<{
    showDynamicIntakeFlow: boolean;
  }>();
  const { programSlug } = useProgramSlug();
  const [forms, setForms] = useState<NativeIntakeContextProviderProps['forms']>(
    [],
  );
  const program = programSlug ?? '';

  const location = useLocation();
  const navigate = useNavigate();

  if (!showDynamicIntakeFlow) {
    navigate('/dashboard');
  }

  const { isLoading, isError } = useQuery(
    ['telehealth-intake-forms', { program }],
    () => {
      const isTelehealthVisitRedemption = isInteger(
        toNumber(userPlanId.planId) || null,
      );

      if (isTelehealthVisitRedemption) {
        return getTelehealthIntakeForms(program, ['insurance', 'payment']);
      }

      return getTelehealthIntakeForms(program);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!program,
      onSuccess: ({ data }) => {
        const intakeForms = getFormsConfig(data, formsMap);

        setForms(intakeForms ?? []);
      },
    },
  );

  useEffect(() => {
    if (
      forms?.length > 0 &&
      matchUrlPattern(location.pathname, INTAKE_FLOW_ENTRY_PATH)
    ) {
      // redirect user to first form page
      navigate(forms[0].pathname);
    }
  }, [forms, location.pathname, navigate]);

  if (isLoading) return <Loader />;
  if (isError ?? forms.length === 0) return <ErrorPage />;

  return (
    <NativeIntakeContextProvider forms={forms} program={program}>
      <Routes>
        {/*
          Dynamic Form Routes have been configured
          in config.ts and are managed in Spree
        */}
        {forms?.map(({ slug, pathname, component: Component }) => (
          <Route
            key={slug}
            path={pathname}
            element={<PrivateRoute component={Component} />}
          />
        ))}
        {/* Static routes */}
        <Route
          path="/insurance/add"
          element={<PrivateRoute component={InsurancePage} />}
        />
        <Route
          path="/insurance"
          element={<PrivateRoute component={InsuranceDetailsPage} />}
        />
        <Route
          path="/on-demand/confirmation"
          element={<OnDemandConfirmation />}
        />
        <Route element={<AsyncConfirmation />} path="/async/confirmation" />
        <Route
          path="/review"
          element={<PrivateRoute component={ReviewPage} />}
        />
      </Routes>
    </NativeIntakeContextProvider>
  );
}

export default NativeIntake;
