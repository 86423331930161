import { useHealthProfileContext } from 'common/providers/HealthProfileProvider';
import { NO_VIDEO_FOR_SLUG } from 'common/utils/constants/features';
import { clearSessionStorage } from 'common/utils/helpers';
import { REGISTRATION_KEYS } from 'common/utils/registrationHelpers';
import { KitRegistrationResponse } from 'common/utils/types';
import React, { useEffect } from 'react';

import DefaultConfirmation from './DefaultConfirmation';
import KitRegistrationConfirmation from './KitRegistrationConfirmation';
import LabVisitProfileConfirmation from './LabVisitProfileConfirmation';

export interface LinkTo {
  href: string;
}

export interface Props {
  kitRegistration?: KitRegistrationResponse;
  redirectUrl: string;
}

const getCollectionType = (kitRegistration?: KitRegistrationResponse) => {
  if (kitRegistration) {
    return NO_VIDEO_FOR_SLUG.includes(kitRegistration.product.slug)
      ? []
      : kitRegistration.collection_types;
  }
  return [];
};

const QuestionnaireCompleteConfirmation = ({ kitRegistration }: Props) => {
  const { redirection } = useHealthProfileContext();

  useEffect(() => {
    clearSessionStorage(REGISTRATION_KEYS);
  }, []);

  if (redirection) {
    return <DefaultConfirmation />;
  } else if (kitRegistration) {
    return kitRegistration.lab_visit ? (
      <LabVisitProfileConfirmation email={kitRegistration.user.email} />
    ) : (
      <KitRegistrationConfirmation
        collectionTypes={getCollectionType(kitRegistration)}
        hasCollectionMethods={kitRegistration.collection_types.length > 0}
        collection_methods={
          kitRegistration && kitRegistration.collection_methods
        }
        sku={kitRegistration.barcode.variant_sku}
      />
    );
  }

  return <DefaultConfirmation />;
};

export default QuestionnaireCompleteConfirmation;
