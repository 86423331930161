import { size, colors, typography, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  text-align: left;
  padding: 0;
`;

export const SubHeading = styled.h3`
  ${typography.buttonTextSmall};
  font-weight: ${typography.weight.bold};
  color: ${colors.gray4};
  margin: 0 0 ${size.xs1}px;
`;

export const Details = styled.p`
  ${typography.bodyTextSmall};
  margin: 0 0 ${size.md}px;
`;

export const HighlightedBox = styled.div`
  padding: ${size.md}px;
  background-color: ${colors.gray1};
  border-radius: ${size.xs2};

  ${mediaQueries.forTabletVerticalUp} {
    padding: ${size.xl1}px;
  }
`;

export const HR = styled.hr`
  height: ${size.xs3}px;
  margin: ${size.lg}px 0;
  border: none;
  border-radius: ${size.xs2}px;
  background-color: ${colors.gray1};

  ${mediaQueries.forTabletVerticalUp} {
    margin-bottom: ${size.md}px;
  }
`;
