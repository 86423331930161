import {
  Button,
  colors,
  H3,
  mediaQueries,
  Row,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

import graphic from './assets/desktop-graphic.svg';
import mobileGraphic from './assets/mobile-graphic.svg';

const maxWidth = '867px';

const Decor = styled.div`
  display: flex;
  position: absolute;
  left: -250px;
  top: 150px;
  width: 334px;
  height: 167px;

  /* prettier-ignore */
  background: url("${graphic}");
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: 100%;

  ${mediaQueries.forPhoneOnly} {
    /* prettier-ignore */
    background: url("${mobileGraphic}");
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center left;
    background-size: 9vw;
    width: 20px;
    height: 100%;
    left: -3.4vw;
    top: 0;
  }
`;

const Embed = styled.div`
  position: relative;
  width: 100%;
  max-width: ${maxWidth};
  max-height: 487px;
  margin: 0 auto;
  background: ${colors.white};
  margin-bottom: 40px;
  z-index: 1;

  ${mediaQueries.forPhoneOnly} {
    max-width: 100%;
    max-height: none;
    margin-bottom: 20px;
  }

  iframe {
    width: 100%;
    height: 100%;
    min-height: 487px;
    z-index: 10;
    position: relative;

    ${mediaQueries.forPhoneOnly} {
      max-width: 100%;
      min-height: 52vw;
    }
  }
`;

const Instructions = styled(Button)`
  display: flex;
  margin: 0 auto;
  width: 290px;
  text-align: center;
  justify-content: center;

  ${mediaQueries.forPhoneOnly} {
    width: 100%;
  }
`;

const Title = styled(H3)`
  font-weight: ${typography.weight.regular};
  max-width: ${maxWidth};
  margin: 0 auto 30px;
  box-sizing: border-box;

  ${mediaQueries.forPhoneOnly} {
    padding: 0;
  }
`;

const Wrapper = styled(Row)`
  min-height: 620px;
  padding: 60px 0;
  box-sizing: border-box;
  width: 100%;
  margin: 0;

  ${mediaQueries.forPhoneOnly} {
    min-height: 0;
    padding: 40px 0 10px;
  }
`;

export { Decor, Embed, Instructions, Title, Wrapper };
