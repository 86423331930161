import useUser from 'common/hooks/useUser/useUser';
import { isUserWeightCarePlusActiveMember } from 'common/utils/user';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useTelehealthAccount } from '../SideNav';
import Icons from './components/Icons';

export type NavLinkType = {
  name: string;
  path: string;
  icon: React.FC;
  activeIcon: React.FC;
  dataTestId: string;
};

export const useNavLinks = () => {
  const { isWeightManagementEnabled, isResultsPortalTabEnabled } = useFlags();
  const { user } = useUser();
  const isActiveWeightMember = user && isUserWeightCarePlusActiveMember(user);

  const { hasTelehealthAccount, isLoading, parsedUrl } = useTelehealthAccount();

  const navLinks: NavLinkType[] = [
    {
      name: 'My Everly',
      path: '/dashboard',
      icon: Icons.Home,
      activeIcon: Icons.HomeActive,
      dataTestId: 'my-everly-side-link',
    },
    ...(isWeightManagementEnabled && isActiveWeightMember
      ? [
          {
            name: 'Weight Care+',
            path: '/weight-management',
            icon: Icons.WeightCare,
            activeIcon: Icons.WeightCareActive,
            dataTestId: 'weight-care-side-link',
          },
        ]
      : []),
    {
      name: 'Virtual Care',
      path: '/virtual-care',
      icon: Icons.Telehealth,
      activeIcon: Icons.TelehealthActive,
      dataTestId: 'virtual-care-side-link',
    },
    {
      name: 'Test Results',
      path: '/results',
      icon: Icons.TestResults,
      activeIcon: Icons.TestResultsActive,
      dataTestId: 'test-results-side-link',
    },
    ...(isResultsPortalTabEnabled && hasTelehealthAccount
      ? [
          {
            name: 'Records Portal',
            path: parsedUrl,
            icon: Icons.Folder,
            activeIcon: Icons.FolderActive,
            dataTestId: 'health-provider-records-side-link',
          },
        ]
      : []),
    {
      name: 'Settings',
      path: '/account/settings',
      icon: Icons.Settings,
      activeIcon: Icons.SettingsActive,
      dataTestId: 'settings-side-link',
    },
  ].filter(Boolean);

  return { navLinks, isLoading };
};
