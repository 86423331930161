import { typography, colors, size, mediaQueries } from '@everlywell/leaves';
import { MAX_RESULT_SECTIONS_COUNT_TO_SCROLL } from 'common/utils/constants';
import styled from 'styled-components';

type LinkToSectionProps = {
  isSelected?: boolean;
};

type BarContentContainerProps = {
  sectionsCount: number;
};

type NavBarWrapperProps = {
  isMobileWebview: boolean;
};

const NavBarWrapper = styled.div<NavBarWrapperProps>`
  width: 100%;
  height: 55px;
  top: ${(props: NavBarWrapperProps) =>
    props.isMobileWebview ? `0px` : `55px`};
  z-index: 3;
  margin: 0 auto;
  display: block;
  padding: ${size.md}px 0;
  align-items: center;
  background-color: ${colors.white};
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  overflow-x: scroll;
  box-shadow: 0 15px 30px #fff;
  transform: translateZ(0);

  ${mediaQueries.forTabletVerticalUp} {
    width: 100%;
    display: flex;
    padding: 0;
    overflow: hidden;
  }
`;

export const NavBarRelativeWrapper = styled(NavBarWrapper)`
  position: relative;
`;

export const NavBarFixedWrapper = styled(NavBarWrapper)`
  position: sticky;
`;

export const BarContentContainer = styled.div<BarContentContainerProps>`
  max-width: 1090px;
  margin: 0 auto;
  display: flex;
  width: ${({ sectionsCount }) =>
    sectionsCount <= MAX_RESULT_SECTIONS_COUNT_TO_SCROLL ? '100%' : '130%'};

  ${mediaQueries.forTabletVerticalUp} {
    width: 70%;
  }
`;

export const LinkToSection = styled.a<LinkToSectionProps>`
  color: ${colors.green5};
  font-size: 18px;
  line-height: 24px;
  font-weight: ${typography.weight.bold};
  text-decoration: none;
  border-bottom: 3px solid ${colors.green1};
  text-align: center;
  flex-grow: 1;
  ${(props) =>
    props.isSelected &&
    `
      border-bottom: 3px solid green;
      font-weight: bold;
    `};
  transition: border 1.5s;

  &:hover {
    color: ${colors.green5};
  }
`;
