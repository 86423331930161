import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import Layout from 'components/Layout';
import ShippingSettings from 'pages/OldSettingsPage/ShippingSettings';
import PageTitle from 'components/PageTitle';
import PhysicianContactFormContainer from 'containers/PhysicianContactFormContainer';
import SettingsFormContainer from 'containers/SettingsFormContainer';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import * as S from './styles';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY || '');

type Props = {
  wasSuccessful?: boolean;
  statusCode?: number;
  hasMembership?: boolean;
};

/**
 * @deprecated - This component is deprecated and will be removed in the future due
 * to the Account Hub work.
 */
function SettingsPage(props: Props) {
  const { wasSuccessful, statusCode, hasMembership } = props;
  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        page: ANALYTICS.PAGES.ACCOUNT_SETTINGS,
      },
    });
  }, []);

  if (!wasSuccessful && statusCode && statusCode !== 401) {
    return <Navigate to={`/${statusCode}`} />;
  }

  return (
    <Layout doNotHideFooter>
      <S.PageHeader>
        <PageTitle>Account Settings</PageTitle>
      </S.PageHeader>
      <S.Content>
        <Elements stripe={stripePromise}>
          <S.InnerContent>
            <SettingsFormContainer />
            {hasMembership && <ShippingSettings />}
            <PhysicianContactFormContainer />
          </S.InnerContent>
        </Elements>
      </S.Content>
    </Layout>
  );
}

export default SettingsPage;
