import useUser from 'common/hooks/useUser';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNativeIntakeContext } from 'pages/VirtualCare/NativeIntake/context/NativeIntakeContext';
import { NATIVE_SCHEDULING_PATH } from 'pages/VirtualCare/NativeScheduling';
import { NavigateFunction, useNavigate } from 'react-router-dom';

const navigateToSuccessPaymentPage = (
  navigate: NavigateFunction,
  program: string,
) => {
  navigate(NATIVE_SCHEDULING_PATH);
};

const navigateProgramOndemand = (
  navigate: NavigateFunction,
  program: string,
) => {
  navigate(`/virtual-care/on-demand/confirmation?book_appointment=true`);
};

export const useRedirectAfterPaymentPage = (program: string): Function => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { showDynamicIntakeFlow } = useFlags<{
    showDynamicIntakeFlow: boolean;
  }>();
  const { goToNextStep: contextGoToNextStep } = useNativeIntakeContext();

  if (showDynamicIntakeFlow) {
    return () => contextGoToNextStep();
  }

  const isProgramOnDemand = user?.enrolled_programs.find(
    (enrolledProgram) => enrolledProgram.slug === program,
  )?.on_demand;

  if (isProgramOnDemand) {
    return () => navigateProgramOndemand(navigate, program);
  } else {
    return () => navigateToSuccessPaymentPage(navigate, program);
  }
};
