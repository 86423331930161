import {
  mediaQueries,
  size,
  typography,
  TextArea,
  Checkbox as LeavesCheckbox,
  H4,
} from '@everlywell/leaves';
import Grid from 'components/Grid';
import styled from 'styled-components';

import { sharedStyles } from '../../NativeScheduling.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${sharedStyles.containerStyles}
  padding: 0 ${size.lg}px;

  ${mediaQueries.forTabletVerticalUp} {
    padding: 0 ${size.lg}px;
    margin-top: ${size.lg}px;
  }

  margin-top: ${size.sm}px;
`;

export const Heading = styled(H4)`
  margin: 0;
  margin-top: ${size.lg}px;
  font-weight: ${typography.weight.bold};
`;

export const GridItem = styled(Grid.Item)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: '0px 0px ${size.lg}px 0px';
`;

export const StyledTextArea = styled(TextArea)<{ hasError: boolean }>`
  width: 80%;
  margin-left: ${size.xl2}px;
  margin-bottom: 0;
  label {
    padding-bottom: ${size.xs2}px;
  }

  /** FIXME: https://everlyhealth.atlassian.net/browse/IN2-704 */
  #feedback-error {
    display: ${({ hasError }) => (hasError ? 'block' : 'none')};
  }
`;

export const Checkbox = styled(LeavesCheckbox)`
  margin: ${size.xs2}px 0;

  div {
    margin: 0;
  }

  label div {
    margin-left: ${size.xs1}px;
    font-size: ${size.md}px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const ErrorText = styled.div`
  ${typography.errorText}
`;
