import { Col, Dropdown, Row, formUtils, states } from '@everlywell/leaves';
import { useAccountSettings } from 'common/hooks/useAccountSettings';
import { useInteractionTracker } from 'common/hooks/useInteractionTracker';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import {
  shippableStates,
  stateNameToAbbreviation,
} from 'common/utils/constants/states';
import { logError, zipcodeCleaningValidation } from 'common/utils/helpers';
import { ShippingSettings as ShippingSettingsType } from 'common/utils/types';
import { NotificationContext } from 'components/Notification/NotificationContext';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ERROR_NOTIFICATION } from 'store/actions';

import { ButtonSpinner, ButtonText } from '../SettingsForm/styles';
import DEFAULT_CONTENT from './content';
import * as S from './styles';

interface Props {
  content?: any;
  v1shipping?: ShippingSettingsType;
  verticalLayout?: boolean;
  onSubmitCallback?: () => void;
  intakeFlow?: boolean;
}

export const TEST_ID = 'ShippingSettingsTest';

export const ShippingSettings = ({
  content,
  verticalLayout,
  onSubmitCallback,
  intakeFlow = false,
}: Props) => {
  const { setNotificationData } = useContext(NotificationContext);
  const trackFormInteraction = useInteractionTracker();
  const { getShippingAddress, updateShippingAddress } = useAccountSettings();
  const { reset, register, errors, setValue, handleSubmit, formState } =
    useForm();
  const [_isSaving, _setIsSaving] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [NYError, setNYError] = useState(false);
  const [invalidAddress, setInvalidAddress] = useState(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
  const [validData, setValidData] = useState(false);

  const CONTENT = content
    ? { ...DEFAULT_CONTENT, ...content }
    : DEFAULT_CONTENT;

  const trackSubmitButton = async () => {
    await analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.SHIPPING_INFORMATION,
      },
    });
  };

  const updateShippingInformation = async (formData: any) => {
    trackSubmitButton();
    try {
      _setIsSaving(true);

      const newSettings: ShippingSettingsType = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        address1: formData.address1,
        address2: formData.address2,
        state: stateNameToAbbreviation[formData.state],
        city: formData.city,
        zipcode: formData.zip_code,
        phone: formData.phone,
      };
      await updateShippingAddress(newSettings);
      setInvalidAddress(false);
      setInvalidPhoneNumber(false);
      setValidData(true);
      reset(formData);
    } catch (err: any) {
      const invalidStatus = err?.response?.status === 422;
      const invalidAddressMsg =
        err.response.data.message === 'Address is invalid';
      const invalidPhoneNumberMsg =
        err.response.data.message === 'Phone number is invalid';

      setInvalidAddress(invalidAddress && invalidAddressMsg);
      setInvalidPhoneNumber(invalidPhoneNumber && invalidPhoneNumberMsg);
      setValidData(false);

      if (invalidStatus && (invalidAddressMsg || invalidPhoneNumberMsg)) return;

      logError(err.message, {
        component: 'ShippingSettings',
        method: 'updateShippingInformation',
      });
    } finally {
      _setIsSaving(false);
      onSubmitCallback && onSubmitCallback();
    }
  };

  const onStateChange = (e: any) => setNYError(e.target.value === 'New York');

  useEffect(() => {
    if (_isSaving && !validData) {
      setNotificationData({
        message: CONTENT.notifications.updateFailed.title,
        persist: false,
        details: CONTENT.notifications.updateFailed.message,
        type: ERROR_NOTIFICATION,
        source: ANALYTICS.LABELS.SHIPPING_INFORMATION,
      });

      analytics.track({
        event: ANALYTICS.EVENTS.API_FAILURE,
        data: {
          label: 'Update Failed',
          component: ANALYTICS.LABELS.SHIPPING_INFORMATION,
        },
      });
    } else if (_isSaving && validData) {
      setNotificationData({
        message: CONTENT.notifications.updateSuccess.message,
        persist: false,
        source: ANALYTICS.LABELS.SHIPPING_INFORMATION,
      });
      analytics.track({
        event: ANALYTICS.EVENTS.API_SUCCESS,
        data: {
          label: CONTENT.notifications.updateSuccess.message,
          component: ANALYTICS.LABELS.SHIPPING_INFORMATION,
        },
      });
    }
  }, [_isSaving, validData]);

  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: ANALYTICS.LABELS.SHIPPING_INFORMATION,
      },
    });

    const loadInitialState = async () => {
      try {
        const data = await getShippingAddress();
        setValue('firstName', data.first_name);
        setValue('lastName', data.last_name);
        setValue('address1', data.street1);
        setValue('address2', data.street2);
        setValue('city', data.city);
        setValue('state', states.stateAbbreviationToNames[data.state]);
        setValue('zip_code', data.zipcode);
      } catch (err) {
        setNotificationData({
          id: 'onGetShippingAddressSuccess',
          message: CONTENT.notifications.fetchFailed.title,
          persist: false,
          details: CONTENT.notifications.fetchFailed.message,
          type: ERROR_NOTIFICATION,
          source: ANALYTICS.LABELS.SHIPPING_INFORMATION,
        });
        await analytics.track({
          event: ANALYTICS.EVENTS.API_FAILURE,
          data: {
            label: 'Fetch Failed',
            component: ANALYTICS.LABELS.SHIPPING_INFORMATION,
          },
        });
        const error = err as Error;
        logError(error.message, {
          component: 'ShippingSettings',
          method: 'loadInitialState',
        });
      }
    };

    loadInitialState().then(() => {
      setDisabled(false);
    });
  }, [getShippingAddress, setNotificationData, setValue]);

  const handleFocus = async () => {
    await trackFormInteraction({
      event: ANALYTICS.EVENTS.FORM_INTERACTION,
      data: {
        component: ANALYTICS.LABELS.SHIPPING_INFORMATION,
      },
    });
  };
  const submissionDisabled =
    !formState.isDirty || disabled || _isSaving || NYError;

  return (
    <div data-test={TEST_ID}>
      <S.Wrapper intakeFlow={intakeFlow}>
        <S.Title verticalLayout={verticalLayout}>{CONTENT.title}</S.Title>
        <S.Box verticalLayout={verticalLayout}>
          {!verticalLayout ? <S.SubTitle>{CONTENT.subtitle}</S.SubTitle> : null}
          <form onSubmit={handleSubmit(updateShippingInformation)}>
            <Row>
              <S.HiddenInput
                name="firstName"
                id="firstName"
                aria-hidden="true"
                ref={register}
              />
              <S.HiddenInput
                name="lastName"
                id="lastName"
                aria-hidden="true"
                ref={register}
              />
              <Col xs={12} md={verticalLayout ? 12 : 6}>
                <S.Input
                  id="ss-address1"
                  name="address1"
                  label="Address"
                  placeholder="Address"
                  ref={register({ required: 'This field is required.' })}
                  disabled={disabled}
                  error={errors.address1 && errors.address1.message}
                  autoComplete="shipping address-line1"
                  onFocus={handleFocus}
                />
              </Col>
              <Col xs={12} md={verticalLayout ? 12 : 6}>
                <S.Input
                  id="ss-address2"
                  name="address2"
                  label="Address Line 2 (Optional)"
                  placeholder="Apt/Unit/Suite"
                  ref={register}
                  disabled={disabled}
                  error={errors.address2 && errors.address2.message}
                  autoComplete="shipping address-line2"
                  onFocus={handleFocus}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <S.Input
                  id="ss-city"
                  name="city"
                  label="City"
                  placeholder="City"
                  ref={register({ required: 'This field is required.' })}
                  disabled={disabled}
                  error={errors.city && errors.city.message}
                  autoComplete="shipping locality"
                  onFocus={handleFocus}
                />
              </Col>
              <Col xs={6} md={6}>
                <Dropdown
                  // @ts-ignore
                  items={shippableStates} // States data does not currently include New York
                  label="State"
                  name="state"
                  id="ss-state"
                  placeholderText="State"
                  showErrorMessage
                  error={
                    (errors.state && errors.state.message) ||
                    (NYError &&
                      'For regulatory reasons, our tests are not available in NY with the exception of COVID-19.')
                  }
                  ref={register({
                    required: 'Please select a state',
                  })}
                  autoComplete="shipping region"
                  onClick={handleFocus}
                  onChange={onStateChange}
                />
              </Col>
              <Col xs={6} md={6}>
                <S.Input
                  id="ss-zip_code"
                  name="zip_code"
                  label="ZIP Code"
                  placeholder="00000"
                  // @ts-ignore
                  inputmode="numeric"
                  pattern="\d*"
                  type="text"
                  ref={register({
                    required: 'Please enter a ZIP code',
                    validate: (value) =>
                      formUtils.validateZipCode(value) ||
                      'Please enter a valid ZIP code.',
                  })}
                  disabled={disabled}
                  error={errors.zip_code && errors.zip_code.message}
                  autoComplete="shipping postal-code"
                  onFocus={handleFocus}
                  onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                    const target = e.target as HTMLInputElement;
                    const zipcode = zipcodeCleaningValidation(target.value);
                    target.value = zipcode;
                  }}
                />
              </Col>
              {verticalLayout && (
                <Col xs={12} md={6}>
                  <S.Input
                    id="ss-phone"
                    name="phone"
                    label="Phone Number (Optional)"
                    pattern="\d*"
                    type="text"
                    disabled={disabled}
                    ref={register({
                      validate: (value) =>
                        formUtils.validateOptionalPhoneNumber(value) ||
                        'Please enter a valid phone number.',
                    })}
                    error={errors.phone && errors.phone.message}
                    autoComplete="shipping phone-number"
                    onFocus={handleFocus}
                  />
                </Col>
              )}
            </Row>
            {invalidAddress && (
              <S.InvalidAddress>Address is invalid</S.InvalidAddress>
            )}
            <S.ButtonRow>
              <S.Button
                appearance="primary"
                isDisabled={submissionDisabled}
                // @ts-ignore
                className={_isSaving ? 'loading' : ''}
                verticalLayout={verticalLayout}
                hasArrow={verticalLayout}
              >
                <ButtonSpinner saving={_isSaving} />

                <ButtonText saving={_isSaving}>{CONTENT.button}</ButtonText>
              </S.Button>
            </S.ButtonRow>
          </form>
        </S.Box>
      </S.Wrapper>
    </div>
  );
};

export default ShippingSettings;
