/**
 * Button
 *
 * this simply wraps the DLS button because we have logic in here to fire
 * mixpanel events if a button has trackingData data attached.  In order
 * for the trackingData to work with the DLS button alone, we will have to
 * add logic for sending mixPanel events in the DLS or we can update this button to be
 * ButtonWithTracking and use that all over results
 */

import { Button as LeavesBTN } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import React from 'react';

function Button(props: any) {
  const { trackingData, onClick, ...extraProps } = props;

  const handleClick = (event: React.MouseEvent) => {
    if (onClick) {
      onClick(event);
    }

    if (trackingData) {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: trackingData,
      });
    }
  };

  return <LeavesBTN onClick={handleClick} {...extraProps} />;
}

export default Button;
