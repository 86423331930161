import { colors, size } from '@everlywell/leaves';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const itemStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  min-width: 32px;
  padding: ${size.xs2}px ${size.sm}px;
  margin: 0 ${size.xs3}px;
  border: 1px solid ${colors.green2};
  border-radius: ${size.xs2}px;
  color: ${colors.green4};
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.25px;
`;

export const Item = styled(Link)`
  ${itemStyle}

  &:active,
  &[aria-current='true'] {
    border-color: ${colors.green3};
    color: ${colors.green3};
    transform: scale(1.125);
  }

  &:hover,
  &:focus {
    transform: scale(1.03);
    transform-origin: center;
    background-color: #f7fbf8;
    border: 1px solid ${colors.green2};
    color: ${colors.green3};
    outline: none;
  }
`;

export const DisabledItem = styled.span`
  ${itemStyle}
  cursor: not-allowed;
  opacity: 0.5;
`;
