/**
 * NoResults
 */
import registrationCardIcon from 'common/images/registration_card_icon.svg';
import {
  LEGACY_APP_ROOT,
  PRODUCTS_INDEX_ROUTE,
  REGISTRATION_ROUTE,
} from 'common/utils/constants';
import { REGISTER_A_KIT } from 'common/utils/constants/dataTest';
import HeroCard from 'components/HeroCard';
import React from 'react';

import * as S from './NoResults.styles';

export type NoResultsProps = {};

function NoResults(_props: NoResultsProps) {
  return (
    <S.CardWrapper>
      <HeroCard>
        <React.Fragment>
          <S.Icon>
            <img
              style={{ width: '100%' }}
              src={registrationCardIcon}
              alt="Registration Card Icon"
            />
          </S.Icon>

          <S.Heading>Get Started</S.Heading>

          <S.Info>
            <p>
              Take the next step on your health and wellness journey. Once your
              test has been completed you can check back here for your test
              status.
            </p>
            <p>
              To begin, register your Everlywell kit, or{' '}
              <S.LineBreakSmall>
                <S.Link
                  aria-label="Search for a Test"
                  data-test={REGISTER_A_KIT.SEARCH}
                  href={`${LEGACY_APP_ROOT}${PRODUCTS_INDEX_ROUTE}`}
                >
                  search for a test
                </S.Link>
                .
              </S.LineBreakSmall>
            </p>
          </S.Info>

          <S.ActionItems>
            <S.RegisterKitButton
              href={`${REGISTRATION_ROUTE}`}
              appearance="primary"
            >
              Register a Kit
            </S.RegisterKitButton>
          </S.ActionItems>
        </React.Fragment>
      </HeroCard>
    </S.CardWrapper>
  );
}

export default NoResults;
