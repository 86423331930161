import { typography, colors, mediaQueries, size } from '@everlywell/leaves';
import { sectionTitle, bodyCopyLarge } from 'common/styles/app-styles';
import { hexToRGB } from 'common/utils/helpers';
import styled from 'styled-components';

type MarkerTabProps = {
  severityColor: string;
  groupedByCategory: boolean;
};

const MarkerCardContainer = styled.div`
  display: block;
`;

const MarkerTab = styled.div<MarkerTabProps>`
  align-items: center;
  border-bottom: solid 1px rgba(${hexToRGB(colors.gray2)}, 0.5);
  color: ${colors.gray5};
  cursor: pointer;
  display: flex;
  font-weight: ${typography.weight.bold};
  min-height: 48px;
  padding: ${size.sm}px ${size.md}px;
  position: relative;

  &:before {
    content: '';
    ${mediaQueries.forTabletHorizontalUp} {
      display: ${(props: MarkerTabProps) =>
        props.groupedByCategory ? 'block' : 'none'};
    }

    background-color: rgb(${(props: MarkerTabProps) => props.severityColor});
    border-radius: 50%;
    height: 10px;
    margin-right: 10px;
    width: 10px;
  }

  ${mediaQueries.forTabletVerticalDown} {
    &:after {
      content: '';
      border-style: solid;
      border-width: 2px 2px 0 0;
      border-color: ${colors.gray5};
      height: 10px;
      width: 10px;
      position: absolute;
      right: 18px;
      transform: rotate(135deg);
    }

    &.show-mobile {
      &:after {
        content: '';
        transform: rotate(-45deg);
      }
    }
  }

  ${mediaQueries.forTabletHorizontalUp} {
    width: 180px;

    &.show {
      padding-left: 12px;
      border-style: solid;
      border-width: 0 0 1px 6px;
      border-left-color: rgb(
        ${(props: { severityColor: string }) => props.severityColor}
      );
    }
  }

  span {
    opacity: 0.9;
    text-transform: capitalize;
  }
`;

const MarkerContent = styled.div`
  display: none;
  background-color: #f9f9f9;
  border-left: solid 1px rgba(${hexToRGB(colors.gray2)}, 0.5);
  transition: opacity 300ms ease-in;

  /* stylelint-disable */
  ${mediaQueries.forTabletVerticalDown} {
    display: none;
    padding: 34px 16px;
    border-left: none;
    &.show-mobile {
      display: block;
      opacity: 1;
    }
  }

  /* stylelint-enable */
  ${mediaQueries.forTabletHorizontalUp} {
    &.show {
      display: block;
    }

    position: absolute;
    left: 180px;
    top: 0;
    bottom: 0;
    right: 0;
  }
`;

const MarkerContentContainer = styled.div`
  padding: 0;

  ${mediaQueries.forTabletVerticalUp} {
    padding: 34px 40px;
  }
`;

const MarkerContentTitle = styled.h3`
  ${sectionTitle};
  margin: 0;
  text-transform: capitalize;
`;

const MarkerChartWrapper = styled.div`
  position: relative;
  margin: 25px 0;
`;

const MarkerContentDataTitle = styled.h2`
  ${sectionTitle};
  margin: 24px 0 8px;
`;

const MarkerCardBodyCopy = styled.div`
  ${bodyCopyLarge};
  color: ${colors.gray4};
  font-weight: ${typography.weight.light};

  &.left {
    padding-right: 30px;
  }
`;

const MarkerCardDataBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0;

  /* stylelint-disable */
  ${mediaQueries.forDesktopUp} {
    flex-direction: row;
  }
`;
/* stylelint-enable */

const MarkerCardDataBlock = styled.div`
  width: 100%;
`;

export {
  MarkerTab,
  MarkerContent,
  MarkerContentContainer,
  MarkerContentTitle,
  MarkerChartWrapper,
  MarkerCardContainer,
  MarkerContentDataTitle,
  MarkerCardBodyCopy,
  MarkerCardDataBlockContainer,
  MarkerCardDataBlock,
};
