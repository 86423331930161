import { PERSONALIZATION_CHECK_IN as DATA_TEST } from 'common/utils/constants/dataTest';
import {
  renderWithProviders,
  screen,
} from 'common/utils/reactTestingLibraryHelpers';
import React from 'react';

import PersonalizationCheckIn from '../index';

const renderComponent = (props?: any) =>
  renderWithProviders(<PersonalizationCheckIn {...props} />);

describe('PersonalizationCheckIn', () => {
  it('should render the component', () => {
    renderComponent();
    expect(screen.getByTestId(DATA_TEST.CHECKIN_WRAPPER)).toBeInTheDocument();
  });
});
