import { TEST_INFORMATION_FORM as DATA_TEST } from 'common/utils/constants/dataTest';
import { handleChange } from 'common/utils/registrationHelpers';
import {
  KitRegistrationUser,
  ThirdPartyRegistration,
} from 'common/utils/types';
import { BaseInput } from 'components/KitRegistration/styles';
import React from 'react';

interface Props {
  thirdPartyId: ThirdPartyRegistration;
  isDisabled: boolean;
  user: KitRegistrationUser;
}

export const validateThirdPartyInput = (
  value: string,
  format: string | boolean,
  error: string,
) => {
  if (!format) return undefined;
  const pattern = new RegExp(format.toString());
  return pattern.test(value) ? undefined : error;
};

const ThirdPartyId = ({ thirdPartyId, user, isDisabled }: Props) => (
  <BaseInput
    label={thirdPartyId.third_party_id_label}
    name="thirdParty"
    id="thirdParty"
    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
      handleChange(user.thirdPartyId, e.target.value)
    }
    value={isDisabled ? thirdPartyId.third_party_id : user.thirdPartyId.value}
    disabled={isDisabled}
    error={
      user.thirdPartyId.value &&
      validateThirdPartyInput(
        user.thirdPartyId.value,
        thirdPartyId.regex_format,
        `The ID you entered does not match the required format. Please check your entry and try again.`,
      )
    }
    data-test={`${DATA_TEST.INPUT}-third-party-id`}
  />
);

export default ThirdPartyId;
