/**
 * DashboardPage
 */

import { Localize } from '@everlywell/leaves';
import { useCommonT2TAnalytics } from 'common/hooks/useCommonT2TAnalytics';
import { useHealthProfileProgressStatus } from 'common/hooks/useHealthProfileProgressStatus';
import logoIcon from 'common/images/everlywell-icon.svg';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { KIT_LIST } from 'common/utils/constants/dataTest';
import {
  isActiveMember,
  isHumanaCustomer,
  isNonHumanaEnterpriseCustomer,
} from 'common/utils/helpers';
import { notifyPaymentFailure } from 'common/utils/notifications';
import {
  KitResult,
  MembershipStatus,
  User,
  Notification as NotificationType,
  HealthProfileProgressStatus,
} from 'common/utils/types';
import DashBoardPageHeader from 'components/DashBoardPageHeader';
import Layout from 'components/Layout';
import PageTitle from 'components/PageTitle';
import ApolloContainer from 'containers/ApolloContainer';
import differenceInDays from 'date-fns/differenceInDays';
import { isEmpty, isNull, isUndefined } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useEffectOnce } from 'react-use';
import { SessionState } from 'store/session/types';

import Appointments from './Appointments';
import HealthProfileBanner from './HealthProfileBanner';
import KitListResults from './KitListResults';
import * as S from './styles';

export type Props = {
  getKitResults: Function;
  session: SessionState;
  inProgressKitResults: KitResult[];
  completedKitResults: KitResult[];
  user: User;
  loadingResults: boolean;
  getUserData: Function;
  setNotification?: (notification: NotificationType) => void;
};

const MIN_DAYS_WITHOUT_HEALTH_PROFILE_INTERACTION = 14;

/**
 * @deprecated - This component is deprecated and will be removed in the future due
 * to the Account Hub work.
 */
const DashboardPage: React.FC<Props> = (props) => {
  const {
    completedKitResults,
    getKitResults,
    getUserData,
    inProgressKitResults,
    loadingResults,
    session: { userId },
    setNotification,
    user,
  } = props;
  const kitListContainerRef = useRef<null | HTMLDivElement>(null);

  const { healthProfileProgressStatus: questionnaire } =
    useHealthProfileProgressStatus();

  const membership = user?.membership;

  const { has_started, last_interacted_at, progress, unanswered_questions } =
    questionnaire as HealthProfileProgressStatus;
  const { commonT2TAnalyticsData } = useCommonT2TAnalytics();

  useEffectOnce(() => {
    if (!user) {
      getUserData(userId);
    }
    getKitResults(userId);

    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        page: ANALYTICS.PAGES.DASHBOARD,
        ...commonT2TAnalyticsData,
      },
    });
  });

  useEffect(() => {
    if (membership) {
      if (setNotification && membership?.state === MembershipStatus.Failed)
        notifyPaymentFailure(setNotification);
    }
  }, [membership, setNotification]);

  function isHealthProfileBannerAvailable() {
    const today = new Date();
    const lastInteractionDate = last_interacted_at
      ? new Date(last_interacted_at)
      : today;

    return (
      !isUndefined(last_interacted_at) &&
      !isNull(last_interacted_at) &&
      progress < 100 &&
      !isEmpty(questionnaire) &&
      differenceInDays(today, lastInteractionDate) >=
        MIN_DAYS_WITHOUT_HEALTH_PROFILE_INTERACTION
    );
  }

  const thirdParty = isHumanaCustomer();
  const healthPlan = isNonHumanaEnterpriseCustomer();
  const hasMembership = isActiveMember(user?.membership?.state);

  return (
    <ApolloContainer>
      <Layout>
        <S.PageHeaderWrapper>
          <DashBoardPageHeader overrideLayout={!thirdParty}>
            <S.HeaderTitle>
              <S.ImageWrapper>
                <S.IconImage src={logoIcon} alt="" />
              </S.ImageWrapper>
              <PageTitle>My Everly</PageTitle>
            </S.HeaderTitle>
            <S.WelcomeText data-test={KIT_LIST.WELCOME_MESSAGE}>
              {user ? (
                <span>
                  Welcome,{' '}
                  <Localize name="user-firstname">{user.first_name}</Localize>
                </span>
              ) : null}
            </S.WelcomeText>
          </DashBoardPageHeader>
        </S.PageHeaderWrapper>

        <S.DashboardContainer isThirdParty={thirdParty}>
          <Appointments />
          <S.Banner>
            {isHealthProfileBannerAvailable() ? (
              <HealthProfileBanner
                progress={progress}
                seen={has_started}
                unansweredQuestions={unanswered_questions}
                user={user}
                lastInteraction={last_interacted_at}
              />
            ) : null}
          </S.Banner>
          <S.KitsAndMemberships hasMembership={hasMembership}>
            <S.KitListContainer
              ref={kitListContainerRef}
              isThirdParty={thirdParty}
            >
              <S.KitListContent
                data-test="kit-list"
                isThirdParty={thirdParty}
                isHealthPlan={healthPlan}
              >
                <KitListResults
                  loading={loadingResults}
                  inProgressKitResults={inProgressKitResults}
                  completedKitResults={completedKitResults}
                />
              </S.KitListContent>
            </S.KitListContainer>
          </S.KitsAndMemberships>
        </S.DashboardContainer>
      </Layout>
    </ApolloContainer>
  );
};

export default DashboardPage;
