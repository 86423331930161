import { Button, Dropdown } from '@everlywell/leaves';
import {
  CancelAppointmentPayload,
  cancelAppointment,
} from 'common/apis/telehealthApis';
import { useCommonT2TAnalytics } from 'common/hooks/useCommonT2TAnalytics';
import useEnterpriseProgramSlug from 'common/hooks/useEnterpriseProgramSlug';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { LEGACY_APP_ROOT } from 'common/utils/constants/urls';
import Grid from 'components/Grid/Grid';
import Layout from 'components/Layout/Layout';
import { GeneralAccentPhysicianIcon } from 'components/telehealth/Enterprise/Icons';
import NeedHelpCard from 'components/telehealth/Enterprise/NeedHelpCard';
import { CARE_PHONE_NUMBER } from 'components/telehealth/Enterprise/NeedHelpCard/utils/constants';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';

import * as S from './CancelAppointmentPage.styles';

const ReasonOptions = [
  'I have a conflict with this appointment time',
  'There were no same day or next day appointment times for my state',
  'I am having technical issues (wifi connectivity, difficulty joining appointment, or the Everly portal was too hard use)',
  "Couldn't find a provider that matched my preferences",
  'I did not understand what this appointment was for',
  'I prefer to see a provider in-person',
  'My cost estimate was too high',
  'Other (please explain below)',
].map((item, index) => ({
  id: index.toString(),
  text: item,
  value: item,
}));

export const DROPDOWN_REQUIRED_ERROR_MESSAGE =
  'Please select a cancellation reason.';
export const TEXTAREA_REQUIRED_ERROR_MESSAGE = 'Please fill in this field';

export type CancelAppointmentPageProps = {};

/**
 * Page that allows the user to cancel their appointment
 */
function CancelAppointmentPage(props: CancelAppointmentPageProps) {
  const { appointmentId } = useParams();

  const { testToTreatProgramSlug } = useEnterpriseProgramSlug();

  const { commonT2TAnalyticsData } = useCommonT2TAnalytics();

  const navigate = useNavigate();

  const form = useForm({
    mode: 'onBlur',
    defaultValues: {
      reason: '',
      feedback: '',
    },
  });

  const { mutate: submitCancellation, isLoading: isSubmitting } = useMutation(
    cancelAppointment,
    {
      onSuccess: async () => {
        await analytics.track({
          event: ANALYTICS.EVENTS.CLICKED_BUTTON,
          data: {
            label: 'Cancel appointment CTA',
            page: ANALYTICS.PAGES.EHS_T2T.CANCEL_APPOINTMENT_PAGE,
            appointmentId,
            ...commonT2TAnalyticsData,
          },
        });

        navigate(
          `/virtual-care/appointments/${appointmentId}/cancel-confirmation?program=${testToTreatProgramSlug}`,
        );
      },

      onError: () => {
        navigate('/error');
      },
    },
  );

  const submitForm = (data: CancelAppointmentPayload) => {
    submitCancellation({
      appointmentId: appointmentId ?? '',
      reason: data.reason,
      feedback: data.feedback,
    });
  };

  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        page: ANALYTICS.PAGES.EHS_T2T.CANCEL_APPOINTMENT_PAGE,
        appointmentId,
        ...commonT2TAnalyticsData,
      },
    });
  }, [commonT2TAnalyticsData, appointmentId]);

  return (
    <Layout>
      <S.Container as="form" onSubmit={form.handleSubmit(submitForm)}>
        <Grid.Container spacing={['md', 'lg']} alignEdges={false}>
          <Grid.Item
            width={[1]}
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <GeneralAccentPhysicianIcon />
            <S.Heading as="h1">Are you sure you want to cancel?</S.Heading>
          </Grid.Item>
          <Grid.Item width={[1]}>
            <S.Label htmlFor="reason">
              What is the biggest reason for your wish to cancel?
            </S.Label>

            <Dropdown
              ref={form.register({
                required: DROPDOWN_REQUIRED_ERROR_MESSAGE,
              })}
              id="reason"
              name="reason"
              label=""
              error={form.errors.reason?.message}
              showErrorMessage
              items={[
                {
                  id: '',
                  text: 'Select a reason',
                  value: '',
                },
                ...ReasonOptions,
              ]}
            />
          </Grid.Item>

          <Grid.Item width={[1]}>
            <S.StyledTextArea
              ref={form.register({
                required: TEXTAREA_REQUIRED_ERROR_MESSAGE,
              })}
              id="feedback"
              name="feedback"
              label="How could we better help you?"
              error={form.errors.feedback?.message}
              hasError={Boolean(form.errors.feedback?.message)}
              placeholder="I found care through another telehealth provider"
            />
          </Grid.Item>

          <Grid.Item width={[1]}>
            <NeedHelpCard
              onClick={() => {
                analytics.track({
                  event: ANALYTICS.EVENTS.CLICKED_BUTTON,
                  data: {
                    label: 'Call Care Team',
                    page: ANALYTICS.PAGES.EHS_T2T.CANCEL_APPOINTMENT_PAGE,
                    appointmentId,
                    ...commonT2TAnalyticsData,
                  },
                });
              }}
              header="Need to reschedule?"
              phoneNumber={CARE_PHONE_NUMBER}
            />
          </Grid.Item>

          <Grid.Item width={[1]}>
            <S.ButtonsContainer spacing={['sm']}>
              <Grid.Item width={[1, 0.45]}>
                <Button
                  type="submit"
                  css={{ width: '100%' }}
                  isLoading={isSubmitting}
                >
                  Cancel appointment
                </Button>
              </Grid.Item>
              <Grid.Item width={[1, 0.52]}>
                <Button
                  component={Link}
                  href={`${LEGACY_APP_ROOT}/members`}
                  appearance="secondary"
                  css={{ width: '100%' }}
                  onClick={() => {
                    analytics.track({
                      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
                      data: {
                        label: 'Do not wish to cancel CTA',
                        page: ANALYTICS.PAGES.EHS_T2T.CANCEL_APPOINTMENT_PAGE,
                        appointmentId,
                        ...commonT2TAnalyticsData,
                      },
                    });
                  }}
                >
                  No, I do not wish to cancel
                </Button>
              </Grid.Item>
            </S.ButtonsContainer>
          </Grid.Item>
        </Grid.Container>
      </S.Container>
    </Layout>
  );
}

export default CancelAppointmentPage;
