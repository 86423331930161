import styled from 'styled-components';

type FadeWrapperProps = {
  duration: number;
};

const FadeWrapper = styled.div<FadeWrapperProps>`
  &.enter {
    opacity: 0;
    transform: scale(0.9);
  }

  &.enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: ${(props) =>
      `opacity ${props.duration}ms, transform ${props.duration}ms`};
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: ${(props) =>
      `opacity ${props.duration}ms, transform ${props.duration}ms`};
  }
`;

export { FadeWrapper };
