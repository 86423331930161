import { justTheNumbers } from '../../Input/utils';

function formatPhoneNumber(value: string) {
  if (value.length > 6) {
    return value.replace(/(\d{3})(\d{3})(\d{0,4})/, '($1) $2-$3');
  }

  if (value.length > 3) {
    return value.replace(/(\d{3})(\d{0,3})/, '($1) $2');
  }

  return value;
}

function isValidPhoneNumber(value: string) {
  // this regex is stripping out dashes & parens and asserting that first value isn't 0 or 1
  const cleanNumber = justTheNumbers(value);
  return cleanNumber.length === 10 && !/^(0|1)/.test(cleanNumber);
}

function isValidPhoneNumberOptional(value: string | null) {
  if (value === null || value === '') {
    return true;
  }
  return isValidPhoneNumber(value);
}

export { formatPhoneNumber, isValidPhoneNumber, isValidPhoneNumberOptional };
