import {
  mediaQueries,
  size,
  colors,
  Input as RawInput,
  Button,
  Dropdown as BaseDropdown,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 950px;

  ${mediaQueries.forDesktopUp} {
    height: 100%;
  }
`;

export const Input = styled(RawInput)`
  padding-bottom: ${size.md}px;
`;

export const Dropdown = styled(BaseDropdown)`
  padding-bottom: ${size.xl2}px;
  display: flex;
  flex-direction: column;
`;

export const InvalidAddress = styled.div`
  color: ${colors.red3};
  margin-bottom: ${size.md}px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  gap: ${size.lg}px;
  position: fixed;
  width: 100%;
  height: 80px;
  left: -1px;
  bottom: 0;
  padding: ${size.md}px;
  background: ${colors.white};
  box-shadow: 0 -3px 19px rgba(0, 0, 0, 0.08);

  ${mediaQueries.forDesktopUp} {
    position: initial;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const BackButton = styled(Button)`
  display: none;

  ${mediaQueries.forDesktopUp} {
    display: block;
    width: 100%;
  }
`;

export const ConfirmButton = styled(Button)`
  width: 100%;
`;
