import { Button } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { TELEHEALTH_INTERSTITIAL_PAGE_URL } from 'common/utils/constants/urls';
import { appendQueryParamsToUrl } from 'common/utils/helpers';
import Grid from 'components/Grid/Grid';
import Layout from 'components/Layout/Layout';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as CancelPageStyles from 'pages/CancelAppointmentPage/CancelAppointmentPage.styles';
import React from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import {
  OPENLOOP_AVAILABILITY,
  OPENLOOP_FORMATTED_PHONE_NUMBER,
  OPENLOOP_PHONE_NUMBER,
} from '../CancelAppointmentPage/utils/constants';
import * as S from './CancelAppointmentConfirmationPage.styles';
import HeaderIcon from './components/HeaderIcon';

export type CancelAppointmentConfirmationPageProps = {};

/**
 * Cancel Appointment Confirmation page.
 */
function CancelAppointmentConfirmationPage(
  props: CancelAppointmentConfirmationPageProps,
) {
  const { appointmentId } = useParams();
  const [searchParams] = useSearchParams();

  const { nativeAppointmentScheduling } = useFlags<{
    nativeAppointmentScheduling?: boolean;
  }>();

  const program = searchParams.get('program') ?? '';

  const telehealthUrl = appendQueryParamsToUrl(
    TELEHEALTH_INTERSTITIAL_PAGE_URL,
    {
      program,
      redirectToProvider: 'true',
    },
  );

  return (
    <Layout>
      <CancelPageStyles.Container>
        <Grid.Container spacing={['md', 'lg']} alignEdges={false}>
          <Grid.Item
            width={[1]}
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <HeaderIcon />
            <S.Heading as="h1">Your appointment has been cancelled</S.Heading>
          </Grid.Item>

          <Grid.Item width={[1]}>
            <S.Text>
              We're sorry you had to cancel your session. You can reschedule any
              time or contact your healthcare provider team at{' '}
              <a
                href={`tel:${OPENLOOP_PHONE_NUMBER}`}
                onClick={() => {
                  analytics.track({
                    event: ANALYTICS.EVENTS.CLICKED_BUTTON,
                    data: {
                      label: 'Call OpenLoop',
                      program,
                      appointmentId,
                    },
                  });
                }}
              >
                {OPENLOOP_FORMATTED_PHONE_NUMBER}
              </a>
              , {OPENLOOP_AVAILABILITY}
            </S.Text>
          </Grid.Item>

          <Grid.Item width={[1]}>
            <CancelPageStyles.ButtonsContainer spacing={['sm']}>
              {nativeAppointmentScheduling === false && (
                <Grid.Item
                  width={[1, 'auto']}
                  css={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    href={telehealthUrl}
                    css={{ width: '100%', maxWidth: '375px' }}
                    onClick={() => {
                      analytics.track({
                        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
                        data: {
                          label: 'Reschedule appointment',
                          program,
                          appointmentId,
                        },
                      });
                    }}
                  >
                    Schedule new appointment
                  </Button>
                </Grid.Item>
              )}
              <Grid.Item
                width={[1, 'auto']}
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  component={Link}
                  to="/dashboard"
                  appearance="secondary"
                  css={{ width: '100%', maxWidth: '375px' }}
                  onClick={() => {
                    analytics.track({
                      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
                      data: {
                        label: 'Go to My Everly',
                        program,
                        appointmentId,
                      },
                    });
                  }}
                >
                  Return to My Everly
                </Button>
              </Grid.Item>
            </CancelPageStyles.ButtonsContainer>
          </Grid.Item>
        </Grid.Container>
      </CancelPageStyles.Container>
    </Layout>
  );
}

export default CancelAppointmentConfirmationPage;
