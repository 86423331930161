/* eslint-disable no-console */
import { Container, mediaQueries } from '@everlywell/leaves';
import { array } from '@storybook/addon-knobs';
import React from 'react';
import styled from 'styled-components';

import FoodSensitivityHealthProfileTable from './index';
export default {
  title: 'Components/FoodSensitivityHealthProfileTable',
  component: FoodSensitivityHealthProfileTable,
};

const HealthProfileContainer = styled(Container)`
  ${mediaQueries.forTabletHorizontalUp} {
    width: 100%;
  }
`;

export const Example = () => {
  const responses = ['Food Allergy', 'Lactose Intolerant', 'Celiac Disease'];
  const value = array('Responses', responses);

  return (
    <HealthProfileContainer>
      <FoodSensitivityHealthProfileTable responses={value} />
    </HealthProfileContainer>
  );
};
