import { colors, mediaQueries, typography } from '@everlywell/leaves';
import { sectionTitle, bodyCopy } from 'common/styles/app-styles';
import styled from 'styled-components';

type Props = {
  isPlainNumberMarkerType: boolean;
};

const ReactivityBadgeWrap = styled.div`
  background: #f9f9f9;
  border-radius: 0 8px 0 0;
  padding: 20px 24px;

  ${mediaQueries.forTabletVerticalUp} {
    border-left: solid 1px #ebebeb;
    width: 320px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${mediaQueries.forTabletHorizontalUp} {
    width: 368px;
  }
`;

const NumericalMarkerResultWrap = styled.div`
  ${mediaQueries.forTabletVerticalUp} {
    display: flex;
  }
`;

const NumericalMarkerResult = styled.div`
  background: ${colors.white};
  border-radius: 8px 0 0;
  flex: 1 1 auto;
  margin: 0 auto;
  padding: 18px 16px 16px;

  ${mediaQueries.forTabletVerticalUp} {
    padding: 20px 24px;
  }
  ${mediaQueries.forTabletHorizontalUp} {
    padding: 28px 48px 23px;
  }
`;

const MarkerName = styled.h3`
  ${sectionTitle};
  font-weight: ${typography.weight.bold};
  margin: 0;
  color: ${colors.gray4};
`;

const MarkerUnits = styled.h4`
  ${bodyCopy};
  font-weight: ${typography.weight.light};
  color: ${colors.gray4};
  margin: 0 0 7px;
`;

const MarkerNameAndUnitContainer = styled.div<Props>`
  ${(props) => (props.isPlainNumberMarkerType ? `padding: 8% 0` : null)};
`;

export {
  ReactivityBadgeWrap,
  NumericalMarkerResult,
  NumericalMarkerResultWrap,
  MarkerName,
  MarkerUnits,
  MarkerNameAndUnitContainer,
};
