import {
  Checkbox,
  DropdownOptionType,
  formUtils,
  mediaQueries,
} from '@everlywell/leaves';
import { Token, loadStripe } from '@stripe/stripe-js';
import { CardLabelType, setPayment } from 'common/apis/telehealthApis';
import { BaseRequestError } from 'common/hooks/useApi/request';
import useProgramType from 'common/hooks/useProgramType';
import useUser from 'common/hooks/useUser';
import analytics from 'common/utils/analytics';
import { WWW_APP_ROOT } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { isAsyncProgram, logError } from 'common/utils/helpers';
import { zipcodeCleaningValidation } from 'common/utils/helpers';
import Grid from 'components/Grid';
import { ClientError } from 'components/Notification/NotificationContext';
import CardDetailsStripe from 'components/StripeComponent/CardDetailsStripe';
import {
  StripeFormProvider,
  useStripeFormContext,
} from 'components/StripeComponent/Providers/StripeFormProvider';
import { useCastPaymentErrorToNotificationData } from 'components/StripeComponent/utils';
import ToastNotification from 'components/ToastNotification';
import isEmpty from 'lodash/isEmpty';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { CONTENT } from '../../content';
import { useRedirectAfterPaymentPage } from '../../utils/redirections';
import { NoShowMessage } from '../NoShowMessage';
import * as S from './Payment.styles';
import PaymentDescription from './PaymentDescription';

const stripePromise = loadStripe(
  process.env.TELEHEALTH_VENDOR_STRIPE_PUBLIC_KEY || '',
);

const SELECT_PAYMENT_TYPE = 'Select Payment Type';

const paymentOptions: DropdownOptionType[] = [
  { value: SELECT_PAYMENT_TYPE, id: '1', isDisabled: true, isSelected: true },
  {
    id: 'personal',
    value: 'personal', // change to lowercase value
    text: 'Personal', // add this for display purposes
  },
  {
    id: 'hsa',
    value: 'hsa',
    text: 'HSA',
  },
  {
    id: 'fsa',
    value: 'fsa',
    text: 'FSA',
  },
];

export type FormValues = {
  card_label_type: CardLabelType;
  zipCode: string;
  wants_marketing: boolean;
};
export enum FormValuesEnum {
  cardNumber = 'cardNumber',
  cardCvc = 'cardCvc',
  cardExpiry = 'cardExpiry',
  card_label_type = 'card_label_type',
  zipCode = 'zipCode',
}

const enumKeys = Object.keys(FormValuesEnum);

export type PaymentProps = {
  program: string;
  isSTIOD: boolean;
};

export function areEmptyInputValues<T>(obj: Partial<T>): boolean {
  for (const key of enumKeys) {
    const inputValue = (obj as Record<string, any>)[key];
    if (inputValue === false) {
      return true;
    }
  }

  return false;
}

function Form(props: PaymentProps) {
  const { program, isSTIOD } = props;
  const navigate = useNavigate();
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const { errors, handleSubmit, register } = useFormContext();
  const navigateProgramOndemandOrSuccessPaymentPage =
    useRedirectAfterPaymentPage(program);

  const [paymentError, setPaymentError] = useState<ClientError | undefined>();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const {
    checkStripeElementsOnError,
    elements,
    fetchToken,
    stripeElementsErrors,
  } = useStripeFormContext();

  const notificationData = useCastPaymentErrorToNotificationData(paymentError);

  const { user } = useUser();
  const programType = useProgramType(program);
  const isProgramAsync = isAsyncProgram(programType);

  const isProgramOnDemand = user?.enrolled_programs.find(
    (enrolledProgram) => enrolledProgram.slug === program,
  )?.on_demand;

  const { mutate, isLoading } = useMutation(setPayment, {
    onSuccess: () => {
      navigateProgramOndemandOrSuccessPaymentPage();
    },
    async onError(error: BaseRequestError) {
      if (error instanceof BaseRequestError) {
        let paymentError: ClientError = error.data;
        paymentError.code = error.fetchResponse.status;

        logError(error.message, error);
        setPaymentError(paymentError);
      }
    },
  });

  function handleSelect(e: SyntheticEvent<HTMLSelectElement>) {
    if (!isSelected) {
      setIsSelected(true);
    }
  }

  const isSubmitting = isSubmitted || isLoading;
  const isSubmitDisabled = !isEmpty(errors) || !isEmpty(stripeElementsErrors);

  const onPlaceOrder: SubmitHandler<FormValues> = async (formData) => {
    setIsSubmitted(true);
    setPaymentError(() => undefined);
    const isSubmitEnable = !isSubmitDisabled;
    if (isSubmitEnable) {
      await analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: ANALYTICS.LABELS.VIRTUAL_CARE_FORMS.CREDIT_CARD_CONTINUE,
          program: program,
        },
      });

      const noElements = !elements;
      if (noElements) {
        return;
      }

      try {
        const token: Token = await fetchToken(formData);

        mutate({
          token: token.id,
          card_label_type: formData.card_label_type,
          wants_marketing: formData.wants_marketing,
        });
      } catch (e) {
        const error = e as Error;
        logError(error.message, {
          ...error,
          method: 'Payment VCV - onPlaceOrder',
        });
        setPaymentError(e as ClientError);
      } finally {
        setIsSubmitted(() => false);
      }
    }
    setIsSubmitted(() => false);
  };

  return (
    <S.Container>
      {notificationData && (
        <S.ToastWrapper>
          <ToastNotification notification={notificationData} />
        </S.ToastWrapper>
      )}

      <PaymentDescription
        title={CONTENT.title}
        caption={isProgramAsync ? CONTENT.asyncProgramCaption : CONTENT.caption}
        acceptedCards
        acceptedCardsNote={CONTENT.acceptedCardsNotes}
      />

      <form onSubmit={handleSubmit(onPlaceOrder, checkStripeElementsOnError)}>
        <Grid.Container spacing={['md', 'md', 'lg']}>
          <Grid.Item width={[1]}>
            <div>
              <S.DropdownWrapper
                error={errors.card_label_type && errors.card_label_type.message}
                id="card_label_type"
                isSelected={isSelected}
                items={paymentOptions}
                label="Select Payment Type"
                name="card_label_type"
                onChange={handleSelect}
                ref={register({
                  required: 'Please enter an option.',
                  validate: (value: string) =>
                    value !== SELECT_PAYMENT_TYPE || 'Please enter an option.',
                })}
              />
            </div>
          </Grid.Item>

          <CardDetailsStripe />

          <Grid.Item width={[1]}>
            <S.Input
              id="zipCode"
              inputMode="numeric"
              label="Zip code"
              name="zipCode"
              placeholder="12345"
              ref={register({
                required: 'Please enter a ZIP code',
                validate: (value: string) =>
                  value.length < 6 &&
                  (formUtils.validateZipCode(value) ||
                    'Please enter a valid ZIP code.'),
              })}
              error={errors.zipCode && errors.zipCode.message}
              onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                const target = e.target as HTMLInputElement;
                const zipcode = zipcodeCleaningValidation(target.value);
                target.value = zipcode;
              }}
              type="text"
            />
          </Grid.Item>

          <Grid.Item width={[1]}>
            <Controller
              name="wants_marketing"
              defaultValue={true}
              render={({ onChange, value, ref }) => (
                <Checkbox
                  name="wants_marketing"
                  label={
                    <S.MarketingOptInLabel>
                      Yes! I want to receive exclusive email offers, tips, and
                      health insights. By subscribing you confirm you've read
                      and accept our{' '}
                      <a
                        href={`${WWW_APP_ROOT}/privacy-policy`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        privacy policy
                      </a>
                    </S.MarketingOptInLabel>
                  }
                  ref={ref}
                  onChange={() => {
                    onChange(!value);
                  }}
                  defaultChecked={true}
                  checked={value}
                />
              )}
            />
          </Grid.Item>

          {isSTIOD && (
            <Grid.Item width={[1]}>
              <NoShowMessage />
            </Grid.Item>
          )}
          <Grid.Item width={[1]}>
            <S.OrderWrapper>
              <Grid.Container
                spacing={['md']}
                css={{
                  [mediaQueries.forPhoneOnly]: {
                    flexDirection: 'column-reverse',
                  },
                }}
              >
                <Grid.Item width={['auto']} css={{ flexGrow: 0 }}>
                  <S.Button
                    type="button"
                    appearance="secondary"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </S.Button>
                </Grid.Item>
                <Grid.Item width={['auto']}>
                  <S.Button
                    appearance="primary"
                    isLoading={isSubmitting}
                    role="button"
                    type="submit"
                  >
                    {isProgramOnDemand
                      ? 'Submit appointment request'
                      : 'Continue'}
                  </S.Button>
                </Grid.Item>
              </Grid.Container>
            </S.OrderWrapper>
          </Grid.Item>
        </Grid.Container>
      </form>
    </S.Container>
  );
}

function Payment(props: PaymentProps) {
  const methods = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    if (!isEmpty(props.program)) {
      analytics.track({
        event: ANALYTICS.EVENTS.VIEWED_PAGE,
        data: {
          page: ANALYTICS.PAGES.ACCOUNT_HUB.CREDIT_CARD,
          program: props.program,
        },
      });
    }
  }, [props.program]);

  return (
    <StripeFormProvider stripe={stripePromise}>
      <FormProvider {...methods}>
        <Form {...props} />
      </FormProvider>
    </StripeFormProvider>
  );
}

export default Payment;
