import Grid from 'components/Grid/Grid';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Input, labelStyles } from '../../utils/styles';
import { FormBuilderField } from '../../utils/types';

export type WaistCircumferenceFieldProps = FormBuilderField;

function WaistCircumferenceField(props: WaistCircumferenceFieldProps) {
  const { register, errors } = useFormContext();
  return (
    <Grid.Item width={[1]} key={props.id}>
      <Input
        css={labelStyles(props.variant)}
        id={props.id}
        label={props.label ?? ''}
        name={props.id}
        ref={register({
          required: props.required && 'Please enter your waist circumference',
          min: {
            value: 0,
            message: 'Please enter a valid number',
          },
          validate: props.validate,
        })}
        required={props.required}
        type="number"
        error={errors[props.id]?.message}
        min={0}
      />
    </Grid.Item>
  );
}

export default WaistCircumferenceField;
