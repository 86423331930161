import { Dropdown, DropdownOptionType } from '@everlywell/leaves';
import { ErrorText } from 'components/Forms';
import {
  eachMonthOfInterval,
  endOfYear,
  format,
  getDaysInMonth,
  getMonth,
  getYear,
  startOfYear,
  sub,
  eachYearOfInterval,
} from 'date-fns';
import React, { useState } from 'react';

import * as S from './DateOfBirthSelectGroup.styles';

const MIN_REQUIRED_AGE = 16;
const MAX_AGE = 120;
const DEFAULT_NUM_OF_DAYS = 31;

const monthOptions: DropdownOptionType[] = eachMonthOfInterval({
  start: startOfYear(new Date()),
  end: endOfYear(new Date()),
}).map((date) => ({
  text: format(date, 'MMMM'), // January, February, March, etc.
  value: format(date, 'MM'), // 1, 2, 3, etc.
  id: String(getMonth(date)), // 0, 1, 2, etc.
}));

const yearOptions = eachYearOfInterval({
  start: sub(new Date(), { years: MAX_AGE }),
  end: sub(new Date(), { years: MIN_REQUIRED_AGE }),
})
  .map((date) => ({
    text: format(date, 'yyyy'), // 2020, 2021, 2022, etc.
    value: format(date, 'yyyy'), // 2020, 2021, 2022, etc.
    id: String(getYear(date)), //
  }))
  .reverse();

const getDayOptions = (month: number, year: number = getYear(new Date())) => {
  const daysInMonth = getDaysInMonth(new Date(year, month));

  return [...new Array(daysInMonth)].fill(0).map((_, i) => ({
    text: String(i + 1).padStart(2, '0'),
    value: String(i + 1).padStart(2, '0'),
    id: `${i}`,
  }));
};

const getDefaultDayOptions = () =>
  new Array(DEFAULT_NUM_OF_DAYS).fill(0).map((_, i) => ({
    text: String(i + 1).padStart(2, '0'),
    value: String(i + 1).padStart(2, '0'),
    id: `${i}`,
  }));

type SelectFieldProps = {
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  onBlur?: React.FocusEventHandler<HTMLSelectElement>;
  ref?: React.RefCallback<HTMLSelectElement>;
  name?: string;
  pattern?: string;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  label?: string;
};

export type DateOfBirthSelectGroupProps = {
  monthField?: SelectFieldProps;
  dayField?: SelectFieldProps;
  yearField?: SelectFieldProps;
  label: React.ReactNode;
  error?: string;
  showFieldLabels?: boolean;
};

function DateOfBirthSelectGroup(props: DateOfBirthSelectGroupProps) {
  const {
    error: errorMessage,
    label,
    monthField,
    dayField,
    yearField,
    showFieldLabels = true,
  } = props;

  const [dayOptions, setDayOptions] = useState(() => {
    if (monthField?.defaultValue) {
      return getDayOptions(
        Number(monthField.defaultValue) - 1,
        Number(yearField?.defaultValue),
      );
    } else {
      return getDefaultDayOptions();
    }
  });
  const [year, setYear] = useState(yearField?.defaultValue ?? '');
  const [month, setMonth] = useState(monthField?.defaultValue ?? '');
  const [day, setDay] = useState(dayField?.defaultValue ?? '');

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const month = e.target.value;
    monthField?.onChange?.(e);
    setMonth(month);
    if (month) {
      setDayOptions(getDayOptions(Number(month) - 1, Number(year)));
    } else {
      setDayOptions(getDefaultDayOptions());
    }
  };
  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const year = e.target.value;
    yearField?.onChange?.(e);
    setYear(year);
    if (month) {
      setDayOptions(getDayOptions(Number(month) - 1, Number(year)));
    } else {
      setDayOptions(getDefaultDayOptions());
    }
  };

  const handleDayChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const day = e.target.value;
    dayField?.onChange?.(e);
    setDay(day);
  };

  return (
    <>
      <label id="dateOfBirth">{label}</label>
      <S.SelectGroup role="group" aria-labelledby="dateOfBirth">
        <Dropdown
          ref={monthField?.ref}
          id={monthField?.name ?? 'month'}
          name={monthField?.name ?? 'month'}
          label="Month"
          placeholderText=" "
          items={monthOptions}
          onChange={handleMonthChange}
          onBlur={monthField?.onBlur}
          hideLabel={!showFieldLabels}
          value={monthField?.ref ? monthField?.defaultValue : month}
        />
        <Dropdown
          id={dayField?.name ?? 'day'}
          name={dayField?.name ?? 'day'}
          label="Day"
          items={dayOptions}
          placeholderText=" "
          ref={dayField?.ref}
          onChange={handleDayChange}
          onBlur={dayField?.onBlur}
          hideLabel={!showFieldLabels}
          value={dayField?.ref ? dayField?.defaultValue : day}
        />
        <Dropdown
          id={yearField?.name ?? 'year'}
          name={yearField?.name ?? 'year'}
          label="Year"
          items={yearOptions}
          placeholderText=" "
          ref={yearField?.ref}
          onChange={handleYearChange}
          onBlur={yearField?.onBlur}
          hideLabel={!showFieldLabels}
          value={yearField?.ref ? yearField?.defaultValue : year}
        />
      </S.SelectGroup>
      <ErrorText>{errorMessage}</ErrorText>
    </>
  );
}

export default DateOfBirthSelectGroup;
