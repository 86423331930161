import { H4 } from '@everlywell/leaves';
import { CollectionDeviceImage } from 'common/utils/types';
import React from 'react';

import * as S from './styles';

const ImageWithoutMarkers = ({ image_url, name }: CollectionDeviceImage) => (
  <S.Container>
    <S.Wrapper>
      <S.Image src={image_url} alt={name} />
    </S.Wrapper>
    <H4>{name}</H4>
  </S.Container>
);

export default ImageWithoutMarkers;
