import { ImmutableReduxState } from 'common/utils/types';
import ResultsDiscussion, {
  Props,
} from 'pages/KitResultDetailPage/TakeActionSection/ResultsDiscussion';
import { connect } from 'react-redux';
import {
  makeSelectContent,
  makeSelectKitResultConsultAvailableUntil,
  makeSelectKitResultTestName,
} from 'store/selectors';

export const CONTENT_KEYS = {
  iconSrc: 'WEBINAR_TAKE_ACTION_ICON',
  alt: 'TAKE_ACTION_ICON_ALT',
  header: 'WEBINAR_DESCRIPTION_TITLE',
  description: 'WEBINAR_DESCRIPTION',
  texts: 'WEBINAR_ACTION_DESCRIPTION_*',
  iconSrcs: 'WEBINAR_ACTION_ICON_*',
  alts: 'WEBINAR_ACTION_ICON_ALT_*',
  modalHeader: 'WEBINAR_MODAL_TITLE',
  modalDescription: 'WEBINAR_MODAL_DESCRIPTION',
  modalLink: 'WEBINAR_MODAL_LINK',
  tooltipHeader: 'WEBINAR_TOOLTIP_TITLE',
  tooltipDescription: 'WEBINAR_TOOLTIP_DESCRIPTION',
};

type StateProps = {
  header: string;
  description: string;
  alt: string;
  iconSrc: string;
  texts: string[];
  iconSrcs: string[];
  alts: string[];
  date: string;
  modalHeader: string;
  modalDescription: string;
  modalLink: string;
  tooltipHeader: string;
  tooltipDescription: string;
  testName: string;
};

type OwnProps = {
  contentToken: string;
  kitResultId: string;
};

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): StateProps {
  return {
    ...makeSelectContent(ownProps.contentToken, CONTENT_KEYS)(state),
    date: makeSelectKitResultConsultAvailableUntil(ownProps.kitResultId)(state),
    testName: makeSelectKitResultTestName(ownProps.kitResultId)(state),
  };
}

export function mergeProps(stateProps: StateProps): Props {
  const {
    texts,
    iconSrcs,
    alts,
    header,
    description,
    iconSrc,
    alt,
    date,
    modalHeader,
    modalDescription,
    modalLink,
    tooltipHeader,
    tooltipDescription,
    testName,
  } = stateProps;

  const items = texts.map((text, index) => ({
    text,
    alt: alts[index],
    iconSrc: iconSrcs[index],
  }));

  return {
    items: items.length ? items : undefined,
    header,
    description,
    iconSrc,
    alt,
    date,
    modal: {
      header: modalHeader,
      description: modalDescription,
      link: modalLink,
    },
    tooltip: {
      header: tooltipHeader,
      description: tooltipDescription,
    },
    testName,
    buttonContent: 'Watch',
    directlyOpenLink: true,
    isWebinar: true,
  };
}

export default connect(mapStateToProps, null, mergeProps)(ResultsDiscussion);
