import { colors, mediaQueries, size } from '@everlywell/leaves';
import styled from 'styled-components';

const Wrapper = styled.div<{ overrideLayout?: boolean }>`
  background-color: ${colors.white};
  border-radius: 0 0 80px 80px;
  display: flex;
  justify-content: center;
  min-height: 152px;
  padding: 0 ${size.lg}px ${size.xl1}px;
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    border-radius: 0 0 200px 200px;
  }

  ${mediaQueries.forDesktopUp} {
    justify-content: flex-start;
    padding: 33px 104px 72px;
  }
`;

const Content = styled.div<{ overrideLayout?: boolean }>`
  margin: ${(props) => (props.overrideLayout ? '0' : '0 auto')};
  max-width: 1157px;
`;

export { Wrapper, Content };
