import { useFlags } from 'launchdarkly-react-client-sdk';

export const LDFlags = {
  authorizedStatesForService: 'authorizedStatesForService',
  bloodDrawCenterWork: 'bloodDrawCenterWork',
  discountWmLite: 'discountWmLite',
  giftGuideNavbarEnabled: 'giftGuideNavbarEnabled',
  hasEverlywellPlusNavMenu: 'hasEverlywellPlusNavMenu',
  hasWeightCarePromo: 'hasWeightCarePromo',
  marketingOptInIntake: 'marketingOptInIntake',
  programTermsOptInIntake: 'programTermsOptInIntake',
  settingsV2: 'settingsV2',
  showDynamicIntakeFlow: 'showDynamicIntakeFlow',
  showMultipleVariantsWcPlus: 'showMultipleVariantsWcPlus',
  showWeightCareAppStoreSection: 'showWeightCareAppStoreSection',
  showWeightCareAppointmentsSection: 'showWeightCareAppointmentsSection',
  showWeightCareJourneyChecklist: 'showWeightCareJourneyChecklist',
  showWeightCareMyPrescriptions: 'showWeightCareMyPrescriptions',
  showWeightCareMyTestKits: 'showWeightCareMyTestKits',
  showWeightCareRecommendedArticles: 'showWeightCareRecommendedArticles',
  showWeightCareSectionFooter: 'showWeightCareSectionFooter',
  simplifiedMembershipPlanSection: 'simplifiedMembershipPlanSection',
  isWcAsyncScheduleEnabled: 'isWcAsyncScheduleEnabled',
};

export const UseIsFlagActive = (flag: string): boolean => {
  const flags = useFlags();
  return flags[flag];
};

export const isBloodDrawActive = (): boolean =>
  UseIsFlagActive(LDFlags.bloodDrawCenterWork);

export const isSimplifiedMembershipPlanSectionActive = (): boolean =>
  UseIsFlagActive(LDFlags.simplifiedMembershipPlanSection);

export const hasDiscountWmLite = (): boolean =>
  UseIsFlagActive(LDFlags.discountWmLite);

export const showMultipleVariantsWcPlus = (): boolean =>
  UseIsFlagActive(LDFlags.showMultipleVariantsWcPlus);

export const showWeightCareAppStoreSection = (): boolean =>
  UseIsFlagActive(LDFlags.showWeightCareAppStoreSection);

export const showWeightCareSectionFooter = (): boolean =>
  UseIsFlagActive(LDFlags.showWeightCareSectionFooter);

export const showWeightCareJourneyChecklist = (): boolean =>
  UseIsFlagActive(LDFlags.showWeightCareJourneyChecklist);

export const showWeightCareAppointmentsSection = (): boolean =>
  UseIsFlagActive(LDFlags.showWeightCareAppointmentsSection);

export const showWeightCareMyPrescriptions = (): boolean =>
  UseIsFlagActive(LDFlags.showWeightCareMyPrescriptions);

export const showWeightCareRecommendedArticles = (): boolean =>
  UseIsFlagActive(LDFlags.showWeightCareRecommendedArticles);

export const hasWeightCarePromo = (): boolean =>
  UseIsFlagActive(LDFlags.hasWeightCarePromo);

export const hasEverlywellPlusNavMenu = (): boolean =>
  UseIsFlagActive(LDFlags.hasEverlywellPlusNavMenu);

export const showWeightCareMyTestKits = (): boolean =>
  UseIsFlagActive(LDFlags.showWeightCareMyTestKits);

export const hasgiftGuideNavbarNavMenu = (): boolean =>
  UseIsFlagActive(LDFlags.giftGuideNavbarEnabled);

export const showDynamicIntakeFlow = (): boolean =>
  UseIsFlagActive(LDFlags.showDynamicIntakeFlow);

export const authorizedStatesForService = (): boolean =>
  UseIsFlagActive(LDFlags.authorizedStatesForService);

export const isWcAsyncScheduleEnabled = (): boolean =>
  UseIsFlagActive(LDFlags.isWcAsyncScheduleEnabled);
