import { ReactElement, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
  children: ReactElement;
};

const ScrollToTop = ({ children }: Props) => {
  const location = useLocation();
  const prevLocation = useRef<any>();
  useEffect(() => {
    if (
      prevLocation?.current &&
      location.pathname !== prevLocation.current.pathname
    ) {
      window.scrollTo(0, 0);
    }
    prevLocation.current = location;
  }, [location]);

  return children;
};

export default ScrollToTop;
