export default {
  title: 'Questions?',
  listOfContentSectionsCollection: {
    items: [
      {
        sectionId: '593e0e96-f8d2-5ab1-9793-6bb4501ed974',
        title: 'This is a title',
        description:
          'The Metabolism Test may be useful for someone having trouble reaching a goal weight (such as a target weight loss goal), someone who suspects they have a “slowed metabolism,” or someone who is just looking to dive deeper into their wellness.',
      },
      {
        sectionId: '593e0e96-f8d2-5ab1-9793-6bb4501ed975',
        title: 'This is another title',
        description:
          'EverlyWell can provide suggestions for lifestyle changes that can jumpstart your health. As always, we encourage you to share your results with your doctor, who can provide more specific guidance.',
      },
    ],
  },
  handleFAQClick: () => {},
};
