import { size } from '@everlywell/leaves';
import {
  Container,
  DetailText as BaseDetailText,
  Heading,
  HelpBody,
  HelpIcon,
  HelpPhone,
  HelpTitle,
  NoWrapSpan,
  Icon as BaseIcon,
} from 'pages/VirtualCare/NativeScheduling/pages/ReviewBooking/ReviewBooking.styles';
import styled, { css } from 'styled-components';

const DetailText = styled(BaseDetailText)`
  display: flex;
  align-items: flex-start;

  `;

type IconProps = {
  verticalAlign?: boolean;
}
const Icon = styled(BaseIcon) <IconProps>`
  ${({ verticalAlign }) => verticalAlign &&
    css`margin-top: ${size.xs1}px`
  }
`

export {
  Container,
  DetailText,
  Heading,
  HelpBody,
  HelpIcon,
  HelpPhone,
  HelpTitle,
  Icon,
  NoWrapSpan,
};
