import { Button, H3 } from '@everlywell/leaves';
import {
  saveInsuranceForm,
  getInsurancePlans,
} from 'common/apis/telehealthApis';
import useProgramSlug from 'common/hooks/useProgramSlug';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { HEALTHIE_WHITE_LABEL_SSO_LINK } from 'common/utils/constants/urls';
import { logError } from 'common/utils/helpers';
import Grid from 'components/Grid';
import Layout from 'components/Layout';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useForm, useFieldArray } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import ErrorPage from '../ErrorPage';
import PolicyForm, {
  PolicyFields,
  PolicyFormErrors,
} from './components/PolicyForm';
import { INSURANCE_PAGE_CONTENT } from './InsurancePage.content';
import InsurancePageSkeleton from './InsurancePage.skeleton';
import * as S from './InsurancePage.styles';
import { useRedirectAfterInsurance } from './utils/redirections';

export type InsurancePageProps = {};

export const formDefaults: PolicyFields = {
  insurance_plan_id: '',
  insurance_type: '',
  priority_type: '',
  holder_relationship: '',
  num: '',
  group_num: '',
  holder_first: '',
  holder_mi: '',
  holder_last: '',
  holder_gender: '',
  holder_dob: '',
  holder_phone: '',
  policy_phone_number: '',
  holder_location: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  insurance_card_front: undefined,
  insurance_card_back: undefined,
};

const defaultValues = {
  name: 'Insurance',
  policies: [formDefaults],
};

function InsurancePage(props: InsurancePageProps) {
  const { telehealthInsurance, showDynamicIntakeFlow } = useFlags();
  const { programSlug } = useProgramSlug();
  const program = programSlug ?? '';
  const { navigateToNextPage, navigationPath } = useRedirectAfterInsurance();

  const isTelehealthInsuranceFormDisabled = telehealthInsurance === false;
  const isTelehealthInsuranceFormEnabled = telehealthInsurance === true;

  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const { register, errors, handleSubmit, control, watch } = formMethods;

  const {
    fields: policies,
    remove,
    append,
  } = useFieldArray({
    control,
    name: `policies`,
  });

  const navigate = useNavigate();
  const onNavigateBack = () => {
    navigate(-1);
  };

  const {
    data: insurancePlansResponse,
    isLoading: isFetchingInsurance,
    isError: isErrorFetching,
  } = useQuery(
    'telehealth-insurance-plans',
    () => getInsurancePlans({ program }),
    {
      enabled:
        (showDynamicIntakeFlow || isTelehealthInsuranceFormEnabled) &&
        !!program,
      onSuccess: () => {
        analytics.track({
          event: ANALYTICS.EVENTS.VIEWED_PAGE,
          data: {
            page: ANALYTICS.PAGES.TELEHEALTH_INSURANCE_PAGE,
            program,
          },
        });
      },
      onError(error) {
        logError((error as Error).message, {
          errorInfo: 'Insurance Plans Response',
          component: 'InsuranceForm',
          method: 'getInsurancePlans',
        });
      },
    },
  );

  const insurancePlans = insurancePlansResponse?.data ?? [];

  const {
    mutate,
    isLoading: isSubmitting,
    isError: isErrorSubmitting,
  } = useMutation(saveInsuranceForm, {
    onSuccess: async () => {
      await analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: ANALYTICS.LABELS.VIRTUAL_CARE_FORMS.INSURANCE_CONTINUE,
          program,
        },
      });
      navigateToNextPage();
    },
  });

  const onSubmit = (data: typeof defaultValues) => {
    mutate({ policies: data.policies, program });
  };

  useEffect(() => {
    if (isTelehealthInsuranceFormDisabled) {
      window.open(HEALTHIE_WHITE_LABEL_SSO_LINK, '_self');
    }
  }, [isTelehealthInsuranceFormDisabled]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'insurancePage',
        programName: program,
      },
    });
  }, [program]);

  const onSkipInsurance = () => {
    // Add skip insurance logic here

    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'Skip Insurance',
        page: ANALYTICS.PAGES.TELEHEALTH_INSURANCE_PAGE,
        program,
      },
    });
  };

  const CONTENT =
    (program && INSURANCE_PAGE_CONTENT[program]) ||
    INSURANCE_PAGE_CONTENT.DEFAULT;

  if (isFetchingInsurance) {
    return (
      <Layout>
        <S.Container>
          <InsurancePageSkeleton />
        </S.Container>
      </Layout>
    );
  }

  if (isErrorFetching) {
    // TODO: replace with new error page
    return <ErrorPage />;
  }

  return (
    // <Layout>
    <S.Container>
      <Grid.Container
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        spacing={{
          spacingX: ['lg'],
          spacingY: ['xl2'],
        }}
      >
        <Grid.Item width={[1]}>
          <H3 as="h1" style={{ margin: 0 }}>
            Add insurance
          </H3>
        </Grid.Item>
        <Grid.Item width={[1]}>
          {policies.map((policy, index) => (
            <PolicyForm
              key={`policy-${index}`}
              {...{
                index,
                policy,
                register,
                errors: errors as PolicyFormErrors,
                remove,
                control,
                insurancePlans,
                watch,
              }}
            />
          ))}
        </Grid.Item>
        <Grid.Item width={[1]}>
          <S.AddPolicyLink onClick={() => append(formDefaults)}>
            + Add policy
          </S.AddPolicyLink>
        </Grid.Item>
        {isErrorSubmitting && (
          <Grid.Item width={[1]}>
            <S.ErrorText>Unable to submit form. Please try again.</S.ErrorText>
          </Grid.Item>
        )}
        <Grid.Item width={[1]}>
          <S.ButtonsContainer>
            <Grid.Container spacing={['md', 'lg']} alignEdges={false}>
              <Grid.Item width={['auto']} css={{ flexGrow: 0 }}>
                <Button
                  type="button"
                  appearance="secondary"
                  isLoading={isSubmitting}
                  onClick={() => onNavigateBack()}
                  css={{ width: '100%' }}
                >
                  Back
                </Button>
              </Grid.Item>
              <Grid.Item width={['auto']}>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  css={{ width: '100%' }}
                >
                  Continue
                </Button>
              </Grid.Item>
              <Grid.Item width={[1]}>
                <S.NoInsuranceLink
                  to={navigationPath}
                  onClick={() => onSkipInsurance()}
                >
                  {CONTENT.WITHOUT_INSURANCE}
                </S.NoInsuranceLink>
              </Grid.Item>
            </Grid.Container>
          </S.ButtonsContainer>
        </Grid.Item>
      </Grid.Container>
    </S.Container>
    // </Layout>
  );
}

export default InsurancePage;
