import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
  MutableRefObject,
  useRef,
} from 'react';

import { KeyValueQuestionsSet } from '../../components/HealthProfile/hooks';

type Props = {
  children: ReactNode;
};
export type CurrentQuestionProps = number | undefined | null;
type ContextProps = {
  currentQuestionId: CurrentQuestionProps;
  keyValueQuestionsSet: MutableRefObject<KeyValueQuestionsSet>;
  prevQuestionId: number[];
  setCurrentQuestion: Dispatch<SetStateAction<CurrentQuestionProps>>;
  setPrevQuestion: Dispatch<SetStateAction<number[]>>;
  setTotalQuestions: Dispatch<SetStateAction<number>>;
  totalQuestions: number;
};

export const NavigationQuestionContext = createContext<ContextProps>({
  currentQuestionId: undefined,
  keyValueQuestionsSet: {
    current: {
      answered: false,
      all: {},
    },
  },
  prevQuestionId: [],
  setCurrentQuestion: () => {},
  setPrevQuestion: () => {},
  setTotalQuestions: () => {},
  totalQuestions: 0,
});

export const useNavigationQuestion = () =>
  useContext(NavigationQuestionContext);
const NavigationQuestionProvider = ({ children }: Props) => {
  const [currentQuestionId, setCurrentQuestion] =
    useState<CurrentQuestionProps>();
  const [prevQuestionId, setPrevQuestion] = useState<number[]>([]);
  const [totalQuestions, setTotalQuestions] = useState<number>(0);
  const keyValueQuestionsSet = useRef<KeyValueQuestionsSet>({
    all: {},
    answered: false,
  });

  return (
    <NavigationQuestionContext.Provider
      value={{
        currentQuestionId,
        keyValueQuestionsSet,
        prevQuestionId,
        setCurrentQuestion,
        setPrevQuestion,
        setTotalQuestions,
        totalQuestions,
      }}
    >
      {children}
    </NavigationQuestionContext.Provider>
  );
};

export default NavigationQuestionProvider;
