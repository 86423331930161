import { Button, colors, H3, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const Title = styled(H3)`
  font-weight: ${typography.weight.bold};
  padding-bottom: ${size.sm}px;
`;

const Subtitle = styled.p`
  ${typography.bodyTextSmall}
  font-weight: ${typography.weight.book};
  color: ${colors.gray4};
  margin-bottom: ${size.xl2}px;
  white-space: pre-line;
  a {
    color: ${colors.green5};
    text-decoration: none;

    &:hover {
      color: ${colors.green5};
      text-decoration: underline;
    }
  }
`;

const Accept = styled(Button)`
  width: 100%;
`;

const OptionalAccept = styled(Button)`
  margin-top: ${size.md}px;
  width: 100%;
`;

export { Title, Subtitle, Accept, OptionalAccept };
