import useTelehealthMedications from 'common/hooks/useTelehealthMedications';
import { MultiSelectAutoCompleteProps } from 'components/MultiSelectAutoComplete/MultiSelectAutoComplete';
import React from 'react';

import { FormBuilderField } from '../../utils/types';
import { AutoCompleteField } from '../AutoCompleteField/AutoCompleteField';

type MedicationsAutoCompleteFieldProps = FormBuilderField &
  MultiSelectAutoCompleteProps;

export const MedicationsAutoCompleteField = (
  props: MedicationsAutoCompleteFieldProps,
) => {
  const { medications: data, ...rest } = useTelehealthMedications();
  return (
    <AutoCompleteField
      data={data}
      {...rest}
      {...props}
      notFoundMessage={props.notFoundMessage || 'No medications found'}
    />
  );
};
