import {
  TelehealthFormAnswer,
  getEnterpriseInsuranceDetails,
  saveEnterpriseInsuranceForm,
} from 'common/apis/telehealthApis';
import { BaseRequestError, handleError } from 'common/hooks/useApi/request';
import { useCommonT2TAnalytics } from 'common/hooks/useCommonT2TAnalytics';
import useEnterpriseProgramSlug from 'common/hooks/useEnterpriseProgramSlug';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { logError } from 'common/utils/helpers';
import Layout from 'components/Layout';
import FormBuilder from 'components/telehealth/FormBuilder';
import FormPageBuilderSkeleton from 'pages/FormPageBuilder/FormPageBuilder.skeleton';
import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import useEffectOnce from 'react-use/lib/useEffectOnce';

import { useNativeIntakeContext } from '../../context/NativeIntakeContext';
import { customModules } from './config';
import { parseFormData, setDefaultValues } from './utils';

const EnterpriseInsuranceFormPage = () => {
  const { enterpriseProgramSlug } = useEnterpriseProgramSlug();
  const { commonT2TAnalyticsData } = useCommonT2TAnalytics();
  const { goToNextStep } = useNativeIntakeContext();
  const { data: defaultEnterpriseInsuraceData, isLoading } = useQuery(
    'getEnterpriseInsuranceDetails',
    getEnterpriseInsuranceDetails,
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const {
    mutate: saveInsuranceForm,
    isLoading: isSubmitting,
    status,
    error: errorResponse,
  } = useMutation(saveEnterpriseInsuranceForm, {
    onSuccess: async () => {
      await analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: ANALYTICS.LABELS.EHS_T2T_FORMS.INSURANCE_CONTINUE,
          ...commonT2TAnalyticsData,
        },
      });

      goToNextStep();
    },
  });
  const error = errorResponse as BaseRequestError | null;

const handleSubmit = async (formData: TelehealthFormAnswer[]) => {
    const parsedFormData = parseFormData(formData);

    saveInsuranceForm({
        ...parsedFormData,
        program_slug: enterpriseProgramSlug ?? '',
        self_payment: false
    });
};

  useEffectOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        page: ANALYTICS.PAGES.EHS_T2T.INSURANCE_PAGE,
        ...commonT2TAnalyticsData,
      },
    });
  });

  useEffect(() => {
    if (error && status === 'error') {
      logError(error.message, error);
    }
  }, [error, status]);

  if (isLoading) {
    return <FormPageBuilderSkeleton />;
  }

  const defaultValues = setDefaultValues(defaultEnterpriseInsuraceData);

  return (
    <Layout>
      <FormBuilder
        custom_modules={customModules}
        defaultValues={defaultValues}
        error={handleError(error)}
        id="enterprise-insurance-form"
        isSubmitting={isSubmitting}
        name="Your Insurance"
        onSubmit={handleSubmit}
      />
    </Layout>
  );
};

export default EnterpriseInsuranceFormPage;
