import { AppointmentStatusType } from 'common/apis/telehealthApis';

export enum STATUSES {
  Occurred = 'occurred',
  NoShow = 'no-show',
  ReScheduled = 're-scheduled',
  Cancelled = 'cancelled',
}
type PMStatus = 'canceled' | 'completed' | 'missed' | 'rescheduled';

const translations: { [s: string]: PMStatus } = {
  'no-show': 'missed',
  're-scheduled': 'rescheduled',
  cancelled: 'canceled',
  occurred: 'completed',
};

function translateStatus(
  status: AppointmentStatusType | null | undefined,
): PMStatus {
  return status ? translations[status.toLowerCase()] : 'completed';
}

function isPmStatusOccurred(
  status: AppointmentStatusType | null | undefined,
): boolean {
  return (
    status === null ||
    status === undefined ||
    status?.toLowerCase() === STATUSES.Occurred
  );
}

export { translateStatus, isPmStatusOccurred };
