export default {
  physicianName: {
    displayName: 'Physician Name',
    key: 'physicianName',
  },
  firstName: {
    displayName: 'First Name',
    key: 'firstName',
  },
  lastName: {
    displayName: 'Last Name',
    key: 'lastName',
  },
  address1: {
    displayName: 'Address1',
    key: 'address1',
  },
  address2: {
    displayName: 'Address2',
    key: 'address2',
  },
  city: {
    displayName: 'City',
    key: 'city',
  },
  state: {
    displayName: 'State',
    key: 'state',
  },
  zipCode: {
    displayName: 'Zip Code',
    key: 'zipCode',
  },
  'consumer.phone_number': {
    displayName: 'Phone number',
    key: 'phone',
  },
};
