/**
 *
 * SegmentedControlButton
 *
 */

import { formatStringSeparator } from 'common/utils/formatText';
import React from 'react';

import * as S from './styles';

type Props = {
  selectedValue?: string;
  onClick: Function;
  control: Control;
};

export type Control = {
  label: string;
  value: string;
  eventTracking?: boolean;
};

function SegmentedControlButton(props: Props) {
  const { selectedValue, onClick, control } = props;
  const { value, label } = control;
  const isSelected = selectedValue === value;

  // TODO: removed role="tab" becaues it was throwing ts error - need to add that to the base button types
  return (
    <S.Wrapper
      appearance={isSelected ? 'primary' : 'secondary'}
      onClick={(e: any) => onClick(e)}
      aria-selected={isSelected ? 'true' : 'false'}
      role="tab"
      data-test={formatStringSeparator(`group by ${label.toLowerCase()}`)}
    >
      <>
        <S.GroupLabel>Group By</S.GroupLabel> {label}
      </>
    </S.Wrapper>
  );
}

export default SegmentedControlButton;
