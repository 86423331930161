/**
 * SectionHeader component
 */

import { SECTION_HEADER as DATA_TEST } from 'common/utils/constants/dataTest';
import { sanitize } from 'common/utils/domHelpers';
import React from 'react';

import * as S from './styles';

export type Props = {
  header?: string;
  description?: string;
  iconSrc?: string;
  alt?: string;
  backgroundSrc?: string;
};

function SectionHeader(props: Props) {
  const { iconSrc, alt, header, description, backgroundSrc } = props;
  const displayableSection = iconSrc || header || description;
  if (displayableSection) {
    return (
      <S.Wrapper backgroundSrc={backgroundSrc} hasIcon={!!iconSrc}>
        <S.ContentWrapper>
          {iconSrc && (
            <S.Icon
              src={iconSrc}
              aria-hidden="true"
              alt={alt}
              data-test={DATA_TEST.ICON}
            />
          )}
          {header && (
            <S.Header
              data-test={DATA_TEST.TITLE}
              dangerouslySetInnerHTML={{ __html: sanitize(header) }}
            />
          )}
          {description && (
            <S.Description
              data-test={DATA_TEST.DESCRIPTION}
              dangerouslySetInnerHTML={{ __html: sanitize(description) }}
            />
          )}
        </S.ContentWrapper>
      </S.Wrapper>
    );
  }
  return null;
}

export default SectionHeader;
