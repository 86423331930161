import { getUser } from 'common/apis/userApis';
import { formatPhoneNumber } from 'components/Forms/PhoneInput/utils';
import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { makeSelectUserId } from 'store/selectors';

import FormSummary from '../../../../FormSummary/FormSummary';
import FormSummarySkeleton from '../../../../FormSummary/FormSummarySkeleton';
import BasicInfoForm from '../../../BasicInfoForm';
import * as S from '../../AccountTabContent.styles';

/**
 * Content for the account tab
 */
export default function UserProfile() {
  const [showSummary, setShowSummary] = useState(true);
  const userId: string = useSelector(makeSelectUserId());

  const queryClient = useQueryClient();

  const { data: response, isLoading } = useQuery(
    ['user', userId],
    () => getUser(userId),
    {
      onSuccess: (response) => {
        if (response.data) {
          queryClient.setQueryData(['user', response.data.id], response.data);
        }
      },
    },
  );

  const user = response?.data;
  const email = user?.email ?? '';
  const phoneNumber = user?.phone_number ?? '';

  return isLoading ? (
    <FormSummarySkeleton />
  ) : (
    <S.Container>
      {showSummary ? (
        <>
          <S.Title>Profile</S.Title>
          <S.Card>
            <FormSummary
              topLine={`${user?.first_name} ${user?.last_name}`}
              secondaryLines={[`${email}`, `${formatPhoneNumber(phoneNumber)}`]}
              onEditClick={() => setShowSummary(false)}
            />
          </S.Card>
        </>
      ) : (
        user && (
          <>
            <S.Title>Profile</S.Title>
            <S.Card data-testid="update-info-modal">
              <BasicInfoForm
                user={user}
                setShowSummary={setShowSummary}
                hasCancelButton
              />
            </S.Card>
          </>
        )
      )}
    </S.Container>
  );
}
