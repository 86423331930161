import {
  TabMenu as LeavesTabMenu,
  size,
  mediaQueries,
  Tab as LeavesTab,
  typography,
  colors,
} from '@everlywell/leaves';
import styled, { css } from 'styled-components';

export const TabWrapper = styled.div`
  width: 100%;
  padding: ${size.xs1}px;
`;

export const TabMenu = styled(LeavesTabMenu)<{ isLoading: boolean }>`
  ul {
    gap: ${size.xs2}px;
    padding-left: ${size.sm}px;

    ${mediaQueries.forDesktopUp} {
      display: list-item;
      width: 280px;
      padding: ${size.xl3}px ${size.lg}px;
      margin: 0;
      gap: ${size.xs1}px;
    }

    li {
      height: ${size.xl2}px;
      padding: 0;
      border: none;
      width: ${({ isLoading }) => (isLoading ? '100%' : 'auto')};
      ${({ isLoading }) =>
        isLoading &&
        css`
          width: 100%;
          margin-left: ${size.xs1}px; ;
        `};

      &:hover,
      &:focus {
        background-color: ${colors.green2};
      }

      &:active,
      &[aria-current='true'] {
        border: 1px solid ${colors.green4};
        background-color: ${colors.green2};
      }

      :last-of-type {
        margin-right: ${size.md}px;
      }

      ${mediaQueries.forDesktopUp} {
        margin: ${size.xs1}px 0;

        :first-of-type {
          margin-top: 0;
        }

        :last-of-type {
          margin-right: 0;
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const Tab = styled(LeavesTab)`
  display: flex;
  align-items: center;
`;

export const TabName = styled.span`
  ${typography.buttonText};
  color: ${colors.green5};
`;

export const IconContainer = styled.span`
  width: ${size.lg}px;
  height: ${size.lg}px;
  margin-right: ${size.xs1}px;
`;
