import { Tab } from '@everlywell/leaves';
import { AppointmentSlot } from 'common/apis/telehealthSchedulingApis';
import Grid from 'components/Grid';
import React, {
  useState,
  useEffect,
  useMemo,
  InputHTMLAttributes,
} from 'react';

import * as S from './TimeSelector.styles';
import { parseAvailableSlots, getTimePeriod } from './utils';

const TABS = ['morning', 'afternoon', 'evening'];

export type TimeSelectorProps = {
  availableSlots: Array<AppointmentSlot>;
  selectedTime?: string | null;
  setSelectedTime: (time: string) => void;
  timeZone: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
};

/**
 * This component displays a list of available slots that the user can select filtered by periods.
 */

const TimeSelector = React.forwardRef<HTMLInputElement, TimeSelectorProps>(
  (
    {
      availableSlots,
      selectedTime = '',
      setSelectedTime,
      timeZone,
      inputProps = {},
    },
    ref,
  ) => {
    const parsedAvailableSlots = useMemo(
      () => parseAvailableSlots(availableSlots, timeZone),
      [availableSlots, timeZone],
    );

    const [activeIndex, setActiveIndex] = useState(0);

    const slotsByPeriod = parsedAvailableSlots.filter(
      (slot) => slot.period === TABS[activeIndex],
    );
    const isSameDay =
      new Date(selectedTime ?? '').getDate() ===
      new Date(parsedAvailableSlots[0]?.value).getDate();

    const handleSelect = (index: number) => {
      setActiveIndex(index);
    };

    const handleClick = (time: string) => {
      setSelectedTime(time);
    };

    useEffect(() => {
      const period =
        selectedTime && isSameDay
          ? getTimePeriod(selectedTime, timeZone)
          : parsedAvailableSlots[0]?.period;

      const index = TABS.indexOf(period);

      setActiveIndex(index === -1 ? 0 : index);
    }, [isSameDay, parsedAvailableSlots, selectedTime, timeZone]);

    return (
      <Grid.Container spacing={['lg']}>
        <Grid.Item width={[1]}>
          <S.TabMenu
            id="available-time-tab"
            ariaControls="available-time-slots"
            ariaLabel="Available Time"
            onItemSelect={handleSelect}
            fillAvailableSpace={true}
            initialActiveIndex={activeIndex}
            colorConfig={{
              containerBackground: 'transparent',
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab}>{tab}</Tab>
            ))}
          </S.TabMenu>
        </Grid.Item>
        <Grid.Item width={[1]}>
          {slotsByPeriod.length > 0 ? (
            <S.TimeSelectorRadioGroup id="available-time-slots">
              {slotsByPeriod.map((availableTime, index) => (
                <S.TimeButton
                  aria-pressed={availableTime.value === selectedTime}
                  data-notranslate="true"
                  key={`${index}-${availableTime.value}`}
                  onClick={() => handleClick(availableTime.value)}
                  type="button"
                  value={availableTime.value}
                >
                  {availableTime.formattedTime}
                </S.TimeButton>
              ))}
            </S.TimeSelectorRadioGroup>
          ) : (
            <S.BlankSlateMessage>
              No times available. Please select a different time of day.
            </S.BlankSlateMessage>
          )}
          <input {...inputProps} type="hidden" required ref={ref} />
        </Grid.Item>
      </Grid.Container>
    );
  },
);

export default TimeSelector;
