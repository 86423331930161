/**
 *
 * Skeleton test result title row and card list
 *
 */
import { colors, size } from '@everlywell/leaves';
import { SKELETON_WRAPPER } from 'common/utils/constants/dataTest';
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import SkeletonTestResultCard from './SkeletonTestResultCard';
import * as S from './styles';

function SkeletonTestResultsList() {
  return (
    <S.TestResultsWrapper data-test={`${SKELETON_WRAPPER.CONTAINER}`}>
      <SkeletonTheme baseColor="#ededed">
        <S.TestResultTitle>
          <S.Heading>
            <Skeleton height={`${size.lg}px`} />
          </S.Heading>
          <S.Number>
            <Skeleton height={`${size.lg}px`} />
          </S.Number>
        </S.TestResultTitle>
      </SkeletonTheme>
      <SkeletonTheme baseColor={colors.gray1} highlightColor={colors.white}>
        <S.TestResultCardList>
          <SkeletonTestResultCard />
          <SkeletonTestResultCard />
        </S.TestResultCardList>
      </SkeletonTheme>
    </S.TestResultsWrapper>
  );
}

export default SkeletonTestResultsList;
