import { RadioButton, mediaQueries, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  ${typography.buttonText}
`;

export const SubLabel = styled.div`
  ${typography.bodyTextSmall}
`;
export const DisabledText = styled.p`
  ${typography.disabledText}
  text-align: right;

  ${mediaQueries.forTabletVerticalUp} {
    width: 100%;
  }
`;

export const AppointmentTypeRadioButton = styled(RadioButton)`
  div {
    width: 100%;
  }
`;

export const AppointmentTypeDetailsWrapper = styled.div`
  ${LabelWrapper}
  flex-direction: column;
`;
