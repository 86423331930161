import { ImmutableReduxState } from 'common/utils/types';
import ResourceLinkCollection, {
  Props as ResourceLinkCollectionProps,
} from 'components/ResourceLinkCollection';
import React from 'react';
import { connect } from 'react-redux';
import { makeSelectContent } from 'store/selectors';

const CONTENT_KEYS = {
  names: 'TEST_RESOURCES_NAME_*',
  descriptions: 'TEST_RESOURCES_DESCRIPTION_*',
  links: 'TEST_RESOURCES_LINK_*',
  images: 'TEST_RESOURCES_IMAGE_*',
  logos: 'TEST_RESOURCES_LOGO_*',
};

type StateProps = {
  content: {
    names: string[];
    descriptions: string[];
    links: string[];
    images: string[];
    logos: string[];
  };
};

type OwnProps = {
  contentToken: string;
};

export function ResourceLinkCollectionContainer(
  props: ResourceLinkCollectionProps,
) {
  const hasContent = props.cards.length > 0;

  if (!hasContent) {
    return null;
  }

  return <ResourceLinkCollection {...props} />;
}

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): StateProps {
  return {
    content: makeSelectContent(ownProps.contentToken, CONTENT_KEYS)(state),
  };
}

export function mergeProps(
  stateProps: StateProps,
): ResourceLinkCollectionProps {
  const { content } = stateProps;
  const { names, descriptions, links, images, logos } = content;
  const cards = names.map((c, index) => ({
    name: names[index],
    description: descriptions[index],
    link: links[index],
    image: images[index],
    logo: logos[index],
    number: index + 1,
  }));

  return {
    cards,
  };
}

export default connect(
  mapStateToProps,
  null,
  mergeProps,
)(ResourceLinkCollectionContainer);
