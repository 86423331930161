import { Button, Localize } from '@everlywell/leaves';
import { AppointmentSlot } from 'common/apis/telehealthSchedulingApis';
import { useCommonT2TAnalytics } from 'common/hooks/useCommonT2TAnalytics';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { stateAbbreviationToNames } from 'common/utils/constants/states';
import Grid from 'components/Grid';
import LoadingError from 'components/LoadingError';
import { format, addMonths } from 'date-fns';
import { format as tzFormat } from 'date-fns-tz';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import FloatingCtaFooter from '../../components/FloatingCtaFooter';
import { useNativeSchedulingContext } from '../../context/NativeSchedulingContext';
import { actions } from '../../state/scheduling-flow.state';
import * as track from './AppointmentTimePage.analytics';
import {
  AppointmentTimePageSkeleton,
  DaySelectorSkeleton,
  StateNameSkeleton,
  TimeSelectorSkeleton,
} from './AppointmentTimePage.skeleton';
import * as S from './AppointmentTimePage.styles';
import DaySelector from './components/DaySelector';
import MonthSelector from './components/MonthSelector/MonthSelector';
import TimeSelector from './components/TimeSelector';
import useAppointmentTimes from './hooks/useAppointmentTimes';

export type AppointmentTimePageProps = {};
type FormValues = {
  time: string | null;
  noAvailableDays?: string | null;
};

function AppointmentTimePage() {
  const { nativeAppointmentScheduling } = useFlags();
  const { commonT2TAnalyticsData } = useCommonT2TAnalytics();

  const { state, dispatch, getNextStep } = useNativeSchedulingContext();
  const { selectedProvider, selectedProgram, selectedAppointment, selectedAppointmentTypeId } = state;

  const selectedTime = selectedAppointment.slot?.datetime;
  const initialDay = selectedTime ? new Date(selectedTime) : null;
  const initialMonth = selectedTime ? new Date(selectedTime) : new Date();

  const today = new Date();

  const [selectedMonth, setSelectedMonth] = useState(initialMonth.getMonth()); // 0, 1, 2 - month of year
  const [selectedYear, selectYear] = useState(initialMonth.getFullYear()); // 2022, 2023, 2024 - year
  const firstDateOfMonth = new Date(selectedYear, selectedMonth, 1);

  const [selectedDay, setSelectedDay] = useState<Date | null>(initialDay); // 0, 1, 2 - day of month

  const analyticsParams = useMemo(
    () =>
      isEmpty(commonT2TAnalyticsData)
        ? { program: selectedProgram }
        : { ...commonT2TAnalyticsData },
    [commonT2TAnalyticsData, selectedProgram],
  );
  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm<FormValues>({
    defaultValues: {
      time: selectedTime, // we'll hold the time selection in the form state
    },
  });

  const {
    availableTimes,
    availableDays,
    availableAppointmentSlots,
    appointmentType,
    selectedState,
    isLoading,
    isError,
    refetch,
    prefetchSpecifiedMonth,
  } = useAppointmentTimes({
    month: selectedMonth,
    year: selectedYear,
    selectedProgram,
    selectedProvider,
    selectedAppointmentTypeId
  });

  const firstAvailableTime: string | undefined = availableTimes[0];
  const firstAvailableDay: string | undefined = Object.keys(availableDays)[0];

  let availableSlotsForSelectedDay: AppointmentSlot[] = [];
  if (selectedDay) {
    availableSlotsForSelectedDay =
      availableDays[selectedDay?.getDate() ?? firstAvailableDay];
  } else if (firstAvailableDay) {
    availableSlotsForSelectedDay = availableDays[firstAvailableDay];
  }

  useEffect(() => {
    if (firstAvailableTime && !selectedTime) {
      setSelectedDay(new Date(firstAvailableTime));
      setValue('time', firstAvailableTime);
    }
  }, [firstAvailableTime, selectedTime, setValue]);

  const navigate = useNavigate();

  useEffect(() => {
    if (nativeAppointmentScheduling) {
      analytics.track({
        event: ANALYTICS.EVENTS.VIEWED_PAGE,
        data: {
          page: ANALYTICS.PAGES.APPOINTMENT_TIME,
          ...analyticsParams,
        },
      });
    }
  }, [analyticsParams, nativeAppointmentScheduling]);

  const handleSelectMonth = (direction: 1 | -1) => {
    const newDate = addMonths(
      new Date(selectedYear, selectedMonth, 1),
      direction,
    );
    const newMonth = newDate.getMonth();
    const newYear = newDate.getFullYear();

    setSelectedMonth(newMonth);
    selectYear(newYear);
  };

  const handleHoverMonth = (direction: 1 | -1) => {
    const newDate = addMonths(
      new Date(selectedYear, selectedMonth, 1),
      direction,
    );
    const newMonth = newDate.getMonth();
    const newYear = newDate.getFullYear();

    prefetchSpecifiedMonth(newMonth, newYear);
  };

  const handleSaveInput = (formValues: FormValues) => {
    if (isEmpty(availableDays)) {
      setError('noAvailableDays', {
        type: 'custom',
        message: 'There are no available dates at the moment.',
      });

      return;
    }

    clearErrors();

    if (!formValues.time || !appointmentType) return;

    const slot = availableAppointmentSlots[formValues.time];
    dispatch(actions.setSelectedAppointment(slot, appointmentType));

    const nextStep = getNextStep();
    track.whenUserClicksContinue({ program: selectedProgram });
    track.whenUserClicksContinue(analyticsParams);

    navigate(nextStep ?? '/dashboard');
  };

  if (isError) {
    return (
      <S.Container>
        <LoadingError handleRetry={refetch} />
      </S.Container>
    );
  }

  if (!nativeAppointmentScheduling) {
    return <AppointmentTimePageSkeleton />;
  }

  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let shortTimezone = tzFormat(today, 'z', { timeZone: timezone });

  return (
    <S.Container>
      <Grid.Container spacing={['lg']}>
        <Grid.Item width={[1]}>
          <S.Header>Select date and time</S.Header>
          <S.BodyCopy>
            Shown in <Localize name="short-timezone">{shortTimezone}</Localize>{' '}
            for appointments in{' '}
            <span>
              {!selectedState ? (
                <StateNameSkeleton />
              ) : (
                stateAbbreviationToNames[selectedState]
              )}
            </span>
          </S.BodyCopy>
        </Grid.Item>
        <Grid.Item width={[1]}>
          <form onSubmit={handleSubmit(handleSaveInput)}>
            <Grid.Container spacing={['lg']}>
              <S.StyledGridItem width={[1]}>
                <MonthSelector
                  month={selectedMonth}
                  year={selectedYear}
                  onNext={() => handleSelectMonth(1)}
                  onPrev={() => handleSelectMonth(-1)}
                  onNextHover={() => handleHoverMonth(1)}
                  onPrevHover={() => handleHoverMonth(-1)}
                  min={today}
                />
                <S.DateCopy>
                  Available dates in{' '}
                  <span data-isolate>{format(firstDateOfMonth, 'MMMM')}</span>
                </S.DateCopy>
              </S.StyledGridItem>

              {isLoading && (
                <Grid.Item width={[1]}>
                  <DaySelectorSkeleton />
                  <TimeSelectorSkeleton />
                </Grid.Item>
              )}

              {!isLoading && isEmpty(availableTimes) && (
                <Grid.Item width={[1]} style={{ textAlign: 'center' }}>
                  <p>The provider does not have any times available.</p>
                  {errors.noAvailableDays && (
                    <S.ErrorText>{errors.noAvailableDays?.message}</S.ErrorText>
                  )}
                </Grid.Item>
              )}

              {!isEmpty(availableTimes) && (
                <>
                  <Grid.Item width={[1]}>
                    <DaySelector
                      days={Object.values(availableDays).map(
                        (slots) => new Date(slots[0].datetime),
                      )}
                      selectedDay={selectedDay}
                      onSelectedDay={setSelectedDay}
                    />
                  </Grid.Item>
                  <Grid.Item width={[1]}>
                    <TimeSelector
                      ref={register({
                        required: 'Please select an appointment time.',
                      })}
                      availableSlots={availableSlotsForSelectedDay}
                      timeZone={timezone}
                      selectedTime={watch('time')}
                      setSelectedTime={(time) => {
                        setValue('time', time);
                      }}
                      inputProps={{
                        name: 'time',
                      }}
                    />
                  </Grid.Item>
                </>
              )}
              {errors.time && (
                <Grid.Item width={[1]}>
                  <S.ErrorText>{errors.time?.message}</S.ErrorText>
                </Grid.Item>
              )}
            </Grid.Container>
            <FloatingCtaFooter
              backButton={
                <Button
                  type="button"
                  appearance="secondary"
                  onClick={() => {
                    track.whenUserClicksBack(analyticsParams);
                    navigate(-1);
                  }}
                >
                  Back
                </Button>
              }
              nextButton={<Button type="submit">Continue</Button>}
            />
          </form>
        </Grid.Item>
      </Grid.Container>
    </S.Container>
  );
}

export default AppointmentTimePage;
