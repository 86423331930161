import {
  getTelehealthAnswerGroups,
  getTelehealthForm,
  saveTelehealthForm,
  TelehealthFormAnswer,
} from 'common/apis/telehealthApis';
import useProgramSlug from 'common/hooks/useProgramSlug';
import useUser from 'common/hooks/useUser';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { HEALTHIE_WHITE_LABEL_SSO_LINK } from 'common/utils/constants/urls';
import Layout from 'components/Layout';
import FormBuilder from 'components/telehealth/FormBuilder';
import FormBuilderSkeleton from 'components/telehealth/FormBuilder/FormBuilder.skeleton';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ErrorPage from '../ErrorPage';

export type MedicalIntakePageProps = {};

const FORM_SLUG = 'medical-intake';

/**
 * Medical Intake Page
 */
function MedicalIntakePage(props: MedicalIntakePageProps) {
  const { programsWithoutInsurance, telehealthMedicalIntakeAllowedPrograms } =
    useFlags<{
      programsWithoutInsurance: string[];
      telehealthMedicalIntakeAllowedPrograms: string[];
    }>();
  const { user, isLoading: isLoadingUser } = useUser();

  const { programSlug } = useProgramSlug();
  const program = programSlug ?? '';

  const navigate = useNavigate();

  const isMedicalIntakeEnabled =
    telehealthMedicalIntakeAllowedPrograms.includes(program);

  /**
   * Determine which screen should be displayed next
   */
  const goToNextScreen = useCallback(() => {
    // JSON array with program slugs, e.g: ['weight-management']
    // Skip insurance if feature flag includes the current program slug or the user is in Everlywell+
    const skipInsurance = programsWithoutInsurance.includes(program);

    if (skipInsurance) {
      window.open(HEALTHIE_WHITE_LABEL_SSO_LINK, '_self');
    } else {
      navigate(`/virtual-care/payment-type`);
    }
  }, [navigate, program, programsWithoutInsurance]);

  /**
   * Redirect to the next screen if this form is not enabled for this program
   */
  useEffect(() => {
    if (!isMedicalIntakeEnabled) {
      goToNextScreen();
    }
  }, [goToNextScreen, isMedicalIntakeEnabled]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'medicalIntakePage',
        programName: program,
      },
    });
  }, [program]);

  const enrolledProgram = user?.enrolled_programs.find(
    (enrolledProgram) => enrolledProgram.slug === program,
  );
  const isProgramOnDemand = enrolledProgram?.on_demand;

  /**
   * Load existing form answer data
   */
  const {
    data: formAnswerGroupsResponse,
    isSuccess: didFetchAnswerGroups,
    isError: isErrorAnswersGroup,
    isLoading: isLoadingAnswersGroup,
  } = useQuery(
    ['telehealth-form-answers-groups', { form_id: FORM_SLUG, program }],
    () => getTelehealthAnswerGroups({ form_id: FORM_SLUG }, { program }),
    {
      refetchOnWindowFocus: false,
      enabled: isMedicalIntakeEnabled && Boolean(user) && !!program,
      onSuccess: ({ data }) => {
        /**
         * Force display the form if the program is on demand
         * so that filling out the form will trigger the
         * flow on Healthie backend
         */
        if (isProgramOnDemand) {
          return;
        }
        /**
         * Redirect to the next screen if the form was already completed
         */
        if (data?.[0]?.finished) {
          goToNextScreen();
        }
      },
    },
  );

  const shouldFetchTelehealthForm =
    isMedicalIntakeEnabled &&
    didFetchAnswerGroups &&
    (isProgramOnDemand || !formAnswerGroupsResponse?.data?.[0]?.finished);

  /**
   * The form is only fetched if there are not answers for the form
   */
  const {
    data: response,
    isLoading: isLoadingTelehealthForm,
    isError: isErrorTelehelthForm,
  } = useQuery(
    ['telehealth-form', FORM_SLUG, { program }],
    () => getTelehealthForm(FORM_SLUG, { program }),
    {
      enabled: shouldFetchTelehealthForm && !!program,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        analytics.track({
          event: ANALYTICS.EVENTS.VIEWED_PAGE,
          data: {
            page: ANALYTICS.PAGES.ACCOUNT_HUB.MEDICAL_INTAKE,
            program,
          },
        });
      },
    },
  );

  /**
   * Handle saving the form data
   * and redirecting to the payment type page
   * if successful.
   */
  const {
    mutate: saveForm,
    isLoading: isSubmitting,
    isError: isErrorSubmitting,
  } = useMutation(saveTelehealthForm, {
    onSuccess: async () => {
      await analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: ANALYTICS.LABELS.VIRTUAL_CARE_FORMS.MEDICAL_INTAKE_CONTINUE,
          program,
        },
      });
      goToNextScreen();
    },
  });

  const onSubmit = (answers: TelehealthFormAnswer[]) => {
    saveForm({ form_id: FORM_SLUG, answers, program });
  };

  const isError = isErrorAnswersGroup || isErrorTelehelthForm;

  if (isError) {
    // TODO: replace with new error page
    return <ErrorPage />;
  }

  const isLoading =
    isLoadingUser || isLoadingAnswersGroup || isLoadingTelehealthForm;

  if (isLoading) {
    return (
      <Layout>
        <FormBuilderSkeleton />
      </Layout>
    );
  }

  return (
    <Layout>
      {response?.data ? (
        <FormBuilder
          variant="secondary"
          {...response.data}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          error={
            isErrorSubmitting
              ? { message: 'Unable to submit form. Please try again.' }
              : null
          }
        />
      ) : null}
    </Layout>
  );
}

export default MedicalIntakePage;
