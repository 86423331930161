import { getRequest } from 'common/hooks/useApi/request';
import { API_ROOT } from 'common/utils/constants';

// Types from the backend. Please add more as types increase.
// https://gitlab.com/everlywell/apps/store/-/blob/main/app/interactors/users/tasks/generate_weight_management_task_list.rb?ref_type=heads#L8
export enum TaskTypes {
  REDEEM_CREDIT = 'RedeemCreditTask',
  REDEEM_WEIGHT_MANAGEMENT_CREDIT = 'RedeemWeightManagementCreditTask',
  SCHEDULE_WEIGHT_MANAGEMENT_APPOINTMENT = 'ScheduleFirstWeightManagementAppointmentTask',
}

export type CreditRedemptionTask = {
  type: TaskTypes.REDEEM_CREDIT;
  properties: {
    title: string;
    cta_url: string;
    cta_label: string;
    image_url: string;
    expiration_date: string;
    total_count: number;
  };
};

export type WeightManagementCreditRedemptionTask = {
  type: TaskTypes.REDEEM_WEIGHT_MANAGEMENT_CREDIT;
  properties: {
    title: string;
    cta_url: string;
    cta_label: string;
    image_url: string;
    expiration_date: string;
  };
};

type ScheduleWeightManagementAppointment = {
  type: TaskTypes.SCHEDULE_WEIGHT_MANAGEMENT_APPOINTMENT;
  properties: {
    title: string;
    cta_url: string;
    cta_label: string;
    image_url: string;
  };
};

export type UserTaskChecklistData =
  | CreditRedemptionTask
  | WeightManagementCreditRedemptionTask
  | ScheduleWeightManagementAppointment;

export const getTaskChecklist = async () => {
  const response = await getRequest<UserTaskChecklistData[]>(
    `${API_ROOT}/v3/tasks`,
    false,
    {
      rejectOnError: false,
    },
  );

  return response;
};

export function isCreditRedemptionTask(task: UserTaskChecklistData) {
  switch (task.type) {
    case TaskTypes.REDEEM_CREDIT:
    case TaskTypes.REDEEM_WEIGHT_MANAGEMENT_CREDIT:
      return true;

    default:
      return false;
  }
}
