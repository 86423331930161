import { TAGS } from '../constants';

export function removeTag(label: string | null): string {
  let labelToReturn = label || '';
  for (const tag of Object.values(TAGS)) {
    labelToReturn = label?.includes(tag) ? label.split(tag)[1] : labelToReturn;
  }
  return labelToReturn;
}
/**
 * containsInvalidOptionTag - Check if the option contains invalid tag
 * then it returns an empty string to prevent default error value.
 *
 * if not it returns undefined so react-hook-form get as a correct value.
 */
export function containsInvalidOptionTag(val: string): string | undefined {
  if (val?.includes(TAGS.INVALID_OPTION)) {
    return '';
  }
  return;
}
