import { Accordion } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { sanitize } from 'common/utils/domHelpers';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import React, { SyntheticEvent, useState } from 'react';

import questionMark from './images/brand-mark.svg';
import infoIcon from './images/info-icon.svg';
import * as S from './styles';

export type TooltipAccordionProps = {
  preview: string;
  description: string;
};

const TooltipAccordion = (props: TooltipAccordionProps) => {
  const { preview, description } = props;
  const [isMobileOpened, setIsMobileOpened] = useState(false);
  const [isDesktopOpened, setIsDesktopOpened] = useState(false);

  const analyticsClickedEvent = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.FAQ_TOOLTIP_3_BUTTON,
        category: ANALYTICS.CATEGORIES.FAQ_TOOLTIPS,
      },
    });
  };
  const handleDesktopClick = (e: SyntheticEvent<HTMLElement, Event>) => {
    setIsDesktopOpened(!isDesktopOpened);
    analyticsClickedEvent();
  };

  const handleMobileClick = (e: SyntheticEvent<HTMLElement, Event>) => {
    setIsMobileOpened(!isMobileOpened);
    analyticsClickedEvent();
  };

  const contentEl = useScrolledIntoViewOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: ANALYTICS.LABELS.FAQ_TOOLTIP_3_BUTTON,
        category: ANALYTICS.CATEGORIES.FAQ_TOOLTIPS,
      },
    });
  });

  return (
    <S.Wrapper ref={contentEl}>
      <S.StyledMobileHeading>
        <img src={infoIcon} alt="Info Icon" data-test="tooltip-info-icon" />
        {preview}
      </S.StyledMobileHeading>
      <Accordion.Wrapper>
        <Accordion.Item>
          <S.StyledDesktopHeading
            onClick={handleDesktopClick}
            isOpened={isDesktopOpened}
          >
            <img src={infoIcon} alt="" />
            <S.StyledText>{preview}</S.StyledText>
          </S.StyledDesktopHeading>
          <S.StyledDescriptionChild>
            <img src={questionMark} alt="" />
            <S.ChildText
              dangerouslySetInnerHTML={{ __html: sanitize(description) }}
            />
          </S.StyledDescriptionChild>
          <S.StyledFooterHeading onClick={handleMobileClick}>
            <S.CenteredText>
              See {isMobileOpened ? 'less' : 'more'} information
            </S.CenteredText>
          </S.StyledFooterHeading>
        </Accordion.Item>
      </Accordion.Wrapper>
    </S.Wrapper>
  );
};

export default TooltipAccordion;
