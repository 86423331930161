import Layout from 'components/Layout';
import React from 'react';
import Lottie from 'react-lottie';

import * as animationData from './loader-animation.json';
import * as S from './Loader.styles';

export const DATA_TEST_LOADER_ID = 'loader';

function Loader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Layout>
      <span data-test={DATA_TEST_LOADER_ID}>
        <S.Container>
          {/* @ts-ignore */}
          <Lottie ariaLabel="Loading" options={defaultOptions} width={120} />
        </S.Container>
      </span>
    </Layout>
  );
}

export default Loader;
