import useProgramSlug from 'common/hooks/useProgramSlug';
import { PROGRAM_TYPE } from 'common/utils/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNativeIntakeContext } from 'pages/VirtualCare/NativeIntake/context/NativeIntakeContext';
import { getRelativePath } from 'pages/VirtualCare/NativeIntake/utils/helpers';
import { NATIVE_SCHEDULING_PATH } from 'pages/VirtualCare/NativeScheduling';
import { useNavigate } from 'react-router-dom';

// If the program is weight-management-full, they have already paid in a previous step

type RedirectAfterInsuranceTypes = {
  navigateToNextPage: Function;
  navigationPath: string;
};
export const useRedirectAfterInsurance = (): RedirectAfterInsuranceTypes => {
  const navigate = useNavigate();
  const { programSlug } = useProgramSlug();

  const { showDynamicIntakeFlow } = useFlags<{
    showDynamicIntakeFlow: boolean;
  }>();
  const { goToNextStep: contextGoToNextStep, nextStep } =
    useNativeIntakeContext();

  const isWCPlus = programSlug === PROGRAM_TYPE['weight-management-full'];

  if (showDynamicIntakeFlow && !isWCPlus) {
    return {
      navigateToNextPage: () => contextGoToNextStep(),
      navigationPath: getRelativePath(nextStep),
    };
  }

  if (showDynamicIntakeFlow && isWCPlus) {
    return {
      navigateToNextPage: () => navigate(NATIVE_SCHEDULING_PATH),
      navigationPath: NATIVE_SCHEDULING_PATH,
    };
  }

  const navigationPath: string = isWCPlus
    ? NATIVE_SCHEDULING_PATH
    : '/virtual-care/payment';

  return {
    navigateToNextPage: () => navigate(navigationPath),
    navigationPath,
  };
};
