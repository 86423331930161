import { throttle } from 'lodash';
import { useState, useEffect } from 'react';

function useDocumentScrollThrottled(
  callback: (props: { scrollTop: number; prevScrollTop: number }) => void,
  delay: number,
) {
  const [, setScrollPosition] = useState(0);
  let prevScrollTop = 0;

  function handleScroll() {
    const el = document.scrollingElement || document.documentElement;
    const { scrollTop } = el;

    setScrollPosition((prevScrollPosition: number) => {
      prevScrollTop = prevScrollPosition;
      return scrollTop;
    });

    return callback({ scrollTop, prevScrollTop });
  }

  const handleScrollThrottled = throttle(handleScroll, delay);

  useEffect(() => {
    window.addEventListener('scroll', handleScrollThrottled);

    return () => window.removeEventListener('scroll', handleScrollThrottled);
  }, []);
}

export { useDocumentScrollThrottled };
