import { colors, typography } from '@everlywell/leaves';
import styled from 'styled-components';

import { sharedStyles } from '../../NativeScheduling.styles';

export const Container = styled.div`
  display: flex;
  ${sharedStyles.containerStyles};
`;

export const Heading = styled.h1`
  ${typography.h3Text}
  margin: 0;
`;

export const SubHeading = styled.p`
  ${typography.bodyText}
  font-weight: ${typography.weight.light};
  margin: 0;
  color: ${colors.gray4};
`;

export const UserState = styled.span`
  color: ${colors.green4};
  font-weight: ${typography.weight.bold};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const ErrorText = styled.div`
  ${typography.errorText}
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;
