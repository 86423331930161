import {
  getUserMedications,
  TelehealthMedication,
} from 'common/apis/telehealthApis';
import { Response } from 'common/hooks/useApi/request';
import { logError } from 'common/utils/helpers';
import { useQuery, UseQueryOptions } from 'react-query';

type QueryOptions<T> = Omit<
  UseQueryOptions<T, Error, T, any[]>,
  'queryKey' | 'queryFn'
>;

export type UseMedicationProps = QueryOptions<Response<TelehealthMedication[]>>;

/**
 * Fetch user medications
 */

export default function useMedications({
  enabled,
  ...rest
}: UseMedicationProps = {}) {
  const data = useQuery(['telehealth-medications'], getUserMedications, {
    ...rest,
    onError: (error) => {
      logError((error as Error).message, {
        errorInfo: 'Error fetching user medications',
        component: 'useMedications',
        method: 'getUserMedications',
      });
    },
  });

  return {
    ...data,
    medications: data.data?.data,
  };
}
