/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';

export const INVALIDATE_AUTH_REDIRECT_REQUEST =
  'INVALIDATE_AUTH_REDIRECT_REQUEST';

export const LOADING_ON = 'LOADING_ON';
export const LOADING_OFF = 'LOADING_OFF';

export const SET_NOTIFICAION = 'SET_NOTIFICAION';
export const CLEAR_NOTIFICAION = 'CLEAR_NOTIFICAION';

export const HTTP_AUTHENTICATION_SUCCESS = 'HTTP_AUTHENTICATION_SUCCESS';
export const HTTP_AUTHENTICATION_REQUEST = 'HTTP_AUTHENTICATION_REQUEST';
export const HTTP_AUTHENTICATION_FAILURE = 'HTTP_AUTHENTICATION_FAILURE';

export const HTTP_GET_KITS_REQUEST = 'HTTP_GET_KITS_REQUEST';
export const HTTP_GET_KITS_SUCCESS = 'HTTP_GET_KITS_SUCCESS';

export const HTTP_GET_USER_DATA_REQUEST = 'HTTP_GET_USER_DATA_REQUEST';
export const HTTP_GET_USER_DATA_SUCCESS = 'HTTP_GET_USER_DATA_SUCCESS';

export const HTTP_GET_KIT_RESULT_REQUEST = 'HTTP_GET_KIT_RESULT_REQUEST';
export const HTTP_GET_KIT_RESULT_SUCCESS = 'HTTP_GET_KIT_RESULT_SUCCESS';
export const HTTP_GET_KIT_RESULT_FAILURE = 'HTTP_GET_KIT_RESULT_FAILURE';

export const HTTP_GET_KIT_RESULT_CONTENT_REQUEST =
  'HTTP_GET_KIT_RESULT_CONTENT_REQUEST';
export const HTTP_GET_KIT_RESULT_CONTENT_SUCCESS =
  'HTTP_GET_KIT_RESULT_CONTENT_SUCCESS';
export const HTTP_GET_KIT_RESULT_CONTENT_FAILURE =
  'HTTP_GET_KIT_RESULT_CONTENT_FAILURE';

export const HTTP_SET_KIT_RESULT_SHARING_ON_REQUEST =
  'HTTP_SET_KIT_RESULT_SHARING_ON_REQUEST';
export const HTTP_SET_KIT_RESULT_SHARING_ON_SUCCESS =
  'HTTP_SET_KIT_RESULT_SHARING_ON_SUCCESS';

export const HTTP_SET_KIT_RESULT_SHARING_OFF_REQUEST =
  'HTTP_SET_KIT_RESULT_SHARING_OFF_REQUEST';
export const HTTP_SET_KIT_RESULT_SHARING_OFF_SUCCESS =
  'HTTP_SET_KIT_RESULT_SHARING_OFF_SUCCESS';
