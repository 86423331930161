import { Notification as NotificationType } from 'common/utils/types';
import ToastNotification from 'components/ToastNotification';
import React, { createContext, useContext, useEffect, useState } from 'react';

interface NotificationContextType {
  notificationData?: NotificationType;
  setNotificationData: Function;
}

/** Match the backend status type responses */
export enum PaymentServerErrors {
  InvaildPaymentInformationError = 'InvaildPaymentInformationError',
  PaymentProcessingError = 'PaymentProcessingError',
  CardError = 'card_error'
}
export type ClientError = {
  code?: number;
  message: string
  status: string
  type: PaymentServerErrors
}

export const CLEAR_NOTIFICATION_DELAY = 5000;

export const NotificationContext = createContext<NotificationContextType>({
  setNotificationData: () => { },
  notificationData: undefined,
});
export const useNotificationContext = () => useContext(NotificationContext)

type Props = {
  children: React.ReactNode;
};

export const NotificationContextContainer = (props: Props): JSX.Element => {
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationData, setNotificationData] = useState<NotificationType>();
  const [viewedNotifications, setViewedNotifications] = useState<{
    [id: string]: boolean;
  }>({});
  const clearNotification = () => {
    setNotificationData(undefined);
    setShowNotification(false);
  };

  useEffect(() => {
    const id = notificationData?.id;
    if (id && !viewedNotifications[id]) {
      setShowNotification(true);
      if (notificationData?.onlyShowOnce) {
        setViewedNotifications({ ...viewedNotifications, [id]: true });
      }
      setTimeout(() => {
        clearNotification();
      }, CLEAR_NOTIFICATION_DELAY);
    }
  }, [notificationData]);

  return (
    <NotificationContext.Provider value={{ setNotificationData }}>
      {showNotification && (
        <ToastNotification
          notification={notificationData}
          clearNotification={clearNotification}
        />
      )}
      {props.children}
    </NotificationContext.Provider>
  );
};
