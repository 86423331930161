import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { sanitize } from 'common/utils/domHelpers';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import React from 'react';

import {
  Body,
  Container,
  Heading,
  InfoContainer,
  Image,
} from './Callout.styles';
import illustration from './illustration.png';

export type CalloutProps = {
  title?: string;
  description: string;
  dataTestId?: string;
  productName?: string;
  analyticsData?: { label: string; category: string };
};

/**
 * This component is used to "call out" and explain parts of the application to the user for better context.
 *
 * For the `description` parameter, users can pass in HTML strings which will be sanitized before render.
 */
const Callout = ({
  title,
  description,
  analyticsData,
  dataTestId,
  productName,
}: CalloutProps) => {
  const contentEl = useScrolledIntoViewOnce(() => {
    analyticsData &&
      analytics.track({
        event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
        data: { ...analyticsData, testName: productName },
      });
  });

  const trackClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    const mixpanelTrackingLabel = target.getAttribute('data-mixpanel-label');
    if (mixpanelTrackingLabel) {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_LINK,
        data: {
          label: mixpanelTrackingLabel,
          category: analyticsData?.category,
          id: analyticsData?.label,
          testName: productName,
        },
      });
    }
  };

  return (
    <Container data-testid={dataTestId || ''} ref={contentEl}>
      <InfoContainer>
        {title && <Heading>{title}</Heading>}
        <Body
          dangerouslySetInnerHTML={{ __html: sanitize(description) }}
          onClick={trackClick}
        ></Body>
      </InfoContainer>
      <Image src={illustration} alt="" data-test="callout-illustration" />
    </Container>
  );
};

export default Callout;
