import { Accordion } from '@everlywell/leaves';
import { AccordionSection as AccordionSectionType } from 'common/utils/types';
import CommonQuestionsItem from 'components/CommonQuestionsItem';
import React from 'react';

import * as S from './styles';

type Props = {
  sections: AccordionSectionType[];
};

function CommonQuestionsItems(props: Props) {
  const { sections } = props as Props;

  return (
    <Accordion.Wrapper>
      {sections &&
        sections.map((values, index: number) => (
          <CommonQuestionsItem {...values} key={index.toString()} />
        ))}
      <S.MenuDivision />
    </Accordion.Wrapper>
  );
}

export default CommonQuestionsItems;
