/**
 *
 * NumericalResultContainerNew
 *
 */

import {
  KitResult,
  ImmutableReduxState,
  GroupedMarkers,
} from 'common/utils/types';
import NumericalResultDetail from 'components/NumericalResultDetail';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { makeGroupedMarkersKitResult } from 'store/kitResult/NumericalResultDetailContainer/selectors';

export interface StateProps {
  groupedMarkers: GroupedMarkers;
  viewingSharedKitResult?: boolean;
  kitResult: KitResult;
}

interface OwnProps {
  kitResult: KitResult;
  viewingSharedKitResult?: boolean;
}

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): StateProps {
  const { kitResult, viewingSharedKitResult } = ownProps;
  const groupedMarkers = makeGroupedMarkersKitResult(kitResult);

  return { groupedMarkers, viewingSharedKitResult, kitResult };
}

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(NumericalResultDetail);
