/**
 *
 * FoodSensitivityMarkerGroupCard
 *
 */

import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { FOOD_SENSITIVITY_RESULT } from 'common/utils/constants/dataTest';
import { formatStringSeparator } from 'common/utils/formatText';
import { Marker, MarkerResult, Test } from 'common/utils/types';
import FoodSensitivityMarkerCardContainer from 'containers/FoodSensitivityMarkerCardContainer';
import { isEqual } from 'lodash';
import React from 'react';

import ReactivityBadge from '../ReactivityBadge';
import * as S from './styles';

export type Props = {
  markers: Marker[];
  markerResults: MarkerResult[];
  groupedByFood: boolean;
  groupTitle?: string;
  showNormalReactivity: Function;
  normalReactivityIsExpanded: boolean;
  test: Test;
};

type State = {
  selectedMarker: number;
  isScrollable: boolean;
  lastElementRef: React.RefObject<HTMLDivElement> | null;
  cardHeight: number;
};

/* eslint-disable react/prefer-stateless-function */
class FoodSensitivityMarkerGroupCard extends React.PureComponent<Props, State> {
  markerGroupCardsWrapperRef: React.RefObject<HTMLDivElement> | null;

  constructor(props: Props) {
    super(props);
    const defaultMarker =
      props.markerResults &&
      props.markerResults.length &&
      props.markerResults[0].id;

    this.state = {
      selectedMarker: defaultMarker || 0,
      isScrollable: false,
      lastElementRef: null,
      cardHeight: 0,
    };

    this.markerGroupCardsWrapperRef = React.createRef();
  }

  UNSAFE_componentWillUpdate(nextProps: Props, nextState: State) {
    const shouldUpdate = !isEqual(nextProps, this.props);
    if (shouldUpdate) {
      const newSelectedMarker =
        nextProps.markerResults &&
        nextProps.markerResults.length &&
        nextProps.markerResults[0].id;

      this.handleMarkerChange(newSelectedMarker);
    }

    this.setScrollState(nextState);
  }

  setLastElementRef = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref) this.setState({ lastElementRef: ref });
  };

  setScrollState = (state: State) => {
    if (
      state.lastElementRef &&
      state.lastElementRef.current &&
      this.markerGroupCardsWrapperRef &&
      this.markerGroupCardsWrapperRef.current
    ) {
      const containerTop = this.markerGroupCardsWrapperRef.current.scrollTop;
      const containerBottom =
        containerTop + this.markerGroupCardsWrapperRef.current.clientHeight;

      const lastElementTop = state.lastElementRef.current.offsetTop;
      const lastElementBottom =
        lastElementTop + state.lastElementRef.current.clientHeight;

      const isLastElementHidden = lastElementBottom > containerBottom;

      this.setState({
        isScrollable: isLastElementHidden,
      });
    }
  };

  handleScroll = () => {
    this.setScrollState(this.state);
  };

  handleMarkerChange = (newMarker: number) => {
    this.setState({
      selectedMarker: newMarker,
    });
  };

  handleShowResultsBtnClick = () => {
    // track button click event
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'View normal reactivity foods',
        category: ANALYTICS.CATEGORIES.FS_MARKER_GROUP,
      },
    });

    this.props.showNormalReactivity();
  };

  updateCardHeight = (height: number) => {
    this.setState({ cardHeight: height }, () => {
      // allow transition to complete before calculating scroll height
      setTimeout(this.handleScroll, 300);
    });
  };

  showScrollContainer = () => {
    const { groupTitle, normalReactivityIsExpanded } = this.props;

    return (
      groupTitle !== 'normal reactivity' ||
      (groupTitle === 'normal reactivity' && normalReactivityIsExpanded)
    );
  };

  renderFoodSensitivityMarkerCard = (
    markerResult: MarkerResult,
    index: number,
  ) => {
    const { groupedByFood, markerResults, markers } = this.props;

    const matchingMarker: Marker | undefined = markers.find(
      (marker: Marker) => marker.id === markerResult.marker_id,
    );

    if (!matchingMarker) return null;

    return (
      <FoodSensitivityMarkerCardContainer
        isLastElement={index === markerResults.length - 1}
        setLastElementRef={this.setLastElementRef}
        key={markerResult.id}
        marker={matchingMarker}
        contentToken={matchingMarker.content_token}
        markerResult={markerResult}
        handleMarkerChange={this.handleMarkerChange}
        selectedMarker={this.state.selectedMarker}
        groupedByFood={groupedByFood}
        updateCardHeight={this.updateCardHeight}
      />
    );
  };

  renderMarkerGroupCardsScrollContainer = () => {
    const { groupTitle, markerResults, markers } = this.props;
    // Lookup table for markers
    const markerLookup: { [key: number]: Marker } = markers.reduce(
      (acc: { [key: number]: Marker }, marker: Marker) => {
        acc[marker.id] = marker;
        return acc;
      },
      {},
    );
    // Sort marker results alphabetically based on name on matching marker
    const sortedMarkerResults = markerResults.sort((a, b) => {
      const markerA: Marker | undefined = markerLookup[a.marker_id];
      const markerB: Marker | undefined = markerLookup[b.marker_id];
      if (!markerA || !markerB) return -1;

      return markerA.name > markerB.name ? 1 : -1;
    });

    const scrollContainerDataTest =
      groupTitle &&
      formatStringSeparator(`scroll container ${groupTitle.toLowerCase()}`);

    return (
      <S.MarkerGroupCardsScrollContainer
        isScrollable={this.state.isScrollable}
        cardHeight={this.state.cardHeight}
        showScrollContainer={this.showScrollContainer()}
        data-test={scrollContainerDataTest}
      >
        <S.MarkerGroupCardsWrapper
          cardHeight={this.state.cardHeight}
          ref={this.markerGroupCardsWrapperRef}
          onScroll={this.handleScroll}
        >
          {sortedMarkerResults.map(this.renderFoodSensitivityMarkerCard)}
        </S.MarkerGroupCardsWrapper>
      </S.MarkerGroupCardsScrollContainer>
    );
  };

  renderShowResultsButton = () => {
    const { markerResults, groupTitle, normalReactivityIsExpanded } =
      this.props;

    const normalReactivity = groupTitle === 'normal reactivity';
    const showButton = normalReactivity && !normalReactivityIsExpanded;

    if (!normalReactivity) return null;

    return (
      <S.ShowResultsButton
        showButton={showButton}
        onClick={this.handleShowResultsBtnClick}
        data-test={FOOD_SENSITIVITY_RESULT.EXPAND_NORMAL_FOODS_BUTTON}
        appearance={'secondary'}
      >
        Show {markerResults.length} Normal Reactivity Foods
      </S.ShowResultsButton>
    );
  };

  renderReactivityBadge = () => {
    const { markerResults, markers } = this.props;
    const markerResult = markerResults[0];
    const marker = markers.find((item) => item.id === markerResult.marker_id);

    if (marker === undefined) return null;

    return (
      <ReactivityBadge
        markerResult={markerResult}
        marker={marker}
        markerLabel="Foods with"
        dataTest={formatStringSeparator(`badge ${markerResult.descriptor}`)}
      />
    );
  };

  render() {
    const { markerResults, groupTitle, test, groupedByFood } = this.props;

    const { content } = test;

    const overviewDataTest =
      groupTitle &&
      formatStringSeparator(`overview ${groupTitle.toLowerCase()}`);

    const groupContentKey =
      `${
        groupTitle && groupTitle.toUpperCase().replace(/\s/g, '_')
      }_OVERVIEW` || 'None';

    const newMarkerResultContent =
      (content && content[groupContentKey]) || 'Marker Overview';

    return (
      <>
        {markerResults.length ? (
          <S.FoodSensitivityMarkerGroupCard>
            <S.MarkerGroupOverview
              showScrollContainer={this.showScrollContainer()}
              data-test={overviewDataTest}
            >
              <S.MarkerGroupOverviewContent groupedByFood={groupedByFood}>
                {newMarkerResultContent}
                {this.renderShowResultsButton()}
              </S.MarkerGroupOverviewContent>
              {!groupedByFood && (
                <S.MarkerGroupOverviewBadge>
                  {this.renderReactivityBadge()}
                </S.MarkerGroupOverviewBadge>
              )}
            </S.MarkerGroupOverview>
            {this.renderMarkerGroupCardsScrollContainer()}
          </S.FoodSensitivityMarkerGroupCard>
        ) : (
          <S.FoodSensitivityMarkerGroupCard
            data-test={FOOD_SENSITIVITY_RESULT.NO_MARKER_RESULTS}
          >
            <S.MarkerGroupOverview data-test={overviewDataTest}>
              All clear here, just stick to foods within your other reactivity
              levels if you decide to do an elimination diet.
            </S.MarkerGroupOverview>
          </S.FoodSensitivityMarkerGroupCard>
        )}
      </>
    );
  }
}

export default FoodSensitivityMarkerGroupCard;
