import { DEFAULT_BOX_IMAGE_URL } from 'common/utils/constants/urls';

export default {
  default: {
    title: 'Where should we ship?',
    subtitle: 'Shipping Address',
    button: 'Next',
    image: { src: DEFAULT_BOX_IMAGE_URL, alt: '' },
  },
  'conception-plan': {
    title: 'Main Title',
    subtitle: 'Shipping Address',
    button: 'Next',
    disclaimer: `*This is a disclaimer.`,
    image: { src: DEFAULT_BOX_IMAGE_URL, alt: '' },
  },
  'conception-plan-partners': {
    title: 'Main title 2',
    subtitle: 'Shipping Address',
    button: 'Next',
    disclaimer: `*This is a disclaimer.`,
    image: { src: DEFAULT_BOX_IMAGE_URL, alt: '' },
  },
};
