import { schema } from 'normalizr';

/** User Entity */
export const user = new schema.Entity('users');

/** KitIssue Entity */
export const kitIssue = new schema.Entity('kitIssues');
export const kitIssuePostAction = new schema.Entity('kitIssuePostActions');

/** PhysicianContact Entity */
export const physicianContact = new schema.Entity('physician_contact');

/** Lab Entity */
export const lab = new schema.Entity('labs');

/** Marker Result Entity */
export const markerResult = new schema.Entity(
  'marker_results',
  {},
  {
    processStrategy: entity => {
      const { boundaries } = entity;
      return {
        ...entity,
        boundaries: boundaries.map((boundary: string | number) =>
          Number(boundary),
        ),
      };
    },
  },
);

/** Array of Marker Results */
export const arrayOfMarkerResults = new schema.Array(markerResult);

/** Marker Entity */
export const marker = new schema.Entity('markers');

/** Array of Markers */
export const arrayOfMarkers = new schema.Array(marker);

/** Test Entity */
export const test = new schema.Entity('tests');

export const contentSnippet = new schema.Entity(
  'content_snippets',
  {},
  {
    idAttribute: 'token',
    mergeStrategy: (a, b) => ({
      ...a,
      ...b,
    }),
    processStrategy: entity => {
      const { body, key } = entity;
      return {
        [key]: body,
      };
    },
  },
);

export const arrayOfContentSnippets = new schema.Array(contentSnippet);

/** Kit Result Entity */
export const kit = new schema.Entity(
  'kits',
  {
    lab,
    marker_results: arrayOfMarkerResults,
    markers: arrayOfMarkers,
    related_marker_results: arrayOfMarkerResults,
    test,
  },
  {
    idAttribute: 'number',
    processStrategy: entity => {
      const { product_name = '' } = entity;

      return {
        ...entity,
        product_name: product_name.replace(/\(.*\)$/, '').trim(),
      };
    },
  },
);

/** Array of Kits */
export const arrayOfKits = new schema.Array(kit);
