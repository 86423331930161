import {
  Container as LeavesContainer,
  H4,
  size,
  colors,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled(LeavesContainer)`
  width: 100%;
  padding: 0 0 ${size.xl2}px 0;
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: ${size.xs1}px;
  align-items: baseline;
`;

export const Title = styled(H4)`
  color: ${colors.teal5};
  margin-bottom: ${size.xs1}px;
`;
export const RedCircleIconWrapper = styled.div`
  position: relative;
`;
export const RedCircleWithTaskNumber = styled.div`
  ${typography.captionText}
  position: absolute;
  top: -${size.md}px;
  width: 18px;
  height: 18px;
  background: ${colors.red3};
  color: ${colors.white};
  border-radius: 50%;
  font-size: ${size.sm}px;
  font-weight: ${typography.weight.bold};
  text-align: center;
  line-height: normal;
`;

export const ChecklistWrapper = styled.div`
  width: 100%;
`;
