import { isEnterpriseCustomer } from 'common/utils/helpers';
import CancelAppointmentConfirmationPage from 'pages/CancelAppointmentConfirmationPage/CancelAppointmentConfirmationPage';
import CancelAppointmentPage from 'pages/CancelAppointmentPage/CancelAppointmentPage';
import EnterpriseCancelAppointmentPage from 'pages/OldDashboardPage/Appointments/Enterprise/CancelAppointmentPage/CancelAppointmentPage';
import EnterpriseConfirmCancelAppointmentPage from 'pages/OldDashboardPage/Appointments/Enterprise/ConfirmCancelAppointmentPage/ConfirmCancelAppointmentPage';
import React from 'react';

const AppointmentConfirmCancelRoutes = (props: {}) =>
  isEnterpriseCustomer() ? (
    <EnterpriseConfirmCancelAppointmentPage />
  ) : (
    <CancelAppointmentConfirmationPage />
  );

const AppointmentCancelRoutes = (props: {}) =>
  isEnterpriseCustomer() ? (
    <EnterpriseCancelAppointmentPage />
  ) : (
    <CancelAppointmentPage />
  );

export { AppointmentConfirmCancelRoutes, AppointmentCancelRoutes };
