import { CollectionDeviceImage, CollectionTip } from 'common/utils/types';
import React from 'react';

import { StepsIconType } from '../model';
import StepIcon from './StepIcon';
import * as S from './styles';

export type StepProps = {
  key: string;
  collectionMethodStep: CollectionTip | CollectionDeviceImage;
  icon: StepsIconType | undefined;
  width: number;
};

const CollectionStep = ({ collectionMethodStep, icon, width }: StepProps) => {
  const hasIcon = Boolean(icon);
  let descriptionAndAlt: string | undefined;
  if ('description' in collectionMethodStep) {
    descriptionAndAlt = collectionMethodStep.description;
  }

  if ('name' in collectionMethodStep) {
    descriptionAndAlt = collectionMethodStep.name;
  }

  return (
    <S.Step width={width} icon={hasIcon}>
      <S.Wrapper>
        {icon && <StepIcon type={icon} />}

        <S.Description icon={hasIcon}>{descriptionAndAlt}</S.Description>
      </S.Wrapper>
      <S.StepImg>
        <S.Image src={collectionMethodStep.image_url} alt={descriptionAndAlt} />
      </S.StepImg>
    </S.Step>
  );
};

export default React.memo(CollectionStep);
