import { getUserMedications } from 'common/apis/telehealthApis';
import { logError } from 'common/utils/helpers';
import React from 'react';
import { useQuery } from 'react-query';

import { GreyCardHeading } from '../../DashboardPage/components/Cards/BaseCard.styles';
import MedicationsCard from './components/MedicationsCard';

function MedicationsSection() {
  const { data: userMedicationsResponse } = useQuery(
    'telehealth-medications',
    getUserMedications,
    {
      onError(error) {
        logError((error as Error).message, {
          errorInfo: 'User Medications Response',
          component: 'MedicationSectionSection',
          method: 'getUserMedications',
        });
      },
    },
  );

  const userMedications = userMedicationsResponse?.data;
  const userHasMedications = userMedications && userMedications?.length > 0;

  return (
    <>
      {userHasMedications && (
        <div>
          <GreyCardHeading>Medication</GreyCardHeading>
          <MedicationsCard />
        </div>
      )}
    </>
  );
}

export default MedicationsSection;
