import { TrackVisibilityWrapper } from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import ResultSectionHeaderContainer, {
  ResultSectionHeaderContainerProps,
} from 'containers/ResultSection/HeaderContainer';
import * as React from 'react';

type ResultSectionProps = Pick<
  ResultSectionHeaderContainerProps,
  'contentToken' | 'headerIcon' | 'defaultContent' | 'onMissingContent'
>;

function ResultSection(props: ResultSectionProps) {
  return (
    <TrackVisibilityWrapper label={ANALYTICS.LABELS.RESULTS_SECTION}>
      <ResultSectionHeaderContainer {...props} noTopBorder />
    </TrackVisibilityWrapper>
  );
}

export default ResultSection;
