import { Col, Row } from '@everlywell/leaves';
import { SAMPLE_COLLECTION_FORM as DATA_TEST } from 'common/utils/constants/dataTest';
import {
  getListOfCollectionTimes,
  isDateValid,
  useFormattedDate,
} from 'common/utils/helpers';
import { handleChange } from 'common/utils/registrationHelpers';
import {
  BarcodeRegistration,
  InputError,
  KitRegistrationInput,
} from 'common/utils/types';
import { addYears, subDays } from 'date-fns';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';

import * as S from './styles';

interface Props {
  sampleCollectionDate: KitRegistrationInput<string>;
  sampleCollectionTime: KitRegistrationInput<string>;
  barcode: BarcodeRegistration;
  handleSubmit: () => void;
}

const SampleCollectionForm = ({
  sampleCollectionDate,
  sampleCollectionTime,
  barcode,
  handleSubmit,
}: Props) => {
  const formatDate = useFormattedDate(sampleCollectionDate.value);
  const [error, setError] = useState<InputError>('');

  const barcodeWithIssues =
    barcode.issues.length > 0 &&
    sampleCollectionDate.value === new Date().toLocaleDateString('en-US');

  const handleDateCollection = (value: string) => {
    const formattedCollectionValue = formatDate(value);
    const dateLimit = isEmpty(barcode.issues)
      ? addYears(new Date(), 1)
      : subDays(new Date(), 1);
    const _error: InputError = isDateValid(
      formattedCollectionValue,
      new Date(2019, 11, 1),
      dateLimit,
    );

    setError(_error);
    handleChange(sampleCollectionDate, formattedCollectionValue);
  };
  return (
    <Row start="xs" middle="xs" data-test={DATA_TEST.CONTAINER}>
      <S.HeaderCol>
        <S.Header>Sample Collection Info</S.Header>
        <S.Subheader>
          Please provide your sample collection date and time.
          <br></br>
          <br></br>
          If your test has multiple samples, like blood and urine, please
          <strong> make sure you collect them on the same day</strong>. Samples
          collected on different days may give invalid results and are unable to
          be processed by the lab.
        </S.Subheader>
      </S.HeaderCol>
      <Col>
        <S.StyledInput
          label="Date (MM/DD/YYYY)"
          id={sampleCollectionDate.id}
          type="text"
          value={sampleCollectionDate.value as string}
          name={sampleCollectionDate.id}
          inputClassName="inspectletIgnore"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleDateCollection(e.target.value);
          }}
          placeholder="MM/DD/YYYY"
          error={error}
          data-test={`${DATA_TEST.INPUT}-${sampleCollectionDate.id}`}
        />
        <S.Picker
          id={sampleCollectionDate.id}
          items={getListOfCollectionTimes()}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(sampleCollectionTime, e.target.value)
          }
          className="inspectletIgnore"
          value={sampleCollectionTime.value}
          label="Time"
          name={sampleCollectionTime.id}
          data-test={`${DATA_TEST.INPUT}-${sampleCollectionTime.id}`}
        />
      </Col>
      <S.ButtonCol>
        <S.SubmitButton
          isDisabled={
            !!error ||
            !sampleCollectionDate.value ||
            !sampleCollectionTime.value ||
            barcodeWithIssues // Disable same day collection date if barcode has issues
          }
          appearance="primary"
          data-test={DATA_TEST.SUBMIT}
          onClick={() => handleSubmit()}
        >
          Next
        </S.SubmitButton>
      </S.ButtonCol>
    </Row>
  );
};

export default SampleCollectionForm;
