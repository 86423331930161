enum TestCollectionMethod {
  aptima_urine = 'aptima_urine',
  blood = 'blood',
  blood_adx100 = 'blood_adx100',
  blood_dbs_ii = 'blood_dbs_ii',
  blood_double_strip_adx = 'blood_double_strip_adx',
  cobas_urine = 'cobas_urine',
  default = 'default',
  dried_urine = 'dried_urine',
  nasal_swab = 'nasal_swab',
  nasopharyngeal_swab = 'nasopharyngeal_swab',
  saliva = 'saliva',
  saliva_no_identifiers = 'saliva_no_identifiers',
  vaginal_swab = 'vaginal_swab',
}

type BloodCollectionMethod =
  | TestCollectionMethod.blood_adx100
  | TestCollectionMethod.blood_double_strip_adx
  | TestCollectionMethod.blood_dbs_ii
  | TestCollectionMethod.blood;

const bloodCollectionMethods: BloodCollectionMethod[] = [
  TestCollectionMethod.blood_adx100,
  TestCollectionMethod.blood_double_strip_adx,
  TestCollectionMethod.blood_dbs_ii,
  TestCollectionMethod.blood,
];

const testCollectionMethods: string[] = Object.values(TestCollectionMethod);

export { TestCollectionMethod, testCollectionMethods, bloodCollectionMethods };
