import { creditCardsIcons, Localize } from '@everlywell/leaves';
import { PaymentMethod } from 'common/apis/telehealthApis';
import useUser from 'common/hooks/useUser';
import analytics from 'common/utils/analytics';
import { convertCardBrandToKey } from 'common/utils/cards';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { isEmpty } from 'lodash';
import FloatingCtaFooter from 'pages/VirtualCare/NativeScheduling/components/FloatingCtaFooter';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRedirectAfterPaymentPage } from '../../utils/redirections';
import { NoShowMessage } from '../NoShowMessage';
import * as S from './PaymentMethods.styles';

type PaymentMethodsProps = {
  isSTIOD: boolean;
  paymentMethods: PaymentMethod[];
  program: string;
  setIsEnablePaymentForm: (value: boolean) => void;
};

/**
 * Left "paymentMethods" as an array to support multiple payment methods
 * in the future if needed
 *
 */
function PaymentMethods({
  isSTIOD,
  paymentMethods,
  program,
  setIsEnablePaymentForm,
}: PaymentMethodsProps) {
  const firstPaymentMethodOption = paymentMethods[0].id;
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<number>(
    firstPaymentMethodOption,
  );
  const navigate = useNavigate();
  const navigateProgramOndemandOrSuccessPaymentPage =
    useRedirectAfterPaymentPage(program);
  const { enrolledProgram } = useUser();

  const isProgramOnDemand = enrolledProgram(program)?.on_demand;

  useEffect(() => {
    if (!isEmpty(program)) {
      analytics.track({
        event: ANALYTICS.EVENTS.VIEWED_PAGE,
        data: {
          page: ANALYTICS.PAGES.ACCOUNT_HUB.PAYMENT_DETAILS,
          program: program,
        },
      });
    }
  }, [program]);

  const handleSubmit = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'Continue (Stored payment)',
        program: program,
      },
    });

    navigateProgramOndemandOrSuccessPaymentPage();
  };

  const handleBack = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'Back (Stored payment)',
        program: program,
      },
    });
    navigate(-1);
  };
  const handleEnablePaymentFormPage = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'Add new payment',
        program: program,
      },
    });
    setIsEnablePaymentForm(true);
  };

  return (
    <S.Container>
      <S.H3 align="left">Payment Details</S.H3>
      <form onSubmit={handleSubmit}>
        {paymentMethods.map((paymentMethod, index) => (
          <S.RadioButton
            id={paymentMethod.id.toString()}
            key={`payment-method-option-${index}`}
            checked={selectedPaymentMethod === paymentMethod.id}
            onChange={() => setSelectedPaymentMethod(paymentMethod.id)}
            label={
              <S.Label>
                <S.CardIcon
                  src={
                    creditCardsIcons[convertCardBrandToKey(paymentMethod.brand)]
                  }
                  alt={paymentMethod.brand}
                />
                Card ending in{' '}
                <Localize name="last-four-card-digits">
                  {paymentMethod.last_four}
                </Localize>
              </S.Label>
            }
            name={paymentMethod.brand}
          />
        ))}
        <S.HR />
        <S.AddNewPaymentBtn
          appearance="text"
          onClick={handleEnablePaymentFormPage}
        >
          + <span data-isolate>Add new payment</span>
        </S.AddNewPaymentBtn>

        <S.SubmitWrapper width={[1]}>
          {isSTIOD && <NoShowMessage />}
          <FloatingCtaFooter
            className={isSTIOD ? 'floating-cta-footer' : ''}
            backButton={
              <S.Button
                type="button"
                appearance="secondary"
                onClick={handleBack}
              >
                Back
              </S.Button>
            }
            nextButton={
              <S.Button appearance="primary" role="button" type="submit">
                {isProgramOnDemand ? 'Submit appointment request' : 'Continue'}
              </S.Button>
            }
          />
        </S.SubmitWrapper>
      </form>
    </S.Container>
  );
}

export default PaymentMethods;
