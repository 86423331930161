import { PUBLIC_APP_ROOT } from 'common/utils/constants';
import { ERROR_PAGE as DATA_TEST } from 'common/utils/constants/dataTest';
import Layout from 'components/Layout';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import StatusCode from './StatusCode';
import * as S from './styles';

function ErrorPage() {
  const { pathname } = useLocation();
  const code = pathname === `/error` || pathname === '/500' ? 500 : 404;
  const is404 = code === 404;

  return (
    <Layout>
      <S.ErrorPage>
        <StatusCode code={code} />
        <S.Title data-test={DATA_TEST.HEADER}>Whoops!</S.Title>

        <S.Message data-test={DATA_TEST.MESSAGE}>
          {is404
            ? 'Sorry, but we were unable to find what you were looking for.'
            : 'Sorry, but we were experiencing some technical difficulties. Please check back again in a little while.'}
        </S.Message>
        <S.ButtonWrapper>
          {is404 ? (
            <S.Button
              href={PUBLIC_APP_ROOT}
              data-test={DATA_TEST.HOMEPAGE_BUTTON}
              trackingData={{
                label: 'Go to homepage',
                category: '404 error page',
              }}
            >
              Go to Homepage
            </S.Button>
          ) : (
            <S.Button
              href="/dashboard"
              component={Link}
              data-test={DATA_TEST.DASHBOARD_BUTTON}
              aria-label="Dashboard"
              trackingData={{
                label: 'Go to dashboard',
                category: '500 error page',
              }}
            >
              Go to Dashboard
            </S.Button>
          )}
        </S.ButtonWrapper>
      </S.ErrorPage>
    </Layout>
  );
}

export default ErrorPage;
