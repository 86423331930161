/*
 * AncestryBannerContainer
 */

import { ImmutableReduxState } from 'common/utils/types';
import AncestryBanner, {
  Props as AncestryBannerProps,
} from 'components/AncestryBanner';
import { connect } from 'react-redux';
import { makeSelectContent } from 'store/selectors';

const CONTENT_KEYS = {
  ancestryBannerCopy: 'THIRD_PARTY_BANNER',
};

type OwnProps = {
  testId: number;
  contentToken: string;
};

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): AncestryBannerProps {
  const { testId, contentToken } = ownProps;

  const content = makeSelectContent(contentToken, CONTENT_KEYS)(state) || {
    ancestryBannerCopy: '',
  };

  return {
    testId,
    content: content.ancestryBannerCopy,
  };
}

export default connect(mapStateToProps)(AncestryBanner);
