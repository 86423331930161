import {
  size,
  colors,
  typography,
  Container as LeavesContainer,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled(LeavesContainer)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-color: ${colors.green1};
  padding: ${size.xl2}px ${size.md}px;
  margin-top: ${size.xl1}px;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: -30px;
`;

export const Title = styled.h3`
  ${typography.h4Text};
  text-align: center;
  margin: 0 0 ${size.md}px 0;
`;

export const SubTitle = styled.p`
  ${typography.bodyTextSmall};
  text-align: center;
`;

export const Link = styled.a`
  ${typography.buttonTextSmall};
  text-align: center;
  text-decoration: none;
  color: ${colors.green4};
`;
