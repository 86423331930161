import { Row, Col } from '@everlywell/leaves';
import React from 'react';

import * as S from './styles';

type ConfirmIdentifiersErrorProps = {
  error: string;
};

const ConfirmIdentifiersErrorPage = (props: ConfirmIdentifiersErrorProps) => {
  const { error } = props;

  let errorTitle;
  let errorSubtitle;

  if (error === 'issue already resolved') {
    errorTitle = 'This issue is already resolved.';
    errorSubtitle = 'You do not need to take any action.';
  } else if (error === 'wait for cx') {
    errorTitle = 'You have already confirmed your identifiers.';
    errorSubtitle = 'For further assistance, ';
    errorSubtitle += 'please wait 1-3 business days ';
    errorSubtitle += 'for our Customer Experience team to reach out to you.';
  }

  return (
    <>
      <Row start="xs" middle="xs">
        <Col>
          <S.TitleWrapper>
            <S.Title>{errorTitle}</S.Title>
          </S.TitleWrapper>
          <S.SubtitleWrapper>
            <S.Subtitle>{errorSubtitle}</S.Subtitle>
          </S.SubtitleWrapper>
        </Col>
      </Row>
    </>
  );
};

export default ConfirmIdentifiersErrorPage;
