import { ProgramsAtCheckout } from 'common/utils/types';

type WelcomeTypeContentValue = {
  HEADING: string;
  SUB_HEADING: string;
  BUTTON: string;
};

export const WELCOME_TYPE_CONTENT: Record<
  ProgramsAtCheckout,
  WelcomeTypeContentValue
> = {
  'weight-management-full': {
    HEADING: 'Welcome to Weight Care+',
    SUB_HEADING: `Next up, we’ll need some information from you to get your appointment scheduled.`,
    BUTTON: 'Continue',
  },
  'weight-management': {
    HEADING: 'Welcome to Weight Care+',
    SUB_HEADING: `Next up, we’ll need some information from you to get your appointment scheduled.`,
    BUTTON: 'Continue',
  },
  'everlywell-plus': {
    HEADING: 'Welcome to Everlywell+',
    SUB_HEADING:
      'Ready to use your credit(s)? Click “continue” to view your available credits and select your test(s) of choice!',
    BUTTON: 'Continue',
  },
};
