import { Col } from '@everlywell/leaves';
import { useAnswers } from 'common/providers/AnswersProvider';
import { HEALTH_PROFILE_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';
import {
  HealthProfileOption,
  HealthProfileResponseOptions,
  QuestionProps,
} from 'common/utils/types';
import React, { FormEvent, useEffect, useRef, useState } from 'react';

import { setResponse } from '../../helpers';

import { QuestionFooter } from '..';

import * as S from '../styles';

const MIN_NEEDED_CHARS = 4;
const HealthProfileMultiAnswerWithFreeTextQuestion = ({
  questionData,
  questionnaireId,
  className,
}: QuestionProps) => {
  const {
    content,
    description,
    question_options: options,
    questionnaire_question_id: questionnaireQuestionId,
  } = questionData;

  const { allAnswers } = useAnswers();
  const [multiAnswers, setMultiAnswers] = useState<
    HealthProfileResponseOptions[]
  >([]);
  const [freeResponseText, setFreeResponseText] = useState<string | null>(null);

  const [error, setError] = useState('');
  const [charCount, setCharCount] = useState(0);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const freeResponseRef = useRef<HTMLInputElement | null>(null);
  const asideRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    // If question has already been answered, retrieve value on mount and insert into UI
    // Move to reducer or custom hook at later date
    if (allAnswers[questionnaireQuestionId]) {
      const previousAnswer = allAnswers[questionnaireQuestionId]?.options;
      setMultiAnswers(previousAnswer);
    } else {
      setMultiAnswers([]);
    }

    if (allAnswers[questionnaireQuestionId]?.value) {
      const { value } = allAnswers[questionnaireQuestionId];
      const initialFreeResponseText = value;
      setFreeResponseText(initialFreeResponseText);
      setCharCount(initialFreeResponseText.length);
    }
  }, [allAnswers, questionnaireQuestionId]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '48px';
      const { scrollHeight } = textareaRef.current;
      const newHeight = scrollHeight + 14;
      textareaRef.current.style.height = `${newHeight}px`;
    }

    if (textareaRef.current && charCount === 300) {
      textareaRef.current.style.height = '265px';
    }

    if (asideRef.current) {
      const { scrollHeight } = asideRef.current;
      asideRef.current.style.bottom = `${scrollHeight}px`;
      asideRef.current.style.right = `-${scrollHeight}px`;
    }
  }, [charCount]);

  const handleChange = (e: string) => {
    if (isCheckboxChecked() && e.length === 0) {
      setError('Cannot be blank');
    } else if (isCheckboxChecked() && e.length < 4 && e.length !== 0) {
      setError('Character minimum is 4');
    } else {
      setError('');
    }
    setCharCount(e.length);
    setFreeResponseText(e);
  };

  function getValue(): string | null {
    if (isCheckboxChecked()) {
      return freeResponseText;
    }
    return null;
  }
  function isFreeTextEmpty(): boolean {
    return freeResponseText === null || freeResponseText === '';
  }
  function isCheckboxChecked(): boolean {
    const checkboxElement =
      freeResponseRef.current?.parentElement?.parentElement;
    return (checkboxElement as HTMLInputElement)?.checked;
  }

  function handlerMultiAnswerChange(
    event: FormEvent<HTMLInputElement>,
    option: HealthProfileOption,
  ) {
    // Checks if answer is already selected aka already in answer object for question with this Id
    // If it is, removes it from list of answers
    // If not, adds it
    let updatedMultiAnswers = [...multiAnswers];
    if (
      multiAnswers.some(
        (answer: HealthProfileResponseOptions) =>
          answer.option_id === option.option_id,
      )
    ) {
      updatedMultiAnswers = updatedMultiAnswers.filter(
        (answer) => answer.option_id !== option.option_id,
      );
    } else {
      // Checks if answer exists yet or not and either updates it or adds new array of answers
      updatedMultiAnswers.push(option);
    }
    setMultiAnswers(updatedMultiAnswers);

    const { currentTarget } = event;
    const checkboxElement =
      freeResponseRef.current?.parentElement?.parentElement;

    if (checkboxElement === currentTarget) {
      const previousCheckedState = (currentTarget as HTMLInputElement).checked;
      const checked = !previousCheckedState;
      const textAreaCharacters = textareaRef?.current?.value?.length ?? 0;
      if (checked && isFreeTextEmpty()) {
        setError('Cannot be blank');
      } else if (checked && textAreaCharacters < MIN_NEEDED_CHARS) {
        setError(`Character minimum is ${MIN_NEEDED_CHARS}`);
      } else {
        setError('');
      }
    } else if (!isCheckboxChecked()) {
      setError('');
    }
  }

  return (
    <S.QuestionContainer
      className={className}
      data-test={`${DATA_TEST.CONTAINER}-multi-question-free-text`}
    >
      <Col>
        <S.Question>{content}</S.Question>
        {description ? <S.SubText>{description}</S.SubText> : null}
      </Col>
      {options.map((option: HealthProfileOption) => (
        <S.Column key={option.option_id}>
          <div data-test={`${DATA_TEST.CONTAINER}-answers`}>
            <S.MultiAnswer
              ref={(ref) => {
                if (option?.free_response) {
                  freeResponseRef.current = ref;
                }
              }}
              key={option.option_id}
              name={option?.content}
              border
              label={
                !option?.free_response ? (
                  option?.content
                ) : (
                  <>
                    {option?.content}
                    <S.TextAreaWrapper>
                      <S.FreeResponseTextArea
                        id={option.option_id.toString()}
                        name={option?.content}
                        ref={textareaRef}
                        value={freeResponseText || ''}
                        placeholder="Please explain"
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => handleChange(e.target.value)}
                        error={error}
                        maxLength={300}
                      />
                      <S.Aside ref={asideRef}>
                        <S.CharacterCounter>{`${charCount}/300`}</S.CharacterCounter>
                      </S.Aside>
                    </S.TextAreaWrapper>
                  </>
                )
              }
              checked={multiAnswers.some(
                (answer: HealthProfileResponseOptions) =>
                  answer.option_id === option.option_id,
              )}
              onChange={(e) => handlerMultiAnswerChange(e, option)}
              bodyTextStyle
              data-test={`${DATA_TEST.BUTTON}-${option.content}`}
            />
          </div>
        </S.Column>
      ))}

      <QuestionFooter
        questionnaireId={questionnaireId}
        questionData={questionData}
        answer={multiAnswers.length}
        error={error}
        setResponse={() => {
          const value = getValue();
          return setResponse(allAnswers, questionnaireQuestionId, {
            options: multiAnswers,
            value,
          });
        }}
      />
    </S.QuestionContainer>
  );
};

export default HealthProfileMultiAnswerWithFreeTextQuestion;
