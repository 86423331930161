import { Modal } from '@everlywell/leaves';
import { redeemCredit } from 'common/apis/creditRedemptionApi';
import {
  getTaskChecklist,
  isCreditRedemptionTask,
} from 'common/apis/taskChecklistApi';
import useUser from 'common/hooks/useUser/useUser';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { DEFAULT_BOX_IMAGE_URL } from 'common/utils/constants/urls';
import { telehealthUrl } from 'common/utils/helpers';
import { logError } from 'common/utils/helpers';
import {
  PROGRAM_TYPE,
  ProgramType,
  RedeemableProduct,
} from 'common/utils/types';
import ApolloContainer from 'containers/ApolloContainer';
import Notification from 'containers/Notification';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import {
  Navigate,
  Outlet,
  useNavigate,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { ERROR_NOTIFICATION, setNotification } from 'store/actions';

import AddressConfirmation from './components/AddressConfirmation';
import ChooseTestKitOrVisitForm from './components/ChooseTestKitOrVisitForm';
import ConsentModal from './components/ConsentModal';
import ProductSelector from './components/ProductSelector/ProductSelector';
import { AddressFormValues } from './components/RedemptionAddressForm';
import RedemptionConfirmation from './components/RedemptionConfirmation';
import {
  CreditRedemptionContext,
  CreditRedemptionProvider,
  useCreditRedemption,
} from './context/CreditRedemptionContext';
import * as S from './CreditRedemptionRoutes.styles';
import { trackRedemption } from './CreditRedemptionTracking';

export type RedeemableProductType = 'virtual-care-visit' | 'test-kit';

export default function CreditRedemptionContainer() {
  const { creditRedemptionEnabled } = useFlags();

  const { data, isLoading } = useQuery('tasks', getTaskChecklist, {
    onError(error) {
      logError((error as Error).message, {
        errorInfo: 'User Tasks Response',
        component: 'CreditRedemptionContainer',
        method: 'getTaskChecklist',
      });
    },
  });

  const hasRedemptionTask = () => {
    const { data: taskData } = data || {};

    const hasRedemptionTask = taskData?.some(isCreditRedemptionTask);

    return hasRedemptionTask;
  };

  // Show loading animation while fetching user tasks
  if (isLoading) {
    return <S.CircleAnimation />;
  }

  // If the user does not have a redemption task or the feature is off, redirect to dashboard
  if (!creditRedemptionEnabled || !hasRedemptionTask()) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <CreditRedemptionProvider>
      <CreditRedemptionRoutes />
    </CreditRedemptionProvider>
  );
}

export function CreditRedemptionRoutes() {
  const {
    getStepCountLabel,
    resetSteps,
    userPlan,
    setUserPlan,
    stepCountVisibility,
  } = useCreditRedemption();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const navigate = useNavigate();

  const { user } = useUser();

  const [searchParams] = useSearchParams();

  const handleModalClose = (isOpen: boolean) => {
    navigate('/dashboard');
    setIsModalOpen(isOpen);
    resetSteps();
  };

  const stepCount = getStepCountLabel();

  useEffect(() => {
    if (userPlan) {
      return;
    }

    const planId = Number(searchParams.get('plan_id'));
    const planSlug = searchParams.get('plan_slug') as ProgramType;

    if (planId && planSlug) {
      setUserPlan({ id: planId, slug: planSlug });
      return;
    }

    const weightManagement = user?.user_plans?.enabled.find(
      (user_plan) => user_plan.program_plan_type === 'weight_management',
    );

    if (weightManagement) {
      setUserPlan({
        id: weightManagement.id,
        slug: PROGRAM_TYPE['weight-management-full'],
      });
    }
  }, [setUserPlan, searchParams, user, userPlan]);

  return (
    <Modal
      open={isModalOpen}
      openHandler={handleModalClose}
      forceFullScreen
      removeGradient
    >
      <S.Container>
        <Notification />
        {stepCountVisibility && <S.StepCount>{stepCount}</S.StepCount>}
        <Outlet />
      </S.Container>
    </Modal>
  );
}

export const ChooseTestKitOrVisitRoute = () => {
  const { setStepCountVisibility, userPlan } = useContext(
    CreditRedemptionContext,
  );
  const { vcvRedemptionAllowedPrograms } = useFlags();
  const navigate = useNavigate();

  const planSlug = userPlan?.slug;
  const trackingFunctions = trackRedemption(planSlug);
  const isProgramAllowedToVCV =
    vcvRedemptionAllowedPrograms.programs.includes(planSlug);

  useEffect(() => {
    if (!planSlug) return navigate('/dashboard');

    if (!isProgramAllowedToVCV) return navigate('/credit-redemption/address');
  }, [isProgramAllowedToVCV, navigate, planSlug]);

  useEffect(() => {
    setStepCountVisibility(!isProgramAllowedToVCV);
  }, [setStepCountVisibility, isProgramAllowedToVCV]);

  useEffectOnce(() => {
    trackingFunctions.testKitOrVCV.whenViewed();
  });

  return (
    <ChooseTestKitOrVisitForm
      trackingFunctions={trackingFunctions.testKitOrVCV}
    />
  );
};

export const AddressConfirmationRoute = () => {
  const { nextStep, saveAddress, setIsNyUser, userPlan } = useContext(
    CreditRedemptionContext,
  );
  const navigate = useNavigate();
  const { vcvRedemptionAllowedPrograms } = useFlags();
  const trackingFunctions = trackRedemption(userPlan?.slug);

  const isVCVRedemptionDisabled =
    vcvRedemptionAllowedPrograms?.programs?.length === 0;

  const handleAddressConfirmation = (newAddress: AddressFormValues) => {
    saveAddress(newAddress);
    nextStep();
    trackingFunctions.address.whenAllSetClicked();
  };

  const onBackClick = () => {
    const route = isVCVRedemptionDisabled
      ? '/dashboard'
      : '/credit-redemption/select-product';

    navigate(route);
    trackingFunctions.address.whenBackClicked();
  };

  const onEditClick = () => {
    trackingFunctions.address.whenEditClicked();
  };

  const onFormBackClick = () => {
    trackingFunctions.address.whenFormBackClicked();
  };

  const onFormSubmitClick = () => {
    trackingFunctions.address.whenFormSubmitClicked();
  };

  useEffectOnce(() => {
    trackingFunctions.address.whenViewed();
  });

  return (
    <AddressConfirmation
      onBackClick={onBackClick}
      onConfirmClick={handleAddressConfirmation}
      onEditClick={onEditClick}
      onFormBackClick={onFormBackClick}
      onFormSubmitClick={onFormSubmitClick}
      isNyUserCallback={setIsNyUser}
    />
  );
};

export const ProductSelectionRoute = () => {
  const {
    nextStep,
    previousStep,
    isStepComplete,
    saveProduct,
    isNyUser,
    userPlan,
  } = useCreditRedemption();

  const [openModal, setModalVisibility] = useState(false);
  const [consentModalProps, setConsentModalProps] = useState({
    programSlug: '',
    telehealthUrl: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as {
    redeemableProductOption?: RedeemableProductType;
  };

  const redeemableProductType = state?.redeemableProductOption ?? 'test-kit';

  const dispatch = useDispatch();
  const trackingFunctions = trackRedemption(userPlan?.slug);

  const handleProductSelection = async (
    userPlanId: number,
    selectedProduct: RedeemableProduct,
  ) => {
    saveProduct(selectedProduct);
    trackingFunctions.products.whenSelected(selectedProduct.name, 'test-kit');

    try {
      setIsLoading(true);

      await redeemCredit(userPlanId, {
        products: [{ slug: selectedProduct.slug }],
      });

      setIsLoading(false);
      nextStep();
    } catch (error) {
      /*
      This is an edge case.

      The only errors that are returned from the API are:

      - The user is not eligible for redemption
      - The product is not available in NY
      - Unknown error

      For the cases above, the UI will prevent users from getting to this step by not starting the redemption process.
      */
      dispatch(
        setNotification({
          message:
            'An error occurred while placing your order. Please try again later.',
          type: ERROR_NOTIFICATION,
          source: ANALYTICS.CATEGORIES.CREDIT_REDEMPTION,
        }),
      );
      setIsLoading(false);

      logError((error as Error).message, {
        errorInfo: 'Error placing order - Credit Redemption',
        component: 'CreditRedemptionRoutes',
        method: 'handleProductSelection',
      });
    }
  };

  const handleVCVSelection = (
    userPlanId: number,
    selectedProduct: RedeemableProduct,
  ) => {
    const { slug } = selectedProduct;

    const url = telehealthUrl({
      program: slug,
      planId: String(userPlanId),
    });

    const telehealthPath = `${url.pathname}/${url.search}`;

    trackingFunctions.products.whenSelected(
      selectedProduct.name,
      'virtual-care-visit',
    );

    setConsentModalProps({
      programSlug: slug,
      telehealthUrl: telehealthPath,
    });
    setModalVisibility(true);
  };

  const handlePreviousStep = () => {
    trackingFunctions.products.whenBackClicked(redeemableProductType);

    switch (redeemableProductType) {
      case 'virtual-care-visit':
        navigate('/credit-redemption/select-product');
        break;
      default:
        previousStep();
    }
  };

  useEffectOnce(() => {
    trackingFunctions.products.whenViewed(redeemableProductType);
  });

  const handleOnSubmit = () => {
    switch (redeemableProductType) {
      case 'virtual-care-visit':
        return handleVCVSelection;
      default:
        return handleProductSelection;
    }
  };

  // If the user has not completed the previous step, redirect to dashboard
  if (
    (redeemableProductType !== 'virtual-care-visit' || !userPlan) &&
    !isStepComplete('AddressStep')
  ) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <ApolloContainer>
      <ProductSelector
        handleOnBack={handlePreviousStep}
        handleOnSubmit={handleOnSubmit()}
        isNYUser={!!isNyUser()}
        isLoading={isLoading}
        redeemableProductType={redeemableProductType}
      ></ProductSelector>
      <ConsentModal
        openModal={openModal}
        setIsModalOpen={setModalVisibility}
        {...consentModalProps}
      />
    </ApolloContainer>
  );
};

export const RedemptionConfirmationRoute = () => {
  const { resetSteps, isStepComplete, getProduct, userPlan } = useContext(
    CreditRedemptionContext,
  );
  const navigate = useNavigate();
  const trackingFunctions = trackRedemption(userPlan?.slug);
  const product = getProduct();

  const handleButtonClick = () => {
    resetSteps();
    navigate('/dashboard');
    trackingFunctions.confirmation.whenContinueClicked();
  };

  useEffectOnce(() => {
    trackingFunctions.confirmation.whenViewed();
  });

  // If the user has not completed the previous step, redirect to dashboard
  if (!isStepComplete('ProductSelectionStep')) {
    return <Navigate to="/dashboard" />;
  }

  const { box_image_url, name } = product || {};

  return (
    <RedemptionConfirmation
      title="Order Summary"
      header="Yay! Your order is on the way!"
      subHeader="Keep an eye on your email for details."
      productImageUrl={box_image_url || DEFAULT_BOX_IMAGE_URL}
      productName={name || ''}
      deliveryEstimation="Est. delivery 2-8 business days"
      buttonText="Done"
      buttonCallback={handleButtonClick}
    />
  );
};
