import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import React, { useEffect } from 'react';

import StethoscopeIcon from '../../../AccountHub/assets/icon-stethoscope.svg';
import * as S from './ShareResultsModal.styles';

export interface ShareResultsModalProps {
  openModal: boolean;
  setOpenModal: (bool: boolean) => void;
  program: string;
  handleConsentChoice: (consent: boolean) => void;
}

function ShareResultsModal({
  openModal,
  setOpenModal,
  handleConsentChoice,
  program,
}: ShareResultsModalProps) {
  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: 'telehealth result share',
        program,
      },
    });
  }, [program]);

  const handleShareResultsButtonClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'telehealth result share consent',
        program,
      },
    });

    handleConsentChoice(true);
  };
  const handleDontShareResultsButtonClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'telehealth result share non-consent',
        program,
      },
    });

    handleConsentChoice(false);
  };

  return (
    <S.ShareResultsModal open={openModal} openHandler={setOpenModal}>
      <S.ContentContainer>
        <S.IconContainer src={StethoscopeIcon} alt="" />

        <S.Header>Share your results for better&nbsp;care</S.Header>
        <S.Copy>
          To provide the best care possible, your healthcare provider would like
          to review your test results. By selecting ‘I consent,’ all past and
          future Everlywell test results will be shared with your provider.
        </S.Copy>
        <S.ConsentButton
          appearance="primary"
          type="button"
          onClick={handleShareResultsButtonClick}
        >
          I consent
        </S.ConsentButton>

        <S.NonConsentButton
          appearance="text"
          type="button"
          onClick={handleDontShareResultsButtonClick}
        >
          No thanks, do not share
        </S.NonConsentButton>
      </S.ContentContainer>
    </S.ShareResultsModal>
  );
}

export default ShareResultsModal;
