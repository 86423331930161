/* prettier-ignore */
import { mediaQueries, HeroSlider, HeroSlideStyles } from '@everlywell/leaves';
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 32px;

  ${mediaQueries.forTabletVerticalUp} {
    margin-bottom: 50px;
  }
`;

export const MobileContainer = styled.div`
  display: flex;
  justify-content: center;

  ${mediaQueries.forDesktopUp} {
    display: none;
  }
`;

export const DesktopContainer = styled.div`
  display: none;

  ${mediaQueries.forDesktopUp} {
    display: flex;
    justify-content: center;
  }
`;

export const MobileInner = styled.div`
  max-width: 327px;
  max-height: 384px;

  ${mediaQueries.forTabletHorizontalUp} {
    max-width: 533px;
    max-height: 284px;
  }
`;

export const CardContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  width: 100%;
`;

export const LeftAlignedContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-left: 20px;
`;

export const SmallInner = styled.div`
  max-width: 533px;
`;

export const LargeInner = styled.div`
  max-width: 866px;
  margin: 0 auto;
`;

export const CustomHeroSlider = styled(HeroSlider)`
  width: 100%;
  margin-bottom: 30px;

  button[aria-label='Next Slide'] {
    top: 50%;
    right: -5%;

    @media screen and (min-width: 400px) {
      right: 0;
    }

    ${mediaQueries.forDesktopUp} {
      right: -4%;
      top: 40%;
    }
  }

  button[aria-label='Previous Slide'] {
    top: 50%;
    left: -5%;

    @media screen and (min-width: 400px) {
      left: 0;
    }

    ${mediaQueries.forDesktopUp} {
      left: -4%;
      top: 40%;
    }
  }

  ${HeroSlideStyles.Image} {
    min-height: 384px;
    ${mediaQueries.forTabletHorizontalUp} {
      min-height: 300px;
    }
  }
  ${HeroSlideStyles.ImageComponentContainer} {
    min-height: 384px;
    ${mediaQueries.forTabletHorizontalUp} {
      min-height: 300px;
    }
  }
  ${HeroSlideStyles.ImageContainer} {
    min-height: 384px;
    ${mediaQueries.forTabletHorizontalUp} {
      min-height: 300px;
    }
  }
`;
