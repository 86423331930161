import { Medication } from 'common/utils/types';
import React from 'react';

import * as CommonStyles from './../CommonCarePlanStyles';
import * as S from './MedicationDetails.styles';
import MedicationDetailsItem from './MedicationDetailsItem';

type MedicationProps = {
  header: string;
  imageIcon?: string;
  medications: Medication[];
};

function MedicationDetails(props: MedicationProps) {
  const { header, imageIcon, medications } = props;

  return (
    <CommonStyles.Card>
      <CommonStyles.CardHeaderContainer>
        {imageIcon && (
          <CommonStyles.CardIconContainer>
            <CommonStyles.CardIcon src={imageIcon} />
          </CommonStyles.CardIconContainer>
        )}
        <CommonStyles.CardHeader>{header}</CommonStyles.CardHeader>
      </CommonStyles.CardHeaderContainer>
      <S.CardContent>
        <S.CardListContainer>
          {medications.map((medication, index) => (
            <MedicationDetailsItem
              key={`${medication?.name?.replace(' ', '')}${index}`}
              medication={medication}
            />
          ))}
        </S.CardListContainer>
      </S.CardContent>
    </CommonStyles.Card>
  );
}

export default MedicationDetails;
