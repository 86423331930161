import { H3 } from '@everlywell/leaves';
import { createOnDemandTelehealthAppointment } from 'common/apis/telehealthSchedulingApis';
import { BaseRequestError } from 'common/hooks/useApi/request';
import useProgramSlug from 'common/hooks/useProgramSlug';
import useUser from 'common/hooks/useUser';
import analytics from 'common/utils/analytics';
import { MY_APP_ROOT } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { onDemandVisitPurchaseTrack } from 'common/utils/trackers';
import { userPlanId } from 'common/utils/userPlanId';
import Grid from 'components/Grid';
import Layout from 'components/Layout';
import SLUGS from 'pages/InterstitialPage/utils/slugs';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import BookingErrorIcon from './assets/booking_error_icon.svg';
import BookingSuccessIcon from './assets/booking_success_icon.svg';
import OnDemandConfirmationSkeleton from './OnDemandConfirmation.skeleton';
import * as S from './OnDemandConfirmation.styles';

export type OnDemandConfirmationProps = {};

/**
 * This component is used to book the confirmation of an on-demand appointment
 * as well as display the confirmation message or error message.
 */
function OnDemandConfirmation(props: OnDemandConfirmationProps) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { programSlug } = useProgramSlug();
  const program = programSlug ?? '';

  const { user } = useUser();
  const userEmail = user?.email;
  const userId = user?.id;

  const [bookAppointmentParam] = useState(searchParams.get('book_appointment'));

  const planId = userPlanId.planId;

  const {
    mutate: createAppointment,
    data,
    isSuccess,
    isError,
    isLoading,
    error,
  } = useMutation(
    async (program: string) => {
      const [response] = await Promise.allSettled([
        createOnDemandTelehealthAppointment({
          program_slug: program,
          user_plan_id: planId,
        }),
        /**
         * ensure a minimum three seconds of wait time to allow
         * the user to see the action of booking an appointment
         * instead of a flicker of a loading state
         * */
        new Promise((resolve) => setTimeout(resolve, 3000)),
      ]);

      if (response.status === 'rejected') throw response.reason;

      return response.status === 'fulfilled' ? response.value : undefined;
    },
    {
      onSuccess: () => {
        if (program) {
          userPlanId.removePlanId();
          analytics.track({
            event: ANALYTICS.EVENTS.VIEWED_PAGE,
            data: {
              program: program,
              page: ANALYTICS.PAGES.ON_DEMAND_CONFIRMATION,
            },
          });

          if (userEmail && userId) {
            onDemandVisitPurchaseTrack({
              email: userEmail,
              userId: userId,
              program,
            });
          }
        }

        TagManager.dataLayer({
          dataLayer: {
            event: 'onDemandConfirmationPage',
            programName: program,
          },
        });
      },
    },
  );

  useEffect(() => {
    if (searchParams.get('book_appointment') === 'true') {
      searchParams.delete('book_appointment');
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (userId) {
      if (bookAppointmentParam === 'true' && !!program) {
        createAppointment(program);
      } else {
        navigate('/dashboard');
      }
    }
  }, [bookAppointmentParam, createAppointment, navigate, program, userId]);

  if (bookAppointmentParam !== 'true') return null;

  const noShowFeeNote =
    program === SLUGS.STI_ON_DEMAND_PROGRAM_SLUG ? (
      <>
        <strong>Note:</strong> You will be charged a no-show fee at full cost if
        you are unable to make your appointment without canceling.
        <br />
        <br />
      </>
    ) : null;

  return (
    <Layout>
      <S.Container>
        <S.InnerContainer>
          {isLoading && <OnDemandConfirmationSkeleton />}

          {isSuccess && data?.data && (
            <Grid.Container spacing={['md']}>
              <Grid.Item width={[1]} css={{ justifyContent: 'center' }}>
                <img src={BookingSuccessIcon} alt="" />
              </Grid.Item>

              <Grid.Item width={[1]}>
                <H3 as="h1" style={{ margin: 0 }}>
                  Thank you!
                </H3>
              </Grid.Item>

              <Grid.Item width={[1]}>
                <S.Copy>
                  Your Everlywell video appointment request has been received
                  and the first available clinician will reach out to you{' '}
                  <b>within 2 hours.</b>
                </S.Copy>
              </Grid.Item>

              <Grid.Item width={[1]}>
                <S.Copy>
                  You will receive an email and text with a meeting link when
                  it’s time to join your appointment. In the meantime, you can{' '}
                  <S.Link href={data.data.on_demand_link} target="_blank">
                    test your video setup here
                  </S.Link>
                  .
                </S.Copy>
              </Grid.Item>

              <Grid.Item width={[1]}>
                <S.Disclaimer>
                  {noShowFeeNote}
                  If you need to cancel your appointment, you can contact
                  Everlywell partner OpenLoop at <span>
                    +1 (708) 787-4438
                  </span>{' '}
                  Monday-Friday 7 a.m.-7 p.m. CST.
                </S.Disclaimer>
              </Grid.Item>
            </Grid.Container>
          )}

          {isError && (
            <Grid.Container spacing={['md']}>
              <Grid.Item width={[1]} css={{ justifyContent: 'center' }}>
                <img src={BookingErrorIcon} alt="" />
              </Grid.Item>

              <Grid.Item width={[1]}>
                <H3 as="h1" style={{ margin: 0 }}>
                  Sorry!
                </H3>
              </Grid.Item>
              <Grid.Item width={[1]}>
                {(error as BaseRequestError)?.data?.errors?.some(
                  (error: { type: string }) =>
                    error.type === 'outside_business_hours',
                ) ? (
                  <S.Copy>
                    Our on-demand clinicians are only available Monday-Friday, 7
                    a.m.-6 p.m. CST. Please try again during those hours or{' '}
                    <S.Link
                      href={`${MY_APP_ROOT}/telehealth/sessions?program=sti-virtual-care-visit`}
                    >
                      schedule an appointment for later.
                    </S.Link>
                  </S.Copy>
                ) : (
                  <S.Copy>
                    We're currently experiencing technical issues. Please try
                    again. If the issue continues, contact{' '}
                    <S.Link
                      href="https://support.everlywell.com/"
                      target="_blank"
                    >
                      Customer Experience
                    </S.Link>
                    .
                  </S.Copy>
                )}
              </Grid.Item>
            </Grid.Container>
          )}
        </S.InnerContainer>
      </S.Container>
    </Layout>
  );
}

export default OnDemandConfirmation;
