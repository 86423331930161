import { colors, mediaQueries, typography } from '@everlywell/leaves';
import {
  bodyCopy,
  sectionTitle,
  sectionTitleLarge,
} from 'common/styles/app-styles';
import styled from 'styled-components';

const DescriptiveMarkerCard = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 16px 24px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: ${colors.white};
  ${mediaQueries.forTabletVerticalUp} {
    padding: 24px 40px;
    align-items: center;
  }
`;

const MarkerResultContainer = styled.div`
  padding-left: 16px;
  color: ${colors.gray4};
`;

const MarkerResultName = styled.div`
  ${sectionTitle};
  font-weight: ${typography.weight.bold};

  ${mediaQueries.forTabletVerticalUp} {
    ${sectionTitleLarge};
  }
`;

const MarkerResultValue = styled.div`
  ${bodyCopy};
  text-transform: capitalize;
  padding-top: 8px;

  ${mediaQueries.forTabletVerticalUp} {
    ${sectionTitle};
  }
`;

export {
  DescriptiveMarkerCard,
  MarkerResultContainer,
  MarkerResultName,
  MarkerResultValue,
};
