import { typography, size, H4, mediaQueries, Button } from '@everlywell/leaves';
import Grid from 'components/Grid/Grid';
import styled from 'styled-components';

export const Title = styled.h1`
  ${typography.h3Text};
  text-align: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Subtitle = styled(H4)`
  ${size.md};
  text-align: center;
  margin: 0;
`;

export const Description = styled.p`
  ${size.md};
  text-align: left;
`;

export const CancelLink = styled.div`
  a {
    ${size.md};
    color: #000;
    text-decoration: underline;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  font-size: ${size.md}px;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: ${size.xl8}px;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonsContainer = styled(Grid.Container)`
  justify-content: center;
  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: row-reverse;
  }
`;
