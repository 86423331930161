import { Col_10 as Col10 } from 'common/styles/grid-styles';
import { TrackVisibilityWrapper } from 'common/utils/analytics';
import { SEVERITY_VALUE } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import CardsSectionHeader from 'containers/ConsiderChangesSection/CardsSectionHeaderContainer';
import ContentCardCollection from 'containers/ConsiderChangesSection/ContentCardCollectionContainer';
import ListWithIcons from 'containers/ConsiderChangesSection/ListWithIconsContainer';
import SectionHeader from 'containers/ConsiderChangesSection/SectionHeaderContainer';
import SubSectionHeader from 'containers/ConsiderChangesSection/SubSectionHeaderContainer';
import { SeverityContext } from 'containers/KitResultDetailPageContainer';
import * as React from 'react';

import * as S from './styles';

type Props = {
  contentToken: string;
};

function ConsiderChangesSection(props: Props) {
  const { contentToken } = props;

  const severity = React.useContext(SeverityContext);

  // covid indeterminate will not have a card section - hide a wave and hide that section
  if (severity === SEVERITY_VALUE.INDETERMINATE) return null;
  return (
    <div>
      <TrackVisibilityWrapper label={ANALYTICS.LABELS.CONSIDER_CHANGES}>
        <S.ConsiderChangesSectionWrapper>
          <SectionHeader contentToken={contentToken} />

          <ListWithIcons contentToken={contentToken} />
          <S.CardsSection>
            <div>
              <CardsSectionHeader contentToken={contentToken} />
              <Col10>
                <ContentCardCollection contentToken={contentToken} />
              </Col10>
              <Col10>
                <SubSectionHeader contentToken={contentToken} />
              </Col10>
            </div>
          </S.CardsSection>
        </S.ConsiderChangesSectionWrapper>
      </TrackVisibilityWrapper>
    </div>
  );
}

export default ConsiderChangesSection;
