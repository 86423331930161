import { Dropdown, DropdownOptionType } from '@everlywell/leaves';
import { TelehealthInsurancePlan } from 'common/apis/telehealthApis';
import { stateAbbreviationToNames } from 'common/utils/constants/states';
import { formatPhoneNumber } from 'common/utils/helpers';
import { justTheNumbers } from 'components/Forms/validations';
import Grid from 'components/Grid';
import React, { useState, useEffect } from 'react';
import {
  Controller,
  FieldErrors,
  Control,
  UseFormMethods,
} from 'react-hook-form';
import validator from 'validator';

import AddCardImage from '../AddCardImage';
import CarrierField from '../CarrierField';
import * as S from './PolicyForm.styles';

const INSURANCE_TYPES_OPTIONS: DropdownOptionType[] = [
  {
    id: 'none',
    value: '',
    text: 'Select an insurance type',
    isSelected: true,
    isDisabled: true,
  },

  ...[
    'CHAMPVA',
    'FECA Black Lung',
    'Group Health Plan',
    'Medicaid',
    'Medicare',
    'TRICARE CHAMPUS',
    'Other',
  ].map((type) => ({ id: type, value: type, text: type })),
];

const PRIMARY_PRIORITY_TYPE = 'primary';

const PRIORITY_TYPES_OPTIONS: DropdownOptionType[] = [
  {
    id: 'none',
    value: '',
    text: 'Select a type',
    isSelected: true,
    isDisabled: true,
  },
  { id: PRIMARY_PRIORITY_TYPE, value: PRIMARY_PRIORITY_TYPE, text: 'Primary' },
  { id: 'secondary', value: 'secondary', text: 'Secondary' },
  { id: 'inactive', value: 'inactive', text: 'Inactive' },
];
const RELATION_TYPES_OPTIONS: DropdownOptionType[] = [
  { id: 'Self', value: 'Self' },
  { id: 'Child', value: 'Child' },
  { id: 'Spouse', value: 'Spouse' },
  { id: 'None', value: 'None' },
  { id: 'Other', value: 'Other' },
];
const HOLDER_GENDERS_OPTIONS: DropdownOptionType[] = [
  { id: 'Female', value: 'Female' },
  { id: 'Male', value: 'Male' },
];
const COUNTRIES: DropdownOptionType[] = [
  { id: 'US', value: 'US', text: 'United States' },
];

export type PolicyFields = {
  insurance_type: string;
  insurance_plan_id: string;
  priority_type: string;
  holder_relationship: string;
  num: string;
  group_num: string;
  holder_first: string;
  holder_mi: string;
  holder_last: string;
  holder_gender: string;
  holder_dob: string;
  holder_phone: string;
  policy_phone_number: string;
  holder_location: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
  insurance_card_front: File | undefined;
  insurance_card_back: File | undefined;
};

export type PolicyFormErrors = FieldErrors<{
  name: string;
  policies: Array<
    Omit<PolicyFields, 'insurance_card_front' | 'insurance_card_back'> & {
      insurance_card_front?: string;
      insurance_card_back?: string;
    }
  >;
}>;

type FlattenObjectKeys<
  T extends Record<string, unknown>,
  Key = keyof T,
> = Key extends string
  ? T[Key] extends Record<string, unknown>
    ? `${Key}.${FlattenObjectKeys<T[Key]>}`
    : `${Key}`
  : never;

const getFieldName = (
  index: number,
  fieldName: FlattenObjectKeys<PolicyFields>,
): string => `policies[${index}].${fieldName}`;

const getFieldKey = (
  policyId: string | undefined,
  fieldName: FlattenObjectKeys<PolicyFields>,
): string => `${fieldName}.${policyId}`;

export type PolicyFormProps = {
  index: number;
  policy: { id?: string | undefined };
  remove: (index?: number | number[]) => void;
  errors: PolicyFormErrors;
  control: Control<{ policies: Array<PolicyFields> }>;
  insurancePlans: TelehealthInsurancePlan[];
  watch: UseFormMethods<{ policies: Array<PolicyFields> }>['watch'];
  register: UseFormMethods<{ policies: Array<PolicyFields> }>['register'];
};

function PolicyForm({
  index,
  policy,
  register,
  errors,
  remove,
  watch,
  control,
  insurancePlans,
}: PolicyFormProps) {
  const [policyHolderRelation, setPolicyHolderRelation] = useState('');

  const watchPolicies = watch('policies');

  const policyWithPrimaryTypeIndex = watchPolicies.findIndex(
    (policy: PolicyFields) => policy.priority_type === PRIMARY_PRIORITY_TYPE,
  );

  const canListPrimaryType =
    policyWithPrimaryTypeIndex === -1 || policyWithPrimaryTypeIndex === index;

  // Make sure there is only one primary type among all policies
  const priorityTypesOptions = canListPrimaryType
    ? PRIORITY_TYPES_OPTIONS
    : PRIORITY_TYPES_OPTIONS.filter(({ id }) => id !== PRIMARY_PRIORITY_TYPE);

  // Show additional inputs if policy relation is NOT self
  const hideNonSelfPolicyInputs =
    policyHolderRelation === '' ||
    policyHolderRelation.toLowerCase() === 'self';

  const shouldShowInsuranceCards = index === 0;

  useEffect(() => {
    if (shouldShowInsuranceCards) {
      register(getFieldName(index, 'insurance_card_front'), {
        required: 'Please add an insurance card front image',
      });
      register(getFieldName(index, 'insurance_card_back'), {
        required: 'Please add an insurance card back image',
      });
    }
  }, [index, register, shouldShowInsuranceCards]);

  return (
    <div>
      <Grid.Container
        spacing={{
          spacingX: ['lg'],
          spacingY: ['xl2'],
        }}
      >
        {index > 0 && (
          <Grid.Item width={[1]}>
            <S.AddPolicySection>
              <S.AddPolicyLink onClick={() => remove(index)}>
                - Remove policy
              </S.AddPolicyLink>
            </S.AddPolicySection>
          </Grid.Item>
        )}

        {shouldShowInsuranceCards && (
          <>
            <Grid.Item width={[1, 1 / 2]}>
              <Controller
                control={control}
                name={getFieldName(index, 'insurance_card_front')}
                rules={{
                  required: 'Please add an insurance card front image',
                }}
                defaultValue={null}
                render={({ onChange }) => (
                  <AddCardImage
                    id={getFieldName(index, 'insurance_card_front')}
                    name={getFieldName(index, 'insurance_card_front')}
                    label="Card Front"
                    onChange={onChange}
                    error={
                      errors?.policies?.[index]?.insurance_card_front?.message
                    }
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item width={[1, 1 / 2]}>
              <Controller
                control={control}
                name={getFieldName(index, 'insurance_card_back')}
                defaultValue={null}
                rules={{
                  required: 'Please add an insurance card back image',
                }}
                render={({ onChange }) => (
                  <AddCardImage
                    id={getFieldName(index, 'insurance_card_back')}
                    name={getFieldName(index, 'insurance_card_back')}
                    label="Card Back"
                    onChange={onChange}
                    error={
                      errors?.policies?.[index]?.insurance_card_back?.message
                    }
                  />
                )}
              />
            </Grid.Item>
          </>
        )}

        <Grid.Item width={[1]}>
          <Dropdown
            id={getFieldName(index, 'insurance_type')}
            key={getFieldKey(policy.id, 'insurance_type')}
            name={getFieldName(index, 'insurance_type')}
            label="Insurance type"
            items={INSURANCE_TYPES_OPTIONS}
            ref={register({
              required: 'Please select an insurance type',
            })}
            error={errors?.policies?.[index]?.insurance_type?.message}
            showErrorMessage
          />
        </Grid.Item>

        <Grid.Item width={[1]}>
          <Controller
            control={control}
            name={getFieldName(index, 'insurance_plan_id')}
            defaultValue={''}
            rules={{
              required: 'Please select an insurance carrier',
            }}
            render={({ onChange, onBlur }) => (
              <CarrierField
                suggestions={insurancePlans}
                error={errors?.policies?.[index]?.insurance_plan_id?.message}
                onSelect={(item) => onChange(item?.id ?? '')}
                onBlur={onBlur}
              />
            )}
          />
        </Grid.Item>

        <Grid.Item width={[1]}>
          <Dropdown
            id={getFieldName(index, 'priority_type')}
            key={getFieldKey(policy.id, 'priority_type')}
            name={getFieldName(index, 'priority_type')}
            label="Type"
            items={priorityTypesOptions}
            ref={register({
              required: 'Please select a type',
            })}
            error={errors?.policies?.[index]?.priority_type?.message}
            showErrorMessage
          />
        </Grid.Item>

        <Grid.Item width={[1]}>
          <Dropdown
            id={getFieldName(index, 'holder_relationship')}
            key={getFieldKey(policy.id, 'holder_relationship')}
            name={getFieldName(index, 'holder_relationship')}
            label="Relation to policy holder"
            items={RELATION_TYPES_OPTIONS}
            onChange={(e) => setPolicyHolderRelation(e.target.value)}
            ref={register({
              required: 'Please select the policy holder relation',
            })}
            error={errors?.policies?.[index]?.holder_relationship?.message}
            showErrorMessage
          />
        </Grid.Item>

        {!hideNonSelfPolicyInputs && (
          <>
            <Grid.Item width={[1, 1 / 2]}>
              <S.TextInput
                id={getFieldName(index, 'holder_first')}
                key={getFieldKey(policy.id, 'holder_first')}
                name={getFieldName(index, 'holder_first')}
                label="Holder first name"
                placeholder="Nancy"
                ref={register({
                  required: "Please enter the holder's first name",
                })}
                error={errors?.policies?.[index]?.holder_first?.message}
              />
            </Grid.Item>
            <Grid.Item width={[1, 1 / 2]}>
              <S.TextInput
                id={getFieldName(index, 'holder_mi')}
                key={getFieldKey(policy.id, 'holder_mi')}
                name={getFieldName(index, 'holder_mi')}
                label="Holder middle name"
                placeholder="E"
                ref={register()}
              />
            </Grid.Item>
            <Grid.Item width={[1, 1 / 2]}>
              <S.TextInput
                id={getFieldName(index, 'holder_last')}
                key={getFieldKey(policy.id, 'holder_last')}
                name={getFieldName(index, 'holder_last')}
                label="Holder last name"
                placeholder="Wheeler"
                ref={register({
                  required: "Please enter the holder's last name",
                })}
                error={errors?.policies?.[index]?.holder_last?.message}
              />
            </Grid.Item>
            <Grid.Item width={[1, 1 / 2]}>
              <S.TextInput
                id={getFieldName(index, 'holder_dob')}
                key={getFieldKey(policy.id, 'holder_dob')}
                name={getFieldName(index, 'holder_dob')}
                label="Holder date of birth"
                placeholder="mm/dd/yyyy"
                type="date"
                role="textbox"
                ref={register({
                  required: "Please enter the holder's date of birth",
                })}
                error={errors?.policies?.[index]?.holder_dob?.message}
              />
            </Grid.Item>
            <Grid.Item width={[1, 1 / 2]}>
              <S.DropdownMenu
                id={getFieldName(index, 'holder_gender')}
                key={getFieldKey(policy.id, 'holder_gender')}
                name={getFieldName(index, 'holder_gender')}
                label="Holder gender"
                placeholderText="Select"
                items={HOLDER_GENDERS_OPTIONS}
                ref={register({
                  required: "Please select the holder's gender",
                })}
                error={errors?.policies?.[index]?.holder_gender?.message}
                showErrorMessage
              />
            </Grid.Item>
            <Grid.Item width={[1, 1 / 2]}>
              <Controller
                control={control}
                name={getFieldName(index, 'holder_phone')}
                key={getFieldKey(policy.id, 'holder_phone')}
                defaultValue=""
                rules={{
                  required: "Please enter the holder's phone number",
                  validate: {
                    isPhoneNumber: phoneNumberValidator,
                  },
                }}
                render={(props) => (
                  <S.TextInput
                    id={props.name}
                    name={props.name}
                    label="Holder phone number"
                    placeholder="(000) 000-0000"
                    ref={props.ref}
                    onChange={(e) => props.onChange(getPhoneNumberValue(e))}
                    onBlur={props.onBlur}
                    value={props.value}
                    error={errors?.policies?.[index]?.holder_phone?.message}
                  />
                )}
              />
            </Grid.Item>
          </>
        )}

        <Grid.Item width={[1, 1 / 2]}>
          <S.TextInput
            id={getFieldName(index, 'num')}
            key={getFieldKey(policy.id, 'num')}
            name={getFieldName(index, 'num')}
            label="Member ID"
            placeholder="DZW9100000"
            type="text"
            ref={register({
              required: 'Please enter the member ID',
            })}
            error={errors?.policies?.[index]?.num?.message}
          />
        </Grid.Item>
        <Grid.Item width={[1, 1 / 2]}>
          <S.TextInput
            id={getFieldName(index, 'group_num')}
            key={getFieldKey(policy.id, 'group_num')}
            name={getFieldName(index, 'group_num')}
            label="Group ID"
            placeholder="9701021777"
            type="text"
            ref={register({
              required: 'Please enter the group ID',
            })}
            error={errors?.policies?.[index]?.group_num?.message}
          />
        </Grid.Item>

        {!hideNonSelfPolicyInputs && (
          <>
            <Grid.Item width={[1, 1 / 2]}>
              <Controller
                control={control}
                name={getFieldName(index, 'policy_phone_number')}
                key={getFieldKey(policy.id, 'policy_phone_number')}
                defaultValue=""
                rules={{
                  required: 'Please enter the insurance company phone number',
                  validate: {
                    isPhoneNumber: phoneNumberValidator,
                  },
                }}
                render={(props) => (
                  <S.TextInput
                    id={props.name}
                    name={props.name}
                    label="Insurance company phone"
                    placeholder="(000) 000-0000"
                    ref={props.ref}
                    onChange={(e) => props.onChange(getPhoneNumberValue(e))}
                    onBlur={props.onBlur}
                    value={props.value}
                    error={
                      errors?.policies?.[index]?.policy_phone_number?.message
                    }
                  />
                )}
              />
            </Grid.Item>
            <Grid.Item width={[1, 1 / 2]}>
              <S.TextInput
                id={getFieldName(index, 'holder_location.line1')}
                key={getFieldKey(policy.id, 'holder_location.line1')}
                name={getFieldName(index, 'holder_location.line1')}
                label="Address line 1"
                placeholder="123 Broad Street"
                ref={register({
                  required: 'Please enter the address line 1',
                })}
                error={
                  errors?.policies?.[index]?.holder_location?.line1?.message
                }
              />
            </Grid.Item>
            <Grid.Item width={[1, 1 / 2]}>
              <S.TextInput
                id={getFieldName(index, 'holder_location.line2')}
                key={getFieldKey(policy.id, 'holder_location.line2')}
                name={getFieldName(index, 'holder_location.line2')}
                label="Address line 2"
                placeholder="Suite 850"
                ref={register()}
              />
            </Grid.Item>
            <Grid.Item width={[1, 1 / 2]}>
              <S.TextInput
                id={getFieldName(index, 'holder_location.city')}
                key={getFieldKey(policy.id, 'holder_location.city')}
                name={getFieldName(index, 'holder_location.city')}
                label="City"
                placeholder="Ex: New York"
                ref={register({
                  required: 'Please enter a city',
                })}
                error={
                  errors?.policies?.[index]?.holder_location?.city?.message
                }
              />
            </Grid.Item>
            <Grid.Item width={[1, 1 / 3]}>
              <S.DropdownMenu
                id={getFieldName(index, 'holder_location.state')}
                key={getFieldKey(policy.id, 'holder_location.state')}
                name={getFieldName(index, 'holder_location.state')}
                label="State/province"
                placeholderText="Select state"
                items={Object.entries(stateAbbreviationToNames).map(
                  ([abbreviation, stateName]) => ({
                    id: abbreviation,
                    value: abbreviation,
                    text: stateName,
                  }),
                )}
                ref={register({
                  required: 'Please select the state',
                })}
                error={
                  errors?.policies?.[index]?.holder_location?.state?.message
                }
                showErrorMessage
              />
            </Grid.Item>
            <Grid.Item width={[1, 1 / 3]}>
              <S.TextInput
                id={getFieldName(index, 'holder_location.zip')}
                key={getFieldKey(policy.id, 'holder_location.zip')}
                name={getFieldName(index, 'holder_location.zip')}
                label="Zip code"
                placeholder="12345"
                ref={register({
                  required: 'Please enter the zip code',
                })}
                error={errors?.policies?.[index]?.holder_location?.zip?.message}
              />
            </Grid.Item>
            <Grid.Item width={[1, 1 / 3]}>
              <Dropdown
                id={getFieldName(index, 'holder_location.country')}
                key={getFieldKey(policy.id, 'holder_location.country')}
                name={getFieldName(index, 'holder_location.country')}
                label="Country"
                items={COUNTRIES}
                ref={register({
                  required: 'Please select the country',
                })}
                error={
                  errors?.policies?.[index]?.holder_location?.country?.message
                }
                showErrorMessage
              />
            </Grid.Item>
          </>
        )}
      </Grid.Container>
    </div>
  );
}

export default PolicyForm;

const phoneNumberValidator = (value: string) => {
  if (justTheNumbers(value ?? '').length < 10) {
    return 'Please complete your phone number';
  }

  if (!validator.isMobilePhone(value)) {
    return 'Please enter a valid phone number';
  }

  return true;
};

const getPhoneNumberValue = (e: React.ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value;

  return value ? formatPhoneNumber(justTheNumbers(value)) : value;
};
