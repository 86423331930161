import {
  AppointmentPeriodType,
  useTelehealthAppointments,
} from 'common/apis/telehealthApis';
import { uniqueId } from 'lodash';
import AppointmentList from 'pages/AccountHub/pages/TelehealthDashboard/components/TelehealthAppointments/components/AppointmentList';
import React from 'react';

import * as S from './MyAppointments.styles';

// Types ----------

interface MyAppointmentsProps {
  title: string;
  filterProgramSlugs?: string[];
  period: AppointmentPeriodType;
}

// MyAppointments ------

const MyAppointments = ({
  title,
  filterProgramSlugs,
  period,
}: MyAppointmentsProps): JSX.Element | null => {
  const {
    data: response,
    isSuccess,
    isLoading,
  } = useTelehealthAppointments({
    period,
    sort_by: 'date_asc',
    program_slugs: filterProgramSlugs,
  });
  const upcomingRegionAria = uniqueId('upcoming-region-');

  const appointments = response?.data?.appointments ?? [];
  const [firstAppointment] = appointments;

  return isSuccess && firstAppointment && !isLoading ? (
    <S.Wrapper>
      <S.Head>
        <S.Title>{title}</S.Title>
      </S.Head>

      <AppointmentList
        filterProgramSlugs={filterProgramSlugs}
        appointmentType={period === 'future' ? 'upcoming' : 'completed'}
        labelledBy={upcomingRegionAria}
        isExpandable={true}
      />
    </S.Wrapper>
  ) : null;
};

// Export ---------

export default React.memo(MyAppointments);
