import { ImmutableReduxState } from 'common/utils/types';
import Footer from 'components/Footer';
import Header from 'containers/Header';
import Notification from 'containers/Notification';
import { isAthenaCarePlanPageUrl } from 'pages/AthenaCarePlanPage/helpers';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import { makeSelectLoading, makeSelectMembership } from 'store/selectors';

import * as S from './Layout.styles';

export type LayoutProps = {
  children: React.ReactNode;
  doNotHideFooter?: boolean;
  loading?: boolean;
  subHeader?: boolean | React.ReactNode;
};

/**
 * This component manages the layout of the application, adding
 * the header and footer with the children passed as props.
 */
function Layout(props: LayoutProps) {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const embedded =
    searchParams.get('embedded') === 'true' ||
    sessionStorage.getItem('embedded') === 'true';

  useEffect(() => {
    sessionStorage.setItem('embedded', embedded.toString());
  }, [embedded]);

  const globalLoading = useSelector((state: ImmutableReduxState) =>
    makeSelectLoading()(state),
  );
  const membership = useSelector((state: ImmutableReduxState) =>
    makeSelectMembership()(state),
  );

  const inMobileWebview =
    embedded || window.navigator.userAgent.includes('com.everlyhealth.ios');

  const isAthenaCarePlanPage = isAthenaCarePlanPageUrl(location.pathname);

  const {
    children,
    subHeader,
    doNotHideFooter,
    loading: loadingOverride,
  } = props;
  const loading =
    loadingOverride !== undefined ? loadingOverride : globalLoading;

  return (
    <S.Container
      inMobileWebview={inMobileWebview}
      isAthenaCarePlanPage={isAthenaCarePlanPage}
    >
      <Notification />
      {inMobileWebview ? null : (
        <Header
          subHeader={subHeader}
          activeMembership={
            membership ? membership.activeMembership : undefined
          }
          numOfMemberCredits={
            membership ? membership.numOfMemberCredits : undefined
          }
          membershipState={membership ? membership.state : undefined}
        />
      )}
      {children}
      {(!loading || doNotHideFooter) && !inMobileWebview && <Footer />}
    </S.Container>
  );
}

export default Layout;
