import { PrimaryButtonStyle } from '@everlywell/leaves';
import { H4 } from '@everlywell/leaves';
import { colors, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Title = styled(H4)`
  font-weight: ${typography.weight.regular};
  margin-bottom: 1rem;
`;

const Body = styled.div`
  ${typography.captionText};
  margin-bottom: 1.5rem;

  a {
    font-weight: ${typography.weight.regular};
    color: ${colors.green6};
    text-decoration: none;
  }

  p:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const Footer = styled.div`
  width: 100%;
`;

const Link = styled.a`
  ${PrimaryButtonStyle};
  cursor: pointer;
  width: 100%;
`;

export { Link, Container, Title, Body, Footer };
