/* eslint-disable @typescript-eslint/no-var-requires */
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import React from 'react';

import * as S from './SocialLink.styles';

type Props = {
  href: string;
  color: string;
  icon: string;
  children: string;
};

type SVGProps = {
  src: string;
  fill: string;
  style?: {};
};

function SocialLink(props: Props) {
  const { href, color, icon, children } = props;
  const shareText = `Share on ${children}`;

  const handleClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: shareText,
        category: ANALYTICS.CATEGORIES.SHARE_RESULTS,
      },
    });
  };

  return (
    <S.Wrapper target="_blank" href={href} color={color} onClick={handleClick}>
      <S.Icon>
        <SVG src={icon} fill={color} />
      </S.Icon>
      <span className="short-text">{children}</span>
      <span className="long-text">{shareText}</span>
    </S.Wrapper>
  );
}

function SVG({ src, fill, style }: SVGProps) {
  return (
    <S.SvgWrapper
      fill={fill}
      style={style}
      dangerouslySetInnerHTML={{
        __html: src,
      }}
    />
  );
}

export default SocialLink;
