import {
  Container,
  size,
  mediaQueries,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const PreRegistrationWrapper = styled(Container)`
  padding-bottom: ${size.xl1}px;

  ${mediaQueries.forTabletHorizontalUp} {
    display: flex;
    justify-content: space-around;
    padding-top: ${size.md}px;
    padding-bottom: 220px;
  }
  ${mediaQueries.forTabletHorizontalDown} {
    padding-top: ${size.xl1}px;
  }
`;