import { showConsultForName } from './helpers';
import { KitResult } from './types';

export const hasTelehealthConsult = (
  kitResult: KitResult,
): boolean => {
  const {
    test,
    pwn_order_number,
    pwn_confirmation_code,
  } = kitResult;
  return (
    (pwn_order_number && pwn_confirmation_code && test?.name && showConsultForName(kitResult, test.name)) || false
  );
};
