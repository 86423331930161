import { Button as RawButton, size, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

const Button = styled(RawButton)`
  margin-top: ${size.xl1}px;
  text-decoration: none !important;
  ${mediaQueries.forTabletHorizontalUp} {
    margin-top: 0;
    margin-bottom: ${size.md}px;
  }
`;

export { Button };
