import { ChildProps } from '@everlywell/leaves';
import { TelehealthMedication } from 'common/apis/telehealthApis';
import React from 'react';

import * as S from './CardContent.styles';

export type CardContentProps = Omit<ChildProps, 'children'> & {
  medication: TelehealthMedication;
};

/**
 * It contains the extra information for medication.
 * It is the Child component for the accordion
 */
function CardContent({ medication }: CardContentProps) {
  const { pharmacy, directions } = medication;
  return (
    <S.Container>
      <S.HR />
      <S.SubHeading>Instructions</S.SubHeading>
      <S.Details>{directions}</S.Details>
      <S.HighlightedBox>
        <S.SubHeading>Pharmacy location</S.SubHeading>

        <S.Details>
          {pharmacy.name}
          <br />
          {pharmacy.line1}
          {pharmacy.line2 && (
            <>
              <br />
              {pharmacy.line2}
            </>
          )}
          <br />
          {pharmacy.city}, {pharmacy.state} {pharmacy.zip}
        </S.Details>
      </S.HighlightedBox>
    </S.Container>
  );
}

export default CardContent;
