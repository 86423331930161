import React from 'react';

import useWeightMembership from '../useWeightMembership';
import * as S from './styles';

const DataContainer = () => {
  const { weightMembershipData } = useWeightMembership();
  const { type, monthlyPrice, nextBillingDate, enrolled } =
    weightMembershipData || {};

  return (
    <S.DataMainContainer>
      <S.UpContentContainer>
        <S.Plan>
          {type}
          <S.MonthlyPrice>
            ${monthlyPrice}/<span data-isolate>month</span>
          </S.MonthlyPrice>
        </S.Plan>
        <S.Enrolled>
          <S.EnrolledTitle>Enrolled on:</S.EnrolledTitle>
          {enrolled}
        </S.Enrolled>
      </S.UpContentContainer>
      <div>
        <S.NextBillingDate>
          <S.BillingTitle>Next billing date:</S.BillingTitle>
          {nextBillingDate}
        </S.NextBillingDate>
      </div>
    </S.DataMainContainer>
  );
};

export default DataContainer;
