import { getKitResults } from 'common/apis/kitResultApis';
import ApolloContainer from 'containers/ApolloContainer';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

import TestKitCard from './KitCard';
import * as S from './MyTestKits.styles';

// Types ----------

interface MyTestKitsProps {}

// MyTestKits ------

const MyTestKits: React.FC<MyTestKitsProps> = (props) => {
  const LIST_SIZE = {
    collapsed: 3,
    expanded: 10,
  };

  const WCPlusTestKitSlugs = [
    'cholesterol-and-lipids-test',
    'hba1c',
    'heart-health-test',
  ];

  const [limit, setLimit] = useState(LIST_SIZE.collapsed);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setLimit(isExpanded ? LIST_SIZE.collapsed : LIST_SIZE.expanded);
    setIsExpanded(!isExpanded);
  };

  const page = 1;

  const { data: response } = useQuery(['kit-results', page], () =>
    getKitResults(page),
  );

  const filteredKits = response?.data?.filter((kit) => {
    if (kit.status === 'canceled') {
      return false;
    }

    return !!WCPlusTestKitSlugs.find((slug) => slug === kit.product_slug);
  });

  return (
    <ApolloContainer>
      <S.Wrapper>
        <S.Head>
          <S.Title>My test kits</S.Title>
          <S.ViewAll href="/results" appearance="text">
            View all
          </S.ViewAll>
        </S.Head>

        <S.TestKitList>
          {filteredKits?.map((kit, index) =>
            index < limit ? (
              <TestKitCard
                key={kit.id}
                title={kit.product_name}
                imageURL={kit.box_image_url}
                registerDate={kit.kit_registered_at}
              />
            ) : null,
          )}
        </S.TestKitList>

        {filteredKits && filteredKits?.length > LIST_SIZE.collapsed ? (
          <S.ViewMoreSection>
            <S.ViewMoreButton appearance="text" onClick={toggleExpand}>
              {isExpanded ? 'Hide history' : 'View history'}
              <S.Caret isExpanded={isExpanded} style={{ width: '12px' }} />
            </S.ViewMoreButton>
          </S.ViewMoreSection>
        ) : null}
      </S.Wrapper>
    </ApolloContainer>
  );
};

// Export ---------

export default React.memo(MyTestKits);
