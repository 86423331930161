import { Col } from '@everlywell/leaves';
import { COVID_SCREENER_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';
import { COVID_SCREENER_STEPS } from 'common/utils/constants/rumActions';
import { KitRegistrationUser } from 'common/utils/types';
import useTimeTracker from 'common/utils/useTimeTracker';
import React from 'react';

import BaseQuestion from '../../../BaseQuestion';
import * as S from '../../../BaseQuestion/styles';
import AddressInformation from '../../TestInformationForm/AddressInformation';
import { NextButton, BackButton, LeftCaretIcon } from '../styles';

interface Props {
  user: KitRegistrationUser;
  totalQuestions: number;
  questionNumber: number;
  handleNext: (action: any) => void;
  handleBack: (action: any) => void;
}

function NewYorkQuestions({
  user,
  totalQuestions,
  questionNumber,
  handleNext,
  handleBack,
}: Props) {
  const fireAction = useTimeTracker(COVID_SCREENER_STEPS.NEW_YORK_STATE);
  const handleButtonDisabled = () => user.newYorkResident.value === '';
  const newYorkResidentAnswers = [
    {
      id: 'yes-new-york-resident',
      label: 'Yes',
    },
    {
      id: 'no-new-york-resident',
      label: 'No',
    },
  ];
  const newYorkEmploymentAnswers = [
    {
      id: 'yes-new-york-employed',
      label: 'Yes',
    },
    {
      id: 'no-new-york-employed',
      label: 'No',
    },
  ];
  const newYorkStudentAnswers = [
    {
      id: 'yes-new-york-student',
      label: 'Yes',
    },
    {
      id: 'no-new-york-student',
      label: 'No',
    },
  ];

  return (
    <Col data-test={`${DATA_TEST.CONTAINER}-new-york-questions`}>
      <BackButton onClick={() => handleBack(fireAction)}>
        <LeftCaretIcon /> Back
      </BackButton>
      <BaseQuestion
        question="Are you currently living in the state of NY?"
        questionNumber={questionNumber}
        totalQuestions={totalQuestions}
        answers={newYorkResidentAnswers}
        value={user.newYorkResident.value}
        setValue={user.newYorkResident.setValue}
        className="new-york-resident"
      />
      {user.newYorkResident.value === 'yes-new-york-resident' ? (
        <>
          <S.AnswerBox
            className="new-york-employment"
            data-test={`${DATA_TEST.CONTAINER}-new-york-employment`}
          >
            <S.Question>
              Are you currently employed in the state of NY?
            </S.Question>
            {newYorkEmploymentAnswers.map((answer) => (
              <S.Answer
                className="inspectletIgnore"
                key={answer.id}
                id={answer.id}
                checked={user.newYorkEmployment.value === answer.id}
                name={answer.id}
                label={answer.label}
                onChange={() => user.newYorkEmployment.setValue(answer.id)}
                data-test={`${DATA_TEST.INPUT}-${answer.id}`}
              />
            ))}
          </S.AnswerBox>
          {user.newYorkEmployment.value === 'yes-new-york-employed' ? (
            <S.AnswerBox
              className="new-york-employer-info"
              data-test={`${DATA_TEST.CONTAINER}-new-york-employer-info`}
            >
              <S.NewYorkLabel
                id="new-york-employer-name"
                label="Employer Name"
                type="text"
                name="employer-name"
                value={user.employerName.value}
                onChange={(e: { target: { value: string } }) =>
                  user.employerName.setValue(e.target.value)
                }
              />
              <AddressInformation
                streetAddress={user.employerStreetAddress}
                subAddress={user.employerSubAddress}
                city={user.employerCity}
                state={user.employerState}
                zipCode={user.employerZipCode}
              />
            </S.AnswerBox>
          ) : null}
          <S.AnswerBox className="new-york-student-status">
            <S.Question>
              Do you currently attend or volunteer at a school, college, or
              university in the state of NY?
            </S.Question>
            {newYorkStudentAnswers.map((answer) => (
              <S.Answer
                key={answer.id}
                id={answer.id}
                checked={user.newYorkStudent.value === answer.id}
                name={answer.id}
                label={answer.label}
                className="inspectletIgnore"
                onChange={() => user.newYorkStudent.setValue(answer.id)}
                data-test={`${DATA_TEST.INPUT}-${answer.id}`}
              />
            ))}
          </S.AnswerBox>
          {user.newYorkStudent.value === 'yes-new-york-student' ? (
            <S.AnswerBox className="new-york-school-info">
              <S.NewYorkLabel
                id="new-york-school-name"
                label="School Name"
                type="text"
                name="school-name"
                value={user.newYorkSchoolName.value}
                onChange={(e: { target: { value: string } }) =>
                  user.newYorkSchoolName.setValue(e.target.value)
                }
              />
              <AddressInformation
                streetAddress={user.schoolStreetAddress}
                subAddress={user.schoolSubAddress}
                city={user.schoolCity}
                state={user.schoolState}
                zipCode={user.schoolZipCode}
              />
            </S.AnswerBox>
          ) : null}
        </>
      ) : null}
      <NextButton
        isDisabled={handleButtonDisabled()}
        appearance="primary"
        onClick={() => handleNext(fireAction)}
        data-test={`${DATA_TEST.BUTTON}-next`}
      >
        Next
      </NextButton>
    </Col>
  );
}
export default NewYorkQuestions;
