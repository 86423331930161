import { Col } from '@everlywell/leaves';
import { invisibleInkSKUS } from 'common/utils/constants';
import Steps from 'components/Steps';
import { StepsIconType } from 'components/Steps/model';
import React from 'react';

import { TestCollectionMethod, bloodCollectionMethods } from '../model';
import { ADXSteps } from './data/adx.data';
import { DBADXSteps } from './data/db-adx.data';
import { DBSIISteps } from './data/dbs-ii.data';
import { DBSSteps } from './data/dbs.data';
import * as S from './styles';

const instructionMap = {
  [TestCollectionMethod.blood]: DBSSteps,
  [TestCollectionMethod.blood_adx100]: ADXSteps,
  [TestCollectionMethod.blood_double_strip_adx]: DBADXSteps,
  [TestCollectionMethod.blood_dbs_ii]: DBSIISteps,
};

type Props = {
  methods: TestCollectionMethod[];
  sku?: string;
};

const targetMethod = ({ methods, sku }: Props) => {
  if (sku && invisibleInkSKUS.includes(sku)) {
    return TestCollectionMethod.blood_dbs_ii;
  }

  return (
    bloodCollectionMethods.find((collectionMethod) =>
      methods.includes(collectionMethod),
    ) || TestCollectionMethod.blood
  );
};

const MailingSample = (props: Props) => {
  const targetCollectionMethod = targetMethod(props);

  return (
    <S.Wrapper center="xs" middle="xs">
      <Col xs={10} md={12} lg={8}>
        <S.Title>Before mailing your sample, make sure to:</S.Title>

        <Steps
          list={instructionMap[targetCollectionMethod]}
          icon={StepsIconType.check}
        />
      </Col>
    </S.Wrapper>
  );
};

export default MailingSample;
