/**
 * returns a boolean value
 * @param email a string to check against regex
 */
export function isValidEmail(email: string) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );
}

/**
 * Checks if a given ZIP code is located in New York.
 *
 * @param {string} value - The ZIP code to check.
 * @returns {boolean} Returns `true` if the ZIP code is not in New York, otherwise `false`.
 *
 */
export const isZipCodeNotInNy = (value: string) => {
  const zipCode = parseInt(value);
  const isNY =
    (zipCode >= 10000 && zipCode <= 14999) ||
    zipCode === 6390 ||
    zipCode === 501 ||
    zipCode === 544;

  return !isNY;
};
