import {
  size,
  colors,
  Accordion,
  mediaQueries,
  typography,
  UpCaret,
  Button,
} from '@everlywell/leaves';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const AppointmentsContainer = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: ${size.md}px;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  display: flex;
  width: 100%;
`;

export const AccordionHeading = styled(Accordion.Heading)`
  width: auto;
  margin: auto;
  gap: ${size.md}px;
  padding: 0;
`;

export const AccordionChild = styled(Accordion.Child)`
  padding: 0;
`;

export const NoResultsText = css`
  ${typography.bodyText}
  color: ${colors.black};
  font-weight: ${typography.weight.book};

  ${mediaQueries.forPhoneOnly} {
    text-align: center;
    font-size: 21px;
    line-height: 30px;
  }
`;

export const Caret = styled(UpCaret)<{ isExpanded: boolean }>`
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(deg)' : 'rotate(180deg)'};
`;

export const ViewMoreButton = styled(Button)`
  margin: auto;
`;
