import { Container, size, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

const ReplacementWrapper = styled(Container)`
  padding-bottom: ${size.xl1}px;
  ${mediaQueries.forTabletHorizontalUp} {
    display: flex;
    justify-content: space-around;
    padding-top: 185px;
    padding-bottom: 220px;
  }
`;

const DesktopGraphic = styled.img`
  display: none;
  ${mediaQueries.forTabletHorizontalUp} {
    display: inline-block;
    align-self: flex-start;
  }
`;

const ContentContainer = styled.div`
  ${mediaQueries.forTabletHorizontalUp} {
    max-width: 532px;
  }
`;

const MobileGraphic = styled.img`
  margin-top: ${size.xl2}px;
  width: 100%;
  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

export {
  ReplacementWrapper,
  DesktopGraphic,
  ContentContainer,
  ContentWrapper,
  MobileGraphic,
};
