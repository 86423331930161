import { FactCollectionItem } from 'common/utils/contentfulGraphQlQueries';

type DataType = { factCollection: { items: FactCollectionItem[] } };

export default {
  factCollection: {
    items: [
      {
        copy: {
          json: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'More than ',
                    nodeType: 'text',
                  },
                  {
                    data: {
                      uri: 'https://everlywell-mlr.ziflow.io/proof/cqc07vcl1h1stsbgdf55scj7d',
                    },
                    content: [
                      {
                        data: {},
                        marks: [],
                        value: '12 percent¹',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'hyperlink',
                  },
                  {
                    data: {},
                    marks: [],
                    value:
                      ' of the U.S. population will develop a thyroid condition.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
        },
        icon: {
          title: 'Thyroid disorder SVG',
          description: 'Digestive Disorder SVG',
          contentType: 'image/svg+xml',
          fileName: 'Group 797.svg',
          size: 1958,
          url: 'https://images.ctfassets.net/ydyvnem5zkxh/4R0kjOy2hlbX2zMEFwhrD0/424dbe61157c959a560366bb770e1c21/Group_797.svg',
          width: 40,
          height: 40,
        },
        slug: 'thyroid-disorder-1',
        source1: '¹ American Thyroid Association',
        source1Url: 'https://www.thyroid.org/media-main/press-room/',
        source2: null,
        source2Url: null,
        title: 'Thyroid health',
      },
      {
        copy: {
          json: {
            nodeType: 'document',
            data: {},
            content: [
              {
                nodeType: 'paragraph',
                content: [
                  {
                    nodeType: 'text',
                    value: 'Women are ',
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: 'hyperlink',
                    content: [
                      {
                        nodeType: 'text',
                        value: 'five to eight',
                        marks: [],
                        data: {},
                      },
                    ],
                    data: {
                      uri: 'https://www.thyroid.org/media-main/press-room/',
                    },
                  },
                  {
                    nodeType: 'text',
                    value:
                      '¹ times more likely than men to have problems with their thyroid. ',
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        icon: {
          title: 'Thyroid disorder SVG',
          description: 'Digestive Disorder SVG',
          contentType: 'image/svg+xml',
          fileName: 'Group 797.svg',
          size: 1958,
          url: 'https://images.ctfassets.net/ydyvnem5zkxh/4R0kjOy2hlbX2zMEFwhrD0/424dbe61157c959a560366bb770e1c21/Group_797.svg',
          width: 40,
          height: 40,
        },
        slug: 'thyroid-disorder-2',
        source1: '¹ American Thyroid Association',
        source1Url: 'https://www.thyroid.org/media-main/press-room/',
        source2: null,
        source2Url: null,
        title: 'Thyroid health',
      },
      {
        copy: {
          json: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: '',
                    nodeType: 'text',
                  },
                  {
                    data: {
                      uri: 'https://www.ncbi.nlm.nih.gov/labs/pmc/articles/PMC5556586/',
                    },
                    content: [
                      {
                        data: {},
                        marks: [],
                        value: 'Mindful eating¹',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'hyperlink',
                  },
                  {
                    data: {},
                    marks: [],
                    value:
                      ' is an approach to food that focuses an individual on being present with their meal. It ',
                    nodeType: 'text',
                  },
                  {
                    data: {
                      uri: 'https://www.ncbi.nlm.nih.gov/labs/pmc/articles/PMC7219460/',
                    },
                    content: [
                      {
                        data: {},
                        marks: [],
                        value: 'may help²',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'hyperlink',
                  },
                  {
                    data: {},
                    marks: [],
                    value:
                      ' regulate stress responses and improve digestive function.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
        },
        icon: {
          title: 'Digestive Disorder SVG',
          description: 'Digestive Disorder SVG',
          contentType: 'image/svg+xml',
          fileName: 'Group 797.svg',
          size: 917,
          url: 'https://images.ctfassets.net/ydyvnem5zkxh/6NaKMdjtb8quPt5dOrvXKw/c80dacd23cf391e3e01ed77719311565/Group_797.svg',
          width: 40,
          height: 40,
        },
        slug: 'digestive-disorder-2',
        source1: '¹ NIH National Library of Medicine',
        source1Url:
          'https://www.ncbi.nlm.nih.gov/labs/pmc/articles/PMC5556586/',
        source2: '² NIH National Library of Medicine',
        source2Url:
          'https://www.ncbi.nlm.nih.gov/labs/pmc/articles/PMC7219460/',
        title: 'Digestive health',
      },
      {
        copy: {
          json: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'Stress ',
                    nodeType: 'text',
                  },
                  {
                    data: {
                      uri: 'https://www.ncbi.nlm.nih.gov/labs/pmc/articles/PMC7219460/',
                    },
                    content: [
                      {
                        data: {},
                        marks: [],
                        value: 'can exacerbate¹ ',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'hyperlink',
                  },
                  {
                    data: {},
                    marks: [],
                    value:
                      'some digestive disorders. Identifying and managing stressors ',
                    nodeType: 'text',
                  },
                  {
                    data: {
                      uri: 'https://www.eatright.org/health/wellness/digestive-health/irritable-bowel-syndrome',
                    },
                    content: [
                      {
                        data: {},
                        marks: [],
                        value: 'may help²',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'hyperlink',
                  },
                  {
                    data: {},
                    marks: [],
                    value: ' to ease uncomfortable symptoms.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
        },
        icon: {
          title: 'Digestive Disorder SVG',
          description: 'Digestive Disorder SVG',
          contentType: 'image/svg+xml',
          fileName: 'Group 797.svg',
          size: 917,
          url: 'https://images.ctfassets.net/ydyvnem5zkxh/6NaKMdjtb8quPt5dOrvXKw/c80dacd23cf391e3e01ed77719311565/Group_797.svg',
          width: 40,
          height: 40,
        },
        slug: 'digestive-disorder-1',
        source1: '¹ NIH National Library of Medicine',
        source1Url:
          'https://www.ncbi.nlm.nih.gov/labs/pmc/articles/PMC7219460/',
        source2: '² Academy of Nutrition and Dietetics',
        source2Url:
          'https://www.eatright.org/health/wellness/digestive-health/irritable-bowel-syndrome',
        title: 'Digestive health',
      },
      {
        copy: {
          json: {
            nodeType: 'document',
            data: {},
            content: [
              {
                nodeType: 'paragraph',
                content: [
                  {
                    nodeType: 'text',
                    value:
                      'Good sleep hygiene, getting ample physical activity, eating healthy food, avoiding triggers, and managing stress ',
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: 'hyperlink',
                    content: [
                      {
                        nodeType: 'text',
                        value: 'may help decrease',
                        marks: [],
                        data: {},
                      },
                    ],
                    data: {
                      uri: 'https://americanmigrainefoundation.org/resource-library/lifestyle-changes-for-migraine/',
                    },
                  },
                  {
                    nodeType: 'text',
                    value: '',
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: 'hyperlink',
                    content: [
                      {
                        nodeType: 'text',
                        value: '¹',
                        marks: [],
                        data: {},
                      },
                    ],
                    data: {
                      uri: 'https://americanmigrainefoundation.org/resource-library/lifestyle-changes-for-migraine/',
                    },
                  },
                  {
                    nodeType: 'text',
                    value: ' how often you get ',
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: 'hyperlink',
                    content: [
                      {
                        nodeType: 'text',
                        value: '',
                        marks: [],
                        data: {},
                      },
                    ],
                    data: {
                      uri: 'https://americanmigrainefoundation.org/',
                    },
                  },
                  {
                    nodeType: 'text',
                    value: 'migraines.',
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        icon: {
          title: 'Migraine icon',
          description: 'Migrate icon',
          contentType: 'image/png',
          fileName: 'migrane-Icon.png',
          size: 4584,
          url: 'https://images.ctfassets.net/ydyvnem5zkxh/1ijBSSP3iLfdBNiCXnjoaT/81939e30d74c89e255f3e998f8614b16/migrane-Icon.png',
          width: 140,
          height: 140,
        },
        slug: 'migraines-1',
        source1: '¹ American Migraine Foundation',
        source1Url:
          'https://americanmigrainefoundation.org/resource-library/lifestyle-changes-for-migraine/',
        source2: null,
        source2Url: null,
        title: 'Migraine health',
      },
    ],
  },
} as DataType;
