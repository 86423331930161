import React from 'react';

const Image = () => (
  <svg
    width="116"
    height="28"
    viewBox="0 0 116 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-0.000169754 0L-0.000169754 28L28.7812 28C28.7813 12.536 15.8954 0 -9.91821e-05 0H-0.000169754Z"
      fill="#007377"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.3446 28V0L115.126 0C115.126 15.464 102.24 28 86.3446 28H86.3446Z"
      fill="#FFD800"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.5633 0V28L86.3447 28C86.3447 12.536 73.4589 0 57.5634 0H57.5633Z"
      fill="#4BA373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.7821 28L28.7821 0L57.5635 0C57.5635 15.464 44.6776 28 28.7821 28H28.7821Z"
      fill="#B8DDE1"
    />
  </svg>
);

export default Image;
