import { getTelehealthMedications } from 'common/apis/telehealthApis';
import { useState } from 'react';
import { useQuery } from 'react-query';

const useTelehealthMedications = () => {
  const [name, setName] = useState('');
  const {
    data: response,
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery(
    ['telehealth-medications', name],
    () => getTelehealthMedications({ name }),
    {
      enabled: name.length >= 2,
      refetchOnWindowFocus: false,
    },
  );

  return {
    medications: response?.data?.medications ?? [],
    search: setName,
    isLoading: isLoading || isFetching,
    isError,
    error,
  };
};

export default useTelehealthMedications;
