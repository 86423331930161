import testKitPlaceholder from 'common/images/test-kit-placeholder.png';
import vcvIcon from 'common/images/virtual-care-visit-icon.png';

export const OPTIONS = [
  {
    productId: 1,
    slug: 'test-kit',
    productName: 'Test Kit',
    productImageURL: testKitPlaceholder,
    description:
      'Choose from any of our at-home lab tests. From markers related to metabolism issues and food allergies to heart health and cholesterol, your Everlywell+ membership has a test for that.',
    shortDescription: '',
  },
  {
    productId: 2,
    slug: 'virtual-care-visit',
    productName: 'Virtual Care Visit',
    productImageURL: vcvIcon,
    description:
      'You’ve got symptoms. We’ve got treatments. Licensed clinicians make it simple for you to address your health needs with high-quality care, prescriptions, and recommendations.',
    shortDescription: '',
  },
];
