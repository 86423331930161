import { Button, H3, typography, size, colors } from '@everlywell/leaves';
import styled from 'styled-components';

import { BaseInput } from '../styles';

const TitleWrapper = styled.div`
  padding-bottom: ${size.lg}px;
`;
const SubtitleWrapper = styled.div`
  padding-bottom: ${size.xl2}px;
`;
const Title = styled(H3)`
  font-weight: ${typography.weight.bold};
  margin: 0;
`;

const Subtitle = styled.p`
  ${typography.bodyTextSmall};
  line-height: 1.75;
  font-weight: ${typography.weight.book};
  display: inline;
`;

const Error = styled.p`
  font-weight: ${typography.weight.bold};
  ${typography.bodyTextSmall};
  line-height: 1.75;
  color: ${colors.red3};
  margin: ${size.sm}px 0;
`;

const Disclaimer = styled.p`
  margin: ${size.sm}px 0;
  padding: ${size.sm}px 0 ${size.xl2}px;
  ${typography.captionText};
`;

const Submit = styled(Button)`
  width: 100%;
`;
const StyledInput = styled(BaseInput as any)`
  height: ${size.xl6}px;
  &&& {
    /* stylelint-disable function-url-quotes */
    @-moz-document url-prefix() {
      input[type='date'] {
        padding-right: 10px;
      }
    }
  }
`;
export {
  TitleWrapper,
  Title,
  SubtitleWrapper,
  Subtitle,
  Disclaimer,
  Error,
  Submit,
  StyledInput,
};
