import { size, mediaQueries, typography, colors } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: ${size.sm}px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${size.xl1}px;
  ${mediaQueries.forTabletVerticalUp} {
    text-align: start;
    align-items: start;
    flex-direction: row;
    gap: ${size.md}px;
    padding: 0;
  }
`;
export const Heading = styled.div`
  display: flex;
  align-items: center;
  min-width: fit-content;
  height: 100%;
`;

export const NeedHelpText = styled.span`
  ${typography.bodyTextSmall}
  color: ${colors.teal4};
  font-weight: ${typography.weight.xbold};
`;

export const PhoneIcon = styled.img`
  max-height: ${size.lg}px;
`;

export const Text = styled.p`
  ${typography.bodyTextSmall}
  margin: 0;
`;

export const Span = styled.span`
  color: #006298;
`;
