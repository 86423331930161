import { ImmutableReduxState } from 'common/utils/types';
import NewLayoutSectionHeader, {
  Props as NewLayoutSectionHeaderProps,
} from 'components/NewLayoutSectionHeader';
import React from 'react';
import { connect } from 'react-redux';
import { makeSelectContent } from 'store/selectors';

const CONTENT_KEYS = {
  header: 'HELP_SECTION_HEADER',
  description: 'HELP_SECTION_DESCRIPTION',
};

type OwnProps = {
  contentToken: string;
  backgroundSrc?: string;
  headerIcon?: string;
  noTopBorder?: boolean;
};

export function GetHelpSectionHeaderContainer(
  props: NewLayoutSectionHeaderProps,
) {
  const hasContent = !!props.header;

  if (!hasContent) {
    return null;
  }

  return <NewLayoutSectionHeader {...props} />;
}

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): NewLayoutSectionHeaderProps {
  return makeSelectContent(ownProps.contentToken, CONTENT_KEYS)(state);
}

export default connect(mapStateToProps)(GetHelpSectionHeaderContainer);
