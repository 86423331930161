import { LeftCaret, breakpoints } from '@everlywell/leaves';
import useMediaQuery from 'common/hooks/useMediaQuery';
import React from 'react';

import * as S from './PageHeader.styles';

function PageHeaderLink() {
  const isMobile = useMediaQuery(
    `(max-width: ${breakpoints.forTabletVerticalUp}px)`,
  );

  return (
    <S.HeaderLink to="/virtual-care">
      <S.VisuallyHidden>
        Go back to your Virtual Care dashboard
      </S.VisuallyHidden>
      <LeftCaret width={isMobile ? '12px' : '6px'} />
      {/* below text is hidden to screen readers so only the above text is what is 
          read out-loud to the users */}
      <S.PageHeaderText aria-hidden>Virtual Care</S.PageHeaderText>
    </S.HeaderLink>
  );
}

export default PageHeaderLink;
