/**
 * Skeleton loader for Settings form
 */

import { colors, size } from '@everlywell/leaves';
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import * as S from './styles';

const inputHeight = `${size.xl3}px`;
const titleHeight = `${size.lg}px`;
const buttonHeight = `${size.xl3}px`;
const subtitleColor = colors.gray1;
const buttonColor = colors.gray1;
// Note: these colors are not in the styleguide on purpose
const subtitleHighlightColor = '#e8e8e8';
const inputColor = '#fafafa';

function Button() {
  return (
    <S.ButtonWrapper>
      <SkeletonTheme
        highlightColor={subtitleHighlightColor}
        baseColor={buttonColor}
      >
        <Skeleton height={buttonHeight} />
      </SkeletonTheme>
    </S.ButtonWrapper>
  );
}

function ShippingAddressFormSkeleton() {
  return (
    <S.FormWrapper>
      <S.FormTitle>
        <SkeletonTheme
          highlightColor={subtitleHighlightColor}
          baseColor={subtitleColor}
        >
          <Skeleton height={titleHeight} />
        </SkeletonTheme>
      </S.FormTitle>
      <SkeletonTheme baseColor={inputColor}>
        <S.GridInputWrapper>
          <S.GridStateDoubleColumnWrapper>
            <Skeleton height={inputHeight} />
            <Skeleton height={inputHeight} />
          </S.GridStateDoubleColumnWrapper>
          <div>
            <Skeleton height={inputHeight} />
          </div>
          <div>
            <Skeleton height={inputHeight} />
          </div>
          <div>
            <Skeleton height={inputHeight} />
          </div>
          <S.GridStateDoubleColumnWrapper>
            <Skeleton height={inputHeight} />
            <Skeleton height={inputHeight} />
          </S.GridStateDoubleColumnWrapper>
        </S.GridInputWrapper>
      </SkeletonTheme>
      <Button />
    </S.FormWrapper>
  );
}

export { ShippingAddressFormSkeleton };
