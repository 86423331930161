import { Row } from '@everlywell/leaves';
import { track } from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { COVID_SCREENER_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';
import { KitRegistrationUser } from 'common/utils/types';
import { QUESTION_NUMBERS } from 'containers/RegistrationContainer';
import React, { useEffect, useState } from 'react';

import CovidVaccineDoses from './CovidVaccineDoses';
import CovidVaccineQuestion from './CovidVaccineQuestion';
import ExposureQuestion from './ExposureQuestion';
import HighRiskQuestion from './HighRiskQuestion';
import MultiQuestions from './MultiQuestions';
import NewYorkQuestions from './NewYorkQuestions';
import SymptomsQuestion from './SymptomsQuestion';

export interface Answer {
  id: string;
  label: string | JSX.Element;
}

interface Props {
  totalQuestions: number;
  questions: string[];
  user: KitRegistrationUser;
  onSubmit: () => void;
}

export type QuestionComponent = (
  user: KitRegistrationUser,
  totalQuestions: number,
  handleNext: (action: any) => void,
  handleBack: (action: any) => void,
) => JSX.Element;

const questionMap: { [step: string]: QuestionComponent } = {
  symptoms: (
    user: KitRegistrationUser,
    totalQuestions: number,
    handleNext: (action: any) => void,
    handleBack: (action: any) => void,
  ) => (
    <SymptomsQuestion
      user={user}
      totalQuestions={totalQuestions}
      questionNumber={1}
      handleNext={handleNext}
      handleBack={handleBack}
    />
  ),
  exposure: (
    user: KitRegistrationUser,
    totalQuestions: number,
    handleNext: (action: any) => void,
    handleBack: (action: any) => void,
  ) => (
    <ExposureQuestion
      user={user}
      totalQuestions={totalQuestions}
      questionNumber={2}
      handleNext={handleNext}
      handleBack={handleBack}
    />
  ),
  multiQuestions: (
    user: KitRegistrationUser,
    totalQuestions: number,
    handleNext: (action: any) => void,
    handleBack: (action: any) => void,
  ) => (
    <MultiQuestions
      user={user}
      totalQuestions={totalQuestions}
      questionNumber={3}
      handleNext={handleNext}
      handleBack={handleBack}
    />
  ),
  highRisk: (
    user: KitRegistrationUser,
    totalQuestions: number,
    handleNext: (action: any) => void,
    handleBack: (action: any) => void,
  ) => (
    <HighRiskQuestion
      user={user}
      totalQuestions={totalQuestions}
      questionNumber={6}
      handleNext={handleNext}
      handleBack={handleBack}
    />
  ),
  newYorkQuestions: (
    user: KitRegistrationUser,
    totalQuestions: number,
    handleNext: (action: any) => void,
    handleBack: (action: any) => void,
  ) => (
    <NewYorkQuestions
      user={user}
      totalQuestions={totalQuestions}
      questionNumber={7}
      handleNext={handleNext}
      handleBack={handleBack}
    />
  ),
  covidVaccine: (
    user: KitRegistrationUser,
    totalQuestions: number,
    handleNext: (action: any, step: number | undefined) => void,
    handleBack: (action: any) => void,
  ) => (
    <CovidVaccineQuestion
      user={user}
      totalQuestions={totalQuestions}
      questionNumber={
        user.addressState.value === 'New York'
          ? QUESTION_NUMBERS.NEW_YORK_STATE
          : QUESTION_NUMBERS.STANDARD
      }
      handleNext={(action, step) => handleNext(action, step)}
      handleBack={handleBack}
    />
  ),
  covidVaccineDoses: (
    user: KitRegistrationUser,
    totalQuestions: number,
    handleNext: (action: any) => void,
    handleBack: (action: any) => void,
  ) => (
    <CovidVaccineDoses
      user={user}
      handleBack={handleBack}
      handleNext={handleNext}
      totalQuestions={totalQuestions}
      questionNumber={user.addressState.value === 'New York' ? 9 : 8}
    />
  ),
};

const QuestionContainer = ({
  totalQuestions: intialTotalQuestions,
  questions,
  user,
  onSubmit,
}: Props) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [totalQuestions, setTotalQuestions] =
    useState<number>(intialTotalQuestions);

  const handleNext = (action: any, step: number = 1) => {
    action(setTotalQuestions);
    return currentIndex + step === questions.length
      ? onSubmit()
      : setCurrentIndex(currentIndex + 1);
  };
  const handleBack = (action: any) => {
    action(setTotalQuestions);
    setCurrentIndex(currentIndex - 1);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const question: string = questions[currentIndex];
    const currentQuestion: string = ANALYTICS.PAGES.COVID_SCREENER[question];
    track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        category: ANALYTICS.CATEGORIES.REGISTRATION,
        component: currentQuestion,
      },
    });
  }, [currentIndex, questions]);
  return (
    <Row data-test={`${DATA_TEST.CONTAINER}-questions`}>
      {questionMap[questions[currentIndex]](
        user,
        totalQuestions,
        handleNext,
        handleBack,
      )}
    </Row>
  );
};

export default QuestionContainer;
