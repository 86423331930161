import { CardContainer } from 'common/styles/app-styles';
import { MARKER_TYPES, SCREEN } from 'common/utils/constants';
import { formatStringSeparator } from 'common/utils/formatText';
import { DescriptiveMarkerContent, MarkerWithResult } from 'common/utils/types';
import MarkerDetails from 'components/MarkerDetails';
import ReactivityBadgeIconContainer from 'containers/ReactivityBadgeIconContainer';
import React from 'react';

import * as S from './styles';

type Props = {
  marker: MarkerWithResult;
  content: DescriptiveMarkerContent;
  outOfRange: boolean;
};

function DescriptiveMarkerCard(props: Props) {
  const { marker, content, outOfRange } = props;
  const { name, marker_result: markerResult } = marker;
  const { descriptor } = markerResult;

  const dataTest = formatStringSeparator(`${name.toLowerCase()}`);
  const exception = marker.name.includes(SCREEN) ? SCREEN : null;
  return (
    <>
      <CardContainer
        data-test={`${dataTest}-marker-card-result`}
        id={`${name}-card`}
      >
        <S.DescriptiveMarkerCard>
          <ReactivityBadgeIconContainer
            dataTest={`${dataTest}-icon`}
            severityIndex={markerResult.severity_index}
            marker={marker}
            height={48}
            width={48}
            markerType={MARKER_TYPES.DESCRIPTIVE}
            exception={exception}
          />
          <S.MarkerResultContainer>
            <S.MarkerResultName data-test={`${dataTest}-marker-title`}>
              {name}
            </S.MarkerResultName>
            <S.MarkerResultValue data-test={`${dataTest}-test-result`}>
              {descriptor}
            </S.MarkerResultValue>
          </S.MarkerResultContainer>
        </S.DescriptiveMarkerCard>
        <MarkerDetails
          name={name}
          content={content}
          detailsExpanded={outOfRange}
          markerValue={markerResult.value.toString().toLocaleLowerCase()}
        />
      </CardContainer>
    </>
  );
}

export default DescriptiveMarkerCard;
