/*
  This helper implements the logic for the filters that are used in the telehealth form builder
  based on the custom module type and the filter type provided by healthie.

  Based on the white label ui those are filters that are supported by healthie

  - contains: Check if the given value is contained in the content
  - does_not_contain: Check if the given value is not contained in the content
  - includes:  Check if the given value is included in the options selected by the user
  - does_not_include:  Check if the given value is not included in the options selected by the user
  - is_equal_to: Check if the given value is equal to the content
  - is_not_equal_to: Check if the given value is not equal to the content; 
  - is_greater_than: Parse the content and the value to numbers and checks if the content is greater than the value 
  - is_greater_than_or_equal_to: Parse the content and the value to numbers and checks if the content is greater than or equal to the value
  - is_less_than: Parse the content and the value to numbers and checks if the content is less than the value
  - is_less_than_or_equal_to: Parse the content and the value to numbers and checks if the content is less than or equal to the value
*/

export type FilterHandler = (content: string, value: string) => boolean;

/**
 * Check if the given value is contained in the content
 * @param content
 * @param value
 * @returns
 */
const contains: FilterHandler = (content, value) => content.includes(value);

/**
 * Check if the given value is not contained in the content
 * @param args
 * @returns
 */
const doesNotContain: FilterHandler = (...args) => !contains(...args);

/**
 * Parse the given content to an array and check if the value is included in the array
 * @param content
 * @param value
 * @returns
 */
const includes: FilterHandler = (content, value) =>
  content.split('\n').includes(value);

/**
 * Parse the given content to an array and check if the value is not included in the array
 * @param args
 * @returns
 */
const doesNotInclude: typeof includes = (...args) => !includes(...args);

const isEqualTo: FilterHandler = (content, value) => content === value;

const isNotEqualTo: FilterHandler = (...args) => !isEqualTo(...args);

/**
 * Parse the given parameters to numbers.
 * @param a
 * @param b
 * @returns
 */
const parseArguments = (a: string, b: string) => [parseFloat(a), parseFloat(b)];

/**
 * Parse the given args to numbers and check if the first one is greater than the second one
 * @param args
 * @returns
 */
const isGreaterThan: FilterHandler = (...args) => {
  const [a, b] = parseArguments(...args);

  return !isNaN(a) && !isNaN(b) && a > b;
};

/**
 * Parse the given args to numbers and check if the first one is greater than or equal to the second one
 * @param args
 * @returns
 */
const isGreaterThanOrEqualTo: FilterHandler = (...args) => {
  const [a, b] = parseArguments(...args);

  return !isNaN(a) && !isNaN(b) && a >= b;
};

/**
 * Parse the given args to numbers and check if the first one is less than the second one
 * @param args
 * @returns
 */
const isLessThan: FilterHandler = (...args) => {
  const [a, b] = parseArguments(...args);

  return !isNaN(a) && !isNaN(b) && a < b;
};

/**
 * Parse the given args to numbers and check if the first one is less than or equal to the second one
 * @param args
 * @returns
 */
const isLessThanOrEqualTo: FilterHandler = (...args) => {
  const [a, b] = parseArguments(...args);

  return !isNaN(a) && !isNaN(b) && a <= b;
};

/**
 * Map of the filters that are supported by healthie and the corresponding handler
 */
export const filtersMap = {
  contains: contains,
  does_not_contain: doesNotContain,
  includes: includes,
  does_not_include: doesNotInclude,
  is_equal_to: isEqualTo,
  is_not_equal_to: isNotEqualTo,
  is_greater_than_or_equal_to: isGreaterThanOrEqualTo,
  is_greater_than: isGreaterThan,
  is_less_than_or_equal_to: isLessThanOrEqualTo,
  is_less_than: isLessThan,
};

export default filtersMap;
