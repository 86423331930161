import { typography, colors, size, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

export const MembershipPlanInfoContainer = styled.div``;

export const Title = styled.div`
  ${typography.overlineText};
  color: ${colors.green4};
  margin-bottom: ${size.md}px;
`;

export const ContentContainer = styled.div`
  border: 1px solid ${colors.green2};
  border-radius: ${size.xs2}px;
  padding: ${size.md}px ${size.sm}px;

  ${mediaQueries.forDesktopUp} {
    padding: ${size.xl1}px ${size.lg}px;
  }
`;

export const MembershipInfoContainer = styled.div``;

export const CancellingDisclaimer = styled.p`
  ${typography.bodyText}
  padding-top: ${size.xl1}px;
  margin-top: ${size.lg}px;
  border-top: 1px solid ${colors.green2};

  ${mediaQueries.forTabletHorizontalUp} {
    margin-top: ${size.xl1}px;
  }
`;

export const Link = styled.a`
  ${typography.buttonText}
  text-decoration: none;
`;
