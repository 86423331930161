export const ERROR_PAGE = {
  HEADER: 'error-page-header',
  MESSAGE: 'error-page-message',
  HOMEPAGE_BUTTON: 'error-page-home-page-button',
  DASHBOARD_BUTTON: 'error-page-dashboard-button',
};

export const HEADER = {
  HEADER_WRAPPER: 'header-wrapper',
  SHOP_TESTS: 'header-shop-tests',
  THE_SCIENCE: 'header-the-science',
  HELP: 'header-help',
  REGISTER_YOUR_KIT: 'header-register-your-kit',
  REGISTER_A_KIT: 'header-register-a-kit',
  MY_EVERLY: 'header-my-everly',
  ACCOUNT: 'header-account',
  LOGIN: 'header-login',
};

export const MOBILE_HEADER = {
  HEADER_WRAPPER: 'mobile-header-wrapper',
  SHOP_TESTS: 'mobile-header-shop-tests',
  THE_SCIENCE: 'mobile-header-the-science',
  HELP: 'mobile-header-help',
  REGISTER_YOUR_KIT: 'mobile-header-register-your-kit',
  REGISTER_A_KIT: 'mobile-header-register-a-kit',
  MY_EVERLY: 'mobile-header-my-everly',
  ACCOUNT: 'mobile-header-account',
  LOGIN: 'mobile-header-login',
};

export const FOOTER = {
  LOGO: 'footer-logo',
  SOCIAL_MEDIA_LINKS: 'footer-social-media-links',
  NAVIGATION_LINKS: 'footer-navigation-links',
  COPY: 'footer-copy',
  FACEBOOK_ICON: 'facebook-icon',
  TWITTER_ICON: 'twitter-icon',
  INSTAGRAM_ICON: 'instagram-icon',
  LINKEDIN_ICON: 'linkedin-icon',
  PRIVACY_POLICY_LINK: 'privacy-policy-link',
  TERMS_USE_LINK: 'footer-terms-link',
  YOU_PRIVACY_CHOICES: 'footer-your-privacy-choices',
  PRODUCT_CONSENT_LINK: 'footer-product-link',
  PRIVACY_AUTH_LINK: 'footer-privacy-link',
  BLOG_LINK: 'footer-blog-link',
  FAQ_LINK: 'footer-faq-link',
  SCIENCE_LINK: 'footer-science-link',
  HELP_LINK: 'footer-help-link',
  MEDIA_LINK: 'footer-media-link',
  CAREERS_LINK: 'footer-careers-link',
  AFFILIATE_LINK: 'footer-affiliate-link',
  REFER_FRIEND_LINK: 'footer-raf-link',
  CONTACT_US_LINK: 'footer-contact-link',
  LEGAL_PANELS_LIST_LINK: 'footer-legal-panels-list-link',
  PARTNERSHIPS_LINK: 'footer-partnerships-link',
};

export const INTRO = {
  NAME: 'intro-name',
  HEADER: 'intro-header',
  CONTENT: 'intro-content',
  SCROLL_TO_CONTENT: 'intro-scroll-to-content',
  ABNORMAL_COUNT: 'intro-abnormal-count',
  TEST_INFO: 'intro-test-info',
  LAB_NAME: 'intro-lab-name',
  RELEASE_DATE: 'intro-release-date',
  SCIENCE_LINK: 'intro-science-link',
  TOOLTIP: 'intro-tooltip',
};

export const SECTION_HEADER = {
  ICON: 'section-header-icon',
  TITLE: 'section-header-title',
  DESCRIPTION: 'section-header-description',
};

export const WHATS_NEXT_SECTION_HEADER = {
  ICON: 'whats-next-header-icon',
  TITLE: 'whats-next-header-title',
  DESCRIPTION: 'whats-next-header-description',
};

export const RESULT_DETAILS = {
  SAVE_BUTTON: 'save-button',
  SAVE_PDF_BUTTON: 'save-pdf-button',
  DOCTOR_PDF: 'pdf-for-doctor',
  DNR_PDF: 'dnr-pdf-message',
  ITEMIZED_RECEIPT_PDF: 'pdf-for-itemized-receipt',
};

export const SUB_NAVBAR = {
  SAVE_PDF_BUTTON: 'sub-navbar-save-pdf-button',
  DASHBOARD_BUTTON: 'sub-navbar-dashboard-button',
};

export const CONSIDER_CHANGES_SECTION_HEADER = {
  ICON: 'consider-changes-header-icon',
  TITLE: 'consider-changes-header-title',
  DESCRIPTION: 'consider-changes-header-description',
};

type SubHeader = {
  [prop: string]: string;
};

export const PCP_QUESTIONS = {
  DOCTOR_PDF: 'pdf-for-doctor',
  CONTAINER: 'container',
  HEADER: 'pcp-questions-header',
  SUB_HEADER: 'pcp-questions-sub-header',
};

export const SUB_HEADER: SubHeader = {
  SAVE_BUTTON: 'save-button',
  SHARE_BUTTON: 'share-button',
  GIFT_CARDS_BUTTON: 'gift-cards-button',
  DOCTOR_PDF: 'pdf-for-doctor',
  DNR_PDF: 'dnr-pdf-message',
  SHARE_COPY_BUTTON: 'share-copy-button',
  REFER_A_FRIEND_BUTTON: 'refer-a-friend',
  ITEMIZED_RECEIPT_PDF: 'pdf-for-itemized-receipt',
};

export const SHARE = {
  START_SHARING_BUTTON: 'share-start-button',
  TOGGLE: 'share-toggle',
  LINK_INPUT: 'share-link-input',
};

export const MODAL = {
  CLOSE_BUTTON: 'modal-close-button',
  CONTENT: 'modal-content',
};

export const TAKE_ACTION = {
  SECTION: 'take-action-section',
  SCHEDULE_CONTINUE_BUTTON: 'schedule-continue-button',
  SCHEDULE_MODAL: 'schedule-modal',
  SCHEDULE_MODAL_TITLE: 'schedule-modal-title',
  SCHEDULE_MODAL_DESCRIPTION: 'schedule-modal-description',
  SCHEDULE_TOOLTIP: 'schedule-tooltip',
  WATCH_TOOLTIP: 'watch-tooltip',
  SCHEDULE_TOOLTIP_CONTENT: 'schedule-tooltip-content',
  WATCH_TOOLTIP_CONTENT: 'watch-tooltip-content',
};

export const COMMON_QUESTIONS = {
  SECTION: 'common-questions-action-section',
};

export const POPOVER = {
  CONTENT: 'popover-content',
};

export const MARKER_DETAILS = {
  TELL_ME_MORE_CONTENT: 'tell-me-more-content',
  TELL_ME_MORE_TOGGLE: 'tell-me-more-toggle',
  MARKER_DETAILS_TOGGLE: 'marker-details-toggle',
};

export const SETTINGS_FORM = {
  FIRST_NAME_FIELD: 'settings-form-first-name-field',
  LAST_NAME_FIELD: 'settings-form-last-name-field',
  EMAIL_FIELD: 'settings-form-email-field',
  PHONE_FIELD: 'settings-form-phone-field',
  DOB_FIELD: 'settings-form-dob-field',
  GENDER_FIELD: 'settings-form-gender-field',
  MARKETING_FIELD: 'settings-form-marketing-field',
  SUBMIT_BUTTON: 'settings-form-submit-button',
};

export const PHYSICIAN_CONTACT_FORM = {
  FIRST_NAME_FIELD: 'physician-contact-form-first-name-field',
  LAST_NAME_FIELD: 'physician-contact-form-last-name-field',
  PRACTICE_NAME_FIELD: 'physician-contact-form-practice-name-field',
  ADDRESS1_FIELD: 'physician-contact-form-address1-field',
  ADDRESS2_FIELD: 'physician-contact-form-address2-field',
  CITY_FIELD: 'physician-contact-form-city-field',
  STATE_FIELD: 'physician-contact-form-state-field',
  ZIP_CODE_FIELD: 'physician-contact-form-zipcode-field',
  PHONE_FIELD: 'physician-contact-form-phone-field',
  SUBMIT_BUTTON: 'physician-contact-form-submit-button',
};

export const REPLACEMENT = {
  WELCOME_TEXT: 'replacement-message',
  CONFIRMATION_WRAPPER: 'replacement-confirmation-wrapper',
  CONTACT_US_LINK: 'replacement-contact-us-suport-link',
  SAMPLE_REQUIREMENTS_LINK: 'replacement-sample-requirements-link',
  BIOMARKERS_LINK: 'replacement-biomarkers-link',
};

export const ADDRESS_FORM = {
  FIRST_NAME_FIELD: 'address-form-first-name-field',
  LAST_NAME_FIELD: 'address-form-last-name-field',
  ADDRESS_1_FIELD: 'address-form-address-1-field',
  ADDRESS_2_FIELD: 'address-form-address-2-field',
  CITY_FIELD: 'address-form-city-field',
  ZIP_CODE_FIELD: 'address-form-zip-code-field',
  SUBMIT_BUTTON: 'address-form-submit-button',
};

export const COBRANDED_ANCESTRY_BANNER = {
  BANNER_CONTAINER: 'cobranded-ancestry-banner',
  BANNER_LOGO: 'cobranded-ancestry-logo',
  BANNER_CONTENT: 'cobranded-ancestry-content',
};

export const ANCESTRY_FOOTER = {
  BUTTON: 'ancestry-footer-button',
};

export const CONTENT_CARD = {
  EXPANDABLE_SECTION: 'expandable-section',
  EXPAND_BUTTON: 'show-more-button',
  MODAL_TITLE: 'modal-title',
  MODAL_DESCRIPTION: 'modal-description',
  CARD_TITLE: 'card-title',
  CARD_PREVIEW_TEXT: 'card-preview-text',
  READ_MORE_BUTTON: 'card-read-more-button',
};

export const FOOD_SENSITIVITY_RESULT = {
  CROSS_REACTIVITIES_BLOCK: 'cross-reactivities-block',
  HIDDEN_SOURCES_BLOCK: 'hidden-sources-block',
  EXPAND_NORMAL_FOODS_BUTTON: 'expand-normal-foods-button',
  NO_MARKER_RESULTS: 'no-marker-results',
};

export const INTENSITY_RESULT = {
  OVERVIEW_HEADER: 'marker-overview-header',
  EXTENDED_CONTENT_BLOCK: 'extended-content-block',
  EXPAND_NORMAL_FOODS_BUTTON: 'expand-normal-foods-button',
  NO_MARKER_RESULTS: 'no-marker-results',
};

export const KIT_LIST = {
  WELCOME_MESSAGE: 'welcome-message',
  PROCESS_TIME: 'process-time',
  KIT_IMAGE: 'kit-image',
  KIT_TITLE: 'kit-title',
  KIT_DATE: 'kit-date',
  STATUS_BADGE: 'status-badge',
};

export const SCHEDULE_CARD = {
  SCHEDULE_CARD_BUTTON: 'schedule-card-button',
  SCHEDULE_CARD_ICON: 'schedule-card-icon',
  SCHEDULE_CARD_TITLE: 'schedule-card-title',
  SCHEDULE_CARD_CONTENT: 'schedule-card-content',
};

export const WHATS_NEXT_SECTION = {
  SCHEDULE_NOW_BUTTON: 'schedule-now-button',
  WATCH_WEBINAR_BUTTON: 'watch-webinar-button',
  PRODUCT_UPSELL_BUTTON: 'product-upsell-button',
  PRODUCT_UPSELL_LIST: 'product-upsell-list',
  PRODUCT_PROMO_BUTTON: 'product-promo-button',
  PRODUCT_PROMO_LIST: 'product-promo-list',
};

export const REGISTER_A_KIT = {
  REGISTER: 'register-a-kit',
  SEARCH: 'search-for-a-test',
};

export const FORM = {
  FIRST_NAME_FIELD: 'first-name-field',
  EMAIL_FIELD: 'email-field',
  PHONE_INPUT_FIELD: 'phone-input-field',
  ZIP_CODE_INPUT_FIELD: 'zip-code-input-field',
};

export const KIT_RESULT_DETAIL = {
  SUB_HEADER: 'sub-header',
  SHARED_RESULT_INFO: 'shared-result-info',
};

export const GENOMICS_REPORT = {
  TITLE: 'helix-title',
  INFO: 'helix-info',
  VIEW_REPORT: 'helix-view-report-button',
};

export const KIT_REGISTRATION_FORM = {
  INPUT: 'kit-registration-title',
  CHECKBOX: 'kit-registration-checkbox',
  SUBMIT: 'kit-registration-submit',
  FORM: 'kit-registration-form',
};

export const PRE_SCREENER_FORM = {
  SUBMIT: 'kit-pre-screener-submit',
  FORM: 'kit-pre-screener-form',
};

export const CONFIRM_IDENTIFIERS_FORM = {
  INPUT: 'confirm-identifiers-title',
  DISPLAY: 'confirm-identifiers-text',
  CHECKBOX: 'confirm-identifiers-checkbox',
  SUBMIT: 'confirm-identifiers-submit',
  FORM: 'confirm-identifiers-form',
  POST_ACTION_BUTTON: 'confirm-identifiers-post-action-button',
};

export const KIT_ID_MODAL = {
  MODAL: 'kit-id-modal',
  TITLE: 'kit-id-modal-title',
  SUBTITLE: 'kit-id-modal-subtitle',
  SHAPES: 'kit-id-modal-shapes',
  INFO: 'kit-id-modal-info',
};

export const DATA_SHARING_MODAL = {
  MODAL: 'data-sharing-modal',
  TITLE: 'data-sharing-modal-title',
  SUBTITLE: 'data-sharing-modal-subtitle',
  INPUT: 'data-sharing-modal-input',
  INFO: 'data-sharing-modal-info',
  CUSTOMER_CARE: 'data-sharing-modal-customer-care',
  CONSENT: 'data-sharing-modal-consent',
};

export const TEST_INFORMATION_FORM = {
  INPUT: 'kit-registration-input',
  CHECKBOX: 'kit-registration-checkbox',
  SUBMIT: 'kit-registration-submit',
  FORM: 'kit-registration-form',
  ICON: 'kit-registration-icon',
};

export const SAMPLE_COLLECTION_FORM = {
  CONTAINER: 'sample-collection-container',
  INPUT: 'sample-collection-input',
  SUBMIT: 'sample-collection-submit',
};

export const REGISTRATION_CONFIRMATION = {
  CONTAINER: 'confirmation-container',
  DISPLAY: 'confirmation-text',
  EDIT: 'confirmation-edit',
  CHECKBOX: 'kit-registration-checkbox',
  MODAL: 'confirmation-modal',
  BUTTON: 'confirmation-button',
  IN_LAB_TAB: 'In-Lab',
  AT_HOME_TAB: 'At-home',
};

export const CARE_PATHWAYS_DASHBOARD = {
  DASHBOARD: 'care-pathways-dashboard',
  FAQ_SECTION: 'care-pathways-faq-section',
  CONTAINER: 'care-pathways-container',
  DISCOVER_MORE_SECTION: 'care-pathways-discover-more-section',
  DISCOVER_MORE_SLIDE: 'care-pathways-discover-more-slide',
};

export const CARE_PLAN_DASHBOARD = {
  VISIT_SUMMARY: 'care-plan-visit-summary',
};

export const DATA_TEST_INTAKE = {
  INDEX: {
    CONTAINER: 'intake-container',
    PAGE: 'intake-page',
  },
  SIGN_UP: {
    ALREADY_MEMBER: 'intake-sign-up-already-member',
    CONTAINER: 'intake-sign-up-container',
    FIRST_NAME: 'intake-sign-up-first-name',
    LAST_NAME: 'intake-sign-up-last-name',
    EMAIL: 'intake-sign-up-email',
    TERMS: 'intake-sign-up-terms',
    NEXT: 'intake-sign-up-next',
  },
  PAYMENT_INFO: {
    ACCEPTED_CARDS: 'intake-payment-accepted-cards',
    APPLE_PAY: 'intake-payment-apple-pay',
    CARD_NUMBER: 'intake-payment-card-number',
    CARD_EXPIRATION: 'intake-payment-card-expiration',
    CARD_CVC: 'intake-payment-card-cvc',
    CHECKOUT_CONTAINER: 'intake-checkout-container',
    CONTAINER: 'intake-payment-container',
    PLAN_FEE: 'intake-payment-plan-fee',
    PLAN_PERKS: 'intake-payment-plan-perks',
    NY_DISCLAIMER_CHECKBOX: 'intake-ny-disclaimer-checkbox',
    OPT_IN_CHECKBOX: 'intake-payment-wants-marketing',
    PAYPAL_PAY: 'intake-payment-paypal-pay',
    PLACE_ORDER_BUTTON: 'intake-place-order-button',
    PLACE_ORDER: 'intake-payment-place-order',
    STRIPE_CONTAINER: 'intake-stripe-container',
    STRIPE_EXPRESS_PAY: 'intake-stripe-express-pay',
  },
  CONFIRMATION: {
    CONTAINER: 'intake-confirmation-container',
    AVATAR_SELECTION: 'intake-confirmation-avatar-selection',
    EXPLORE_TESTS: 'intake-confirmation-explore-tests',
    HEALTH_PROFILE: 'intake-confirmation-health-profile',
    NOTIFICATION: 'intake-confirmation-notification',
  },
};

export const MEMBERSHIP_STATUS = {
  BECOME_MEMBER_TEST_BUTTON: 'become-member-test-button',
  REDEEM_TEST_BUTTON: 'redeem-test-button',
  NO_MEMBERSHIP_STATUS: 'no-membership-status',
  CREDIT_COUNT: 'credit-count',
};

export const REGISTRATION_COMPLETE = {
  CONTAINER: 'registration-complete-container',
  BUTTON: 'registration-complete-button',
};

export const COVID_SCREENER_QUESTIONS = {
  BUTTON: 'covid-screener-button',
  MODAL: 'covid-screener-modal',
  INPUT: 'covid-screener-input',
  CONTAINER: 'covid-screener-container',
};

export const HEALTH_PROFILE_QUESTIONS = {
  INTRO: 'health-profile-intro',
  BUTTON: 'health-profile-button',
  INPUT: 'health-profile-input',
  CONTAINER: 'health-profile-container',
};
export const NOTIFICATION = {
  WRAPPER: 'notification-wrapper',
  ERROR_NOTIFICATION: 'notification-error',
  INFO_NOTIFICATION: 'notification-info',
  ICON: 'notification-icon',
  SHOW_DETAILS_BUTTON: 'notification-show-details',
  DETAILS: 'notification-details',
  CLOSE_NOTIFICATION: 'notification-close-button',
  TOOLTIP: 'notification-tooltip',
};

export const FOOD_SENSITIVITY_PERSONALIZATION = {
  ROW: 'fs-table-row',
  COLGROUP: 'fs-table-colgroup',
  CONTAINER: 'fs-table-container',
};

export const DROP_DOWN = {
  SELECT_COMPONENT: 'select-component',
  OPTION_ELEMENT: 'option-element',
};

export const PERSONALIZATION_CHECK_IN = {
  THUMBS_UP_BUTTON: 'thumbs-up-button',
  THUMBS_DOWN_BUTTON: 'thumbs-down-button',
  CHECKIN_WRAPPER: 'checkin-wrapper',
  FEEDBACK_MESSAGE: 'feedback-message',
};

export const PERSONALIZATION_FACTS = {
  FACT_CARD: 'fact-card',
  FACT_IMAGE: 'fact-image',
};

export const HEALTH_PROFILE_BANNER = {
  CONTAINER: 'health-profile-banner-container',
  HEADER: 'health-profile-banner-header',
  ICON: 'health-profile-banner-icon',
};

export const SKELETON_WRAPPER = {
  CONTAINER: 'skeleton-results-list',
};

export const RESULTS_GROUPING = {
  CONTAINER: 'results-grouping-container',
};

export const CALLOUT = {
  CONTAINER: 'fal-results-callout',
};

export const LEGAL = {
  TERMS_AND_CONDITIONS: 'terms-of-use',
  PRIVACY_POLIC: 'privacy-policy',
  PROGRAM_TERMS: 'program-terms',
  MARKETING: 'marketing',
};

export const DATA_TEST_WEIGHT_MANAGEMENT = {
  PAYMENT_INFO: {
    ACCEPTED_CARDS: 'weight-management-payment-accepted-cards',
    CHECKOUT_CONTAINER: 'weight-management-checkout-container',
    LEGEND: 'weight-management-legend',
    OPT_IN_CHECKBOX: 'weight-management-payment-wants-marketing',
    PLACE_ORDER_BUTTON: 'weight-management-place-order-button',
    PLACE_ORDER: 'weight-management-payment-place-order',
    PAYMENT_INPUTS_CONTAINER: 'weight-management-payment-inputs-container',
    STRIPE_CONTAINER: 'weight-management-stripe-container',
    TERMS_AND_CONDITIONS: 'weight-management-terms-and-conditions',
    PROGRAM_TERMS: 'weight-management-program-terms',
  },
  DASHBOARD: {
    RECOMMENDED_ARTICLE_CARD: 'recommended-article-card',
  },
};
