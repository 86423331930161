import { useQuery } from '@apollo/client';
import { colors, Information, Tooltip } from '@everlywell/leaves';
import useApi from 'common/hooks/useApi';
import { useRandomizedData } from 'common/hooks/useRandomizedData';
import { FACTS_COLLECTION_QUERY } from 'common/utils/contentfulGraphQlQueries';
import { logError } from 'common/utils/helpers';
import { HealthProfileProps, Personalizations } from 'common/utils/types';
import { isUndefined } from 'lodash';
import React, { useEffect, useState, FC } from 'react';
import { useEffectOnce } from 'react-use';

import FactCard, { Props as FactCardProps } from './FactCard';
import * as S from './styles';
import { getEligibleFacts, isPersonalizationNotAvailable } from './utils';

type Props = { testLabel?: string };
type DataProps =
  | {
      factCollection: {
        items: FactCardProps[];
      };
    }
  | undefined;

const Facts: FC<Props> = ({ testLabel }) => {
  const { getPersonalizations } = useApi();
  const { data, error } = useQuery<DataProps>(FACTS_COLLECTION_QUERY);
  const [personalizations, setPersonalizations] =
    useState<HealthProfileProps>();
  const [facts, setFacts] = useState<FactCardProps[]>([]);

  const eligibleFacts = getEligibleFacts(facts, personalizations);
  const displayFacts = useRandomizedData(eligibleFacts).slice(0, 2);

  useEffect(() => {
    if (error) {
      logError((error as Error).message, {
        errorInfo: 'Graphql fetching Error',
        component: 'Facts',
        method: 'setFacts',
      });
    } else if (!isUndefined(data)) {
      setFacts(data?.factCollection?.items);
    }
  }, [error, data]);

  useEffectOnce(() => {
    const loadPersonalizations = async () => {
      try {
        const personalizations: Personalizations = await getPersonalizations();
        setPersonalizations(personalizations.health_profile);
      } catch (err) {
        logError((err as Error).message, {
          errorInfo: 'Error Fetching Personalizations',
          component: 'Facts',
          method: 'loadPersonalizations',
        });
      }
    };
    loadPersonalizations();
  });

  if (isPersonalizationNotAvailable(facts, personalizations)) {
    return null;
  }

  return (
    <S.Wrapper>
      <S.Header>
        <S.Heading>Quick Health Facts</S.Heading>
        <Tooltip
          animationSpeed={'normal'}
          arrow={'center'}
          color={colors.blue1}
          content="Based on answers in your health profile"
          position={'top'}
          textColor={colors.white}
          className="tooltip"
        >
          <Information />
        </Tooltip>
      </S.Header>
      <S.FactCardsWrapper>
        {displayFacts.map((fact: FactCardProps, index: number) => (
          <FactCard
            index={index}
            key={`fact-card-${fact.slug}`}
            testLabel={testLabel}
            {...fact}
            length={displayFacts.length}
          />
        ))}
      </S.FactCardsWrapper>
    </S.Wrapper>
  );
};

export default Facts;
