import { TelehealthMedication } from 'common/apis/telehealthApis';
import { format } from 'date-fns';
import React, { useState } from 'react';

import * as S from './PrescriptionAccordion.styles';

export type PrescriptionAccordionProps = {
  prescription: TelehealthMedication;
};

/**
 * This is a component for a single prescription accordion
 */
function PrescriptionAccordion({
  prescription: { product_name, refills, date_written, directions, pharmacy },
}: PrescriptionAccordionProps) {
  const dateWritten = new Date(date_written);
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <S.Container>
      <S.AccordionHeader onClick={() => setIsExpanded(!isExpanded)}>
        <S.BadgeContainer>
          <S.Badge>eRx sent</S.Badge>
          <S.AccordionButton appearance="tertiary">
            <S.AccordionIcon isExpanded={isExpanded} />
          </S.AccordionButton>
        </S.BadgeContainer>

        <S.ProductName>Prescription: {product_name}</S.ProductName>

        <S.HeaderLabel>
          Ordered on: {format(dateWritten, 'MM/dd/yyyy')}
        </S.HeaderLabel>
        <S.HeaderLabel>Refills: {refills}</S.HeaderLabel>
      </S.AccordionHeader>

      <S.AccordionChild
        isExpanded={isExpanded}
        data-test="accordion-child"
        aria-expanded={isExpanded}
      >
        <S.Separator />
        <S.ChildLabel>Instructions</S.ChildLabel>
        <S.Directions>{directions}</S.Directions>

        <S.PharmacyContainer>
          <S.ChildLabel>Pharmacy location:</S.ChildLabel>

          <p>{pharmacy.name}</p>
          <p>{pharmacy.line1}</p>
          {pharmacy.line2 ? <p>{pharmacy.line2}</p> : null}
          <p>
            {pharmacy.city}, {pharmacy.state} {pharmacy.zip}
          </p>
        </S.PharmacyContainer>
      </S.AccordionChild>
    </S.Container>
  );
}

export default PrescriptionAccordion;
