import { colors, typography } from '@everlywell/leaves';
import { hexToRGB } from 'common/utils/helpers';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &:not(:first-child) {
    margin-left: 80px;
  }
`;

const Label = styled.label`
  position: relative;
  height: 24px;
  line-height: 1.69;
  font-size: 0.89rem;
  font-weight: ${typography.weight.light};
  color: ${colors.gray5};

  &:before {
    content: '';
    position: absolute;
    display: inline-block;
    top: -3px;
    left: -42px;
    height: 27px;
    width: 27px;
    border-radius: 50%;
    border: 1px solid #767676; /* TODO: color not in styleguide */
    background-color: ${colors.white};
  }
`;

const Input = styled.input`
  position: absolute;
  left: -9999999px;

  &:checked + label:before {
    border: 1px solid ${colors.green4};
  }

  &:focus + label:before {
    border: 1px solid rgba(${hexToRGB(colors.black)}, 30%);
    box-shadow: 0 0 0 2px rgba(${hexToRGB(colors.green4)}, 0.5);
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    display: inline-block;
    left: -35px;
    top: 4px;
    height: 13.4px;
    width: 13.4px;
    border-radius: 50%;
    background-color: ${colors.green4};
  }
`;

export { Wrapper, Label, Input };
