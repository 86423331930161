import {
  documentToReactComponents,
  NodeRenderer,
} from '@contentful/rich-text-react-renderer';
import { INLINES, Hyperlink } from '@contentful/rich-text-types';
import { colors, Tooltip } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { FactCollectionItem } from 'common/utils/contentfulGraphQlQueries';
import { hasRatedPersonalization } from 'common/utils/helpers';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import { capitalize } from 'lodash';
import React, { FC } from 'react';

import InfoIcon from './components/InfoIcon';
import Source from './components/Source';
import * as S from './FactCard.styles';

export type FactCardProps = {
  fact: FactCollectionItem;
};
const Facts: FC<FactCardProps> = ({ fact }) => {
  const { copy, title, icon, source1, source2, slug } = fact;

  const factCardPersonalizationEl = useScrolledIntoViewOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: `${ANALYTICS.LABELS.FACT_SET_PERSONALIZATION} - ${slug}`,
      },
    });
  });

  const handleClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: `${ANALYTICS.LABELS.FACT_SOURCE} [${source1}, ${source2}] - ${slug}`,
      },
    });
  };

  return (
    <>
      <S.Card ref={factCardPersonalizationEl}>
        <S.Header>
          <S.Heading>Quick Health Facts</S.Heading>
          <Tooltip
            animationSpeed={'normal'}
            arrow={'center'}
            color={colors.blue1}
            content="Based on answers in your health profile"
            position={'top'}
            textColor={colors.white}
            className="tooltip"
          >
            <InfoIcon />
          </Tooltip>
        </S.Header>

        <S.Title>
          <span>{capitalize(title)}</span>{' '}
          {icon?.url && <S.Image src={icon.url} alt="" />}
        </S.Title>
        {copy && (
          <S.Copy onClick={handleClick}>
            {documentToReactComponents(copy.json, options)}
          </S.Copy>
        )}
        {source1 && <Source source={source1} />}
        {source2 && <Source source={source2} />}
      </S.Card>
      {!hasRatedPersonalization(slug, PERSONALIZATION_LABEL) && (
        <S.PersonalizationCheckInCustom
          widget={`${PERSONALIZATION_LABEL} - ${slug}`}
          hasToolTip={false}
        />
      )}
    </>
  );
};

export default Facts;

const PERSONALIZATION_LABEL = ANALYTICS.LABELS.FACT_SET_PERSONALIZATION;

const Link = ({ node }: { node: Hyperlink }): JSX.Element => (
  <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
    {node.content[0].value}
  </a>
);

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: ((node: Hyperlink) => (
      <Link node={node} />
    )) as unknown as NodeRenderer,
  },
};
