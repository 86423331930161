import { sanitize } from 'common/utils/domHelpers';
import React from 'react';

import * as S from './styles';

export type Item = {
  alt: string;
  iconSrc: string;
  text: string;
};

export type Props = {
  header: string;
  items: Item[];
  isCovid?: boolean;
};

function ListWithIcons(props: Props) {
  const { header, items, isCovid = false } = props;

  return (
    <S.ListWithIconsWrapper>
      <div>
        <S.Inner>
          <S.Header dangerouslySetInnerHTML={{ __html: sanitize(header) }} />

          <S.List>
            {items.map((item, index) => (
              <S.ListItem key={item.alt + index.toString()} isCovid={isCovid}>
                <S.IconWrapper isCovid={isCovid}>
                  <S.Icon
                    src={item.iconSrc}
                    alt={item.alt ? item.alt : `${index + 1} Icon`}
                  />
                </S.IconWrapper>
                <S.Text
                  dangerouslySetInnerHTML={{ __html: sanitize(item.text) }}
                />
              </S.ListItem>
            ))}
          </S.List>
        </S.Inner>
      </div>
    </S.ListWithIconsWrapper>
  );
}

export default ListWithIcons;
