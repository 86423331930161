import {
  typography,
  size,
  colors,
  mediaQueries,
  Button as BaseButton,
  Icons,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  box-shadow: 0 7px 30px -10px #dce4e5;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${size.md}px;
  padding: ${size.xl1}px;
  position: relative;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  ${mediaQueries.forTabletVerticalUp} {
    flex-wrap: nowrap;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: ${size.md}px;

  ${mediaQueries.forTabletVerticalUp} {
    flex-wrap: nowrap;
    margin-bottom: 0;
    justify-content: flex-start;
  }
`;

export const Image = styled.img`
  display: block;
  height: 85px;
  margin-bottom: ${size.md}px;

  ${mediaQueries.forTabletVerticalUp} {
    height: ${size.xl6}px;
    margin-bottom: 0;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 0;
  justify-content: center;

  ${mediaQueries.forTabletVerticalUp} {
    padding-left: ${size.md}px;
    padding-right: ${size.lg}px;
    width: auto;
  }
`;

export const Title = styled.span`
  ${typography.h5Text};
  color: ${colors.teal4};
  display: block;
  margin-bottom: ${size.xs1}px;
  text-align: center;
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    text-align: left;
    margin-bottom: 0;
  }
`;

export const RegisterDate = styled.span`
  ${typography.bodyTextSmall};
  text-align: center;
  display: block;

  ${mediaQueries.forTabletVerticalUp} {
    text-align: left;
  }
`;

export const InteractionWrapper = styled.div`
  position: relative;
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    height: ${size.xl6}px;
    width: auto;
  }
`;

export const ToggleButton = styled.button`
  background-color: ${colors.green1};
  border-radius: 4px;
  width: 25px;
  height: 23px;
  display: block;
  top: ${size.xl1}px;
  right: ${size.xl1}px;
  position: absolute;
`;

export const Chevron = styled(Icons.DownCaret)`
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 13px;
`;

export const Button = styled(BaseButton)`
  display: block;
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    min-width: 210px;
    width: auto;
  }
`;
