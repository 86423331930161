import { TaskTypes, UserTaskChecklistData } from 'common/apis/taskChecklistApi';
import { pluralizeCreditText } from 'common/utils/formatText';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import * as S from './TaskCard.styles';

// Types ----------

export interface TaskCardProps {
  task: UserTaskChecklistData;
}

// TaskCard ------

const TaskCard: React.FC<TaskCardProps> = (props) => {
  const { task } = props;
  const { cta_label, image_url, title, cta_url } = task.properties;

  const navigate = useNavigate();

  const onCardClick = (ctaURL: string) => {
    if (ctaURL.length) {
      navigate(ctaURL);
    }
  };

  return (
    <S.TaskWrapper
      onClick={() => onCardClick(cta_url)}
      data-test="task-wrapper"
    >
      {task.type === TaskTypes.REDEEM_CREDIT ? (
        <S.TaskCreditsWrapper>
          <S.TotalCredits>{task.properties.total_count}</S.TotalCredits>

          <S.CreditsLabel>
            {pluralizeCreditText(task.properties.total_count)}
          </S.CreditsLabel>
        </S.TaskCreditsWrapper>
      ) : (
        <S.TaskImageWrapper imageURL={image_url} />
      )}

      <S.TaskContentWrapper>
        <S.InnerWrapper>
          <S.ContentHug>
            <S.TaskTitle>{title}</S.TaskTitle>

            {task.type === TaskTypes.REDEEM_WEIGHT_MANAGEMENT_CREDIT ? (
              <S.TaskDate>{task.properties.expiration_date}</S.TaskDate>
            ) : null}
          </S.ContentHug>

          {cta_label ? <S.TaskCTA>{cta_label}</S.TaskCTA> : null}
        </S.InnerWrapper>
      </S.TaskContentWrapper>
    </S.TaskWrapper>
  );
};

// Export ---------

export default React.memo(TaskCard);
