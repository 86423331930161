import {
  Button as LeavesButton,
  H3,
  typography,
  mediaQueries,
  colors,
  size,
} from '@everlywell/leaves';
import Grid from 'components/Grid';
import styled from 'styled-components';

import { sharedStyles } from '../../NativeScheduling.styles';

export const MonthsContainer = styled.span`
  color: ${colors.green4};
  font-size: ${size.md}px;
  font-weight: ${typography.weight.xbold};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${sharedStyles.containerStyles};
`;

export const InnerContainer = styled.div`
  width: 100svw;
  overflow: hidden;

  ${mediaQueries.forTabletVerticalUp} {
    width: auto;
    max-width: 500px;
  }
`;

export const Header = styled(H3)`
  margin-top: 0;
  margin-bottom: ${size.sm}px;
`;

export const BodyCopy = styled.p`
  ${typography.bodyTextSmall};
  margin-bottom: ${size.sm}px;

  & span {
    color: ${colors.green4};
    font-weight: ${typography.weight.bold};
  }

  ${mediaQueries.forTabletVerticalUp} {
    ${typography.bodyText};
  }
`;

export const Button = styled(LeavesButton)`
  margin-bottom: ${size.sm}px;
  padding-left: 0;
  text-align: left;
`;

export const DateCopy = styled.p`
  ${typography.bodyTextSmall};
  margin-bottom: 0;

  ${mediaQueries.forTabletVerticalUp} {
    margin-bottom: ${size.lg}px;
  }
`;

export const StyledGridItem = styled(Grid.Item)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ErrorText = styled.div`
  ${typography.errorText}
`;
