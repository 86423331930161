import { size, H5, colors, mediaQueries, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Card = styled.div`
  padding: ${size.lg}px;
  display: flex;
  flex-direction: row;
  border: 1px solid #edf5f1;
`;

export const CardDetails = styled.div`
  padding: ${size.md}px ${size.md}px ${size.xl1}px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 7px 30px -10px rgba(220, 228, 229);
  border-radius: ${size.xs1}px;
  height: fit-content;
  margin: ${size.xl1}px 10px;
  background-color: ${colors.white};

  ${mediaQueries.forTabletVerticalUp} {
    margin: 0;
    border-radius: 0 8px 8px 0;
  }
`;

export const CardHeader = styled(H5)`
  margin: 0 0 8px;
  min-width: 280px;
`;

export const CardTitle = styled.div`
  color: ${colors.gray4};
  font-size: ${size.md}px;
  font-weight: ${typography.weight.light};
  line-height: 26px;
  padding-bottom: 8px;
`;

export const CardListContainer = styled.div`
  margin-left: 20px;
`;

export const CardList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0;
  margin: 0 0 8px;
`;

export const CardListItem = styled.li`
  margin: 0;
`;

export const CardIconContainer = styled.div`
  margin-right: 26px;
`;

export const CardIcon = styled.img`
  width: 32px;
  height: 32px;
  align-self: center;

  ${mediaQueries.forTabletVerticalUp} {
    align-self: flex-start;
  }
`;

export const CardContent = styled.div`
  color: ${colors.gray4};
  display: flex;
  flex-direction: column;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.25px;
  font-feature-settings: 'ss01' on;
`;
