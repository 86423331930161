/**
 * ConfirmIdentifiersButton
 */

import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import React from 'react';

import * as S from '../../styles';

type Props = {
  issueId?: number;
};

const ConfirmIdentifiersButton = ({ issueId }: Props) => (
  <S.KitButton
    data-test="confirm-identifiers-button"
    appearance="primary"
    href={`/confirm-identifiers/${issueId}`}
    onClick={() => {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: `Confirm Identifiers`,
          issue_id: issueId,
          category: 'Results Dashboard',
        },
      });
    }}
  >
    Confirm Information
  </S.KitButton>
);

export default ConfirmIdentifiersButton;
