import useUser from 'common/hooks/useUser';
import analytics from 'common/utils/analytics';
import {
  CONTENTFUL_PUBLIC_ACCESS_TOKEN,
  CONTENTFUL_PUBLIC_SPACE_ID,
} from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import {
  showWeightCareSectionFooter,
  showWeightCareAppStoreSection,
  showWeightCareJourneyChecklist,
  showWeightCareAppointmentsSection,
  showWeightCareMyPrescriptions,
  showWeightCareRecommendedArticles,
  showWeightCareMyTestKits,
} from 'common/utils/flags';
import { PROGRAM_TYPE } from 'common/utils/types';
import AppStoreSection from 'components/AppStoreSection/AppStoreSection';
import MyEverlyPhoneWithHand from 'components/AppStoreSection/assets/MyEverlyPhoneWithHand.png';
import JourneyChecklist from 'components/JourneyChecklist';
import RecommendedArticlesSection from 'components/RecommendedArticlesSection';
import SectionFooter from 'components/SectionFooter/SectionFooter';
import ApolloContainer from 'containers/ApolloContainer';
import MyPrescriptions from 'pages/AccountHub/components/MyPrescriptions';
import MyTestKits from 'pages/AccountHub/components/MyTestKits';
import React, { useState, useEffect } from 'react';

import MyAppointments from '../../components/MyAppointments';
import FollowUpSection from '../TelehealthDashboard/FollowUpSection';
import * as S from './WeightManagementDashboard.styles';

export type WeightManagementDashboardProps = {};

/**
 * Weight management dashboard that shows users information and actions
 * related to the weight management experience
 */
function WeightManagementDashboard(props: WeightManagementDashboardProps) {
  const [isWmDashboardViewed, setIsWmDashboardViewed] =
    useState<boolean>(false);

  const { user } = useUser();

  const WC_SLUGS = [
    PROGRAM_TYPE['weight-management'],
    PROGRAM_TYPE['weight-management-full'],
    PROGRAM_TYPE['weight-care-ongoing'],
  ];

  useEffect(() => {
    if (user && !isWmDashboardViewed) {
      analytics.track({
        event: ANALYTICS.EVENTS.VIEWED_PAGE,
        data: {
          page: ANALYTICS.PAGES.ACCOUNT_HUB.WEIGHT_CARE_PLUS,
          category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
        },
      });
      setIsWmDashboardViewed(true);
    }
  }, [user, isWmDashboardViewed]);

  return (
    <>
      <S.PageContent>
        <S.HeaderContainer>
          <S.Title>Weight Care+</S.Title>
          <S.SubTitle>Let's do this</S.SubTitle>
        </S.HeaderContainer>
        {/*
          Show all other sections here. Eg: WC+ journey, Care plans, etc.
          Each section should be a component used in as <S.Section> below
        */}

        {/* Future appointments */}
        {showWeightCareAppointmentsSection() ? (
          <MyAppointments
            title="My Appointments"
            filterProgramSlugs={WC_SLUGS}
            period="future"
          />
        ) : null}

        {showWeightCareJourneyChecklist() ? (
          <JourneyChecklist slugFilter="weight-care-getting-started" />
        ) : null}

        <div>
          {/* This page is only shown for WC+ users */}
          <FollowUpSection isWcPlusMember={true} />
        </div>

        {/* Past appointments */}
        {showWeightCareAppointmentsSection() ? (
          <MyAppointments
            title="Appointment history"
            filterProgramSlugs={WC_SLUGS}
            period="past"
          />
        ) : null}

        {showWeightCareMyPrescriptions() ? <MyPrescriptions /> : null}

        {showWeightCareMyTestKits() ? <MyTestKits /> : null}

        {showWeightCareAppStoreSection() ? (
          <S.Section>
            <AppStoreSection
              title="Check your progress from anywhere"
              description="Manage care, join your appointments, and track your progress right from the Everlywell app."
              starRatings={4.9}
              image={{
                src: MyEverlyPhoneWithHand,
                alt: 'My Everly Phone With Hand Image',
              }}
            />
          </S.Section>
        ) : null}

        {showWeightCareRecommendedArticles() ? (
          <ApolloContainer
            spaceId={CONTENTFUL_PUBLIC_SPACE_ID}
            contentfulPublicAccessToken={CONTENTFUL_PUBLIC_ACCESS_TOKEN}
          >
            <S.Section>
              <RecommendedArticlesSection title="Recommended articles" />
            </S.Section>
          </ApolloContainer>
        ) : null}

        {showWeightCareSectionFooter() ? (
          <S.Section>
            <SectionFooter
              title="Have questions?"
              subTitle="We have answers"
              link={{
                href: 'https://support.everlywell.com/article/1857-weight-care-faq',
                label: 'Explore FAQs and get 1:1 help',
              }}
            ></SectionFooter>
          </S.Section>
        ) : null}
      </S.PageContent>
    </>
  );
}

export default WeightManagementDashboard;
