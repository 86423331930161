import { REGISTRATION_MODAL_ACTIONS as ACTIONS } from 'common/utils/constants';
import { REGISTRATION_CONFIRMATION as DATA_TEST } from 'common/utils/constants/dataTest';
import { KitRegistrationUser } from 'common/utils/types';
import { BaseModal } from 'components/KitRegistration/styles';
import React from 'react';

import * as S from './styles';

export interface ConfirmationErrorWithAddressModalProps {
  openModal: boolean;
  setOpenModal: (cta: string) => void;
  title: string;
  user: KitRegistrationUser;
  message: string;
}

const ConfirmationErrorWithAddressModal = ({
  openModal,
  setOpenModal,
  title,
  user,
  message,
}: ConfirmationErrorWithAddressModalProps) => {
  const onEditAddressButtonClick = () => setOpenModal(ACTIONS.EDIT_ADDRESS);
  const onUseAddressButtonClick = () => setOpenModal(ACTIONS.USE_ADDRESS);

  return (
    <BaseModal
      open={openModal}
      openHandler={setOpenModal}
      data-test={`${DATA_TEST.MODAL}-error-with-address`}
    >
      <S.Wrapper>
        <S.Body>
          <S.Title>{title}</S.Title>
          <S.Subtitle>{message}</S.Subtitle>

          <S.AddressWrapper>
            <S.AddressTitle>You entered:</S.AddressTitle>
            <S.Address>
              <p>{user.streetAddress.value}</p>
              {!!user.subAddress.value && <p>{user.subAddress.value}</p>}
              <p>
                {user.addressCity.value}, {user.addressState.value}{' '}
                {user.addressZipCode.value}
              </p>
            </S.Address>
          </S.AddressWrapper>
        </S.Body>

        <S.ButtonWrapper>
          <S.UseAddressButton
            appearance="secondary"
            onClick={onUseAddressButtonClick}
          >
            {ACTIONS.USE_ADDRESS}
          </S.UseAddressButton>

          <S.EditAddressButton
            appearance="primary"
            onClick={onEditAddressButtonClick}
          >
            {ACTIONS.EDIT_ADDRESS}
          </S.EditAddressButton>
        </S.ButtonWrapper>
      </S.Wrapper>
    </BaseModal>
  );
};

export default ConfirmationErrorWithAddressModal;
