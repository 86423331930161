import { colors, mediaQueries, typography } from '@everlywell/leaves';
import wave from 'common/images/wave.svg';
import {
  sectionTitleLarge,
  sectionTitle,
  bodyCopy,
} from 'common/styles/app-styles';
import Button from 'components/Button';
import styled from 'styled-components';

const Wrapper = styled.div`
  /* prettier-ignore */
  background: ${colors.white} url("${wave}")
    bottom center no-repeat;
  padding-top: 64px;
  height: 665px;
  ${mediaQueries.forBigDesktopUp} {
    background-size: 100%;
  }
`;

const Image = styled.img`
  width: 234px;
  height: 159px;
  object-fit: contain;
`;

const AlertLg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${typography.weight.bold};
  background-color: ${colors.white};
  flex-direction: column;
  text-align: center;
  margin: auto;
`;

const Heading = styled.div`
  ${sectionTitle};
  width: 326px;
  text-align: center;
  margin-top: 41px;
  max-width: 600px;
  color: ${colors.gray5};
  ${mediaQueries.forTabletVerticalUp} {
    ${sectionTitleLarge};
    width: 100%;
    margin-top: 59px;
    margin-bottom: 16px;
  }
`;

const SubHeading = styled.div`
  ${bodyCopy};
  font-weight: ${typography.weight.light};
  width: 327px;
  margin-bottom: 32px;
  max-width: 600px;
  text-align: center;
  color: ${colors.gray4};

  ${mediaQueries.forTabletVerticalUp} {
    ${sectionTitle};
    width: 100%;
  }
`;

const BackButton = styled(Button)`
  &:hover,
  &:active {
    text-decoration: none;
    color: inherit;
  }

  &:focus {
    color: inherit;
    &:active {
      text-decoration: none;
      color: inherit;
    }
  }
`;

const Accent = styled.div`
  width: 100%;
  height: 16px;
  opacity: 0.5;
  background-color: ${colors.gray2};
`;

const ButtonWrapper = styled.div`
  width: 280px;
  display: block;
  margin-bottom: 25px;
`;

export {
  Wrapper,
  AlertLg,
  ButtonWrapper,
  BackButton,
  Image,
  Heading,
  SubHeading,
  Accent,
};
