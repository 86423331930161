import React from 'react';

import * as S from './styles';

type Props = {
  sectionName: string;
  children: React.ReactNode;
  reference: any;
};

function ResultDetailSection(props: Props) {
  const { children, sectionName, reference } = props;
  return (
    <S.Section id={sectionName}>
      <S.SectionBody ref={reference}>{children}</S.SectionBody>
    </S.Section>
  );
}

export default ResultDetailSection;
