import { colors, typography } from '@everlywell/leaves';
import { bodyCopy } from 'common/styles/app-styles';
import styled from 'styled-components';

type Props = {
  color: string;
};

const HistoricalDataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: solid 1px ${colors.gray2};
  margin-top: 16px;
  padding-top: 16px;
`;

const HistoricalTimeLabel = styled.div`
  ${bodyCopy};
  padding-right: 8px;
  font-weight: ${typography.weight.light};
`;

const PreviousValue = styled.div`
  ${bodyCopy};
  font-weight: ${typography.weight.bold};
  display: flex;
  align-items: center;
`;

const PreviousSeverityIndicator = styled.div<Props>`
  background-color: rgb(${(props) => props.color});
  height: 14px;
  width: 14px;
  border-radius: 50%;
  margin-left: 4px;
`;

export {
  HistoricalDataWrapper,
  HistoricalTimeLabel,
  PreviousValue,
  PreviousSeverityIndicator,
};
