import {
  TelehealthMedication,
  TelehealthMedicationV2,
} from 'common/apis/telehealthApis';

import { buildTelehealthPharmacy } from './telehealthPharmacy';

const baseTelehealthMedication: TelehealthMedication = {
  product_name: 'levothyroxine 75 mcg (0.075 mg) oral tablet',
  quantity: '30',
  refills: '12',
  status: 'Error',
  directions: 'Take once a day on empty stomach',
  date_written: '2022-08-25T19:14:02.807',
  pharmacy: buildTelehealthPharmacy(),
};

const baseTelehealthMedicationV2: TelehealthMedicationV2 = {
  id: 1,
  name: 'asparaginase (Erwinia chrysan)',
};

const buildEntity = <T>(base: T, overrideProps: Partial<T> = {}): T => ({
  ...base,
  ...overrideProps,
});

export const buildTelehealthMedication = (
  overrideProps: Partial<TelehealthMedication> = {},
) => buildEntity(baseTelehealthMedication, overrideProps);

export const buildTelehealthMedicationV2 = (
  overrideProps: Partial<TelehealthMedicationV2> = {},
) => buildEntity(baseTelehealthMedicationV2, overrideProps);
