import { getRequest, postRequest } from 'common/hooks/useApi/request';
import { API_ROOT, LEGACY_APP_ROOT } from 'common/utils/constants';
import { isArray } from 'lodash-es';

/**
 * Get Telehealth Providers
 */
export type Provider = {
  id: string;
  name: string;
  credentials: Array<string>;
  has_future_availabilities: boolean;
};

export type GetProvidersParams = {
  programSlug: string;
};

export const getTelehealthProviders = async (params: GetProvidersParams) => {
  const { programSlug } = params;
  return getRequest<Array<Provider>>(
    `${API_ROOT}/v3/telehealth/providers?program_slug=${programSlug}`,
    false,
    {
      rejectOnError: true,
    },
  );
};

/**
 * Get Telehealth Appointment Times/Slots
 */

export type AppointmentsSlotsMeta = {
  user: {
    id: number;
    time_zone: string;
    state_abbrev: string;
  };
  appointment_type: {
    id: number;
    name: string;
    contact_type: string | null;
  };
};

export type AppointmentSlot = {
  datetime: string;
  provider: {
    id: string;
    name: string;
    credentials: Array<string>;
  };
};

export type AppointmentsSlotsResponse = {
  slots: AppointmentSlot[];
  meta: AppointmentsSlotsMeta;
};

export type SelectedProvider = number | number[] | null;

type GetAppointmentsSlotsParams = {
  programSlug: string;
  startDate: string;
  endDate: string;
  selectedProvider: SelectedProvider;
  selectedAppointmentTypeId: string | null;
};

/**
 * GET url native schedule get appointments and slots
 * URL
 * `${apiRoot}/aapi/v3/telehealth/${program}/appointment_slots?start_date=2023-07-13&end_date=2023-07-19`
 *
 * optional params: `provider_ids`
 * ex: `&provider_ids[]=60594&provider_ids[]=62898`
 */

/**
 * For the params especially the selectedProvider, we are using number even if we expect a string.
 * The reason is the API will always expects a number for the provider id, but it is using a string for the selectedProvider parameter.
 */

export const getAppointmentsSlots = async (
  params: GetAppointmentsSlotsParams,
) => {
  const {
    endDate,
    programSlug,
    selectedProvider,
    startDate,
    selectedAppointmentTypeId,
  } = params;
  const providerParam = isArray(selectedProvider)
    ? selectedProvider.map((provider) => `&provider_ids[]=${provider}`).join('')
    : `&provider_ids[]=${selectedProvider}`;
  const appointmentTypeParam = selectedAppointmentTypeId
    ? `&appointment_type_id=${selectedAppointmentTypeId}`
    : '';
  const response = await getRequest<AppointmentsSlotsResponse>(
    `${LEGACY_APP_ROOT}/aapi/v3/telehealth/${programSlug}/appointment_slots/?start_date=${startDate}&end_date=${endDate}${providerParam}${appointmentTypeParam}`,
    false,
    { rejectOnError: true },
  );
  return response;
};

/**
 * Create Telehealth Appointment
 */

type CreateOnDemandTelehealthAppointmentRequestData = {
  program_slug: string;
  booking_type: 'on_demand';
  user_plan_id: number | null;
};

type CreateAsyncTelehealthAppointmentRequestData = Omit<
  CreateOnDemandTelehealthAppointmentRequestData,
  'booking_type'
> & {
  booking_type: 'async';
};

type CreateSelfScheduledTelehealthAppointmentRequestData = {
  program_slug: string;
  booking_type: 'self_scheduled';
  datetime: string; // example 2023-07-04T10:09:00Z
  provider_id: string;
  user_plan_id: number | null;
  appointment_type_id: string | number | null;
  kit_results: AppointmentKitResult[] | null | undefined;
  reason: string | null | undefined;
};

export type CreateTelehealthAppointmentRequestData =
  | CreateAsyncTelehealthAppointmentRequestData
  | CreateOnDemandTelehealthAppointmentRequestData
  | CreateSelfScheduledTelehealthAppointmentRequestData;

export type NewAppointmentProvider = {
  id: string;
  first_name: string;
  last_name: string;
  credentials: string[];
  has_future_availabilities: boolean;
};

export type CalendarLinks = {
  google_cal: string;
  outlook_cal: string;
  ical: string;
  yahoo_cal: string;
};

export type AppointmentReason = {
  reason: string | null;
  kit_results: AppointmentKitResult[] | null;
};

type AppointmentKitResult = {
  id: string | number;
};

export type NewSelfScheduledAppointment = {
  id: string;
  /**
   * @example "2023-07-04T10:00:00Z"
   */
  start_at: string;
  /**
   * @example "2023-07-04T10:30:00Z"
   */
  end_at: string;
  type: 'self_scheduled';
  join_appointment_url: string | null;
  appointment_type: {};
  provider: NewAppointmentProvider;
  add_to_calendar_links: CalendarLinks;
};
export type NewOnDemandAppointment = {
  id: string;
  type: 'on_demand';
  booking_type: 'on_demand';
  on_demand_link: string;
};

export type NewAsyncAppointment = Omit<
  NewOnDemandAppointment,
  'on_demand_link'
> & {
  type: 'async';
  booking_type: 'async';
};

type CreateTelehealthAppointmentResponse =
  | NewAsyncAppointment
  | NewOnDemandAppointment
  | NewSelfScheduledAppointment;

export type NewTelehealthAppointment = CreateTelehealthAppointmentResponse;

/**
 * Creates either an on-demand or self-scheduled telehealth appointment
 */
export const createTelehealthAppointment = async <
  T = CreateTelehealthAppointmentResponse,
>(
  data: CreateTelehealthAppointmentRequestData,
) =>
  postRequest<T>(`${API_ROOT}/v3/telehealth/appointments`, data, false, {
    rejectOnError: true,
  });

/**
 * Creates a self-scheduled telehealth appointment
 */
export const createSelfScheduledTelehealthAppointment = async (
  data: Omit<
    CreateSelfScheduledTelehealthAppointmentRequestData,
    'booking_type'
  >,
) =>
  createTelehealthAppointment<NewSelfScheduledAppointment>({
    ...data,
    booking_type: 'self_scheduled',
  });

/**
 * Creates an on-demand telehealth appointment
 */
export const createOnDemandTelehealthAppointment = async (
  data: Omit<CreateOnDemandTelehealthAppointmentRequestData, 'booking_type'>,
) =>
  createTelehealthAppointment<NewOnDemandAppointment>({
    ...data,
    booking_type: 'on_demand',
  });

/**
 * Creates an async telehealth appointment
 */
export const createAsyncTelehealthAppointment = async (
  data: Omit<CreateAsyncTelehealthAppointmentRequestData, 'booking_type'>,
) =>
  createTelehealthAppointment<NewAsyncAppointment>({
    ...data,
    booking_type: 'async',
  });
