import {
  mediaQueries,
  size,
  SkeletonLoader,
  SkeletonUnit,
} from '@everlywell/leaves';
import React from 'react';

import * as HeaderStyles from './components/CardHeader/CardHeader.styles';
import * as S from './PrescriptionCard.styles';

function PrescriptionCardSkeleton() {
  return (
    <S.Container>
      <HeaderStyles.Container>
        <HeaderStyles.LeftColumn>
          <SkeletonLoader
            width={{
              value: 70,
              unit: SkeletonUnit.Pixel,
            }}
            height={{
              value: 30,
              unit: SkeletonUnit.Pixel,
            }}
            css={{
              margin: `${size.xl1}px 0 ${size.md}px`,
              [mediaQueries.forTabletVerticalUp]: {
                marginTop: 0,
                marginBottom: `${size.xs1}px`,
              },
            }}
          />

          <HeaderStyles.Heading as="div">
            <SkeletonLoader
              width={{
                value: 80,
                unit: SkeletonUnit.Percentage,
              }}
              height={{
                value: 28,
                unit: SkeletonUnit.Pixel,
              }}
            />
          </HeaderStyles.Heading>

          <HeaderStyles.Details as="div">
            <SkeletonLoader
              width={{
                value: 35,
                unit: SkeletonUnit.Percentage,
              }}
              height={{
                value: 16,
                unit: SkeletonUnit.Pixel,
              }}
            />
          </HeaderStyles.Details>
          <HeaderStyles.Details as="div">
            <SkeletonLoader
              width={{
                value: 35,
                unit: SkeletonUnit.Percentage,
              }}
              height={{
                value: 16,
                unit: SkeletonUnit.Pixel,
              }}
            />
          </HeaderStyles.Details>
        </HeaderStyles.LeftColumn>
        <HeaderStyles.RightColumn>
          <SkeletonLoader
            width={{
              value: 25,
              unit: SkeletonUnit.Pixel,
            }}
            height={{
              value: 25,
              unit: SkeletonUnit.Pixel,
            }}
          />
        </HeaderStyles.RightColumn>
      </HeaderStyles.Container>
    </S.Container>
  );
}

export default PrescriptionCardSkeleton;
