import { colors, Localize, LocalizeIgnore } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { KIT_ID_MODAL as DATA_TEST } from 'common/utils/constants/dataTest';
import { EVERLYWELL_SUPPORT_ROOT } from 'common/utils/constants/urls';
import { isBloodDrawActive } from 'common/utils/flags';
import Tabs from 'components/Tabs';
import TabPane from 'components/Tabs/TabPane';
import React from 'react';

import { BaseModal } from '../styles';
import * as S from './styles';

type Props = {
  openModal: boolean;
  setOpenModal: (bool: boolean) => void;
};

const KIT_LABEL = 'Unique KIT ID';
const QR_CODE_SRC =
  'https://images.ctfassets.net/ydyvnem5zkxh/EFH8X798tD79Iv9WovrRW/29e868d4cfdd000ac9c189e8b37edd6d/QR_Code2x.png';

const AtHomeTabContent = (
  <>
    <S.Title data-testid={DATA_TEST.TITLE}>
      Can’t find your Unique <LocalizeIgnore>Kit ID</LocalizeIgnore>?
    </S.Title>
    <S.Subtitle data-testid={DATA_TEST.SUBTITLE}>
      Your Unique <Localize name="KitIDModal-KitID">Kit ID</Localize> and QR
      Code are located on the registration slip inside of your kit. The slip
      will look like one of the two images:
    </S.Subtitle>
    <S.ShapeContainer data-testid={DATA_TEST.SHAPES}>
      <S.CombinedShape color={colors.teal6}>
        <S.Notch />
        <S.LabelContainer firstLabel>
          <p>{KIT_LABEL}</p>
        </S.LabelContainer>
      </S.CombinedShape>
      <S.CombinedShape color="#39A372">
        <S.Notch />
        <S.LabelContainer withQR>
          <img src={QR_CODE_SRC} alt="QR Code" />
          <p>{KIT_LABEL}</p>
        </S.LabelContainer>
      </S.CombinedShape>
    </S.ShapeContainer>
    <S.Subtitle data-testid={DATA_TEST.INFO}>
      Enter the Unique <Localize name="KitIDModal-KitID2">Kit ID</Localize> or
      use your mobile device and scan the QR Code found on these slips. The
      Unique <Localize name="KitIDModal-KitID2">Kit IDs</Localize> are not case
      sensitive, and you don’t need to enter the dashes.
    </S.Subtitle>
  </>
);

const InLabTabContent = (
  <>
    <S.Title data-testid={DATA_TEST.TITLE}>
      Can’t find your Unique <LocalizeIgnore>Lab Request ID</LocalizeIgnore>?
    </S.Title>
    <S.Subtitle data-testid={DATA_TEST.SUBTITLE}>
      Enter the Unique lab request ID you received in your order confirmation
      email or click the button in the email to fill in your ID.
    </S.Subtitle>
    <S.Subtitle data-testid={DATA_TEST.INFO}>
      If you can’t find your order confirmation email, please reach out to{' '}
      <S.Link
        to={`${EVERLYWELL_SUPPORT_ROOT}/article/45-how-do-i-submit-a-customer-support-request`}
      >
        Customer Experience
      </S.Link>
      .
    </S.Subtitle>
  </>
);

const KitIDModal = ({ openModal, setOpenModal }: Props) => {
  const handleTracking = (title: string) => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: `Help Modal Tab: ${title}`,
        category: ANALYTICS.CATEGORIES.REGISTRATION,
      },
    });
  };

  return (
    <BaseModal
      open={openModal}
      openHandler={setOpenModal}
      data-test={DATA_TEST.MODAL}
    >
      {/* while the blood draw is not active we only show the first tab */}
      <Tabs hideTitles={!isBloodDrawActive()} handleCallback={handleTracking}>
        <TabPane title="At-home">{AtHomeTabContent}</TabPane>
        <TabPane title="In-lab">{InLabTabContent}</TabPane>
      </Tabs>
    </BaseModal>
  );
};

export default KitIDModal;
