type PlanIdType = null | number;

/**
 * UserPlanId class is a singleton class that stores the planId in the session storage.
 */
class UserPlanId {
  private static instance: UserPlanId;
  private planIdData: PlanIdType;

  private constructor() {
    this.planIdData = null;
  }

  public static getInstance(): UserPlanId {
    if (!UserPlanId.instance) {
      UserPlanId.instance = new UserPlanId();
    }
    return UserPlanId.instance;
  }

  get planId(): PlanIdType {
    const storedPlanId = window.sessionStorage.getItem('planId');
    return Number(storedPlanId) ? Number(storedPlanId) : null;
  }

  set planId(value: PlanIdType | string) {
    if (value === null || value === '') {
      this.removePlanId();
      return;
    }
    this.planIdData = Number(value) || null;
    window.sessionStorage.setItem('planId', this.planIdData?.toString() || '');
  }

  removePlanId() {
    window.sessionStorage.removeItem('planId');
  }
}

const userPlanId = UserPlanId.getInstance();

export { userPlanId };
