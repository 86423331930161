/* stylelint-disable selector-type-no-unknown */
import { mediaQueries } from '@everlywell/leaves';
import styled, { css } from 'styled-components';

const Text = styled.div`
  width: 100%;
  margin-bottom: 7px;
`;

const HideLastChildText = css`
  ${Text}:last-child {
    display: none;
  }
`;

const UpperParagraphWrapper = styled.div`
  width: 100%;

  ${mediaQueries.forTabletHorizontalUp} {
    /* prettier-ignore */
    ${Text}:nth-child(2) {
      width: 54%;
    }

    ${HideLastChildText};
  }
`;

const LowerParagraphWrapper = styled.div`
  width: 100%;
  margin: 47px 0 16px;

  ${Text}:last-child {
    width: 80%;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    margin-top: 25px;

    ${Text}:first-child {
      width: 80%;
    }

    ${HideLastChildText};
  }
`;

export { Text, UpperParagraphWrapper, LowerParagraphWrapper };
