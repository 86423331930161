import { Button, colors, mediaQueries, size, Icons } from '@everlywell/leaves';
import arrowIcon from 'components/SubHeader/SubHeaderButton/images/chevron.svg';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SubNavbar = styled.nav`
  width: 100%;
  height: 52px;
  background-color: ${colors.green5};
  padding: ${size.md}px ${size.lg}px;
  position: relative;
  font-size: ${size.md}px;
  justify-content: center;
  display: flex;
  ${mediaQueries.forPhoneOnly} {
    margin: 0 !important;
    padding: ${size.md}px;
  }
`;

const SubNavbarContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
  position: relative;
  white-space: nowrap;

  & div {
    span {
      color: ${colors.white};
    }

    button {
      padding: ${size.xs2}px ${size.sm}px;
      color: ${colors.white};

      &:hover {
        color: ${colors.white};
        background-color: rgba(184, 221, 225, 0.2);
      }
    }

    &:last-child {
      margin-right: ${size.sm}px;

      button {
        border-color: transparent;
        color: ${colors.white};

        &:focus {
          background-color: transparent;
          border-color: transparent;
        }
      }
      ${mediaQueries.forPhoneOnly} {
        margin-right: 0;
      }
    }
  }
`;

const ContentLeft = styled.div`
  display: block;
`;

const ContentRight = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 100%;
  justify-content: flex-end;
`;

const BackToDashboard = styled(Link)`
  align-items: center;
  color: ${colors.white};
  display: flex;
  padding: ${size.xs2}px ${size.sm}px;
  text-decoration: none;

  &:before {
    content: '';
    display: inline-block;
    margin-right: ${size.sm}px;
    height: ${size.sm}px;
    width: ${size.sm}px;

    /* prettier-ignore */
    mask: url("${arrowIcon}"); /* Image url needs double quotes instead of single due to svg-url-loader */
    mask-repeat: no-repeat;
    background-color: ${colors.white};
    transform: rotate(90deg);
  }

  &:hover {
    text-decoration: none;
    color: ${colors.white};
    background-color: rgba(184, 221, 225, 0.2);

    &:before {
      background: ${colors.white};
    }
  }

  ${mediaQueries.forPhoneOnly} {
    span {
      display: none;
    }

    padding: ${size.xs2}px 0;
  }
`;

const PdfButton = styled(Button)`
  text-decoration: none !important;
  height: max-content;
  margin-left: ${size.xl1}px;
  border: 1px solid ${colors.white};
  font-size: ${size.md}px;
  padding: ${size.xs2}px ${size.sm}px;

  &:hover,
  &:focus {
    background: ${colors.green4};
    border-color: ${colors.white};
  }
  ${mediaQueries.forPhoneOnly} {
    span {
      display: none;
    }

    margin-left: ${size.md}px;
  }
`;

const DownloadIcon = styled(Icons.Download)`
  margin: 0 ${size.sm}px 0 0 !important;
  display: inline-block;
  width: ${size.md}px !important;
  height: ${size.md}px !important;
  ${mediaQueries.forPhoneOnly} {
    margin: 0 !important;
    background-color: transparent !important;
  }
`;

export {
  SubNavbar,
  SubNavbarContent,
  BackToDashboard,
  ContentLeft,
  ContentRight,
  PdfButton,
  DownloadIcon,
};
