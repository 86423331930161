/**
 *
 * analytics
 *
 * This utility acts as a helper for access to mixpanel
 * tracking methods, and will eventually be a module for any other
 * analytic libraries
 *
 */
import analytics from 'common/utils/analytics';
import { freshpaint } from 'common/utils/trackers';
import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useInView } from 'react-intersection-observer';

import { ENTERPRISE_MIXPANEL_API_KEY, MIXPANEL_API_KEY } from './constants';
import { ANALYTICS } from './constants/analytics';
import { hashSha1, isEnterpriseCustomer, logError } from './helpers';
import { User } from './types';

export interface TrackProps {
  event: string;
  data: {
    [prop: string]: string | string[] | number | boolean | undefined | null;
  };
}

let initialized = false;

export const init = () => {
  const mixpanelApiKey = isEnterpriseCustomer()
    ? ENTERPRISE_MIXPANEL_API_KEY
    : MIXPANEL_API_KEY;

  return new Promise((resolve, reject) => {
    if (mixpanelApiKey) {
      if (!initialized) {
        mixpanel.init(mixpanelApiKey);
        initialized = true;
      }
      // @ts-ignore
      return resolve(mixpanel);
    }

    return reject();
  });
};

export const identify = (id: string) =>
  init()
    .then(() => {
      mixpanel.identify(id);

      return Promise.resolve();
    })
    .catch(() => Promise.resolve());

export const alias = (id: string) =>
  init()
    .then(() => {
      mixpanel.alias(id);

      return Promise.resolve();
    })
    .catch(() => Promise.resolve());

export const track = (props: TrackProps) =>
  init()
    .then(() => {
      const { data, event } = props;

      const enrolledPrograms = JSON.parse(
        window.localStorage.getItem('enrolledPrograms') || '',
      );

      const trackData = {
        ...data,
        ...(enrolledPrograms && { enrolledPrograms }),
      };

      mixpanel.track(event, trackData);

      return Promise.resolve();
    })
    .catch(() => Promise.resolve());

export const setUserInfo = (user: User) =>
  init()
    .then(() => {
      const {
        created_at: createdAt,
        consumer_attributes,
        id,
        ip_address: ipAddress,
      } = user;
      const age =
        'age' in consumer_attributes ? consumer_attributes.age : undefined;
      const gender =
        'age' in consumer_attributes ? consumer_attributes.gender : undefined;

      const userInfo = {
        'EverlyWell user ID': id,
        'IP address': ipAddress,
        $created: createdAt,
        Gender: gender,
        Age: age,
      };

      mixpanel.people.set(userInfo);
      mixpanel.identify(id.toString());

      freshpaint.identify(id.toString(), {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
      });

      return Promise.resolve();
    })
    .catch((error) =>
      logError((error as Error).message, {
        errorInfo: 'Error setting user info in analytics',
        method: 'setUserInfo',
      }),
    );

/*
 * handle events for analytics tracking
 *
 * required attribute on the HTML element:
 * * data-analytics-event
 *
 * optional attributes:
 * * data-analytics-label
 * * data-analytics-category
 * * data-analytics-test-id
 *
 */
export const eventHandler = (event: Event) => {
  const clickedElement = event.target as HTMLAnchorElement;
  // if the element that was clicked was a child of the element with the data-analytics-event
  const element = clickedElement.closest(
    '[data-analytics-event]',
  ) as HTMLAnchorElement;

  // tracking requires data attributes
  if (!element || !element.dataset) return;

  const { analyticsEvent, analyticsLabel, analyticsCategory, analyticsTestId } =
    element.dataset;

  // event is required
  if (!analyticsEvent) return;

  track({
    event: analyticsEvent,
    data: {
      label: analyticsLabel,
      category: analyticsCategory,
      test_id: analyticsTestId,
    },
  });
};

const handleTrackableLinkClick = (e: Event): void => {
  eventHandler(e);
};

const useAttachTrackingToLinks = (ref: React.RefObject<HTMLElement>): void => {
  useEffect(() => {
    let trackableLinks: NodeListOf<HTMLAnchorElement> | null;
    if (ref.current) {
      trackableLinks = ref.current.querySelectorAll('[data-analytics-event]');
      if (trackableLinks) {
        trackableLinks.forEach((link) =>
          link.addEventListener('click', handleTrackableLinkClick),
        );
      }
    }

    return (): void => {
      if (trackableLinks) {
        trackableLinks.forEach((link) =>
          link.removeEventListener('click', handleTrackableLinkClick),
        );
      }
    };
  }, [ref]);
};

export interface ComponentVisibilityTrackingData {
  label: string;
  slug?: string;
  id?: string;
}
export const useTrackComponentVisibility = (
  inView: boolean,
  trackingData: ComponentVisibilityTrackingData,
): void => {
  try {
    useEffect(() => {
      if (inView) {
        analytics.track({
          event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
          data: {
            ...trackingData,
          },
        });
      }
    }, [inView, trackingData]);
  } catch (err: any) {
    logError(err?.message, {
      method: 'trackComponentVisibility',
    });
  }
};
type TrackVisibilityWrapperProps = {
  label: string;
  children: React.ReactNode;
  slug?: string;
  appointmentId?: string | null;
  formAnswerGroupId?: string | null;
};
export const TrackVisibilityWrapper = ({
  label,
  children,
  slug,
  appointmentId,
  formAnswerGroupId,
}: TrackVisibilityWrapperProps): JSX.Element => {
  const [componentRef, inComponentView] = useInView();
  useTrackComponentVisibility(inComponentView, {
    label,
    ...(appointmentId && { appointmentId }),
    ...(formAnswerGroupId && { formAnswerGroupId }),
    ...(slug && {
      slug,
    }),
  });

  return <div ref={componentRef}>{children}</div>;
};

export const setPageViewDataLayer = (user: User) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      hashedEmail: hashSha1(user.email),
      userId: user.id,
    },
  });
};

export default {
  identify,
  alias,
  setUserInfo,
  track,
  eventHandler,
  useAttachTrackingToLinks,
  TrackVisibilityWrapper,
  setPageViewDataLayer,
};
