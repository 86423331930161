import {
  getOrdersByTypeAndId,
  TelehealthOrder,
} from 'common/apis/telehealthApis';
import { useState } from 'react';
import { useQueries } from 'react-query';

export type OrderParam = {
  type: string;
  id: string;
};

const useOrders = (initialOrders: OrderParam[]) => {
  const [params, setParams] = useState(initialOrders);
  const [shouldFetch, setShouldFetch] = useState(false);

  const results = useQueries(
    params.map((order) => ({
      queryKey: ['telehealth-order-by-type-and-id', order],
      queryFn: () => getOrdersByTypeAndId(order),
      refetchOnWindowFocus: false,
      enabled: shouldFetch,
    })),
  );

  const fetchOrders = (newOrders: OrderParam[]) => {
    setParams(newOrders);

    setShouldFetch(true);

    return () => {
      setShouldFetch(false);
    };
  };

  const isLoading = results.some((result) => result.isLoading);
  const isError = results.some((result) => result.isError);
  const orders = results.map((result, index) => ({
    type: params[index].type,
    id: Number(params[index].id),
    order: result?.data?.data?.order ?? ({} as TelehealthOrder),
  }));

  return {
    isLoading,
    isError,
    orders,
    fetchOrders,
  };
};

export default useOrders;
