import { size, H5, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Card = styled.div`
  padding: ${size.lg}px;
  border: 1px solid #edf5f1;
  width: 100%;
`;

export const CardHeaderContainer = styled.div`
  display: flex;
  align-self: flex-start;
  padding: 0;
`;

export const CardIconContainer = styled.div`
  margin-right: ${size.lg}px;
`;

export const CardHeader = styled(H5)`
  margin: 0 0 ${size.xs1}px;
  min-width: 280px;
`;

export const CardIcon = styled.img`
  width: ${size.xl1}px;
  height: ${size.xl1}px;
  align-self: center;

  ${mediaQueries.forTabletVerticalUp} {
    align-self: flex-start;
  }
`;
