import React from 'react';

import * as S from './styles';

type Props = { [key: string]: any; label: string };

function RadioButton(props: Props) {
  const { label, ...rest } = props;

  return (
    <S.Wrapper>
      <S.Input type="radio" id={label} {...rest} />
      <S.Label htmlFor={label}>{label}</S.Label>
    </S.Wrapper>
  );
}

export { RadioButton };
