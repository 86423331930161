import React, { ComponentPropsWithoutRef, useContext } from 'react';

import * as S from './Grid.styles';

const SpacingContext = React.createContext<
  | S.Spacing[]
  | {
      spacingX: S.Spacing[];
      spacingY: S.Spacing[];
    }
>([]);

export type GridProps = {};

/**
 * Grid component to help with layout management
 */
function Grid(props: GridProps) {
  return null;
}

/**
 * Grid container component takes in a spacing prop
 * which will apply it to the grid items inside of it
 * using React context.
 */
const GridContainer: React.FC<
  React.ComponentProps<typeof S.GridContainerInternal> & S.GridContainerProps
> = (props) => (
  <S.GridContainerInternal {...props}>
    <SpacingContext.Provider value={props.spacing}>
      {props.children}
    </SpacingContext.Provider>
  </S.GridContainerInternal>
);

/**
 * Grid item component takes in a width prop which
 * determines what percentage of the grid container
 * it should take up.
 */
const GridItem = (
  props: {
    width: Array<number | 'auto'>;
    children: any;
  } & ComponentPropsWithoutRef<'div'>,
) => {
  const spacing = useContext(SpacingContext);
  return <S.GridItemInternal {...props} spacing={spacing} />;
};

Grid.Container = GridContainer;
Grid.Item = GridItem;

export default Grid;
