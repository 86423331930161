import { useNavigationQuestion } from 'common/providers/NavigationQuestionProvider';
import { MY_APP_ROOT } from 'common/utils/constants';
import { KitRegistrationResponse } from 'common/utils/types';
import { sortQuestionOptions } from 'components/HealthProfile/helpers';
import { isEmpty, isNull, isUndefined } from 'lodash';
import React from 'react';

import * as S from '../../styles';
import HealthProfileProgressBar from '../ProgressBar';
import QuestionHandler from '../QuestionHandler';
import QuestionnaireCompleteConfirmation from '../QuestionnaireCompleteConfirmation';

export interface QuestionsProps {
  index: number;
  progressPath?: boolean;
  questionsLength: string;
  questionnaireId: number;
}

export interface CompleteProps {
  kitRegistration: KitRegistrationResponse | undefined;
  redirect: string | null;
}

export const Questions = ({
  index,
  progressPath,
  questionnaireId,
  questionsLength,
}: QuestionsProps) => {
  const { currentQuestionId, keyValueQuestionsSet, totalQuestions } =
    useNavigationQuestion();

  if (
    isEmpty(keyValueQuestionsSet.current) ||
    isUndefined(currentQuestionId) ||
    isNull(currentQuestionId)
  ) {
    return null;
  }
  const currentQuestion = keyValueQuestionsSet.current.all[currentQuestionId];

  const idx = index + 1 >= totalQuestions ? totalQuestions : index + 1;

  sortQuestionOptions(currentQuestion.question_options);

  return (
    <>
      {progressPath ? null : (
        <HealthProfileProgressBar activeStep={1} currentStep={1} />
      )}
      <S.QuestionWrapper>
        <S.Pagination>
          {idx} of {questionsLength}
        </S.Pagination>

        <QuestionHandler
          questionData={currentQuestion}
          questionnaireId={questionnaireId}
        />
      </S.QuestionWrapper>
    </>
  );
};
export const Complete = ({ kitRegistration, redirect }: CompleteProps) => (
  <>
    <HealthProfileProgressBar activeStep={2} currentStep={2} />
    <S.IntroWrapper>
      <QuestionnaireCompleteConfirmation
        kitRegistration={kitRegistration}
        redirectUrl={redirect || MY_APP_ROOT}
      />
    </S.IntroWrapper>
  </>
);
