import { Marker, MarkerResult } from 'common/utils/types';
import React from 'react';

import { BoundaryBadge } from './BoundaryBadge';
import { MarkerBadge } from './MarkerBadge';
import { MarkerBadgeLabel } from './MarkerBadgeLabel';
import { RangeLabels } from './RangeLabels';
import * as S from './styles';
import { getMarkerPosition } from './utils';

export type Props = {
  excludedMarkerValues?: string[];
  isClassStyle?: boolean;
  marker?: Marker;
  markerResult: MarkerResult;
  rangeColors?: any;
};

const NewMarkerChart = (props: Props) => {
  const {
    excludedMarkerValues = [],
    isClassStyle = false,
    marker,
    markerResult,
    rangeColors,
  } = props;

  const { descriptors = [], max_value } = marker || {};
  const { boundaries = [], severity_index: severityIndex } = markerResult;

  let { value: markerValue } = markerResult || {};

  const markerResultPosition = getMarkerPosition(
    markerResult,
    marker,
    isClassStyle,
  );

  // excludedMarkerValues array contains unusual marker values such as '<dl', 'N/A'
  // The marker value badge should not be rendered if the result is optional values like TNP
  // this will check if current marker value is one of these values so we can conditionally render the marker value badge
  const showMarkerValueBadge =
    !excludedMarkerValues.includes(markerValue.toString().toLowerCase()) &&
    (markerResult.severity_index === 0 ||
      markerResult.severity_index < boundaries.length + 1);

  if (typeof markerValue === 'string') {
    markerValue = Number(markerValue.replace(/[^\d.-]/g, ''));
  }

  // NEW: marker.descriptors could contains optional values like 'TNP' which are used to configure replaceable result values
  // Therefore, instead of descriptors, we use boundaries to determine the number of regions to render in the marker chart
  // Example of a marker with optional values:
  // marker: { descriptors: ['Low', 'Normal', 'High', 'TNP', 'Test Not Performed'], boundaries: [2, 10] }
  // This yields:
  // totalBoundaries: 2
  // usableDescriptors: ['Low', 'Normal', 'High'] (Notice TNP and Test Not Performed are not included in usableDescriptors, therefore not rendered)
  // totalDescriptors: 3
  const totalBoundaries = boundaries.length;
  const usableDescriptors = descriptors.slice(0, totalBoundaries + 1);
  const totalDescriptors = usableDescriptors.length;

  const boundaryRange = 100 / (totalBoundaries + 1);
  let end = boundaryRange;
  let last = 0;

  return (
    <S.MarkerChartCard data-test="NewMarkerChart">
      <S.MarkerChartWrapper>
        {usableDescriptors.map((descriptor, index) => {
          const markerMaxValue = max_value;
          const upperBoundary = boundaries[index] || markerMaxValue || Infinity;

          // save the last position
          last = end;
          end += boundaryRange;

          // This draws the boundary (the line between regions)
          const badge = usableDescriptors[index + 1] ? (
            <BoundaryBadge
              index={index}
              isClassStyle={isClassStyle}
              key={`BoundaryBadge-${index}`}
              last={last}
              upperBoundary={upperBoundary}
            />
          ) : null;

          return badge;
        })}
        {showMarkerValueBadge && ( // This draws the marker result value
          <MarkerBadge
            isClassStyle={isClassStyle}
            markerResultPosition={markerResultPosition}
            rangeColors={rangeColors}
            severityIndex={severityIndex}
          >
            <MarkerBadgeLabel
              markerResult={markerResult}
              isClassStyle={isClassStyle}
            />
          </MarkerBadge>
        )}
        <S.MarkerChartBase isClassStyle={isClassStyle}>
          <RangeLabels
            markerResultPosition={markerResultPosition}
            boundaryRange={boundaryRange}
            descriptors={usableDescriptors}
            isClassStyle={isClassStyle}
            rangeColors={rangeColors}
            totalDescriptors={totalDescriptors}
          />
        </S.MarkerChartBase>
      </S.MarkerChartWrapper>
    </S.MarkerChartCard>
  );
};

export default NewMarkerChart;
