import { mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 32px;

  ${mediaQueries.forTabletVerticalUp} {
    margin-bottom: 50px;
  }
`;

const Inner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  line-height: 1.5px;

  ${mediaQueries.forTabletHorizontalUp} {
    flex-flow: row wrap;
    justify-content: center;
  }
`;

const ExpandableSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ExpandedContent = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  ${mediaQueries.forTabletHorizontalUp} {
    flex-flow: row wrap;
    justify-content: center;
  }
`;

export { Wrapper, Inner, ExpandableSection, ExpandedContent };
