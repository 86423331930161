import analytics, { TrackProps } from 'common/utils/analytics';
import { useState } from 'react';

export const useInteractionTracker: () => (props: TrackProps) => Promise<void> =
  () => {
    const [hasInteracted, setHasInteracted] = useState(false);
    return async (props: TrackProps) => {
      if (!hasInteracted) {
        await analytics.track(props);
        setHasInteracted(true);
      }
    };
  };
