import {
  getRequest,
  postRequest,
  putRequest,
  Response,
} from 'common/hooks/useApi/request';
import { API_ROOT, LEGACY_APP_ROOT } from 'common/utils/constants';
import { CarePlanDataResponse, ProgramType } from 'common/utils/types';
import { isEmpty, isNil, omitBy } from 'lodash';
import { STATUSES } from 'pages/AccountHub/pages/TelehealthDashboard/components/TelehealthAppointments/utils';
import { useQuery, UseQueryOptions, MutationFunction } from 'react-query';

interface AppointmentUser {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  state_abbrev: string;
  timezone?: string;
}

type Provider = {
  id: string;
  first_name: string;
  last_name: string;
  credentials: string[];
};

// "occurred" | "no-show" | "re-scheduled" | "cancelled"
export type AppointmentStatusType = `${STATUSES}`;

export interface TelehealthAppointment {
  id: string;
  booking_type: 'scheduled' | 'on_demand' | 'async';
  program: {
    slug: string;
  };
  start_time: string;
  end_time: string;
  join_url?: string;
  status?: AppointmentStatusType | null;
  user: AppointmentUser;
  care_plan?: {
    id: string;
    completed_at: string;
  } | null;
  appointment_type?: {
    id: string;
    name: string;
    contact_type: string | null;
  };
  provider: Provider;
  on_demand_link: string | null;
  calendar_links: {
    google: string;
    ical: string;
    outlook: string;
    yahoo: string;
  };
}

export interface TelehealthAppointmentResponse {
  appointments: TelehealthAppointment[];
}

// From the API Spec user task mode
export enum UserTaskModes {
  AUTOMATIC = 'automatic',
  USER_DRIVEN = 'user_driven',
}

// From the API Spec user task type
export enum UserTaskTypes {
  USER_TASK = 'UserTask',
  LAB_WORK = 'LabWork',
  SCHEDULE_FIRST_APPOINTMENT = 'ScheduleFirstAppointment',
  SCHEDULE_SECOND_APPOINTMENT = 'ScheduleSecondAppointment',
  COMPLETE_FIRST_APPOINTMENT = 'CompleteFirstAppointment',
  COMPLETE_SECOND_APPOINTMENT = 'CompleteSecondAppointment',
  COMPLETE_THIRD_APPOINTMENT = 'CompleteThirdAppointment',
}

export interface TelehealthUserTask {
  id: number;
  image_uri: string;
  mode: UserTaskModes;
  title: string;
  type: UserTaskTypes;
  cta: {
    label: string;
    link: string;
  } | null;
  expires_at: string | null;
}

export interface TelehealthUserStep {
  id: number;
  is_current: boolean;
  primary_description: string;
  tasks: TelehealthUserTask[];
  title: string;
  estimated_time_to_completion: string | null;
  secondary_description?: string | null;
}

export interface TelehealthUserJourney {
  id: number;
  slug: string;
  steps: TelehealthUserStep[];
  title: string;
}

export interface TelehealthUserJourneys {
  journeys: TelehealthUserJourney[];
}

export interface TelehealthUserTaskActionResponse {
  meta: TelehealthUserJourneys;
  task: TelehealthUserTask;
}

export type GetTelehealthAppointmentsParams = {
  period?: 'all' | 'future' | 'past';
  sort_by?: 'date_asc' | 'date_desc';
  limit?: number;
  program_slugs?: string[];
};

export type AppointmentPeriodType = GetTelehealthAppointmentsParams['period'];

export const getTelehealthAppointments = ({
  period = 'all',
  sort_by,
  limit,
  program_slugs,
}: GetTelehealthAppointmentsParams = {}) => {
  const params = new URLSearchParams(
    omitBy({ period, sort_by, limit, program_slugs }, isNil) as Record<
      string,
      string
    >,
  );

  return getRequest<TelehealthAppointmentResponse>(
    `${API_ROOT}/v3/telehealth/appointments?${params}`,
    false,
    { rejectOnError: true },
  );
};

type QueryOptions<T> = Omit<
  UseQueryOptions<T, Error, T, any[]>,
  'queryKey' | 'queryFn'
>;

export const useTelehealthAppointments = (
  params: GetTelehealthAppointmentsParams,
  options?: QueryOptions<Response<TelehealthAppointmentResponse>>,
) =>
  useQuery(
    ['telehealth-appointments', params],
    () => getTelehealthAppointments(params),
    options,
  );

export type GetTelehealthUserJourneysParams = {
  id: number;
};

/**
 * Get the list of user journeys based on the id
 * @param {Object} id of the user membership
 * @returns
 */
export const getTelehealthUserJourneys = ({
  id,
}: GetTelehealthUserJourneysParams) =>
  getRequest<TelehealthUserJourneys>(
    `${API_ROOT}/v3/memberships/${id}/journeys`,
  );

export const useTelehealthUserJourneys = (
  params: GetTelehealthUserJourneysParams,
  options?: QueryOptions<Response<TelehealthUserJourneys>>,
) =>
  useQuery(
    ['telehealth-user-journeys', params],
    () => getTelehealthUserJourneys(params),
    options,
  );

export type UpdateUserTaskActionParams = {
  taskId: number;
  planId: number;
};

/**
 * Trigger a user_driven task action
 * @param {Object}
 */
export const updateUserTaskAction = ({
  taskId,
  planId,
}: UpdateUserTaskActionParams) =>
  putRequest<TelehealthUserTaskActionResponse>(
    `${API_ROOT}/v3/tasks/${taskId}`,
    {
      user_plan_id: planId,
    },
    false,
    {
      rejectOnError: true,
    },
  );

/**
 * Get a care plan by formAnswerGroupId
 *
 * @param formAnswerGroupId
 * @returns
 */
export const getCarePlanByFormAnswerGroupId = async (
  formAnswerGroupId: string,
) => {
  const response = await getRequest<CarePlanDataResponse>(
    `${LEGACY_APP_ROOT}/aapi/v3/telehealth/care_plans/${formAnswerGroupId}`,
    false,
    { rejectOnError: true },
  );
  return response;
};

export type TelehealthPharmacy = {
  name: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zip: string;
  phone_number: string;
};

export type TelehealthMedication = {
  product_name: string;
  status: string;
  quantity: string;
  refills: string;
  directions: string;
  date_written: string;
  pharmacy: TelehealthPharmacy;
};

export const getUserMedications = async () => {
  const response = await getRequest<TelehealthMedication[]>(
    `${LEGACY_APP_ROOT}/aapi/v2/telehealth/medications`,
    false,
    { rejectOnError: true },
  );
  return response;
};

export type TelehealthCustomModuleConditionFilterType =
  | 'contains'
  | 'does_not_contain'
  | 'includes'
  | 'does_not_include'
  | 'is_equal_to'
  | 'is_not_equal_to'
  | 'is_greater_than_or_equal_to'
  | 'is_greater_than'
  | 'is_less_than_or_equal_to'
  | 'is_less_than';

export type TelehealthCustomModuleCondition = {
  id: string;
  conditional_custom_module_id: string;
  filter_type: TelehealthCustomModuleConditionFilterType;
  value_to_filter: string;
};

export type TelehealthCustomModule = {
  id: string;
  label: string | null;
  sublabel: string | null;
  mod_type:
    | 'agree_to_above'
    | 'allergies'
    | 'blood_pressure'
    | 'BMI(in.)'
    | 'checkbox'
    | 'date'
    | 'dob'
    | 'document'
    | 'dropdown'
    | 'ethnicity'
    | 'gender_identity'
    | 'Height (in.)'
    | 'horizontal_radio'
    | 'label'
    | 'location'
    | 'medications'
    | 'name'
    | 'phone'
    | 'pulse'
    | 'race'
    | 'radio'
    | 'read_only'
    | 'sex_at_birth'
    | 'snomed_radio'
    | 'text'
    | 'textarea'
    | 'Weight'
    | 'waist_circumference';
  options_array: string[];
  required: boolean;
  custom_module_condition: null | TelehealthCustomModuleCondition;
  validate?: {};
  readOnly?: boolean;
};

export type TelehealthFormData = {
  id: string;
  name: string;
  custom_modules: Array<TelehealthCustomModule>;
};

export const getTelehealthForm = async (
  formId: string,
  params: {
    program: string;
  },
) =>
  getRequest<TelehealthFormData>(
    `${API_ROOT}/v2/telehealth/forms/${formId}?${new URLSearchParams(
      params,
    ).toString()}`,
    false,
    { rejectOnError: true },
  );

export type AppointmentType = {
  id: number;
  name: string;
  contact_type?: string;
};

export type TelehealthProgramAppointmentTypeResponse = {
  appointment_types: Array<AppointmentType>;
};

export const getTelehealthProgramAppointmentTypes = async (
  program_slug: string,
) => {
  const url = new URL(
    `${API_ROOT}/v3/telehealth/programs/${program_slug}/appointment_types`,
  );

  return getRequest<TelehealthProgramAppointmentTypeResponse>(
    url.toString(),
    false,
    {
      rejectOnError: true,
    },
  );
};

export type TelehealthIntakeForm = {
  id: string;
  external_form_id: string;
  form_type: 'custom' | 'payment' | 'insurance';
  position: number;
  program_slug: string;
  slug: string;
};

export const getTelehealthIntakeForms = async (
  program: string,
  exclude_form_types?: TelehealthIntakeForm['form_type'][],
) => {
  const url = new URL(
    `${API_ROOT}/v3/telehealth/programs/${program}/intake_forms`,
  );

  exclude_form_types?.forEach((form_type) => {
    url.searchParams.append('exclude_types[]', form_type);
  });

  return getRequest<TelehealthIntakeForm[]>(url.toString(), false, {
    rejectOnError: true,
  });
};

export type TelehealthFormAnswer = {
  custom_module_id: string;
  answer: string | FileList;
  type: string;
};

export type SaveTelehealthFormData = {
  form_id: string;
  program: string;
  answers: Array<TelehealthFormAnswer>;
};
export const saveTelehealthForm = async (data: SaveTelehealthFormData) => {
  const { form_id, program, ...postData } = data;
  const search = new URLSearchParams({ program }).toString();

  return await postRequest(
    `${API_ROOT}/v2/telehealth/forms/${form_id}/answer_groups?${search}`,
    postData,
    false,
    { rejectOnError: true },
  );
};

export type SaveTelehealthDocumentFormData = {
  files: File[];
  program: ProgramType | (string & {});
};

export const saveTelehealthDocuments = async (
  data: SaveTelehealthDocumentFormData,
) => {
  const { files, program } = data;
  const formData = new FormData();

  for (const file of files) {
    if (!file) return;

    formData.append(`files[]`, file, file.name);
  }

  formData.append(`program`, program);

  return await postRequest(
    `${API_ROOT}/v3/telehealth/documents`,
    formData,
    false,
    {
      rejectOnError: true,
      body: formData, // overrides internal stringify
      headers: {},
    },
  );
};

export type TelehealthSessionData = {
  state_abbrev: string | null;
  time_zone: string | null;
  results_sharing_consented_at: Date | null;
  program_integration: string;
};

export const getTelehealthSession = async (params: { program: string }) => {
  const { program } = params;
  const searchParams = new URLSearchParams({ program }).toString();

  return await getRequest<TelehealthSessionData>(
    `${API_ROOT}/v2/telehealth/sessions/new?${searchParams}`,
    false,
    { rejectOnError: true },
  );
};

type TelehealthSessionsData = {
  state_abbrev: string;
  time_zone: string;
  program?: string;
  results_sharing_consent: boolean;
  dob: string;
};

export const saveTelehealthUserSession = async (
  data: TelehealthSessionsData,
) => {
  const { program, ...user } = data;
  const postData = {
    user,
    ...(program && { program }),
  };
  return await postRequest(
    `${API_ROOT}/v2/telehealth/sessions`,
    postData,
    false,
    {
      rejectOnError: true,
    },
  );
};

export type TelehealthAnswerGroup = {
  id: string;
  finished: boolean;
  locked_at: string | null;
};

export const getTelehealthAnswerGroups = async (
  params: { form_id: string },
  searchParams: {
    program: string;
  },
) =>
  await getRequest<TelehealthAnswerGroup[]>(
    `${API_ROOT}/v2/telehealth/forms/${
      params.form_id
    }/answer_groups?${new URLSearchParams(searchParams).toString()}`,
    false,
    { rejectOnError: true },
  );

export type TelehealthInsurancePlan = {
  id: string;
  is_accepted: Boolean;
  name_and_id: string;
  payer_id: string;
  payer_name: string;
};

type GetInsurancePlansParams = {
  keywords?: string;
  program: ProgramType | (string & {});
};

export const getInsurancePlans = async (params: GetInsurancePlansParams) => {
  const cleanParams = new URLSearchParams(
    omitBy(params, isEmpty) as Record<string, string>,
  );

  const response = await getRequest<TelehealthInsurancePlan[]>(
    `${LEGACY_APP_ROOT}/aapi/v2/telehealth/insurance_plans?${cleanParams}`,
    false,
    { rejectOnError: true },
  );
  return response;
};

export type InsurancePolicy = {
  insurance_plan_id: string;
  priority_type: string;
  holder_relationship: string;
  num: string;
  group_num: string;
  holder_first: string;
  holder_mi: string;
  holder_last: string;
  holder_gender: string;
  holder_dob: string;
  policy_phone_number: string;
  holder_location: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
  insurance_card_front: File | undefined;
  insurance_card_back: File | undefined;
};

export type SaveInsuranceFormRequestBody = {
  policies: Array<InsurancePolicy>;
  program: ProgramType | (string & {});
};

export type SaveInsuranceFormResponse = Array<{
  id: string;
}>;

export const saveInsuranceForm = async (data: SaveInsuranceFormRequestBody) => {
  const { policies, program } = data;

  const formData = new FormData();

  formData.append('program', program);

  policies.forEach((policy) => {
    Object.entries(policy).forEach(([key, value], index) => {
      if (!value) return;

      if ((value as unknown) instanceof File) {
        const file = value as File;
        formData.append(`policies[][${key}]`, file, file.name);
        return;
      } else if (typeof value === 'object') {
        const obj = value as Record<string, string>;
        Object.entries(obj).forEach(([k, v]) => {
          formData.append(`policies[][${key}][${k}]`, v);
        });
      } else {
        formData.append(`policies[][${key}]`, value);
      }
    });
  });

  return postRequest<SaveInsuranceFormResponse>(
    `${LEGACY_APP_ROOT}/aapi/v2/telehealth/insurance_plans`,
    formData,
    false,
    {
      rejectOnError: true,
      body: formData, // overrides internal stringify
      headers: {},
    },
  );
};

export type CardLabelType = 'personal' | 'hsa' | 'fsa';
export type CardBrandType =
  | 'Amex'
  | 'Discover'
  | 'MasterCard'
  | 'Paypal'
  | 'Visa';
export type PaymentDataProps = {
  token: string;
  card_label_type: CardLabelType;
  wants_marketing: boolean;
};

export const setPayment: MutationFunction<unknown, PaymentDataProps> = async (
  data: PaymentDataProps,
) => {
  const response = await postRequest<PaymentDataProps>(
    `${LEGACY_APP_ROOT}/aapi/v2/telehealth/payments`,
    data,
    false,
    { rejectOnError: true },
  );
  return response;
};

export type CancelAppointmentPayload = {
  appointmentId: string;
  reason: string;
  feedback: string;
};

/**
 * Cancels a telehealth appointment
 * @param payload
 * @returns
 * TODO: Move to telehealthSchedulingApis.ts to create a subgroup of the telehealth apis
 */
export const cancelAppointment = (payload: CancelAppointmentPayload) =>
  postRequest<{}>(
    `${LEGACY_APP_ROOT}/aapi/v3/telehealth/appointment_cancellations`,
    {
      appointment_id: payload.appointmentId,
      reason: payload.reason,
      feedback: payload.feedback,
    },
    false,
    { rejectOnError: true },
  );

export type PaymentMethod = {
  id: number;

  brand: CardBrandType;
  default: boolean;
  expired: boolean;
  expiring_next_month: boolean;
  expiry_month: string;
  expiry_year: string;
  last_four: string;
  type: CardLabelType;
  zip_code?: string | null;
};

export type PaymentMethodResponse = {
  payment_methods: PaymentMethod[];
};

export const getPaymentMethods = async (only_default = true) => {
  const defaultPaymentMethodParam = only_default ? '?only_default=true' : '';
  const response = await getRequest<PaymentMethodResponse>(
    `${API_ROOT}/v3/telehealth/payment_methods${defaultPaymentMethodParam}`,
    false,
    { rejectOnError: true },
  );
  return response;
};

export type InsurancePlanPayerDetails = {
  id: string;
  name: string;
};

export type InsurancePlanDetails = {
  id: string;
  payer: InsurancePlanPayerDetails;
};

export type InsuranceDetails = {
  id: string;
  num: string;
  group_num: string;
  priority_type: string;
  insurance_plan: InsurancePlanDetails;
};

export type GetSavedInsurancePlansResponse = {
  insurance_details: InsuranceDetails[];
};

export const getSavedInsurancePlans = () =>
  getRequest<GetSavedInsurancePlansResponse>(
    `${API_ROOT}/v3/telehealth/insurance_details`,
    false,
    { rejectOnError: true },
  );

export const getEnterpriseInsuranceDetails = async () => {
  const response = await getRequest<EnterpriseInsuranceFormData>(
    `${API_ROOT}/v3/telehealth/enterprise/insurance_details`,
    false,
    { rejectOnError: true },
  );
  return response;
};

export type EnterpriseInsuranceFormData = {
  program_slug: string;
  member_id: string;
  policy_holder_first_name: string;
  policy_holder_middle_name: string;
  policy_holder_last_name: string;
  policy_holder_dob: string;
  policy_holder_sex: 'male' | 'female';
  policy_holder_address1: string;
  policy_holder_address2: string;
  policy_holder_city: string;
  policy_holder_state: string;
  policy_holder_zip: string;
  group_number: string;
  relationship_to_insured: 'self' | 'spouse' | 'child';
  self_payment: boolean;
  insurance_card_front: File;
  insurance_card_back: File;
};

export const saveEnterpriseInsuranceForm = async (
  data: EnterpriseInsuranceFormData,
) => {
  let multiPartFormData = new FormData();

  for (let key in data) {
    let formElement = data[key as keyof EnterpriseInsuranceFormData];
    if (formElement instanceof File) {
      multiPartFormData.append(key, formElement, formElement.name);
    } else {
      multiPartFormData.append(key, String(formElement));
    }
  }

  await postRequest(
    `${API_ROOT}/v3/telehealth/insurances`,
    multiPartFormData,
    false,
    {
      rejectOnError: true,
      body: multiPartFormData, // overrides internal stringify
      headers: {},
    },
  );
};

export type PatientRegistrationFormData = {
  address1: string;
  address2?: string;
  city: string;
  consent_to_text?: boolean;
  country: string;
  current_state_location: string;
  dob: string;
  email: string;
  ethnicity?: string;
  first_name: string;
  gender_identity?: string;
  home_phone: string;
  language?: string;
  last_name: string;
  maritalstatus: string;
  middle_name?: string;
  mobile_phone?: string;
  program_slug: string;
  race?: string;
  sex: string;
  sex_assigned_at_birth?: string;
  state: string;
  time_zone: string;
  zip: string;
};
export const savePatientRegistration = async (
  data: PatientRegistrationFormData,
) => {
  const response = await postRequest<{}>(
    `${API_ROOT}/v3/telehealth/patients`,
    data,
    false,
    { rejectOnError: true },
  );
  return response;
};
export type ConsentFormFieldsData = {
  billing_and_assignment: boolean;
  privacy_notice: boolean;
  results_sharing_consent: boolean;
  signature: string;
  telehealth_consent: boolean;
};
export const savePatientConsent = (data: ConsentFormFieldsData) =>
  putRequest(`${API_ROOT}/v3/telehealth/patient_consents`, data, false, {
    rejectOnError: true,
  });

export type Patient = {
  email: string | null;
  first_name: string | null;
  last_name: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  sex: string | null;
  dob: string | null;
  home_phone: string | null;
};
export type MemberData = {
  patient: Patient;
};

export const getMemberData = async () => {
  const response = await getRequest<MemberData>(
    `${API_ROOT}/v3/telehealth/enterprise/patients`,
    false,
    { rejectOnError: true },
  );
  return response;
};

export type TelehealthMedicationsData = {
  name: string;
};

export type TelehealthMedicationV2 = {
  id: number;
  name: string;
};

export type TelehealthMedicationsResponse = {
  medications: TelehealthMedicationV2[];
};

export const getTelehealthMedications = async (
  data: TelehealthMedicationsData,
) => {
  const { name } = data;
  const search = new URLSearchParams({ name }).toString();

  return await getRequest<TelehealthMedicationsResponse>(
    `${API_ROOT}/v3/telehealth/medications?${search}`,
    false,
    { rejectOnError: true },
  );
};

export type AllergiesData = {
  name: string;
};

export type Allergy = TelehealthMedicationV2;

export type AllergiesResponse = {
  allergies: Allergy[];
};

export const getAllergies = async (data: AllergiesData) => {
  const { name } = data;
  const search = new URLSearchParams({ name }).toString();

  return await getRequest<AllergiesResponse>(
    `${API_ROOT}/v3/telehealth/allergies?${search}`,
    false,
    { rejectOnError: true },
  );
};

export type TelehealthCarePlanOrderTypes =
  | 'patient_info_orders'
  | 'lab_orders'
  | 'other_orders'
  | 'prescription_orders'
  | 'vaccine_orders'
  | 'referral_orders'
  | 'consult_orders'
  | 'imaging_orders';

export type TelehealthCarePlanOrderDataResponse = {
  id: number;
  type:
    | 'patient_info'
    | 'lab'
    | 'other'
    | 'prescription'
    | 'vaccine'
    | 'referral'
    | 'consult'
    | 'imaging';
  description: string;
};

export type TelehealthCarePlanOrders = {
  [key in TelehealthCarePlanOrderTypes]:
    | TelehealthCarePlanOrderDataResponse[]
    | [];
};

export type TelehealthCarePlan = {
  assessment: string;
  diagnoses: {
    description: string;
    note: string;
  }[];
  orders: TelehealthCarePlanOrders;
  physician: {
    name: string;
  };
};

export type TelehealthCarePlanResponse = {
  care_plan: TelehealthCarePlan;
};

export const getCarePlan = async (appointmentId: string) => {
  const response = await getRequest<TelehealthCarePlanResponse>(
    `${API_ROOT}/v3/telehealth/appointments/${appointmentId}/care_plan`,
    false,
    { rejectOnError: true },
  );
  return response;
};

export type TelehealthOrder = {
  sent_on: string;
  clinical_provider: {
    name: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
  };
};

export type TelehealthOrderByTypeAndIdResponse = {
  order: TelehealthOrder;
};

export const getOrdersByTypeAndId = async ({
  type,
  id,
}: {
  type: string;
  id: string;
}) => {
  const response = await getRequest<TelehealthOrderByTypeAndIdResponse>(
    `${API_ROOT}/v3/telehealth/orders/${type}/${id}`,
    false,
    { rejectOnError: true },
  );
  return response;
};
