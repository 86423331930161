import {
  PROGRAM_TYPE,
  ProgramDisplayNames,
  ProgramsAtCheckout,
} from 'common/utils/types';

type ProgramInfo = {
  TITLE: string;
  PRICE?: string;
  PRICE_DISCOUNT?: string;
  PERKS_SUBTITLE: string;
  SUBMIT_BUTTON: string;
  PERKS: string[];
  LEGEND_POINTS: string[];
};

const PaymentContent: Record<ProgramsAtCheckout, ProgramInfo> = {
  [PROGRAM_TYPE['weight-management-full']]: {
    TITLE: `${ProgramDisplayNames['weight-management-full']} Payment Information`,
    PERKS_SUBTITLE: "Perks you'll enjoy as a member:",
    SUBMIT_BUTTON: 'Confirm your membership',
    PERKS: [
      'Regular clinician consults*',
      'Access to prescriptions**',
      'Ongoing at-home testing or supplements',
      'Support for issues like high cholesterol and prediabetes',
      'Lifestyle recommendations and tracking in our app',
      'No cancellation fees, ever',
    ],
    LEGEND_POINTS: [
      '*Consults are required on a periodic basis to evaluate whether Rx refills are appropriate.',
      '**Rx cost is dependent on insurance and not included in program cost.',
    ],
  },
  [PROGRAM_TYPE['weight-management']]: {
    TITLE: `${ProgramDisplayNames['weight-management']} Payment Information`,
    PRICE: '$99/mo \n (No commitment required!)*',
    PRICE_DISCOUNT:
      '$0 first month \n (No commitment required!)* \n $99/mo after',
    PERKS_SUBTITLE: "Perks you'll enjoy as a member:",
    SUBMIT_BUTTON: 'Confirm your membership',
    PERKS: [
      'Regular clinician consults**',
      'Access to prescriptions***',
      'Ongoing at-home testing**** or supplements',
      'Support for issues like high cholesterol and prediabetes',
      'Lifestyle recommendations and tracking in our app',
      'No cancellation fees, ever',
    ],
    LEGEND_POINTS: [
      '*Charged monthly; you can cancel at any time.',
      '**Consults are required on a periodic basis to evaluate whether Rx refills are appropriate.',
      '***Rx cost is dependent on insurance and not included in program cost. Initial lab testing is required for GLP-1 prescriptions.',
      '****At-home lab tests are not available to NY residents due to state regulation of laboratory testing.',
    ],
  },
  [PROGRAM_TYPE['everlywell-plus']]: {
    TITLE: `${ProgramDisplayNames['everlywell-plus']} Payment Information`,
    PERKS_SUBTITLE: "Perks you'll enjoy as an Everlywell+ member",
    SUBMIT_BUTTON: 'Confirm your membership',
    PERKS: [
      'Use your credits for at-home lab tests at up to 57% off',
      '20% off cash-pay telehealth and additional tests',
      'Physician-reviewed, digital results within days',
    ],
    LEGEND_POINTS: [
      '*Charged monthly',
      '*NY residents are not eligible for at home lab-testing',
    ],
  },
};

export default PaymentContent;
