import React, { ReactElement } from 'react';

type Props = {
  title: string;
  children: ReactElement | ReactElement[];
};

const TabPane: React.FC<Props> = ({ children }: Props): JSX.Element => (
  <div>{children}</div>
);

export default TabPane;
