import {
  typography,
  Container as LeavesContainer,
  size,
  mediaQueries,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled(LeavesContainer)`
  display: flex;
  flex-direction: column;
  max-width: 550px;
  margin-bottom: ${size.xl4}px;
  padding: 0 ${size.xs1}px 0 ${size.xs1}px;

  ${mediaQueries.forDesktopUp} {
    margin-bottom: ${size.xl7}px;
  }
`;

export const BrandImage = styled.img`
  width: 60%;
  margin: ${size.xl1}px auto 0 auto;

  ${mediaQueries.forDesktopUp} {
    margin-top: ${size.xl4}px;
  }
`;

export const Heading = styled.h1`
  ${typography.h3Text};
  text-align: center;
  margin: ${size.xl1}px 0 ${size.md}px 0;

  ${mediaQueries.forDesktopUp} {
    margin: ${size.xl1}px 0;
  }
`;

export const SubHeading = styled.p`
  ${typography.bodyText};
  text-align: center;
  margin-bottom: ${size.xl1}px;

  ${mediaQueries.forDesktopUp} {
    margin-bottom: ${size.xl2}px;
  }
`;
