import {
  colors,
  Input as LeavesInput,
  Label as LeavesLabel,
} from '@everlywell/leaves';
import { labelTextCSSbyVariant } from 'components/telehealth/FormBuilder/utils/styles';
import { FormBuilderVariant } from 'components/telehealth/FormBuilder/utils/types';
import styled, { css } from 'styled-components';

export const Input = styled(LeavesInput)`
  margin-bottom: 0;
`;

export const Label = styled(LeavesLabel)<{
  hasError: boolean;
  variant: FormBuilderVariant;
}>`
  ${({ hasError }) =>
    hasError &&
    css`
      color: ${colors.red3};
    `}
  width: 100%;
  ${(props) => labelTextCSSbyVariant(props.variant)}
`;
