import { Container as LeavesContainer, size } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled(LeavesContainer)`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  max-width: 800px;
  padding: ${size.xl3}px ${size.lg}px ${size.xl5}px;
`;
