import { WHATS_NEXT_SECTION } from 'common/utils/constants/dataTest';
import { renderWithProviders } from 'common/utils/reactTestingLibraryHelpers';
import React from 'react';

import ProductUpsell from '../index';
import * as testProps from './testProps';

const renderComponent = (props: any = {}) =>
  renderWithProviders(<ProductUpsell {...props} />);

describe('<ProductUpsell />', () => {
  it('should render a list of items', () => {
    const { getByTestId } = renderComponent(testProps);
    expect(getByTestId(WHATS_NEXT_SECTION.PRODUCT_UPSELL_LIST)).toBeDefined();
  });

  it('should not render a list if not items are present', () => {
    const { queryByTestId } = renderComponent({ testProps, items: [] });
    expect(queryByTestId(WHATS_NEXT_SECTION.PRODUCT_UPSELL_LIST)).toBeNull();
  });
});
