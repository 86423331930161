import { Allergy, TelehealthMedicationV2 } from 'common/apis/telehealthApis';

import { OptionType } from '../../../MultiSelectAutoComplete/types';

export const responseAutoCompleteParser = (
  response: TelehealthMedicationV2[] | Allergy[],
) =>
  response.map(
    (option): OptionType => ({
      label: option.name,
      value: option.id.toString(),
    }),
  );
