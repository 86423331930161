import { mediaQueries } from '@everlywell/leaves';

const breakpoints = {
  m: mediaQueries.forTabletHorizontalDown,
  s: mediaQueries.forTabletVerticalDown,
};

const breakpointsVals = {
  m: 1198,
  s: 898,
};

export { breakpoints, breakpointsVals };
