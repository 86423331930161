import {
  size,
  typography,
  colors,
  mediaQueries,
  Row,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  padding-top: ${size.lg}px;
`;

export const Title = styled.div`
  ${typography.overlineText};
  color: ${colors.green4};
  margin-bottom: ${size.md}px;
`;

export const EditCard = styled.div`
  box-shadow: ${size.xs2}px ${size.xs2}px ${size.md}px -${size.xs2}px rgba(0, 0, 0, 0.1);
`;

export const PaymentSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${size.xl1}px ${size.md}px;
  border: 1px solid ${colors.green2};
  border-radius: ${size.xs2}px;

  ${mediaQueries.forDesktopUp} {
    padding: ${size.xl1}px;
  }
`;

export const EditButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const EditIcon = styled.img`
  width: 14px;
  height: 14px;
`;

export const ButtonText = styled.div`
  ${typography.buttonText}
  padding-left: ${size.xs2}px;
`;

export const PaymentInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const CardImage = styled.img`
  width: 56px;
  height: 38.32px;
  border-radius: 2.4px;
`;

export const CreditCardText = styled.div`
  ${typography.bodyTextSmall}
  display: flex;
  padding-left: ${size.xs1}px;
`;

export const CreditCardNumber = styled.div`
  font-weight: ${typography.weight.bold};
  padding-left: ${size.xs2}px;
`;

export const SkeletonContainer = styled(Row)`
  ${mediaQueries.forTabletHorizontalUp} {
    min-width: 532px;
  }
`;

export const SkeletonWrapper = styled.span`
  margin-bottom: ${size.md}px;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
