import { HealthProfileProps } from 'common/utils/types';
import { isEmpty, isUndefined } from 'lodash';

import { Props as FactCardProps } from './FactCard';

type FactCardPersonalizationProps = { [f: string]: string };
const factCardPersonalizations: FactCardPersonalizationProps = {
  'Digestive Disorder': 'digestive-disorder-',
  'Headaches/Migraines': 'migraines-',
  'Thyroid Disorder': 'thyroid-disorder-',
};

function validPersonalizations(
  facts: FactCardProps[],
  personalizations: HealthProfileProps | undefined,
): boolean {
  return (
    !isEmpty(facts) &&
    !isUndefined(personalizations) &&
    !isEmpty(personalizations.fact_cards)
  );
}

export function getEligibleFacts(
  facts: FactCardProps[],
  personalizations: HealthProfileProps | undefined,
): FactCardProps[] {
  let filteredFacts: FactCardProps[] = [];
  if (validPersonalizations(facts, personalizations)) {
    const customerFactCards = personalizations!.fact_cards.map(
      (fc) => factCardPersonalizations[fc],
    );

    filteredFacts = facts.filter((fact) => {
      const factSlug = fact.slug;

      if (customerFactCards.some((cfc) => factSlug.includes(cfc))) {
        return true;
      }
      return false;
    });
  }

  return filteredFacts;
}

export function isPersonalizationNotAvailable(
  facts: FactCardProps[],
  personalizations: HealthProfileProps | undefined,
): boolean {
  if (validPersonalizations(facts, personalizations)) {
    return false;
  }
  return true;
}
