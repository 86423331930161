import { Col, Row, LocalizeDisabled } from '@everlywell/leaves';
import { REGISTRATION_CONFIRMATION as DATA_TEST } from 'common/utils/constants/dataTest';
import {
  parseValueForPayload,
  parseYesNoValueForPayload,
} from 'common/utils/helpers';
import {
  AdditionalDose,
  CovidVaccineData,
  KitRegistrationUser,
} from 'common/utils/types';
import moment from 'moment';
import React from 'react';

import SevereNote from '../../CovidScreenerQuestions/SevereNote';
import * as S from './styles';

const TWO_FIRST_DOSES = 2;

type COVIDQuestionConfirmationMap = {
  [key: string]: { question: string; value: string; displayValue?: any };
};
type IsUserVaccinatedProps = {
  isVaccinated: string;
  questions: COVIDQuestionConfirmationMap;
  user: KitRegistrationUser;
};

const questions: COVIDQuestionConfirmationMap = {
  symptoms: {
    question: 'What best describes your symptoms?',
    value: 'symptoms',
    displayValue: {
      'no-symptoms': 'No Symptoms',
      mild: 'Mild Symptoms',
      severe: 'Severe Symptoms',
    },
  },
  exposure: {
    question: 'Please describe your exposure to Coronavirus',
    value: 'exposure',
    displayValue: {
      'not-exposed': 'Not Exposed',
      'area-community-spread': 'Community Spread',
      'sick-contact': 'Suspected Exposure',
      'known-exposure': 'Known Exposure',
    },
  },
  highRisk: {
    question: 'Do any of the following apply to you? (Y/N)',
    value: 'highRisk',
    displayValue: {
      'high-risk': 'Yes',
      'low-risk': 'No',
    },
  },
  firstCovidTest: {
    question: 'Is this your first COVID-19 test? (PCR, antigen, antibody)',
    value: 'firstCovidTest',
  },
  healthcareSetting: {
    question: 'Are you employed in a healthcare setting?',
    value: 'healthcareSetting',
  },
  congregateSetting: {
    question: 'Do you live or work in a congregate setting?',
    value: 'congregateSetting',
  },
  symptomsDate: {
    question: 'What day did your symptoms first begin?',
    value: 'symptomsDate',
    displayValue: {
      'no-symptoms': 'No Symptoms',
      mild: 'Mild Symptoms',
      severe: 'Severe Symptoms',
    },
  },
  covidVaccine: {
    question: 'Have you ever received a dose of a COVID-19 vaccine?',
    value: 'vaccinated',
  },
  covidVaccineData: {
    question: 'Please add the date, dose and product you received.',
    value: 'covidVaccineData',
  },
  newYorkResident: {
    question: 'Are you currently living in the state of NY?',
    value: 'newYorkResident',
  },
  newYorkEmployment: {
    question: 'Are you currently employed in the state of NY?',
    value: 'newYorkEmployment',
  },
  newYorkStudent: {
    question:
      'Do you currently attend or volunteer at a school, college, or  university in the state of NY?',
    value: 'newYorkStudent',
  },
  employerName: {
    question: 'Employer Name',
    value: 'employerName',
  },
  employerAddress: {
    question: 'Employer Address',
    value: 'employerAddress',
  },
  schoolName: {
    question: 'School Name',
    value: 'schoolName',
  },
  schoolAddress: {
    question: 'School Address',
    value: 'schoolAddress',
  },
};

interface Props {
  user: KitRegistrationUser;
  onScreenerEdit: () => void;
}

const interpolateAddress = (
  street: string,
  sub: string,
  city: string,
  state: string,
  zip: string,
) => {
  if (street === '') return 'Not Provided';
  return `${street} ${sub} ${city}, ${state} ${zip}`;
};

function parceVaccine(
  arr: JSX.Element[],
  { name, date }: AdditionalDose,
): JSX.Element[] {
  const emptyArr: JSX.Element[] = [];
  const elArr: JSX.Element[] = emptyArr.concat(
    ...arr,
    <li>
      {name}, {date}
    </li>,
  );
  return elArr;
}

function parseVaccines(vaccines: CovidVaccineData): JSX.Element | null {
  if (vaccines.doses === 0) {
    return null;
  }
  let elArr: JSX.Element[] = [];
  let doses = 0;

  const numberOfDoses =
    (vaccines.doses ?? 0) >= TWO_FIRST_DOSES
      ? TWO_FIRST_DOSES
      : vaccines.doses ?? 0;
  while (doses < numberOfDoses) {
    doses += 1;
    if (doses === 1) {
      const { name, first_dose_date: date } = vaccines;
      const dose = 1;
      elArr = parceVaccine(elArr, { dose, name: name!, date: date! });
    }
    if (doses === 2) {
      const { second_dose_name: name, second_dose_date: date } = vaccines;
      const dose = 2;
      elArr = parceVaccine(elArr, { dose, name: name!, date: date! });
    }
  }

  vaccines.additional_doses?.forEach(({ date, dose, name }) => {
    elArr = parceVaccine(elArr, { dose, name: name!, date: date! });
  });

  return <S.Ol>{elArr.map((el) => el)}</S.Ol>;
}

const getDisplayValueForScreenerQuestion = (
  user: KitRegistrationUser,
  value: string,
) => {
  switch (value) {
    case 'symptoms':
      return questions.symptoms.displayValue[user.symptoms.value];
    case 'symptomsDate':
      return moment(user.symptomsDate.value).format('MMM Do yyyy');
    case 'exposure':
      return questions.exposure.displayValue[user.exposure.value];
    case 'firstCovidTest':
      return parseYesNoValueForPayload(user.firstCovidTest.value);
    case 'healthcareSetting':
      return parseYesNoValueForPayload(user.healthcareSetting.value);
    case 'congregateSetting':
      return parseYesNoValueForPayload(user.congregateSetting.value);
    case 'highRisk':
      return questions.highRisk.displayValue[user.highRisk.value];
    case 'vaccinated':
      return parseValueForPayload(user.vaccinated.value).split('-').join(' ');
    case 'covidVaccineData':
      return parseVaccines(user.covidVaccineData.value);
    case 'newYorkResident':
      return parseYesNoValueForPayload(user.newYorkResident.value);
    case 'newYorkEmployment':
      return parseYesNoValueForPayload(user.newYorkEmployment.value);
    case 'newYorkStudent':
      return parseYesNoValueForPayload(user.newYorkStudent.value);
    case 'employerAddress':
      return interpolateAddress(
        user.employerStreetAddress.value,
        user.employerSubAddress.value,
        user.employerCity.value,
        user.employerState.value,
        user.employerZipCode.value,
      );
    case 'schoolAddress':
      return interpolateAddress(
        user.schoolStreetAddress.value,
        user.schoolSubAddress.value,
        user.schoolCity.value,
        user.schoolState.value,
        user.schoolZipCode.value,
      );
    case 'employerName':
      return user.employerName.value || 'Not Provided';
    case 'schoolName':
      return user.newYorkSchoolName.value || 'Not Provided';
    default:
      return 'Not Provided';
  }
};

const CovidScreenerConfirmation = ({ user, onScreenerEdit }: Props) => (
  <>
    {user.symptoms.value === 'severe' && (
      <Col xs={12}>
        <SevereNote addMargin={false} />
      </Col>
    )}
    <S.HeaderColumn severe={user.symptoms.value === 'severe'} xs={12}>
      <Row between="xs">
        <Col xs={10} md>
          <S.Header>COVID Screener</S.Header>
        </Col>
        <Col xs md>
          <S.EditButton
            data-test={`${DATA_TEST.EDIT}-personal`}
            onClick={() => onScreenerEdit()}
          >
            {'Edit'}
          </S.EditButton>
        </Col>
      </Row>
    </S.HeaderColumn>
    <Col xs={12}>
      <S.ScreenerQuestion>{questions.symptoms.question}</S.ScreenerQuestion>
      <S.ScreenerAnswer className="inspectletIgnore">
        {getDisplayValueForScreenerQuestion(user, questions.symptoms.value)}
      </S.ScreenerAnswer>
    </Col>
    {user.symptoms.value !== 'no-symptoms' && (
      <Col xs={12}>
        <S.ScreenerQuestion>
          {questions.symptomsDate.question}
        </S.ScreenerQuestion>
        <S.ScreenerAnswer className="inspectletIgnore">
          <LocalizeDisabled>
            {getDisplayValueForScreenerQuestion(
              user,
              questions.symptomsDate.value,
            )}
          </LocalizeDisabled>
        </S.ScreenerAnswer>
      </Col>
    )}
    <Col xs={12}>
      <S.ScreenerQuestion>{questions.exposure.question}</S.ScreenerQuestion>
      <S.ScreenerAnswer className="inspectletIgnore">
        {getDisplayValueForScreenerQuestion(user, questions.exposure.value)}
      </S.ScreenerAnswer>
    </Col>
    <Col xs={12}>
      <S.ScreenerQuestion>
        {questions.firstCovidTest.question}
      </S.ScreenerQuestion>
      <S.ScreenerAnswer className="inspectletIgnore">
        {getDisplayValueForScreenerQuestion(
          user,
          questions.firstCovidTest.value,
        )}
      </S.ScreenerAnswer>
    </Col>
    <Col xs={12}>
      <S.ScreenerQuestion>
        {questions.healthcareSetting.question}
      </S.ScreenerQuestion>
      <S.ScreenerAnswer className="inspectletIgnore">
        {getDisplayValueForScreenerQuestion(
          user,
          questions.healthcareSetting.value,
        )}
      </S.ScreenerAnswer>
    </Col>
    <Col xs={12}>
      <S.ScreenerQuestion>
        {questions.congregateSetting.question}
      </S.ScreenerQuestion>
      <S.ScreenerAnswer className="inspectletIgnore">
        {getDisplayValueForScreenerQuestion(
          user,
          questions.congregateSetting.value,
        )}
      </S.ScreenerAnswer>
    </Col>
    <Col xs={12}>
      <S.ScreenerQuestion>{questions.highRisk.question}</S.ScreenerQuestion>
      <S.ScreenerAnswer className="inspectletIgnore">
        {getDisplayValueForScreenerQuestion(user, questions.highRisk.value)}
      </S.ScreenerAnswer>
    </Col>
    {user.addressState.value === 'New York' && (
      <>
        <Col xs={12}>
          <S.ScreenerQuestion>
            {questions.newYorkResident.question}
          </S.ScreenerQuestion>
          <S.ScreenerAnswer className="inspectletIgnore">
            {getDisplayValueForScreenerQuestion(
              user,
              questions.newYorkResident.value,
            )}
          </S.ScreenerAnswer>
        </Col>
        {parseYesNoValueForPayload(user.newYorkResident.value) === 'yes' && (
          <>
            <Col xs={12}>
              <S.ScreenerQuestion>
                {questions.newYorkEmployment.question}
              </S.ScreenerQuestion>
              <S.ScreenerAnswer className="inspectletIgnore">
                {getDisplayValueForScreenerQuestion(
                  user,
                  questions.newYorkEmployment.value,
                )}
              </S.ScreenerAnswer>
            </Col>
            <Col xs={12}>
              <S.ScreenerQuestion>
                {questions.newYorkStudent.question}
              </S.ScreenerQuestion>
              <S.ScreenerAnswer className="inspectletIgnore">
                {getDisplayValueForScreenerQuestion(
                  user,
                  questions.newYorkStudent.value,
                )}
              </S.ScreenerAnswer>
            </Col>
            {parseYesNoValueForPayload(user.newYorkEmployment.value) ===
              'yes' && (
              <>
                <Col xs={12}>
                  <S.ScreenerQuestion>
                    {questions.employerName.question}
                  </S.ScreenerQuestion>
                  <S.ScreenerAnswer className="inspectletIgnore">
                    <LocalizeDisabled>
                      {getDisplayValueForScreenerQuestion(
                        user,
                        questions.employerName.value,
                      )}
                    </LocalizeDisabled>
                  </S.ScreenerAnswer>
                </Col>
                <Col xs={12}>
                  <S.ScreenerQuestion>
                    {questions.employerAddress.question}
                  </S.ScreenerQuestion>
                  <S.ScreenerAnswer className="inspectletIgnore">
                    <LocalizeDisabled>
                      {getDisplayValueForScreenerQuestion(
                        user,
                        questions.employerAddress.value,
                      )}
                    </LocalizeDisabled>
                  </S.ScreenerAnswer>
                </Col>
              </>
            )}
            {parseYesNoValueForPayload(user.newYorkStudent.value) === 'yes' && (
              <>
                <Col xs={12}>
                  <S.ScreenerQuestion>
                    {questions.schoolName.question}
                  </S.ScreenerQuestion>
                  <S.ScreenerAnswer className="inspectletIgnore">
                    <LocalizeDisabled>
                      {getDisplayValueForScreenerQuestion(
                        user,
                        questions.schoolName.value,
                      )}
                    </LocalizeDisabled>
                  </S.ScreenerAnswer>
                </Col>
                <Col xs={12}>
                  <S.ScreenerQuestion>
                    {questions.schoolAddress.question}
                  </S.ScreenerQuestion>
                  <S.ScreenerAnswer className="inspectletIgnore">
                    <LocalizeDisabled>
                      {getDisplayValueForScreenerQuestion(
                        user,
                        questions.schoolAddress.value,
                      )}
                    </LocalizeDisabled>
                  </S.ScreenerAnswer>
                </Col>
              </>
            )}
          </>
        )}
      </>
    )}
    <Col xs={12}>
      <S.ScreenerQuestion>{questions.covidVaccine.question}</S.ScreenerQuestion>
      <S.ScreenerAnswer className="inspectletIgnore">
        {getDisplayValueForScreenerQuestion(user, questions.covidVaccine.value)}
      </S.ScreenerAnswer>
    </Col>

    <IsUserVaccinated
      user={user}
      questions={questions}
      isVaccinated={user.vaccinated.value}
    />
  </>
);

function IsUserVaccinated({
  isVaccinated,
  questions,
  user,
}: IsUserVaccinatedProps) {
  if (parseYesNoValueForPayload(isVaccinated) !== 'yes') {
    return null;
  }
  const VaccinesList = getDisplayValueForScreenerQuestion(
    user,
    questions.covidVaccineData.value,
  );
  return (
    <>
      <Col xs={12}>
        <S.ScreenerQuestion>
          {questions.covidVaccineData.question}
        </S.ScreenerQuestion>
        <S.ScreenerAnswer className="inspectletIgnore">
          {VaccinesList}
        </S.ScreenerAnswer>
      </Col>
    </>
  );
}

export default CovidScreenerConfirmation;
