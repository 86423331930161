import { loadStripe } from '@stripe/stripe-js';
import { StripeFormProvider } from 'components/StripeComponent/Providers/StripeFormProvider';
import React from 'react';

import PaymentInfo from './components/PaymentInfo';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY || '');

type PaymentProps = {
  program: string;
};

const Payment: ({ program }: PaymentProps) => JSX.Element = ({ program }) => (
  <StripeFormProvider stripe={stripePromise}>
    <PaymentInfo program={program} />
  </StripeFormProvider>
);

export default Payment;
