import { size, colors, Accordion, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${size.md}px;
  background-color: ${colors.white};
  border-radius: ${size.xs1}px;
  box-shadow: 0 10px 30px -15px #b6b6b6;

  ${mediaQueries.forTabletVerticalUp} {
    padding: ${size.xl1}px;
  }
`;

export const AccordionItem = styled(Accordion.Item)``;
