import { getRequest, postRequest } from 'common/hooks/useApi/request';
import { API_ROOT } from 'common/utils/constants';

export type Address = {
  id: number;
  first_name: string;
  last_name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  phone: string | null;
};

export type UpdateAddress = {
  first_name: string;
  last_name: string;
  address1: string;
  address2: string | null;
  city: string;
  state: string;
  zipcode: string;
};

export const getShippingAddress = async () =>
  getRequest<Address>(`${API_ROOT}/v1/settings/shipping_address`, false, {
    rejectOnError: true,
  });

export const updateShippingAddress = async (data: UpdateAddress) =>
  await postRequest<Address>(
    `${API_ROOT}/v1/settings/shipping_address`,
    { shipping_address: data },
    false,
    {
      rejectOnError: true,
    },
  );
