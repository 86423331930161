import { states } from '@everlywell/leaves';
import {
  MemberData,
  PatientRegistrationFormData,
  TelehealthCustomModule,
  TelehealthFormAnswer,
} from 'common/apis/telehealthApis';
import { getFormattedDate } from 'components/telehealth/FormBuilder/components/LocationField/utils';

import { ADDRESS_SECTION_ID } from '../config';

export const parseFormData = (
  formData: TelehealthFormAnswer[],
): PatientRegistrationFormData => {
  const values = formData
    .filter((field) => field?.type !== 'read_only' && field?.type !== 'label')
    .reduce((acc, field) => {
      const residenseData = residenceParse(field);
      if (residenseData) {
        return { ...acc, ...residenseData };
      }
      const raceData = parseRaceData(field);
      if (raceData) {
        return { ...acc, ...raceData };
      }
      const currentStateLocationData = currentStateLocationParse(field);
      if (currentStateLocationData) {
        return { ...acc, ...currentStateLocationData };
      }
      const phoneData = parsePhoneData(field);
      if (phoneData) {
        return { ...acc, ...phoneData };
      }

      return { ...acc, ...{ [field.custom_module_id]: field.answer } };
    }, {});

  (values as PatientRegistrationFormData).time_zone =
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  const nonEmptyValues = getNonEmptyValues(values);
  return nonEmptyValues as PatientRegistrationFormData;
};

function getNonEmptyValues(values: object) {
  return Object.entries(values).reduce((acc, attr) => {
    if (attr[1] !== '') {
      return { ...acc, ...{ [attr[0]]: attr[1] } };
    }
    return { ...acc };
  }, {});
}

export function currentStateLocationParse(
  field: TelehealthFormAnswer,
): Partial<PatientRegistrationFormData> | undefined {
  if (field.custom_module_id === 'current_state_location') {
    const stateAbbr = states.stateNameToAbbreviation[field.answer as string];
    return {
      current_state_location: stateAbbr,
    };
  }
}

export function parseRaceData(field: TelehealthFormAnswer) {
  if (field.custom_module_id === 'race') {
    return {
      race: (field.answer as string).toLowerCase().replace(/ /g, '_'),
    };
  }
}
export function parsePhoneData(field: TelehealthFormAnswer) {
  const formattedPhoneNumber = (field.answer as string)
    .match(/\d+/g)
    ?.join('-');
  if (field.custom_module_id === 'mobile_phone' && formattedPhoneNumber) {
    return {
      mobile_phone: formattedPhoneNumber,
    };
  }
  if (field.custom_module_id === 'home_phone') {
    return {
      home_phone: formattedPhoneNumber,
    };
  }

  if (field.custom_module_id === 'consent_to_text') {
    return {
      consent_to_text: field.answer === 'Yes',
    };
  }
  if (field.custom_module_id === 'consent_to_call') {
    return {
      consent_to_call: field.answer === 'Yes',
    };
  }
}

export function residenceParse(
  field: TelehealthFormAnswer,
): Partial<PatientRegistrationFormData> | undefined {
  let value = field.answer as string;

  if (field.type === 'location') {
    const [address1, address2, state, city, country, zipcode] =
      value.split(/\r\n/);

    return {
      address1: address1,
      address2: address2 ?? '',
      city: city,
      state: state,
      zip: zipcode,
      country: country || 'US',
    };
  }
}

export const setDefaultValues = (
  custom_modules: TelehealthCustomModule[],
  data?: MemberData | null,
): Record<string, string | null> => {
  const memberPrefetchData = data?.patient;

  const modulesValues = custom_modules.reduce<Record<string, string>>(
    (acc, { id }) => {
      if (id === 'consent_to_text' || id === 'consent_to_call') {
        acc[id] = 'Yes';
      }
      return acc;
    },
    {},
  );
  let addressDefaultValuesObj: Record<string, string> = {};

  if (memberPrefetchData) {
    addressDefaultValuesObj = getFormattedDate({
      addressSectionId: ADDRESS_SECTION_ID,
      defaultValuesObj: memberPrefetchData,
    });

    memberPrefetchData.home_phone = sanitizePhoneNumber(
      memberPrefetchData.home_phone,
    );
  }

  return {
    ...modulesValues,
    ...memberPrefetchData,
    ...addressDefaultValuesObj,
  };
};

export function sanitizePhoneNumber(phoneNumber: string | null) {
  let sanitizedNumber = phoneNumber;
  if (sanitizedNumber) {
    sanitizedNumber = sanitizedNumber.replace(/\D/g, ''); // Remove non-digit characters
    sanitizedNumber = sanitizedNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      '$1-$2-$3',
    ); // Add dashes

    if (sanitizedNumber.includes('-555-')) {
      // ignore "fake" phone numbers
      sanitizedNumber = null;
    }
  }
  return sanitizedNumber;
}
