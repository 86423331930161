import { getRedeemableProducts } from 'common/apis/creditRedemptionApi';
import { Response } from 'common/hooks/useApi/request';
import { logError } from 'common/utils/helpers';
import { RedeemableProductsResponse } from 'common/utils/types';
import { useQuery, UseQueryOptions } from 'react-query';

type QueryOptions<T> = Omit<
  UseQueryOptions<T, Error, T, any[]>,
  'queryKey' | 'queryFn'
>;

export type UseRedeemableProductsProps = QueryOptions<
  Response<RedeemableProductsResponse>
>;

/**
 * Fetch redeemable products for a given membership
 *
 * @param membershipId {number}
 * @param options {UseRedeemableProductsProps}
 * @returns redeemable products
 */

export default function useRedeemableProducts(
  membershipId: number | undefined,
  telehealthOnly = false,
  { enabled, ...rest }: UseRedeemableProductsProps = {},
) {
  const response = useQuery(
    ['redeemable-products'],
    () => getRedeemableProducts(membershipId, telehealthOnly),
    {
      ...rest,
      onError: (error) => {
        logError((error as Error).message, {
          errorInfo: 'Error fetching redeemable products',
          component: 'useRedeemableProducts',
          method: 'getRedeemableProducts',
        });
      },
      enabled: Boolean(membershipId) && enabled !== false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    ...response,
    redeemableProducts: response.data?.data?.redeemable_products,
  };
}
