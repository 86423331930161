import { ImmutableReduxState } from 'common/utils/types';
import SectionHeader, {
  Props as SectionHeaderProps,
} from 'components/SectionHeader';
import { connect } from 'react-redux';
import { makeSelectContent } from 'store/selectors';

const CONTENT_KEYS = {
  header: 'TAKE_ACTION_TITLE',
  iconSrc: 'TAKE_ACTION_SECTION_ICON',
  alt: 'TAKE_ACTION_SECTION_ICON_ALT',
};

type OwnProps = {
  contentToken: string;
};

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): SectionHeaderProps {
  return makeSelectContent(ownProps.contentToken, CONTENT_KEYS)(state);
}

export default connect(mapStateToProps)(SectionHeader);
