import { H3, typography, size, colors, mediaQueries } from '@everlywell/leaves';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

const Link = styled(RouterLink)`
  color: ${colors.teal4};
  cursor: pointer;
  text-decoration: none;
`;

const TitleWrapper = styled.div`
  padding-bottom: ${size.lg}px;
  ${mediaQueries.forTabletVerticalDown} {
    padding-top: ${size.lg}px;
  }
`;

const SubtitleWrapper = styled.div`
  padding-bottom: ${size.xl2}px;
`;

const Title = styled(H3)`
  font-weight: ${typography.weight.bold};
  margin: 0;
  color: ${colors.teal4};
`;

const Subtitle = styled.p`
  ${typography.bodyTextSmall};
  line-height: 1.75;
  font-weight: ${typography.weight.book};
  display: inline;
`;

const SubSubtitle = styled.p`
  margin-top: ${size.sm}px;
`;

const ModalCta = styled.a`
  color: ${colors.white};
  text-decoration: none;
  font-size: ${size.md}px;
  &:hover,
  &:active {
    color: ${colors.white};
    text-decoration: none;
  }
`;

export {
  TitleWrapper,
  Title,
  SubtitleWrapper,
  Subtitle,
  SubSubtitle,
  Link,
  ModalCta,
};
