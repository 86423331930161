import {
  size,
  mediaQueries,
  H4,
  typography,
  colors,
  Button,
  Modal,
} from '@everlywell/leaves';
import styled from 'styled-components';

const InsuranceModal = styled(Modal)`
  top: ${size.xl4}px;

  ${mediaQueries.forPhoneOnly} {
    div:nth-child(2) {
      padding: 0;
    }
  }

  ${mediaQueries.forTabletVerticalUp} {
    div:nth-child(2) {
      align-items: center;
      justify-content: center;
      margin-bottom: ${size.xl8}px;
    }
  }
  ${mediaQueries.forTabletHorizontalUp} {
    div:nth-child(2) {
      margin-bottom: 0;
      overflow: hidden;
    }
  }
  ${mediaQueries.forDesktopUp} {
    top: ${size.xl8}px;
  }
`;

const Heading = styled(H4)`
  text-align: center;
  margin: ${size.sm}px auto ${size.md}px auto;

  ${mediaQueries.forTabletVerticalUp} {
    width: 80%;
  }
`;

const Copy = styled.div`
  ${typography.bodyText}
  text-align: center;
  font-feature-settings: 'ss01' on;

  a {
    text-decoration: none;
    color: ${colors.blue1};
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  margin-top: ${size.xl3}px;

  ${mediaQueries.forTabletVerticalUp} {
    margin-top: 0;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const ActionButton = styled(Button)`
  padding: ${size.sm}px ${size.lg}px;
  width: 100%;
  border-radius: ${size.xs2}px;
  margin-top: ${size.lg}px;
  ${mediaQueries.forTabletVerticalUp} {
    width: 327px;
  }
`;

export {
  InsuranceModal,
  Heading,
  Copy,
  IconContainer,
  ActionButtons,
  ActionButton,
};
