import { logError } from 'common/utils/helpers';

export class BaseErrorResponse implements Error {
  name: string;
  message: string;
  fatal: boolean;
  component: string;

  constructor(
    message: string,
    fatal: boolean,
    name: string,
    component: string = 'Care Intake',
  ) {
    this.message = message;
    this.fatal = fatal;
    this.name = name;
    this.component = component;
    logError(message, { context: { fatal, name, component } });
  }
}

export class ContextInitializationError extends BaseErrorResponse {
  constructor(
    message: string = 'Context failed to initialize',
    fatal: boolean = false,
  ) {
    super(message, fatal, 'ContextInitializationError');
  }
}

export class CreateStoreOrderError extends BaseErrorResponse {
  constructor(message: string, fatal: boolean = false) {
    super(message, fatal, 'CreateStoreOrderError');
  }
}

export class StripeStoreOrderCompleteError extends BaseErrorResponse {
  constructor(message: string, fatal: boolean = false) {
    super(message, fatal, 'StripeStoreOrderCompleteError');
  }
}

export class StoreOrderFailureError extends BaseErrorResponse {
  constructor(message: string, fatal: boolean = false) {
    super(message, fatal, 'StoreOrderFailureError');
  }
}

export class StripePaymentIntentError extends BaseErrorResponse {
  constructor(message: string, fatal: boolean = false) {
    super(message, fatal, 'StripePaymentIntentError');
  }
}

export class FinalizeOrderError extends BaseErrorResponse {
  constructor(message: string, fatal: boolean = false) {
    super(message, fatal, 'FinalizeOrderFailure');
  }
}
