import { ImmutableReduxState } from 'common/utils/types';
import ProductPromo, {
  Props,
} from 'pages/KitResultDetailPage/TakeActionSection/ProductPromo';
import { connect } from 'react-redux';
import {
  makeSelectContent,
  makeSelectKitResultTestName,
} from 'store/selectors';

const CONTENT_KEYS = {
  iconSrc: 'PRODUCT_PROMO_ICON',
  alt: 'PRODUCT_PROMO_ICON_ALT',
  header: 'PRODUCT_PROMO_DESCRIPTION_TITLE',
  description: 'PRODUCT_PROMO_DESCRIPTION',
  texts: 'PROMO_DESCRIPTION_*',
  iconSrcs: 'PROMO_ICON_*',
  alts: 'PROMO_ICON_ALT_*',
  url: 'PRODUCT_PROMO_URL',
  productName: 'PRODUCT_PROMO_NAME',
  buttonText: 'PROMO_BUTTON_TEXT',
};

type StateProps = {
  header: string;
  description: string;
  alt: string;
  iconSrc: string;
  texts: string[];
  iconSrcs: string[];
  alts: string[];
  testName: string;
  url: string;
  productName: string;
  buttonText: string;
};

type OwnProps = {
  contentToken: string;
  kitResultId: string;
};

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): StateProps {
  return {
    ...makeSelectContent(ownProps.contentToken, CONTENT_KEYS)(state),
    testName: makeSelectKitResultTestName(ownProps.kitResultId)(state),
  };
}

export function mergeProps(stateProps: StateProps): Props {
  const {
    texts,
    iconSrcs,
    alts,
    header,
    description,
    iconSrc,
    alt,
    testName,
    url,
    productName,
    buttonText,
  } = stateProps;

  const items = texts?.map((text, index) => ({
    text,
    alt: alts[index],
    iconSrc: iconSrcs[index],
  }));

  return {
    items,
    header,
    description,
    iconSrc,
    alt,
    testName,
    url,
    productName,
    buttonText,
  };
}

export default connect(mapStateToProps, null, mergeProps)(ProductPromo);
