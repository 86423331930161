import { Toast, ToastType } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { NOTIFICATION } from 'common/utils/constants/dataTest';
import { Notification as NotificationType } from 'common/utils/types';
import { CLEAR_NOTIFICATION_DELAY } from 'containers/Notification';
import React from 'react';

import * as S from './styles';

type Props = {
  notification?: NotificationType;
  clearNotification?: () => void;
  type?: ToastType;
  persistent?: boolean;
};

function Notification(props: Props) {
  const {
    notification,
    clearNotification,
    type = ToastType.SUCCESS,
    persistent = false,
  } = props;

  const handleClearNotification = () => {
    if (clearNotification) {
      analytics.track({
        event: ANALYTICS.EVENTS.NOTIFICATION,
        data: {
          label: 'Clear Notification',
          type,
          category: ANALYTICS.CATEGORIES.NOTIFICATION,
          component: notification?.source,
        },
      });
      clearNotification();
    }
  };
  const message = notification?.message ?? '';
  const details = notification?.details;
  return notification ? (
    <S.Wrapper
      data-testid={
        type === ToastType.SUCCESS
          ? NOTIFICATION.INFO_NOTIFICATION
          : NOTIFICATION.ERROR_NOTIFICATION
      }
    >
      <Toast
        type={type}
        content={message}
        subtext={details}
        handleClose={handleClearNotification}
        visibleTime={CLEAR_NOTIFICATION_DELAY - 500}
        persistent={persistent}
      />
    </S.Wrapper>
  ) : null;
}

export default Notification;
