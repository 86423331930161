import { colors, mediaQueries, size, typography } from '@everlywell/leaves';
import PersonalizationCheckIn from 'pages/KitResultDetailPage/PersonalizationCheckIn';
import styled from 'styled-components';

import { BaseCard } from '../BaseCard.styles';

const ToolTipWidth = 150;

export const Card = styled(BaseCard)``;

export const Header = styled.header`
  align-items: center;
  color: ${colors.green5};
  display: flex;
  margin-bottom: ${size.sm};

  .tooltip {
    [id^='tooltip-box'] {
      min-width: ${ToolTipWidth}px;
    }
  }
`;

export const Heading = styled.div`
  ${typography.overlineText};
  text-transform: uppercase;
  color: ${colors.green4};
`;

export const Title = styled.h3`
  ${typography.h5Text};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  color: ${colors.teal4};
  line-height: 40px;

  ${mediaQueries.forTabletVerticalUp} {
    line-height: 30px;
    margin-bottom: ${size.xs1}px;
  }

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const Image = styled.img`
  width: 40px;
  height: 40px;

  ${mediaQueries.forTabletVerticalUp} {
    position: absolute;
    right: 0;
    width: 70px;
    height: 70px;
  }
`;

export const Copy = styled.section`
  ${typography.buttonTextSmall};
  color: ${colors.black};
  font-weight: ${typography.weight.light};
  margin: 0 0 ${size.xs1}px;

  ${mediaQueries.forTabletVerticalUp} {
    max-width: 66.85%;
  }

  a {
    color: ${colors.green4};
    font-weight: ${typography.weight.bold};
    text-decoration: none;
  }

  p {
    margin: 0 0 ${size.md}px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const PersonalizationCheckInCustom = styled(PersonalizationCheckIn)`
  margin: 0;
  padding: ${size.xs1}px 0;
  box-shadow: 0 7px 30px -10px rgba(220, 228, 229);
  border-radius: 0 0 8px 8px;
  background: ${colors.white};
  p {
    margin: 0;
  }
`;
