import { mediaQueries, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const InnerContent = styled.div`
  margin: 0 auto;
`;

export const PageContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${size.md}px;
  overflow: hidden;

  ${mediaQueries.forTabletVerticalUp} {
    gap: ${size.lg}px;
  }
`;

export const Section = styled.div`
  width: 100%;

  .mySwiper {
    width: 100%;
  }

  .mySwiper > .swiper-pagination {
    position: relative;
    margin-top: ${size.md}px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Title = styled.h2`
  ${typography.h2Text};
  margin: 0 0 ${size.md}px 0;
`;

export const SubTitle = styled.h3`
  ${typography.h5Text};
  margin: 0;
`;
