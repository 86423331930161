import { Col } from '@everlywell/leaves';
import { useAnswers } from 'common/providers/AnswersProvider';
import { HEALTH_PROFILE_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';
import { isValidInput, stickyUnit } from 'common/utils/helpers';
import { QuestionProps } from 'common/utils/types';
import React, { useState, useEffect } from 'react';

import { QuestionFooter } from '..';

import { setResponse } from '../../helpers';
import * as S from '../styles';

const HealthProfileWeightQuestion = ({
  className,
  questionData,
  questionnaireId,
}: QuestionProps) => {
  const {
    content,
    description,
    question_options: options,
    questionnaire_question_id: questionnaireQuestionId,
  } = questionData;
  const { allAnswers } = useAnswers();
  const [weight, setWeight] = useState('');
  const [error, setError] = useState('');
  const [displayWeightValue, setDisplayWeightValue] = useState('');

  useEffect(() => {
    // If question has already been answered, retrieve value on mount and insert into UI
    // Move to reducer or custom hook at later date
    if (allAnswers[questionnaireQuestionId]?.value) {
      const initialWeight = allAnswers[questionnaireQuestionId].value;
      setWeight(initialWeight);
      setDisplayWeightValue(initialWeight ? `${initialWeight} lb` : '');
    }
  }, [allAnswers, questionnaireQuestionId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.replace(/[^\d]/g, '');
    stickyUnit(e);
    const num = Number(val);
    const checkError: string = isValidInput(num, 75, 999, 'lbs');
    setError(checkError);
    const value = num === 0 ? '' : `${num} lb`;
    setDisplayWeightValue(value);
    setWeight(val);
  };

  return (
    <div data-test={`${DATA_TEST.CONTAINER}-weight-question`}>
      <S.QuestionContainer className={className}>
        <Col>
          <S.Question>{content}</S.Question>
          {description ? <S.SubText>{description}</S.SubText> : null}
        </Col>
        <Col>
          <S.WeightInput
            label="Weight"
            type="text"
            inputMode="numeric"
            placeholder="150 lb"
            value={displayWeightValue}
            id="Weight"
            name="Weight"
            error={error}
            onChange={(e) => handleChange(e)}
            data-test={`${DATA_TEST.INPUT}-weight-input`}
          />
        </Col>
        <QuestionFooter
          questionData={questionData}
          error={error}
          answer={weight}
          setResponse={() =>
            setResponse(allAnswers, questionnaireQuestionId, {
              options,
              value: weight,
            })
          }
          questionnaireId={questionnaireId}
        />
      </S.QuestionContainer>
    </div>
  );
};

export default HealthProfileWeightQuestion;
