import { size, colors } from '@everlywell/leaves';
import styled from 'styled-components';

const SCROLL_START = 0;

export const OuterContainer = styled.div<{
  'data-scroll-current': number;
  'data-scroll-width': number;
}>`
  position: relative;
  max-width: 100%;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: ${size.xl3}px; /* card width + padding */
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }

  &:before {
    left: 0;
    background-image: linear-gradient(to right, ${colors.white}, transparent);
  }

  &:after {
    right: 0;
    background-image: linear-gradient(to left, ${colors.white}, transparent);
  }

  ${(props) => {
    const scrollCurrent = props['data-scroll-current'];
    const scrollWidth = props['data-scroll-width'];

    if (scrollCurrent <= SCROLL_START) {
      return `
        &:before {
          visibility: hidden;
        }
      `;
    }

    if (scrollWidth === scrollCurrent) {
      return `
        &:after {
          visibility: hidden;
        }
      `;
    }
  }}
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  max-width: 100%;
  gap: ${size.md}px;
  overflow-x: auto;
`;
