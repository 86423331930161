import clamp from 'lodash/clamp';
import React from 'react';
import { useLocation } from 'react-router-dom';

import * as S from './Pagination.styles';
import PaginationItem from './PaginationItem';

export type PaginationProps = React.ComponentProps<typeof S.Container> & {
  /** Total of records to paginate */
  totalItems: number;

  /** Number of items displayed per page */
  itemsPerPage: number;

  /** Number of the page that is being displayed */
  currentPage: number;

  /** pagination items to show. @default 5 */
  size?: number;
};

/**
 * Pagination Component
 */
function Pagination(props: PaginationProps) {
  const {
    totalItems,
    itemsPerPage,
    currentPage: currentPageProp,
    size = 5,
    ...containerProps
  } = props;

  const location = useLocation();

  const getLink = (page: number) => {
    const params = new URLSearchParams(location.search);
    params.set('page', page.toString());

    return `${location.pathname}?${params.toString()}`;
  };

  const totalPages = calcPages(totalItems, itemsPerPage);
  const currentPage = clamp(currentPageProp, 1, totalPages);
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  if (totalPages <= 1) {
    return null;
  }

  return (
    <S.Container {...containerProps}>
      <S.Nav aria-label="Pagination Navigation">
        <PaginationItem
          page={1}
          text="First"
          aria-label="Go to the first page"
          to={getLink(1)}
          disabled={isFirstPage}
        />
        <PaginationItem
          page={currentPage - 1}
          text="<"
          aria-label="Go to the previous page"
          to={getLink(currentPage - 1)}
          disabled={isFirstPage}
        />
        {pagesToRender(size, currentPage, totalPages).map((page) => (
          <PaginationItem
            key={page}
            page={page}
            current={currentPage === page}
            to={getLink(page)}
          />
        ))}
        <PaginationItem
          page={currentPage + 1}
          text=">"
          aria-label="Go to the next page"
          to={getLink(currentPage + 1)}
          disabled={isLastPage}
        />
        <PaginationItem
          page={totalPages}
          text="Last"
          aria-label="Go to the last page"
          current={false}
          to={getLink(totalPages)}
          disabled={isLastPage}
        />
      </S.Nav>
      <S.Text>{`Page ${currentPage} of ${totalPages}`}</S.Text>
    </S.Container>
  );
}

export default Pagination;

/**
 * Generate the list of items to render
 *
 * @param size : number of items to display;
 * @param page : current page number
 * @param lastPage : last page number
 * @returns an array with the number of pages to render
 *
 */
const pagesToRender = (size: number, page: number, lastPage: number) => {
  const start = clamp(
    page - Math.floor((size - 1) / 2),
    1,
    lastPage + 1 - size,
  );

  return Array(Math.min(size, lastPage))
    .fill(0)
    .map((_, index) => start + index);
};

/**
 * Calc the total of pages based on the total of items, and the items show per page
 * @param total
 * @param perPage
 * @returns
 */
const calcPages = (total: number, perPage: number) => {
  let result = Math.ceil(total / perPage);
  return isFinite(result) ? Math.max(result, 1) : 1;
};
