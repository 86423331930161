import { getProgramInfoBySlug } from 'common/apis/programsApi';
import { SubscriptionPlan } from 'common/utils/types';
import _ from 'lodash';
import { useQuery } from 'react-query';

export type UseProgramsProps = {
  slug: string;
};

export default function useProgramsBySlug({ slug }: UseProgramsProps) {
  const { data: program, ...rest } = useQuery({
    queryKey: ['program', slug],
    queryFn: () => getProgramInfoBySlug(slug),
    refetchOnWindowFocus: false,
  });

  const sortedSubscriptionPlans =
    program?.data && orderByMostSavings(program?.data?.subscription_plans);

  return {
    data: {
      data: {
        ...program?.data,
        subscription_plans: sortedSubscriptionPlans,
      },
    },
    ...rest,
  };
}

/**
 *
 * @param program plans, the subscription plans of a program
 * @returns subscription plans sorted by bonuses credits and annual savings,
 * higher credits bonus first, and use annual savings as fallback
 * in the case there is no bonuses credits
 */
const orderByMostSavings = (plans: SubscriptionPlan[]): SubscriptionPlan[] => {
  const subPlanWithMaxBonusCredit = _.maxBy(plans, 'enrollment_credits_amount');

  const subPlanWithMinBonusCredit = _.minBy(plans, 'enrollment_credits_amount');

  const enrollmentCreditDifference =
    _.toNumber(subPlanWithMaxBonusCredit?.enrollment_credits_amount) -
    _.toNumber(subPlanWithMinBonusCredit?.enrollment_credits_amount);

  if (enrollmentCreditDifference) {
    return _.sortBy(plans, ['enrollment_credits_amount']).reverse();
  }

  return plans.sort((a, b) => b.annual_savings - a.annual_savings);
};
