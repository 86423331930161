import styled from 'styled-components';

const TabTitleWrapper = styled.li`
  flex: 1;

  button {
    border-width: 2px;
    width: 100%;
  }

  &:first-of-type {
    button {
      border-radius: 4px 0 0 4px;
      height: 50px;
    }
  }

  &:last-of-type {
    button {
      border-radius: 0 4px 4px 0;
      height: 50px;
    }
  }
`;

export { TabTitleWrapper };
