/* istanbul ignore file */

import { useHooks } from '@everlywell/leaves';
import useApi from 'common/hooks/useApi';
import useRegistrationUser, {
  useKitRegistration,
} from 'common/hooks/useRegistrationUser';
import useScreenerQuestionnaire from 'common/hooks/useScreenerQuestionnaire';
import { track } from 'common/utils/analytics';
import { API_ERRORS } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { logError } from 'common/utils/helpers';
import {
  getKitType,
  registrationContainsValue,
} from 'common/utils/registrationHelpers';
import {
  KitRegistrationResponse,
  KitRegistrationUser,
  PreRegistrationDisclaimerType,
  PreRegistrationScreenerAnswer,
  PreRegistrationScreenerType,
  AddressSuggestion,
  AddressValidationError,
} from 'common/utils/types';
import Confirmation from 'components/KitRegistration/Confirmation';
import QuestionContainer from 'components/KitRegistration/CovidScreenerQuestions';
import KitRegistrationForm from 'components/KitRegistration/KitRegistrationForm';
import RegistrationComplete from 'components/KitRegistration/RegistrationComplete';
import SampleCollectionForm from 'components/KitRegistration/SampleCollectionForm';
import TestInformationForm from 'components/KitRegistration/TestInformationForm';
import KitRegistrationWrapper from 'components/KitRegistrationWrapper';
import Layout from 'components/Layout';
import PreRegistrationDisclaimer from 'components/PreRegistrationDisclaimer';
import PreRegistrationScreener from 'components/PreRegistrationScreener';
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import { PreRegistrationWrapper } from './styles';

export const QUESTION_NUMBERS = {
  STANDARD: 7,
  NEW_YORK_STATE: 8,
};

const PRE_REGISTRATION_STEPS = {
  SCREENER: 'screener',
};

type KitType = 'covid' | 'standard' | 'needsSample';
type ComponentString =
  | 'registration'
  | 'tester info'
  | 'sample'
  | 'screener'
  | 'review'
  | 'done';

type StepMap = { [k in KitType]: ComponentString[] };
const stepsMap: StepMap = {
  covid: ['tester info', 'sample', 'screener', 'review', 'done'],
  needsSample: ['tester info', 'sample', 'review', 'done'],
  standard: ['tester info', 'review', 'done'],
};

const newYorkScreener = [
  'symptoms',
  'exposure',
  'multiQuestions',
  'highRisk',
  'newYorkQuestions',
  'covidVaccine',
  'covidVaccineDoses',
];
const standardScreener = [
  'symptoms',
  'exposure',
  'multiQuestions',
  'highRisk',
  'covidVaccine',
  'covidVaccineDoses',
];

const getQuestionsForScreener = (user: KitRegistrationUser) =>
  user.addressState.value === 'New York' ? newYorkScreener : standardScreener;

const getIndexFromStepName = (stepName: ComponentString, steps: string[]) =>
  steps.indexOf(stepName) >= 0 ? steps.indexOf(stepName) : null;

const getCompanyName = (kitRegistration?: KitRegistrationResponse): string => {
  if (kitRegistration && kitRegistration.enterprise) {
    const { enterprise_client_name: client, enterprise_partner_name: partner } =
      kitRegistration.enterprise;
    if (client) return client;
    if (partner) return partner;
  }
  return '';
};

const componentMap: {
  [k in ComponentString]: (
    handleSubmit: (value?: any) => Promise<void>,
    user: KitRegistrationUser,
    kitRegistration?: KitRegistrationResponse,
    errors?: string,
    setErrors?: React.Dispatch<React.SetStateAction<string>>,
    addressSuggestions?: AddressSuggestion[],
    isAddressError?: boolean,
    handleScreenerEdit?: () => void,
    collectionTypes?: string,
  ) => JSX.Element;
} = {
  registration: (
    handleSubmit: (value: string) => Promise<void>,
    user: KitRegistrationUser,
    kitRegistration?: KitRegistrationResponse,
    errors?: string,
    setErrors?: React.Dispatch<React.SetStateAction<string>>,
  ) => (
    <KitRegistrationForm
      onSubmit={handleSubmit}
      errors={errors}
      setErrors={setErrors}
    />
  ),
  'tester info': (
    handleSubmit: () => void,
    user: KitRegistrationUser,
    kitRegistration?: KitRegistrationResponse,
  ) => (
    <TestInformationForm
      previouslyRegistered={
        kitRegistration ? kitRegistration.previously_registered_kit : false
      }
      onSubmit={handleSubmit}
      isCovid={kitRegistration ? !!kitRegistration.covid : false}
      minimum_age={kitRegistration?.minimum_age ?? 18}
      explicitConsent={
        kitRegistration && kitRegistration.enterprise
          ? kitRegistration.enterprise.explicit_consent
          : false
      }
      testName={kitRegistration ? kitRegistration.product.name : ''}
      companyName={getCompanyName(kitRegistration)}
      customConsentCopy={
        kitRegistration &&
        kitRegistration.enterprise &&
        kitRegistration.enterprise.custom_consent_copy
          ? kitRegistration.enterprise.custom_consent_copy
          : ''
      }
      thirdPartyId={
        kitRegistration &&
        kitRegistration.enterprise &&
        kitRegistration.enterprise.third_party
          ? kitRegistration.enterprise.third_party
          : undefined
      }
      customTermsEnabled={
        !!(
          kitRegistration &&
          kitRegistration.enterprise &&
          kitRegistration.enterprise.custom_terms_enabled
        )
      }
      customTerms={
        kitRegistration &&
        kitRegistration.enterprise &&
        kitRegistration.enterprise.custom_terms_copy
          ? kitRegistration.enterprise.custom_terms_copy
          : ''
      }
      user={user}
      consumerContainsSex={registrationContainsValue('gender', kitRegistration)}
      consumerContainsDOB={registrationContainsValue('dob', kitRegistration)}
      kitGenderRestriction={
        kitRegistration && kitRegistration.gender_restriction
      }
    />
  ),
  sample: (
    handleSubmit: () => void,
    user: KitRegistrationUser,
    kitRegistration?: KitRegistrationResponse,
  ) =>
    user.sampleCollectionDate &&
    user.sampleCollectionTime &&
    kitRegistration ? (
      <SampleCollectionForm
        sampleCollectionDate={user.sampleCollectionDate}
        sampleCollectionTime={user.sampleCollectionTime}
        barcode={kitRegistration.barcode}
        handleSubmit={handleSubmit}
      />
    ) : (
      <></>
    ),
  screener: (handleSubmit: () => void, user: KitRegistrationUser) => {
    const intialTotalQuestions =
      user.addressState.value === 'New York'
        ? QUESTION_NUMBERS.NEW_YORK_STATE
        : QUESTION_NUMBERS.STANDARD;
    return (
      <QuestionContainer
        totalQuestions={intialTotalQuestions}
        questions={getQuestionsForScreener(user)}
        user={user}
        onSubmit={handleSubmit}
      />
    );
  },
  review: (
    handleSubmit: () => void,
    user: KitRegistrationUser,
    kitRegistration?: KitRegistrationResponse,
    errors?: string,
    setErrors?: React.Dispatch<React.SetStateAction<string>>,
    addressSuggestions?: AddressSuggestion[],
    isAddressError?: boolean,
    handleScreenerEdit?: () => void,
  ) => (
    <Confirmation
      onSubmit={handleSubmit}
      user={user}
      errors={errors}
      isAddressError={isAddressError}
      setErrors={setErrors}
      addressSuggestions={addressSuggestions}
      consumerContainsSex={registrationContainsValue('gender', kitRegistration)}
      consumerContainsDOB={registrationContainsValue('dob', kitRegistration)}
      isCovidKit={kitRegistration ? !!kitRegistration.covid : false}
      productImageURL={
        kitRegistration && kitRegistration.product.box_image_url
          ? kitRegistration.product.box_image_url
          : ''
      }
      minimum_age={kitRegistration?.minimum_age ?? 18}
      testName={kitRegistration ? kitRegistration.product.name : ''}
      barcode={kitRegistration ? kitRegistration.barcode.dash_format : ''}
      onScreenerEdit={handleScreenerEdit}
      previouslyRegistered={
        kitRegistration ? kitRegistration.previously_registered_kit : false
      }
      thirdPartyId={
        kitRegistration &&
        kitRegistration.enterprise &&
        kitRegistration.enterprise.third_party
          ? kitRegistration.enterprise.third_party
          : undefined
      }
      customTermsEnabled={
        !!(
          kitRegistration &&
          kitRegistration.enterprise &&
          kitRegistration.enterprise.custom_terms_enabled
        )
      }
      customTerms={
        kitRegistration &&
        kitRegistration.enterprise &&
        kitRegistration.enterprise.custom_terms_copy
          ? kitRegistration.enterprise.custom_terms_copy
          : ''
      }
      isLabVisit={kitRegistration?.lab_visit}
    />
  ),
  done: (
    handleSubmit: () => void,
    user: KitRegistrationUser,
    kitRegistration?: KitRegistrationResponse,
    collectionTypes?: string,
  ) => (
    <RegistrationComplete
      onSubmit={handleSubmit}
      user={user}
      kitRegistration={kitRegistration || undefined}
      isCovidKit={kitRegistration ? !!kitRegistration.covid : false}
      isEnterprise={kitRegistration ? !!kitRegistration.enterprise : false}
      productImageURL={
        kitRegistration && kitRegistration.product.box_image_url
          ? kitRegistration.product.box_image_url
          : ''
      }
      testName={kitRegistration ? kitRegistration.product.name : ''}
      collectionTypes={collectionTypes}
    />
  ),
};

export const RegistrationContainer = () => {
  const { postKitRegistration, patchKitRegistration } = useApi();
  const { getQuestionnaire } = useScreenerQuestionnaire();

  const [kitRegistration, setKitRegistration] =
    useState<KitRegistrationResponse>();
  const [kitRegistrationPayload, setKitRegistrationPayload] =
    useState<KitRegistrationResponse>();

  const [hasPreScreener, setHasPreScreener] = useState(false);
  const [isEligible, setIsEligible] = useState(false);
  const [isPreScreenerCompleted, setIsPreScreenerCompleted] = useState(false);
  const [screenerContent, setScreenerContent] =
    useState<PreRegistrationScreenerType | null>(null);
  const [disclamerContent, setDisclaimerContent] =
    useState<PreRegistrationDisclaimerType | null>(null);
  const [screenerAnswer, setScreenerAnswer] =
    useState<PreRegistrationScreenerAnswer>();

  const shouldRenderPreScreener = hasPreScreener && !isPreScreenerCompleted;

  const [kitType, setKitType] = useState<KitType>();
  const [steps, setSteps] = useState<string[]>([]);

  const { currentStep, navigation, hasNextStep, isLastStep } = useHooks.useStep(
    { steps },
  );

  const [errors, setErrors] = useState('');
  const [isAddressError, setIsAddressError] = useState(false);
  const [addressSuggestions, setAddressSuggestions] = useState<
    AddressSuggestion[]
  >([]);
  const [collectionTypes, setCollectionTypes] = useKitRegistration();
  const [user, setUser] = useRegistrationUser();

  const handleOnPreRegistrationScreener = async (
    response: KitRegistrationResponse,
  ) => {
    const {
      product: { slug },
    } = response;

    const { screenerContent, disclaimerContent } = await getQuestionnaire(slug);
    const isValidContent =
      !isEmpty(screenerContent) && !isEmpty(disclaimerContent);

    if (isValidContent) {
      handleOnShowPreScreener(screenerContent, disclaimerContent);
    } else {
      handleNotPreRegistrationScreener(response);
      logError('Error', {
        method: 'handleOnPreRegistrationScreener',
        message: `Invalid Screener or Disclaimer content for ${slug}`,
      });
    }
  };

  const handleKitIDSubmission = async (kitID: string) => {
    try {
      if (kitID) {
        const kitRegistrationResponse = await postKitRegistration(kitID);
        setKitRegistrationPayload(kitRegistrationResponse);

        const { partner_type, pre_kit_registration_step } =
          kitRegistrationResponse ?? {};

        if (partner_type && partner_type !== 'dtc') {
          Cookies.set('thirdparty', partner_type);
        } else {
          Cookies.remove('thirdparty');
        }

        if (pre_kit_registration_step === PRE_REGISTRATION_STEPS.SCREENER) {
          handleOnPreRegistrationScreener(kitRegistrationResponse);
        } else {
          handleNotPreRegistrationScreener(kitRegistrationResponse);
        }
      }
    } catch (err) {
      const error = err as Error;
      if (error.toString() === API_ERRORS.kitRegistration.NETWORK_ERROR.value) {
        setErrors(API_ERRORS.kitRegistration.NETWORK_ERROR.display);
      } else if (err instanceof Error) {
        setErrors(API_ERRORS.kitRegistration.UNEXPECTED_ERROR);
      } else {
        setErrors(error.toString());
      }
    }
  };

  const handleOnShowPreScreener = async (
    screenerContent: any,
    disclaimerContent: any,
  ) => {
    setScreenerContent(screenerContent);
    setDisclaimerContent(disclaimerContent);
    setHasPreScreener(true);
  };

  const handleNotPreRegistrationScreener = (
    response: KitRegistrationResponse | undefined,
  ) => {
    if (response) {
      setKitRegistration(response);
      setCollectionTypes(response);
      setErrors('');
    } else {
      // Invalid KitRegistrationResponse
      setErrors(API_ERRORS.kitRegistration.UNEXPECTED_ERROR);
      logError('Error', {
        method: 'handleNotPreRegistrationScreener',
        message: 'Invaliid KitRegistrationResponse',
      });
    }
  };

  const handleScreenerEdit = () => {
    const screenerStep = getIndexFromStepName(
      'screener',
      stepsMap[kitType || 'standard'],
    );
    if (screenerStep) {
      track({
        event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
        data: {
          category: ANALYTICS.CATEGORIES.REGISTRATION,
          component: ANALYTICS.PAGES.REGISTRATION.screener,
          testName: kitRegistration && kitRegistration.product.name,
          kitType: getKitType(kitType || '', kitRegistration),
          registrationID: kitRegistration && kitRegistration.id,
        },
      });
      navigation.goToStep(screenerStep);
    }
  };

  const handleOnPreScreenerSubmit = (
    answer: PreRegistrationScreenerAnswer,
    isEligible: boolean,
  ) => {
    setIsEligible(isEligible);
    setScreenerAnswer(answer);
    setIsPreScreenerCompleted(true);

    if (isEligible) {
      handleNotPreRegistrationScreener(kitRegistrationPayload);
    }
  };

  const getActiveStep = (
    kitType: KitType,
    currentStep: number,
  ): ComponentString => stepsMap[kitType][currentStep];

  const getActiveComponent = () => {
    if (!kitType) {
      track({
        event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
        data: {
          category: ANALYTICS.CATEGORIES.REGISTRATION,
          component: ANALYTICS.PAGES.REGISTRATION.registration,
        },
      });

      const shouldShowPreDisclaimer = isPreScreenerCompleted && !isEligible;
      const shouldShowRegistration = !hasPreScreener && !isPreScreenerCompleted;

      if (shouldRenderPreScreener) {
        return (
          <PreRegistrationScreener
            screener={screenerContent as PreRegistrationScreenerType}
            eligibleOptionCallback={(answer) =>
              handleOnPreScreenerSubmit(answer, true)
            }
            ineligibleOptionCallback={(answer) =>
              handleOnPreScreenerSubmit(answer, false)
            }
          />
        );
      } else if (shouldShowPreDisclaimer) {
        return (
          <PreRegistrationDisclaimer
            kitRegistration={kitRegistration}
            {...(disclamerContent as PreRegistrationDisclaimerType)}
          />
        );
      } else if (shouldShowRegistration) {
        return componentMap.registration(
          handleKitIDSubmission,
          user,
          kitRegistration,
          errors || '',
          setErrors,
          addressSuggestions,
          isAddressError,
        );
      }

      return <></>;
    }

    const activeStep = getActiveStep(kitType, currentStep);

    track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        category: ANALYTICS.CATEGORIES.REGISTRATION,
        component: ANALYTICS.PAGES.REGISTRATION[activeStep] || '',
        testName: kitRegistration && kitRegistration.product.name,
        kitType: getKitType(kitType, kitRegistration),
        registrationID: kitRegistration && kitRegistration.id,
      },
    });

    return componentMap[activeStep](
      handleSubmit,
      user,
      kitRegistration,
      errors || '',
      setErrors,
      addressSuggestions,
      isAddressError,
      handleScreenerEdit,
      // @ts-ignore
      collectionTypes,
    );
  };

  /**
   * Kit Registration Effect
   */
  useEffect(() => {
    if (kitRegistration) {
      if (kitRegistration.covid) {
        setKitType('covid');
        setSteps(stepsMap.covid);
      } else if (kitRegistration.needs_sample_collection_date) {
        setKitType('needsSample');
        setSteps(stepsMap.needsSample);
      } else {
        setKitType('standard');
        setSteps(stepsMap.standard);
        if (
          kitRegistration.previously_registered_kit &&
          (!kitRegistration.enterprise ||
            !kitRegistration.enterprise.explicit_consent)
        ) {
          track({
            event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
            data: {
              category: ANALYTICS.CATEGORIES.REGISTRATION,
              component: ANALYTICS.PAGES.REGISTRATION[stepsMap.standard[1]],
              testName: kitRegistration && kitRegistration.product.name,
              kitType: getKitType(kitType || '', kitRegistration),
              registrationID: kitRegistration && kitRegistration.id,
            },
          });

          navigation.goToStep(1);
        }
      }
      setUser(kitRegistration);
    }
  }, [kitRegistration]); // eslint-disable-line

  const handleSubmit = async (overrideAddress?: boolean) => {
    let errorInSubmission = false;
    if (kitType) {
      if (stepsMap[kitType][currentStep] === 'review') {
        try {
          await patchKitRegistration(
            user,
            kitRegistration,
            overrideAddress,
            screenerAnswer,
          );
        } catch (err) {
          const error = err as AddressValidationError;
          if (
            !error ||
            API_ERRORS.kitRegistration.UNEXPECTED_ERROR.includes(
              error.toString(),
            )
          ) {
            setErrors(API_ERRORS.kitRegistration.UNEXPECTED_ERROR);
          } else {
            const { suggestions, message, addressInvalid } = error;

            setAddressSuggestions(suggestions);
            setIsAddressError(addressInvalid);
            setErrors(message);
          }
          errorInSubmission = true;
        }
      }
      if (!errorInSubmission && hasNextStep) {
        navigation.goToNextStep();
      }
    }
  };

  return (
    <Layout>
      <>
        {shouldRenderPreScreener ? (
          <PreRegistrationWrapper>
            {getActiveComponent()}
          </PreRegistrationWrapper>
        ) : (
          <KitRegistrationWrapper
            steps={steps}
            currentStep={currentStep}
            navigation={navigation}
            hasNextStep={hasNextStep}
            isLastStep={isLastStep}
          >
            {getActiveComponent()}
          </KitRegistrationWrapper>
        )}
      </>
    </Layout>
  );
};
export default RegistrationContainer;
