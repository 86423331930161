import { RUM_ACTIONS } from 'common/utils/constants/rumActions';
import useTimeTracker from 'common/utils/useTimeTracker';
import React, { useEffect } from 'react';

import * as S from './styles';

export type Props = {
  children: JSX.Element | JSX.Element[];
  steps: string[];
  currentStep: number;
  navigation: {
    goToNextStep: () => void;
    goToPreviousStep: () => void;
    goToStep: (newStep: any) => void;
  };
  isLastStep: boolean;
  hasNextStep: boolean;
  uuid?: string;
};

const KitRegistrationWrapper = (props: Props) => {
  const { children, steps, navigation, currentStep, hasNextStep, uuid } = props;

  const fireAction = useTimeTracker(RUM_ACTIONS.COMPLETED);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!hasNextStep && currentStep) fireAction();
  }, [currentStep, hasNextStep]);

  return (
    <S.KitRegistrationWrapper steps={steps}>
      <S.StepsContainer>
        {steps.length > 0 && (
          <S.ProgressBar
            steps={steps}
            goToStep={navigation.goToStep}
            currentStep={currentStep}
            uid={uuid}
          />
        )}
        <S.ContentContainer>{children}</S.ContentContainer>
      </S.StepsContainer>
    </S.KitRegistrationWrapper>
  );
};

export default KitRegistrationWrapper;
