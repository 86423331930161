import { H2, mediaQueries, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.p`
  margin: 0;
  ${typography.bodyTextSmall};

  ${mediaQueries.forTabletVerticalUp} {
    ${typography.bodyText};
  }
`;

export const Title = styled(H2)`
  ${typography.h3Text};
  margin: 0;
`;
