import { BaseModal } from 'components/KitRegistration/styles';
import React, { ReactElement, useEffect } from 'react';

import * as S from './styles';
interface Props {
  openModal: boolean;
  setOpenModal: (cta: string | ReactElement) => void;
  title: string;
  message: string | ReactElement;
  cta: string | ReactElement;
}

const MissingReplacementVariantModal = ({
  openModal,
  setOpenModal,
  title,
  message,
  cta,
}: Props) => {
  useEffect(() => {
    if (openModal) {
      document.body.style.cssText = 'height: 100vh; overflow: hidden;';
    } else {
      document.body.style.cssText = '';
    }
  }, [openModal]);
  return (
    <BaseModal open={openModal} openHandler={setOpenModal} noX={openModal}>
      <S.Title>{title}</S.Title>
      <S.Subtitle>{message}</S.Subtitle>
      <S.Accept appearance="primary" onClick={() => setOpenModal(cta)}>
        {cta}
      </S.Accept>
    </BaseModal>
  );
};

export default MissingReplacementVariantModal;
