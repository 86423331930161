import { T2TAnalyticsType } from 'common/hooks/useCommonT2TAnalytics';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';

export const whenUserClicksContinue = (
  params: { program: string } | T2TAnalyticsType,
) => {
  analytics.track({
    event: ANALYTICS.EVENTS.CLICKED_BUTTON,
    data: {
      label:
        ANALYTICS.LABELS.VIRTUAL_CARE.APPOINTMENT_SCHEDULING
          .APPOINTMENT_SELECTION_CONTINUE,
      ...params,
    },
  });
};

export const whenUserClicksBack = (
  params: { program: string } | T2TAnalyticsType,
) => {
  analytics.track({
    event: ANALYTICS.EVENTS.CLICKED_BUTTON,
    data: {
      label:
        ANALYTICS.LABELS.VIRTUAL_CARE.APPOINTMENT_SCHEDULING
          .APPOINTMENT_SELECTION_BACK,
      ...params,
    },
  });
};
