import Grid from 'components/Grid/Grid';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormBuilderField } from '../../utils/types';
import ConsentField from './components/ConsentField/ConsentField';

export type AgreeToAboveFieldProps = FormBuilderField;

export const REQUIRED_ERROR = 'Agreement is required to continue';

function AgreeToAboveField({ modules, ...props }: AgreeToAboveFieldProps) {
  const { control, errors } = useFormContext();

  return (
    <Controller
      control={control}
      name={props.id}
      defaultValue={'false'}
      rules={{
        validate: (value) => {
          if (props.required && value !== 'true') {
            return REQUIRED_ERROR;
          }
        },
      }}
      render={({ onChange, value, ref }) => (
        <Grid.Item width={[1]}>
          <ConsentField
            ref={ref}
            bodyTextStyle
            modules={modules}
            checked={value === 'true'}
            error={errors[props.id]?.message}
            onChange={() => {
              const toggledValue = value === 'true' ? 'false' : 'true';

              onChange(toggledValue);
            }}
          />
        </Grid.Item>
      )}
    />
  );
}

export default AgreeToAboveField;
