import { colors } from '@everlywell/leaves';
import CommonQuestionsSectionHeader from 'containers/CommonQuestionsSection/HeaderContainer';
import styled from 'styled-components';

const Header = styled(CommonQuestionsSectionHeader)`
  + div {
    color: ${colors.teal2};
    font-size: 33px;
    text-align: center;
  }
`;

export {
  Header,
}
