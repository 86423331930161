import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  Box,
  theme,
} from '@everlywell/ui-kit';
import React, { useState } from 'react';

import CarePlanInfoCard, {
  CarePlanInfoCardProps,
} from '../CarePlanInfoCard/CarePlanInfoCard';

export type CarePlanAccordionProps = {
  items: CarePlanInfoCardProps[] & { onClick?: () => void };
};

const CarePlanAccordion = ({ items }: CarePlanAccordionProps) => {
  const hasSingleItem = (items?.length ?? 0) === 1;
  const hasLessThenThreeItems = (items?.length ?? 0) <= 3;
  const defaultIndex = hasLessThenThreeItems ? [0] : [];
  const [expandedIndex, setExpandedIndex] = useState<number | number[]>(
    defaultIndex,
  );

  const handleChange = (index: number | number[]) => {
    setExpandedIndex(index);
  };

  return (
    <Accordion
      allowToggle={!hasSingleItem}
      defaultIndex={defaultIndex}
      onChange={handleChange}
    >
      <Flex direction="column" gap={{ base: 2, desktop: 4 }}>
        {items.map((item, index) => {
          const isExpanded = Array.isArray(expandedIndex)
            ? expandedIndex.includes(index)
            : expandedIndex === index;

          return (
            <AccordionItem key={index} border={0}>
              <Box
                boxShadow={isExpanded ? `${theme.shadows.tooltip}` : 'none'}
                transition="box-shadow 0.2s ease-in-out"
                borderRadius="2xl"
                backgroundColor="tints.white"
              >
                <CarePlanInfoCard
                  {...item}
                  headerWrapper={{
                    component: AccordionButton,
                    props: { padding: 0 },
                  }}
                  bodyWrapper={{
                    component: AccordionPanel,
                    props: { padding: 0 },
                  }}
                />
              </Box>
            </AccordionItem>
          );
        })}
      </Flex>
    </Accordion>
  );
};

export default CarePlanAccordion;
