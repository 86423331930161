/**
 * HeroCard
 */

import React from 'react';

import * as S from './styles';

type Props = {
  children: JSX.Element | JSX.Element[];
};

function HeroCard({ children }: Props) {
  return <S.HeroCard>{children}</S.HeroCard>;
}
export default HeroCard;
