import { size, colors, typography, mediaQueries } from '@everlywell/leaves';
import { Container } from 'pages/VirtualCare/NativeScheduling/components/FloatingCtaFooter/FloatingCtaFooter.styles';
import styled from 'styled-components';

const ErrorMessage = styled.p`
  ${typography.bodyTextSmall};
  font-weight: ${typography.weight.bold};
  color: ${colors.red3};
  min-height: ${size.lg}px;
  line-height: 1.5;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  ${Container} {
    /* Fix sticky footer position in mobile devices */
    ${mediaQueries.forPhoneOnly} {
      &[data-in-view='false'] {
        transform: translateX(-10px);
      }
    }
  }
`;

export { ErrorMessage, Form };
