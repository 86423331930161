import {
  TelehealthAppointment,
  TelehealthUserJourneys,
} from 'common/apis/telehealthApis';
import { buildTelehealthAppointment } from 'common/testUtils/builders/telehealthAppointments';
import {
  add,
  compareAsc,
  compareDesc,
  endOfTomorrow,
  endOfYesterday,
  isFuture,
  isPast,
  sub,
} from 'date-fns';
import { rest } from 'msw';

const futureAppointments = [
  buildTelehealthAppointment({
    start_time: add(Date.now(), { minutes: 10 }).toISOString(),
    end_time: add(Date.now(), { minutes: 70 }).toISOString(),
  }),
  buildTelehealthAppointment({
    start_time: endOfTomorrow().toISOString(),
    end_time: add(endOfTomorrow(), { minutes: 40 }).toISOString(),
  }),
];

const pastAppointments = [
  buildTelehealthAppointment({
    start_time: endOfYesterday().toISOString(),
  }),
  buildTelehealthAppointment({
    start_time: sub(new Date(), { weeks: 3 }).toISOString(),
    care_plan: {
      id: '123',
      completed_at: endOfTomorrow().toISOString(),
    },
  }),
];

const defaultAppointments = [...futureAppointments, ...pastAppointments];

const filterAppointmentsByPeriod = (
  period: string | null,
  appointments: Array<TelehealthAppointment>,
) => {
  switch (period) {
    case 'future':
      return appointments.filter((appointment) =>
        isFuture(new Date(appointment.start_time)),
      );
    case 'past':
      return appointments.filter((appointment) =>
        isPast(new Date(appointment.start_time)),
      );
    default:
      return appointments;
  }
};

const sortAppointmentsBy = (
  appointments: TelehealthAppointment[],
  option: string | null,
) => {
  if (option === 'date_desc') {
    return appointments.sort((a, b) =>
      compareDesc(new Date(a.start_time), new Date(b.start_time)),
    );
  }

  return appointments.sort((a, b) =>
    compareAsc(new Date(a.start_time), new Date(b.start_time)),
  );
};

export const appointmentsResolver =
  (
    appointments: Array<TelehealthAppointment> = defaultAppointments,
  ): Parameters<typeof rest.get>[1] =>
  (req, res, ctx) => {
    const period = req.url.searchParams.get('period');
    let filteredAppointments = filterAppointmentsByPeriod(period, appointments);

    filteredAppointments = sortAppointmentsBy(
      filteredAppointments,
      req.url.searchParams.get('sort_by'),
    );

    const limit = parseInt(req.url.searchParams.get('limit') ?? '0');
    if (limit >= 1) {
      filteredAppointments = filteredAppointments?.slice(0, limit);
    }

    return res(
      ctx.json({
        appointments: filteredAppointments,
      }),
    );
  };

export const journeyResolver =
  (journeys: TelehealthUserJourneys): Parameters<typeof rest.get>[1] =>
  (_, res, ctx) =>
    res(ctx.json(journeys));
