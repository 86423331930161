import { Text } from 'pages/AccountHub/pages/DashboardPage/components/Cards/BaseCard.styles';
import image404 from 'pages/ErrorPage/StatusCode/images/404.svg';
import image500 from 'pages/ErrorPage/StatusCode/images/500.svg';
import React from 'react';
import { Link } from 'react-router-dom';

import * as S from './ErrorComponent.styles';

interface ErrorComponentProps {
  statusCode: number | undefined;
}

/**
 * Error component used for care plan UI.
 */

export const ERROR_HEADER = 'Whoops!';
export const ERROR_404_TEXT =
  'Sorry, but we were unable to find what you were looking for.';
export const ERROR_500_TEXT =
  'Sorry, but we were experiencing some technical difficulties. Please check back again in a little while.';
export const LINK_TEXT = 'Go to dashboard';

function ErrorComponent({ statusCode }: ErrorComponentProps) {
  const is404 = statusCode === 404;

  return (
    <S.Container>
      <S.ImageContainer>
        <img src={is404 ? image404 : image500} alt="" />
      </S.ImageContainer>
      <S.InnerContainer>
        <S.ContentContainer>
          <S.Header>{ERROR_HEADER}</S.Header>
          <Text>{is404 ? ERROR_404_TEXT : ERROR_500_TEXT}</Text>
        </S.ContentContainer>
        <S.CTAContainer>
          <S.ButtonLink
            component={Link}
            appearance="primary"
            href="/virtual-care"
          >
            {LINK_TEXT}
          </S.ButtonLink>
        </S.CTAContainer>
      </S.InnerContainer>
    </S.Container>
  );
}

export default ErrorComponent;
