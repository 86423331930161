import {
  AppointmentSlot,
  AppointmentsSlotsMeta,
} from 'common/apis/telehealthSchedulingApis';
import { random, uniqueId } from 'lodash';

const buildTelehealthAppointmentUser = (
  overrideProps?: Partial<AppointmentsSlotsMeta['user']>,
) => ({
  id: parseInt(uniqueId()),
  time_zone: 'Asia/Yakutsk',
  state_abbrev: 'CA',
  ...overrideProps,
});

export const buildTelehealthAppointmentType = (
  overrideProps?: Partial<AppointmentsSlotsMeta['appointment_type']>,
) => ({
  id: random(0, 100),
  name: 'Initial Virtual Care Visit',
  contact_type: 'Healthie Video Call',
  ...overrideProps,
});

export const buildTelehealthProvider = (
  overrideProps?: Partial<AppointmentSlot['provider']>,
) => ({
  id: String(random(0, 100)),
  name: 'Dr. Jim Vanaria',
  credentials: ['NP'],
  ...overrideProps,
});

const currentDate = new Date();
currentDate.setUTCHours(13, 0, 0, 0);

const baseAppointmentSlot: AppointmentSlot = {
  datetime: currentDate.toISOString(),
  provider: buildTelehealthProvider(),
};

export const buildTelehealthAppointmentSlot = (
  overrideProps: Partial<AppointmentSlot> = {},
) => ({
  ...baseAppointmentSlot,
  ...overrideProps,
});

export const buildTimes = (
  date: Date,
  {
    availableHours = ['09', '11', '13', '14', '19', '20'],
    availableMinutes = ['00', '30'],
  } = {},
) =>
  availableHours
    .map((hour) => availableMinutes.map((minute) => `${hour}:${minute}:00`))
    .flat()
    .map((time) => `${date.toISOString().split('T')[0]}T${time}Z`);

export const buildTelehealthAppointmentMeta = (
  overrideProps: Partial<AppointmentsSlotsMeta> = {},
) => ({
  user: buildTelehealthAppointmentUser(overrideProps?.user),
  appointment_type: buildTelehealthAppointmentType(
    overrideProps?.appointment_type,
  ),
});
