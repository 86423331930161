import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';

import { RedeemableProductType } from './CreditRedemptionRoutes';

export const trackRedemption = (program: string | undefined) => ({
  address: {
    whenViewed: () => {
      analytics.track({
        event: ANALYTICS.EVENTS.VIEWED_PAGE,
        data: {
          label: ANALYTICS.PAGES.CREDIT_REDEMPTION.ADDRESS,
          category: ANALYTICS.CATEGORIES.CREDIT_REDEMPTION,
          program: program || 'unknown',
        },
      });
    },
    whenEditClicked: () => {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label:
            ANALYTICS.LABELS.CREDIT_REDEMPTION.ADDRESS_CONFIRMATION_EDIT_CLICK,
          category: ANALYTICS.CATEGORIES.CREDIT_REDEMPTION,
          program: program || 'unknown',
        },
      });
    },
    whenBackClicked: () => {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label:
            ANALYTICS.LABELS.CREDIT_REDEMPTION.ADDRESS_CONFIRMATION_BACK_CLICK,
          category: ANALYTICS.CATEGORIES.CREDIT_REDEMPTION,
          program: program || 'unknown',
        },
      });
    },
    whenAllSetClicked: () => {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label:
            ANALYTICS.LABELS.CREDIT_REDEMPTION
              .ADDRESS_CONFIRMATION_CONFIRM_CLICK,
          category: ANALYTICS.CATEGORIES.CREDIT_REDEMPTION,
          program: program || 'unknown',
        },
      });
    },
    whenFormBackClicked: () => {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: ANALYTICS.LABELS.CREDIT_REDEMPTION.ADDRESS_FORM_BACK_CLICK,
          category: ANALYTICS.CATEGORIES.CREDIT_REDEMPTION,
          program: program || 'unknown',
        },
      });
    },
    whenFormSubmitClicked: () => {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: ANALYTICS.LABELS.CREDIT_REDEMPTION.ADDRESS_FORM_CONFIRM_CLICK,
          category: ANALYTICS.CATEGORIES.CREDIT_REDEMPTION,
          program: program || 'unknown',
        },
      });
    },
  },
  products: {
    whenViewed: (redeemableProductType: RedeemableProductType) => {
      analytics.track({
        event: ANALYTICS.EVENTS.VIEWED_PAGE,
        data: {
          label: ANALYTICS.PAGES.CREDIT_REDEMPTION.PRODUCT_SELECTION,
          category: ANALYTICS.CATEGORIES.CREDIT_REDEMPTION,
          program: program || 'unknown',
          redeemableProductType,
        },
      });
    },
    whenSelected: (
      product: string,
      redeemableProductType: RedeemableProductType,
    ) => {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: ANALYTICS.LABELS.CREDIT_REDEMPTION.PRODUCT_SELECTION_CLICK,
          category: ANALYTICS.CATEGORIES.CREDIT_REDEMPTION,
          product,
          program: program || 'unknown',
          redeemableProductType,
        },
      });
    },
    whenBackClicked: (redeemableProductType: RedeemableProductType) => {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label:
            ANALYTICS.LABELS.CREDIT_REDEMPTION.PRODUCT_SELECTION_BACK_CLICK,
          category: ANALYTICS.CATEGORIES.CREDIT_REDEMPTION,
          program: program || 'unknown',
          redeemableProductType,
        },
      });
    },
  },
  confirmation: {
    whenViewed: () => {
      analytics.track({
        event: ANALYTICS.EVENTS.VIEWED_PAGE,
        data: {
          label: ANALYTICS.PAGES.CREDIT_REDEMPTION.CONFIRMATION,
          category: ANALYTICS.CATEGORIES.CREDIT_REDEMPTION,
          program,
        },
      });
    },
    whenContinueClicked: () => {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: ANALYTICS.LABELS.CREDIT_REDEMPTION.CONFIRMATION_CLICK,
          category: ANALYTICS.CATEGORIES.CREDIT_REDEMPTION,
          program: program || 'unknown',
        },
      });
    },
  },
  testKitOrVCV: {
    whenViewed: () => {
      analytics.track({
        event: ANALYTICS.EVENTS.VIEWED_PAGE,
        data: {
          label: ANALYTICS.PAGES.CREDIT_REDEMPTION.TEST_KIT_OR_VCV_SELECTION,
          category: ANALYTICS.CATEGORIES.CREDIT_REDEMPTION,
          program: program || 'unknown',
        },
      });
    },
    whenBackClicked: () => {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label:
            ANALYTICS.LABELS.CREDIT_REDEMPTION
              .TEST_KIT_OR_VCV_SELECTION_BACK_CLICK,
          category: ANALYTICS.CATEGORIES.CREDIT_REDEMPTION,
          program: program || 'unknown',
        },
      });
    },
    whenFormSubmitClicked: (selectedProduct: string) => {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label:
            ANALYTICS.LABELS.CREDIT_REDEMPTION
              .TEST_KIT_OR_VCV_SELECTION_PROCEED_CLICK,
          category: ANALYTICS.CATEGORIES.CREDIT_REDEMPTION,
          program: program || 'unknown',
          product: selectedProduct,
        },
      });
    },
  },
});
