import {
  TAKE_ACTION,
  WHATS_NEXT_SECTION,
} from 'common/utils/constants/dataTest';
import { renderWithProviders } from 'common/utils/reactTestingLibraryHelpers';
import React from 'react';

import ResultsDiscussion, { Props } from '../index';
import * as testProps from './testProps';

const doRender = (overrideProps: Partial<Props> = {}) =>
  renderWithProviders(<ResultsDiscussion {...testProps} {...overrideProps} />);

describe('<ResultsDiscussion />', () => {
  it('should render a list of items', () => {
    const { container, queryByTestId } = doRender();

    testProps.items.forEach((item) => {
      expect(container.textContent).toContain(item.text);
    });

    expect(queryByTestId(TAKE_ACTION.SCHEDULE_TOOLTIP)).toBeDefined();
    expect(queryByTestId(TAKE_ACTION.SCHEDULE_TOOLTIP_CONTENT)).toBeDefined();
    expect(queryByTestId(WHATS_NEXT_SECTION.SCHEDULE_NOW_BUTTON)).toBeDefined();
  });

  it('should render the webinar component when passed the correct buttonContent, but no tooltip', () => {
    const { queryByTestId } = doRender({
      buttonContent: 'Watch',
    });

    expect(queryByTestId(TAKE_ACTION.SCHEDULE_TOOLTIP)).toBeNull();
    expect(queryByTestId(TAKE_ACTION.SCHEDULE_TOOLTIP_CONTENT)).toBeNull();
    expect(
      queryByTestId(WHATS_NEXT_SECTION.WATCH_WEBINAR_BUTTON),
    ).toBeDefined();
  });

  it('should indicate webinar is always available', () => {
    const { container } = doRender({
      buttonContent: 'Watch',
      isWebinar: true,
    });

    expect(container.textContent).toContain('Available Always');
  });
});
