import React from 'react';

import {
  OPENLOOP_AVAILABILITY,
  OPENLOOP_FORMATTED_PHONE_NUMBER,
  OPENLOOP_PHONE_NUMBER,
} from '../../utils/constants';
import * as S from './NeedHelpCard.styles';

export type NeedHelpCardProps = {
  onClick: () => void;
};

/**
 * Section to display help information for the user
 */
function NeedHelpCard({ onClick }: NeedHelpCardProps) {
  return (
    <S.Container>
      <svg
        width="73"
        height="73"
        viewBox="0 0 73 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="36.5" cy="36.5" r="36" fill="#EEF5F6" />
        <path
          d="M47.8183 38.3091C47.8183 36.4836 47.4214 34.6421 46.5642 32.88C44.437 28.4828 40.0716 25.9112 35.5156 25.8477"
          stroke="#007377"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M54.9935 38.3086C54.9935 35.4353 54.3586 32.5144 53.0251 29.7522C49.6597 22.7992 42.7385 18.7354 35.5156 18.6719"
          stroke="#007377"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.8056 48.3575C41.8056 48.3575 39.7102 48.167 32.9159 40.8806C26.074 33.5466 26.074 31.4988 26.074 31.4988L28.725 27.9429C29.4394 27.0539 29.3917 25.7999 28.6139 24.9744L23.2801 19.2596C22.2959 18.196 20.5814 18.2595 19.6765 19.3865L18.1843 21.228C13.0569 27.2603 19.6449 38.1502 27.2011 46.2462C34.5351 54.104 45.2345 61.8825 51.5366 56.9614L53.4734 55.5962C54.664 54.7549 54.8385 53.0722 53.8384 52.0086L48.5046 46.2938C47.7268 45.4683 46.4727 45.3254 45.5361 45.9763L41.8056 48.3733V48.3575Z"
          stroke="#007377"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <S.Heading>Need help?</S.Heading>

      <S.Text>
        Contact your healthcare provider team at{' '}
        <a href={`tel:${OPENLOOP_PHONE_NUMBER}`} onClick={onClick}>
          {OPENLOOP_FORMATTED_PHONE_NUMBER}
        </a>
        , {OPENLOOP_AVAILABILITY}
      </S.Text>
    </S.Container>
  );
}

export default NeedHelpCard;
