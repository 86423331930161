import { typography, colors, mediaQueries } from '@everlywell/leaves';
import { sectionTitle, bodyCopy } from 'common/styles/app-styles';
import { hexToRGB } from 'common/utils/helpers';
import styled, { css } from 'styled-components';

const badgeWrapper = css`
  display: block;
  padding: 0 48px 25px;
  background-color: #f9f9f9;
`;

const ChartsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${mediaQueries.forTabletVerticalUp} {
    grid-template-columns: 3fr 1fr;
  }
`;

const HeadingWrapper = styled.div`
  display: block;
  padding: 18px 16px;

  ${mediaQueries.forDesktopUp} {
    padding: 16px 45px 0 48px;
  }
`;

const ChartGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 16px 23px;

  ${mediaQueries.forTabletVerticalUp} {
    padding: 0 24px 30px;
  }

  ${mediaQueries.forDesktopUp} {
    grid-template-columns: 1fr 4fr;
    padding: 0 45px 25px 48px;
  }
`;

const FlexWrapper = styled.div`
  padding-top: 48px;
  display: flex;
  justify-content: space-between;
`;

const MarkerChartWrapper = styled.div`
  display: block;
`;

const MarkerChartContainer = styled.div`
  display: block;

  ${mediaQueries.forTabletVerticalUp} {
    padding-top: 16px;
  }
  ${mediaQueries.forDesktopUp} {
    padding-top: 0;
  }
`;

const ReactivityBadgeWrapper = styled.div`
  ${badgeWrapper};
  padding: 16px;

  ${mediaQueries.forTabletVerticalUp} {
    padding: 0 24px 30px;
  }

  ${mediaQueries.forDesktopUp} {
    padding: 0 48px 25px;
  }
`;

const ReactivityBadgeFlexWrapper = styled.div`
  ${badgeWrapper};
  border-radius: 0 8px 0 0;
  display: none;

  ${mediaQueries.forTabletVerticalUp} {
    display: block;
    padding: 0 24px 17px;
  }

  ${mediaQueries.forDesktopUp} {
    padding: 0 48px 25px;
  }
`;

const MarkerName = styled.p`
  margin: 0;
  ${sectionTitle};
  color: ${colors.gray4};
`;

const LightText = styled.p`
  ${bodyCopy};
  color: ${colors.gray4};
  font-weight: ${typography.weight.light};
`;

const collectionLabelStyles = css`
  ${bodyCopy};
  border-radius: 4px;
  border: solid 1px rgba(${hexToRGB(colors.gray2)}, 0.5);
  color: ${colors.gray4};
  font-weight: ${typography.weight.bold};
  width: 100%;
  justify-self: center;
  align-self: center;
  text-align: center;
  padding: 3px 0;

  span {
    font-size: 14px;
  }

  ${mediaQueries.forDesktopUp} {
    padding: 10px 16px;
    height: 66px;
    margin-top: 30px;
    width: 101px;
    justify-self: start;
    align-self: start;
  }
`;

const CollectionLabel = styled.div`
  ${collectionLabelStyles};
  background-color: #f9fafc;
  margin: 15px 0 26px;

  ${mediaQueries.forTabletVerticalUp} {
    display: none;
  }

  ${mediaQueries.forDesktopUp} {
    display: block;
  }
`;

const CollectionLabelTablet = styled.div`
  ${collectionLabelStyles};
  display: none;
  background-color: ${colors.white};
  margin: 0 0 8px;

  ${mediaQueries.forTabletVerticalUp} {
    display: block;
  }

  ${mediaQueries.forDesktopUp} {
    display: none;
  }
`;

export {
  ChartsWrapper,
  HeadingWrapper,
  FlexWrapper,
  ChartGridWrapper,
  MarkerChartWrapper,
  MarkerChartContainer,
  ReactivityBadgeWrapper,
  ReactivityBadgeFlexWrapper,
  MarkerName,
  LightText,
  CollectionLabel,
  CollectionLabelTablet,
};
