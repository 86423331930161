/**
 *
 * MultiNumericalMarkerCard
 *
 */

import { Localize } from '@everlywell/leaves';
import { CardContainer } from 'common/styles/app-styles';
import { formatStringSeparator } from 'common/utils/formatText';
import {
  MarkerWithResult,
  MarkerResult,
  NumericalMarkerContent,
} from 'common/utils/types';
import React from 'react';

import MarkerDetails from '../MarkerDetails';
import MultiNumericalChartRow from './MultiNumericalChartRow';
import * as S from './styles';

export type Props = {
  markersWithResults: MarkerWithResult[];
  content: NumericalMarkerContent;
  outOfRange?: boolean;
  viewingSharedKitResult?: boolean;
};

function renderCharts(props: Props) {
  const { markersWithResults } = props;
  const markersWithResultsLength = markersWithResults.length;

  return markersWithResults.map((markerWithResult: MarkerWithResult, index) => (
    <MultiNumericalChartRow
      markerWithResult={markerWithResult}
      collectionNumber={index}
      addScrollId={index === markersWithResultsLength - 1}
    />
  ));
}

function MultiNumericalMarkerCard(props: Props) {
  const { content, outOfRange = false, markersWithResults } = props;
  const { name, units, order } = markersWithResults[0];

  // only S&S tests from Everlywell DX will have an order property
  // EWDX is not currently sending back an observation_datetime so we must
  // rely on this hardcoded 'order' attribute to properly order based on collection time
  if (order && markersWithResults) {
    markersWithResults.sort(
      (a: MarkerWithResult, b: MarkerWithResult) =>
        Number(a.order) - Number(b.order),
    );
  } else {
    markersWithResults.sort((a: MarkerWithResult, b: MarkerWithResult) => {
      const aObvDate = Number(a.marker_result.observation_datetime);
      const bObvDate = Number(b.marker_result.observation_datetime);
      return aObvDate - bObvDate;
    });
  }

  const markerResult: MarkerResult = markersWithResults[0].marker_result;

  const numberOfResults =
    (markersWithResults && markersWithResults.length) || '';
  const testAttr = formatStringSeparator(name.toLowerCase());

  return (
    <CardContainer data-test={`${testAttr}-marker-wrapper`}>
      <S.ChartsWrapper>
        <S.HeadingWrapper>
          <S.MarkerName data-test={`${testAttr}-marker-name`}>
            <Localize name="MultiNumericalMarkerCard-name">{name}</Localize>
          </S.MarkerName>
          <S.LightText data-test={`${testAttr}-marker-units`}>
            measured in{' '}
            <Localize name="MultiNumericalMarkerCard-units">{units}</Localize>
          </S.LightText>
        </S.HeadingWrapper>
        <S.ReactivityBadgeFlexWrapper>
          <S.FlexWrapper>
            <S.LightText>Multiple Collections</S.LightText>
            <S.LightText>
              <Localize name="MultiNumericalMarkerCard-numberOfResults">
                {numberOfResults}
              </Localize>
            </S.LightText>
          </S.FlexWrapper>
        </S.ReactivityBadgeFlexWrapper>
        {renderCharts(props)}
      </S.ChartsWrapper>
      <MarkerDetails
        name={name}
        content={content}
        detailsExpanded={outOfRange}
        markerValue={
          markerResult ? markerResult.value.toString().toLocaleLowerCase() : ''
        }
      />
    </CardContainer>
  );
}

export default MultiNumericalMarkerCard;
