import { breakpoints } from '@everlywell/leaves';
import useMediaQuery from 'common/hooks/useMediaQuery';
import React, { useEffect, useState, ReactNode } from 'react';

import * as S from './styles';

const MIN_DISTANCE_FROM_EDGE_MOBILE = 20;
const MIN_DISTANCE_FROM_EDGE = 7;
const BREAKPOINT = `(max-width: ${breakpoints.forTabletHorizontalDown}px)`;

export type Props = {
  children: ReactNode;
  isClassStyle: boolean;
  markerResultPosition: number;
  rangeColors?: any;
  severityIndex: number;
};

export const MarkerBadge = (props: Props) => {
  const {
    children,
    isClassStyle,
    markerResultPosition,
    rangeColors,
    severityIndex,
  } = props;

  const [position, setPosition] = useState('center');

  const isMobile = useMediaQuery(BREAKPOINT);

  const getIsClassMinDistance = () =>
    isMobile ? MIN_DISTANCE_FROM_EDGE_MOBILE : MIN_DISTANCE_FROM_EDGE;

  // Calculate the allowed min distance to the chart edges
  const minDistance = isClassStyle
    ? getIsClassMinDistance()
    : MIN_DISTANCE_FROM_EDGE;

  useEffect(() => {
    const alignMarkerBadge = (markerPosition: number) => {
      if (markerPosition <= minDistance) {
        setPosition('flex-start');
      } else if (markerPosition >= 100 - minDistance) {
        setPosition('flex-end');
      } else {
        setPosition('center');
      }
    };
    alignMarkerBadge(markerResultPosition);
  }, [markerResultPosition, minDistance]);

  return (
    <S.ResultMarkerWrapper
      isClassStyle={isClassStyle}
      data-test="marker-value-badge"
      percentage={markerResultPosition}
    >
      <S.ResultMarker alignItems={position}>
        <S.ResultMarkerBadge
          color={rangeColors[severityIndex]}
          isClassStyle={isClassStyle}
        >
          {children}
        </S.ResultMarkerBadge>
      </S.ResultMarker>
    </S.ResultMarkerWrapper>
  );
};
