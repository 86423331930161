import {
  size,
  colors,
  typography,
  Icons,
  mediaQueries,
} from '@everlywell/leaves';
import styled, { css } from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${size.lg}px 0;

  ${mediaQueries.forTabletVerticalUp} {
    margin: ${size.xl2}px 0;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${size.md}px;
  color: ${colors.teal5};

  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Header = styled.h3`
  ${typography.h4Text};
  color: ${colors.teal5};
  margin: 0;
`;

export const PrescriptionNumber = styled.span`
  ${typography.bodyText};
  color: ${colors.gray5};
`;

export const ExpandLink = styled.a`
  ${typography.buttonTextSmall};
  color: ${colors.green4};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${size.xs1}px;
`;

export const ChevronIcon = styled(Icons.DownSolidCaret)<{
  isExpanded: boolean;
}>`
  display: inline-block;
  width: 17px;
  transition: transform ease 0.5s;
  transform: rotate(${({ isExpanded }) => (isExpanded ? 180 : 0)}deg);
`;

export const RemainingRxContainer = styled.div<{
  isExpanded: boolean;
}>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
  max-height: 0;
  ${({ isExpanded }) =>
    isExpanded
      ? css`
          transition: max-height 1s ease-in-out;
          /**
             Animating the height from a specific value to auto using CSS animations is not straightforward
            since auto is not an animatable value. However, we can achieve a similar effect by
            animating the maximum height of the div instead and setting the max height to a large value.
          */
          max-height: 10000px;
          /* 
            Each element inside of this container will be a prescription accordion. The prescription accordion 
            has a box shadow. In order to make the box shadow visible, we need to add a negative margin and 
            padding with the same value. This step is necessary because the overflow is hidden, therefore 
            it would hide the box-shadow.
          */
          margin: -${size.md}px;
          padding: ${size.md}px;
        `
      : css``};
`;
