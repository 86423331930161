/**
 *
 * ResultsIntro
 *
 */

import { Localize } from '@everlywell/leaves';
import { TrackVisibilityWrapper } from 'common/utils/analytics';
import { PUBLIC_APP_ROOT } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { INTRO as DATA_TEST } from 'common/utils/constants/dataTest';
import { format, parseISO } from 'date-fns';
import React from 'react';

import IntroContent from './IntroContent';
import * as S from './styles';

export const QUEST_TOOLTIP = 'View full lab details by downloading lab results';

export const QUEST_LAB = 'Quest Diagnostics';

export type Props = {
  name?: string;
  introHeader?: string;
  intro?: string;
  totalAbnormal: number;
  markerType: string;
  resultsApprovedDate?: string;
  introScrollToContent: string;
  testId: number;
  testName: string;
  testType: string;
  isThirdParty: boolean;
  labName: string;
  clia: string;
  conditions?: string[];
  headerIcon?: string;
};

function ResultsIntro(props: Props) {
  const {
    name,
    introHeader,
    intro,
    labName,
    resultsApprovedDate,
    totalAbnormal,
    markerType,
    introScrollToContent,
    testId,
    testName,
    testType,
    isThirdParty,
    clia,
    conditions,
    headerIcon,
  } = props;

  const isQuestLab = labName.toLowerCase().trim() === QUEST_LAB.toLowerCase();

  return (
    <S.GridWrapper data-test="introduction">
      <TrackVisibilityWrapper label={ANALYTICS.LABELS.RESULTS_INTRO}>
        <div>
          <S.Wrapper>
            <img src={headerIcon} alt="section icon" />
            <S.ResultsIntro>
              <S.Welcome data-test={DATA_TEST.NAME}>
                Hi, <Localize name="user-firstname">{name}</Localize>
              </S.Welcome>
              <S.IntroHeader data-test={DATA_TEST.HEADER}>
                {introHeader}
              </S.IntroHeader>
              {intro && (
                <IntroContent
                  intro={intro}
                  totalAbnormal={totalAbnormal}
                  markerType={markerType}
                  introScrollToContent={introScrollToContent}
                  testId={testId}
                  testName={testName}
                  testType={testType}
                  conditions={conditions}
                />
              )}
              <S.LabInfo data-test={DATA_TEST.TEST_INFO}>
                <S.TooltipWrapper data-test={DATA_TEST.TOOLTIP}>
                  Your results were released by{' '}
                  <Localize name="lab-name">
                    <S.CliaTooltip
                      content={isQuestLab ? QUEST_TOOLTIP : `CLIA ID: ${clia}`}
                      position="top"
                      animationSpeed="normal"
                      arrow="right"
                      tooltipBoxClass="tooltip-box"
                    >
                      <S.InfoWrapper>
                        <span data-test={DATA_TEST.LAB_NAME}>{labName} </span>
                        <S.LabIcon />
                      </S.InfoWrapper>
                    </S.CliaTooltip>
                  </Localize>
                  {resultsApprovedDate && (
                    <span data-test={DATA_TEST.RELEASE_DATE}>
                      {' on '}
                      <Localize name="results-approved-date">
                        {format(parseISO(resultsApprovedDate), 'MM/dd/yy')}
                      </Localize>
                    </span>
                  )}
                  .
                </S.TooltipWrapper>
                {!isThirdParty && (
                  <S.ScienceLink
                    data-test={DATA_TEST.SCIENCE_LINK}
                    target="_blank"
                    href={`${PUBLIC_APP_ROOT}/science/`}
                  >
                    Learn more about our science.
                  </S.ScienceLink>
                )}
              </S.LabInfo>
            </S.ResultsIntro>
          </S.Wrapper>
        </div>
      </TrackVisibilityWrapper>
    </S.GridWrapper>
  );
}

export default ResultsIntro;
