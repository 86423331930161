import Grid from 'components/Grid/Grid';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormBuilderField } from '../../utils/types';
import * as S from './TextAreaField.styles';

export const REQUIRED_ERROR = 'Please fill in this field';

export type TextareaFieldProps = FormBuilderField;

/**
 * TextArea Field
 */
function TextareaField(props: TextareaFieldProps) {
  const { control, errors } = useFormContext();

  return (
    <Grid.Item width={[1]}>
      <Controller
        control={control}
        name={props.id}
        defaultValue=""
        rules={{
          required: props.required && REQUIRED_ERROR,
        }}
        render={({ onChange, value, ref }) => (
          <S.Textarea
            ref={ref}
            variant={props.variant}
            label={props.label ?? ''}
            id={props.id}
            name={props.id}
            error={errors[props.id]?.message}
            onChange={onChange}
            value={value}
            required={props.required}
          />
        )}
      />
    </Grid.Item>
  );
}

export default TextareaField;
