import { Checkbox, formUtils } from '@everlywell/leaves';
import { useInteractionTracker } from 'common/hooks/useInteractionTracker';
import { WWW_APP_ROOT } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import {
  DATA_TEST_WEIGHT_MANAGEMENT,
  LEGAL,
} from 'common/utils/constants/dataTest';
import { isZipCodeNotInNy } from 'common/utils/formHelpers';
import { zipcodeCleaningValidation } from 'common/utils/helpers';
import { ProgramsAtCheckout } from 'common/utils/types';
import Grid from 'components/Grid';
import CardDetailsStripe from 'components/StripeComponent/CardDetailsStripe';
import React, { useState, useEffect, Ref, SyntheticEvent } from 'react';
import { FieldError } from 'react-hook-form';

import * as S from '../Payment.styles';

type Props = {
  register<T>(validationConfig?: {
    required?: string | boolean;
    pattern?: {
      value: RegExp;
      message: string;
    };
    validate?: any;
  }): Ref<T> | undefined;
  errors: {
    [key: string]: FieldError;
  };
  onMarketingOptIn: (isChecked: boolean) => void;
  setHasAgreedToLegalTerms: (checked: boolean) => void;
  blockNewYork?: boolean;
  program: ProgramsAtCheckout;
};

const DEFAULT_PROGRAM_TERMS_LINK = `${WWW_APP_ROOT}/program-terms`;

const PROGRAM_TERMS_LINKS: Record<ProgramsAtCheckout, string> = {
  'weight-management-full': DEFAULT_PROGRAM_TERMS_LINK,
  'weight-management': DEFAULT_PROGRAM_TERMS_LINK,
  'everlywell-plus': `${WWW_APP_ROOT}/everlywell-plus-program-terms`,
};

const PaymentDetails = ({
  errors,
  onMarketingOptIn,
  register,
  setHasAgreedToLegalTerms,
  blockNewYork,
  program,
}: Props): JSX.Element => {
  const trackFormInteraction = useInteractionTracker();
  const [marketingIsChecked, setMarketingIsChecked] = useState<boolean>(true);
  const [termsAndConditionsIsChecked, setTermsAndConditionsIsChecked] =
    useState<boolean>(false);
  const [programTermsIsChecked, setProgramTermsIsChecked] =
    useState<boolean>(false);

  const handleChecked = (name: string) => {
    switch (name) {
      case LEGAL.TERMS_AND_CONDITIONS:
        setTermsAndConditionsIsChecked((prevState) => !prevState);
        break;
      case LEGAL.PROGRAM_TERMS:
        setProgramTermsIsChecked((prevState) => !prevState);
        break;
      case LEGAL.MARKETING:
        setMarketingIsChecked((prevState) => !prevState);
        break;
      default:
        return;
    }
  };

  const handleFocus = async () => {
    await trackFormInteraction({
      event: ANALYTICS.EVENTS.FORM_INTERACTION,
      data: {
        component: ANALYTICS.LABELS.WEIGHT_CARE.ENROLLMENT.PAYMENT_INFO,
      },
    });
  };

  useEffect(() => {
    onMarketingOptIn(marketingIsChecked);
  }, [marketingIsChecked, onMarketingOptIn]);

  useEffect(() => {
    if (termsAndConditionsIsChecked && programTermsIsChecked) {
      setHasAgreedToLegalTerms(true);
    } else {
      setHasAgreedToLegalTerms(false);
    }
  }, [
    termsAndConditionsIsChecked,
    programTermsIsChecked,
    setHasAgreedToLegalTerms,
  ]);

  return (
    <S.Container
      data-testid={DATA_TEST_WEIGHT_MANAGEMENT.PAYMENT_INFO.CHECKOUT_CONTAINER}
    >
      <Grid.Container
        spacing={['md', 'md', 'lg']}
        data-test={
          DATA_TEST_WEIGHT_MANAGEMENT.PAYMENT_INFO.PAYMENT_INPUTS_CONTAINER
        }
      >
        <CardDetailsStripe handleFocus={handleFocus} />

        <Grid.Item width={[1]}>
          <S.ZipCodeInput
            data-test="zipCode-input"
            id="zipCode"
            name="zipCode"
            label="ZIP Code"
            placeholder="12345"
            ref={register<HTMLInputElement>({
              required: 'Please enter a ZIP code',
              validate: {
                validZipCode: (value: any) =>
                  formUtils.validateZipCode(value) ||
                  'Please enter a valid ZIP code',
                validateNewYorkZipCode: (value: any) =>
                  !blockNewYork ||
                  isZipCodeNotInNy(value) ||
                  'The program is not available for New York residents at this time',
              },
            })}
            error={errors.zipCode && errors.zipCode.message}
            onChange={(e: SyntheticEvent<HTMLInputElement>) => {
              const target = e.target as HTMLInputElement;
              const zipcode = zipcodeCleaningValidation(target.value);
              target.value = zipcode;
            }}
            onFocus={handleFocus}
          />
        </Grid.Item>

        <Grid.Container spacing={['none']}>
          <Grid.Item width={[1]}>
            <Checkbox
              checked={termsAndConditionsIsChecked}
              data-testid={
                DATA_TEST_WEIGHT_MANAGEMENT.PAYMENT_INFO.TERMS_AND_CONDITIONS
              }
              name={LEGAL.TERMS_AND_CONDITIONS}
              onChange={() => handleChecked(LEGAL.TERMS_AND_CONDITIONS)}
              label={
                <span>
                  I agree to and acknowledge the{' '}
                  <S.Link href={`${WWW_APP_ROOT}/terms-of-use`} target="_blank">
                    Terms and Conditions
                  </S.Link>
                </span>
              }
              uid={LEGAL.TERMS_AND_CONDITIONS}
              bodyTextStyle={true}
            />
          </Grid.Item>
          <Grid.Item width={[1]}>
            <Checkbox
              checked={programTermsIsChecked}
              data-testid={
                DATA_TEST_WEIGHT_MANAGEMENT.PAYMENT_INFO.PROGRAM_TERMS
              }
              name={LEGAL.PROGRAM_TERMS}
              onChange={() => handleChecked(LEGAL.PROGRAM_TERMS)}
              label={
                <span>
                  I agree and acknowledge the{' '}
                  <S.Link
                    href={
                      PROGRAM_TERMS_LINKS[program] || DEFAULT_PROGRAM_TERMS_LINK
                    }
                    target="_blank"
                  >
                    Program Terms
                  </S.Link>
                </span>
              }
              uid={LEGAL.PROGRAM_TERMS}
              bodyTextStyle={true}
            />
          </Grid.Item>
          <Grid.Item width={[1]}>
            <Checkbox
              checked={marketingIsChecked}
              data-testid={
                DATA_TEST_WEIGHT_MANAGEMENT.PAYMENT_INFO.OPT_IN_CHECKBOX
              }
              name={LEGAL.MARKETING}
              onChange={() => handleChecked(LEGAL.MARKETING)}
              label="I agree to customized suggestions and content for Everlywell marketing purposes. We will never spam you!"
              uid={LEGAL.MARKETING}
              bodyTextStyle={true}
            />
          </Grid.Item>
        </Grid.Container>
      </Grid.Container>
    </S.Container>
  );
};

export default PaymentDetails;
