import { Col } from '@everlywell/leaves';
import { sanitize } from 'common/utils/domHelpers';
import React from 'react';

import * as S from './styles';

export type Props = {
  backgroundSrc?: string;
  description: string;
  header: string;
  headerIcon?: string;
  noPaddingBottom?: boolean;
  noTopBorder?: boolean;
};

function NewLayoutSectionHeader(props: Props) {
  const { backgroundSrc, description, header, headerIcon, noTopBorder } = props;

  return (
    <S.NewLayoutSectionHeaderWrapper
      backgroundSrc={backgroundSrc}
      noTopBorder={noTopBorder}
    >
      <img src={headerIcon} alt="section icon" />
      <Col lg={10} md={12} xs={12}>
        <S.Header dangerouslySetInnerHTML={{ __html: sanitize(header) }} />
        <div>
          <S.Description
            dangerouslySetInnerHTML={{ __html: sanitize(description) }}
          />
        </div>
      </Col>
    </S.NewLayoutSectionHeaderWrapper>
  );
}

export default NewLayoutSectionHeader;
