import { KitResult } from 'common/utils/types';
import KitList, { sortKitsResults } from 'components/KitList';
import RegisterKitHero from 'components/RegisterKitHero';
import SkeletonTestResults from 'components/SkeletonTestResults';
import React from 'react';

import Facts from './Facts';

type KitListResultsProps = {
  loading: boolean;
  inProgressKitResults: KitResult[];
  completedKitResults: KitResult[];
};

function KitListResults({
  loading,
  inProgressKitResults,
  completedKitResults,
}: KitListResultsProps): JSX.Element {
  if (loading) {
    return <SkeletonTestResults />;
  }

  const hasKits = inProgressKitResults.length || completedKitResults.length;

  if (!hasKits) {
    return <RegisterKitHero />;
  }

  const sortedInprogress = sortKitsResults(inProgressKitResults);
  const sortedCompleted = sortKitsResults(completedKitResults);

  return (
    <>
      <KitList
        kitResults={sortedInprogress}
        heading="IN PROGRESS"
        testLabel="inprogress-results"
      />
      <KitList
        kitResults={sortedCompleted}
        heading="COMPLETED"
        testLabel="completed-results"
      />
      <Facts />
    </>
  );
}

export default KitListResults;
