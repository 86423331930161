import { Col } from '@everlywell/leaves';
import { useAnswers } from 'common/providers/AnswersProvider';
import { HEALTH_PROFILE_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';
import { isValidInput, stickyUnit } from 'common/utils/helpers';
import { QuestionProps } from 'common/utils/types';
import React, { useState, useEffect } from 'react';

import { QuestionFooter } from '..';

import { setResponse } from '../../helpers';
import * as S from '../styles';

const HealthProfileHeightQuestion = ({
  questionnaireId,
  questionData,
}: QuestionProps) => {
  const {
    content,
    description,
    question_options: options,
    questionnaire_question_id: questionId,
  } = questionData;
  const { allAnswers } = useAnswers();
  const [feetValue, setFeetValue] = useState(0);
  const [inchesValue, setInchesValue] = useState(0);
  const [feetError, setFeetError] = useState('');
  const [inchesError, setInchesError] = useState('');
  const [displayFeetValue, setDisplayFeetValue] = useState('');
  const [displayInchesValue, setDisplayInchesValue] = useState('');

  useEffect(() => {
    // If question has already been answered, retrieve value on mount and insert into UI
    if (allAnswers[questionId]?.value) {
      const totalInches = allAnswers[questionId].value;
      const feetInitialValue = Math.floor(totalInches / 12);
      const inchesInitialValue = totalInches % 12;
      setFeetValue(feetInitialValue);
      setInchesValue(inchesInitialValue);
      setDisplayFeetValue(feetInitialValue ? `${feetInitialValue} ft` : '');
      setDisplayInchesValue(
        inchesInitialValue ? `${inchesInitialValue} in` : '',
      );
    }
  }, [allAnswers, questionId]);

  const rangeValidations = (inchesValue: number, feetValue: number) => {
    if (feetValue === 0 && inchesValue >= 0)
      setFeetError('Please input ft value');
    if (feetValue === 96 && inchesValue > 1)
      setInchesError('Maxium height accepted is 8 ft 1 in');
    if (feetValue === 24 && inchesValue < 6)
      setInchesError('Minimum height accepted is 2 ft 6 in');
  };

  const handleFeetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.replace(/[^\d]/g, '');
    stickyUnit(e);
    const num = Number(val);
    const checkError: string = isValidInput(num, 2, 8, 'ft');
    setFeetError(checkError);
    const value = num === 0 ? '' : `${num} ft`;

    setDisplayFeetValue(value);
    setFeetValue(num);
  };

  const handleInchesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.replace(/[^\d]/g, '');
    stickyUnit(e);
    const num = parseInt(val, 10);
    const checkError: string = isValidInput(num, 0, 11, 'in');
    setInchesError(checkError);
    rangeValidations(num, feetValue);

    const value =
      // eslint-disable-next-line no-restricted-globals
      isNaN(num) || num > 11 ? '' : `${num} in`;

    setDisplayInchesValue(value);
    setInchesValue(num || 0);
  };

  return (
    <S.QuestionContainer data-test={`${DATA_TEST.CONTAINER}-height-question`}>
      <Col>
        <S.Question>{content}</S.Question>
        {description ? <S.SubText>{description}</S.SubText> : null}
      </Col>
      <Col>
        <S.HeightWrapper>
          <S.HeightInput
            label="Feet"
            type="text"
            placeholder="5 ft"
            value={displayFeetValue}
            inputMode="numeric"
            id="Feet"
            name="Feet"
            error={feetError}
            onChange={(e) => handleFeetChange(e)}
            data-test={`${DATA_TEST.INPUT}-feet-input`}
          />
          <S.HeightInput
            label="Inches"
            type="text"
            placeholder="8 in"
            inputMode="numeric"
            value={displayInchesValue}
            id="Inches"
            name="Inches"
            error={inchesError}
            onChange={(e) => handleInchesChange(e)}
            data-test={`${DATA_TEST.INPUT}-inches-input`}
          />
        </S.HeightWrapper>
      </Col>

      <QuestionFooter
        questionnaireId={questionnaireId}
        questionData={questionData}
        answer={feetValue * 12 + inchesValue}
        error={feetError || inchesError}
        setResponse={() =>
          setResponse(allAnswers, questionId, {
            options,
            value: feetValue * 12 + inchesValue,
          })
        }
      />
    </S.QuestionContainer>
  );
};

export default HealthProfileHeightQuestion;
