import { ThumbsDown, ThumbsUp } from '@everlywell/leaves';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { PERSONALIZATION_CHECK_IN as DATA_TEST } from 'common/utils/constants/dataTest';
import React, { useState, useEffect, useRef } from 'react';
import { animated, config, easings, useSpring } from 'react-spring';

import * as S from './styles';

interface Props {
  widget: string;
  hasToolTip: boolean;
  className?: string;
}

const PersonalizationCheckIn = ({ widget, hasToolTip, className }: Props) => {
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [hasClicked, setHasClicked] = useLocalStorage(
    `${widget}-viewed`,
    false,
  );
  const [showFeedbackMessage, setShowFeedbackMessage] = useState(true);

  const contentRef = useRef<HTMLDivElement>(null);

  const handleLiked = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: `Thumbs Up ${widget}`,
      },
    });
    setHasClicked(true);
    setLiked(!liked);
  };

  const handleDisliked = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: `Thumbs Down ${widget}`,
      },
    });
    setHasClicked(true);
    setDisliked(!disliked);
  };

  const sectionAnimation = useSpring({
    to: {
      height: hasToolTip ? 'auto' : !hasClicked ? '50px' : '0px',
      paddingTop: hasToolTip ? '24px' : '0px',
      borderTop: hasClicked ? 'none' : '1px solid #d6ebdd',
      marginTop: hasToolTip ? '24px' : '-4px',
      background: hasToolTip ? 'none' : '#fff',
      maxWidth: hasToolTip ? '1799px' : '',
    },

    config: {
      duration: 200,
      easing: easings.easeInBack,
    },
    delay: 1700,
  });

  const feedbackAnimation = useSpring({
    to: {
      opacity: hasClicked ? 0 : 1,
    },
    delay: 1000,
    config: config.slow,
  });

  const FeedbackMessage = () => {
    useEffect(() => {
      const timer = setTimeout(() => {
        setShowFeedbackMessage(false);
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }, []);

    if (!showFeedbackMessage) {
      return null;
    }
    return (
      <animated.div style={feedbackAnimation}>
        <S.FeedbackMessage>
          <S.CheckMark />
          Thanks for your feedback!
        </S.FeedbackMessage>
      </animated.div>
    );
  };
  return (
    <animated.div style={sectionAnimation}>
      <S.CheckInWrapper
        data-test={DATA_TEST.CHECKIN_WRAPPER}
        className={className}
        ref={contentRef}
      >
        {!hasClicked ? (
          <>
            {hasToolTip ? (
              <S.ToolTipContainer>
                <S.ToolTip>Help us improve!</S.ToolTip>
              </S.ToolTipContainer>
            ) : null}
            <S.TextIconWrapper>
              <S.CheckInQuestion>Did you find this helpful?</S.CheckInQuestion>
              <S.IconsWrapper>
                <S.IconImageWrapper
                  onClick={handleLiked}
                  data-test={DATA_TEST.THUMBS_UP_BUTTON}
                  aria-label="Yes"
                >
                  <ThumbsUp />
                </S.IconImageWrapper>
                <S.IconImageWrapper onClick={handleDisliked} aria-label="No">
                  <ThumbsDown />
                </S.IconImageWrapper>
              </S.IconsWrapper>
            </S.TextIconWrapper>
          </>
        ) : (
          <FeedbackMessage data-test={DATA_TEST.FEEDBACK_MESSAGE} />
        )}
      </S.CheckInWrapper>
    </animated.div>
  );
};

export default PersonalizationCheckIn;
