import { Button } from '@everlywell/leaves';
import Layout from 'components/Layout';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ErrorIcon from './assets/error-icon.svg';
import * as S from './SSOErrorPage.styles';

/**
 * This page is also duplicated used in the native scheduling flow.
 * TODO: Make this error page more reusable and move it to a shared location.
 */
function SSOErrorPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const inMobileWebview = searchParams.get('embedded') === 'true';

  const handleRetry = () => {
    navigate(-3);
  };

  return (
    <Layout>
      <S.Container>
        <S.ImageContainer>
          <img src={ErrorIcon} alt="" />
        </S.ImageContainer>
        <S.InnerContainer>
          <S.ContentContainer>
            <S.CardHeader>
              Sorry, we're currently experiencing technical issues.
            </S.CardHeader>
            <S.Text>
              Please try again. If the issue continues, contact{' '}
              <S.CustomLink
                href="https://support.everlywell.com/"
                target="_blank"
              >
                Customer Experience
              </S.CustomLink>
              .
            </S.Text>
          </S.ContentContainer>
        </S.InnerContainer>
        {!inMobileWebview && (
          <S.CTAContainer>
            <Button appearance="primary" onClick={handleRetry}>
              Okay
            </Button>
          </S.CTAContainer>
        )}
      </S.Container>
    </Layout>
  );
}

export default SSOErrorPage;
