import { TrackVisibilityWrapper } from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { ImmutableReduxState } from 'common/utils/types';
import NewLayoutSectionHeader, {
  Props as NewLayoutSectionHeaderProps,
} from 'components/NewLayoutSectionHeader';
import React from 'react';
import { connect } from 'react-redux';
import { makeSelectContent } from 'store/selectors';

const CONTENT_KEYS = {
  header: 'WHATS_NEXT_SECTION_HEADER',
  description: 'WHATS_NEXT_SECTION_DESCRIPTION',
};

type OwnProps = {
  backgroundSrc?: string;
  contentToken: string;
  headerIcon?: string;
  noTopBorder?: boolean;
};

export function WhatsNextSectionHeaderContainer(
  props: NewLayoutSectionHeaderProps,
) {
  const hasContent = !!props.header;

  if (!hasContent) {
    return null;
  }

  return (
    <TrackVisibilityWrapper label={ANALYTICS.LABELS.WHATS_NEXT}>
      <NewLayoutSectionHeader {...props} />
    </TrackVisibilityWrapper>
  );
}

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): NewLayoutSectionHeaderProps {
  return makeSelectContent(ownProps.contentToken, CONTENT_KEYS)(state);
}

export default connect(mapStateToProps)(WhatsNextSectionHeaderContainer);
