import { TelehealthIntakeForm } from 'common/apis/telehealthApis';
import FormPageBuilder from 'pages/FormPageBuilder';
import React from 'react';

import { NativeIntakeContextProviderProps } from '../context/NativeIntakeContext';
import { FormsMapConfig } from './config';
import { INTAKE_FLOW_ENTRY_PATH } from './constants';

export const getFormsConfig = (
  intakeForms: TelehealthIntakeForm[] | null | undefined,
  formsMap: FormsMapConfig,
) => {
  if (!intakeForms) return [];

  const forms = intakeForms
    .sort(
      (intakeFormA, intakeFormB) => intakeFormA.position - intakeFormB.position,
    )
    .map((intakeForm) => {
      const {
        program_slug: programSlug,
        slug: formSlug,
        form_type: formType,
      } = intakeForm;
      const formConfig = formsMap[formSlug];

      return {
        ...(intakeForm as TelehealthIntakeForm),
        pathname: formConfig?.pathname ?? intakeForm.slug,
        component:
          formConfig?.component ??
          (() => (
            <FormPageBuilder
              formType={formType}
              formSlug={formSlug}
              programSlug={programSlug}
            />
          )),
      } as NativeIntakeContextProviderProps['forms'][0];
    });

  return forms;
};

export const getRelativePath = (pathname: string) => {
  if (!pathname) return INTAKE_FLOW_ENTRY_PATH;

  return `${INTAKE_FLOW_ENTRY_PATH}/${pathname}`;
};

export const matchUrlPattern = (url: string, basePattern: string) => {
  const pattern = new RegExp(`^${basePattern}/?$`, 'i');
  return pattern.test(url);
};
