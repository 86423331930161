import { Col } from '@everlywell/leaves';
import React from 'react';

import NasalSwabCollectionChecklist from './checklist';
import { CONTENT } from './content';
import * as S from './styles';

const NasalSwabCollection = () => (
  <S.Wrapper>
    <Col
      xs={10}
      md={8}
      lg={6}
      xl={4}
      xsOffset={1}
      mdOffset={2}
      lgOffset={3}
      xlOffset={4}
    >
      <S.Title>{CONTENT.TITLE}</S.Title>
    </Col>
    <Col xs={10} md={8} lg={4} xsOffset={1} mdOffset={2} lgOffset={4}>
      <NasalSwabCollectionChecklist
        title={CONTENT.DO_LIST.TITLE}
        items={CONTENT.DO_LIST.ITEMS}
      />
    </Col>
    <Col xs={10} md={8} lg={4} xsOffset={1} mdOffset={2} lgOffset={4}>
      <NasalSwabCollectionChecklist
        title={CONTENT.DO_NOT_LIST.TITLE}
        items={CONTENT.DO_NOT_LIST.ITEMS}
      />
    </Col>
  </S.Wrapper>
);

export default NasalSwabCollection;
