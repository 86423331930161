import { size, typography, H4, Button } from '@everlywell/leaves';
import styled from 'styled-components';

const Title = styled(H4)`
  width: 100%;
  height: auto;
  margin-bottom: ${size.xs1}px;
  text-align: left;
`;

const Content = styled.p`
  ${typography.bodyTextSmall}
  width: 100%;
  height: auto;
  margin-bottom: ${size.xl5}px;
  text-align: left;
`;

const ContinueButton = styled(Button)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export { Title, Content, ContinueButton };
