import ADX1 from '../assets/sample-collection-tips-illustrations-adx-1.svg';
import ADX2 from '../assets/sample-collection-tips-illustrations-adx-2.svg';
import ADX3 from '../assets/sample-collection-tips-illustrations-adx-3.svg';
import ADX4 from '../assets/sample-collection-tips-illustrations-adx-4.svg';

const ADXSteps = [
  {
    id: '1',
    image: ADX1,
    alt:
      'Write your name, date of birth, and collection date on the card. The lab cannot process your sample if this step is skipped.',
    description:
      'Write your name, date of birth, and collection date on the card. The lab cannot process your sample if this step is skipped.',
  },
  {
    id: '2',
    image: ADX2,
    alt:
      'Let 4-7 drops of blood fall onto only the square on the left, not the rectangle on the right.',
    description:
      'Let 4-7 drops of blood fall onto only the square on the left, not the rectangle on the right.',
  },
  {
    id: '3',
    image: ADX3,
    alt:
      'Continue adding drops until the blood reaches the designated line on the right. Blood will soak through and naturally expand.',
    description:
      'Continue adding drops until the blood reaches the designated line on the right. Blood will soak through and naturally expand.',
  },
  {
    id: '4',
    image: ADX4,
    alt:
      'Once blood expands to the first line on the right hand side of the card, let sample card dry on a flat surface until completely dry, about an hour.',
    description:
      'Once blood expands to the first line on the right hand side of the card, let sample card dry on a flat surface until completely dry, about an hour.',
  },
];

export { ADXSteps };
