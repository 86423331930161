import { Localize } from '@everlywell/leaves';
import { CardContainer } from 'common/styles/app-styles';
import { MARKER_TYPES, MARKER_VALUE_EXCEPTIONS } from 'common/utils/constants';
import { formatStringSeparator } from 'common/utils/formatText';
import { getRGBRangeColors } from 'common/utils/helpers';
import { MarkerWithResult, NumericalMarkerContent } from 'common/utils/types';
import NewMarkerChartWrapper from 'components/NewMarkerChartWrapper';
import * as React from 'react';

import MarkerDetails from '../MarkerDetails';
import ReactivityBadge from '../ReactivityBadge';
import * as S from './styles';

type Props = {
  markersWithResults: MarkerWithResult[];
  content: NumericalMarkerContent;
  outOfRange?: boolean;
  viewingSharedKitResult?: boolean;
};

function NumericalMarkerCard(props: Props) {
  const {
    markersWithResults,
    content,
    outOfRange = false,
    viewingSharedKitResult,
  } = props;
  const markerWithResult = { ...markersWithResults[0] };
  const { name, units, marker_result: markerResult } = markerWithResult;

  const dataTest = formatStringSeparator(`${name.toLowerCase()}`);

  return (
    <>
      <CardContainer data-test={`${dataTest}-marker-card`} id={`${name}-card`}>
        <S.NumericalMarkerResultWrap>
          <S.NumericalMarkerResult data-test={`${dataTest}-marker-result`}>
            <S.MarkerNameAndUnitContainer
              isPlainNumberMarkerType={
                markerWithResult.type === MARKER_TYPES.PLAIN_NUMBER
              }
            >
              <S.MarkerName>{name}</S.MarkerName>
              <S.MarkerUnits>
                {units ? (
                  <>
                    measured in{' '}
                    <Localize name="NumericalMarkerCard-units">
                      {units}
                    </Localize>
                  </>
                ) : null}
              </S.MarkerUnits>
            </S.MarkerNameAndUnitContainer>
            {markerWithResult.type !== MARKER_TYPES.PLAIN_NUMBER ? (
              <div data-test={`${dataTest}-marker-chart`}>
                <NewMarkerChartWrapper
                  marker={markerWithResult}
                  markerResult={markerResult}
                  rangeColors={getRGBRangeColors(markerWithResult)}
                  excludedMarkerValues={MARKER_VALUE_EXCEPTIONS}
                />
              </div>
            ) : null}
          </S.NumericalMarkerResult>
          <S.ReactivityBadgeWrap>
            <ReactivityBadge
              marker={markerWithResult}
              dataTest={`${dataTest}-reactivity-badge`}
              markerResult={markerResult}
              markerLabel="Your Level"
              viewingSharedKitResult={viewingSharedKitResult}
              showMarkerValue
              showRangeInfo
              showComparison
            />
          </S.ReactivityBadgeWrap>
        </S.NumericalMarkerResultWrap>
        <MarkerDetails
          name={name}
          content={content}
          detailsExpanded={outOfRange}
          markerValue={
            markerResult
              ? markerResult.value.toString().toLocaleLowerCase()
              : ''
          }
          markerWithResult={markerWithResult}
          viewingSharedKitResult={viewingSharedKitResult}
        />
      </CardContainer>
    </>
  );
}

export default NumericalMarkerCard;
