import { size, breakpoints } from '@everlywell/leaves';
import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

const _breakpoints = [
  0,
  breakpoints.forTabletVerticalUp,
  breakpoints.forDesktopUp,
  breakpoints.forBigDesktopUp,
];

const InternalSize = { ...size, none: '0' };

export type Spacing = Exclude<keyof typeof InternalSize, 'code'>;
export type GridContainerProps = {
  spacing:
    | Spacing[]
    | {
        spacingX: Spacing[];
        spacingY: Spacing[];
      };
  alignEdges?: boolean;
  as?: React.ElementType | string;
};
export const GridContainerInternal = styled.div<GridContainerProps>`
  display: flex;
  flex-wrap: wrap;
  ${(props) => {
    const { spacing, alignEdges = true } = props;
    const spacingX = Array.isArray(spacing) ? spacing : spacing.spacingX;
    const spacingY = Array.isArray(spacing) ? spacing : spacing.spacingY;

    const maxSpacingLength = Math.max(spacingX.length, spacingY.length);

    const styling = [...new Array(maxSpacingLength).fill(undefined)].map(
      (_, index) => {
        const valueX = spacingX[index] || spacingX[spacingX.length - 1];
        const valueY = spacingY[index] || spacingY[spacingY.length - 1];
        const sizeValueX = valueX ? InternalSize[valueX] : 0;
        const sizeValueY = valueY ? InternalSize[valueY] : 0;

        const sizeStyling = `
        padding: ${sizeValueY}px 0px 0px ${sizeValueX}px;
        margin: ${
          alignEdges ? `-${sizeValueY}px -${sizeValueX}px` : 'inherit'
        }};
      `;

        if (index === 0) {
          return sizeStyling;
        } else {
          return `
          @media (min-width: ${_breakpoints[index]}px) {
            ${sizeStyling}
          }
        `;
        }
      },
    );
    return styling.join('');
  }}

  flex-grow: 1;
`;

export const GridItemInternal = styled.div<{
  spacing:
    | Spacing[]
    | {
        spacingX: Spacing[];
        spacingY: Spacing[];
      };
  width: Array<number | 'auto'>;
}>`
  ${({ width }) => {
    const styling = width.map((widthValue, index) => {
      const widthStyling = `
        width:${
          widthValue === 'auto'
            ? 'auto'
            : widthValue <= 1
            ? widthValue * 100
            : widthValue
        }${widthValue === 'auto' ? '' : '%'};
        flex-grow: ${widthValue === 'auto' ? 1 : 0};
      `;
      if (index === 0) {
        return widthStyling;
      } else {
        return `
          @media (min-width: ${_breakpoints[index]}px) {
            ${widthStyling}
          }
        `;
      }
    });
    return styling.join('');
  }}

  ${({ spacing }) => {
    const spacingX = Array.isArray(spacing) ? spacing : spacing.spacingX;
    const spacingY = Array.isArray(spacing) ? spacing : spacing.spacingY;

    const maxSpacingLength = Math.max(spacingX.length, spacingY.length);

    const styling = [...new Array(maxSpacingLength).fill(undefined)].map(
      (_, index) => {
        const valueX = spacingX[index] || spacingX[spacingX.length - 1];
        const valueY = spacingY[index] || spacingY[spacingY.length - 1];
        const sizeValueX = valueX ? InternalSize[valueX] : 0;
        const sizeValueY = valueY ? InternalSize[valueY] : 0;

        const sizeStyling = `
        padding: 0px ${sizeValueX}px ${sizeValueY}px 0px;
      `;
        if (index === 0) {
          return sizeStyling;
        } else {
          return `
          @media (min-width: ${_breakpoints[index]}px) {
            ${sizeStyling}
          }
        `;
        }
      },
    );
    return styling.join('');
  }}
`;
