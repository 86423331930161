import {
  InsuranceDetails,
  InsurancePlanDetails,
  InsurancePlanPayerDetails,
} from 'common/apis/telehealthApis';
import { random } from 'lodash';

const baseInsurancePayer: InsurancePlanPayerDetails = {
  id: '1',
  name: 'Blue Cross Blue Shield',
};

const baseInsurancePlanDetails: InsurancePlanDetails = {
  id: '1',
  payer: baseInsurancePayer,
};

export const buildInsurancePayer = (
  overrides?: Partial<InsurancePlanPayerDetails>,
): InsurancePlanPayerDetails => ({
  ...baseInsurancePayer,
  id: random(0, 1000).toString(),
  ...overrides,
});

export const buildInsurancePlanDetails = (
  overrides?: Partial<InsurancePlanDetails>,
): InsurancePlanDetails => ({
  ...baseInsurancePlanDetails,
  id: random(0, 1000).toString(),
  ...overrides,
});

const baseInsuranceDetails: InsuranceDetails = {
  id: '1',
  num: 'AZ123123',
  group_num: '12345',
  priority_type: '',
  insurance_plan: baseInsurancePlanDetails,
};

export const buildInsuranceDetails = (
  overrides?: Partial<InsuranceDetails>,
): InsuranceDetails => ({
  ...baseInsuranceDetails,
  id: random(0, 1000).toString(),
  ...overrides,
});
