import { colors, size, typography, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

type Props = {
  needsReplacement: boolean;
};

const Status = styled.span<Props>`
  text-transform: capitalize;
  margin-bottom: 8px;
  line-height: ${size.lg}px;
  font-weight: ${typography.weight.bold};
  color: ${(props) => (props.needsReplacement ? colors.red3 : colors.teal4)};
  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
  }
`;

export { Status };
