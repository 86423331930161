/* eslint-disable @typescript-eslint/no-var-requires */
import { breakpoints } from '@everlywell/leaves';
import useMediaQuery from 'common/hooks/useMediaQuery';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { CONTENT_CARD } from 'common/utils/constants/dataTest';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import Fade from 'components/Animations/Fade';
import ContentCard, {
  Props as ContentCardProps,
} from 'components/ContentCard/ContentCard';
import React, { useState } from 'react';

import ExpandButton from './ExpandButton';
import * as S from './styles';

export type Props = {
  cards: ContentCardProps[];
};

function ContentCardCollection(props: Props) {
  const { cards } = props;

  const isTablet = useMediaQuery(
    `(min-width: ${breakpoints.forTabletHorizontalUp}px) and (max-width: ${breakpoints.forDesktopUp}px)`,
  );

  const [isOpen, setIsOpen] = useState(false);

  const rowLimit = isTablet ? 2 : 3;

  const firstRowCards = cards.slice(0, rowLimit);
  const expandableCards = cards.slice(rowLimit);

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    setIsOpen(!isOpen);
    e.currentTarget.blur();
  }

  const contentCardsEl = useScrolledIntoViewOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: ANALYTICS.LABELS.CONSIDER_CHANGES,
      },
    });
  });

  return (
    <S.Wrapper>
      <S.Inner ref={contentCardsEl}>
        {firstRowCards.map((card) => (
          <ContentCard {...card} key={card.header} />
        ))}
      </S.Inner>
      {!!expandableCards.length && (
        <S.ExpandableSection data-test={CONTENT_CARD.EXPANDABLE_SECTION}>
          <Fade active={isOpen}>
            <S.ExpandedContent>
              {expandableCards.map((card) => (
                <ContentCard {...card} key={card.header} />
              ))}
            </S.ExpandedContent>
          </Fade>
          <ExpandButton onClick={handleClick} isActive={isOpen} />
        </S.ExpandableSection>
      )}
    </S.Wrapper>
  );
}

export default ContentCardCollection;
