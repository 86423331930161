import {
  getRGBColorBySeverityIndex,
  getTimestampString,
} from 'common/utils/helpers';
import { HistoricalResult, Marker } from 'common/utils/types';
import React from 'react';

import * as S from './styles';

type Props = {
  resultsApprovedAt: string;
  historicalResult: HistoricalResult;
  marker: Marker;
  dataTest: string;
};

const HistoricalData = (props: Props) => {
  const { historicalResult, resultsApprovedAt, marker, dataTest } = props;

  const {
    value,
    severity_index: severityIndex,
    results_approved_at: historicalApprovedDate,
  } = historicalResult;

  const timeSince = getTimestampString(
    historicalApprovedDate,
    resultsApprovedAt,
  );

  return (
    <S.HistoricalDataWrapper data-test={dataTest}>
      <S.HistoricalTimeLabel>
        {timeSince} <span data-isolate>prior it was</span>
      </S.HistoricalTimeLabel>
      <S.PreviousValue>
        {value}
        <S.PreviousSeverityIndicator
          color={getRGBColorBySeverityIndex(severityIndex, marker)}
        />
      </S.PreviousValue>
    </S.HistoricalDataWrapper>
  );
};

export default HistoricalData;
