import { T2TAnalyticsType } from 'common/hooks/useCommonT2TAnalytics';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { isEmpty } from 'lodash';

type ExtraParams = T2TAnalyticsType;

function getParams(data: { program: string; extraParams: ExtraParams }) {
  let params = {};
  if (isEmpty(data.extraParams)) {
    params = {
      program: data.program,
    };
  } else {
    params = {
      ...data.extraParams,
    };
  }
  return params;
}

export const whenUserViewsPage = (data: {
  program: string;
  extraParams: ExtraParams;
}) => {
  const params = getParams(data);
  analytics.track({
    event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
    data: {
      label:
        ANALYTICS.PAGES.VIRTUAL_CARE.APPOINTMENT_SCHEDULING.PROVIDER_SELECTION,
      ...params,
    },
  });
};

export const whenUserClicksContinue = (data: {
  program: string;
  extraParams: ExtraParams;
}) => {
  const params = getParams(data);
  analytics.track({
    event: ANALYTICS.EVENTS.CLICKED_BUTTON,
    data: {
      label:
        ANALYTICS.LABELS.VIRTUAL_CARE.APPOINTMENT_SCHEDULING
          .PROVIDER_SELECTION_CONTINUE,
      ...params,
    },
  });
};
