import { Tab } from '@everlywell/leaves';
import React from 'react';

import * as S from './SelectionTabs.styles';

export type SelectionTabsProps = {
  availableTiers: number[];
  onItemSelect: (index: number, tier: number) => void;
};

/**
 * Tabs for splitting up the product selection in tabs for each credit tier
 */
export default function SelectionTabs({
  onItemSelect,
  availableTiers,
}: SelectionTabsProps) {
  const tabTitles = availableTiers.map(
    (tier) => `${tier} Credit${tier > 1 ? 's' : ''}`,
  );

  return (
    <S.Container>
      <S.TabMenu
        onItemSelect={(index) => onItemSelect(index, availableTiers[index])}
        id="product-selection-tabs"
        ariaLabel="product-selection-tabs"
        ariaControls="product-selection"
        isScrollable
        hasArrows
        initialActiveIndex={0}
      >
        {tabTitles.map((tab) => (
          <Tab key={tab}>{tab}</Tab>
        ))}
      </S.TabMenu>
    </S.Container>
  );
}
