import { size, H5, colors, mediaQueries, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Card = styled.div`
  padding: ${size.lg}px;
  display: flex;
  flex-direction: row;
  border: 1px solid #edf5f1;
`;

export const CardDetails = styled.div`
  padding: ${size.md}px ${size.md}px ${size.xl1}px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 7px 30px -10px rgba(220, 228, 229);
  border-radius: ${size.xs1}px;
  height: fit-content;
  margin: ${size.xl1}px 10px;
  background-color: ${colors.white};

  ${mediaQueries.forTabletVerticalUp} {
    margin: 0;
    border-radius: 0 ${size.xs1}px ${size.xs1}px 0;
  }
`;

export const CardHeader = styled(H5)`
  margin: 0 0 ${size.xs1}px;
  min-width: 280px;
`;

export const RecommendationName = styled.div`
  font-weight: ${typography.weight.xbold};
`;

export const CardIconContainer = styled.div`
  margin-right: 26px;
`;

export const CardIcon = styled.img`
  width: ${size.xl1}px;
  height: ${size.xl1}px;
  align-self: center;

  ${mediaQueries.forTabletVerticalUp} {
    align-self: flex-start;
  }
`;

export const CardContent = styled.div`
  color: ${colors.gray4};
  display: flex;
  flex-direction: column;
  font-weight: ${typography.weight.light};
  font-size: ${size.md}px;
  line-height: 26px;
  letter-spacing: 0.25px;
  font-feature-settings: 'ss01' on;
`;
