import { Button, colors, size, typography } from '@everlywell/leaves';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${typography.buttonText}
  display: flex;
  align-items: center;
  color: ${colors.gray4};
  gap: ${size.xs1}px;
`;

const iconStyles = css`
  margin: 0;
`;

export const StyledButton = styled(Button)`
  padding: ${size.xs1}px;

  &.svg-class svg {
    ${iconStyles}
  }
`;

export const Label = styled.span`
  display: block;
  min-width: 160px;
  text-align: center;
  user-select: none;
`;
