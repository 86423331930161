/* istanbul ignore file */
import useApi from 'common/hooks/useApi';
import useRegistrationUser from 'common/hooks/useRegistrationUser';
import { useSessionStorage } from 'common/hooks/useSessionStorage';
import { API_ERRORS } from 'common/utils/constants';
import { KitIssue } from 'common/utils/types';
import ConfirmIdentifiersErrorPage from 'components/ConfirmIdentifiers/ConfirmIdentifiersErrorPage';
import ConfirmIdentifiersForm from 'components/ConfirmIdentifiers/ConfirmIdentifiersForm';
import ConfirmIdentifiersWrapper from 'components/ConfirmIdentifiersWrapper';
import Layout from 'components/Layout';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type ConfirmIdentifiersContainerProps = {
  //TODO: check before v6
  kitIssue?: KitIssue;
};

export const ConfirmIdentifiersContainer = (
  props: ConfirmIdentifiersContainerProps,
) => {
  const { kitIssueId: kiID } = useParams();
  const kitIssueId = Number(kiID);

  const stabilityTimeInDays = 365;
  const { fetchKitIssueData, patchKitIssues } = useApi();
  const [errors, setErrors] = useState('');
  const [apiError, setApiError] = useState('');
  const [kitIssue, setKitIssueData] = useState(props);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [user] = useRegistrationUser();
  const [dateOfCollectionValue, setDateOfCollectionValue] = useSessionStorage(
    'dateOfCollection',
    '',
  );
  const dateOfCollection = {
    value: dateOfCollectionValue,
    setValue: setDateOfCollectionValue,
    name: 'dateOfCollection',
    id: 'dateOfCollection',
    required: true,
    validation: new RegExp('\\w+'),
    invalid: false,
    error: 'Please enter your sample collection date',
  };

  const clearInputs = () => {
    user.firstName.setValue('');
    user.lastName.setValue('');
    user.dateOfBirth.setValue('');
    dateOfCollection.setValue('');
  };

  const getAction = (action: string) => {
    switch (action) {
      case 'wait_for_cx':
        return 'customer_support';
      case 'none':
        return 'confirmed';
      default:
        return action;
    }
  };

  const handleSubmit = async (
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    dateOfCollectionString: string,
  ) => {
    try {
      setIsSubmitting(true);
      const data = await patchKitIssues(
        kitIssueId,
        firstName,
        lastName,
        dateOfBirth,
        dateOfCollectionString,
      );
      const action = getAction(data.post_action);

      clearInputs();
      setErrors('');
      navigate(`/identifiers-confirmed/${action}`);
      setIsSubmitting(false);
    } catch (err) {
      const errorObject = API_ERRORS.kitRegistration;
      if (
        err instanceof Error &&
        err.toString() === errorObject.NETWORK_ERROR.value
      ) {
        setErrors(errorObject.NETWORK_ERROR.display);
      } else {
        setErrors(errorObject.UNEXPECTED_ERROR);
      }
    }
  };

  useEffect(() => {
    fetchKitIssueData(kitIssueId).then((data) => {
      if (data.error) {
        setApiError(data.error);
      } else {
        setKitIssueData(data);
      }
    });
  }, [fetchKitIssueData, kitIssueId]);

  if (apiError) {
    return (
      <Layout>
        <ConfirmIdentifiersWrapper>
          <ConfirmIdentifiersErrorPage error={apiError} />
        </ConfirmIdentifiersWrapper>
      </Layout>
    );
  }

  return (
    <Layout>
      <ConfirmIdentifiersWrapper>
        <ConfirmIdentifiersForm
          user={user}
          // @ts-ignore
          kitIssue={kitIssue}
          invalidFields={[]}
          stabilityTimeInDays={stabilityTimeInDays}
          dateOfCollection={dateOfCollection}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          errors={errors}
          setErrors={setErrors}
        />
      </ConfirmIdentifiersWrapper>
    </Layout>
  );
};
export default ConfirmIdentifiersContainer;
