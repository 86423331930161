import { typography, colors, mediaQueries } from '@everlywell/leaves';
import { CardContainer, bodyCopyLarge } from 'common/styles/app-styles';
import { hexToRGB } from 'common/utils/helpers';
import Button from 'components/Button';
import styled from 'styled-components';

type Props = {
  isScrollable?: boolean;
  showScrollContainer?: boolean;
  showButton?: boolean;
  cardHeight?: number;
  groupedByFood?: boolean;
};

const FoodSensitivityMarkerGroupCard = styled(CardContainer)`
  display: flex;
  flex-direction: column;
`;

const MarkerGroupOverview = styled.div<Props>`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: 100%;
  background: ${colors.white};
  ${bodyCopyLarge};
  padding: 16px;
  transition: height 0.5s ease-in-out 0.3, opacity 0.3s ease-in-out 0.8s,
    border-bottom 0.3s ease-in-out 0.5s;
  border-bottom: ${(props) =>
    props.showScrollContainer
      ? `solid 1px rgba(${hexToRGB(colors.gray2)}, 0.5)`
      : 'none'};
  color: ${colors.gray4};
  font-weight: ${typography.weight.light};

  ${mediaQueries.forTabletVerticalUp} {
    padding: 32px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    padding: 34px 28px 38px;
    flex-direction: row;
  }

  ${mediaQueries.forDesktopUp} {
    padding: 34px 40px 38px;
  }
`;

MarkerGroupOverview.defaultProps = {
  theme: {
    colors: {
      primaryText: '#333',
    },
  },
};

const MarkerGroupCardsScrollContainer = styled.div<Props>`
  display: block;
  transition: height 0.5s ease-in-out 0.3s, transform 0.5s ease-in-out 0.3s,
    opacity 0.3s ease-in-out 0.8s;
  transform: ${(props) =>
    props.showScrollContainer ? 'scaleY(1)' : 'scaleY(0)'};
  transform-origin: top;
  height: ${(props) => (props.showScrollContainer ? `auto` : '0')};
  opacity: ${(props) => (props.showScrollContainer ? '1' : '0')};
  position: relative;

  ${mediaQueries.forTabletHorizontalUp} {
    transition: height 0.5s ease-in-out 0.3s, opacity 0.3s ease-in-out 0.8s;
    height: ${(props) =>
      props.showScrollContainer ? `${props.cardHeight}px` : '0'};
    opacity: ${(props) => (props.showScrollContainer ? '1' : '0')};

    &:before {
      border-style: solid;
      border-width: 2px 2px 0 0;
      content: '';
      border-color: rgba(138, 138, 139, 1);
      display: ${(props) =>
        props.isScrollable && props.showScrollContainer
          ? 'inline-block'
          : 'none'};
      width: 7px;
      height: 7px;
      bottom: 10px;
      left: 82px;
      position: absolute;
      z-index: 1;
      transform: rotate(135deg);
      vertical-align: top;
    }

    &:after {
      content: '';
      border-top: ${(props) =>
        props.isScrollable && props.showScrollContainer
          ? `solid 1px rgba( ${hexToRGB(colors.gray2)}, 0.5)`
          : 'none'};
      display: ${(props) => (props.showScrollContainer ? 'block' : 'none')};
      position: absolute;
      bottom: 0;
      width: 180px;
      height: 25px;
      pointer-events: none;
      background-color: ${(props) =>
        props.isScrollable ? colors.white : 'transparent'};
    }
  }
`;

const MarkerGroupCardsWrapper = styled.div<Props>`
  ${mediaQueries.forTabletHorizontalUp} {
    height: ${(props) => props.cardHeight}px;
    overflow-y: auto;
    overflow-x: unset;
    width: 180px;
    transition: height 0.3s ease-in;
  }
`;

const FoodSensitivityMarkerCard = styled.div`
  display: flex;
`;

const ShowResultsButton = styled(Button)<Props>`
  display: block;
  letter-spacing: normal;
  font-size: 16px;
  padding: 1px 16px 2px;
  margin: 32px auto;
  text-transform: none;
  width: auto;
  max-width: unset;
  ${(props) => {
    if (props.showButton) return 'opacity: 1;';

    // hide and animate button
    return `
        opacity: 0;
        height: 0;
        margin: 0;
        padding: 0;
        border: 0;
        cursor: default;
    `;
  }};

  &:hover {
    border: ${(props) => (props.showButton ? '' : '0')};
  }

  ${mediaQueries.forTabletVerticalUp} {
    margin: 32px 0 0;
  }
`;

const MarkerGroupOverviewContent = styled.div<Props>`
  ${mediaQueries.forTabletHorizontalUp} {
    padding-right: ${(props) => (props.groupedByFood ? '0' : '40px')};
  }
`;

const MarkerGroupOverviewBadge = styled.div`
  padding-bottom: 26px;

  ${mediaQueries.forTabletHorizontalUp} {
    padding: 6px 0 0;
  }
`;

export {
  FoodSensitivityMarkerGroupCard,
  MarkerGroupOverview,
  MarkerGroupCardsScrollContainer,
  MarkerGroupCardsWrapper,
  FoodSensitivityMarkerCard,
  ShowResultsButton,
  MarkerGroupOverviewContent,
  MarkerGroupOverviewBadge,
};
