import useApi from 'common/hooks/useApi';
import {
  HealthProfileProgressStatus,
  HealthProfileProgressStatusResponse,
} from 'common/utils/types';
import { useEffect, useState } from 'react';

type ReturnProps = {
  healthProfileProgressStatus: HealthProfileProgressStatus | {};
  loading: boolean;
};

export function useHealthProfileProgressStatus(): ReturnProps {
  const { getHealthProfileProgressStatus } = useApi();
  const [loading, setLoading] = useState<boolean>(true);
  const [healthProfileProgressStatus, setHealthProfileProgressStatus] =
    useState<HealthProfileProgressStatusResponse | {}>({
      questionnaire: {},
    });

  useEffect(() => {
    getHealthProfileProgressStatus()
      .then((response: HealthProfileProgressStatusResponse) => {
        setHealthProfileProgressStatus(response?.questionnaire ?? {});
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [getHealthProfileProgressStatus]);

  return { healthProfileProgressStatus, loading };
}
