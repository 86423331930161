import { NewWindow } from '@everlywell/leaves';
import {
  HEALTHIE_WHITE_LABEL_SSO_LINK,
  QUEST_TEST_DIRECTORY_URL,
} from 'common/utils/constants/urls';
import { CarePlanLab } from 'common/utils/types';
import React, { useState } from 'react';
import { animated, easings, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';

import * as CommonStyles from '../../CommonCarePlanStyles';
import * as S from './LabDetails.styles';

export type LabDetailsProps = {
  header: string;
  icon: string;
  disclaimer: string;
  documentsLabel: string;
  viewDetailsLabel: string;
  hideDetailsLabel: string;
  carePlanLab: CarePlanLab;
};

const LabCodeDetails = ({ code }: { code: string }) => {
  const LAB_LINK = `${QUEST_TEST_DIRECTORY_URL}/results?q=${code}`;

  return (
    <div>
      <S.LabCodeLabel>CPT: </S.LabCodeLabel>
      <S.LabCodeLink href={LAB_LINK} target="_blank">
        {code}
      </S.LabCodeLink>
    </div>
  );
};

/**
 * Lab details component used to show lab detials codes in virtual care management
 */
export default function LabDetails({
  header,
  icon,
  disclaimer,
  documentsLabel,
  viewDetailsLabel,
  hideDetailsLabel,
  carePlanLab: { name, cpt_codes },
}: LabDetailsProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [ref, { height: viewHeight }] = useMeasure<HTMLDivElement>();

  const buttonAnimation = useSpring({
    config: {
      duration: 100,
      easing: easings.easeInOutBack,
    },
    delay: 0,
  });

  const toggleAnimation = useSpring({
    height: isOpen ? viewHeight : 0,
    opacity: isOpen ? 1 : 0,
  });

  return (
    <S.Container>
      <CommonStyles.Card>
        <CommonStyles.CardHeaderContainer>
          {icon && (
            <CommonStyles.CardIconContainer>
              <CommonStyles.CardIcon src={icon} role="icon" />
            </CommonStyles.CardIconContainer>
          )}
          <CommonStyles.CardHeader>{header}</CommonStyles.CardHeader>
        </CommonStyles.CardHeaderContainer>
        <S.CardContent>
          <S.ContentHeader isOpen={isOpen}>
            <S.LabName isOpen={isOpen}>{name}</S.LabName>
            <animated.div style={buttonAnimation}>
              <S.ToggleButton onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? hideDetailsLabel : viewDetailsLabel}
              </S.ToggleButton>
            </animated.div>
          </S.ContentHeader>
          <animated.div
            style={{
              ...toggleAnimation,
              overflow: 'hidden',
            }}
          >
            <S.LabDetailsContent ref={ref}>
              <S.LabCodeLink
                href={HEALTHIE_WHITE_LABEL_SSO_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                {documentsLabel} &nbsp;<NewWindow></NewWindow>
              </S.LabCodeLink>
              <div>
                {cpt_codes?.map((code) => (
                  <LabCodeDetails key={code} code={code}></LabCodeDetails>
                ))}
              </div>
              <S.Disclaimer>{disclaimer}</S.Disclaimer>
            </S.LabDetailsContent>
          </animated.div>
        </S.CardContent>
      </CommonStyles.Card>
    </S.Container>
  );
}
