import { FormModalType } from 'common/utils/types';
import FormBuilderErrorIcon from 'components/telehealth/FormBuilder/images/FormBuilderErrorModal.png';
import FormBuilderInfoIcon from 'components/telehealth/FormBuilder/images/FormBuilderInfoModal.png';
import FormBuilderWarningIcon from 'components/telehealth/FormBuilder/images/FormBuilderWarningModal.png';
import React from 'react';

export const Image = ({ type }: { type: FormModalType }) => {
  switch (type) {
    case 'error':
      return <img src={FormBuilderErrorIcon} alt="" />;
    case 'warning':
      return <img src={FormBuilderWarningIcon} alt="" />;
    default:
      return <img src={FormBuilderInfoIcon} alt="" />;
  }
};
