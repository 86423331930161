/**
 * RegisterAKitHero
 */
import registrationCardIcon from 'common/images/registration_card_icon.svg';
import {
  LEGACY_APP_ROOT,
  PRODUCTS_INDEX_ROUTE,
  REGISTRATION_ROUTE,
} from 'common/utils/constants';
import { REGISTER_A_KIT } from 'common/utils/constants/dataTest';
import HeroCard from 'components/HeroCard';
import React from 'react';

import * as S from './styles';

function RegisterKitHero() {
  return (
    <S.CardWrapper>
      <S.CardTitle>MY TESTS</S.CardTitle>
      <HeroCard>
        <React.Fragment>
          <S.Icon>
            <img
              style={{ width: '100%' }}
              src={registrationCardIcon}
              alt="Registration Card Icon"
            />
          </S.Icon>

          <div>
            <S.Heading>Get Started</S.Heading>
          </div>

          <S.Info>
            <S.TextWrapper>
              <span>
                Take the next step on your health and wellness journey. Register
                your Everlywell kit, or{' '}
              </span>
              <S.LineBreakSmall>
                <S.Link
                  aria-label="Search for a Test"
                  data-test={REGISTER_A_KIT.SEARCH}
                  href={`${LEGACY_APP_ROOT}${PRODUCTS_INDEX_ROUTE}`}
                >
                  search for a test
                </S.Link>
                .
              </S.LineBreakSmall>
            </S.TextWrapper>
          </S.Info>

          <S.ActionItems>
            <S.LinkButton
              href={`${LEGACY_APP_ROOT}${REGISTRATION_ROUTE}`}
              role="button"
              aria-label="Register a Kit"
              data-test={REGISTER_A_KIT.REGISTER}
              id="register-a-kit"
            >
              Register a Kit
            </S.LinkButton>
          </S.ActionItems>
        </React.Fragment>
      </HeroCard>
    </S.CardWrapper>
  );
}

export default RegisterKitHero;
