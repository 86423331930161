/* stylelint-disable selector-type-no-unknown */
import { colors, typography } from '@everlywell/leaves';
import styled from 'styled-components';

import checkIcon from './images/checked.svg';

const Label = styled.label`
  color: ${colors.gray5};
  display: flex;
  font-weight: ${typography.weight.light};
  font-size: 16px;
  line-height: 24px;
  border-color: ${colors.green5};
`;
const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0;
`;

const StyledCheckbox = styled.div`
  border: 1px solid ${colors.green5};
  border-radius: 3px;
  display: inline-block;
  height: 29px;
  margin-right: 16px;
  transition: all 150ms;
  width: 29px;

  &:after {
    /* prettier-ignore */
    background-image: url("${checkIcon}");
    content: '';
    display: block;
    height: 100%;
    opacity: 0;
    transition: opacity 150ms;
    width: 100%;
  }

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 2px ${colors.green5};
  }

  ${HiddenCheckbox}:checked + & {
    border: 1px solid ${colors.green5};

    &:after {
      opacity: 1;
    }
  }
`;

export { Label, HiddenCheckbox, StyledCheckbox, CheckboxContainer };
