import { size, H4, mediaQueries, Button } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${mediaQueries.forDesktopUp} {
    justify-content: flex-start;
    padding-bottom: ${size.md}px;
    height: auto;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${size.xl8}px;

  ${mediaQueries.forDesktopUp} {
    padding-bottom: ${size.lg}px;
  }
`;

export const ConfirmationTitle = styled(H4)`
  margin: 0 0 ${size.lg}px 0;
`;

export const InformationBoxWrapper = styled.div`
  margin-top: ${size.lg}px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  padding: ${size.md}px ${size.md}px;
  width: 100%;
  box-shadow: 0 -3px 19px rgba(0, 0, 0, 0.08);
  gap: ${size.md}px;
  position: fixed;
  left: -1px;
  bottom: 0;

  > button {
    flex: 1;
  }

  ${mediaQueries.forDesktopUp} {
    position: initial;
    padding: 0;
    margin-top: ${size.md}px;
    box-shadow: none;
  }
`;

export const BackButton = styled(Button)`
  display: none;

  ${mediaQueries.forDesktopUp} {
    display: block;
  }
`;
