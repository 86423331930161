import {
  TelehealthAppointment,
  TelehealthIntakeForm,
} from 'common/apis/telehealthApis';
import {
  CalendarLinks,
  NewAppointmentProvider,
  NewOnDemandAppointment,
  NewSelfScheduledAppointment,
  NewTelehealthAppointment,
} from 'common/apis/telehealthSchedulingApis';
import { add, sub } from 'date-fns';
import { random } from 'lodash';

const baseAppointment: TelehealthAppointment = {
  id: '1234',
  provider: {
    first_name: 'Harry',
    last_name: 'Potter',
    credentials: ['MD'],
    id: '123',
  },
  start_time: new Date('1/1/2001').toISOString(),
  end_time: new Date('1/2/2001').toISOString(),
  user: {
    id: '321',
    first_name: 'Patient',
    last_name: 'Zero',
    email: 'patient_zero@mail.com',
    state_abbrev: 'TX',
    timezone: 'America/Chicago',
  },
  program: {
    slug: 'virtual-care-visit',
  },
  join_url: 'https://www.zoom.com',
  care_plan: {
    id: '123',
    completed_at: new Date('1/3/2001').toISOString(),
  },
  booking_type: 'scheduled',
  on_demand_link: null,
  calendar_links: {
    google: 'https://google.com',
    ical: 'https://ical.com',
    outlook: 'https://outlook.com',
    yahoo: 'https://yahoo.com',
  },
};

export const buildTelehealthAppointment = (
  overrideProps: Partial<TelehealthAppointment> = {},
) => ({
  ...baseAppointment,
  id: random(1, 9999).toString(),
  ...cleanProps(overrideProps),
});

//Helper when mocked end date is less than start date, avoids interval errors
const cleanProps = (props: Partial<TelehealthAppointment>) => {
  const cleanedProps = { ...props };
  let { start_time, end_time } = props;

  if (start_time && !end_time) {
    cleanedProps.end_time = add(new Date(start_time), {
      minutes: 10,
    }).toISOString();
  }

  if (end_time && !start_time) {
    cleanedProps.start_time = sub(new Date(end_time), {
      minutes: 10,
    }).toISOString();
  }

  return cleanedProps;
};

export type AppointmentCancellation = {
  id: string;
  reason: string;
  feedback: string;
};
export const buildTelehealthAppointmentCancellation = (
  overrideProps: Partial<AppointmentCancellation> = {},
) => ({
  id: random(1, 9999).toString(),
  reason: 'Cost of Service',
  feedback: '',
  ...overrideProps,
});

const baseProvider: NewAppointmentProvider = {
  id: '1',
  first_name: 'Dr. Jim',
  last_name: 'Vanaria',
  credentials: ['MD'],
  has_future_availabilities: true,
};
export const buildProvider = (
  overrides?: Partial<NewAppointmentProvider>,
): NewAppointmentProvider => ({
  ...baseProvider,
  id: random(0, 1000).toString(),
  ...overrides,
});

const baseCalendarLinks: CalendarLinks = {
  google_cal:
    'https://www.google.com/calendar/render?action=TEMPLATE&text=Initial%20Virtual%20Care%20Visit%20with%20Dr.%20Jim%20Vanaria&dates=20230728T203000/20230728T210000&ctz=America/Fortaleza',

  outlook_cal:
    'https://outlook.live.com/calendar/0/deeplink/compose?path=/calendar/action/compose&rru=addevent&subject=Initial%20Virtual%20Care%20Visit%20with%20Dr.%20Jim%20Vanaria&startdt=2023-07-28T23:30:00Z&enddt=2023-07-29T00:00:00Z',

  ical: 'data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0APRODID:-//AddToCalendar//RubyGem//EN%0ABEGIN:VEVENT%0ADTSTAMP:20230705T183822Z%0ADTSTART:20230728T233000Z%0ADTEND:20230729T000000Z%0ASUMMARY:Initial%20Virtual%20Care%20Visit%20with%20Dr.%20Jim%20Vanaria%0AUID:-20230728T233000Z-Initial%20Virtual%20Care%20Visit%20with%20Dr.%20Jim%20Vanaria%0AEND:VEVENT%0AEND:VCALENDAR',

  yahoo_cal:
    'https://calendar.yahoo.com/?v=60&TITLE=Initial%20Virtual%20Care%20Visit%20with%20Dr.%20Jim%20Vanaria&ST=20230728T233000Z&DUR=0030',
};

export const buildCalendarLinks = (
  overrides?: Partial<CalendarLinks>,
): CalendarLinks => ({
  ...baseCalendarLinks,
  ...overrides,
});

export const baseNewSelfAppointment: NewSelfScheduledAppointment = {
  id: '1234',
  start_at: '2023-07-28T12:30:00Z',
  end_at: '2023-07-28T13:00:00Z',
  type: 'self_scheduled',
  join_appointment_url: null,
  appointment_type: {},
  add_to_calendar_links: buildCalendarLinks(),
  provider: buildProvider(),
};

export const buildNewSelfScheduledAppointment = (
  overrideProps: Partial<NewSelfScheduledAppointment> = {},
) => ({
  ...baseNewSelfAppointment,
  id: random(1, 9999).toString(),
  ...overrideProps,
});

const baseNewOnDemandAppointment: NewTelehealthAppointment = {
  id: '1234',
  on_demand_link: 'http://calculating-incident.org',
  type: 'on_demand',
  booking_type: 'on_demand',
};

export const buildNewOnDemandAppointment = (
  overrideProps: Partial<NewOnDemandAppointment> = {},
) => ({
  ...baseNewOnDemandAppointment,
  id: random(1, 9999).toString(),
  ...overrideProps,
});

export const buildTelehealthIntakeForms = (
  overrides?: Partial<TelehealthIntakeForm>,
): TelehealthIntakeForm => ({
  id: random(0, 1000).toString(),
  external_form_id: random(0, 1000).toString(),
  form_type: 'custom',
  position: 0,
  program_slug: 'virtual-care-visit',
  slug: 'demographics',
  ...overrides,
});
