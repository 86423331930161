import { select } from '@storybook/addon-knobs';
import ApolloContainer from 'containers/ApolloContainer';
import React from 'react';

import PCPQuestions from '.';

export default {
  title: 'Components/PCPQuestions',
  component: PCPQuestions,
};

const productTypes = ['thyroid-test', 'metabolism'];

export const Example = () => {
  const resultTypeKnob = select('Result', productTypes, 'thyroid-test');
  const pcpKnob = select('PCP', [true, false], 'Yes');

  return (
    <ApolloContainer>
      <PCPQuestions testType={resultTypeKnob} kitResultId="1" pcp={pcpKnob} />
    </ApolloContainer>
  );
};
