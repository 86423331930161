import { mediaQueries } from '@everlywell/leaves';
import { Col_12_Span_N, Col_10 } from 'common/styles/grid-styles';
import styled from 'styled-components';

const TakeActionWrapper = styled(Col_12_Span_N)`
  margin-bottom: 0;
  padding: 24px 0 8px;

  ${mediaQueries.forTabletVerticalUp} {
    padding-bottom: 0;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 0;
    padding: 32px 0 8px;
  }
`;

const Col10Wrapper = styled(Col_10)`
  grid-column: 1 / 24;

  ${mediaQueries.forTabletVerticalUp} {
    grid-column: 1 / 24;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    grid-column: 1 / 24;
  }
`;

export { TakeActionWrapper, Col10Wrapper };
