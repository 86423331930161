import { Col, Row, Localize } from '@everlywell/leaves';
import { track } from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { DATA_SHARING_MODAL as DATA_TEST } from 'common/utils/constants/dataTest';
import { isValidFullName } from 'common/utils/helpers';
import { KitRegistrationUser } from 'common/utils/types';
import React, { useState, useRef } from 'react';
import { useEffectOnce } from 'react-use';

import infoIcon from '../assets/ui-icons-info.svg';
import { BaseInput, BaseModal } from '../styles';
import * as S from './styles';

interface DataSharingModalProps {
  testName: string;
  companyName: string;
  closeModal: () => void;
  customConsentCopy?: string;
  user: KitRegistrationUser;
  onSubmit: () => void;
}

enum CustomConsentCopyType {
  yes = 'yes',
  no = 'no',
}

const DataSharingModal = ({
  onSubmit,
  testName,
  companyName,
  closeModal,
  customConsentCopy = '',
  user,
}: DataSharingModalProps) => {
  const [fullName, setFullName] = useState('');
  const [fullNameError, setFullNameError] = useState('');
  const [consentChecked, setConsentChecked] = useState(
    customConsentCopy !== '' ? CustomConsentCopyType.yes : '',
  );

  useEffectOnce(() => {
    track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        component: ANALYTICS.PAGES.DATA_SHARING_MODAL,
        testName,
        kitType: 'Enterprise',
      },
    });
  });

  const consentRef = useRef<any>(null);
  const consentCopy = (
    <span ref={consentRef}>
      Consent to sharing your <span data-isolate="true">{testName}</span>{' '}
      results with{' '}
      <Localize name="DataSharingModal-companyName2">{companyName}</Localize> by
      selecting this company as the known test administrator and typing your
      full name.
    </span>
  );

  const handleSubmit = async () => {
    handleConsentAttributes();
    onSubmit();
    closeModal();
  };

  const handleConsentAttributes = () => {
    user.consentTimestamp.setValue(new Date().toISOString());
    user.consentCopy.setValue(
      customConsentCopy || consentRef.current?.innerText || '',
    );
    user.consentName.setValue(fullName);
    user.consentOrganizationName.setValue(companyName || '');
  };

  const handleButtonClick = () => {
    const message =
      fullName !== '' ? 'Added Full Name' : 'Closed without adding Full Name';
    updateMixPanel(message);

    if (consentChecked === CustomConsentCopyType.yes || customConsentCopy) {
      if (consentChecked === CustomConsentCopyType.yes) {
        updateMixPanel('Consented to Enterprise Partner consent form');
      }
      return isValidFullName(fullName, user)
        ? handleSubmit()
        : setFullNameError(
            'Invalid Entry. Please enter your first and last name.',
          );
    }
    return closeModal();
  };

  const updateMixPanel = (message: string) => {
    track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        component: ANALYTICS.PAGES.DATA_SHARING_MODAL,
        outcome: message,
        testName,
        kitType: 'Enterprise',
      },
    });
  };

  const handleRadioButtonClick = (value: string) => {
    setConsentChecked(value);
  };

  const showConsentButton = () => {
    if (consentChecked === CustomConsentCopyType.no) return false;
    return true;
  };

  const disableConsentButton = () => {
    if (!fullName) {
      return true;
    }

    return false;
  };

  const fullNameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(e.target.value);
    setFullNameError('');
  };

  return (
    <BaseModal
      open
      openHandler={() => closeModal()}
      data-test={DATA_TEST.MODAL}
    >
      {/* custom consent don't show copany name */}
      {customConsentCopy ? (
        <S.Title data-test={DATA_TEST.TITLE}>Sharing your results</S.Title>
      ) : (
        <S.Title data-test={`${DATA_TEST.TITLE}-enterprise-partner`}>
          Sharing your results with{' '}
          <Localize name="DataSharingModal-companyName1">
            {companyName}
          </Localize>
        </S.Title>
      )}
      {customConsentCopy ? (
        <S.CustomContentContainer
          data-test={`${DATA_TEST.SUBTITLE}-custom`}
          dangerouslySetInnerHTML={{ __html: customConsentCopy }}
        />
      ) : (
        <div>
          <S.Subtitle data-test={`${DATA_TEST.SUBTITLE}-standard`}>
            {consentCopy}
          </S.Subtitle>
          <S.RadioGroup>
            <S.StyledRadioButton
              id="consent-yes"
              name="consent-yes"
              checked={consentChecked === CustomConsentCopyType.yes}
              data-test={`${DATA_TEST.INPUT}-consent-yes`}
              onChange={() => handleRadioButtonClick(CustomConsentCopyType.yes)}
              label={`I confirm that ${companyName} is my test administrator`}
            />
            <S.StyledRadioButton
              id="consent-no"
              name="consent-no"
              checked={consentChecked === CustomConsentCopyType.no}
              data-test={`${DATA_TEST.INPUT}-consent-no`}
              onChange={() => handleRadioButtonClick(CustomConsentCopyType.no)}
              label={`${companyName} is not my test administrator`}
            />
          </S.RadioGroup>
        </div>
      )}
      <S.StyledRow start="xs" middle="xs">
        {(consentChecked === CustomConsentCopyType.yes ||
          customConsentCopy) && (
          <Col xs={12} md={6}>
            <BaseInput
              value={fullName}
              label="Type your full name"
              id="fullname"
              name="fullname"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                fullNameOnChange(e)
              }
              data-test={`${DATA_TEST.INPUT}-fullname`}
              error={fullNameError}
            />
          </Col>
        )}
      </S.StyledRow>
      <S.StyledRow start="xs" middle="xs">
        {/* Display custom care contact information if the person
          registering does not recognize company as test administrator */}
        {consentChecked === CustomConsentCopyType.no && (
          <Col xs={12} md={12}>
            <S.CustomerCareInformationWrapper>
              <Row>
                <Col sm={1}>
                  <S.TooltipIcon src={infoIcon} alt="info" />
                </Col>
                <Col sm={11} data-test={`${DATA_TEST.CUSTOMER_CARE}`}>
                  Immediate action is required. Please contact Customer
                  Experience to proceed with your registration.
                </Col>
              </Row>
              <br />
              <strong>Customer Experience</strong>
              <br />
              P:{' '}
              <S.ContactData href="tel:855-463-1670">
                855-463-1670
              </S.ContactData>
              <br />
              E:{' '}
              <S.ContactData href="mailto:contact@everlywell.com">
                contact@everlywell.com
              </S.ContactData>
              <br />
              Monday-Friday, 9 am to 5pm (CST)
            </S.CustomerCareInformationWrapper>
          </Col>
        )}
      </S.StyledRow>
      <S.Footer>
        {showConsentButton() && (
          <S.StyledRow>
            <Col xs={12} md={6}>
              <S.ConsentButton
                onClick={handleButtonClick}
                appearance="primary"
                data-test={DATA_TEST.CONSENT}
                isDisabled={disableConsentButton()}
              >
                I consent
              </S.ConsentButton>
            </Col>
          </S.StyledRow>
        )}
      </S.Footer>
    </BaseModal>
  );
};

export default DataSharingModal;
