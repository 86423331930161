import { Button, colors, H3, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const Title = styled(H3)`
  margin: 0;
  font-weight: ${typography.weight.bold};
  padding-top: ${size.xl3}px;
`;

const Subtitle = styled.p`
  ${typography.bodyTextSmall}
  font-weight: ${typography.weight.book};
  color: ${colors.gray4};
  margin-bottom: ${size.xl2}px;
  white-space: pre-line;
`;

const Accept = styled(Button)`
  width: 100%;
`;

export { Title, Subtitle, Accept };
