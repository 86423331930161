import { Col } from '@everlywell/leaves';
import { CollectionDeviceImage } from 'common/utils/types';
import React, { useEffect, useState } from 'react';

import ImageWithoutMarkers from '../CollectionDeviceImages/ImageWithoutMarkers';
import { breakpointsVals } from '../SampleInstructions/breakpoints';
import { SampleCardViewport } from '../SampleInstructions/model';
import * as S from '../SampleInstructions/styles';

type Props = {
  collectionDeviceImages: CollectionDeviceImage[];
};

const CollectionDeviceImages = ({ collectionDeviceImages }: Props) => {
  const getViewport = () => {
    switch (true) {
      case window.innerWidth <= breakpointsVals.s: {
        return SampleCardViewport.mobile;
      }
      case window.innerWidth <= breakpointsVals.m &&
        window.innerWidth > breakpointsVals.s: {
        return SampleCardViewport.tablet;
      }
      default: {
        return SampleCardViewport.desktop;
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_viewport, _setViewport] = useState(getViewport());

  useEffect(() => {
    const debouncedViewportResize = () => {
      const viewport = getViewport();
      _setViewport(viewport);
    };
    window.addEventListener('resize', debouncedViewportResize);

    return () => window.removeEventListener('resize', debouncedViewportResize);
  }, []);

  if (collectionDeviceImages && Array.isArray(collectionDeviceImages)) {
    return (
      <S.Wrapper center="xs" middle="xs">
        <Col xs={12} md={12} lg={12}>
          <S.Title>Your sample should look like this</S.Title>
          <S.Card>
            {collectionDeviceImages &&
              Array.isArray(collectionDeviceImages) &&
              collectionDeviceImages.map((collectionDeviceImage, i) => (
                <ImageWithoutMarkers
                  key={i.toString()}
                  image_url={collectionDeviceImage.image_url}
                  name={collectionDeviceImage.name}
                />
              ))}
          </S.Card>
        </Col>
      </S.Wrapper>
    );
  }
  return null;
};

export default CollectionDeviceImages;
