/**
 * ScheduleModal
 */

import { ANALYTICS } from 'common/utils/constants/analytics';
import { TAKE_ACTION } from 'common/utils/constants/dataTest';
import { sanitize } from 'common/utils/domHelpers';
import Modal from 'components/Modal';
import React from 'react';

import * as S from './styles';

export type Props = {
  header: string;
  description: string;
  link: string;
  testName: string;
  onClose: () => void;
};

function ScheduleModal(props: Props) {
  const { header, description, link, onClose, testName } = props;

  return (
    <Modal
      onClose={onClose}
      footer={({ hasScrollableContent }) => (
        <S.Footer needsGradient={hasScrollableContent}>
          <S.ContinueButton
            data-test={TAKE_ACTION.SCHEDULE_CONTINUE_BUTTON}
            href={link}
            target="_blank"
            trackingData={{
              label: 'Continue Discussion',
              category: ANALYTICS.CATEGORIES.NAVIGATION,
              testName,
            }}
          >
            Continue
          </S.ContinueButton>
        </S.Footer>
      )}
    >
      <S.Wrapper data-test={TAKE_ACTION.SCHEDULE_MODAL}>
        <S.Title
          dangerouslySetInnerHTML={{ __html: sanitize(header) }}
          data-test={TAKE_ACTION.SCHEDULE_MODAL_TITLE}
        />
        <S.Description
          dangerouslySetInnerHTML={{ __html: sanitize(description) }}
          data-test={TAKE_ACTION.SCHEDULE_MODAL_DESCRIPTION}
        />
      </S.Wrapper>
    </Modal>
  );
}

export default ScheduleModal;
