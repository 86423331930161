import useApi from 'common/hooks/useApi';
import {
  KitRegistrationResponse,
  TouchHealthProfileProgressStatus,
} from 'common/utils/types';
import { isUndefined } from 'lodash';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';

type Props = {
  lastInteraction: string | null;
  reEntryParameter?: string | null;
  kitRegistration?: KitRegistrationResponse;
};
export function useTouchOnHealthProfile({
  lastInteraction,
  reEntryParameter,
  kitRegistration,
}: Props) {
  const { touchHealthProfileProgressStatus: touchCall } = useApi();
  const [touchHealthProfile, setTouchHealthProfile] =
    useState<TouchHealthProfileProgressStatus>();

  useEffectOnce(() => {
    function hasTouchBeenCalled(): boolean {
      return (
        lastInteraction !== '' &&
        !isUndefined(lastInteraction) &&
        (reEntryParameter !== '' || !isUndefined(kitRegistration))
      );
    }
    function isKitEligible(): boolean {
      const isCovid = Boolean(kitRegistration?.covid);
      const isEnterprise = Boolean(kitRegistration?.enterprise);
      if (isCovid || isEnterprise) {
        return false;
      }
      return true;
    }
    if (hasTouchBeenCalled() && isKitEligible()) {
      touchCall().then((response: TouchHealthProfileProgressStatus) => {
        setTouchHealthProfile({
          last_interacted_at: response?.last_interacted_at,
        });
      });
    }
  });

  return touchHealthProfile;
}
