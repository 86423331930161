import {
  Button,
  colors,
  H3,
  mediaQueries,
  Row,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

const Btn = styled(Button)`
  &:hover {
    text-decoration: none;
  }
  ${mediaQueries.forPhoneOnly} {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const Subtitle = styled.p`
  ${typography.bodyTextSmall};
  line-height: 1.75;
  font-weight: ${typography.weight.book};
  margin-bottom: 40px;

  a {
    font-weight: ${typography.weight.bold};
    color: ${colors.green5};
  }
`;

const Title = styled(H3)`
  font-weight: ${typography.weight.regular};
  margin: 0;
  margin-bottom: 20px;
`;

const Wrapper = styled(Row)`
  box-sizing: border-box;
  padding: 60px 0;
  margin: 0;
  width: 100%;

  ${mediaQueries.forPhoneOnly} {
    padding: 40px 0 20px;
  }
`;

export { Btn, Subtitle, Title, Wrapper };
