import { Container } from '@everlywell/leaves';
import { getPaymentMethods } from 'common/apis/telehealthApis';
import useProgramSlug from 'common/hooks/useProgramSlug';
import useUser from 'common/hooks/useUser';
import { HEALTHIE_WHITE_LABEL_SSO_LINK } from 'common/utils/constants/urls';
import Grid from 'components/Grid';
import Layout from 'components/Layout/Layout';
import LoadingError from 'components/LoadingError';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash-es';
import PaymentForm from 'pages/CreditCardForm/components/Payment';
import SLUGS from 'pages/InterstitialPage/utils/slugs';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useQuery } from 'react-query';

import PaymentMethods from './components/PaymentMethods';
import CreditCardFormSkeleton from './CreditCardForm.skeleton';
import * as S from './CreditCardForm.styles';

export type CreditCardFormProps = {};

function CreditCardForm(props: CreditCardFormProps) {
  const { telehealthCreditCardVerticals, showDynamicIntakeFlow } = useFlags<{
    telehealthCreditCardVerticals: string[];
    showDynamicIntakeFlow: boolean;
  }>();

  const { programSlug } = useProgramSlug();
  const program = programSlug ?? '';

  const [isEnablePaymentForm, setIsEnablePaymentForm] = useState(false);
  // Allow insurance if feature flag includes the current program slug
  const allowedRender =
    showDynamicIntakeFlow || telehealthCreditCardVerticals.includes(program);

  const { user } = useUser();
  const {
    data: paymentMethodsResponse,
    isLoading,
    refetch,
    isError,
  } = useQuery(['get-payment-methods'], () => getPaymentMethods());
  const paymentMethods = paymentMethodsResponse?.data?.payment_methods || [];

  /**
   * Redirect directly to the SSO link if
   * this form is disabled via feature flag.
   */
  useEffect(() => {
    if (!!program && !allowedRender) {
      window.open(HEALTHIE_WHITE_LABEL_SSO_LINK, '_self');
    }
  }, [allowedRender, program, showDynamicIntakeFlow]);

  useEffect(() => {
    if (!allowedRender) return;
    TagManager.dataLayer({
      dataLayer: {
        event: 'creditCardFormPage',
        programName: program,
      },
    });
  }, [allowedRender, program]);

  if (isError) {
    return (
      <Layout>
        <S.Container>
          <LoadingError handleRetry={refetch} />
        </S.Container>
      </Layout>
    );
  }

  if (!allowedRender || !user || isLoading) {
    return (
      <Layout>
        <Container>
          <S.LoaderContainer>
            <CreditCardFormSkeleton />
          </S.LoaderContainer>
        </Container>
      </Layout>
    );
  }

  const isSTIOD = program === SLUGS.STI_ON_DEMAND_PROGRAM_SLUG;
  const isPaymentFormEnabled = isEmpty(paymentMethods) || isEnablePaymentForm;

  return (
    <Layout>
      <S.Container>
        <Container>
          <Grid.Container spacing={['lg']}>
            <S.Item width={[1]}>
              <S.InnerContainer>
                {isPaymentFormEnabled ? (
                  <PaymentForm program={program} isSTIOD={isSTIOD} />
                ) : (
                  <PaymentMethods
                    paymentMethods={paymentMethods}
                    setIsEnablePaymentForm={setIsEnablePaymentForm}
                    program={program}
                    isSTIOD={isSTIOD}
                  />
                )}
              </S.InnerContainer>
            </S.Item>
          </Grid.Container>
        </Container>
      </S.Container>
    </Layout>
  );
}

export default CreditCardForm;
