/**
 * ContentCardModal
 */

import { CONTENT_CARD } from 'common/utils/constants/dataTest';
import { sanitize } from 'common/utils/domHelpers';
import Modal from 'components/Modal';
import React from 'react';

import * as S from './ContentCardModal.styles';

export type Props = {
  header: string;
  description: string;
  backgroundSrc: string;
  onClose: () => void;
};

function ContentCardModal(props: Props) {
  const { header, description, backgroundSrc, onClose } = props;

  return (
    <Modal
      onClose={onClose}
      footer={({ hasScrollableContent }) =>
        hasScrollableContent && <S.Gradient />
      }
    >
      <S.Wrapper backgroundSrc={backgroundSrc}>
        <S.Title
          dangerouslySetInnerHTML={{ __html: sanitize(header) }}
          data-test={CONTENT_CARD.MODAL_TITLE}
        />
        <S.Description
          dangerouslySetInnerHTML={{ __html: sanitize(description) }}
          data-test={CONTENT_CARD.MODAL_DESCRIPTION}
        />
      </S.Wrapper>
    </Modal>
  );
}

export default ContentCardModal;
