import React from 'react';

import * as S from './styles';

type Props = {
  statusText: string | null;
  needsReplacement: boolean;
};

const Status = (props: Props) => {
  const { statusText, needsReplacement } = props;

  return statusText ? (
    <S.Status data-test="status-badge" needsReplacement={needsReplacement}>
      {statusText}
    </S.Status>
  ) : null;
};

export default Status;
