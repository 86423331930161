import { Dropdown } from '@everlywell/leaves';
import Grid from 'components/Grid/Grid';
import { isEmpty } from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { labelStyles } from '../../utils/styles';
import { FormBuilderField } from '../../utils/types';

type DropDownItems = {
  options_array: string[] | { name: string; value: string }[];
};

export type DropdownFieldProps = Omit<FormBuilderField, 'options_array'> &
  DropDownItems;

function dropdownValue(option: string | { name: string; value: string }) {
  if (typeof option === 'object' && 'name' in option) {
    return {
      text: option['name'],
      value: option['value'],
      id: option['name'],
    };
  }

  return {
    text: option,
    value: option,
    id: option,
  };
}

function DropdownField(props: DropdownFieldProps) {
  const { control, errors } = useFormContext();
  return (
    <Grid.Item width={[1]} key={props.id}>
      <Controller
        control={control}
        name={props.id}
        rules={{
          required: props.required && 'Please select an option',
        }}
        render={({ value, ref, onChange }) => (
          <Dropdown
            css={labelStyles(props.variant)}
            id={props.id}
            name={props.id}
            label={props.label ?? ''}
            placeholderText={props.sublabel ?? ''}
            items={props.options_array.map((option) => dropdownValue(option))}
            required={props.required}
            value={value}
            ref={ref}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              onChange(event.target.value)
            }
            showErrorMessage={!isEmpty(errors[props.id])}
            error={errors[props.id]?.message}
          />
        )}
      />
    </Grid.Item>
  );
}

export default DropdownField;
