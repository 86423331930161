import { Icon, Text, Box, theme, useMediaQuery } from '@everlywell/ui-kit';
import { CaretUp, Check, X } from '@phosphor-icons/react';
import React from 'react';
import {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  GroupProps,
  MultiValueRemoveProps,
  OptionProps,
} from 'react-select';

import { OptionType } from '../types';
import {
  checkStyles,
  groupStyles,
  optionStyles,
  wrapOptionStyles,
} from './styles';

const {
  colors: { tints, viridian },
  space,
} = theme;

export const MultiValueRemove = (props: MultiValueRemoveProps<OptionType>) => (
  <components.MultiValueRemove {...props}>
    <Icon as={X} fill={tints.darkGrey} />
  </components.MultiValueRemove>
);

const Checkbox = (props: OptionProps<OptionType>) => {
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);
  const checkboxWrapperSize = !isMobile ? '24px' : '32px';
  const checkboxSize = !isMobile ? '16px' : '24px';

  const isDisabled = Boolean(props.data.isDisabled);
  return (
    <Box
      style={checkStyles({
        isSelected: props.isSelected,
        isDisabled,
      })}
      width={checkboxWrapperSize}
      height={checkboxWrapperSize}
    >
      {isDisabled ? null : (
        <Icon
          as={Check}
          fill={tints.white}
          width={checkboxSize}
          height={checkboxSize}
        />
      )}
    </Box>
  );
};
export const Option = (props: OptionProps<any>) => (
  <Box style={wrapOptionStyles}>
    <components.Option {...props}>
      <Box style={optionStyles}>
        <Text
          alignItems="center"
          display="flex"
          fontSize={[14, null, 16]}
          margin="0"
          maxWidth={`calc(100% - ${space[8]})`}
        >
          {props.data.label}
        </Text>
        <Checkbox {...props} />
      </Box>
    </components.Option>
  </Box>
);

export const ClearIndicator = (_props: ClearIndicatorProps<any>) => null;

export const DropdownIndicator = (
  props: DropdownIndicatorProps<any, boolean>,
) => (
  <components.DropdownIndicator {...props}>
    <Icon as={CaretUp} fill={viridian.base} />
  </components.DropdownIndicator>
);

export const Group = (props: GroupProps<OptionType>) => (
  <div style={groupStyles}>
    <components.Group {...props} />
  </div>
);
