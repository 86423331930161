import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  cashPayPriceLabel,
  DEFAULT_VISIT_PRICE,
} from 'pages/PaymentTypePage/PaymentTypePage';
import { useRedirectAfterPaymentType } from 'pages/PaymentTypePage/utils/redirections';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import InsurancePayIcon from './assets/InsurancePayIcon.svg';
import {
  InsurancePageContent,
  INSURANCE_PAGE_CONTENT,
} from './SkipInsuranceModal.content';
import * as S from './SkipInsuranceModal.styles';

export interface SkipInsuranceModalProps {
  openModal: boolean;
  setOpenModal: (bool: boolean) => void;
  program: string;
  hasCashPayDiscount: boolean;
}

const HEALTHIE_PHONE_NUMBER = '(708)-787-4438';

function SkipInsuranceModal({
  openModal,
  setOpenModal,
  program,
  hasCashPayDiscount,
}: SkipInsuranceModalProps) {
  const { stiPriceTest } = useFlags<{
    stiPriceTest?: number;
  }>();

  const { insurancePagePath, skipInsurancePath } =
    useRedirectAfterPaymentType(program);

  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        label: ANALYTICS.PAGES.TELEHEALTH_SKIP_INSURANCE_MODAL,
        program,
      },
    });
  }, [program]);
  const handlePrimaryButtonClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'Intake cash pay modal',
        program,
      },
    });
  };
  const handleSecondaryButtonClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'Intake add insurance modal',
        program: program,
      },
    });
  };

  const CONTENT =
    (program && INSURANCE_PAGE_CONTENT[program]) ||
    INSURANCE_PAGE_CONTENT.DEFAULT;

  const visitPrice =
    stiPriceTest &&
    ['sti-on-demand', 'sti-virtual-care-visit'].includes(program)
      ? stiPriceTest
      : DEFAULT_VISIT_PRICE;

  // Replace $59 with the stiPriceTest value for sti-on-demand program
  // as a part of a price test (temporary, please remove after the test is done)
  const CONTENT_UNDER_TEST: InsurancePageContent = Object.entries(
    CONTENT,
  ).reduce((acc, [key, value]) => {
    if (typeof value === 'string') {
      return {
        ...acc,
        [key]: value.replace(
          '$59',
          `$${cashPayPriceLabel(visitPrice, hasCashPayDiscount)}`,
        ),
      };
    }
    return {
      ...acc,
      [key]: value,
    };
  }, CONTENT);

  return (
    <S.InsuranceModal open={openModal} openHandler={setOpenModal}>
      <S.IconContainer>
        <img src={InsurancePayIcon} alt="" />
      </S.IconContainer>
      <S.Heading>{CONTENT_UNDER_TEST.HEADING}</S.Heading>
      <S.Copy>
        If you want to add insurance later, you can do so by calling <br />
        <a href={`tel:${HEALTHIE_PHONE_NUMBER}`}>{HEALTHIE_PHONE_NUMBER}</a>,
        Monday through&nbsp;Friday,
        <br /> 7 a.m. to 7 p.m. CST.
      </S.Copy>
      <S.ActionButtons>
        <S.ActionButton
          component={Link}
          to={skipInsurancePath}
          appearance="primary"
          onClick={handlePrimaryButtonClick}
        >
          {CONTENT_UNDER_TEST.WITHOUT_INSURANCE}
        </S.ActionButton>

        <S.ActionButton
          component={Link}
          to={insurancePagePath}
          appearance="secondary"
          onClick={handleSecondaryButtonClick}
        >
          {CONTENT_UNDER_TEST.WITH_INSURANCE}
        </S.ActionButton>
      </S.ActionButtons>
    </S.InsuranceModal>
  );
}

export default SkipInsuranceModal;
