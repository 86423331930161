/*
 * ResultsIntroContainer
 */

import {
  MARKER_TYPES,
  FS_SEVERITIES,
  INTENSITY_SEVERITIES,
  NUMERICAL_SEVERITIES,
  DESCRIPTIVE_SEVERITIES,
} from 'common/utils/constants';
import {
  ImmutableReduxState,
  Content,
  SeverityResolution,
} from 'common/utils/types';
import ResultsIntro, {
  Props as ResultsIntroProps,
} from 'components/ResultsIntro';
import { connect } from 'react-redux';
import { makeSelectContent } from 'store/selectors';

import { ifAbnormal, ifEquivocalIndeterminate, ifInvalid } from './utils';

const CONTENT_KEYS = {
  introCopyTestComplete: 'INTRO_COPY_TEST_COMPLETE',
  introAbnormal: 'INTRO_ABNORMAL',
  introInvalid: 'INTRO_INVALID',
  introIndeterminate: 'INTRO_INDETERMINATE',
  introNormal: 'INTRO_NORMAL',
  introMultipleReactivities: 'INTRO_MULTIPLE_REACTIVITIES',
  introMildReactivities: 'INTRO_MILD_REACTIVITIES',
  introNormalReactivities: 'INTRO_NORMAL_REACTIVITIES',
  introHighIntensity: 'INTRO_HIGH_INTENSITY',
  introModerateIntensity: 'INTRO_MODERATE_INTENSITY',
  introLowIntensity: 'INTRO_LOW_INTENSITY',
  introVeryLowIntensity: 'INTRO_VERY_LOW_INTENSITY',
  introFaq: 'INTRO_FAQ',
  introDiscussion: 'INTRO_DISCUSSION',
};

// Intro snippet definition begin based on the severity resolution => This should come from the backend.
const INTRO_MAP: Record<string, Record<number, string>> = {
  [MARKER_TYPES.INTENSITY]: {
    [INTENSITY_SEVERITIES.HIGH]: 'introHighIntensity',
    [INTENSITY_SEVERITIES.MODERATE]: 'introModerateIntensity',
    [INTENSITY_SEVERITIES.LOW]: 'introLowIntensity',
    [INTENSITY_SEVERITIES.VERY_LOW]: 'introVeryLowIntensity',
  },
  [MARKER_TYPES.FOOD_SENSITIVITY]: {
    [FS_SEVERITIES.MILD]: 'introMildReactivities',
    [FS_SEVERITIES.LOW]: 'introNormalReactivities',
    [FS_SEVERITIES.MODERATE]: 'introMultipleReactivities',
    [FS_SEVERITIES.HIGH]: 'introMultipleReactivities',
  },
  [MARKER_TYPES.NUMERICAL]: {
    [NUMERICAL_SEVERITIES.NORMAL]: 'introNormal',
    [NUMERICAL_SEVERITIES.LOW]: 'introAbnormal',
    [NUMERICAL_SEVERITIES.HIGH]: 'introAbnormal',
  },
  [MARKER_TYPES.MULTI_NUMERICAL]: {
    [NUMERICAL_SEVERITIES.NORMAL]: 'introNormal',
    [NUMERICAL_SEVERITIES.LOW]: 'introAbnormal',
    [NUMERICAL_SEVERITIES.HIGH]: 'introAbnormal',
  },
  [MARKER_TYPES.DESCRIPTIVE]: {
    [DESCRIPTIVE_SEVERITIES.NOT_DETECTED]: 'introNormal',
    [DESCRIPTIVE_SEVERITIES.EQUIVOCAL_OR_INDETERMINATE]: 'introIndeterminate',
    [DESCRIPTIVE_SEVERITIES.DETECTED]: 'introAbnormal',
    [DESCRIPTIVE_SEVERITIES.INVALID_OR_REJECTED]: 'introInvalid',
  },
};

type GroupCountType = Record<number, number>;
type customGroupCount = (groupCount: GroupCountType) => number;

const COUNT_MAP: Record<string, Record<number, customGroupCount>> = {
  [MARKER_TYPES.INTENSITY]: {
    [INTENSITY_SEVERITIES.HIGH]: (groupCount: GroupCountType) =>
      groupCount[INTENSITY_SEVERITIES.HIGH],
    [INTENSITY_SEVERITIES.MODERATE]: (groupCount: GroupCountType) =>
      groupCount[INTENSITY_SEVERITIES.MODERATE],
    [INTENSITY_SEVERITIES.LOW]: (groupCount: GroupCountType) =>
      groupCount[INTENSITY_SEVERITIES.LOW],
    [INTENSITY_SEVERITIES.VERY_LOW]: (groupCount: GroupCountType) =>
      groupCount[INTENSITY_SEVERITIES.VERY_LOW],
  },
  [MARKER_TYPES.FOOD_SENSITIVITY]: {
    [FS_SEVERITIES.MILD]: (groupCount: GroupCountType) =>
      groupCount[FS_SEVERITIES.MILD],
    [FS_SEVERITIES.LOW]: abnormalCountFS,
    [FS_SEVERITIES.MODERATE]: abnormalCountFS,
    [FS_SEVERITIES.HIGH]: abnormalCountFS,
  },
  [MARKER_TYPES.NUMERICAL]: {
    [NUMERICAL_SEVERITIES.NORMAL]: () => 0,
    [NUMERICAL_SEVERITIES.LOW]: abnormalCountNumericalSeverities,
    [NUMERICAL_SEVERITIES.HIGH]: abnormalCountNumericalSeverities,
  },
  [MARKER_TYPES.MULTI_NUMERICAL]: {
    [NUMERICAL_SEVERITIES.NORMAL]: () => 0,
    [NUMERICAL_SEVERITIES.LOW]: abnormalCountNumericalSeverities,
    [NUMERICAL_SEVERITIES.HIGH]: abnormalCountNumericalSeverities,
  },
  [MARKER_TYPES.DESCRIPTIVE]: {
    [DESCRIPTIVE_SEVERITIES.EQUIVOCAL_OR_INDETERMINATE]: (
      groupCount: GroupCountType,
    ) => groupCount[DESCRIPTIVE_SEVERITIES.EQUIVOCAL_OR_INDETERMINATE],
    [DESCRIPTIVE_SEVERITIES.INVALID_OR_REJECTED]: invalidCountDescriptive,
    [DESCRIPTIVE_SEVERITIES.NOT_DETECTED]: () => 0,
    [DESCRIPTIVE_SEVERITIES.DETECTED]: detectedCountDescriptive,
  },
};

function detectedCountDescriptive(groupCount: GroupCountType) {
  return (
    (groupCount[DESCRIPTIVE_SEVERITIES.DETECTED] || 0) +
    (groupCount[DESCRIPTIVE_SEVERITIES.EQUIVOCAL_OR_INDETERMINATE] || 0) +
    (groupCount[DESCRIPTIVE_SEVERITIES.INVALID_OR_REJECTED] || 0)
  );
}

function invalidCountDescriptive(groupCount: GroupCountType) {
  return (
    (groupCount[DESCRIPTIVE_SEVERITIES.EQUIVOCAL_OR_INDETERMINATE] || 0) +
    (groupCount[DESCRIPTIVE_SEVERITIES.INVALID_OR_REJECTED] || 0)
  );
}

function abnormalCountFS(groupCount: GroupCountType) {
  return (
    (groupCount[FS_SEVERITIES.HIGH] || 0) +
    (groupCount[FS_SEVERITIES.MODERATE] || 0) +
    (groupCount[FS_SEVERITIES.MILD] || 0)
  );
}

function abnormalCountNumericalSeverities(groupCount: GroupCountType) {
  return (
    (groupCount[NUMERICAL_SEVERITIES.HIGH] || 0) +
    (groupCount[NUMERICAL_SEVERITIES.LOW] || 0)
  );
}
// <= Intro snippet definition end

// props that are sent to the container where its called
export type OwnProps = {
  markerTypes: string[];
  contentToken: string;
  userName: string;
  severityResolution?: number | null;
  severityResolutions?: SeverityResolution[] | null;
  resultsApprovedDate: string;
  groupCount: Record<number, number>;
  testId: number;
  testName: string;
  testType: string;
  viewingSharedResult?: boolean;
  consultAvailable?: boolean;
  isThirdParty: boolean;
  labName: string;
  clia: string;
  conditions: string[];
  headerIcon?: string;
};

function setResolvedIntro(
  severityResolution: number,
  content: Content,
  markerType: string,
) {
  const contentKey = INTRO_MAP[markerType][severityResolution] || '';
  const { [contentKey]: contentVal } = content;
  return contentVal;
}

function setResolvedTotal(
  markerType: string,
  severityResolution: number,
  groupCount: GroupCountType,
) {
  return COUNT_MAP[markerType][severityResolution](groupCount) || 0;
}

// resolve severity intro + set snippet begin. all this logic should move to backend
function setIntroCopy(
  markerType: string,
  groupCount: Record<number, number>,
  content: Content,
) {
  const {
    introMildReactivities,
    introMultipleReactivities,
    introNormalReactivities,
    introHighIntensity,
    introModerateIntensity,
    introLowIntensity,
    introVeryLowIntensity,
    introAbnormal,
    introNormal,
    introInvalid,
    introIndeterminate,
  } = content;

  let intro = '';

  switch (markerType) {
    case MARKER_TYPES.INTENSITY:
      if (groupCount[INTENSITY_SEVERITIES.HIGH]) {
        intro = introHighIntensity || '';
      } else if (groupCount[INTENSITY_SEVERITIES.MODERATE]) {
        intro = introModerateIntensity || '';
      } else if (groupCount[INTENSITY_SEVERITIES.LOW]) {
        intro = introLowIntensity || '';
      } else {
        intro = introVeryLowIntensity || '';
      }
      break;

    case MARKER_TYPES.FOOD_SENSITIVITY:
      if (
        !groupCount[FS_SEVERITIES.HIGH] &&
        !groupCount[FS_SEVERITIES.MODERATE] &&
        groupCount[FS_SEVERITIES.MILD]
      ) {
        intro = introMildReactivities || '';
      } else if (
        !groupCount[FS_SEVERITIES.HIGH] &&
        !groupCount[FS_SEVERITIES.MODERATE] &&
        !groupCount[FS_SEVERITIES.MILD]
      ) {
        intro = introNormalReactivities || '';
      } else {
        intro = introMultipleReactivities || '';
      }
      break;

    case MARKER_TYPES.NUMERICAL:
    case MARKER_TYPES.MULTI_NUMERICAL:
      if (
        !groupCount[NUMERICAL_SEVERITIES.HIGH] &&
        !groupCount[NUMERICAL_SEVERITIES.LOW]
      ) {
        intro = introNormal || '';
      } else {
        intro = introAbnormal || '';
      }
      break;

    case MARKER_TYPES.DESCRIPTIVE:
      if (ifAbnormal(groupCount)) {
        intro = introAbnormal ?? '';
      } else if (ifEquivocalIndeterminate(groupCount)) {
        intro = introIndeterminate ?? '';
      } else if (ifInvalid(groupCount)) {
        intro = introInvalid ?? '';
      } else {
        intro = introNormal ?? '';
      }
      break;
    default:
      break;
  }
  return intro;
}

function setTotalAbnormal(
  markerType: string,
  groupCount: { [key: number]: number },
  content: Content,
) {
  const { introIndeterminate } = content;

  let totalAbnormal = 0;

  switch (markerType) {
    case MARKER_TYPES.INTENSITY:
      if (groupCount[INTENSITY_SEVERITIES.HIGH]) {
        totalAbnormal = groupCount[INTENSITY_SEVERITIES.HIGH];
      } else if (groupCount[INTENSITY_SEVERITIES.MODERATE]) {
        totalAbnormal = groupCount[INTENSITY_SEVERITIES.MODERATE];
      } else if (groupCount[INTENSITY_SEVERITIES.LOW]) {
        totalAbnormal = groupCount[INTENSITY_SEVERITIES.LOW];
      } else {
        totalAbnormal = groupCount[INTENSITY_SEVERITIES.VERY_LOW];
      }
      break;

    case MARKER_TYPES.FOOD_SENSITIVITY:
      if (
        !groupCount[FS_SEVERITIES.HIGH] &&
        !groupCount[FS_SEVERITIES.MODERATE] &&
        groupCount[FS_SEVERITIES.MILD]
      ) {
        totalAbnormal = groupCount[FS_SEVERITIES.MILD];
      } else {
        totalAbnormal =
          (groupCount[FS_SEVERITIES.HIGH] || 0) +
          (groupCount[FS_SEVERITIES.MODERATE] || 0) +
          (groupCount[FS_SEVERITIES.MILD] || 0);
      }
      break;

    case MARKER_TYPES.NUMERICAL:
    case MARKER_TYPES.MULTI_NUMERICAL:
      if (
        !groupCount[NUMERICAL_SEVERITIES.HIGH] &&
        !groupCount[NUMERICAL_SEVERITIES.LOW]
      ) {
        // intro = introNormal || '';
      } else {
        totalAbnormal =
          (groupCount[NUMERICAL_SEVERITIES.HIGH] || 0) +
          (groupCount[NUMERICAL_SEVERITIES.LOW] || 0);
      }
      break;

    case MARKER_TYPES.DESCRIPTIVE:
      if (
        // none detected AND indeterminate
        !groupCount[DESCRIPTIVE_SEVERITIES.DETECTED] &&
        groupCount[DESCRIPTIVE_SEVERITIES.EQUIVOCAL_OR_INDETERMINATE]
      ) {
        if (introIndeterminate) {
          totalAbnormal =
            groupCount[DESCRIPTIVE_SEVERITIES.EQUIVOCAL_OR_INDETERMINATE];
        } else {
          totalAbnormal =
            (groupCount[DESCRIPTIVE_SEVERITIES.EQUIVOCAL_OR_INDETERMINATE] ||
              0) +
            (groupCount[DESCRIPTIVE_SEVERITIES.INVALID_OR_REJECTED] || 0);
        }
      } else if (
        // none detected AND (some indeterminate, equivocal, invalid or rejected)
        !groupCount[DESCRIPTIVE_SEVERITIES.DETECTED] &&
        (groupCount[DESCRIPTIVE_SEVERITIES.EQUIVOCAL_OR_INDETERMINATE] ||
          groupCount[DESCRIPTIVE_SEVERITIES.INVALID_OR_REJECTED])
      ) {
        totalAbnormal =
          (groupCount[DESCRIPTIVE_SEVERITIES.EQUIVOCAL_OR_INDETERMINATE] || 0) +
          (groupCount[DESCRIPTIVE_SEVERITIES.INVALID_OR_REJECTED] || 0);
      } else if (
        // if anything other than normal is positive
        groupCount[DESCRIPTIVE_SEVERITIES.DETECTED] ||
        groupCount[DESCRIPTIVE_SEVERITIES.EQUIVOCAL_OR_INDETERMINATE] ||
        groupCount[DESCRIPTIVE_SEVERITIES.INVALID_OR_REJECTED]
      ) {
        totalAbnormal =
          (groupCount[DESCRIPTIVE_SEVERITIES.DETECTED] || 0) +
          (groupCount[DESCRIPTIVE_SEVERITIES.EQUIVOCAL_OR_INDETERMINATE] || 0) +
          (groupCount[DESCRIPTIVE_SEVERITIES.INVALID_OR_REJECTED] || 0);
      }
      break;

    default:
      break;
  }
  return totalAbnormal;
}
// resolve severity intro + set snippet end

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): ResultsIntroProps {
  const {
    markerTypes,
    userName,
    labName,
    resultsApprovedDate,
    contentToken,
    severityResolution,
    severityResolutions,
    groupCount,
    testId,
    testName,
    testType,
    viewingSharedResult,
    consultAvailable,
    isThirdParty,
    clia,
    conditions,
    headerIcon,
  } = ownProps;

  let intro = '';

  const content = makeSelectContent(contentToken, CONTENT_KEYS)(state);
  const { introCopyTestComplete: header, introFaq, introDiscussion } = content;

  let totalAbnormal = 0;
  const markerType = markerTypes[0];

  const introArray: string[] = [];
  const totalAbnormalArray: number[] = [];

  markerTypes.forEach((mT) => {
    if (severityResolution) {
      introArray.push(setResolvedIntro(severityResolution, content, mT));
      totalAbnormalArray.push(
        setResolvedTotal(mT, severityResolution, groupCount),
      );
    } else {
      if (severityResolutions && severityResolutions.length > 0) {
        const resolution = severityResolutions.find(
          (res) => res.type === mT,
        )?.value;
        if (resolution) {
          introArray.push(setResolvedIntro(resolution, content, mT));
          totalAbnormalArray.push(setResolvedTotal(mT, resolution, groupCount));
        }
      } else {
        // TODO: Remove all the default severity intro logic after having this fully tested
        // Its resolution must come from BE through the `severity_resolutions` API attr
        introArray.push(setIntroCopy(mT, groupCount, content));
        totalAbnormalArray.push(setTotalAbnormal(mT, groupCount, content));
      }
    }
  });

  [intro] = introArray.sort((a, b) => b.length - a.length);
  [totalAbnormal] = totalAbnormalArray;

  let approvalDate: string | undefined = resultsApprovedDate;

  if (resultsApprovedDate === 'unknown') {
    approvalDate = undefined;
  }

  let introScrollToContent = '';
  if (!viewingSharedResult) {
    if (consultAvailable) {
      introScrollToContent = introDiscussion || '';
    } else {
      introScrollToContent = introFaq || '';
    }
  }

  return {
    clia,
    conditions,
    headerIcon,
    intro,
    introHeader: header,
    introScrollToContent,
    isThirdParty,
    labName,
    markerType,
    name: userName,
    resultsApprovedDate: approvalDate,
    testId,
    testName,
    testType,
    totalAbnormal,
  };
}

export default connect(mapStateToProps)(ResultsIntro);
