import React from 'react';

/**
 * Icon to be used in the header of the page
 */
function Icon() {
  return (
    <svg
      width="90"
      height="91"
      viewBox="0 0 90 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="18" y="18.5" width="54" height="54" rx="27" fill="#F5FAF7" />
      <path
        d="M43.4423 29.75V29.75C44.1856 29.75 44.8886 29.9307 45.5101 30.2498C47.2989 31.1708 48.2628 33.2102 47.9375 35.2341L44.3609 49.9081C43.7032 52.6073 41.3382 54.5 38.6248 54.5C35.9124 54.5 33.5475 52.6073 32.8898 49.9081L29.3122 35.2341C28.9879 33.2102 29.9518 31.1708 31.7396 30.2498C32.362 29.9307 33.064 29.75 33.8084 29.75"
        stroke="#16644A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M39 54.8693V57.9496C39 60.5059 39.965 63.0425 43.646 63.0425C47.327 63.0425 48.9694 58.5783 49.5 55.625L52.0859 45.2616C52.3664 43.2469 54.0434 41.75 56.0232 41.75C58.2197 41.75 60 43.5813 60 45.8408V52.5453"
        stroke="#16644A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M62.25 55.625C62.25 57.0758 61.0752 58.25 59.625 58.25C58.1748 58.25 57 57.0758 57 55.625C57 54.1742 58.1748 53 59.625 53C61.0752 53 62.25 54.1742 62.25 55.625Z"
        stroke="#16644A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.0089 31.25H35.4217C34.4986 31.25 33.75 30.5787 33.75 29.75C33.75 28.9223 34.4986 28.25 35.4217 28.25H36.0089C36.4174 28.25 36.75 28.5473 36.75 28.9145V30.5855C36.75 30.9517 36.4174 31.25 36.0089 31.25Z"
        stroke="#16644A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.2402 28.25H41.8277C42.7522 28.25 43.5 28.9213 43.5 29.75C43.5 30.5777 42.7522 31.25 41.8277 31.25H41.2402C40.8316 31.25 40.5 30.9517 40.5 30.5855V28.9145C40.5 28.5473 40.8316 28.25 41.2402 28.25Z"
        stroke="#16644A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="63" cy="26.5" r="7.875" fill="#F16173" />
      <rect
        x="62.0625"
        y="22.5625"
        width="1.875"
        height="5.25"
        rx="0.3125"
        fill="white"
      />
      <rect
        x="62.0625"
        y="28.9375"
        width="1.875"
        height="1.875"
        rx="0.3125"
        fill="white"
      />
    </svg>
  );
}

export default Icon;
