import { useQuery } from '@apollo/client';
import { CareDashboardType } from 'common/hooks/useCareDashboard/types';
import { useCareDashboardContext } from 'common/providers/CareDashboardProvider';
import { TrackVisibilityWrapper } from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { CARE_PATHWAYS_DASHBOARD } from 'common/utils/constants/dataTest';
import { CARE_PATHWAYS_PAGE_SECTION_QUERY } from 'common/utils/contentfulGraphQlQueries';
import CancellationModule from 'components/CarePathwaysCancellationModule';
import DiscoverMore from 'components/DiscoverMore';
import FAQSection from 'components/FAQSection';
import Layout from 'components/Layout';
import React from 'react';

import * as S from './CareDashboard.styles';

export const CareDashboard = () => {
  const { loadingDashboard } = useCareDashboardContext();

  const useInitialState = () => {
    const pageId = '3nNu6CdBRe1YQ53nIqBbZj';
    const { data } = useQuery(CARE_PATHWAYS_PAGE_SECTION_QUERY, {
      variables: {
        pageId,
      },
    });

    const state: CareDashboardType = {
      cardsForSectionCollection: [],
      digDeeperSectionsCollection:
        data?.pageSectionCarePathways.digDeeperSectionsCollection?.items || [],
    };

    return state;
  };

  const { digDeeperSectionsCollection } = useInitialState();

  return (
    <TrackVisibilityWrapper
      label={ANALYTICS.LABELS.CARE_DASHBOARD.DASHBOARD.label}
    >
      <Layout loading={loadingDashboard}>
        <S.Container data-test={CARE_PATHWAYS_DASHBOARD.DASHBOARD}>
          {loadingDashboard ? (
            <S.Loading />
          ) : (
            <>
              {digDeeperSectionsCollection.map((section) => {
                switch (section.sectionId) {
                  case 'Care Pathways Dashboard - FAQ Section': {
                    return <FAQSection key={section.sectionId} {...section} />;
                  }
                  case 'Care Pathways Dashboard - Discover More': {
                    return (
                      <DiscoverMore
                        key={section.sectionId}
                        articles={section.listOfContentSectionsCollection.items}
                        {...section}
                      />
                    );
                  }
                  case 'Care Pathways Dashboard - Cancellation module': {
                    return (
                      <CancellationModule
                        key={section.sectionId}
                        {...section}
                      />
                    );
                  }
                  default: {
                    return null;
                  }
                }
              })}
            </>
          )}
        </S.Container>
      </Layout>
    </TrackVisibilityWrapper>
  );
};

export default CareDashboard;
