import {
  size,
  colors,
  TabMenu as LeavesTabMenu,
  mediaQueries,
} from '@everlywell/leaves';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const InnerContent = styled.div`
  margin: 0 auto;
`;

export const TabMenu = styled(LeavesTabMenu)`
  ul {
    padding: 0;
    margin: 0;
  }
`;

export const TabContentWrapper = styled.div`
  padding-top: ${size.lg}px;
`;

export const BackLink = styled(Link)`
  color: ${colors.green4};
  display: flex;
  z-index: 1;
  text-decoration: none;
  width: 50px;
  position: relative;
  margin-top: ${size.xs1}px;
  margin-bottom: ${size.xs1}px;

  ${mediaQueries.forDesktopUp} {
    display: none;
  }
`;
