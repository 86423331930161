import {
  TelehealthFormAnswer,
  getTelehealthForm,
  saveTelehealthDocuments,
  saveTelehealthForm,
} from 'common/apis/telehealthApis';
import useProgramType from 'common/hooks/useProgramType';
import useUser from 'common/hooks/useUser';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { slugFormatting } from 'common/utils/helpers';
import { FormType } from 'common/utils/types';
import Layout from 'components/Layout';
import FormBuilder from 'components/telehealth/FormBuilder/FormBuilder';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNativeIntakeContext } from 'pages/VirtualCare/NativeIntake/context/NativeIntakeContext';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useMutation, useQuery } from 'react-query';

import ErrorPage from '../ErrorPage';
import FormPageBuilderSkeleton from './FormPageBuilder.skeleton';
import { setDefaultValues, setSubmitCopy } from './utils';

export type FormPageBuilderProps = {
  formSlug: string;
  formType: string;
  programSlug: string;
};

/**
 * This is the FormPageBuilder page component that renders the
 * personal information form for the Telehealth flow.
 */
function FormPageBuilder({
  formSlug,
  formType,
  programSlug: program,
}: FormPageBuilderProps) {
  const { excludeReviewPage } = useFlags<{
    excludeReviewPage: boolean;
  }>();
  const { data: userData, isLoading: isLoadingUserData } = useUser();
  const analyticsFormSlug = slugFormatting(formSlug);
  const programType = useProgramType(program);
  const { goToNextStep: contextGoToNextStep } = useNativeIntakeContext();

  /**
   * Load the form field configuration data
   * to build the form, only if the form has not
   * already been completed. Otherwise, redirect
   * to the payment type page link.
   */

  const {
    data,
    isLoading: isLoadingForm,
    isError,
  } = useQuery(
    ['telehealth-form', formSlug, { program }],
    () => getTelehealthForm(formSlug, { program }),
    {
      enabled: !!program,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        analytics.track({
          event: ANALYTICS.EVENTS.VIEWED_PAGE,
          data: {
            page: analyticsFormSlug,
            program,
          },
        });
      },
    },
  );

  /**
   * Determine which screen should be displayed next
   */
  const goToNextScreen = () => {
    contextGoToNextStep();
  };

  const handleSuccess = async () => {
    await analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: `${analyticsFormSlug} continue`,
        program,
      },
    });

    goToNextScreen();
  };

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: `${analyticsFormSlug}Page`,
        programName: program,
      },
    });
  }, [analyticsFormSlug, program]);

  /**
   * Handle saving the form data
   * and redirecting to the payment type page
   * if successful.
   */
  const {
    mutate,
    isLoading: isSubmitting,
    isError: isErrorSubmitting,
  } = useMutation(saveTelehealthForm, {
    onSuccess: handleSuccess,
  });

  const {
    mutate: uploadFiles,
    isLoading: isSubmittingFiles,
    isError: isErrorUploadingFiles,
  } = useMutation(saveTelehealthDocuments, {
    onSuccess: handleSuccess,
  });

  const getFiles = (answers: TelehealthFormAnswer[]) =>
    answers
      .filter(
        (answer) =>
          answer.type === 'document' && answer.answer instanceof FileList,
      )
      .map(({ answer }) => answer[0] as File);

  const onSubmit = (answers: TelehealthFormAnswer[]) => {
    if (formType === 'document') {
      const files = getFiles(answers);

      uploadFiles({ files: files, program: program });
    } else {
      mutate({ form_id: formSlug, answers, program });
    }
  };

  const isLoading = isLoadingForm || isLoadingUserData;

  if (isLoading) {
    return <FormPageBuilderSkeleton />;
  }

  if (isError) {
    // TODO: replace with new error page
    return <ErrorPage />;
  }

  if (data?.data) {
    const { custom_modules } = data.data;
    const defaultValues = setDefaultValues(
      custom_modules,
      userData,
      formSlug as FormType,
    );

    const submitCopy = excludeReviewPage
      ? setSubmitCopy(formSlug as FormType, programType)
      : undefined;
    return (
      <Layout>
        <FormBuilder
          {...data.data}
          submitCopy={submitCopy}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting || isSubmittingFiles}
          defaultValues={defaultValues}
          error={
            isErrorSubmitting || isErrorUploadingFiles
              ? { message: 'Unable to submit form. Please try again.' }
              : null
          }
        />
      </Layout>
    );
  }

  return null;
}

export default FormPageBuilder;
