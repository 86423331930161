/* eslint-disable @typescript-eslint/no-var-requires */
import {
  typography,
  size,
  mediaQueries,
  colors,
  breakpoints,
} from '@everlywell/leaves';
import { bodyCopyLarge } from 'common/styles/app-styles';
import styled from 'styled-components';

type Props = {
  isHidden?: boolean;
};

const MarkerGroupHeading = styled.h2<Props>`
  padding: 0 8px;
  margin: 0 0 24px;
  grid-column-start: 1;
  grid-row-start: 1;
  ${bodyCopyLarge};
  color: ${colors.gray4};
  font-weight: ${typography.weight.regular};

  ${mediaQueries.forTabletHorizontalUp} {
    padding: 0 16px;
  }

  ${(props) =>
    props.isHidden
      ? 'opacity: 0; font-size: 0; transition: opacity 0.3s ease-in-out, font-size 0.3s ease-in-out 0.3s;'
      : 'opacity: 1; transition: opacity 0.3s ease-in-out 0.3s'};
`;

const GroupName = styled.span`
  text-transform: capitalize;
`;

const LineBreak = styled.br`
  display: none;
  ${mediaQueries.forPhoneOnly} {
    display: block;
  }
`;

const MarkerGroupWrapper = styled.div`
  ${mediaQueries.forDesktopUp} {
    max-width: ${breakpoints.forDesktopDown}px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const MarkerGroupHeadingContainer = styled.div`
  display: grid;
`;

const MarkerGroupHeadingContainerShadow = styled.div`
  display: grid;
  box-shadow: 0 ${size.xs3}px ${size.md}px 0 rgba(0, 0, 0, 0.05);
`;

const FooterWrapper = styled.div`
  margin-bottom: ${size.lg}px;
`;

export {
  FooterWrapper,
  GroupName,
  LineBreak,
  MarkerGroupHeading,
  MarkerGroupHeadingContainer,
  MarkerGroupHeadingContainerShadow,
  MarkerGroupWrapper,
};
