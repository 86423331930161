import CreditCardForm from 'pages/CreditCardForm';
import PaymentPage from 'pages/PaymentTypePage';

import EnterpriseConsentsFormPage from '../pages/EnterpriseConsentsFormPage';
import EnterpriseInsuranceFormPage from '../pages/EnterpriseInsuranceFormPage';

export type FormsMapConfig = Record<
  string,
  { pathname: string; component: React.FC }
>;

/* There are forms that need to be handled differently in the Form Builder component
 * so those forms are included in the formsMap.
 */
export const formsMap: FormsMapConfig = {
  athena_consents: {
    pathname: 'consents',
    component: EnterpriseConsentsFormPage,
  },
  insurance: { pathname: 'payment-type', component: PaymentPage },
  payment: { pathname: 'payment', component: CreditCardForm },
  athena_insurance: {
    pathname: 'member-insurance/add',
    component: EnterpriseInsuranceFormPage,
  },
};
