import {
  typography,
  size,
  mediaQueries,
  colors,
  Container as LeavesContainer,
} from '@everlywell/leaves';
import styled from 'styled-components';

const Footer = styled.footer`
  margin-top: auto;
  padding: ${size.xl1}px 0;
  background-color: ${colors.white};
  border-top: 2px solid ${colors.gray1};
`;

const Container = styled(LeavesContainer)`
  display: grid;
  grid-template-areas:
    'nav contact-availability'
    'copyright sample-instructions-disclaimer';
  gap: ${size.lg}px;

  ${mediaQueries.forTabletVerticalUp} {
    padding-top: 0;
    padding-bottom: 0;
  }

  ${mediaQueries.forTabletVerticalDown} {
    grid-template-areas:
      'nav'
      'contact-availability'
      'sample-instructions-disclaimer'
      'copyright';
  }
`;

const ContactText = styled.p`
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: 0.7px;
  margin: 0;
  ${typography.captionText}

  a {
    font-weight: 600;
    text-decoration: none;
    color: ${colors.blue1};

    &:hover {
      text-decoration: underline;
      color: ${colors.blue1};
    }
  }

  ${mediaQueries.forTabletVerticalDown} {
    text-align: center;
  }
`;

const NavLinks = styled.nav`
  grid-area: nav;
  display: flex;
  flex-wrap: wrap;
  gap: ${size.md}px ${size.lg}px;

  a {
    ${typography.captionText}
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${colors.gray4};
    }
  }

  ${mediaQueries.forTabletVerticalDown} {
    justify-content: center;
  }
`;

const ContactAvailability = styled.div`
  grid-area: contact-availability;
  text-align: right;
`;

const Copyright = styled(ContactText)`
  grid-area: copyright;
`;

const SampleInstructions = styled(ContactText)`
  grid-area: sample-instructions-disclaimer;
  text-align: right;
`;

export {
  Footer,
  Container,
  NavLinks,
  ContactAvailability,
  Copyright,
  SampleInstructions,
  ContactText,
};
