import { ErrorText, FormFieldType, FormValueType } from 'components/Forms';
import React, { useState, SyntheticEvent } from 'react';

import * as S from './styles';

export type Props = {
  label: string;
  name: string;
  value?: FormValueType;
  error?: string;
  placeholder?: string;
  type?: string;
  onChange: (formField: FormFieldType) => void;
  onValidate: (formField: FormFieldType) => void;
  [key: string]: any;
};

function Input(props: Props) {
  const {
    label,
    name,
    value = '',
    error,
    placeholder,
    onChange,
    onValidate,
    type = 'text',
    ...rest
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const isEmpty = value === '';
  const hasError = !!error;
  const showPlaceholder = isFocused && isEmpty;

  function handleChange(event: SyntheticEvent<HTMLInputElement>) {
    const { currentTarget } = event;
    onChange({ name: currentTarget.name, value: currentTarget.value });
  }

  function handleBlur(event: SyntheticEvent<HTMLInputElement>) {
    setIsFocused(false);

    if (onValidate) {
      const { currentTarget } = event;
      onValidate({ name: currentTarget.name, value: currentTarget.value });
    }
  }

  function handleFocus() {
    setIsFocused(true);
    if (props.onFocus) props.onFocus();
  }

  return (
    <S.Wrapper>
      <S.Label
        htmlFor={name}
        data-has-error={hasError}
        data-is-empty={isEmpty}
        data-is-focused={isFocused}
      >
        {label}
      </S.Label>
      <S.Input
        maxLength={80}
        {...rest}
        onChange={handleChange}
        name={name}
        value={`${value || ''}`}
        id={name}
        placeholder={showPlaceholder ? placeholder : undefined}
        data-has-error={hasError}
        data-is-empty={isEmpty}
        data-is-focused={isFocused}
        onFocus={handleFocus}
        onBlur={handleBlur}
        type={type}
      />
      <ErrorText>{error}</ErrorText>
    </S.Wrapper>
  );
}

export default Input;
