import React, { SVGProps } from 'react';

const MicroscopeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 69 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="34.5" cy="34" r="34" fill="#EDF5F1" />
    <path
      d="M33.75 29.125L32.25 24.625C21 31.75 22.375 42.5 24.375 46.75H28.5C24.75 37.375 30.875 30.625 33.75 29.125Z"
      fill="#EEF5F6"
      stroke="#007377"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5 46.75H47.25L45.0078 51.25H22.5V46.75Z"
      fill="#C9E9ED"
      stroke="#007377"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <rect
      x="31.0305"
      y="20.5796"
      width="6.75"
      height="12.75"
      transform="rotate(-19 31.0305 20.5796)"
      stroke="#007377"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      width="6.75"
      height="3.75"
      transform="matrix(0.945519 -0.325568 -0.325568 -0.945519 36.4023 36.1807)"
      fill="#EEF5F6"
      stroke="#007377"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <rect
      x="31.7162"
      y="17.9639"
      width="3.75"
      height="2.25"
      transform="rotate(-19 31.7162 17.9639)"
      stroke="#007377"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.2587 42.4165H45.0078"
      stroke="#007377"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MicroscopeIcon;
