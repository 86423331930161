import { useCommonT2TAnalytics } from 'common/hooks/useCommonT2TAnalytics';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { AddToCalendar } from 'components/AddToCalendar';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import useEffectOnce from 'react-use/lib/useEffectOnce';

import { useNativeSchedulingContext } from '../../context/NativeSchedulingContext';
import { ComfirmationModal } from './components/ConfirmationModal';
import * as S from './EnterpriseConfirmationPage.styles';

/**
 * This is the  Enterprise confirmation page
 * where a user can also add their appointment to their calendar.
 * or go to Athena dahsboard
 */
function EnterpriseConfirmationPage() {
  const { commonT2TAnalyticsData } = useCommonT2TAnalytics();

  const { state } = useNativeSchedulingContext();
  const { confirmedAppointment } = state;
  const [openModal, setOpenModal] = useState(false);

  useEffectOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        label: ANALYTICS.PAGES.EHS_T2T.CONFIRMATION_PAGE,
        ...commonT2TAnalyticsData,
      },
    });
  });

  const handleOpenModal = () => {
    setOpenModal((preState) => !preState);
  };

  /**
   * In case user refreshes the page, confirmedAppointment
   * will be null and we should redirect them to the member dashboard
   */
  if (!confirmedAppointment) return <Navigate to="/dashboard" replace />;

  return (
    <>
      <S.Container>
        <AddToCalendar ctaLink="/dashboard" />
        <S.PromoContainer>
          <S.ImageWrapper>
            <S.PromoButton appearance="primary" onClick={handleOpenModal}>
              Complete intake on the MyCare for Everly Portal
            </S.PromoButton>
            <S.H5>You can do it now - this should take just 2 minutes!</S.H5>
            <S.H2>
              You’re not done yet! Complete your intake before your upcoming
              appointment
            </S.H2>
          </S.ImageWrapper>
        </S.PromoContainer>
      </S.Container>
      <ComfirmationModal openModal={openModal} openHandler={handleOpenModal} />
    </>
  );
}

export default EnterpriseConfirmationPage;
