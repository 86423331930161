import { TelehealthCustomModule } from 'common/apis/telehealthApis';
import { Context, createContext, useContext } from 'react';

type FormModalContextType = {
  showModal: boolean;
  setModalsToBeShown: (modalsToBeShown: boolean) => void;
  formName: string;
  allCustomModules: TelehealthCustomModule[];
};

const defaultFormModal = {
  showModal: false,
  setModalsToBeShown: () => {},
  formName: '',
  allCustomModules: [],
};

const FormModalContext: Context<FormModalContextType> =
  createContext<FormModalContextType>(defaultFormModal);

export const FormModalProvider = FormModalContext.Provider;

export const useFormModalContext = () => useContext(FormModalContext);
