import React from 'react';

import * as S from './VisuallyHidden.styles';

export type VisuallyHiddenProps = {
  /** Components to hide visually */
  children: React.ReactNode;
};

/**
 * Visually hide children but this will be accessible to screen readers
 */
function VisuallyHidden(props: VisuallyHiddenProps) {
  return <S.Container>{props.children}</S.Container>;
}

export default VisuallyHidden;
