import { colors } from '@everlywell/leaves';
import React from 'react';

import * as S from './ProgressCircle.styles';

export type ProgressCircleProps = {
  /** number between 0 and 100 */
  progress: number;
  className?: string;
};

/**
 * Icon used to show the progress on the health profile questionarie
 */
function ProgressCircle({
  progress: progressProp,
  className,
}: ProgressCircleProps) {
  const radius = 29.0526;
  const circumference = Math.ceil(2 * Math.PI * radius);
  const progress = Math.ceil(circumference * (progressProp / 100));

  return (
    <S.SVG
      viewBox="0 0 73 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <defs></defs>

      <circle
        cx="31.5889"
        cy="40.9477"
        r="29.0526"
        stroke={colors.teal1}
        strokeWidth="4"
        strokeLinecap="round"
        fill="none"
      ></circle>
      <path
        className="circle-fill"
        d="M 60.642 40.948 C 60.642 24.902 47.635 11.895 31.589 11.895 C 15.543 11.895 2.536 24.902 2.536 40.948 C 2.537 56.993 15.544 70 31.589 70 C 47.634 70 60.641 56.993 60.642 40.948 Z"
        stroke={progressProp > 0 ? colors.teal4 : colors.teal1}
        strokeWidth="4"
        strokeLinecap="round"
        strokeDasharray={`${progress}, ${circumference}`}
        transform="matrix(0, -1, 1, 0, -9.358501, 72.5365)"
        fill="none"
      ></path>

      <path
        d="M63.9068 0C63.9068 0.142168 63.9058 0.287523 63.9036 0.435748C63.9312 4.99557 65.835 13.0774 72.4637 13.0774C65.6182 13.0774 63.9068 20.5077 63.9068 25.2632C63.9068 20.5077 62.7659 13.0774 54.7795 13.0774C62.5271 13.0774 63.8325 5.24557 63.9036 0.435748C63.9027 0.286595 63.9038 0.14121 63.9068 0Z"
        fill={colors.teal1}
      ></path>

      <S.Percentage x="32" y="48">{`${progressProp}%`}</S.Percentage>
    </S.SVG>
  );
}

export default ProgressCircle;
