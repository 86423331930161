import { PROGRAM_TYPE } from 'common/utils/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNativeIntakeContext } from 'pages/VirtualCare/NativeIntake/context/NativeIntakeContext';
import { getRelativePath } from 'pages/VirtualCare/NativeIntake/utils/helpers';
import { NATIVE_SCHEDULING_PATH } from 'pages/VirtualCare/NativeScheduling';

type AfterPaymentType = {
  skipInsurancePath: string;
  insurancePagePath: string;
};
export const useRedirectAfterPaymentType = (
  program: string,
): AfterPaymentType => {
  const { showDynamicIntakeFlow } = useFlags<{
    showDynamicIntakeFlow: boolean;
  }>();
  const { nextStep } = useNativeIntakeContext();
  const isWCPlus = program === PROGRAM_TYPE['weight-management-full'];

  // If the program is weight-management-full, they have already paid in a previous step
  // then the next step is the native scheduling page
  if (showDynamicIntakeFlow && !isWCPlus) {
    return {
      skipInsurancePath: getRelativePath(nextStep),
      insurancePagePath: getRelativePath('insurance/add'),
    };
  }

  if (showDynamicIntakeFlow && isWCPlus) {
    return {
      skipInsurancePath: NATIVE_SCHEDULING_PATH,
      insurancePagePath: getRelativePath('insurance/add'),
    };
  }

  // If the program is weight-management-full, they have already paid in a previous step
  const skipInsurancePath = isWCPlus
    ? NATIVE_SCHEDULING_PATH
    : '/virtual-care/payment';

  const insurancePagePath = `/virtual-care/insurance/add`;

  return {
    insurancePagePath,
    skipInsurancePath,
  };
};
