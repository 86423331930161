import { Col, Row } from '@everlywell/leaves';
import { TEST_INFORMATION_FORM as DATA_TEST } from 'common/utils/constants/dataTest';
import React from 'react';

import * as S from '../styles';

type KitRegistrationCheckbox = {
  label: string;
  id: any;
  checked: boolean;
  setValue: React.Dispatch<React.SetStateAction<any>>;
};

type ConsentProps = {
  sms: KitRegistrationCheckbox;
  hippa: KitRegistrationCheckbox;
  product: KitRegistrationCheckbox;
  hippaURL: string;
  productURL: string;
  previouslyRegistered?: boolean;
  customTermsEnabled?: boolean;
  customTerms?: KitRegistrationCheckbox;
};

const Consent = ({
  sms,
  hippa,
  product,
  hippaURL,
  productURL,
  previouslyRegistered,
  customTermsEnabled,
  customTerms,
}: ConsentProps) => (
  <Col>
    <Row>
      <S.CheckBoxCol>
        <S.CheckBox
          uid={sms.id}
          checked={sms.checked}
          label=""
          aria-labelledby="smsCheckboxLabel"
          name={sms.label}
          onChange={() => sms.setValue(!sms.checked)}
          data-test={`${DATA_TEST.INPUT}-${sms.id}`}
        />
        <label htmlFor={`${sms.label}-${sms.id}`} id={`${sms.label}Label`}>
          {"Get real time text updates on the status of your test. We'll "}
          {'never include personal information'}
        </label>
      </S.CheckBoxCol>
      {customTermsEnabled && customTerms && (
        <S.CheckBoxCol>
          <S.CheckBox
            uid={customTerms.id}
            checked={customTerms.checked}
            label=""
            aria-labelledby="customerTermsLabel"
            name="customTerms"
            onChange={() => customTerms.setValue(!customTerms.checked)}
            data-test={`${DATA_TEST.INPUT}-${customTerms.id}`}
          />
          <label
            htmlFor={`customTerms-${customTerms.id}`}
            id="customerTermsLabel"
          >
            <S.CustomTermsContainer
              dangerouslySetInnerHTML={{ __html: customTerms.label || '' }}
            />
          </label>
        </S.CheckBoxCol>
      )}
      <S.CheckBoxCol>
        <S.CheckBox
          uid={hippa.id}
          checked={previouslyRegistered ? true : hippa.checked}
          disabled={previouslyRegistered}
          label=""
          aria-labelledby="hippaLabel"
          name={hippa.label}
          onChange={() => hippa.setValue(!hippa.checked)}
          data-test={`${DATA_TEST.INPUT}-${hippa.id}`}
        />
        <label
          htmlFor={`${hippa.label}-${hippa.id}`}
          id={`${hippa.label}Label`}
        >
          I have read and accept the{' '}
          <S.Anchor target="_blank" href={hippaURL}>
            HIPAA & Telehealth Authorizations
          </S.Anchor>
        </label>
      </S.CheckBoxCol>
      <S.CheckBoxCol>
        <S.CheckBox
          uid={product.id}
          checked={previouslyRegistered ? true : product.checked}
          disabled={previouslyRegistered}
          label=""
          aria-labelledby="productLabel"
          name={product.label}
          onChange={() => product.setValue(!product.checked)}
          data-test={`${DATA_TEST.INPUT}-${product.id}`}
        />
        <label
          htmlFor={`${product.label}-${product.id}`}
          id={`${product.label}Label`}
        >
          I have read and accept the{' '}
          <S.Anchor target="_blank" href={productURL}>
            Product Consent
          </S.Anchor>
        </label>
      </S.CheckBoxCol>
    </Row>
  </Col>
);

export default Consent;
