import { Col, Row } from '@everlywell/leaves';
import { MY_APP_ROOT } from 'common/utils/constants';
import { HEALTH_PROFILE_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';
import React from 'react';

import * as S from '../styles';
import { confirmationSteps } from './confirmationSteps';

type Props = {
  email: string;
};

const LabVisitProfileConfirmation = ({ email }: Props): JSX.Element => (
  <Row data-test={`${DATA_TEST.CONTAINER}-in-lab-registration`}>
    <Col>
      <S.Header>Your health profile is complete</S.Header>
    </Col>
    <Col>
      <S.LabSubtitle>
        A provider will review your request, and a confirmation email will be
        sent to {email} upon approval. Requests are usually approved within 1-2
        business days.
      </S.LabSubtitle>
    </Col>
    <Col>
      {confirmationSteps.map((step) => (
        <S.ProfileStep key={`lab-visit-step-${step.key}`}>
          <img src={step.image.src} alt={step.image.alt} />
          <div>
            <h3>{step.header}</h3>
            <p>{step.description}</p>
          </div>
        </S.ProfileStep>
      ))}
    </Col>
    <Col>
      <S.DashboardButton
        appearance="primary"
        href={MY_APP_ROOT}
        data-test={`${DATA_TEST.BUTTON}-dashboard`}
      >
        Go to Dashboard
      </S.DashboardButton>
    </Col>
  </Row>
);

export default LabVisitProfileConfirmation;
