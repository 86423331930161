import Grid from 'components/Grid';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FormBuilderField } from '../../utils/types';
import { isNotNegative } from '../../utils/validations';
import * as S from './PulseField.styles';

/**
 * Pulse component
 */
export type PulseFieldProps = FormBuilderField;
function PulseField(props: PulseFieldProps) {
  const { id, label, required } = props;
  const { register, errors } = useFormContext();

  return (
    <Grid.Item width={[1]}>
      <S.Input
        error={errors[id]?.message}
        id={id}
        label={label ?? ''}
        min={0}
        name={id}
        ref={register<HTMLInputElement>({
          required: 'Please enter your pulse',
          validate: { isNotNegative: isNotNegative },
        })}
        required={required}
        type="number"
      />
    </Grid.Item>
  );
}

export default PulseField;
