import { Container } from '@everlywell/leaves';
import { TrackVisibilityWrapper } from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import SectionWrapper from 'components/SectionWrapper';
import React from 'react';

import * as S from './styles';

export interface Props {
  title: string;
  description: string | JSX.Element;
}

const CancellationModule = ({ title, description }: Props) => (
  <TrackVisibilityWrapper
    label={ANALYTICS.LABELS.CARE_DASHBOARD.CANCELLATION_MODULE.label}
  >
    <SectionWrapper>
      <Container>
        <S.Wrapper>
          <S.Header>{title}</S.Header>
          <S.Description>{description}</S.Description>
        </S.Wrapper>
      </Container>
    </SectionWrapper>
  </TrackVisibilityWrapper>
);
export default CancellationModule;
