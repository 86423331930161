import {
  colors,
  H4,
  size,
  typography,
  Button,
  UpCaret,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 100%;
`;

export const TestKitList = styled.div``;

export const Title = styled(H4)`
  color: ${colors.teal5};
  margin: 0;
  margin-bottom: ${size.md}px;
  font-weight: 700;
`;

export const ViewAll = styled(Button)`
  ${typography.buttonText};
  color: ${colors.teal4};
  padding: 0;
`;

export const Head = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ViewMoreSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${size.sm}px;
`;

export const ViewMoreButton = styled(Button)`
  margin: auto;
`;

export const Caret = styled(UpCaret)<{ isExpanded: boolean }>`
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(deg)' : 'rotate(180deg)'};
`;
