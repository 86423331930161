import {
  size,
  typography,
  Icons,
  colors,
  mediaQueries,
  TertiaryLink,
  H3,
  H4,
} from '@everlywell/leaves';
import styled from 'styled-components';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

type SectionSubHeaderProps = {
  showPowerBySection: boolean;
};

export const Container = styled.div`
  box-sizing: border-box;
  margin-top: ${size.xl2}px;
  margin-bottom: ${size.xl6}px;
  overflow: hidden;

  ${mediaQueries.forTabletVerticalDown} {
    margin-bottom: 0;
  }

  ${mediaQueries.forPhoneOnly} {
    margin-top: 0;
    margin-bottom: ${size.xl4}px;
  }
`;

export const SectionName = styled.div`
  ${typography.overlineText};
  font-weight: 800;
  text-align: center;
  margin-bottom: ${size.sm}px;
`;

export const SectionSubHeader = styled(H3)<SectionSubHeaderProps>`
  font-weight: 400;
  text-align: center;
  line-height: 2.744rem;
  font-size: 2.063rem;
  margin-bottom: ${(props: SectionSubHeaderProps) =>
    props.showPowerBySection ? `${size.md}px;` : `${size.xl4}px;`};
`;

export const SectionPowerByText = styled.div`
  ${typography.captionText};
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.438rem;
  text-align: center;
  margin-bottom: ${size.xl4}px;
`;

export const NatalistLogoImage = styled.img`
  height: 16px;
  width: 76px;
`;

export const SliderContainer = styled.div`
  position: relative;
  padding: 0 ${size.xl4}px ${size.xl1}px ${size.xl4}px;
  overflow: hidden;
  max-width: 90%;
  margin: 0 auto;
  z-index: 1;

  ${mediaQueries.forPhoneOnly} {
    padding: 0;
    max-width: 100%;
  }

  .swiper-container {
    height: 100%;

    ${mediaQueries.forTabletVerticalDown} {
      max-width: 324px;
    }

    ${mediaQueries.forPhoneOnly} {
      padding-bottom: 10%;
    }

    .swiper-wrapper {
      .swiper-slide {
        padding: 0 10px;
      }
    }
  }
  .swiper-pagination {
    display: none;
    ${mediaQueries.forPhoneOnly} {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 10px;

      .swiper-pagination-bullet {
        flex: 1;
        margin: 0 ${size.xs3}px;
        border-radius: 4px;
        max-width: 20px;
        height: 4px;
        opacity: 1;
        background: ${colors.green2};

        &.swiper-pagination-bullet-active {
          background: ${colors.teal4};
        }
      }
    }
  }
`;

/* stylelint-disable no-descending-specificity */
export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  border: 1px solid ${colors.green1};
  height: 284px;

  ${mediaQueries.forTabletVerticalDown} {
    flex-flow: column wrap;
    height: auto;
    max-height: none;
    max-width: 312px;
  }
`;

export const CardTitle = styled(H4)`
  width: 100%;
  line-height: 2.125rem;
  letter-spacing: 0.025rem;
  margin: 0;
`;

export const CardDescription = styled.div`
  width: 100%;
  color: ${colors.gray4};
  ${typography.captionText};
  line-height: 1.438rem;
  font-weight: 300;
  padding-bottom: 24px;
`;

export const CardLink = styled(TertiaryLink)`
  color: ${colors.green4};
  font-weight: 700;
`;

export const ImageWrapper = styled.div`
  width: 42%;
  align-items: center;

  ${mediaQueries.forTabletVerticalDown} {
    width: 100%;
  }
`;

export const Content = styled.div`
  flex: 1;
  height: 100%;
  padding: ${size.xl1}px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
  position: relative;

  ${mediaQueries.forPhoneOnly} {
    justify-content: flex-start;
    margin: ${size.md}px ${size.md}px ${size.lg}px ${size.md}px;
    padding: 0;
  }
`;

const CarouselButtons = `
    background: none;
    z-index: 99;
    display: inline-flex;
    cursor: pointer;
    padding: 15.4px 17.5px;
    border: 1px solid rgb(214, 235, 221);
    border-radius: 1px;
    background: rgba(214, 235, 221, 0.3);
    cursor: pointer;
    top: 35%;

    ${mediaQueries.forPhoneOnly} {
      display: none;
    }

  `;

const CarouselIcon = `
    color: ${colors.teal4};
    display: block;
    width: 11px;
    height: 15px;
  `;

export const Next = styled.button`
  ${CarouselButtons};
  position: absolute;
  right: 0;
`;
export const Prev = styled.button`
  ${CarouselButtons};
  position: absolute;
  left: 0;
`;

export const LeftIcon = styled(Icons.LeftCaret)`
  ${CarouselIcon};
`;

export const RightIcon = styled(Icons.RightCaret)`
  ${CarouselIcon};
`;

export const Img = styled.img`
  width: auto;
  height: 100%;
  align-self: center;
  opacity: 0;

  ${mediaQueries.forTabletVerticalDown} {
    width: 100%;
    height: auto;
  }
`;
