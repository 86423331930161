import { UserTaskChecklistData } from 'common/apis/taskChecklistApi';
import React from 'react';

import TaskCard from '../TaskCard';
import * as S from './TaskList.styles';

// Types ----------

export interface TaskListProps {
  title: string;
  tasks: UserTaskChecklistData[];
}

// MyChecklist ------

const MyChecklist: React.FC<TaskListProps> = (props) => {
  const { title, tasks } = props;

  return (
    <div>
      <S.Title>{title}</S.Title>
      <S.TaskListWrapper>
        {tasks.map((task, index: number) => (
          <TaskCard key={`${task.type}-${index}`} task={task} />
        ))}
      </S.TaskListWrapper>
    </div>
  );
};

// Export ---------

export default React.memo(MyChecklist);
