import { TelehealthOrder } from 'common/apis/telehealthApis';

const baseOrder: TelehealthOrder = {
  sent_on: '12/06/2024',
  clinical_provider: {
    address: '9911 Brodie Ln',
    city: 'Austin',
    name: 'Quest Diagnostics PSC',
    phone: '(512) 292-1292',
    state: 'TX',
    zip: '78704-5802',
  },
};

export const buildOrder = (overrideProps: Partial<TelehealthOrder> = {}) => ({
  ...baseOrder,
  ...overrideProps,
});
