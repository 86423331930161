import { Button, mediaQueries } from '@everlywell/leaves';
import { size, colors } from '@everlywell/leaves';
import { Intro } from 'components/ResultsIntro/styles';
import styled from 'styled-components';

const ReplacementWrapper = styled.div`
  text-align: center;
`;

const ReplacementButton = styled(Button)`
  margin-top: ${size.md}px;
  text-decoration: none !important;
  margin-bottom: ${size.md}px;
`;

const Link = styled.a`
  color: ${colors.green5};
  text-decoration: none;

  &:hover {
    color: ${colors.green5};
    text-decoration: underline;
  }
`;

const ReplacementLegend = styled(Intro)`
  font-size: 14px;
  ${mediaQueries.forTabletVerticalUp} {
    font-size: 19px;
  }
`;

export { ReplacementWrapper, ReplacementButton, Link, ReplacementLegend };
