import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import Grid from 'components/Grid';
import React, { FC, Fragment, useEffect } from 'react';

import StethoscopeWithDocsImage from '../../assets/stethoscope-with-docs.png';
import DEFAULT_CONTENT from './content';
import * as S from './NoAsyncServiceModal.styles';

export type NoAsyncServiceModalProps = {
  openModal: boolean;
  setOpenModal: (_: boolean) => void;
  handleButtonClick: () => void;
  program: string;
};

/**
 * Modal that shows when a user selects a state that is not allowed
 * for the AsyncRx program
 */
export const NoAsyncServiceModal: FC<NoAsyncServiceModalProps> = ({
  openModal,
  setOpenModal,
  handleButtonClick,
  program,
}: NoAsyncServiceModalProps) => {
  const handleCloseModal = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label:
          ANALYTICS.PAGES.INTERSTITIAL.NO_ASYNC_SERVICE_AREA_MODAL
            .CTA_SCHEDULE_APPOINTMENT,
        program,
      },
    });

    handleButtonClick();
  };

  useEffect(() => {
    if (openModal) {
      analytics.track({
        event: ANALYTICS.EVENTS.VIEWED_PAGE,
        data: {
          page: ANALYTICS.PAGES.INTERSTITIAL.NO_ASYNC_SERVICE_AREA_MODAL.PAGE,
        },
      });
    }
  }, [openModal]);

  return (
    <S.Modal open={openModal} openHandler={setOpenModal}>
      <Grid.Container spacing={[]}>
        <Grid.Item width={[1]}>
          <S.ImageContainer>
            <S.Image
              src={StethoscopeWithDocsImage}
              alt="Async Rx stethoscope with Everlywell documents"
            />
          </S.ImageContainer>
        </Grid.Item>
        <Grid.Item width={[1]}>
          <S.Title>{DEFAULT_CONTENT.title}</S.Title>
        </Grid.Item>
        <Grid.Item width={[1]}>
          <>
            {DEFAULT_CONTENT.copy.map((copyParagraph, index) => (
              <Fragment key={`no-async-service-modal-copy-${index}`}>
                <S.Copy>{copyParagraph}</S.Copy>
                {index < DEFAULT_CONTENT.copy.length - 1 && <br />}
              </Fragment>
            ))}
          </>
        </Grid.Item>
        <Grid.Item width={[1]}>
          <S.Button appearance="primary" onClick={handleCloseModal}>
            {DEFAULT_CONTENT.buttonText}
          </S.Button>
        </Grid.Item>
      </Grid.Container>
    </S.Modal>
  );
};

export default NoAsyncServiceModal;
