import iconSrc3 from '../../images/icons-cost.svg';
import iconSrc2 from '../../images/icons-time.svg';
import iconSrc1 from '../../images/icons-who.svg';
import iconSrc from './images/icon-healthcare-pro-indigo.svg';

const alt = 'Take Action';
const header = 'Talk with a Healthcare Professional';
const date = '2019-06-04T19:59:49.115Z';
const testName = 'Food Sensitivity';
const description =
  '15-minute phone call with a Nutritionist to discuss your Metabolism Test results and answer any questions.';
const modalHeader = 'Take Action Modal Title';
const modalDescription = 'Take Action Modal Description';
const modalLink = 'https://testlink.com';
const modal = {
  header: modalHeader,
  description: modalDescription,
  link: modalLink,
};
const items = [
  {
    iconSrc: iconSrc1,
    alt: '1',
    text: 'Nutritionist',
  },
  {
    iconSrc: iconSrc2,
    alt: '2',
    text: 'Takes 15 minutes',
  },
  {
    iconSrc: iconSrc3,
    alt: '3',
    text: 'Free',
  },
];

const tooltip = {
  header: 'tooltip header',
  description: 'tooltip description',
};

const buttonContent = 'Schedule';

export {
  iconSrc,
  alt,
  header,
  description,
  items,
  date,
  modal,
  tooltip,
  testName,
  buttonContent,
};
