/* eslint-disable no-unused-expressions */
import { useState, useLayoutEffect, RefObject } from 'react';

const THRESHOLD = 20;

function useHasScrollableContent(parentRef: RefObject<HTMLDivElement>) {
  const [hasScrollableContent, setHasScrollableContent] = useState(false);

  function handleScroll(e: Event) {
    if (!e.currentTarget) {
      return;
    }

    const target = e.currentTarget as HTMLDivElement;
    setHasScrollableContent(
      target.scrollHeight - target.scrollTop > target.clientHeight + THRESHOLD,
    );
  }

  useLayoutEffect(() => {
    if (!parentRef.current) {
      return;
    }

    parentRef.current.addEventListener('scroll', handleScroll);

    const { clientHeight, scrollHeight } = parentRef.current;

    if (scrollHeight > clientHeight + THRESHOLD) {
      setHasScrollableContent(true);
    }

    /* eslint-disable-next-line consistent-return */
    return () => {
      if (!parentRef.current) {
        return;
      }

      parentRef.current.removeEventListener('scroll', handleScroll);
    };
  }, [parentRef]);

  return hasScrollableContent;
}

export default useHasScrollableContent;
