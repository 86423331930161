import React, { ComponentProps } from 'react';

import * as S from './styles';

type Props = ComponentProps<typeof S.Wrapper> & {
  children: React.ReactNode;
};

function PageTitle(props: Props) {
  const { children, ...rest } = props;

  return <S.Wrapper {...rest}>{children}</S.Wrapper>;
}

export default PageTitle;
