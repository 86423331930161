import { datadogRum } from '@datadog/browser-rum';
import { useState, useEffect } from 'react';

const useTimeTracker = (action: string) => {
  const [loadTimestamp, setLoadTimestamp] = useState(0);

  useEffect(() => {
    if (loadTimestamp) return;
    setLoadTimestamp(Date.now());
  });

  return () => {
    datadogRum.addAction(action, {
      duration: (Date.now() - loadTimestamp) / 1000 / 60,
    });
  };
};

export default useTimeTracker;
