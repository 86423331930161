import {
  size,
  colors,
  typography,
  mediaQueries,
  TabMenu as LeavesTabMenu,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const TabMenu = styled(LeavesTabMenu)`
  overflow-x: auto;
  box-shadow: 0 -3px 0 0 ${colors.green2} inset;

  ul {
    padding: 0;
    margin: 0;
    gap: 0;
  }

  ul > li {
    padding: ${size.xs1}px ${size.xl1}px;
    background-color: transparent;
  }

  ul + div {
    position: absolute;
    bottom: 0;
    height: 3px;
  }
`;

export const TimeSelectorRadioGroup = styled.div`
  display: grid;
  gap: ${size.lg}px;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;

  ${mediaQueries.forTabletVerticalUp} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const BlankSlateMessage = styled.p`
  color: ${colors.gray4};
  text-align: center;
`;

export const TimeButton = styled.button`
  padding: calc(${size.xs2}px + ${size.xs3}px) ${size.xs1}px;
  border: 1px solid ${colors.gray3};
  border-radius: 500em;
  white-space: nowrap;
  font-weight: ${typography.weight.bold};
  color: ${colors.gray4};
  cursor: pointer;
  transition: all 100ms ease-in-out;

  &:hover,
  &:focus {
    color: ${colors.green5};
    border-color: ${colors.green4};
  }

  &[aria-pressed='true'] {
    color: ${colors.green5};
    background-color: ${colors.green2};
    border-color: ${colors.green4};
    box-shadow: 0 0 0 1px ${colors.green4} inset;
  }
`;
