import { breakpoints, LocalizeDisabled } from '@everlywell/leaves';
import useMediaQuery from 'common/hooks/useMediaQuery';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { User } from 'common/utils/types';
import React, { useState, useEffect } from 'react';

import Image from './components/Image';
import * as S from './PageHeader.styles';

export type PageHeaderProps = {
  user?: User;
};

/**
 * PageHeader dashboard
 */
function PageHeader({ user }: PageHeaderProps) {
  const [isPageHeaderViewed, setIsPageHeaderViewed] = useState<boolean>(false);
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.forPhoneOnly}px)`);

  useEffect(() => {
    if (user && !isPageHeaderViewed) {
      analytics.track({
        event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
        data: {
          label: ANALYTICS.LABELS.ACCOUNT_HUB.HEADER,
          category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
        },
      });
      setIsPageHeaderViewed(true);
    }
  }, [user, isPageHeaderViewed]);

  return (
    <S.Header>
      {isMobile && <Image />}
      <S.Heading>
        Hi, {user ? <LocalizeDisabled>{user.first_name}</LocalizeDisabled> : ''}
        <span>Welcome to your dashboard</span>
      </S.Heading>
    </S.Header>
  );
}

export default PageHeader;
