import image1 from '../assets/db-adx-card.png';
import { SampleCardMarkerDirection } from '../model';
import { SIDataType } from './model';

const DBADXMailingSample: SIDataType[] = [
  {
    id: '1',
    width: {
      l: '600px',
      m: '430px',
      s: '330px',
    },
    height: {
      l: '300px',
      m: '300px',
      s: '250px',
    },
    image: {
      src: image1,
      alt: 'DBS card',
      width: {
        l: '485px',
        m: '400px',
        s: '330px',
      },
      height: {
        l: 'auto',
        m: 'auto',
        s: 'auto',
      },
      position: {
        x: 50,
        y: 0,
        // Tablet
        xm: 1,
        ym: 20,
        // Phones
        xs: 1,
        ys: 20,
      },
    },
    markers: [
      {
        id: '1',
        title: {
          copy: 'Information filled out',
          width: {
            l: '180px',
            s: '100px',
          },
          height: {
            l: 'auto',
          },
          alignment: {
            l: SampleCardMarkerDirection.right,
            s: SampleCardMarkerDirection.left,
          },
          fontSize: {
            s: '16px',
          },
          position: {
            x: -192,
            y: 210,
            // Medium
            xm: 19,
            ym: 270,
            // Phones
            xs: 30,
            ys: 220,
          },
        },
        line: {
          start: {
            x: 10,
            y: 222,
            // Medium devices
            xm: 120,
            ym: 185,
            // Phones
            xs: 100,
            ys: 160,
          },
          end: {
            x: 68,
            y: 222,
            // Medium devices
            xm: 120,
            ym: 260,
            // Phones
            xs: 100,
            ys: 210,
          },
        },
      },
      {
        id: '2',
        title: {
          copy:
            'Blood has expanded to the first line on the right-hand side of the card.',
          width: {
            l: '270px',
            m: '180px',
            s: '150px',
          },
          height: {
            l: 'auto',
          },
          alignment: {
            l: SampleCardMarkerDirection.left,
          },
          fontSize: {
            s: '14px',
          },
          position: {
            x: 590,
            y: 125,
            // Medium devices
            xm: 250,
            ym: 270,
            // Phones
            xs: 200,
            ys: 220,
          },
        },
        line: {
          start: {
            x: 400,
            y: 137,
            // Medium devices
            xm: 290,
            ym: 105,
            // Phones
            xs: 240,
            ys: 90,
          },
          end: {
            x: 570,
            y: 137,
            // Medium devices
            xm: 290,
            ym: 260,
            // Phones
            xs: 240,
            ys: 210,
          },
        },
      },
    ],
  },
];

export { DBADXMailingSample };
