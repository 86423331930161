import Grid from 'components/Grid/Grid';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Input } from '../../utils/styles';
import { FormBuilderField } from '../../utils/types';
import * as S from './BMIField.styles';

export type BMIFieldProps = FormBuilderField;

export const INCHES_PER_FOOT = 12;
export const REQUIRED_FEET_ERROR = 'Please enter your height in feet';
export const REQUIRED_INCHES_ERROR = 'Please enter your height in inches';
export const REQUIRED_WEIGHT_ERROR = 'Please enter your weight';

// TODO: Allow have custom validations, so it can be used for the demographics form
// https://everlyhealth.atlassian.net/browse/IN2-565

function BMIField(props: BMIFieldProps) {
  const { register, errors, watch, control } = useFormContext();

  const tempFieldName = `${props.id}-tmp`;
  const feet: string = watch(`${tempFieldName}.feet`);
  const inches: string = watch(`${tempFieldName}.inches`);
  const weight: string = watch(`${tempFieldName}.weight`);

  const bmiValues = getBMIValues({ feet, inches, weight });

  const hasError = !!errors[tempFieldName];

  return (
    <React.Fragment>
      <S.Label hasError={!!hasError} variant={props.variant}>
        {props.label}
      </S.Label>

      <Grid.Item width={[1, 1 / 4]}>
        <Controller
          control={control}
          defaultValue=""
          name={`${tempFieldName}.feet`}
          rules={{
            required: props.required && REQUIRED_FEET_ERROR,
          }}
          render={(field) => {
            const onFeetChange = (
              event: React.ChangeEvent<HTMLInputElement>,
            ) => {
              const { value } = event.target;

              // allow for user to clear the field
              if (value === '') {
                field.onChange(value);
                return;
              }

              const parsedValue = parseInt(value);

              if (parsedValue >= 0) {
                field.onChange(parsedValue.toString());
              }
            };

            return (
              <Input
                {...field}
                label="Feet"
                type="number"
                id={field.name}
                name={field.name}
                error={errors[tempFieldName]?.feet?.message}
                onChange={onFeetChange}
                required={props.required}
              />
            );
          }}
        />
      </Grid.Item>
      <Grid.Item width={[1, 1 / 4]}>
        <Controller
          control={control}
          defaultValue=""
          name={`${tempFieldName}.inches`}
          rules={{
            required: props.required && REQUIRED_INCHES_ERROR,
          }}
          render={(field) => {
            const onInchesChange = (
              event: React.ChangeEvent<HTMLInputElement>,
            ) => {
              const { value } = event.target;

              // allow for user to clear the field
              if (value === '') {
                field.onChange(value);
                return;
              }

              const parsedValue = parseFloat(value);

              // validate the value is between 0 and 12
              if (parsedValue >= 0 && parsedValue < INCHES_PER_FOOT) {
                field.onChange(value);
              }
            };

            return (
              <Input
                {...field}
                label="Inches"
                type="number"
                id={field.name}
                name={field.name}
                error={errors[tempFieldName]?.inches?.message}
                onChange={onInchesChange}
                required={props.required}
              />
            );
          }}
        />
      </Grid.Item>
      <Grid.Item width={[1, 1 / 2]}>
        <Controller
          control={control}
          defaultValue=""
          name={`${tempFieldName}.weight`}
          rules={{
            required: props.required && REQUIRED_WEIGHT_ERROR,
          }}
          render={(field) => {
            const onWeightChange = (
              event: React.ChangeEvent<HTMLInputElement>,
            ) => {
              const { value } = event.target;

              // allow for user to clear the field
              if (value === '') {
                field.onChange(value);
                return;
              }

              const parsedValue = parseFloat(value);

              // positive numbers only
              if (parsedValue >= 0) {
                field.onChange(value);
              }
            };

            return (
              <Input
                {...field}
                label="Weight"
                type="number"
                id={field.name}
                name={field.name}
                error={errors[tempFieldName]?.weight?.message}
                onChange={onWeightChange}
                required={props.required}
              />
            );
          }}
        />
      </Grid.Item>
      <Grid.Item width={[1]}>
        <Input
          name={`${tempFieldName}.bmi`}
          id={`${tempFieldName}.bmi`}
          label="BMI"
          value={bmiValues?.BMI?.toFixed(2) ?? ''}
          ref={register()}
          readOnly
        />
      </Grid.Item>
      <input
        type="hidden"
        name={props.id}
        ref={register()}
        value={bmiValues?.formattedValue ?? ''}
      />
    </React.Fragment>
  );
}

export default BMIField;

/**
 * Calculate the BMI, height in inches, and weight in pounds if the given values are valid.
 * Otherwise return undefined
 * @returns
 */
const getBMIValues = ({
  feet,
  inches,
  weight,
}: {
  feet: string;
  inches: string;
  weight: string;
}) => {
  const feetValue = parseInt(feet);
  const inchesValue = parseFloat(inches);
  const weightValue = parseFloat(weight);

  if (
    [feetValue, inchesValue, weightValue].some(
      (value) => isNaN(value) || value < 0,
    )
  ) {
    return;
  }

  const heightInInches: number = feetValue * INCHES_PER_FOOT + inchesValue;

  if (heightInInches > 0) {
    const BMI = (weightValue / (heightInInches * heightInInches)) * 703;

    return {
      BMI,
      height: heightInInches,
      weight: weightValue,
      formattedValue: `${heightInInches}\n${weightValue}\n${BMI.toFixed(2)}`,
    };
  }
};
