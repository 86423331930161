import {
  TelehealthCarePlanOrderDataResponse,
  TelehealthCarePlanOrders,
  TelehealthCarePlanOrderTypes,
  TelehealthOrder,
} from 'common/apis/telehealthApis';
import { OrderParam } from 'common/hooks/useOrders/useOrders';

import { ORDER_TYPE_MAPPING } from './constants';

type OrdersDetails = {
  type: string;
  id: number;
  order: TelehealthOrder;
}[];

const setNote = (order: TelehealthOrder) => {
  if (!order?.clinical_provider) return '';

  const { name, address, city, state, zip } = order?.clinical_provider;

  return `${name} at ${address}, ${city}, ${state} ${zip}`;
};

const setItems = (
  value: TelehealthCarePlanOrderDataResponse[] | [],
  ordersDetails: OrdersDetails,
) => {
  if (ordersDetails.length === 0)
    return value.map((value) => ({ description: value.description }));

  const foundOrder = ordersDetails.find(
    (order) => order.id === value[0].id && order.type === value[0].type,
  );

  return value.map((value) => ({
    description: value.description,
    note: foundOrder?.order && setNote(foundOrder?.order),
  }));
};

const setParams = (value: TelehealthCarePlanOrderDataResponse[] | []) =>
  value.map((item) => ({
    type: item.type,
    id: String(item.id),
  }));

export const formatOrdersAccordionItems = (
  orders: TelehealthCarePlanOrders | null,
  ordersDetails: OrdersDetails,
  props: {
    handleClick: (params: OrderParam[]) => void;
    isLoading?: boolean;
  },
) => {
  if (!orders) return [];

  return Object.entries(orders).map(([key, value]) => {
    const orderType = key as TelehealthCarePlanOrderTypes;

    return {
      sectionName: ORDER_TYPE_MAPPING?.[orderType]?.text,
      iconName: ORDER_TYPE_MAPPING?.[orderType]?.iconName,
      items: setItems(value, ordersDetails),
      onButtonClick: () => {
        const params = setParams(value);

        props?.handleClick(params);
      },
      isLoading: props.isLoading,
    };
  });
};

export const isAthenaCarePlanPageUrl = (url: string) =>
  /^\/virtual-care\/appointments\/(\d+)/.test(url);
