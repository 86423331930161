import { TelehealthCustomModule } from 'common/apis/telehealthApis';
import { Response } from 'common/hooks/useApi/request';
import { isAsyncProgram } from 'common/utils/helpers';
import { FormType, User } from 'common/utils/types';

/**
 *
 * @param custom_modules
 * @param userData
 * @param program
 * @returns the default values for the different types of forms
 */
export const setDefaultValues = (
  custom_modules: TelehealthCustomModule[],
  userData: Response<User> | undefined,
  formName: FormType,
): Record<string, string> => {
  switch (formName) {
    case 'demographics':
      return demographicsDefaultsFormValues(custom_modules, userData);
    default: {
      return {};
    }
  }
};

function demographicsDefaultsFormValues(
  custom_modules: TelehealthCustomModule[],
  userData: Response<User> | undefined,
): Record<string, string> {
  return custom_modules.reduce<Record<string, string>>(
    (acc, { id, mod_type, options_array }) => {
      const userState = userData?.data?.health_provider_user_state;

      if (
        userState &&
        mod_type === 'dropdown' &&
        options_array?.includes(userState)
      ) {
        acc[id] = userState;
        // the above is being hardcoded to the user health provider state for now
        // it can cause issues without a more robust solution
        // we can add a new dropdown to the form that is not related to the user state
      }

      return acc;
    },
    {},
  );
}

export function setSubmitCopy(formSlug: FormType, programType: string) {
  if (formSlug === 'photo-document-upload' && isAsyncProgram(programType)) {
    return 'Upload and Submit Request';
  } else {
    return 'Continue';
  }
}
