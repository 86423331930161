import {
  size,
  colors,
  typography,
  Checkbox as LeavesCheckbox,
  Label as LeavesLabel,
} from '@everlywell/leaves';
import styled, { css } from 'styled-components';

import { labelTextCSSbyVariant } from '../../utils/styles';
import { FormBuilderVariant } from '../../utils/types';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

/* Leaves checkboxes have huge border around them, this is to override that  */
export const Checkbox = styled(LeavesCheckbox)`
  margin: ${size.xs2}px 0;

  div {
    margin: 0;
  }

  label div {
    margin-left: ${size.xs1}px;
  }
`;

export const Label = styled(LeavesLabel)<{
  hasError: boolean;
  variant: FormBuilderVariant;
}>`
  width: 100%;
  ${({ hasError }) =>
    hasError &&
    css`
      color: ${colors.red3};
    `}
  ${({ variant }) => labelTextCSSbyVariant(variant)}
`;

export const Error = styled.span`
  ${typography.errorText};
  font-weight: ${typography.weight.regular};
`;
