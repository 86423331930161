import {
  ErrorText,
  FormFieldType,
  OptionsType,
  FormValueType,
} from 'components/Forms';
import React, { SyntheticEvent } from 'react';

import { RadioButton } from './RadioButton';
import * as S from './styles';

type Props = {
  label: string;
  name: string;
  options: OptionsType[];
  error?: string;
  value: FormValueType;
  onChange: (formField: FormFieldType) => void;
};

function RadioGroup(props: Props) {
  const {
    name,
    options,
    error,
    onChange,
    value: selectedValue,
    label: groupLabel,
    ...rest
  } = props;

  function handleChange(event: SyntheticEvent<HTMLInputElement>) {
    const { currentTarget } = event;
    onChange({ name: currentTarget.name, value: currentTarget.value });
  }

  return (
    <>
      <S.Heading>{groupLabel}</S.Heading>
      <S.Group>
        {options.map(({ label, value }) => (
          <RadioButton
            onChange={handleChange}
            checked={selectedValue === value}
            name={name}
            key={value}
            label={label}
            value={value}
            {...rest}
          />
        ))}
      </S.Group>
      <ErrorText>{error}</ErrorText>
    </>
  );
}

export { RadioGroup };
