import { useTelehealthAppointments } from 'common/apis/telehealthApis';
import React from 'react';

import HelpPhoneIcon from './help-phone-icon.svg';
import * as S from './OpenLoopSupport.styles';

/**
 * This component displays Open Loop support for dashboards
 */
function OpenLoopSupport() {
  const { data: response } = useTelehealthAppointments({
    limit: 1,
  });

  const [appointment] = response?.data?.appointments ?? [];
  const hasAppointments = Boolean(appointment);

  return hasAppointments ? (
    <S.Container>
      <S.Heading>
        <S.PhoneIcon src={HelpPhoneIcon} alt="" />
        <S.NeedHelpText>Need help? </S.NeedHelpText>
      </S.Heading>
      <S.Text>
        For technical support or questions about your care, contact Everlywell's
        partner, OpenLoop, at <S.Span> (708)-787-4438</S.Span>, Monday-Friday,
        7am-7pm CST
      </S.Text>
    </S.Container>
  ) : null;
}

export default OpenLoopSupport;
