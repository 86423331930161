/**
 *
 * DescriptiveResultDetailContainer
 *
 */

import {
  ImmutableReduxState,
  KitResult,
  MarkerWithResult,
} from 'common/utils/types';
import DescriptiveResultDetail from 'components/DescriptiveResultDetail';
import { connect } from 'react-redux';
import { makeGroupedMarkersKitResult } from 'store/kitResult/DescriptiveResultDetailContainer/selectors';

type StateProps = {
  markers: MarkerWithResult[];
  kitResult: KitResult;
};

type OwnProps = {
  kitResult: KitResult;
};

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): StateProps {
  const { kitResult } = ownProps;

  const markers = makeGroupedMarkersKitResult(kitResult)(state);

  return {
    markers,
    kitResult,
  };
}

export default connect(mapStateToProps)(DescriptiveResultDetail);
