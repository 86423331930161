import _ from 'lodash';

import { GROUPING_METHODS, SEVERITY_DESCRIPTORS } from './constants';
import { FOOD_CLASSIFICATIONS } from './constants/foodClassifications';
import { Marker, MarkerResult, FoodSensitivityGroup } from './types';

/**
 * Transform FS Kit Result and Marker Data into clean format for components
 */

const groupBySeverity = (
  markers: Marker[],
  markerResults: MarkerResult[],
): { [key: number]: MarkerResult[] } => {
  const groupedMarkers: { [key: number]: MarkerResult[] } = {};
  markerResults.forEach((markerResult: MarkerResult) => {
    const marker = markers.find((m) => m.id === markerResult.marker_id);

    if (marker) {
      const { severities } = marker;
      const severity = severities[markerResult.severity_index];

      if (groupedMarkers[severity]) {
        groupedMarkers[severity].push(markerResult);
      } else {
        groupedMarkers[severity] = [markerResult];
      }
    }
  });

  return groupedMarkers;
};

const groupFSMarkersBySeverity = (
  markers: Marker[],
  markerResults: MarkerResult[],
): { [key: number]: MarkerResult[] } => {
  const groupedMarkers: { [key: number]: MarkerResult[] } = {};

  markerResults.forEach((markerResult: MarkerResult) => {
    const marker = markers.find((m) => m.id === markerResult.marker_id);

    if (marker) {
      const severity = Number(markerResult.severity_index) + 1;

      if (groupedMarkers[severity]) {
        groupedMarkers[severity].push(markerResult);
      } else {
        groupedMarkers[severity] = [markerResult];
      }
    }
  });

  return groupedMarkers;
};

/** reusable function to group by descriptors
 * Applies to all test types
 */

const groupByDescriptors = (
  markers: Marker[],
  markerResults: MarkerResult[],
): FoodSensitivityGroup[] => {
  // get all the descriptors for the test
  const descriptorGroups =
    (markers && markers.length && markers[0].descriptors) || [];
  const groupedMarkers: any = {};
  // create a group for each descriptor
  descriptorGroups.forEach((descriptor: string) => {
    groupedMarkers[descriptor.toLowerCase()] = [];
  });

  // filter marker results in respective descriptor groups
  markerResults.forEach((markerResult: MarkerResult) => {
    if (groupedMarkers[markerResult.descriptor.toLowerCase()]) {
      groupedMarkers[markerResult.descriptor.toLowerCase()].push(markerResult);
    } else if (
      markerResult.descriptor &&
      markerResult.descriptor.includes('<')
    ) {
      groupedMarkers[SEVERITY_DESCRIPTORS.LOW].push(markerResult);
    }
  });

  // create an object with a descriptor title and its grouped results
  const groupedResults = Object.keys(groupedMarkers).map((descriptor) => {
    // create a grouping of severity index to be displayed
    const includedSeverityIndexes: number[] = [];

    descriptorGroups.forEach((descriptorLabel: string, index) => {
      if (descriptorLabel.toLowerCase() === descriptor) {
        includedSeverityIndexes.push(index);
      }
    });

    return {
      title: descriptor,
      groupedMarkers: groupedMarkers[descriptor],
      includedSeverityIndexes: includedSeverityIndexes,
    };
  });

  return groupedResults;
};

/* Function to group by food descriptors */
const groupByFoodDescriptors = (
  markers: Marker[],
  markerResults: MarkerResult[],
  lab: string,
) =>
  _.groupBy(markerResults, (markerResult: MarkerResult) => {
    const marker: Marker | undefined = markers.find(
      (currentMarker: Marker) => currentMarker.id === markerResult.marker_id,
    );

    // @ts-ignore-next-line : marker result will have matching marker
    return FOOD_CLASSIFICATIONS[lab][marker.name.toUpperCase().trim()];
  });

/* Function to group by food */
const groupByFood = (
  markers: Marker[],
  markerResults: MarkerResult[],
  lab: string,
): FoodSensitivityGroup[] => {
  const groupedResults = groupByFoodDescriptors(markers, markerResults, lab);
  const containers = _.map(
    groupedResults,
    (value: MarkerResult[], key: string) => {
      const title: string = _.startCase(key);
      // Build object of grouped marker data
      return { title, groupedMarkers: value };
    },
  );

  return containers;
};

function generateFSResultProps(
  markerResults: MarkerResult[],
  markers: Marker[],
  lab: string,
  groupingType?: string,
): FoodSensitivityGroup[] {
  // Temporarily Permute 'low reactivity' to 'normal reactivity' until we change at the data layer
  /* eslint-disable no-param-reassign */
  _.forEach(markers, (marker) => {
    marker.descriptors = [
      'Normal Reactivity',
      'Mild Reactivity',
      'Moderate Reactivity',
      'High Reactivity',
    ];
  });
  _.forEach(markerResults, (result) => {
    if (result.descriptor === 'low reactivity') {
      result.descriptor = 'normal reactivity';
    }
  });
  /* eslint-enable no-param-reassign */

  let groupedMarkers;
  switch (groupingType) {
    case GROUPING_METHODS.REACTIVITY:
      groupedMarkers = groupByDescriptors(markers, markerResults);
      break;
    case GROUPING_METHODS.FOOD:
      groupedMarkers = groupByFood(markers, markerResults, lab);
      break;
    default:
      groupedMarkers = groupByDescriptors(markers, markerResults);
  }

  return groupedMarkers.reverse();
}

/* Function to group by food descriptors */
const groupByGroupings = (
  markers: Marker[],
  markerResults: MarkerResult[],
  grouping: string,
) =>
  _.groupBy(markerResults, (markerResult: MarkerResult) => {
    const marker: Marker | undefined = markers.find(
      (currentMarker: Marker) => currentMarker.id === markerResult.marker_id,
    );

    const uniqGroup = _.find(
      // @ts-ignore-next-line : marker result will have matching marker
      marker.groupings,
      (group: any) => group.grouping_type === grouping,
    );

    return (uniqGroup && _.capitalize(uniqGroup.name)) || 'Other';
  });

/* Function to group by food */
const groupByGrouping = (
  markers: Marker[],
  markerResults: MarkerResult[],
  grouping: string,
): FoodSensitivityGroup[] => {
  const groupedResults = groupByGroupings(markers, markerResults, grouping);
  const containers = _.map(
    groupedResults,
    (value: MarkerResult[], key: string) => {
      const title: string = _.startCase(key);
      // Build object of grouped marker data
      return { title, groupedMarkers: value };
    },
  );

  return containers;
};

function generateIntensityResultProps(
  markerResults: MarkerResult[],
  markers: Marker[],
  lab: string,
  groupingType?: string,
): FoodSensitivityGroup[] {
  let groupedMarkers;
  switch (groupingType) {
    case GROUPING_METHODS.REACTIVITY:
      groupedMarkers = groupByDescriptors(markers, markerResults);
      break;
    default:
      if (!groupingType) {
        groupedMarkers = groupByDescriptors(markers, markerResults);
      } else {
        groupedMarkers = groupByGrouping(markers, markerResults, groupingType);
      }
  }
  return groupedMarkers.reverse();
}

export {
  generateFSResultProps,
  groupByDescriptors,
  groupBySeverity,
  groupFSMarkersBySeverity,
  generateIntensityResultProps,
};
