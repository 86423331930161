import {
  colors,
  H5,
  size,
  typography,
  mediaQueries,
  Input,
} from '@everlywell/leaves';
import styled from 'styled-components';

import { BaseRadioButton } from '../KitRegistration/styles';

interface AnswerRadioProps {
  checked: boolean;
}
const handleStyleForClassName = (className: string) => {
  const classStyle = {
    'margin-bottom': '0px',
    'margin-top': '0px',
    'margin-left': '0px',
    width: '100%',
  };
  switch (className) {
    case 'healthcare-setting':
    case 'first-covid-test':
      classStyle[`margin-bottom`] = '56px';
      break;
    case 'vaccine-product':
      classStyle[`margin-top`] = '-124px';
      classStyle[`margin-bottom`] = '56px';
      classStyle[`margin-left`] = `-${size.sm}px`;
      break;
    case 'vaccine-doses':
      classStyle[`margin-bottom`] = '56px';
      classStyle[`margin-left`] = `-${size.sm}px`;
      break;
    case 'vaccine-date':
      classStyle[`margin-left`] = `-${size.sm}px`;
      break;
    case 'vaccinated':
      classStyle[`margin-bottom`] = `180px`;
      break;
    case 'new-york-resident':
      classStyle[`margin-bottom`] = `200px`;
      break;
    case 'new-york-employment':
      classStyle[`margin-top`] = `-144px`;
      break;
    case 'new-york-employer-info':
      classStyle[`margin-bottom`] = `16px`;
      break;
    case 'new-york-student-status':
      classStyle[`margin-top`] = `56px`;
      classStyle[`margin-bottom`] = `32px`;
      break;
    default:
      break;
  }
  return classStyle;
};

const AnswerBox = styled.div`
  ${(props) => handleStyleForClassName(props.className ? props.className : '')};
`;

const Answer = styled(BaseRadioButton)<AnswerRadioProps>`
  border: 1px solid ${colors.green2};
  background-color: ${(props) => (props.checked ? '#f5faf7' : colors.white)};
  box-shadow: ${(props) =>
    props.checked
      ? `0 ${size.xs1}px ${size.xl1}px -${size.sm}px #dce4e5`
      : null};
  width: 100%;
  height: auto;
  margin: ${size.md}px 0 0;
  padding: ${size.md}px ${size.xl1}px ${size.md}px ${size.md}px;

  ${mediaQueries.forTabletVerticalDown} {
    width: 100%;
  }

  label {
    height: auto;
  }

  div {
    width: 100%;
  }

  ul {
    list-style: none; /* Remove default bullets */
    padding-left: ${size.md}px;
    margin-top: -${size.xs3}px;

    li {
      &:before {
        content: '\\2022'; /* Add content: \\2022 is the CSS Code/unicode for a bullet */
        color: ${colors.teal6}; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
      }
    }
  }
`;

const QuestionNumber = styled.p`
  ${typography.overlineText};
  width: 66px;
  height: ${size.md}px;
  margin: ${size.md}px 466px ${size.md}px 0;
  ${mediaQueries.forPhoneOnly} {
    margin: 0 264px ${size.md}px 0;
  }
`;
const Question = styled(H5)`
  ${typography.h5Text};
  width: 100%;
  margin: ${size.sm}px 0 ${size.xl1}px;

  ${mediaQueries.forPhoneOnly} {
    margin-top: ${size.md}px;
  }
`;

const SubText = styled.div`
  ${typography.bodyTextSmall};
  width: 100%;
  min-height: 84px;
`;

const VaccineProductLabel = styled(Input)`
  width: 100%;
  margin: ${size.lg}px 0 0 -${size.xl1}px;
  svg {
    fill: none;
  }
`;

const VaccineDate = styled(Input)`
  width: 100%;
  margin-top: ${size.xl1}px;
  svg {
    fill: none;
  }

  input {
    min-height: 50px;
  }
`;

const NewYorkLabel = styled(Input)`
  width: 532px;
  margin-top: ${size.xl1}px;
  margin-bottom: ${size.md}px;
  svg {
    fill: none;
  }

  input {
    min-height: 50px;
  }
`;

export {
  AnswerBox,
  Answer,
  QuestionNumber,
  Question,
  SubText,
  VaccineProductLabel,
  VaccineDate,
  NewYorkLabel,
};
