import {
  colors,
  Input as LeavesInput,
  Label as LeavesLabel,
  size,
  typography,
} from '@everlywell/leaves';
import { labelTextCSSbyVariant } from 'components/telehealth/FormBuilder/utils/styles';
import { FormBuilderVariant } from 'components/telehealth/FormBuilder/utils/types';
import styled, { css } from 'styled-components';

export const FieldContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: ${size.xs2}px;
  margin-bottom: ${size.md}px;
`;

export const Label = styled(LeavesLabel)<{
  hasError: boolean;
  variant: FormBuilderVariant;
  isRequired: boolean;
}>`
  ${({ hasError }) =>
    hasError &&
    css`
      color: ${colors.red3};
    `}
  ${({ isRequired }) =>
    isRequired &&
    `
    &::after {
      content: '*';
    }
  `}
  display: block;
  width: 100%;
  margin: 0;
  ${(props) => labelTextCSSbyVariant(props.variant)}
`;

export const HelperText = styled.span`
  font-size: ${typography.bodyTextSmall};
  font-weight: ${typography.weight.book};
  color: ${colors.gray4};
  letter-spacing: 0.5px;
`;

export const Input = styled(LeavesInput)`
  max-width: ${Number(size.xl4) + Number(size.xs1)}px;
  margin-bottom: 0;

  input + div,
  [id*='-error'] {
    display: none;
  }
`;

export const InputSeparator = styled.span`
  color: ${colors.gray4};
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: ${size.xs1}px;
`;

export const ErrorMessage = styled.p`
  ${typography.bodyTextSmall};
  font-weight: ${typography.weight.bold};
  color: ${colors.red3};
  min-height: ${size.lg}px;
  line-height: 1.5;
`;

export const ErrorMessageGroup = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: ${size.xs2}px;

  > p {
    margin: 0;
  }
`;
