import {
  colors,
  size,
  typography,
  mediaQueries,
  Button,
} from '@everlywell/leaves';
import styled from 'styled-components';

type Props = {
  viewedAt?: string;
};

const ViewResultsSection = styled.div<Props>`
  background-color: ${colors.gray1};
  padding: ${size.lg}px ${size.md}px;
  display: flex;
  margin-top: ${props => (props.viewedAt ? null : `${size.xl1}px`)};
`;

const TextSection = styled.div`
  flex: 1 0 50%;
`;

const CollectionText = styled.p`
  ${typography.bodyTextXSmall};
  ${mediaQueries.forPhoneOnly} {
    padding-right: 6px;
  }
  ${mediaQueries.forTabletHorizontalUp} {
    font-size: ${size.md}px;
    line-height: ${size.lg}px;
  }
`;
const TimeText = styled.p`
  ${typography.bodyTextXSmall};
  font-weight: ${typography.weight.light};
  ${mediaQueries.forTabletHorizontalUp} {
    font-size: ${size.md}px;
    line-height: ${size.lg}px;
  }
`;

const ViewButton = styled(Button)`
  text-decoration: none !important;
  height: max-content;
  align-self: center;
`;

const HideOnMobile = styled.span`
  display: none;
  ${mediaQueries.forTabletHorizontalUp} {
    display: inline;
  }
`;

export {
  ViewResultsSection,
  TextSection,
  CollectionText,
  TimeText,
  ViewButton,
  HideOnMobile,
};
