import { colors, mediaQueries } from '@everlywell/leaves';
import { hexToRGB } from 'common/utils/helpers';
import styled from 'styled-components';

/* stylelint-disable no-descending-specificity */

const TestResultCard = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px 32px;
  padding: 24px 32px;
  background-color: ${colors.white};
  border-radius: 10px;
  box-shadow: 0 0 9px 0 rgba(${hexToRGB(colors.black)}, 0.04);

  &:last-child {
    display: none;
  }

  ${mediaQueries.forTabletVerticalUp} {
    &:last-child {
      display: flex;
    }
  }

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
  }
`;

// Wrapper for In Progress and Completed sections
const TestResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  &:last-child {
    display: none;
  }

  ${mediaQueries.forTabletVerticalUp} {
    &:last-child {
      display: flex;
    }
  }

  ${mediaQueries.forTabletHorizontalUp} {
    &:first-child {
      ${TestResultCard as any} {
        &:last-child {
          display: none;
        }
      }
    }
  }
`;

const TestResultTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px 20px;
`;

const Divider = styled.div`
  margin: 26px;
  width: 90%;
  height: 1px;
  background-color: ${colors.gray2};

  ${mediaQueries.forTabletHorizontalUp} {
    margin: 0 40px;
    width: 1px;
    height: 80px;
  }
`;

const TestResultDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 30px;
  width: 100%;

  ${mediaQueries.forTabletHorizontalUp} {
    align-items: flex-start;
    width: 70%;
  }
`;

const TestResultDetails = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  width: 100%;

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
  }
`;

const TestResultStatus = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
  }
`;

const TestResultCardList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: row;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: column;
  }
`;

const Spacer = styled.span`
  height: 5px;
`;

/* Skeleton wrappers */
const Heading = styled.div`
  width: 38%;

  ${mediaQueries.forTabletHorizontalUp} {
    width: 28%;
  }
`;

const Number = styled.div`
  width: 10%;
`;

const BoxImage = styled.div`
  width: 55%;

  ${mediaQueries.forTabletHorizontalUp} {
    width: 30%;
  }
`;

const Title = styled.div`
  width: 90%;
`;

const Date = styled.div`
  width: 65%;
`;

const Button = styled.div`
  width: 90%;
  max-width: 218px;

  ${mediaQueries.forTabletHorizontalUp} {
    width: 44%;
  }
`;

const Status = styled.div`
  width: 45%;

  ${mediaQueries.forTabletHorizontalUp} {
    width: 20%;
  }
`;

export {
  TestResultsWrapper,
  TestResultTitle,
  TestResultCard,
  Divider,
  TestResultDescription,
  TestResultDetails,
  TestResultStatus,
  TestResultCardList,
  Spacer,
  Heading,
  Number,
  BoxImage,
  Title,
  Date,
  Button,
  Status,
};
