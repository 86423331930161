import { Checkbox, Row, Col, LocalizeIgnore } from '@everlywell/leaves';
import { useSearchParam } from 'common/polyfill/router';
import analytics from 'common/utils/analytics';
import { API_ERRORS } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { LAB_VISIT_QP } from 'common/utils/constants/bloodDraw';
import { KIT_REGISTRATION_FORM as DATA_TEST } from 'common/utils/constants/dataTest';
import ROUTES from 'common/utils/constants/routes';
import { RUM_ACTIONS } from 'common/utils/constants/rumActions';
import { formatKitId, replaceQueryString } from 'common/utils/helpers';
import useTimeTracker from 'common/utils/useTimeTracker';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { StringParam } from 'serialize-query-params';

import ConfirmationErrorModal from '../Confirmation/ConfirmationErrorModal';
import KitIDModal from '../KitIDModal';
import {
  AT_HOME_REGISTRATION,
  IN_LAB_REGISTRATION,
  RegistrationContent,
} from './constants';
import * as S from './styles';

const textID = 'kitID';
const checkboxID = 'approvedTestTaker';
const CODE = 'code';

const trackQRCodeUsage = (kitId: string) => {
  analytics.track({
    event: ANALYTICS.EVENTS.QR_CODE_USED,
    data: {
      kitId,
    },
  });
};

const isLabVisit = (labVisitParam: string): boolean => labVisitParam === 'true';

const getDynamicRegistrationContent = (
  labVisitParam: string,
): RegistrationContent =>
  isLabVisit(labVisitParam) ? IN_LAB_REGISTRATION : AT_HOME_REGISTRATION;

type Props = {
  onSubmit: (kitID: string) => void;
  errors?: string;
  setErrors?: React.Dispatch<React.SetStateAction<string>>;
};

const KitRegistrationForm = (props: Props) => {
  const { onSubmit, errors, setErrors } = props;

  const navigate = useNavigate();
  const { search } = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [kitID, setKitID] = useState('');
  const [checked, setChecked] = useState(false);
  const [kitDiscountinued, setKitDiscountinued] = useState(false);
  const fireAction = useTimeTracker(RUM_ACTIONS.SUBMIT_KIT_ID);
  const analyticsAttributes = {
    'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
    'data-analytics-category': ANALYTICS.CATEGORIES.REGISTRATION,
    'data-analytics-label': ANALYTICS.LABELS.REGISTRATION,
  };
  const [requestIdFromUrl, setRequestIdFromUrl] = useSearchParam(
    CODE,
    StringParam,
  );
  const [labVisitParam] = useSearchParam<string>(LAB_VISIT_QP);
  const isLabVisitRequest = isLabVisit(labVisitParam);
  const registrationContent = getDynamicRegistrationContent(labVisitParam);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errors && setErrors) {
      setErrors('');
    }
    if (e.target.value.length <= 14) {
      setKitID(formatKitId(e.target.value));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const label = `${registrationContent.title} Submitted`;
    const trackingData = {
      label,
      category: ANALYTICS.CATEGORIES.REGISTRATION,
    };
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: trackingData,
    });
    fireAction();
    onSubmit(kitID);
  };

  const navigateToReplacement = () => {
    navigate(ROUTES.replacement.root);
  };

  const handleModalClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'Open Registration Help Modal',
        category: ANALYTICS.CATEGORIES.REGISTRATION,
      },
    });
    setOpenModal(!openModal);
  };

  useEffect(() => {
    setKitDiscountinued((prevValue) =>
      Boolean(
        errors &&
          !prevValue &&
          errors === API_ERRORS.kitRegistration.DISCONTINUED_KIT,
      ),
    );
  }, [errors]);

  useEffectOnce(() => {
    if (search.includes('complete=')) {
      const urlSearch = replaceQueryString(search, 'complete');
      navigate({ search: urlSearch });
    }
  });

  // Pre-fill input if code in URL via QR Code
  useEffectOnce(() => {
    if (requestIdFromUrl) {
      setKitID(requestIdFromUrl);
      setRequestIdFromUrl(undefined);

      if (!isLabVisitRequest) {
        trackQRCodeUsage(requestIdFromUrl);
      }
    }
  });

  const setConfirmationModalState = (isOpen: boolean) => {
    setKitDiscountinued(isOpen);
  };

  return (
    <>
      <Row start="xs" middle="xs">
        <Col>
          <S.TitleWrapper>
            <S.Title>{registrationContent.title}</S.Title>
          </S.TitleWrapper>
          <S.SubtitleWrapper>
            {registrationContent.subtitle}
            {!isLabVisitRequest && (
              <S.ModalButton
                onClick={handleModalClick}
                data-test="registration-help-modal-trigger"
              >
                Can’t find your Unique <LocalizeIgnore>ID</LocalizeIgnore>?
              </S.ModalButton>
            )}
          </S.SubtitleWrapper>
        </Col>
        <Col>
          <form onSubmit={handleSubmit} data-test={DATA_TEST.FORM}>
            <S.KitIDInput
              inputClassName="inspectletIgnore"
              label={registrationContent.inputLabel}
              id={textID}
              value={kitID}
              name={textID}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e)
              }
              data-test={DATA_TEST.INPUT}
              error={errors}
            />
            <S.CheckboxContainer>
              <Checkbox
                name={checkboxID}
                label="I am the test taker"
                onChange={() => setChecked(!checked)}
                checked={checked}
                data-test={DATA_TEST.CHECKBOX}
                border
              />
            </S.CheckboxContainer>
            <S.Disclaimer>{registrationContent.disclaimer}</S.Disclaimer>
            <S.Submit
              isDisabled={!checked || kitID.length === 0}
              appearance="primary"
              data-test={DATA_TEST.SUBMIT}
              {...analyticsAttributes}
            >
              {registrationContent.registerLabel}
            </S.Submit>
          </form>
        </Col>
        <KitIDModal openModal={openModal} setOpenModal={setOpenModal} />
      </Row>
      {kitDiscountinued && (
        <ConfirmationErrorModal
          openModal={kitDiscountinued}
          setOpenModal={navigateToReplacement}
          onClose={() => setConfirmationModalState(false)}
          title="Unable to Register"
          message="The kit you are trying to register is no longer able to be processed by our partner lab. We are happy to send you a replacement kit so that you can receive results."
          cta="Replace Kit"
        />
      )}
    </>
  );
};

export default KitRegistrationForm;
