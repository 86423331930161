import useUser from 'common/hooks/useUser/useUser';
import {
  MEMBERSHIP_PROGRAMS_LIST,
  MembershipProgram,
} from 'common/utils/types';
import { isUserMemberOfProgram } from 'common/utils/user';
import MembershipPlanInfo from 'components/MembershipPlanInfo';
import React from 'react';

import MembershipShippingForm from '../MembershipShippingForm';
import { PaymentInfo } from '../PaymentInfo';
import * as S from './MembershipsSettingsTabContent.styles';

export default function MembershipsSettingsTabContent() {
  const { data: userData } = useUser();

  if (!userData?.data) {
    return null;
  }

  const hasActiveSubscription = isUserMemberOfProgram(
    userData.data,
    MEMBERSHIP_PROGRAMS_LIST,
  );

  // We show the plan info for enabled or disabled programs
  const enabledOrDisabledPrograms = [
    ...(userData?.data?.user_plans?.enabled || []),
    ...(userData?.data?.user_plans?.disabled || []),
  ];

  const programSlug = enabledOrDisabledPrograms[0]
    ?.program_plan_type as MembershipProgram;

  return (
    <S.Container data-testid="settings-page-weight-care-tab">
      <MembershipPlanInfo programPlanType={programSlug} />
      <MembershipShippingForm />
      {hasActiveSubscription && <PaymentInfo />}
    </S.Container>
  );
}
