const initJustUno = () => {
  if (process.env.JUSTUNO_ACCOUNT_ID === undefined) {
    return;
  }

  // Code adapted from https://my.justuno.com/admin/v2/account/embed
  window.ju_num = process.env.JUSTUNO_ACCOUNT_ID;
  window.asset_host = '//cdn.jst.ai/';
  (function (i, s, o, g, r, a, m) {
    i[r] =
      i[r] ||
      function () {
        (i[r].q = i[r].q || []).push(arguments);
      };
    // eslint-disable-next-line no-unused-expressions, no-sequences
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(window, document, 'script', `${window.asset_host}vck.js`, 'juapp');
};

export { initJustUno };
