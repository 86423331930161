import { logError } from 'common/utils/helpers';
import * as React from 'react';

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface State {
  hasError: boolean;
}

export default class GlobalErrorBoundary extends React.Component<Props, State> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: any) {
    logError(error.message, info);
  }

  render() {
    if (this.state.hasError) {
      return null;
      // TODO: show global error dialog?
    }

    return this.props.children;
  }
}
