import { theme } from '@everlywell/ui-kit';
import { StylesConfig, Theme } from 'react-select';

import { OptionType } from '../types';

const {
  space,
  fontSizes,
  fontWeights,
  fonts,
  colors: { viridian, tints, redCS },
} = theme;

const BORDER_RADIUS = space[2];

export const ThemeMultiSelect = (themeMultiSelect: Theme) => ({
  ...themeMultiSelect,
  colors: {
    ...themeMultiSelect.colors,
    primary: viridian.base,
    primary75: viridian.light,
    primary50: viridian.lighter,
    primary25: viridian.wash,
  },
});

type AdditionalProps = {
  hasError?: boolean;
  isMobile?: boolean;
};
export const colourStyles = ({
  hasError,
  isMobile,
}: AdditionalProps): StylesConfig<OptionType, true> => ({
  container: (styles) => ({
    ...styles,
  }),
  control: (styles, props) => {
    const errorStyles = hasError
      ? {
          border: `1px solid ${redCS[50]}`,
          ':hover': {
            border: `1px solid ${redCS[50]}`,
            boxShadow: `0 0 0 1px ${redCS[50]}`,
          },
        }
      : {};

    return {
      ...styles,
      background: tints.white,
      border: `1px solid ${viridian.base}`,
      borderRadius: BORDER_RADIUS,
      fontFamily: fonts.body,
      padding: space[2],
      paddingRight: space[3],
      '& > div': {
        gap: space[2],
        padding: '0',
      },
      ...errorStyles,
    };
  },
  dropdownIndicator: (styles, data) => {
    const transformed = data.selectProps.menuIsOpen && {
      transform: 'rotate(180deg)',
    };

    return {
      ...styles,
      padding: '0',
      willChange: 'transform',
      transition: '.3s all ease-in-out',
      ...transformed,
    };
  },
  input: (styles) => ({
    ...styles,
    margin: 0,
    padding: `${space[2]} 0`,
  }),
  multiValue: (styles, { data }) => {
    const paddingHeight = !isMobile ? space[2] : space[0.5];
    return {
      ...styles,
      alignItems: 'center',
      backgroundColor: tints.cream,
      borderRadius: BORDER_RADIUS,
      boxSizing: 'content-box',
      display: 'flex',
      gap: space[2],
      height: space[6],
      justifyContent: 'center',
      padding: paddingHeight,
      margin: 0,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: tints.black,
    fontFamily: fonts.body,
    fontSize: fontSizes.md,
    fontStyle: 'normal',
    fontWeight: fontWeights.medium,
    lineHeight: '150%' /* 21px */,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    height: '100%',
    overflow: 'hidden',
    padding: '0',
    width: 'auto',

    ':hover': {
      cursor: 'pointer',
      padding: '0',
    },
  }),
  menu: (styles, props) => ({
    ...styles,
    borderRadius: BORDER_RADIUS,
  }),
  menuList: (styles, data) => ({
    ...styles,
    background: tints.white,
    border: `1px solid ${hasError ? redCS[50] : viridian.base}`,
    borderRadius: BORDER_RADIUS,
    padding: '0',
    paddingBottom: space[4],
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    textAlign: 'left',
  }),
  option: (_styles, props) => {
    const disabledStyles = props.data.isDisabled && {
      color: tints.uiGrey,
      cursor: 'not-allowed',
    };
    return {
      fontSize: isMobile ? fontSizes.md : fontSizes.lg,
      marginTop: space[4],
      padding: `0 ${space[4]}`,
      color: tints.black,
      cursor: 'pointer',
      ':hover': {
        backgroundColor: viridian.wash,
      },
      ':last-of-type': {
        marginBottom: space[4],
      },
      ...disabledStyles,
    };
  },
});

export const optionStyles = {
  alignItems: 'center',
  display: 'flex',
  fontFamily: fonts.body,
  justifyContent: 'space-between',
};

export const wrapOptionStyles = {
  backgroundColor: 'transparent',
  ':hover, :focus': {
    background: 'transparent !impotant',
  },
};

type CheckTypes = {
  isSelected?: boolean;
  isDisabled?: boolean;
};

export const checkStyles = ({ isSelected = false, isDisabled }: CheckTypes) => {
  const colourBorder = isSelected ? viridian.base : tints.uiGrey;
  const colourBg = isSelected ? viridian.base : tints.white;

  const disabledStyles = isDisabled && {
    borderColor: tints.uiGrey,
    opacity: '0.5',
    background: tints.lightGrey,
  };

  return {
    alignItems: 'center',
    background: colourBg,
    border: `1.5px solid ${colourBorder}`,
    borderRadius: space[1.5],
    display: 'flex',
    justifyContent: 'center',
    ...disabledStyles,
  };
};

export const groupStyles = {
  borderRadius: BORDER_RADIUS,
  border: `1px solid ${viridian.base})`,
  background: tints.white,
};
