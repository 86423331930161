import { postRequest, getRequest } from 'common/hooks/useApi/request';
import { API_ROOT } from 'common/utils/constants';
import { RedeemableProductsResponse } from 'common/utils/types';

export type RedemptionCreditRequestData = {
  products: [{ slug: string }];
};

export const redeemCredit = async (
  programId: number,
  requestPayload: RedemptionCreditRequestData,
) =>
  postRequest(
    `${API_ROOT}/v3/memberships/${programId}/redemptions`,
    requestPayload,
    false,
    {
      rejectOnError: true,
    },
  );

export const getRedeemableProducts = async (
  membershipId: number | undefined,
  telehealthOnly: boolean = false,
) =>
  await getRequest<RedeemableProductsResponse>(
    `${API_ROOT}/v3/memberships/${membershipId}/redeemable_products?telehealth_only=${telehealthOnly}`,
    false,
    { rejectOnError: true },
  );
