import {
  Button,
  Col,
  H4,
  Input,
  Modal,
  colors,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import * as BaseQuestion from 'components/BaseQuestion/styles';
import styled, { css } from 'styled-components';

const Question = styled(BaseQuestion.Question)`
  margin: ${size.sm}px 0 ${size.lg}px;
`;

const EntryContainer = styled.div`
  border-radius: ${size.xs2}px;
  border: 1px solid ${colors.green2};
  color: ${colors.green5};
  font-size: ${size.md}px;
  font-weight: ${typography.weight.xbold};
  line-height: 24px;
  margin-top: ${size.md}px;
  padding: ${size.sm}px;
  width: 100%;

  &:hover {
    border: 1px solid ${colors.green4};
  }
`;

const EntryContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AddButton = styled.button`
  font-weight: ${typography.weight.xbold};
  &:hover {
    cursor: pointer;
  }
`;

const EntryModal = styled(Modal)`
  height: 100%;
`;

const OtherLabel = styled(Input)`
  width: 100%;

  label {
    font-weight: ${typography.weight.book};
    margin-bottom: ${size.md}px;
  }

  svg {
    display: none;
  }
`;
const baseButton = css`
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    width: calc((100% / 2) - 8px);
    height: ${size.xl3}px;
  }
`;

const RemoveButton = styled(Button)`
  color: ${colors.gray3};
  border: solid 1px ${colors.gray3};
  ${baseButton}
`;

const SaveButton = styled(Button)`
  margin-top: ${size.sm}px;
  ${baseButton};

  ${mediaQueries.forTabletVerticalUp} {
    margin-top: 0;
    margin-left: ${size.md}px;
  }
`;

const VaccineEntry = styled.div`
  border-radius: ${size.xs2}px;
  border: solid 1px ${colors.green2};
  color: ${colors.gray4};
  font-size: 18px;
  margin-bottom: ${size.md}px;
  padding: ${size.md}px;
`;

const VaccineEntryH4 = styled(H4)`
  margin-bottom: ${size.xs1}px;
`;

const EditButton = styled.button`
  color: ${colors.green5};
  display: flex;
  font-size: 16px;
  font-weight: ${typography.weight.xbold};
  margin-left: auto;
`;
const numberContainerSize = `${size.lg}px`;
const DoseNumber = styled.div`
  align-items: center;
  background-color: ${colors.green1};
  border-radius: 50%;
  color: ${colors.green5};
  display: flex;
  font-size: 15px;
  height: ${numberContainerSize};
  justify-content: center;
  padding: 6px;
  width: ${numberContainerSize};
`;

const VaccineEntryContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const DosesCopy = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${size.sm}px;
`;

const VaccineShotText = styled.p`
  ${typography.subtitle}
  line-height: ${size.lg}px;
`;

const VaccineDetailsText = styled.p`
  ${typography.bodyTextSmall}
  line-height: ${size.lg}px;
`;

const Column = styled(Col)`
  padding: 0;
`;

const ButtonsContainer = styled.section`
  margin-top: ${size.xl3}px;
  display: flex;
  flex-direction: column;

  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: row;
  }
`;

const Question2 = styled(BaseQuestion.Question)`
  margin-top: ${size.xl3}px;
  margin-bottom: ${size.lg}px;
`;

const RadioButtonsContainer = styled.section`
  & > div {
    &:first-of-type {
      margin-top: 0;
    }
  }
  .other-radio-button {
    label {
      &:before {
        top: 0;
      }
      &:after {
        top: 10px;
      }
    }
  }
`;

export {
  AddButton,
  ButtonsContainer,
  Column,
  DoseNumber,
  DosesCopy,
  EditButton,
  EntryContainer,
  EntryContentWrapper,
  EntryModal,
  OtherLabel,
  Question,
  Question2,
  RadioButtonsContainer,
  RemoveButton,
  SaveButton,
  VaccineDetailsText,
  VaccineEntry,
  VaccineEntryContentWrapper,
  VaccineEntryH4,
  VaccineShotText,
};
