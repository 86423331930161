// Hook
import { useState } from 'react';

import { logError } from '../../utils/helpers';

export function useLocalStorage<T = string>(
  key: string,
  initialValue: T,
): [T, (value: T) => void] {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      if (error instanceof Error) {
        logError(error.message, {
          component: `getValue for ${key}`,
          method: `getValue in useLocalStorage`,
        });
      }
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      if (error instanceof Error) {
        logError(error.message, {
          component: `setValue for ${key}`,
          method: 'setValue in useLocalStorage',
        });
      }
    }
  };

  return [storedValue, setValue];
}
