import { TextArea as LeavesTextArea } from '@everlywell/leaves';
import styled from 'styled-components';

import { labelStyles } from '../../utils/styles';
import { FormBuilderVariant } from '../../utils/types';

export const Textarea = styled(LeavesTextArea)<{
  variant: FormBuilderVariant;
}>`
  margin-bottom: 0;
  ${({ variant }) => labelStyles(variant)}
`;
