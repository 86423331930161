import { LocalizeDisabled } from '@everlywell/leaves';
import useUser from 'common/hooks/useUser/useUser';
import analytics, { TrackVisibilityWrapper } from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { DEFAULT_BOX_IMAGE_URL } from 'common/utils/constants/urls';
import { WWW_APP_ROOT } from 'common/utils/constants/urls';
import { logError } from 'common/utils/helpers';
import { RecommendedTest } from 'common/utils/types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import * as S from './styles';

export interface RecommendedTestCardsProps {
  recommendedTests: RecommendedTest[];
  formAnswerGroupId: string | null;
}

const VIEW_PRODUCT = 'View product';
const RECOMMENDED_TITLE = 'Recommended test';
const REDEEM_TITLE = 'Redeem Credit';
const NY_REDEEMABLE_PRODUCT_SlUGS = [
  'multivitamin-gummy',
  'omega-3-fish-oil-1000-mg',
];

const RecommendedTestCards = ({
  recommendedTests,
  formAnswerGroupId,
}: RecommendedTestCardsProps) => {
  const { data: userData } = useUser();
  const navigate = useNavigate();

  const userState = userData?.data?.health_provider_user_state;
  const isLocatedInNY = userState === 'New York';

  const handleViewProduct = (slug: string) => {
    try {
      //TODO: WSH-346 add promo code in below URL when BE is ready
      const url = `${WWW_APP_ROOT}/products/${slug}/`;

      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: ANALYTICS.LABELS.CARE_PLAN.CARE_PLAN_VIEW_PRODUCT,
          formAnswerGroupId,
        },
      });
      return window.open(url, '_blank');
    } catch (error) {
      logError((error as Error).message, {
        method: 'handleViewProduct',
        component: 'RecommendedTestCards',
        params: { formAnswerGroupId, recommendedTests },
      });
    }
  };

  const handleRedeemCredit = () => {
    try {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: ANALYTICS.LABELS.CARE_PLAN.CARE_PLAN_REDEEM_CREDIT,
          formAnswerGroupId,
        },
      });

      return navigate('/credit-redemption');
    } catch (error) {
      logError((error as Error).message, {
        method: 'handleRedeemCredit',
        component: 'RecommendedTestCards',
        params: { formAnswerGroupId, recommendedTests },
      });
    }
  };

  const handleClick = (test: RecommendedTest) => {
    if (!test.credit_redeemable) return handleViewProduct(test.slug);

    if (
      test.credit_redeemable &&
      isLocatedInNY &&
      NY_REDEEMABLE_PRODUCT_SlUGS.includes(test.slug)
    ) {
      return handleRedeemCredit();
    }

    if (test.credit_redeemable && !isLocatedInNY) return handleRedeemCredit();
  };

  return (
    <TrackVisibilityWrapper
      label={ANALYTICS.LABELS.CARE_PLAN.TEST_RECOMMENDATION}
      formAnswerGroupId={formAnswerGroupId}
    >
      <S.CardsWrapper>
        {recommendedTests.map((test) => (
          <S.Card key={test.name}>
            <S.Title>{RECOMMENDED_TITLE}</S.Title>
            <S.TestImage src={test.box_image_url || DEFAULT_BOX_IMAGE_URL} />
            <S.TestName>{test.name}</S.TestName>
            <S.TestDescription>{test.meta_description}</S.TestDescription>
            <S.TestPrice>
              <LocalizeDisabled>{test.price_in_dollars}</LocalizeDisabled>
            </S.TestPrice>
            <S.AddCartButton
              appearance="secondary"
              onClick={() => handleClick(test)}
              data-variantid={test.master_variant_id}
              isDisabled={
                !test.master_variant_id ||
                !Number.isInteger(test.master_variant_id)
              }
            >
              {test.credit_redeemable ? REDEEM_TITLE : VIEW_PRODUCT}
            </S.AddCartButton>
          </S.Card>
        ))}
      </S.CardsWrapper>
    </TrackVisibilityWrapper>
  );
};

export default RecommendedTestCards;
