import { LEGACY_APP_ROOT, MY_APP_ROOT } from 'common/utils/constants';
import {
  clearUserLocalStorageAndSession,
  isHumanaCustomer,
} from 'common/utils/helpers';

/**
 *
 * HTTP response status codes
 * HTTP response status codes indicate whether a specific HTTP request has been successfully completed.
 * Responses are grouped in five classes:
 *
 *  - Informational responses (100–199)
 *  - Successful responses (200–299)
 *  - Redirection messages (300–399)
 *  - Client error responses (400–499)
 *  - Server error responses (500–599)
 *
 * Read more: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
 */

export enum HTTPStatusCode {
  OK = 200,
  NO_CONTENT = 204,
  RESET_CONTENT = 205,
  MULTIPLE_CHOICES = 300,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export type Response<T = any> = {
  data?: T | null;
  statusCode: number;
  errors?: any;
  wasSuccessful: boolean;
  headers?: Headers;
};

type CustomBaseRequestOptions = {
  // flag to indicate that a error response needs to be thrown.
  rejectOnError?: boolean;
};

export type BaseRequestOptions = RequestInit & CustomBaseRequestOptions;

export class BaseRequestError extends Error {
  fetchResponse: globalThis.Response;
  data: any;

  // TODO: Add proper typing for data depending on the API response
  constructor(message: string, fetchResponse: globalThis.Response, data: any) {
    super(message);
    this.name = 'BaseRequestError';
    this.fetchResponse = fetchResponse;
    this.data = data;
  }
}

/**
 * Helper to make requests to the API
 * @param url
 * @param baseRequestOptions
 * @param shouldRedirectOnError
 * @throws {BaseRequestError} Will thrown an error if the response does not have a successful status and rejectOnError is true
 * @returns
 */
export async function baseRequest<T>(
  url: string,
  baseRequestOptions: BaseRequestOptions = {},
  shouldRedirectOnError: boolean,
): Promise<Response<T>> {
  const jwtToken = window.localStorage.getItem('jwtToken');

  const {
    rejectOnError = false,
    headers = {
      'Content-Type': 'application/json',
    },
    ...initOptions
  } = baseRequestOptions;

  const defaultOptions: RequestInit = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      ...(isHumanaCustomer() && { 'X-EW-Partner': 'humana' }),
      ...headers,
    },
  };

  const response = await fetch(url, { ...defaultOptions, ...initOptions });
  const { status } = response;
  const wasSuccessful =
    status >= HTTPStatusCode.OK && status < HTTPStatusCode.MULTIPLE_CHOICES;

  if (status === HTTPStatusCode.UNAUTHORIZED) {
    clearUserLocalStorageAndSession();

    let newLocation = `${LEGACY_APP_ROOT}/logout`;
    const redirect = encodeURIComponent(window.location.href);
    newLocation += `?my_results_redirect=${redirect}`;
    window.location.assign(newLocation);
    return {
      statusCode: status,
      wasSuccessful,
    };
  }

  if (!wasSuccessful && shouldRedirectOnError) {
    window.location.assign(`${MY_APP_ROOT}/${status}`);
    return {
      statusCode: status,
      wasSuccessful,
    };
  }

  const data = [
    HTTPStatusCode.NO_CONTENT,
    HTTPStatusCode.RESET_CONTENT,
  ].includes(status)
    ? null
    : await response
        .json()
        .then((json) => json)
        .catch(() => null);

  if (!wasSuccessful && rejectOnError === true) {
    const method = initOptions.method ?? 'GET';

    throw new BaseRequestError(`${method} ${url} failed`, response, data);
  }

  return {
    data,
    statusCode: status,
    errors: data?.errors,
    wasSuccessful,
    headers: response.headers,
  };
}

export async function getRequest<T = any>(
  url: string,
  shouldRedirectOnError = true,
  options: BaseRequestOptions = {},
) {
  return baseRequest<T>(
    url,
    {
      ...options,
      method: 'GET',
    },
    shouldRedirectOnError,
  );
}

export async function putRequest<T = any>(
  url: string,
  body: object,
  shouldRedirectOnError = false,
  options: BaseRequestOptions = {},
) {
  return baseRequest<T>(
    url,
    {
      ...options,
      method: 'PUT',
      body: JSON.stringify(body),
    },
    shouldRedirectOnError,
  );
}

export async function postRequest<T = any>(
  url: string,
  body: object,
  shouldRedirectOnError = false,
  options: BaseRequestOptions = {},
) {
  return baseRequest<T>(
    url,
    {
      body: JSON.stringify(body),
      ...options,
      method: 'POST',
    },
    shouldRedirectOnError,
  );
}

export async function patchRequest<T = any>(
  url: string,
  body: object,
  shouldRedirectOnError = false,
  options: BaseRequestOptions = {},
) {
  return baseRequest<T>(
    url,
    {
      ...options,
      method: 'PATCH',
      body: JSON.stringify(body),
    },
    shouldRedirectOnError,
  );
}

export function handleError(error: BaseRequestError | null) {
  if (error) {
    const customMessage =
      error?.data?.type === 'InternalServerError' ||
      error?.data?.type === 'InvalidRequestError'
        ? error?.data?.errors[0]?.message
        : null;
    const message =
      customMessage ??
      'Oops! Something went wrong. Please try again or contact support for help.';

    return { message };
  }

  return null;
}
