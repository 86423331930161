import { useSearchParam } from 'common/polyfill/router';
import { useHealthProfileContext } from 'common/providers/HealthProfileProvider';
import {
  HealthProfileQuestionnaire,
  KitRegistrationResponse,
} from 'common/utils/types';
import { isUndefined } from 'lodash-es';
import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { StringParam } from 'serialize-query-params';

import { useAnswers } from '../../common/providers/AnswersProvider';
import { useNavigationQuestion } from '../../common/providers/NavigationQuestionProvider';
import { Complete, Questions } from './components/HelpersComponents';
import { useValidationParameter } from './helpers';
import {
  useInitialEffects,
  useCastQuestions,
  useInitialCurrentQuestion,
  useQuestionsCounter,
  useFilterQuestions,
  useRedirectToParam,
} from './hooks';
export interface HealthProfileProps {
  healthProfileData: HealthProfileQuestionnaire;
  kitRegistration?: KitRegistrationResponse;
}

export const HealthProfile = ({
  healthProfileData,
  kitRegistration,
}: HealthProfileProps) => {
  const { questions, has_started, id: questionnaireId } = healthProfileData;
  const { setAllAnswers } = useAnswers();
  const { keyValueQuestionsSet, prevQuestionId, currentQuestionId } =
    useNavigationQuestion();
  const testUrlChunk = useValidationParameter('test');
  const unansweredUrlChunk = useValidationParameter('unanswered');
  const [redirectToParam] = useSearchParam('redirect', StringParam);

  const { redirection, setRedirectionURL } = useHealthProfileContext();
  const navigate = useNavigate();

  const location = useLocation();
  const pathname = location.pathname;

  const validator = unansweredUrlChunk ? 'unanswered' : 'test';
  const urlChunk = unansweredUrlChunk ? unansweredUrlChunk : testUrlChunk;
  const unansweredParam = validator === 'unanswered';
  const castAllQuestions = useCastQuestions(questions);

  keyValueQuestionsSet.current.all = castAllQuestions;

  useInitialEffects({
    pathname,
    redirectToParam,
    setRedirect: setRedirectionURL,
  });

  const filterQuestions = useFilterQuestions({
    questions,
    unansweredParam,
    keyValueQuestionsSetAll: keyValueQuestionsSet.current.all,
  });

  keyValueQuestionsSet.current.answered = unansweredParam;
  const firstQQId =
    filterQuestions &&
    Array.isArray(filterQuestions) &&
    filterQuestions[0]?.questionnaire_question_id;

  useEffect(() => {
    const answers = questions.reduce(
      (total, q) => ({
        ...total,
        [q.questionnaire_question_id]: q.response,
      }),
      {},
    );
    setAllAnswers(() => answers);
  }, [questions, setAllAnswers]);
  useEffectOnce(() => {
    if (urlChunk) {
      const url = `/health-profile/${questionnaireId}/${firstQQId}/1${urlChunk}`;
      if (isUndefined(questionnaireId) || isUndefined(firstQQId)) {
        navigate('/dashboard');
      } else {
        navigate(url);
      }
    }
  });

  useRedirectToParam({
    has_started,
    redirection,
    redirectToParam,
    setRedirectionURL,
  });

  const healthProfileRegex = new RegExp(/^\/health-profile\/$/);
  const match = pathname.match(healthProfileRegex);
  const isFirstQuestion =
    firstQQId === currentQuestionId &&
    match?.length === 1 &&
    !unansweredUrlChunk;

  useInitialCurrentQuestion(
    filterQuestions,
    questionnaireId,
    firstQQId,
    urlChunk,
  );
  const questionsLength = useQuestionsCounter(filterQuestions);
  if (Number(questionsLength) === 0) {
    return null;
  }
  if (isFirstQuestion) {
    navigate(`/health-profile/${questionnaireId}/${firstQQId}/1${urlChunk}`);
  }

  return (
    <Routes>
      {has_started && !unansweredUrlChunk && !testUrlChunk ? (
        <Route path="/" element={<Navigate to="/health-profile/complete" />} />
      ) : null}
      <Route
        path={`:questionnaireId/:id/:step/*`}
        key={currentQuestionId}
        element={
          <Questions
            questionsLength={questionsLength}
            index={prevQuestionId.length}
            questionnaireId={questionnaireId}
            progressPath={!!unansweredUrlChunk}
          />
        }
      />
      <Route
        path="complete/*"
        element={
          <Complete kitRegistration={kitRegistration} redirect={redirection} />
        }
      />
    </Routes>
  );
};
export default HealthProfile;
