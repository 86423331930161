import Grid from 'components/Grid/Grid';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Input, labelStyles } from '../../utils/styles';
import { FormBuilderField } from '../../utils/types';

export type NameFieldProps = FormBuilderField;

function NameField(props: NameFieldProps) {
  const { register, errors, watch } = useFormContext();

  /**
   * We need to use a temp field name because the name prop is used to send the proper data to the backend.
   */
  const tempFieldsName = `${props.id}-tmp`;

  return (
    <React.Fragment key={props.id}>
      <Grid.Item width={[1, 1 / 2]}>
        <Input
          css={labelStyles(props.variant)}
          label={'First Name'}
          type="text"
          autoComplete="given-name"
          id={`${tempFieldsName}.firstName`}
          name={`${tempFieldsName}.firstName`}
          required={props.required}
          ref={register({
            required: props.required && 'Please enter your first name',
          })}
          error={errors[tempFieldsName]?.firstName?.message}
        />
      </Grid.Item>
      <Grid.Item width={[1, 1 / 2]}>
        <Input
          css={labelStyles(props.variant)}
          label={'Last Name'}
          type="text"
          autoComplete="family-name"
          id={`${tempFieldsName}.lastName`}
          name={`${tempFieldsName}.lastName`}
          required={props.required}
          ref={register({
            required: props.required && 'Please enter your last name',
          })}
          error={errors[tempFieldsName]?.lastName?.message}
        />
      </Grid.Item>
      <input
        type="hidden"
        name={props.id}
        ref={register()}
        value={`${watch(`${tempFieldsName}.firstName`) ?? ''} ${
          watch(`${tempFieldsName}.lastName`) ?? ''
        }`}
      />
    </React.Fragment>
  );
}

export default NameField;
