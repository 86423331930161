import { MARKER_TYPES } from 'common/utils/constants';
import { MarkerValueException, MarkerResult } from 'common/utils/types';
import React from 'react';

import * as S from './styles';

type Props = {
  isMultiNumerical?: boolean;
  formattedDataTest: string;
  isException: boolean;
  exception: MarkerValueException;
  descriptor: string;
  showMarkerValue: boolean;
  markerResult: MarkerResult;
};

const BOUNDARY_CONDITIONS = {
  LOWER: 'lower',
  UPPER: 'upper',
};

const comparisonFunctions = {
  '<': (val1: number, val2: number) => val1 < val2,
  '<=': (val1: number, val2: number) => val1 <= val2,
  '>': (val1: number, val2: number) => val1 > val2,
  '>=': (val1: number, val2: number) => val1 >= val2,
};

const getRangeValue = (markerResult: MarkerResult) => {
  const { boundaries, boundary_conditions: boundaryConditions } = markerResult;
  let { value: markerValue = '' } = markerResult;
  const totalBoundaries = boundaries.length;
  const lastBoundaryIndex = totalBoundaries - 1;
  const lowerBound = boundaries[0];
  const upperBound = boundaries[lastBoundaryIndex];

  const lowerBoundCondition = boundaryConditions[0];
  const upperBoundCondition = boundaryConditions[lastBoundaryIndex];

  // to check whether the marker value lies between two boundaries and if the boundary values are inclusive in that range.
  const lowerConditionOperator =
    lowerBoundCondition === BOUNDARY_CONDITIONS.UPPER ? '>=' : '>';
  const upperConditionOperator =
    upperBoundCondition === BOUNDARY_CONDITIONS.LOWER ? '<=' : '<';

  let range = '';

  if (typeof markerValue === 'string') {
    // instead of stripping off < and > characters and converting to a Number,
    // if it's a less-than value (like <0.05), treat it as negative
    // if it's a greater-than value, treat it as max value
    // this prevents the lower/upper condition operators from including these
    // values, becuase <0.05 would otherwise convert to 0.05 for comparison
    // this is not called for exceptions (like N/A) so no need to handle them
    markerValue = markerValue[0] === '<' ? -1 : Number.MAX_VALUE;
  }

  // if marker value is less than the first boundary
  if (markerValue < lowerBound) {
    range = `${
      lowerBoundCondition === BOUNDARY_CONDITIONS.LOWER ? '<=' : '<'
    } ${lowerBound}`;
    // if marker value is greater than the last boundary
  } else if (markerValue > upperBound) {
    range = `${
      upperBoundCondition === BOUNDARY_CONDITIONS.UPPER ? '>=' : '>'
    } ${upperBound}`;
    // if marker value lies between the 2 boundaries
  } else if (
    comparisonFunctions[lowerConditionOperator](markerValue, lowerBound) &&
    comparisonFunctions[upperConditionOperator](markerValue, upperBound)
  ) {
    range = `${lowerBound} to ${upperBound}`;
    // if marker value is equal to one of the boundaries
  } else if (markerValue === upperBound || markerValue === lowerBound) {
    // index of the boundary which is equal to the marker value.
    const boundaryIndex = boundaries.indexOf(markerValue);
    const boundaryCondition = boundaryConditions[boundaryIndex];
    if (boundaryCondition === BOUNDARY_CONDITIONS.LOWER) {
      range = `<= ${markerValue}`;
    } else {
      range = `>= ${markerValue}`;
    }
  }

  return range;
};

const MarkerRangeDetails = (props: Props) => {
  const {
    isMultiNumerical = false,
    isException,
    formattedDataTest,
    exception,
    descriptor,
    markerResult,
    showMarkerValue,
  } = props;

  const rangeValue = getRangeValue(markerResult);
  const isPlainNumberType = markerResult.kind === MARKER_TYPES.PLAIN_NUMBER;
  const footerDescriptor = () => {
    if (!isPlainNumberType && isException) {
      return exception.footerDescriptor;
    }

    if (!isPlainNumberType && !isException) {
      return `${descriptor} Range`;
    }
    return null;
  };

  return (
    <S.MarkerRangeDetailsWrapper
      isMultiNumerical={Boolean(isMultiNumerical)}
      showMarkerValue={showMarkerValue}
      data-test={`${formattedDataTest}-range-details`}
    >
      <S.RangeNameFooter
        isException={isException}
        data-test={`${formattedDataTest}-range-level`}
      >
        {isPlainNumberType ? `Normal Range` : null}
        {footerDescriptor()}
      </S.RangeNameFooter>
      {!isException && (
        <S.LabelValue data-test={`${formattedDataTest}-range-value`}>
          {isPlainNumberType
            ? `${markerResult.boundaries[0]} to ${markerResult.boundaries[1]}`
            : rangeValue}
        </S.LabelValue>
      )}
    </S.MarkerRangeDetailsWrapper>
  );
};

export default MarkerRangeDetails;
