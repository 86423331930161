import Grid from 'components/Grid';
import React from 'react';

import * as S from './InsuranceCard.styles';

export type InsuranceCardProps = {
  heading: string;
  insuranceName: string;
  memberID: string;
  groupID: string;
};

/**
 * Card component to display insurance information
 */
function InsuranceCard(props: InsuranceCardProps) {
  return (
    <div>
      <S.Heading>{props.heading}</S.Heading>
      <S.Card>
        <Grid.Container spacing={['sm']}>
          <Grid.Item width={[1]}>
            <S.Label>Insurance company name:</S.Label>
            <S.Text>{props.insuranceName}</S.Text>
          </Grid.Item>
          <S.StyledGridItem width={['auto']}>
            <S.Label>Member ID #:</S.Label>
            <S.Text>{props.memberID}</S.Text>
          </S.StyledGridItem>
          <Grid.Item width={['auto']}>
            <S.Label>Group ID #:</S.Label>
            <S.Text>{props.groupID}</S.Text>
          </Grid.Item>
        </Grid.Container>
      </S.Card>
    </div>
  );
}

export default InsuranceCard;
