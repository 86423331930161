import LabIcon from 'pages/CarePlanPage/components/CarePlanLayout/LabDetails/icons/LabIcons.svg';
import LifestyleChangesIcon from 'pages/CarePlanPage/components/LifestyleRecommendationsCard/Icons/lifestylechanges.svg';
import PrescriptionIcon from 'pages/CarePlanPage/components/MedicationDetails/Icons/prescription.svg';
import ScreeningsIcon from 'pages/CarePlanPage/components/RecommendationCard/Icons/screenings.svg';
import VaccinationIcon from 'pages/CarePlanPage/components/RecommendationCard/Icons/vaccination.svg';

export default {
  patient_plan: {
    header: 'Patient Plan',
    title:
      'Provider recommends testing to rule out potential blood condition prior to next session',
  },
  medication_list: {
    header: 'Current medication list',
    image_icon: PrescriptionIcon,
    content: {
      view_details: 'View details',
      hide_details: 'Hide details',
      dosage_text: 'Dosage:',
      status_text: 'Status:',
      instructions_text: 'Instructions:',
      comments_text: 'Comments:',
      active_text: 'Active',
      inactive_text: 'Inactive',
    },
  },
  screening_recommendations: {
    header: 'Screenings',
    title: 'Provider recommends',
    image_icon: ScreeningsIcon,
  },
  vaccination_recommendations: {
    header: 'Vaccination',
    title: 'Provider recommends',
    image_icon: VaccinationIcon,
  },
  lifestyle_changes: {
    header: 'Lifestyle changes',
    image_icon: LifestyleChangesIcon,
  },
  lab: {
    header: 'Labs',
    icon: LabIcon,
    documentsLabel: 'My lab documents',
    discalimer: `OpenLoop will contact you to provide a lab order for your appointment at Quest Diagnostics.
      If you do not receive a lab order, contact (708) 787-4438 for assistance.`,
    viewDetails: 'View details',
    hideDetails: 'Hide details',
  },
};
