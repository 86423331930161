import { Col_10 as Col10 } from 'common/styles/grid-styles';
import { SCROLL_TO_RESULTS } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { isFaqOneAvailableForConsumers } from 'common/utils/helpers';
import { GroupedMarkers, KitResult } from 'common/utils/types';
import Callout from 'components/Callout';
import TooltipAccordion from 'components/TooltipAccordion';
import React from 'react';

import { NumericalMarkerGroup } from '../NumericalMarkerGroup';

export type Props = {
  groupedMarkers: GroupedMarkers;
  viewingSharedKitResult?: boolean;
  kitResult?: KitResult;
};

function NumericalResultDetail(props: Props) {
  const { groupedMarkers, viewingSharedKitResult, kitResult } = props;

  const {
    FAQ_TOOLTIP_1_HEADER,
    FAQ_TOOLTIP_1_DESCRIPTION,
    FAQ_TOOLTIP_2_HEADER,
    FAQ_TOOLTIP_2_DESCRIPTION,
    FAQ_TOOLTIP_3_PREVIEW,
    FAQ_TOOLTIP_3_DESCRIPTION,
  } = kitResult?.test?.content ?? {};

  const faqOneAvailable = isFaqOneAvailableForConsumers({
    FAQ_TOOLTIP_1_DESCRIPTION,
    FAQ_TOOLTIP_1_HEADER,
  });

  const faqTwoAvailable = Boolean(FAQ_TOOLTIP_2_DESCRIPTION);
  const faqThreeAvailable = Boolean(FAQ_TOOLTIP_3_DESCRIPTION);

  const shouldShowMidTooltip =
    groupedMarkers &&
    groupedMarkers.outOfRange.length > 0 &&
    groupedMarkers.inRange.length > 0;

  if (!groupedMarkers) return null;

  return (
    <Col10 id={SCROLL_TO_RESULTS}>
      {faqOneAvailable && (
        <Callout
          title={FAQ_TOOLTIP_1_HEADER}
          description={FAQ_TOOLTIP_1_DESCRIPTION}
          analyticsData={{
            label: ANALYTICS.LABELS.FAQ_TOOLTIP_1_TOP,
            category: ANALYTICS.CATEGORIES.FAQ_TOOLTIPS,
          }}
          productName={kitResult?.test?.name}
        />
      )}
      <NumericalMarkerGroup
        title="Out of Range Results"
        groupedMarkers={groupedMarkers.outOfRange}
        viewingSharedKitResult={viewingSharedKitResult}
        outOfRange
      />
      {faqTwoAvailable && shouldShowMidTooltip && (
        <Callout
          title={FAQ_TOOLTIP_2_HEADER}
          description={FAQ_TOOLTIP_2_DESCRIPTION}
          analyticsData={{
            label: ANALYTICS.LABELS.FAQ_TOOLTIP_2_MIDDLE,
            category: ANALYTICS.CATEGORIES.FAQ_TOOLTIPS,
          }}
          productName={kitResult?.test?.name}
        />
      )}
      <NumericalMarkerGroup
        title="In Range Results"
        groupedMarkers={groupedMarkers.inRange}
        viewingSharedKitResult={viewingSharedKitResult}
      />
      {faqThreeAvailable && (
        <TooltipAccordion
          preview={FAQ_TOOLTIP_3_PREVIEW}
          description={FAQ_TOOLTIP_3_DESCRIPTION}
        />
      )}
    </Col10>
  );
}

export default NumericalResultDetail;
