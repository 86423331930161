import {
  InformationBox,
  SkeletonLoader,
  SkeletonUnit,
} from '@everlywell/leaves';
import useRedeemableProducts from 'common/hooks/useRedeemableProducts';
import {
  EnrolledProgram,
  PROGRAM_TYPE,
  ProgramType,
  RedeemableProduct,
  RedeemableTier,
} from 'common/utils/types';
import { RedeemableProductType } from 'components/CreditRedemption/CreditRedemptionRoutes';
import { isNil } from 'lodash';
import ErrorCard from 'pages/AccountHub/components/ErrorCard';
import React, { useState, useEffect } from 'react';
import { useSet } from 'react-use';

import { useCreditRedemption } from '../../context/CreditRedemptionContext';
import * as S from './ProductSelector.styles';
import ProductTile, {
  SectionsType,
  InnerSection,
  ProductTileProps,
} from './ProductTile/ProductTile';
import SelectionTabs from './SelectionTabs';

// Types ----------

type InnerElement = {
  type: SectionsType;
  title: string;
};

export interface ProductSelectorProps {
  handleOnSubmit: (
    userPlanId: number,
    selectedProduct: RedeemableProduct,
  ) => void;
  handleOnBack: () => void;
  isNYUser: boolean;
  isLoading?: boolean;
  redeemableProductType?: RedeemableProductType;
}

/**
 * Parses and handles Inner Sections data
 * @param section
 * @returns cleaned up parsed inner sections
 */
const parseInnerSections = (section: InnerElement[]): InnerSection => {
  const parsedElements = section.map((element: InnerElement) => element.title);

  let label = '';
  const sectionType = section[0].type;

  switch (sectionType) {
    case 'Markers':
      label = 'biomarkers';
      break;

    case 'Symptom':
      label = 'symptoms';
      break;

    default:
      break;
  }

  return {
    type: sectionType as SectionsType,
    label: label,
    elements: parsedElements,
  };
};

// ProductSelector ------

function descriptions(isNyUser: boolean, plan: ProgramType) {
  if (isNyUser) {
    switch (plan) {
      case PROGRAM_TYPE['weight-management-full']:
        return ["We suggest choosing a supplement you're low on."];
      default:
        return [];
    }
  }

  switch (plan) {
    case PROGRAM_TYPE['weight-management-full']:
    case PROGRAM_TYPE['everlywell-plus']:
      return [
        'We suggest choosing a test that could help you monitor your biomarkers overtime.',
      ];
    default:
      return [];
  }
}

const getTabDescription = (
  plan: ProgramType,
  tierIndex: number,
  redeemableProductType?: ProductSelectorProps['redeemableProductType'],
) => {
  switch (plan) {
    case PROGRAM_TYPE['everlywell-plus']:
      const productType =
        redeemableProductType === 'virtual-care-visit'
          ? 'virtual care services'
          : 'tests';

      return [
        `Below are the ${productType} that cost ${tierIndex} credit${
          tierIndex > 1 ? 's' : ''
        } to redeem.`,
      ];
    default:
      return [];
  }
};

const InformationBoxContent = React.memo(
  ({ isNyUser, plan }: { isNyUser: boolean; plan: ProgramType }) => {
    if (!isNyUser) return null;

    switch (plan) {
      case PROGRAM_TYPE['weight-management-full']:
        return (
          <S.InformationContent>
            New York has some regulations around lab testing. Unfortunately,
            this means you won't be eligible for some of our products.
          </S.InformationContent>
        );
      case PROGRAM_TYPE['everlywell-plus']:
        return (
          <S.InformationContent>
            New York residents are not currently eligible for the Everlywell+
            membership.
            <br />
            <br />
            Please reach out to{' '}
            <S.Link href="mailto:contact@everlywell.com">
              contact@everlywell.com
            </S.Link>{' '}
            to receive a refund and cancel your subscription
          </S.InformationContent>
        );
      default:
        return null;
    }
  },
);

const config = (
  type: ProductSelectorProps['redeemableProductType'],
  isNYUser: boolean,
  plan: ProgramType,
) => {
  switch (type) {
    case 'virtual-care-visit':
      return {
        title: 'What type of care are you looking for?',
        primaryButtonText: 'Continue',
        description: (
          <S.Description>
            We offer care for a variety of conditions, handled both completely
            online or by appointment.
          </S.Description>
        ),
      };
    default:
      return {
        title: 'Which product would you like to try?',
        primaryButtonText: 'Place Order',
        description: descriptions(isNYUser, plan).map((copy, index) => (
          <S.Description
            key={`description_copy_${index}`}
            dangerouslySetInnerHTML={{ __html: copy }}
          />
        )),
      };
  }
};

const ProductSelector: React.FC<ProductSelectorProps> = (props) => {
  const {
    handleOnBack,
    handleOnSubmit,
    isNYUser,
    isLoading,
    redeemableProductType,
  } = props;

  const { userPlan } = useCreditRedemption();

  const plan = userPlan?.slug ?? PROGRAM_TYPE['weight-management-full'];
  const isVirtualCareVisit = redeemableProductType === 'virtual-care-visit';

  const {
    redeemableProducts = [],
    isFetching,
    isError,
    refetch,
  } = useRedeemableProducts(userPlan?.id, isVirtualCareVisit);

  const productMap: Record<string, RedeemableProduct> = {};

  const [selectedIndex, setSelectedIndex] = useState<number>();
  // Keeps track of expanded product cards
  const [
    ,
    {
      toggle: toggleExpandedIndex,
      has: isIndexExpanded,
      reset: resetExpandedIndexes,
    },
  ] = useSet<number>(new Set([]));
  const [selectedSlug, setSelectedSlug] = useState<string>();
  const [selectedProductTier, setSelectedProductTier] =
    useState<RedeemableTier>();
  const [selectedTier, setSelectedTier] = useState<number | null>(null);
  const [availableTiers, setAvailableTiers] = useState<number[]>([]);

  /**
   * Handle product selection
   * @param variantId
   * @param index
   */
  const handleSelect = (productId: number, index: number, slug: string) => {
    if (index === selectedIndex) {
      setSelectedIndex(undefined);
      setSelectedSlug(undefined);
    } else {
      setSelectedIndex(index);
      setSelectedSlug(slug);
    }
  };

  /**
   * Handle product card toggle
   * @param productId
   */
  const handleOpen = (productId: number) => toggleExpandedIndex(productId);

  /**
   * Handle order submit
   * @param selectedProduct
   */
  const handleSubmit = () => {
    if (selectedSlug && userPlan) {
      handleOnSubmit(userPlan.id, productMap[selectedSlug]);
    }
  };

  const getProductTilePropsV2 = (
    product: RedeemableProduct,
    index: number,
  ): ProductTileProps | null => {
    const {
      description,
      slug,
      collection_types,
      symptoms,
      biomarkers,
      meta_description,
      box_image_url,
      name,
      id,
      available_to_redeem,
    } = product;

    // Creating a map of products to be used in the order submit handler.
    productMap[slug] = product;

    const addedInnerSections = [
      biomarkers.map((biomarker) => {
        const biomarkerElement: InnerElement = {
          title: biomarker,
          type: 'Markers',
        };

        return biomarkerElement;
      }),
      symptoms.map((symptom) => {
        const symptomElement: InnerElement = {
          title: symptom,
          type: 'Symptom',
        };

        return symptomElement;
      }),
    ];

    const consolidatedInnerSections = addedInnerSections
      .filter((section) => section.length > 0)
      .map((section) => parseInnerSections(section));

    const tileProps = {
      collectionMethods: collection_types,
      description: description || '',
      shortDescription: meta_description || '',
      index: index,
      innerSections: consolidatedInnerSections,
      isExpanded: isIndexExpanded(id),
      isSelected: slug === selectedSlug,
      productImageURL: box_image_url || '',
      productName: name,
      productId: id,
      slug: slug,
      handleSelect: handleSelect,
      handleOpen: handleOpen,
      isAvailableToRedeem: available_to_redeem,
    };

    return tileProps;
  };

  const handleTabSelect = (index: number, tier: number) => {
    // set selected tier (how many credits it costs)
    setSelectedTier(tier);
    // Switch selected product tier based on tab index
    setSelectedProductTier(redeemableProducts[index]);
    // Reset expanded product cards on tab switch
    resetExpandedIndexes();
    // Reset selected product on tab switch in case there was one selected
    setSelectedSlug(undefined);
  };

  useEffect(() => {
    if (redeemableProducts.length > 0) {
      setSelectedProductTier(redeemableProducts[0]);
      setSelectedTier(redeemableProducts[0].credits_cost);

      // Set available tiers
      const availableTiers = redeemableProducts.map(
        (tier) => tier.credits_cost,
      );

      setAvailableTiers(availableTiers);
    }
  }, [redeemableProducts]);

  const { title, description, primaryButtonText } = config(
    redeemableProductType,
    isNYUser,
    plan,
  );

  const renderProductTiles = (
    products: RedeemableProduct[],
    roundImage?: boolean,
  ) =>
    products.map((product, index) => {
      const productTileProps = getProductTilePropsV2(product, index);

      return productTileProps ? (
        <ProductTile
          key={product.slug}
          {...productTileProps}
          roundImage={roundImage}
        />
      ) : null;
    });

  const renderProductTilesByType = (
    products: Record<string, RedeemableProduct[]>,
    programType: EnrolledProgram['program_type'],
  ) => {
    if (products[programType].length === 0) return null;

    const HEADINGS = {
      async: 'Online Care (within 24 hours)',
      on_demand: 'Urgent Care (within 2 hours)',
      scheduled: 'Scheduled Visits',
    };

    const isRoundImage = true;
    return (
      <S.ProductSection key={programType}>
        <S.ProductSectionHeading>
          {HEADINGS[programType]}
        </S.ProductSectionHeading>
        {renderProductTiles(products[programType], isRoundImage)}
      </S.ProductSection>
    );
  };

  const renderProductsList = (products: RedeemableProduct[]) => {
    if (isVirtualCareVisit) {
      const programTypes: EnrolledProgram['program_type'][] = [
        'async',
        'on_demand',
        'scheduled',
      ];

      const productsByType = products.reduce(
        (acc, product: RedeemableProduct) => {
          if (!product.type) {
            return acc;
          }

          return { ...acc, [product.type]: [...acc[product.type], product] };
        },
        { async: [], scheduled: [], on_demand: [] } as Record<
          EnrolledProgram['program_type'],
          RedeemableProduct[]
        >,
      );

      return programTypes.map((type) =>
        renderProductTilesByType(productsByType, type),
      );
    }

    return renderProductTiles(products);
  };

  return (
    <>
      <S.Title>{title}</S.Title>
      {description}

      {!isFetching && isError ? (
        <div>
          <ErrorCard onRetry={refetch} />
        </div>
      ) : null}

      {redeemableProducts.length > 1 ? (
        <>
          <SelectionTabs
            onItemSelect={handleTabSelect}
            availableTiers={availableTiers}
          />
          {selectedTier
            ? getTabDescription(plan, selectedTier, redeemableProductType).map(
                (copy, index) => (
                  <S.Description
                    key={`tab_description_copy_${index}`}
                    dangerouslySetInnerHTML={{ __html: copy }}
                  />
                ),
              )
            : null}
        </>
      ) : null}

      {isFetching ? (
        <S.LoaderWrapper>
          {/* Show loader 5 times */}
          {[...Array(5)].fill(null).map((_, index) => (
            <SkeletonLoader
              key={index}
              height={{
                value: 104,
                unit: SkeletonUnit.Pixel,
              }}
              width={{
                value: 100,
                unit: SkeletonUnit.Percentage,
              }}
            />
          ))}
        </S.LoaderWrapper>
      ) : (
        <S.Products disableClicks={!!isLoading}>
          {selectedProductTier
            ? renderProductsList(selectedProductTier?.products)
            : null}

          {isNYUser ? (
            <InformationBox text="">
              <S.InformationBoxTitle>
                It looks like you're in New York!
              </S.InformationBoxTitle>

              <InformationBoxContent isNyUser={isNYUser} plan={plan} />
            </InformationBox>
          ) : null}
        </S.Products>
      )}

      <S.ActionArea>
        <S.BackButton
          appearance="secondary"
          onClick={handleOnBack}
          isDisabled={isLoading}
        >
          Back
        </S.BackButton>

        <S.OrderButton
          onClick={handleSubmit}
          isDisabled={isNil(selectedSlug) || isLoading}
          isLoading={isLoading}
        >
          {primaryButtonText}
        </S.OrderButton>
      </S.ActionArea>
    </>
  );
};

// Export ---------

export default React.memo(ProductSelector);
