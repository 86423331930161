import {
  Col,
  Dropdown,
  Row,
  size,
  formUtils,
  states,
} from '@everlywell/leaves';
import {
  getShippingAddress,
  updateShippingAddress,
} from 'common/apis/settingsApis';
import { useInteractionTracker } from 'common/hooks/useInteractionTracker';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import {
  shippableStates,
  stateNameToAbbreviation,
} from 'common/utils/constants/states';
import { zipcodeCleaningValidation } from 'common/utils/helpers';
import { NotificationContext } from 'components/Notification/NotificationContext';
import ErrorCard from 'pages/AccountHub/components/ErrorCard';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ERROR_NOTIFICATION } from 'store/actions';

import FormSummary from '../../FormSummary/FormSummary';
import FormSummarySkeleton from '../../FormSummary/FormSummarySkeleton';
import DEFAULT_CONTENT from './content';
import * as S from './MembershipShippingForm.styles';

export const TEST_ID = 'ShippingSettingsTest';

type FormValues = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip_code: string;
  phone: string | null;
  first_name: string;
  last_name: string;
};

export const MembershipShippingForm = () => {
  const { setNotificationData } = useContext(NotificationContext);
  const trackFormInteraction = useInteractionTracker();
  const { register, errors, setValue, handleSubmit } = useForm<FormValues>();
  const [showSummary, setShowSummary] = useState(true);
  const queryClient = useQueryClient();

  const {
    data: shippingAddressResponse,
    isLoading: isGettingAddress,
    error: isErrorGettingAddress,
    refetch: refetchAddress,
  } = useQuery(['shipping-address'], getShippingAddress, {});

  const shippingAddress = shippingAddressResponse?.data;
  useEffect(() => {
    if (shippingAddress || (shippingAddress && !showSummary)) {
      setValue('address1', shippingAddress.street1);
      setValue('address2', shippingAddress.street2);
      setValue('city', shippingAddress.city);
      setValue('state', states.stateAbbreviationToNames[shippingAddress.state]);
      setValue('zip_code', shippingAddress.zipcode);
      setValue('first_name', shippingAddress.first_name);
      setValue('last_name', shippingAddress.last_name);
      setValue('phone', shippingAddress.phone);
    }
  }, [shippingAddress, setValue, showSummary]);

  const {
    mutate,
    error,
    isLoading: isSaving,
  } = useMutation(updateShippingAddress, {
    onSuccess: () => {
      setNotificationData({
        message: CONTENT.notifications.updateSuccess.message,
        persist: false,
        source: ANALYTICS.LABELS.SHIPPING_INFORMATION,
      });
      analytics.track({
        event: ANALYTICS.EVENTS.API_SUCCESS,
        data: {
          label: CONTENT.notifications.updateSuccess.message,
          component: ANALYTICS.LABELS.SHIPPING_INFORMATION,
        },
      });

      // Update cached data after a successful update
      queryClient.invalidateQueries(['shipping-address']).finally(() => {
        setShowSummary(true);
      });
    },
    onError: () => {
      setNotificationData({
        message: CONTENT.notifications.updateFailed.title,
        persist: false,
        details: CONTENT.notifications.updateFailed.message,
        type: ERROR_NOTIFICATION,
        source: ANALYTICS.LABELS.SHIPPING_INFORMATION,
      });

      analytics.track({
        event: ANALYTICS.EVENTS.API_FAILURE,
        data: {
          label: 'Update Failed',
          component: ANALYTICS.LABELS.SHIPPING_INFORMATION,
        },
      });
    },
  });

  const errorMessage =
    (error as any)?.fetchResponse?.status === 422 &&
    (error as any)?.data?.message;

  const [NYError, setNYError] = useState(false);

  const CONTENT = DEFAULT_CONTENT;

  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: ANALYTICS.LABELS.SHIPPING_INFORMATION,
      },
    });
  }, []);

  const onEditClick = () => {
    setShowSummary(false);

    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.ACCOUNT_SETTINGS.SHIPPING_ADDRESS.EDIT,
        category: ANALYTICS.CATEGORIES.ACCOUNT_SETTINGS,
        component: ANALYTICS.LABELS.SHIPPING_INFORMATION,
      },
    });
  };

  const onCancelClick = () => {
    setShowSummary(true);

    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.ACCOUNT_SETTINGS.SHIPPING_ADDRESS.CANCEL,
        category: ANALYTICS.CATEGORIES.ACCOUNT_SETTINGS,
        component: ANALYTICS.LABELS.SHIPPING_INFORMATION,
      },
    });
  };

  const handleFocus = async () => {
    await trackFormInteraction({
      event: ANALYTICS.EVENTS.FORM_INTERACTION,
      data: {
        component: ANALYTICS.LABELS.SHIPPING_INFORMATION,
      },
    });
  };

  const trackSubmitButton = async () => {
    await analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.ACCOUNT_SETTINGS.SHIPPING_ADDRESS.SAVE,
        category: ANALYTICS.CATEGORIES.ACCOUNT_SETTINGS,
        component: ANALYTICS.LABELS.SHIPPING_INFORMATION,
      },
    });
  };

  const handleFormSubmit = async (formData: FormValues) => {
    trackSubmitButton();

    const { address1, address2, city, state, zip_code, first_name, last_name } =
      formData;

    mutate({
      first_name,
      last_name,
      address1,
      address2,
      city,
      state: stateNameToAbbreviation[state],
      zipcode: zip_code,
    });
  };

  const onStateChange = (e: any) => setNYError(e.target.value === 'New York');

  const hasIncompleteShippingAddress =
    !shippingAddress?.street1 ||
    !shippingAddress?.city ||
    !shippingAddress?.state ||
    !shippingAddress?.zipcode;

  return (
    <div data-test={TEST_ID} css={{ marginTop: `${size.lg}px` }}>
      <S.Wrapper>
        <S.SubTitle>{CONTENT.subtitle}</S.SubTitle>
        {isGettingAddress && <FormSummarySkeleton />}

        <S.Box>
          {shippingAddress && showSummary && !isErrorGettingAddress ? (
            <FormSummary
              topLine={
                hasIncompleteShippingAddress
                  ? ''
                  : `${shippingAddress.street1} ${
                      shippingAddress.street2 ?? ''
                    }`
              }
              secondaryLines={[
                hasIncompleteShippingAddress
                  ? 'Please enter your address'
                  : `${shippingAddress.city} ${shippingAddress.state} ${shippingAddress.zipcode}`,
              ]}
              onEditClick={onEditClick}
            />
          ) : null}
          {isErrorGettingAddress ? (
            <ErrorCard onRetry={refetchAddress} />
          ) : null}
          {!showSummary ? (
            <form onSubmit={handleSubmit(handleFormSubmit)} aria-label="form">
              <Row>
                <S.HiddenInput
                  name="first_name"
                  id="first_name"
                  aria-hidden="true"
                  ref={register}
                />
                <S.HiddenInput
                  name="last_name"
                  id="last_name"
                  aria-hidden="true"
                  ref={register}
                />
                <Col xs={12} md={6}>
                  <S.Input
                    id="ss-address1"
                    name="address1"
                    label="Address"
                    placeholder="Address"
                    ref={register({ required: 'This field is required.' })}
                    disabled={isSaving}
                    error={errors.address1 && errors.address1.message}
                    autoComplete="shipping address-line1"
                    onFocus={handleFocus}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <S.Input
                    id="ss-address2"
                    name="address2"
                    label="Address Line 2 (Optional)"
                    placeholder="Apt/Unit/Suite"
                    ref={register}
                    disabled={isSaving}
                    error={errors.address2 && errors.address2.message}
                    autoComplete="shipping address-line2"
                    onFocus={handleFocus}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <S.Input
                    id="ss-city"
                    name="city"
                    label="City"
                    placeholder="City"
                    ref={register({ required: 'This field is required.' })}
                    disabled={isSaving}
                    error={errors.city && errors.city.message}
                    autoComplete="shipping locality"
                    onFocus={handleFocus}
                  />
                </Col>
                <Col xs={6} md={6}>
                  <Dropdown
                    // @ts-ignore
                    items={shippableStates} // States data does not currently include New York
                    label="State"
                    name="state"
                    id="ss-state"
                    placeholderText="State"
                    error={errors.state && errors.state.message}
                    helperText={
                      NYError
                        ? 'For regulatory reasons, our tests are not available in NY with the exception of COVID-19, vitamins, and supplements.'
                        : undefined
                    }
                    ref={register({
                      required: 'Please select a state',
                    })}
                    autoComplete="shipping region"
                    onClick={handleFocus}
                    onChange={onStateChange}
                    disabled={isSaving}
                  />
                </Col>
                <Col xs={6} md={6}>
                  <S.Input
                    id="ss-zip_code"
                    name="zip_code"
                    label="ZIP Code"
                    placeholder="00000"
                    // @ts-ignore
                    inputmode="numeric"
                    pattern="\d*"
                    type="text"
                    ref={register({
                      required: 'Please enter a ZIP code',
                      validate: (value) =>
                        formUtils.validateZipCode(value) ||
                        'Please enter a valid ZIP code.',
                    })}
                    disabled={isSaving}
                    error={errors.zip_code && errors.zip_code.message}
                    autoComplete="shipping postal-code"
                    onFocus={handleFocus}
                    onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                      const target = e.target as HTMLInputElement;
                      const zipcode = zipcodeCleaningValidation(target.value);
                      target.value = zipcode;
                    }}
                  />
                </Col>
              </Row>
              {errorMessage && (
                <S.InvalidAddress>{errorMessage}</S.InvalidAddress>
              )}
              <S.ButtonRow>
                <S.Button
                  appearance="secondary"
                  isDisabled={isSaving}
                  type="button"
                  onClick={onCancelClick}
                >
                  {CONTENT.cancelButton}
                </S.Button>

                <S.Button
                  appearance="primary"
                  isDisabled={isSaving}
                  type="submit"
                >
                  {CONTENT.saveButton}
                </S.Button>
              </S.ButtonRow>
            </form>
          ) : null}
        </S.Box>
      </S.Wrapper>
    </div>
  );
};

export default MembershipShippingForm;
