import { RedeemableProduct } from 'common/utils/types';
import React, { createContext, useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AddressFormValues } from '../components/RedemptionAddressForm';
import {
  CreditRedemptionContextType,
  Step,
  StepName,
  UserPlan,
} from '../utils';

type Props = { children?: React.ReactNode };

export const CreditRedemptionContext =
  createContext<CreditRedemptionContextType>({
    steps: [],
    nextStep: () => {},
    previousStep: () => {},
    getStepCountLabel: () => '',
    resetSteps: () => {},
    saveAddress: () => {},
    isStepComplete: () => false,
    getAddress: () => undefined,
    saveProduct: () => {},
    getProduct: () => undefined,
    setIsNyUser: () => {},
    isNyUser: () => undefined,
    setUserPlan: () => {},
    stepCountVisibility: true,
    setStepCountVisibility: () => undefined,
  });

export const useCreditRedemption = () => useContext(CreditRedemptionContext);

const defaultSteps: Step[] = [
  {
    name: 'AddressStep',
    route: '/credit-redemption/address',
    isComplete: false,
  },
  {
    name: 'ProductSelectionStep',
    route: '/credit-redemption/products',
    isComplete: false,
  },
  {
    name: 'RedemptionConfirmationStep',
    route: '/credit-redemption/confirmation',
    isComplete: false,
  },
];

export const CreditRedemptionProvider: React.FC<Props> = ({ children }) => {
  const [steps, setSteps] = useState<Step[]>(defaultSteps);
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [address, setAddress] = useState<AddressFormValues>();
  const [product, setProduct] = useState<RedeemableProduct>();
  const [isNyUserValue, setIsNyUserValue] = useState<boolean>(false);
  const [userPlan, setUserPlan] = useState<UserPlan>();
  const [stepCountVisibility, setStepCountVisibility] = useState<boolean>(true);

  const navigate = useNavigate();
  const location = useLocation();

  const nextStep = () => {
    setSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      const currentStep = updatedSteps[activeStepIndex];
      if (currentStep) {
        currentStep.isComplete = true;
      }
      return updatedSteps;
    });

    // Math min is used to prevent going over the last step
    setActiveStepIndex((prevIndex) =>
      Math.min(prevIndex + 1, steps.length - 1),
    );

    const nextIndexRoute = steps[activeStepIndex + 1].route;
    navigate(nextIndexRoute);
  };

  const previousStep = () => {
    setSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      const currentStep = updatedSteps[activeStepIndex];
      if (currentStep) {
        currentStep.isComplete = false;
      }
      return updatedSteps;
    });

    // Math max is used to prevent going below the first step
    setActiveStepIndex((prevIndex) => Math.max(prevIndex - 1, 0));

    const previousIndexRoute = steps[activeStepIndex - 1].route;
    navigate(previousIndexRoute);
  };

  const getStepCountLabel = () => {
    const isLastStep = activeStepIndex === steps.length - 1;
    // Last step doesn't have a count label.
    return isLastStep ? '' : `${activeStepIndex + 1} of ${steps.length - 1}`;
  };

  const resetSteps = () => {
    setSteps(defaultSteps);
    setActiveStepIndex(0);
    setIsInitialized(false);
  };

  const saveAddress = (address: AddressFormValues) => setAddress(address);

  const getAddress = () => address;

  const saveProduct = (product: RedeemableProduct) => setProduct(product);

  const getProduct = () => product;

  const isNyUser = () => isNyUserValue;

  const setIsNyUser = (isNyUser: boolean) => setIsNyUserValue(isNyUser);

  const isStepComplete = (stepName: StepName) => {
    const step = steps.find((step) => step.name === stepName);
    return step ? step.isComplete : false;
  };

  useEffect(() => {
    const stepIndexByRoute = steps.findIndex(
      (step) => step.route === location.pathname,
    );

    if (stepIndexByRoute !== -1) {
      setActiveStepIndex(stepIndexByRoute);
    }
  }, [location, steps]);

  useEffect(() => {
    if (isInitialized) {
      setSteps((prevSteps) =>
        prevSteps.map((step, index) => ({
          ...step,
          isComplete: index < activeStepIndex,
        })),
      );
    } else {
      setIsInitialized(true);
    }
  }, [activeStepIndex, isInitialized]);

  return (
    <CreditRedemptionContext.Provider
      value={{
        getAddress,
        getProduct,
        getStepCountLabel,
        isNyUser,
        isStepComplete,
        nextStep,
        previousStep,
        resetSteps,
        saveAddress,
        saveProduct,
        setIsNyUser,
        setStepCountVisibility,
        setUserPlan,
        stepCountVisibility,
        steps,
        userPlan,
      }}
    >
      {children}
    </CreditRedemptionContext.Provider>
  );
};
