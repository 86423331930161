import { colors, H3, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled(H3)`
  width: 100%;
  ${typography.overlineText};
`;

export const Card = styled.div`
  display: flex;
  padding: ${size.xl1}px;
  gap: ${size.lg}px;
  width: 100%;
  background: ${colors.white};
  border: 1px solid ${colors.green2};
  border-radius: ${size.xs1}px;
`;
