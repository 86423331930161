/**
 *
 * SaveSubHeaderButton
 *
 */
import analytics from 'common/utils/analytics';
import { LEGACY_APP_ROOT, COVID_19_IDS } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { SUB_HEADER as DATA_TEST } from 'common/utils/constants/dataTest';
import React, { useState } from 'react';

import Popover from '../PopoverModal/Popover';
import SubHeaderButton from '../SubHeaderButton';
import * as S from './SaveSubHeaderButton.styles';

export type SaveSubHeaderButtonProps = {
  kitResultId: string;
  kitResultHasAnyDnrMarkers?: boolean;
  testId?: number;
  testName?: string;
  paid: boolean;
};

function SaveSubHeaderButton(props: SaveSubHeaderButtonProps) {
  const { kitResultId, testId, paid, kitResultHasAnyDnrMarkers } = props;
  const [isOpen, setIsOpen] = useState(false);

  function handleToggleOpen() {
    setIsOpen((currentIsOpen) => !currentIsOpen);
  }

  const isCovidDTCKit = testId && COVID_19_IDS.includes(testId) && paid;

  const doctorPDFUrl = `${LEGACY_APP_ROOT}/results/${kitResultId}/doctor_results.pdf`;
  const receiptPDFLink = `${LEGACY_APP_ROOT}/results/${kitResultId}/reimbursement_letter.pdf`;

  const analyticsAttributes = {
    'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
    'data-analytics-category': ANALYTICS.CATEGORIES.DOWNLOAD_PDF,
    'data-analytics-label': 'Download doctor PDF',
    'data-analytics-test-id': testId,
  };

  const reimbursementAnalyticsAttributes = {
    'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
    'data-analytics-category': ANALYTICS.CATEGORIES.DOWNLOAD_PDF,
    'data-analytics-label': 'Download reimbursement PDF',
    'data-analytics-test-id': testId,
  };

  const trackDownload = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.DOWNLOAD_PDF_REPORT,
      },
    });
  };

  const doctorPDFLink = () => (
    <S.Link
      target="_blank"
      href={doctorPDFUrl}
      data-test={DATA_TEST.DOCTOR_PDF}
      onClick={trackDownload}
      {...analyticsAttributes}
    >
      Results For My Doctor
    </S.Link>
  );

  const contactCustomerCareMessage = () => (
    <S.Content>
      <p data-test={DATA_TEST.DNR_PDF}>
        Please contact{' '}
        <a href="mailto:contact@everlywell.com">contact@everlywell.com</a> to
        receive a PDF of your results.
      </p>
    </S.Content>
  );
  return (
    <S.Wrapper>
      {isCovidDTCKit ? (
        <SubHeaderButton
          onClick={handleToggleOpen}
          isActive={isOpen}
          name="View Documents"
          dataTest={DATA_TEST.SAVE_BUTTON}
          shouldShowChevron
          hideChevronOnMobile
          hideTextOnMobile
        />
      ) : (
        <SubHeaderButton
          afterText=" a PDF"
          onClick={handleToggleOpen}
          isActive={isOpen}
          name="save"
          dataTest={DATA_TEST.SAVE_BUTTON}
          shouldShowChevron
          hideChevronOnMobile
          hideTextOnMobile
        />
      )}

      {isOpen && (
        <S.PopoverWrap>
          <Popover onClose={handleToggleOpen}>
            <S.Content>
              {kitResultHasAnyDnrMarkers
                ? contactCustomerCareMessage()
                : doctorPDFLink()}
              {isCovidDTCKit ? (
                <S.Link
                  target="_blank"
                  href={receiptPDFLink}
                  data-test={DATA_TEST.ITEMIZED_RECEIPT_PDF}
                  {...reimbursementAnalyticsAttributes}
                >
                  Itemized Receipt
                </S.Link>
              ) : null}
            </S.Content>
          </Popover>
        </S.PopoverWrap>
      )}
    </S.Wrapper>
  );
}

export default SaveSubHeaderButton;
