import { Col, Row } from '@everlywell/leaves';
import { TEST_INFORMATION_FORM as DATA_TEST } from 'common/utils/constants/dataTest';
import { DTC_STATES } from 'common/utils/constants/states';
import { handleChange } from 'common/utils/registrationHelpers';
import {
  BaseRegistrationInput,
  KitRegistrationInput,
} from 'common/utils/types';
import { BaseDropdown } from 'components/KitRegistration/styles';
import React from 'react';

import * as S from '../styles';

type AddressInformationProps = {
  streetAddress: KitRegistrationInput<string>;
  subAddress: KitRegistrationInput<string>;
  city: KitRegistrationInput<string>;
  state: KitRegistrationInput<string>;
  zipCode: KitRegistrationInput<string>;
  overrideAddress?: BaseRegistrationInput<boolean>;
  invalidFields?: string[];
  setInvalidFields?: React.Dispatch<React.SetStateAction<string[]>>;
  error?: string;
  setError?: React.Dispatch<React.SetStateAction<boolean>>;
  shouldFocus?: boolean;
};

type DropdownOptionType = {
  value: string;
  id: string;
};

const dtcStates: DropdownOptionType[] = DTC_STATES.map(
  (state) =>
    ({
      value: state[1],
      id: state[0],
    } as DropdownOptionType),
);

const AddressInformation = (props: AddressInformationProps) => {
  const {
    streetAddress,
    subAddress,
    city,
    state,
    zipCode,
    overrideAddress,
    invalidFields,
    setInvalidFields,
    error,
    setError,
    shouldFocus,
  } = props;

  const handleAddressChange = (
    input: KitRegistrationInput<string>,
    value: string,
    invalidFields?: string[],
    setInvalidFields?: React.Dispatch<React.SetStateAction<string[]>>,
  ) => {
    if (overrideAddress) {
      overrideAddress.setValue(false);
    }
    if (input.id === zipCode.id && setError) setError(false);
    handleChange(input, value, invalidFields, setInvalidFields);
  };

  const getZipCodeError = () => {
    if (error) return error;
    if (invalidFields && invalidFields.includes(zipCode.id))
      return zipCode.error;
    return undefined;
  };

  return (
    <>
      <S.StyledInput
        label="Address Line 1"
        id={streetAddress.id}
        value={streetAddress.value}
        name={streetAddress.id}
        inputClassName="inspectletIgnore"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleAddressChange(
            streetAddress,
            e.target.value,
            invalidFields,
            setInvalidFields,
          );
        }}
        error={
          invalidFields && invalidFields.includes(streetAddress.id)
            ? streetAddress.error
            : undefined
        }
        autoFocus={shouldFocus}
        data-test={`${DATA_TEST.INPUT}-${streetAddress.id}`}
      />
      <S.StyledInput
        label="Apt / Suite/ Unit (Optional)"
        id={subAddress.id}
        value={subAddress.value}
        name={subAddress.id}
        inputClassName="inspectletIgnore"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleAddressChange(
            subAddress,
            e.target.value,
            invalidFields,
            setInvalidFields,
          );
        }}
        data-test={`${DATA_TEST.INPUT}-${subAddress.id}`}
      />
      <Row>
        <Col xs={12} md={4}>
          <S.StyledInput
            label="City"
            id={city.id}
            value={city.value}
            name={city.id}
            inputClassName="inspectletIgnore"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleAddressChange(
                city,
                e.target.value,
                invalidFields,
                setInvalidFields,
              );
            }}
            error={
              invalidFields && invalidFields.includes(city.id)
                ? city.error
                : undefined
            }
            data-test={`${DATA_TEST.INPUT}-${city.id}`}
          />
        </Col>
        <S.StateCol xs={6} md={4}>
          <S.LabelContainer>
            <S.Label
              error={
                (invalidFields && invalidFields.includes('addressState')) ||
                false
              }
            >
              State
            </S.Label>
          </S.LabelContainer>
          <BaseDropdown
            id={state.id}
            hideLabel
            label="State"
            value={state.value}
            name={state.id}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleAddressChange(
                state,
                e.target.value,
                invalidFields,
                setInvalidFields,
              )
            }
            items={dtcStates}
            data-test={`${DATA_TEST.INPUT}-${state.id}`}
            className="inspectletIgnore"
          />
        </S.StateCol>
        <Col xs={6} md={4}>
          <S.StyledInput
            label="Zipcode"
            id={zipCode.id}
            value={zipCode.value}
            name={zipCode.id}
            inputClassName="inspectletIgnore"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleAddressChange(
                zipCode,
                e.target.value,
                invalidFields,
                setInvalidFields,
              );
            }}
            error={getZipCodeError()}
            data-test={`${DATA_TEST.INPUT}-${zipCode.id}`}
          />
        </Col>
      </Row>
    </>
  );
};

export default AddressInformation;
