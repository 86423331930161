import React, { ElementType } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectAuthenticated } from 'store/selectors';

type PrivateRouteProps = {
  component: ElementType;
  authenticated: boolean;
};
const PrivateRoute = ({
  component: Component,
  authenticated,
  ...componentProps
}: PrivateRouteProps) => {
  if (authenticated) {
    return <Component {...componentProps} />;
  }
  return null;
};

const mapStateToProps = createStructuredSelector({
  // @ts-ignore
  authenticated: makeSelectAuthenticated(),
});

const withConnect = connect(mapStateToProps);

export default compose<any>(withConnect)(PrivateRoute);
