import { getUser } from 'common/apis/userApis';
import { Response } from 'common/hooks/useApi/request';
import { freshpaint } from 'common/utils/trackers';
import { EnrolledProgram, User } from 'common/utils/types';
import { useEffect } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { makeSelectSession } from 'store/selectors';
import { setUserData } from 'store/user/actions';

type QueryOptions<T> = Omit<
  UseQueryOptions<T, Error, T, any[]>,
  'queryKey' | 'queryFn'
>;

export type UseUserProps = QueryOptions<Response<User>>;

/**
 * Fetch the current user
 */
const useUser = ({ enabled, ...rest }: UseUserProps = {}) => {
  const dispatch = useDispatch();
  const { userId } = useSelector(makeSelectSession());

  // NOTE: It seems this data is already on redux.
  // But since there is a plan to remove it, the info is requested again.
  // and useQuery will cache this request for us

  const data = useQuery(['user', userId], () => getUser(userId!), {
    ...rest,
    onSuccess: (response) => {
      if (response.data) {
        dispatch(
          setUserData({
            ...response.data,
            full_user_data: true,
          }),
        );
      }

      rest.onSuccess?.(response);
    },
    enabled: Boolean(userId) && enabled !== false,
    refetchOnWindowFocus: false,
  });

  const { first_name, last_name, email, consumer_attributes, phone_number } =
    data.data?.data || {};
  const gender =
    consumer_attributes && 'gender' in consumer_attributes
      ? consumer_attributes.gender
      : undefined;

  useEffect(() => {
    if (first_name && last_name && email && phone_number) {
      freshpaint.addEventProperties({
        email,
        first_name,
        last_name,
        gender,
        phone: phone_number,
      });
    }
  }, [email, first_name, gender, last_name, phone_number]);

  const enrolledProgram = (program: string): EnrolledProgram | undefined =>
    data.data?.data?.enrolled_programs.find(
      (enrolledProgram) => enrolledProgram.slug === program,
    );

  return {
    ...data,
    user: data.data?.data,
    enrolledProgram,
  };
};

export default useUser;
