import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
/**
 * Testing error on ApolloClient and jest.
 * https://github.com/apollographql/apollo-client/issues/4892
 */
import {
  CONTENTFUL_PRODUCTS_SPACE_ID,
  CONTENTFUL_PRODUCTS_ACCESS_TOKEN,
  CONTENTFUL_ENV,
} from 'common/utils/constants';
import { logError } from 'common/utils/helpers';
import React from 'react';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      const error = new Error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
      logError((error as Error).message, {
        errorInfo: 'Graphql fetching Error',
        component: 'ApolloContainer',
        method: 'errorLink',
      });
      return error;
    });
  }
  if (networkError) {
    logError((networkError as Error).message, {
      errorInfo: 'Graphql networkError',
      component: 'ApolloContainer',
      method: 'errorLink',
    });
  }
});

const httpLink = (
  spaceId: string | undefined,
  contentfulPublicAccessToken: string | undefined,
) =>
  new HttpLink({
    uri: `https://graphql.contentful.com/content/v1/spaces/${spaceId}/environments/${CONTENTFUL_ENV}`,
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${contentfulPublicAccessToken}`,
    },
    fetch,
  });

const client = (
  spaceId: string | undefined,
  contentfulPublicAccessToken: string | undefined,
) =>
  new ApolloClient({
    link: from([errorLink, httpLink(spaceId, contentfulPublicAccessToken)]),
    cache: new InMemoryCache(),
  });

function ApolloContainer(props: {
  spaceId?: string | undefined;
  contentfulPublicAccessToken?: string | undefined;
  children: React.ReactNode;
}) {
  const clientInstance = client(
    props.spaceId || CONTENTFUL_PRODUCTS_SPACE_ID,
    props.contentfulPublicAccessToken || CONTENTFUL_PRODUCTS_ACCESS_TOKEN,
  );

  return (
    <ApolloProvider client={clientInstance}>{props.children}</ApolloProvider>
  );
}

export default ApolloContainer;
