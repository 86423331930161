import React, { SyntheticEvent } from 'react';

import * as S from './styles';

type CheckboxProps = {
  checked: boolean;
  label: string;
  [key: string]: any;
};

function Checkbox(props: CheckboxProps) {
  const { checked, label, onChange, ...rest } = props;

  function handleChange(event: SyntheticEvent<HTMLInputElement>) {
    const { currentTarget } = event;
    onChange({ name: currentTarget.name, value: currentTarget.checked });
  }

  return (
    <S.Label>
      <S.CheckboxContainer>
        <S.HiddenCheckbox checked={checked} onChange={handleChange} {...rest} />
        <S.StyledCheckbox />
      </S.CheckboxContainer>
      {label}
    </S.Label>
  );
}

export default Checkbox;
