import {
  size,
  colors,
  typography,
  mediaQueries,
  H4,
  LeftCaret,
  RightCaret,
} from '@everlywell/leaves';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const SectionText = styled(H4)`
  color: ${colors.teal5};
  margin-bottom: ${size.md}px;
  font-weight: 700;
`;

export const SwiperSlideItem = styled(SwiperSlide)`
  width: 278px !important;
`;

export const CardWrapper = styled.div`
  width: 278px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid ${colors.green1};
  border-radius: 0 0 ${size.xs1}px ${size.xs1}px;
  margin-right: ${size.md}px;
  box-shadow: 0 7px 30px -10px rgba(220, 228, 229, 0.5);
  cursor: pointer;

  ${mediaQueries.forTabletVerticalDown} {
    height: auto;
    max-height: none;
  }
`;

export const CardImage = styled.img`
  border-radius: ${size.xs1}px ${size.xs1}px 0 0;

  ${mediaQueries.forTabletVerticalDown} {
    width: 100%;
    height: auto;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 112px;
  align-self: center;
  padding: ${size.sm}px;

  ${mediaQueries.forTabletVerticalDown} {
    width: 100%;
    min-height: 120px;
  }
`;

export const CardHeadingCopy = styled.p`
  ${typography.bodyText};
  padding: 0 ${size.sm}px;
`;

export const CardFooterBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 ${size.sm}px;
`;

export const CategoryText = styled.p`
  ${typography.buttonTextSmall}
`;

export const ReadDurationText = styled.p`
  ${typography.disabledText}
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: ${size.md}px;
  align-self: stretch;
  margin-top: ${size.lg}px;

  ${mediaQueries.forTabletVerticalDown} {
    display: none;
  }
`;

export const PaginationButton = styled.button`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: ${size.xs2}px;
  border: 1px solid ${colors.green2};
  background: ${colors.white};

  &:hover {
    background: ${colors.green1};
    cursor: pointer;
  }
`;

export const PaginationLeft = styled(LeftCaret)`
  color: ${colors.teal4};
  width: ${size.xs1}px;
`;

export const PaginationRight = styled(RightCaret)`
  color: ${colors.teal4};
  width: ${size.xs1}px;
`;
