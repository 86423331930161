import { Col } from '@everlywell/leaves';
import Player from '@vimeo/player';
import analytics from 'common/utils/analytics';
import React, { createRef, useEffect, useState } from 'react';

import * as S from './CollectionMethods/styles';

type Props = {
  methodName: string;
  videoUrl: string;
  isFoodSensitivity?: boolean | undefined;
};

const track = (event: string, method: string) =>
  analytics.track({ event, data: { method } });

const CollectionVideos = ({
  methodName,
  videoUrl,
  isFoodSensitivity,
}: Props) => {
  const vimeoRef = createRef<HTMLIFrameElement>();
  const [player, setPlayer] = useState<any>();

  useEffect(() => {
    if (vimeoRef.current) {
      const player = new Player(vimeoRef.current);
      setPlayer(player);
      player.on('loaded', () => track('video loaded', methodName));
      player.on('play', () => track('video started', methodName));
      player.on('ended', () => track('video ended', methodName));
    }
    return () => {
      if (player) {
        player.off('loaded');
        player.off('play');
        player.off('ended');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methodName]);

  return (
    <S.Wrapper center="xs">
      <Col xs={11} md lg={9}>
        <S.Title>
          <span data-test="collection-methods">
            {
              'When you’re ready to collect your sample, watch this instructional video.'
            }
          </span>
        </S.Title>
        <S.Embed data-test={methodName}>
          <iframe
            ref={vimeoRef}
            title="Collection Method"
            src={videoUrl}
            frameBorder="0"
          />
          <S.Decor />
        </S.Embed>

        {isFoodSensitivity && (
          <S.Instructions
            href={require('./CollectionMethods/data/fs-Instructions.pdf')}
            // @ts-ignore
            target="_blank"
            data-testid="collection-instructions-doc"
          >
            View digital instructions
          </S.Instructions>
        )}
      </Col>
    </S.Wrapper>
  );
};

export default CollectionVideos;
