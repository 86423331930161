import styled from 'styled-components';

const TabsWrapper = styled.div`
  ul {
    list-style: none;
    display: flex;
    padding: 0;
  }
`;

export { TabsWrapper };
