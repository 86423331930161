import { Icons } from '@everlywell/leaves';
import React from 'react';

import * as S from './Disclaimer.styles';

export type DisclaimerProps = {};

/**
 * Disclaimer component
 */
function Disclaimer() {
  return (
    <S.Container>
      <Icons.InformationV2 />
      There is a $25 cancellation fee for missed or canceled appointments
      without 24hr notice—not applicable to on-demand appointments.
    </S.Container>
  );
}

export default Disclaimer;
