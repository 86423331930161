import { size, H5, colors, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

export const Card = styled.div`
  padding: ${size.lg}px;
  display: flex;
  flex-direction: row;
  border: 1px solid #edf5f1;
`;

export const CardIconContainer = styled.div`
  margin-right: 26px;
`;

export const CardIcon = styled.img`
  width: 32px;
  height: 32px;
  align-self: center;

  ${mediaQueries.forTabletVerticalUp} {
    align-self: flex-start;
  }
`;

export const CardContent = styled.div`
  color: ${colors.gray4};
  display: flex;
  flex-direction: column;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.25px;
  font-feature-settings: 'ss01' on;
`;

export const CardHeader = styled(H5)`
  margin: 0 0 8px;
  min-width: 280px;
`;

export const CardText = styled.div`
  margin: 0;
`;
