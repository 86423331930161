import { Link } from 'react-router-dom';

import {
  PUBLIC_APP_ROOT,
  LEGACY_APP_ROOT,
  EVERLYWELL_SUPPORT_ROOT,
} from './constants';
import { ANALYTICS } from './constants/analytics';
import { HEADER, MOBILE_HEADER } from './constants/dataTest';
import { isHumanaCustomer } from './helpers';
import { NavItem as NavItemType } from './types';

// reused values within the file
const theScienceLink = {
  label: 'The Science',
  url: `${PUBLIC_APP_ROOT}/science`,
  className: 'science',
  htmlId: 'scienceNavlink',
  target: '_blank',
  dataTest: HEADER.THE_SCIENCE,
  dataAttributes: {
    'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
    'data-analytics-label': 'The Science',
    'data-analytics-category': ANALYTICS.CATEGORIES.NAVIGATION,
  },
};

const thirdParty = isHumanaCustomer();

const helpLinkDesktop = {
  label: 'Help',
  url: '#',
  className: 'help',
  htmlId: 'helpNavLink',
  dataTest: HEADER.HELP,
  items: [
    {
      label: 'FAQs',
      className: 'faq',
      url: `${EVERLYWELL_SUPPORT_ROOT}/`,
      htmlId: 'faqNavLink',
      target: '_blank',
      dataAttributes: {
        'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
        'data-analytics-label': 'FAQs',
        'data-analytics-category': ANALYTICS.CATEGORIES.NAVIGATION,
      },
    },
  ],
};

const shopNavLinkMobile = {
  label: 'Shop Tests',
  url: `${PUBLIC_APP_ROOT}/products`,
  className: 'shop-tests',
  htmlId: 'shopTestsLinkMobile',
  dataTest: MOBILE_HEADER.SHOP_TESTS,
  dataAttributes: {
    'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
    'data-analytics-label': 'Shop Tests',
    'data-analytics-category': ANALYTICS.CATEGORIES.NAVIGATION,
  },
  items: [
    {
      label: 'All',
      url: `${PUBLIC_APP_ROOT}/products`,
      className: 'all-products',
      htmlId: 'allProductsLinkMobile',
    },
    {
      label: "Women's Health",
      url: `${PUBLIC_APP_ROOT}/products/?category=6`,
      className: 'womens-health',
      htmlId: 'womensHealthLinkMobile',
    },
    {
      label: 'Energy + Weight',
      url: `${PUBLIC_APP_ROOT}/products/?category=4`,
      className: 'energy-weight',
      htmlId: 'energyWeightLinkMobile',
    },
    {
      label: 'General Wellness',
      url: `${PUBLIC_APP_ROOT}/products/?category=3`,
      className: 'general-wellness',
      htmlId: 'generalWellnessLinkMobile',
    },
    {
      label: "Men's Health",
      url: `${PUBLIC_APP_ROOT}/products/?category=5`,
      className: 'mens-health',
      htmlId: 'mensHealthLinkMobile',
    },
    {
      label: 'Sexual Health',
      url: `${PUBLIC_APP_ROOT}/products/?category=8`,
      className: 'sexual-health',
      htmlId: 'sexualHealthLinkMobile',
    },
  ],
};

const helpLinkMobile = {
  label: 'Help',
  url: '#',
  className: 'help',
  htmlId: 'helpNavLinkMobile',
  dataTest: MOBILE_HEADER.HELP,
  items: [
    {
      label: 'FAQs',
      className: 'faq',
      url: `${EVERLYWELL_SUPPORT_ROOT}/`,
      htmlId: 'faqNavLinkMobile',
      target: '_blank',
      dataAttributes: {
        'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
        'data-analytics-label': 'FAQs',
        'data-analytics-category': ANALYTICS.CATEGORIES.NAVIGATION,
      },
    },
  ],
};

// exported values
// desktop nav items
const shopNavLink: NavItemType = {
  label: 'Shop Tests',
  url: `${PUBLIC_APP_ROOT}/products`,
  className: 'shop-tests',
  htmlId: 'shopTestsNavLink',
  dataTest: HEADER.SHOP_TESTS,
  dataAttributes: {
    'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
    'data-analytics-label': 'Shop Tests',
    'data-analytics-category': ANALYTICS.CATEGORIES.NAVIGATION,
  },
  items: [],
};

const accountNavLink: any = {
  label: 'Account',
  url: '#',
  className: 'account',
  htmlId: 'accountNavLink',
  dataTest: HEADER.ACCOUNT,
  items: [
    {
      label: 'Settings',
      url: '/account/settings',
      component: Link,
      className: 'settings',
      htmlId: 'settingsNavLink',
      dataAttributes: {
        'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
        'data-analytics-label': 'Settings',
        'data-analytics-category': ANALYTICS.CATEGORIES.NAVIGATION,
      },
    },
    {
      label: 'Log Out',
      url: `/logout`,
      className: 'logout ',
      htmlId: 'logoutNavLink',
    },
  ],
};

if (!thirdParty) {
  accountNavLink.items.splice(1, 0, {
    label: 'Subscriptions',
    url: `${EVERLYWELL_SUPPORT_ROOT}/article/162-how-to-cancel-your-subscription`,
    className: 'subscription',
    htmlId: 'subscriptionsNavLink',
    target: '_blank',
    dataAttributes: {
      'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
      'data-analytics-label': 'Subscriptions',
      'data-analytics-category': ANALYTICS.CATEGORIES.NAVIGATION,
    },
  });
}

const accountNavLinkMobile: any = {
  label: 'Account',
  url: '#',
  className: 'account',
  htmlId: 'accountNavLinkMobile',
  dataTest: MOBILE_HEADER.ACCOUNT,
  items: [
    {
      label: 'Settings',
      url: `/account/settings`,
      component: Link,
      className: 'settings',
      htmlId: 'settingsNavLinkMobile',
      dataAttributes: {
        'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
        'data-analytics-label': 'Settings',
        'data-analytics-category': ANALYTICS.CATEGORIES.NAVIGATION,
      },
      target: '_blank',
    },
    {
      label: 'Log Out',
      url: '/logout',
      className: 'logout',
      htmlId: 'logoutNavLinkMobile',
    },
  ],
};

if (!thirdParty) {
  accountNavLinkMobile.items.splice(1, 0, {
    label: 'Subscriptions',
    url: `${EVERLYWELL_SUPPORT_ROOT}/article/162-how-to-cancel-your-subscription`,
    className: 'subscription',
    htmlId: 'subscriptionsNavLinkMobile',
    dataAttributes: {
      'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
      'data-analytics-label': 'Subscriptions',
      'data-analytics-category': ANALYTICS.CATEGORIES.NAVIGATION,
    },
    target: '_blank',
  });
}

const desktopResultsNavItems = [
  !thirdParty && shopNavLink,
  !thirdParty && theScienceLink,
  !thirdParty && helpLinkDesktop,
  {
    label: 'Register Your Kit',
    url: `${LEGACY_APP_ROOT}/register`,
    className: 'register',
    htmlId: 'registerNavLink',
    dataTest: HEADER.REGISTER_YOUR_KIT,
    target: '_blank',
    dataAttributes: {
      'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
      'data-analytics-label': 'Register Your Kit',
      'data-analytics-category': ANALYTICS.CATEGORIES.NAVIGATION,
    },
  },
  {
    label: 'My Everly',
    url: '/dashboard',
    className: 'my-everly active',
    htmlId: 'myEverlyNavLink',
    dataTest: HEADER.MY_EVERLY,
    component: Link,
    dataAttributes: {
      'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
      'data-analytics-label': 'From results to dashboard',
      'data-analytics-category': ANALYTICS.CATEGORIES.NAVIGATION,
    },
  },
  accountNavLink,
];

const unauthenticatedDesktopResultsNavItems = [
  shopNavLink,
  theScienceLink,
  helpLinkDesktop,
  {
    label: 'Register a Kit',
    url: `${LEGACY_APP_ROOT}/register`,
    className: 'register',
    htmlId: 'registerNavLink',
    dataTest: HEADER.REGISTER_A_KIT,
    target: '_blank',
    dataAttributes: {
      'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
      'data-analytics-label': 'Register Your Kit',
      'data-analytics-category': ANALYTICS.CATEGORIES.NAVIGATION,
    },
  },
  {
    label: 'Login',
    url: `${LEGACY_APP_ROOT}/login`,
    className: 'login',
    htmlId: 'loginNavLink',
    dataTest: HEADER.LOGIN,
    target: '_blank',
  },
];

// mobile nav items
const mobileResultsNavItems = [
  !thirdParty && shopNavLinkMobile,
  !thirdParty && { ...theScienceLink, htmlId: 'scienceLinkMobile' },
  !thirdParty && helpLinkMobile,
  {
    label: 'Register Your Kit',
    url: `${LEGACY_APP_ROOT}/register`,
    className: 'register',
    htmlId: 'registerLinkMobile',
    dataTest: MOBILE_HEADER.REGISTER_YOUR_KIT,
    target: '_blank',
    dataAttributes: {
      'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
      'data-analytics-label': 'Register Your Kit',
      'data-analytics-category': ANALYTICS.CATEGORIES.NAVIGATION,
    },
  },
  {
    label: 'My Everly',
    url: '/dashboard',
    component: Link,
    className: 'my-everly active',
    htmlId: 'myEverlyNavLinkMobile',
    dataTest: MOBILE_HEADER.MY_EVERLY,
    dataAttributes: {
      'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
      'data-analytics-label': 'From results to dashboard',
      'data-analytics-category': ANALYTICS.CATEGORIES.NAVIGATION,
    },
  },
  accountNavLinkMobile,
];

const unauthenticatedMobileResultsNavItems = [
  shopNavLinkMobile,
  { ...theScienceLink, htmlId: 'scienceLinkMobile' },
  helpLinkMobile,
  {
    label: 'Register a Kit',
    url: `${LEGACY_APP_ROOT}/register`,
    className: 'register',
    htmlId: 'registerLinkMobile',
    dataTest: MOBILE_HEADER.REGISTER_A_KIT,
    target: '_blank',
    dataAttributes: {
      'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
      'data-analytics-label': 'Register Your Kit',
      'data-analytics-category': ANALYTICS.CATEGORIES.NAVIGATION,
    },
  },
  {
    label: 'Login',
    url: `${LEGACY_APP_ROOT}/login`,
    className: 'login',
    htmlId: 'loginNavLinkMobile',
    dataTest: MOBILE_HEADER.LOGIN,
    target: '_blank',
  },
];

export {
  shopNavLink,
  desktopResultsNavItems,
  unauthenticatedDesktopResultsNavItems,
  mobileResultsNavItems,
  unauthenticatedMobileResultsNavItems,
};
