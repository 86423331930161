import {
  Dropdown,
  Input,
  Modal,
  ModalProps,
  RadioButton,
  colors,
  mediaQueries,
  size,
} from '@everlywell/leaves';
import styled from 'styled-components';

const BaseRadioButton = styled(RadioButton)`
  &&& {
    label {
      width: 100%;
      height: 100%;
    }
  }
`;
interface BaseModalProps extends ModalProps {
  noX?: boolean;
}
const BaseModal = styled(Modal)<BaseModalProps>`
  ${mediaQueries.forTabletHorizontalUp} {
    top: ${size.xl8}px;
  }
  ${mediaQueries.forTabletHorizontalDown} {
    z-index: 100;
  }
  button:first-child {
    display: ${({ noX }) => (noX ? 'none' : '?')};
  }
`;

const BaseInput = styled(Input)`
  svg {
    fill: none;
  }
  &&& {
    &[data-error] {
      ${mediaQueries.forPhoneOnly} {
        height: auto;
        margin-bottom: 20px;
      }
    }
    input {
      ${mediaQueries.forPhoneOnly} {
        ${(props) =>
          props.type === 'date' ? { 'min-height': `${size.xl3}px` } : {}};
      }
    }

    input::-webkit-calendar-picker-indicator {
      z-index: ${size.xs1};
      margin-right: ${size.md}px;
    }
  }
  span {
    opacity: ${(props) => (props.error ? 1 : 0)};
  }
`;

const BaseDropdown = styled(Dropdown)`
  select {
    width: 100%;
    height: 50px;
    border: 1px solid ${colors.green2};
    font-family: 'EW Nexa', Helvetica, Arial, sans-serif;
  }
`;

export { BaseRadioButton, BaseInput, BaseDropdown, BaseModal };
