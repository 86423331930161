import { mediaQueries, size } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const PrescriptionsList = styled.div`
  display: grid;
  grid-template-columns: 100%;
  row-gap: ${size.xl1}px;

  ${mediaQueries.forTabletVerticalUp} {
    row-gap: ${size.xl3}px;
  }
`;
