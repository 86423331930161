import { Col, Row } from '@everlywell/leaves';
import { COVID_SCREENER_QUESTIONS as DATA_TEST } from 'common/utils/constants/dataTest';
import React from 'react';

import { Answer } from '../KitRegistration/CovidScreenerQuestions';
import * as S from './styles';

export interface BaseQuestionProps {
  question: string;
  subText?: React.ReactNode;
  questionNumber?: number;
  totalQuestions: number;
  answers: Answer[];
  setValue: (value: string) => void;
  value: string;
  className?: string;
}

const BaseQuestion = ({
  question,
  subText,
  questionNumber,
  totalQuestions,
  answers,
  value,
  setValue,
  className,
}: BaseQuestionProps) => {
  const handleAnswerChange = (value: string) => {
    setValue(value);
  };
  const areQuestions = totalQuestions && questionNumber;
  return (
    <Row>
      <S.AnswerBox className={className}>
        <Col>
          {areQuestions && (
            <S.QuestionNumber>
              {questionNumber?.toString()} of {totalQuestions?.toString()}
            </S.QuestionNumber>
          )}
        </Col>
        <Col>
          <S.Question>
            {questionNumber}. {question}
          </S.Question>
          {subText ? <S.SubText>{subText}</S.SubText> : null}
        </Col>
        {answers.map((answer: Answer) => (
          <Col key={`${answer.id}-column`}>
            <S.Answer
              className="inspectletIgnore"
              key={answer.id}
              id={answer.id}
              name={answer.id}
              label={answer.label}
              checked={value === answer.id}
              onChange={() => handleAnswerChange(answer.id)}
              data-test={`${DATA_TEST.INPUT}-${answer.id}`}
            />
          </Col>
        ))}
      </S.AnswerBox>
    </Row>
  );
};

export default BaseQuestion;
