import { ImmutableReduxState } from 'common/utils/types';
import SectionHeader, {
  Props as SectionHeaderProps,
} from 'components/SectionHeader';
import React from 'react';
import { connect } from 'react-redux';
import { makeSelectContent } from 'store/selectors';

const CONTENT_KEYS = {
  description: 'WHATS_NEXT_DESCRIPTION',
  iconSrc: 'WHATS_NEXT_ICON',
  alt: 'WHATS_NEXT_ICON_ALT',
};

type OwnProps = {
  contentToken: string;
};

export function SubSectionHeaderContainer(props: SectionHeaderProps) {
  const hasContent = !!props.description;

  if (!hasContent) {
    return null;
  }

  return <SectionHeader {...props} />;
}

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): SectionHeaderProps {
  return makeSelectContent(ownProps.contentToken, CONTENT_KEYS)(state);
}

export default connect(mapStateToProps)(SubSectionHeaderContainer);
