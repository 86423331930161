import TagManager from 'react-gtm-module';

import { hashSha1 } from '../helpers';
import { freshpaint } from './freshpaint';

interface TrackType {
  email: string;
  userId: number;
}
interface TrackTypeWithProgram extends TrackType {
  program?: string;
}

export const onWeightCareConversionTrack = (params: TrackTypeWithProgram) => {
  //TODO: Configure freshpaint to track Weight Care Purchase for prod environment when required.
  freshpaint.track('Weight Care Purchase', {
    ...params,
  });

  TagManager.dataLayer({
    dataLayer: {
      event: 'Purchase',
      program: params.program,
      hashedEmail: hashSha1(params.email),
      userId: params.userId,
    },
  });
};

export const onDemandVisitPurchaseTrack = (params: TrackTypeWithProgram) => {
  const { program } = params;
  //TODO: Configure freshpaint to track On Demand Visit Purchase for prod environment when required.
  freshpaint.track('On Demand Visit Purchase', {
    ...params,
  });

  TagManager.dataLayer({
    dataLayer: {
      event: `${program}-confirmation`,
      program: program,
    },
  });
};
