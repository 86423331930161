import { Toast, ToastType } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { NOTIFICATION } from 'common/utils/constants/dataTest';
import { Notification as NotificationType } from 'common/utils/types';
import React, { useEffect } from 'react';

import { CLEAR_NOTIFICATION_DELAY } from '../../containers/Notification';
import * as S from './ToastNotification.styles';

type Props = {
  notification?: NotificationType;
  clearNotification?: () => void;
};

const getLabelFromNotification = (notification: NotificationType) => {
  if (notification.mixpanelLabel) return notification.mixpanelLabel;
  return (notification.details ?? notification.message) as string;
};

function ToastNotification(props: Props): JSX.Element | null {
  const { notification, clearNotification } = props;
  const type = notification?.type ?? ToastType.SUCCESS;
  const persistent = notification?.persist ?? false;
  const handleClearNotification = () => {
    if (clearNotification) {
      analytics.track({
        event: ANALYTICS.EVENTS.CLEAR_NOTIFICATION,
        data: {
          label: ANALYTICS.LABELS.NOTIFICATION,
          type,
          category: ANALYTICS.CATEGORIES.NOTIFICATION,
          component: ANALYTICS.CATEGORIES.NOTIFICATION,
        },
      });
      clearNotification();
    }
  };

  useEffect(() => {
    if (notification) {
      analytics.track({
        event: ANALYTICS.EVENTS.NOTIFICATION,
        data: {
          label: getLabelFromNotification(notification),
          type: notification.type,
          category: ANALYTICS.CATEGORIES.NOTIFICATION,
          component: notification.source,
        },
      });
    }
  }, []);
  const message = notification?.message ?? '';
  const details = notification?.details;
  return notification ? (
    <S.Wrapper
      data-testid={
        notification.type === ToastType.SUCCESS
          ? NOTIFICATION.INFO_NOTIFICATION
          : NOTIFICATION.ERROR_NOTIFICATION
      }
    >
      <Toast
        type={type}
        content={message}
        subtext={details}
        handleClose={handleClearNotification}
        visibleTime={CLEAR_NOTIFICATION_DELAY - 500}
        persistent={persistent}
      />
    </S.Wrapper>
  ) : null;
}

export default ToastNotification;
