import { H3 } from '@everlywell/leaves';
import {
  TelehealthCustomModule,
  TelehealthFormAnswer,
  TelehealthFormData,
} from 'common/apis/telehealthApis';
import Grid from 'components/Grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { Fragment, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import Field from './components/Field';
import { HEADER_TAG } from './components/LabelField';
import * as S from './FormBuilder.styles';
import { FormModalProvider } from './providers';
import { FormBuilderVariant } from './utils/types';

export type FormBuilderProps = TelehealthFormData & {
  children?: React.ReactNode;
  defaultValues?: Record<string, string | null>;
  error?: { message: string } | null;
  isSubmitting?: boolean;
  onSubmit: (answers: TelehealthFormAnswer[]) => void;
  submitCopy?: string;
  variant?: FormBuilderVariant;
};

const formContainsRequiredFields = (custom_modules: TelehealthCustomModule[]) =>
  custom_modules.some((module) => module.required);

const RequiredField = ({
  custom_modules,
}: {
  custom_modules: TelehealthCustomModule[];
}) =>
  formContainsRequiredFields(custom_modules) ? (
    <Grid.Item width={[1]}>
      <S.RequiredText>* indicates a required field</S.RequiredText>
    </Grid.Item>
  ) : null;

type FieldsType = {
  custom_modules: TelehealthCustomModule[];
  variant: FormBuilderVariant;
  showDynamicFormHeaders: boolean;
};
const Fields = ({
  custom_modules,
  showDynamicFormHeaders,
  variant,
}: FieldsType) => (
  <>
    {custom_modules.map((customModule, index) => (
      <Fragment key={index}>
        <Field
          key={customModule.id}
          {...customModule}
          variant={variant}
          modules={custom_modules}
        />
        {showDynamicFormHeaders &&
          index === 0 &&
          customModule.mod_type === 'label' &&
          customModule.label?.includes(HEADER_TAG) && (
            <RequiredField custom_modules={custom_modules} />
          )}
      </Fragment>
    ))}
  </>
);

/**
 * The goal of this component is to render a form based on the custom modules' types.
 */
function FormBuilder({
  children,
  custom_modules,
  error,
  id,
  isSubmitting,
  name,
  onSubmit,
  submitCopy = 'Continue',
  variant = 'primary',
  ...props
}: FormBuilderProps) {
  const { showDynamicFormHeaders } = useFlags<{
    showDynamicFormHeaders: boolean;
  }>();
  const defaultValues: Record<string, string> = custom_modules.reduce(
    (acc, module) => ({
      ...acc,
      [module.id]: props.defaultValues?.[module.id] ?? '',
    }),
    {},
  );

  const modTypes: Record<string, string> = custom_modules.reduce(
    (acc, module) => ({
      ...acc,
      [module.id]: module.mod_type,
    }),
    {},
  );

  /**
   * handle modals for readonly fields
   */
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalsToBeShown, setModalsToBeShown] = useState<boolean>(false);

  const useFormMethods = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const onSubmitHandler = (values: Record<string, string | FileList>) => {
    // Make sure all the hidden fields are included in the answers
    if (modalsToBeShown) {
      setShowModal(true);
      return;
    }
    const updatedValues = Object.entries(values).reduce<
      Record<string, string | FileList>
    >(
      (acc, [key, value]) => {
        // Make sure the payload only contains the custom module id that are listed in the custom_modules list.
        // Also make sure the value is a string, otherwise it will be ignored.
        if (
          defaultValues.hasOwnProperty(key) &&
          (typeof value === 'string' || value instanceof FileList)
        ) {
          acc[key] = value;
        }

        return acc;
      },
      { ...defaultValues },
    );

    const answers = Object.entries(updatedValues).map(([key, value]) => ({
      custom_module_id: key,
      answer: value,
      type: modTypes[key],
    }));
    onSubmit(answers);
  };

  /**
   * If this component gets notified of a modal that needs to be shown
   *   store a boolean indicating that we need to show the modals
   *
   * If the user clicks continue AND there is a modal that needs to be shown
   *   send a signal to trigger opening the modal to the ReadOnlyField (or any field that's listening)
   */

  return (
    <FormModalProvider
      value={{
        setModalsToBeShown,
        showModal,
        formName: name,
        allCustomModules: custom_modules,
      }}
    >
      <FormProvider {...useFormMethods}>
        <S.Container id={`form-${id}`}>
          {
            /**
             * @deprecated - This Element is deprecated and will be removed in the future due
             * to Dynamic Forms
             */
            !showDynamicFormHeaders && <H3 as="h1">{name}</H3>
          }
          <Grid.Container
            as="form"
            spacing={['xl1']}
            onSubmit={useFormMethods.handleSubmit(onSubmitHandler)}
          >
            {!showDynamicFormHeaders &&
              /**
               * @deprecated - This Element is deprecated and will be removed in the future due
               * to Dynamic Forms
               */
              formContainsRequiredFields(custom_modules) && (
                <Grid.Item width={[1]}>
                  <S.RequiredText>* indicates a required field</S.RequiredText>
                </Grid.Item>
              )}

            <Fields
              custom_modules={custom_modules}
              showDynamicFormHeaders={showDynamicFormHeaders}
              variant={variant}
            />

            {error && (
              <Grid.Item width={[1]}>
                <S.ErrorText>{error.message}</S.ErrorText>
              </Grid.Item>
            )}

            <Grid.Item width={[1]}>
              <S.Button type="submit" isLoading={isSubmitting}>
                {submitCopy}
              </S.Button>
            </Grid.Item>
            {children && <Grid.Item width={[1]}>{children}</Grid.Item>}
          </Grid.Container>
        </S.Container>
      </FormProvider>
    </FormModalProvider>
  );
}

export default FormBuilder;
