/**
 * ContentCard
 */

import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { CONTENT_CARD } from 'common/utils/constants/dataTest';
import { sanitize } from 'common/utils/domHelpers';
import React, { useState } from 'react';

import * as S from './ContentCard.styles';
import ContentCardModal from './ContentCardModal';

export type Props = {
  header: string;
  previewText: string;
  buttonText?: string;
  description: string;
  backgroundSrc: string;
  number: number;
};

function ContentCard(props: Props) {
  const {
    header,
    previewText,
    buttonText = 'Read More',
    backgroundSrc,
    number,
    description,
  } = props;
  const hasIconResource = backgroundSrc.includes('icon_resources') || false;
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'Close Modal',
        category: ANALYTICS.CATEGORIES.WHATS_NEXT,
      },
    });
  };

  return (
    <>
      <S.Card
        backgroundSrc={backgroundSrc}
        hasIconResource={hasIconResource}
        data-test={`content-card${number}`}
      >
        <div>
          <S.CardTitle
            dangerouslySetInnerHTML={{ __html: sanitize(header) }}
            data-test={CONTENT_CARD.CARD_TITLE}
          />
          <S.CardText
            dangerouslySetInnerHTML={{ __html: sanitize(previewText) }}
            data-test={CONTENT_CARD.CARD_PREVIEW_TEXT}
          />
        </div>
        <S.CardButton
          appearance={'secondary'}
          data-test={CONTENT_CARD.READ_MORE_BUTTON}
          onClick={() => setIsOpen(true)}
          trackingData={{
            label: 'Read More',
            category: ANALYTICS.CATEGORIES.WHATS_NEXT,
          }}
        >
          {buttonText}
        </S.CardButton>
      </S.Card>
      {isOpen && (
        <ContentCardModal
          onClose={handleClose}
          header={header}
          description={description}
          backgroundSrc={backgroundSrc}
        />
      )}
    </>
  );
}

export default ContentCard;
