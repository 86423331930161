type QuestionnaireTypes = {
  [key: string]: string;
};

export const QUESTIONNAIRE_BY_PRODUCT: QuestionnaireTypes = {
  'food-allergy-test': 'food-allergy-screener',
};

const getQuestionnaireByProductSlug = (slug: string): string | null =>
  QUESTIONNAIRE_BY_PRODUCT.hasOwnProperty(slug)
    ? QUESTIONNAIRE_BY_PRODUCT[slug]
    : null;

export default getQuestionnaireByProductSlug;
