import { InformationV2 } from '@everlywell/leaves';
import { StripeElement } from '@stripe/stripe-js';
import { stripeElementStyles } from 'common/utils/stripeHelpers';
import Grid from 'components/Grid';
import * as StripeS from 'components/StripeComponent/StripeElements/StripeElements.styles';
import { FormValues } from 'pages/CreditCardForm/components/Payment';
import React, { useState, useEffect } from 'react';

import { useStripeFormContext } from '../Providers/StripeFormProvider';
import * as S from './CardDetailsStripe.styles';

type StripeElementsValidation = (event?: any) => void;
export interface CardDetailsStripeProps {
  handleFocus?: ({
    value,
    name,
  }: {
    value: string;
    name: keyof FormValues;
  }) => void;
  isDisabled?: boolean;
}

type MessageError = { message: string };
export type StripeElementsErrors = {
  incomplete_cvc?: MessageError;
  incomplete_expiry?: MessageError;
  incomplete_number?: MessageError;
  incorrect_cvc?: MessageError;
  invalid_expiry_month_past?: MessageError;
  invalid_expiry_year?: MessageError;
  invalid_expiry_year_past?: MessageError;
  invalid_number?: MessageError;
};

function elementActions(
  stripeElement: StripeElement,
  onChangeStripeElementsValidation: StripeElementsValidation,
) {
  //@ts-expect-error: accepted methods https://stripe.com/docs/js/element/other_methods
  stripeElement.on('change', onChangeStripeElementsValidation);
  //@ts-expect-error: accepted methods https://stripe.com/docs/js/element/other_methods
  stripeElement.on('focus', onChangeStripeElementsValidation);
}

export function CardDetailsStripe({ isDisabled }: CardDetailsStripeProps) {
  const { stripeElementsErrors, onChangeStripeElementsValidation, elements } =
    useStripeFormContext();

  const [stripeIsLoaded, setStripeIsLoaded] = useState<boolean>(false);

  const [stripeElements, setStripeElements] = useState<
    Record<string, StripeElement>
  >({});

  useEffect(() => {
    // Wait for stripe to load, but only create elements once.
    if (elements && !stripeIsLoaded) {
      // Flag that stripe is loaded so we don't try this again.
      setStripeIsLoaded(true);
      // Create the Stripe Elements, which we mount later
      const cardNumber = elements.create('cardNumber', {
        showIcon: false,
        placeholder: '0000-0000-0000-0000',
        style: stripeElementStyles,
      });
      elementActions(cardNumber, onChangeStripeElementsValidation);

      const cardExpiry = elements.create('cardExpiry', {
        placeholder: 'MM/YY',
        style: stripeElementStyles,
      });
      elementActions(cardExpiry, onChangeStripeElementsValidation);

      const cardCvc = elements.create('cardCvc', {
        placeholder: '123',
        style: stripeElementStyles,
      });
      elementActions(cardCvc, onChangeStripeElementsValidation);
      // Setup is complete. We can use these elements to mount and unmount
      setStripeElements({ cardNumber, cardExpiry, cardCvc });
    }
  }, [elements, onChangeStripeElementsValidation, stripeIsLoaded]);

  useEffect(() => {
    if (!stripeIsLoaded) return () => {};

    const { cardNumber, cardExpiry, cardCvc } = stripeElements;

    // Mount Stripe Elements
    cardNumber.mount('#stripeCardNumber');
    cardExpiry.mount('#stripeExpirationDate');
    cardCvc.mount('#stripeCvcNumber');

    return () => {
      // Cleanup, we need to destroy these so we can remount this component
      cardNumber.destroy();
      cardExpiry.destroy();
      cardCvc.destroy();
    };
  }, [stripeElements, stripeIsLoaded]);

  useEffect(() => {
    if (!stripeIsLoaded) return () => {};

    const { cardNumber, cardExpiry, cardCvc } = stripeElements;

    // Enable disable card inputs
    cardNumber.update({ disabled: isDisabled });
    cardExpiry.update({ disabled: isDisabled });
    cardCvc.update({ disabled: isDisabled });
  }, [stripeElements, stripeIsLoaded, isDisabled]);

  const cardNumberErrorMessage =
    stripeElementsErrors?.incomplete_number?.message ||
    stripeElementsErrors?.invalid_number?.message ||
    '';

  const expiryErrorMessage =
    stripeElementsErrors?.incomplete_expiry?.message ||
    stripeElementsErrors?.invalid_expiry_year?.message ||
    '';

  const cvcErrorMessage =
    stripeElementsErrors?.incomplete_cvc?.message ||
    stripeElementsErrors?.incorrect_cvc?.message ||
    '';

  return (
    <>
      <Grid.Item width={[1]}>
        <StripeS.InputWrapper>
          <StripeS.Label htmlFor="stripeCardNumber" id="stripeCardNumberLabel">
            Card Number
          </StripeS.Label>
          <StripeS.Input
            aria-labelledby="stripeCardNumberLabel"
            className="inspectletIgnore"
            id="stripeCardNumber"
            name="cardNumber"
            role="textbox"
            isDisabled={!!isDisabled}
          />
        </StripeS.InputWrapper>
        <StripeS.ErrorLabel>{cardNumberErrorMessage}</StripeS.ErrorLabel>
      </Grid.Item>

      <Grid.Item width={[1 / 2]}>
        <StripeS.InputWrapper>
          <StripeS.Label
            htmlFor="stripeExpirationDate"
            id="stripeExpirationDateLabel"
          >
            Expiration Date
          </StripeS.Label>
          <StripeS.Input
            aria-labelledby="stripeExpirationDateLabel"
            id="stripeExpirationDate"
            name="cardExpiry"
            className="inspectletIgnore"
            role="textbox"
            isDisabled={!!isDisabled}
          />
        </StripeS.InputWrapper>
        <StripeS.ErrorLabel>{expiryErrorMessage}</StripeS.ErrorLabel>
      </Grid.Item>
      <Grid.Item width={[1 / 2]}>
        <S.StyledInputContainer>
          <StripeS.InputWrapper>
            <StripeS.LabelWrapper>
              <StripeS.Label
                htmlFor="stripeCvcNumber"
                id="stripeCvcNumberLabel"
              >
                CVC
              </StripeS.Label>
              <StripeS.TooltipWrapper data-test="cvcNumber-tooltip">
                <StripeS.CvcTooltip
                  animationSpeed="normal"
                  content="3-digit security code usually found on the back of your card. American Express cards have a 4-digit code located on the front."
                  position="top"
                  arrow="right"
                  tooltipBoxClass="tooltipBox"
                >
                  <InformationV2 />
                </StripeS.CvcTooltip>
              </StripeS.TooltipWrapper>
            </StripeS.LabelWrapper>
            <StripeS.Input
              aria-labelledby="stripeCvcNumberLabel"
              id="stripeCvcNumber"
              name="cardCvc"
              className="inspectletIgnore"
              role="textbox"
              isDisabled={!!isDisabled}
            />
          </StripeS.InputWrapper>
        </S.StyledInputContainer>
        <StripeS.ErrorLabel>{cvcErrorMessage}</StripeS.ErrorLabel>
      </Grid.Item>
    </>
  );
}

export default CardDetailsStripe;
