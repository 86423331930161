import {
  Container,
  size,
  mediaQueries,
  ProgressBar as BaseProgressBar,
} from '@everlywell/leaves';
import styled from 'styled-components';

interface WrapperProps {
  steps: string[];
}

const KitRegistrationWrapper = styled(Container)<WrapperProps>`
  padding-bottom: ${size.xl1}px;

  ${mediaQueries.forTabletHorizontalUp} {
    display: flex;
    justify-content: space-around;
    padding-top: ${(props) =>
      props.steps.length > 1 ? `${size.xl1}px` : '185px'};
    padding-bottom: 220px;
  }
  ${mediaQueries.forTabletHorizontalDown} {
    padding-top: ${size.xl1}px;
  }
`;

const ContentContainer = styled.div`
  flex-grow: 2;
  ${mediaQueries.forTabletHorizontalUp} {
    max-width: 532px;
  }
`;

const ContentContainerFull = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

const ProgressBar = styled(BaseProgressBar)`
  margin-bottom: ${size.xl1}px;
  align-self: center;
  width: 100%;

  ${mediaQueries.forTabletHorizontalUp} {
    width: 70%;
  }
  ${mediaQueries.forPhoneOnly} {
    .accomplished {
      font-size: 10px;
    }

    .null {
      font-size: 10px;
    }
  }
`;

export {
  KitRegistrationWrapper,
  ContentContainer,
  ContentContainerFull,
  StepsContainer,
  ProgressBar,
};
