import {
  Icons as LeavesIcons,
  SkeletonLoader,
  SkeletonUnit,
} from '@everlywell/leaves';
import { useTelehealthAppointments } from 'common/apis/telehealthApis';
import useUser from 'common/hooks/useUser/useUser';
import { telehealthUrl } from 'common/utils/helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';

import Icons from './components/Icons';
import SideNavLink from './components/SideNavLink';
import * as S from './SideNav.styles';

export type SideNavProps = {};

export function useTelehealthAccount(): {
  isLoading: boolean;
  hasTelehealthAccount: boolean;
  parsedUrl: string;
} {
  const { user, isLoading: isLoadingUser } = useUser();
  const url = telehealthUrl({ redirectToProvider: 'true' });
  const parsedUrl = `${url.pathname}${url.search}`;

  const enrolledPrograms = user?.enrolled_programs ?? [];
  const isEnrolled = enrolledPrograms.length > 0;
  const { data: response, isLoading: isLoadingAppointments } =
    useTelehealthAppointments(
      {
        period: 'all',
      },
      {
        enabled: isEnrolled,
      },
    );
  let hasTelehealthAccount = false;

  const appointments = response?.data?.appointments ?? [];
  if (appointments.length > 0) {
    hasTelehealthAccount = true;
  }

  return {
    hasTelehealthAccount,
    isLoading: isLoadingAppointments || isLoadingUser,
    parsedUrl,
  };
}

/**
 * Side navigation component for desktop view
 */
function SideNav(props: SideNavProps) {
  const { isWeightManagementEnabled, isResultsPortalTabEnabled } = useFlags();

  const { hasTelehealthAccount, isLoading, parsedUrl } = useTelehealthAccount();

  return (
    <S.Container>
      {/* room space for the side nav */}
      <S.NavContainer>
        <SideNavLink
          to="/dashboard"
          Icon={Icons.Home}
          data-testid="my-everly-side-link"
        >
          My Everly
        </SideNavLink>
        <SideNavLink
          to="/results"
          Icon={Icons.TestResults}
          data-testid="test-results-side-link"
        >
          Test Results
        </SideNavLink>

        <SideNavLink
          to="/virtual-care"
          Icon={Icons.Telehealth}
          data-testid="virtual-care-side-link"
        >
          Virtual Care
        </SideNavLink>

        {isLoading && (
          <SkeletonLoader
            height={{ value: 20, unit: SkeletonUnit.Pixel }}
            width={{ value: 100, unit: SkeletonUnit.Percentage }}
          />
        )}
        {isResultsPortalTabEnabled && hasTelehealthAccount && (
          <SideNavLink
            Icon={Icons.Folder}
            data-testid="health-provider-records-side-link"
            to={parsedUrl}
          >
            Records Portal
          </SideNavLink>
        )}

        {isWeightManagementEnabled ? (
          <SideNavLink
            to="/weight-management"
            Icon={LeavesIcons.Refine}
            data-testid="weight-care-side-link"
          >
            Weight Management
          </SideNavLink>
        ) : null}
        <SideNavLink
          to="/account/settings"
          Icon={Icons.Settings}
          data-testid="settings-side-link"
        >
          Settings
        </SideNavLink>
      </S.NavContainer>
    </S.Container>
  );
}

export default SideNav;
