import { LeftCaret, Tab } from '@everlywell/leaves';
import { loadStripe } from '@stripe/stripe-js';
import { getUser } from 'common/apis/userApis';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { MEMBERSHIP_PROGRAMS_LIST } from 'common/utils/types';
import { isUserMemberOfProgram } from 'common/utils/user';
import { StripeFormProvider } from 'components/StripeComponent/Providers/StripeFormProvider';
import VisuallyHidden from 'components/VisuallyHidden';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';
import { setPhysicianContact } from 'store/physicianContact/actions';
import { makeSelectUserId } from 'store/selectors';
import { setUserData } from 'store/user/actions';

import { PageHeaderStyle } from '../../AccountHub.styles';
import AccountTabContent from './components/AccountTabContent';
import MembershipsSettingsTabContent from './components/MembershipsSettingsTabContent';
import * as S from './SettingsPage.styles';
import { SettingsPageTabList } from './SettingsPage.types';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY || '');

export type SettingsPageProps = {};

function SettingsPage(props: SettingsPageProps) {
  const dispatch = useDispatch();
  const userId: string = useSelector(makeSelectUserId());
  const [isProgramMember, setIsProgramMember] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSettingsPageViewed, setIsSettingsPageViewed] =
    useState<boolean>(false);
  const isEmbedded =
    searchParams.get('embedded') === 'true' ||
    sessionStorage.getItem('embedded') === 'true';
  const tab = searchParams.get('tab');

  const inMobileWebview =
    isEmbedded || window.navigator.userAgent.includes('com.everlyhealth.ios');

  const { data: response } = useQuery(['user', userId], () => getUser(userId), {
    onSuccess: (response) => {
      if (response.data) {
        // @ts-ignore - TODO: `physician` is not a property of `User`
        const { physician } = response.data;
        if (physician) {
          delete physician.id;
          dispatch(setPhysicianContact(physician));
        }

        dispatch(setUserData({ ...response.data, full_user_data: true }));

        setIsProgramMember(
          isUserMemberOfProgram(response.data, MEMBERSHIP_PROGRAMS_LIST),
        );
      }
    },
  });

  const wasSuccessful = response?.wasSuccessful ?? false;
  const statusCode = response?.statusCode;
  const user = response?.data;

  const SETTINGS_TABS = useMemo(() => {
    const TABS: SettingsPageTabList = {
      account: {
        key: 'account',
        label: 'Account',
        component: <AccountTabContent />,
        trackingLabel: ANALYTICS.LABELS.ACCOUNT_SETTINGS.TABS.ACCOUNT,
      },
      ...(isProgramMember && {
        memberships: {
          key: 'memberships',
          label: 'Memberships',
          component: <MembershipsSettingsTabContent />,
          trackingLabel: ANALYTICS.LABELS.ACCOUNT_SETTINGS.TABS.MEMBERSHIPS,
        },
      }),
    };

    return TABS;
  }, [isProgramMember]);

  const [settingsSelectedTabKey, setSettingsSelectedTabKey] = useState(
    SETTINGS_TABS.account.key,
  );

  useEffect(() => {
    if (user && !isSettingsPageViewed) {
      analytics.track({
        event: ANALYTICS.EVENTS.VIEWED_PAGE,
        data: {
          page: ANALYTICS.PAGES.ACCOUNT_SETTINGS,
        },
      });

      setIsSettingsPageViewed(true);
    }
  }, [user, isSettingsPageViewed]);

  useEffect(() => {
    if (tab && SETTINGS_TABS[tab]) {
      setSettingsSelectedTabKey(tab);
    }
  }, [SETTINGS_TABS, tab, isProgramMember]);

  if (!wasSuccessful && statusCode && statusCode !== 401) {
    return <Navigate to={`/${statusCode}`} />;
  }

  const handleSettingsTabSelect = (tabIndex: number) => {
    const objectKey = Object.keys(SETTINGS_TABS)[tabIndex];
    const selectedTab = SETTINGS_TABS[objectKey].key;
    setSearchParams({ tab: selectedTab });

    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: SETTINGS_TABS[objectKey].trackingLabel,
      },
    });
  };

  return (
    <>
      <>
        {!inMobileWebview ? (
          <S.BackLink to="/dashboard">
            <VisuallyHidden>Go back to dashboard</VisuallyHidden>
            <LeftCaret width="12px" />
          </S.BackLink>
        ) : null}
        <PageHeaderStyle>Settings</PageHeaderStyle>
      </>

      <div>
        <StripeFormProvider stripe={stripePromise}>
          <S.InnerContent>
            <S.TabMenu
              onItemSelect={(index) => handleSettingsTabSelect(index)}
              id="settings-tabs"
              ariaLabel="settings-tabs"
              ariaControls="settings"
              initialActiveIndex={Object.keys(SETTINGS_TABS).findIndex(
                (objectKey) => objectKey === settingsSelectedTabKey,
              )}
            >
              {Object.keys(SETTINGS_TABS).map((key) => (
                <Tab key={key}>{SETTINGS_TABS[key].label}</Tab>
              ))}
            </S.TabMenu>
            <S.TabContentWrapper>
              {SETTINGS_TABS[settingsSelectedTabKey].component}
            </S.TabContentWrapper>
          </S.InnerContent>
        </StripeFormProvider>
      </div>
    </>
  );
}

export default SettingsPage;
