import { ImmutableReduxState } from 'common/utils/types';
import ContentCardCollection, {
  Props as ContentCardCollectionProps,
} from 'components/ContentCardCollection';
import React from 'react';
import { connect } from 'react-redux';
import { makeSelectContent } from 'store/selectors';

const CONTENT_KEYS = {
  headers: 'CARDS_TITLE_*',
  previewTexts: 'CARDS_DESCRIPTION_*',
  buttonTexts: 'CARDS_BUTTON_TEXT_*',
  backgroundSrcs: 'CARDS_ICON_*',
  descriptions: 'CARDS_EXTENDED_DESCRIPTION_*',
};

type StateProps = {
  content: {
    headers: string[];
    previewTexts: string[];
    buttonTexts: string[];
    backgroundSrcs: string[];
    descriptions: string[];
  };
};

type OwnProps = {
  contentToken: string;
};

export function ContentCardCollectionContainer(
  props: ContentCardCollectionProps,
) {
  const hasContent = props.cards.length > 0;

  if (!hasContent) {
    return null;
  }

  return <ContentCardCollection {...props} />;
}

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): StateProps {
  return {
    // @ts-ignore
    content: makeSelectContent(ownProps.contentToken, CONTENT_KEYS)(state),
  };
}

export function mergeProps(stateProps: StateProps): ContentCardCollectionProps {
  const { content } = stateProps;
  const { headers, previewTexts, backgroundSrcs, buttonTexts, descriptions } =
    content;
  const cards = previewTexts.map((previewText, index) => ({
    previewText,
    buttonText: buttonTexts[index],
    backgroundSrc: backgroundSrcs[index],
    description: descriptions[index],
    header: headers[index],
    number: index + 1,
  }));

  return {
    cards,
  };
}

export default connect(
  mapStateToProps,
  null,
  mergeProps,
)(ContentCardCollectionContainer);
