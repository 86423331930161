/**
 *
 * SegmentedControl
 *
 */

import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { RESULTS_GROUPING as DATA_TEST } from 'common/utils/constants/dataTest';
import React, { useState } from 'react';

import SegmentedControlButton, { Control } from './SegmentedControlButton';
import * as S from './styles';

export type Props = {
  selectedValue?: string;
  controlList: Control[];
  onChange: Function;
};

function SegmentedControl(props: Props) {
  const { controlList, onChange, selectedValue: propSelectedValue } = props;

  const initialSelectedValue =
    propSelectedValue || (controlList.length > 0 ? controlList[0].value : '');

  const [selectedValue, setSelectedValue] = useState(initialSelectedValue);

  const handleClick = (control: Control) => {
    if (control.eventTracking) {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: `Group by ${control.label.toLowerCase()}`,
          category: ANALYTICS.CATEGORIES.GROUP_BY,
        },
      });
    }

    setSelectedValue(control.value);
    onChange(control.value);
  };

  if (!controlList.length) {
    return null;
  }

  return (
    <>
      <S.WrapperLabel>Group By</S.WrapperLabel>
      <S.Wrapper role="tablist" data-testid={DATA_TEST.CONTAINER}>
        {controlList.map((control) => (
          <SegmentedControlButton
            key={control.value}
            control={control}
            onClick={() => handleClick(control)}
            selectedValue={selectedValue}
          />
        ))}
      </S.Wrapper>
    </>
  );
}

export default SegmentedControl;
