/* eslint-disable @typescript-eslint/no-var-requires */
/* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
import { colors, mediaQueries, typography } from '@everlywell/leaves';
import {
  CardContainer,
  bodyCopyLarge,
  bodyCopy,
} from 'common/styles/app-styles';
import Button from 'components/Button';
import styled from 'styled-components';

type CardProps = {
  backgroundSrc: string;
  hasIconResource: boolean;
};

const Card = styled(CardContainer)<CardProps>`
  /* prettier-ignore */
  background-image: url("${(props) => props.backgroundSrc}");
  background-position: ${(props) =>
    !props.hasIconResource ? 'center 40px' : '50% 3%'};
  background-repeat: no-repeat;
  background-size: 30%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 380px;
  justify-content: space-between;
  max-width: 332px;
  padding: 120px 28px 40px;
  text-align: center;

  ${mediaQueries.forTabletVerticalUp} {
    margin-bottom: 24px;
    max-width: 380px;
    padding: 120px 32px 40px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    align-self: stretch;
    flex: 0 1 47%;
    max-width: 380px;
    margin: 0 12px 24px;
    min-height: 430px;
  }

  ${mediaQueries.forDesktopUp} {
    flex: 0 1 30%;
  }
`;

const CardTitle = styled.div`
  color: ${colors.gray5};
  font-weight: ${typography.weight.bold};
  ${bodyCopy};
  line-height: 24px;
  margin: 0 0 16px;

  ${mediaQueries.forTabletVerticalUp} {
    ${bodyCopyLarge};
    font-weight: ${typography.weight.regular};
    margin: 0 0 12px;
  }
`;

const CardText = styled.div`
  ${bodyCopy};
  color: ${colors.gray4};
  font-weight: ${typography.weight.light};
  max-width: 100%;
  padding-bottom: 28px;
  text-align: center;

  ${mediaQueries.forTabletVerticalUp} {
    ${bodyCopyLarge};
  }
`;

const CardButton = styled(Button)`
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: none;
  width: 230px;
  height: 48px;
  padding: 0;
`;

export { Card, CardTitle, CardText, CardButton };
