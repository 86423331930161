import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import Grid from 'components/Grid';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import * as S from './ConsentModal.styles';
import { consent, continueButton, copy, title } from './constants';

export type ConsentModalProps = {
  openModal: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  programSlug: string;
  telehealthUrl: string;
};

/**
 * Consents modal VCV
 */
function ConsentModal(props: ConsentModalProps) {
  const { openModal, setIsModalOpen, programSlug = '', telehealthUrl } = props;
  const navigate = useNavigate();

  const handleModalClose = (isOpen: boolean) => {
    setIsModalOpen(isOpen);
  };

  const handleContinue = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.CREDIT_REDEMPTION.CONTINUE_CONSENT_MODAL,
        program: programSlug,
      },
    });

    navigate(telehealthUrl);
  };

  const handleClose = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.CREDIT_REDEMPTION.CLOSE_CONSENT_MODAL,
        program: programSlug,
      },
    });
    setIsModalOpen(false);
  };

  useEffectOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        page: ANALYTICS.PAGES.CREDIT_REDEMPTION.CONSENT_MODAL,
        program: programSlug,
      },
    });
  });

  return (
    <S.Modal
      open={openModal}
      data-test="consent-credit-modal"
      forceFullScreen={true}
      openHandler={handleModalClose}
    >
      <S.ModalInnerContainer>
        <S.Grid.Container spacing={['xs1']}>
          <Grid.Item width={[1]}>
            <S.Title>{title}</S.Title>
          </Grid.Item>

          <Grid.Item width={[1]}>
            <S.Copy>{copy}</S.Copy>
            <S.Consent>{consent}</S.Consent>
          </Grid.Item>

          <Grid.Item width={[1]} css={S.centerModalStyles}>
            <S.Button appearance="primary" onClick={handleContinue}>
              {continueButton}
            </S.Button>
            <S.Button appearance="text" onClick={handleClose}>
              Close
            </S.Button>
          </Grid.Item>
        </S.Grid.Container>
      </S.ModalInnerContainer>
    </S.Modal>
  );
}

export default ConsentModal;
