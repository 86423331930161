import {
  creditCardsIcons,
  SkeletonLoader,
  SkeletonShape,
  SkeletonUnit,
} from '@everlywell/leaves';
import useAccountSettings, {
  StripePaymentInformation,
} from 'common/hooks/useAccountSettings';
import analytics from 'common/utils/analytics';
import { convertCardBrandToKey } from 'common/utils/cards';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { logError } from 'common/utils/helpers';
import { StripePaymentSource } from 'common/utils/stripeHelpers';
import { NotificationContext } from 'components/Notification/NotificationContext';
import React, { useState, useCallback, useContext, useEffect } from 'react';
import { ERROR_NOTIFICATION } from 'store/actions';

import FormSummary from '../../FormSummary/FormSummary';
import UpdatePaymentModal from './components/UpdatePaymentModal';
import DEFAULT_CONTENT from './content';
import * as S from './PaymentInfo.styles';

export interface PaymentInfoProps {}

export const PaymentInfo = (props: PaymentInfoProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paymentInfo, setPaymentInfo] = useState<StripePaymentSource>();
  const { setNotificationData } = useContext(NotificationContext);
  const { getBillingDetails } = useAccountSettings();
  interface Notifyable {
    message: string;
    details?: string;
    context?: any;
  }

  const fetchPaymentData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getBillingDetails();
      const paymentInformation =
        response.payment_information as StripePaymentInformation;
      setPaymentInfo(paymentInformation?.default_source);
      setIsLoading(false);
    } catch (error: any) {
      setNotificationData({
        message: error,
        persist: false,
        type: ERROR_NOTIFICATION,
        source: ANALYTICS.LABELS.PAYMENT_INFORMATION,
      });

      setIsLoading(false);
      logError(error);
    }
  }, [getBillingDetails, setNotificationData]);

  const onSuccess = useCallback(
    ({ message, details }: Notifyable, data?: any) => {
      if (data) {
        analytics.track({
          event: ANALYTICS.EVENTS.API_SUCCESS,
          data: {
            label: message,
            category: ANALYTICS.CATEGORIES.ACCOUNT_SETTINGS,
            component: ANALYTICS.LABELS.PAYMENT_INFORMATION,
          },
        });
        setPaymentInfo(data?.payment_information?.default_source);
      }
      setNotificationData({
        message,
        persist: false,
        details,
        source: ANALYTICS.LABELS.PAYMENT_INFORMATION,
      });
    },
    [setNotificationData],
  );

  const onError = useCallback(
    ({
      message,
      details,
      error,
      context = {},
    }: Notifyable & { error: Error; context?: any }) => {
      analytics.track({
        event: ANALYTICS.EVENTS.API_FAILURE,
        data: {
          label: 'Update Failed',
          category: ANALYTICS.CATEGORIES.ACCOUNT_SETTINGS,
          component: ANALYTICS.LABELS.PAYMENT_INFORMATION,
        },
      });

      setNotificationData({
        message,
        persist: false,
        details,
        type: ERROR_NOTIFICATION,
        source: ANALYTICS.LABELS.PAYMENT_INFORMATION,
      });

      logError(`${error.name}: ${error.message}`, context);
    },
    [setNotificationData],
  );

  const handleEditPaymentInfoClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.ACCOUNT_SETTINGS.PAYMENT.EDIT,
        category: ANALYTICS.CATEGORIES.ACCOUNT_SETTINGS,
        component: ANALYTICS.LABELS.PAYMENT_INFORMATION,
      },
    });
    setOpenModal(true);
  };

  useEffect(() => {
    fetchPaymentData();
  }, [fetchPaymentData]);

  return (
    <S.Container data-test="payment-info-settings">
      <S.Title>{DEFAULT_CONTENT.title}</S.Title>
      <S.PaymentSection>
        <S.PaymentInfo>
          {isLoading ? (
            <S.SkeletonContainer>
              <S.SkeletonWrapper>
                <SkeletonLoader
                  height={{
                    value: 40,
                    unit: SkeletonUnit.Pixel,
                  }}
                  width={{
                    value: 100,
                    unit: SkeletonUnit.Percentage,
                  }}
                  duration={1}
                  shape={SkeletonShape.Rectangle}
                />
              </S.SkeletonWrapper>
            </S.SkeletonContainer>
          ) : (
            <>
              <S.CardImage
                src={
                  creditCardsIcons[convertCardBrandToKey(paymentInfo?.brand)]
                }
                alt="card-icon"
              />
              <S.CreditCardText>
                {DEFAULT_CONTENT.paymentInfo.creditCardText}
                <S.CreditCardNumber>
                  {paymentInfo?.last4 ||
                    DEFAULT_CONTENT.paymentInfo.creditCardLastFourDigits}
                </S.CreditCardNumber>
              </S.CreditCardText>
            </>
          )}
        </S.PaymentInfo>
        <S.EditButton data-test="edit-icon-container">
          <FormSummary onEditClick={() => handleEditPaymentInfoClick()} />
        </S.EditButton>
        <UpdatePaymentModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          onSuccess={onSuccess}
          onError={onError}
        />
      </S.PaymentSection>
    </S.Container>
  );
};

export default PaymentInfo;
