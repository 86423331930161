import { ImmutableReduxState } from 'common/utils/types';
import CommonQuestionsSection, {
  Props as SectionHeaderProps,
} from 'components/SectionHeader';
import { connect } from 'react-redux';
import { makeSelectContent } from 'store/selectors';

const CONTENT_KEYS = {
  header: 'COMMON_QUESTIONS_TITLE',
  description: 'COMMON_QUESTIONS_DESCRIPTION',
};

type OwnProps = {
  contentToken: string;
};

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): SectionHeaderProps {
  return makeSelectContent(ownProps.contentToken, CONTENT_KEYS)(state);
}

export default connect(mapStateToProps)(CommonQuestionsSection);
