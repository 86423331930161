import useApi from 'common/hooks/useApi';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import {
  ImmutableReduxState,
  User,
  Notification as NotificationType,
  AccountSettings,
} from 'common/utils/types';
import { FormValuesType } from 'components/Forms';
import SettingsForm from 'pages/OldSettingsPage/SettingsForm';
import { SettingsFormSkeleton } from 'pages/OldSettingsPage/SettingsFormSkeleton';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setNotification, ERROR_NOTIFICATION } from 'store/actions';
import {
  makeSelectAccountSettings,
  makeSelectHasConsumerAttributes,
  makeSelectUserId,
} from 'store/selectors';
import { setUserData } from 'store/user/actions';

type StateProps = {
  hasConsumerAttributes: boolean;
  accountSettings?: AccountSettings;
  userId: string;
};

type DispatchProps = {
  setUserData: (user: User) => void;
  setNotification: (notification: NotificationType) => void;
};

type Props = StateProps & DispatchProps;

export function SettingsFormContainer(props: Props) {
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const { updateAccountSettings } = useApi();
  const { userId, accountSettings, hasConsumerAttributes } = props;

  function handleSubmit(formValues: FormValuesType) {
    setIsSaving(true);
    const submittedFromValues = {
      ...formValues,
      email:
        typeof formValues.email === 'string' && formValues.email.trim() === ''
          ? null
          : formValues.email,
    };
    updateAccountSettings(userId, submittedFromValues as AccountSettings)
      .then((data) => {
        props.setUserData(data);
        analytics.track({
          event: ANALYTICS.EVENTS.API_SUCCESS,
          data: {
            label: 'Update Succeeded',
            component: ANALYTICS.LABELS.BASIC_INFORMATION,
          },
        });
        props.setNotification({
          message: 'Information saved!',
          source: ANALYTICS.LABELS.BASIC_INFORMATION,
        });
        setIsSaving(false);
      })
      .catch((errs) => {
        analytics.track({
          event: ANALYTICS.EVENTS.API_FAILURE,
          data: {
            label: 'Update Failed',
            component: ANALYTICS.LABELS.BASIC_INFORMATION,
          },
        });
        if (errs instanceof Error) {
          props.setNotification({
            message:
              'There was an issue with updating your account settings, please contact Customer Experience.',
            type: ERROR_NOTIFICATION,
            source: ANALYTICS.LABELS.BASIC_INFORMATION,
          });
        } else {
          setErrors(errs);
        }
        setIsSaving(false);
      });
  }

  if (!accountSettings) {
    return <SettingsFormSkeleton />;
  }

  return (
    <SettingsForm
      errors={errors}
      onSubmit={handleSubmit}
      accountSettings={accountSettings}
      isSaving={isSaving}
      hasConsumerAttributes={hasConsumerAttributes}
    />
  );
}

export default connect(
  (state: ImmutableReduxState) => ({
    accountSettings: makeSelectAccountSettings()(state),
    userId: makeSelectUserId()(state),
    hasConsumerAttributes: makeSelectHasConsumerAttributes()(state),
  }),
  {
    setUserData,
    setNotification,
  },
)(SettingsFormContainer);
