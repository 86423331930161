import { Footer } from '@everlywell/leaves';
import logoImage from 'common/images/new-ew-logo@2x.png';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { FOOTER as dataTest } from 'common/utils/constants/dataTest';
import {
  humanaLegalLinks,
  humanaFooterCopy,
} from 'common/utils/footer-content';
import {
  isHumanaCustomer,
  isNonHumanaEnterpriseCustomer,
} from 'common/utils/helpers';
import { handleIterableSubscription } from 'common/utils/iterableSubscribe';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import React from 'react';

import usePoolingCookie from '../../common/hooks/usePoolingCookie/index';
import HealthPlanFooter from './HealthPlanFooter';
import * as S from './styles';

const { Footer: DLSFooter } = require('@everlywell/dls'); // eslint-disable-line

function FooterContainer() {
  usePoolingCookie(200);

  const footerEl = useScrolledIntoViewOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: 'Site Footer',
      },
    });
  });

  const handleOnCLickLink = (label: string) => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_LINK,
      data: {
        sectionHeader: 'Footer',
        label,
      },
    });
  };

  const handleSubscribeSubmit = async (email: string): Promise<void> => {
    if (!isHumanaCustomer()) {
      handleIterableSubscription(email);
    }
  };

  const HumanaFooter = () => (
    <DLSFooter
      onSubscribeSubmit={null}
      onSubscribeChange={null}
      logoImageSource={logoImage}
      dataTest={dataTest}
      socialMediaLinks={null}
      policyLegalLinks={humanaLegalLinks}
      footerNavLinks={null}
      footerInfoLinks={null}
      footerCopy={humanaFooterCopy}
    />
  );

  if (isHumanaCustomer()) {
    return (
      <S.FooterContainer ref={footerEl}>
        <HumanaFooter />
      </S.FooterContainer>
    );
  }

  if (!isHumanaCustomer() && isNonHumanaEnterpriseCustomer()) {
    return (
      <S.FooterContainer ref={footerEl}>
        <HealthPlanFooter />
      </S.FooterContainer>
    );
  }

  return (
    <S.FooterContainer ref={footerEl}>
      <Footer
        onSubscribeSubmit={handleSubscribeSubmit}
        onClickLink={handleOnCLickLink}
        showFDADisclaimer={false}
      />
    </S.FooterContainer>
  );
}

export default FooterContainer;
