import {
  BundleOrderStates,
  BundleType,
  BundleTypeOptions,
  CompleteStoreOrder,
  IntakeContextType,
  StripeStoreOrderComplete,
} from 'components/Intake/types';
import { useState } from 'react';

import { ContextInitializationError } from './errors';
import { completeStripeStoreOrder } from './helpers';

export const defaultIntake: IntakeContextType = {
  bundleType: BundleTypeOptions.single,
  isIntakeComplete: false,
  paymentIntent: '',
  step: 0,
  orderState: BundleOrderStates.NOT_STARTED,
  setIntakeStep: () => {},
  setIntakeComplete: () => {},
  completeStoreOrder: () =>
    Promise.resolve({
      data: null,
      error: new ContextInitializationError(),
    }),
  updateBundleOrderState: () => {},
};

export const useIntake = (): IntakeContextType => {
  const [step, setStep] = useState(defaultIntake.step);
  const [isIntakeComplete, setIsIntakeComplete] = useState(
    defaultIntake.isIntakeComplete,
  );
  const [bundleType] = useState<BundleType>(BundleTypeOptions.single);
  const [orderState, setOrderState] = useState<BundleOrderStates>(
    BundleOrderStates.NOT_STARTED,
  );
  const [paymentIntent, setPaymentIntent] = useState<string>('');

  const setIntakeStep = (_step: number) => {
    setStep(_step);
  };

  const setIntakeComplete = (_isIntakeComplete: boolean) => {
    setIsIntakeComplete(_isIntakeComplete);
  };

  const completeStoreOrder = async () => {
    if (paymentIntent) {
      return { data: { client_secret: paymentIntent }, error: null };
    }

    const orderStatus: CompleteStoreOrder = await completeStripeStoreOrder();
    if (!orderStatus?.error) {
      const { client_secret } = orderStatus?.data as StripeStoreOrderComplete;
      setPaymentIntent(client_secret);
    }
    return orderStatus;
  };

  const updateBundleOrderState = (state: BundleOrderStates) =>
    setOrderState(state);

  return {
    bundleType,
    completeStoreOrder,
    isIntakeComplete,
    orderState,
    paymentIntent,
    step,
    setIntakeStep,
    setIntakeComplete,
    updateBundleOrderState,
  };
};

export default useIntake;
