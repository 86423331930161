import { MARKER_TYPES } from 'common/utils/constants';
import {
  ImmutableReduxState,
  IntensityMarkerContent,
} from 'common/utils/types';
import IntensityMarkerCard from 'components/IntensityMarkerCard';
import { connect } from 'react-redux';
import { makeSelectContent } from 'store/selectors';

const CONTENT_KEYS = {
  markerOverviewHeader: 'MARKER_OVERVIEW_HEADER',
  markerOverview: 'MARKER_OVERVIEW',
  markerOverviewExtendedContent1Header:
    'MARKER_OVERVIEW_EXTENDED_CONTENT_1_HEADER',
  markerOverviewExtendedContent1: 'MARKER_OVERVIEW_EXTENDED_CONTENT_1',
  markerOverviewExtendedContent2Header: `MARKER_OVERVIEW_EXTENDED_CONTENT_2_HEADER`,
  markerOverviewExtendedContent2: `MARKER_OVERVIEW_EXTENDED_CONTENT_2`,
  hiddenSources: 'HIDDEN_SOURCES',
  abnormalReactivity: 'ABNORMAL_REACTIVITY',
};

type StateProps = {
  content: IntensityMarkerContent;
};

type OwnProps = {
  contentToken: string;
  markerType: string;
};

function mapStateToProps(
  state: ImmutableReduxState,
  ownProps: OwnProps,
): StateProps {
  const { contentToken, markerType } = ownProps;
  const content = makeSelectContent(contentToken, CONTENT_KEYS)(state);

  if (markerType === MARKER_TYPES.FOOD_SENSITIVITY) {
    content.markerOverviewExtendedContent1 = content.hiddenSources;
  }
  return {
    // @ts-ignore
    content,
  };
}

export default connect(mapStateToProps)(IntensityMarkerCard);
