import {
  TelehealthAnswerGroup,
  TelehealthCustomModule,
  TelehealthFormData,
  TelehealthInsurancePlan,
} from 'common/apis/telehealthApis';
import { DropdownFieldProps } from 'components/telehealth/FormBuilder/components/DropdownField';
import { random } from 'lodash';

const telehealthCustomModuleBuilderFactory =
  <T = TelehealthCustomModule>({
    mod_type,
    ...props
  }: TelehealthCustomModule) =>
  (overrides?: Partial<T>): TelehealthCustomModule => ({
    ...props,
    id: random(1, 10000).toString(),
    ...overrides,
    mod_type, // make sure the mod_type is not overrode later
  });

const baseTelehealthNameModule: TelehealthCustomModule = {
  id: '1',
  label: 'Name',
  sublabel: null,
  mod_type: 'name',
  options_array: [],
  required: false,
  custom_module_condition: null,
};

export const buildTelehealthNameModule = telehealthCustomModuleBuilderFactory(
  baseTelehealthNameModule,
);

const baseTelehealthReadOnlyModule: TelehealthCustomModule = {
  id: '1',
  label: 'Read Only',
  sublabel: null,
  mod_type: 'read_only',
  options_array: ['<p><span>This is a text only field</span></p>'],
  required: false,
  custom_module_condition: null,
};

export const buildTelehealthReadOnlyModule =
  telehealthCustomModuleBuilderFactory(baseTelehealthReadOnlyModule);

const baseTelehealthPhoneModule: TelehealthCustomModule = {
  id: '1',
  label: 'Phone',
  sublabel: null,
  mod_type: 'phone',
  options_array: [],
  required: false,
  custom_module_condition: null,
};

export const buildTelehealthPhoneModule = telehealthCustomModuleBuilderFactory(
  baseTelehealthPhoneModule,
);

const baseTelehealthDateOfBirthModule: TelehealthCustomModule = {
  id: '1',
  label: 'Date of Birth',
  sublabel: null,
  mod_type: 'dob',
  options_array: [],
  required: false,
  custom_module_condition: null,
};

export const buildTelehealthDateOfBirthModule =
  telehealthCustomModuleBuilderFactory(baseTelehealthDateOfBirthModule);

const baseTelehealthLocationModule: TelehealthCustomModule = {
  id: '1',
  label: 'Address',
  sublabel: null,
  mod_type: 'location',
  options_array: [],
  required: false,
  custom_module_condition: null,
};

export const buildTelehealthLocationModule =
  telehealthCustomModuleBuilderFactory(baseTelehealthLocationModule);

const baseTelehealthDocumentModule: TelehealthCustomModule = {
  id: '1',
  label: 'Document',
  sublabel: null,
  mod_type: 'document',
  options_array: ['1000'], // in documents, the options field contain the ID of the actual document
  required: false,
  custom_module_condition: null,
};

export const buildTelehealthDocumentModule =
  telehealthCustomModuleBuilderFactory(baseTelehealthDocumentModule);

const baseTelehealthAgreeToAboveModule: TelehealthCustomModule = {
  id: '1',
  label: 'Agree to Above',
  sublabel: null,
  mod_type: 'agree_to_above',
  options_array: [],
  required: false,
  custom_module_condition: null,
};

export const buildTelehealthAgreeToAboveModule =
  telehealthCustomModuleBuilderFactory(baseTelehealthAgreeToAboveModule);

const baseTelehealthForm: TelehealthFormData = {
  id: '1',
  name: 'Telehealth Form',
  custom_modules: [
    buildTelehealthReadOnlyModule(),
    buildTelehealthNameModule(),
    buildTelehealthDateOfBirthModule(),
    buildTelehealthPhoneModule(),
    buildTelehealthLocationModule(),
    buildTelehealthDocumentModule(),
    buildTelehealthAgreeToAboveModule(),
  ],
};

export const buildTelehealthForm = (
  overrides?: Partial<TelehealthFormData>,
): TelehealthFormData => ({
  ...baseTelehealthForm,
  ...overrides,
  custom_modules:
    overrides?.custom_modules ?? baseTelehealthForm.custom_modules,
});

const baseInsurancePlan: TelehealthInsurancePlan = {
  id: '1',
  is_accepted: true,
  payer_id: '1',
  payer_name: 'Plan 1',
  name_and_id: 'Plan 1 - 1',
};

export const buildTelehealthInsurancePlan = (
  overrides?: Partial<TelehealthInsurancePlan>,
): TelehealthInsurancePlan => {
  const id = random(1, 10000).toString();
  return {
    ...baseInsurancePlan,
    id,
    payer_id: id,
    payer_name: `Plan ${id}`,
    name_and_id: `Plan ${id} - ${id}`,
    ...overrides,
  };
};

const telehealthDropdownModule: TelehealthCustomModule = {
  id: '1',
  label: 'Currently located in?',
  sublabel: null,
  mod_type: 'dropdown',
  options_array: ['California', 'Texas'],
  required: false,
  custom_module_condition: null,
};

export const buildTelehealthDropdownModule =
  telehealthCustomModuleBuilderFactory<DropdownFieldProps>(
    telehealthDropdownModule,
  );

const baseAnswerGroup: TelehealthAnswerGroup = {
  id: '1',
  finished: false,
  locked_at: null,
};

export const buildTelehealthAnswerGroup = (
  overrides?: Partial<TelehealthAnswerGroup>,
): TelehealthAnswerGroup => ({
  ...baseAnswerGroup,
  id: random(1, 10000).toString(),
  ...overrides,
});

const baseRadioFieldGroup: TelehealthCustomModule = {
  id: '1',
  label: 'Sex Assigned at Birth',
  sublabel: null,
  mod_type: 'radio',
  options_array: ['Female', 'Male'],
  required: true,
  custom_module_condition: null,
};

export const buildTelehealthRadioFieldGroup =
  telehealthCustomModuleBuilderFactory(baseRadioFieldGroup);

export const buildTelehealthHorizontalRadioField =
  telehealthCustomModuleBuilderFactory({
    ...baseRadioFieldGroup,
    label: `${baseRadioFieldGroup.label} (Horizontal)`,
    mod_type: 'horizontal_radio',
  });

const baseHeightField: TelehealthCustomModule = {
  id: '1',
  label: 'Height (in.)',
  sublabel: null,
  mod_type: 'Height (in.)',
  options_array: [],
  required: true,
  custom_module_condition: null,
};

export const buildTelehealthHeightField =
  telehealthCustomModuleBuilderFactory(baseHeightField);

const baseCheckboxField: TelehealthCustomModule = {
  id: '1',
  label: 'Favorite Color',
  sublabel: null,
  mod_type: 'checkbox',
  options_array: ['Red', 'Green', 'Blue'],
  required: true,
  custom_module_condition: null,
};

export const buildTelehealthCheckboxField =
  telehealthCustomModuleBuilderFactory(baseCheckboxField);

const baseTelehealthWeightField: TelehealthCustomModule = {
  id: '1',
  label: 'Weight',
  sublabel: null,
  mod_type: 'Weight',
  options_array: [],
  required: true,
  custom_module_condition: null,
};

export const buildTelehealthWeightField = telehealthCustomModuleBuilderFactory(
  baseTelehealthWeightField,
);

const baseTelehealthTextAreaField: TelehealthCustomModule = {
  id: '1',
  label: 'Text Area',
  sublabel: null,
  mod_type: 'textarea',
  options_array: [],
  required: true,
  custom_module_condition: null,
};

export const buildTelehealthTextAreaField =
  telehealthCustomModuleBuilderFactory(baseTelehealthTextAreaField);

const baseTelehealthTextField: TelehealthCustomModule = {
  id: '1',
  label: 'Text',
  sublabel: null,
  mod_type: 'text',
  options_array: [],
  required: true,
  custom_module_condition: null,
};

export const buildTelehealthTextField = telehealthCustomModuleBuilderFactory(
  baseTelehealthTextField,
);

const baseTelehealthDateField: TelehealthCustomModule = {
  id: '1',
  label: 'Date',
  sublabel: null,
  mod_type: 'date',
  options_array: [],
  required: true,
  custom_module_condition: null,
};

export const buildTelehealthDateField = telehealthCustomModuleBuilderFactory(
  baseTelehealthDateField,
);

const baseTelehealthBMIField: TelehealthCustomModule = {
  id: '1',
  label: 'BMI(in.)',
  sublabel: null,
  mod_type: 'BMI(in.)',
  options_array: [],
  required: true,
  custom_module_condition: null,
};

export const buildTelehealthBMIField = telehealthCustomModuleBuilderFactory(
  baseTelehealthBMIField,
);

const baseTelehealthPulseField: TelehealthCustomModule = {
  id: '1',
  label: 'Pulse',
  sublabel: null,
  mod_type: 'pulse',
  options_array: [],
  required: true,
  custom_module_condition: null,
};

export const buildTelehealthPulseField = telehealthCustomModuleBuilderFactory(
  baseTelehealthPulseField,
);

const baseTelehealthLabelModule: TelehealthCustomModule = {
  id: '1',
  label: 'Label Field',
  sublabel: null,
  mod_type: 'label',
  options_array: [],
  required: false,
  custom_module_condition: null,
};

export const buildTelehealthLabelModule = telehealthCustomModuleBuilderFactory(
  baseTelehealthLabelModule,
);

const baseTelehealthWaistCircumferenceField: TelehealthCustomModule = {
  id: '1',
  label: 'Waist Circumference',
  sublabel: null,
  mod_type: 'waist_circumference',
  options_array: [],
  required: false,
  custom_module_condition: null,
};

export const buildTelehealthWaistCircumferenceField =
  telehealthCustomModuleBuilderFactory(baseTelehealthWaistCircumferenceField);

const baseTelehealthBloodPressureField: TelehealthCustomModule = {
  id: '1',
  label: 'Blood Pressure',
  sublabel: null,
  mod_type: 'blood_pressure',
  options_array: [],
  required: false,
  custom_module_condition: null,
};

export const buildTelehealthBloodPressureField =
  telehealthCustomModuleBuilderFactory(baseTelehealthBloodPressureField);

type SnomedFieldCustomModule = Omit<TelehealthCustomModule, 'options_array'> & {
  options_array: Array<string[]>;
};

const baseTelehealthSnomedField: SnomedFieldCustomModule = {
  id: '1',
  label: 'Please select an option:',
  sublabel: null,
  mod_type: 'snomed_radio',
  options_array: [
    ['Yes', 'Yes - 169449001'],
    ['No', 'No - 169449001'],
  ],
  required: false,
  custom_module_condition: null,
};

export const buildTelehealthSnomedField = (
  overrides?: Partial<SnomedFieldCustomModule>,
) => ({
  ...baseTelehealthSnomedField,
  id: random(1, 10000).toString(),
  ...overrides,
});
