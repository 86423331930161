import { colors, mediaQueries, typography } from '@everlywell/leaves';
import { bodyCopy, baseFont } from 'common/styles/app-styles';
import styled from 'styled-components';

import chevronIcon from './images/chevron.svg';

type ChevronProps = {
  isActive?: boolean;
  hideChevronOnMobile?: boolean;
};

type NameProps = {
  afterText?: string;
  hideTextOnMobile?: boolean;
  textTransform: string;
};

type IconProps = {
  iconSrc?: string;
};

const Icon = styled.span<IconProps>`
  margin-right: 8px;
  display: flex;
  align-items: center;
  height: 16px;
  width: 16px;
  mask: url('${(props) => props.iconSrc}');
  background: ${colors.green4};
`;

const Chevron = styled.span<ChevronProps>`
  width: 11px;
  height: 11px;
  margin: 0 5px;
  mask: url('${chevronIcon}');
  background: ${colors.green4};
  display: ${(props) => (props.hideChevronOnMobile ? 'none' : 'inline-block')};
  transform: ${(props) => (props.isActive ? 'rotate(180deg)' : '')};

  ${mediaQueries.forTabletVerticalUp} {
    display: inline-block;
  }
`;

const Name = styled.span<NameProps>`
  ${bodyCopy};
  font-family: ${baseFont};
  font-weight: ${typography.weight.bold};
  text-transform: ${(props) => props.textTransform};
  display: ${(props) => (props.hideTextOnMobile ? 'none' : 'inline-block')};

  ${mediaQueries.forTabletVerticalUp} {
    display: inline-block;
  }

  ${mediaQueries.forDesktopUp} {
    &:after {
      text-transform: none;
      content: '${(props) => (props.afterText ? props.afterText : null)}';
    }
  }
`;

const Wrapper = styled.button`
  align-items: center;
  color: ${colors.green4};
  cursor: pointer;
  display: flex;
  margin-left: 24px;
  padding: 0;

  &:hover {
    color: ${colors.green3};
    text-decoration: none;
    ${Icon} {
      background: ${colors.green3};
    }
    ${Chevron} {
      background: ${colors.green3};
    }
  }

  &:focus,
  &:active {
    color: ${colors.green3};
    ${Icon} {
      background: ${colors.green3};
    }
    ${Chevron} {
      background: ${colors.green3};
    }
  }
`;

export { Wrapper, Icon, Chevron, Name };
