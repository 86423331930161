import NewLayoutSectionHeader, {
  Props as NewLayoutSectionHeaderProps,
} from 'components/NewLayoutSectionHeader';
import { isNil } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { makeSelectContent, makeSelectLoadingContent } from 'store/selectors';

const CONTENT_KEYS = {
  header: 'RESULTS_SECTION_HEADER',
  description: 'RESULTS_SECTION_DESCRIPTION',
};

type OwnProps = {
  contentToken: string;
  noTopBorder?: boolean;

  // Content to show when the content snippets are missing
  defaultContent?: {
    header: string;
    description: string;
  };
  // Callback used when the header or the description are missing
  onMissingContent?: () => void;

  // From redux
  loadingContent?: boolean;
};

export type ResultSectionHeaderContainerProps = Omit<
  NewLayoutSectionHeaderProps,
  'header' | 'description'
> &
  OwnProps;

export function ResultSectionHeaderContainer({
  onMissingContent,
  defaultContent,
  ...props
}: ResultSectionHeaderContainerProps) {
  const wasMissingContentNotified = useRef(false);

  const content = useSelector(
    makeSelectContent(props.contentToken, CONTENT_KEYS),
  );
  const loadingContent = useSelector(makeSelectLoadingContent());

  const header = content?.header || defaultContent?.header;
  const description = content?.description || defaultContent?.description;

  useEffect(() => {
    const hasMissingContent = !header || !description;
    const shouldNotify =
      onMissingContent &&
      !loadingContent &&
      hasMissingContent &&
      !wasMissingContentNotified.current;

    if (shouldNotify) {
      onMissingContent!();

      // it guaranties this is called only once
      wasMissingContentNotified.current = true;
    }
  }, [
    loadingContent,
    header,
    description,
    props.contentToken,
    onMissingContent,
  ]);

  if (isNil(header) || isNil(description)) {
    return null;
  }

  return (
    <NewLayoutSectionHeader
      {...props}
      header={header!}
      description={description!}
    />
  );
}

export default ResultSectionHeaderContainer;
