import { colors, typography } from '@everlywell/leaves';
import styled from 'styled-components';

import { breakpoints } from '../breakpoints';
import { SIDataMarkerTypeTitle } from '../data/model';

type TitleProps = {
  title: SIDataMarkerTypeTitle;
};

const Title = styled.p<TitleProps>`
  width: ${({ title: { width } }) => width && width.l};
  height: ${({ title: { height } }) => height && height.l};
  overflow-y: auto;
  left: ${({ title: { position } }) => (position && position.x) || 0}px;
  top: ${({ title: { position } }) => (position && position.y) || 0}px;
  font-weight: ${typography.weight.regular};
  position: absolute;
  color: ${colors.gray4};
  ${({ title: { alignment } }) =>
    alignment && alignment.l && `text-align: ${alignment.l}`};
  ${({ title: { fontSize } }) =>
    fontSize && fontSize.l && `font-size: ${fontSize.l}`};

  ${breakpoints.m} {
    ${({ title: { position } }) =>
      position && position.xm && `left: ${position.xm}px`};
    ${({ title: { position } }) =>
      position && position.ym && `top: ${position.ym}px`};
    ${({ title: { alignment } }) =>
      alignment && alignment.m && `text-align: ${alignment.m}`};
    ${({ title: { width } }) => width && width.m && `width: ${width.m}`};
    ${({ title: { height } }) => height && height.m && `height: ${height.m}`};
    ${({ title: { fontSize } }) =>
      fontSize && fontSize.m && `font-size: ${fontSize.m}`};
  }

  ${breakpoints.s} {
    ${({ title: { position } }) =>
      position && position.xs && `left: ${position.xs}px`};
    ${({ title: { position } }) =>
      position && position.ys && `top: ${position.ys}px`};
    ${({ title: { alignment } }) =>
      alignment && alignment.s && `text-align: ${alignment.s}`};
    ${({ title: { width } }) => width && width.s && `width: ${width.s}`};
    ${({ title: { height } }) => height && height.s && `height: ${height.s}`};
    ${({ title: { fontSize } }) =>
      fontSize && fontSize.s && `font-size: ${fontSize.s}`};
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;

  canvas {
    width: 100%;
    height: 100%;
  }
`;

export { Title, Wrapper };
