import { breakpoints, size } from '@everlywell/leaves';
import VisuallyHidden from 'components/VisuallyHidden';
import { uniqueId } from 'lodash';
import React from 'react';
import { useWindowSize } from 'react-use';

import AppointmentList from './components/AppointmentList';
import * as S from './TelehealthAppointments.styles';

export type TelehealthAppointmentsProps = {
  currentTab: AppointmentType;
  handleSelectTab: (index: AppointmentType) => void;
};

enum AppointmentType {
  Upcoming,
  Completed,
}

/**
 * This component renders the users telehealth appointments, both
 * upcoming and completed
 */
function TelehealthAppointments({
  currentTab,
  handleSelectTab,
}: TelehealthAppointmentsProps) {
  const { width } = useWindowSize();
  const isMobile = width <= breakpoints.forPhoneOnly;

  const upcomingRegionAria = uniqueId('upcoming-region-');
  const completedRegionAria = uniqueId('completed-region-');

  return (
    <S.Container>
      {isMobile ? (
        <>
          <div>
            <S.CustomTabMenu
              onItemSelect={handleSelectTab}
              initialActiveIndex={currentTab}
              ariaControls="list"
              id="listFilter"
              ariaLabel="Item Filter"
              key={width}
            >
              <S.CustomTab key={AppointmentType.Upcoming}>
                Upcoming <VisuallyHidden>appointments</VisuallyHidden>
              </S.CustomTab>
              <S.CustomTab key={AppointmentType.Completed}>
                Past <VisuallyHidden>appointments</VisuallyHidden>
              </S.CustomTab>
            </S.CustomTabMenu>
            <div css={{ marginTop: `${size.lg}px` }}>
              <AppointmentList
                appointmentType={
                  currentTab === AppointmentType.Upcoming
                    ? 'upcoming'
                    : 'completed'
                }
                isExpandable={currentTab === AppointmentType.Completed}
                label={
                  currentTab === AppointmentType.Upcoming
                    ? 'Upcoming appointments'
                    : 'Past appointments'
                }
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <section aria-labelledby={upcomingRegionAria}>
            {/* Upcoming appointments list */}
            <S.SectionTitle id={upcomingRegionAria}>
              Upcoming appointments
            </S.SectionTitle>
            <AppointmentList
              appointmentType={'upcoming'}
              labelledBy={upcomingRegionAria}
            />
          </section>

          <section aria-labelledby={completedRegionAria}>
            {/* Past appointments list */}
            <S.SectionTitle
              id={completedRegionAria}
              css={{ paddingTop: `${size.xl4}px` }}
            >
              Past appointments
            </S.SectionTitle>
            <AppointmentList
              appointmentType={'completed'}
              labelledBy={completedRegionAria}
              isExpandable
            />
          </section>
        </>
      )}
    </S.Container>
  );
}

export default TelehealthAppointments;
