import {
  kitResult as fsKitResult,
  fsTest,
} from 'common/fixtures/food_sensitivity';
import { kitResult as numericalKitResult } from 'common/fixtures/numerical';
import { kitResult as descriptiveKitResult } from 'common/fixtures/std_female';
import { kitResult as thyroidKitResult } from 'common/fixtures/thyroid';
import useApi from 'common/hooks/useApi';
import {
  FS_SEVERITIES,
  NUMERICAL_SEVERITIES,
  DESCRIPTIVE_SEVERITIES,
} from 'common/utils/constants';
import {
  KIT_RESULT_DETAIL,
  WHATS_NEXT_SECTION,
  RESULT_DETAILS as DATA_TEST,
} from 'common/utils/constants/dataTest';
import { findByTestAttr, renderPageContainer } from 'common/utils/testHelpers';
import SavePdfButton from 'pages/KitResultDetailPage/SavePdfButton';
import { shallow } from 'enzyme';
import React from 'react';
import 'common/testUtils/mocks/msw/server';
import { mockAllIsIntersecting } from 'react-intersection-observer/test-utils';
import { QueryClient, QueryClientProvider } from 'react-query';
import { screen } from 'common/utils/reactTestingLibraryHelpers';

import KitResultDetailPage from '..';

const testProps = {
  contentToken: '1234',
  kitResultIdOrPublishHash: '1234',
  testId: 3,
  viewingSharedKitResult: false,
  session: {
    userId: '1',
    token: 'test',
  },
  loadingContent: false,
  userId: '1',
  getKitResult: jest.fn(),
  getKitResultContent: jest.fn(),
  setUserData: jest.fn(),
};

const renderComponent = (props: any) =>
  shallow(<KitResultDetailPage {...testProps} {...props} />, {
    wrappingComponent: QueryClientProvider,
    wrappingComponentProps: { client: new QueryClient() },
  });

const getPersonalizationsMock = jest.fn();
jest.mock('common/hooks/useApi');

describe('<KitResultDetailPage />', () => {
  beforeEach(() => {
    (useApi as jest.Mock).mockReturnValue({
      getPersonalizations: getPersonalizationsMock,
    });
    mockAllIsIntersecting(true);
  });

  describe('when viewing a Food Sensitivity test', () => {
    it('should render the FoodSensitivityResultDetail component', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
      });

      expect(
        renderedComponent.find('FoodSensitivityResultDetail'),
      ).toHaveLength(1);
    });
  });

  describe('when viewing a numerical test', () => {
    it('should render the NumericalResultsDetail component', () => {
      const renderedComponent = renderComponent({
        kitResult: {
          ...numericalKitResult,
          test_id: 47,
        },
        testId: 47,
      });

      expect(
        renderedComponent.find('Connect(NumericalResultDetail)'),
      ).toHaveLength(1);
    });
  });

  describe('when viewing a descriptive test', () => {
    it('should render the DescriptiveResultDetail component', () => {
      const renderedComponent = renderComponent({
        kitResult: {
          ...descriptiveKitResult,
          test_id: 55,
        },
        testId: 55,
      });

      expect(
        renderedComponent.find('Connect(DescriptiveResultDetail)'),
      ).toHaveLength(1);
    });
  });

  describe('when viewing a shared result', () => {
    it('should render the shared result information banner', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
        viewingSharedKitResult: true,
      });

      expect(
        findByTestAttr(renderedComponent, KIT_RESULT_DETAIL.SHARED_RESULT_INFO),
      ).toHaveLength(1);
    });

    it('should not render the product upsell component', () => {
      const renderedComponent = renderComponent({
        viewingSharedKitResult: true,
        kitResult: {
          ...fsKitResult,
        },
      });

      expect(
        findByTestAttr(
          renderedComponent,
          WHATS_NEXT_SECTION.PRODUCT_UPSELL_BUTTON,
        ),
      ).toHaveLength(0);
    });

    it('should not render the cobranded ancestry banner for ancestry tests', () => {
      const renderedComponent = renderComponent({
        kitResult: {
          ...numericalKitResult,
          test_id: 86,
        },
        viewingSharedKitResult: true,
        testId: 86,
      });

      expect(
        findByTestAttr(renderedComponent, 'cobranded-ancestry-banner'),
      ).toHaveLength(0);
    });

    it('should not render the sub header', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
        viewingSharedKitResult: true,
      });

      expect(findByTestAttr(renderedComponent, 'sub-header')).toHaveLength(0);
    });

    it('should not render TakeAction', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
        viewingSharedKitResult: true,
      });

      expect(renderedComponent.find('Connect(TakeActionSection)')).toHaveLength(
        0,
      );
    });

    it('should not load personalizations', async () => {
      const options = {
        propsOverride: {
          kitResult: fsKitResult,
          viewingSharedKitResult: true,
        },
      };
      renderPageContainer(KitResultDetailPage, options);
      expect(getPersonalizationsMock).not.toHaveBeenCalled();
    });
  });

  describe('when viewing a non-shared result', () => {
    it('should not render the shared result information banner', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
        viewingSharedKitResult: false,
      });

      expect(
        findByTestAttr(renderedComponent, KIT_RESULT_DETAIL.SHARED_RESULT_INFO),
      ).toHaveLength(0);
    });

    it('should render the cobranded ancestry banner for ancestry tests', () => {
      const renderedComponent = renderComponent({
        kitResult: {
          ...numericalKitResult,
          test_id: 86,
        },
        testId: 86,
      });

      expect(
        findByTestAttr(renderedComponent, 'cobranded-ancestry-banner'),
      ).toHaveLength(1);
    });

    it('should not render the cobranded ancestry banner for non-ancestry tests', () => {
      const renderedComponent = renderComponent({
        kitResult: {
          ...fsKitResult,
          test_id: 83,
        },
        testId: 83,
      });

      expect(
        findByTestAttr(renderedComponent, 'cobranded-ancestry-banner'),
      ).toHaveLength(0);
    });

    it('should not render TakeAction when showConsult is false', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
        viewingSharedKitResult: false,
        showConsult: false,
      });
      expect(renderedComponent.find('Connect(TakeActionSection)')).toHaveLength(
        0,
      );
    });

    it('should render TakeAction to show Live Webinar', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
        viewingSharedKitResult: false,
        showConsult: true,
      }).find('Connect(TakeActionSection)');

      expect(renderedComponent.prop('showConsult')).toEqual(true);
      expect(renderedComponent.prop('showLiveWebinar')).toEqual(true);
    });

    it('should render TakeAction to show only Live Webinar when Consult is not available', () => {
      const renderedComponent = renderComponent({
        kitResult: {
          ...fsKitResult,
          consult: {
            available: false,
            available_until: '2050-07-31T14:34:59Z',
          },
        },
        viewingSharedKitResult: false,
        showConsult: true,
      }).find('Connect(TakeActionSection)');

      expect(renderedComponent.prop('showConsult')).toEqual(false);
      expect(renderedComponent.prop('showLiveWebinar')).toEqual(true);
    });

    it('should load personalizations', async () => {
      const options = {
        propsOverride: {
          kitResult: fsKitResult,
          viewingSharedKitResult: false,
        },
      };
      renderPageContainer(KitResultDetailPage, options);
      expect(getPersonalizationsMock).toHaveBeenCalled();
    });
  });

  it('should not render when there are no kitResult', () => {
    const renderedComponent = renderComponent({
      kitResult: undefined,
    });

    expect(renderedComponent.isEmptyRender()).toBe(true);
  });

  describe('when telehealth consult is available', () => {
    it('displays the telehealth consult link', () => {
      const kit = {
        status: 'results_approved',
        test: 41,
        lab: 2345,
        markers: [16],
        consult: {
          available_until: new Date('2050-01-01').toISOString(),
        },
        marker_results: [3611189],
      };

      const options = {
        propsOverride: {
          ...testProps,
          showConsult: true,
          kitResult: { ...thyroidKitResult },
          testId: 41,
        },
        stateOverride: {
          app: { loadingContent: false },
          entities: {
            kits: {
              K123: kit,
            },
            tests: {
              41: thyroidKitResult.test,
            },
            content_snippets: {
              '1234': {},
              '944cfa6f-c7a5-4d89-a576-392a3741e7d8': {},
            },
          },
        },
      };

      renderPageContainer(KitResultDetailPage, options);

      expect(
        screen.queryByRole('button', { name: /Telehealth/ }),
      ).toBeDefined();
    });
  });

  describe('when rendering summary text for FS', () => {
    it('renders the ResultsIntro component with the correct groupCount', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
      });

      expect(
        renderedComponent.find('Connect(ResultsIntro)').prop('groupCount'),
      ).toStrictEqual({
        [FS_SEVERITIES.LOW]: 1,
        [FS_SEVERITIES.MILD]: 1,
        [FS_SEVERITIES.MODERATE]: 1,
        [FS_SEVERITIES.HIGH]: 1,
      });
    });
  });

  describe('when rendering a numerical test', () => {
    it('renders the ResultsIntro component with the correct groupCount', () => {
      const renderedComponent = renderComponent({
        kitResult: {
          ...numericalKitResult,
          test_id: 47,
        },
        testId: 47,
      });

      expect(
        renderedComponent.find('Connect(ResultsIntro)').prop('groupCount'),
      ).toStrictEqual({
        [NUMERICAL_SEVERITIES.NORMAL]: 1,
        [NUMERICAL_SEVERITIES.LOW]: 1,
        [NUMERICAL_SEVERITIES.HIGH]: 1,
      });
    });
  });

  describe('when viewing a descriptive test', () => {
    it('renders the ResultsIntro component with the correct groupCount', () => {
      const renderedComponent = renderComponent({
        kitResult: {
          ...descriptiveKitResult,
          test_id: 55,
        },
        testId: 55,
      });

      expect(
        renderedComponent.find('Connect(ResultsIntro)').prop('groupCount'),
      ).toStrictEqual({
        [DESCRIPTIVE_SEVERITIES.NOT_DETECTED]: 3,
        [DESCRIPTIVE_SEVERITIES.EQUIVOCAL_OR_INDETERMINATE]: 1,
        [DESCRIPTIVE_SEVERITIES.DETECTED]: 2,
        [DESCRIPTIVE_SEVERITIES.INVALID_OR_REJECTED]: 1,
      });
    });
  });

  describe('should render the SavePdfButton', () => {
    const kitResult = {
      ...fsKitResult,
      id: 112233,
      needs_replacement: false,
      number: 'KRDB99CD65BB5643438B506AF7830D60',
      tracking_url: 'www.ups.com',
      product_slug: 'food-sensitivity',
      clia: '39D0673919',
      serial_number: 65549690,
    };
    it('renders the SavePdfButton', () => {
      const mockKitResult = { ...kitResult, test: { ...fsTest } };
      const renderedComponent = renderComponent({
        kitResult: mockKitResult,
      });

      expect(
        renderedComponent.containsMatchingElement(
          <SavePdfButton
            kitResult={mockKitResult}
            key={DATA_TEST.SAVE_PDF_BUTTON}
          />,
        ),
      ).toBeTruthy();
    });

    it('Does not renders the SavePdfButton when viewingSharedKitResult is true', () => {
      const mockKitResult = { ...kitResult, test: { ...fsTest } };
      const renderedComponent = renderComponent({
        kitResult: mockKitResult,
        viewingSharedKitResult: true,
      });

      expect(
        renderedComponent.containsMatchingElement(
          <SavePdfButton
            kitResult={mockKitResult}
            key={DATA_TEST.SAVE_PDF_BUTTON}
          />,
        ),
      ).toBeFalsy();
    });
  });
});
