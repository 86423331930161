import { size, colors, mediaQueries, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const TasksWrapper = styled.div`
  display: block;
  align-items: center;
  background-color: ${colors.teal1};
  border-radius: ${size.xs1}px;
  margin-bottom: ${size.sm}px;
  padding: ${size.lg}px ${size.md}px;

  &:last-child {
    margin-bottom: 0;
  }

  ${mediaQueries.forTabletVerticalUp} {
    display: flex;
    padding: ${size.md}px ${size.lg}px ${size.md}px ${size.md}px;
  }
`;

export const Title = styled.div`
  ${typography.buttonText}
  color: ${colors.teal5};
  font-weight: ${typography.weight.book};
`;

export const TaskImage = styled.img`
  width: 54px;
  border-radius: 1000px;
`;

export const TaskImageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${size.md}px;
`;

export const TaskTitleCTAWrapper = styled.div`
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;

  a {
    width: 100%;
  }

  ${mediaQueries.forTabletVerticalUp} {
    justify-content: space-between;
    padding-left: ${size.sm}px;

    a {
      width: auto;
    }
  }
`;
