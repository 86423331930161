/**
 * Header
 */
import {
  Header as LeavesHeader,
  EnterpriseHeader,
  HeaderLinks,
  hardCodedHeaderContent,
  NevMenuTelehealthCategory,
  colors,
} from '@everlywell/leaves';
import { getUser } from 'common/apis/userApis';
import logoImage from 'common/images/everlywell-logo.svg';
import { withRouter } from 'common/polyfill/router';
import analytics from 'common/utils/analytics';
import {
  PUBLIC_APP_ROOT,
  THIRD_PARTY_APP_ROOT,
  MY_APP_ROOT,
  LEGACY_APP_ROOT,
  WWW_APP_ROOT,
} from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { HEADER } from 'common/utils/constants/dataTest';
import { MEMBER_SUPPORT_URL } from 'common/utils/constants/urls';
import {
  hasEverlywellPlusNavMenu,
  hasWeightCarePromo,
  hasgiftGuideNavbarNavMenu,
} from 'common/utils/flags';
import {
  desktopResultsNavItems,
  mobileResultsNavItems,
  shopNavLink,
  unauthenticatedDesktopResultsNavItems,
  unauthenticatedMobileResultsNavItems,
} from 'common/utils/headerMenuItems';
import shopItems from 'common/utils/headerShopContent.json';
import {
  isHumanaCustomer,
  isNonHumanaEnterpriseCustomer,
  isEnterpriseCustomer,
  clearUserLocalStorageAndSession,
} from 'common/utils/helpers';
import { MembershipStatus } from 'common/utils/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useState, useRef, useCallback } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { makeSelectSession } from 'store/selectors';

import iconLogoImage from './images/mark-green@3x.png';
import * as S from './styles';
import { useDocumentScrollThrottled } from './useDocumentScrollThrottled';

const { Header: OldHeader } = require('@everlywell/dls');

const DLSHeaderWithRouter: any = withRouter(OldHeader);

type Props = {
  subHeader?: boolean | React.ReactNode;
  authenticated: boolean;
  userId?: number;
  activeMembership?: 'control' | 'current';
  numOfMemberCredits?: number;
  membershipState?: MembershipStatus;
};

const { topLevelLinks } = HeaderLinks;

export const THROTTLE = 200;

const accountLinks = {
  hub: {
    label: 'My Everly',
    url: `${MY_APP_ROOT}/dashboard`,
  },
  results: {
    label: 'Test Results',
    url: `${MY_APP_ROOT}/dashboard`,
  },

  logout: {
    label: 'Log Out',
    url: `${LEGACY_APP_ROOT}/logout`,
  },
  membership: {
    label: 'My Membership',
  },
};

const enterpriseAccountLinks = {
  label: 'Account',
  results: {
    label: 'Test Results',
    url: `${MY_APP_ROOT}/dashboard`,
  },
  settings: {
    label: 'Settings',
    url: `${MY_APP_ROOT}/account/settings`,
  },
  logout: {
    label: 'Log Out',
    url: `${LEGACY_APP_ROOT}/logout`,
  },
  membership: {
    label: 'My Membership',
  },
};

const WEIGHT_CARE_PAGE_SLUG = 'glp-1-weight-loss-online';
const CONTROL_MEMBERSHIP_PAGE_SLUG = 'everlywell-plus';

const asyncFlows = [
  '/bacterial-vaginosis-treatment-online',
  '/yeast-infection-treatment-online',
  '/genital-herpes-treatment-online',
  '/cold-sores-treatment-online',
];

const buildHeaderContent = (flags: { showAsyncFlowHeader: boolean }) => {
  const { showAsyncFlowHeader } = flags;
  const newTelehealthCategories =
    hardCodedHeaderContent.telehealthCategories.map(
      (category: NevMenuTelehealthCategory) => {
        const items = category.items.map((item) => {
          if (item.url.includes(CONTROL_MEMBERSHIP_PAGE_SLUG)) {
            item.isEnabled = hasEverlywellPlusNavMenu();
          }

          if (
            showAsyncFlowHeader &&
            asyncFlows.some((asyncFlowUrl) => asyncFlowUrl === item.url)
          ) {
            item.isEnabled = true;
          }

          if (item.url.includes(WEIGHT_CARE_PAGE_SLUG)) {
            return {
              ...item,
              badge: {
                ...item.badge,
                text: 'Sale',
                backgroundColor: colors.red2,
              },
            };
          }

          return item;
        });

        return {
          ...category,
          items,
        };
      },
    );

  return {
    ...hardCodedHeaderContent,
    telehealthCategories: newTelehealthCategories,
  };
};

function Header(props: Props) {
  const {
    subHeader,
    authenticated,
    activeMembership,
    numOfMemberCredits,
    membershipState,
  } = props;
  const [shouldHide, setShouldHide] = useState(false);
  const [shouldShowShadow, setShouldShowShadow] = useState(false);
  const [isMenuItemOpen, setIsMenuItemOpen] = useState(false);
  const headerContainerRef = useRef<HTMLHeadElement>(null);
  const hasEverlywellPlusNavMenuActive = hasEverlywellPlusNavMenu();
  const hasWeightCarePlusPromo = hasWeightCarePromo();
  const flags = useFlags<{
    showAsyncFlowHeader: boolean;
  }>();

  const { userId } = useSelector(makeSelectSession());

  const { data: response } = useQuery(
    ['user', userId],
    () => getUser(userId!),
    {
      enabled: Boolean(userId),
    },
  );

  const user = response?.data || undefined;

  const trackMixpanelEvents = (eventTitle: string) => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: eventTitle,
      },
    });
  };

  const healthPlanProps = (): Object => {
    if (!isNonHumanaEnterpriseCustomer()) return {};

    /**
     * Displays only logo, support, login and logout links on navbar
     * if current user comes from a health plan login
     */
    return {
      root: `${WWW_APP_ROOT}/members`,
      topLevelLinks: {
        support: {
          label: 'Support',
          url: MEMBER_SUPPORT_URL,
          mobileSubcategoryLabel: 'Help',
        },
        login: { label: 'Login', url: `${MY_APP_ROOT}` },
        logout: { label: 'Log Out', url: `${MY_APP_ROOT}/logout/` },
      },
    };
  };

  let baseTopLevelLinks = {
    ...topLevelLinks(WWW_APP_ROOT),
    register: {
      label: 'Register kit',
      url: '/register',
    },
  };

  const everlywellPlus = hasEverlywellPlusNavMenu() && {
    everlywellPlus: {
      label: 'Everlywell+',
      url: `${WWW_APP_ROOT}/monthly-at-home-lab-testing-membership`,
      displayBadge: true,
      badgeText: 'New',
    },
  };

  const giftGuide = hasgiftGuideNavbarNavMenu() && {
    giftGuide: {
      label: 'Gift Guide',
      url: `${WWW_APP_ROOT}/gift-guide`,
      displayBadge: true,
      badgeText: 'Sale',
    },
  };

  baseTopLevelLinks = {
    ...baseTopLevelLinks,
    ...everlywellPlus,
    ...giftGuide,
  };

  shopNavLink.items = shopItems;
  const cart = (
    <a key="cart" href={PUBLIC_APP_ROOT} aria-label="Cart">
      <S.CartStub />
    </a>
  );
  useDocumentScrollThrottled(({ scrollTop, prevScrollTop }) => {
    setShouldShowShadow(scrollTop > 2);
    setTimeout(() => {
      setShouldHide(prevScrollTop < scrollTop && scrollTop > 80);
    }, 200);
  }, THROTTLE);

  analytics.useAttachTrackingToLinks(headerContainerRef);

  const handleMenuClose = useCallback(() => {
    setIsMenuItemOpen(false);
  }, []);

  const handleMenuOpen = useCallback(() => {
    setIsMenuItemOpen(true);
  }, []);

  return (
    <div>
      {isHumanaCustomer() && (
        <S.HeaderWrapper
          data-test={HEADER.HEADER_WRAPPER}
          showShadow={shouldShowShadow}
          shouldHide={!isMenuItemOpen && shouldHide}
          headerOffsetHeight={
            headerContainerRef.current && subHeader
              ? headerContainerRef.current.offsetHeight
              : 0
          }
        >
          <DLSHeaderWithRouter
            headerContainerRef={headerContainerRef}
            items={
              authenticated
                ? desktopResultsNavItems
                : unauthenticatedDesktopResultsNavItems
            }
            logoImage={logoImage}
            iconLogoImage={iconLogoImage}
            mobileItems={
              authenticated
                ? mobileResultsNavItems
                : unauthenticatedMobileResultsNavItems
            }
            logoUrl={
              isHumanaCustomer() ? THIRD_PARTY_APP_ROOT : PUBLIC_APP_ROOT
            }
            onMenuOpenStatusChange={(status: boolean) =>
              setIsMenuItemOpen(status)
            }
            activeMembership={activeMembership}
            numOfMemberCredits={numOfMemberCredits}
          />
          {subHeader}
          {!subHeader && (
            <S.HeaderActivator onMouseEnter={() => setShouldHide(false)} />
          )}
        </S.HeaderWrapper>
      )}
      {isNonHumanaEnterpriseCustomer() && (
        <S.DLSHeader data-healthplan={isNonHumanaEnterpriseCustomer()}>
          <EnterpriseHeader
            accountLinks={enterpriseAccountLinks}
            activeMembership={activeMembership}
            cartComponent={cart}
            data-test="enterprise-header-logo"
            id="everlywellHeader"
            loggedIn={authenticated}
            logoAlt="Everlywell"
            logoSrc={logoImage}
            membershipState={membershipState}
            numOfMemberCredits={numOfMemberCredits}
            onMenuClose={handleMenuClose}
            onMenuOpen={handleMenuOpen}
            root={`${WWW_APP_ROOT}`}
            onLogout={clearUserLocalStorageAndSession}
            {...healthPlanProps()}
          />
          {subHeader}
        </S.DLSHeader>
      )}
      {!isEnterpriseCustomer() && (
        <S.DLSHeader data-healthplan={isNonHumanaEnterpriseCustomer()}>
          <LeavesHeader
            accountLinks={accountLinks}
            activeMembership={activeMembership}
            cartComponent={cart}
            data-test="header-logo"
            userName={user?.first_name}
            id="everlywellHeader"
            loggedIn={authenticated}
            logoAlt="Everlywell"
            logoSrc={logoImage}
            topLevelLinks={baseTopLevelLinks}
            membershipState={membershipState}
            numOfMemberCredits={numOfMemberCredits}
            onMenuClose={handleMenuClose}
            onMenuOpen={handleMenuOpen}
            onMenuItemClick={trackMixpanelEvents}
            root={`${WWW_APP_ROOT}`}
            onLogout={clearUserLocalStorageAndSession}
            headerContent={
              hasWeightCarePlusPromo || hasEverlywellPlusNavMenuActive
                ? buildHeaderContent(flags)
                : undefined
            }
            {...healthPlanProps()}
          />
          {subHeader}
        </S.DLSHeader>
      )}
    </div>
  );
}

export default Header;
